import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Typography } from '@mui/material';

// core components
import { AppForm, AppFormInput, AppSubmitButton } from 'components/new_components/forms';
// redux
import { connect } from 'react-redux';
import { UpdatePassword } from 'store/actions/generalActions';

// validation
import { passwordValidation } from './utils/validation';

//translation
import { useTranslation } from 'react-i18next';

const PasswordMgt = (props) => {
    const { UpdatePassword } = props;

    const [loading, setLoading] = useState(false);

    //translations hook
    const { t } = useTranslation('accounts');

    // async functions
    const updatePassword = async (values) => {
        setLoading(true);
        const res = await UpdatePassword(values);
        setLoading(false);
        if (res?.success) {
            toast.success('Password updated successfully.');
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h2" component="h3" color="primary" sx={{ my: 3, pl: 1 }}>
                {t('passwordManagement.header')}
            </Typography>
            <Box sx={{ maxWidth: '500px' }}>
                <AppForm
                    initialValues={{
                        old_password: '',
                        new_password: '',
                        confirm_password: '',
                    }}
                    onSubmit={updatePassword}
                    validate={passwordValidation}
                >
                    <AppFormInput
                        type="password"
                        label={t('passwordManagement.input1.label')}
                        placeholder={t('passwordManagement.input2.placeholder')}
                        name="old_password"
                    />
                    <AppFormInput
                        type="password"
                        label={t('passwordManagement.input2.label')}
                        placeholder={t('passwordManagement.input2.placeholder')}
                        name="new_password"
                    />
                    <AppFormInput
                        type="password"
                        label={t('passwordManagement.input3.label')}
                        placeholder={t('passwordManagement.input3.placeholder')}
                        name="confirm_password"
                    />

                    <AppSubmitButton
                        text={t('passwordManagement.cta')}
                        variant="contained"
                        color="primary"
                        loadingPosition="center"
                        loading={loading}
                        sx={{ mt: 3 }}
                    />
                </AppForm>
            </Box>
        </Box>
    );
};
const mapStateToProps = (state) => {
    return state;
};
export default connect(mapStateToProps, {
    UpdatePassword,
})(PasswordMgt);
