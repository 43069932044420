import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Input, Row, Select, Space, TimePicker } from 'antd';
import moment from 'moment';
import React from 'react';

const FormItem = (props) => {
    const { form, onEdit, onDelete, title } = props;
    return (
        <Col xs={24} style={{ marginBottom: '1rem' }} key={form?.id}>
            <Card
                title={title}
                extra={
                    <Space>
                        <Button shape="circle" icon={<EditOutlined />} onClick={onEdit} />
                        <Button shape="circle" danger icon={<DeleteOutlined />} onClick={onDelete} />
                    </Space>
                }
                style={{ marginBottom: '1rem' }}
            >
                <Row gutter={16}>
                    {form?.form_fields
                        ?.sort((a, b) => a?.id - b?.id)
                        ?.map((field) => {
                            return (
                                <Col xs={24} md={12} key={field.name}>
                                    <div className="form-group" style={{ marginBottom: '1rem' }}>
                                        <label>
                                            {field?.name} {field?.required && <span style={{ color: 'red' }}>*</span>}
                                        </label>
                                        {field.type === 'text' ? (
                                            <Input type="text" size="large" />
                                        ) : field.type === 'number' ? (
                                            <Input type="number" size="large" required={field?.required} />
                                        ) : field?.type === 'dropdown' ? (
                                            <Select size="large" style={{ width: '100%' }} required={field?.required}>
                                                {field?.options?.split(', ')?.map((option, index) => (
                                                    <Select.Option value={option} key={index}>
                                                        {option}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        ) : field?.type === 'time' ? (
                                            <TimePicker
                                                defaultValue={moment('00:00:00', 'HH:mm:ss')}
                                                size="large"
                                                style={{ width: '100%' }}
                                                required={field?.required}
                                            />
                                        ) : field?.type === 'date' ? (
                                            <DatePicker
                                                defaultValue={moment('00:00:00', 'HH:mm:ss')}
                                                size="large"
                                                style={{ width: '100%' }}
                                                required={field?.required}
                                            />
                                        ) : (
                                            <Input.TextArea rows={4} size="large" required={field?.required} />
                                        )}
                                    </div>
                                </Col>
                            );
                        })}
                </Row>
            </Card>
        </Col>
    );
};

export default FormItem;
