import React from 'react';
import { Tabs, Row, Col } from 'antd';

// core component
import ClauseDocumentsCardsView from './ClauseDocumentsCardsView';
import BreadCrumb from 'components/Breadcrumb';
import ClauseFormsView from './ClauseFormsView';

// icons
import { FileOutlined, FormOutlined } from '@ant-design/icons';

// sub compliance
const { TabPane } = Tabs;

const ClauseFormsAndDocumentsView = (props) => {
    const { location } = props;

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[
                            { label: 'ISO27001-2022', link: '/iso27001_2022' },
                            { label: 'Clauses', link: '/iso27001_2022/clauses' },
                            { label: location?.state?.clause?.title },
                        ]}
                    />
                </Col>
            </Row>
            <section className="my-3">
                <Tabs defaultActiveKey="1">
                    <TabPane
                        tab={
                            <span>
                                <FileOutlined />
                                Documents
                            </span>
                        }
                        key="1"
                    >
                        <ClauseDocumentsCardsView clause_id={location?.state?.clause?.id} />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <FormOutlined />
                                Audit Questions
                            </span>
                        }
                        key="2"
                    >
                        <ClauseFormsView clause_id={location?.state?.clause?.id} />
                    </TabPane>
                </Tabs>
            </section>
        </div>
    );
};

export default ClauseFormsAndDocumentsView;
