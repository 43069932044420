import React from 'react';
import { Box } from '@mui/material';
import { Empty } from 'antd';

const EmptyState = (props) => {
    const { description } = props;
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3, width: '100%', height: '100%' }}>
            <Empty description={description} />
        </Box>
    );
};

export default EmptyState;
