import React from 'react';
import { Typography } from '@mui/material';

const StatusTag = ({ type, text }) => {
    const tagColor = type === 'success' ? '#55BE8B' : type === 'error' ? '#FF5449' : '#B28C00';
    return (
        <Typography
            sx={{
                color: tagColor,
                border: `1px solid ${tagColor}`,
                p: 0.4,
                fontSize: 10,
                fontWeight: 400,
                borderRadius: '2px',
                textTransform: 'capitalize',
            }}
        >
            {text}
        </Typography>
    );
};

export default StatusTag;
