import React from 'react';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AppCenteredModalNew from 'components/new_components/AppCenteredModalNew';
import FileQuestion from 'assets/img/FileQuestion.svg';

const DownloadQuestionnaireModal = (props) => {
    const { open, handleClose } = props;
    const theme = useTheme();
    return (
        <AppCenteredModalNew
            open={open}
            handleClose={handleClose}
            title={
                <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                    Download Questionnaire
                </Typography>
            }
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        backgroundColor: '#F8FAFC',
                        py: '1.5rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{
                            mr: 2,
                            border: '1px solid #E2E8F0',
                            textTransform: 'inherit',
                            color: '#334155',
                            backgroundColor: '#FFFFFF',
                        }}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Box>
            }
            width="540px"
            height="400px"
        >
            <Stack sx={{ py: '8px', mt: 1 }}>
                <Stack
                    flexDirection="row"
                    alignItems="start"
                    sx={{
                        mb: '12px',
                        border: '1px solid #F1F5F9',
                        borderRadius: '5px',
                        my: 1,
                        px: 2,
                        py: 1,
                    }}
                >
                    <img src={FileQuestion} alt="file question" width={35} />
                    <Stack
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ width: '465px', ml: 1 }}
                    >
                        <Stack>
                            <Typography sx={{ color: theme.palette.gray[500], fontWeight: 600, fontSize: '15px' }}>
                                Download AML vendor questionnaire
                            </Typography>
                            <Typography sx={{ color: theme.palette.gray[500], fontWeight: 400, fontSize: '12px' }}>
                                For vendors subjected to anti-money laundering <br /> regulations
                            </Typography>
                        </Stack>
                        <a
                            href={'https://secure-gen.s3.amazonaws.com/templates/AML Vendor Questionnaire.xlsx'}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FileDownloadOutlinedIcon sx={{ color: theme.palette.gray[500], fontSize: '20px' }} />
                        </a>
                    </Stack>
                </Stack>
                <Stack
                    flexDirection="row"
                    alignItems="start"
                    sx={{
                        mb: '12px',
                        border: '1px solid #F1F5F9',
                        borderRadius: '5px',
                        my: 1,
                        px: 2,
                        py: 1,
                    }}
                >
                    <img src={FileQuestion} alt="file question" width={35} />
                    <Stack
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ width: '465px', ml: 1 }}
                    >
                        <Stack>
                            <Typography sx={{ color: theme.palette.gray[500], fontWeight: 600, fontSize: '15px' }}>
                                Download Non-AML vendor questionnaire
                            </Typography>
                            <Typography sx={{ color: theme.palette.gray[500], fontWeight: 400, fontSize: '12px' }}>
                                For vendors not subjected to anti-money laundering <br /> regulations
                            </Typography>
                        </Stack>
                        <a
                            href={'https://secure-gen.s3.amazonaws.com/templates/NON-AML Vendor Questionnaire.xlsx'}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FileDownloadOutlinedIcon sx={{ color: theme.palette.gray[500], fontSize: '20px' }} />
                        </a>
                    </Stack>
                </Stack>
            </Stack>
        </AppCenteredModalNew>
    );
};

export default DownloadQuestionnaireModal;
