import React, { useState, useEffect } from 'react';
//core components
import ScanGroups from './Components/ScanGroups';
import ScansView from './Components/ScansView';
// import ScansDetails from './Components/ScanDetails';
import CircularLoader from 'components/Loaders/CircularLoader';

//redux
import { connect, useSelector } from 'react-redux';
import {
    GetAutomatedScanGroups,
    GetGroupScanResults,
    AssignedAutomatedScan,
} from 'store/actions/automatedScansActions';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import { GetAllCompanyEmployees } from 'store/actions/generalActions';

const Scans = (props) => {
    const [loading, setLoading] = useState(false);

    const {
        GetAutomatedScanGroups,
        all_automated_scan_groups,
        view,
        changeView,
        GetGroupScanResults,
        category,
        setCategory,
        AssignedAutomatedScan,
        GetAllCompanyEmployees,
    } = props;

    const user_type = useSelector((state) => state?.authReducers?.user_type);

    // functions
    const getAllAutomatedScanGroups = async () => {
        setLoading(true);
        const res = await GetAutomatedScanGroups();
        setLoading(false);
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    const assignedAutomatedScan = async () => {
        const res = await AssignedAutomatedScan();
        if (!res.message) {
            toast.error(res.message);
        }
    };

    // async functions
    const getAllMerchantEmployees = async () => {
        const res = await GetAllCompanyEmployees();
        if (!res.message) {
            toast.error(res.message);
        }
    };
    // useEffect
    useEffect(() => {
        getAllAutomatedScanGroups();
    }, []);

    useEffect(() => {
        if (user_type !== 'auditor') {
            assignedAutomatedScan();
            getAllMerchantEmployees();
        }
    }, [user_type]);

    const handleChangeView = async (viewType, category) => {
        setLoading(true);
        changeView(viewType);
        setCategory(category);
        if (viewType === 'scans') {
            const res = await GetGroupScanResults();
            setLoading(false);
            if (!res.success) {
                toast.error('Something went wrong! Unable to fetch results.');
            }
        }
    };
    const handleCategory = (category) => {
        setCategory(category);
    };
    return (
        <>
            {loading ? (
                <CircularLoader />
            ) : (
                <Box sx={{ height: 'calc(100vh - 150px)' }}>
                    {view === 'groups' ? (
                        <ScanGroups
                            handleChangeView={handleChangeView}
                            groups={all_automated_scan_groups}
                            isScan={true}
                            category={category}
                            setCategory={setCategory}
                            handleCategory={handleCategory}
                        />
                    ) : (
                        <ScansView
                            goBack={handleChangeView}
                            category={category}
                            isScan={true}
                            view={view}
                            loading={loading}
                        />
                    )}
                </Box>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_automated_scan_groups: state?.automatedScansReducers?.all_automated_scan_group,
    };
};

export default connect(mapStateToProps, {
    AssignedAutomatedScan,
    GetAutomatedScanGroups,
    GetAllCompanyEmployees,
    GetGroupScanResults,
})(Scans);
