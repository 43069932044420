import React from 'react';
import { Progress } from 'antd';

const BaseProgressBar = ({ text, percentage = 70, styleClass = '' }) => {
    return (
        <div>
            <div className={`${styleClass} w-full mt-3 transition`}>
                <Progress percent={percentage} showInfo={false} strokeColor="#0E2C66" style={{ marginBottom: '0' }} />
            </div>
            <p className="flex textbrandColor font-bold lg:text-[13px] text-[7px] text-right justify-end">{text}</p>
        </div>
    );
};

export default BaseProgressBar;
