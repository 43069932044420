import React from 'react';
import { Box } from '@mui/material';

// core components
import SidebarDrawer from './SidebarDrawer';
import { connect } from 'react-redux';

const MerchantSidebar = (props) => {
    const { open, handleClose, routes, merchant_info, ref2, title } = props;

    return (
        <Box
            // component="nav"
            sx={{
                width: { md: 280 },
                flexShrink: { md: 0 },
                height: '100vh',
                zIndex: 100,
            }}
        >
            {/* for small screens */}
            <SidebarDrawer
                largeScreen={false}
                open={open}
                onClose={handleClose}
                routes={routes}
                title={title}
                ref2={ref2}
            />

            {/* for large screens */}
            <SidebarDrawer largeScreen={true} routes={routes} merchant_info={merchant_info} title={title} ref2={ref2} />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant_info: state?.merchantReducers?.merchant_info,
    };
};
export default connect(mapStateToProps, {})(MerchantSidebar);

// export default MerchantSidebar;
