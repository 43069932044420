export const steps = [
    {
        title: 'Sub-Requirement',
        description: 'Each criterion has sub-requirements that must be met, including policies and audit questions.',
    },
];
export const moreSteps = [
    {
        title: 'Not Applicable',
        description: `If a document or collection of policies doesn't apply to your organization's environment, click this button.`,
    },
    {
        title: 'Additional Menu',
        description:
            'This option displays the history of your documents and policies, the history of your responses to audit questions, and a code review for blockchain compliance.',
    },
];
