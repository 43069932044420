import { API_SCANS, API_SCAN_RESULT_BY_SCAN, SOURCE_CODE, CODE_REVIEW_REPORT_BY_SCAN } from '../constants';

import axiosInstance from '../../utils/https';

// ALL API SCANS
export const GetAllAPIScans = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/api_security/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: API_SCANS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const RequestAPIScans = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/compliance/api_security/`, { ...credentials, merchant: company_id });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAPIScans());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const DeleteScanReport = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/api_security/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAPIScans());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAPIScanResultByScan = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/api_result/?api_compliance=${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: API_SCAN_RESULT_BY_SCAN, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// ALL API SCANS
export const GetAllCodeReviewScans = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/asvc_requests/source_code`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: SOURCE_CODE, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const RequestCodeReviewcans = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/asvc_requests/source_code/`, {
            ...credentials,
            merchant_id: company_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCodeReviewScans());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const UpdateRequestCodeReviewcans = (credentials, id) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().patch(`/asvc_requests/source_code/${id}/`, {
            ...credentials,
            merchant_id: company_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCodeReviewScans());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const DeleteCodeReviewReport = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/asvc_requests/source_code/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCodeReviewScans());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetCodeReviewReports = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/asvc_requests/source_code_report/?source_code=${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: CODE_REVIEW_REPORT_BY_SCAN, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
