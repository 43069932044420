import { Box, Typography } from '@mui/material';
import Card from './components/Card';
import AppSelectInput from 'components/new_components/AppSelectInput';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import { GetFraudMonitoringSummary } from 'store/actions/FraudGauardianActions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LoadingState from 'components/new_components/LoadingState';

const Overview = () => {
    // Hook state
    const [year, setYear] = useState('Current Year');
    const [loading, setLoading] = useState(false);

    // Redux state
    const dispatch = useDispatch();
    const fraud_summary = useSelector((state) => state?.fraudGuardianReducers?.fraud_summary);

    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

    const option = {
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: true,
                color: '#395BA9',
                formatter: Math.round,
                anchor: 'end',
                offset: -20,
                align: 'start',
            },
            tooltip: {
                yAlign: 'bottom',
                backgroundColor: '#395BA9',
                font: {
                    weight: 700,
                    size: 12,
                },
                callbacks: {
                    label: (context) => {
                        let label = '';
                        if (context.parsed.y) {
                            label = context.parsed.y + ' transactions';
                        }
                        return label;
                    },
                },
            },
        },
        elements: {
            point: {
                radius: 0,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                ticks: {
                    color: '#A3AED0',
                    font: {
                        weight: 500,
                        size: 12,
                    },
                },
            },
            y: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                min: 0,
                max: 100,
                ticks: {
                    color: '#A3AED0',
                    stepSize: 10,
                    font: {
                        weight: 500,
                        size: 12,
                    },
                },
            },
        },
    };

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    const data = {
        labels,
        datasets: [
            {
                label: 'Transactions',
                data: fraud_summary?.monthly_data?.map((summary) => summary?.total_fraud_transactions),
                borderColor: '#395BA9',
                backgroundColor: '#395BA9',
                pointHoverBackgroundColor: '#395BA9',
                pointBorderColor: 'transparent',
                pointBackgroundColor: 'transparent',
                pointBorderWidth: 0,
                pointHoverRadius: 0,
                lineTension: 0.3,
            },
        ],
    };

    const date = new Date();
    const now = new Date('2020').getUTCFullYear();
    const years = Array(now - (now - 5))
        .fill('')
        .map((v, idx) => now + idx);

    const yearsOptions = years.map((year) => {
        return {
            name: year === date?.getFullYear() ? 'Current Year' : year,
            value: year === date?.getFullYear() ? 'Current Year' : year,
        };
    });
    // Asymc function
    const getFraudMonitoringSummary = async (currentYear) => {
        setLoading(true);
        const year = currentYear === 'Current Year' ? '2024' : currentYear;
        const res = await dispatch(GetFraudMonitoringSummary(year));
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        getFraudMonitoringSummary(year);
    }, [year]);

    const handleChange = (e) => {
        setYear(e.target.value);
        getFraudMonitoringSummary(e.target.value);
    };

    return (
        <Box sx={{ m: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 3 }}>
                <Card
                    title="Total Transaction"
                    value={fraud_summary?.total_fraud_transactions}
                    sx={{ height: '152px' }}
                />
                <Card
                    title="Total Fraudulent"
                    value={fraud_summary?.status_counts?.total_fraudulent}
                    sx={{ height: '152px' }}
                />
                <Card title="Total Clean" value={fraud_summary?.status_counts?.total_clean} sx={{ height: '152px' }} />
            </Box>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', height: '400px', gap: 3 }}>
                <Box sx={{ backgroundColor: '#FFFFFF', width: '50%', px: 2, pt: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2.5 }}>
                        <Box sx={{ width: '75%' }}>
                            <Typography sx={{ color: '#2B3674', fontSize: '20px', fontWeight: 600 }}>
                                Fraudulent transactions metrics
                            </Typography>
                        </Box>
                        <Box sx={{ width: '25%', float: 'right' }}>
                            <AppSelectInput
                                name="Current Year"
                                options={yearsOptions}
                                defaultValue={'Current Year'}
                                sx={{
                                    border: 'none !important',
                                    fontSize: 12,
                                    mt: '-1rem',
                                    backgroundColor: '#F8FAFC',
                                    color: '#A3AED0',
                                    fontWeight: 500,
                                }}
                                value={year}
                                // value={year === date?.getFullYear() ? 'Current Year' : year}
                                onChange={handleChange}
                                size="small"
                            />
                        </Box>
                    </Box>
                    {loading ? (
                        <Box
                            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '4rem' }}
                        >
                            <LoadingState />
                        </Box>
                    ) : (
                        <Line options={option} data={data} />
                    )}
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        width: '50%',
                        gap: 3,
                        height: '380px',
                    }}
                >
                    <Box>
                        <Card
                            title="Total Failed Transaction"
                            value={fraud_summary?.status_counts?.total_failed}
                            sx={{ height: '180px' }}
                        />
                    </Box>
                    <Box>
                        <Card
                            title="Total successful Transaction"
                            value={fraud_summary?.status_counts?.total_successful}
                            sx={{ height: '180px' }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Overview;
