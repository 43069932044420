import React, { useState, useEffect } from 'react';
//core components
import { Box, Stack } from '@mui/material';
import EmptyTable from 'components/new_components/app-table/EmptyTable';
import Questionnaire from './Questionnaire';

// import { data } from "../../../../utils/constants";

const QuestionsLayout = (props) => {
    const {
        selectedQuestionnaire,
        handleAnswerInput,
        handleCommentInput,
        handleAnswerSelect,
        handleCommentSelect,
        fieldValues,
        handleSingleApproveAction,
        handleDisapproveAction,
        handleDeleteQuestion,
        // handleShowPredictionScore,
        // handleHidePredictionScore,
        // showPredictionScore,
        acceptedAnswers,
    } = props;

    const [questions, setQuestions] = useState([]);
    useEffect(() => {
        const availableQuestions = Object.keys(selectedQuestionnaire?.predictions?.Question || {});
        const deletedQuestions = Object.keys(selectedQuestionnaire?.deleted || {});
        const questionsDifference = availableQuestions?.filter((x) => !deletedQuestions.includes(x));
        if (availableQuestions?.length) {
            setQuestions(questionsDifference);
        }
    }, [selectedQuestionnaire, selectedQuestionnaire?.deleted]);

    return (
        <Box
            sx={{
                p: 4,
                height: 'calc(100vh - 340px)',
                overflowY: 'auto',
            }}
        >
            {questions?.length ? (
                questions?.map((question, idx) => {
                    return (
                        <div key={idx} className="block">
                            <Questionnaire
                                key={idx}
                                field={selectedQuestionnaire?.predictions?.Question?.[question]}
                                responses={selectedQuestionnaire?.predictions?.Response}
                                confidence={selectedQuestionnaire?.predictions?.Confidence}
                                comments={selectedQuestionnaire?.predictions?.Comment}
                                questionId={selectedQuestionnaire?.predictions?.Question?.[question]}
                                questionsIndex={question}
                                fieldValues={fieldValues}
                                acceptedAnswers={acceptedAnswers}
                                handleAnswerInput={handleAnswerInput}
                                handleCommentInput={handleCommentInput}
                                handleAnswerSelect={handleAnswerSelect}
                                handleCommentSelect={handleCommentSelect}
                                handleSingleApproveAction={handleSingleApproveAction}
                                handleDisapproveAction={handleDisapproveAction}
                                handleDeleteQuestion={handleDeleteQuestion}
                                completed={selectedQuestionnaire?.status === 'completed'}
                                // handleShowPredictionScore={handleShowPredictionScore}
                                // handleHidePredictionScore={handleHidePredictionScore}
                                // showPredictionScore={showPredictionScore}
                                // selectedQuestionnaire={selectedQuestionnaire}
                            />
                        </div>
                    );
                })
            ) : (
                <Stack alignItems="center" justifyContent="center">
                    <EmptyTable description="No Fields Available" />
                </Stack>
            )}
        </Box>
    );
};

export default QuestionsLayout;
