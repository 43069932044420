import React, { useState } from 'react';
import { Stack } from '@mui/material';

// core components
import ExceptionGroupListItem from './ExceptionGroupListItem';
import ExceptionSubgroupAccordion from './ExceptionSubgroupAccordion';

//translation
import { useTranslation } from 'react-i18next';

const ExceptionGroups = (props) => {
    const { groups, handleSubgroupIdChange, selectedSubgroup, standard, hasSubgroup } = props;
    const [activeGroup, setActiveGroup] = useState(null);

    //translation
    const { t } = useTranslation('compliance');

    //function
    const handleAccordionToggle = (panel) => (_, newExpanded) => {
        setActiveGroup(newExpanded ? panel : false);
        handleSubgroupChange(null);
    };
    // the resources are selected by the subgroup ID
    const handleSubgroupChange = (id) => {
        handleSubgroupIdChange(id);
    };

    return (
        <Stack sx={{ py: 3.5 }}>
            {hasSubgroup
                ? groups?.map((group) => (
                      <ExceptionSubgroupAccordion
                          handleChange={handleAccordionToggle}
                          handleSubgroupChange={handleSubgroupChange}
                          active={activeGroup}
                          activeSubgroup={selectedSubgroup}
                          group={group}
                          key={group?.id}
                          id={group?.id}
                          subgroupInfo={`${t('selectSub')} ${
                              standard === 'pcidss' || standard === 'card4' ? t('req') : t('control')
                          } ${t('toView')}`}
                      />
                  ))
                : groups?.map((group) => {
                      return (
                          <ExceptionGroupListItem
                              handleGroupChange={handleSubgroupChange}
                              active={selectedSubgroup}
                              group={group}
                              key={group?.id}
                              id={group?.id}
                          />
                      );
                  })}
        </Stack>
    );
};

export default ExceptionGroups;
