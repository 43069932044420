import React from 'react';
import { Chip, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

const AppChip = ({ text, background, noBg }) => {
    const theme = useTheme();

    return (
        <Chip
            sx={{ background: background }}
            label={
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: noBg ? theme.palette.primary.main : theme.palette.white.main,
                        textTransform: 'capitalize',
                    }}
                >
                    {text}
                </Typography>
            }
        />
    );
};

export default AppChip;
