import { UserGuideHeader } from 'components/new_components/AppUserGuide';

const STEPS_LENGTH = 2;

export const steps = [
    {
        title: <UserGuideHeader title="Add Employees" id={1} length={STEPS_LENGTH} />,
        description: 'Add an employee for a quicker onboarding process',
        placement: 'rightTop',
    },
    {
        title: <UserGuideHeader title="Upload from CSV" id={2} length={STEPS_LENGTH} />,
        description: 'Use this to add many employees at once.',
        placement: 'rightTop',
    },
];
