import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { Box, Card, Container, Divider, Grid, Stack } from '@mui/material';

// core components
import CompanyInfo from './CompanyInfo';
import PageHeader from 'components/new_components/PageHeader';

// redux
import { connect } from 'react-redux';
import { GetCompanyDetails, GetAllMerchantUsers } from 'store/actions/merchantActions';
import OwnerInfo from './OwnerInfo';
import AuditorsInfo from './AuditorsInfo';

const CompanySetup = (props) => {
    const theme = useTheme();
    const { GetCompanyDetails, GetAllMerchantUsers } = props;

    const [loading, setLoading] = useState({ content: false });

    const getCompanyDetails = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetCompanyDetails();
        setLoading({ ...loading, content: false });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    // this is to get the auditor users
    const getMerchantUsers = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAllMerchantUsers();
        setLoading({ ...loading, content: false });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        getCompanyDetails();
        getMerchantUsers();
    }, []);

    return (
        <>
            <PageHeader browserTitle="Company Setup | Smartcomply" />
            <Container
                component={Card}
                maxWidth={false}
                elevation={0}
                sx={{
                    p: '0 !important',
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container sx={{ flex: 1 }}>
                    <Grid
                        item
                        xs={12}
                        xl={6}
                        sx={{
                            borderRight: {
                                xs: 'none',
                                xl: '1px solid ' + theme.palette.gray[100],
                            },
                            borderBottom: {
                                xs: '1px solid ' + theme.palette.gray[100],
                                xl: 'none',
                            },
                            minHeight: '100%',
                        }}
                    >
                        <CompanyInfo />
                    </Grid>
                    <Grid item xs={12} xl={6} component={Stack}>
                        <Stack
                            divider={<Divider orientation="horizontal" sx={{ color: theme.palette.gray[100] }} />}
                            sx={{ width: '100%' }}
                        >
                            <Box sx={{ minHeight: '50%' }}>
                                <OwnerInfo />
                            </Box>
                            <Box>
                                <AuditorsInfo />
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps, {
    GetCompanyDetails,
    GetAllMerchantUsers,
})(CompanySetup);
