import { MoreHoriz } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

const RiskTableAction = (props) => {
    const { row, handleDeleteOpen, openViewDetails, handleEditOpen } = props;
    const [actionAnchorEl, setActionAnchorEl] = useState(null);

    return (
        <div>
            <button
                aria-controls={actionAnchorEl ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={actionAnchorEl ? 'true' : undefined}
                onClick={(event) => {
                    event.stopPropagation();
                    setActionAnchorEl(event.currentTarget);
                }}
                className="hover:scale-90 transition-all"
            >
                <MoreHoriz />
            </button>
            <Menu
                anchorEl={actionAnchorEl}
                open={Boolean(actionAnchorEl)}
                onClose={() => setActionAnchorEl(null)}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '4px',
                        boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                    },
                    '& .MuiList-root': {
                        padding: 0,
                    },
                }}
            >
                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation();
                        openViewDetails(row);
                        setActionAnchorEl(null);
                    }}
                    sx={{
                        color: '#64748B',
                        fontWeight: 500,
                        fontSize: '14px',
                        textTransform: 'none',
                        borderBottom: '1px solid #F1F5F9',
                        padding: '8px 16px',
                    }}
                >
                    View details
                </MenuItem>

                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation();
                        handleEditOpen(row);
                        setActionAnchorEl(null);
                    }}
                    sx={{
                        color: '#64748B',
                        fontWeight: 500,
                        fontSize: '14px',
                        textTransform: 'capitalize',
                        borderBottom: '1px solid #F1F5F9',
                        padding: '8px 16px',
                    }}
                >
                    Edit
                </MenuItem>

                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteOpen(row);
                        setActionAnchorEl(null);
                    }}
                    sx={{
                        color: '#FF5449',
                        fontWeight: 500,
                        fontSize: '14px',
                        textTransform: 'capitalize',
                        borderBottom: '1px solid #F1F5F9',
                        padding: '8px 16px',
                    }}
                >
                    Delete
                </MenuItem>
            </Menu>
        </div>
    );
};

export default RiskTableAction;
