import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
// core components
import LoadingState from 'components/new_components/LoadingState';
import ControlCard from './ControlCard';
// redux
import { connect } from 'react-redux';

//translations
import { useTranslation } from 'react-i18next';

const Controls = (props) => {
    const {
        resource,
        onViewChange,
        loading,
        all_controls,
        all_controls_metrics,
        all_exempted_controls,
        openTaskAssignModal,
        all_compliance_assigned_tasks,
        all_soc2_documents,
        all_soc2_forms,
    } = props;

    //translation
    const { t } = useTranslation('compliance');

    const sortedControls = useMemo(() => {
        if (!all_controls || !all_exempted_controls) {
            return [];
        }

        //sort to get controls not exempted
        const notExemptedControls = all_controls.filter((control) => {
            return !all_exempted_controls.some(
                (exempted_control) => exempted_control?.soc_detail?.title === control?.title
            );
        });
        //sort to get controls exempted
        const exemptedControls = all_controls
            .filter((control) => {
                return all_exempted_controls.some(
                    (exempted_control) => exempted_control?.soc_detail?.title === control?.title
                );
            })
            .map((ec) => ({
                ...ec,
                exempted: true,
            }));

        return [...notExemptedControls, ...exemptedControls];
    }, [all_controls, all_exempted_controls]);

    const subControlAssignedChecker = (assignedSubControl, sub_controls) => {
        let matchedRequirements;
        const subControls = (sub_controls?.length ? sub_controls : [])?.map((subCon) => subCon?.title);
        matchedRequirements = assignedSubControl?.filter((item) => {
            return subControls?.includes(item);
        });
        return matchedRequirements?.length;
    };

    const documentsByRequirement = useMemo(() => {
        return all_soc2_documents?.filter((document) => {
            const soc2Documents = all_compliance_assigned_tasks?.documents?.forms?.soc2 || [];

            const res = soc2Documents?.length ? soc2Documents.filter((item) => document?.id === item) : [];

            return res?.length ? document?.sub_control : null;
        });
        // .map((document) => Math.floor(document?.requirement_sub_no));
    }, [all_soc2_documents, all_compliance_assigned_tasks?.documents?.forms?.soc2]);

    const formsByRequirement = useMemo(() => {
        return all_soc2_forms?.filter((form) => {
            const soc2Forms = all_compliance_assigned_tasks?.questions?.forms?.soc2 || [];

            const res = soc2Forms?.length ? soc2Forms.filter((item) => form?.id === item) : [];

            return res?.length ? form?.sub_control : null;
        });
        // .map((form) => Math.floor(form?.requirement_sub_no));
    }, [all_soc2_forms, all_compliance_assigned_tasks?.documents?.forms?.soc2]);
    return !loading ? (
        <Grid container spacing={2.5} sx={{ p: 4, pt: 6 }}>
            {sortedControls
                ? sortedControls?.map((control) => {
                      const { total_form, total_doc, user_form, user_doc } =
                          all_controls_metrics?.[control?.title] || {};
                      const metric =
                          resource === 'doc'
                              ? { totalValue: total_doc, totalAttendedTo: user_doc }
                              : { totalValue: total_form, totalAttendedTo: user_form };
                      const resourceLabel = resource === 'doc' ? t('fileUploaded') : t('questionAnswered');
                      const isControlAssigned =
                          resource === 'doc'
                              ? all_compliance_assigned_tasks?.documents?.category?.soc2.includes(control?.id)
                              : all_compliance_assigned_tasks?.questions?.category?.soc2?.includes(control?.id);
                      const isSubControlAssigned =
                          resource === 'doc'
                              ? subControlAssignedChecker(
                                    all_compliance_assigned_tasks?.documents?.sub_category?.soc2,
                                    control?.sub_controls
                                )
                              : subControlAssignedChecker(
                                    all_compliance_assigned_tasks?.questions?.sub_category?.soc2,
                                    control?.sub_controls
                                );

                      return (
                          <ControlCard
                              key={control?.id}
                              id={control?.id}
                              title={control?.title}
                              description={control?.description}
                              service={control?.service_category}
                              exempted={control?.exempted}
                              metric={metric}
                              resourceLabel={resourceLabel}
                              onViewChange={onViewChange}
                              isControlAssigned={isControlAssigned}
                              isSubControlAssigned={isSubControlAssigned}
                              openTaskAssignModal={openTaskAssignModal}
                              resourceAssigned={resource === 'doc' ? documentsByRequirement : formsByRequirement}
                          />
                      );
                  })
                : t('noControl')}
        </Grid>
    ) : (
        <LoadingState />
    );
};
const mapStateToProps = (state) => {
    return {
        all_controls: state?.adminReducers?.all_controls,
        all_controls_metrics: state?.complianceReducers?.all_controls_metrics,
        all_exempted_controls: state?.complianceReducers?.all_exempted_controls,
        all_soc2_documents: state?.adminReducers?.all_documents_by_tags,
        all_soc2_forms: state?.adminReducers?.all_forms_by_tag,
    };
};
export default connect(mapStateToProps, {})(Controls);
