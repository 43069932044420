import React, { forwardRef } from 'react';
import { Tab } from '@mui/material';

const AppTab = forwardRef((props, ref) => {
    const { id, sx, tabVariant, ...otherProps } = props;

    // functions
    const getId = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    return (
        <Tab
            {...otherProps}
            {...getId(id)}
            sx={{ fontSize: 15, whiteSpace: 'inherit', mr: 1, ...sx }}
            variant={tabVariant}
            ref={ref}
        />
    );
});

export default AppTab;
