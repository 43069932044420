import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
//Components
import { Table, Button, Modal, Row, Col, Input } from 'antd';
import { ThreatOperations } from './ThreatOperations/ThreatOperations';
import ThreatModal from './ThreatOperations/ThreatModal';
import MultipleThreatModal from './ThreatOperations/MultipleThreats';
import EditModal from './ThreatOperations/EditModal';
import { SearchOutlined } from '@ant-design/icons';

//Icons
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

//Redux
import { connect, useDispatch, useSelector } from 'react-redux';
import { GetAllThreats, DeleteThreat } from '../../../../store/actions/adminActions';
import useSearch from 'hooks/useSearch';

const Threat = () => {
    const [loading, setloading] = useState(false);
    const [AddModal, setAddModal] = useState(false);
    const [AddMultipleModal, setAddMultipleModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [record, setrecord] = useState(null);

    const dispatch = useDispatch();
    const all_threats = useSelector((state) => state.adminReducers.all_threats);
    const { data, handleSearch } = useSearch(all_threats, ['name']);

    const columns = [
        {
            title: 'Threat',
            render: (record) => record?.name,
        },
        {
            title: 'Threat Category',
            render: (record) => record?.threat_category_detail?.name,
        },
        {
            title: 'Tag',
            render: (record) => record?.tag,
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => (
                <ThreatOperations record={record} openUpdateModal={openEditModal} deleteModal={deleteModal} />
            ),
        },
    ];
    const openAddModal = () => {
        setAddModal(true);
    };
    const closeAddModal = () => {
        setAddModal(false);
    };

    const openAddMultipleModal = () => {
        setAddMultipleModal(true);
    };
    const closeAddMultipleModal = () => {
        setAddMultipleModal(false);
    };

    //Edit Modal
    const openEditModal = (data) => {
        setrecord(data);
        setEditModal(true);
    };
    const closeEditModal = () => {
        setEditModal(false);
    };

    const deleteModal = (threat) => {
        Modal.confirm({
            title: 'Do you want to delete this threat?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await dispatch(DeleteThreat(threat?.id));
                if (res?.success) {
                    toast.success('Threat Deleted Successfully');
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };
    useEffect(() => {
        const getAllThreats = async () => {
            setloading(true);
            const res = await dispatch(GetAllThreats());
            setloading(false);
            if (!res.success) {
                toast.error(res?.message);
            }
        };

        getAllThreats();
    }, []);

    return (
        <div>
            <Row justify="end">
                <Col span={12} className="flex items-center gap-5">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                    <Button
                        onClick={openAddModal}
                        type="primary"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        Bulk Threats
                    </Button>
                    <Button
                        onClick={openAddMultipleModal}
                        type="primary"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        Add Threats
                    </Button>
                </Col>
            </Row>

            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(data) => data?.id}
                    columns={columns}
                    loading={loading}
                    dataSource={data?.sort((a, b) => b?.id - a?.id)}
                    pagination={{
                        total: data?.length,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                    }}
                />
            </section>
            <ThreatModal open={AddModal} handleClose={closeAddModal} />
            <MultipleThreatModal open={AddMultipleModal} handleClose={closeAddMultipleModal} />
            <EditModal open={editModal} handleClose={closeEditModal} record={record} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_threats: state?.adminReducers?.all_threats,
    };
};
export default connect(mapStateToProps, { GetAllThreats, DeleteThreat })(Threat);
