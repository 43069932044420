import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';

// core component
import AnswerHistory from '../../common/audit_questions/AnswerHistory';
import LoadingState from 'components/new_components/LoadingState';

// redux
import { connect } from 'react-redux';
import { GetAllAnswerHistory, ConditionalFormAnswer } from 'store/actions/complianceActions';
import AppViewModal from 'components/new_components/AppViewModal';

const AnswerHistoryDrawer = (props) => {
    const { open, handleClose, GetAllAnswerHistory, answer_history, form, exception } = props;
    // state
    const [loading, setLoading] = useState(false);

    const getAllAnswerHistory = async () => {
        setLoading(true);
        const res = await GetAllAnswerHistory(form.form_id);
        setLoading(false);
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log('Error in answer history drawer, error:::', res.message);
        }
    };

    useEffect(() => {
        if (!exception) {
            getAllAnswerHistory();
            return () => setLoading(false);
        }
    }, [form?.form_id, form?.conditional, open, exception]);

    return (
        <>
            <AppViewModal open={open} handleClose={handleClose} title="Answer History" anchor="right" width={500}>
                <Box sx={{ py: 1 }}>
                    {!loading ? (
                        answer_history &&
                        answer_history
                            ?.sort((a, b) => b?.id - a?.id)
                            ?.map((answer) => {
                                return <AnswerHistory answer={answer} key={answer.id} />;
                            })
                    ) : (
                        <LoadingState />
                    )}
                </Box>
            </AppViewModal>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        answer_history: state?.complianceReducers?.answer_history,
        all_condition_answers: state?.complianceReducers?.all_condition_answers,
    };
};
export default connect(mapStateToProps, { GetAllAnswerHistory, ConditionalFormAnswer })(AnswerHistoryDrawer);
