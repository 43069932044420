import React from 'react';
import { Route, Switch } from 'react-router';
import Courses from './Courses';
import CourseResourcesView from './CourseResourcesView';

const Training = () => {
    return (
        <Switch>
            <Route path="/admin/training" exact component={Courses} />
            <Route path="/admin/training/:course_id" exact component={CourseResourcesView} />
        </Switch>
    );
};

export default Training;
