import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { AppFormSelect, AppFormInput } from 'components/new_components/forms';
import { connect } from 'react-redux';
import { StatusOption, timelineOptions } from '../../../utils/constants';
import Cloud from 'assets/img/cloud.svg';
import { GetAllMerchantDocuments } from 'store/actions/complianceActions';
import { toast } from 'react-toastify';
import { accept } from 'validate';
import Pdf from 'assets/img/pngfile.png';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

//translations
import { useTranslation } from 'react-i18next';

const Form3 = (props) => {
    const { new_document, setNew_document, GetAllMerchantDocuments, all_merchant_documents, setExisting_, existing_ } =
        props;
    const [img, setImg] = useState('');
    const [document, setDocument] = useState('existing');
    // const { pdf, ms, png, jpeg } = accept;

    //translation
    const { t } = useTranslation('riskAssessment');

    // UPLOAD IMAGE ONCHANGE
    const uploadImage = async (event) => {
        const input = event.target;
        const file = input.files?.item(0);
        const base64 = await convertedBase64(file);
        setNew_document(file);
        setImg(base64);
    };
    // CONVERT IMAGE TO BASE 64
    const convertedBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const filReader = new FileReader();
            filReader.readAsDataURL(file);
            filReader.onload = () => {
                resolve(filReader.result);
            };
            filReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const dragEnter = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#C4C4C4';
        target.style.backgroundColor = '#A58980';
    };

    const dragLeave = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#A58980';
        target.style.backgroundColor = 'transparent';
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const dragDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type.includes('image')) {
            const reader = new FileReader();
            reader.onload = () => {
                setImg(reader.result);
            };
            reader.readAsDataURL(file);
            convertedBase64(file).then(() => {});
        } else {
            // enqueueSnackbar('Invalid Data Dropped', { variant: 'error' });
        }
        dragLeave();
    };

    useEffect(() => {
        const getAllMerchantDocuments = async () => {
            const res = await GetAllMerchantDocuments();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllMerchantDocuments();
    }, []);

    const filterDoc = all_merchant_documents?.filter(
        (docs) =>
            docs?.document_type?.iso_title ||
            docs?.document_type?.ndpr_title ||
            docs?.document_type?.soc2_title ||
            docs?.document_type?.document_title
    );

    const docLinkOptions = useMemo(
        () =>
            // Array.isArray(linkDocument) ?
            filterDoc
                ?.sort((a, b) => a?.id - b?.id)
                ?.map((doc) => {
                    return {
                        name:
                            doc?.document_type?.iso_title ||
                            doc?.document_type?.ndpr_title ||
                            doc?.document_type?.document_title ||
                            doc?.document_type?.soc2_title,
                        value: doc?.id,
                    };
                }),
        // : [],
        [filterDoc]
    );

    return (
        <Box sx={{ overflow: 'scroll !Important' }}>
            <AppFormInput
                type="text"
                label={t('cyberSecurityAssessment.recommended')}
                name="recommended_controls"
                placeholder={t('cyberSecurityAssessment.recommended')}
            />

            <Grid container spacing={2}>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <AppFormSelect
                        name="timeline"
                        label={t('cyberSecurityAssessment.timeline')}
                        options={timelineOptions}
                        defaultValue={t('cyberSecurityAssessment.timelineValue')}
                        required
                    />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <AppFormInput
                        type="text"
                        label={t('cyberSecurityAssessment.responsibility')}
                        name="responsibility"
                        placeholder={t('cyberSecurityAssessment.responsibilityValue')}
                    />
                </Grid>
            </Grid>
            <Box sx={{ marginTop: 2 }}>
                <Typography sx={{ color: '#161B28', fontSize: '14px' }}>Policy/document</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                    <Typography
                        onClick={() => setDocument('existing')}
                        sx={{
                            borderBottom: document === 'existing' ? '2px solid #002C72' : '',
                            color: document === 'existing' ? '#303034' : '#303034',
                            cursor: 'pointer',
                            fontSize: '14px',
                        }}
                    >
                        {t('cyberSecurityAssessment.useExisting')}
                    </Typography>
                    <Typography
                        sx={{
                            borderBottom: document === 'new' ? '2px solid #002C72' : '',
                            marginLeft: 2,
                            fontSize: '14px',
                            cursor: 'pointer',
                        }}
                        onClick={() => setDocument('new')}
                    >
                        {t('cyberSecurityAssessment.new')}
                    </Typography>
                </Box>
            </Box>
            {document === 'existing' ? (
                <Box>
                    <AppFormInput
                        type="text"
                        label={t('cyberSecurityAssessment.controlRequirement')}
                        name="control_requirement"
                        placeholder={t('cyberSecurityAssessment.controlRequirementValue')}
                    />
                    <Box sx={{ marginTop: 2 }}>
                        <Typography sx={{ color: '#303034', fontWeigh: 600, fontSize: '14px' }}>
                            {t('cyberSecurityAssessment.existing')}
                        </Typography>
                        <Autocomplete
                            value={existing_}
                            onChange={(event, value) => setExisting_(value)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            options={docLinkOptions?.map((doc) => ({
                                label: doc?.name,
                                value: doc?.value,
                            }))}
                            renderInput={(params) => <TextField {...params} size={'small'} variant={'outlined'} />}
                        />
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: '1.5rem',
                        border: '0.7px solid #C7C6CA',
                        borderRadius: '8px',
                        width: '283px',
                        height: '90px',
                    }}
                    id="drop-zone"
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={dragDrop}
                    onDragOver={dragOver}
                >
                    <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
                        <img src={Cloud} alt={'Cloud'} />
                    </label>
                    <input type="file" accept={accept} onChange={(e) => uploadImage(e)} hidden id="file-input" />
                    <Typography sx={{ color: '#000000', fontSize: '12px', fontWeight: 600, marginTop: '0.5rem' }}>
                        <strong>{t('cyberSecurityAssessment.click')}</strong>{' '}
                        <span style={{ color: '#77777A' }}>{t('cyberSecurityAssessment.dragAndDrop')}</span>
                    </Typography>
                    <Typography sx={{ color: '#ACAAAF', fontSize: '10px', fontWeight: 600 }}>
                        {t('cyberSecurityAssessment.fileType')}
                    </Typography>
                </Box>
            )}
            {img !== '' ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '1rem',
                    }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <img
                            src={
                                new_document?.type === 'application/pdf'
                                    ? Pdf
                                    : new_document?.type === 'application/png'
                                    ? img
                                    : new_document?.type === 'application/jpeg'
                                    ? img
                                    : null
                            }
                            alt="img"
                        />
                        <Typography sx={{ marginLeft: '1rem', color: '#5E5E62', fontSize: '11px' }}>
                            {new_document.name}
                        </Typography>
                    </Box>
                </Box>
            ) : (
                ''
            )}
            <AppFormSelect
                name="risk_status"
                label={t('cyberSecurityAssessment.status')}
                options={StatusOption}
                defaultValue={t('cyberSecurityAssessment.status')}
                required
            />
        </Box>
    );
};

// const AppTooltip = styled(({ className, ...props }) => (
//     <Tooltip classes={{ popper: className }} {...props} placement="top" arrow />
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: theme.palette.primary[900],
//         color: theme.palette.white.main,
//         boxShadow: theme.shadows[1],
//         fontSize: 11,
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//     },
//     [`& .${tooltipClasses.arrow}`]: {
//         color: '#0E2C66',
//         fontSize: 16,
//     },
// }));

const mapStateToProps = (state) => {
    return {
        all_merchant_infrastructures: state?.merchantReducers?.all_merchant_infrastructures,
        all_computers: state?.merchantReducers?.all_computers,
        all_merchant_documents: state?.complianceReducers?.all_merchant_documents,
    };
};
export default connect(mapStateToProps, { GetAllMerchantDocuments })(Form3);
