import { DeleteOutlined, EditOutlined, EyeOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Empty, Modal } from 'antd';
import BreadCrumb from 'components/Breadcrumb';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { EditAccessorSubCategory, EditAccessorCategory, DeleteAccessorSubCategory } from 'store/actions/auditActions';
import FieldModal from '../components/FieldModal';
import FieldItem from '../components/FieldItem';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
// import SectionCard from '../components/SectionCard';
import SubCategoryModal from '../components/SubCategoryModal';

const { confirm } = Modal;

const Index = (props) => {
    const { location, all_accessor_categories, EditAccessorCategory, DeleteAccessorSubCategory } = props;
    // state
    const [fieldModal, setFieldModal] = useState(false);
    const [fieldIndex, setFieldIndex] = useState(null);
    const [modal, setModal] = useState(null);
    const [data, setData] = useState({});

    // memos
    const category = useMemo(
        () => all_accessor_categories.find((category) => category?.id == location?.state?.category_id),
        [location, all_accessor_categories]
    );
    const questions = useMemo(() => {
        try {
            return JSON.parse(category.questions).sort((a, b) => a?.id - b?.id);
        } catch {
            return [];
        }
    }, [category]);

    // functions
    const openFieldModal = (mode, idx) => {
        setFieldModal(mode);
        setFieldIndex(idx);
    };
    const closeFieldModal = () => {
        setFieldModal(null);
        setFieldIndex(null);
    };
    const openModal = (mode, data = {}) => {
        setModal(mode);
        setData(data);
    };
    const closeModal = () => {
        setModal(null);
        setData({});
    };
    // const handleTitleChange = (e, index) => {
    //     const sectionsArray = [...sections];
    //     sectionsArray.splice(index, 1, e.target.value);
    //     setSections(sectionsArray);
    // };
    const updateCategoryDetail = async (sections, field) => {
        const res = await EditAccessorCategory({ [field]: JSON.stringify(sections) }, category?.id);
        if (res.success) {
            return toast.success(res?.message);
        } else {
            return toast.error(res.message);
        }
    };
    // const handleTitleSave = (e) => {
    //     e.preventDefault();
    //     updateCategoryDetail(sections, 'subcategory');
    // };
    // const deleteASection = (index) => {
    //     confirm({
    //         title: 'Are you sure remove this sections?',
    //         icon: <ExclamationCircleOutlined />,
    //         okText: 'Yes',
    //         okType: 'danger',
    //         cancelText: 'No',
    //         onOk: async () => {
    //             const sectionsArray = [...sections];
    //             sectionsArray.splice(index, 1);
    //             await updateCategoryDetail(sectionsArray, 'sections');
    //         },
    //     });
    // };
    const deleteField = (index) => {
        confirm({
            title: 'Are you sure remove this field?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const questionsArray = [...questions];
                questionsArray.splice(index, 1);
                await updateCategoryDetail(questionsArray, 'questions');
            },
        });
    };

    const deleteAccessorCategory = (id) => {
        confirm({
            title: 'Are you sure delete this Category?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteAccessorSubCategory(id);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    console.log({ sections: Array.isArray(category?.subcategory) });

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Audit Categories', link: '/audit-categories' }]} />
                </Col>
                <Col className="flex justify-end items-center space-x-2">
                    <Button
                        size="large"
                        type="primary"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center"
                        onClick={() => openFieldModal('Add')}
                    >
                        Add Field
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        className="flex jsutify-between items-center"
                        onClick={() => openModal('Add')}
                    >
                        Add Sub Categories
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Row gutter={16}>
                    {questions?.map((question, index) => {
                        return (
                            <FieldItem
                                question={question}
                                key={index}
                                openFieldModal={() => openFieldModal('Edit', index)}
                                deleteField={() => deleteField(index)}
                            />
                        );
                    })}
                </Row>
                <>
                    {category?.subcategory && category?.subcategory?.length ? (
                        <Row wrap gutter={24} justify="start">
                            {category?.subcategory
                                ?.sort((a, b) => a?.id - b?.id)
                                .map((cat) => (
                                    <Col xs={24} md={12} lg={8} xxl={6} style={{ marginBottom: '1rem' }} key={cat.id}>
                                        <Card
                                            title={cat.name}
                                            loading={false}
                                            actions={[
                                                <Link
                                                    key="preview"
                                                    to={{
                                                        pathname: `/admin/accessor-categories/single-duplicate/${cat?.id}`,
                                                        state: {
                                                            category_id: cat?.category,
                                                            id: cat?.id,
                                                        },
                                                    }}
                                                >
                                                    <EyeOutlined key="preview" />
                                                </Link>,
                                                <EditOutlined key="edit" onClick={() => openModal('Edit', cat)} />,
                                                <DeleteOutlined
                                                    key="delete"
                                                    onClick={() => deleteAccessorCategory(cat?.id)}
                                                />,
                                            ]}
                                        ></Card>
                                    </Col>
                                ))}
                        </Row>
                    ) : (
                        <Row justify="center" className="justify-center">
                            <Col>
                                <Empty
                                    description={
                                        <div>
                                            <h3>No Sub Category</h3>
                                            <span>Add a new sub category </span>
                                        </div>
                                    }
                                />
                            </Col>
                        </Row>
                    )}
                </>
            </section>

            <FieldModal
                open={fieldModal}
                fieldIndex={fieldIndex}
                closeModal={closeFieldModal}
                category={location?.state?.category_id}
                questions={questions}
            />
            <SubCategoryModal
                open={modal}
                handleClose={closeModal}
                modalData={data}
                category_id={location?.state?.category_id}
            />
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_accessor_categories: state?.auditReducers?.all_accessor_categories,
});
export default connect(mapStateToProps, { EditAccessorCategory, EditAccessorSubCategory, DeleteAccessorSubCategory })(
    Index
);
