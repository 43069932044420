import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/Breadcrumb';
import { toast } from 'react-toastify';
import { Table } from 'antd';

//redux
import { connect } from 'react-redux';
import ChangeStatusModal from './operations/ChangeStatusModal';
import { GetAllAddonRequests } from 'store/actions/adminActions';

import moment from 'moment';
import { capitalizeFirstLetter } from 'utils';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const Addons = () => {
    const [loading, setLoading] = useState({ content: false });
    const [openModal, setOpenModal] = useState(false);
    const [mode, setMode] = useState('');
    const [requests, setRequests] = useState([]);
    const [merchant, setMerchant] = useState(null);

    console.log(requests);

    const OpenModal = (mode = 'enable', merchant) => {
        setOpenModal(true);
        setMode(mode);
        setMerchant(merchant);
    };
    const CloseModal = () => {
        setOpenModal(false);
    };

    const columns = [
        {
            title: 'Merchant name',
            render: (record) => record?.merchant_name,
        },
        {
            title: 'Status',
            render: (record) => capitalizeFirstLetter(record?.smart_intel?.status),
        },
        {
            title: 'Date Requested',
            render: (record) => moment(record?.date_created).format('MMM DD, YYYY'),
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => (
                <div className="flex gap-3 flex-wrap float-end">
                    {record?.status?.toLowercase() === 'approved' ? (
                        <CheckOutlined
                            className="text-[#55BE8B] cursor-pointer"
                            onClick={() => OpenModal('enable', record.merchant)}
                        />
                    ) : (
                        <CloseOutlined
                            className="text-[#ff5449] cursor-pointer"
                            onClick={() => OpenModal('disable', record.merchant)}
                        />
                    )}
                </div>
            ),
        },
    ];

    const getAllRequests = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAllAddonRequests()();
        setLoading({ ...loading, content: false });
        if (res.success) {
            setRequests(res.requests);
        } else {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        getAllRequests();
    }, []);

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <BreadCrumb view="Addons" views={[{ link: '/addons', label: 'Addons' }]} />
            </div>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(requests) => requests.id}
                    columns={columns}
                    dataSource={requests?.sort((a, b) => b?.id - a?.id)}
                    sortDirections="descend"
                    pagination={{
                        total: requests?.length,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                    }}
                    loading={loading.content || false}
                />
            </section>
            <ChangeStatusModal
                open={openModal}
                handleClose={CloseModal}
                mode={mode}
                refetch={getAllRequests}
                merchant={merchant}
            />
        </>
    );
};

const mapStateToProps = () => {
    return {};
};
export default connect(mapStateToProps, {})(Addons);
