import React from 'react';
import { Box, Stack } from '@mui/material';
import { WarningAmberOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
//core components
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import AppButton from 'components/new_components/AppButton';

//translation
import { useTranslation } from 'react-i18next';

const AuthChangeConfirmModal = (props) => {
    const theme = useTheme();
    const { title, subtitle, open, handleClose, openConfirmPasswordModal } = props;

    const { t } = useTranslation('accounts');

    const handleOpenPasswordModal = () => {
        handleClose();
        setTimeout(() => openConfirmPasswordModal(), 2000);
    };

    return (
        <AppCenteredModal open={open} handeClose={handleClose} width="480px">
            <Box
                sx={{
                    fontWeight: 500,
                    color: theme.palette.gray[800],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    mb: 2,
                }}
            >
                <WarningAmberOutlined sx={{ fontSize: '70px', color: theme.palette.error[500], mb: 1 }} />
                {title && <p style={{ fontSize: '16px', fontWeight: 400 }}>{title}</p>}
                {subtitle && <p style={{ fontSize: '14px', fontWeight: 500, marginTop: '1rem' }}>{subtitle}</p>}
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <AppButton
                        name={t('twoFactorAuth.cancel')}
                        sx={{ color: theme.palette.error[500] }}
                        onClick={handleClose}
                    />
                    <AppButton
                        name={t('twoFactorAuth.changeMethod')}
                        sx={{
                            color: theme.palette.white.main,
                            '&, &:hover': {
                                backgroundColor: theme.palette.error[500],
                            },
                            ml: 2,
                        }}
                        onClick={handleOpenPasswordModal}
                    />
                </Stack>
            </Box>
        </AppCenteredModal>
    );
};

export default AuthChangeConfirmModal;
