import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';
import TrainingIntro from './TrainingIntro';
import TrainingVideos from './TrainingVideos';

// redux
import { connect } from 'react-redux';
import { GetTrainingById, EditTraining, GetOneTrainingCourse, GetTraineeDetails } from 'store/actions/trainingActions';
import { Box, Typography, useTheme } from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';

//translations
import { useTranslation } from 'react-i18next';

const EmployeeTrainingView = (props) => {
    const { GetTrainingById, one_training, EditTraining, GetTraineeDetails } = props;
    const { training_id } = useParams();
    const history = useHistory();
    const theme = useTheme();

    // state
    const [loading, setLoading] = useState(false);
    const [isTrainingView, setIsTrainingView] = useState(false);
    const [error, setError] = useState(false);
    // const [readOnly, setReadOnly] = useState(false);

    //translation
    const { t } = useTranslation('training');

    // memos
    const readOnly = useMemo(() => training_id === 'preview', [training_id]);

    // functions
    const goToCertificate = () => {
        history.push({ pathname: `/training-cert/${training_id}`, state: { from_training: true } });
    };
    const goToTraining = async () => {
        if (readOnly) return setIsTrainingView(true);
        const res = await EditTraining({ acknowledge: true }, training_id);
        if (res?.success) {
            return setIsTrainingView(true);
        } else {
            return toast.error("Something went wrong, couldn't proceed. Try again!");
        }
    };
    const getTrainingById = async () => {
        setLoading(true);
        const res = await GetTrainingById(training_id);
        setLoading(false);
        if (!res?.success) {
            setError(true);
            // toast.error("Something went wrong, couldn't fetch training details.");
        }
    };
    const getTrainee = async () => {
        setLoading(true);
        const res = await GetTraineeDetails(one_training?.enrollee);
        setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong, couldn't get your details.");
        }
    };

    // useEffect
    useEffect(() => {
        if (!training_id || readOnly) return;
        getTrainingById();
    }, [training_id]);
    useEffect(() => {
        if (readOnly || !one_training) return;
        setIsTrainingView(one_training?.acknowledge);
        getTrainee();
    }, [one_training?.acknowledge, one_training?.enrollee, readOnly]);
    return error ? (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <CancelOutlined sx={{ color: theme.palette.primary[900], fontSize: 120, mb: 2 }} />
            <Typography
                sx={{
                    fontWeight: 700,
                    fontSize: 20,
                    color: theme.palette.primary[900],
                    my: 1,
                }}
            >
                {t('trainingRecord1')}
            </Typography>
            <Typography sx={{ maxWidth: 650, mx: 'auto', textAlign: 'center', color: theme.palette.gray[40] }}>
                {t('trainingRecord2')}
            </Typography>
        </Box>
    ) : isTrainingView ? (
        <TrainingVideos
            training={one_training}
            loading={loading}
            goToCertificate={goToCertificate}
            readOnly={readOnly}
        />
    ) : (
        <TrainingIntro goToTraining={goToTraining} training={one_training} loading={loading} readOnly={readOnly} />
    );
};
const mapStateToProps = (state) => ({
    one_training: state?.trainingReducers?.one_training,
});
export default connect(mapStateToProps, { GetTrainingById, EditTraining, GetOneTrainingCourse, GetTraineeDetails })(
    EmployeeTrainingView
);
