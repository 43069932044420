import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Input, Upload, Button } from 'antd';
import { AiOutlineInbox } from 'react-icons/ai';
//redux
import { connect } from 'react-redux';
import { AddCourseDocument, UpdateCourseDocument } from 'store/actions/trainingActions';
import { toast } from 'react-toastify';

const DocumentModal = (props) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const { open, handleClose, type, modalPayload, AddCourseDocument, UpdateCourseDocument, course_id } = props;
    //functions
    const { Dragger } = Upload;
    const { TextArea } = Input;
    const draggers = {
        name: 'default',
        action: null,
        multiple: false,
        maxCount: 1,
        onChange(info) {
            setData({ ...data, file: info.fileList[0]?.originFileObj });
        },
    };
    const onTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    const closeAll = () => {
        handleClose();
        setData();
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        const payload = { ...data, course: course_id };
        for (const key in payload) {
            if (key === 'file') {
                formData.append(key, payload?.file || modalPayload?.file);
            } else {
                formData.append(key, payload?.[key]);
            }
        }
        const res =
            type === 'Add' ? await AddCourseDocument(formData) : await UpdateCourseDocument(formData, modalPayload?.id);
        setLoading(false);
        if (res?.success) {
            toast.success(`Document ${type === 'Add' ? 'Added' : 'Updated'} successfuly`);
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (type !== 'Add' && open) {
            setData({
                title: modalPayload?.title,
                description: modalPayload?.description,
                course: modalPayload?.course,
            });
        }
    }, [type, open]);
    return (
        <Modal
            title={type === 'Add' ? 'Add Course Document' : 'Update Course Document'}
            open={open}
            onCancel={closeAll}
            footer={null}
            destroyOnClose={true}
        >
            <form onSubmit={handleSubmit}>
                <Row justify="end" className="overflow-y-auto custom-scroll pr-3">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="plan">Doc Name</label>
                        <Input
                            size="large"
                            name="title"
                            onChange={onTextChange}
                            placeholder="Document title"
                            value={data?.title}
                            allowClear
                        />
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="plan">Doc Description</label>
                        <TextArea
                            rows={4}
                            name="description"
                            onChange={onTextChange}
                            placeholder="Document Description"
                            value={data?.description}
                            allowClear
                        />
                    </Col>

                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="description">Upload Document</label>
                        <Dragger {...draggers}>
                            <p className="ant-upload-drag-icon flex justify-center">
                                <AiOutlineInbox />
                            </p>
                            <p className="ant-upload-text text-[14px]">Click or drag file to this area to upload</p>
                        </Dragger>
                    </Col>
                </Row>
                <Row justify="end" className="mt-3">
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        all_training_courses: state?.trainingReducers?.all_training_courses,
    };
};
export default connect(mapStateToProps, { AddCourseDocument, UpdateCourseDocument })(DocumentModal);
