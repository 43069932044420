import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material/';

import { RequestNameChange } from 'store/actions/onboardingActions';
import { connect } from 'react-redux';

import AppButton from 'components/new_components/AppButton';
import AppCard from 'components/new_components/AppCard';
import NameModal from '../NameModal';

const BackgroundCheck = (props) => {
    const { increaseOnboardingPolicy, match, one_merchant_person, id, RequestNameChange } = props;
    const theme = useTheme();

    const [openCorrectName, setOpenCorrectName] = useState(false);
    const [loading, setLoading] = useState(false);

    const openNameModal = () => {
        setOpenCorrectName(!openCorrectName);
    };
    const closeNameModal = () => {
        setOpenCorrectName(!openCorrectName);
    };

    const handleNameChange = async (data) => {
        setLoading(true);
        const res = await RequestNameChange({
            merchant: one_merchant_person?.[0]?.merchant,
            email: one_merchant_person?.[0]?.email,
            new_name: `${data.first_name} ${data.last_name}`,
        });
        setLoading(false);
        if (res?.success) {
            toast.success('Name Change Requested Successfully');
            closeNameModal();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <>
            <Grid item md={7} lg={7}>
                <AppCard sx={{ p: 5 }}>
                    <Stack>
                        <Box
                            sx={{
                                fontSize: '12px',
                                color: theme.palette.gray[40],
                                fontWeight: 400,
                                border: '0.3px solid #C7C6CA',
                                p: 0.5,
                                mx: 1,
                                my: 1,
                                borderRadius: '4px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ErrorOutline color="#77777A" fontSize="small" />
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    color: '#77777A',
                                    marginLeft: '10px',
                                }}
                            >
                                Please verify that the name given here is accurate to lessen the likelihood that the
                                background check <br /> verification will not be successful.
                            </Typography>
                        </Box>
                        <Box sx={{ my: 8 }}>
                            <Typography sx={{ color: '#5E5E62', fontWeight: 500, fontSize: '18px' }}>
                                First Name:{' '}
                                <span style={{ color: '#0E2C66' }}>
                                    {' '}
                                    {match?.params?.id?.toString()?.length >= 4
                                        ? `${one_merchant_person?.[0]?.first_name}`
                                        : 'John'}
                                </span>
                            </Typography>
                            <Typography sx={{ color: '#5E5E62', fontWeight: 500, fontSize: '18px' }}>
                                Last Name:{' '}
                                <span style={{ color: '#0E2C66' }}>
                                    {match?.params?.id?.toString()?.length >= 4
                                        ? `${one_merchant_person?.[0]?.last_name}`
                                        : 'Doe'}
                                </span>
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                color: '#77777A',
                                fontWeight: 400,
                                fontSize: '12px',
                                marginBottom: '25px',
                            }}
                        >
                            Is the information provided above accurate?
                        </Typography>
                        <Box sx={{ display: 'flex' }}>
                            <Box
                                onClick={openNameModal}
                                sx={{
                                    width: 'fit-content',
                                    height: '40px',
                                    color: '#183366',
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    marginRight: '25px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                No, not correct
                            </Box>
                            <AppButton
                                name="Yes, Proceed"
                                color="primary"
                                variant="contained"
                                onClick={increaseOnboardingPolicy}
                                sx={{
                                    width: '140px',
                                    height: '40px',
                                }}
                            />
                        </Box>
                    </Stack>
                </AppCard>
            </Grid>
            <NameModal
                open={openCorrectName}
                handleClose={closeNameModal}
                id={id}
                handleNameChange={handleNameChange}
                loading={loading}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { RequestNameChange })(BackgroundCheck);
