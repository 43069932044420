import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DeleteOutlined, PlusOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Empty, Modal, Row, Spin } from 'antd';
import BreadCrumb from 'components/Breadcrumb';

// redux
import { connect } from 'react-redux';
import { GetAllTestimonials, DeleteTestimonial } from 'store/actions/adminActions';
import TestimonialModal from './components/TestimonialModal';

const { confirm } = Modal;

const Testimonials = (props) => {
    const { GetAllTestimonials, all_testimonials, DeleteTestimonial } = props;

    // state
    const [modal, setModal] = useState(null);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // functions
    const openModal = (mode, data = {}) => {
        setModal(mode);
        setData(data);
    };
    const closeModal = () => setModal(null);

    // async functions
    const getAllTestimonials = async () => {
        setLoading(true);
        const res = await GetAllTestimonials();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.success);
        }
    };
    const deleteItem = (id) => {
        confirm({
            title: 'Are you sure delete this testimonial?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteTestimonial(id);
                if (res.success) {
                    toast.success('Item deleted successfully.');
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    // useEffect
    useEffect(() => {
        getAllTestimonials();
    }, []);

    return (
        <>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Testimonials' }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => openModal('Add')}
                    >
                        Add Testimonial
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {loading ? (
                    <Row justify="center">
                        <Spin />
                    </Row>
                ) : all_testimonials && all_testimonials?.length ? (
                    <Row wrap gutter={24}>
                        {all_testimonials
                            ?.sort((a, b) => a?.id - b?.id)
                            ?.map((testimonial) => (
                                <Col xs={24} md={12} lg={8} xxl={6} key={testimonial?.id} className="mb-2">
                                    <Card
                                        title=""
                                        actions={[
                                            <EditOutlined key="edit" onClick={() => openModal('Edit', testimonial)} />,
                                            <DeleteOutlined key="delete" onClick={() => deleteItem(testimonial?.id)} />,
                                        ]}
                                    >
                                        <Row
                                            wrap={false}
                                            align="center"
                                            justify="start"
                                            gutter={12}
                                            style={{ justifyContent: 'flex-start' }}
                                        >
                                            <Col>
                                                <Avatar size={56} src={testimonial?.person_image} />
                                            </Col>
                                            <Col className="flex flex-col justify-center">
                                                <h3 className="font-medium">{testimonial?.full_name}</h3>
                                                <span className="truncate ...">
                                                    {testimonial?.role}, {testimonial?.company_name}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row justify="end">
                                            <Col xs={24}>
                                                <p>{testimonial?.testimonial}</p>
                                            </Col>
                                            <Col>
                                                <img src={testimonial?.company_logo} alt="" className="h-6 w-6" />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                ) : (
                    <Row justify="center">
                        <Col>
                            <Empty
                                description={
                                    <div>
                                        <h3>No Testimonials</h3>
                                        <span>Add a testimonials</span>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                )}
            </section>
            <TestimonialModal open={modal} modalData={data} onClose={closeModal} />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_testimonials: state?.adminReducers?.all_testimonials,
    };
};
export default connect(mapStateToProps, { GetAllTestimonials, DeleteTestimonial })(Testimonials);
