import React from 'react';

const AssigneeTag = ({ name }) => {
    return (
        <div className="p-1 text-[#5E5E62] text-[10px] font-medium border border-solid border-[1px] border-[#E1E2EC] rounded  whitespace-nowrap">
            {name}
        </div>
    );
};

export default AssigneeTag;
