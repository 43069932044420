import React, { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';

const AppButton = forwardRef((props, ref) => {
    const theme = useTheme();
    const { name, icon, sx, ...otherProps } = props;

    return (
        <Button
            sx={{
                display: 'flex-inline',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: '600',
                borderRadius: '4px',
                py: 1,
                px: 2.4,
                textTransform: 'unset',
                '&.Mui-disabled': {
                    color: theme.palette.white.main,
                    backgroundColor: theme.palette.gray[600],
                },
                ...sx,
            }}
            startIcon={icon}
            {...otherProps}
            ref={ref}
        >
            {name}
        </Button>
    );
});
export default AppButton;
