import React from 'react';
import { useSelector } from 'react-redux';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PCIReport = (props) => {
    const { standards } = props;
    const {
        pcidss_ciso_summary,
        ndpr_ciso_summary,
        soc2_ciso_summary,
        iso_ciso_summary,
        pci_slc_ciso_summary,
        iso22301_summary,
        pcidss4_ciso_summary,
        iso_two_ciso_summary,
        iso_9001_ciso_summary,
        kdpa_ciso_summary,
        gdpa_ciso_summary,
        tech_sec_ciso_summary,
        blockchain_general_ciso_summary,
        cii_general_ciso_summary,
    } = useSelector((state) => state?.CISOReducers);
    const pci_total_document =
        pcidss_ciso_summary &&
        Object?.values(pcidss_ciso_summary)
            ?.map((item) => item?.total_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const pci_user_document =
        pcidss_ciso_summary &&
        Object?.values(pcidss_ciso_summary)
            ?.map((item) => item?.user_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const pci_total_form =
        pcidss_ciso_summary &&
        Object?.values(pcidss_ciso_summary)
            ?.map((item) => item?.total_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const pci_user_form =
        pcidss_ciso_summary &&
        Object?.values(pcidss_ciso_summary)
            ?.map((item) => item?.user_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);

    // NDPR
    const ndpr_total_document =
        ndpr_ciso_summary &&
        Object?.values(ndpr_ciso_summary)
            ?.map((item) => item?.total_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const ndpr_user_document =
        ndpr_ciso_summary &&
        Object?.values(ndpr_ciso_summary)
            ?.map((item) => item?.user_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const ndpr_total_form =
        ndpr_ciso_summary &&
        Object?.values(ndpr_ciso_summary)
            ?.map((item) => item?.total_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const ndpr_user_form =
        ndpr_ciso_summary &&
        Object?.values(ndpr_ciso_summary)
            ?.map((item) => item?.user_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);

    // SOC2
    const soc2_total_document =
        soc2_ciso_summary &&
        Object?.values(soc2_ciso_summary)
            ?.map((item) => item?.total_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const soc2_user_document =
        soc2_ciso_summary &&
        Object?.values(soc2_ciso_summary)
            ?.map((item) => item?.user_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const soc2_total_form =
        soc2_ciso_summary &&
        Object?.values(soc2_ciso_summary)
            ?.map((item) => item?.total_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const soc2_user_form =
        soc2_ciso_summary &&
        Object?.values(soc2_ciso_summary)
            ?.map((item) => item?.user_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);

    // ISO 27001
    const iso_total_document =
        iso_ciso_summary &&
        Object?.values(iso_ciso_summary)
            ?.map((item) => item?.total_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const iso_user_document =
        iso_ciso_summary &&
        Object?.values(iso_ciso_summary)
            ?.map((item) => item?.user_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const iso_total_form =
        iso_ciso_summary &&
        Object?.values(iso_ciso_summary)
            ?.map((item) => item?.total_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const iso_user_form =
        iso_ciso_summary &&
        Object?.values(iso_ciso_summary)
            ?.map((item) => item?.user_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);

    // ISO 22301
    const iso22301_total_document =
        iso22301_summary &&
        Object?.values(iso22301_summary)
            ?.map((item) => item?.total_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const iso22301_user_document =
        iso22301_summary &&
        Object?.values(iso22301_summary)
            ?.map((item) => item?.user_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const iso22301_total_form =
        iso22301_summary &&
        Object?.values(iso22301_summary)
            ?.map((item) => item?.total_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const iso22301_user_form =
        iso22301_summary &&
        Object?.values(iso22301_summary)
            ?.map((item) => item?.user_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);

    // PCI DSS 4.0
    const pcidss4_total_document =
        pcidss4_ciso_summary &&
        Object?.values(pcidss4_ciso_summary)
            ?.map((item) => item?.total_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const pcidss4_user_document =
        pcidss4_ciso_summary &&
        Object?.values(pcidss4_ciso_summary)
            ?.map((item) => item?.user_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const pcidss4_total_form =
        pcidss4_ciso_summary &&
        Object?.values(pcidss4_ciso_summary)
            ?.map((item) => item?.total_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const pcidss4_user_form =
        pcidss4_ciso_summary &&
        Object?.values(pcidss4_ciso_summary)
            ?.map((item) => item?.user_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);

    // ISO 27001 -2022
    const iso_two_total_document =
        iso_two_ciso_summary &&
        Object?.values(iso_two_ciso_summary)
            ?.map((item) => item?.total_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const iso_two_user_document =
        iso_two_ciso_summary &&
        Object?.values(iso_two_ciso_summary)
            ?.map((item) => item?.user_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const iso_two_total_form =
        iso_two_ciso_summary &&
        Object?.values(iso_two_ciso_summary)
            ?.map((item) => item?.total_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const iso_two_user_form =
        iso_two_ciso_summary &&
        Object?.values(iso_two_ciso_summary)
            ?.map((item) => item?.user_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);

    // ISO 9001

    const iso_9001_total_document =
        iso_9001_ciso_summary &&
        Object?.values(iso_9001_ciso_summary)
            ?.map((item) => item?.total_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const iso_9001_user_document =
        iso_9001_ciso_summary &&
        Object?.values(iso_9001_ciso_summary)
            ?.map((item) => item?.user_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const iso_9001_total_form =
        iso_9001_ciso_summary &&
        Object?.values(iso_9001_ciso_summary)
            ?.map((item) => item?.total_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const iso_9001_user_form =
        iso_9001_ciso_summary &&
        Object?.values(iso_9001_ciso_summary)
            ?.map((item) => item?.user_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);

    // KDPA
    const kdpa_total_document =
        kdpa_ciso_summary &&
        Object?.values(kdpa_ciso_summary)
            ?.map((item) => item?.total_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const kdpa_user_document =
        kdpa_ciso_summary &&
        Object?.values(kdpa_ciso_summary)
            ?.map((item) => item?.user_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const kdpa_total_form =
        kdpa_ciso_summary &&
        Object?.values(kdpa_ciso_summary)
            ?.map((item) => item?.total_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const kdpa_user_form =
        kdpa_ciso_summary &&
        Object?.values(kdpa_ciso_summary)
            ?.map((item) => item?.user_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);

    // GDPA
    const gdpa_total_document =
        gdpa_ciso_summary &&
        Object?.values(gdpa_ciso_summary)
            ?.map((item) => item?.total_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const gdpa_user_document =
        gdpa_ciso_summary &&
        Object?.values(gdpa_ciso_summary)
            ?.map((item) => item?.user_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const gdpa_total_form =
        gdpa_ciso_summary &&
        Object?.values(gdpa_ciso_summary)
            ?.map((item) => item?.total_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const gdpa_user_form =
        gdpa_ciso_summary &&
        Object?.values(gdpa_ciso_summary)
            ?.map((item) => item?.user_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);

    // ISO2017
    const tech_sec_total_document =
        tech_sec_ciso_summary &&
        Object?.values(tech_sec_ciso_summary)
            ?.map((item) => item?.total_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const tech_sec_user_document =
        tech_sec_ciso_summary &&
        Object?.values(tech_sec_ciso_summary)
            ?.map((item) => item?.user_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const tech_sec_total_form =
        tech_sec_ciso_summary &&
        Object?.values(tech_sec_ciso_summary)
            ?.map((item) => item?.total_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const tech_sec_user_form =
        tech_sec_ciso_summary &&
        Object?.values(tech_sec_ciso_summary)
            ?.map((item) => item?.user_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);

    // BLOCKCHAIN
    const blockchain_total_document =
        blockchain_general_ciso_summary &&
        Object?.values(blockchain_general_ciso_summary)
            ?.map((item) => item?.total_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const blockchain_user_document =
        blockchain_general_ciso_summary &&
        Object?.values(blockchain_general_ciso_summary)
            ?.map((item) => item?.user_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const blockchain_total_form =
        blockchain_general_ciso_summary &&
        Object?.values(blockchain_general_ciso_summary)
            ?.map((item) => item?.total_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const blockchain_user_form =
        blockchain_general_ciso_summary &&
        Object?.values(blockchain_general_ciso_summary)
            ?.map((item) => item?.user_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);

    // CII
    const total_document =
        cii_general_ciso_summary &&
        Object?.values(cii_general_ciso_summary)
            ?.map((item) => item?.total_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const user_document =
        cii_general_ciso_summary &&
        Object?.values(cii_general_ciso_summary)
            ?.map((item) => item?.user_doc)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const total_form =
        cii_general_ciso_summary &&
        Object?.values(cii_general_ciso_summary)
            ?.map((item) => item?.total_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    const user_form =
        cii_general_ciso_summary &&
        Object?.values(cii_general_ciso_summary)
            ?.map((item) => item?.user_form)
            ?.reduce((accumulator, item) => accumulator + (item || 0), 0);
    return (
        <div>
            {standards?.includes('pcidss') && (
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {pci_user_document === pci_total_document && pci_user_form === pci_total_form ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 14, height: 14 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 14, height: 14 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal mt-[-0.8rem]">PCI DSS</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {pci_user_form}/{pci_total_form} Questions answered
                            </p>
                        </div>
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {pci_user_document}/{pci_total_document} Documents uploaded
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {standards?.includes('ndpr') && (
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {ndpr_user_document === ndpr_total_document && ndpr_user_form === ndpr_total_form ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal mt-[-0.8rem]">NDPR</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {ndpr_user_form}/{ndpr_total_form} Questions answered
                            </p>
                        </div>
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {ndpr_user_document}/{ndpr_total_document} Documents uploaded
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {standards?.includes('soc2') && (
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {soc2_user_document === soc2_total_document && soc2_user_form === soc2_total_form ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal mt-[-0.8rem]">SOC 2</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {soc2_user_form}/{soc2_total_form} Questions answered
                            </p>
                        </div>
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {soc2_user_document}/{soc2_total_document} Documents uploaded
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {standards?.includes('iso27001') && (
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {iso_user_document === iso_total_document && iso_user_form === iso_total_form ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal mt-[-0.8rem]">ISO 27001</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {iso_user_form}/{iso_total_form} Questions answered
                            </p>
                        </div>
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {iso_user_document}/{iso_total_document} Documenst uploaded
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {standards?.includes('sslc') && (
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center gap-5">
                    <div className="flex items-center gap-1">
                        {pci_slc_ciso_summary?.documents?.user === pci_slc_ciso_summary?.documents?.total &&
                        pci_slc_ciso_summary?.forms?.user === pci_slc_ciso_summary?.forms?.total ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal mt-[-0.8rem]">SSLC</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {pci_slc_ciso_summary?.forms?.user}/{pci_slc_ciso_summary?.forms?.total} Questions
                                answered
                            </p>
                        </div>
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {pci_slc_ciso_summary?.documents?.user}/{pci_slc_ciso_summary?.documents?.total}{' '}
                                Documents uploaded
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {standards?.includes('iso22301') && (
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {iso22301_user_document === iso22301_total_document &&
                        iso22301_user_form === iso22301_total_form ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal mt-[-0.8rem]">ISO 22301</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {iso22301_user_form}/{iso22301_total_form} Questions answered
                            </p>
                        </div>
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {iso22301_user_document}/{iso22301_total_document} Documents uploaded
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {standards?.includes('pcidss4.0') && (
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {pcidss4_user_document === pcidss4_total_document &&
                        pcidss4_user_form === pcidss4_total_form ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal mt-[-0.8rem]">PCI DSS 4.0</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {pcidss4_user_form}/{pcidss4_total_form} Questions answered
                            </p>
                        </div>
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {pcidss4_user_document}/{pcidss4_total_document} Documents uploaded
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {standards?.includes('iso27001-2022') && (
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {iso_two_user_document === iso_two_total_document &&
                        iso_two_user_form === iso_two_total_form ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal mt-[-0.8rem]">ISO 27001-2022</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {iso_two_user_form}/{iso_two_total_form} Questions answered
                            </p>
                        </div>
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {iso_two_user_document}/{iso_two_total_document} Documents uploaded
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {standards?.includes('iso9001') && (
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {iso_9001_user_document === iso_9001_total_document &&
                        iso_9001_user_form === iso_9001_total_form ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal mt-[-0.8rem]">ISO9001</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {iso_9001_user_form}/{iso_9001_total_form} Questions answered
                            </p>
                        </div>
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {iso_9001_user_document}/{iso_9001_total_document} Documents uploaded
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {standards?.includes('kdpa') && (
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {kdpa_user_document === kdpa_total_document && kdpa_user_form === kdpa_total_form ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal mt-[-0.8rem]">KDPA</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {kdpa_user_form}/{kdpa_total_form} Questions answered
                            </p>
                        </div>
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {kdpa_user_document}/{kdpa_total_document} Documents uploaded
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {standards?.includes('gdpa') && (
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {gdpa_user_document === gdpa_total_document && gdpa_user_form === gdpa_total_form ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal mt-[-0.8rem]">GDPA</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {gdpa_user_form}/{gdpa_total_form} Questions answered
                            </p>
                        </div>
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {gdpa_user_document}/{gdpa_total_document} Documents uploaded
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {standards?.includes('iso27017+iso27032') && (
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {tech_sec_user_document === tech_sec_total_document &&
                        tech_sec_user_form === tech_sec_total_form ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal mt-[-0.8rem]">ISO 27017 + ISO 27032</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {tech_sec_user_form}/{tech_sec_total_form} Questions answered
                            </p>
                        </div>
                        <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                            <p className=" mt-[-0.8rem]">
                                {tech_sec_user_document}/{tech_sec_total_document} Documents uploaded
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {standards?.includes('blockchain') && (
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {blockchain_user_document === blockchain_total_document &&
                        blockchain_user_form === blockchain_total_form ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal mt-[-0.8rem]">BLOCKCHAIN</p>
                    </div>
                    {blockchain_general_ciso_summary && (
                        <div className="flex items-center gap-2">
                            <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                                <p className=" mt-[-0.8rem]">
                                    {blockchain_user_form}/{blockchain_total_form} Questions answered
                                </p>
                            </div>
                            <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                                <p className=" mt-[-0.8rem]">
                                    {blockchain_user_document}/{blockchain_total_document} Documents uploaded
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {standards?.includes('cii') && (
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {user_document === total_document && user_form === total_form ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal mt-[-0.8rem]">CII Directive</p>
                    </div>
                    {cii_general_ciso_summary && (
                        <div className="flex items-center gap-2">
                            <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                                <p className=" mt-[-0.8rem]">
                                    {user_form}/{total_form} Questions answered
                                </p>
                            </div>
                            <div className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium flex items-center gap-2">
                                <p className=" mt-[-0.8rem]">
                                    {user_document}/{total_document} Documents uploaded
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default PCIReport;
