import React from 'react';
import { Row, Col, Card, Typography, Empty } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';

const { Title } = Typography;

const Documents = (props) => {
    const { items, openEditModal, deleteModal } = props;
    return (
        <>
            {items?.length ? (
                <Row wrap gutter={24}>
                    {items
                        ?.sort((a, b) => a?.id - b?.id)
                        ?.map((item, index) => (
                            <React.Fragment key={index}>
                                <Col xs={24} md={12} lg={8} xxl={6} style={{ marginBottom: '1rem' }}>
                                    <Card
                                        loading={false}
                                        actions={[
                                            <a key="view" href={item?.file} target="_blank" rel="noreferrer">
                                                <EyeOutlined />
                                            </a>,
                                            <EditOutlined key="edit" onClick={() => openEditModal(item)} />,
                                            <DeleteOutlined key="delete" onClick={() => deleteModal(item)} />,
                                        ]}
                                    >
                                        <Title level={5} style={{ textAlign: 'center' }}>
                                            {item?.name}
                                        </Title>
                                    </Card>
                                </Col>
                            </React.Fragment>
                        ))}
                </Row>
            ) : (
                <Row justify="center">
                    <Col>
                        <Empty
                            description={
                                <div>
                                    <h3>No Item</h3>
                                    <span>Add a new item </span>
                                </div>
                            }
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};

export default Documents;
