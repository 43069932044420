import { Divider } from '@mui/material';
import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
// import PolygonMedium from 'assets/img/medium_polygon.png';
// import PolygonLow from 'assets/img/ciso_positive_angle.png';
// import PolygonHigh from 'assets/img/LowPolygon.svg';
import { useSelector } from 'react-redux';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const RiskBy5 = ({ view, risk_options }) => {
    const { all_risk_assessment_summary, all_risk_summary } = useSelector((state) => state?.CISOReducers);

    const maxValue_5x5_inherent = useMemo(
        () =>
            all_risk_summary?.data?.matrix['5x5']?.risk_level?.inherent.reduce((max, item) => {
                return item.value > max ? item.value : max;
            }, 0),
        [all_risk_summary?.data?.matrix['5x5']?.risk_level?.inherent]
    );

    const data_5x5_inherent = {
        labels: ['Very High', 'High', 'Medium', 'Low', 'Very Low'],
        datasets: [
            {
                axis: 'y',
                data: all_risk_summary?.data?.matrix['5x5']?.risk_level?.inherent,
                // fill: false,
                backgroundColor: ['#002C72', '#002C72', '#002C72', '#002C72', '#002C72'],
                borderColor: ['#002C72', '#002C72', '#002C72', '#002C72', '#002C72'],
                borderWidth: 1,
                borderRadius: 20, // Rounded corners
                barThickness: 20,
                maxBarThickness: 25,
                padding: 40,
                margin: 40,
            },
        ],
    };

    const options_5x5_inherent = useMemo(
        () => ({
            indexAxis: 'y',
            plugins: {
                legend: {
                    display: false,
                },
                datalabels: {
                    display: false,
                },
                tooltip: {
                    font: {
                        family: 'Poppins, sans-serif',
                    },
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    border: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                        color: '#64748B',
                        font: {
                            family: 'Poppins, sans-serif',
                        },
                    },
                },
                y: {
                    min: 0,
                    ticks: {
                        stepSize: Math.round(maxValue_5x5_inherent / 5),
                        color: '#64748B',
                        font: {
                            size: 12,
                            fontWeight: 'bold',
                            family: 'Poppins, sans-serif',
                        },
                    },
                    grid: {
                        display: false,
                    },
                    border: {
                        display: true,
                        borderColor: '#cbd5e1',
                    },
                },
            },
        }),
        [all_risk_summary?.data?.matrix['5x5']?.risk_level?.inherent]
    );

    const maxValue_5x5_residual = useMemo(
        () =>
            all_risk_summary?.data?.matrix['5x5']?.risk_level?.residual.reduce((max, item) => {
                return item.value > max ? item.value : max;
            }, 0),
        [all_risk_summary?.data?.matrix['5x5']?.risk_level?.inherent]
    );

    const data_5x5_residual = {
        labels: ['Very High', 'High', 'Medium', 'Low', 'Very Low'],
        datasets: [
            {
                axis: 'y',
                data: all_risk_summary?.data?.matrix['5x5']?.risk_level?.residual,
                // fill: false,
                backgroundColor: ['#002C72', '#002C72', '#002C72', '#002C72', '#002C72'],
                borderColor: ['#002C72', '#002C72', '#002C72', '#002C72', '#002C72'],
                borderWidth: 1,
                borderRadius: 20, // Rounded corners
                barThickness: 20,
                maxBarThickness: 25,
                padding: 40,
                margin: 40,
            },
        ],
    };

    const options_5x5_residual = useMemo(
        () => ({
            indexAxis: 'y',
            plugins: {
                legend: {
                    display: false,
                },
                datalabels: {
                    display: false,
                },
                tooltip: {
                    font: {
                        family: 'Poppins, sans-serif',
                    },
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    border: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                        color: '#64748B',
                        font: {
                            family: 'Poppins, sans-serif',
                        },
                    },
                },
                y: {
                    min: 0,
                    ticks: {
                        stepSize: Math.round(maxValue_5x5_residual / 5),
                        color: '#64748B',
                        font: {
                            size: 12,
                            fontWeight: 'bold',
                            family: 'Poppins, sans-serif',
                        },
                    },
                    grid: {
                        display: false,
                    },
                    border: {
                        display: true,
                        borderColor: '#cbd5e1',
                    },
                },
            },
        }),
        [all_risk_summary?.data?.matrix['5x5']?.risk_level?.residual]
    );

    const filtered_risk = all_risk_assessment_summary?.filter(
        (risk) =>
            risk?.inherent_risk_level_slug !== 'NULL' &&
            risk?.inherent_risk_level_slug !== null &&
            risk?.residual_risk_level_slug !== 'NULL' &&
            risk?.residual_risk_level_slug !== null
    );
    return (
        <div>
            {(view === 'Graphical Presentation' || view === 'All') && (
                <div className="flex justify-center items-center w-full mt-[4rem] gap-10 flex-col">
                    <h4 className="text-[#002C72] text-lg font-semibold">5X5 matrix</h4>
                    <div className="flex justify-center items-center w-full mt-[4rem]">
                        <div className="flex justify-center items-center xl:gap-10 md:gap-3">
                            {risk_options?.includes('Inherent risk') && (
                                <div>
                                    <h4 className="text-[#002C72] text-sm font-bold mb-5">Inherent</h4>
                                    <div>
                                        <Bar data={data_5x5_inherent} options={options_5x5_inherent} />
                                    </div>
                                </div>
                            )}
                            <Divider orientation="vertical" flexItem />
                            {risk_options?.includes('Residual risk') && (
                                <div>
                                    <h4 className="text-[#002C72] text-sm font-bold mb-5">Residual</h4>
                                    <div>
                                        <Bar data={data_5x5_residual} options={options_5x5_residual} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {(view === 'Report View' || view === 'All') && (
                <div className="mt-[5rem]">
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">Risk Register(5X5)</p>
                    </div>
                    <table className="w-full">
                        <thead className="w-full">
                            <tr>
                                <th
                                    rowspan="2"
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                                >
                                    Asset{' '}
                                </th>
                                <th
                                    rowspan="2"
                                    className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black"
                                >
                                    Threat
                                </th>
                                <th
                                    rowspan="2"
                                    className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black"
                                >
                                    Risk Owner
                                </th>
                                <th
                                    rowspan="2"
                                    className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black"
                                >
                                    Status
                                </th>
                                {risk_options?.length > 0 && (
                                    <th
                                        colspan="3"
                                        className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black"
                                    >
                                        Risk Level
                                    </th>
                                )}
                            </tr>
                            {risk_options?.length > 0 && (
                                <tr>
                                    {risk_options?.includes('Inherent risk') && (
                                        <td className="text-[#64748B] text-xs  font-semibold p-2 text-center border-2 border-black">
                                            Inherent
                                        </td>
                                    )}
                                    {risk_options?.includes('Residual risk') && (
                                        <td className="text-[#64748B] text-xs  font-semibold p-2 text-center border-2 border-black">
                                            Residual
                                        </td>
                                    )}
                                </tr>
                            )}
                        </thead>
                        {filtered_risk
                            ?.filter((item) => item?.matrix === '5x5')
                            ?.map((option, index) => (
                                <tbody key={index}>
                                    <tr>
                                        {option?.asset_details?.length > 0 ? (
                                            <th
                                                scope="row"
                                                className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black"
                                            >
                                                {option?.asset_details?.map((asset, idx) => (
                                                    <span key={idx}>{asset?.name || 'N/A'}</span>
                                                ))}
                                            </th>
                                        ) : (
                                            <th
                                                scope="row"
                                                className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black"
                                            >
                                                N/A
                                            </th>
                                        )}
                                        <th
                                            scope="row"
                                            className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black"
                                        >
                                            {option?.threat_detail?.name}
                                        </th>
                                        <th
                                            scope="row"
                                            className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black"
                                        >
                                            {option?.solution_owner?.detail || option?.solution_owner || 'N/A'}
                                        </th>

                                        <th
                                            scope="row"
                                            className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black capitalize"
                                        >
                                            {option?.risk_assessment_status?.replace(/_/g, ' ') || 'N/A'}
                                        </th>
                                        {risk_options?.includes('Inherent risk') && (
                                            <td
                                                className={`${
                                                    option?.inherent_risk_level_slug === 'medium'
                                                        ? 'text-[#F2C021]'
                                                        : option?.inherent_risk_level_slug === 'low'
                                                        ? 'text-[#55BE8B]'
                                                        : option?.inherent_risk_level_slug === 'very low'
                                                        ? 'text-[#55BE8B]'
                                                        : 'text-[#FF5449]'
                                                } text-xs font-normal p-3 text-center border-2 border-black capitalize`}
                                            >
                                                {option?.inherent_risk_level_slug}
                                            </td>
                                        )}
                                        {risk_options?.includes('Residual risk') && (
                                            <td
                                                className={`${
                                                    option.residual_risk_level_slug === 'medium'
                                                        ? 'text-[#F2C021]'
                                                        : option?.residual_risk_level_slug === 'low'
                                                        ? 'text-[#55BE8B]'
                                                        : option?.residual_risk_level_slug === 'very low'
                                                        ? 'text-[#55BE8B]'
                                                        : 'text-[#FF5449]'
                                                } text-xs font-normal p-3 text-center border-2 border-black capitalize`}
                                            >
                                                {option.residual_risk_level_slug}
                                            </td>
                                        )}
                                    </tr>
                                </tbody>
                            ))}
                    </table>
                </div>
            )}
        </div>
    );
};

export default RiskBy5;
