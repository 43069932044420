export const quarterOptions = [
    {
        name: 'First Quarter',
        value: 1,
    },
    {
        name: 'Second Quarter',
        value: 2,
    },
    {
        name: 'Third Quarter',
        value: 3,
    },
    {
        name: 'Fourth Quarter',
        value: 4,
    },
];
export const scheduleOptions = [
    {
        name: 'Run Now',
        value: 'run_now',
    },
    {
        name: 'Monthly',
        value: 'monthly',
    },
    {
        name: 'Quarterly',
        value: 'quarterly',
    },
];
export const statusOptions = [
    {
        name: 'Passed',
        value: true,
    },
    {
        name: 'Failed',
        value: false,
    },
];

export const requestInitialValues = {
    name: '',
    ip_address: '',
    schedule: '',
};

export const uploadReportInitialValues = {
    name: '',
    status: '',
    ip_address: '',
    uploaded_by: '',
    file_field: null,
    conducted_date: '',
};
