import React from 'react';
import { Box } from '@mui/material';
import { Empty } from 'antd';

const EmptyComponent = ({ text }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3, width: '100%' }}>
            <Empty description={text} />
        </Box>
    );
};

export default EmptyComponent;
