import React, { useEffect, useState } from 'react';
import { Modal, Row, Select, Col, Button } from 'antd';

//redux
import { connect } from 'react-redux';
import { GetAllTrainingCourses } from 'store/actions/trainingActions';
import { UpdateMerchantRecords } from 'store/actions/adminActions';
import { toast } from 'react-toastify';

const AssignCourseModal = (props) => {
    const { open, closeModal, GetAllTrainingCourses, UpdateMerchantRecords, all_training_courses, record } = props;
    const [data, setData] = useState([]);
    const [loading, setloading] = useState(false);

    const closeAll = () => {
        setData([]);
        closeModal();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setloading(true);
        const body = {
            avaliable_courses: data?.join(','),
        };
        const res = await UpdateMerchantRecords(record?.id, body);
        setloading(false);
        if (res?.success) {
            toast.success(res?.message);
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        const getAllTrainingCourses = async () => {
            const res = await GetAllTrainingCourses();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllTrainingCourses();
    }, []);

    useEffect(() => {
        if (open && record?.avaliable_courses) {
            setData(record?.avaliable_courses?.split(','));
        }
    }, [record?.avaliable_courses, open]);
    return (
        <Modal title={'Assign Course'} open={open} onCancel={closeAll} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="plan">Choose Course</label>
                        <Select
                            size="large"
                            name="data"
                            id="data"
                            mode="multiple"
                            allowClear
                            onChange={(value) => setData(value)}
                            value={data || ''}
                            required
                            style={{ width: '100%' }}
                            options={all_training_courses?.map((training) => {
                                return {
                                    label: training.title,
                                    value: training.id,
                                };
                            })}
                        />
                    </Col>
                </Row>
                <Row justify="end" className="mt-3">
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        all_training_courses: state?.trainingReducers?.all_training_courses,
    };
};
export default connect(mapStateToProps, { GetAllTrainingCourses, UpdateMerchantRecords })(AssignCourseModal);
