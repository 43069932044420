import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { numExtraction } from 'utils';
import { Link } from 'react-router-dom';

// lib
import { Row, Col, Button, Table, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// core components
import BreadCrumb from 'components/Breadcrumb';
import SubcontrolsModal from './PCISLCActions/SubcontrolsModal';
import SubcontrolOperations from './PCISLCActions/SubcontrolOperations';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { CreateLifeCycle, EditLifeCycle, DeleteLifeCycle } from 'store/actions/pci_slcActions';

// subcomponents
const { confirm } = Modal;

const SubcontrolsView = (props) => {
    const { location } = props;
    const [modal, setModal] = useState(false);
    const [control, setControl] = useState({});
    const [subcontrol, setSubcontrol] = useState({});
    const dispatch = useDispatch();
    const all_Lifecycle = useSelector((state) => state?.pcislcReducers?.all_Lifecycle);

    const openModal = (mode, data) => {
        setModal(mode);
        setSubcontrol(mode === 'Edit' ? data : {});
    };
    const closeModal = () => {
        setModal(false);
        setSubcontrol(null);
    };
    const deleteSubcontrol = (id) => {
        confirm({
            title: 'Are you sure you want to delete this sub objective? Note: Deleting this sub objective means you will be deleting this sub objective, the documents, audit questions and merchant data associated with this sub objective',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await dispatch(DeleteLifeCycle(id, true));
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    // data
    const columns = [
        {
            title: 'Sub Objective Number',
            render: (record) => `CO ${record.lifecycle_number}`,
            sorter: (a, b) => a.lifecycle_number - b.lifecycle_number,
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Title',
            render: (record) => (
                <Link
                    to={{
                        pathname: '/admin/pcislc/subobjective/items',
                        state: { subcontrol: record },
                    }}
                >
                    {record.lifecycle_title}
                </Link>
            ),
            sorter: (a, b) => numExtraction(a.lifecycle_title) - numExtraction(b.lifecycle_title),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Description',
            render: (record) => record.lifecycle_description,
            width: '70%',
        },
        {
            title: 'Actions',
            align: 'right',
            render: (record) => (
                <SubcontrolOperations
                    openEditModal={openModal}
                    subcontrol={record}
                    deleteSubcontrol={deleteSubcontrol}
                    control={control}
                    // parent_req_num={location?.state?.req_num}
                />
            ),
        },
    ];

    // useEffect
    useEffect(() => {
        const { objective_id } = location.state || {};
        const one_control = all_Lifecycle?.find((control) => control?.id === objective_id);
        setControl(one_control);
    }, [all_Lifecycle, location?.state]);
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'PCI SLC', link: '/pcislc' }, { label: control?.title }]} />
                </Col>

                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal('Add')}
                    >
                        Add Sub Control Objective
                    </Button>
                </Col>
            </Row>

            <section className="my-3">
                <Table
                    columns={columns}
                    dataSource={control?.sub_lifecycle}
                    rowKey={(sub_control) => sub_control?.id}
                />
            </section>
            <SubcontrolsModal
                open={modal}
                handleCancel={closeModal}
                parent_control_id={control?.id}
                one_subcontrol={subcontrol}
                CreateControl={CreateLifeCycle}
                EditControl={EditLifeCycle}
            />
        </div>
    );
};
export default SubcontrolsView;
