import React, { useState, useEffect, useRef, useContext } from 'react';
import { toast } from 'react-toastify';
import AppUserGuide from 'components/new_components/AppUserGuide';
import { steps } from './steps';

//core components
import AppTable from 'components/new_components/app-table';
import AppButton from 'components/new_components/AppButton';
import VulnerabilityScanDeleteModal from './VulnerabilityScanDeleteModal';
import VulnerabilityScanModal from './VulnerabilityScanModal';
import { SettingsInputAntenna, ReceiptLong } from '@mui/icons-material/';
import PageHeader from 'components/new_components/PageHeader';
import useSearch from 'hooks/useSearch';
//redux
import { connect } from 'react-redux';
import { GetAllVulnerabilityScans } from 'store/actions/merchantActions';
import { initialValues } from './utils/constants';
import SubscriptionAlert from '../../../components/Pricing/SubscriptionAlert';
import { GetRemaining } from '../../../components/Pricing/usePricingHook';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';

//translations
import { useTranslation } from 'react-i18next';

const NO_PER_PAGE = 8;

const VulnerabilityScan = (props) => {
    const { GetAllVulnerabilityScans, pricing_plan, all_vulnerability_scans, merchant_id, userguide_info } = props;
    const [loading, setloading] = useState(false);
    const [page, setPage] = useState(1);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [requestModalOpen, setRequestModalOpen] = useState(false);
    const [deletingID, setDeletingID] = useState(0);
    const [payload, setPayload] = useState(initialValues);
    const [openUserGuide, setOpenUserGuide] = useState(false);

    const stepsTargetRef = useRef([]);

    //translation
    const { t } = useTranslation('vulnerabilityManagement');

    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    // hooks
    const { data, handleSearch } = useSearch(all_vulnerability_scans, ['request_name', 'public_ip']);

    const columns = [
        {
            title: t('vulnerabilityScans.table.column1'),
            render: (row) => <>{row?.request_name}</>,
        },
        {
            title: t('vulnerabilityScans.table.column2'),
            key: 'server',
            render: (row) => <>{row?.public_ip}</>,
        },
        {
            title: t('vulnerabilityScans.table.column3'),
            align: 'center',
            key: 'action',
            render: (row) => (
                <AppButton
                    name={t('vulnerabilityScans.table.delete')}
                    color="error"
                    variant="contained"
                    disableElevation
                    onClick={() => openDeleteModal(row)}
                    key={2}
                />
            ),
        },
    ];

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ vulnerability_scans: true });
        setToggleUserGuide({ ...toggleUserGuide, vulnerabilityscans: false });
        setOpenUserGuide(false);
    };

    //functions
    const handlePageChange = (page) => {
        setPage(page);
    };

    const openDeleteModal = (row) => {
        setDeleteModalOpen(true);
        setDeletingID(row?.id);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setDeletingID(0);
    };

    const openRequestModal = () => {
        setRequestModalOpen(true);
        setPayload(initialValues);
    };
    const closeRequestModal = () => {
        setRequestModalOpen(false);
        setPayload({});
    };

    useEffect(() => {
        const getAllVulnerabilityScans = async () => {
            setloading(true);
            const res = await GetAllVulnerabilityScans();
            setloading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllVulnerabilityScans();
    }, []);
    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        data ? data?.slice(start, stop) : [];

        return data;
    }, [page, data]);

    useEffect(() => {
        !userguide_info || toggleUserGuide.vulnerabilityscans ? OpenUserGuide() : null;
    }, [userguide_info, toggleUserGuide.vulnerabilityscans]);

    const { reminderCount, showReminder } = GetRemaining(pricing_plan?.vendor_mgt, all_vulnerability_scans?.length);

    return (
        <>
            <PageHeader browserTitle="Vulnerability Scans | Smartcomply" />
            {showReminder && (
                <SubscriptionAlert isDashboardPage={false} limitedNumber={`${reminderCount} vulnerability scans`} />
            )}
            <AppTable
                columns={columns}
                data={data}
                loading={loading}
                sorter={(a, b) => b?.id - a?.id}
                title={t('vulnerabilityScans.table.title')}
                dataLength={data?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                actions={[
                    <AppButton
                        icon={<SettingsInputAntenna />}
                        name={t('vulnerabilityScans.table.scanIP')}
                        color="primary"
                        variant="contained"
                        disableElevation
                        onClick={openRequestModal}
                        key={1}
                        ref={(el) => (stepsTargetRef.current[0] = el)}
                    />,
                    <AppButton
                        icon={<ReceiptLong />}
                        name={t('vulnerabilityScans.table.scanResult')}
                        color="primary"
                        variant="contained"
                        disableElevation
                        href={`https://scans.smartcomplyapp.com/${merchant_id}`}
                        sx={{
                            '&:hover': {
                                color: '#fff',
                            },
                        }}
                        target="_blank"
                        key={2}
                        ref={(el) => (stepsTargetRef.current[1] = el)}
                    />,
                ]}
                search={true}
                onSearch={handleSearch}
            />

            <VulnerabilityScanModal open={requestModalOpen} handleClose={closeRequestModal} payload={payload} />
            <VulnerabilityScanDeleteModal open={deleteModalOpen} handleClose={closeDeleteModal} id={deletingID} />

            <AppUserGuide
                open={openUserGuide}
                onClose={CloseUserGuide}
                steps={steps}
                targets={stepsTargetRef.current}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant_id: state?.authReducers?.company_id,
        pricing_plan: state?.authReducers.pricing_plan,
        all_vulnerability_scans: state?.merchantReducers?.all_vulnerability_scans,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.vulnerability_scans,
    };
};
export default connect(mapStateToProps, { GetAllVulnerabilityScans })(VulnerabilityScan);
