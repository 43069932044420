import {
    ALL_APPROVED_USER,
    ALL_KYC_RESULTS,
    KYC_RESULTS,
    ALL_FRAUD_MONITORING,
    ALL_COUNTRIES,
    ALL_SOURCES,
    ALL_SOURCES_LIST,
    FRAUD_SETTINGS,
    FRAUD_SUMMARY,
} from '../constants';
import axiosInstance from '../../utils/https';

// APPROVED USERS
export const GetAllApprovedUser = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/v1/fraudguardian/approve_users/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_APPROVED_USER, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const UpdateApprovedUser = (merchant, credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/v1/fraudguardian/approve_users/${merchant}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllApprovedUser());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        console.log({ err });
        return { success: false, message: err?.message };
    }
};

// REQUEST API
export const RequestFraudApi = () => async (dispatch, getState) => {
    const merchant_id = getState().authReducers?.company_id;
    const body = {
        merchant_id,
    };
    try {
        const res = await axiosInstance().post(`/v1/fraudguardian/request/`, body);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllApprovedUser());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// GENERATE TOKEN
export const GenerateTokenRequest = () => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    const body = {
        merchant,
    };
    try {
        const res = await axiosInstance().post(`/v1/fraudguardian/generate_token/`, body);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllApprovedUser());
        return {
            success: true,
            message: res?.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GenerateToken = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/v1/fraudguardian/generate_token/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllApprovedUser());
        return {
            success: true,
            message: res?.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const FraudSettings = () => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/v1/fraudguardian/fraud_settings/?merchant=${merchant}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: FRAUD_SETTINGS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const CreateFraudSettings = (credentials) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/v1/fraudguardian/fraud_settings/`, {
            ...credentials,
            merchant,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(FraudSettings());
        return {
            success: true,
            message: res?.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
// KYC RESULTS
export const GetKycResults = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/v1/fraudguardian/kyc_result/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: KYC_RESULTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAllKycResults = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/v1/fraudguardian/kyc_result/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_KYC_RESULTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// TRANSACTIONS RESULTS
export const GetFraudMonitoring = (params) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/v1/fraudguardian/fraud_monitoring/`, {
            params,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_FRAUD_MONITORING, payload: res?.data });
        return {
            success: true,
            message: res?.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const DeleteFraudMonitoring = (id, params) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/v1/fraudguardian/fraud_monitoring/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetFraudMonitoring(params));
        return {
            success: true,
            message: res?.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetFraudMonitoringSummary = (year) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/v1/fraudguardian/fraud_dashboard/?year=${year}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: FRAUD_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// ALL COUNTRIES
export const GetAllCountries = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/v1/fraudguardian/countries/`);
        dispatch({ type: ALL_COUNTRIES, payload: res });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// ALL SOURCES
export const GetAllSources = (params) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/v1/fraudguardian/type/`, {
            params,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_SOURCES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAllSourcesList = (params) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/v1/fraudguardian/type_list/`, {
            params,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_SOURCES_LIST, payload: res?.data });
        return {
            success: true,
            message: res?.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const RequestKyc = (credentials) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/v1/fraudguardian/in_app_search/`, { ...credentials, merchant });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetKycResults());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const DeleteKycResult = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/v1/fraudguardian/kyc_result/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetKycResults());
        return {
            success: true,
            message: res?.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const UploadBulkKycRequests = (credentials) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    credentials.append('merchant_id', merchant);
    try {
        const res = await axiosInstance().post(`/v1/fraudguardian/kyc_entities_upload/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetKycResults());
        return {
            success: true,
            message: 'You have successfully invited this vendor! They will get a mail in few minutes.',
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
