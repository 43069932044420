import React from 'react';
//core componenent
import { Stack, Typography, Box, Tooltip } from '@mui/material';
import AppButton from 'components/new_components/AppButton';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import { ArrowBackIosNew, CheckCircleOutlined, Save } from '@mui/icons-material';
import { FiDownload, FiHelpCircle } from 'react-icons/fi';
import { useTheme } from '@mui/material/styles';
import AppProgressBar from 'components/new_components/AppProgressBar';
import { answeredQuestionsPercentage } from 'utils';

const QuestionnaireHeader = (props) => {
    const { goBack, openTipsModal, showTips, handleCheckAndApprove, fileURL, title, questionnaireDetails, loading } =
        props;
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                py: 2.5,
                px: 2.5,
                borderBottom: `1px solid ${theme.palette.gray[200]}`,
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ width: '50%' }}>
                <ArrowBackIosNew
                    sx={{
                        width: 42,
                        height: 42,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: theme.palette.gray[50],
                        color: theme.palette.gray[500],
                        p: 1,
                        mr: 2,
                        borderRadius: '50%',
                        cursor: 'pointer',
                    }}
                    onClick={goBack}
                />
                <Typography
                    sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 700,
                        fontSize: 17,
                        wordBreak: 'break-all',
                    }}
                >
                    {title}
                </Typography>
            </Stack>
            <Box>
                <Box sx={{ width: '90%', ml: 4 }}>
                    <AppProgressBar
                        sx={{
                            width: '100%',
                            '&.MuiLinearProgress-colorSuccess': {
                                backgroundColor: questionnaireDetails?.status?.includes('analy')
                                    ? 'initial'
                                    : theme.palette.warning[900],
                            },
                        }}
                        value={answeredQuestionsPercentage(
                            questionnaireDetails?.accepted_count,
                            questionnaireDetails?.question_count
                        )}
                        color={questionnaireDetails?.status?.includes('analy') ? 'primary' : 'success'}
                    />
                    <Stack direction="row" justifyContent="space-between" sx={{ fontSize: '14px' }}>
                        <div>{questionnaireDetails?.accepted_count} Approved</div>
                        <div>
                            {questionnaireDetails?.question_count - questionnaireDetails?.accepted_count} Unapproved
                        </div>
                    </Stack>
                </Box>

                <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                    <AppLoadingButton
                        text="Submit All"
                        startIcon={<Save />}
                        sx={{
                            backgroundColor: theme.palette.primary[900],
                            color: theme.palette.white.main,
                            p: 1,
                            '&:hover': {
                                backgroundColor: theme.palette.primary[700],
                            },
                            '&.Mui-disabled': {
                                color: theme.palette.gray[90],
                                backgroundColor: '#fff',
                                border: `2px solid ${theme.palette.gray[95]}`,
                            },
                        }}
                        disabled={
                            questionnaireDetails?.status === 'completed' ||
                            questionnaireDetails?.status === 'analysed' ||
                            questionnaireDetails?.status !== 'in_progress'
                        }
                        type="submit"
                        loading={loading?.submit}
                    />
                    <AppLoadingButton
                        text="Check & Approve all"
                        startIcon={<CheckCircleOutlined />}
                        sx={{
                            border: `2px solid ${theme.palette.primary[900]}`,
                            mx: 1.5,
                            p: 1,
                            '&.Mui-disabled': {
                                color: theme.palette.gray[90],
                                border: `2px solid ${theme.palette.gray[95]}`,
                            },
                        }}
                        disabled={questionnaireDetails?.status === 'completed'}
                        onClick={handleCheckAndApprove}
                        loading={loading?.approveAll}
                    />
                    <AppButton
                        name={<FiDownload size="23px" />}
                        sx={{
                            border: `2px solid ${theme.palette.primary[900]}`,
                            px: 0.5,
                            minWidth: 'unset',
                            '&.Mui-disabled': {
                                color: theme.palette.gray[90],
                                border: `2px solid ${theme.palette.gray[95]}`,
                            },
                            '&:hover': {
                                color: theme.palette.primary[900],
                                border: `2px solid ${theme.palette.primary[900]}`,
                            },
                        }}
                        disabled={!fileURL}
                        href={fileURL}
                        target="_blank"
                    />
                    <Box sx={{ ml: 1.5 }}>
                        <Tooltip
                            title="Feeling Lost?"
                            arrow
                            placement="bottom-end"
                            open={!showTips}
                            disableHoverListener
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-arrow': {
                                        color: theme.palette.secondary[500],
                                    },
                                    '& .MuiTooltip-tooltip': {
                                        backgroundColor: theme.palette.secondary[500],
                                        zIndex: -999,
                                        color: theme.palette.primary.main,
                                        p: 1,
                                    },
                                },
                            }}
                        >
                            <div>
                                <AppButton
                                    name={<FiHelpCircle size="18px" />}
                                    sx={{
                                        border: 'none',
                                        mx: 1.5,
                                        p: 0.5,
                                        minWidth: 'unset',
                                    }}
                                    onClick={openTipsModal}
                                />
                            </div>
                        </Tooltip>
                    </Box>
                </Stack>
            </Box>
        </Stack>
    );
};

export default QuestionnaireHeader;
