import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
// import { MoreVert } from '@mui/icons-material';

//translations
import { useTranslation } from 'react-i18next';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { useSelector } from 'react-redux';

const VendorsTableActions = (props) => {
    const theme = useTheme();
    const { row, openDeleteModal, openDetailsModal } = props;

    // state
    const [anchor, setAnchor] = useState(null);

    //translation
    const { t } = useTranslation('vendorManagement');

    //accessing values from the redux store
    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const role = useSelector((state) => state?.generalReducers?.user_info?.role);

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const handleOpenDeleteModal = () => {
        closeMenu();
        openDeleteModal(row);
    };
    return (
        <>
            <IconButton sx={{ color: theme.palette.gray[900], ml: -1 }} onClick={openMenu}>
                <MoreHoriz sx={{ color: '#64748B', height: 20 }} />
            </IconButton>
            <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={closeMenu}
                PaperProps={{
                    style: {
                        width: 180,
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        openDetailsModal(row);
                        closeMenu();
                    }}
                    sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B', py: 0.2 }}
                >
                    {t('table.tableAction.view_detail')}
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={handleOpenDeleteModal}
                    sx={{ fontSize: '14px', fontWeight: 500, color: '#FF5449', py: 0.2 }}
                    disabled={user_type === 'auditor' || role === 'auditor'}
                >
                    {t('table.tableAction.delete')}
                </MenuItem>
            </Menu>
        </>
    );
};

export default VendorsTableActions;
