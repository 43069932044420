import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

// core components
import AppAvatar from 'components/new_components/AppAvatar';
import InfoListItem from './components/InfoListItem';

// images
import pfp from 'assets/img/avatar.jpg';

// redux
import { connect, useSelector } from 'react-redux';

//translation
import { useTranslation } from 'react-i18next';
import { GetDownloadLink } from 'store/actions/generalActions';
import { toast } from 'react-toastify';

const OwnerInfo = (props) => {
    const theme = useTheme();
    const { owner_info } = props;

    const { t } = useTranslation('company');
    const user_info = useSelector((state) => state?.generalReducers?.user_info);
    const [loading, setLoading] = useState(false);
    const [imageLink, setImageLink] = useState('');

    useEffect(() => {
        if (user_info?.picture) {
            // set loading icon on start of fetch request
            setLoading(true);
            GetDownloadLink('user_mgt', 'user', user_info.id)()
                .then((res) => {
                    if (res.success) {
                        setLoading(false);
                        setImageLink(res.data);
                    } else {
                        setImageLink('');
                    }
                })
                .catch(() => toast.error('Error getting owner image'));
        }
    }, [user_info?.picture]);

    return (
        <Box sx={{ p: 3 }}>
            <Typography
                variant="h2"
                component="h2"
                sx={{
                    color: theme.palette.primary[900],
                    fontSize: 18,
                    fontWeight: 700,
                    mb: 2.5,
                }}
            >
                {t('ownerInfo.header')}
            </Typography>
            <Box sx={{ maxWidth: '500px' }}>
                <AppAvatar
                    label={t('ownerInfo.avatarLabel')}
                    src={imageLink || pfp}
                    id="owner_image"
                    loading={loading}
                />
                <InfoListItem
                    label={t('ownerInfo.infoListItem1')}
                    value={`${owner_info?.first_name} ${owner_info?.last_name}`}
                />
                <InfoListItem label={t('ownerInfo.infoListItem2')} value={owner_info?.email} />
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        owner_info: state?.merchantReducers?.merchant_info?.owner_detail,
    };
};
export default connect(mapStateToProps, {})(OwnerInfo);
