import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

//translations
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { useTheme } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { DeleteFraudMonitoring } from 'store/actions/FraudGauardianActions';
import { useDispatch } from 'react-redux';

const DeleteRecordModal = (props) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { open, handleClose, payload } = props;
    const [deleting, setDeleting] = useState(false);

    const deleteFraudMonitoring = async () => {
        setDeleting(true);
        const res = await dispatch(DeleteFraudMonitoring(payload?.id));
        setDeleting(false);
        if (res?.success) {
            handleClose();
            toast.success('Deleted successfully!');
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            width="500px"
            height="250px"
            maxHeight={'250px'}
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#F8FAFC',
                        py: 3,
                        // pr: 3,
                        mx: -2,
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit' }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={deleting}
                        loadingPosition="center"
                        variant="contained"
                        color="primary"
                        disabled={deleting}
                        onClick={deleteFraudMonitoring}
                        sx={{
                            backgroundColor: '#F43F5E',
                            textTransform: 'inherit',
                            px: 3,
                            py: 1,
                            fontSize: '14px',
                            fontWeight: 400,
                            '&:hover': {
                                backgroundColor: '#F43F5E',
                            },
                        }}
                    >
                        Yes, delete
                    </LoadingButton>
                </Box>
            }
        >
            <Box
                sx={{ my: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
            >
                <Typography sx={{ color: theme.palette.neutral[600], fontSIZE: '18px', fontWeight: 600, mb: 2 }}>
                    Delete Record?
                </Typography>
                <Typography
                    sx={{ color: theme.palette.neutral[600], fontSize: '13px', fontWeight: 400, textAlign: 'center' }}
                >
                    Deleting record is irreversible. <br />
                    Are you sure you want to go ahead?
                </Typography>
            </Box>
        </AppCenteredModal>
    );
};

export default DeleteRecordModal;
