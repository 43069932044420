import React from 'react';
import { useTheme } from '@mui/material/styles';

import AppRadioGroup from 'components/new_components/AppRadioGroup';

//translation
import { useTranslation } from 'react-i18next';
const PaymentMethodRadio = (props) => {
    const { labelStyle, options, value, onChange } = props;
    const theme = useTheme();

    //translation hook
    const { t } = useTranslation('accounts');
    return (
        <AppRadioGroup
            label={t('plans.paymentMethodRadioLabel')}
            labelStyle={labelStyle}
            options={options}
            groupStyle={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                '& input': {
                    width: 0,
                },
                '& svg': {
                    width: 20,
                    height: 20,
                },
            }}
            sx={{ my: 1 }}
            value={value}
            onChange={onChange}
            radioLabelStyle={{
                m: 1,
                ml: 0,
                mr: 1.5,
                p: 1,
                pr: 1.2,
                py: 1.3,
                minWidth: 170,
                fontSize: 14,
                fontWeight: 400,
                color: theme.palette.primary[900],
                backgroundColor: theme.palette.gray[60],
                borderRadius: 2,
                '& .MuiRadio-root': {
                    p: 0,
                    mr: 1,
                },
                '&.Mui-disabled': {
                    color: theme.palette.gray[400],
                },
            }}
        />
    );
};

export default PaymentMethodRadio;
