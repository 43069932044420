import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
// import { MoreVert } from '@mui/icons-material';

//translations
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const VendorsTableActions = (props) => {
    const theme = useTheme();
    const { row, openModal, openDeleteModal, openDetailsModal, ResendQuestionaire } = props;

    // state
    const [anchor, setAnchor] = useState(null);
    const history = useHistory();

    //translation
    const { t } = useTranslation('vendorManagement');
    //accessing values from the redux store
    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const role = useSelector((state) => state?.generalReducers?.user_info?.role);

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const openEditModal = () => {
        closeMenu();
        const payload = {
            name: row?.name,
            description: row?.description,
            data: row?.data,
            risk_level: row?.risk_level?.toLowerCase(),
            products: row?.products,
            email: row?.email,
            min_risk_level: row?.min_risk_level,
            ran_aml: row?.ran_aml,
            is_aml_vendor: row?.is_aml_vendor,
        };
        openModal('edit', payload, row?.id);
    };

    const handleOpenDeleteModal = () => {
        closeMenu();
        openDeleteModal(row?.id);
    };
    return (
        <>
            <IconButton sx={{ color: theme.palette.gray[900], ml: -1 }} onClick={openMenu}>
                <MoreHoriz />
            </IconButton>
            <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={closeMenu}
                PaperProps={{
                    style: {
                        width: 180,
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        openDetailsModal(row);
                        closeMenu();
                    }}
                    sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B', py: 0.2 }}
                >
                    {t('table.tableAction.view_detail')}
                </MenuItem>
                <Divider />
                {row?.has_responded && (
                    <MenuItem
                        onClick={() => {
                            history.push(`/vendor/responses/${row?.id}`);
                            closeMenu();
                        }}
                        sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B', py: 0.2 }}
                    >
                        {t('table.tableAction.view_response')}
                    </MenuItem>
                )}
                {row?.has_responded && <Divider />}
                <MenuItem
                    onClick={openEditModal}
                    sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B', py: 0.2 }}
                    disabled={user_type === 'auditor' || role === 'auditor'}
                >
                    {t('table.tableAction.edit')}
                </MenuItem>
                <Divider />
                {!row?.has_responded && (
                    <MenuItem
                        onClick={() => {
                            ResendQuestionaire(row?.id);
                            closeMenu();
                        }}
                        sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B', py: 0.2 }}
                        disabled={user_type === 'auditor' || role === 'auditor'}
                    >
                        {t('table.tableAction.resend_questionaire')}
                    </MenuItem>
                )}
                {!row?.has_responded && <Divider />}
                <MenuItem
                    onClick={handleOpenDeleteModal}
                    sx={{ fontSize: '14px', fontWeight: 500, color: '#FF5449', py: 0.2 }}
                    disabled={user_type === 'auditor' || role === 'auditor'}
                >
                    {t('table.tableAction.delete')}
                </MenuItem>
            </Menu>
        </>
    );
};

export default VendorsTableActions;
