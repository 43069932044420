import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Drawer, Row, Col, Input, Button } from 'antd';
import { useDispatch } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const HeaderModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [body, setBody] = useState('');
    const { open, handleCancel, one_article, CreateArticle, EditArticle, header_id } = props;

    // redux
    const dispatch = useDispatch();

    const modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                ['image', 'link'],
                [
                    {
                        color: [
                            '#000000',
                            '#e60000',
                            '#ff9900',
                            '#ffff00',
                            '#008a00',
                            '#0066cc',
                            '#9933ff',
                            '#ffffff',
                            '#facccc',
                            '#ffebcc',
                            '#ffffcc',
                            '#cce8cc',
                            '#cce0f5',
                            '#ebd6ff',
                            '#bbbbbb',
                            '#f06666',
                            '#ffc266',
                            '#ffff66',
                            '#66b966',
                            '#66a3e0',
                            '#c285ff',
                            '#888888',
                            '#a10000',
                            '#b26b00',
                            '#b2b200',
                            '#006100',
                            '#0047b2',
                            '#6b24b2',
                            '#444444',
                            '#5c0000',
                            '#663d00',
                            '#666600',
                            '#003700',
                            '#002966',
                            '#3d1466',
                        ],
                    },
                ],
            ],
        },
    };

    // function
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res =
            open === 'Add'
                ? await dispatch(CreateArticle(data, header_id))
                : await dispatch(EditArticle(one_article.id, data, header_id));
        setLoading(false);
        if (res.success) {
            setData({});
            setBody('');
            handleCancel();
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    // useEffect
    useEffect(() => {
        if (one_article) {
            setData(one_article);
            open !== 'Add' && setBody(one_article?.body);
        } else {
            setData({});
            setBody('');
        }
    }, [one_article]);

    useEffect(() => {
        if (body) {
            setData({ ...data, body });
        }
    }, [body]);

    return (
        <Drawer
            title={`${open} the Article`}
            open={open}
            onClose={handleCancel}
            footer={null}
            destroyOnClose={true}
            width="600px"
        >
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="title">Article Title</label>
                        <Input
                            type="text"
                            size="large"
                            name="title"
                            id="title"
                            onChange={handleTextChange}
                            value={data.title || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="sub_title">Article SubTitle</label>
                        <Input
                            type="text"
                            size="large"
                            name="sub_title"
                            id="sub_title"
                            onChange={handleTextChange}
                            value={data.sub_title || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="body">Article Body</label>
                        <ReactQuill theme="snow" onChange={setBody} value={body || ''} modules={modules} />
                    </Col>
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Drawer>
    );
};

export default HeaderModal;
