import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const AppTag = (props) => {
    const theme = useTheme();

    const { text, type = 'default', sx = {}, icon, fontStyle, noItalic } = props;

    const getTagBackgroundColor = () => {
        return type === 'success'
            ? theme.palette.success[500] + '20'
            : type === 'pending'
            ? theme.palette.warning[900] + '20'
            : type === 'failed'
            ? theme.palette.error[500] + '20'
            : type === 'high'
            ? theme.palette.error[300] + '20'
            : type === 'critical'
            ? theme.palette.error[500]
            : type === 'default'
            ? theme.palette.white.main
            : type === 'primary'
            ? theme.palette.primary[100] + 80
            : type === 'draft'
            ? theme.palette.primary[100] + 30
            : type === 'auditing'
            ? '#B1C5FF' + 80
            : '';
    };

    const getTagTextAndBorderColor = () => {
        return type === 'success'
            ? theme.palette.success[500]
            : type === 'pending'
            ? theme.palette.warning[900]
            : type === 'failed'
            ? theme.palette.error[500]
            : type === 'high'
            ? theme.palette.error[300]
            : type === 'critical'
            ? theme.palette.white.main
            : type === 'default'
            ? '#77777A'
            : type === 'primary'
            ? theme.palette.primary[900]
            : type === 'draft'
            ? '#77777A'
            : type === ''
            ? ''
            : '';
    };
    return (
        <Box
            component="span"
            sx={{
                color: getTagTextAndBorderColor(),
                background: getTagBackgroundColor(),
                textTransform: 'capitalize',
                fontWeight: 500,
                padding: '2px 16px',
                border: `1px solid ${getTagTextAndBorderColor()}`,
                borderRadius: '4px',
                width: '100%',
                maxWidth: 100,
                fontSize: 12,
                margin: '0 8px 12px 0',
                whiteSpace: 'nowrap',
                ...sx,
                ...fontStyle,
            }}
        >
            <span style={{ fontStyle: noItalic ? 'normal' : 'italic' }}>
                {icon}
                {text}
            </span>
        </Box>
    );
};

export default AppTag;
