import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Col, Button, Empty, Card, Typography, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

// redux
import { connect } from 'react-redux';
import { GetEvidencesBySubmanagement, DeleteEvidence } from 'store/actions/adminActions';
import AddEvidenceModal from './ISO9001Actions/AddEvidenceModal';

// sub-components
const { Title } = Typography;
const { confirm } = Modal;

const Soc2EvidencesView = (props) => {
    const { GetEvidencesBySubmanagement, subcontrol, all_admin_evidence_by_management, DeleteEvidence } = props;

    // state
    const [modal, setModal] = useState({ add: false, edit: false });
    const [oneEvidence, setOneEvidence] = useState({});
    const [loading, setLoading] = useState({ content: false });

    // functions
    const openAddEvidenceModal = () => setModal({ ...modal, add: true });
    const closeAddEvidenceModal = () => setModal({ ...modal, add: false });

    const openEditEvidenceModal = (doc) => {
        setModal({ ...modal, edit: true });
        setOneEvidence(doc);
    };
    const closeAEditEvidenceModal = () => setModal({ ...modal, edit: false });
    // const closeEditDocumentModal = () => setModal({ ...modal, edit: false });

    const getEvidenceBySubcontrol = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetEvidencesBySubmanagement(subcontrol?.number);
        setLoading({ ...loading, content: false });
        if (!res?.success) {
            toast?.error(res?.message);
        }
    };
    //Actions Array
    const documentActions = (document) => {
        let actions = [
            <Tooltip title="Edit this Evidence" color="blue">
                <EditOutlined key="edit" title="Edit this evidence" onClick={() => openEditEvidenceModal(document)} />
            </Tooltip>,
            <Tooltip title="Delete this Evidence" color="red">
                <DeleteOutlined key="delete" title="Delete this evidence" onClick={() => deleteModal(document)} />
            </Tooltip>,
        ];
        return actions;
    };
    const deleteModal = (document) => {
        confirm({
            title: 'Do you want to delete this Evidence?',
            content:
                'If this evidence is linked to other standards, you will be deleting it across all standards by clicking yes. If you intend to remove it from this standard alone and it is linked, just unlink it to avoid loss of data.',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await DeleteEvidence(document?.id, 'iso9001', subcontrol?.id);
                if (res.success) {
                    toast.success('Evidence Deleted Successfully');
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    // useEffect
    useEffect(() => {
        getEvidenceBySubcontrol();
    }, []);
    return (
        <>
            <Row justify="end">
                <Button type="primary" icon={<PlusOutlined />} onClick={openAddEvidenceModal}>
                    Add Evidence
                </Button>
            </Row>
            <section className="my-3">
                {loading.content ? (
                    'loading'
                ) : (
                    <>
                        {all_admin_evidence_by_management && all_admin_evidence_by_management?.length > 0 ? (
                            <Row gutter={16}>
                                {all_admin_evidence_by_management
                                    ?.sort((a, b) => a?.id - b?.id)
                                    .map((document) => {
                                        return (
                                            <Col
                                                xs={24}
                                                sm={12}
                                                md={8}
                                                lg={6}
                                                style={{ marginBottom: '1rem' }}
                                                key={document.id}
                                            >
                                                <Card loading={false} actions={documentActions(document)}>
                                                    <Title level={5} style={{ textAlign: 'center' }}>
                                                        {document.quality_mgt_title}
                                                    </Title>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Evidence</h3>
                                                <span>Add a new evidence </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section>
            {modal?.add && (
                <AddEvidenceModal
                    open={modal.add}
                    type="add"
                    handleClose={closeAddEvidenceModal}
                    tag="iso9001"
                    payload={{
                        quality_mgt: subcontrol?.parent_quality,
                        quality_mgt_sub_no: subcontrol?.number,
                    }}
                    titleLabel="quality_mgt_title"
                    descLabel="quality_mgt_description"
                    segmentLabel="segments"
                    numberLabel="quality_mgt_number"
                    groupIdLabel="quality_mgt_sub_no"
                    title={subcontrol?.number}
                />
            )}
            {modal?.edit && (
                <AddEvidenceModal
                    open={modal.edit}
                    type="edit"
                    handleClose={closeAEditEvidenceModal}
                    tag="iso9001"
                    payload={{
                        quality_mgt: subcontrol?.parent_quality,
                        quality_mgt_sub_no: subcontrol?.number,
                    }}
                    titleLabel="quality_mgt_title"
                    descLabel="quality_mgt_description"
                    segmentLabel="segments"
                    groupIdLabel="quality_mgt_sub_no"
                    oneEvidence={oneEvidence}
                    title={subcontrol?.number}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_admin_evidence_by_management: state?.adminReducers?.all_admin_evidence_by_management,
    };
};
export default connect(mapStateToProps, {
    GetEvidencesBySubmanagement,
    DeleteEvidence,
})(Soc2EvidencesView);
