import { Dropdown, Menu } from 'antd';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import React from 'react';

const { Item } = Menu;

const FeatureActions = (props) => {
    const { record, openEditModal, openDeleteModal } = props;
    const menu = (
        <Menu>
            <Item key="0" onClick={() => openEditModal('Edit', record)}>
                <div className="flex items-center">
                    <EditOutlined className="mr-1" />
                    Edit
                </div>
            </Item>
            <Item key="1" style={{ color: 'red' }} onClick={() => openDeleteModal(record)}>
                <div className="flex items-center">
                    <DeleteOutlined className="mr-1" />
                    Delete
                </div>
            </Item>
        </Menu>
    );
    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <MoreOutlined />
        </Dropdown>
    );
};

export default FeatureActions;
