import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    RadioGroup,
    Stack,
    Typography,
} from '@mui/material';

import React, { useEffect, useMemo, useState } from 'react';
import { Info, AddCircle, Clear } from '@mui/icons-material';
import AppSelectInput from 'components/new_components/AppSelectInput';
import AppButton from 'components/new_components/AppButton';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import AppTooltip from 'components/new_components/AppTooltip';
import Check from '@mui/material/Radio';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/system';
import AppInput from 'components/new_components/AppInput';

// redux
import { connect } from 'react-redux';
import {
    AnswerAccessorQuestion,
    UpdateAccessorQuestion,
    CreateAuditRequest,
    UpdateAuditRequest,
    GetAllAuditRequests,
} from 'store/actions/auditActions';

const FormItem = (props) => {
    // hook
    const theme = useTheme();

    // props
    const {
        category,
        goPrev,
        AnswerAccessorQuestion,
        answers,
        UpdateAccessorQuestion,
        navigateCategory,
        isLast,
        GetAllAuditRequests,
        UpdateAuditRequest,
        merchant,
        roc_id,
        formData,
        setFormData,
    } = props;

    // state
    const [loading, setLoading] = useState(false);
    const [formIsTouched, setFormIsTouched] = useState(false);
    const [sectionItems, setSectionItems] = useState([]);
    const [new_questions, setNewQuestions] = useState([]);

    // memos
    const questions = useMemo(() => {
        try {
            return JSON.parse(category.questions).sort((a, b) => a?.id - b?.id);
        } catch {
            return [];
        }
    }, [category]);

    const sections = useMemo(() => {
        try {
            return category?.subcategory;
        } catch {
            return [''];
        }
    }, [category]);

    const answer = useMemo(() => {
        return answers && answers?.find((answer) => answer.category === category?.id);
    }, [answers, category?.id]);

    // functions
    const addNewSection = (section, item) => {
        const newItem = [...sectionItems];
        const new_section_item = {
            ...section,
            sections: [...section.sections, item],
        };
        const find_section = newItem?.indexOf(section);
        newItem[find_section] = new_section_item;
        setSectionItems(newItem);
    };
    const removeSection = (section, entryIdx) => {
        const newItem = [...sectionItems];
        const new_section_item = {
            ...section,
            sections: section?.sections.splice(entryIdx, 1),
        };
        const find_section = newItem?.indexOf(section);
        newItem[find_section] = new_section_item;
        setSectionItems(newItem);
    };

    // const addNewQuestion = (section) => {
    //     const newItem = [...new_questions];
    //     newItem.push(section);
    //     setNewQuestions(newItem);
    // };
    // const removeQuestion = (section, entryIdx) => {
    //     const newItem = [...sectionItems];
    //     const new_section_item = {
    //         ...section,
    //         sections: section?.sections.splice(entryIdx, 1),
    //     };
    //     const find_section = newItem?.indexOf(section);
    //     newItem[find_section] = new_section_item;
    //     setSectionItems(newItem);
    // };

    const handleTextChange = (e, sectionName) => {
        const { name, value } = e.target;
        const sectName = sectionName ? sectionName : 'questions';
        setFormData((prevData) => ({
            ...prevData,
            [sectName]: {
                ...prevData[sectName],
                [name]: value,
            },
        }));
        setFormIsTouched(true);
    };

    const UpdateRoc = async () => {
        const body = {
            assessor_answer_completed: true,
        };
        const res = await UpdateAuditRequest(body, roc_id);
        if (res?.success) {
            toast.success('Answers successfully saved!');
        } else {
            return toast.error("Something went wrong, couldn't save your asnwer");
        }
    };
    const goToNext = async () => {
        setFormData({});
        await navigateCategory();
    };

    const handleSubmitAndNext = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (formIsTouched) {
            const payload = {
                category: category?.id,
                merchant: merchant?.id,
                answers: formData,
            };
            const res = answer
                ? await UpdateAccessorQuestion(payload, answer?.id)
                : await AnswerAccessorQuestion(payload);
            if (!res?.success) {
                return toast.error("Something went wrong, couldn't save your asnwer");
            } else {
                if (isLast()) {
                    UpdateRoc();
                } else {
                    toast.success('Answers successfully saved!');
                }
                // toast.success('Answers successfully saved!');
            }
        }
        goToNext();
        setFormIsTouched(false);
        setLoading(false);
    };

    const getAllAuditRequests = async () => {
        const res = await GetAllAuditRequests();
        if (!res?.success) {
            toast.error("Something went wrong, couldn't get audits.");
        }
    };

    useEffect(() => {
        getAllAuditRequests();
    }, []);

    useEffect(() => {
        if (answer) {
            const answers = answer?.answers;
            setFormData(answers);
        }
    }, [answer]);

    const goToPrev = () => {
        goPrev();
        setFormData({});
    };
    useEffect(() => {
        if (sections) {
            const modified_sections = sections?.map((section) => {
                return {
                    category: section?.category,
                    date_created: section?.date_created,
                    date_updated: section?.date_updated,
                    id: section?.id,
                    name: section?.name,
                    sections: JSON.parse(section?.sections),
                };
            });
            setSectionItems(modified_sections);
        }
    }, [sections]);

    useEffect(() => {
        if (questions) {
            setNewQuestions(questions);
        }
    }, [questions]);
    return (
        <Stack component="form" onSubmit={handleSubmitAndNext} justifyContent="space-between" className="flex-1">
            <Box sx={{ pb: 3 }}>
                {category?.question_type === 'single-duplicate' && (
                    <div className="flex items-center">
                        <Info color="primary" />
                        <span className="text-[#5E5E62] text-[14px] pl-2">
                            Use the <strong>Add field</strong> button to enter a new data.
                        </span>
                    </div>
                )}

                <Grid container spacing={2}>
                    {new_questions?.map((entry, entryIdx) => {
                        return (
                            <React.Fragment key={entryIdx}>
                                <FieldItem
                                    onChange={handleTextChange}
                                    question={entry}
                                    entryIdx={entryIdx}
                                    formData={formData}
                                    answer={answer}
                                    type={entry?.type}
                                    required={entry?.required}
                                    tooltip={entry?.tooltip}
                                />
                                <Divider sx={{ mt: 3 }} />
                            </React.Fragment>
                        );
                    })}
                </Grid>
                {category?.question_type === 'duplicate' && (
                    <Grid container spacing={2}>
                        {new_questions?.map((entry, entryIdx) => {
                            return (
                                <React.Fragment key={entryIdx}>
                                    {Object?.keys(entry)?.map((obj, indx) => {
                                        return (
                                            <FieldItem_
                                                onChange={handleTextChange}
                                                key={indx}
                                                question={entry[obj]}
                                                formData={formData}
                                            />
                                        );
                                    })}

                                    {/* <Grid justifyContent="space-between" container className="my-3">
                                        <Grid item>
                                            {entryIdx === 0 && (
                                                <Button
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        color: theme.palette.primary[900],
                                                    }}
                                                    onClick={() => addNewQuestion(entry)}
                                                    startIcon={<AddCircle />}
                                                >
                                                    Add Field
                                                </Button>
                                            )}
                                        </Grid>
                                        {new_questions.length > 1 && (
                                            <Grid item>
                                                <Box
                                                    component="button"
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        color: theme.palette.primary[900],
                                                    }}
                                                    onClick={() => removeQuestion()}
                                                >
                                                    <Clear />
                                                    <span className="font-medium text-base">Remove Field</span>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid> */}
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                )}
                <Box>
                    {category?.question_type === 'single-duplicate' && (
                        <>
                            {sectionItems?.map((section, index) => {
                                const sectionEntries = section?.sections;
                                return (
                                    <Box key={index} sx={{ mt: 3 }}>
                                        <Typography sx={{ fontWeight: 700 }}>{section?.name}</Typography>
                                        <Grid container spacing={2}>
                                            {sectionEntries?.map((entry, entryIdx) => {
                                                return (
                                                    <React.Fragment key={entryIdx}>
                                                        {Object?.keys(entry)?.map((obj, indx) => {
                                                            return (
                                                                <FieldItem_
                                                                    onChange={handleTextChange}
                                                                    key={indx}
                                                                    question={entry[obj]}
                                                                    section={section?.name}
                                                                    formData={formData}
                                                                    index={entryIdx}
                                                                />
                                                            );
                                                        })}
                                                        <Grid justifyContent="space-between" container className="my-3">
                                                            <Grid item>
                                                                {entryIdx + 1 === sectionEntries.length && (
                                                                    <Button
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            color: theme.palette.primary[900],
                                                                        }}
                                                                        onClick={() => addNewSection(section, entry)}
                                                                        startIcon={<AddCircle />}
                                                                    >
                                                                        Add Field
                                                                    </Button>
                                                                )}
                                                            </Grid>
                                                            {sectionEntries.length > 1 && (
                                                                <Grid item>
                                                                    <Box
                                                                        component="button"
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            color: theme.palette.primary[900],
                                                                        }}
                                                                        onClick={() =>
                                                                            removeSection(section, entryIdx, entry)
                                                                        }
                                                                    >
                                                                        <Clear />
                                                                        <span className="font-medium text-base">
                                                                            Remove Field
                                                                        </span>
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </Grid>
                                    </Box>
                                );
                            })}
                        </>
                    )}
                </Box>
                {category?.question_type === 'multiple' &&
                    sectionItems?.map((section, index) => {
                        const sectionEntries = section?.sections;
                        return (
                            <Box key={index} sx={{ mt: 4 }}>
                                <Typography sx={{ fontWeight: 700 }}>{section?.name}</Typography>
                                <Grid container spacing={2}>
                                    {sectionEntries?.map((entry, entryIdx) => {
                                        return (
                                            <FieldItem
                                                onChange={handleTextChange}
                                                key={entryIdx}
                                                question={entry}
                                                answers={answer?.answers[section.name]}
                                                section={section}
                                                formData={formData}
                                                answer={answer}
                                                type={entry?.type}
                                                required={entry?.required}
                                                tooltip={entry?.tooltip}
                                            />
                                        );
                                    })}
                                </Grid>
                                <Divider sx={{ mt: 3 }} />
                            </Box>
                        );
                    })}
            </Box>

            {isLast() && (
                <span style={{ color: 'red', fontSize: '12px', marginTop: '8rem' }}>
                    please check all answers properly, you will not be able to edit once submitted.
                </span>
            )}
            <Stack direction="row" spacing={4} sx={{ px: 3 }}>
                <AppButton name="Previous" variant="outlined" color="primary" onClick={goToPrev} />
                <AppLoadingButton
                    text={isLast() ? 'Submit' : 'Next'}
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={loading}
                />
            </Stack>
        </Stack>
    );
};

const FieldItem_ = (props) => {
    const { question, onChange, section, formData, index } = props;
    const section_name = index === 0 ? `${section}` : `${section}${index}`;

    return (
        <Grid item xs={12} md={question?.fullWidth ? 12 : 6}>
            <AppInput
                label={
                    <>
                        <span>{question}</span>
                    </>
                }
                multiline
                required
                onChange={(e) => onChange(e, section_name)}
                value={(formData[section_name || 'questions'] && formData[section_name || 'questions'][question]) || ''}
                name={question}
            />
        </Grid>
    );
};

const FieldItem = (props) => {
    const theme = useTheme();
    const { question, onChange, section, formData } = props;
    return (
        <Grid item xs={12} md={question?.fullWidth ? 12 : 6}>
            {question.type === 'text' ? (
                <AppInput
                    label={
                        <>
                            <span>{question?.name}</span>
                            {!!question.tooltip && (
                                <AppTooltip title={question?.tooltip || ''} placement="top">
                                    <IconButton sx={{ p: 0 }}>
                                        <Info
                                            sx={{
                                                width: 16,
                                                height: 16,
                                                ml: 0.5,
                                                color: theme.palette.gray[500],
                                            }}
                                        />
                                    </IconButton>
                                </AppTooltip>
                            )}
                        </>
                    }
                    multiline
                    required={question?.required}
                    onChange={(e) => onChange(e, section?.name)}
                    value={
                        (formData[section?.name || 'questions'] &&
                            formData[section?.name || 'questions'][question.name]) ||
                        ''
                    }
                    name={question?.name}
                />
            ) : question?.type === 'check' ? (
                <FormControl component="fieldset">
                    <FormLabel component="typography" sx={{ mb: 1, fontWeight: 600, fontSize: '16px', my: 2 }}>
                        {question?.name}
                    </FormLabel>
                    <RadioGroup
                        aria-label="gender"
                        name={question?.name}
                        value={
                            (formData[section?.name || 'questions'] &&
                                formData[section?.name || 'questions'][question.name]) ||
                            ''
                        }
                        onChange={(e) => onChange(e, section?.name)}
                    >
                        {question?.options?.map((option) => (
                            <FormControlLabel value={option} control={<Check />} label={option} />
                        ))}
                    </RadioGroup>
                </FormControl>
            ) : question?.type === 'dropdown' ? (
                <AppSelectInput
                    label={question?.name}
                    defaultValue="Select an option"
                    onChange={(e) => onChange(e, section?.name)}
                    value={
                        (formData[section?.name || 'questions'] &&
                            formData[section?.name || 'questions'][question.name]) ||
                        ''
                    }
                    required={question?.required}
                    options={question?.options}
                    name={question?.name}
                />
            ) : null}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    all_audit_requests: state?.auditReducers?.all_audit_requests,
    merchant_info: state?.merchantReducers?.merchant_info,
});
export default connect(mapStateToProps, {
    GetAllAuditRequests,
    AnswerAccessorQuestion,
    UpdateAccessorQuestion,
    UpdateAuditRequest,
    CreateAuditRequest,
})(FormItem);
