import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Input, Button, Modal } from 'antd';
import { SearchOutlined, CheckCircleFilled } from '@ant-design/icons';
import UploadedDocOperations from './operations/UploadedDocOperations';
//redux
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllAdminUploadedDocs, GetAllMerchants, DeleteAdminDocs, UpdateAdminDocs } from 'store/actions/adminActions';
import useSearch from 'hooks/useSearch';
import UploadDocument from './operations/UploadDocument';
import UpdateDocument from './operations/UpdateDocument';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ViewMerchantResponses from './operations/ViewMerchantResponses';

const AdminUploadedDocs = (props) => {
    const { GetAllAdminUploadedDocs, GetAllMerchants, DeleteAdminDocs, UpdateAdminDocs, all_admin_uploaded_docs } =
        props;

    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [modalRecord, setModalRecord] = useState({});
    const [selectedDoc, setSelectedDoc] = useState({});

    // hooks
    const { data, handleSearch } = useSearch(all_admin_uploaded_docs, [
        'name',
        'description',
        'company_name',
        'merchant_name',
    ]);

    //functions
    const openUploadModal = () => {
        setUploadModalOpen(true);
    };
    const closeUploadModal = () => {
        setUploadModalOpen(false);
    };
    const openUpdateModal = (data) => {
        setModalRecord(data);
        setUpdateModalOpen(true);
    };
    const closeUpdateModal = () => {
        setUpdateModalOpen(false);
        setModalRecord({});
    };
    const openViewModal = (document) => {
        setViewModalOpen(true);
        setSelectedDoc(document);
    };
    const closeViewModal = () => {
        setViewModalOpen(false);
        setSelectedDoc({});
    };
    const requestForUpdate = async (record) => {
        const res = await UpdateAdminDocs({ update_needed: !record?.update_needed }, record?.id);
        if (res?.success) {
            toast.success('Update request sent to Merchant');
        } else {
            toast.error(res?.message);
        }
    };
    const deleteModal = (doc) => {
        Modal.confirm({
            title: 'Do you want to delete this Document?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await DeleteAdminDocs(doc?.id);
                if (res?.success) {
                    toast.success('Document Deleted Successfully');
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };
    //async functions
    const getAllAdminUploadedDocs = () => {
        const res = GetAllAdminUploadedDocs();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllMerchants = () => {
        const res = GetAllMerchants();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    // constants
    const columns = [
        {
            title: 'Doc Name',
            render: (record) => {
                return record?.name;
            },
        },
        {
            title: 'Doc Description',
            render: (record) => record?.description,
        },
        {
            title: 'Merchant',
            render: (record) => {
                return record?.company_name ? record?.company_name : record?.merchant_name;
            },
        },
        {
            title: 'Needs Reponse',
            align: 'center',
            render: (record) =>
                record?.response_needed ? (
                    <CheckCircleFilled className="text-green-600 text-[20px]" />
                ) : (
                    <div className="flex justify-center items-center">
                        <div className="h-[4px] w-[40px] bg-[#cacaca] rounded-[4px]"></div>
                    </div>
                ),
        },
        {
            title: 'Update Required',
            align: 'center',
            render: (record) =>
                record?.update_needed ? (
                    <CheckCircleFilled className="text-green-600 text-[20px]" />
                ) : (
                    <div className="flex justify-center items-center">
                        <div className="h-[4px] w-[40px] bg-[#cacaca] rounded-[4px]"></div>
                    </div>
                ),
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => (
                <UploadedDocOperations
                    record={record}
                    openUpdateModal={openUpdateModal}
                    deleteModal={deleteModal}
                    requestForUpdate={requestForUpdate}
                    openViewModal={openViewModal}
                />
            ),
        },
    ];
    useEffect(() => {
        getAllAdminUploadedDocs();
        getAllMerchants();
    }, []);

    return (
        <div>
            <Row justify="end">
                <Col span={12} className="flex items-center">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                    <Button type="primary" className="flex justify-between items-center ml-3" onClick={openUploadModal}>
                        Upload Document
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(merchant) => merchant?.id}
                    columns={columns}
                    dataSource={data?.sort((a, b) => b?.id - a?.id)}
                    pagination={{
                        total: data?.length,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                    }}
                />
            </section>
            <UploadDocument open={uploadModalOpen} handleClose={closeUploadModal} />
            <UpdateDocument open={updateModalOpen} handleClose={closeUpdateModal} modalRecord={modalRecord} />
            <ViewMerchantResponses visible={viewModalOpen} closeDrawer={closeViewModal} selectedDoc={selectedDoc} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_admin_uploaded_docs: state?.adminReducers?.all_admin_uploaded_docs,
    };
};
export default connect(mapStateToProps, { GetAllAdminUploadedDocs, GetAllMerchants, DeleteAdminDocs, UpdateAdminDocs })(
    AdminUploadedDocs
);
