import React, { useState, useRef, useEffect } from 'react';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { Stack, Typography, IconButton, Box } from '@mui/material';
// import { AppFormInput } from 'components/new_components/forms';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import { AppSubmitButton } from 'components/new_components/forms';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/styles';

//form
import { AppForm } from 'components/new_components/forms';
import { runScanValues } from './utils/constant';
import { toast } from 'react-toastify';

//redux
import { connect } from 'react-redux';
import { RequestAPIScans } from 'store/actions/apiComplianceActions';
import { CloseOutlined } from '@ant-design/icons';

const RunScanModal = (props) => {
    const [authorization, setAuthorization] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [links, setLinks] = useState([]);
    const [error, setError] = useState('');
    const inputRef = useRef(null);

    const { open, handleClose, RequestAPIScans } = props;

    const theme = useTheme();

    const closeModal = () => {
        handleClose();
        setLinks([]);
        setError('');
        if (inputRef?.current) {
            inputRef.current.value = '';
        }
    };

    //functions
    const handleAuthorizationCheck = () => {
        setAuthorization(!authorization);
    };

    const handleSubmit = async () => {
        const values = { links: links };

        if (authorization && links.length) {
            setLoadingState(true);
            const res = await RequestAPIScans(values);
            setLoadingState(false);
            if (res?.success) {
                toast.success('Scan initiated Successfully');
                closeModal();
            } else {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    toast.error(res?.message);
                }
            }
        } else {
            toast.info('You have to authorize this request!');
        }
    };

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        }
    }, [links]);

    return (
        <AppForm initialValues={runScanValues} onSubmit={handleSubmit}>
            <AppCenteredModal
                open={open}
                handleClose={closeModal}
                width="464px"
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            mb: 6,
                        }}
                    >
                        <AppSubmitButton
                            text="Run Scan"
                            variant="contained"
                            color="primary"
                            loading={loadingState}
                            loadingPosition="center"
                            sx={{ width: '100%', py: 1.5 }}
                            disabled={!links.length || !authorization}
                        />
                    </Box>
                }
                sx={{ top: '-20%' }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mt: -1, borderBottom: `1px solid ${theme.palette.gray[90]}`, mb: 4 }}
                >
                    <Typography sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.gray[30] }}>
                        New Scan
                    </Typography>
                    <IconButton onClick={closeModal}>
                        <Close />
                    </IconButton>
                </Stack>

                <div className="custom-scroll flex flex h-20 max-h-20 w-full w-full flex-wrap items-start gap-2 overflow-x-hidden overflow-y-auto rounded-md border-2 bg-transparent p-0 p-2 text-sm">
                    {links?.map((link, index) => (
                        <button
                            key={index}
                            className="flex min-h-8 h-fit text-left break-all items-center gap-2 border border-[#E2E8F0] !rounded bg-transparent !px-2 !py-2 transition-all hover:brightness-90"
                            type="button"
                        >
                            <p className="text-sm font-normal text-[#475569]">{link}</p>

                            <CloseOutlined
                                className="text-sm"
                                onClick={() => {
                                    const data = [...links];
                                    const index = links.findIndex((el) => el?.toLowerCase() === link?.toLowerCase());
                                    data.splice(index, 1);
                                    setLinks(data);
                                }}
                            />
                        </button>
                    ))}
                    <input
                        placeholder="Enter endpoint(s)"
                        ref={inputRef}
                        onFocus={(event) => {
                            if (event.target.innerText === 'Enter endpoint(s)') {
                                event.target.innerText = '';
                            }

                            event.target.style.color = 'hsl(215,20%,40%)';
                        }}
                        onChange={(event) => {
                            // enforce > 2 characters length
                            if (event?.target?.value?.includes(',') && event?.target?.value?.length < 3) {
                                setError('Link must be at least two characters');
                            }

                            // save link
                            if (event?.target?.value?.includes(',') && event.target.value.length > 2) {
                                const inlinks = links?.find(
                                    (el) => el?.toLowerCase() + ',' === event.target.value?.toLowerCase()
                                );

                                if (inlinks) {
                                    setError('Link already exists in list');
                                } else if (!event?.target?.value?.startsWith('https://')) {
                                    setError('Link must start with https://');
                                } else {
                                    setError('');
                                    setLinks([...links, ...event.target.value.split(',')].filter(Boolean));
                                    event.target.value = '';
                                }
                            }
                        }}
                        className="min-h-10 break-all w-full border-none bg-transparent text-[#94A3B8] outline-none placeholder:text-[#94A3B8] text-sm min-h-8 h-fit"
                    />
                </div>
                {error ? <p className="text-sm text-[#ff5449] pt-2">{error}</p> : null}
                <p className="text-sm text-[#475569] pt-2">
                    Use commas to separate each endpoint. Endpoints are not case sensitive.
                </p>
                <AppCheckboxInput
                    name="authorization"
                    label="I give my complete consent to this request."
                    onChange={handleAuthorizationCheck}
                    value={authorization}
                    sx={{ mt: 1 }}
                />
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { RequestAPIScans })(RunScanModal);
