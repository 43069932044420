import { UserGuideHeader } from 'components/new_components/AppUserGuide';

const STEPS_LENGTH = 2;

export const steps = [
    {
        title: <UserGuideHeader title="Add Infrastructures" id={1} length={STEPS_LENGTH} />,
        description: 'Click the add infrastructures button to select any of the options and click submit.',
        placement: 'leftTop',
    },
    {
        title: <UserGuideHeader title="Download Agent" id={2} length={STEPS_LENGTH} />,
        description:
            'Click download agent button, select any of your preferable link and follow the steps to download agent',
        placement: 'leftTop',
    },
];
