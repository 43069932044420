import React from 'react';
// import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';

// core components
import ExceptionTab from './ExceptionTab';

//translation
import { useTranslation } from 'react-i18next';

const ExceptionsTabs = (props) => {
    const { onTabChange, currentTab } = props;
    // const theme = useTheme();

    //translation
    const { t } = useTranslation('compliance');

    return (
        <Stack direction="row" justifyContent="space-between">
            {[
                { label: t('documents'), value: 'doc' },
                { label: t('auditQuestion'), value: 'form' },
            ].map((tab) => {
                return (
                    <ExceptionTab
                        label={tab.label}
                        sx={{ width: '48%' }}
                        onClick={() => onTabChange(tab.value)}
                        active={tab.value === currentTab}
                        key={tab.value}
                    />
                );
            })}
        </Stack>
    );
};

export default ExceptionsTabs;
