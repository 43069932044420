import React, { useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { ChevronRightRounded } from '@mui/icons-material';

//translation
import { useTranslation } from 'react-i18next';

const CategorySelect = (props) => {
    const theme = useTheme();
    const { onCategoryChange, category, includeCybersecurity } = props;

    // state
    const [selectOpen, setSelectOpen] = useState(true);

    //translation
    const { t } = useTranslation('compliance');

    const CATEGORIES = includeCybersecurity
        ? [
              { name: t('management'), value: 'clause' },
              { name: t('annex'), value: 'annex' },
              { name: t('cybersecurity'), value: 'cybersecurity' },
              { name: t('cloud_security'), value: 'cloud_security' },
              { name: t('project_management'), value: 'project_management' },
          ]
        : [
              { name: t('management'), value: 'clause' },
              { name: t('annex'), value: 'annex' },
          ];

    // functions
    // logic
    const toggleSelectOpen = () => {
        setSelectOpen((current) => !current);
    };
    const handleCategoryChange = (selected) => {
        onCategoryChange(selected);
        setSelectOpen(false);
    };

    const activeCategories = useMemo(() => {
        return CATEGORIES?.filter((cat) => cat.value !== category)?.map((cat) => cat?.value);
    }, [category]);

    return (
        <Stack direction="row" alignItems="center" sx={{ py: 2, borderBottom: `1px solid ${theme.palette.gray[100]}` }}>
            <Box
                component="button"
                sx={{
                    height: 48,
                    width: 48,
                    border: `1px solid ${theme.palette.primary[100]}`,
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    position: 'relative',
                    left: -24,
                    boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.16)',
                    zIndex: 5,
                    backgroundColor: theme.palette.white.main,
                }}
                onClick={toggleSelectOpen}
            >
                <ChevronRightRounded
                    color="primary"
                    sx={{
                        transform: selectOpen ? 'rotateY(180deg)' : 'rotateY(0)',
                        transition: 'transform 0.5s',
                    }}
                />
            </Box>
            <Box
                sx={{
                    position: 'relative',
                    left: -24,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Box
                    component="span"
                    sx={{
                        color: theme.palette.primary[900],
                        borderWidth: '0 2px 2px 0',
                        borderColor: theme.palette.primary[900],
                        borderStyle: 'solid',
                        borderRadius: '0 24px 24px 0',
                        backgroundColor: theme.palette.secondary[500],
                        px: 2,
                        py: 0.7,
                        pl: 2.5,
                        position: 'relative',
                        left: -16,
                        fontSize: 16,
                        fontWeight: 600,
                        zIndex: 4,
                        width:
                            category === 'clause'
                                ? 206
                                : category === 'cybersecurity' || category === 'cloud_security'
                                ? 156
                                : category === 'project_management'
                                ? 206
                                : 110,
                        transition: `width 0.3s`,
                        whiteSpace: 'nowrap',
                    }}
                >
                    {CATEGORIES.find((cat) => cat.value === category).name}
                </Box>
                {CATEGORIES.map((cat) => {
                    console.log(activeCategories?.[0]);
                    return cat.value === category ? null : (
                        <Box
                            component="button"
                            a
                            sx={{
                                color: theme.palette.primary[900],
                                border: `1px solid ${theme.palette.primary[900]}`,
                                borderRadius: '0 24px 24px 0',
                                px: 2,
                                py: 0.6,
                                pl: 2.5,
                                position: 'relative',
                                fontSize: 16,
                                fontWeight: 600,
                                backgroundColor: theme.palette.white.main,
                                left:
                                    selectOpen && category === 'project_management' && cat.value === 'cybersecurity'
                                        ? -59
                                        : selectOpen &&
                                          cat.value === 'cloud_security' &&
                                          category === 'project_management'
                                        ? -70
                                        : selectOpen && category === 'cloud_security' && cat.value === 'cybersecurity'
                                        ? -58
                                        : selectOpen && cat.value === 'cybersecurity'
                                        ? -45
                                        : selectOpen && category === 'cybersecurity' && cat.value === 'annex'
                                        ? -45
                                        : selectOpen && category === 'project_management' && cat.value === 'annex'
                                        ? -46
                                        : selectOpen && category === 'cloud_security' && cat.value === 'annex'
                                        ? -45
                                        : selectOpen && cat.value === 'cloud_security'
                                        ? -57
                                        : selectOpen && cat.value === 'project_management'
                                        ? -70
                                        : selectOpen && cat.value !== 'cybersecurity'
                                        ? -32
                                        : '-100%',
                                transition: 'left 1s',
                                zIndex:
                                    selectOpen && activeCategories?.[0] === cat.value
                                        ? 3
                                        : selectOpen && activeCategories?.[1] === cat.value
                                        ? 2
                                        : selectOpen && activeCategories?.[2] === cat.value
                                        ? 1
                                        : 'unset',
                            }}
                            onClick={() => handleCategoryChange(cat.value)}
                            key={cat.value}
                        >
                            {cat.name}
                        </Box>
                    );
                })}
            </Box>
        </Stack>
    );
};

export default CategorySelect;
