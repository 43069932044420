import React, { useState } from 'react';
import { toast } from 'react-toastify';

import AppDeleteModal from 'components/new_components/AppDeleteModal';

// redux
import { connect } from 'react-redux';
import { DeleteMerchantUser, UpdateMerchantSupportUser } from 'store/actions/merchantActions';
import { useTranslation } from 'react-i18next';

const UserDeleteModal = (props) => {
    const { open, handleClose, payload, DeleteMerchantUser, UpdateMerchantSupportUser } = props;

    const [deleting, setDeleting] = useState(false);

    //translation
    const { t } = useTranslation('user');

    const handleDelete = async () => {
        setDeleting(true);
        const res =
            payload.role === 'support'
                ? await UpdateMerchantSupportUser({
                      support_user: null,
                      support_routes: '',
                  })
                : await DeleteMerchantUser(payload.user_detail.id);
        setDeleting(false);
        if (res?.success) {
            toast.success('Successfully deleted a user.');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <AppDeleteModal
            open={open}
            handleClose={handleClose}
            title={t('deleteModal.title')}
            subtitle={t('deleteModal.subtitle')}
            deleting={deleting}
            onDelete={handleDelete}
        />
    );
};
const mapStateToProps = (state) => {
    return { ...state };
};
export default connect(mapStateToProps, {
    UpdateMerchantSupportUser,
    DeleteMerchantUser,
})(UserDeleteModal);
