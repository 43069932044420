import React, { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { CheckCircle, Error } from '@mui/icons-material';

const SubgroupChip = forwardRef((props, ref) => {
    const theme = useTheme();
    const { label, id, onClick, active, disabled, completed, showCheck, bordered, isAssigned, sx } = props;

    return (
        <Chip
            disabled={disabled}
            label={
                <>
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 700,
                            textTransform: 'capitalize',
                            color: disabled
                                ? theme.palette.gray[600]
                                : active
                                ? theme.palette.white.main
                                : theme.palette.primary[900],
                            ...sx?.typography,
                        }}
                    >
                        {label}
                        {showCheck &&
                            (completed ? (
                                <CheckCircle sx={{ color: theme.palette.success[300], ml: 0.5 }} />
                            ) : (
                                <Error sx={{ color: theme.palette.error[500], ml: 0.5 }} />
                            ))}
                    </Typography>
                    {isAssigned ? (
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                position: 'absolute',
                                right: '-1.2px',
                                top: '-4px',
                                backgroundColor: theme.palette.shades.white,
                                width: '13px',
                                height: '13px',
                                borderRadius: '50%',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    backgroundColor: theme.palette.tertiary[70],
                                }}
                            />
                        </Stack>
                    ) : null}
                </>
            }
            sx={{
                border: bordered ? `1px solid ${theme.palette.primary[900]}` : '',
                position: 'relative',
                '&, &:hover': {
                    backgroundColor: active ? theme.palette.primary[900] : theme.palette.white.main,
                },
                '&.Mui-disabled': {
                    backgroundColor: theme.palette.gray[300],
                },
                ...sx?.root,
            }}
            clickable
            onClick={() => onClick(id)}
            ref={ref}
        />
    );
});

export default SubgroupChip;
