import React, { useState, useMemo } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './pagination.css';
import { IconButton, Menu } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const CompliancePagination = (props) => {
    const { page_, onPageChange, dataLength, data, selectedSubgroup, pagesAssigned } = props;
    const pageNeighbours = 2;

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const RIGHT_PAGE = 'RIGHT';

    const range = (from, to, step = 1) => {
        let i = from;
        const range = [];

        while (i <= to) {
            range.push(i);
            i += step;
        }

        return range;
    };

    const handlePageChange = (event, page) => {
        event.preventDefault();
        onPageChange(page);
    };

    const fetchPageNumbers = useMemo(() => {
        const totalBlocks = 5;

        if (dataLength > totalBlocks) {
            const startPage = Math.max(2, page_ - pageNeighbours);
            const endPage = Math.min(dataLength - 1, page_ + pageNeighbours);
            let pages = range(startPage, endPage);
            switch (true) {
                default: {
                    pages = [...pages, RIGHT_PAGE];
                    break;
                }
            }
            return [1, ...pages, dataLength];
        }
        return range(1, dataLength);
    }, [dataLength, selectedSubgroup]);

    const pages = fetchPageNumbers || [];
    const all_pages = range(1, dataLength);
    const menu_pages = all_pages.filter((item) => !pages.includes(item));

    return (
        <nav>
            <ul className="pagination">
                <IconButton
                    sx={{ mr: 2, backgroundColor: '#F0F0FA' }}
                    onClick={(event) => handlePageChange(event, page_ - 1)}
                >
                    <KeyboardArrowLeftIcon />
                </IconButton>
                {pages?.map((page, index) => {
                    const isActive = page === page_;
                    const hasBeenAttendedTo = dataLength ? data?.[page - 1] : null;
                    if (page === RIGHT_PAGE)
                        return (
                            <li key={index} className={anchorEl ? 'active_anchor' : 'neutral'} onClick={handleClick}>
                                <MoreHorizIcon sx={{ width: '15px', height: '15px', mt: 1 }} />
                                <ArrowDropUpIcon sx={{ width: '20px', height: '20px', mt: 0.5, ml: '-5px' }} />
                            </li>
                        );

                    return (
                        <li
                            key={index}
                            className={
                                isActive
                                    ? 'active_pagination'
                                    : !isActive && pagesAssigned?.includes(page)
                                    ? 'assigned'
                                    : hasBeenAttendedTo
                                    ? 'answered'
                                    : 'not_answered'
                            }
                            onClick={(event) => handlePageChange(event, page)}
                        >
                            {page}
                        </li>
                    );
                })}

                <IconButton
                    sx={{ ml: 2, backgroundColor: '#F0F0FA' }}
                    onClick={(event) => handlePageChange(event, page_ + 1)}
                >
                    <KeyboardArrowRightIcon />
                </IconButton>
            </ul>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                elevation={10}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                sx={{
                    '& .MuiMenu-paper': {
                        p: 1.5,
                        width: '210px',
                        mt: '-1rem',
                    },
                }}
            >
                <ul className="pagination grid gap-2 grid-cols-4">
                    {menu_pages?.map((page, index) => {
                        const isActive = page === page_;
                        const hasBeenAttendedTo = dataLength ? data?.[page - 1] : null;
                        return (
                            <li
                                key={index}
                                className={
                                    isActive
                                        ? 'active_pagination'
                                        : !isActive && !hasBeenAttendedTo && pagesAssigned?.includes(page)
                                        ? 'assigned'
                                        : hasBeenAttendedTo
                                        ? 'answered'
                                        : 'not_answered'
                                }
                                onClick={(event) => {
                                    handlePageChange(event, page);
                                    handleClose();
                                }}
                            >
                                {page}
                            </li>
                        );
                    })}
                </ul>
            </Menu>
        </nav>
    );
};

export default CompliancePagination;
