import {
    ALL_MERCHANT_DOCUMENTS,
    ALL_KDPA_CATEGORY_METRICS,
    ALL_CONTROL_METRICS,
    ALL_ISOGROUP_METRICS,
    ALL_ISOGROUPTWO_METRICS,
    ALL_CONTINUITYGROUPS_METRICS,
    MERCHANT_DOCUMENT_VERSIONS,
    MERCHANT_DOCUMENT_MESSAGES,
    MERCHANT_ANSWERS,
    ANSWER_HISTORY,
    ALL_EXEMPTED_DOCUMENTS,
    ALL_EXEMPTED_FORMS,
    MERCHANT_DOCUMENTS_BY_TAG,
    ALL_CONDITIONAL_ANSWER,
    ALL_CRITERIA_SUMMARY,
    ALL_BLOCKCHAIN_EXCEPTION,
    COMPLIANCE_AI_ANSWERS_BY_SUBREQ_AND_STANDARD,
    ALL_EXEMPTED_CONTROLS,
    ALL_GDPA_CATEGORY_METRICS,
    ALL_CII_CONDITIONAL_ANSWER,
    ALL_CII_SUMMARY,
    ALL_CII_EXCEPTION,
    ALL_CII_EXCEPTIONS,
    ALL_PCIDSS4POINT0_REQUIREMENTS_METRICS,
    ALL_CATEGORY_METRICS,
    ALL_REQUIREMENTS_METRICS,
    ALL_MERCHANT_EVIDENCES,
    MERCHANT_EVIDENCE_VERSIONS,
    ALL_EVIDENCE_METRICS,
    ALL_EVIDENCE_EXCEPTIONS,
} from '../constants';

import axiosInstance from '../../utils/https';
import {
    GetAllCiiConditionalItems,
    GetAllCiiToolsItems,
    GetAllConditionalItems,
    GetAllControls,
    GetAllPcidss4Point0Requirements,
    GetAllSector,
    GetAllToolsItems,
    GetEvidencesByControl,
} from './adminActions';
import { GetTechSecMetrics } from './iso27001+iso27035Actions';
import { GetAllLifecycles, GetControlObjectiveMetrics, GetEvidencesByControlObjective } from './pci_slcActions';
import { GetAllQualityManagement, GetEvidencesByQualityManagement, GetQualityManagementMetrics } from './iso9001';
import { URLKeys } from 'utils';
import { GetEvidencesByCard4 } from './pcidssActions';

// PCIDSS

export const GetAllRequirementMetrics = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/get_summary/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_REQUIREMENTS_METRICS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// NDPR

export const GetAllCategoryMetrics = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/ndpr_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CATEGORY_METRICS, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
// K D P A
export const GetAllKdpaCategoryMetrics = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/kdpa_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_KDPA_CATEGORY_METRICS, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// G D P A
export const GetAllGdpaCategoryMetrics = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/gdpa_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_GDPA_CATEGORY_METRICS, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// S O C 2
export const GetControlMetrics = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/soc2_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CONTROL_METRICS, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// I S O 2 7 0 0 1
export const GetIsogroupMetrics = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/iso_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ISOGROUP_METRICS, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
// I S O 2 7 0 0 1 - 2
export const GetIsogroupTwoMetrics = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/iso_two_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ISOGROUPTWO_METRICS, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
//I S 0 2 2 3 0 1
export const GetContinuityGroupMetrics = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/continuity_management_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CONTINUITYGROUPS_METRICS, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// D O C U M E N T S
export const GetAllMerchantDocuments = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/user_document/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_DOCUMENTS, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAllPcidss4Point0RequirementMetrics = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/card4_summary/?merchant_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_PCIDSS4POINT0_REQUIREMENTS_METRICS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const UploadMerchantDocument = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    credentials.append('merchant_id', company_id);
    try {
        const res = await axiosInstance().post(`/compliance/user_document/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantDocuments());
        dispatch(GetAllRequirementMetrics());
        dispatch(GetAllPcidss4Point0RequirementMetrics());
        dispatch(GetAllCategoryMetrics());
        dispatch(GetControlMetrics());
        dispatch(GetControlObjectiveMetrics());
        dispatch(GetIsogroupMetrics());
        dispatch(GetTechSecMetrics());
        dispatch(GetIsogroupTwoMetrics());
        dispatch(GetCriteriaSummaries());
        dispatch(GetContinuityGroupMetrics());
        dispatch(GetAllKdpaCategoryMetrics());
        dispatch(GetAllGdpaCategoryMetrics());
        dispatch(GetCiiSectorSummaries());
        dispatch(GetAllControls());
        dispatch(GetAllQualityManagement());
        dispatch(GetQualityManagementMetrics());
        dispatch(GetAllLifecycles());
        dispatch(GetControlObjectiveMetrics());
        return {
            success: true,
            message: 'Document upload successful',
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const UpdateMerchantDocument = (credentials, id) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    // credentials.append('merchant_id', company_id);
    const body = {
        ...credentials,
        merchant_id: company_id,
    };
    try {
        const res = await axiosInstance().patch(`/compliance/user_document/${id}/`, body);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantDocuments());
        dispatch(GetAllRequirementMetrics());
        dispatch(GetAllPcidss4Point0RequirementMetrics());
        dispatch(GetAllCategoryMetrics());
        dispatch(GetControlMetrics());
        dispatch(GetControlObjectiveMetrics());
        dispatch(GetIsogroupMetrics());
        dispatch(GetTechSecMetrics());
        dispatch(GetIsogroupTwoMetrics());
        dispatch(GetCriteriaSummaries());
        dispatch(GetContinuityGroupMetrics());
        dispatch(GetAllKdpaCategoryMetrics());
        dispatch(GetAllGdpaCategoryMetrics());
        dispatch(GetCiiSectorSummaries());
        dispatch(GetAllLifecycles());
        dispatch(GetControlObjectiveMetrics());
        dispatch(GetAllCiiExceptions());
        dispatch(GetAllQualityManagement());
        dispatch(GetQualityManagementMetrics());
        dispatch(GetAllLifecycles());
        dispatch(GetControlObjectiveMetrics());
        return {
            success: true,
            message: 'Document upload successful',
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const ExemptMerchantDocument = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/compliance/documentexempted/`, {
            ...credentials,
            merchant: company_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);

        dispatch(GetAllExemptedDocuments(credentials?.standard));
        dispatch(GetAllRequirementMetrics());
        dispatch(GetAllPcidss4Point0RequirementMetrics());
        dispatch(GetAllCategoryMetrics());
        dispatch(GetAllKdpaCategoryMetrics());
        dispatch(GetTechSecMetrics());
        dispatch(GetAllGdpaCategoryMetrics());
        dispatch(GetControlMetrics());
        dispatch(GetControlObjectiveMetrics());
        dispatch(GetIsogroupMetrics());
        dispatch(GetIsogroupTwoMetrics());
        dispatch(GetAllBlockchainExceptions());
        dispatch(GetQualityManagementMetrics());
        dispatch(GetControlObjectiveMetrics());
        dispatch(GetAllCiiExceptions());
        dispatch(GetAllQualityManagement());
        dispatch(GetQualityManagementMetrics());
        dispatch(GetAllLifecycles());
        dispatch(GetControlObjectiveMetrics());
        return {
            success: true,
            message: 'Document Exempted successfully',
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantDocumentVersions = (doc_id, merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(
            `/compliance/user_document/?merchant_id=${company_id}&document_id=${doc_id}`
        );

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: MERCHANT_DOCUMENT_VERSIONS, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetCodeReviewDocuments = (tag, sub_requirement_no) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(
            `/compliance/user_document/?tag=${tag}&subcriteria=${sub_requirement_no}`
        );
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: MERCHANT_DOCUMENTS_BY_TAG,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeleteMerchantDocument = (doc_id, template_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/user_document/${doc_id}/`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantDocumentVersions(template_id));
        dispatch(GetAllMerchantDocuments());
        dispatch(GetAllRequirementMetrics());
        dispatch(GetAllPcidss4Point0RequirementMetrics());
        dispatch(GetAllCategoryMetrics());
        dispatch(GetControlMetrics());
        dispatch(GetControlObjectiveMetrics());
        dispatch(GetIsogroupMetrics());
        dispatch(GetIsogroupTwoMetrics());
        dispatch(GetContinuityGroupMetrics());
        dispatch(GetAllLifecycles());
        dispatch(GetQualityManagementMetrics());
        dispatch(GetControlObjectiveMetrics());
        dispatch(GetAllQualityManagement());
        dispatch(GetQualityManagementMetrics());
        dispatch(GetAllLifecycles());
        dispatch(GetControlObjectiveMetrics());
        return {
            success: true,
            message: 'Document Deleted successfully',
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetDocumentMessages = (doc_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/chat/messages/?topic=${doc_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: MERCHANT_DOCUMENT_MESSAGES, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const AddDocumentMessage = (credentials) => async (dispatch, getState) => {
    const merchant_id = getState().generalReducers?.user_info?.organization?.owner_detail?.id;
    const auditor_id = getState().generalReducers?.user_info?.organization?.auditor_detail?.owner_detail?.id;
    try {
        const res = await axiosInstance().post(`/chat/messages/`, {
            ...credentials,
            sender: merchant_id,
            receiver: auditor_id,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetDocumentMessages(credentials?.topic));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// F O R M S

export const GetAllMerchantAnswers = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/user_form/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: MERCHANT_ANSWERS, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAllAnswerHistory = (form_id, merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/user_form/?merchant_id=${company_id}&form_id=${form_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ANSWER_HISTORY, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const SubmitMerchantAnswers = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/compliance/user_form/`, { ...credentials, merchant_id: company_id });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantAnswers());
        dispatch(GetAllRequirementMetrics());
        dispatch(GetAllPcidss4Point0RequirementMetrics());
        dispatch(GetAllCategoryMetrics());
        dispatch(GetControlMetrics());
        dispatch(GetControlObjectiveMetrics());
        dispatch(GetIsogroupMetrics());
        dispatch(GetTechSecMetrics());
        dispatch(GetIsogroupTwoMetrics());
        dispatch(GetCriteriaSummaries());
        dispatch(GetContinuityGroupMetrics());
        dispatch(GetAllKdpaCategoryMetrics());
        dispatch(GetAllGdpaCategoryMetrics());
        dispatch(GetCiiSectorSummaries());
        dispatch(GetAllQualityManagement());
        dispatch(GetQualityManagementMetrics());
        dispatch(GetAllLifecycles());
        dispatch(GetControlObjectiveMetrics());
        return {
            success: true,
            message: 'Successfully submitted form.',
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const UpdateFormAnswersStatus = (credentials, id, merchant) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/user_form/${id}/`, {
            ...credentials,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantAnswers(merchant));
        dispatch(GetAllAnswerHistory(id, merchant));
        dispatch(GetAllRequirementMetrics());
        dispatch(GetAllPcidss4Point0RequirementMetrics());
        dispatch(GetAllCategoryMetrics());
        dispatch(GetControlMetrics());
        dispatch(GetControlObjectiveMetrics());
        dispatch(GetIsogroupMetrics());
        dispatch(GetTechSecMetrics());
        dispatch(GetIsogroupTwoMetrics());
        dispatch(GetCriteriaSummaries());
        dispatch(GetContinuityGroupMetrics());
        dispatch(GetAllKdpaCategoryMetrics());
        dispatch(GetAllGdpaCategoryMetrics());
        dispatch(GetCiiSectorSummaries());
        dispatch(GetAllQualityManagement());
        dispatch(GetQualityManagementMetrics());
        dispatch(GetAllLifecycles());
        dispatch(GetControlObjectiveMetrics());
        return {
            success: true,
            message: 'Successfully updated form status.',
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const ExemptQuestion = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/compliance/formsexempted/`, { ...credentials, merchant: company_id });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllExemptedForms(credentials?.standard));
        dispatch(GetAllRequirementMetrics());
        dispatch(GetQualityManagementMetrics());
        dispatch(GetAllPcidss4Point0RequirementMetrics());
        dispatch(GetAllCategoryMetrics());
        dispatch(GetTechSecMetrics());
        dispatch(GetControlMetrics());
        dispatch(GetControlObjectiveMetrics());
        dispatch(GetIsogroupMetrics());
        dispatch(GetIsogroupTwoMetrics());
        dispatch(GetAllKdpaCategoryMetrics());
        dispatch(GetAllGdpaCategoryMetrics());
        dispatch(GetQualityManagementMetrics());
        dispatch(GetAllLifecycles());
        dispatch(GetControlObjectiveMetrics());
        return {
            success: true,
            message: 'Question Exempted successfully',
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
// E X C E P T I O N S

export const GetAllExemptedDocuments = (standard, merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(
            `/compliance/documentexempted/?merchant=${company_id}&standard=${standard}`
        );

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_EXEMPTED_DOCUMENTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAllExemptedForms = (standard, merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/formsexempted/?merchant=${company_id}&standard=${standard}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_EXEMPTED_FORMS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const DeleteDocumentExemption = (doc_id, standard) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/documentexempted/${doc_id}/`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllExemptedDocuments(standard));
        dispatch(GetAllRequirementMetrics());
        dispatch(GetAllPcidss4Point0RequirementMetrics());
        dispatch(GetAllCategoryMetrics());
        dispatch(GetTechSecMetrics());
        dispatch(GetControlMetrics());
        dispatch(GetControlObjectiveMetrics());
        dispatch(GetIsogroupMetrics());
        dispatch(GetIsogroupTwoMetrics());
        dispatch(GetAllKdpaCategoryMetrics());
        return {
            success: true,
            message: 'Document Removed from Exception List',
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const DeleteFormExemption = (form_id, standard) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/formsexempted/${form_id}/`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);

        dispatch(GetAllExemptedForms(standard));
        dispatch(GetAllRequirementMetrics());
        dispatch(GetAllPcidss4Point0RequirementMetrics());
        dispatch(GetAllCategoryMetrics());
        dispatch(GetControlMetrics());
        dispatch(GetControlObjectiveMetrics());
        dispatch(GetTechSecMetrics());
        dispatch(GetIsogroupMetrics());
        dispatch(GetIsogroupTwoMetrics());
        dispatch(GetAllKdpaCategoryMetrics());
        return {
            success: true,
            message: 'Form Removed from Exception List',
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const SubmitConditionalForm = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/userconditional/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantDocuments());
        dispatch(GetAllRequirementMetrics());
        dispatch(GetAllPcidss4Point0RequirementMetrics());
        dispatch(GetAllCategoryMetrics());
        dispatch(GetControlMetrics());
        dispatch(GetControlObjectiveMetrics());
        dispatch(GetIsogroupMetrics());
        dispatch(GetIsogroupTwoMetrics());
        dispatch(GetCriteriaSummaries());
        dispatch(ConditionalFormAnswer());
        dispatch(GetAllKdpaCategoryMetrics());
        dispatch(CiiConditionalFormAnswer());
        return {
            success: true,
            message: 'Document upload successful',
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const SubmitCiiConditionalForm = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/cii_userconditional/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantDocuments());
        dispatch(CiiConditionalFormAnswer());
        dispatch(GetCiiSectorSummaries());
        return {
            success: true,
            message: 'Document upload successful',
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const ConditionalFormAnswer = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/userconditional/`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CONDITIONAL_ANSWER, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const CiiConditionalFormAnswer = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/cii_userconditional/`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CII_CONDITIONAL_ANSWER, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// S U M M A R Y
export const GetCriteriaSummaries = () => async (dispatch) => {
    // const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/criteria_summary/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CRITERIA_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetCiiSectorSummaries = () => async (dispatch) => {
    // const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/cii_summary/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CII_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAllBlockchainExceptions = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`compliance/block_exception/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_BLOCKCHAIN_EXCEPTION, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAllCiiExceptions = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`compliance/c_exception/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CII_EXCEPTION, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const AllCiiExceptions = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`compliance/cii_exception/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CII_EXCEPTIONS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const ExemptBlockchainCompliance = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    credentials?.append('merchant', company_id);
    try {
        const res = await axiosInstance().post(`/compliance/blockchain_exception/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllBlockchainExceptions());
        dispatch(GetCriteriaSummaries());
        dispatch(GetAllConditionalItems());
        dispatch(GetAllToolsItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const ExemptCiiCompliance = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    credentials?.append('merchant', company_id);
    try {
        const res = await axiosInstance().post(`/compliance/cii_exception/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCiiExceptions());
        dispatch(GetAllCiiToolsItems());
        dispatch(GetAllCiiConditionalItems());
        dispatch(GetCiiSectorSummaries());
        dispatch(GetAllSector());
        dispatch(AllCiiExceptions());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const RevertBlockchainCompliance = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/blockchain_exception/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllBlockchainExceptions());
        return {
            success: true,
            message: 'Reverted Successfully',
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const RevertCiiCompliance = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/cii_exception/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCiiExceptions());
        dispatch(GetAllSector());
        dispatch(AllCiiExceptions());
        return {
            success: true,
            message: 'Reverted Successfully',
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const UploadConditionalDocument = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    credentials.append('merchant_id', company_id);
    try {
        const res = await axiosInstance().post(`/compliance/user_document/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantDocuments());
        return {
            success: true,
            message: 'Document upload successful',
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//Exempted subcontrols
export const GetAllExemptedControls = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/module_exempted/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_EXEMPTED_CONTROLS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const ExemptControl = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/compliance/module_exempted/`, {
            ...credentials,
            merchant: company_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllExemptedControls());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const DeleteFromExemptedControlList = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/module_exempted/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllExemptedControls());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//compliance ai
export const GetComplianceAIAnswersBySubReqAndStandard = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(
            `/compliance/compliance_ai/?standard=${credentials?.standard}&req_sub_no=${credentials?.subreq}&merchant=${company_id}`
        );
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        const previousPayload = getState()?.complianceReducers?.compliance_ai_answers_by_subreq_and_standard;
        const newPayload = {
            ...previousPayload,
            [credentials?.subreq]: res?.data?.[0]?.answers,
        };
        dispatch({ type: COMPLIANCE_AI_ANSWERS_BY_SUBREQ_AND_STANDARD, payload: newPayload });
        return {
            success: true,
            message: 'successful',
            data: newPayload,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//  Merchant Evidence

export const GetAllMerchantEvidences = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/user_evidence/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_EVIDENCES, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantEvidenceVersions = (doc_id) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(
            `/compliance/user_evidence/?merchant_id=${company_id}&evidence_id=${doc_id}`
        );

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: MERCHANT_EVIDENCE_VERSIONS, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const UploadMerchantEvidence = (credentials, control_id, standard) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    // credentials.append('merchant_id', company_id);

    try {
        const res = await axiosInstance().post(`/compliance/user_evidence/`, {
            ...credentials,
            merchant_id: company_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        if (standard === 'soc2') {
            dispatch(GetEvidencesByControl(control_id));
        } else if (standard === 'sslc') {
            dispatch(GetEvidencesByControlObjective(control_id));
        }
        dispatch(GetAllMerchantEvidences());
        dispatch(GetEvidenceMetrics(standard));
        return {
            success: true,
            message: 'Document upload successful',
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const UpdateMerchantEvidence = (credentials, control_id, id, standard) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    // credentials.append('merchant_id', company_id);

    try {
        const res = await axiosInstance().patch(`/compliance/user_evidence/${id}/`, {
            ...credentials,
            merchant_id: company_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        if (standard === 'soc2') {
            dispatch(GetEvidencesByControl(control_id));
        } else if (standard === 'sslc') {
            dispatch(GetEvidencesByControlObjective(control_id));
        }
        dispatch(GetAllMerchantEvidences());
        dispatch(GetEvidenceMetrics(standard));
        return {
            success: true,
            message: 'Document upload successful',
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const DeleteMerchantEvidence = (doc_id, evidence_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/user_evidence/${doc_id}/`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantEvidenceVersions(evidence_id));
        dispatch(GetAllMerchantEvidences());
        dispatch(GetEvidenceMetrics('soc2'));
        return {
            success: true,
            message: 'Document Deleted successfully',
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetEvidenceMetrics = (tag) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(
            `/compliance/evidence_summary/?merchant_id=${company_id}&standard=${tag}`
        );

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_EVIDENCE_METRICS, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// Reminder
export const AddReminder = (credentials) => async (_, getState) => {
    const user_id = getState().authReducers?.user_id;
    const body = {
        ...credentials,
        user: user_id,
    };
    try {
        const res = await axiosInstance().post(`/compliance/reminder/`, body);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: 'Reminder added successfully',
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const RemoveReminder = (id) => async () => {
    try {
        const res = await axiosInstance().delete(`/compliance/reminder/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: 'Document upload successful',
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetEvidenceExeption = (standard, merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;

    try {
        const res = await axiosInstance().get(
            `/compliance/evidence_exempted/?merchant=${company_id}&standard=${standard}`
        );
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_EVIDENCE_EXCEPTIONS, payload: res?.data });
        return {
            success: true,
            message: 'Document upload successful',
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const EvidenceExeption = (credentials, control_id, standard) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    credentials.append('merchant', company_id);

    try {
        const res = await axiosInstance().post(`/compliance/evidence_exempted/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        if (standard === 'sslc') {
            dispatch(GetEvidencesByControlObjective(control_id));
        } else if (standard === 'card4') {
            dispatch(GetAllPcidss4Point0Requirements());
            dispatch(GetEvidencesByCard4(control_id));
            dispatch(GetEvidenceExeption(standard));
        } else {
            dispatch(GetEvidencesByQualityManagement(control_id));
        }
        dispatch(GetAllMerchantEvidences());
        dispatch(GetEvidenceMetrics(standard));
        return {
            success: true,
            message: 'Document upload successful',
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const DeleteEvidenceExemption = (evidence_id, standard) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/evidence_exempted/${evidence_id}/`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);

        dispatch(GetEvidenceExeption(standard));
        dispatch(GetAllLifecycles());
        dispatch(GetAllQualityManagement());
        dispatch(DeleteEvidenceExemption());
        return {
            success: true,
            message: 'Form Removed from Exception List',
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetDocumentLink = (type, id) => async () => {
    const getFirstValidUrl = (res) => {
        return URLKeys.map((key) => res?.urls?.[0]?.[key]).find(Boolean);
    };

    try {
        const res = await axiosInstance().get(`/compliance/${type}/${id}/download/`);
        const url = getFirstValidUrl(res);

        return {
            success: true,
            message: 'Form Removed from Exception List',
            data: url,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
