import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
    Button,
    Typography,
    Card,
    Row,
    Col,
    Input,
    Select,
    TimePicker,
    DatePicker,
    Space,
    Spin,
    Empty,
    Modal,
    Tooltip,
} from 'antd';
import {
    PlusOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    ArrowLeftOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import FieldModal from './FieldModal';
// redux
import { connect } from 'react-redux';
import {
    CreateForm,
    EditForm,
    GetAllFormFields,
    CreateFormField,
    EditFormField,
    DeleteFormField,
} from 'store/actions/adminActions';
import { useHistory } from 'react-router-dom';

// sub components
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

const FormCreation = (props) => {
    const history = useHistory();
    const {
        location: { state = {} },
        CreateForm,
        EditForm,
        GetAllFormFields,
        all_form_fields = [],
        CreateFormField,
        EditFormField,
        DeleteFormField,
        tag,
    } = props;
    const { mode, form, titleLabel, groupIdLabel, payload } = state;

    // states
    const [formData, setFormData] = useState({});
    const [modal, setModal] = useState(false);
    const [modalMode, setModalMode] = useState('');
    const [one_field, setOneField] = useState({});
    const [loading, setLoading] = useState({ form: false, content: false });
    const [title, setTitle] = useState('');

    //functions;

    const openModal = (mode, data) => {
        setModal(true);
        setModalMode(mode);
        setOneField(data);
    };
    const closeModal = () => {
        setModal(false);
    };
    const getPayload = () => {
        return {
            ...payload,
            [titleLabel]: title,
            tags: tag,
        };
    };
    const getGroupId = () => {
        return payload?.[groupIdLabel];
    };
    const handleFormTitle = () => {
        setTitle(formData?.[titleLabel]);
    };

    const createForm = async () => {
        if (!title) {
            return toast.info('Please enter a title for the form.');
        }
        const payload = getPayload();
        setLoading({ ...loading, form: true });
        const res = await CreateForm(payload, getGroupId());
        setLoading({ ...loading, form: false });

        if (res.success) {
            toast.success(res?.message);
            setFormData(res.form);
        } else {
            toast.error(res.message);
        }
    };
    const editForm = async () => {
        const payload = getPayload();
        setLoading({ ...loading, form: true });
        const res = await EditForm(formData?.id, payload, tag, getGroupId());
        setLoading({ ...loading, form: false });

        if (res.success) {
            setFormData(res.form);
            toast.success(res?.message);
        } else {
            toast.error(res.message);
        }
    };
    const deleteFormField = (field) => {
        confirm({
            title: 'Are you sure you want to delete this form? Note: Deleting this form means you will be deleting all questions and merchant responses associated with this form',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteFormField(field?.id, field?.form);
                if (res.success) {
                    setFormData(res.form);
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };
    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };
    const getAllFormFields = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAllFormFields(form?.id);
        setLoading({ ...loading, content: false });
        if (!res.success) {
            toast.error(res?.message);
        }
    };
    // use effect

    useEffect(() => {
        handleFormTitle();
    }, [formData]);
    useEffect(() => {
        if (form?.id) {
            getAllFormFields();
        }
    }, [form]);
    useEffect(() => {
        if (all_form_fields?.length) {
            setFormData({
                ...formData,
                ...form,
                form_fields: all_form_fields?.filter((field) => field?.form === formData?.id),
            });
        }
    }, [all_form_fields, form, loading?.content]);
    return (
        <>
            <Row justify="space-between">
                <Col className="flex justify-start items-center space-x-3">
                    <button onClick={() => history.goBack()}>
                        <ArrowLeftOutlined />
                    </button>
                    <Title level={2} className="mb-0">
                        {mode === 'create' ? 'Create Form' : 'Edit Form'}
                    </Title>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => openModal('add')}
                        disabled={!formData?.id}
                    >
                        Add Field
                    </Button>
                </Col>
            </Row>
            <Row style={{ margin: '1rem 0' }}>
                <Col xs={20}>
                    <Input
                        placeholder="Form Title"
                        size="large"
                        value={formData?.iso_title || formData?.iso_two_title || title}
                        onChange={handleTitleChange}
                    />
                </Col>
                <Col xs={4}>
                    {formData?.id ? (
                        <Button type="primary" size="large" onClick={editForm} loading={loading?.form}>
                            Edit Title
                        </Button>
                    ) : (
                        <Button type="primary" size="large" onClick={createForm} loading={loading?.form}>
                            Create Form
                        </Button>
                    )}
                </Col>
            </Row>
            <section className="my-3">
                {loading?.content ? (
                    <Row justify="center" align="center" className="h-full">
                        <Spin size="large" />
                    </Row>
                ) : formData?.form_fields && formData?.form_fields?.length > 0 ? (
                    <Row gutter={16}>
                        {formData?.form_fields
                            ?.sort((a, b) => a?.id - b?.id)
                            ?.map((field) => {
                                return (
                                    <Col xs={24} md={12} style={{ marginBottom: '1rem' }} key={field.name}>
                                        <Card
                                            extra={
                                                <Space>
                                                    <Tooltip title="Edit this field" color="blue">
                                                        <Button
                                                            shape="circle"
                                                            icon={<EditOutlined />}
                                                            onClick={() => openModal('edit', field)}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Delete field" color="red">
                                                        <Button
                                                            shape="circle"
                                                            danger
                                                            icon={<DeleteOutlined />}
                                                            onClick={() => deleteFormField(field)}
                                                        />
                                                    </Tooltip>
                                                </Space>
                                            }
                                        >
                                            <div className="form-group" style={{ marginBottom: '1rem' }}>
                                                <label>
                                                    {field?.name}
                                                    {field?.required && <span style={{ color: 'red' }}>*</span>}
                                                </label>
                                                {field.type === 'text' ? (
                                                    <Input type="text" size="large" />
                                                ) : field.type === 'number' ? (
                                                    <Input type="number" size="large" required={field?.required} />
                                                ) : field?.type === 'dropdown' ? (
                                                    <Select
                                                        size="large"
                                                        style={{ width: '100%' }}
                                                        required={field?.required}
                                                    >
                                                        {field?.options?.split(',')?.map((option, index) => (
                                                            <Option value={option} key={index}>
                                                                {option}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                ) : field?.type === 'time' ? (
                                                    <TimePicker
                                                        defaultValue={moment('00:00:00', 'HH:mm:ss')}
                                                        size="large"
                                                        style={{ width: '100%' }}
                                                        required={field?.required}
                                                    />
                                                ) : field?.type === 'date' ? (
                                                    <DatePicker
                                                        defaultValue={moment('00:00:00', 'HH:mm:ss')}
                                                        size="large"
                                                        style={{ width: '100%' }}
                                                        required={field?.required}
                                                    />
                                                ) : (
                                                    <TextArea rows={4} size="large" required={field?.required} />
                                                )}
                                            </div>
                                        </Card>
                                    </Col>
                                );
                            })}
                    </Row>
                ) : (
                    <Row justify="center">
                        <Col>
                            <Empty
                                description={
                                    <div>
                                        <h3>No Fields</h3>
                                        <span>Add a new field </span>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                )}
            </section>
            <FieldModal
                open={modal}
                closeModal={closeModal}
                formData={formData}
                mode={modalMode}
                one_field={one_field}
                CreateFormField={CreateFormField}
                EditFormField={EditFormField}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return { all_form_fields: state?.adminReducers?.all_form_fields };
};
export default connect(mapStateToProps, {
    CreateForm,
    EditForm,
    GetAllFormFields,
    CreateFormField,
    EditFormField,
    DeleteFormField,
})(FormCreation);
