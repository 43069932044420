import React from 'react';
import CheckGreen from '../../../assets/img/dashboard/CheckGreen.svg';
import BaseProgressBar from './BaseProgressBar';
import { RiScan2Line } from 'react-icons/ri';
import BaseProgressCircle from './BaseProgressCircle';

//translation
import { useTranslation } from 'react-i18next';

export const CardContainer = ({ cardStyle = '', children }) => {
    return <div className={`${cardStyle} _shadow_2 transition`}>{children}</div>;
};

export const LabelTag = ({ labelStyle = '', label, type }) => {
    const bgStyle = type === 'primary' ? 'bg-[#058F5D]/20 text-[#058F5D]' : 'bg-[#F90404]/20 text-[#F90404]';
    return (
        <span className={`${labelStyle} w-fit ${bgStyle} rounded-[4px] px-2 p-1 text-[8px] font-medium`}>{label}</span>
    );
};

export const RectangleBox = ({ boxStyle = '', children }) => {
    // p-4 px-5
    return (
        <div className={`${boxStyle} transition w-full rounded-[14px] flex items-center transition bg-white shadow-md`}>
            {children}
        </div>
    );
};

export const ComplianceCardReport = ({ hasIcon = false, title, subTitle, percentage }) => {
    //translation
    const { t } = useTranslation('overview');

    return (
        <div className="w-full bg-white compliance_shadow px-3 py-2 h-[150px] transition">
            <div>
                <h3 className="textbrandColor font-bold lg:text-[18px] text-[11px] pt-2">{title}</h3>
                <div className="flex items-center transition">
                    <h4 className="lg:text-[10px] text-[9px] font-medium text-black pr-2">{subTitle}</h4>
                    {hasIcon && <img src={CheckGreen} alt="CheckGreen" className="lg:w-[14px] w-[10px]" />}
                </div>
                <BaseProgressBar text={`${percentage}% ${t('completion')}`} percentage={percentage} />
            </div>
        </div>
    );
};

export const ComplianceCardReportProgress = ({ cardStyle = '', children }) => {
    return (
        <div className={`${cardStyle} w-full bg-white compliance_shadow px-3 py-2 h-[150px] transition`}>
            <div className="transition">{children}</div>
        </div>
    );
};

export const PenetrationResult = ({ label, testName }) => {
    return (
        <div className="h-[147px]">
            <div className="flex items-center">
                <LabelTag label={label} />
                <span className="text-[#5E5E62] text-[11px] pl-2">12th January - 12th June, 2022</span>
            </div>
            <div className="bg-[#F2F0F4] mt-3 rounded-md px-3 py-3">
                <div className="flex items-center">
                    <div className="w-[50px] h-[50px] border border-gray-400">
                        <BaseProgressCircle />
                    </div>
                    <div className="ml-4">
                        <h3 className="text-[#919094] text-[11px] font-medium">12th July, 2022</h3>
                        <h2 className="text-[#0E2C66] text-[12px] font-bold">{testName}</h2>
                        <div className="flex items-center">
                            <RiScan2Line />
                            <h5 className="mb-0 text-[#77777A] text-[12px] pl-2">July - December</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const TaskCheck = ({ icon, name }) => {
    return (
        <div className="setup_card">
            <div className="border-r border-[#C7C6CA] px-3 py-2">
                <img src={icon} alt="CheckDone" />
            </div>
            <div className="textbrandColor font-bold px-2 pl-2 text-[14px]">{name}</div>
        </div>
    );
};
