import * as types from '../constants';
import axiosInstance from '../../utils/https';

// ARTICLES
export const GetAllArticleHeader = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/user_guide_article_header`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.ALL_ARTICLE_HEADER, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllArticles = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/user_guide_article/?header_id=${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.ALL_ARTICLES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        dispatch({ type: types.ALL_ARTICLES, payload: [] });
        return { success: false, message: err.message };
    }
};
export const GetAllMerchantArticles = () => async (dispatch) => {
    try {
        dispatch({ type: types.ARTICLES_REQUEST });
        const res = await axiosInstance().get(`/user_mgt/user_guide_article/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.ALL_MERCHANT_ARTICLES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetOneArticle = (id) => async (dispatch) => {
    try {
        dispatch({ type: types.ONE_ARTICLE_REQUEST });
        const res = await axiosInstance().get(`/user_mgt/user_guide_article/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.ONE_ARTICLE, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateArticleHeader = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/user_guide_article_header/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllArticleHeader());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateArticle = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/user_guide_article/`, { ...credentials, header: id });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllArticles(id));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditArticleHeader = (id, credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/user_guide_article_header/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllArticleHeader());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditArticle = (id, credentials, header_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/user_guide_article/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllArticles(header_id));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteArticleHeader = (id, header_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/user_guide_article_header/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllArticleHeader(header_id));
        return {
            success: true,
            message: 'Deleted Successful.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteArticle = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/user_guide_article/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllArticles());
        return {
            success: true,
            message: 'Deleted Successful.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UserguideHelpful = (credentials) => async (dispatch, getState) => {
    const merchant_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/user_mgt/user_guide_article_helpful/`, {
            ...credentials,
            merchant_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantArticles());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetArticleFeedback = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/user_guide_article_helpful/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.ONE_ARTICLE_HELPFUL, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
