export const initialValues = {
    asset_type: '',
    asset: '',
    asset_: '',
    threat: '',
    vulnerabilities: '',
    inherent_likelihood: '',
    inherent_impact: '',
    risk_owner: '',
    threat_description: '',
    existing_controls: '',
    control_comments: '',
    recommended_controls: '',
    timeline: '',
    responsibility: '',
    control_requirement: '',
    existing_document: '',
    risk_status: '',
    residual_likelihood: '',
    residual_impact: '',
    comments: '',
    id: '',
    matrix: '',
};
export const reportInitialValues = {
    threat: '',
    threat_description: '',
    risk_source: '',
    asset_type: '',
    asset: '',
    risk_owner: '',
    date_detected: '',
    inherent_likelihood: '',
    inherent_impact: '',
    existing_controls: '',
    comments: '',
    residual_likelihood: '',
    residual_impact: '',
    risk_status: '',
};
export const steps = ['Risk Description', 'Pre-Treatment', 'Treatment', 'Post - Treatment'];
export const stepsFr = ['Description du Risque', 'Avant-Traitement', 'Traitement', 'Après-Traitement'];

export const reportSteps = ['Threat Identification', 'Existing Control'];
export const reportStepsFr = ['Identification des Menaces', 'Contrôle Existantl'];

export const StatusOption = [
    { name: 'All', value: 'All' },
    { name: 'Active', value: 'active' },
    { name: 'Occured', value: 'occured' },
    { name: 'Closed', value: 'closed' },
];
export const StatusOptionFr = [
    { name: 'Tous', value: 'All' },
    { name: 'Actif', value: 'active' },
    { name: 'Survenu', value: 'occured' },
    { name: 'Fermé', value: 'closed' },
];

export const StatusOption_ = [
    { name: 'All', value: 'All' },
    { name: 'Active', value: 'active' },
    { name: 'Occured', value: 'occured' },
    { name: 'Closed', value: 'closed' },
];

export const RiskOwnerOption = [
    { name: 'Active', value: 'Active' },
    { name: 'Inactive', value: 'Inactive' },
];
export const RiskOwnerOptionFr = [
    { name: 'Active', value: 'Active' },
    { name: 'Inactive', value: 'Inactive' },
];

export const RiskMatrixsOption = [
    { name: 'All', value: 'All' },
    { name: '3X3', value: '3x3' },
    { name: '5X5', value: '5x5' },
];
export const RiskMatrixsOptionFr = [
    { name: 'Tous', value: 'All' },
    { name: '3X3', value: '3x3' },
    { name: '5X5', value: '5x5' },
];

export const ReportTypeOption = [
    { name: 'All', value: 'All' },
    { name: 'Cbn Report', value: 'cbn_report' },
    { name: 'Smart Comply Report', value: 'smartcomplyapp_report' },
];
export const ReportTypeOptionFr = [
    { name: 'Tous', value: 'All' },
    { name: 'Rapport CBN', value: 'cbn_report' },
    { name: 'Rapport Smart Comply', value: 'smartcomplyapp_report' },
];

export const SpoolOption = [
    { name: 'CBN report', value: 'CBN report' },
    { name: 'General report', value: 'General report' },
];
export const SpoolOptionFr = [
    { name: 'Rapport CBN', value: 'CBN report' },
    { name: 'Rapport Général', value: 'General report' },
];

export const stepQuestionList_assessement = [
    { id: 1, name: 'Risk identification' },
    { id: 2, name: 'Existing control' },
    { id: 3, name: 'Recovery measures' },
    { id: 4, name: 'Post-risk treatment' },
];
export const stepQuestionList_assessementFr = [
    { id: 1, name: 'Identification des risques' },
    { id: 2, name: 'Contrôle existant' },
    { id: 3, name: 'Mesures de récupération' },
    { id: 4, name: 'Traitement post-risque' },
];

export const stepQuestionList_report = [
    { id: 1, name: 'Threat identification' },
    { id: 2, name: 'Existing control' },
];
export const stepQuestionList_reportFr = [
    { id: 1, name: 'Identification des menaces' },
    { id: 2, name: 'Contrôle existant' },
];

export const AssetTypesOption = [
    { name: 'All', value: 'All' },
    { name: 'Infrastructures', value: 'infrastructure' },
    { name: 'Employee’s computer', value: 'employee_computer' },
    { name: 'Appliances', value: 'appliances' },
    { name: 'Softwares', value: 'softwares' },
    { name: 'Digital', value: 'digital' },
    { name: 'Hardcopy', value: 'hardcopy' },
    { name: 'Service provider', value: 'service_provider' },
    { name: 'People', value: 'people"' },
    { name: 'Utilities', value: 'utilities' },
];
export const AssetTypesOptionFr = [
    { name: 'Tous', value: 'All' },
    { name: 'Infrastructures', value: 'infrastructure' },
    { name: `Ordinateur de l'employé`, value: 'employee_computer' },
    { name: 'Appareils', value: 'appliances' },
    { name: 'Logiciels', value: 'softwares' },
    { name: 'Numérique', value: 'digital' },
    { name: 'Copie papier', value: 'hardcopy' },
    { name: 'Fournisseur de services', value: 'service_provider' },
    { name: 'Personnes', value: 'people' },
    { name: 'Utilitaires', value: 'utilities' },
];

export const threatOptions = [
    { name: 'Access to the network by unauthorized persons', value: 'Access to the network by unauthorized persons' },
    { name: 'Bomb attack', value: 'Bomb attack' },
    { name: 'Breach of contractual relations', value: 'Breach of contractual relations' },
    { name: 'Breach of legislation', value: 'Breach of legislation' },
    { name: 'Compromising confidential information', value: 'Compromising confidential information' },
    { name: 'Concealing user identity', value: 'Concealing user identity' },
    { name: 'Damage caused by a third party', value: 'Damage caused by a third party' },
    { name: 'Damages resulting from penetration testing', value: 'Damages resulting from penetration testing' },
    { name: 'Destruction of records', value: 'Destruction of records' },
    { name: 'Disaster (human caused)', value: 'Disaster (human caused)' },
    { name: 'Disaster (natural)', value: 'Disaster (natural)' },
    { name: 'Disclosure of information', value: 'Disclosure of information' },
    { name: 'Disclosure of passwords', value: 'Disclosure of passwords' },
    { name: 'Eavesdropping', value: 'Eavesdropping' },
    { name: 'Embezzlement', value: 'Embezzlement' },
    { name: 'Errors in maintenance', value: 'Errors in maintenance' },
    { name: 'Failure of communication links', value: 'Failure of communication links' },
    { name: 'Falsification of records', value: 'Falsification of records' },
    { name: 'Fire', value: 'Fire' },
    { name: 'Flood', value: 'Flood' },
    { name: 'Fraud', value: 'Fraud' },
    { name: 'Industrial espionage', value: 'Industrial espionage' },
    { name: 'Information leakage', value: 'Information leakage' },
    { name: 'Interruption of business processes', value: 'Interruption of business processes' },
    { name: 'Loss of electricity', value: 'Loss of electricity' },
    { name: 'Loss of support services', value: 'Loss of support services' },
    { name: 'Malfunction of equipment', value: 'Malfunction of equipment' },
    { name: 'Malicious code', value: 'Malicious code' },
    { name: 'Misuse of information systems', value: 'Misuse of information systems' },
    { name: 'Misuse of audit tools', value: 'Misuse of audit tools' },
    { name: 'Pollution', value: 'Pollution' },
    { name: 'Social engineering', value: 'Social engineering' },
    { name: 'Software errors', value: 'Software errors' },
    { name: 'Strike', value: 'Strike' },
    { name: 'Terrorist attacks', value: 'Terrorist attacks' },
    { name: 'Theft', value: 'Theft' },
    { name: 'Thunderstroke', value: 'Thunderstroke' },
    {
        name: 'Unintentional change of data in an information system',
        value: 'Unintentional change of data in an information system',
    },
    { name: 'Unauthorized access to the information system', value: 'Unauthorized access to the information system' },
    { name: 'Unauthorized changes of records', value: 'Unauthorized changes of records' },
    { name: 'Unauthorized installation of software', value: 'Unauthorized installation of software' },
    { name: 'Unauthorized physical access', value: 'Unauthorized physical access' },
    { name: 'Unauthorized use of copyright material', value: 'Unauthorized use of copyright material' },
    { name: 'Unauthorized use of software', value: 'Unauthorized use of software' },
    { name: 'User error', value: 'User error' },
    { name: 'Vandalism', value: 'Vandalism' },
];
export const threatOptionsFr = [
    {
        name: 'Accès au réseau par des personnes non autorisées',
        value: 'Access to the network by unauthorized persons',
    },
    { name: 'Attaque à la bombe', value: 'Bomb attack' },
    { name: 'Violation des relations contractuelles', value: 'Breach of contractual relations' },
    { name: 'Violation de la législation', value: 'Breach of legislation' },
    { name: `Compromission d'informations confidentielles`, value: 'Compromising confidential information' },
    { name: `Masquage de l'identité de l'utilisateur`, value: 'Concealing user identity' },
    { name: 'Dommages causés par un tiers', value: 'Damage caused by a third party' },
    { name: 'Dommages résultant de tests de pénétration', value: 'Damages resulting from penetration testing' },
    { name: 'Destruction de dossiers', value: 'Destruction of records' },
    { name: `Catastrophe (causée par l'homme)`, value: 'Disaster (human caused)' },
    { name: 'Catastrophe (naturelle)', value: 'Disaster (natural)' },
    { name: `Divulgation d'informations`, value: 'Disclosure of information' },
    { name: 'Divulgation de mots de passe', value: 'Disclosure of passwords' },
    { name: 'Écoute clandestine', value: 'Eavesdropping' },
    { name: 'Détournement de fonds', value: 'Embezzlement' },
    { name: 'Erreurs de maintenance', value: 'Errors in maintenance' },
    { name: 'Défaillance des liaisons de communication', value: 'Failure of communication links' },
    { name: 'Falsification de dossiers', value: 'Falsification of records' },
    { name: 'Incendie', value: 'Fire' },
    { name: 'Inondation', value: 'Flood' },
    { name: 'Fraude', value: 'Fraud' },
    { name: 'Espionnage industriel', value: 'Industrial espionage' },
    { name: `Fuite d'informations`, value: 'Information leakage' },
    { name: 'Interruption des processus métier', value: 'Interruption of business processes' },
    { name: `Perte d'électricité`, value: 'Loss of electricity' },
    { name: 'Perte de services de support', value: 'Loss of support services' },
    { name: `Dysfonctionnement de l'équipement`, value: 'Malfunction of equipment' },
    { name: 'Code malveillant', value: 'Malicious code' },
    { name: `Mauvaise utilisation des systèmes d'information`, value: 'Misuse of information systems' },
    { name: `Mauvaise utilisation des outils d'audit`, value: 'Misuse of audit tools' },
    { name: 'Pollution', value: 'Pollution' },
    { name: 'Ingénierie sociale', value: 'Social engineering' },
    { name: 'Erreurs logicielles', value: 'Software errors' },
    { name: 'Grève', value: 'Strike' },
    { name: 'Attaques terroristes', value: 'Terrorist attacks' },
    { name: 'Vol', value: 'Theft' },
    { name: 'Foudre', value: 'Thunderstroke' },
    {
        name: `Modification involontaire des données dans un système d'information`,
        value: 'Unintentional change of data in an information system',
    },
    { name: `Accès non autorisé au système d'information`, value: 'Unauthorized access to the information system' },
    { name: 'Modifications non autorisées des dossiers', value: 'Unauthorized changes of records' },
    { name: 'Installation non autorisée de logiciels', value: 'Unauthorized installation of software' },
    { name: 'Accès physique non autorisé', value: 'Unauthorized physical access' },
    {
        name: "Utilisation non autorisée de matériel protégé par le droit d'auteur",
        value: 'Unauthorized use of copyright material',
    },
    { name: 'Utilisation non autorisée de logiciels', value: 'Unauthorized use of software' },
    { name: `Erreur de l'utilisateur`, value: 'User error' },
    { name: 'Vandalisme', value: 'Vandalism' },
];

export const vulnerablilityOptions = [
    { name: 'Complicated user interface', value: 'Complicated user interface' },
    { name: 'Default passwords not changed', value: 'Default passwords not changed' },
    {
        name: 'Disposal of storage media without deleting data',
        value: 'Disposal of storage media without deleting data',
    },
    { name: 'Equipment sensitivity to changes in voltage', value: 'Equipment sensitivity to changes in voltage' },
    {
        name: 'Equipment sensitivity to moisture and contaminants',
        value: 'Equipment sensitivity to moisture and contaminants',
    },
    { name: 'Equipment sensitivity to temperature', value: 'Equipment sensitivity to temperature' },
    { name: 'Inadequate cabling security', value: 'Inadequate cabling security' },
    { name: 'Inadequate capacity management', value: 'Inadequate capacity management' },
    { name: 'Inadequate change management', value: 'Inadequate change management' },
    { name: 'Inadequate classification of information', value: 'Inadequate classification of information' },
    { name: 'Inadequate control of physical access', value: 'Inadequate control of physical access' },
    { name: 'Inadequate maintenance', value: 'Inadequate maintenance' },
    { name: 'Inadequate network management', value: 'Inadequate network management' },
    { name: 'Inadequate or irregular backup', value: 'Inadequate or irregular backup' },
    { name: 'Inadequate password management', value: 'Inadequate password management' },
    { name: 'Inadequate physical protection', value: 'Inadequate physical protection' },
    { name: 'Inadequate protection of cryptographic keys', value: 'Inadequate protection of cryptographic keys' },
    { name: 'Inadequate replacement of older equipment', value: 'Inadequate replacement of older equipment' },
    { name: 'Inadequate security awareness', value: 'Inadequate security awareness' },
    { name: 'Inadequate segregation of duties', value: 'Inadequate segregation of duties' },
    {
        name: 'Inadequate segregation of operational and testing facilities',
        value: 'Inadequate segregation of operational and testing facilities',
    },
    { name: 'Industrial espionage', value: 'Industrial espionage' },
    { name: 'Information leakage', value: 'Information leakage' },
    { name: 'Interruption of business processes', value: 'Interruption of business processes' },
    { name: 'Inadequate supervision of employees', value: 'Inadequate supervision of employees' },
    { name: 'Inadequate supervision of vendors', value: 'Inadequate supervision of vendors' },
    { name: 'Inadequate training of employees', value: 'Inadequate training of employees' },
    {
        name: 'Incomplete specification for software development',
        value: 'Incomplete specification for software development',
    },
    { name: 'Insufficient software testing', value: 'Insufficient software testing' },
    { name: 'Lack of access control policy', value: 'Lack of access control policy' },
    { name: 'Lack of clean desk and clear screen policy', value: 'Lack of clean desk and clear screen policy' },
    { name: 'Lack of control over the input and output data', value: 'Lack of control over the input and output data' },
    { name: 'Lack of internal documentation', value: 'Lack of internal documentation' },
    {
        name: 'Lack of or poor implementation of internal audit',
        value: 'Lack of or poor implementation of internal audit',
    },
    { name: 'Lack of policy for the use of cryptography', value: 'Lack of policy for the use of cryptography' },
    {
        name: 'Lack of procedure for removing access rights upon termination of employment',
        value: 'Lack of procedure for removing access rights upon termination of employment',
    },
    { name: 'Lack of protection for mobile equipment', value: 'Lack of protection for mobile equipment' },
    {
        name: 'Lack of redundancy',
        value: 'Lack of redundancy',
    },
    {
        name: 'Lack of systems for identification and authentication',
        value: 'Lack of systems for identification and authentication',
    },
    { name: 'Lack of validation of the processed data', value: 'Lack of validation of the processed data' },
    { name: 'Location vulnerable to flooding', value: 'Location vulnerable to flooding' },
    { name: 'Poor selection of test data', value: 'Poor selection of test data' },
    { name: 'Single copy', value: 'Single copy' },
    { name: 'Too much power in one person', value: 'Too much power in one person' },
    { name: 'Uncontrolled copying of data', value: 'Uncontrolled copying of data' },
    { name: 'Uncontrolled download from the Internet', value: 'Uncontrolled download from the Internet' },
    { name: 'Uncontrolled use of information systems', value: 'Uncontrolled use of information systems' },
    { name: 'Undocumented software', value: 'Undocumented software' },
    { name: 'Unmotivated employees', value: 'Unmotivated employees' },
    { name: 'Unprotected public network connections', value: 'Unprotected public network connections' },
    { name: 'User rights are not reviewed regularly', value: 'User rights are not reviewed regularly' },
];
export const vulnerablilityOptionsFr = [
    { name: 'Interface utilisateur compliquée', value: 'Complicated user interface' },
    { name: 'Mots de passe par défaut non modifiés', value: 'Default passwords not changed' },
    {
        name: 'Élimination des supports de stockage sans suppression des données',
        value: 'Disposal of storage media without deleting data',
    },
    {
        name: `Sensibilité de l'équipement aux variations de tension`,
        value: 'Equipment sensitivity to changes in voltage',
    },
    {
        name: `Sensibilité de l'équipement à l'humidité et aux contaminants`,
        value: 'Equipment sensitivity to moisture and contaminants',
    },
    { name: `Sensibilité de l'équipement à la température`, value: 'Equipment sensitivity to temperature' },
    { name: 'Sécurité du câblage insuffisante', value: 'Inadequate cabling security' },
    { name: 'Gestion de la capacité insuffisante', value: 'Inadequate capacity management' },
    { name: 'Gestion des changements insuffisante', value: 'Inadequate change management' },
    { name: `Classification insuffisante de l'information`, value: 'Inadequate classification of information' },
    { name: `Contrôle insuffisant de l'accès physique`, value: 'Inadequate control of physical access' },
    { name: 'Maintenance insuffisante', value: 'Inadequate maintenance' },
    { name: 'Gestion réseau insuffisante', value: 'Inadequate network management' },
    { name: 'Sauvegarde insuffisante ou irrégulière', value: 'Inadequate or irregular backup' },
    { name: 'Gestion des mots de passe insuffisante', value: 'Inadequate password management' },
    { name: 'Protection physique insuffisante', value: 'Inadequate physical protection' },
    { name: 'Protection insuffisante des clés cryptographiques', value: 'Inadequate protection of cryptographic keys' },
    {
        name: `Remplacement insuffisant de l'équipement plus ancien`,
        value: 'Inadequate replacement of older equipment',
    },
    { name: 'Sensibilisation à la sécurité insuffisante', value: 'Inadequate security awareness' },
    { name: 'Séparation insuffisante des tâches', value: 'Inadequate segregation of duties' },
    {
        name: 'Séparation insuffisante des installations opérationnelles et de test',
        value: 'Inadequate segregation of operational and testing facilities',
    },
    { name: 'Espionnage industriel', value: 'Industrial espionage' },
    { name: `Fuite d'informations`, value: 'Information leakage' },
    { name: 'Interruption des processus métier', value: 'Interruption of business processes' },
    { name: 'Supervision insuffisante des employés', value: 'Inadequate supervision of employees' },
    { name: 'Supervision insuffisante des fournisseurs', value: 'Inadequate supervision of vendors' },
    { name: 'Formation insuffisante des employés', value: 'Inadequate training of employees' },
    {
        name: 'Spécification incomplète pour le développement de logiciels',
        value: 'Incomplete specification for software development',
    },
    { name: 'Tests logiciels insuffisants', value: 'Insufficient software testing' },
    { name: `Absence de politique de contrôle d'accès`, value: 'Lack of access control policy' },
    {
        name: `Absence de politique de bureau propre et d'écran clair`,
        value: 'Lack of clean desk and clear screen policy',
    },
    {
        name: `Absence de contrôle sur les données d'entrée et de sortie`,
        value: 'Lack of control over the input and output data',
    },
    { name: 'Absence de documentation interne', value: 'Lack of internal documentation' },
    {
        name: `Absence ou mauvaise mise en œuvre de l'audit interne`,
        value: 'Lack of or poor implementation of internal audit',
    },
    {
        name: `Absence de politique d'utilisation de la cryptographie`,
        value: 'Lack of policy for the use of cryptography',
    },
    {
        name: `Absence de procédure de suppression des droits d'accès en cas de cessation d'emploi`,
        value: 'Lack of procedure for removing access rights upon termination of employment',
    },
    { name: `Absence de protection pour l'équipement mobile`, value: 'Lack of protection for mobile equipment' },
    { name: 'Absence de redondance', value: 'Lack of redundancy' },
    {
        name: `Absence de systèmes d'identification et d'authentification`,
        value: 'Lack of systems for identification and authentication',
    },
    { name: 'Absence de validation des données traitées', value: 'Lack of validation of the processed data' },
    { name: 'Emplacement vulnérable aux inondations', value: 'Location vulnerable to flooding' },
    { name: 'Mauvais choix de données de test', value: 'Poor selection of test data' },
    { name: 'Copie unique', value: 'Single copy' },
    { name: `Trop de pouvoir entre les mains d'une personne`, value: 'Too much power in one person' },
    { name: 'Copie incontrôlée de données', value: 'Uncontrolled copying of data' },
    { name: 'Téléchargement incontrôlé depuis Internet', value: 'Uncontrolled download from the Internet' },
    { name: `Utilisation incontrôlée des systèmes d'information`, value: 'Uncontrolled use of information systems' },
    { name: 'Logiciel non documenté', value: 'Undocumented software' },
    { name: 'Employés non motivés', value: 'Unmotivated employees' },
    { name: 'Connexions non protégées au réseau public', value: 'Unprotected public network connections' },
    { name: `Droits de l'utilisateur non examinés régulièrement`, value: 'User rights are not reviewed regularly' },
];

export const LikelihoodOptionBy5 = [
    { name: 'Very Low', value: '1' },
    { name: 'Low', value: '2' },
    { name: 'Medium', value: '3' },
    { name: 'High', value: '4' },
    { name: 'Very High', value: '5' },
];
export const LikelihoodOptionBy5Fr = [
    { name: 'Très basse', value: '1' },
    { name: 'Basse', value: '2' },
    { name: 'Moyenne', value: '3' },
    { name: 'Élevée', value: '4' },
    { name: 'Très élevée', value: '5' },
];

export const ImpactOptionBy5 = [
    { name: 'Very Low', value: '1' },
    { name: 'Low', value: '2' },
    { name: 'Medium', value: '3' },
    { name: 'High', value: '4' },
    { name: 'Very High', value: '5' },
];
export const ImpactOptionBy5Fr = [
    { name: 'Très basse', value: '1' },
    { name: 'Basse', value: '2' },
    { name: 'Moyenne', value: '3' },
    { name: 'Élevée', value: '4' },
    { name: 'Très élevée', value: '5' },
];

export const LikelihoodOptionBy3 = [
    { name: 'Low', value: '1' },
    { name: 'Medium', value: '2' },
    { name: 'High', value: '3' },
];
export const LikelihoodOptionBy3Fr = [
    { name: 'Basse', value: '1' },
    { name: 'Moyenne', value: '2' },
    { name: 'Élevée', value: '3' },
];

export const ImpactOptionBy3 = [
    { name: 'Low', value: '1' },
    { name: 'Medium', value: '2' },
    { name: 'high', value: '3' },
];
export const ImpactOptionBy3Fr = [
    { name: 'Basse', value: '1' },
    { name: 'Moyenne', value: '2' },
    { name: 'Élevée', value: '3' },
];

export const timelineOptions = [
    { name: 'Q2(Apr - Jun 2024)', value: 'Q2(Apr - Jun 2024)' },
    { name: 'Q3(Jul - Sep 2024)', value: 'Q3(Jul - Sep 2024)' },
    { name: 'Q4(Oct - Dec 2024)', value: 'Q4(Oct - Dec 2024)' },
];

export const risklevels = {
    '5x5': [
        { color: '#93000A', name: 'extreme' },
        { name: 'very high', color: '#DE3730' },
        { name: 'high', color: '#B28C00' },
        { name: 'medium', color: '#FFDF91' },
        { name: 'low', color: '#006C46' },
        { name: 'very low', color: '#55BE8B' },
    ],
    '3x3': [
        { name: 'high', color: '#DE3730' },
        { name: 'medium', color: '#FFDF91' },
        { name: 'low', color: '#006C46' },
    ],
};
export const risklevelsFr = {
    '5x5': [
        { name: 'extrême', color: '#93000A' },
        { name: 'très élevé', color: '#DE3730' },
        { name: 'élevé', color: '#B28C00' },
        { name: 'moyen', color: '#FFDF91' },
        { name: 'faible', color: '#006C46' },
        { name: 'très faible', color: '#55BE8B' },
    ],
    '3x3': [
        { name: 'élevé', color: '#DE3730' },
        { name: 'moyen', color: '#FFDF91' },
        { name: 'faible', color: '#006C46' },
    ],
};

export const typeOptions = [
    { name: 'Inherent', value: 'inherent' },
    { name: 'Residual', value: 'residual' },
];
export const typeOptionsFr = [
    { name: 'Intrinsèque', value: 'inherent' },
    { name: 'Résiduel', value: 'residual' },
];

export const tabList = [
    { id: 1, name: '5x5 Matrix' },
    { id: 2, name: '3x3 Matrix' },
];
export const tabListFr = [
    { id: 1, name: 'Matrice 5x5' },
    { id: 2, name: 'Matrice 3x3' },
];

export const likelihoodBy5 = [
    { name: 'Very High', value: 5 },
    { name: 'High', value: 4 },
    { name: 'Medium', value: 3 },
    { name: 'Low', value: 2 },
    { name: 'Very Low', value: 1 },
];
export const likelihoodBy5Fr = [
    { name: 'Très élevée', value: 5 },
    { name: 'Élevée', value: 4 },
    { name: 'Moyenne', value: 3 },
    { name: 'Basse', value: 2 },
    { name: 'Très basse', value: 1 },
];

export const impactBy5 = [
    { name: 'Very Low', value: 1 },
    { name: 'Low', value: 2 },
    { name: 'Medium', value: 3 },
    { name: 'High', value: 4 },
    { name: 'Very High', value: 5 },
];
export const impactBy5Fr = [
    { name: 'Très basse', value: 1 },
    { name: 'Basse', value: 2 },
    { name: 'Moyenne', value: 3 },
    { name: 'Élevée', value: 4 },
    { name: 'Très élevée', value: 5 },
];

export const likelihoodBy3 = [
    { name: 'High', value: 3 },
    { name: 'Medium', value: 2 },
    { name: 'Low', value: 1 },
];
export const likelihoodBy3Fr = [
    { name: 'Élevée', value: 3 },
    { name: 'Moyenne', value: 2 },
    { name: 'Basse', value: 1 },
];

export const impactBy3 = [
    { name: 'Low', value: 1 },
    { name: 'Medium', value: 2 },
    { name: 'High', value: 3 },
];
export const impactBy3Fr = [
    { name: 'Basse', value: 1 },
    { name: 'Moyenne', value: 2 },
    { name: 'Élevée', value: 3 },
];

export const data = {
    '3x3': {
        risk_level: {
            inherent: [1, 1, 1],
            residual: [1, 1],
        },
        risk_assets: {
            infrastructure: 0,
            employee_computer: 0,
            appliances: 0,
            softwares: 0,
            digital: 0,
            hardcopy: 0,
            service_provider: 0,
            people: 0,
            utilities: 0,
        },
        risk_distribution: {
            inherent: {
                '3_1': 0,
                '3_2': 0,
                '3_3': 0,
                '2_1': 0,
                '2_2': 1,
                '2_3': 0,
                '1_1': 0,
                '1_2': 1,
                '1_3': 0,
            },
            residual: {
                '2_1': 0,
                '2_2': 1,
                '1_1': 0,
                '1_2': 1,
            },
        },
    },
    '5x5': {
        risk_level: {
            inherent: [0, 0, 0, 0, 0, 0],
            residual: [0, 0, 0, 0, 0],
        },
        risk_assets: {
            infrastructure: 0,
            employee_computer: 0,
            appliances: 0,
            softwares: 0,
            digital: 0,
            hardcopy: 0,
            service_provider: 0,
            people: 0,
            utilities: 0,
        },
        risk_distribution: {
            inherent: {
                '5_1': 0,
                '5_2': 0,
                '5_3': 0,
                '5_4': 0,
                '5_5': 0,
                '4_1': 0,
                '4_2': 0,
                '4_3': 0,
                '4_4': 0,
                '4_5': 0,
                '3_1': 0,
                '3_2': 0,
                '3_3': 0,
                '3_4': 0,
                '3_5': 0,
                '2_1': 0,
                '2_2': 0,
                '2_3': 0,
                '2_4': 0,
                '2_5': 0,
                '1_1': 0,
                '1_2': 0,
                '1_3': 0,
                '1_4': 0,
                '1_5': 0,
            },
            residual: {
                '4_1': 0,
                '4_2': 0,
                '4_3': 0,
                '4_4': 0,
                '3_1': 0,
                '3_2': 0,
                '3_3': 0,
                '3_4': 0,
                '2_1': 0,
                '2_2': 0,
                '2_3': 0,
                '2_4': 0,
                '1_1': 0,
                '1_2': 0,
                '1_3': 0,
                '1_4': 0,
            },
        },
    },
};

export const threeby3 = [
    { name: 'low', color: '#006C46' },
    { name: 'medium', color: '#FFDF91' },
    { name: 'high', color: '#93000A' },
];
export const threeby3Fr = [
    { name: 'basse', color: '#006C46' },
    { name: 'moyenne', color: '#FFDF91' },
    { name: 'élevée', color: '#93000A' },
];

export const fiveby5 = [
    { name: 'very low', color: '#55BE8B' },
    { name: 'low', color: '#006C46' },
    { name: 'medium', color: '#FFDF91' },
    { name: 'high', color: '#B28C00' },
    { name: 'very high', color: '#DE3730' },
    { name: 'extreme', color: '#93000A' },
];
export const fiveby5Fr = [
    { name: 'Très bas', color: '#55BE8B' },
    { name: 'Bas', color: '#006C46' },
    { name: 'Moyen', color: '#FFDF91' },
    { name: 'Élevé', color: '#B28C00' },
    { name: 'Très élevé', color: '#DE3730' },
    { name: 'Extrême', color: '#93000A' },
];

export const AssessmentOptions = [
    {
        name: 'Cybersecurity Self-assessment',
        type: 'self',
    },
    {
        name: 'Intelligence assessment',
        type: 'intelligence',
    },
];
export const AssessmentOptionsFr = [
    {
        name: 'Auto-évaluation de la cybersécurité',
        type: 'self',
    },
    {
        name: 'Évaluation du renseignement',
        type: 'intelligence',
    },
];

export const ThreatIdentification = [
    { name: 'External', value: 'external' },
    { name: 'Internal', value: 'internal' },
];
export const ThreatIdentificationFr = [
    { name: 'Externe', value: 'external' },
    { name: 'Interne', value: 'internal' },
];

export const by5_distribution = [1, 2, 3, 4, 5, 2, 4, 5, 8, 10, 3, 6, 9, 12, 15, 4, 8, 12, 16, 20, 5, 10, 15, 20, 25];
