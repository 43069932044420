import React from 'react';
import { Stack, Typography } from '@mui/material';
import { TipsAndUpdatesRounded } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const ComplianceTip = (props) => {
    const theme = useTheme();
    const { tip } = props;
    return (
        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 1 }}>
            <TipsAndUpdatesRounded color="primary" />
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: 13,
                    color: theme.palette.gray[600],
                    ml: 1,
                }}
            >
                {tip}
            </Typography>
        </Stack>
    );
};

export default ComplianceTip;
