import { Stack } from '@mui/material';
import React from 'react';
import AppInput from 'components/new_components/AppInput';
import { useTheme } from '@mui/material/styles';
import { riskLevelOptions, statusOptions } from '../../utils/constants';
import AppSelectInput from 'components/new_components/AppSelectInput';
// import SearchIcon from '@mui/icons-material/Search';
import SearchIcon from 'assets/img/Searchicon.svg';

//translations
import { useTranslation } from 'react-i18next';

const VendorTitle = (props) => {
    const { onSearch, status, setStatusFilter, entity_type, setEntity_type } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('riskAssessment');

    //functions
    const handleStatusChange = (e) => {
        setStatusFilter(e.target.value);
    };
    const onEntitySelect = (e) => {
        setEntity_type(e.target.value);
    };

    return (
        <Stack direction="row" alignItems="center" sx={{ ml: '-1rem' }}>
            <AppInput
                placeholder={'Search'}
                fullWidth
                sx={{
                    borderColor: theme.palette.gray[30],
                    height: 38,
                    width: '230px',
                    '& input::placeholder': {
                        color: theme.palette.gray[40],
                        fontWeight: 600,
                    },
                }}
                startAdornment={<img src={SearchIcon} alt="SearchIcon" className="mr-1" />}
                onChange={onSearch}
            />
            <AppSelectInput
                name="status"
                options={statusOptions}
                defaultValue={t('riskRegisterTab.table.column5')}
                sx={{ ml: 6.5, borderRadius: '5px', height: 38, fontSize: 12, width: '120px' }}
                onChange={handleStatusChange}
                value={status}
                size="small"
            />
            <AppSelectInput
                name="status"
                options={riskLevelOptions}
                defaultValue={'Risk Level'}
                sx={{ borderRadius: '5px', height: 38, fontSize: 12, width: '150px' }}
                onChange={onEntitySelect}
                value={entity_type}
                size="small"
            />
        </Stack>
    );
};

export default VendorTitle;
