import { validation } from 'validate';

export const riskIdentificationValidation = (values) => {
    const errors = {};
    const assetTypeValidate = validation(values?.asset_type, 'Asset Type', true);
    const assetValidate = validation(values?.asset, 'Asset', true);
    // const assetValidate_ = validation(values?.asset_, 'Asset', true);
    const threatValidate = validation(values?.threat, 'Threat', true);
    const threat_descriptionValidate = validation(values?.threat_description, 'Threat Description', true);
    const vulnerabilityValidate = validation(values?.vulnerabilities, 'Vulnerabilties', true);
    const likelihoodValidate = validation(values?.inherent_likelihood, 'Likelihood', true);
    const impactValidate = validation(values?.inherent_impact, 'Impact', true);
    const risk_ownerValidate = validation(values?.risk_owner, 'Risk Owner', true);

    if (!assetTypeValidate.isValid) {
        errors.asset_type = assetTypeValidate.errorMessage;
    }
    if (!assetValidate.isValid) {
        errors.asset = assetValidate.errorMessage;
    }
    if (!threatValidate.isValid) {
        errors.threat = threatValidate.errorMessage;
    }
    if (!threat_descriptionValidate.isValid) {
        errors.threat_description = threat_descriptionValidate.errorMessage;
    }
    if (!likelihoodValidate.isValid) {
        errors.inherent_likelihood = likelihoodValidate.errorMessage;
    }
    if (!impactValidate.isValid) {
        errors.inherent_impact = impactValidate.errorMessage;
    }
    // if (values?.asset_ === 'employee_computer' && !assetValidate_.isValid) {
    //     errors.asset_ = assetValidate_.errorMessage;
    // }
    if (
        values?.asset_type !== 'employee_computer' &&
        values?.asset_type !== 'infrastructure' &&
        !risk_ownerValidate.isValid
    ) {
        errors.risk_owner = risk_ownerValidate.errorMessage;
    }
    if (!vulnerabilityValidate.isValid) {
        errors.vulnerabilities = vulnerabilityValidate.errorMessage;
    }

    return errors;
};

export const existingControlValidation = (values) => {
    const errors = {};
    const controlValidate = validation(values?.existing_controls, 'Controls', true);

    if (!controlValidate.isValid) {
        errors.existing_controls = controlValidate.errorMessage;
    }

    return errors;
};

export const recoveryMeasuresValidation = (values) => {
    const errors = {};
    const controlValidate = validation(values?.recommended_controls, 'Recommended Control', true);
    const timelineValidate = validation(values?.timeline, 'Timeline', true);
    const responsibiltyValidate = validation(values?.responsibility, 'Responsibility', true);
    const statusValidate = validation(values?.risk_status, 'Risk Status', true);

    if (!controlValidate.isValid) {
        errors.recommended_controls = controlValidate.errorMessage;
    }
    if (!timelineValidate.isValid) {
        errors.timeline = timelineValidate.errorMessage;
    }
    if (!responsibiltyValidate.isValid) {
        errors.responsibility = responsibiltyValidate.errorMessage;
    }
    if (!statusValidate.isValid) {
        errors.risk_status = statusValidate.errorMessage;
    }

    return errors;
};

export const postRiskTreatmentValidation = (values) => {
    const errors = {};
    const likelihoodValidate = validation(values?.residual_likelihood, 'Residual Likelihood', true);
    const impactValidate = validation(values?.residual_impact, 'Residual Impact', true);

    if (!likelihoodValidate.isValid) {
        errors.residual_likelihood = likelihoodValidate.errorMessage;
    }
    if (!impactValidate.isValid) {
        errors.residual_impact = impactValidate.errorMessage;
    }

    return errors;
};

export const threatIdentificationValidation = (values) => {
    const errors = {};
    const assetTypeValidate = validation(values?.asset_type, 'Asset Type', true);
    const assetValidate = validation(values?.asset, 'Asset', true);
    const threatValidate = validation(values?.threat, 'Threat', true);
    const likelihoodValidate = validation(values?.inherent_likelihood, 'Likelihoos', true);
    const impactValidate = validation(values?.inherent_impact, 'Impact', true);
    const riskIdentofocationValidate = validation(values?.risk_source, 'Risk Identification', true);
    const describe_threatValidate = validation(values?.threat_description, 'Threat Description', true);
    const statusValidate = validation(values?.risk_status, 'Risk Status', true);
    const risk_ownerValidate = validation(values?.risk_owner, 'Risk Owner', true);
    const risk_dateValidate = validation(values?.date_detected, 'Date Detected', true);

    if (!assetTypeValidate.isValid) {
        errors.asset_type = assetTypeValidate.errorMessage;
    }
    if (!assetValidate.isValid) {
        errors.asset = assetValidate.errorMessage;
    }
    if (!threatValidate.isValid) {
        errors.threat = threatValidate.errorMessage;
    }
    if (!likelihoodValidate.isValid) {
        errors.inherent_likelihood = likelihoodValidate.errorMessage;
    }
    if (!impactValidate.isValid) {
        errors.inherent_impact = impactValidate.errorMessage;
    }
    if (!riskIdentofocationValidate.isValid) {
        errors.risk_source = riskIdentofocationValidate.errorMessage;
    }
    if (!describe_threatValidate.isValid) {
        errors.threat_description = describe_threatValidate.errorMessage;
    }
    if (!risk_dateValidate.isValid) {
        errors.date_detected = risk_dateValidate.errorMessage;
    }
    if (
        values?.asset_type !== 'employee_computer' &&
        values?.asset_type !== 'infrastructure' &&
        !risk_ownerValidate.isValid
    ) {
        errors.risk_owner = risk_ownerValidate.errorMessage;
    }
    if (!statusValidate.isValid) {
        errors.risk_status = statusValidate.errorMessage;
    }

    return errors;
};

export const existing_controlValidation = (values) => {
    const errors = {};
    const controlValidate = validation(values?.existing_controls, 'Control', true);
    const commentsValidate = validation(values?.comments, 'Comments', true);
    const likelihoodValidate = validation(values?.residual_likelihood, 'Residual Likelihood', true);
    const impactValidate = validation(values?.residual_impact, 'Residual Impact', true);

    if (!controlValidate.isValid) {
        errors.existing_controls = controlValidate.errorMessage;
    }
    if (!commentsValidate.isValid) {
        errors.comments = controlValidate.errorMessage;
    }
    if (!likelihoodValidate.isValid) {
        errors.residual_likelihood = likelihoodValidate.errorMessage;
    }
    if (!impactValidate.isValid) {
        errors.residual_impact = impactValidate.errorMessage;
    }
    return errors;
};
