import { Box, Typography } from '@mui/material';
import React from 'react';
import CompletedSvg from '../../../assets/img/CompletedSvg.svg';
import { useTheme } from '@mui/material/styles';

const Responded = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: '5rem',
            }}
        >
            <img src={CompletedSvg} alt="CompletedSvg" />
            <Typography sx={{ color: theme.palette.primary[900], fontWeight: 700, fontSize: '20px', marginTop: 5 }}>
                RESPONDED
            </Typography>
            <Typography
                sx={{
                    color: theme.palette.primary[900],
                    fontWeight: 500,
                    fontSize: '12px',
                    marginTop: 1.5,
                }}
            >
                You have responded to this survey
            </Typography>
        </Box>
    );
};

export default Responded;
