import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Modal } from 'antd';
import BreadCrumb from 'components/Breadcrumb';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { EditAccessorCategory } from 'store/actions/auditActions';
import FieldModal from '../components/FieldModal';
import { toast } from 'react-toastify';
import FieldItem from '../components/FieldItem';

const { confirm } = Modal;
const CategorySettings = (props) => {
    const { location, all_accessor_categories, EditAccessorCategory } = props;
    // state
    const [fieldModal, setFieldModal] = useState(false);
    const [fieldIndex, setFieldIndex] = useState(null);

    // memos
    const category = useMemo(
        () => all_accessor_categories.find((category) => category?.id == location?.state?.category_id),
        [location, all_accessor_categories]
    );

    const questions = useMemo(() => {
        try {
            return JSON.parse(category.questions).sort((a, b) => a?.id - b?.id);
        } catch {
            return [];
        }
    }, [category]);

    // functions
    const openFieldModal = (mode, idx) => {
        setFieldModal(mode);
        setFieldIndex(idx);
    };
    const closeFieldModal = () => {
        setFieldModal(null);
        setFieldIndex(null);
    };

    const updateCategoryDetail = async (sections, field) => {
        const res = await EditAccessorCategory({ [field]: JSON.stringify(sections) }, category?.id);
        if (res.success) {
            return toast.success(res?.message);
        } else {
            return toast.error(res.message);
        }
    };

    const deleteField = (index) => {
        confirm({
            title: 'Are you sure remove this field?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const questionsArray = [...questions];
                questionsArray.splice(index, 1);
                await updateCategoryDetail(questionsArray, 'questions');
            },
        });
    };
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[
                            { label: 'Accessor Categories', link: '/accessor-categories' },
                            { label: category?.name },
                        ]}
                    />
                </Col>
                <Col className="flex justify-end items-center space-x-2">
                    <Button
                        size="large"
                        type="primary"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center"
                        onClick={() => openFieldModal('Add')}
                    >
                        Add Field
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Row gutter={16}>
                    {questions?.map((question, index) => {
                        return (
                            <FieldItem
                                question={question}
                                key={index}
                                openFieldModal={() => openFieldModal('Edit', index)}
                                deleteField={() => deleteField(index)}
                            />
                        );
                    })}
                </Row>
            </section>

            {/* modal */}
            <FieldModal
                open={fieldModal}
                fieldIndex={fieldIndex}
                closeModal={closeFieldModal}
                category={category?.id}
                questions={questions}
            />
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_accessor_categories: state?.auditReducers?.all_accessor_categories,
});
export default connect(mapStateToProps, { EditAccessorCategory })(CategorySettings);
