import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

import AppCenteredModalNew from 'components/new_components/AppCenteredModalNew';

//translations
import { useTranslation } from 'react-i18next';
import { UpdateSelfServiceData } from 'store/actions/generalActions';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

const MarkResolvedModal = (props) => {
    // Props
    const { open, handleClose, details, params } = props;

    // state
    const [loading, setLoading] = useState(false);

    // Redux
    const dispatch = useDispatch();

    //translation
    const { t } = useTranslation('riskAssessment');

    // Asyn function
    const handleResolved = async () => {
        setLoading(true);
        const body = {
            risk_assessment_id: details?.id,
            risk_assessment_status: 'resolved',
        };
        const res = await dispatch(UpdateSelfServiceData(body, params));
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message || 'Risk resolved successfully');
            handleClose();
        } else {
            toast.error(res?.message || 'Something went wrong');
        }
    };

    return (
        <AppCenteredModalNew
            open={open}
            onClose={handleClose}
            noShadow
            noClose
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 1,
                        backgroundColor: '#F8FAFC',
                        height: '60px',
                        pb: '-2rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit', color: '#334155' }}
                        onClick={handleClose}
                    >
                        {t('resolvedModal.cancel')}
                    </Button>
                    <LoadingButton
                        variant="contained"
                        sx={{
                            textTransform: 'inherit',
                            fontWeight: 600,
                            backgroundColor: '#55BE8B',
                            color: '#FFFFFF',
                            '&:hover': {
                                backgroundColor: '#55BE8B',
                                color: '#FFFFFF',
                            },
                        }}
                        loading={loading}
                        loadingPosition="center"
                        onClick={handleResolved}
                    >
                        {t('resolvedModal.resolved')}
                    </LoadingButton>
                </Box>
            }
            width="500px"
            height="240px"
        >
            <Box sx={{ my: 1 }}>
                <Typography sx={{ color: '#475569', fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>
                    {t('resolvedModal.title')}
                </Typography>
                <Typography sx={{ color: '#64748B', fontSize: '13px', fontWeight: 400, textAlign: 'center', mt: 2 }}>
                    {t('resolvedModal.description')}
                </Typography>
            </Box>
        </AppCenteredModalNew>
    );
};

export default MarkResolvedModal;
