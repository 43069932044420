import React from 'react';

//core components
import { Tooltip, Typography, Box } from '@mui/material';
import { Error } from '@mui/icons-material';
import Fade from '@mui/material/Fade';
import { useTheme } from '@mui/material/styles';
import { IoInformationCircleSharp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
//utils
// import { generatedTextTips } from '../utils/constants';

const GeneratedTextTooltip = () => {
    const theme = useTheme();
    const { t } = useTranslation('accounts');
    const toolTipContent = (
        <>
            <Typography sx={{ textAlign: 'center', mb: 3, fontWeight: 700, fontSize: 15 }}>
                <Error color={theme.palette.primary.main} sx={{ mr: 1 }} />
                {t('twoFactorAuth.generatedTextTipsHeader')}
            </Typography>
            <ol style={{ fontSize: '13px', fontWeight: 400 }}>
                {t('twoFactorAuth.generatedTextTips', { returnObjects: true })?.map((item) => {
                    return <li key={item.id} dangerouslySetInnerHTML={{ __html: item.text }}></li>;
                })}
            </ol>
        </>
    );
    return (
        <Tooltip
            title={toolTipContent}
            placement="top-start"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: '#fff !important',
                        color: theme.palette.primary.main,
                        borderRadius: 8,
                        p: 4,
                        width: '400px',
                        maxWidth: '430px !important',
                        boxShadow:
                            '-189px 200px 110px rgba(209, 209, 209, 0.01), -106px 112px 93px rgba(209, 209, 209, 0.05), -47px 50px 69px rgba(209, 209, 209, 0.09), -12px 12px 38px rgba(209, 209, 209, 0.1), 0px 0px 0px rgba(209, 209, 209, 0.1)',
                    },
                },
            }}
        >
            <Box
                component="button"
                sx={{
                    fontSize: '30px',
                    p: 0,
                    ml: 0.2,
                }}
            >
                <IoInformationCircleSharp color={theme.palette.primary.main} />
            </Box>
        </Tooltip>
    );
};

export default GeneratedTextTooltip;
