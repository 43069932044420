import React, { useState } from 'react';
import { useTheme } from '@mui/styles';

//components
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import CardHeader from './CardHeader';

//translations
import { useTranslation } from 'react-i18next';

const RiskLevelCard = (props) => {
    const [indexValue, setIndexValue] = useState(1);
    const { Data, fiveByFiveMatrix, threeBythreeMatrix } = props;

    const theme = useTheme();

    //translation
    const { t } = useTranslation('riskAssessment');

    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

    const option = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
            datalabels: {
                display: true,
                color: 'black',
                formatter: Math.round,
                anchor: 'end',
                offset: -20,
                align: 'start',
            },
        },
        aspectRatio: 5 / 3,
        scales: {
            x: {
                stacked: true,
                barThickness: 6,
                min: 0,
                max: 100,
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                barThickness: 6,
                grid: {
                    display: false,
                },
            },
        },
    };

    const data = {
        labels:
            indexValue === 1
                ? [
                      t('riskLevelCard.veryHigh'),
                      t('riskLevelCard.high'),
                      t('riskLevelCard.medium'),
                      t('riskLevelCard.low'),
                      t('riskLevelCard.veryLow'),
                  ]
                : [t('riskLevelCard.high'), t('riskLevelCard.medium'), t('riskLevelCard.low')],
        datasets: [
            {
                label: '',
                data: indexValue === 1 ? Data?.fiveByFiveMatrix?.inherent : Data?.threeBythreeMatrix?.inherent,
                backgroundColor: `${theme.palette.primary.main}`,
                barThickness: 20,
            },
        ],
    };
    const data2 = {
        labels:
            indexValue === 1
                ? [
                      t('riskLevelCard.veryHigh'),
                      t('riskLevelCard.high'),
                      t('riskLevelCard.medium'),
                      t('riskLevelCard.low'),
                      t('riskLevelCard.veryLow'),
                  ]
                : [t('riskLevelCard.high'), t('riskLevelCard.medium'), t('riskLevelCard.low')],
        datasets: [
            {
                label: '',
                data: indexValue === 1 ? Data?.fiveByFiveMatrix?.residual : Data?.threeBythreeMatrix?.residual,
                backgroundColor: `${theme.palette.primary.main}`,
                barThickness: 20,
            },
        ],
    };

    const onChangeTab = async (val) => {
        setIndexValue(val);
    };
    const tabList = [
        {
            id: 1,
            name: t('riskLevelCard.5By5'),
        },
        {
            id: 2,
            name: t('riskLevelCard.3By3'),
        },
    ];
    return (
        <Box
            sx={{
                border: `0.4px solid ${theme.palette.neutral.new80}`,
                boxShadow: '0px 8px 20px rgba(88, 88, 88, 0.05)',
                borderRadius: '8px',
                width: { md: '100%', lg: '100%', xl: '60%' },
                height: '370px',
                p: 2,
            }}
        >
            <CardHeader
                onChangeTab={onChangeTab}
                indexValue={indexValue}
                name={t('riskLevelCard.title')}
                tabList={tabList}
            />
            {(indexValue === 1 ? fiveByFiveMatrix : threeBythreeMatrix)?.length > 0 ? (
                <Stack direction="row" spacing={2} sx={{ height: '100%', mt: 3 }}>
                    <div className="w-1/2 h-[fit-content]">
                        <Typography
                            sx={{
                                color: theme.palette.primary[20],
                                fontSize: '14px',
                                fontWeight: 700,
                                letterSpacing: '0.1px',
                                ml: 2,
                            }}
                        >
                            {t('riskLevelCard.inherent')}
                        </Typography>
                        <Bar options={option} data={data} width={100} height={140} />
                    </div>
                    <div className="w-1/2 border-l-[0.7px] border-l-[#E1E2EC] pl-4 h-[fit-content]">
                        <Typography
                            sx={{
                                color: theme.palette.primary[20],
                                fontSize: '14px',
                                fontWeight: 700,
                                letterSpacing: '0.1px',
                                ml: 1.5,
                            }}
                        >
                            {t('riskLevelCard.residual')}
                        </Typography>
                        <Bar options={option} data={data2} width={100} height={140} />
                    </div>
                </Stack>
            ) : (
                <Stack direction="column" justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
                    {' '}
                    {/* <div style={{ width: '10px', height: '300px', backgroundColor: 'black' }}></div> */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                            flexDirection: 'column',
                            mt: 2,
                            width: '100%',
                        }}
                    >
                        <Skeleton
                            width="30%"
                            height={15}
                            sx={{ mb: 2, borderRadius: '20px' }}
                            animation="wave"
                            variant="rounded"
                        />
                        <Skeleton
                            width="25%"
                            height={15}
                            sx={{ mb: 2, ml: '-2rem', borderRadius: '20px' }}
                            animation="wave"
                            variant="rounded"
                        />
                        <Skeleton
                            width="20%"
                            height={15}
                            sx={{ mb: 2, ml: '-4rem', borderRadius: '20px' }}
                            animation="wave"
                            variant="rounded"
                        />
                        <Skeleton
                            width="15%"
                            height={15}
                            sx={{ mb: 2, ml: '-5.5rem', borderRadius: '20px' }}
                            animation="wave"
                            variant="rounded"
                        />
                    </Box>
                    <Typography sx={{ color: '#5C5E67', fontSize: '14px', fontWeight: 600, mt: 2 }}>
                        {t('riskLevelCard.noRisk')}
                    </Typography>
                </Stack>
            )}
        </Box>
    );
};

export default RiskLevelCard;
