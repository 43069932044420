export const getResourceDetailsByStandard = (resource, standard) => {
    const details = {};
    const admin_resource = resource?.document_type;

    details.title =
        standard === 'pcidss'
            ? admin_resource?.document_title || admin_resource?.title
            : standard === 'ndpr'
            ? admin_resource?.ndpr_title
            : standard === 'soc2'
            ? admin_resource?.soc2_title
            : admin_resource?.iso_title;
    details.description =
        standard === 'pcidss'
            ? admin_resource?.document_description || admin_resource?.description
            : standard === 'ndpr'
            ? admin_resource?.ndpr_document_description
            : standard === 'soc2'
            ? admin_resource?.soc2_description
            : admin_resource?.iso_description;
    details.doc_id = admin_resource?.id;
    details.user_doc_id = resource?.id;
    return details;
};

export const formatVersionDate = (date) => {
    let formatedDate = new Date(date);
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
    return `${formatedDate.toLocaleDateString('en-US', dateOptions)} (${formatedDate.toLocaleTimeString(
        'en-US',
        timeOptions
    )})`;
};
