import { Box, Divider, Grid, IconButton, Stack, useTheme } from '@mui/material';

import React, { useEffect, useMemo, useState } from 'react';
import AppInput from 'components/new_components/AppInput';
import { Info } from '@mui/icons-material';
import AppSelectInput from 'components/new_components/AppSelectInput';
import AppButton from 'components/new_components/AppButton';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import AppTooltip from 'components/new_components/AppTooltip';

// redux
import { connect } from 'react-redux';
import {
    AnswerAuditCategory,
    UpdateAuditAnswer,
    CreateAuditRequest,
    UpdateAuditRequest,
    GetAllAuditRequests,
} from 'store/actions/auditActions';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const FormItem = (props) => {
    // hook
    const theme = useTheme();
    const history = useHistory();

    // props
    const { category, goPrev, answers, navigateCategory, isLast, GetAllAuditRequests, merchant } = props;

    // state
    const [entries, setEntries] = useState({});

    // memos
    const questions = useMemo(() => {
        try {
            return JSON.parse(category.questions).sort((a, b) => a?.id - b?.id);
        } catch {
            return [];
        }
    }, [category]);
    const sections = useMemo(() => {
        try {
            return JSON.parse(category?.sections);
        } catch {
            return [''];
        }
    }, [category]);
    const answer = useMemo(() => {
        return answers?.find((answer) => answer.category === category?.id);
    }, [answers, category?.id]);

    // functions
    const handleSubmitAndNext = async (e) => {
        e.preventDefault();
        await navigateCategory();
    };

    // useEffects
    useEffect(() => {
        if (answer) {
            const answers = answer?.answers;
            if (category?.name !== 'In-scope Networks' && category?.name !== 'In-scope Business Functions') {
                setEntries({ '': answers });
            } else {
                setEntries(answers);
            }
        } else {
            const entries = {};
            sections.forEach((section) => (entries[section] = [{}]));
            setEntries(entries);
        }
    }, [sections, answer]);

    useEffect(() => {
        const getAllAuditRequests = async () => {
            const res = await GetAllAuditRequests();
            if (!res?.success) {
                toast.error("Something went wrong, couldn't get audits.");
            }
        };

        getAllAuditRequests();
    }, []);

    return (
        <Stack component="form" onSubmit={handleSubmitAndNext} justifyContent="space-between" className="flex-1">
            <Box sx={{ pb: 3 }}>
                {category?.question_type === 'multiple' && (
                    <div className="flex items-center">
                        <Info color="primary" />
                        <span className="text-[#5E5E62] text-[14px] pl-2">
                            Use the <strong>Add field</strong> button to enter a new data.
                        </span>
                    </div>
                )}
                {/* sections loop */}
                {sections?.map((section, index) => {
                    const sectionEntries = entries[section];
                    return (
                        <React.Fragment key={index}>
                            <p className="text-[#46464A] font-medium">{section}</p>
                            {/* entries loop */}
                            <Box sx={{ px: 3, py: 1 }}>
                                {sectionEntries?.map((entry, entryIdx) => {
                                    return (
                                        <React.Fragment key={entryIdx}>
                                            <Grid container spacing={2}>
                                                {/* questions loop */}
                                                {questions.map((question, index) => {
                                                    return (
                                                        <FieldItem key={index} question={question} answers={entry} />
                                                    );
                                                })}
                                            </Grid>

                                            {entryIdx + 1 < sectionEntries?.length && <Divider />}
                                        </React.Fragment>
                                    );
                                })}
                            </Box>
                        </React.Fragment>
                    );
                })}
            </Box>{' '}
            <Stack direction="row" spacing={4} sx={{ px: 3 }}>
                <AppButton name="Previous" variant="outlined" color="primary" onClick={goPrev} />
                {!isLast() && <AppLoadingButton text="Next" variant="contained" color="primary" type="submit" />}
                {isLast() && (
                    <Box sx={{ display: 'flex' }}>
                        <AppLoadingButton
                            onClick={() => history.push(`/admin/accessor/${merchant?.id}`)}
                            text="Approve"
                            variant="contained"
                            color="primary"
                        />{' '}
                        <AppLoadingButton
                            text="Disapprove"
                            variant="contained"
                            sx={{
                                backgroundColor: theme.palette.error[50],
                                mx: '2rem',
                                '&:hover': {
                                    backgroundColor: theme.palette.error[50],
                                },
                            }}
                        />{' '}
                    </Box>
                )}
            </Stack>
        </Stack>
    );
};

const FieldItem = (props) => {
    // hook
    const theme = useTheme();
    // props
    const { question, answers } = props;

    return (
        <Grid item xs={12} md={question?.fullWidth ? 12 : 6}>
            {question.type === 'text' ? (
                <AppInput
                    label={
                        <>
                            <span>{question?.name}</span>
                            {!!question.tooltip && (
                                <AppTooltip title={question?.tooltip} placement="top">
                                    <IconButton sx={{ p: 0 }}>
                                        <Info
                                            sx={{
                                                width: 16,
                                                height: 16,
                                                ml: 0.5,
                                                color: theme.palette.gray[500],
                                            }}
                                        />
                                    </IconButton>
                                </AppTooltip>
                            )}
                        </>
                    }
                    multiline
                    required={question?.required}
                    value={answers?.[question?.name] || ''}
                    name={question?.name}
                    disable
                />
            ) : question?.type === 'dropdown' ? (
                <AppSelectInput
                    name={question?.name}
                    label={question?.name}
                    defaultValue="Select an option"
                    value={answers?.[question?.name] || ''}
                    required={question?.required}
                    options={question?.options}
                    disable
                />
            ) : null}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    all_audit_requests: state?.auditReducers?.all_audit_requests,
    merchant_info: state?.merchantReducers?.merchant_info,
});
export default connect(mapStateToProps, {
    GetAllAuditRequests,
    AnswerAuditCategory,
    UpdateAuditAnswer,
    UpdateAuditRequest,
    CreateAuditRequest,
})(FormItem);
