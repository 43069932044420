import React from 'react';
import BLOCKCHAINReport from '../../AllReport/report/BLOCKCHAINReport';

const BLOCKCHAINirstLayerReport = ({ percentage }) => {
    return (
        <div className="bg-white py-8 px-3">
            <div className="grid grid-cols-3 gap-8">
                <BLOCKCHAINReport percentage={percentage} />
            </div>
        </div>
    );
};

export default BLOCKCHAINirstLayerReport;
