import React, { useState } from 'react';
import { toast } from 'react-toastify';
//core
import AppCenteredModal from './AppCenteredModal';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Upload, Download } from '@mui/icons-material';

//translations
import { useTranslation } from 'react-i18next';

const AppUploadCSVModal = ({ open, handleClose, title, subheader, templateLink, uploadCSVAction }) => {
    //states
    const [uploading, setUploading] = useState(false);
    //theme
    const theme = useTheme();

    //translation
    const { t } = useTranslation('common');

    //functions
    const uploadCSV = async (e) => {
        const { files } = e.target;
        console.log(files[0]);
        const fileExt = files?.[0]?.name?.split('.')?.splice(-1)?.[0];
        const acceptedExt = ['xlsx'];
        console.log(fileExt);

        if (!acceptedExt?.includes(fileExt)) {
            if (!files?.[0]) {
                return toast.info('Select a file please');
            }
            return toast.info('File is not a valid type. Kindly upload an excel file.');
        }
        const formData = new FormData();
        formData.append('file_upload', files[0]);
        setUploading(true);
        const res = await uploadCSVAction(formData);
        setUploading(false);
        if (res?.success) {
            toast.success('You have successfully imported your details.');
            handleClose();
        } else {
            toast.error(res?.message);
        }
        e.target.value = '';
    };
    return (
        <AppCenteredModal open={open} handleClose={handleClose} title={title} width={600}>
            {uploading ? (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#00000040',
                        zIndex: 100,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress color="inherit" size={25} style={{ color: '#333' }} />
                    </div>
                </div>
            ) : null}
            <Typography sx={{ color: theme.palette.gray[400] }}>{subheader}</Typography>
            <List>
                <ListItem sx={{ alignItems: 'flex-start' }}>
                    <ListItemAvatar>
                        <Avatar>
                            <Download />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText sx={{ fontSize: '16px', color: theme.palette.gray[800] }}>
                        <Typography
                            color={theme.palette.gray[900]}
                            sx={{ fontWeight: 500, fontSize: '18px', marginTop: '-8px' }}
                        >
                            {t('downloadTemplate')}
                        </Typography>
                        {t('downloadTemplateFile')}
                        <a
                            href={templateLink}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                color: theme.palette.primary[900],
                                textDecoration: 'underline',
                            }}
                        >
                            {t('download')}
                        </a>
                    </ListItemText>
                </ListItem>
                <ListItem sx={{ alignItems: 'flex-start', marginTop: '1rem' }}>
                    <ListItemAvatar>
                        <Avatar>
                            <Upload />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText sx={{ fontSize: '16px', color: theme.palette.gray[800] }}>
                        <Typography
                            color={theme.palette.gray[900]}
                            sx={{ fontWeight: 500, fontSize: '18px', marginTop: '-8px' }}
                        >
                            {t('uploadFilled')}
                        </Typography>
                        {t('finishedFilling')}
                        <label
                            htmlFor="upload-csv"
                            style={{
                                display: 'inline-flex',
                            }}
                        >
                            <input
                                id="upload-csv"
                                name="upload-csv"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={uploadCSV}
                                accept=".xlsx"
                            />

                            <span
                                style={{
                                    color: theme.palette.primary[900],
                                    textDecoration: 'underline',
                                    paddingRight: '5px',
                                    cursor: 'pointer',
                                }}
                            >
                                {t('click')}
                            </span>
                        </label>
                        {t('uploadFilledFile')}
                    </ListItemText>
                </ListItem>
            </List>
        </AppCenteredModal>
    );
};

export default AppUploadCSVModal;
