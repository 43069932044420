import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Row, Col, Table, Modal, Input } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';

// app components
import FeatureModal from './components/FeatureModal';
import BreadCrumb from 'components/Breadcrumb';
import FeatureActions from './components/FeatureActions';

// redux
import { connect } from 'react-redux';
import { GetAllFeatures, DeleteFeature } from 'store/actions/subscriptionActions';

// utility
import { VALUE_TYPES } from './util';
import useSearch from 'hooks/useSearch';

const Features = (props) => {
    // props
    const { GetAllFeatures, all_features, DeleteFeature } = props;

    // state
    const [loading, setLoading] = useState(false);
    const [featureModalOpen, setFeatureModalOpen] = useState(null);
    const [modalData, setModalData] = useState({});
    const [deleting, setDeleting] = useState(false);

    // hooks
    const { data, handleSearch } = useSearch(all_features, ['name', 'category', 'suffix', 'value_type'], (record) => ({
        value_type: VALUE_TYPES?.find((type) => type?.key === record?.value_type)?.label,
    }));

    // logic functions
    const openFeatureModal = (mode = 'Add', data = {}) => {
        setFeatureModalOpen(mode);
        setModalData(data);
    };
    const closeFeatureModal = () => {
        setFeatureModalOpen(null);
        setModalData({});
    };
    const deleteFeature = (record) => {
        Modal.confirm({
            title: 'Do you wish to delete this feature?',
            content: 'Click yes to proceed',
            okText: 'Delete',
            loading: deleting,
            okType: 'danger',
            async onOk() {
                setDeleting(true);
                const res = await DeleteFeature(record.id);
                setDeleting(false);
                if (res?.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            },
            onCancel() {
                return false;
            },
        });
    };

    // async function
    const getAllFeatures = async () => {
        setLoading(true);
        const res = await GetAllFeatures();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        getAllFeatures();
    }, []);

    // constant
    const columns = [
        {
            title: 'S/N',
            render: (record) => record?.index + 1,
        },
        {
            title: 'Name',
            render: (record) => record?.name,
        },
        {
            title: 'Category',
            render: (record) => record?.category,
        },
        {
            title: 'Suffix/Item',
            render: (record) => record?.suffix || 'Nil',
        },
        {
            title: 'Value Type',
            render: (record) => VALUE_TYPES?.find((type) => type?.key === record?.value_type)?.label,
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => (
                <FeatureActions record={record} openEditModal={openFeatureModal} openDeleteModal={deleteFeature} />
            ),
        },
    ];

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'All Features' }]} />
                </Col>
                <Col span={12} className="flex items-center">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                    <Button
                        onClick={() => openFeatureModal()}
                        type="primary"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center ml-3"
                    >
                        Add Feature
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(feature) => feature.id}
                    loading={loading}
                    columns={columns}
                    dataSource={data?.map((feature, index) => ({
                        ...feature,
                        index,
                    }))}
                    pagination={{
                        total: data?.length,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                    }}
                />
            </section>
            <FeatureModal mode={featureModalOpen} onClose={closeFeatureModal} modalData={modalData} />
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        all_features: state?.subscriptionReducers?.all_features,
    };
};
export default connect(mapStateToProps, { GetAllFeatures, DeleteFeature })(Features);
