import React from 'react';
import SummaryReportCard from '../SummaryReportCard';
import PCIDSS4Point0FirstLayerReport from './PCIDSS4Point0FirstLayerReport';

//translations
import { useTranslation } from 'react-i18next';

const PCIDSS4Point0Index = ({ percentage, pcidss4Point0General = {}, pcidss4Point0Summary = {} }) => {
    //translation
    const { t } = useTranslation('overview');

    //function
    const toListEntry = Object.entries(pcidss4Point0Summary);
    const newList = toListEntry
        ?.sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]))
        .map((res) => {
            const { user_doc, user_form, total_form, user_scan, total_scan, total_doc } = res?.[1] || {};
            const steps = ((user_doc + user_form + user_scan) * 100) / (total_doc + total_form + total_scan) || 0;
            return {
                title: `${t('standardTab.requirement')} ${parseInt(res?.[0])} - ${res?.[1]?.title}`,
                // ...res?.[1],
                total_doc: res?.[1]?.total_doc,
                total_form: res?.[1]?.total_form,
                total_scan: res?.[1]?.total_scan,
                user_doc: res?.[1]?.user_doc,
                user_form: res?.[1]?.user_form,
                user_scan: res?.[1]?.user_scan,
                stepCount: parseInt(steps.toString()),
            };
        });
    return (
        <div className="">
            <PCIDSS4Point0FirstLayerReport percentage={percentage} pcidss4Point0General={pcidss4Point0General} />
            <div className="py-8">
                <h3 className="textbrandColor font-bold text-[23px]">{t('standardTab.summary')}</h3>
                <div className="pt-4">
                    {newList?.map((res, index) => (
                        <React.Fragment key={index}>
                            <SummaryReportCard details={res} />
                        </React.Fragment>
                    ))}
                    {/*<SummaryReportCard title="Requirement 1 - Install and maintain a firewall configuration to protect cardholder data" />*/}
                </div>
            </div>
        </div>
    );
};

export default PCIDSS4Point0Index;
