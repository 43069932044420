import React from 'react';

import { useTheme } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';

// core component
import IntegrationCard from './IntegrationCard';

// redux
import { connect } from 'react-redux';

const Group = (props) => {
    const theme = useTheme();

    const { group, merchant_integrations, setSelected } = props;

    //translation
    const language = localStorage.getItem('i18nextLng');

    return (
        <Box sx={{ pt: 4, px: 2.5 }}>
            <Typography
                variant="h3"
                component="h3"
                sx={{
                    color: theme.palette.primary[700],
                    fontWeight: 600,
                    textTransform: 'capitalize',
                }}
            >
                {language?.includes('fr') ? group?.name_fr : language?.includes('en') ? group?.name_en : group?.name}
            </Typography>
            <Grid container spacing={3}>
                {group.integrations.map((integration, key) => {
                    const environments = merchant_integrations?.filter(
                        (merch_integration) => merch_integration?.integration === integration.id
                    );

                    return integration.fields.length ? (
                        <Grid item xs={6} md={4} lg={3} xl={3} key={key} className="md:min-w-[320px]">
                            <IntegrationCard
                                integration={integration}
                                groupName={
                                    language?.includes('fr')
                                        ? group?.name_fr
                                        : language?.includes('en')
                                        ? group?.name_en
                                        : group?.name
                                }
                                setSelected={setSelected}
                                environments={environments}
                            />
                        </Grid>
                    ) : null;
                })}
            </Grid>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant_integrations: state?.integrationsReducers?.merchant_integrations,
    };
};
export default connect(mapStateToProps, {})(Group);
