import React from 'react';
import { useTheme } from '@mui/material/styles';

// core components
import AppButton from 'components/new_components/AppButton';

const ExceptionTab = (props) => {
    const { label, active, onClick, sx } = props;

    const theme = useTheme();

    return (
        <AppButton
            name={label}
            variant={active ? 'contained' : 'outlined'}
            color="primary"
            sx={{
                border: active ? '' : `1px solid ${theme.palette.gray[600]}`,
                ...sx,
            }}
            onClick={onClick}
        />
    );
};

export default ExceptionTab;
