import React, { useState } from 'react';

import { useTheme } from '@mui/material/styles';

// core components
import AppButton from 'components/new_components/AppButton';
import ActivitiesViewModal from './ActivitiesViewModal';
import { useTranslation } from 'react-i18next';

const ActivityTableActions = (props) => {
    const theme = useTheme();
    const { activity } = props;
    const [modalOpen, setModalOpen] = useState(false);

    const { t } = useTranslation('activities');
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    return (
        <>
            <AppButton
                name={t('table.view')}
                sx={{
                    color: theme.palette.gray[900],
                    backgroundColor: theme.palette.gray[200],
                    px: 3,
                    py: 0.8,
                    '&:hover': {
                        backgroundColor: theme.palette.gray[300],
                    },
                }}
                onClick={openModal}
            />
            <ActivitiesViewModal open={modalOpen} handleClose={closeModal} activity={activity} />
        </>
    );
};

export default ActivityTableActions;
