import { Box, Card, Divider, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import React from 'react';

import { useTheme } from '@mui/material/styles';
import CircularLoader from 'components/Loaders/CircularLoader';
import ScansViewCategory from './ScanViewCategory';

// redux
import { connect } from 'react-redux';

const ScansView = (props) => {
    const theme = useTheme();
    const { goBack, category, all_group_automated_scans, loading, isScan } = props;
    const filtered_all_group_automated_scans = all_group_automated_scans?.filter(
        (subcat) => subcat?.category === category?.id
    );
    const group_scans = isScan ? filtered_all_group_automated_scans : category?.subcategory;
    return (
        <Box sx={{ m: 2 }}>
            <Card elevation={0} sx={{ py: 1, height: '85vh' }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        px: 4,
                        py: 1,
                        width: '100%',
                    }}
                >
                    <Box
                        onClick={() => goBack('groups')}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: `1px solid ${theme.palette.neutral[95]}`,
                            borderRadius: '50%',
                            cursor: 'pointer',
                            p: 0.1,
                        }}
                    >
                        <KeyboardArrowLeftIcon />
                    </Box>
                    <Typography
                        sx={{
                            ml: 1.5,
                            color: theme.palette.primary[20],
                            fontSize: '14px',
                            fontWeight: 600,
                            textTransform: 'capitalize',
                        }}
                    >
                        {category?.name}
                    </Typography>
                </Box>
                <Divider sx={{ color: theme.palette.neutral[95] }} />
                <Box sx={{ p: 4 }}>
                    {loading ? (
                        <CircularLoader />
                    ) : (
                        <Box>
                            {isScan ? (
                                <>
                                    {group_scans?.map((sub, index) => {
                                        return Object.keys(sub?.accounts)?.length === 0 ? null : (
                                            <ScansViewCategory
                                                key={index}
                                                sub={sub}
                                                isScan={isScan}
                                                category={category}
                                            />
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    {group_scans?.map((sub, index) => {
                                        return sub?.account?.length === 0 ? null : (
                                            <ScansViewCategory
                                                key={index}
                                                sub={sub}
                                                isScan={isScan}
                                                category={category}
                                            />
                                        );
                                    })}
                                </>
                            )}
                        </Box>
                    )}
                </Box>
            </Card>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_group_automated_scans: state?.automatedScansReducers?.all_group_automated_scans,
    };
};

export default connect(mapStateToProps, {})(ScansView);
