import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import FeatureRow from './FeatureRow';
import { connect } from 'react-redux';

const FeatureTable = (props) => {
    const { all_features, plan, customPlanPayload, setCustomPlanPayload } = props;
    const theme = useTheme();

    // states
    const [groupedFeatures, setGroupedFeatures] = useState({});
    const [featureChecklist, setFeatureChecklist] = useState({});

    //translation
    const language = localStorage.getItem('i18nextLng');

    const groupAllFeatures = () => {
        const groups = {};
        all_features?.forEach((feature) => {
            const featureCategory = language?.includes('fr')
                ? feature?.category_fr
                : language?.includes('en')
                ? feature?.category_en
                : feature?.category;
            if (!groups[featureCategory]) {
                groups[featureCategory] = {};
            }
            groups[featureCategory][feature?.key] = feature;
        });
        setGroupedFeatures(groups);
    };
    const prepareCustomPlanPayload = () => {
        const payload = {},
            checklist = {};
        all_features?.forEach((feature) => {
            checklist[feature?.key] = false;
            payload[feature?.key] =
                plan?.id === 'custom' ? (feature?.value_type === 'check' ? false : 0) : plan[feature?.key];
        });
        setCustomPlanPayload(payload);
        setFeatureChecklist(checklist);
    };
    const handleCustomPlanPayloadChange = (key, value) => {
        setCustomPlanPayload({ ...customPlanPayload, [key]: value });
    };
    const handleFeatureCheckList = (key, value) => {
        setFeatureChecklist({ ...featureChecklist, [key]: value });
        const currentValue = customPlanPayload[key];
        if (typeof currentValue === 'number') {
            if (value) {
                setCustomPlanPayload({
                    ...customPlanPayload,
                    [key]: currentValue < 1 ? 1 : currentValue,
                });
            }
        } else {
            setCustomPlanPayload({ ...customPlanPayload, [key]: !currentValue });
        }
    };

    useEffect(() => {
        groupAllFeatures();
        prepareCustomPlanPayload();
    }, [all_features]);

    return (
        <Box sx={{ borderRadius: 2, overflow: 'hidden' }}>
            {Object.keys(groupedFeatures)
                ?.sort((a, b) => (a > b ? 1 : -1))
                ?.map((category_name) => {
                    const category_features = groupedFeatures[category_name];

                    return (
                        <Stack key={category_name}>
                            <Box
                                component="header"
                                sx={{
                                    backgroundColor: theme.palette.primary[900],
                                    px: 3,
                                    py: 0.3,
                                }}
                            >
                                <Typography sx={{ color: theme.palette.white.main }}>{category_name}</Typography>
                            </Box>
                            <Stack sx={{ backgroundColor: theme.palette.gray[60] + 'cc' }}>
                                {Object.keys(category_features)
                                    ?.sort((a, b) => (a > b ? 1 : -1))
                                    ?.map((feature_key) => {
                                        const feature = category_features?.[feature_key];

                                        return (
                                            <FeatureRow
                                                label={
                                                    language?.includes('fr')
                                                        ? feature?.name_fr
                                                        : language?.includes('en')
                                                        ? feature?.name_en
                                                        : feature?.name
                                                }
                                                type={feature?.value_type}
                                                key={feature_key}
                                                featureKey={feature_key}
                                                suffix={feature?.suffix}
                                                value={customPlanPayload[feature?.key]}
                                                checked={featureChecklist[feature?.key]}
                                                planId={plan?.id}
                                                onValueChange={handleCustomPlanPayloadChange}
                                                onCheckChange={handleFeatureCheckList}
                                            />
                                        );
                                    })}
                            </Stack>
                        </Stack>
                    );
                })}
        </Box>
    );
};

export default connect()(FeatureTable);
