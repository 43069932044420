// core components
// import Overview from "views/merchant/Overview";
// import Users from '../views/merchant/Users';
import PCIScans from 'views/merchant/PCIScans';
import PenetrationTest from 'views/merchant/PenetrationTest';
import Log from 'views/merchant/Log';
import Employee from 'views/merchant/Inventory/Employee';
import Computers from 'views/merchant/Inventory/Computers';
import Infrastructures from 'views/merchant/Inventory/Infrastructures';
import PCIDSS from 'views/merchant/Compliance/Pcidss';
import CIIDIRECTIVE from 'views/merchant/Compliance/CiiDirective';
import NDPR from 'views/merchant/Compliance/Ndpr';
import SOC2 from 'views/merchant/Compliance/Soc2';
import BLOCKCHAIN from 'views/merchant/Compliance/Blockchain';
import ISO27001 from 'views/merchant/Compliance/Iso27001';
import ISO27001_2022 from 'views/merchant/Compliance/Iso27001-2022';
// import Setup from 'views/merchant/Setup';
import Integrations from 'views/merchant/Setup/Setup2';
import FraudGuardian from 'views/merchant/FraudGuardian/index';
import OnboardingPolicy from 'views/merchant/OnboardingPolicy';
// import CompanySetup from 'views/merchant/CompanySetup';
// import UserInfo from 'views/merchant/UserInfo';
import Settings from 'views/merchant/settings/Index';
// import ReleaseNotes from 'views/merchant/Releases';
import VulnerabilityScan from 'views/merchant/VulnerabilityScans';
// import Vendors from "views/merchant/VendorManagement/Vendors/Vendors";
import VendorLayout from 'views/merchant/VendorManagement/Vendors';
import kyc from 'views/merchant/AmlKyc/Kyc';
// import Response from 'views/merchant/VendorManagement/Responses/Responses';
// import Riskassessment from 'views/merchant/Risk_Assessment';
import RiskAssessmentRoot from 'views/merchant/Risk_Assessment_2.5';
import AutomatedScans from 'views/merchant/AutomatedScans';
// import Blank from 'views/merchant/Blank';
import AuditIndex from 'views/merchant/audit';
import AuditStandardsIndex from 'views/merchant/audit/AuditStandards';
import AuditPreviewIndex from 'views/merchant/audit/AuditPreview';
import ISO22301 from 'views/merchant/Compliance/Iso22301/index';
import PCIDSS4POINT0 from 'views/merchant/Compliance/Pcidss4.0/index';
import DataRoom from 'views/merchant/DataRoom';

// icons
import { Topic } from '@mui/icons-material';
import DashboardIndex from '../components/Dashboard/index';
import Training from 'views/merchant/training';
import { ReactComponent as HomeActiveIcon } from 'assets/img/HomeActive.svg';
import { ReactComponent as HomeInactiveIcon } from 'assets/img/HomeInactive.svg';
import { ReactComponent as OnboardingActiveIcon } from 'assets/img/OnboardingActive.svg';
import { ReactComponent as OnboardingInactiveIcon } from 'assets/img/OnboardingInactive.svg';
import { ReactComponent as ComplianceActiveIcon } from 'assets/img/ComplianceActive.svg';
import { ReactComponent as ComplianceInactiveIcon } from 'assets/img/ComplianceInactive.svg';
import { ReactComponent as RiskInactiveIcon } from 'assets/img/RiskInactive.svg';
import { ReactComponent as SecurityInactiveIcon } from 'assets/img/SecurityInactive.svg';
import { ReactComponent as AMLInactiveIcon } from 'assets/img/AMLInactive.svg';
import { ReactComponent as AMLActiveIcon } from 'assets/img/AMLActive.svg';
import { ReactComponent as IntegrationInactiveIcon } from 'assets/img/IntegrationInactive.svg';
import { ReactComponent as IntegrationActiveIcon } from 'assets/img/IntegrationActive.svg';
import { ReactComponent as ActivityIcon } from 'assets/img/activity.svg';
import { ReactComponent as HomeIcon } from 'assets/img/home.svg';
import { ReactComponent as SettingsIcon } from 'assets/img/SettingsInactive.svg';
import { ReactComponent as SettingsActiveIcon } from 'assets/img/SettingsActive.svg';
import { ReactComponent as ArrowIcon } from 'assets/img/arrow_right.svg';
import VulnerabilityMgt from 'views/merchant/VulnerabilityMgt';
import Apicompliance from 'views/merchant/Setup/QualityControl/APICompliance';
import Codebasecompliance from 'views/merchant/Setup/QualityControl/CodeReview';
import Kdpa from 'views/merchant/Compliance/Kdpa';
import Gdpa from 'views/merchant/Compliance/Gdpa';
import PCISLC from 'views/merchant/Compliance/PCISLC';
import ISO9001 from 'views/merchant/Compliance/ISO9001';
import ISO27001plusISO27035 from 'views/merchant/Compliance/Iso27001+Iso27035';

const frameWorkSubmenus = [
    {
        path: '/compliance/pcidss',
        name: 'PCIDSS',
        component: PCIDSS,
        layout: '/merchant',
    },
    {
        path: '/compliance/ndpr',
        name: 'NDPR',
        component: NDPR,
        layout: '/merchant',
    },
    {
        path: '/compliance/iso27001',
        name: 'ISO27001',
        component: ISO27001,
        layout: '/merchant',
    },
    {
        path: '/compliance/iso27001+iso27035',
        name: 'ISO27017+ISO27032',
        component: ISO27001plusISO27035,
        layout: '/merchant',
    },
    {
        path: '/compliance/iso27001_2',
        name: 'ISO27001-2022',
        component: ISO27001_2022,
        layout: '/merchant',
    },
    {
        path: '/compliance/soc2',
        name: 'SOC2',
        component: SOC2,
        layout: '/merchant',
    },
    {
        path: '/compliance/cii_directive',
        name: 'CII',
        component: CIIDIRECTIVE,
        layout: '/merchant',
    },
    {
        path: '/compliance/blockchain',
        name: 'BLOCKCHAIN',
        component: BLOCKCHAIN,
        layout: '/merchant',
    },
    {
        path: '/compliance/iso22301',
        name: 'ISO22301',
        component: ISO22301,
        layout: '/merchant',
    },
    {
        path: '/compliance/pcidss4.0',
        name: 'PCIDSS4.0',
        component: PCIDSS4POINT0,
        layout: '/merchant',
    },
    {
        path: '/compliance/kdpa',
        name: 'KDPA',
        component: Kdpa,
        layout: '/merchant',
    },
    {
        path: '/compliance/gdpa',
        name: 'GDPA',
        component: Gdpa,
        layout: '/merchant',
    },
    {
        path: '/compliance/pcislc',
        name: 'PCISLC',
        component: PCISLC,
        layout: '/merchant',
    },
    {
        path: '/compliance/iso9001',
        name: 'ISO9001',
        component: ISO9001,
        layout: '/merchant',
        isNew: true,
    },
];

const onboardingSubmenus = [
    {
        path: '/onboarding/employee',
        name: 'Employee onboarding',
        sub_name: 'Configure your onboarding process',
        icon: ArrowIcon,
        component: Employee,
        layout: '/merchant',
    },
    {
        path: '/onboarding/computers',
        name: 'People and computers',
        sub_name: 'Add employee to process',
        icon: ArrowIcon,
        component: Computers,
        layout: '/merchant',
    },
    {
        path: '/onboarding/policies',
        name: 'Policies',
        sub_name: 'Add policies to your organization',
        icon: ArrowIcon,
        component: OnboardingPolicy,
        layout: '/merchant',
    },
    {
        path: '/onboarding/training',
        name: 'Employee Training',
        sub_name: 'Train employee on courses',
        icon: ArrowIcon,
        component: Training,
        layout: '/merchant',
    },
];

const onboardingSubmenusFr = [
    {
        path: '/onboarding/employee',
        name: 'Intégration des employés',
        sub_name: "Configurez votre processus d'intégration",
        icon: ArrowIcon,
        component: Employee,
        layout: '/merchant',
    },
    {
        path: '/onboarding/computers',
        name: 'Les gens et les ordinateurs',
        sub_name: 'Ajouter un employé au processus',
        icon: ArrowIcon,
        component: Computers,
        layout: '/merchant',
    },
    {
        path: '/onboarding/policies',
        name: 'Politiques',
        sub_name: 'Ajouter des politiques à votre organisation',
        icon: ArrowIcon,
        component: OnboardingPolicy,
        layout: '/merchant',
    },
    {
        path: '/onboarding/training',
        name: 'Formation des employés',
        sub_name: 'Former les employés sur des cours',
        icon: ArrowIcon,
        component: Training,
        layout: '/merchant',
    },
];

const amlSubmenus = [
    {
        path: '/aml/kyc',
        name: 'AML',
        sub_name: 'Third party risks',
        icon: ArrowIcon,
        component: kyc,
        layout: '/merchant',
    },
    {
        path: '/aml/fraud-monitoring',
        name: 'Transaction Fraud Monitoring',
        sub_name: 'Add, update company policy',
        icon: ArrowIcon,
        component: FraudGuardian,
        layout: '/merchant',
        exempt: true,
    },
    {
        path: '/aml/vendors',
        name: 'Vendors',
        sub_name: 'Add policies to your organization',
        icon: ArrowIcon,
        component: VendorLayout,
        layout: '/merchant',
    },
];

const amlSubmenusFr = [
    {
        path: '/aml/kyc',
        name: 'AML',
        sub_name: 'Add policies to your organization',
        icon: ArrowIcon,
        component: kyc,
        layout: '/merchant',
    },
    {
        path: '/aml/computers',
        name: 'Surveillance des fraudes de transaction',
        sub_name: "Ajouter, mettre à jour la politique de l'entreprise",
        icon: ArrowIcon,
        component: FraudGuardian,
        layout: '/merchant',
        exempt: true,
    },
    {
        path: '/aml/vendors',
        name: 'Vendors',
        sub_name: 'Add policies to your organization',
        icon: ArrowIcon,
        component: VendorLayout,
        layout: '/merchant',
    },
];

const securitySubmenus = [
    {
        path: '/scans/automated-scans',
        name: 'Infrastructures Scans',
        sub_name: 'Add, update company policy',
        icon: ArrowIcon,
        component: AutomatedScans,
        layout: '/merchant',
    },
    {
        path: '/scans/pentest',
        name: 'Penetration Testing',
        sub_name: 'Add employee to process',
        icon: ArrowIcon,
        component: PenetrationTest,
        layout: '/merchant',
    },
    {
        path: '/scans/pciscans',
        name: 'PCI Scans',
        sub_name: 'Add policies to your organization',
        icon: ArrowIcon,
        component: PCIScans,
        layout: '/merchant',
    },
    {
        path: '/scans/api-scans',
        name: 'API Compliance Scans',
        sub_name: 'Train employee on courses',
        icon: ArrowIcon,
        component: Apicompliance,
        layout: '/merchant',
        exempt: true,
    },
    {
        path: '/scans/codebase',
        name: 'Codebase Security Scans',
        sub_name: 'Add employee to your process',
        icon: ArrowIcon,
        component: Codebasecompliance,
        layout: '/merchant',
        exempt: true,
    },
    {
        path: '/scans/vulnerability',
        name: 'Vulnerability Scans',
        sub_name: 'Add employee to your process',
        icon: ArrowIcon,
        component: VulnerabilityMgt,
        layout: '/merchant',
    },
    {
        path: '/vulnerability',
        name: 'Vulnerability Scans',
        icon: ArrowIcon,
        component: VulnerabilityScan,
        layout: '/merchant',
        hasAccess: ['merchant', 'user', 'admin', 'support'],
        subRoute: true,
    },
];

const securitySubmenusFr = [
    {
        path: '/scans/infrastructures',
        // name: "Scans d'infrastructures",
        name: 'Scans automatisés',
        sub_name: 'Add, update company policy',
        icon: ArrowIcon,
        component: Infrastructures,
        layout: '/merchant',
    },
    {
        path: '/scans/pentest',
        name: "Test d'intrusion",
        sub_name: 'Add employee to process',
        icon: ArrowIcon,
        component: PenetrationTest,
        layout: '/merchant',
    },
    {
        path: '/scans/pciscans',
        name: 'Analyses PCI',
        sub_name: 'Add policies to your organization',
        icon: ArrowIcon,
        component: PCIScans,
        layout: '/merchant',
    },
    {
        path: '/scans/api-scans',
        name: 'Scans de conformité des API',
        sub_name: 'Train employee on courses',
        icon: ArrowIcon,
        component: Apicompliance,
        layout: '/merchant',
        exempt: true,
    },
    {
        path: '/scans/codebase',
        name: 'Scans de sécurité du code source',
        sub_name: 'Add employee to your process',
        icon: ArrowIcon,
        component: Codebasecompliance,
        layout: '/merchant',
        exempt: true,
    },

    {
        path: '/scans/vulnerability',
        name: 'Analyses de vulnérabilités',
        sub_name: 'Add employee to your process',
        icon: ArrowIcon,
        component: VulnerabilityScan,
        layout: '/merchant',
    },
    {
        path: '/vulnerability',
        name: 'Scans de vulnérabilité',
        icon: ArrowIcon,
        component: VulnerabilityScan,
        layout: '/merchant',
        hasAccess: ['merchant', 'user', 'auditor', 'admin', 'support'],
        subRoute: true,
    },
];

const getRoutes = (plan, role, standard, custom_route) => {
    let complianceStandardRoutes;
    if (plan === 'demo' || plan === '3_days') {
        complianceStandardRoutes = frameWorkSubmenus?.filter((item) => item.name === 'PCIDSS');
    } else if (plan === 'free' || plan === 'val') {
        complianceStandardRoutes = frameWorkSubmenus?.filter((item) => item.name === 'NDPR');
    } else {
        complianceStandardRoutes = frameWorkSubmenus?.filter(
            (route) => standard && standard.includes(route.name.toLowerCase())
        );
    }

    const complianceSubmenus = [
        {
            name: 'Framework',
            sub_name: 'Add, update company policy',
            icon: ArrowIcon,
            layout: '/merchant',
            subMenus: complianceStandardRoutes,
        },
        {
            path: '/compliance/pcidss',
            name: 'PCIDSS',
            icon: ArrowIcon,
            component: PCIDSS,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/ndpr',
            name: 'NDPR',
            icon: ArrowIcon,
            component: NDPR,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/iso27001',
            name: 'ISO27001',
            component: ISO27001,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/iso27001+iso27035',
            name: 'ISO27017+ISO27032',
            component: ISO27001plusISO27035,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/iso27001_2',
            name: 'ISO27001-2022',
            component: ISO27001_2022,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/soc2',
            name: 'SOC2',
            component: SOC2,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/cii_directive',
            name: 'CII',
            component: CIIDIRECTIVE,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/blockchain',
            name: 'BLOCKCHAIN',
            component: BLOCKCHAIN,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/iso22301',
            name: 'ISO22301',
            component: ISO22301,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/pcidss4.0',
            name: 'PCIDSS4.0',
            component: PCIDSS4POINT0,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/kdpa',
            name: 'KDPA',
            component: Kdpa,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/gdpa',
            name: 'GDPA',
            component: Gdpa,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/pcislc',
            name: 'PCISLC',
            component: PCISLC,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/iso9001',
            name: 'ISO9001',
            component: ISO9001,
            layout: '/merchant',
            subRoute: true,
            isNew: true,
        },
        {
            path: '/compliance/audits',
            name: 'Audit',
            sub_name: 'Add employee to process',
            icon: ArrowIcon,
            component: AuditIndex,
            layout: '/merchant',
        },
        {
            path: '/compliance/audits/:standard',
            name: 'Create Audit',
            icon: ArrowIcon,
            component: AuditStandardsIndex,
            layout: '/merchant',
            hasAccess: ['merchant', 'admin', 'support'],
            subRoute: true,
        },
        {
            path: '/compliance/audits/preview/:standard',
            name: 'Preview Audit',
            icon: ArrowIcon,
            component: AuditPreviewIndex,
            layout: '/merchant',
            hasAccess: ['merchant', 'admin', 'support'],
            subRoute: true,
        },
        {
            path: '/compliance/audits/edit/:standard',
            name: 'Edit Audit',
            icon: ArrowIcon,
            component: AuditStandardsIndex,
            layout: '/merchant',
            hasAccess: ['merchant', 'admin', 'support'],
            subRoute: true,
        },
        {
            path: '/compliance/data-room',
            name: 'Data Room',
            sub_name: 'Train employee on courses',
            icon: ArrowIcon,
            component: DataRoom,
            layout: '/merchant',
        },
    ];
    const specialComplianceSubmenus = [
        {
            path: '/compliance/audits',
            name: 'Audit',
            sub_name: 'Add employee to process',
            icon: ArrowIcon,
            component: AuditIndex,
            layout: '/merchant',
        },
        {
            path: '/compliance/audits/:standard',
            name: 'Create Audit',
            icon: ArrowIcon,
            component: AuditStandardsIndex,
            layout: '/merchant',
            hasAccess: ['merchant', 'admin', 'support'],
            subRoute: true,
        },
        {
            path: '/compliance/audits/preview/:standard',
            name: 'Preview Audit',
            icon: ArrowIcon,
            component: AuditPreviewIndex,
            layout: '/merchant',
            hasAccess: ['merchant', 'admin', 'support'],
            subRoute: true,
        },
        {
            path: '/compliance/audits/edit/:standard',
            name: 'Edit Audit',
            icon: ArrowIcon,
            component: AuditStandardsIndex,
            layout: '/merchant',
            hasAccess: ['merchant', 'admin', 'support'],
            subRoute: true,
        },
        {
            path: '/compliance/data-room',
            name: 'Data Room',
            sub_name: 'Train employee on courses',
            icon: ArrowIcon,
            component: DataRoom,
            layout: '/merchant',
        },
    ];

    const complianceSubmenusFree = [
        {
            name: 'Framework',
            sub_name: 'Add, update company policy',
            icon: ArrowIcon,
            layout: '/merchant',
            subMenus: complianceStandardRoutes,
        },
        {
            path: '/compliance/pcidss',
            name: 'PCIDSS',
            icon: ArrowIcon,
            component: PCIDSS,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/ndpr',
            name: 'NDPR',
            icon: ArrowIcon,
            component: NDPR,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/iso27001',
            name: 'ISO27001',
            component: ISO27001,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/iso27001+iso27035',
            name: 'ISO27017+ISO27032',
            component: ISO27001plusISO27035,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/iso27001_2',
            name: 'ISO27001-2022',
            component: ISO27001_2022,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/soc2',
            name: 'SOC2',
            component: SOC2,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/cii_directive',
            name: 'CII',
            component: CIIDIRECTIVE,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/blockchain',
            name: 'BLOCKCHAIN',
            component: BLOCKCHAIN,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/iso22301',
            name: 'ISO22301',
            component: ISO22301,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/pcidss4.0',
            name: 'PCIDSS4.0',
            component: PCIDSS4POINT0,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/kdpa',
            name: 'KDPA',
            component: Kdpa,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/gdpa',
            name: 'GDPA',
            component: Gdpa,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/pcislc',
            name: 'PCISLC',
            component: PCISLC,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/iso9001',
            name: 'ISO9001',
            component: ISO9001,
            layout: '/merchant',
            subRoute: true,
            isNew: true,
        },
    ];

    const complianceSubmenusFr = [
        {
            name: 'Cadres',
            sub_name: 'Ajouter, mettre à jour la politique de l"entreprise.',
            icon: ArrowIcon,
            layout: '/merchant',
            subMenus: complianceStandardRoutes,
            // component: PCIDSS,
        },
        {
            path: '/compliance/pcidss',
            name: 'PCIDSS',
            icon: ArrowIcon,
            component: PCIDSS,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/ndpr',
            name: 'NDPR',
            icon: ArrowIcon,
            component: NDPR,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/iso27001',
            name: 'ISO27001',
            component: ISO27001,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/iso27001+iso27035',
            name: 'ISO27017+ISO27032',
            component: ISO27001plusISO27035,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/iso27001_2',
            name: 'ISO27001-2022',
            component: ISO27001_2022,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/soc2',
            name: 'SOC2',
            component: SOC2,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/cii_directive',
            name: 'CII',
            component: CIIDIRECTIVE,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/blockchain',
            name: 'BLOCKCHAIN',
            component: BLOCKCHAIN,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/iso22301',
            name: 'ISO22301',
            component: ISO22301,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/pcidss4.0',
            name: 'PCIDSS4.0',
            component: PCIDSS4POINT0,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/kdpa',
            name: 'KDPA',
            component: Kdpa,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/gdpa',
            name: 'GDPA',
            component: Gdpa,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/pcislc',
            name: 'PCISLC',
            component: PCISLC,
            layout: '/merchant',
            subRoute: true,
        },
        {
            path: '/compliance/iso9001',
            name: 'ISO9001',
            component: ISO9001,
            layout: '/merchant',
            subRoute: true,
            isNew: true,
        },
        {
            path: '/compliance/audits',
            name: 'Vérification" ou "Audit',
            sub_name: 'Ajouter un employé au processus',
            icon: ArrowIcon,
            component: AuditIndex,
            layout: '/merchant',
        },
        {
            path: '/audits/:standard',
            name: 'Créer un audit',
            icon: ArrowIcon,
            component: AuditStandardsIndex,
            layout: '/merchant',
            hasAccess: ['merchant', 'auditor', 'admin', 'support'],
            subRoute: true,
        },
        {
            path: '/audits/preview/:standard',
            name: "Aperçu de l'audit",
            icon: ArrowIcon,
            component: AuditPreviewIndex,
            layout: '/merchant',
            hasAccess: ['merchant', 'auditor', 'admin', 'support'],
            subRoute: true,
        },
        {
            path: '/audits/edit/:standard',
            name: "Modifier l'audit",
            icon: ArrowIcon,
            component: AuditStandardsIndex,
            layout: '/merchant',
            hasAccess: ['merchant', 'auditor', 'admin', 'support'],
            subRoute: true,
        },
        {
            path: '/data-room',
            name: 'Salle des données',
            sub_name: 'Former l"employé sur des cours',
            icon: ArrowIcon,
            component: DataRoom,
            layout: '/merchant',
        },
    ];

    const complianceSubmenusFrFree = [
        {
            name: 'Cadres',
            sub_name: 'Add, update company policy',
            icon: ArrowIcon,
            layout: '/merchant',
            subMenus: complianceStandardRoutes,
            // component: PCIDSS,
        },
        // {
        //     path: '/compliance/audits',
        //     name: 'Vérification" ou "Audit',
        //     sub_name: 'Add employee to process',
        //     icon: ArrowIcon,
        //     component: AuditIndex,
        //     layout: '/merchant',
        // },
        // {
        //     path: '/audits/:standard',
        //     name: 'Créer un audit',
        //     icon: ArrowIcon,
        //     component: AuditStandardsIndex,
        //     layout: '/merchant',
        //     hasAccess: ['merchant', 'auditor', 'admin', 'support'],
        //     subRoute: true,
        // },
        // {
        //     path: '/audits/preview/:standard',
        //     name: "Aperçu de l'audit",
        //     icon: ArrowIcon,
        //     component: AuditPreviewIndex,
        //     layout: '/merchant',
        //     hasAccess: ['merchant', 'auditor', 'admin', 'support'],
        //     subRoute: true,
        // },
        // {
        //     path: '/audits/edit/:standard',
        //     name: "Modifier l'audit",
        //     icon: ArrowIcon,
        //     component: AuditStandardsIndex,
        //     layout: '/merchant',
        //     hasAccess: ['merchant', 'auditor', 'admin', 'support'],
        //     subRoute: true,
        // },
        // {
        //     path: '/data-room',
        //     name: 'Salle des données',
        //     sub_name: 'Train employee on courses',
        //     icon: ArrowIcon,
        //     component: DataRoom,
        //     layout: '/merchant',
        // },
    ];

    const TrialRoutes = [
        {
            path: '/index',
            name: 'Overview',
            icon: HomeInactiveIcon,
            icon_active: HomeActiveIcon,
            component: DashboardIndex,
            layout: '/merchant',
            hasAccess: ['merchant', 'user', 'auditor', 'admin', 'support'],
            subRoute: false,
        },
        {
            name: 'Onboarding',
            icon: OnboardingInactiveIcon,
            icon_active: OnboardingActiveIcon,
            subMenus: onboardingSubmenus,
            hasAccess: ['merchant', 'admin', 'support', 'auditor'],
            subRoute: false,
            isNew: false,
        },
        {
            name: 'Compliance',
            icon: ComplianceInactiveIcon,
            icon_active: ComplianceActiveIcon,
            subMenus: complianceSubmenus,
            hasAccess: ['merchant', 'user', 'auditor', 'admin', 'support'],
            subRoute: false,
            isNew: true,
        },
        // {
        //     path: '/assessment/risk-asessement',
        //     name: 'Risk Management',
        //     icon: RiskInactiveIcon,
        //     component: RiskAssessmentRoot,
        //     layout: '/merchant',
        //     unavailable: false,
        //     hasAccess: ['merchant', 'admin', 'support', 'auditor'],
        //     subRoute: false,
        // },
        {
            name: 'Security Scans',
            icon: SecurityInactiveIcon,
            subMenus: securitySubmenus,
            hasAccess: ['merchant', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            name: 'AML/KYC',
            icon: AMLInactiveIcon,
            icon_active: AMLActiveIcon,
            subMenus: amlSubmenus,
            hasAccess: ['merchant', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            path: '/integrations',
            component: Integrations,
            name: 'Integrations',
            icon: IntegrationInactiveIcon,
            icon_active: IntegrationActiveIcon,
            layout: '/merchant',
            hasAccess: ['merchant', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            path: '/settings',
            // component: Log,
            name: 'Settings',
            icon: SettingsIcon,
            component: Settings,
            icon_active: SettingsActiveIcon,
            layout: '/merchant',
            hasAccess: ['merchant', 'admin', 'support', 'auditor'],
        },
        {
            path: '/activities',
            component: Log,
            name: 'Activities',
            icon: ActivityIcon,
            icon_active: ActivityIcon,
            layout: '/merchant',
            hasAccess: ['merchant', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
    ];

    const TrialRoutesFr = [
        {
            path: '/index',
            name: 'Aperçu',
            icon: HomeInactiveIcon,
            icon_active: HomeActiveIcon,
            component: DashboardIndex,
            layout: '/merchant',
            hasAccess: ['merchant', 'user', 'auditor', 'admin', 'support'],
            subRoute: false,
        },
        {
            name: 'Intégration',
            icon: OnboardingInactiveIcon,
            icon_active: OnboardingActiveIcon,
            subMenus: onboardingSubmenusFr,
            hasAccess: ['merchant', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            name: 'Conformité',
            icon: ComplianceInactiveIcon,
            icon_active: ComplianceActiveIcon,
            subMenus: complianceSubmenusFr,
            hasAccess: ['merchant', 'user', 'auditor', 'admin', 'support'],
            subRoute: false,
        },
        // {
        //     path: '/assessment/risk-asessement',
        //     name: 'Gestion des risques',
        //     icon: RiskInactiveIcon,
        //     component: RiskAssessmentRoot,
        //     layout: '/merchant',
        //     unavailable: false,
        //     hasAccess: ['merchant', 'admin', 'support', 'auditor'],
        //     subRoute: false,
        // },
        {
            name: 'Scans de sécurité',
            icon: SecurityInactiveIcon,
            subMenus: securitySubmenusFr,
            hasAccess: ['merchant', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            name: 'AML/KYC',
            icon: AMLInactiveIcon,
            icon_active: AMLActiveIcon,
            subMenus: amlSubmenusFr,
            hasAccess: ['merchant', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            path: '/settings',
            name: 'Paramètres',
            icon: SettingsIcon,
            component: Settings,
            icon_active: SettingsActiveIcon,
            layout: '/merchant',
            hasAccess: ['merchant', 'admin', 'support', 'auditor'],
        },
        {
            path: '/activities',
            component: Log,
            name: 'Activités',
            icon: ActivityIcon,
            icon_active: ActivityIcon,
            layout: '/merchant',
            hasAccess: ['merchant', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
    ];

    const freeTrialRoutes = [
        {
            path: '/index',
            name: 'Overview',
            icon: HomeInactiveIcon,
            icon_active: HomeActiveIcon,
            component: DashboardIndex,
            layout: '/merchant',
            hasAccess: ['merchant', 'user', 'auditor', 'admin', 'support'],
            subRoute: false,
        },
        {
            name: 'Onboarding',
            icon: OnboardingInactiveIcon,
            icon_active: OnboardingActiveIcon,
            subMenus: [
                {
                    path: '/onboarding/employee',
                    name: 'Employee onboarding',
                    sub_name: 'Configure your onboarding process',
                    icon: ArrowIcon,
                    component: Employee,
                    layout: '/merchant',
                },
            ],
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            name: 'Compliance',
            icon: ComplianceInactiveIcon,
            icon_active: ComplianceActiveIcon,
            subMenus: complianceSubmenusFree,
            hasAccess: ['merchant', 'user', 'auditor', 'admin', 'support'],
            subRoute: false,
        },
        // {
        //     path: '/assessment/risk-asessement',
        //     name: 'Risk Management',
        //     icon: RiskInactiveIcon,
        //     component: RiskAssessmentRoot,
        //     layout: '/merchant',
        //     unavailable: false,
        //     hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
        //     subRoute: false,
        // },
        {
            name: 'Security Scans',
            icon: SecurityInactiveIcon,
            subMenus: [
                {
                    path: '/scans/vulnerability',
                    name: 'Vulnerability Scans',
                    sub_name: 'Add employee to your process',
                    icon: ArrowIcon,
                    component: VulnerabilityMgt,
                    layout: '/merchant',
                },
                {
                    path: '/vulnerability',
                    name: 'Vulnerability Scans',
                    icon: ArrowIcon,
                    component: VulnerabilityScan,
                    layout: '/merchant',
                    hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
                    subRoute: true,
                },
                {
                    path: '/scans/pciscans',
                    name: 'PCI Scans',
                    sub_name: 'Add policies to your organization',
                    icon: ArrowIcon,
                    component: PCIScans,
                    layout: '/merchant',
                },
                {
                    path: '/scans/api-scans',
                    name: 'API Compliance Scans',
                    sub_name: 'Train employee on courses',
                    icon: ArrowIcon,
                    component: Apicompliance,
                    layout: '/merchant',
                    exempt: true,
                },
            ],
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            name: 'AML/KYC',
            icon: AMLInactiveIcon,
            icon_active: AMLActiveIcon,
            subMenus: amlSubmenus,
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            path: '/settings',
            // component: Log,
            name: 'Settings',
            icon: SettingsIcon,
            component: Settings,
            icon_active: SettingsActiveIcon,
            layout: '/merchant',
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
        },
        {
            path: '/activities',
            name: 'Activities',
            icon: ActivityIcon,
            icon_active: ActivityIcon,
            component: Log,
            layout: '/merchant',
            hasAccess: ['merchant', 'user', 'auditor', 'admin', 'support'],
        },
    ];

    const freeTrialRoutesFr = [
        {
            path: '/index',
            name: 'Aperçu',
            icon: HomeInactiveIcon,
            icon_active: HomeActiveIcon,
            component: DashboardIndex,
            layout: '/merchant',
            hasAccess: ['merchant', 'user', 'auditor', 'admin', 'support'],
        },
        {
            name: 'Intégration',
            icon: OnboardingInactiveIcon,
            icon_active: OnboardingActiveIcon,
            subMenus: [
                {
                    path: '/onboarding/employee',
                    name: 'Intégration des employés',
                    sub_name: "Configurez votre processus d'intégration",
                    icon: ArrowIcon,
                    component: Employee,
                    layout: '/merchant',
                },
            ],
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            name: 'Conformité',
            icon: ComplianceInactiveIcon,
            icon_active: ComplianceActiveIcon,
            subMenus: complianceSubmenusFrFree,
            hasAccess: ['merchant', 'user', 'auditor', 'admin', 'support'],
            subRoute: false,
        },
        // {
        //     path: '/assessment/risk-asessement',
        //     name: 'Gestion des risques',
        //     icon: RiskInactiveIcon,
        //     component: RiskAssessmentRoot,
        //     layout: '/merchant',
        //     unavailable: false,
        //     hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
        //     subRoute: false,
        // },
        {
            name: 'Scans de sécurité',
            icon: SecurityInactiveIcon,
            subMenus: [
                {
                    path: '/scans/vulnerability',
                    name: 'Analyses de vulnérabilités',
                    sub_name: 'Add employee to your process',
                    icon: ArrowIcon,
                    component: VulnerabilityScan,
                    layout: '/merchant',
                },
                {
                    path: '/vulnerability',
                    name: 'Scans de vulnérabilité',
                    icon: ArrowIcon,
                    component: VulnerabilityScan,
                    layout: '/merchant',
                    hasAccess: ['merchant', 'user', 'auditor', 'admin', 'support'],
                    subRoute: true,
                },
                {
                    path: '/scans/pciscans',
                    name: 'Analyses PCI',
                    sub_name: 'Add policies to your organization',
                    icon: ArrowIcon,
                    component: PCIScans,
                    layout: '/merchant',
                },
                {
                    path: '/scans/api-scans',
                    name: 'Scans de conformité des API',
                    sub_name: 'Train employee on courses',
                    icon: ArrowIcon,
                    component: Apicompliance,
                    layout: '/merchant',
                    exempt: true,
                },
            ],
            hasAccess: ['merchant', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            name: 'AML/KYC',
            icon: AMLInactiveIcon,
            icon_active: AMLActiveIcon,
            subMenus: amlSubmenus,
            hasAccess: ['merchant', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            path: '/settings',
            name: 'Paramètres',
            icon: SettingsIcon,
            component: Settings,
            icon_active: SettingsActiveIcon,
            layout: '/merchant',
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
        },
        {
            path: '/activities',
            component: Log,
            name: 'Activités',
            icon: ActivityIcon,
            icon_active: ActivityIcon,
            layout: '/merchant',
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
    ];

    const SpecialRoutes = [
        {
            name: 'Compliance',
            icon: ComplianceInactiveIcon,
            icon_active: ComplianceActiveIcon,
            subMenus: specialComplianceSubmenus,
            hasAccess: ['merchant', 'user', 'auditor', 'admin', 'support', 'implementer'],
            subRoute: false,
        },
    ];

    const premiumRoutes = [
        {
            path: '/index',
            name: 'Overview',
            icon: HomeIcon,
            icon_active: HomeActiveIcon,
            component: DashboardIndex,
            layout: '/merchant',
            hasAccess: ['merchant', 'user', 'auditor', 'admin', 'support'],
            subRoute: false,
        },
        {
            name: 'Onboarding',
            icon: OnboardingInactiveIcon,
            icon_active: OnboardingActiveIcon,
            subMenus: onboardingSubmenus,
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            name: 'Compliance',
            icon: ComplianceInactiveIcon,
            icon_active: ComplianceActiveIcon,
            subMenus: complianceSubmenus,
            hasAccess: ['merchant', 'user', 'auditor', 'admin', 'support'],
            subRoute: false,
        },
        {
            path: '/assessment/risk-asessement',
            name: 'Risk Management',
            icon: RiskInactiveIcon,
            component: RiskAssessmentRoot,
            layout: '/merchant',
            unavailable: false,
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            name: 'Security Scans',
            icon: SecurityInactiveIcon,
            subMenus: securitySubmenus,
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            name: 'AML/KYC',
            icon: AMLInactiveIcon,
            icon_active: AMLActiveIcon,
            subMenus: amlSubmenus,
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            path: '/integrations',
            component: Integrations,
            name: 'Integrations',
            icon: IntegrationInactiveIcon,
            icon_active: IntegrationActiveIcon,
            layout: '/merchant',
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            path: '/settings',
            // component: Log,
            name: 'Settings',
            icon: SettingsIcon,
            component: Settings,
            icon_active: SettingsActiveIcon,
            layout: '/merchant',
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
        },
        {
            path: '/activities',
            component: Log,
            name: 'Activities',
            icon: ActivityIcon,
            icon_active: ActivityIcon,
            layout: '/merchant',
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
    ];

    const premiumRoutesFr = [
        {
            path: '/index',
            name: 'Aperçu',
            icon: HomeInactiveIcon,
            icon_active: HomeActiveIcon,
            component: DashboardIndex,
            layout: '/merchant',
            hasAccess: ['merchant', 'user', 'auditor', 'admin', 'support'],
            subRoute: false,
        },
        {
            name: 'Intégration',
            icon: OnboardingInactiveIcon,
            icon_active: OnboardingActiveIcon,
            subMenus: onboardingSubmenus,
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            name: 'Conformité',
            icon: ComplianceInactiveIcon,
            icon_active: ComplianceActiveIcon,
            subMenus: complianceSubmenusFr,
            hasAccess: ['merchant', 'user', 'auditor', 'admin', 'support'],
            subRoute: false,
        },
        {
            path: '/assessment/risk-asessement',
            name: 'Gestion des risques',
            icon: RiskInactiveIcon,
            component: RiskAssessmentRoot,
            layout: '/merchant',
            unavailable: false,
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            name: 'Scans de sécurité',
            icon: SecurityInactiveIcon,
            subMenus: securitySubmenusFr,
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            name: 'AML/KYC',
            icon: AMLInactiveIcon,
            icon_active: AMLActiveIcon,
            subMenus: amlSubmenusFr,
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            path: '/integrations',
            component: Integrations,
            name: 'Intégrations',
            icon: IntegrationInactiveIcon,
            icon_active: IntegrationActiveIcon,
            layout: '/merchant',
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
        {
            path: '/settings',
            name: 'Paramètres',
            icon: SettingsIcon,
            component: Settings,
            icon_active: SettingsActiveIcon,
            layout: '/merchant',
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
        },
        {
            path: '/activities',
            name: 'Activités',
            icon: ActivityIcon,
            icon_active: ActivityIcon,
            component: Log,
            layout: '/merchant',
            hasAccess: ['merchant', 'user', 'admin', 'support', 'auditor'],
            subRoute: false,
        },
    ];

    const language = window.localStorage.i18nextLng;
    if (plan === 'special') {
        return SpecialRoutes.filter((r) => {
            return r.hasAccess?.includes(role);
        });
        // return premiumRoutes.filter((r) => {
        //     return r.hasAccess?.includes(role);
        // });
    } else if (plan === 'demo' || plan === 'val') {
        if (language?.includes('fr')) {
            const trialRoutes =
                plan === 'val'
                    ? [
                          ...freeTrialRoutesFr.slice(0, 6),
                          {
                              path: '/data-room',
                              name: 'Data Room',
                              icon: Topic,
                              component: DataRoom,
                              layout: '/merchant',
                              hasAccess: ['merchant', 'admin', 'user', 'support', 'auditor'],
                              subRoute: false,
                              isNew: true,
                          },
                          ...freeTrialRoutesFr.slice(6),
                      ]
                    : freeTrialRoutesFr;
            return trialRoutes?.filter((r) => {
                return r.hasAccess?.includes(role);
            });
        } else if (plan === '3_days') {
            if (language?.includes('fr')) {
                return TrialRoutesFr.filter((r) => {
                    return r.hasAccess?.includes(role);
                });
            } else
                return TrialRoutes.filter((r) => {
                    return r.hasAccess?.includes(role);
                });
        } else {
            const trialRoutes =
                plan === 'val'
                    ? [
                          ...freeTrialRoutes.slice(0, 6),
                          {
                              path: '/data-room',
                              name: 'Data Room',
                              icon: Topic,
                              component: DataRoom,
                              layout: '/merchant',
                              hasAccess: ['merchant', 'admin', 'user', 'support', 'auditor'],
                              subRoute: false,
                              isNew: true,
                          },
                          ...freeTrialRoutes.slice(6),
                      ]
                    : freeTrialRoutes;
            return trialRoutes?.filter((r) => {
                return r.hasAccess?.includes(role);
            });
        }
    } else if (plan === '3_days') {
        if (language?.includes('fr')) {
            return TrialRoutesFr.filter((r) => {
                return r.hasAccess?.includes(role);
            });
        } else
            return TrialRoutes.filter((r) => {
                return r.hasAccess?.includes(role);
            });
    } else if (plan === 'custom') {
        console.log({ custom_route });
        if (language?.includes('fr')) {
            return premiumRoutesFr.filter((r) => {
                return r.hasAccess?.includes(role);
            });
        } else
            return premiumRoutes.filter((r) => {
                return r.hasAccess?.includes(role);
            });
    } else if (plan === 'main') {
        if (language?.includes('fr')) {
            return premiumRoutesFr.filter((r) => {
                return r.hasAccess?.includes(role);
            });
        } else
            return premiumRoutes.filter((r) => {
                return r.hasAccess?.includes(role);
            });
    } else if (plan === 'customized') {
        if (language?.includes('fr')) {
            return freeTrialRoutesFr.filter((r) => {
                return r.hasAccess?.includes(role);
            });
        } else
            return freeTrialRoutes.filter((r) => {
                return r.hasAccess?.includes(role);
            });
    } else {
        if (language?.includes('fr')) {
            return freeTrialRoutesFr.filter((r) => {
                return r.hasAccess?.includes(role);
            });
        } else
            return freeTrialRoutes.filter((r) => {
                return r.hasAccess?.includes(role);
            });
    }
};
export default getRoutes;
