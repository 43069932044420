import { Box, Stack, Typography, useTheme } from '@mui/material';
import { West } from '@mui/icons-material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import AppButton from 'components/new_components/AppButton';
import { ReactComponent as CertificateBadge } from 'assets/img/icons/certi-badge.svg';
import Brand from 'components/new_components/Brand';
import html2canvas from 'html2canvas';
// functions
import { connect } from 'react-redux';
import { GetTrainingById, GetTraineeDetails } from 'store/actions/trainingActions';
import { toast } from 'react-toastify';
import LoadingState from 'components/new_components/LoadingState';

const TrainingCertificate = (props) => {
    const { GetTrainingById, one_training, GetTraineeDetails, one_trainee, location } = props;
    const { training_id } = useParams();
    const theme = useTheme();
    const certRef = useRef();

    // state
    const [loading, setLoading] = useState(false);

    // memos
    const course = useMemo(() => one_training?.course_info, [one_training]);

    // functions
    const generateCertificate = () => {
        html2canvas(certRef.current).then(function (canvas) {
            download(canvas);
        });
    };
    const download = (canvas) => {
        var link = document.createElement('a');
        link.download = 'certificate.png';
        link.href = canvas.toDataURL();
        link.click();
    };
    const getTrainingById = async () => {
        setLoading(true);
        const res = await GetTrainingById(training_id);
        setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong, couldn't fetch training details.");
        }
    };
    const getTrainee = async () => {
        setLoading(true);
        const res = await GetTraineeDetails(one_training?.enrollee);
        setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong, couldn't get your details.");
        }
    };

    // useEffect
    useEffect(() => {
        if (training_id) {
            getTrainingById();
        }
    }, [training_id]);
    useEffect(() => {
        if (!one_training) return;
        getTrainee();
    }, [one_training?.enrollee]);

    return (
        <Box sx={{ maxWidth: 1150, mx: 'auto', my: 6 }}>
            {location?.state?.from_training && (
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pb: 6 }}>
                    <Box
                        component={Link}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.primary[900],
                            textDecoration: 'none',
                            gap: 1,
                            m: 2,
                        }}
                        to={`/training/${training_id}`}
                    >
                        <West color="primary" />
                        <Typography sx={{ color: theme.palette.primary[900], fontWeight: 700, textDecoration: 'none' }}>
                            Back to training
                        </Typography>
                    </Box>
                    <AppButton
                        name="Download Certificate"
                        color="primary"
                        variant="contained"
                        onClick={generateCertificate}
                    />
                </Stack>
            )}
            {/* certificate */}
            {loading ? (
                <Box sx={{ height: '100%' }}>
                    <LoadingState />
                </Box>
            ) : (
                <Box
                    sx={{
                        border: `20px solid ${theme.palette.primary[900]}`,
                        height: 800,
                        p: 6,
                        position: 'relative',
                        minWidth: 1100,
                    }}
                    ref={certRef}
                >
                    <Box sx={{ position: 'absolute', top: '0px', left: 105, zIndex: 1 }}>
                        <CertificateBadge />
                    </Box>
                    {/* fat yellow border */}
                    <Box
                        sx={{
                            border: `15px solid ${theme.palette.warning[900]}`,
                            height: '100%',
                            position: 'relative',
                            padding: '11px',
                            '&::before, &::after': {
                                content: '""',
                                position: 'absolute',
                                backgroundColor: theme.palette.primary[900],
                                width: 17,
                                height: 28,
                            },
                            '&::before': {
                                bottom: 0,
                                left: 0,
                            },
                            '&::after': {
                                top: 0,
                                right: 0,
                            },
                        }}
                    >
                        {/* thin yellow  border */}
                        <Stack
                            justifyContent="space-between"
                            sx={{ border: `6px solid ${theme.palette.warning[900]}`, height: '100%' }}
                        >
                            {/* content */}
                            <Box component="header" sx={{ ml: '141px', width: 702, textAlign: 'center', pt: 4 }}>
                                <Typography
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: '40px',
                                        textTransform: 'uppercase',
                                        color: theme.palette.primary[900],
                                        pb: 2,
                                    }}
                                >
                                    Certificate of completion
                                </Typography>
                                <Box
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#E1E2EC',
                                        height: '3px',
                                        position: 'relative',
                                        '&::before, &::after': {
                                            content: '""',
                                            width: 20,
                                            height: 20,
                                            display: 'block',
                                            position: 'absolute',
                                            borderRadius: '50%',
                                            top: -8.5,
                                            backgroundColor: '#E1E2EC',
                                        },
                                        '&::before': {
                                            left: -10,
                                        },
                                        '&::after': {
                                            right: -10,
                                        },
                                    }}
                                />
                            </Box>
                            {/* body */}
                            <Stack sx={{ flex: 1, mt: 9, textAlign: 'center', width: '90%', mx: 'auto' }}>
                                <Typography sx={{ fontWeight: 700, fontSize: 16, color: theme.palette.gray[40] }}>
                                    This is to certify that
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: 40,
                                        color: theme.palette.gray[30],
                                        mt: 5,
                                        mb: 4,
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {one_trainee?.first_name} {one_trainee?.last_name}
                                </Typography>
                                <Typography sx={{ fontWeight: 700, fontSize: 16, color: theme.palette.gray[40] }}>
                                    Has successfully completed the professional {course?.title} training course offered
                                    by SmartcomplyApp on{' '}
                                    {new Date(one_training?.certificate_date).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    })}
                                </Typography>
                            </Stack>
                            {/* footer */}
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-end"
                                component="footer"
                            >
                                <Stack sx={{ textAlign: 'center', mx: 4, my: 4 }}>
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: 14,
                                            color: '#757780',
                                            // lineHeight: 0,
                                        }}
                                    >
                                        {one_training?.certificate_number}
                                    </Typography>
                                    <Box sx={{ width: '100%', height: '1px', backgroundColor: '#aaabb4', my: 1 }} />
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: 10,
                                            color: '#757780',
                                            lineHeight: 0,
                                        }}
                                    >
                                        Certificate No.
                                    </Typography>
                                </Stack>
                                <Brand type="blue" />
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
            )}
        </Box>
    );
};
const mapStateToProps = (state) => ({
    one_training: state?.trainingReducers?.one_training,
    one_trainee: state?.trainingReducers?.one_trainee,
});
export default connect(mapStateToProps, { GetTrainingById, GetTraineeDetails })(TrainingCertificate);
