import { Drawer, Row, Col, Tag, Button } from 'antd';
import React from 'react';

const ViewUserModal = (props) => {
    const { visible, closeDrawer, record, openAssignmentModal } = props;
    const handleViewRequest = () => {
        closeDrawer();
        openAssignmentModal(record);
    };
    return (
        <div>
            <Drawer
                title={`User Details for ${record?.owner_detail?.first_name} ${record?.owner_detail?.last_name}`}
                open={visible}
                width="800px"
                getContainer={false}
                onClose={closeDrawer}
                destroyOnClose={true}
                contentWrapperStyle={{
                    overflow: 'auto',
                    // paddingBottom: '14px',
                }}
                className="h-full"
            >
                <div className="userdataContainer">
                    <div className="item">
                        <Row gutter={28} align="middle">
                            <Col xs={12} xl={8} lg={8}>
                                <span>Name:</span>
                            </Col>
                            <Col xs={12} xl={12} lg={12}>
                                {record?.owner_detail?.first_name} {record?.owner_detail?.last_name}
                            </Col>
                        </Row>
                    </div>
                    <div className="item">
                        <Row gutter={28} align="middle">
                            <Col xs={12} xl={8} lg={8}>
                                <span>Email:</span>
                            </Col>
                            <Col xs={12} xl={12} lg={12}>
                                {record?.owner_detail?.email}
                            </Col>
                        </Row>
                    </div>
                    <div className="item">
                        <Row gutter={28} align="middle">
                            <Col xs={12} xl={8} lg={8}>
                                <span>Phone:</span>
                            </Col>
                            <Col xs={12} xl={12} lg={12}>
                                {record?.phone_number || 'N/A'}
                            </Col>
                        </Row>
                    </div>
                    <div className="item">
                        <Row gutter={28} align="middle">
                            <Col xs={12} xl={8} lg={8}>
                                <span>Verification Process:</span>
                            </Col>
                            <Col xs={12} xl={12} lg={12}>
                                {record?.verification_process || 'N/A'}
                            </Col>
                        </Row>
                    </div>
                    <div className="item">
                        <Row gutter={28} align="middle">
                            <Col xs={12} xl={8} lg={8}>
                                <span>Auditor Request Status</span>
                            </Col>
                            <Col xs={12} xl={12} lg={12}>
                                {record?.auditor_detail?.name ? (
                                    <Tag color="green">APPROVED</Tag>
                                ) : record?.seek_auditor ? (
                                    <Tag color="yellow">PENDING</Tag>
                                ) : (
                                    <Tag color="red">NO REQUEST</Tag>
                                )}
                            </Col>
                        </Row>
                    </div>
                    {record?.seek_auditor && (
                        <div className="item">
                            <Row gutter={28} align="middle">
                                <Col xs={12} xl={8} lg={8}>
                                    <span>Auditor Request</span>
                                </Col>
                                <Col xs={12} xl={12} lg={12}>
                                    <Button onClick={handleViewRequest} size="small">
                                        View Request
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>
            </Drawer>
        </div>
    );
};

export default ViewUserModal;
