import { Box, Card, Fade, Stack, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { ReactComponent as VulnerabilitySocIcon } from 'assets/img/vulnerability-soc.svg';
import { ReactComponent as VulnerabilityScanIcon } from 'assets/img/vulnerability-scan-icon.svg';
import { ReactComponent as CyberSocIcon } from 'assets/img/brand/cybersoc-logo.svg';
import AppButton from 'components/new_components/AppButton';

// redux
import { connect, useSelector } from 'react-redux';
import { GetSocToken } from 'store/actions/authActions';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import AppBackdrop from 'components/new_components/AppBackdrop';
import LoadingState from 'components/new_components/LoadingState';

//translations
import { useTranslation } from 'react-i18next';
import { getType } from 'utils';

const VulnerabilityMgt = (props) => {
    const { GetSocToken } = props;
    const history = useHistory();
    const theme = useTheme();
    const role = getType('role');
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);

    // state
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    //translation
    const { t } = useTranslation('vulnerabilityManagement');

    // fucntions
    const getSocToken = async () => {
        setLoading(true);
        const res = await GetSocToken();
        if (res?.success) {
            setSuccess(true);
            window.open(`https://ksiem.sectica.com/login?tsc=${res?.token}`, '_blank');
            setLoading(false);
        } else {
            setSuccess(false);
            setLoading(false);
            toast.error(res?.message);
        }
    };

    return (
        <Box
            sx={{
                m: -3,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 5,
                pt: 8,
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    zIndex: loading ? 800 : 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <AppBackdrop open={loading} sx={{ backgroundColor: 'rgba(0,0,0,0.7)' }} />
                <Fade in={loading}>
                    <Box sx={{ zIndex: 102 }}>
                        <LoadingState
                            label={
                                <Typography sx={{ color: theme.palette.white.main, fontWeight: 600 }}>
                                    {success ? t('authentication') : t('token')}
                                </Typography>
                            }
                        />
                    </Box>
                </Fade>
            </Box>
            {role !== 'auditor' && (
                <VulnerabilityCard
                    title={t('vulnerabilityCard1.title')}
                    description={t('vulnerabilityCard1.description')}
                    buttonText={t('vulnerabilityCard1.buttonText')}
                    onClick={getSocToken}
                    icon={<VulnerabilitySocIcon />}
                    isNew={true}
                    isPartner={true}
                    buttonDisabled={!merchant_info?.soc_approved}
                    partnerLogo={<CyberSocIcon />}
                />
            )}
            <VulnerabilityCard
                title={t('vulnerabilityCard2.title')}
                description={t('vulnerabilityCard2.description')}
                buttonText={t('vulnerabilityCard2.buttonText')}
                onClick={() => history.push('/merchant/vulnerability')}
                icon={<VulnerabilityScanIcon />}
                buttonDisabled={role === 'auditor'}
            />
        </Box>
    );
};
const VulnerabilityCard = (props) => {
    const { title, description, buttonText, onClick, icon, isNew, isPartner, partnerLogo, buttonDisabled } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('vulnerabilityManagement');

    return (
        <NewAppCard sx={{ maxWidth: 850, pb: 8, width: '90%', position: 'relative' }}>
            <Stack direction="row" alignItems="center" sx={{ gap: 9 }}>
                <Box sx={{ width: 130 }}>{icon}</Box>
                <Box>
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: 20,
                            color: theme.palette.gray[40],
                            position: 'relative',
                            width: 'fit-content',
                            '&::after': {
                                content: '"NEW"',
                                display: isNew ? 'block' : 'none',
                                position: 'absolute',
                                top: 0,
                                right: -25,
                                background: theme.palette.error[60],
                                color: theme.palette.white.main,
                                borderRadius: 0.5,
                                p: 0.25,
                                fontWeight: 700,
                                fontSize: 8,
                            },
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{
                            mt: 3,
                            mb: 5,
                            color: theme.palette.gray[40],
                            fontSize: 14,
                            fontWeight: 500,
                            maxWidth: 550,
                        }}
                    >
                        {description}
                    </Typography>
                    <AppButton
                        name={buttonText}
                        color="primary"
                        variant="contained"
                        onClick={onClick}
                        disabled={buttonDisabled}
                    />
                </Box>
            </Stack>
            {isPartner && (
                <Stack alignItems="center" sx={{ position: 'absolute', bottom: 20, right: 40, ml: 'auto' }}>
                    <Typography sx={{ fontWeight: 600, fontSize: 10, color: theme.palette.gray[40] }}>
                        {t('poweredBy')}
                    </Typography>
                    {partnerLogo}
                </Stack>
            )}
        </NewAppCard>
    );
};

const NewAppCard = (props) => {
    const { children, sx = {} } = props;
    const theme = useTheme();
    return (
        <Card
            sx={{
                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
                border: `1px solid ${theme.palette.gray[90]}66`,
                borderRadius: 2,
                backgroundColor: theme.palette.shades.white,
                p: 2.5,
                ...sx,
            }}
        >
            {children}
        </Card>
    );
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, { GetSocToken })(VulnerabilityMgt);
