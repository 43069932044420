import React, { useEffect, useState } from 'react';
import { Box, Typography, Stack, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OutlinedLayout from 'components/new_components/tabs/OutlinedLayout';
import moment from 'moment';

//redux
import { connect } from 'react-redux';
import { GetGroupScanResultsDetails } from 'store/actions/automatedScansActions';

//core components
import Vulnerabilities from './modalComponents/Vulnerabilities';
import AvailableStandards from './modalComponents/AvailableStandards';
import CircularLoader from 'components/Loaders/CircularLoader';
import { toast } from 'react-toastify';
import CorrectiveMeasures from './modalComponents/CorrectiveMeasures';
import Recommendations from './modalComponents/Recommendations';
import AppViewModal from 'components/new_components/AppViewModal';

const ScanViewModal = (props) => {
    const { scan_details, open, handleClose, scan_items, GetGroupScanResultsDetails, sub, isScan, category } = props;
    const [indexValue, setIndexValue] = useState(1);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();

    const tabList = [
        {
            id: 1,
            name: 'Scan Info',
        },
        {
            id: 2,
            name: 'Help',
        },
    ];

    const onChangeTab = async (val) => {
        setIndexValue(val);
    };

    // functions
    const getGroupScanResultsDetails = async () => {
        setLoading(true);
        const res = await GetGroupScanResultsDetails();
        setLoading(false);
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (open) {
            getGroupScanResultsDetails();
        }
        return () => {
            setLoading(false);
        };
    }, [open]);
    const failed_scan =
        scan_details?.accounts !== undefined &&
        scan_details?.accounts !== null &&
        Object?.keys(scan_details?.accounts)?.length &&
        Object?.keys(scan_details?.accounts)?.find((scan) => scan_details?.accounts[scan] === 'failed');

    const filtered_scan_items = (scan_items || [])?.filter((scan) => scan?.subcategory === scan_details?.id);
    return (
        <AppViewModal open={open} handleClose={handleClose} width={600}>
            <Box sx={{ my: 1.5 }}>
                <Box sx={{ display: 'flex', mx: 3, pb: 1 }}>
                    <ArrowBackIosIcon
                        onClick={handleClose}
                        sx={{ width: 18, height: 18, color: theme.palette.gray[30], cursor: 'pointer' }}
                    />
                    <Stack direction="column" sx={{ ml: 2, mt: '-0.2rem' }}>
                        <Typography sx={{ fontWeight: 600, fontSize: '13px', color: theme.palette.gray[30] }}>
                            {!isScan ? 'Exempted Scans' : scan_details?.name?.replace(/_/g, ' ')}
                        </Typography>
                        {isScan ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.3 }}>
                                <Typography sx={{ fontWeight: 400, fontSize: '10px', color: theme.palette.gray[40] }}>
                                    Scanned about {moment(scan_details?.accounts?.scan_time).fromNow()}
                                </Typography>
                                {failed_scan && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1.5 }}>
                                        <span
                                            style={{
                                                height: '8px',
                                                width: '8px',
                                                borderRadius: '30%',
                                                marginRight: '8px',
                                                backgroundColor: theme.palette.error[500],
                                                display: 'inline-block',
                                                fontSize: '12px',
                                            }}
                                        ></span>
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: '10px',
                                                color: theme.palette.neutral.main,
                                            }}
                                        >
                                            Needs Attention
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Typography sx={{ fontWeight: 400, fontSize: '10px', color: theme.palette.gray[40] }}>
                                {category?.name}
                            </Typography>
                        )}
                    </Stack>
                </Box>
                <Divider sx={{ color: theme.palette.neutral[95] }} />
                {isScan ? (
                    <>
                        {loading ? (
                            <CircularLoader />
                        ) : (
                            <Box>
                                <OutlinedLayout
                                    tabList={tabList}
                                    indexValue={indexValue}
                                    onChangeTab={onChangeTab}
                                    border
                                    color="#395BA9"
                                />
                                <Box sx={{ mx: 1 }}>
                                    {indexValue === 1 && (
                                        <Box>
                                            <Vulnerabilities
                                                scan_details={scan_details}
                                                sub={sub}
                                                isScan={isScan}
                                                category={category}
                                            />
                                            <AvailableStandards scan_items={filtered_scan_items} />
                                        </Box>
                                    )}
                                    {indexValue === 2 && (
                                        <Box>
                                            <CorrectiveMeasures scan_items={filtered_scan_items} />
                                            <Recommendations scan_items={filtered_scan_items} />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </>
                ) : (
                    <Vulnerabilities isScan={isScan} sub={sub} />
                )}
            </Box>
        </AppViewModal>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
        scan_items: state?.automatedScansReducers?.scan_items,
    };
};
export default connect(mapStateToProps, { GetGroupScanResultsDetails })(ScanViewModal);
