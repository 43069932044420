import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, Card, Stack, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

const ExceptionGroupListItem = (props) => {
    const { group, active, handleGroupChange } = props;
    const theme = useTheme();
    return (
        <Card
            elevation={0}
            component={Button}
            sx={{
                textTransform: 'unset',
                justifyContent: 'space-between',
                border: '1px solid',
                borderColor: active === group?.id ? theme.palette.secondary[700] : theme.palette.gray[300],
                borderRadius: 1,
                my: 1,
                fontSize: 14,
                '&, &:hover': {
                    backgroundColor: active === group?.id ? theme.palette.secondary[300] : theme.palette.white.main,
                },
            }}
            onClick={() => handleGroupChange(group?.id)}
        >
            <Stack
                sx={{
                    textAlign: 'left',
                }}
            >
                <Typography
                    sx={{
                        color: theme.palette.primary[900],
                        fontWeight: 500,
                    }}
                >
                    {group?.title}
                </Typography>
                <Typography sx={{ fontWeight: 500, fontSize: 12, color: theme.palette.gray[600] }}>
                    {group?.description}
                </Typography>
            </Stack>
            <ChevronRight sx={{ color: theme.palette.gray[900], fontSize: 18 }} />
        </Card>
    );
};

export default ExceptionGroupListItem;
