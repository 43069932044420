import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Tabs, Row, Col, Spin } from 'antd';

// core component
import GdpaDocumentsCardsView from './GdpaDocumentsCardsView';
import BreadCrumb from 'components/Breadcrumb';
import GdpaFormsView from './GdpaFormsView';

// icons
import { FileOutlined, FormOutlined } from '@ant-design/icons';

// redux
import { connect } from 'react-redux';
import { GetAllDocumentsByGdpaCategory } from 'store/actions/adminActions';

// sub compliance
const { TabPane } = Tabs;

const FormsAndDocumentsView = (props) => {
    const { location, GetAllDocumentsByGdpaCategory, all_gdpa_documents } = props;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getAllGdpaDocuments = async () => {
            setLoading(true);
            const res = await GetAllDocumentsByGdpaCategory(location?.state?.category?.id);
            if (res?.success) {
                setLoading(false);
            } else {
                setLoading(false);
                toast.error(res?.message);
            }
        };
        getAllGdpaDocuments();
    }, [location?.state?.category?.id]);

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[{ label: 'GDPA', link: '/gdpa' }, { label: location?.state?.category?.title }]}
                    />
                </Col>
            </Row>
            <section className="my-3">
                <Tabs defaultActiveKey="1">
                    <TabPane
                        tab={
                            <span>
                                <FileOutlined />
                                Documents
                            </span>
                        }
                        key="1"
                    >
                        {loading ? (
                            <Row justify="center">
                                <Spin size="large" />
                            </Row>
                        ) : (
                            <GdpaDocumentsCardsView
                                category_id={location?.state?.category?.id}
                                all_documents_by_subreq={all_gdpa_documents}
                            />
                        )}
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <FormOutlined />
                                Audit Questions
                            </span>
                        }
                        key="2"
                    >
                        {loading ? (
                            <Row justify="center">
                                <Spin size="large" />
                            </Row>
                        ) : (
                            <GdpaFormsView category_id={location?.state?.category?.id} />
                        )}
                    </TabPane>
                </Tabs>
            </section>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        all_gdpa_documents: state?.adminReducers?.all_gdpa_documents,
    };
};
export default connect(mapStateToProps, {
    GetAllDocumentsByGdpaCategory,
})(FormsAndDocumentsView);
