import React from 'react';
import { Breadcrumb, Row, Col, Card } from 'antd';

const MainDashboard = () => {
    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <span>Dashboard</span>
                </Breadcrumb.Item>
            </Breadcrumb>
            <div className="my-3">
                <Row gutter={28}>
                    <Col xs={24} xl={6} lg={6}>
                        <div className="">
                            <Card title="Card title" bordered={true} style={{ maxWidth: '300px' }}>
                                <p>Card content</p>
                                <p>Card content</p>
                                <p>Card content</p>
                            </Card>
                        </div>
                    </Col>
                    <Col xs={24} xl={6} lg={6}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="Card title" bordered={true} style={{ maxWidth: '300px' }}>
                                <p>Card content</p>
                                <p>Card content</p>
                                <p>Card content</p>
                            </Card>
                        </div>
                    </Col>
                    <Col xs={24} xl={6} lg={6}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="Card title" bordered={true} style={{ maxWidth: '300px' }}>
                                <p>Card content</p>
                                <p>Card content</p>
                                <p>Card content</p>
                            </Card>
                        </div>
                    </Col>
                    <Col xs={24} xl={6} lg={6}>
                        <div className="site-card-border-less-wrapper">
                            <Card title="Card title" bordered={true} style={{ maxWidth: '300px' }}>
                                <p>Card content</p>
                                <p>Card content</p>
                                <p>Card content</p>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default MainDashboard;
