import { CancelRounded } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppFormSelect } from 'components/new_components/forms';
import { AppFormInput } from 'components/new_components/forms';
import { AppForm } from 'components/new_components/forms';
import { AppSubmitButton } from 'components/new_components/forms';
import React, { useMemo, useState } from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { requestToolValidation } from '../utils';
import { connect } from 'react-redux';
import { RequestATool } from 'store/actions/integrationsActions';
import { toast } from 'react-toastify';

//translations
import { useTranslation } from 'react-i18next';

const RequestToolModal = (props) => {
    const theme = useTheme();
    // props
    const { open, handleClose, RequestATool, integrations_groups } = props;

    //translation
    const { t } = useTranslation('setups');

    // memos
    const groups = useMemo(() => {
        return integrations_groups?.map((group) => ({ name: group?.name, value: group?.id })) || [];
    }, [integrations_groups]);
    // state
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {
        setLoading(true);
        const res = await RequestATool(values);
        setLoading(false);
        if (res?.success) {
            toast.success('Your request has been sent to the admin, you will be notified as soon as it is added.');
            handleClose();
        } else {
            if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                toast.error('Something went wrong, please try again!');
            }
        }
    };

    return (
        <AppForm initialValues={{ category: '', name: '' }} onSubmit={handleSubmit} validate={requestToolValidation}>
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                headerAction={
                    <button onClick={handleClose}>
                        <CancelRounded sx={{ color: theme.palette.neutral[60] }} />
                    </button>
                }
                title={
                    <Typography sx={{ color: theme.palette.primary[900], fontWeight: 700, fontSize: 16 }}>
                        {t('requestToolModal.title')}
                    </Typography>
                }
                subtitle={
                    <Typography
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            mt: 1,
                            mb: 3,
                            fontSize: 12,
                            color: theme.palette.neutral[50],
                            fontWeight: 400,
                            gap: 1.5,
                        }}
                    >
                        <BsInfoCircle />
                        <span>{t('requestToolModal.subtitle')}</span>
                    </Typography>
                }
                width={600}
                height="50%"
            >
                <AppFormSelect
                    name="category"
                    label={t('requestToolModal.label1')}
                    options={[...groups, { name: 'Others', value: '' }]}
                />
                <AppFormInput
                    type="text"
                    label={t('requestToolModal.label2')}
                    name="name"
                    placeholder={t('requestToolModal.label2')}
                />
                <Box sx={{ mt: 3 }}>
                    <AppSubmitButton
                        text={t('requestToolModal.button')}
                        variant="contained"
                        color="primary"
                        loading={loading}
                        loadingPosition="center"
                    />
                </Box>
            </AppCenteredModal>
        </AppForm>
    );
};
const mapStateToProps = (state) => ({
    integrations_groups: state?.integrationsReducers?.integrations_groups,
});
export default connect(mapStateToProps, { RequestATool })(RequestToolModal);
