import {
    NDPR_CISO_SUMMARY,
    KDPA_CISO_SUMMARY,
    GDPA_CISO_SUMMARY,
    SOC2_CISO_SUMMARY,
    PCIDSS_CISO_SUMMARY,
    PCIDSS4POINT0_CISO_SUMMARY,
    ISO_CISO_SUMMARY,
    ISO_TWO_CISO_SUMMARY,
    TECH_SEC_CISO_SUMMARY,
    ISO9001_CISO_SUMMARY,
    PCISLC_CISO_SUMMARY,
    PEOPLE_INFO_SUMMARY,
    PEOPLE_SUMMARY,
    PEOPLE_TRAINING_SUMMARY,
    ISO22301_CISO_SUMMARY,
    PEOPLE_SCAN_SUMMARY,
    INVENTORY_SCAN_SUMMARY,
    SOURCE_CODE_SCAN_SUMMARY,
    API_SECURITY_SCAN_SUMMARY,
    PCI_SCAN_SUMMARY,
    VUL_SCAN_SUMMARY,
    ALL_VENDORS_SUMMARY,
    ALL_KYC_SUMMARY,
    ALL_FRAUD_SUMMARY,
    ALL_MERCHANT_RISK_ASSESSMENTS_SUMMARY,
    ALL_RISK_ASSESSMENTS_SUMMARY,
    ALL_PENTESTS_SUMMARY,
    ALL_AUTOMATEDSCANS_SUMMARY,
    ALL_THIRD_PARTY_SUMMARY,
    PCISLC_TABLE_CISO_SUMMARY,
    BLOCKCHAIN_CISO_SUMMARY,
    CII_CISO_SUMMARY,
    BLOCKCHAIN_GENERAL_CISO_SUMMARY,
    CII_GENERAL_CISO_SUMMARY,
    FRAMEWORK_PERCENTAGE_CISO_SUMMARY,
    CISO_PERCENTAGE_CISO_SUMMARY,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case NDPR_CISO_SUMMARY:
            return {
                ...state,
                ndpr_ciso_summary: action.payload,
            };

        case KDPA_CISO_SUMMARY:
            return {
                ...state,
                kdpa_ciso_summary: action.payload,
            };

        case GDPA_CISO_SUMMARY:
            return {
                ...state,
                gdpa_ciso_summary: action.payload,
            };

        case SOC2_CISO_SUMMARY:
            return {
                ...state,
                soc2_ciso_summary: action.payload,
            };

        case PCIDSS_CISO_SUMMARY:
            return {
                ...state,
                pcidss_ciso_summary: action.payload,
            };

        case PCIDSS4POINT0_CISO_SUMMARY:
            return {
                ...state,
                pcidss4_ciso_summary: action.payload,
            };

        case ISO_CISO_SUMMARY:
            return {
                ...state,
                iso_ciso_summary: action.payload,
            };

        case ISO_TWO_CISO_SUMMARY:
            return {
                ...state,
                iso_two_ciso_summary: action.payload,
            };

        case TECH_SEC_CISO_SUMMARY:
            return {
                ...state,
                tech_sec_ciso_summary: action.payload,
            };

        case ISO9001_CISO_SUMMARY:
            return {
                ...state,
                iso_9001_ciso_summary: action.payload,
            };

        case PCISLC_CISO_SUMMARY:
            return {
                ...state,
                pci_slc_ciso_summary: action.payload,
            };

        case PEOPLE_INFO_SUMMARY:
            return {
                ...state,
                people_info_summary: action.payload,
            };
        case PEOPLE_SUMMARY:
            return {
                ...state,
                people_summary: action.payload,
            };
        case PEOPLE_TRAINING_SUMMARY:
            return {
                ...state,
                people_training_summary: action.payload,
            };
        case ISO22301_CISO_SUMMARY:
            return {
                ...state,
                iso22301_summary: action.payload,
            };
        case PEOPLE_SCAN_SUMMARY:
            return {
                ...state,
                people_scan_summary: action.payload,
            };
        case INVENTORY_SCAN_SUMMARY:
            return {
                ...state,
                inventory_scan_summary: action.payload,
            };
        case SOURCE_CODE_SCAN_SUMMARY:
            return {
                ...state,
                source_code_scan_summary: action.payload,
            };
        case API_SECURITY_SCAN_SUMMARY:
            return {
                ...state,
                api_security_scan_summary: action.payload,
            };
        case PCI_SCAN_SUMMARY:
            return {
                ...state,
                pci_scan_summary: action.payload,
            };
        case VUL_SCAN_SUMMARY:
            return {
                ...state,
                vul_scan_summary: action.payload,
            };
        case ALL_VENDORS_SUMMARY:
            return {
                ...state,
                all_vendor_summary: action.payload,
            };
        case ALL_KYC_SUMMARY:
            return {
                ...state,
                all_kyc_summary: action.payload,
            };
        case ALL_FRAUD_SUMMARY:
            return {
                ...state,
                all_fraud_summary: action.payload,
            };
        case ALL_MERCHANT_RISK_ASSESSMENTS_SUMMARY:
            return {
                ...state,
                all_risk_assessment_summary: action.payload,
            };
        case ALL_RISK_ASSESSMENTS_SUMMARY:
            return {
                ...state,
                all_risk_summary: action.payload,
            };
        case ALL_PENTESTS_SUMMARY:
            return {
                ...state,
                all_pentest_summary: action.payload,
            };
        case ALL_AUTOMATEDSCANS_SUMMARY:
            return {
                ...state,
                all_automated_scan_summary: action.payload,
            };
        case ALL_THIRD_PARTY_SUMMARY:
            return {
                ...state,
                all_third_party_summary: action.payload,
            };
        case PCISLC_TABLE_CISO_SUMMARY:
            return {
                ...state,
                all_pcislc_table_ciso_summary: action.payload,
            };
        case BLOCKCHAIN_CISO_SUMMARY:
            return {
                ...state,
                blockchain_ciso_summary: action.payload,
            };
        case CII_CISO_SUMMARY:
            return {
                ...state,
                cii_ciso_summary: action.payload,
            };
        case BLOCKCHAIN_GENERAL_CISO_SUMMARY:
            return {
                ...state,
                blockchain_general_ciso_summary: action.payload,
            };
        case CII_GENERAL_CISO_SUMMARY:
            return {
                ...state,
                cii_general_ciso_summary: action.payload,
            };
        case FRAMEWORK_PERCENTAGE_CISO_SUMMARY:
            return {
                ...state,
                framework_percentage_ciso_summary: action.payload,
            };
        case CISO_PERCENTAGE_CISO_SUMMARY:
            return {
                ...state,
                ciso_percentage_ciso_summary: action.payload,
            };
        case 'CLEAR_DATA':
            return {};
        default:
            return state;
    }
};
