import React, { useState } from 'react';
//components
import { Link } from 'react-router-dom';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { Box, Stack, Button, Typography } from '@mui/material';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import { HiLightBulb } from 'react-icons/hi';
import { Close } from '@mui/icons-material';
import SuggestImg from 'assets/img/suggest.png';
import ShieldIcon from 'assets/img/shield.svg';
import { useTheme } from '@mui/styles';

const ComplianceAITipsModal = (props) => {
    const { open, handleClose, width, minWidth, neverShowComplianceAiTipsModal } = props;
    const theme = useTheme();

    const [neverShow, setNeverShow] = useState(false);
    const handleNeverShow = () => {
        setNeverShow(!neverShow);
        neverShowComplianceAiTipsModal();
    };
    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            width={width}
            minWidth={minWidth}
            height={500}
            padding="0px"
        >
            <Stack direction="row" justifyContent="space-between" sx={{ height: '100%' }}>
                <Box sx={{ width: '55%', height: '100%', fontSize: 12, p: 4 }}>
                    <Stack direction="row" alignItems="center" sx={{ ml: 1 }}>
                        <img src={ShieldIcon} alt="shield icon" className="h-[27px]" />
                        <Typography sx={{ ml: 1, color: theme.palette.primary.main, fontWeight: 700, fontSize: 24 }}>
                            Compliance made easy
                        </Typography>
                    </Stack>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: 12, fontWeight: 600, pl: 5.5 }}>
                        With Ask AI
                    </Typography>
                    <Typography
                        sx={{ fontSize: 14, lineHeight: '21px', mt: 4, color: theme.palette.gray[40], fontWeight: 500 }}
                    >
                        We've streamlined the audit questions process by assisting you in responding to them based on
                        the uploaded documents.
                    </Typography>
                    <Typography
                        sx={{ fontSize: 14, lineHeight: '21px', mt: 2, color: theme.palette.gray[40], fontWeight: 500 }}
                    >
                        In light of this, we advise you to upload your policies first before accessing the audit
                        question module in order for this feature to function.
                    </Typography>
                    <Typography sx={{ mt: 4, mb: 1.5, fontSize: 14, color: theme.palette.gray[20], fontWeight: 500 }}>
                        Key Features:
                    </Typography>
                    <ul className="list-disc ml-4 text-[14px] text-[#5E5E62] font-medium">
                        <li>Answers to all audit questions are automatically filled in</li>
                        <li>Auto-generated suggestions to audit queries</li>
                    </ul>
                    <Stack direction="row" alignItems="center" sx={{ mt: 4 }}>
                        <HiLightBulb size="20px" color={theme.palette.primary.main} />
                        <Typography sx={{ fontSize: 12, ml: 1 }}>
                            You can activate this feature by clicking{' '}
                            <Button
                                sx={{
                                    color: theme.palette.primary.main,
                                    textTransform: 'none',
                                    fontSize: 12,
                                    minWidth: 'unset',
                                    fontWeight: 500,
                                    pl: 0,
                                    '&:hover': {
                                        background: 'transparent',
                                    },
                                }}
                                component={Link}
                                to={{
                                    pathname: '/merchant/settings/account',
                                    state: { tab: 'compliance' },
                                }}
                            >
                                here
                            </Button>
                        </Typography>
                    </Stack>

                    <AppCheckboxInput
                        name="close"
                        label="I don’t want to see this"
                        onChange={handleNeverShow}
                        value={neverShow}
                        sx={{ marginTop: 'auto' }}
                    />
                </Box>
                <Box sx={{ background: '#EEF0FF', width: '45%', height: '100%', p: 1 }}>
                    <Stack alignItems="flex-end">
                        <Button
                            sx={{
                                display: 'flex',
                                backgroundColor: 'transparent',
                                color: theme.palette.primary.main,
                                fontSize: '14px',
                                width: '30px',
                                minWidth: 'unset',
                                borderRadius: '58%',
                                height: '30px',
                                border: `2px solid ${theme.palette.primary.main}`,
                                // px: 0.5,
                                // ml: 1,
                                justifyContent: 'center',
                                alignitems: 'center',
                            }}
                            variant="outlined"
                            onClick={handleClose}
                        >
                            <Close />
                        </Button>
                    </Stack>
                    <Stack alignItems="center" sx={{ width: '100%', mt: 4 }}>
                        <img src={SuggestImg} className="w-[80%]" />
                    </Stack>
                </Box>
            </Stack>
        </AppCenteredModal>
    );
};

export default ComplianceAITipsModal;
