import React, { useState } from 'react';
import { toast } from 'react-toastify';

//core Components
import { Box } from '@mui/material';
import AppModal from 'components/new_components/AppModal';
import { AppForm, AppFormInput, AppSubmitButton } from 'components/new_components/forms';
import { GroupAdd } from '@mui/icons-material';
import { FaUserEdit } from 'react-icons/fa';

//utils
import { connect } from 'react-redux';
import { AddMerchantPeople, UpdateMerchantPerson } from 'store/actions/merchantActions';
import { employeeValidation } from './utils/validation';
import { pattern } from 'validate';

//translations
import { useTranslation } from 'react-i18next';

const EmployeeModal = ({ open, handleClose, isAddModal, payload, id, AddMerchantPeople, UpdateMerchantPerson }) => {
    const [loadingState, setloadingState] = useState(false);

    //translation
    const { t } = useTranslation('inventory');

    //functions
    const handleSubmit = async (values) => {
        setloadingState(true);
        const body = {
            ...values,
            run_kyc_check: false,
        };
        const res = isAddModal ? await AddMerchantPeople(body) : await UpdateMerchantPerson(body, id);
        if (res?.success) {
            setloadingState(false);
            toast.success(isAddModal ? 'Employee Added Successfully' : 'Employee Credentials Updated Successfully');
            handleClose();
        } else {
            if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                setloadingState(false);
                toast.error(res.message);
            }
        }
    };
    return (
        <AppForm initialValues={payload} onSubmit={handleSubmit} validate={employeeValidation}>
            <AppModal
                open={open}
                handleClose={handleClose}
                title={isAddModal ? t('addEmployeeModal.add') : t('addEmployeeModal.update')}
                width={450}
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            my: 3,
                        }}
                    >
                        <AppSubmitButton
                            text={isAddModal ? t('addEmployeeModal.button') : t('addEmployeeModal.update')}
                            startIcon={isAddModal ? <GroupAdd /> : <FaUserEdit />}
                            variant="contained"
                            color="primary"
                            loading={loadingState}
                            loadingPosition="center"
                            sx={{ width: '50%' }}
                        />
                    </Box>
                }
            >
                <AppFormInput
                    type="text"
                    label={t('addEmployeeModal.firstName')}
                    name="first_name"
                    placeholder={t('addEmployeeModal.firstName')}
                />
                <AppFormInput
                    type="text"
                    label={t('addEmployeeModal.lastName')}
                    name="last_name"
                    placeholder={t('addEmployeeModal.lastName')}
                />
                <AppFormInput
                    type="text"
                    label={t('addEmployeeModal.email')}
                    name="email"
                    placeholder={t('addEmployeeModal.email')}
                    disabled={!isAddModal}
                />
                <AppFormInput
                    type="text"
                    label={t('addEmployeeModal.role')}
                    name="position"
                    placeholder={t('addEmployeeModal.role')}
                />
                <AppFormInput
                    type="text"
                    label={t('addEmployeeModal.phoneNumber')}
                    name="phone"
                    placeholder={t('addEmployeeModal.phoneNumber')}
                    regex={pattern.phone}
                />
                <AppFormInput
                    type="text"
                    label={t('addEmployeeModal.department')}
                    name="department"
                    placeholder={t('addEmployeeModal.department')}
                />
                <AppFormInput
                    type="text"
                    label={t('addEmployeeModal.address')}
                    name="address"
                    placeholder={t('addEmployeeModal.address')}
                />
            </AppModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, {
    AddMerchantPeople,
    UpdateMerchantPerson,
})(EmployeeModal);
