import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Button, Select, Upload } from 'antd';
import { AiOutlineInbox } from 'react-icons/ai';

//Redux
import { connect } from 'react-redux';
import { UploadPentestReport } from 'store/actions/adminActions';

const UploadModal = (props) => {
    const [loading, setloading] = useState(false);
    const [data, setData] = useState({});
    const { open, handleClose, record, UploadPentestReport } = props;

    const { Dragger } = Upload;

    const draggers = {
        name: 'default',
        action: null,
        multiple: false,
        maxCount: 1,
        onChange(info) {
            setData({ ...data, report: info.fileList[0]?.originFileObj });
        },
    };

    const handleSelectChange = (value) => {
        setData({ ...data, severity: value });
    };

    const onClose = () => {
        setData({});
        handleClose();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setloading(true);

        console.log(data);
        const formData = new FormData();
        formData.append('name', record?.name);
        formData.append('severity', data?.severity);
        formData.append('report', data?.report);
        formData.append('pentest_id', record?.id);
        formData.append('merchant_id', record?.merchant_id);

        const res = await UploadPentestReport(formData);
        setloading(false);
        if (res?.success) {
            toast.success('Threat Added Successfully!');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <Modal title="Upload Report" open={open} onCancel={handleClose} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="severity">Severity Level</label>
                        <Select
                            type="text"
                            size="large"
                            name="severity"
                            id="severity"
                            className="block"
                            onChange={handleSelectChange}
                            value={data?.severity || ''}
                            options={[
                                {
                                    value: 'low',
                                    label: 'Low',
                                },
                                {
                                    value: 'medium',
                                    label: 'Medium',
                                },
                                {
                                    value: 'high',
                                    label: 'High',
                                },
                            ]}
                            required
                        ></Select>
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="description">Upload Report</label>
                        <Dragger {...draggers}>
                            <p className="ant-upload-drag-icon">
                                <AiOutlineInbox />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                    </Col>
                    <Col style={{ marginTop: '5rem' }}>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Upload
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { UploadPentestReport })(UploadModal);
