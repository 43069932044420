import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
//redux

const TableAction = (props) => {
    const theme = useTheme();
    const { row, openDeleteModal, openViewModal } = props;

    // state
    const [anchor, setAnchor] = useState(null);

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const handleViewModal = () => {
        openViewModal(row);
        closeMenu();
    };

    const handleOpenDeleteModal = () => {
        closeMenu();
        openDeleteModal(row);
    };

    return (
        <>
            <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                <MoreHoriz />
            </IconButton>
            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                <MenuItem
                    onClick={handleViewModal}
                    sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: theme.palette.neutral[500],
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    View details
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={handleOpenDeleteModal}
                    sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: '#FF5449',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    Delete
                </MenuItem>
            </Menu>
        </>
    );
};
export default TableAction;
