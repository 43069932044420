import React from 'react';
// import { useTheme } from '@mui/material/styles';
import { Container, Stack, Box } from '@mui/material';

//translation
import { useTranslation } from 'react-i18next';

import AppTabsBarNew from 'components/new_components/tabs/AppTabsBarNew';
import RequestToolModal from '../Integrations/components/RequestToolModal';

const IntegrationsLayout = (props) => {
    const {
        children,
        onTabChange,
        tab,
        standard,
        tabAction,
        open,
        closeToolModal,
        // standards,
    } = props;

    //translation
    const { t } = useTranslation('setup');

    const handleTabChange = (tab) => {
        onTabChange(tab);
    };

    const tabs = [
        {
            index: 0,
            label: t('Tools'),
        },
        {
            index: 1,
            label: t('Assets'),
        },
    ];

    return (
        <Container
            maxWidth={false}
            elevation={0}
            sx={{
                p: '0 !important',
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: 'transparent',
                mb: 5,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    background: 'white',
                }}
            >
                <AppTabsBarNew
                    currentTab={tab}
                    tabs={tabs}
                    onChange={handleTabChange}
                    standard={standard}
                    sx={{ gap: 3 }}
                />
                {tabAction && <Box sx={{ mr: 3 }}>{tabAction}</Box>}
            </Box>

            <Stack sx={{ flex: 1, py: 3 }}>{children}</Stack>
            <RequestToolModal open={open} handleClose={closeToolModal} />
        </Container>
    );
};

export default IntegrationsLayout;
