import {
    ALL_QUALITY_MANAGEMENT,
    ALL_QUALITY_MANAGEMENT_METRICS,
    ALL_ADMIN_DOCUMENTS_QUALITY_MANAGEMENT,
    ALL_ADMIN_EVIDENCE_BY_QUALITY_MANAGEMENT,
    ALL_EXEMPTED_EVIDENCE,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case ALL_QUALITY_MANAGEMENT:
            return {
                ...state,
                all_quality_management: action.payload,
            };
        case ALL_QUALITY_MANAGEMENT_METRICS:
            return {
                ...state,
                all_quality_management_summary: action.payload,
            };
        case ALL_ADMIN_DOCUMENTS_QUALITY_MANAGEMENT:
            return {
                ...state,
                documents_by_control: action.payload,
            };
        case ALL_ADMIN_EVIDENCE_BY_QUALITY_MANAGEMENT:
            return {
                ...state,
                evidence_by_control: action.payload,
            };

        case ALL_EXEMPTED_EVIDENCE:
            return {
                ...state,
                exempted_evidence: action.payload,
            };
        case 'CLEAR_DATA':
            return {};
        default:
            return state;
    }
};
