import React, { useEffect, useState } from 'react';
import EditModal from './RiskCategoryOperations/EditModal';
import RiskCategoryModal from './RiskCategoryOperations/RiskCategoryModal';
import { RiskCategoryOperations } from './RiskCategoryOperations/RiskCategoryOperations';
import { Button, Col, Input, Modal, Row, Table } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllRiskCategories } from 'store/actions/adminActions';
import { DeleteRiskCategory } from 'store/actions/adminActions';
import useSearch from 'hooks/useSearch';

const RiskCategory = () => {
    const [loading, setloading] = useState(false);
    const [AddModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [record, setrecord] = useState(null);

    // Redux
    const dispatch = useDispatch();
    const all_risk_categories = useSelector((state) => state.adminReducers.all_risk_categories);
    const { data, handleSearch } = useSearch(all_risk_categories, ['name']);

    const columns = [
        {
            title: 'Risk Category',
            render: (record) => record?.name,
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => (
                <RiskCategoryOperations record={record} openUpdateModal={openEditModal} deleteModal={deleteModal} />
            ),
        },
    ];
    const openAddModal = () => {
        setAddModal(true);
    };
    const closeAddModal = () => {
        setAddModal(false);
    };

    //Edit Modal
    const openEditModal = (data) => {
        setrecord(data);
        setEditModal(true);
    };
    const closeEditModal = () => {
        setEditModal(false);
    };

    const deleteModal = (threat) => {
        Modal.confirm({
            title: 'Do you want to delete this category?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await dispatch(DeleteRiskCategory(threat?.id));
                if (res?.success) {
                    toast.success('Risk category Deleted Successfully');
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    useEffect(() => {
        const getAllRiskCategories = async () => {
            setloading(true);
            const res = await dispatch(GetAllRiskCategories());
            setloading(false);
            if (!res.success) {
                toast.error(res?.message);
            }
        };

        getAllRiskCategories();
    }, []);
    return (
        <div>
            <Row justify="end">
                <Col span={12} className="flex items-center gap-5">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                    <Button
                        onClick={openAddModal}
                        type="primary"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        Add Risk Category
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(data) => data?.id}
                    columns={columns}
                    loading={loading}
                    dataSource={data?.sort((a, b) => b?.id - a?.id)}
                    pagination={{
                        total: data?.length,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                    }}
                />
            </section>
            <RiskCategoryModal open={AddModal} handleClose={closeAddModal} />
            <EditModal open={editModal} handleClose={closeEditModal} record={record} />
        </div>
    );
};

export default RiskCategory;
