import React from 'react';
import { useTheme } from '@mui/material/styles';
import { OutlinedInput } from '@mui/material';

import AppFormControl from './forms/AppFormControl';

const AppFileUploadInput = ({ label, type, name, onChange, error, ...restProps }) => {
    const theme = useTheme();
    return (
        <AppFormControl name={name} label={label} error={error}>
            <OutlinedInput
                id={name}
                name={name}
                onChange={onChange}
                type={type}
                {...restProps}
                sx={{
                    height: 48,
                    border: '1px solid ' + (error ? theme.palette.error[700] : theme.palette.gray[900]),
                    color: theme.palette.gray[900],
                    overflow: 'hidden',
                    '& fieldset': { border: '0 !important' },
                }}
            />
        </AppFormControl>
    );
};

export default AppFileUploadInput;
