import React from 'react';
import { useTheme } from '@mui/material/styles';

// app components
import AppFormCheckboxGroup from 'components/new_components/forms/AppFormCheckboxGroup';
//translation
import { useTranslation } from 'react-i18next';
const StandardCheckboxGroup = (props) => {
    const { labelStyle, all_standards, value } = props;
    const theme = useTheme();
    //translation hook
    const { t } = useTranslation('accounts');
    return (
        <AppFormCheckboxGroup
            label={t('plans.standardCheckboxGroupLabel')}
            labelStyle={labelStyle}
            name="standards"
            groupStyle={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                '& label': {
                    m: 0,
                    minWidth: 170,
                    fontSize: 14,
                    fontWeight: 400,
                    color: theme.palette.primary[900],
                },
            }}
            sx={{ my: 1 }}
            options={all_standards?.map((standard) => standard.standard_type)}
            values={value}
            // onChange={onChange}
        />
    );
};

export default StandardCheckboxGroup;
