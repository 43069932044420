import React, { useContext, useEffect, useMemo, useState } from 'react';
import PageHeader from 'components/new_components/PageHeader';
import AuditTableRecord from './AuditTableRecord';
import AppArticle from 'components/new_components/AppArticle';
import { connect, useSelector } from 'react-redux';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';

const AuditIndex = (props) => {
    const { all_article_header } = props;

    const [openArticle, setOpenArticle] = useState(false);

    const { toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const userguide_info = useSelector((state) => state?.guideReducers?.userguide_info?.[0]?.infrastructures);

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Audit'),
        [all_article_header]
    );

    // functions
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, audits: false });
        setOpenArticle(false);
    };

    useEffect(() => {
        toggleUserGuide.audits ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.audits]);

    return (
        <>
            <PageHeader browserTitle="Audit | Smartcomply" />
            <AuditTableRecord />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Audit"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};
export default connect(mapStateToProps, {})(AuditIndex);
