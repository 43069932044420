import { Box } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

// core components
import PageHeader from 'components/new_components/PageHeader';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import ComplianceLayout from '../common/ComplianceLayout';
import AuditQuestions from './AuditQuestions';
import Documents from './Documents';
import Exceptions from './Exceptions';
// import ComplianceAITipsModal from '../common/ComplianceAITipsModal';

// redux
import { connect, useSelector } from 'react-redux';
import { GetAllDocumentsByTags, GetAllFormsByTags, GetAllGdpaCategories } from 'store/actions/adminActions';
import {
    GetAllGdpaCategoryMetrics,
    GetAllMerchantAnswers,
    GetAllMerchantDocuments,
} from 'store/actions/complianceActions';
import {
    CreateModuleGuide,
    GetModuleGuides,
    GetUserDetails,
    UpdateModuleGuide,
    UpdateUserDetails,
} from 'store/actions/generalActions';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';
import AllTaskModal from '../common/AllTaskModal';
import AssignTaskModal from '../common/AssignTaskModal';
import TaskIntroductionModal from '../common/TaskIntroductionModal';

//userguide
import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';

const GDPA = (props) => {
    const {
        GetAllGdpaCategories,
        GetAllGdpaCategoryMetrics,
        GetAllMerchantDocuments,
        GetAllMerchantAnswers,
        GetUserDetails,
        // UpdateUserDetails,
        GetModuleGuides,
        UpdateModuleGuide,
        CreateModuleGuide,
        GetAllDocumentsByTags,
        GetAllFormsByTags,
        categories_fetched,
        categories_metrics_fetched,
        guideModal,
        userguide_info,
        all_article_header,
    } = props;

    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [loading, setLoading] = useState({ categories: false });
    const [introModalOpen, setIntroModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [tasksModalOpen, setTaskModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [taskInfo, setTaskInfo] = useState({});
    // const [openAiModal, setOpenAiModal] = useState(false);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    const user_type = useSelector((state) => state.authReducers.user_type);
    const { activeMerchant } = useContext(SelectedMerchantContext);

    //Userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);

    const STEPS_LENGTH = 3;

    const steps = [
        {
            title: <UserGuideHeader title="Documents" id={1} length={STEPS_LENGTH} />,
            description: 'Here you can Upload compliance doc, evidences and policies.',
            target: () => ref1.current,
            placement: 'rightTop',
        },
        {
            title: <UserGuideHeader title="Audit Questions" id={2} length={STEPS_LENGTH} />,
            description: 'Respond to audit-related queries here.',
            target: () => ref2.current,
            placement: 'rightTop',
        },
        {
            title: <UserGuideHeader title="Exceptions" id={3} length={STEPS_LENGTH} />,
            description: 'This tab displays questions and documents that are exempted',
            target: () => ref3.current,
            placement: 'rightTop',
        },
    ];

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Compliance'),
        [all_article_header]
    );

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ compliance: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, compliance: false });
        setOpenArticle(false);
    };

    // functions
    // logic functions
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };
    // const handleOpenAiModal = () => {
    //     setOpenAiModal(true);
    // };
    // const handleCloseAiModal = () => {
    //     setOpenAiModal(false);
    // };
    //task introduction/explanation modal
    const closeIntroModal = () => {
        setIntroModalOpen(false);
    };

    const openTaskAssignModal = (type, info) => {
        setModalOpen(true);
        setModalType(type);
        setTaskInfo(info);
    };
    const closeTaskAssignModal = () => {
        setModalOpen(false);
        setTimeout(() => setModalType(null), 500);
        setTaskInfo({});
    };

    const openTasksModal = () => {
        setTaskModalOpen(true);
    };
    const closeTasksModal = () => {
        setTaskModalOpen(false);
    };
    // async functions
    // get all categories
    const getAllCategories = async () => {
        setLoading((current) => ({ ...current, categories: !categories_fetched }));
        const res = await GetAllGdpaCategories();
        setLoading((current) => ({ ...current, categories: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't get categories");
            console.log(res?.message);
        }
    };
    // fetch as categories metric for this merchant
    const getAllCategoryMetrics = async () => {
        setLoading((current) => ({
            ...current,
            categories: !categories_metrics_fetched,
        }));
        const res = await GetAllGdpaCategoryMetrics(activeMerchant);
        setLoading((current) => ({ ...current, categories: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't get categories metrics");
        }
    };
    const getAllMerchantDocuments = async () => {
        const res = await GetAllMerchantDocuments(activeMerchant);
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch your documents.");
        }
    };
    const getMerchantAnswers = async () => {
        const res = await GetAllMerchantAnswers(activeMerchant);
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch previous answers.");
        }
    };

    const getUserDetails = async () => {
        const res = await GetUserDetails();
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch user details");
        }
    };
    const getModuleGuides = async () => {
        const res = await GetModuleGuides();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllDocumentsByTags = async () => {
        const res = await GetAllDocumentsByTags('gdpa');
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllFormsByTags = async () => {
        const res = await GetAllFormsByTags('gdpa');
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const updateModuleIntroModal = async () => {
        const res =
            guideModal && guideModal[0]
                ? await UpdateModuleGuide({ compliance_task: true }, guideModal[0]?.id)
                : await CreateModuleGuide({ compliance_task: true });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        getAllCategories();
        getAllCategoryMetrics();
        getAllMerchantDocuments();
        getMerchantAnswers();
    }, [activeMerchant]);

    useEffect(() => {
        if (user_type !== 'auditor') {
            getUserDetails();
            getModuleGuides();
            getAllDocumentsByTags();
            getAllFormsByTags();
        }
    }, [user_type]);
    // useEffect(() => {
    //     handleOpenAiModal();
    // }, []);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.compliance ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.compliance]);

    return (
        <>
            <PageHeader browserTitle="GDPA - Compliance | Smartcomply" />
            <Box>
                <ComplianceLayout
                    onTabChange={handleTabChange}
                    tab={currentTab}
                    openTasksModal={openTasksModal}
                    ref1={ref1}
                    ref2={ref2}
                    ref3={ref3}
                    standard="gdpa"
                >
                    <AppTabPanel value={currentTab} index={0}>
                        <Documents
                            loading={loading.categories}
                            getCategories={getAllCategories}
                            openTaskAssignModal={openTaskAssignModal}
                        />
                    </AppTabPanel>
                    <AppTabPanel value={currentTab} index={1}>
                        <AuditQuestions
                            loading={loading.categories}
                            getCategories={getAllCategories}
                            openTaskAssignModal={openTaskAssignModal}
                        />
                    </AppTabPanel>
                    <AppTabPanel value={currentTab} index={2}>
                        <Exceptions getCategories={getAllCategories} />
                    </AppTabPanel>
                </ComplianceLayout>
                {/* <ComplianceAITipsModal open={openAiModal} handleClose={handleCloseAiModal} width="700px" minWidth={900} /> */}
            </Box>

            <TaskIntroductionModal
                open={introModalOpen}
                handleClose={closeIntroModal}
                updateModuleIntroModal={updateModuleIntroModal}
            />

            <AssignTaskModal open={modalOpen} onClose={closeTaskAssignModal} type={modalType} taskInfo={taskInfo} />
            <AllTaskModal open={tasksModalOpen} handleClose={closeTasksModal} standard="gdpa" />

            {!loading.categories && <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />}
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Compliance"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        categories_fetched: state?.adminReducers?.all_gdpa_categories?.length,
        categories_metrics_fetched: state?.complianceReducers?.all_gdpa_categories_metrics?.length,
        guideModal: state?.generalReducers?.module,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.compliance,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};
export default connect(mapStateToProps, {
    GetAllGdpaCategories,
    GetAllGdpaCategoryMetrics,
    GetAllMerchantDocuments,
    GetAllMerchantAnswers,
    GetUserDetails,
    UpdateUserDetails,
    GetModuleGuides,
    UpdateModuleGuide,
    CreateModuleGuide,
    GetAllDocumentsByTags,
    GetAllFormsByTags,
})(GDPA);
