import React from 'react';
import { Box, Button, Typography } from '@mui/material';

//translations
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { LoadingButton } from '@mui/lab';

const ExemptModal = (props) => {
    const { open, handleClose, handleExempt, loading, resource } = props;

    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            noShadow
            noClose
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#F8FAFC',
                        gap: 2,
                        py: 2,
                        pr: 3,
                        mx: '-2rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit' }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        sx={{
                            mr: 2,
                            backgroundColor: '#FF5449',
                            textTransform: 'inherit',
                            color: '#FFFFFF',
                            px: 2,
                            '&:hover': {
                                backgroundColor: '#FF5449',
                                color: '#FFFFFF',
                            },
                        }}
                        loading={loading}
                        loadingPosition="center"
                        variant="contained"
                        color="primary"
                        onClick={handleExempt}
                    >
                        Yes, not applicable
                    </LoadingButton>
                </Box>
            }
            sx={{ borderRadius: 1, overflowX: 'scroll' }}
            width="500px"
        >
            <Box sx={{ mt: 2 }}>
                <Typography sx={{ color: '#475569', fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>
                    Not applicable?
                </Typography>
                <Typography sx={{ color: '#475569', fontSize: '13px', fontWeight: 400, textAlign: 'center', my: 2 }}>
                    By marking this {resource} as not applicable, it will no longer show in your control object. Go to{' '}
                    <strong>Exceptions</strong> to revert this changes
                </Typography>
            </Box>
        </AppCenteredModal>
    );
};

export default ExemptModal;
