import React from 'react';
import { RectangleBox } from './dashboard-utils/DashboardUtils';
// import GetStartedIcon from '../../assets/img/dashboard/GetStartedIcon.svg';
// import AuditGreenIcon from '../../assets/img/dashboard/AuditGreenIcon.svg'
import SubscriptionAlert from '../Pricing/SubscriptionAlert';
import { isFullFunctionPlan } from 'utils';

//translations
import { useTranslation } from 'react-i18next';
// import { GetAllMerchantCertificates } from 'store/actions/auditActions';
// import { connect } from 'react-redux';

const TopDashboardReport = ({ pricing_plan }) => {
    //translation
    const { t } = useTranslation('overview');

    //functions
    const featureFlag = {
        auditing: false,
    };

    return (
        <>
            <div className="border-b border-gray-200 pb-4 lg:px-0 lg:pt-0 lg:pb-0 pt-4 flex">
                <div className="w-full flex lg:flex-row flex-col gap-3">
                    {!isFullFunctionPlan(pricing_plan?.tag) && <SubscriptionAlert />}
                    {featureFlag.auditing && (
                        <RectangleBox boxStyle="lg:w-[280px] lg:h-[65px] p-2 px-3">
                            <div className="flex items-center">
                                <div className="pl-2">
                                    <h2 className="lg:text-[13px] font-bold">{t('inProgress')}</h2>
                                    <div className="rounded-[8px] border-[1px] border-[#0E2C66] cursor-pointer p-1 px-2 text-[12px] text-[#46464A] font-bold w-fit">
                                        {t('viewProgress')}
                                    </div>
                                </div>
                            </div>
                        </RectangleBox>
                    )}
                </div>
            </div>
        </>
    );
};
export default TopDashboardReport;
