import AppViewModal from 'components/new_components/AppViewModal';
import React, { useEffect, useMemo, useState } from 'react';
import { ArrowBackIosNew, CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { AppForm } from 'components/new_components/forms';
import { AppFormContext } from 'components/new_components/forms';
import { AppSubmitButton } from 'components/new_components/forms';
import { useTranslation } from 'react-i18next';
import { AppFormInput } from 'components/new_components/forms';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import StandardCheckboxGroup from '../AccountSetup/plans/components/StandardCheckboxGroup';
import { connect } from 'react-redux';
import { GetStandardsPrices, GetAllFeatures } from 'store/actions/subscriptionActions';
import { toast } from 'react-toastify';
// import { formatComma } from '../AccountSetup/plans/utils';
import FeatureTable from './FeatureTable';
import { formatComma } from '../AccountSetup/plans/utils';

const SubscribeModal = (props) => {
    const {
        open,
        handleClose,
        all_plans,
        planId,
        all_standards_prices,
        GetStandardsPrices,
        GetAllFeatures,
        all_features,
        type,
    } = props;
    const { t } = useTranslation('accounts');
    const [amountOfEmployee, setAmountOfEmployee] = useState('');
    const [checkboxVal, setCheckboxVal] = useState(false);
    const [standards, setStandards] = useState([]);
    const [customPlanPayload, setCustomPlanPayload] = useState({});
    const theme = useTheme();

    const handleCheckboxChange = () => {
        setCheckboxVal((value) => !value);
    };

    const handleStandardsChange = (value) => {
        let idx,
            newStandards = [...standards];
        for (let i = 0; i < newStandards.length; i++) {
            if (newStandards[i] === value) idx = i;
        }
        if (isNaN(idx)) {
            newStandards.push(value);
        } else {
            newStandards.splice(idx, 1);
        }
        setStandards(newStandards);
    };

    // async functions
    const getAllFeatures = async () => {
        // setLoading(true);
        const res = await GetAllFeatures();
        // setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't get the updated features.");
        }
    };
    const getStandardsPrices = async () => {
        // setLoading(true);
        const res = await GetStandardsPrices();
        // setLoading(false);
        if (!res.success) {
            toast.error('Something went wrong!');
        }
    };

    useEffect(() => {
        getAllFeatures();
        getStandardsPrices();
    }, []);

    // memos
    const planDetails = {
        id: 'custom',
        name: 'Custom',
    };

    const plan_details = all_plans?.find((plan) => plan?.id === planId);

    const totalCost = useMemo(() => {
        return standards.reduce((prev, current) => {
            const currentCost = all_standards_prices?.find((standard) => standard?.standard_type === current)?.amount;
            return prev + currentCost;
        }, plan_details?.price);
    }, [plan_details?.price, standards]);

    const getCurrentValues = (values) => {
        setAmountOfEmployee(values?.amountOfEmployee);
        setStandards(values?.standards);
    };

    // constants
    const labelStyle = { fontWeight: 500, color: '#161B28' };
    return (
        <AppViewModal
            width={!plan_details ? (type === 'upgrade' ? 600 : 'unset') : 500}
            backIcon={
                <ArrowBackIosNew
                    sx={{
                        width: 40,
                        height: 40,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#46464A',
                        p: 1,
                        cursor: 'pointer',
                    }}
                    onClick={handleClose}
                />
            }
            open={open}
            handleClose={handleClose}
            noClose
            title={
                <Box
                    sx={{
                        marginLeft: '-3rem',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: 700,
                            marginTop: 1,
                            color: '#46464A',
                            fontSize: '16px',
                        }}
                    >
                        {type === 'upgrade' ? 'Upgrade to ' : 'Subscribe to'}
                    </Typography>
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: 700,
                            marginTop: 1,
                            color: '#46464A',
                            fontSize: '16px',
                            ml: 0.5,
                        }}
                    >
                        {plan_details?.name || 'Custom'}
                    </Typography>
                </Box>
            }
            anchor="right"
        >
            <Box sx={{ my: 3 }}>
                <Stack direction={type === 'subscribe' ? 'row' : 'column'} spacing={5} sx={{ m: 3 }}>
                    {!plan_details && (
                        <Stack sx={{ width: type === 'upgrade' ? '100%' : '60%', maxWidth: 700 }}>
                            <FeatureTable
                                all_features={all_features}
                                plan={planDetails}
                                customPlanPayload={customPlanPayload}
                                setCustomPlanPayload={setCustomPlanPayload}
                            />
                        </Stack>
                    )}
                    <Box sx={{ maxWidth: !plan_details && type === 'subscribe' ? '40%' : '100%' }}>
                        <AppForm initialValues={{ amountOfEmployee: '', standards: [] }}>
                            <AppFormContext getValues={getCurrentValues}>
                                {(type === 'subscribe' || plan_details) && (
                                    <AppFormInput
                                        type="number"
                                        name="amountOfEmployee"
                                        placeholder="What is your company's size"
                                        labelStyle={labelStyle}
                                        regex={/^[0-9]*$/g}
                                        value={amountOfEmployee}
                                        min={0}
                                        sx={{ borderColor: theme.palette.gray[400], height: 50 }}
                                    />
                                )}

                                {(type === 'subscribe' || plan_details) && (
                                    <StandardCheckboxGroup
                                        labelStyle={labelStyle}
                                        all_standards={all_standards_prices}
                                        value={standards}
                                        onChange={handleStandardsChange}
                                    />
                                )}
                                {amountOfEmployee && plan_details && (
                                    <Typography color="primary" sx={{ fontWeight: 600 }}>
                                        Total Cost
                                        <Box component="span" sx={{ fontWeight: 700, ml: 1 }}>
                                            ₦ {formatComma(totalCost)}
                                        </Box>
                                    </Typography>
                                )}
                                <AppCheckboxInput
                                    label={
                                        <Typography
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: '10px',
                                                color: '#77777A',
                                            }}
                                        >
                                            I agree to receive marketing communications from <br />
                                            Smartcomplyapp and also subjected to our{' '}
                                            <Typography
                                                component="a"
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.smartcomplyapp.com/privacy"
                                                sx={{ fontWeight: 700 }}
                                            >
                                                privacy
                                            </Typography>{' '}
                                            and{' '}
                                            <Typography
                                                component="a"
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.smartcomplyapp.com/terms"
                                                sx={{ fontWeight: 700 }}
                                            >
                                                terms
                                            </Typography>
                                            .
                                        </Typography>
                                    }
                                    sx={{ mb: 1 }}
                                    checkboxProps={{
                                        icon: <RadioButtonUnchecked sx={{ fontSize: 14, mt: '-1.3rem' }} />,
                                        checkedIcon: <CheckCircle sx={{ fontSize: 14, mt: '-1.3rem' }} />,
                                    }}
                                    value={checkboxVal}
                                    onChange={handleCheckboxChange}
                                />
                                <AppSubmitButton
                                    text={t('plans.cta')}
                                    variant="contained"
                                    color="primary"
                                    sx={{ width: '100%', p: 2 }}
                                />
                            </AppFormContext>
                        </AppForm>
                    </Box>
                </Stack>
            </Box>
        </AppViewModal>
    );
};

const mapStateToProps = (state) => {
    return {
        all_plans: state?.subscriptionReducers?.all_plans,
        all_standards_prices: state?.subscriptionReducers?.all_standards_prices,
        all_features: state?.subscriptionReducers?.all_features,
    };
};
export default connect(mapStateToProps, {
    GetAllFeatures,
    GetStandardsPrices,
})(SubscribeModal);
