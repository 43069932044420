import React from 'react';
import BreadCrumb from 'components/Breadcrumb';
import PaymentMethods from './PaymentMethods';
import StandardPricing from './StandardPricing';
import { Col, Row } from 'antd';

const StandardsAndPayments = () => {
    return (
        <>
            <BreadCrumb views={[{ label: 'All Standards and Payments methods' }]} />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <StandardPricing />
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <PaymentMethods />
                </Col>
            </Row>
        </>
    );
};

export default StandardsAndPayments;
