import React, { createElement } from 'react';
import { Layout, Dropdown } from 'antd';
import { useHistory, Link } from 'react-router-dom';

// icons
import { FaUserCircle } from 'react-icons/fa';
import { BsChevronDown } from 'react-icons/bs';
import { AiOutlineMenuUnfold, AiOutlineMenuFold } from 'react-icons/ai';

// redux
import { connect } from 'react-redux';
import { LogoutUser } from 'store/actions/authActions';

const { Header } = Layout;

const SuperAdminHeader = (props) => {
    const history = useHistory();
    const { toggle, collapsed, LogoutUser, user_info } = props;

    // functions
    const logoutAdmin = async () => {
        await LogoutUser();
        history.push('/auth/login');
    };

    const items = [
        { label: <Link to="/admin/setup-two-factor-authenication">Setup 2fa</Link>, key: '0' },
        { label: <span onClick={logoutAdmin}>Sign out</span>, key: '1' },
    ];

    return (
        <Header
            className="site-layout-background"
            style={{
                paddingLeft: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            {createElement(!collapsed ? AiOutlineMenuFold : AiOutlineMenuUnfold, {
                className: 'trigger',
                onClick: toggle,
            })}
            <Dropdown menu={{ items }} trigger={['click']}>
                <span
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <FaUserCircle />{' '}
                    <span style={{ padding: '0 0.5rem' }}>
                        {user_info?.organization?.owner_detail?.user_type === 'aud_admin'
                            ? 'Auditor Admin'
                            : 'Super Admin'}{' '}
                    </span>
                    <BsChevronDown />
                </span>
            </Dropdown>
        </Header>
    );
};

const mapStateToProps = (state) => ({
    user_info: state?.authReducers?.user_info,
});

export default connect(mapStateToProps, { LogoutUser })(SuperAdminHeader);
