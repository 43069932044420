import React from 'react';
import { Box, Button, Typography } from '@mui/material';

//core components
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import AppLoadingButton from 'components/new_components/AppLoadingButton';

const MarkAsCompletedModal = (props) => {
    //props
    const { open, handleClose, loading, updateTaskStatus } = props;

    // const theme = useTheme();
    return (
        <AppCenteredModal open={open} handleClose={handleClose} width={520} height={250} sx={{ borderRadius: 0 }}>
            <Box sx={{ my: 1.5, posiiton: 'relaitve' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#475569' }}>
                        Mark as completed?
                    </Typography>
                    <Typography
                        sx={{ fontSize: '13px', fontWeight: 400, color: '#64748B', textAlign: 'center', mt: 2 }}
                    >
                        Task marked as completed will undergo approval. You cannot undo this action
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: '#F8FAFC',
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                my: 4,
                            }}
                        >
                            <Button
                                sx={{
                                    textTransform: 'inherit',
                                    color: '#334155',
                                    border: '1px solid #E2E8F0',
                                    borderRadius: 1,
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    '&:hover': {
                                        border: '1px solid #E2E8F0',
                                    },
                                }}
                                variant="outlined"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <AppLoadingButton
                                sx={{
                                    ml: 1.5,
                                    textTransform: 'inherit',
                                    borderRadius: 0.5,
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    backgroundColor: '#37A372',
                                    '&:hover': {
                                        backgroundColor: '#37A372',
                                    },
                                }}
                                loading={loading}
                                loadingPosition="center"
                                onClick={updateTaskStatus}
                                variant="contained"
                                text={'Yes, mark as completed'}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </AppCenteredModal>
    );
};

export default MarkAsCompletedModal;
