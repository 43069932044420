export const TOOLS_CATEGORY = ['risk assessment', 'background check', 'antivirus', 'automated scan'];
export const SCAN_CATEGORY = [
    'network operations',
    'configuration management',
    'data management',
    'server configuration',
    'vulnerability management',
    'identity and access management',
    'systems and security monitoring',
    'documentation lifecycle',
    'code repository',
];
