import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import logo from 'assets/img/vendor_smart_logo.svg';
import Ellipse from 'assets/img/vendor_response_ellipse.png';
import RiskCenterImage from 'assets/img/risk_center_image.svg';
import EastIcon from '@mui/icons-material/East';
import AuthenticateModal from './components/AuthenticateModal';
import { useDispatch, useSelector } from 'react-redux';
import { SelfServiceLogin } from 'store/actions/generalActions';
import { toast } from 'react-toastify';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import { RISK_SELF_PORTAL_TOKEN } from 'store/constants';

const SelfServiceLogins = () => {
    // State
    const [openAuthenticateModal, setOpenAuthenticateModal] = useState(false);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    // Redux
    const dispatch = useDispatch();
    const { risk_current_permission } = useSelector((state) => state?.generalReducers);

    const handleSubmit = async () => {
        event.preventDefault();
        setLoading(true);
        const res = await dispatch(SelfServiceLogin({ email }));
        setLoading(false);
        if (res?.success) {
            setEmail('');
            setOpenAuthenticateModal(true);
        } else {
            toast.error("Something went wrong, couldn't log you in");
        }
    };
    const handleClose = () => {
        setOpenAuthenticateModal(false);
    };
    useEffect(() => {
        if (risk_current_permission) {
            dispatch({ type: RISK_SELF_PORTAL_TOKEN, payload: undefined });
        }
    }, [risk_current_permission]);
    return (
        <Box sx={{ position: 'relative', backgroundColor: '#FFFFFF', height: '100vh' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2, px: 4 }}>
                <Typography sx={{ color: '#002C72', fontSize: 18, fontWeight: 700 }}>
                    Risk assessment Self service
                </Typography>
                <img src={logo} alt="SmartComply logo" />
            </Box>
            <Box sx={{ position: 'absolute', top: 0, right: 0, z: 0 }}>
                <img src={Ellipse} alt="Ellipse" />
            </Box>{' '}
            <Box className="flex items-center justify-between gap-2 xl:mx-[10rem] md:mx-[4rem] my-[8rem]">
                <Box>
                    <Typography sx={{ fontWeight: 700, fontSize: '48px', color: '#002C72' }}>
                        Open risk center
                    </Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: '16px', color: '#002C72', my: 1.5 }}>
                        For Risk Mitigation Owner, Risk Escalation, Management approval
                    </Typography>
                    <Typography sx={{ color: '#64748B', fontSize: '14px', fontWeight: 400 }}>
                        Approve proposed risk mitigation strategies, review escalated risks <br /> requiring immediate
                        attention, and monitor progress on assigned risk <br /> action plans.
                    </Typography>
                    <div className="mt-4">
                        <form onSubmit={handleSubmit}>
                            <p className="text-[#64748B] text-[13px] font-medium">Enter email to Proceed</p>
                            <input
                                placeholder="Enter email"
                                value={email}
                                type="email"
                                required={true}
                                onChange={(e) => setEmail(e.target.value)}
                                className="bg-[#FFFFFF] border border-[#E2E8F0] w-full px-3 py-[10px] rounded mt-1 placeholder:text-[#94A3B8] placeholder:text-sm placeholder:font-normal focus:outline-none"
                            />
                            <AppLoadingButton
                                text="Proceed to risk register"
                                variant="contained"
                                color="primary"
                                type="submit"
                                endIcon={<EastIcon sx={{ ml: 1.5 }} />}
                                sx={{ px: 2, py: 1.5, textTransform: 'inherit', mt: 4 }}
                                loading={loading}
                                loadingPosition="center"
                                disabled={!email}
                            />
                        </form>
                    </div>
                </Box>
                <img src={RiskCenterImage} alt="QuestionMarkIcon" style={{ zIndex: 1000 }} />
            </Box>
            <AuthenticateModal open={openAuthenticateModal} handleClose={handleClose} />
        </Box>
    );
};
export default SelfServiceLogins;
