import { validation } from 'validate';

export const formatAddedDate = (date) => {
    let formatedDate = new Date(date);
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
    return {
        date: formatedDate.toLocaleDateString('en-US', dateOptions),
        time: formatedDate.toLocaleTimeString('en-US', timeOptions),
    };
};
export const questionnaireValidate = (values) => {
    const errors = {};
    const answerValidate = validation(values.answer, 'Answer', true);

    if (!answerValidate.isValid) {
        errors.answer = answerValidate.errorMessage;
    }

    return errors;
};
