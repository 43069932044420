import React, { useContext, useState } from 'react';
import { Stack, Typography, IconButton, Box } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { useTheme } from '@mui/styles';
import { Close } from '@mui/icons-material';
import { AppFormInput } from 'components/new_components/forms';
import { AppSubmitButton } from 'components/new_components/forms';
import { statusUpdateValidation } from './validation';
import { AppForm } from 'components/new_components/forms';
//redux
import { useDispatch } from 'react-redux';
import { UpdateFormAnswersStatus } from 'store/actions/complianceActions';
import { toast } from 'react-toastify';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

const FormStatusUpdateModal = ({ open, handleClose, type, answerDetails, standard }) => {
    const theme = useTheme();

    const [loadingState, setLoadingState] = useState(false);

    const { activeMerchant } = useContext(SelectedMerchantContext);
    const dispatch = useDispatch();

    const handleSubmit = async (values) => {
        setLoadingState(true);
        const res = await dispatch(
            UpdateFormAnswersStatus(
                { comment: [values?.comment], status: type === 'accept' ? 'accepted' : 'rejected', standard: standard },
                answerDetails?.id,
                activeMerchant
            )
        );
        setLoadingState(false);
        if (res?.success) {
            toast.success('Status Updated successfully');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <AppForm initialValues={{ comment: '' }} onSubmit={handleSubmit} validate={statusUpdateValidation}>
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                width={500}
                height="42%"
                sx={{ top: '-20%' }}
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            mb: 6,
                        }}
                    >
                        <AppSubmitButton
                            text={type === 'accept' ? 'Accept' : 'Reject'}
                            variant="contained"
                            color="primary"
                            loading={loadingState}
                            loadingPosition="center"
                            sx={{
                                width: '100%',
                                py: 1.5,
                                backgroundColor: type === 'accept' ? '#37A372' : theme.palette.error[60],
                                '&:hover': {
                                    backgroundColor: type === 'accept' ? '#37A372' : theme.palette.error[60],
                                },
                            }}
                        />
                    </Box>
                }
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mt: -1, borderBottom: `1px solid ${theme.palette.gray[90]}` }}
                >
                    <Typography
                        sx={{
                            fontSize: 18,
                            fontWeight: 700,
                            color: theme.palette.gray[30],
                            textTransform: 'capitalize',
                        }}
                    >{`${type} Answer`}</Typography>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Stack>
                <AppFormInput
                    type="text"
                    name="comment"
                    placeholder={`${type === 'accept' ? 'Any other information' : 'Reason for rejecting'} (if any)`}
                    multiline
                    row={4}
                    sx={{ mt: 1, mb: 1 }}
                />
            </AppCenteredModal>
        </AppForm>
    );
};

export default FormStatusUpdateModal;
