import React from 'react';
import { useFormikContext } from 'formik';

import AppLoadingButton from '../AppLoadingButton';

const AppSubmitButton = (props) => {
    const { ...restProps } = props;
    const { handleSubmit } = useFormikContext();
    return <AppLoadingButton {...restProps} onClick={handleSubmit} />;
};

export default AppSubmitButton;
