import React from 'react';
//core component
import { Cancel } from '@mui/icons-material';
import { Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const EmptyComponent = (props) => {
    const theme = useTheme();

    const { text, subtext } = props;
    return (
        <Stack justifyContent="center" alignItems="center" direction="column" sx={{ height: '90%', flex: 1 }}>
            <Stack justifyContent="center" alignItems="center" direction="column" sx={{ flex: 1, textAlign: 'center' }}>
                <Cancel color="#B8BCCC" sx={{ fontSize: '50px' }} />
                <Typography
                    sx={{
                        color: theme.palette.gray[300],
                        fontWeight: 700,
                        fontSize: 22,
                        lineHeight: '29px',
                    }}
                >
                    {text}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: 16,
                        lineHeight: '22px',
                        letterSpacing: '0.5px',
                    }}
                >
                    {subtext}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default EmptyComponent;
