import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import PageViewModal from './components/PageViewModal';
import CyberSecurityAssessment from './SecurityAssessment/CyberSecurityAssessment';
import IntelligenceAssessment from './IntelligenceAssessment/IntelligenceAssessment';

//translations
import { useTranslation } from 'react-i18next';

const RunAssessment = (props) => {
    const {
        intelligencePayload,
        setIntelligencePayload,
        selfPayload,
        setSelfPayload,
        openModal,
        setOpenModal,
        type,
        setType,
        goBack,
        setIndexValue,
    } = props;

    //translation
    const { t } = useTranslation('riskAssessment');

    useEffect(() => {
        if (type === '') {
            setOpenModal(true);
        }
    }, [type]);

    const handleClose = () => {
        setOpenModal(false);
        setIndexValue(1);
    };
    const closeForm = () => {
        setType('');
    };

    const handleType = (type) => {
        setType(type);
        setOpenModal(false);
    };

    return (
        <Box>
            {type === 'self' ? (
                <CyberSecurityAssessment
                    payload={selfPayload}
                    setPayload={setSelfPayload}
                    handleGoBack={goBack}
                    closeForm={closeForm}
                />
            ) : type === 'intelligence' ? (
                <IntelligenceAssessment
                    payload={intelligencePayload}
                    setPayload={setIntelligencePayload}
                    handleGoBack={goBack}
                    closeForm={closeForm}
                />
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '830px' }}>
                    <Typography sx={{ height: '100px' }}>{t('runAssessmentTab.assessment')}</Typography>
                </Box>
            )}
            <PageViewModal open={openModal} handleClose={handleClose} handleType={handleType} />
        </Box>
    );
};

export default RunAssessment;
