import React, { useMemo, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { Box, Stack, Typography } from '@mui/material';
import { TextSnippet } from '@mui/icons-material';
import FileUploadIcon from '@mui/icons-material/FileUpload';

// core components
import AppUploadButton from 'components/new_components/AppUploadButton';
import ItemSubheader from '../../common/ItemSubheader';
import DocumentDrawer from '../../common/document/DocumentDrawer';
import { accept } from 'validate';

// Redux
import { UploadMerchantDocument } from 'store/actions/complianceActions';
import { connect } from 'react-redux';

//translation
import { useTranslation } from 'react-i18next';

const { pdf, docx, excel, txt, ppt } = accept;

const PentestItem = (props) => {
    const { onResourceChange, title, id, document, all_merchant_documents, UploadMerchantDocument } = props;
    const theme = useTheme();
    const [drawerOpenedTab, setDrawerOpenedTab] = useState(null);
    const [loading, setLoading] = useState({ upload: false, exempt: false });
    const inputRef = useRef();

    //translation
    const { t } = useTranslation('compliance');

    const openDrawer = (tab) => setDrawerOpenedTab(tab);
    const closeDrawer = () => setDrawerOpenedTab(null);

    const selectedDocument = useMemo(() => {
        return all_merchant_documents?.find((doc) => doc?.tag === 'pentest' && doc?.subcriteria === id);
    }, [all_merchant_documents]);

    // async functions
    const uploadMerchantDocument = async (e) => {
        // get the form data
        const doc = new FormData();
        doc.append('document', e.target.files[0]);
        doc.append('document_id', document?.id);
        doc.append('subcriteria', document?.subcriteria);
        doc.append('tag', 'pentest');

        // sending the data
        setLoading({ ...loading, upload: true });
        const res = await UploadMerchantDocument(doc);
        setLoading({ ...loading, upload: false });

        //handling response
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
        inputRef.current.value = null;
    };

    const document_id = {
        doc_id: selectedDocument?.tag,
        user_doc_id: selectedDocument?.id,
        subcriteria: selectedDocument?.subcriteria,
    };

    const payload = {
        document: document?.id,
        subcriteria: document?.subcriteria,
        criteria: document?.criteria,
        reason: '',
    };

    return (
        <Box sx={{ p: 2, py: 4 }}>
            <ItemSubheader
                hasResource={Boolean(selectedDocument)}
                exempting={loading.exempt}
                exceptionReason={document}
                menuItems={[
                    { label: t('blockchain.docAndVersions'), action: () => openDrawer(0) },
                    { label: t('blockchain.comments'), action: () => openDrawer(1) },
                ]}
                resource="doc"
                payload={payload}
                blockchain
                onResourceChange={onResourceChange}
            />
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ py: 5 }} spacing={3}>
                <TextSnippet color="primary" />
                <Stack>
                    <Typography
                        sx={{
                            color: theme.palette.gray[800],
                            fontWeight: 500,
                            fontSize: 16,
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 300,
                            fontSize: 12,
                        }}
                    >
                        {t('blockchain.pentestReport')}
                        <Typography
                            sx={{
                                color: theme.palette.primary[900],
                                fontWeight: 500,
                                fontSize: 12,
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            component={'a'}
                            href="/merchant/pentest"
                        >
                            {t('blockchain.requestPentest')}
                        </Typography>
                        .
                    </Typography>
                    <Stack direction="row" spacing={3} sx={{ pt: 4 }}>
                        <AppUploadButton
                            color="primary"
                            variant="outlined"
                            label={selectedDocument ? t('uploadVersion') : t('uploadDocument')}
                            startIcon={<FileUploadIcon />}
                            id={document?.id}
                            loading={loading.upload}
                            onChange={uploadMerchantDocument}
                            accept={`${pdf},${docx},${excel},${txt},${ppt}`}
                            sx={{ textTransform: 'uppercase' }}
                            inputRef={inputRef}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <DocumentDrawer
                open={Boolean(drawerOpenedTab !== null)}
                tab={drawerOpenedTab}
                handleClose={closeDrawer}
                tag={'pentest'}
                document={document_id}
                onResourceChange={onResourceChange}
            />
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        all_merchant_documents: state?.complianceReducers?.all_merchant_documents,
    };
};

export default connect(mapStateToProps, {
    UploadMerchantDocument,
})(PentestItem);
