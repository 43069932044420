import React from 'react';
import { Button, Card, List, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// redux
import { connect } from 'react-redux';
import { GetStandardsPrices, DeleteStandardPrice } from 'store/actions/subscriptionActions';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import StandardPriceModal from './components/StandardPriceModal';
import { CurrencySign } from 'utils';
import { formatAmount } from 'utils';

const StandardPricing = (props) => {
    const { GetStandardsPrices, all_standards_prices, DeleteStandardPrice } = props;

    // state
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(null);
    const [modalData, setModalData] = useState({});

    // functions
    const openModal = (mode = 'Add', data = {}) => {
        setModalOpen(mode);
        setModalData(data);
    };
    const closeModal = () => {
        setModalOpen(null);
        setModalData({});
    };
    // async
    const getStandardsPrices = async () => {
        setLoading(true);
        const res = await GetStandardsPrices();
        setLoading(false);
        if (!res.success) {
            toast.error('Something went wrong!');
        }
    };
    const deleteModal = (id) => {
        Modal.confirm({
            title: 'Do you want to delete this compliance price?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await DeleteStandardPrice(id);
                if (res?.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    // useEffect
    useEffect(() => {
        getStandardsPrices();
    }, []);

    return (
        <>
            <Card
                title="Standard Pricing"
                className="my-4"
                extra={
                    <Button
                        type="link"
                        className="flex justify-center items-center"
                        style={{ display: 'flex' }}
                        onClick={() => openModal()}
                    >
                        <PlusOutlined />
                        Add Standard
                    </Button>
                }
            >
                <List
                    itemLayout="horizontal"
                    loading={loading}
                    dataSource={all_standards_prices}
                    renderItem={(item, index) => (
                        <List.Item
                            actions={[
                                <Button
                                    key={index}
                                    type="link"
                                    icon={<EditOutlined />}
                                    onClick={() => openModal('Edit', item)}
                                />,
                                <Button
                                    key={index + 1}
                                    type="link"
                                    danger
                                    icon={<DeleteOutlined />}
                                    onClick={() => deleteModal(item.id)}
                                />,
                            ]}
                        >
                            <div className="flex items-center justify-start">
                                <span className="font-semibold min-w-[100px]">{item.standard_type}:</span>
                                {CurrencySign} {formatAmount(item.amount)}
                            </div>
                        </List.Item>
                    )}
                />
            </Card>
            <StandardPriceModal mode={modalOpen} handleClose={closeModal} modalData={modalData} />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_standards_prices: state?.subscriptionReducers?.all_standards_prices,
    };
};
export default connect(mapStateToProps, {
    GetStandardsPrices,
    DeleteStandardPrice,
})(StandardPricing);
