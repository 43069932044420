import React from 'react';
import { Box, Typography } from '@mui/material';
import { AppFormSelect, AppFormInput } from 'components/new_components/forms';
import { connect } from 'react-redux';

//translations
import { useTranslation } from 'react-i18next';

const Form4 = (props) => {
    const { matrix, residualImpact3, residualLikelihood3, residualImpact5, residualLikelihood5 } = props;

    //translation
    const { t } = useTranslation('riskAssessment');

    const LikelihoodOption = matrix === '3x3' ? residualLikelihood3 : residualLikelihood5;
    const ImpactOption = matrix === '3x3' ? residualImpact3 : residualImpact5;

    return (
        <Box sx={{ overflow: 'scroll !Important' }}>
            <AppFormSelect
                type="text"
                label={<Typography>{t('cyberSecurityAssessment.residualLikelihood')}</Typography>}
                name="residual_likelihood"
                options={LikelihoodOption}
                defaultValue={t('cyberSecurityAssessment.residualLikelihoodValue')}
            />
            <AppFormSelect
                type="text"
                label={<Typography>{t('cyberSecurityAssessment.residualImpact')}</Typography>}
                name="residual_impact"
                options={ImpactOption}
                defaultValue={t('cyberSecurityAssessment.residualImpactValue')}
            />
            <AppFormInput
                type="text"
                label={<Typography>{t('cyberSecurityAssessment.comments')}</Typography>}
                name="comments"
                placeholder={t('cyberSecurityAssessment.recommended')}
            />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_infrastructures: state?.merchantReducers?.all_merchant_infrastructures,
        all_computers: state?.merchantReducers?.all_computers,
    };
};
export default connect(mapStateToProps, {})(Form4);
