import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Box, IconButton, Menu, MenuItem } from '@mui/material';
import { HiOutlineAnnotation } from 'react-icons/hi';
import { MoreHoriz } from '@mui/icons-material';
import AnswerItem from './AnswerItem';
import moment from 'moment';
import StatusTag from './StatusTag';
import { useSelector } from 'react-redux';
import FormStatusUpdateModal from './FormStatusUpdateModal';

const AnswerHistory = (props) => {
    const theme = useTheme();
    const { answerDetails, conditional, standard } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null);

    const user_type = useSelector((state) => state?.authReducers?.user_type);

    const menuOpen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openModal = (type) => {
        setModalType(type);
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(null);
        setTimeout(() => setModalType(null), 500);
    };

    return (
        <>
            <Box
                sx={{
                    px: 1,
                    py: 1,
                    border: `1px solid ${theme.palette.neutral[95]}`,
                    mx: 2,
                    borderRadius: '4px',
                }}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Typography
                            sx={{
                                fontSize: 10,
                                fontWeight: 500,
                                color: theme.palette.primary[20],
                            }}
                        >
                            {moment(answerDetails?.date_created).format('LLL')}
                        </Typography>
                        <StatusTag
                            text={
                                answerDetails?.status === 'accepted'
                                    ? 'Approved'
                                    : answerDetails?.status === 'awaiting'
                                    ? 'Awaiting Approval'
                                    : answerDetails?.status
                            }
                            type={
                                answerDetails?.status === 'accepted'
                                    ? 'success'
                                    : answerDetails?.status === 'awaiting'
                                    ? 'warning'
                                    : 'error'
                            }
                        />
                    </Stack>
                    {user_type === 'auditor' && (
                        <>
                            <IconButton
                                sx={{
                                    border: `1px solid ${theme.palette.neutral[90]}`,
                                    borderRadius: '4px',
                                    p: 0,
                                }}
                                id="basic-button"
                                aria-controls={menuOpen ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={menuOpen ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <MoreHoriz />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={menuOpen}
                                onClose={handleMenuClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem
                                    sx={{ fontSize: 13, color: '#37A372' }}
                                    onClick={() => {
                                        openModal('accept');
                                        handleMenuClose();
                                    }}
                                >
                                    Accept
                                </MenuItem>
                                <MenuItem
                                    sx={{ fontSize: 13, color: '#FF5449' }}
                                    onClick={() => {
                                        openModal('reject');
                                        handleMenuClose();
                                    }}
                                >
                                    Reject
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </Stack>
                <Stack
                    direction="row"
                    alignItems="flex-start"
                    gap={1}
                    sx={{ mt: 1, borderBottom: `1px solid ${theme.palette.neutral[95]}`, pb: 0.5 }}
                >
                    <HiOutlineAnnotation className="text-[20px] text-[#77777A] -mt-1 pl-1" />
                    <Typography sx={{ fontWeight: 400, fontSize: 11, color: theme.palette.neutral[50] }}>
                        <span className="font-semibold">Auditor comment:</span>{' '}
                        {answerDetails?.comment?.[0] ? answerDetails?.comment?.[0] : 'Not available'}
                    </Typography>
                </Stack>
                <div className="grid grid-cols-1">
                    {conditional ? (
                        <AnswerItem answer={answerDetails?.answer} form_answer={answerDetails?.form_answer} />
                    ) : (
                        answerDetails?.answer &&
                        Object.values(answerDetails?.answer)?.map((answer, index) => {
                            return (
                                <AnswerItem
                                    answer={answer}
                                    number={index + 1}
                                    key={answer + index}
                                    formAnswers={Object.values(answerDetails?.answer)}
                                />
                            );
                        })
                    )}
                </div>
            </Box>

            <FormStatusUpdateModal
                open={modalOpen}
                handleClose={closeModal}
                type={modalType}
                answerDetails={answerDetails}
                standard={standard}
            />
        </>
    );
};

export default AnswerHistory;
