import React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const Progress = styled(LinearProgress)(({ theme }) => ({
    borderRadius: 5,
    height: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.gray[100],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
    },
}));

const AppProgressBar = (props) => {
    const { value = 0, sx, color } = props;
    const getColor = (value) => {
        if (value >= 0 && value <= 24) {
            return 'error';
        } else if (value >= 25 && value <= 49) {
            return 'warning';
        } else if (value >= 50 && value <= 79) {
            return 'secondary';
        } else if (value >= 80 && value <= 100) {
            return 'success';
        } else {
            return 'inherit';
        }
    };
    return <Progress variant="determinate" value={value} color={color || getColor(value)} sx={sx} />;
};

export default AppProgressBar;
