import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
// core components
import AppViewModal from 'components/new_components/AppViewModal';
// import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
// import TabLayout from 'components/new_components/tabs/TabLayout';
// import Versions from './versions';
// import Comments from './comments';

// redux
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    GetMerchantDocumentVersions,
    GetDocumentMessages,
    GetCodeReviewDocuments,
} from 'store/actions/complianceActions';

//translations
import { useTranslation } from 'react-i18next';
import { KeyboardArrowLeft, MoreHoriz, TextSnippet } from '@mui/icons-material';
import { HiOutlineAnnotation } from 'react-icons/hi';
import AppColorTag from 'components/new_components/AppColorTag';
import AppTablePagination from 'components/new_components/app-table/AppTablePagination';
import LoadingState from 'components/new_components/LoadingState';
import moment from 'moment';
import { DeleteMerchantDocument } from 'store/actions/complianceActions';
import AppDeleteModal from 'components/new_components/AppDeleteModal';
import ComplianceMenu from '../ComplianceMenu';
import DocStatusUpdateModal from './DocStatusUpdateModal';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';
import { GetDocumentLink } from 'store/actions/complianceActions';

const NO_PER_PAGE = 4;
const DocumentDrawer = (props) => {
    const {
        open,
        tab: initialTab,
        handleClose,
        document,
        GetMerchantDocumentVersions,
        GetDocumentMessages,
        DeleteMerchantDocument,
        onResourceChange,
        GetCodeReviewDocuments,
        tag,
        role,
        // standard,
        // form,
        // conditional,
    } = props;
    // state
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState({ versions: false, messages: false, delete: false });
    const [menuAnchor, setMenuAnchor] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [modalOpen, setModalOpen] = useState(null);
    const [modalpayload, setModalPayload] = useState({});
    const [modalType, setModalType] = useState('');
    const [new_document, setNew_Document] = useState();
    const [loadingLink, setLoadingLink] = useState(false);
    const dispatch = useDispatch();

    //translation
    const { t } = useTranslation('compliance');

    const document_versions = useSelector((state) => state?.complianceReducers?.merchant_document_versions);
    const merchant_document_version_by_tag = useSelector(
        (state) => state?.complianceReducers?.merchant_document_version_by_tag
    );
    const user_type = useSelector((state) => state?.authReducers?.user_type);

    const theme = useTheme();

    const version_documents = tag ? merchant_document_version_by_tag : document_versions;

    const { activeMerchant } = useContext(SelectedMerchantContext);

    // logic functions
    // const handleTabChange = (tab) => {
    //     setTab(tab);
    // };
    const openMenu = (e) => setMenuAnchor(e.currentTarget);
    const closeMenu = () => setMenuAnchor(null);

    const handlePageChange = (page) => {
        setPage(page);
    };
    const openDeleteModal = (doc) => {
        setModalOpen('delete');
        setModalPayload(doc);
    };
    const closeDeleteModal = () => {
        setModalPayload({});
        setTimeout(() => setModalOpen(null), 700);
    };

    const openStatusUpdateModal = (doc, type) => {
        setModalOpen('update');
        setModalPayload(doc);
        setModalType(type);
    };
    const closeStatusUpdateModal = () => {
        setTimeout(() => {
            setModalPayload({});
            setModalType(null);
            setModalOpen(null);
        }, 700);
    };
    // async functions
    const getDocumentVersions = async () => {
        setLoading({ ...loading, versions: true });
        const res = await GetMerchantDocumentVersions(document?.doc_id, activeMerchant);
        setLoading({ ...loading, versions: false });
        if (!res?.success && document?.doc_id !== undefined) {
            toast.error(res?.message);
        }
    };
    const getDocumentMessages = async () => {
        setLoading({ ...loading, messages: true });
        const res = await GetDocumentMessages(document?.user_doc_id, activeMerchant);
        setLoading({ ...loading, messages: false });
        if (!res?.success && document?.doc_id !== undefined) {
            toast.error(res?.message);
        }
    };

    // async functions
    const getCodeReviewDocuments = async () => {
        const res = await GetCodeReviewDocuments(tag, document?.subcriteria);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    // async functions
    const handleVersionDelete = async () => {
        setLoading({ ...loading, delete: true });
        const res = await DeleteMerchantDocument(modalpayload.id, modalpayload?.document_id);
        setLoading({ ...loading, delete: false });
        if (res?.success) {
            toast.success('You have successfully deleted a version of this document');
            onResourceChange();
            setModalOpen(null);
        } else {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };
    // useEffect
    useEffect(() => {
        if (initialTab !== null) {
            setTab(initialTab);
        }
        return () => setTab(0);
    }, [initialTab, open]);

    useEffect(() => {
        if (open) {
            if (tag) {
                getCodeReviewDocuments();
                getDocumentMessages();
            } else {
                getDocumentVersions();
                getDocumentMessages();
            }
        }
    }, [open, tag, activeMerchant]);

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;

        setPageData(
            version_documents?.length > 0 ? version_documents?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []
        );
        return version_documents;
    }, [page, version_documents]);

    const getDocumentLink = async () => {
        setLoadingLink(true);
        const res = await dispatch(GetDocumentLink('userdoc', new_document?.id));
        setLoadingLink(false);
        if (res?.success) {
            window.open(res?.data, '_blank');
        } else {
            toast.error('Something went wrong!');
        }
    };
    return (
        <>
            <AppViewModal
                open={open}
                handleClose={handleClose}
                title="Documents & Versions"
                width={tab === 2 ? 900 : 500}
                anchor="right"
                backIcon={<KeyboardArrowLeft onClick={handleClose} />}
                noClose
            >
                {/* <TabLayout
                tabFullWidth
                tab={tab || 0}
                onTabChange={handleTabChange}
                tabs={[
                    { name: t('version'), id: 0 },
                    { name: t('comment'), id: 1, disabled: true },
                ]}
            > */}
                <div className="relative h-[calc(100%-30px)]">
                    <div className="grid grid-col-1 gap-2 mt-4">
                        {!loading?.versions ? (
                            pageData?.length ? (
                                pageData?.map((doc, index) => (
                                    <Box
                                        sx={{
                                            px: 1,
                                            py: 2,
                                            border: `1px solid ${theme.palette.neutral[95]}`,
                                            mx: 2,
                                            borderRadius: '4px',
                                        }}
                                        key={index}
                                    >
                                        <Stack direction="row" justifyContent="space-between">
                                            <div>
                                                <Stack direction="row" alignItems="center" gap={1}>
                                                    <TextSnippet sx={{ color: theme.palette.primary[40] }} />
                                                    <Typography
                                                        sx={{
                                                            fontSize: 12,
                                                            fontWeight: 400,
                                                            color: theme.palette.primary[40],
                                                        }}
                                                    >
                                                        Doc ( {moment(doc?.date_created).format('LLL')})
                                                    </Typography>
                                                </Stack>
                                            </div>
                                            <Stack direction="row" alignItems="center" gap={1}>
                                                <AppColorTag
                                                    text={
                                                        doc?.approved_status === 'accepted'
                                                            ? 'Approved'
                                                            : doc?.approved_status === 'awaiting'
                                                            ? 'Awaiting Approval'
                                                            : doc?.approved_status
                                                    }
                                                    type={
                                                        doc?.approved_status === 'accepted'
                                                            ? 'success'
                                                            : doc?.approved_status === 'awaiting'
                                                            ? 'warning'
                                                            : 'error'
                                                    }
                                                    fontSize={10}
                                                    fontWeight={500}
                                                    margin="0px"
                                                />
                                                <IconButton
                                                    sx={{
                                                        border: `1px solid ${theme.palette.neutral[90]}`,
                                                        borderRadius: '4px',
                                                        p: 0,
                                                    }}
                                                    onClick={(e) => {
                                                        setNew_Document(doc);
                                                        openMenu(e);
                                                    }}
                                                >
                                                    <MoreHoriz />
                                                </IconButton>
                                                <ComplianceMenu
                                                    anchor={menuAnchor}
                                                    closeMenu={closeMenu}
                                                    role={role}
                                                    items={
                                                        user_type === 'auditor'
                                                            ? [
                                                                  {
                                                                      label: (
                                                                          <div>
                                                                              {loadingLink ? (
                                                                                  <LoadingState size={20} />
                                                                              ) : (
                                                                                  t('download')
                                                                              )}
                                                                          </div>
                                                                      ),
                                                                      action: () => getDocumentLink(),
                                                                      sx: {
                                                                          justifyContent: 'flex-start',
                                                                          '&, &:hover': {
                                                                              color: theme.palette.gray[700],
                                                                          },
                                                                      },
                                                                  },

                                                                  {
                                                                      label: 'Accept',
                                                                      action: () =>
                                                                          openStatusUpdateModal(doc, 'accept'),
                                                                      disabled: new_document?.status !== 'awaiting',
                                                                      sx: {
                                                                          justifyContent: 'flex-start',
                                                                          color: '#37A372',
                                                                      },
                                                                  },
                                                                  {
                                                                      label: 'Reject',
                                                                      action: () =>
                                                                          openStatusUpdateModal(doc, 'reject'),
                                                                      disabled: new_document?.status !== 'awaiting',
                                                                      sx: {
                                                                          justifyContent: 'flex-start',
                                                                          color: '#FF5449',
                                                                      },
                                                                  },
                                                              ]
                                                            : [
                                                                  {
                                                                      label: (
                                                                          <div>
                                                                              {loadingLink ? (
                                                                                  <LoadingState size={20} />
                                                                              ) : (
                                                                                  t('download')
                                                                              )}
                                                                          </div>
                                                                      ),
                                                                      action: () => getDocumentLink(),
                                                                      sx: {
                                                                          justifyContent: 'flex-start',
                                                                          '&, &:hover': {
                                                                              color: theme.palette.gray[700],
                                                                          },
                                                                      },
                                                                  },
                                                                  {
                                                                      label: t('delete'),
                                                                      action: () => openDeleteModal(new_document),
                                                                      disabled:
                                                                          doc?.status === 'awaiting' ||
                                                                          doc?.status === 'rejected',
                                                                      sx: { justifyContent: 'flex-start' },
                                                                  },
                                                              ]
                                                    }
                                                />
                                            </Stack>
                                        </Stack>
                                        <Stack direction="row" alignItems="flex-start" gap={1} sx={{ mt: 1 }}>
                                            <HiOutlineAnnotation className="text-[26px] text-[#77777A] -mt-1 pl-1" />
                                            <Typography
                                                sx={{ fontWeight: 400, fontSize: 11, color: theme.palette.neutral[50] }}
                                            >
                                                <span className="font-semibold">Auditor comment:</span>{' '}
                                                {doc?.comment?.[0] ? doc?.comment?.[0] : 'Not available'}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                ))
                            ) : null
                        ) : (
                            <LoadingState />
                        )}
                    </div>

                    <Stack
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            height: '56px',
                            boxShadow: '-4px -4px 8px 0px #AFACAC26',
                        }}
                    >
                        <div className="flex flex-row justify-end items-center -mt-6">
                            <AppTablePagination
                                dataLength={version_documents?.length}
                                noPerPage={NO_PER_PAGE}
                                page={page}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </Stack>
                </div>

                {/* <Stack sx={{ mt: 3, flex: 1 }}>
                <AppTabPanel value={tab} index={0}>
                    <Versions
                        loading={loading.versions}
                        onResourceChange={onResourceChange}
                        tag={tag}
                        form={form}
                        conditional={conditional}
                        doc_id={document?.doc_id}
                    />
                </AppTabPanel>
                <AppTabPanel value={tab} index={1}>
                    <Comments loading={loading.messages} doc_id={document?.user_doc_id} />
                </AppTabPanel>
            </Stack> */}
                {/* </TabLayout> */}
            </AppViewModal>
            <AppDeleteModal
                open={modalOpen === 'delete'}
                handleClose={closeDeleteModal}
                title={t('title')}
                subtitle={t('subtitle')}
                deleting={loading?.delete}
                onDelete={handleVersionDelete}
            />
            <DocStatusUpdateModal
                open={modalOpen === 'update'}
                handleClose={closeStatusUpdateModal}
                type={modalType}
                documentDetails={modalpayload}
                tag={tag}
            />
        </>
    );
};
const mapStateToProps = () => {
    return {};
};
export default connect(mapStateToProps, {
    GetMerchantDocumentVersions,
    GetDocumentMessages,
    GetCodeReviewDocuments,
    DeleteMerchantDocument,
})(DocumentDrawer);
