import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// core component
import AppViewModal from 'components/new_components/AppViewModal';
// import AnswerHistory from './AnswerHistory';
import LoadingState from 'components/new_components/LoadingState';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { GetAllAnswerHistory } from 'store/actions/complianceActions';

//translations
import { useTranslation } from 'react-i18next';
import AnswerHistory from './AnswerHistory';
import { Stack } from '@mui/material';
import AppTablePagination from 'components/new_components/app-table/AppTablePagination';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

const NO_PER_PAGE = 4;
const AnswerHistoryDrawer = (props) => {
    const { open, handleClose, form, exception, standard } = props;
    const dispatch = useDispatch();
    // state
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);

    const { activeMerchant } = useContext(SelectedMerchantContext);
    //accesing values from the store
    const answer_history = useSelector((state) => state?.complianceReducers?.answer_history);

    //translation
    const { t } = useTranslation('compliance');

    const handlePageChange = (page) => {
        setPage(page);
    };

    const getAllAnswerHistory = async () => {
        setLoading(true);
        const res = await dispatch(GetAllAnswerHistory(form?.form_id, activeMerchant));
        setLoading(false);
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log('Error in answer history drawer, error:::', res?.message);
        }
    };

    useEffect(() => {
        if (open && !exception) {
            getAllAnswerHistory();
        }
        return () => setLoading(false);
    }, [form?.form_id, open]);

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;

        setPageData(
            answer_history?.length > 0 ? answer_history?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []
        );
        return answer_history;
    }, [page, answer_history]);

    return (
        <AppViewModal open={open} handleClose={handleClose} title={t('answerHistory')} anchor="right" width={500}>
            <div className="relative h-[calc(100%-30px)]">
                {!loading ? (
                    pageData &&
                    pageData?.map((answerDetails, index) => {
                        return (
                            <div className="grid grid-col-1 gap-2 mt-4" key={index}>
                                <AnswerHistory
                                    answerDetails={answerDetails}
                                    key={answerDetails.id}
                                    standard={standard}
                                />
                            </div>
                        );
                    })
                ) : (
                    <LoadingState />
                )}
                <Stack
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        height: '56px',
                        boxShadow: '-4px -4px 8px 0px #AFACAC26',
                    }}
                >
                    <div className="flex flex-row justify-end items-center -mt-6">
                        <AppTablePagination
                            dataLength={answer_history?.length}
                            noPerPage={NO_PER_PAGE}
                            page={page}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </Stack>
            </div>
        </AppViewModal>
    );
};

export default AnswerHistoryDrawer;
