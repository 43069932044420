import { Button, Col, Input, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';

// redux
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { CreateTrainingCourse, UpdateTrainingCourse } from 'store/actions/trainingActions';

const CoursesModal = (props) => {
    const { open, mode, handleClose, modalData, CreateTrainingCourse, UpdateTrainingCourse } = props;

    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // functions
    const onCancel = () => {
        handleClose();
        setData({});
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = mode === 'Add' ? await CreateTrainingCourse(data) : await UpdateTrainingCourse(data, data?.id);
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            onCancel();
        } else {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (open) {
            setData(modalData);
        }
    }, [open]);

    return (
        <Modal open={open} onCancel={onCancel} footer={null} destroyOnClose title={`${mode} Training Course`}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="title">Course Title</label>
                            <Input
                                size="large"
                                name="title"
                                id="title"
                                onChange={handleTextChange}
                                value={data.title || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="description">Course Description</label>
                            <Input.TextArea
                                size="large"
                                name="description"
                                id="description"
                                onChange={handleTextChange}
                                value={data.description || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="departments">Departments Concerned</label>
                            <Input
                                size="large"
                                name="departments"
                                id="departments"
                                onChange={handleTextChange}
                                value={data.departments || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col>
                        <Button htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, { CreateTrainingCourse, UpdateTrainingCourse })(CoursesModal);
