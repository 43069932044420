import { Box, Button, Stack, Typography, useTheme, Divider } from '@mui/material';
import Brand from 'components/new_components/Brand';
import React, { useState } from 'react';

import { ReactComponent as PlayIcon } from 'assets/img/icons/play-course.svg';
import { CheckCircle } from '@mui/icons-material';
import { HiOutlineDocumentAdd } from 'react-icons/hi';
import LoadingState from 'components/new_components/LoadingState';
import { toast } from 'react-toastify';
import { GetDownloadLinkUnauthorized } from 'store/actions/generalActions';

const VideosNavigator = (props) => {
    const { current, onVideoChange, videos, watched, documents } = props;
    const [loadingLink, setLoadingLink] = useState({ id: null, loading: false });

    const getDownloadLink = async (record) => {
        if (record.file) {
            setLoadingLink({ id: record.id, loading: true });
            const res = await GetDownloadLinkUnauthorized('compliance', 'course_doc', record.id)();
            setLoadingLink({ id: record.id, loading: false });
            if (res?.success) {
                // open decoded url
                window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
            }
        }
    };

    const theme = useTheme();
    return (
        <Box
            component="nav"
            sx={{
                width: { md: 400 },
                flexShrink: { md: 0 },
                height: '100%',
                backgroundColor: theme.palette.white.main,
            }}
        >
            <Brand type="blue" />
            <Typography
                component="h2"
                sx={{ color: theme.palette.gray[30], fontSize: 16, fontWeight: 700, mt: 6, ml: 4 }}
            >
                Course Outline
            </Typography>
            <Stack
                sx={{
                    height: 'calc(100% - 164px)',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    py: 3,
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary[900] + '30',
                        borderRadius: '10px',
                    },
                }}
            >
                {videos?.map((video, idx) => {
                    const isWatched = watched?.includes(video?.id);
                    return (
                        <VideoNavLink
                            key={video?.id}
                            onClick={() => onVideoChange(idx)}
                            active={idx === current}
                            watched={isWatched}
                            title={video?.title}
                        />
                    );
                })}
                <Divider />
                {documents?.map((doc) => {
                    return (
                        <Button
                            key={doc?.id}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textTransform: 'unset',
                                textAlign: 'left',
                                py: 1.2,
                                px: 2,
                                borderLeft: '4px solid',
                                borderColor: '#FFF',
                                borderRadius: 0,
                                backgroundColor: 'transparent',
                                gap: 3,
                            }}
                            aria-label="download"
                            onClick={() => getDownloadLink(doc)}
                        >
                            <div className="flex gap-4 items-center justify-start flex-1 w-full">
                                <HiOutlineDocumentAdd size={32} />
                                <div>
                                    <p className="text-[15px] font-semibold text-[#3E414D] m-0">{doc?.title}</p>
                                    <p className="text-[11px] font-normal text-[#77777A] m-0">{doc?.description}</p>
                                </div>
                            </div>
                            {loadingLink.id === doc.id && loadingLink.loading ? (
                                <LoadingState size={13} styles={{ mr: 1.5, width: 'fit-content' }} />
                            ) : null}
                        </Button>
                    );
                })}
            </Stack>
        </Box>
    );
};

const VideoNavLink = (props) => {
    const { active, onClick, watched, title } = props;
    const theme = useTheme();
    return (
        <Button
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                textTransform: 'unset',
                textAlign: 'left',
                py: 1.2,
                px: 2,
                borderLeft: '4px solid',
                borderColor: active ? theme.palette.primary[900] : '#FFF',
                borderRadius: 0,
                backgroundColor: active ? '#f8f9fe' : 'transparent',
            }}
            onClick={onClick}
        >
            {watched ? (
                <CheckCircle sx={{ color: '#37a372', fontSize: 28 }} />
            ) : (
                <Box
                    component={PlayIcon}
                    sx={{ '& path': { fill: active ? theme.palette.primary[900] : theme.palette.neutral[70] } }}
                />
            )}
            <Box sx={{ flex: 1, pl: 2 }}>
                <Typography
                    sx={{
                        fontSize: 15,
                        fontWeight: 600,
                        color: active ? theme.palette.primary[900] : theme.palette.neutral[70],
                    }}
                >
                    {title}
                </Typography>
                {/* <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: active ? theme.palette.primary[900] : theme.palette.neutral[70],
                    }}
                >
                    Duration: 4 minutes
                </Typography> */}
            </Box>
        </Button>
    );
};

export default VideosNavigator;
