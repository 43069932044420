import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import React from 'react';
import FieldItem from './FieldItem';

const SectionCard = (props) => {
    const { enableDelete, deleteASection, index, questions, openFieldModal, deleteField } = props;
    return (
        <Col xs={24} className="mb-3">
            <Card
                title={
                    <Button
                        type="primary"
                        size="large"
                        style={{ float: 'right' }}
                        htmlType="submit"
                        onClick={() => openFieldModal('Add')}
                    >
                        Add Field
                    </Button>
                }
                extra={
                    enableDelete && (
                        <Button
                            shape="circle"
                            danger
                            icon={<DeleteOutlined />}
                            size="large"
                            onClick={() => deleteASection(index)}
                        />
                    )
                }
            >
                <Row gutter={16}>
                    {questions?.map((question, index) => {
                        return (
                            <FieldItem
                                question={question}
                                key={index}
                                openFieldModal={() => openFieldModal('Edit', index)}
                                deleteField={() => deleteField(index)}
                            />
                        );
                    })}
                </Row>
            </Card>
        </Col>
    );
};

export default SectionCard;
