import React, { useEffect, useState } from 'react';
import { CardContainer } from 'components/Dashboard/dashboard-utils/DashboardUtils';
import { useTheme } from '@mui/styles';
import { Link } from 'react-router-dom';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { ArrowDropDown, KeyboardArrowRight } from '@mui/icons-material';
import { LabelTag } from 'components/Dashboard/dashboard-utils/DashboardUtils';
import { useSelector } from 'react-redux';

const StandardCard = ({ merchantStandards }) => {
    const theme = useTheme();

    const auditor_dashboard_result = useSelector((state) => state?.dashboardReducers?.auditor_dashboard_result);

    const [anchorEl, setAnchorEl] = useState(null);
    const [activeStandard, setActiveStandard] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, selected) => {
        setActiveStandard(selected);
        setAnchorEl(null);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const availableStandards = merchantStandards?.length ? merchantStandards : [];
    useEffect(() => {
        if (availableStandards?.length) {
            setActiveStandard(availableStandards?.[0]);
        }
    }, [availableStandards]);

    return (
        <CardContainer cardStyle="w-full md:h-[272px] flex flex-row justify-between p-3">
            <div>
                <div className="flex flex-row items-center gap-4">
                    <Typography sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.primary.main }}>
                        Standards
                    </Typography>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        endIcon={<ArrowDropDown />}
                        sx={{
                            fontSize: 10,
                            fontWeight: 600,
                            color: theme.palette.primary.main,
                            textTransform: 'capitalize',
                            minHeight: 'unset',
                            border: `1px solid ${theme.palette.gray[30]} `,
                            px: 0.8,
                            py: 0.5,
                            borderRadius: '16px',
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        {activeStandard}
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {availableStandards?.map((standard) => (
                            <MenuItem
                                key={standard}
                                selected={standard?.id === activeStandard}
                                onClick={(event) => handleMenuItemClick(event, standard)}
                                sx={{ fontSize: 10, fontWeight: 600, textTransform: 'capitalize' }}
                            >
                                {standard}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
                <div className="grid grid-cols-1 gap-4 mt-6">
                    <div className="flex flex-col gap-2">
                        <Typography sx={{ color: theme.palette.primary.main, fontSize: 14, fontWeight: 700 }}>
                            Documents Uploaded:{' '}
                            <span className="text-[#46464A]">
                                {`${
                                    auditor_dashboard_result?.documents?.[activeStandard?.toUpperCase()]
                                        ?.user_uploaded_document
                                } / ${
                                    auditor_dashboard_result?.documents?.[activeStandard?.toUpperCase()]?.total_document
                                } `}
                            </span>
                        </Typography>
                        <div className="flex flex-row gap-2">
                            <LabelTag
                                label={`${
                                    auditor_dashboard_result?.documents?.[activeStandard?.toUpperCase()]?.accepted
                                } Accepted`}
                                type="primary"
                            />
                            <LabelTag
                                label={`${
                                    auditor_dashboard_result?.documents?.[activeStandard?.toUpperCase()]?.rejected
                                } Rejected`}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <Typography sx={{ color: theme.palette.primary.main, fontSize: 14, fontWeight: 700 }}>
                            Audit Question Answered:{' '}
                            <span className="text-[#46464A]">
                                {` ${auditor_dashboard_result?.forms?.[activeStandard?.toUpperCase()]?.user} / ${
                                    auditor_dashboard_result?.forms?.[activeStandard?.toUpperCase()]?.total
                                } `}
                            </span>
                        </Typography>
                        <div className="flex flex-row gap-2">
                            <LabelTag
                                label={`${
                                    auditor_dashboard_result?.forms?.[activeStandard?.toUpperCase()]?.rejected
                                } Accepted`}
                                type="primary"
                            />
                            <LabelTag
                                label={`${
                                    auditor_dashboard_result?.forms?.[activeStandard?.toUpperCase()]?.accepted
                                } Rejected`}
                            />
                        </div>
                    </div>
                    <div>
                        <Typography sx={{ color: theme.palette.primary.main, fontSize: 14, fontWeight: 700 }}>
                            Exceptions:{' '}
                            {auditor_dashboard_result?.exempted_docs + auditor_dashboard_result?.exempted_forms}
                        </Typography>
                    </div>
                </div>
            </div>
            <Button
                sx={{
                    height: 'fit-content',
                    fontSize: 14,
                    fontWeight: 500,
                    textTransform: 'capitalize',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }}
                variant="text"
                endIcon={<KeyboardArrowRight />}
                component={Link}
                to={`/auditor/compliance/${activeStandard?.toLowerCase()}`}
            >
                Full Report
            </Button>
        </CardContainer>
    );
};

export default StandardCard;
