import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
// mui lab
import { Avatar, Card, CardActions, CardContent, CardHeader, Container, Divider, Grid } from '@mui/material';

// core
import Header from './AuditorPoolHeader';

// styles
import '../../assets/css/auditorpool.css';

// import
import { connect } from 'react-redux';
import { GetApprovedAuditors } from 'store/actions/generalActions';
import { toast } from 'react-toastify';

const AuditorPool = (props) => {
    const { GetApprovedAuditors, approved_auditors } = props;

    // state
    // const [loading, setLoading] = useState(false);
    // functions
    const getApprovedAuditors = async () => {
        // setLoading(true);
        const res = await GetApprovedAuditors();
        // setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    // useEffects
    useEffect(() => {
        getApprovedAuditors();
    }, []);

    return (
        <section className="auditor-pool">
            <Header showFilter={true} />
            <Container className="auditor-pool__body">
                <Grid container columns={12} spacing={4}>
                    {approved_auditors?.map((auditor) => {
                        return <AuditorCardItem auditor={auditor} key={auditor?.id} />;
                    })}
                </Grid>
            </Container>
        </section>
    );
};
const AuditorCardItem = ({ auditor }) => {
    return (
        <Grid item xs={12} sm={6} lg={4} xl={3}>
            <Card>
                <CardHeader
                    avatar={<Avatar>AA</Avatar>}
                    title={`${auditor?.owner_detail?.first_name} ${auditor?.owner_detail?.last_name}`}
                    subheader={auditor?.owner_detail?.email}
                />
                <Divider />
                <CardContent>
                    <div className="list-item">
                        <span>country: </span>
                        <span className="value">{auditor?.country}</span>
                    </div>
                    <div className="list-item">
                        <span>standards: </span>
                        <span className="value">{auditor?.auditor_standards || 'N/A'}</span>
                    </div>
                </CardContent>
                <CardActions>
                    <Link
                        to={{
                            pathname: '/auditor-pool/auditor',
                            state: { auditor: auditor?.id },
                        }}
                    >
                        view auditor
                    </Link>
                </CardActions>
            </Card>
        </Grid>
    );
};
const mapStateToProps = (state) => {
    return {
        approved_auditors: state?.generalReducers?.approved_auditors,
    };
};

export default connect(mapStateToProps, { GetApprovedAuditors })(AuditorPool);
