import {
    ALL_TECH_SEC_METRICS,
    ALL_TECH_SEC_GROUPS,
    ALL_TECH_SEC_DOCUMENTS,
    ALL_TECH_SEC_FORMS,
    ALL_TECH_SEC_CATEGORIES,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case ALL_TECH_SEC_METRICS:
            return {
                ...state,
                all_tech_sec_metrics: action.payload,
            };
        case ALL_TECH_SEC_GROUPS:
            return {
                ...state,
                all_tech_sec_groups: action.payload,
            };
        case ALL_TECH_SEC_DOCUMENTS:
            return {
                ...state,
                all_tech_sec_documents: action.payload,
            };
        case ALL_TECH_SEC_FORMS:
            return {
                ...state,
                all_tech_sec_forms: action.payload,
            };
        case ALL_TECH_SEC_CATEGORIES:
            return {
                ...state,
                all_tech_sec_categories: action.payload,
            };
        case 'CLEAR_DATA':
            return {};
        default:
            return state;
    }
};
