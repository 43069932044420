import React, { useState } from 'react';
// import { Modal } from 'antd';

// redux
import { connect } from 'react-redux';
import { UploadCodeReviewDocument } from 'store/actions/adminActions';
import { Upload } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { accept } from 'validate';
import { AppFormFileInput, AppSubmitButton, AppForm, AppFormSelect } from 'components/new_components/forms';
import AppModal from 'components/new_components/AppModal';
import { Box } from '@mui/material';

const SubcriteriaModal = (props) => {
    const { onClose, open, modalData, UploadCodeReviewDocument } = props;
    const [loadingState, setloadingState] = useState(false);
    const { pdf, docx, excel, txt, ppt } = accept;

    const handleSubmit = async (values) => {
        const formdata = new FormData();
        formdata.append('file', values?.file_upload);
        formdata.append('codereview_id', modalData?.codereview_id);
        formdata.append('severity', values?.severity);
        formdata.append('merchant', modalData?.merchant);
        setloadingState(true);
        const res = await UploadCodeReviewDocument(formdata, modalData?.codereview_id, modalData?.merchant);
        setloadingState(false);
        if (res?.success) {
            toast.success(res?.message);
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };
    // state

    // functions
    const handleClose = () => {
        onClose();
    };

    const riskLevelOptions = [
        {
            name: 'Low',
            value: 'low',
        },
        {
            name: 'Medium',
            value: 'medium',
        },
        {
            name: 'High',
            value: 'high',
        },
    ];

    return (
        <AppForm
            initialValues={{
                file_upload: '',
            }}
            onSubmit={handleSubmit}
        >
            <AppModal
                title={`Upload a Document Result`}
                open={open}
                handleClose={handleClose}
                actions={
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2, marginBottom: 2 }}>
                        <AppSubmitButton
                            text="Upload report"
                            startIcon={<Upload />}
                            variant="contained"
                            color="primary"
                            loading={loadingState}
                            loadingPosition="center"
                            sx={{ width: '50%' }}
                        />
                    </Box>
                }
            >
                <AppFormSelect
                    name="severity"
                    label="Severity"
                    options={riskLevelOptions}
                    defaultValue="Select Severity"
                />
                <AppFormFileInput
                    type="file"
                    label="File"
                    name="file_upload"
                    inputProps={{
                        accept: `${pdf},${docx},${excel},${txt},${ppt}`,
                    }}
                />
            </AppModal>
        </AppForm>
    );
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, { UploadCodeReviewDocument })(SubcriteriaModal);
