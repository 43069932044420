import React, { useEffect, useState } from 'react';
import AppCenteredModal from './AppCenteredModal';
import { Box, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LightIcon from 'assets/img/Light.svg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RegisterClientCredentialsSchema } from 'validate';
import { useDispatch } from 'react-redux';
import { CreateClient } from 'store/actions/ImplementerAction';
import { toast } from 'react-toastify';
import { UpdateClient } from 'store/actions/ImplementerAction';

const AppClientModal = (props) => {
    // props
    const { open, handleClose, modalPayload } = props;
    // state
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    // Theme
    // const theme = useTheme();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(RegisterClientCredentialsSchema),
    });

    const onClose = () => {
        reset({
            name: '',
            mailing_address: '',
            company_description: '',
            phone_number: '',
        });
        handleClose();
    };
    const onSubmit = async (values) => {
        setLoading(true);
        const res = await dispatch(modalPayload ? UpdateClient(values, modalPayload?.id) : CreateClient(values));
        setLoading(false);
        if (res.status) {
            onClose();
        } else {
            toast.error("Something went wrong, we couldn't validate your credentials. Try again!");
        }
    };

    // effect
    useEffect(() => {
        setValue('name', modalPayload?.name);
        setValue('mailing_address', modalPayload?.mailing_address);
        setValue('phone_number', modalPayload?.phone_number);
        setValue('company_description', modalPayload?.description);
    }, [modalPayload]);
    return (
        <AppCenteredModal
            open={open}
            handleClose={onClose}
            title={
                <Typography sx={{ color: '#202D66', fontSize: '18px', fontWeight: 600 }}>
                    {modalPayload ? 'Update Info' : 'Register New Client'}
                </Typography>
            }
            headerAction={
                <div
                    className="flex justify-center items-center bg-white border border-[#E2E8F0] rounded-full h-[20px] w-[20px] cursor-pointer"
                    onClick={onClose}
                >
                    <CloseOutlinedIcon sx={{ color: '#334155', width: 12, height: 12 }} />
                </div>
            }
            width="630px"
            height={Object.keys(errors)?.length ? '680px' : '640px'}
            maxHeight={Object.keys(errors)?.length ? '680px' : '640px'}
            padding="0"
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ mt: '2rem', overflow: 'hidden', mx: '2rem' }}>
                    <Box
                        sx={{
                            backgroundColor: '#F8FAFC',
                            border: '1px solid #F0F0FA',
                            display: 'flex',
                            alignItems: 'flex-start',
                            my: 1.5,
                            py: 1.5,
                            px: 2,
                            borderRadius: 1,
                        }}
                    >
                        <img src={LightIcon} alt={'LightIcon'} />
                        <Typography sx={{ color: '#64748B', fontWeight: 400, fontSize: '13px', ml: 1 }}>
                            A client registered is not automatically approved until some other additional information is
                            collected when we reach out to you.
                        </Typography>
                    </Box>
                    <div className="block mb-3">
                        <label className="text-[#64748B] text-sm font-medium">Business name</label>
                        <input
                            {...register?.('name', { required: true })}
                            className="border border-[#E2E8F0] p-2 w-full rounded text-[#64748B] text-sm font-medium"
                        />
                        {errors?.name && <p className="text-[12px] text-[#FF0000]">Business name is required</p>}
                    </div>
                    {/* <div className="block mb-3">
                        <label className="text-[#64748B] text-sm font-medium">DBA(Doing Bussiness As)</label>
                        <input {...register?.(name, { required: true })}className="border border-[#E2E8F0] py-2 px-1 w-full rounded" />
                    </div> */}
                    <div className="block mb-3">
                        <label className="text-[#64748B] text-sm font-medium">Mailing Address</label>
                        <input
                            {...register?.('mailing_address', { required: false })}
                            className="border border-[#E2E8F0] p-2 w-full rounded text-[#64748B] text-sm font-medium"
                        />
                    </div>
                    <div className="block mb-3">
                        <label className="text-[#64748B] text-sm font-medium">Phone number</label>
                        <input
                            type="tel"
                            {...register?.('phone_number', { required: false })}
                            className="border border-[#E2E8F0] p-2 w-full rounded text-[#64748B] text-sm font-medium"
                        />
                    </div>
                    <div className="block mb-3">
                        <label className="text-[#64748B] text-sm font-medium">Company description</label>
                        <textarea
                            rows={3}
                            {...register?.('company_description', { required: false })}
                            className="border border-[#E2E8F0] p-2 w-full rounded text-[#64748B] text-sm font-medium"
                        />
                    </div>
                </Box>
                <div className="flex justify-end items-end gap-3 bg-[#F8FAFC] py-3 pr-[2rem]">
                    <button
                        className="bg-white text-[#334155] rounded px-4 py-2.5 text-sm font-medium border border-[#E2E8F0]"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button type="submit" className="bg-[#202D66] text-white rounded px-4 py-2.5 text-sm font-semibold">
                        {loading ? 'Loading...' : modalPayload ? 'Save' : 'Register'}
                    </button>
                </div>
            </form>
        </AppCenteredModal>
    );
};

export default AppClientModal;
