import React, { useState } from 'react';
import { toast } from 'react-toastify';

// core components
import Requirements from './components/Requirements';
import Subrequirements from './components/Subrequirements';
import { AppForm, AppFormContext } from 'components/new_components/forms';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { GetAllFormsBySubRequirement } from 'store/actions/adminActions';
import { SubmitMerchantAnswers } from 'store/actions/complianceActions';

// validation
import { validateForm } from '../validation';

const AuditQuestions = (props) => {
    const { loading: loadingRequirement, getRequirements, isComplianceOn, openTaskAssignModal } = props;

    // state
    const [view, setView] = useState(0);
    const [selectedRequirement, setSelectedRequirement] = useState(null);
    const [loading, setLoading] = useState({ content: false, submit: false });
    const [formPayload, setFormPayload] = useState({});
    const [touchedFields, setTouchedFields] = useState({});

    // redux
    const dispatch = useDispatch();
    const all_forms_by_subreq = useSelector((state) => state?.adminReducers?.all_pcidss_forms);
    const merchant_answers = useSelector((state) => state?.complianceReducers?.merchant_answers);
    const all_compliance_assigned_tasks = useSelector(
        (state) => state?.generalReducers?.user_info?.compliance_assigned_tasks
    );
    // logic functions
    const handleViewChange = (requirementId) => {
        setView(1);
        setSelectedRequirement(requirementId);
    };
    const goBack = () => {
        setView(0);
        setSelectedRequirement(null);
    };

    // async functions
    const getSubrequirementQuestion = async (subReqNo) => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await dispatch(GetAllFormsBySubRequirement(subReqNo));
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch forms.");
        }
    };
    //submit a page answers
    const handleSubmitAnswers = async (values) => {
        setLoading((current) => ({ ...current, submit: true }));
        const res = await dispatch(
            SubmitMerchantAnswers({
                answer: values,
                form_id: formPayload.form_id,
            })
        );
        setLoading((current) => ({ ...current, submit: false }));
        if (res?.success) {
            toast.success("You have successfully answered this question, press 'NEXT' to go to the nest question.");
            handleQuestionExempted(formPayload?.subReqNo);
        } else {
            toast.error('Something went wrong!!');
        }
    };
    //submit ai answers to the backend for a particular sub - requirement
    const handleSubmitAutoFilledAnswers = async (values, form_id) => {
        const res = await dispatch(
            SubmitMerchantAnswers({
                answer: values,
                form_id,
            })
        );
        if (res?.success) {
            toast.success('The Compliance AI has successfully answered the questions for this sub-requirement');
            handleQuestionExempted(formPayload?.subReqNo);
        } else {
            toast.error('Something went wrong!!');
        }
    };
    //handle Question exemptions
    const handleQuestionExempted = (subrequirementNo) => {
        getRequirements();
        getSubrequirementQuestion(subrequirementNo);
    };

    return view === 0 ? (
        <Requirements
            onViewChange={handleViewChange}
            loadingContent={loadingRequirement}
            resource="form"
            openTaskAssignModal={openTaskAssignModal}
            all_compliance_assigned_tasks={all_compliance_assigned_tasks}
        />
    ) : (
        <AppForm
            initialValues={formPayload?.values || {}}
            validate={(values) => validateForm(values, formPayload?.requiredValues)}
            onSubmit={handleSubmitAnswers}
        >
            {/* App form and form context aren't put in the subrequirement because subrequirement is used for both form and doc. */}
            <AppFormContext getTouched={setTouchedFields}>
                <Subrequirements
                    requirement={selectedRequirement}
                    goBack={goBack}
                    resource="form"
                    loading={loading}
                    onSubrequirementChange={getSubrequirementQuestion}
                    merchantResources={merchant_answers}
                    subrequirementResources={all_forms_by_subreq}
                    setFormPayload={setFormPayload}
                    touchedFields={touchedFields}
                    submitting={loading.submit}
                    handleResourceExempted={handleQuestionExempted}
                    isComplianceOn={isComplianceOn}
                    formPayload={formPayload}
                    handleSubmitAutoFilledAnswers={handleSubmitAutoFilledAnswers}
                    openTaskAssignModal={openTaskAssignModal}
                    all_compliance_assigned_tasks={all_compliance_assigned_tasks}
                />
            </AppFormContext>
        </AppForm>
    );
};

export default AuditQuestions;
