import React, { useEffect, useRef, useState } from 'react';
import AppInput from 'components/new_components/AppInput';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import { Box, Stack } from '@mui/material';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import UploadSignature from 'views/merchant/audit/AuditSignature/UploadSignature';
import DrawSignature from 'views/merchant/audit/AuditSignature/DrawSignature';
import { Tabs } from 'antd';
import { dataURLtoFile } from 'utils';
import { Cancel } from '@mui/icons-material';

// redux
import { connect } from 'react-redux';
import { GetAuditorDetails, UpdateAuditorDetails } from 'store/actions/adminActions';
import { toast } from 'react-toastify';
import { UpdateUserDetails, GetUserDetails } from 'store/actions/generalActions';

const QuestionOne = (props) => {
    const {
        goNext,
        GetAuditorDetails,
        auditor_info,
        UpdateAuditorDetails,
        GetUserDetails,
        UpdateUserDetails,
        user_details,
    } = props;
    const signatureRef = useRef();

    const id = auditor_info?.id;

    // state
    const [loading, setLoading] = useState({ content: false, submit: false });
    const [data, setData] = useState({});
    const [currentTab, setCurrentTab] = useState('0');
    const [image, setImage] = useState(auditor_info?.auditor_signature);

    const getSignatureImg = () => {
        if (!signatureRef.current) return;
        const signURL = signatureRef.current.toDataURL();
        const image = dataURLtoFile(signURL, 'signature.png');
        console.log({ image });
        return image;
    };
    const clearSignature = () => {
        if (!signatureRef.current) return;
        signatureRef.current.clear();
    };

    const updateUser = async () => {
        const first_name = data?.contact_name?.split(' ')[1];
        const last_name = data?.contact_name?.split(' ')[2];
        const payload = {
            first_name,
            last_name,
            title: data?.title,
        };
        const res = await UpdateUserDetails(payload);
        setLoading({ ...loading, submit: false });
        if (res?.success) {
            goNext();
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    // functions
    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading({ ...loading, submit: true });
        const signatureImage = currentTab === '0' ? image : getSignatureImg();
        const payload = new FormData();

        const payload_ = {
            mailing_address: data?.mailing_address,
            phone_number: data?.phone_number,
            website: data?.website,
            assessor_pci_credentials: data?.assessor_pci_credentials,
            dba: data?.dba,
        };
        payload.append('mailing_address', data?.mailing_address);
        payload.append('phone_number', data?.phone_number);
        payload.append('website', data?.website);
        payload.append('assessor_pci_credentials', data?.assessor_pci_credentials);
        payload.append('auditor_signature', signatureImage);
        payload.append('dba', data?.dba);
        const res = await UpdateAuditorDetails(typeof signatureImage === 'object' ? payload : payload_, id);
        if (res?.success) {
            updateUser();
        } else {
            toast.error(res?.message);
        }
    };
    const getAuditorDetails = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAuditorDetails();
        setLoading({ ...loading, content: false });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getUserDetails = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetUserDetails();
        setLoading({ ...loading, content: false });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
        // setFormIsTouched(true);
    };

    // useEffect
    useEffect(() => {
        getUserDetails();
        getAuditorDetails();
    }, []);
    useEffect(() => {
        const { name, dba, mailing_address, phone_number, website, owner_detail, assessor_pci_credentials } =
            auditor_info;
        const { first_name, last_name, title } = user_details;
        setData({
            name,
            contact_name: `${first_name} ${last_name}`,
            dba,
            mailing_address,
            phone_number,
            website,
            email: owner_detail?.email,
            assessor_pci_credentials: assessor_pci_credentials,
            title: title,
        });

        return () => setData({});
    }, [auditor_info]);

    // constants
    const tabs = [
        { key: '0', label: 'Upload signature' },
        { key: '1', label: 'Draw Signature' },
    ];

    return (
        <Stack component="form" onSubmit={handleSubmit} justifyContent="space-between" className="flex-1">
            <Box sx={{ px: 3, pb: 3 }}>
                <div className="w-full">
                    <AppInput
                        label="Company Name"
                        name="name"
                        value={data?.name || ''}
                        onChange={handleTextChange}
                        required
                    />
                </div>
                <div className="flex w-full lg:flex-row flex-col">
                    <div className="lg:w-1/2 w-full lg:mr-4">
                        <AppInput
                            label="DBA (Doing business as)"
                            name="dba"
                            value={data?.dba || ''}
                            onChange={handleTextChange}
                            required
                        />
                    </div>
                    <div className="lg:w-1/2 w-full lg:ml-4">
                        <AppInput
                            label="Mailing Address"
                            name="mailing_address"
                            value={data?.mailing_address || ''}
                            onChange={handleTextChange}
                            required
                        />
                    </div>
                </div>
                <div className="flex w-full lg:flex-row flex-col">
                    <div className="lg:w-1/2 w-full lg:mr-4">
                        <AppInput
                            label="Contact Name"
                            name="contact_name"
                            value={data?.contact_name || ''}
                            onChange={handleTextChange}
                            disabled={data?.contact_name === ''}
                        />
                    </div>
                    <div className="lg:w-1/2 w-full lg:ml-4">
                        <AppInput
                            label="Contact Title"
                            name="title"
                            value={data?.title || ''}
                            onChange={handleTextChange}
                        />
                    </div>
                </div>
                <div className="flex w-full lg:flex-row flex-col">
                    <div className="lg:w-1/2 w-full lg:mr-4">
                        <AppInput
                            label="Contact Phone number"
                            name="phone_number"
                            value={data?.phone_number || ''}
                            onChange={handleTextChange}
                            required
                        />
                    </div>
                    <div className="lg:w-1/2 w-full lg:ml-4">
                        <AppInput
                            label="Contact email address"
                            name="email"
                            value={data?.email || ''}
                            onChange={handleTextChange}
                            disabled
                        />
                    </div>
                </div>
                <div className="w-full">
                    <AppInput
                        label="Company main website"
                        name="website"
                        value={data?.website || ''}
                        onChange={handleTextChange}
                        required
                    />
                </div>
                <div className="w-full">
                    <AppInput
                        label="Pci credentials"
                        name="assessor_pci_credentials"
                        value={data?.assessor_pci_credentials || ''}
                        onChange={handleTextChange}
                        required
                    />
                </div>
                {/* {!auditor_info?.auditor_signature && ( */}
                <>
                    <Tabs
                        items={tabs}
                        defaultActiveKey={currentTab}
                        onChange={(e) => setCurrentTab(e)}
                        className="mt-3"
                    />
                    <div className="border-2 border-dashed border-sky-500 rounded-lg relative h-64">
                        <AppTabPanel value={currentTab} index="0">
                            <UploadSignature image={image} setImageFile={setImage} />
                        </AppTabPanel>
                        <AppTabPanel value={currentTab} index="1">
                            <DrawSignature ref={signatureRef} />
                        </AppTabPanel>
                    </div>
                    {currentTab === '1' && (
                        <button
                            className="flex justify-end items-center ml-auto"
                            onClick={clearSignature}
                            type="button"
                        >
                            <span>Clear signature</span>
                            <Cancel sx={{ fontSize: 14, m: 'auto' }} />
                        </button>
                    )}
                </>
                {/* )} */}
            </Box>
            <Stack direction="row" sx={{ px: 3 }}>
                <AppLoadingButton
                    text="Next"
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={loading?.submit}
                />
            </Stack>
        </Stack>
    );
};
const mapStateToProps = (state) => ({
    auditor_info: state?.adminReducers?.auditor_info,
    user_details: state?.generalReducers?.user_info,
});
export default connect(mapStateToProps, { GetAuditorDetails, UpdateAuditorDetails, GetUserDetails, UpdateUserDetails })(
    QuestionOne
);
