import { combineReducers } from 'redux';

import authReducers from './authReducers';
import auditorReducers from './auditorReducers';
import merchantReducers from './merchantReducers';
import generalReducers from './generalReducers';
import adminReducers from './adminReducers';
import vendorReducers from './vendorReducers';
import notificationReducers from './notificationReducers';
import guideReducers from './guideReducers';
import onboardingReducers from './onboardingReducers';
import integrationsReducers from './integrationsReducers';
import complianceReducers from './complianceReducers';
import automatedScansReducers from './automatedScansReducers';
import subscriptionReducers from './subscriptionReducers';
import dashboardReducers from './dashboardReducers';
import auditReducers from './auditReducers';
import trainingReducers from './trainingReducers';
import fraudGuardianReducers from './fraudGuardianReducers';
import apiComplianceReducers from './apiComplianceReducers';
import iso27001plusiso27035Reducers from './iso270001+iso27035Reducers';
import pcidssReducers from './pcidssReducers';
import ndprReducers from './ndprReducers';
import tasksReducers from './taskReducers';
import articlesReducers from './articlesReducers';
import pcislcReducers from './pci_slcReducers';
import implementerReducer from './implementerReducer';
import userGuideReducers from './userGuideReducers';
import ISO9001Reducers from './iso9001Reducers';
import CISOReducers from './cisoReducers';
import riskAssessmentReducers from './riskAssessmentReducers';

export default combineReducers({
    authReducers,
    auditReducers,
    auditorReducers,
    merchantReducers,
    generalReducers,
    adminReducers,
    vendorReducers,
    notificationReducers,
    guideReducers,
    onboardingReducers,
    integrationsReducers,
    complianceReducers,
    automatedScansReducers,
    subscriptionReducers,
    dashboardReducers,
    trainingReducers,
    fraudGuardianReducers,
    apiComplianceReducers,
    iso27001plusiso27035Reducers,
    pcidssReducers,
    ndprReducers,
    tasksReducers,
    articlesReducers,
    pcislcReducers,
    implementerReducer,
    userGuideReducers,
    ISO9001Reducers,
    CISOReducers,
    riskAssessmentReducers,
});
