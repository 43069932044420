import React, { forwardRef } from 'react';
import AppLoadingButton from './AppLoadingButton';

const AppUploadButton = forwardRef((props, ref) => {
    const { id, label, onChange, accept, inputRef, ...restProps } = props;
    return (
        <label htmlFor={id} ref={ref}>
            <input
                id={id}
                name={id}
                type="file"
                style={{ display: 'none' }}
                onChange={onChange}
                accept={accept}
                ref={inputRef}
            />
            <AppLoadingButton {...restProps} text={label} component="span" />
        </label>
    );
});

export default AppUploadButton;
