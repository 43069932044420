import React from 'react';
import { Tabs } from 'antd';
import AdminUploadedDocs from './admin_uploaded_docs/AdminUploadedDocs';
import ExtraDocs from './extra_docs/ExtraDocs';

const index = () => {
    return (
        <Tabs
            defaultActiveKey="1"
            items={[
                {
                    label: 'Admin Docs',
                    key: '1',
                    children: <AdminUploadedDocs />,
                },
                {
                    label: 'Merchant Extra Docs',
                    key: '2',
                    children: <ExtraDocs />,
                },
            ]}
        />
    );
};

export default index;
