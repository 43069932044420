import { Stack } from '@mui/material';
import React from 'react';
import SearchIcon from 'assets/img/Searchicon.svg';

const ClientTitle = (props) => {
    const { onSearch } = props;

    return (
        <Stack direction="row" alignItems="center" sx={{ ml: -2 }}>
            <div className="relative">
                <input
                    onChange={onSearch}
                    className="border border-[#E2E8F0] rounded pl-[28px] text-[#94A3B8] text-sm font-normal py-2 outline-none w-[250px] placeholder:text-[#94A3B8]"
                    placeholder="Search"
                />
                <img src={SearchIcon} alt="SearchIcon" className="absolute top-[11px] left-2" />
            </div>
        </Stack>
    );
};
export default ClientTitle;
