import { useEffect, useState } from 'react';
import { Select, Stack, MenuItem, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Matrix3Data, Matrix5Data } from './utils';

export default function RiskMatrix({ matrix }) {
    const [type, setType] = useState('inherent');
    const get_risk_settings = useSelector((state) => state?.riskAssessmentReducers?.get_risk_settings);
    const [riskMatrixTabStates, setRiskMatrixTabStates] = useState({
        risk5x5Matrix: {
            columnLabels: get_risk_settings?.[0]?.columns_5x5,
            rowLabels: get_risk_settings?.[0]?.rows_5x5,
            data: Matrix5Data,
        },
        risk3x3Matrix: {
            columnLabels: get_risk_settings?.[0]?.columns_3x3,
            rowLabels: get_risk_settings?.[0]?.rows_3x3,
            data: Matrix3Data,
        },
    });

    // functions
    function updateRisk3x3MatrixLabel(rowLabels, columnLabels) {
        setRiskMatrixTabStates({
            ...riskMatrixTabStates,
            risk3x3Matrix: {
                ...riskMatrixTabStates.risk3x3Matrix,
                columnLabels,
                rowLabels,
            },
        });
    }

    function updateRisk5x5MatrixLabel(rowLabels, columnLabels) {
        setRiskMatrixTabStates({
            ...riskMatrixTabStates,
            risk5x5Matrix: {
                ...riskMatrixTabStates.risk5x5Matrix,
                columnLabels,
                rowLabels,
            },
        });
    }

    function updateMatrixData(type, data) {
        if (type === 3) {
            setRiskMatrixTabStates({
                ...riskMatrixTabStates,
                risk3x3Matrix: {
                    ...riskMatrixTabStates.risk3x3Matrix,
                    data,
                },
            });
        } else {
            setRiskMatrixTabStates({
                ...riskMatrixTabStates,
                risk5x5Matrix: {
                    ...riskMatrixTabStates.risk5x5Matrix,
                    data,
                },
            });
        }
    }

    // constants
    const overview = useSelector((state) => state?.riskAssessmentReducers?.overview);

    useEffect(() => {
        if (overview) {
            if (matrix === 3 && overview.rows_3x3 && overview.columns_3x3) {
                updateRisk3x3MatrixLabel(overview.rows_3x3, overview.columns_3x3);
                updateMatrixData(3, overview.risk_heat_map_3x3[type] ?? Matrix3Data);
            }

            if (matrix === 5 && overview.rows_5x5 && overview.columns_5x5) {
                updateRisk5x5MatrixLabel(overview.rows_5x5, overview.columns_5x5);
                updateMatrixData(5, overview.risk_heat_map_5x5[type] ?? Matrix5Data);
            }
        }
    }, [overview]);

    useEffect(() => {
        if (overview) {
            if (matrix === 3 && overview?.risk_heat_map_3x3?.[type]) {
                updateMatrixData(3, overview?.risk_heat_map_3x3?.[type]);
            }

            if (matrix === 5 && overview?.risk_heat_map_5x5?.[type]) {
                updateMatrixData(5, overview?.risk_heat_map_5x5?.[type]);
            }
        }
    }, [type]);

    const handleChange = (e) => {
        setType(e.target.value);
    };

    return (
        <div className="col-span-6 bg-white rounded-md border-[#f1f5f9] px-3 py-3">
            <Stack
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        color: '#2b3674',
                        fontWeight: 600,
                    }}
                >
                    Risk heat map
                </Typography>
                <Select
                    labelId="year-select-label"
                    id="year-select"
                    value={type}
                    label="Years"
                    onChange={handleChange}
                    size="small"
                    sx={{
                        textTransform: 'capitalize',
                        backgroundColor: '#F8FAFC',
                        border: '1px solid #E2E8F0',

                        '& .MuiSelect-select': {
                            fontSize: 11,
                            paddingLeft: '10px',
                            paddingBlock: '3px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #E2E8F0 !important',
                        },
                    }}
                    defaultValue="inherent"
                >
                    {['inherent', 'residual'].map((value, key) => (
                        <MenuItem sx={{ fontSize: 11, textTransform: 'capitalize' }} key={key} value={value}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </Stack>
            <div className="mt-4">
                {matrix === 3 ? (
                    <ThreeByThreeMatrix
                        risk3x3Matrix={riskMatrixTabStates?.risk3x3Matrix}
                        get_risk_settings={get_risk_settings?.[0]?.matrix_3x3}
                    />
                ) : (
                    <FiveByFiveMatrix
                        risk5x5Matrix={riskMatrixTabStates?.risk5x5Matrix}
                        get_risk_settings={get_risk_settings?.[0]?.matrix_5x5}
                    />
                )}
            </div>
        </div>
    );
}

function FiveByFiveMatrix({ risk5x5Matrix, get_risk_settings }) {
    const [localColumnLabels, setLocalColumnLabels] = useState({
        0: 'Severe',
        1: 'Major',
        2: 'Sigificant',
        3: 'Minor',
        4: 'Insigificant',
    });
    const [localRowLabels, setLocalRowLabels] = useState({
        0: 'Rare',
        1: 'Unlikely',
        2: 'Moderate',
        3: 'Likely',
        4: 'Almost Certain',
    });

    // const greenBoxes = [2, 6, 10];
    // const deepGreenBoxes = [0, 1, 5];
    // const yellowBoxes = [3, 4, 7, 8, 11, 12, 15, 16, 20];
    // const orangeBoxes = [9, 13, 17, 21];
    // const redBoxes = [14, 18, 22, 19, 23, 24];
    const getBoxColor = (color) => {
        return color === 'very_low'
            ? 'bg-[#006C46] text-[#ffffff]'
            : color === 'low'
            ? 'bg-[#55BE8B] text-[#ffffff]'
            : color === 'medium'
            ? 'bg-[#F6EB50] text-[#475569]'
            : color === 'high'
            ? 'bg-[#DE3730] text-[#ffffff]'
            : color === 'very_high'
            ? 'bg-[#93000A] text-[#ffffff]'
            : 'bg-[#E2E8F0] text-transparent';
    };

    const matrices = [
        [
            { index: 0, value: get_risk_settings[0] },
            { index: 1, value: get_risk_settings[1] },
            { index: 2, value: get_risk_settings[2] },
            { index: 3, value: get_risk_settings[3] },
            { index: 4, value: get_risk_settings[4] },
        ],
        [
            { index: 5, value: get_risk_settings[5] },
            { index: 6, value: get_risk_settings[6] },
            { index: 7, value: get_risk_settings[7] },
            { index: 8, value: get_risk_settings[8] },
            { index: 9, value: get_risk_settings[9] },
        ],
        [
            { index: 10, value: get_risk_settings?.[10] },
            { index: 11, value: get_risk_settings?.[11] },
            { index: 12, value: get_risk_settings?.[12] },
            { index: 13, value: get_risk_settings?.[13] },
            { index: 14, value: get_risk_settings?.[14] },
        ],
        [
            { index: 15, value: get_risk_settings?.[15] },
            { index: 16, value: get_risk_settings?.[16] },
            { index: 17, value: get_risk_settings?.[17] },
            { index: 18, value: get_risk_settings?.[18] },
            { index: 19, value: get_risk_settings?.[19] },
        ],
        [
            { index: 20, value: get_risk_settings?.[20] },
            { index: 21, value: get_risk_settings?.[21] },
            { index: 22, value: get_risk_settings?.[22] },
            { index: 23, value: get_risk_settings?.[23] },
            { index: 24, value: get_risk_settings?.[24] },
        ],
    ];

    useEffect(() => {
        setLocalColumnLabels(risk5x5Matrix?.columnLabels);
        setLocalRowLabels(risk5x5Matrix?.rowLabels);
    }, [risk5x5Matrix]);

    return (
        <div className="flex gap-6 relative px-1.5">
            <p className="text-[#475569] font-semibold text-sm -rotate-90 h-fit flex self-start w-0 mt-[10rem]">
                Likelihood
            </p>

            <div className="flex gap-1.5">
                <div className="flex flex-col-reverse gap-1.5 w-[80px]">
                    <div className="flex-1" />

                    {(Object?.values(localRowLabels) || [])?.map((label, index) => (
                        <div key={index} className="flex-1 !rounded-sm">
                            <div className="grid place-items-center h-10 focus-within:bg-black/10 transition-colors">
                                <p className="text-xs font-medium text-[#64748B] text-center break-all">{label}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex flex-col">
                    <div className="grid grid-cols-[repeat(5,minmax(0,100px))] gap-1.5 relative p-0">
                        {matrices?.map((el, key) => {
                            return (
                                <div className="flex flex-col-reverse gap-1.5" key={key}>
                                    <div className="flex-1 !rounded-sm">
                                        <div className="grid place-items-center h-10 focus-within:bg-black/10 transition-colors">
                                            <p className="text-xs font-medium text-[#64748B] text-center break-all">
                                                {localColumnLabels[key.toString()]}
                                            </p>
                                        </div>
                                    </div>

                                    {el?.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`flex-1 !rounded-sm ${getBoxColor(item?.value)}`}
                                            >
                                                <p
                                                    className={`truncate capitalize font-medium text-[13px] h-[50px] flex place-items-center justify-center`}
                                                >
                                                    {risk5x5Matrix?.data[item?.index][`${item?.index}`]}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>

                    <p className="text-[#475569] font-semibold text-sm h-fit flex self-center">Impact</p>
                </div>
            </div>
        </div>
    );
}

function ThreeByThreeMatrix({ risk3x3Matrix, get_risk_settings }) {
    const [localColumnLabels, setLocalColumnLabels] = useState({
        0: 'Insigificant',
        1: 'Minor',
        2: 'Insigificant',
    });
    const [localRowLabels, setLocalRowLabels] = useState({
        0: 'Rare',
        1: 'Unlikely',
        2: 'Moderately',
    });

    // const greenBoxes = [0, 1, 3];
    // const yellowBoxes = [2, 4, 6];
    // const redBoxes = [5, 8, 7];
    const getBoxColor = (color) => {
        return color === 'low'
            ? 'bg-[#55BE8B] text-[#ffffff]'
            : color === 'medium'
            ? 'bg-[#F6EB50] text-[#475569]'
            : color === 'high'
            ? 'bg-[#DE3730] text-[#ffffff]'
            : 'bg-[#E2E8F0] text-transparent';
    };

    const matrices = [
        [
            { index: 0, value: get_risk_settings?.[0] },
            { index: 1, value: get_risk_settings?.[1] },
            { index: 2, value: get_risk_settings?.[2] },
        ],
        [
            { index: 3, value: get_risk_settings?.[3] },
            { index: 4, value: get_risk_settings?.[4] },
            { index: 5, value: get_risk_settings?.[5] },
        ],
        [
            { index: 6, value: get_risk_settings?.[6] },
            { index: 7, value: get_risk_settings?.[7] },
            { index: 8, value: get_risk_settings?.[8] },
        ],
    ];

    useEffect(() => {
        setLocalColumnLabels(risk3x3Matrix?.columnLabels);
        setLocalRowLabels(risk3x3Matrix?.rowLabels);
    }, [risk3x3Matrix]);

    return (
        <div className="flex gap-6 relative pr-8 pl-1.5">
            <p className="text-[#475569] font-semibold text-sm -rotate-90 h-fit flex self-start w-0 mt-[7rem]">
                Likelihood
            </p>

            {localRowLabels && (
                <div className="flex gap-3">
                    <div className="flex flex-col-reverse gap-1.5 w-[110px]">
                        <div className="flex-1" />

                        {(Object?.values(localRowLabels) || [])?.map((label, index) => (
                            <div key={index} className="flex-1 !rounded-sm">
                                <div className="grid place-items-center h-[50px] focus-within:bg-black/10 transition-colors">
                                    <p className="text-xs font-medium text-[#64748B] text-center break-all">{label}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col">
                        <div className="grid grid-cols-[repeat(3,minmax(0,136px))] gap-1.5 relative p-0">
                            {matrices?.map((el, key) => {
                                return (
                                    <div className="flex flex-col-reverse gap-1.5" key={key}>
                                        <div className="flex-1 !rounded-sm">
                                            <div className="grid place-items-center h-[50px] focus-within:bg-black/10 transition-colors">
                                                <p className="text-xs font-medium text-[#64748B] text-center break-all">
                                                    {localColumnLabels[key.toString()]}
                                                </p>
                                            </div>
                                        </div>

                                        {el?.map((item, index) => (
                                            <div
                                                key={index}
                                                className={`flex-1 flex justify-center items-center !rounded-sm ${getBoxColor(
                                                    item?.value
                                                )}`}
                                            >
                                                <p
                                                    className={`truncate capitalize font-medium text-[13px] h-[50px] flex place-items-center justify-center`}
                                                >
                                                    {risk3x3Matrix?.data[item?.index][item?.index]}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>

                        <p className="text-[#475569] font-semibold text-sm h-fit flex self-center">Impact</p>
                    </div>
                </div>
            )}
        </div>
    );
}
