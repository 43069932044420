export const StatusOption = [
    { name: 'All', value: 'All' },
    { name: 'Clean', value: 'clean' },
    { name: 'Fraudulent', value: 'fraudulent' },
];

export const TransactionStatusption = [
    { name: 'All', value: 'All' },
    { name: 'Failed', value: 'failed' },
    { name: 'Successful', value: 'successful' },
];

export const DATE_OPTIONS = [
    {
        name: 'Any Time',
        value: 'all',
    },
    {
        name: 'Today',
        value: 'today',
    },
    {
        name: 'Yesterday',
        value: 'yesterday',
    },
    {
        name: 'Last 7 days',
        value: 'last_seven_days',
    },
    {
        name: 'Last 30 days',
        value: 'last_thirty_days',
    },
    {
        name: 'Last 3 months',
        value: 'last_three_months',
    },
    {
        name: 'Last 12 months',
        value: 'last_twelve_months',
    },
];
export const initialValues = {
    threshold: '',
    limit: '',
};
