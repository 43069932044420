import React from 'react';
import { Dropdown, Menu } from 'antd';
import { DeleteOutlined, MoreOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

export const PlansOperations = (props) => {
    const { openEditModal, deleteModal, record, openViewModal } = props;
    const menu = (
        <Menu className="tableaction">
            <Menu.Item key="0" onClick={() => openViewModal(record)}>
                <EyeOutlined /> View
            </Menu.Item>
            <Menu.Item key="1" onClick={() => openEditModal('Edit', record)}>
                <EditOutlined /> Edit
            </Menu.Item>
            <Menu.Item key="2" style={{ color: 'red' }} onClick={() => deleteModal(record)}>
                <DeleteOutlined /> Delete
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <a
                href="#"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                onKeyDown={(e) => e.preventDefault()}
            >
                <MoreOutlined />
            </a>
        </Dropdown>
    );
};
