import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import { Drawer, Row, Col, Avatar, Tooltip, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';

// redux
import { connect } from 'react-redux';
import { GetMerchantsByAuditorID } from 'store/actions/adminActions';

const ViewAuditorDrawer = (props) => {
    const { visible, closeViewDrawer, record, all_auditor_merchants, GetMerchantsByAuditorID } = props;
    // const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (visible) {
            getAllAuditorMerchants();
        }
    }, [record]);

    const getAllAuditorMerchants = async () => {
        // setLoading(true);
        const res = await GetMerchantsByAuditorID(record?.id);
        // setLoading(false);
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    return (
        <>
            <Drawer
                title={'Auditor Details'}
                open={visible}
                width="800px"
                getContainer={false}
                onClose={closeViewDrawer}
                destroyOnClose={true}
                contentWrapperStyle={{
                    height: 'calc(100% - 50px)',
                    overflow: 'auto',
                }}
            >
                <div className="userdataContainer">
                    <div className="item">
                        <Row gutter={28} align="middle">
                            <Col xs={12} xl={8} lg={8}>
                                <span>Name:</span>
                            </Col>
                            <Col xs={12} xl={12} lg={12}>
                                {record?.owner_detail?.first_name}
                                {record?.owner_detail?.last_name}
                            </Col>
                        </Row>
                    </div>
                    <div className="item">
                        <Row gutter={28} align="middle">
                            <Col xs={12} xl={8} lg={8}>
                                <span>Email:</span>
                            </Col>
                            <Col xs={12} xl={12} lg={12}>
                                {record?.owner_detail?.email}
                            </Col>
                        </Row>
                    </div>
                    <div className="item">
                        <Row gutter={28} align="middle">
                            <Col xs={12} xl={8} lg={8}>
                                <span>Phone:</span>
                            </Col>
                            <Col xs={12} xl={12} lg={12}>
                                {record?.phone_number || 'N/A'}
                            </Col>
                        </Row>
                    </div>
                    <div className="item">
                        <Row gutter={28} align="middle">
                            <Col xs={12} xl={8} lg={8}>
                                <span>Approved:</span>
                            </Col>
                            <Col xs={12} xl={12} lg={12}>
                                <>
                                    {record?.is_approved && <Tag color="green">Approved</Tag>}
                                    {!record?.is_approved && <Tag color="red">Not Approved</Tag>}
                                </>
                            </Col>
                        </Row>
                    </div>
                    <div className="item">
                        <Row gutter={28} align="middle">
                            <Col xs={12} xl={8} lg={8}>
                                <span>Assigned Merchants:</span>
                            </Col>
                            <Col xs={12} xl={12} lg={12}>
                                {all_auditor_merchants?.length > 0 ? (
                                    <Avatar.Group
                                        maxCount={2}
                                        size="large"
                                        maxStyle={{
                                            color: '#f56a00',
                                            backgroundColor: '#fde3cf',
                                        }}
                                    >
                                        {all_auditor_merchants?.map((merchant) => {
                                            return (
                                                <Tooltip
                                                    title={`${merchant?.owner_detail?.first_name} ${merchant?.owner_detail?.last_name}`}
                                                    placement="top"
                                                    key={merchant?.id}
                                                >
                                                    <Avatar
                                                        style={{
                                                            backgroundColor: '#87d068',
                                                        }}
                                                        icon={<UserOutlined />}
                                                        src={merchant?.company_img}
                                                    />
                                                </Tooltip>
                                            );
                                        })}
                                    </Avatar.Group>
                                ) : (
                                    'Not assigned'
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </Drawer>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_auditor_merchants: state?.adminReducers?.all_auditor_merchants,
    };
};
export default connect(mapStateToProps, { GetMerchantsByAuditorID })(ViewAuditorDrawer);
