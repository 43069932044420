import React, { useMemo, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import { TextSnippet } from '@mui/icons-material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { toast } from 'react-toastify';

// Core components
import AppUploadButton from 'components/new_components/AppUploadButton';
import ItemSubheader from '../../common/ItemSubheader';
import DocumentDrawer from '../../common/document/DocumentDrawer';
import { accept } from 'validate';
import CodeReviewModal from './CodeReviewModal';
import CodeReviewListModal from '../../common/document/codereviews/CodereviewList';

// Utils
import { codeReviewInitialValues } from '../utils/constants';

// Redux
import { connect } from 'react-redux';
import { UploadMerchantDocument, ExemptBlockchainCompliance } from 'store/actions/complianceActions';

//translation
import { useTranslation } from 'react-i18next';

const { pdf, docx, excel, txt, ppt } = accept;

const Code_reviewItem = (props) => {
    const {
        onResourceChange,
        UploadMerchantDocument,
        document,
        all_merchant_documents,
        ExemptBlockchainCompliance,
        title,
        id,
    } = props;
    const theme = useTheme();
    const [drawerOpenedTab, setDrawerOpenedTab] = useState(null);
    const [ModalOpen, setModalOpen] = useState(false);
    const [listModal, setListModal] = useState(false);
    const [loading, setLoading] = useState({ upload: false, exempt: false });
    const [modalPayload, setModalPayload] = useState(codeReviewInitialValues);
    const inputRef = useRef();

    //translation
    const { t } = useTranslation('compliance');

    const openDrawer = (tab) => setDrawerOpenedTab(tab);
    const closeDrawer = () => setDrawerOpenedTab(null);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setTimeout(() => setModalPayload({}), 1000);
    };

    const selectedDocument = useMemo(() => {
        return all_merchant_documents?.find((doc) => doc?.tag === 'codereview' && doc?.subcriteria === id);
    }, [all_merchant_documents]);

    // async functions
    // Upload Document
    const uploadMerchantDocument = async (e) => {
        // get the form data
        const doc = new FormData();
        doc.append('document', e.target.files[0]);
        doc.append('document_id', document?.id);
        doc.append('subcriteria', document?.subcriteria);
        doc.append('tag', 'codereview');

        // sending the data
        setLoading({ ...loading, upload: true });
        const res = await UploadMerchantDocument(doc);
        setLoading({ ...loading, upload: false });
        //handling response
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
        inputRef.current.value = null;
    };

    const openListModal = () => {
        setListModal(true);
    };

    const closeListModal = () => {
        setListModal(false);
    };

    // Exempt Document
    const exemptMerchantDocument = async () => {
        const payload = {
            document: document?.id,
            subcriteria: document?.subcriteria,
            criteria: document?.criteria,
        };
        setLoading({ ...loading, exempt: true });
        const res = await ExemptBlockchainCompliance(payload);
        setLoading({ ...loading, exempt: false });
        if (res?.success) {
            toast.success(res?.message);
            onResourceChange();
        } else {
            toast.error(res?.message);
        }
    };

    const document_id = {
        doc_id: selectedDocument?.tag,
        user_doc_id: selectedDocument?.id,
        subcriteria: selectedDocument?.subcriteria,
    };

    const payload = {
        document: document?.id,
        subcriteria: document?.subcriteria,
        criteria: document?.criteria,
        reason: '',
    };

    return (
        <Box sx={{ p: 2, py: 4 }}>
            <ItemSubheader
                hasResource={Boolean(selectedDocument)}
                exempting={loading.exempt}
                exceptionReason={document}
                onExempt={exemptMerchantDocument}
                menuItems={[
                    { label: t('blockchain.docAndVersions'), action: () => openDrawer(0) },
                    // { label: 'Comments', action: () => openDrawer(1) },
                    { label: t('blockchain.codeReview'), action: () => openListModal() },
                ]}
                resource="doc"
                payload={payload}
                blockchain
                onResourceChange={onResourceChange}
            />
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ py: 5 }} spacing={3}>
                <TextSnippet color="primary" />
                <Stack>
                    <Typography
                        sx={{
                            color: theme.palette.gray[800],
                            fontWeight: 500,
                            fontSize: 16,
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 300,
                            fontSize: 12,
                        }}
                    >
                        {t('blockchain.noCodeReview')}
                        <Typography
                            sx={{
                                color: theme.palette.primary[900],
                                fontWeight: 500,
                                fontSize: 12,
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            component={'span'}
                            onClick={openModal}
                        >
                            {t('blockchain.requestCodeReview')}
                        </Typography>
                        .
                    </Typography>
                    <Stack direction="row" spacing={3} sx={{ pt: 4 }}>
                        <AppUploadButton
                            color="primary"
                            variant="outlined"
                            label={selectedDocument ? t('uploadVersion') : t('uploadDocument')}
                            startIcon={<FileUploadIcon />}
                            id={document?.id}
                            loading={loading.upload}
                            onChange={uploadMerchantDocument}
                            accept={`${pdf},${docx},${excel},${txt},${ppt}`}
                            sx={{ textTransform: 'uppercase' }}
                            inputRef={inputRef}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <CodeReviewModal open={ModalOpen} handleClose={closeModal} payload={modalPayload} />
            <DocumentDrawer
                open={Boolean(drawerOpenedTab !== null)}
                tab={drawerOpenedTab}
                handleClose={closeDrawer}
                tag={'codereview'}
                document={document_id}
                onResourceChange={onResourceChange}
            />
            <CodeReviewListModal open={listModal} handleClose={closeListModal} />
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        all_merchant_documents: state?.complianceReducers?.all_merchant_documents,
    };
};

export default connect(mapStateToProps, {
    UploadMerchantDocument,
    ExemptBlockchainCompliance,
})(Code_reviewItem);
