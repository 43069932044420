import React, { useState, useMemo, useEffect } from 'react';
import LoadingState from 'components/new_components/LoadingState';

// redux
import { useSelector } from 'react-redux';
import QuestionItem from '../../common/audit_questions/QuestionItem';
import DescriptionLayer from '../../common/DescriptionLayer';
import DocumentItem from '../../common/document/DocumentItem';
import SubgroupView from '../../common/SubgroupView';

//translations
import { useTranslation } from 'react-i18next';

const ResourceView = (props) => {
    // props
    const {
        goBack,
        resource,
        isogroupID,
        isogroupResources,
        merchantResources,
        loading,
        setFormPayload,
        touchedFields,
        submitting,
        handleResourceExempted,
    } = props;

    // state
    const [page, setPage] = useState(1);
    const [overallMetric, setOverallMetric] = useState('');
    const [isogroupPercent, setIsogroupPercent] = useState(0);

    const all_techsec_groups = useSelector((state) => state?.iso27001plusiso27035Reducers?.all_tech_sec_groups);
    const all_techsecgroup_metrics = useSelector((state) => state?.iso27001plusiso27035Reducers?.all_tech_sec_metrics);

    //translation
    const { t } = useTranslation('compliance');
    const language = localStorage.getItem('i18nextLng');

    console.log(language);

    // memos
    // get the selected isogroup by the given isogroup id
    const selectedIsogroup = useMemo(() => {
        return all_techsec_groups?.find((isogroup) => isogroup?.id === isogroupID);
    }, [isogroupID, page]);
    // get the status of te resource in category
    const resourcesHaveBeenAttendedTo = useMemo(() => {
        return isogroupResources?.map((isogroupRes) => {
            return merchantResources?.some((merchRes) =>
                resource === 'doc' ? merchRes?.document_id === isogroupRes?.id : merchRes?.form_id === isogroupRes?.id
            );
        });
    }, [isogroupResources, merchantResources]);
    // get the resource for the current page
    const selectedResource = useMemo(() => {
        return isogroupResources?.[page - 1];
    }, [isogroupResources, page]);

    // logic functions
    const goToResource = (page) => {
        if (page > isogroupResources?.length) {
            setPage(isogroupResources?.length);
        } else if (page < 1) {
            setPage(1);
        } else {
            setPage(page);
        }
    };
    const onResourceExempted = () => {
        handleResourceExempted(selectedResource?.tech_sec);
    };

    // useeffect
    // whenever the selected resource chnages and the current one is undefined, then go back.
    useEffect(() => {
        if (!selectedResource) {
            if (isogroupResources?.length) {
                return goToResource(isogroupResources?.length);
            }
            goBack();
        }
    }, [selectedResource, isogroupResources]);
    // to calculate the percentage and mtrics for each category
    useEffect(() => {
        const { total_form, total_doc, user_form, user_doc } = all_techsecgroup_metrics[selectedIsogroup?.title];
        let percentage, metric;
        if (resource === 'doc') {
            metric = `${user_doc}/${total_doc} ${t('documentsUploaded')}`;
            percentage = (user_doc / total_doc) * 100;
        } else {
            metric = `${user_form}/${total_form} ${t('questionsAnswered')}`;
            percentage = (user_form / total_form) * 100;
        }
        setOverallMetric(metric);
        setIsogroupPercent(percentage);
    }, [all_techsecgroup_metrics, selectedIsogroup]);
    // to set the current question's answer to the appform initial value.
    useEffect(() => {
        if (resource === 'form') {
            // get the admin resource for that page
            const adminResource = selectedResource;
            // get the merchant resource corresponding to that page
            const merchantResource = merchantResources
                ?.sort((a, b) => b?.id - a?.id)
                ?.find((resource) => resource?.form_id === adminResource?.id);
            // set the answers
            let initialValues = {},
                requiredValues = {};
            adminResource?.form_fields?.forEach((field) => {
                initialValues[field?.id] = merchantResource?.answer?.[field?.id] || '';
                requiredValues[field?.id] = field?.required;
            });
            setFormPayload({ values: initialValues, form_id: adminResource?.id, requiredValues });
        }
    }, [page, merchantResources, selectedResource]);

    return (
        <SubgroupView
            goBack={goBack}
            title={selectedIsogroup?.title}
            groupMetric={overallMetric}
            goToNextResource={goToResource}
            currentResourcePage={page}
            data={resourcesHaveBeenAttendedTo}
            resource={resource}
            touchedFields={touchedFields}
            submitting={submitting}
        >
            {loading ? (
                <LoadingState />
            ) : (
                <>
                    <DescriptionLayer percentCompletion={isogroupPercent} description="" />
                    {resource === 'doc' ? (
                        <DocumentItem
                            document={selectedResource}
                            title_key={
                                //to do
                                // uncomment this when Anita does the translation
                                // language?.includes('fr')
                                //     ? 'tech_sec_title_fr'
                                //     : language?.includes('en')
                                //     ? 'tech_sec_title_en'
                                //     : 'tech_sec_title'
                                'tech_sec_title'
                            }
                            standard="tech_sec"
                            onResourceChange={onResourceExempted}
                        />
                    ) : (
                        <QuestionItem
                            form={selectedResource}
                            title={
                                //to do
                                // uncomment this when Anita does the translation
                                // language?.includes('fr')
                                //     ? 'tech_sec_title_fr'
                                //     : language?.includes('en')
                                //     ? 'tech_sec_title_en' || 'tech_sec_title'
                                //     : 'tech_sec_title'

                                'tech_sec_title'
                            }
                            standard="tech_sec"
                            onResourceChange={onResourceExempted}
                        />
                    )}
                </>
            )}
        </SubgroupView>
    );
};

export default ResourceView;
