import React, { useEffect, useState } from 'react';
import { Stack, Typography, IconButton, Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { MdTextSnippet } from 'react-icons/md';
import { GoDownload } from 'react-icons/go';
import AppButton from 'components/new_components/AppButton';

import { connect } from 'react-redux';
import { GetAPIScanResultByScan } from 'store/actions/apiComplianceActions';
import { toast } from 'react-toastify';
import LoadingState from 'components/new_components/LoadingState';
import { formatDateObjectHandler } from 'utils';
import EmptyState from 'components/new_components/EmptyState';
import { GetDownloadLink } from 'store/actions/generalActions';
const ScanReportModal = (props) => {
    const { open, handleClose, GetAPIScanResultByScan, payload, api_scan_result_by_scan } = props;
    const [loadingReport, setLoadingReport] = useState(false);
    const [loadingLink, setLoadingLink] = useState(false);
    const [activeId, setActiveId] = useState(null);

    const theme = useTheme();
    const getAPIScanResultByScan = async () => {
        setLoadingReport(true);
        const res = await GetAPIScanResultByScan(payload?.id);
        setLoadingReport(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getDownloadLink = async (row) => {
        if ((row.detailed_report || row.executive_report) && !loadingLink) {
            setLoadingLink(true);
            const res = await GetDownloadLink('compliance', 'api_result', row.id)();
            setLoadingLink(false);
            if (res?.success) {
                // open decoded url
                window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
            }
        }
    };

    useEffect(() => {
        if (open) {
            getAPIScanResultByScan();
        }
    }, [open]);

    return (
        <AppCenteredModal open={open} handleClose={handleClose} width="554px" sx={{ top: '-20%' }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mt: -1, borderBottom: `1px solid ${theme.palette.gray[90]}`, pb: 1 }}
            >
                <Typography sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.gray[30] }}>
                    Download API compliance scan report
                </Typography>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Stack>

            <Box sx={{ my: 3 }}>
                {loadingReport ? (
                    <LoadingState />
                ) : api_scan_result_by_scan?.length ? (
                    api_scan_result_by_scan
                        ?.sort((a, b) => b?.id - a?.id)
                        ?.map((result) => (
                            <Stack
                                direction="row"
                                alignItems="flex-start"
                                gap={1.5}
                                sx={{ borderBottom: `1px solid ${theme.palette.neutral[90]}`, pb: 1, mb: 2 }}
                                key={result?.id}
                            >
                                <MdTextSnippet className="mt-[0.5px] text-[#395BA9]" />
                                <Box>
                                    <Typography sx={{ fontSize: 12, fontWeight: 600 }}>{payload?.link}</Typography>
                                    <Stack direction="row" gap={1} sx={{ my: 1 }}>
                                        {/* <AppButton
                                            name="Download brief report"
                                            sx={{
                                                fontSize: 10,
                                                fontWeight: 400,
                                                py: 0.5,
                                                px: 1,
                                                color: theme.palette.gray[40],
                                            }}
                                            variant="outlined"
                                            icon={<GoDownload size="12px" />}
                                        /> */}
                                        <AppButton
                                            name="Download detailed report"
                                            sx={{
                                                fontSize: 10,
                                                fontWeight: 400,
                                                py: 0.5,
                                                px: 1,
                                                color: theme.palette.gray[40],
                                                cursor: loadingLink ? 'default' : 'pointer',
                                                '&:hover': {
                                                    color: theme.palette.gray[40],
                                                },
                                            }}
                                            onClick={() => {
                                                setActiveId(result?.id);
                                                getDownloadLink(result);
                                            }}
                                            variant="outlined"
                                            icon={
                                                loadingLink && result?.id === activeId ? (
                                                    <LoadingState size={12} />
                                                ) : (
                                                    <GoDownload size="12px" />
                                                )
                                            }
                                        />
                                    </Stack>
                                    <Typography sx={{ fontSize: 10, fontWeight: 400 }}>
                                        Scannsed: {formatDateObjectHandler(result?.date_created, 'MMMM Do, YYYY')}
                                    </Typography>
                                </Box>
                            </Stack>
                        ))
                ) : (
                    <EmptyState description="No Report Available" />
                )}
            </Box>
        </AppCenteredModal>
    );
};

const mapStateToProps = (state) => {
    return {
        api_scan_result_by_scan: state?.apiComplianceReducers?.api_scan_result_by_scan,
    };
};
export default connect(mapStateToProps, { GetAPIScanResultByScan })(ScanReportModal);
