import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

//redux
import { connect } from 'react-redux';
import { AddVendor, EditVendor } from 'store/actions/vendorActions';

//translations
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppDrawer from 'components/new_components/AppDrawer';
import SubTab from 'components/new_components/tabs/SubTab';
import VendorDetails from './VendorDetails';
import VendorScoreCard from './VendorScoreCard';

const VendorDetailsModal = (props) => {
    const { open, handleClose, details } = props;
    const [currentTab, setCurrentTab] = useState(0);

    const tabs = [
        {
            index: 0,
            label: 'Vendor details',
        },
        {
            index: 1,
            label: 'Scorecard',
        },
    ];

    //translation
    const { t } = useTranslation('vendorManagement');
    const onClose = () => {
        setCurrentTab(0);
        handleClose();
    };
    return (
        <AppDrawer
            open={open}
            handleClose={onClose}
            title={
                <Box>
                    <Typography sx={{ color: '#202D66', fontSize: '16px', fontWeight: 600 }}>
                        {t('vendorDetailsModal.title')}
                    </Typography>
                    <Typography sx={{ color: '#64748B', fontSize: '13px', fontWeight: 400 }}>
                        {details?.name}
                    </Typography>
                </Box>
            }
            icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
            noShadow
            noClose
            width="450px"
            height="520px"
        >
            <Box sx={{ mx: 3.5, my: 2 }}>
                <SubTab tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} sx={{ mx: '4rem' }} />

                {currentTab === 0 ? <VendorDetails details={details} /> : <VendorScoreCard details={details} />}
            </Box>
        </AppDrawer>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { AddVendor, EditVendor })(VendorDetailsModal);
