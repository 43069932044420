import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ScoreCardModal from './ScoreCardModal';

//redux
import { connect } from 'react-redux';
import { GetAllResponses, GetAllVendors } from 'store/actions/vendorActions';

//utils
import PageHeader from 'components/new_components/PageHeader';
import ReadOnlyVendor from '../VendorsView/ReadOnlyVendor';
import { useHistory, useParams } from 'react-router-dom';

const Responses = (props) => {
    const { GetAllResponses } = props;

    const [scoreModalOpen, setScoreModaOpen] = useState(false);
    const [vendorID, setVendorID] = useState(0);
    const [scaleModalOpen, setScaleModalOpen] = useState(null);
    const history = useHistory();
    const { id } = useParams();

    const goBack = () => {
        history.push('/merchant/aml/vendors');
    };

    // const openScoreModal = (id) => {
    //     setScoreModaOpen(true);
    //     setVendorID(id);
    // };
    const closeScoreModal = () => {
        setScoreModaOpen(false);
        setTimeout(() => setVendorID(0), []);
    };

    const openScaleModal = (event) => {
        setScaleModalOpen(event.currentTarget);
    };

    const closeScaleModal = () => {
        setScaleModalOpen(null);
    };

    const open = Boolean(scaleModalOpen);

    useEffect(() => {
        const getAllResponses = async () => {
            const res = await GetAllResponses();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllResponses();
    }, []);
    return (
        <>
            <div>
                <PageHeader browserTitle="Vendor Responses | Smartcomply" />
                <ReadOnlyVendor goBack={goBack} vendor={id} />
            </div>

            <ScoreCardModal
                open={scoreModalOpen}
                handleClose={closeScoreModal}
                scaleModalOpen={open}
                openScaleModal={openScaleModal}
                closeScaleModal={closeScaleModal}
                id={vendorID}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_responses: state?.vendorReducers?.all_responses,
        all_vendors: state?.vendorReducers?.all_vendors,
    };
};

export default connect(mapStateToProps, { GetAllResponses, GetAllVendors })(Responses);
