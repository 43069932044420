import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

const CircularLoader = (props) => {
    const { message } = props;
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                p: 5,
            }}
        >
            <CircularProgress style={{ color: '#aaa', transition: 'color .3s' }} />
            {message && (
                <Typography sx={{ fontSize: ' 11px', fontWeight: 500, color: '#395BA9' }}>{message}</Typography>
            )}
        </Box>
    );
};

export default CircularLoader;
