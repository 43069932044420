import React from 'react';
import { Box, Typography } from '@mui/material';

const ResponseItem = ({ id, title, activeTab, setActiveTab }) => {
    const handleClick = () => {
        setActiveTab(id);
    };
    return (
        <Box
            sx={{
                display: 'flex',
                textAlign: 'left',
                width: '100%',
                py: '10px',
                px: '15px',
                borderRadius: 1.5,
                backgroundColor: activeTab === id ? '#DBEAFE' : '#F1F5F9',
            }}
            component="button"
            onClick={handleClick}
        >
            <Typography
                sx={{
                    fontSize: { xs: '14px', md: '14px' },
                    color: activeTab === id ? '#395BA9' : '#919094',
                    fontWeight: activeTab === id ? 500 : 400,
                }}
            >
                {title}
            </Typography>
        </Box>
    );
};

export default ResponseItem;
