import React from 'react';
import AuditorPool from './AuditorPool';
import AuditorProfile from './AuditorProfile';

// redux
import { connect } from 'react-redux';
// route
import { Switch, Route } from 'react-router-dom';
//Css
import '../../assets/css/marketplace.css';

const Marketplace = () => {
    return (
        <>
            <Switch>
                <Route path="/auditor-pool" render={(props) => <AuditorPool {...props} />} exact />
                <Route path="/auditor-pool/auditor" render={(props) => <AuditorProfile {...props} />} />
            </Switch>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, {})(Marketplace);
