import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Input, Modal, Row } from 'antd';

// redux
import { connect } from 'react-redux';
import { UpdateSubsector, CreateSubsector } from 'store/actions/adminActions';

const SubcriteriaModal = (props) => {
    const { onClose, open, modalData = {}, UpdateSubsector, CreateSubsector } = props;

    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // functions
    const handleClose = () => {
        onClose();
        setData({});
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = open === 'Add' ? await CreateSubsector(data) : await UpdateSubsector(data, data?.id);
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (open) {
            setLoading(false);
            setData(modalData);
        }
    }, [open]);

    return (
        <Modal title={`${open} a Subsector`} open={Boolean(open)} onCancel={handleClose} destroyOnClose footer={null}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24}>
                        <div className="form-group">
                            <label htmlFor="name">Subsector Name</label>
                            <Input
                                size="large"
                                name="name"
                                id="name"
                                onChange={handleTextChange}
                                value={data.name || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="form-group">
                            <label htmlFor="description">Subsector Description</label>
                            <Input.TextArea
                                size="large"
                                name="description"
                                id="description"
                                onChange={handleTextChange}
                                value={data.description || ''}
                            />
                        </div>
                    </Col>
                    <Col className="mt-4">
                        <Button htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, { UpdateSubsector, CreateSubsector })(SubcriteriaModal);
