import React from 'react';
import { FormHelperText } from '@mui/material';

// formik
import { useFormikContext } from 'formik';

// core components
import AppChipGroup from '../chips/AppChipGroup';

import { formatSentence } from 'utils';

const AppFormChips = (props) => {
    const { name, ...restProps } = props;

    const { handleChange, values, touched, errors, setFieldTouched } = useFormikContext();
    return (
        <>
            <AppChipGroup
                selectedChips={values[name]}
                onChange={handleChange(name)}
                onTouch={() => setFieldTouched(name)}
                name={name}
                {...restProps}
            />
            {touched[name] && errors[name] && <FormHelperText error>{formatSentence(errors[name])}</FormHelperText>}
        </>
    );
};

export default AppFormChips;
