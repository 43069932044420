import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from 'components/new_components/AppButton';
import { useMemo } from 'react';
import CertificationModal from '../requests/components/CertificationModal';
import LoadingState from 'components/new_components/LoadingState';

// redux
import { connect } from 'react-redux';
import { GetDownloadLink } from 'store/actions/generalActions';
import { toast } from 'react-toastify';

const AuditPreviewIndex = (props) => {
    const { all_audit_requests } = props;
    const history = useHistory();
    const { id } = useParams();

    // state
    const [certModal, setCertModal] = useState(false);
    const [iFrameLoading, setIFrameLoading] = useState(true);
    const [loadingLink, setLoadingLink] = useState({ loading: false, link: '' });

    // memos
    const auditRequest = useMemo(() => {
        return all_audit_requests?.find((request) => request?.id === parseInt(id));
    }, [id, all_audit_requests]);

    // functions
    const goBack = () => {
        history.push('/admin/audit-requests');
    };

    const openCertModal = () => {
        setCertModal(true);
    };
    const closeCertModal = () => {
        setCertModal(false);
    };

    const getDownloadLink = async () => {
        setLoadingLink({ ...loadingLink, loading: true });
        const res = await GetDownloadLink('compliance', 'spool_report', auditRequest.id)();
        setLoadingLink({ ...loadingLink, loading: false });

        if (res?.success) {
            setLoadingLink({ loading: false, link: res.data });
        } else {
            toast.error('Something went wrong!');
        }
    };

    useEffect(() => {
        auditRequest?.spoolreport && getDownloadLink();
    }, [auditRequest?.spoolreport]);

    return (
        <div className="-m-6 h-[calc(100%_+_44px)]">
            <div className="flex justify-content-between bg-[#FFFFFF] py-2 px-4 border-b border-[#b8bccc]">
                <div className="flex items-center">
                    <div
                        className="w-8 h-8 flex items-center justify-center bg-[#DAE2FF] cursor-pointer overflow-hidden rounded-full font-bold"
                        onClick={goBack}
                    >
                        <FaAngleLeft className="font-bold" color="#455A64B8" size="25px" />
                    </div>
                    <span className="pl-3 font-bold cursor-pointer">
                        {auditRequest?.compliance?.toUpperCase()} Report
                    </span>
                </div>
                {auditRequest?.status !== 'completed' && (
                    <Stack direction="row" spacing={2}>
                        <AppButton
                            onClick={openCertModal}
                            variant="contained"
                            name={'Add certificate to approve'}
                            sx={{ py: 0.5, px: 1.5 }}
                        />
                        {/* <AppButton variant="outlined" name="Disapprove" color="error" sx={{ py: 0.5, px: 1.5 }} /> */}
                    </Stack>
                )}
            </div>
            <Box
                sx={{
                    px: 2.5,
                    height: 'calc(100% - 67px)',
                }}
            >
                {iFrameLoading ||
                    (loadingLink.loading && (
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                my: 3,
                            }}
                        >
                            <LoadingState />
                        </Box>
                    ))}
                {auditRequest && (
                    <Box
                        sx={{
                            backgroundColor: '#ffffff',
                            width: '90%',
                            maxWidth: 800,
                            height: '100%',
                            m: 'auto',
                            display: iFrameLoading || loadingLink.loading ? 'none' : 'block',
                        }}
                        component="iframe"
                        src={`https://docs.google.com/gview?url=${
                            loadingLink.link || auditRequest?.spoolreport
                        }&embedded=true`}
                        // src="https://docs.google.com/gview?url=http://remote.url.tld/path/to/document.doc&embedded=true"
                        onLoad={() => setIFrameLoading(false)}
                    />
                )}
            </Box>
            <CertificationModal open={certModal} modalData={auditRequest} handleClose={closeCertModal} />
        </div>
    );
};
const mapStateToProps = (state) => {
    return { all_audit_requests: state?.auditReducers?.all_audit_requests };
};
export default connect(mapStateToProps, {})(AuditPreviewIndex);
