import React, { useEffect, useState } from 'react';

//redux
import { connect } from 'react-redux';

//core components
import AppViewModal from 'components/new_components/AppViewModal';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { truncateText } from 'utils';

const EvidencesDetailModal = (props) => {
    //props
    const {
        open,
        handleClose,
        resource,
        exempted_documents,
        exempted_forms,
        all_exempted_evidences,
        name,
        handleOpenRevert,
    } = props;
    const [active_tab, setActive_tab] = useState(0);

    const theme = useTheme();
    const group1 = exempted_documents?.filter(
        (item) =>
            item?.document_detail?.quality_mgt === name?.id && item?.document_detail?.quality_mgt_sub_no === active_tab
    );
    const group2 = exempted_forms?.filter(
        (item) => item?.form_detail?.quality_mgt === name?.id && item?.form_detail?.quality_mgt_sub_no === active_tab
    );
    const group3 = all_exempted_evidences?.filter(
        (item) =>
            item?.evidence_detail?.quality_mgt === name?.id && item?.evidence_detail?.quality_mgt_sub_no === active_tab
    );
    const group = resource === 'doc' ? group1 : resource === 'form' ? group2 : group3;

    useEffect(() => {
        if (name) {
            setActive_tab(name?.subgroups?.[0]?.id);
        }
    }, [name]);

    return (
        <AppViewModal open={open} handleClose={handleClose} width={550}>
            <Box sx={{ my: 1.5 }}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start', m: 2 }}>
                    <ArrowBackIosNewIcon
                        onClick={handleClose}
                        sx={{ width: 20, height: 20, color: '#000000', cursor: 'pointer', mt: 0.3 }}
                    />
                    <Box>
                        <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#202D66' }}>
                            {name?.title} Exceptions
                        </Typography>
                    </Box>
                </Box>
                <Divider sx={{ color: theme.palette.neutral[95] }} />
            </Box>
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mx: 2, mb: 2 }}>
                    {name?.subgroups?.map((item) => {
                        return (
                            <Typography
                                key={item?.id}
                                variant={item?.id === active_tab ? 'contained' : 'outlined'}
                                sx={{
                                    borderRadius: 5,
                                    backgroundColor: item?.id === active_tab ? '#395BA9' : '',
                                    color: item?.id === active_tab ? '#ffffff' : '#475569',
                                    border: item?.id === active_tab ? '' : '1px solid #E2E8F0',
                                    textTransform: 'inherit',
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    px: 1.5,
                                    py: 0.5,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: item?.id === active_tab ? '#395BA9' : '',
                                        color: item?.id === active_tab ? 'white' : '#475569',
                                    },
                                }}
                                onClick={() => setActive_tab(item?.id)}
                            >
                                CO{item?.id}
                            </Typography>
                        );
                        // );
                    })}
                </Box>
                <Divider sx={{ color: theme.palette.neutral[95] }} />
                {group?.length ? (
                    <Box sx={{ mx: 2 }}>
                        {group?.map((item) => (
                            <Box key={item?.id} sx={{ py: 1 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#475569' }}>
                                        {truncateText(
                                            item?.document_detail?.quality_mgt_title ||
                                                item?.form_detail?.quality_mgt_title ||
                                                item?.evidence_detail?.quality_mgt_title,
                                            '100'
                                        )}
                                    </Typography>
                                    <Button
                                        variant="text"
                                        sx={{
                                            backgroundColor: '#F8FAFC',
                                            color: '#64748B',
                                            textTransform: 'inherit',
                                            p: 0.3,
                                            fontSize: '13px',
                                            fontWeight: 500,
                                            border: '1px solid #E2E8F0',
                                            '&:hover': {
                                                backgroundColor: '#F8FAFC',
                                                border: '1px solid #E2E8F0',
                                            },
                                        }}
                                        // onClick={() => removeException(item?.id)}
                                        onClick={() => handleOpenRevert(item?.id)}
                                    >
                                        Revert
                                    </Button>
                                </Box>
                                <Divider sx={{ color: theme.palette.neutral[95], mt: 1, mx: -2 }} />
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', items: 'center', mt: '15rem' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#475569' }}>
                            No Exception Found
                        </Typography>
                    </Box>
                )}
            </Box>
        </AppViewModal>
    );
};

const mapStateToProps = (state) => {
    return {
        exempted_documents: state?.complianceReducers?.all_exempted_documents,
        exempted_forms: state?.complianceReducers?.all_exempted_forms,
        all_exempted_evidences: state?.complianceReducers?.all_exempted_evidences,
    };
};
export default connect(mapStateToProps, {})(EvidencesDetailModal);
