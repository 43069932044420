import React from 'react';
import { Typography, Box, Stack } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { useTheme } from '@mui/styles';
import AppButton from 'components/new_components/AppButton';

const IntroductionModal = (props) => {
    const { open, handleClose, updateModuleIntroModal } = props;
    const theme = useTheme();

    const closeAll = () => {
        updateModuleIntroModal();
        handleClose();
    };
    return (
        <AppCenteredModal
            open={open}
            handleClose={closeAll}
            width="540px"
            actions={
                <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
                    <AppButton
                        name="Close"
                        variant="contained"
                        sx={{ minWidth: 'unset', width: 'fit-content' }}
                        onClick={closeAll}
                    />
                </Stack>
            }
        >
            <img src="/img/api_compliance.svg" alt="API compliance" />
            <Box sx={{ my: 3, color: theme.palette.primary[40], fontWeight: 600 }}>
                Introducing{' '}
                <Typography
                    sx={{
                        borderBottom: `3px solid ${theme.palette.warning[700]}`,
                        fontWeight: 600,
                        color: theme.palette.primary[40],
                    }}
                    component="span"
                >
                    API COMPLIANCE
                </Typography>
            </Box>
            <Typography sx={{ fontSize: 13, fontWeight: 400, letterSpacing: '0.1px', color: theme.palette.gray[30] }}>
                API compliance scans play a vital role in assessing the implementation of security measures designed to
                safeguard the integrity of Application Programming Interfaces (APIs). APIs serve as essential conduits
                for communication and data exchange among diverse software applications and systems. The imperative of
                ensuring API security lies in preventing unauthorized access, mitigating data breaches, and averting
                other potential cyber threats.
            </Typography>
        </AppCenteredModal>
    );
};

export default IntroductionModal;
