import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Modal, Row, Col, Button, Upload, Input } from 'antd';
import { AiOutlineInbox } from 'react-icons/ai';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { connect } from 'react-redux';
import { EditIntegration } from 'store/actions/adminActions';

const IntegrationModal = (props) => {
    const { open, handleClose, EditIntegration, id, group_id, group_name, payload } = props;

    const [values, setValues] = useState({});
    const [tips, setTips] = useState('<p>Type here!</p>');

    const { Dragger } = Upload;

    const draggers = {
        name: 'default',
        action: null,
        multiple: false,
        maxCount: 1,
        onChange(info) {
            setValues({ ...values, logo: info.fileList[0]?.originFileObj });
        },
    };

    const closeAll = () => {
        setValues({});
        setTips('<p> </p>');
        handleClose();
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log({ ...values, tips: tips });
        const formdata = new FormData();
        formdata.append('name', values.name);
        formdata.append('tips', tips);
        formdata.append('logo', values.logo);
        formdata.append('group', group_id);
        const res = await EditIntegration(id, formdata, group_id, group_name);
        if (res?.success) {
            toast.success('Integration Updated Successfully');
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (payload) {
            setValues({ name: payload?.name });
            setTips(payload?.tips);
        }
    }, [payload]);
    return (
        <>
            <Modal title="Add Integration" open={open} onCancel={closeAll} footer={null} destroyOnClose={true}>
                <form encType="multipart/form-data" onSubmit={handleSubmit}>
                    <Row justify="end">
                        <Col span={24}>
                            <div className="form-group" style={{ marginBottom: '1rem' }}>
                                <label htmlFor="name">Name</label>
                                <Input
                                    type="text"
                                    size="large"
                                    name="name"
                                    id="name"
                                    onChange={handleTextChange}
                                    value={values.name || ''}
                                    required
                                />
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="form-group" style={{ marginBottom: '1rem' }}>
                                <label htmlFor="tips">Tips</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={tips}
                                    onReady={(editor) => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        // console.log( { event, editor, data } );

                                        setTips(data);
                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        console.log('Focus.', editor);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="template_file">Logo</label>
                            <Dragger {...draggers}>
                                <p className="ant-upload-drag-icon" style={{ textAlign: 'center' }}>
                                    <AiOutlineInbox />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>
                        </Col>
                        <Col>
                            <Button
                                className="ant-btn"
                                htmlType="submit"
                                type="primary"
                                // loading={loading}
                                style={{ marginTop: '3rem' }}
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </form>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { EditIntegration })(IntegrationModal);
