import {
    ALL_LIFECYCLE,
    ALL_CONTROL_ObJECTIVE_METRICS,
    ALL_ADMIN_DOCUMENTS_BY_CONTROL_OBJECTIVE,
    ALL_ADMIN_EVIDENCE_BY_CONTROL_OBJECTIVE,
    ALL_EXEMPTED_EVIDENCE,
} from '../constants';
import axiosInstance from '../../utils/https';

//SOC2
export const GetAllLifecycles = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/lifecycle/?parent_lifecycle=false`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_LIFECYCLE, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const CreateLifeCycle = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/lifecycle/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllLifecycles());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditLifeCycle = (credentials, control_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/lifecycle/${control_id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllLifecycles());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteLifeCycle = (control_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/lifecycle/${control_id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllLifecycles());
        return {
            success: true,
            message: 'Deleted Successful.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetControlObjectiveMetrics = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/sslc_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CONTROL_ObJECTIVE_METRICS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetDocumentsByControlObjective = (subcontrol) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?lifecycle_id=${subcontrol}&tags=sslc`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_DOCUMENTS_BY_CONTROL_OBJECTIVE,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetEvidencesByControlObjective = (control) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/evidence/?lifecycle=${control}&tags=sslc`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_ADMIN_EVIDENCE_BY_CONTROL_OBJECTIVE,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//Exempted evidence
export const GetAllExemptedEvidence = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/module_exempted/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_EXEMPTED_EVIDENCE, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const ExemptEvidence = (credentials, merchant) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/compliance/evidence_exempted/`, {
            ...credentials,
            merchant: company_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllExemptedEvidence(merchant));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
