import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ComplianceDashboard from './ComplianceDashboard';
import RequirementPage from './RequirementPage';
import SubReqPage from './SubReqPage';
import FormCreation from '../Operations/FormCreation';

const Compliance = () => {
    return (
        <Switch>
            <Route path="/admin/pcidss" exact component={ComplianceDashboard} />
            <Route path="/admin/pcidss/requirement/" exact component={RequirementPage} />
            <Route path="/admin/pcidss/requirement/subrequirement/" exact component={SubReqPage} />
            <Route
                path="/admin/pcidss/requirement/subrequirement/form"
                component={(props) => <FormCreation {...props} tag="pcidss" />}
            />
        </Switch>
    );
};

export default Compliance;
