import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DATA_COLLECTION_LAW } from '../utils/constants';
import { useTheme } from '@mui/styles';

const DataCollectionTable = () => {
    const theme = useTheme();
    return (
        <Box sx={{ border: `1px solid ${theme.palette.gray[100]}`, borderRadius: '4px', py: 3, px: 2, mb: 3 }}>
            <Stack direction="row" gap={3} sx={{ backgroundColor: '#EEF0FF4D', px: 2, py: 1 }}>
                <Typography
                    sx={{
                        flexBasis: '50%',
                        textTransform: 'capitalize',
                        fontSize: 13,
                        fontWeight: 500,
                        color: theme.palette.gray[20],
                    }}
                >
                    Purpose of Processing
                </Typography>
                <Typography
                    sx={{
                        flexBasis: '50%',
                        textTransform: 'capitalize',
                        fontSize: 13,
                        fontWeight: 500,
                        color: theme.palette.gray[20],
                    }}
                >
                    Lawful Bases
                </Typography>
            </Stack>
            {DATA_COLLECTION_LAW?.map((item, index) => (
                <Stack
                    direction="row"
                    gap={3}
                    key={index}
                    sx={{
                        px: 2,
                        py: 1,
                        borderBottom:
                            DATA_COLLECTION_LAW?.length - 1 === index ? 'none' : `1px solid ${theme.palette.gray[100]}`,
                    }}
                >
                    <Typography
                        sx={{
                            flexBasis: '50%',
                            fontSize: 12,
                            fontWeight: 400,
                            letterSpacing: '0.4px',
                            color: '#0F172A',
                        }}
                    >
                        <ul className="ml-3 list-disc">
                            {item.purpose?.map((purpose, index) => (
                                <li key={index}>{purpose}</li>
                            ))}
                        </ul>
                    </Typography>
                    <Typography
                        sx={{
                            flexBasis: '50%',
                            fontSize: 12,
                            fontWeight: 400,
                            letterSpacing: '0.4px',
                            color: '#0F172A',
                        }}
                    >
                        {item?.lawful_bases}
                    </Typography>
                </Stack>
            ))}
        </Box>
    );
};

export default DataCollectionTable;
