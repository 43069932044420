import { Grid } from '@mui/material';
import EmptyState from 'components/new_components/EmptyState';
import LoadingState from 'components/new_components/LoadingState';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import CategoryCard from '../../common/CategoryCard';

//translations
import { useTranslation } from 'react-i18next';

const Isogroups = (props) => {
    const {
        resource,
        loading,
        onViewChange,
        all_isogroup_metrics,
        all_isogroups,
        all_compliance_assigned_tasks,
        openTaskAssignModal,
        all_iso27001_documents,
        all_iso27001_forms,
    } = props;

    //translation
    const { t } = useTranslation('compliance');

    const documentsByCategory = useMemo(() => {
        return all_iso27001_documents
            ?.filter((document) => {
                const iso27001Documents = all_compliance_assigned_tasks?.documents?.forms?.isms || [];

                const res = iso27001Documents?.length ? iso27001Documents.filter((item) => document?.id === item) : [];

                return res?.length ? document : null;
            })
            .map((document) => document?.annex);
    }, [all_iso27001_documents, all_compliance_assigned_tasks?.documents?.forms?.isms]);

    const formsByCategory = useMemo(() => {
        return all_iso27001_forms
            ?.filter((form) => {
                const iso27001Forms = all_compliance_assigned_tasks?.questions?.forms?.isms || [];

                const res = iso27001Forms?.length ? iso27001Forms.filter((item) => form?.id === item) : [];

                return res?.length ? form : null;
            })
            .map((form) => form?.annex);
    }, [all_iso27001_forms, all_compliance_assigned_tasks?.documents?.forms?.isms]);
    return !loading ? (
        <Grid container spacing={2.5} sx={{ p: 4, pt: 6 }}>
            {all_isogroups && all_isogroups?.length ? (
                all_isogroups
                    ?.sort((a, b) => a?.id - b?.id)
                    ?.map((isogroup) => {
                        {
                            /* get and descructure the categories metrics */
                        }
                        // const { total_form, total_doc, user_form, user_doc } =
                        //   all_isogroup_metrics?.[isogroup?.title];

                        {
                            /* format metric and label based on resource type */
                        }
                        const metric =
                            resource === 'doc'
                                ? {
                                      totalValue: all_isogroup_metrics?.[isogroup?.title]?.total_doc,
                                      totalAttendedTo: all_isogroup_metrics?.[isogroup?.title]?.user_doc,
                                  }
                                : {
                                      totalValue: all_isogroup_metrics?.[isogroup?.title]?.total_form,
                                      totalAttendedTo: all_isogroup_metrics?.[isogroup?.title]?.user_form,
                                  };
                        const resourceLabel = resource === 'doc' ? t('fileUploaded') : t('questionAnswered');
                        const isCategoryAssigned =
                            resource === 'doc'
                                ? all_compliance_assigned_tasks?.documents?.category?.isms?.includes(isogroup?.id)
                                : all_compliance_assigned_tasks?.questions?.category?.isms?.includes(isogroup?.id);
                        return (
                            <CategoryCard
                                key={isogroup?.id}
                                id={isogroup?.id}
                                title={isogroup?.title}
                                metric={metric}
                                onViewChange={onViewChange}
                                resourceLabel={resourceLabel}
                                openTaskAssignModal={() =>
                                    openTaskAssignModal('category', {
                                        annex: isogroup?.id,
                                        is_document: resourceLabel !== 'Questions Answered',
                                        standard: 'isms',
                                    })
                                }
                                isCategoryAssigned={isCategoryAssigned}
                                resourceAssigned={resource === 'doc' ? documentsByCategory : formsByCategory}
                            />
                        );
                    })
            ) : (
                <EmptyState description={t('noISO')} />
            )}
        </Grid>
    ) : (
        <LoadingState />
    );
};

const mapStateToProps = (state) => {
    return {
        all_isogroups: state?.adminReducers?.all_isogroupstwo,
        all_isogroup_metrics: state?.complianceReducers?.all_isogrouptwo_metrics,
        all_iso27001_forms: state?.adminReducers?.all_forms_by_tag,
        all_iso27001_documents: state?.adminReducers?.all_documents_by_tags,
    };
};
export default connect(mapStateToProps, {})(Isogroups);
