import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Card, Space, Button, Row, Col, Modal } from 'antd';
import { EditOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import FormFieldItem from './FormFieldItem';

const { confirm } = Modal;
const FormItem = (props) => {
    const { form, DeleteForm } = props;
    const history = useHistory();

    // functions
    const deleteForm = (form) => {
        confirm({
            title: 'Are you sure you want to delete this form? Note: Deleting this form means you will be deleting all questions and merchant responses associated with this form',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteForm(form?.id);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    return (
        <Card
            extra={
                <Space>
                    <Button
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() =>
                            history.push({
                                pathname: `/admin/questionaire/form/${form?.id}`,
                                state: {
                                    mode: 'edit',
                                    form_id: form?.id,
                                },
                            })
                        }
                    />
                    <Button shape="circle" danger icon={<DeleteOutlined />} onClick={() => deleteForm(form)} />
                </Space>
            }
            title={form.title}
            style={{ marginBottom: '2rem' }}
        >
            <Row gutter={24}>
                {form?.form_fields?.length
                    ? form?.form_fields
                          ?.sort((a, b) => a?.id - b?.id)
                          ?.map((field) => {
                              return (
                                  <Col xs={24} md={12} key={field.name}>
                                      <FormFieldItem field={field} />
                                  </Col>
                              );
                          })
                    : 'No Form Fields Available'}
            </Row>
        </Card>
    );
};

export default FormItem;
