import React, { useContext, useEffect, useMemo, useState } from 'react';
import RiskTitle from './RiskTitle';
import PageHeader from 'components/new_components/PageHeader';
import AppTable from 'components/new_components/app-table';
import { toast } from 'react-toastify';
import Actions from './TableAction';

import useSearch from 'hooks/useSearch';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import { GetFilteredReports } from 'store/actions/merchantActions';
import moment from 'moment';

//translations
import { useTranslation } from 'react-i18next';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

const NO_PER_PAGE = 10;

const Index = (props) => {
    const { GetFilteredReports, spool_reports } = props;
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('');

    //translation
    const { t } = useTranslation('riskAssessment');

    //context api for auditor's selected merchant
    const { activeMerchant } = useContext(SelectedMerchantContext);

    const { data, handleSearch } = useSearch(spool_reports, ['report_name']);
    const columns = [
        {
            title: t('reportTable.column1'),
            key: 'report_name',
            render: (row) => <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>{row?.report_name}</Typography>,
        },
        {
            title: t('reportTable.column2'),
            key: 'type',
            render: (row) => (
                <Typography sx={{ color: '#5E5E62', fontSize: '14px', textTransform: 'uppercase' }}>
                    {row?.type?.replace(/_/g, ' ')}
                </Typography>
            ),
        },
        {
            title: t('reportTable.column3'),
            key: 'date_created',
            render: (row) => (
                <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>
                    {moment(row?.date_created).format('Do MMMM, YYYY, h:mm a')}
                </Typography>
            ),
        },
        {
            title: t('reportTable.column4'),
            align: 'center',
            key: 'action',
            render: (row) => <Actions row={row} />,
        },
    ];

    const query_params = useMemo(() => {
        const params = {
            ...(type.length && { type: type === 'All' ? '' : type }),
        };
        return params;
    }, [type]);

    useEffect(() => {
        const getFilteredReports = async () => {
            setLoading(true);
            const res = await GetFilteredReports(page, query_params, activeMerchant);
            setLoading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getFilteredReports();
    }, [query_params, page, activeMerchant]);

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        data ? data?.slice(start, stop) : [];

        return data;
    }, [page, data]);

    const handlePageChange = (page) => {
        setPage(page);
    };
    return (
        <div className="mt-3">
            <PageHeader browserTitle="Risk Assessment | Smartcomply" />

            <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#46464A' }}>
                {t('reportTable.reportHistory')}
            </Typography>
            <AppTable
                columns={columns}
                data={data}
                sorter={(a, b) => b?.id - a?.id}
                loading={loading}
                title={<RiskTitle onSearch={handleSearch} type={type} setType={setType} />}
                dataLength={spool_reports?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        spool_reports: state?.merchantReducers?.all_spool_reports,
    };
};

export default connect(mapStateToProps, { GetFilteredReports })(Index);
