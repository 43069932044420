import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Box, Grid, Typography } from '@mui/material';
// import { Info } from '@mui/icons-material';
import { AppFormSelect, AppFormInput } from 'components/new_components/forms';
import { toast } from 'react-toastify';
import {
    AssetTypesOption,
    LikelihoodOptionBy3,
    ImpactOptionBy3,
    LikelihoodOptionBy5,
    ImpactOptionBy5,
    StatusOption,
    ThreatIdentification,
} from '../../../utils/constants';
import AppFormDateInput from 'components/new_components/forms/AppFormDateInput';
import { GetAllThreats } from 'store/actions/adminActions';
import './style.css';

//translations
import { useTranslation } from 'react-i18next';

const ThreatIdentificationForm = (props) => {
    const { values, all_merchant_infrastructures, all_computers, owner, setOwner, matrix, GetAllThreats, all_threats } =
        props;

    //translation
    const { t } = useTranslation('riskAssessment');

    useEffect(() => {
        if (values?.asset !== null) {
            if (values?.asset?.toString()?.length === 4) {
                const assetArray = all_merchant_infrastructures?.find(
                    (infra) => infra?.asset_number === parseInt(values?.asset)
                );
                setOwner(assetArray?.location);
            } else {
                const assetValue = all_computers?.find((computer) => computer?.serialnumber === values?.asset);
                setOwner(assetValue?.name);
            }
        }
    }, [values?.asset]);

    useEffect(() => {
        if (values?.asset_type !== ('employee_computer' || 'infrastructure')) {
            setOwner('');
        }
    }, [values?.asset_type]);

    const getAllThreats = async () => {
        const res = await GetAllThreats();
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    //options
    const computerOptions = useMemo(
        () =>
            all_computers
                ?.sort((a, b) => a?.id - b?.id)
                ?.map((computer) => {
                    return {
                        name: `${computer?.serialnumber} ${computer?.name}`,
                        value: computer?.serialnumber,
                    };
                }),
        [all_computers]
    );

    const infrastructureOptions = useMemo(
        () =>
            all_merchant_infrastructures
                ?.sort((a, b) => a?.id - b?.id)
                ?.map((infra) => {
                    return {
                        name: `${infra?.asset_number} - ${infra?.location}`,
                        value: infra?.asset_number,
                    };
                }),
        [all_merchant_infrastructures]
    );

    useEffect(() => {
        getAllThreats();
    }, []);

    const threatOptions = useMemo(
        () =>
            all_threats
                ?.sort((a, b) => a?.id - b?.id)
                ?.map((threat) => {
                    return {
                        name: `${threat?.name} - ${threat?.sub_category}`,
                        value: threat?.id,
                    };
                }),
        [all_threats]
    );
    const LikelihoodOption = matrix === '3x3' ? LikelihoodOptionBy3 : LikelihoodOptionBy5;
    const ImpactOption = matrix === '3x3' ? ImpactOptionBy3 : ImpactOptionBy5;

    const filteredAssetTypesOption_ = AssetTypesOption?.filter((option) => option?.value !== 'All');
    return (
        <Box sx={{ overflow: 'scroll !Important', height: '500px' }}>
            <AppFormSelect
                name="threat"
                label={t('intelligenceAssessment.threat')}
                options={threatOptions}
                defaultValue={t('intelligenceAssessment.threatValue')}
            />
            <AppFormInput
                type="text"
                label={<Typography>{t('intelligenceAssessment.description')}</Typography>}
                name="threat_description"
                placeholder={t('intelligenceAssessment.describe')}
            />
            <AppFormSelect
                name="risk_source"
                label={t('intelligenceAssessment.source')}
                options={ThreatIdentification}
                defaultValue={t('intelligenceAssessment.sourceValue')}
            />
            <Grid container spacing={2}>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <AppFormSelect
                        name="asset_type"
                        label={t('intelligenceAssessment.assetType')}
                        options={filteredAssetTypesOption_}
                        defaultValue={t('intelligenceAssessment.assetTypeValue')}
                    />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    {values?.asset_type === 'employee_computer' ? (
                        <AppFormSelect
                            name="asset"
                            label={t('intelligenceAssessment.asset')}
                            options={computerOptions}
                            defaultValue={t('intelligenceAssessment.assetValue')}
                        />
                    ) : values?.asset_type === 'infrastructure' ? (
                        <AppFormSelect
                            name="asset"
                            label={t('intelligenceAssessment.asset')}
                            options={infrastructureOptions}
                            defaultValue={t('intelligenceAssessment.assetValue')}
                        />
                    ) : (
                        <AppFormInput
                            type="text"
                            label={t('intelligenceAssessment.asset')}
                            name="asset"
                            placeholder={t('intelligenceAssessment.enterAsset')}
                        />
                    )}
                </Grid>
            </Grid>
            {values?.asset_type === 'employee_computer' || values?.asset_type === 'infrastructure' ? (
                <AppFormInput
                    type="text"
                    label={t('intelligenceAssessment.riskOwner')}
                    placeholder={t('intelligenceAssessment.riskOwner')}
                    disabled
                    value={owner}
                />
            ) : (
                <AppFormInput
                    type="text"
                    label={t('intelligenceAssessment.riskOwner')}
                    name="risk_owner"
                    placeholder={t('intelligenceAssessment.riskOwner')}
                />
            )}

            <Grid container spacing={2}>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <AppFormSelect
                        name="inherent_likelihood"
                        label={t('intelligenceAssessment.inherentLikelihood')}
                        options={LikelihoodOption}
                        defaultValue={t('intelligenceAssessment.inherentLikelihoodValue')}
                    />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <AppFormSelect
                        name="inherent_impact"
                        label={t('intelligenceAssessment.inherentImpact')}
                        options={ImpactOption}
                        defaultValue={t('intelligenceAssessment.inherentImpactValue')}
                    />
                </Grid>
            </Grid>
            <AppFormDateInput
                name="date_detected"
                label={t('intelligenceAssessment.dateDetected')}
                max={new Date().toISOString().split('T')[0]}
            />
            <AppFormSelect
                name="risk_status"
                label={t('intelligenceAssessment.status')}
                options={StatusOption}
                defaultValue={t('intelligenceAssessment.status')}
                required
            />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_infrastructures: state?.merchantReducers?.all_merchant_infrastructures,
        all_computers: state?.merchantReducers?.all_computers,
        all_threats: state?.adminReducers?.all_threats,
    };
};
export default connect(mapStateToProps, { GetAllThreats })(ThreatIdentificationForm);
