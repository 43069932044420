import React, { useEffect, useState } from 'react';
import { Stack, Typography, IconButton, Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { MdTextSnippet } from 'react-icons/md';
import { GoDownload } from 'react-icons/go';
import AppButton from 'components/new_components/AppButton';

import { connect } from 'react-redux';
import { GetCodeReviewReports } from 'store/actions/apiComplianceActions';
import { toast } from 'react-toastify';
import LoadingState from 'components/new_components/LoadingState';
import { formatDateObjectHandler } from 'utils';
import EmptyState from 'components/new_components/EmptyState';
import { GetDownloadLink } from 'store/actions/generalActions';

const ScanReportModal = (props) => {
    const { open, handleClose, GetCodeReviewReports, payload, code_review_report } = props;

    // state
    const [loadingReport, setLoadingReport] = useState(false);

    const theme = useTheme();
    const getAPIScanResultByScan = async () => {
        setLoadingReport(true);
        const res = await GetCodeReviewReports(payload?.id);
        setLoadingReport(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (open) {
            getAPIScanResultByScan();
        }
    }, [open]);

    return (
        <AppCenteredModal open={open} handleClose={handleClose} width="554px" sx={{ top: '-20%' }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mt: -1, borderBottom: `1px solid ${theme.palette.gray[90]}`, pb: 1 }}
            >
                <Typography sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.gray[30] }}>
                    Download Code review report
                </Typography>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Stack>

            <Box sx={{ my: 3 }}>
                {loadingReport ? (
                    <LoadingState />
                ) : code_review_report?.length ? (
                    code_review_report
                        ?.sort((a, b) => b?.id - a?.id)
                        ?.map((result) => <DataItem result={result} key={result.id} theme={theme} payload={payload} />)
                ) : (
                    <EmptyState description="No Report Available" />
                )}
            </Box>
        </AppCenteredModal>
    );
};

const DataItem = ({ result, theme, payload }) => {
    const [loadingLink, setLoadingLink] = useState(false);
    const getDownloadLink = async () => {
        if (result.report && !loadingLink) {
            setLoadingLink(true);
            const res = await GetDownloadLink('asvc_requests', 'sourcecode_report', result.id)();
            setLoadingLink(false);
            if (res?.success) {
                // open decoded url
                window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
            }
        }
    };

    return (
        <Box
            sx={{
                borderBottom: `1px solid ${theme.palette.neutral[90]}`,
                pb: 1,
                mb: 2,
                display: 'flex',
                alignItems: 'flex-start',
                width: '100% !important',
            }}
        >
            <MdTextSnippet className="mt-[0.5px] mr-[1rem] text-[#395BA9]" />
            <Box sx={{ width: '100% !important' }}>
                <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 1 }}>{payload?.url}</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <AppButton
                        name="Download detailed report"
                        sx={{
                            fontSize: 10,
                            fontWeight: 400,
                            py: 0.5,
                            px: 1,
                            color: theme.palette.gray[40],
                            cursor: loadingLink ? 'default' : 'pointer',
                            '&:hover': {
                                color: theme.palette.gray[40],
                            },
                        }}
                        onClick={getDownloadLink}
                        variant="outlined"
                        icon={loadingLink ? <LoadingState size={12} /> : <GoDownload size="12px" />}
                    />
                    <Typography sx={{ fontSize: 10, fontWeight: 400 }}>
                        Scanned: {formatDateObjectHandler(result?.date_created, 'MMMM Do, YYYY')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        code_review_report: state?.apiComplianceReducers?.code_review_report,
    };
};
export default connect(mapStateToProps, { GetCodeReviewReports })(ScanReportModal);
