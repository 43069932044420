import { CloseOutlined, DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Button, Card, Col, Divider, Input, Row } from 'antd';
import React, { useState } from 'react';
import FieldItem from './FieldItem';

const SectionCard = (props) => {
    const {
        handleTitleChange,
        handleTitleSave,
        section,
        enableDelete,
        deleteASection,
        index,
        questions,
        hasMore,
        openFieldModal,
        deleteField,
    } = props;

    // state
    const [entryCount, setEntryCount] = useState(1);

    // functions
    const incrementEntryCount = () => {
        setEntryCount((current) => current + 1);
    };
    const decrementEntryCount = () => {
        setEntryCount((current) => current - 1);
    };

    return (
        <Col xs={24} className="mb-3">
            <Card
                title={
                    <form onSubmit={handleTitleSave} className="flex items-start space-x-3 w-3/4">
                        <Input.TextArea
                            placeholder="Section Title"
                            size="large"
                            value={section}
                            onChange={(e) => handleTitleChange(e, index)}
                            className="flex-1"
                            required
                        />
                        <Button type="primary" size="large" htmlType="submit">
                            Save Title
                        </Button>
                    </form>
                }
                extra={
                    enableDelete && (
                        <Button
                            shape="circle"
                            danger
                            icon={<DeleteOutlined />}
                            size="large"
                            onClick={() => deleteASection(index)}
                        />
                    )
                }
            >
                {new Array(entryCount).fill(null).map((_, idx) => {
                    return (
                        <React.Fragment key={idx}>
                            <Row gutter={16}>
                                {questions.map((question, index) => {
                                    return (
                                        <FieldItem
                                            question={question}
                                            key={index}
                                            openFieldModal={() => openFieldModal('Edit', index)}
                                            deleteField={() => deleteField(index)}
                                        />
                                    );
                                })}
                            </Row>
                            {hasMore && (
                                <Row justify="space-between">
                                    <Col>
                                        {idx + 1 === entryCount && (
                                            <Button
                                                type="link"
                                                icon={<PlusCircleFilled />}
                                                className="flex justify-center items-center"
                                                onClick={incrementEntryCount}
                                            >
                                                New Entry
                                            </Button>
                                        )}
                                    </Col>
                                    {entryCount > 1 && (
                                        <Col>
                                            <Button
                                                danger
                                                type="link"
                                                icon={<CloseOutlined />}
                                                className="flex justify-center items-center"
                                                onClick={decrementEntryCount}
                                            >
                                                Remove Entry
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            )}
                            {idx + 1 < entryCount && <Divider />}
                        </React.Fragment>
                    );
                })}
            </Card>
        </Col>
    );
};

export default SectionCard;
