export const VALUE_TYPES = [
    { label: 'Absolute Value', key: 'abs_value' },
    { label: 'Upto Value', key: 'upto' },
    { label: 'Check', key: 'check' },
];
export const FEATURE_KEYS = [
    'ciso_report',
    'vendor_mgt',
    'support',
    'trust_report',
    'risk_assessment',
    'audits_collaboration',
    'pentest_scan',
    'pentest_scan_freq',
    'pci_scan',
    'pci_scan_freq',
    'background_check',
    'vulnerability_scan',
    'server_monitoring',
    'auditor_support',
    'ai_roc',
    'compliance_ai',
    'users',
    'in_app_kyc_check',
    'smart_dashboard',
    'ai_chat',
    'vendor_ai',
    'vulnerability_mgt',
    'automated_scan_ai',
    'trust_index',
    'task_assignment',
    'source_code_scan',
    'api_security',
    'security_assessement',
    'fraud_monitoring',
    'training',
    'continous_monitoring',
];
export const FEATURE_CATEGORIES = ['Features & content', 'Scan functionality', 'Auditing'];
