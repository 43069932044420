import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Dialog, DialogContent, DialogTitle, Slide, Stack, DialogActions } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const AppViewModal2 = (props) => {
    const theme = useTheme();
    const { open, handleClose, children, title, actions, width } = props;
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={handleClose}
            sx={{
                '& .MuiDialog-container': {
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                },
            }}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 300,
                    width,
                    height: '100vh',
                    maxHeight: '100vh',
                    m: 0,
                    zIndex: 1002,
                    padding: 'unset',
                },
            }}
            scroll="paper"
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <DialogTitle
                    sx={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: theme.palette.gray[900],
                        borderBottom: '1px solid #F1F5F9',
                    }}
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        {title}
                    </Stack>
                </DialogTitle>
            </Stack>
            <DialogContent>{children}</DialogContent>
            {actions && <DialogActions sx={{ display: 'block', px: 3, mb: 3 }}>{actions}</DialogActions>}
        </Dialog>
    );
};

export default AppViewModal2;
