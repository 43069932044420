import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import Play from '../../../../../assets/img/bullet.svg';
// core components
import { AppFormInput, AppFormSelect } from 'components/new_components/forms';
import AppInput from 'components/new_components/AppInput';
import AppSelect from 'components/new_components/AppSelectInput';
import ExemptTag from '../../common/ExemptTag';

//translation
import { useTranslation } from 'react-i18next';

const FieldItem = (props) => {
    const theme = useTheme();
    const { field, exception, removeExempt, loading, conditional } = props;

    //translation
    const { t } = useTranslation('compliance');

    return (
        <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
            <Box sx={{ marginTop: 0.8 }}>
                <img src={Play} alt="" />
            </Box>
            <Stack>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            color: theme.palette.gray[800],
                            fontWeight: 500,
                            maxWidth: 750,
                        }}
                    >
                        {field.name}
                    </Typography>
                    {exception && conditional ? (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <ExemptTag
                                onClick={removeExempt}
                                loading={loading?.exempt}
                                loadingLabel={t('reverting')}
                                label={t('revert')}
                            />
                        </Box>
                    ) : null}
                </Box>
                <Box sx={{ width: 500 }}>
                    {exception ? (
                        <ExceptionField field={field} name={field?.id?.toString()} exception={exception} />
                    ) : (
                        <Field field={field} name={field?.id?.toString()} exception={exception} />
                    )}
                </Box>
            </Stack>
        </Stack>
    );
};

const Field = (props) => {
    const { field, name } = props;

    //translation
    const { t } = useTranslation('compliance');

    return field?.type === 'text' ? (
        <AppFormInput type="text" name={name} placeholder={t('blockchain.enterResponse')} />
    ) : field?.type === 'number' ? (
        <AppFormInput type="number" name={name} placeholder={t('blockchain.enterNumber')} />
    ) : field?.type === 'textarea' ? (
        <AppFormInput type="text" name={name} placeholder={t('blockchain.explain')} multiline />
    ) : field?.type === 'date' ? (
        <AppFormInput type="date" name={name} />
    ) : field?.type === 'time' ? (
        <AppFormInput type="time" name={name} />
    ) : field?.type === 'dropdown' ? (
        <AppFormSelect name={name} options={field?.options?.split(',') || []} defaultValue="Select Option" />
    ) : null;
};

const ExceptionField = (props) => {
    const { field, name, exception } = props;

    //translation
    const { t } = useTranslation('compliance');

    return field?.type === 'text' ? (
        <AppInput
            type="text"
            name={name}
            placeholder={t('blockchain.enterResponse')}
            disabled={exception ? true : false}
        />
    ) : field?.type === 'number' ? (
        <AppInput
            type="number"
            name={name}
            placeholder={t('blockchain.enterNumber')}
            disabled={exception ? true : false}
        />
    ) : field?.type === 'textarea' ? (
        <AppInput
            type="text"
            name={name}
            placeholder={t('blockchain.explain')}
            disabled={exception ? true : false}
            multiline
        />
    ) : field?.type === 'date' ? (
        <AppInput type="date" name={name} disabled={exception ? true : false} />
    ) : field?.type === 'time' ? (
        <AppInput type="time" name={name} disabled={exception ? true : false} />
    ) : field?.type === 'dropdown' ? (
        <AppSelect
            name={name}
            options={field?.options?.split(',') || []}
            defaultValue={t('blockchain.selectOption')}
            disabled={exception ? true : false}
        />
    ) : null;
};

export default FieldItem;
