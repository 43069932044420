import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button, Upload, Select, Empty } from 'antd';
import { AiOutlineInbox } from 'react-icons/ai';

import { getOptionLabel } from 'utils';

// redux
import { connect } from 'react-redux';
import { CreateDocument, EditDocument } from 'store/actions/adminActions';

const { Option } = Select;

const AddDocumentModal = (props) => {
    const {
        open,
        handleClose,
        all_documents_by_alttags,
        tag,
        CreateDocument,
        EditDocument,
        payload,
        titleLabel,
        descLabel,
        groupIdLabel,
        one_document,
    } = props;

    // state
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [mode, setMode] = useState('new');
    const [extendingDocID, setExtendingDocID] = useState();

    // drgagger
    const { Dragger } = Upload;
    const draggers = {
        name: 'default',
        action: null,
        multiple: false,
        maxCount: 1,
        onChange(info) {
            setData({ ...data, default: info.fileList[0]?.originFileObj });
        },
    };

    // functions
    const closeAll = () => {
        setData({});
        setExtendingDocID(null);
        handleClose();
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (mode === 'new') {
            handleCreateDocument();
        } else {
            // if the record is to be extended to another document
            handleDocumentExtension();
        }
    };
    const createFormData = () => {
        const formData = new FormData();
        const newPayload = { ...payload, ...data, ...one_document };
        for (let field in newPayload) {
            if (field !== 'default') {
                formData.append(field, newPayload[field]);
            }
        }
        return formData;
    };
    const handleCreateDocument = async () => {
        //collating the form data
        const formData = createFormData();
        formData.append('tags', tag);

        // for codereview and pentest documents, don't upload a document
        if (!one_document?.type) {
            formData.append('default', data?.default);
        }

        // get the group ID (requirement no, category id or continuity id) based on the tag
        const groupID = payload?.[groupIdLabel];
        setLoading(true);
        const res = await CreateDocument(formData, groupID, tag);
        setLoading(false);
        if (res.success) {
            toast.success('Document Added Successfully');
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };
    const handleDocumentExtension = async () => {
        // collating the form data
        const formData = createFormData();
        // adding the new tag.
        // first get the document from the alttag documents
        const extendingDoc = all_documents_by_alttags?.find((doc) => doc.id === extendingDocID);
        // then split the csv into array
        let newTags = extendingDoc?.tags?.split(',');
        // push the new standard's tag to the existing one (linking the document)
        newTags.push(tag);
        // append the new tags list to the exist form data
        formData.append('tags', newTags.join(','));

        // get the group ID (requirement no, category id, control id or continuity id) based on the tag
        const groupID = payload?.[groupIdLabel];
        setLoading(true);
        // send request to edit the form data.
        // extendingDocID => the existing document that hosts the extension
        const res = await EditDocument(formData, extendingDocID, tag, groupID);
        setLoading(false);
        if (res.success) {
            toast.success('Document Added Successfully');
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    useEffect(() => {
        createFormData();
    }, [one_document]);
    return (
        <Modal
            title={`Add ${one_document?.type || ''} document`}
            open={open}
            onCancel={closeAll}
            footer={null}
            destroyOnClose={true}
        >
            <form encType="multipart/form-data" onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor={titleLabel}>Document title</label>
                            <Input
                                type="text"
                                size="large"
                                name={titleLabel}
                                id={titleLabel}
                                onChange={handleTextChange}
                                value={data?.[titleLabel] || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor={descLabel}>Document description</label>
                            <Input.TextArea
                                type="text"
                                size="large"
                                name={descLabel}
                                id={descLabel}
                                onChange={handleTextChange}
                                value={data?.[descLabel] || ''}
                                required
                            />
                        </div>
                    </Col>
                    {!one_document?.type && (
                        <>
                            <Col span={24}>
                                <div className="form-group" style={{ marginBottom: '1rem' }}>
                                    <label htmlFor="creation_mode">Select creation mode</label>
                                    <Select
                                        defaultValue="new"
                                        size="large"
                                        id="creation_mode"
                                        onChange={(value) => setMode(value)}
                                        value={mode}
                                        required
                                        style={{ width: '100%' }}
                                    >
                                        <Option value="new">Upload a new document</Option>
                                        <Option value="extend">Extend an existing document</Option>
                                    </Select>
                                </div>
                            </Col>

                            {mode === 'new' ? (
                                <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                                    <label htmlFor="template_file">Upload Template</label>
                                    <Dragger {...draggers}>
                                        <p className="ant-upload-drag-icon">
                                            <AiOutlineInbox />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    </Dragger>
                                </Col>
                            ) : (
                                <Col span={24}>
                                    <div className="form-group" style={{ marginBottom: '1rem' }}>
                                        <label htmlFor="extending_doc">Select the document to extend</label>
                                        <Select
                                            size="large"
                                            id="extending_doc"
                                            required
                                            style={{ width: '100%' }}
                                            onChange={(value) => setExtendingDocID(value)}
                                            value={extendingDocID}
                                        >
                                            {all_documents_by_alttags?.filter(
                                                (doc) => !doc.tags.split(',').includes(tag)
                                            )?.length ? (
                                                all_documents_by_alttags
                                                    ?.filter((doc) => !doc.tags.split(',').includes(tag))
                                                    ?.map((doc) => {
                                                        return (
                                                            <Option
                                                                value={doc.id}
                                                                key={doc.id}
                                                                title={getOptionLabel(doc, tag)}
                                                            >
                                                                {getOptionLabel(doc, tag)}
                                                            </Option>
                                                        );
                                                    })
                                            ) : (
                                                <Option key="">
                                                    <Empty description="No document to extend yet" />
                                                </Option>
                                            )}
                                        </Select>
                                    </div>
                                </Col>
                            )}
                        </>
                    )}

                    <Col>
                        <Button
                            className="ant-btn"
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                            style={{ marginTop: '3rem' }}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = (state) => {
    return {
        all_documents_by_alttags: state?.adminReducers?.all_documents_by_alttags,
    };
};
export default connect(mapStateToProps, { CreateDocument, EditDocument })(AddDocumentModal);
