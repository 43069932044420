import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import Cookies from 'js-cookie';
// core components
import Register from './Register';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import OtpScreen from './OtpScreen';
import ChangePassword from './ChangePasword';

// imgs
import { ReactComponent as Logo } from 'assets/img/brand/logo.svg';
import { Language } from '@mui/icons-material';
import LanguageMenu from 'components/new_components/merchant-layout/header/menus/LanguageMenu';
import { useTranslation } from 'react-i18next';
import CookieNoticeModal from './components/CookieNoticeModal';
import PrivacyNoticeModal from './components/PrivacyNoticeModal';
import CookiesModal from './components/CookiesModal';
import spiral from 'assets/img/img-auth.svg';
// import bannerCompliance from 'assets/img/auth-banner-text.svg';

const AuthLayout = () => {
    Cookies.defaults = {
        expires: 365, // Cookie expiration in days
        path: '/',
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const [cookieNoticeOpen, setCookieNoticeOpen] = useState(false);
    const [privacyNoticeOpen, setPrivacyNoticeOpen] = useState(false);
    const [cookieModalOpen, setCookieModalOpen] = useState(false);
    const theme = useTheme();
    // const date = new Date();
    const { t } = useTranslation('auth');
    const location = useLocation();

    const open = Boolean(anchorEl);
    const openLanguageMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeLanguageMenu = () => {
        setAnchorEl(null);
    };

    const openCookieNoticeModal = () => {
        setCookieNoticeOpen(true);
    };
    const closeCookieNoticeModal = () => {
        setCookieNoticeOpen(false);
    };

    const openPrivacyNoticeModal = () => {
        setPrivacyNoticeOpen(true);
    };
    const closePrivacyNoticeModal = () => {
        setPrivacyNoticeOpen(false);
    };
    const closeCookieModal = () => {
        setCookieModalOpen(false);
    };
    useEffect(() => {
        if (Cookies.get('accepted')) {
            setCookieModalOpen(false);
        } else {
            setCookieModalOpen(true);
        }
    }, []);

    return (
        <>
            <Grid container spacing={0} sx={{ minHeight: '100vh', backgroundColor: theme.palette.white.main }}>
                <Grid
                    item
                    xs={0}
                    lg={5}
                    sx={{
                        display: { xs: 'none', lg: 'flex' },
                        justifyContent: 'center',
                        alignItems: 'center',
                        py: 3,
                    }}
                    className="auth-aside-design"
                >
                    <Stack alignItems="center" sx={{ zIndex: 99 }}>
                        <Box>
                            <Typography
                                sx={{ fontSize: '32px', fontWeight: '700', color: '#0E2C66', textAlign: 'center' }}
                            >
                                {t('motto.title')} <br /> {t('motto.businesses')}
                            </Typography>
                        </Box>
                        <Box sx={{ mt: '44px', display: 'flex', justifyContent: 'center', gap: '24px' }}>
                            <Box
                                className="automated"
                                sx={{
                                    width: '142.63px',
                                    height: '52.38px',
                                    background: '#E4EFFF',
                                    border: '2.61px solid #5474C4',
                                    borderRadius: '9.34px',
                                    position: 'relative',
                                }}
                            >
                                <Box
                                    sx={{
                                        background: '#FFFFFF',
                                        borderRadius: '5.22px',
                                        padding: '8.69px',
                                        position: 'absolute',
                                        top: '8.36px',
                                        right: '8.36px',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '20.87px', fontWeight: '700', color: '#5474C4' }}>
                                        {t('motto.automated')}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                className="secured"
                                sx={{
                                    width: '106.95px',
                                    height: '48.69px',
                                    background: '#5474C4',
                                    border: '2.61px solid #5474C4',
                                    borderRadius: '5.22px',
                                    position: 'relative',
                                }}
                            >
                                <Box
                                    sx={{
                                        background: '#5474C4',
                                        borderRadius: '5.22px',
                                        padding: '8.69px',
                                        position: 'absolute',
                                        top: '4.35px',
                                        right: '4.35px',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '20.87px', fontWeight: '700', color: '#FFFFFF' }}>
                                        {t('motto.secured')}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                className="reliable"
                                sx={{
                                    width: '106.95px',
                                    height: '48.69px',
                                    background: '#5474C4',
                                    border: '2.61px solid #5474C4',
                                    borderRadius: '5.22px',
                                    position: 'relative',
                                }}
                            >
                                <Box
                                    sx={{
                                        background: '#5474C4',
                                        borderRadius: '5.22px',
                                        padding: '8.69px',
                                        position: 'absolute',
                                        top: '4.35px',
                                        right: '4.35px',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '20.87px', fontWeight: '700', color: '#FFFFFF' }}>
                                        {t('motto.reliable')}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Stack>
                    <Box sx={{ position: 'absolute', top: '-15px', left: '-100px' }}>
                        <img src={spiral} alt="spiral" className="-z-10 h-[55vh] w-[750px]" />
                    </Box>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <Container sx={{ pt: 4, pb: 3, height: '100%', width: '100%' }}>
                        <Stack sx={{ height: '100%' }} justifyContent="space-between">
                            <header className="flex flex-row justify-between items-center">
                                <Logo className="w-[120px]" />
                                <Box className="text-[12px] font-medium cursor-pointer" onClick={openLanguageMenu}>
                                    <Language fontSize="small" className="mr-2" />
                                    {window.localStorage.i18nextLng?.includes('en')
                                        ? 'English'
                                        : window.localStorage.i18nextLng?.includes('fr')
                                        ? 'French'
                                        : window.localStorage.i18nextLng}
                                </Box>
                            </header>
                            <LanguageMenu
                                open={open}
                                closeLanguageMenu={closeLanguageMenu}
                                anchorEl={anchorEl}
                                language={
                                    window.localStorage.i18nextLng?.includes('en')
                                        ? 'English'
                                        : window.localStorage.i18nextLng?.includes('fr')
                                        ? 'French'
                                        : window.localStorage.i18nextLng
                                }
                                menuListStyles={{
                                    parent: {
                                        right: '2rem',
                                    },
                                }}
                            />
                            <Box>
                                <Switch>
                                    <Route path="/auth/login" render={(props) => <Login {...props} />} />
                                    <Route path="/auth/add-merchant" render={(props) => <Register {...props} />} />
                                    <Route
                                        path="/auth/forgot-password"
                                        render={(props) => <ForgotPassword {...props} />}
                                    />
                                    <Route path="/auth/confirm-otp" render={(props) => <OtpScreen {...props} />} />
                                    <Route
                                        path="/auth/change-password"
                                        render={(props) => <ChangePassword {...props} />}
                                    />
                                </Switch>
                            </Box>
                            <Stack component="footer" justifyContent="center" alignItems="center">
                                {location?.pathname !== '/auth/add-merchant' && (
                                    <Stack
                                        direction="row"
                                        ml="auto"
                                        fontSize="13px"
                                        divider={
                                            <Divider
                                                orientation="vertical"
                                                sx={{
                                                    borderColor: theme.palette.primary[900],
                                                    x: '25px',
                                                }}
                                            />
                                        }
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <FooterItem onClick={openPrivacyNoticeModal}>
                                            {t('footerLinks.link1')}
                                        </FooterItem>
                                        <FooterItem onClick={openCookieNoticeModal}>
                                            {' '}
                                            {t('footerLinks.link2')}
                                        </FooterItem>
                                    </Stack>
                                )}

                                <Typography
                                    sx={{
                                        color: theme.palette.primary[100],
                                        paddingTop: location?.pathname === '/auth/add-merchant' ? 3 : '',
                                    }}
                                >
                                    <Box component="span" sx={{ fontSize: 18 }}>
                                        {/* &copy;{' '} */}
                                    </Box>
                                    {/* {date.getFullYear()} Smartcomply */}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Container>
                </Grid>
            </Grid>

            <CookiesModal
                open={cookieModalOpen}
                handleClose={closeCookieModal}
                openCookieNoticeModal={openCookieNoticeModal}
                openPrivacyNoticeModal={openPrivacyNoticeModal}
            />
            <CookieNoticeModal open={cookieNoticeOpen} handleClose={closeCookieNoticeModal} />
            <PrivacyNoticeModal open={privacyNoticeOpen} handleClose={closePrivacyNoticeModal} />
        </>
    );
};
const FooterItem = (props) => {
    const theme = useTheme();
    const { children, onClick } = props;
    return (
        <Box
            sx={{
                p: 1,
                textAlign: 'center',
                color: theme.palette.primary[900],
            }}
            component="button"
            onClick={onClick}
        >
            {children}
        </Box>
    );
};
export default AuthLayout;
