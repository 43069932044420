import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import { getData } from './utils/constants';
import { ReactComponent as ArrowLeftIcon } from 'assets/img/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/img/arrow-right.svg';
import { connect, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import * as types from 'store/constants';
import { ReactComponent as ChatBubbleCorner } from 'assets/img/chat-bubble-corner.svg';

const UserGuideModal = (props) => {
    const { name, corner, align } = props;
    const guide_id = useSelector((state) => state?.userGuideReducers?.id);
    const dispatch = useDispatch();
    const theme = useTheme();
    const [step] = useState(guide_id ?? 0);
    const language = localStorage.getItem('i18nextLng');

    const capitalizedName = name[0]?.toUpperCase() + name.substring(1) ?? '';
    const data = getData(capitalizedName) ?? [];

    const { id, title, title_fr, description, image } = data[step] ?? {};

    return (
        <Box sx={{ display: 'flex', alignItems: align ?? 'start' }}>
            {corner && <ChatBubbleCorner className={`w-4 h-4 ${corner} mr-[-2px]`} />}
            <Box
                sx={{
                    display: 'flex',
                    padding: 0,
                    width: '370px',
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    borderRadius: '5px',
                    maxHeight: 600,
                    zIndex: 1002,
                    position: 'relative',
                    background: 'white',
                    '@media (max-width:450px)': {
                        width: '95%',
                    },
                    '& .bold': {
                        color: '#0E2C66',
                    },
                }}
            >
                <Stack justifyContent="space-between">
                    <Stack>
                        <Box
                            sx={{
                                margin: '0px',
                            }}
                        >
                            <img src={image} alt={title} className="w-full h-[210px] object-cover" />
                        </Box>
                    </Stack>
                    <Box sx={{ p: '15px' }}>
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: 700,
                                color: '#334155',
                                textAlign: 'left',
                                marginBottom: '8px',
                            }}
                        >
                            {language?.includes('fr') ? title_fr : title}
                        </Typography>

                        <Typography
                            sx={{
                                fontSize: '13px',
                                fontWeight: 400,
                                color: '#3E414D',
                                textAlign: 'justify',
                            }}
                            dangerouslySetInnerHTML={{ __html: description }}
                        ></Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '30px',
                                alignItems: 'center',
                            }}
                        >
                            {id === 0 ? null : (
                                <Typography
                                    sx={{
                                        fontSize: '13px',
                                        fontWeight: 300,
                                        color: '#77777A',
                                        textAlign: 'justify',
                                    }}
                                >
                                    {id}/{getData()?.length - 1}
                                </Typography>
                            )}
                            <Box sx={{ marginLeft: 'auto' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'right',
                                        alignSelf: 'end',
                                        gap: '15px',
                                    }}
                                >
                                    {id <= 1 ? null : (
                                        <Box
                                            onClick={() => dispatch({ type: types.GO_TO_PREVIOUS_GUIDE })}
                                            sx={{
                                                backgroundColor: 'white',
                                                color: '#475569',
                                                borderRadius: '5px',
                                                fontWeight: 600,
                                                fontSize: '13px',
                                                padding: '8px',
                                                width: '80px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                border: '1px solid #E2E8F0',
                                            }}
                                        >
                                            <ArrowLeftIcon className="mr-2 w-5 h-5" /> Prev
                                        </Box>
                                    )}

                                    <Box
                                        onClick={() =>
                                            id < getData()?.length - 1
                                                ? dispatch({ type: types.GO_TO_NEXT_GUIDE })
                                                : dispatch({ type: types.TOGGLE_USERGUIDE, payload: false })
                                        }
                                        sx={{
                                            backgroundColor: theme.palette.primary[900],
                                            color: theme.palette.white.main,
                                            borderRadius: '5px',
                                            fontWeight: 600,
                                            fontSize: '13px',
                                            padding: '8px',
                                            width: 'auto',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            border: '2px solid #6E8EDF',
                                        }}
                                    >
                                        {id === 0 ? "Let's Go " : id === getData()?.length - 1 ? 'Finish ' : 'Next '}{' '}
                                        <ArrowRightIcon className="ml-2 w-5 h-5" />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        name: state?.generalReducers?.user_info?.first_name,
    };
};

export default connect(mapStateToProps)(UserGuideModal);
