import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Button } from '@mui/material';

const RequestAuditorModal = (props) => {
    const { open, handleClose, auditor, requestAuditor, seekedAuditor } = props;
    // const handleRequest = () => {};
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ fontSize: '1.2rem' }}>Do you want to request for this auditor?</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {seekedAuditor?.id
                        ? `You are trying to request for ${auditor?.owner_detail?.first_name} ${auditor?.owner_detail?.last_name} as your auditor. You have a pending reuqest for ${seekedAuditor?.owner_detail?.first_name} ${seekedAuditor?.owner_detail?.last_name}, do you want to overwrite the previous request?`
                        : `You are trying to request for ${auditor?.owner_detail?.first_name} ${auditor?.owner_detail?.last_name} as your auditor. Do you want to proceed?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={requestAuditor} autoFocus>
                    Request
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RequestAuditorModal;
