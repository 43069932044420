import React from 'react';
import { Box, Typography } from '@mui/material';
import { AppFormInput } from 'components/new_components/forms';
import { FaUmbrella } from 'react-icons/fa';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

import slantArrow from 'assets/img/slantArrow.png';
import './style.css';

//translations
import { useTranslation } from 'react-i18next';

const RiskControl = ({ treatment_type, setTreatment_type }) => {
    //translation
    const { t } = useTranslation('riskAssessment');

    return (
        <Box sx={{ overflow: 'scroll !Important' }}>
            <AppFormInput
                type="text"
                label={t('cyberSecurityAssessment.control')}
                name="existing_controls"
                placeholder={t('cyberSecurityAssessment.existingControl')}
            />
            <Box sx={{ marginTop: 1.5 }}>
                <Typography>{t('cyberSecurityAssessment.riskTreatment')}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'between', alignItems: 'center', marginTop: 1 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            border: treatment_type === 'mitigate' ? '2px solid #002C72' : ' 1px solid #AAABB4',
                            color: treatment_type === 'mitigate' ? '#002C72' : '#AAABB4',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            width: '70px',
                            height: '60px',
                        }}
                        onClick={() => setTreatment_type('mitigate')}
                    >
                        <FaUmbrella />
                        <Typography sx={{ fontSize: '12px' }}>{t('cyberSecurityAssessment.mitigate')}</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            border: treatment_type === 'accept' ? '2px solid #002C72' : ' 1px solid #AAABB4',
                            color: treatment_type === 'accept' ? '#002C72' : '#AAABB4',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            width: '70px',
                            height: '60px',
                            marginLeft: 3,
                        }}
                        onClick={() => setTreatment_type('accept')}
                    >
                        <CheckCircleIcon />
                        <Typography sx={{ fontSize: '12px' }}>{t('cyberSecurityAssessment.accept')}</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            border: treatment_type === 'avoid' ? '2px solid #002C72' : ' 1px solid #AAABB4',
                            color: treatment_type === 'avoid' ? '#002C72' : '#AAABB4',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            width: '70px',
                            height: '60px',
                            marginLeft: 3,
                        }}
                        onClick={() => setTreatment_type('avoid')}
                    >
                        <img src={slantArrow} alt="slantArrow" />
                        <Typography sx={{ fontSize: '12px' }}>{t('cyberSecurityAssessment.avoid')}</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            border: treatment_type === 'transfer' ? '2px solid #002C72' : ' 1px solid #AAABB4',
                            color: treatment_type === 'transfer' ? '#002C72' : '#AAABB4',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            width: '70px',
                            height: '60px',
                            marginLeft: 3,
                        }}
                        onClick={() => setTreatment_type('transfer')}
                    >
                        <SwapHorizIcon />
                        <Typography sx={{ fontSize: '12px' }}>{t('cyberSecurityAssessment.transfer')}</Typography>
                    </Box>
                </Box>
                {/* <img src={RiskLine} alt="RiskLine" /> */}

                {treatment_type === 'mitigate' ? (
                    <div className="option1">
                        <div className="first-line"></div>
                        <div className="second-line"></div>
                        <div className="third-line"></div>
                        <div className="forth-line"></div>
                    </div>
                ) : treatment_type === 'accept' ? (
                    <div className="option2">
                        <div className="first-line"></div>
                        <div className="second-line"></div>
                        <div className="third-line"></div>
                        <div className="forth-line"></div>
                    </div>
                ) : treatment_type === 'avoid' ? (
                    <div className="option3">
                        <div className="first-line"></div>
                        <div className="second-line"></div>
                        <div className="third-line"></div>
                        <div className="forth-line"></div>
                    </div>
                ) : (
                    <div className="option4">
                        <div className="first-line"></div>
                        <div className="second-line"></div>
                        <div className="third-line"></div>
                        <div className="forth-line"></div>
                    </div>
                )}
                <Typography sx={{ fontSize: '12px', fontWeight: 500, color: '#8F9099', marginTop: 1.5 }}>
                    {treatment_type === 'accept'
                        ? t('cyberSecurityAssessment.acceptValue')
                        : treatment_type === 'avoid'
                        ? t('cyberSecurityAssessment.avoidValue')
                        : treatment_type === 'mitigate'
                        ? t('cyberSecurityAssessment.mitigateValue')
                        : t('cyberSecurityAssessment.transferValue')}
                </Typography>
            </Box>

            <AppFormInput
                type="text"
                label={t('cyberSecurityAssessment.commentsControl')}
                name="control_comments"
                placeholder={t('cyberSecurityAssessment.recommended')}
            />
        </Box>
    );
};

export default RiskControl;
