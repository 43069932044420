import React from 'react';
import { Drawer, Slide, Box, Typography, Button } from '@mui/material';
import Rectangle from '../../assets/img/Rectangle.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AppLinearProgress from 'components/new_components/AppLinearProgress';
import CancelIcon from '@mui/icons-material/Cancel';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const AppSummaryModal = (props) => {
    const {
        open,
        handleClose,
        children,
        anchor,
        page,
        setPage,
        totalLength,
        setAnswer,
        setStatus,
        response,
        completed,
        question,
        setQuestion,
        setOtheranswer,
    } = props;

    const handleNextPage = () => {
        if (question && page !== totalLength) {
            setPage(page + 1);
            setAnswer([]);
            setStatus(false);
            setQuestion('');
            setOtheranswer('');
        }
    };
    const handlePreviousPage = () => {
        if (page === 1) {
            setPage(page);
        } else {
            setPage(page - 1);
        }

        setStatus(false);
        setQuestion('');
        setOtheranswer('');
    };
    const percentage = (response?.length / totalLength) * 100;
    return (
        <div>
            <Drawer
                open={open}
                anchor={anchor || 'right'}
                TransitionComponent={Transition}
                transitionDuration={1000}
                keepMounted
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        boxShadow: 'none',
                        borderRadius: 4,
                        width: '700px',
                        overflow: 'auto',
                        background: 'none',
                        zIndex: -1,
                    },
                }}
                scroll="paper"
            >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                        variant={'contained'}
                        startIcon={<CancelIcon />}
                        onClick={handleClose}
                        size="medium"
                        sx={{
                            transform: 'rotate(-90deg)',
                            margin: -4,
                            textTransform: 'capitalize',
                            borderRadius: '14px 14px 0px 0px',
                        }}
                    >
                        Survey
                    </Button>
                    <Box
                        sx={{
                            boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                            borderRadius: 4,
                            width: '700px',
                            height: '100vh',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            background: '#ffffff',
                        }}
                    >
                        <Box sx={{ padding: -4 }}>
                            <AppLinearProgress value={percentage} survey={true} />
                        </Box>

                        {completed ? null : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    padding: 4,
                                    // height: '15vh',
                                }}
                            >
                                <img src={Rectangle} alt="rectangle" />
                                <Box
                                    sx={{
                                        marginLeft: 2,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '20px',
                                            fontWeight: '700',
                                            color: '#46464A',
                                        }}
                                    >
                                        Survey
                                    </Typography>
                                    <Typography
                                        component={'span'}
                                        sx={{
                                            fontSize: '10px',
                                            fontWeight: '200',
                                            color: '#46464A',
                                        }}
                                    >
                                        The road so far...
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                        <Box sx={{ padding: '0 4rem', height: '70vh', overflowY: 'scroll', overflowX: 'hidden' }}>
                            {children}
                        </Box>
                        {completed ? null : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: '#F7F7F7',
                                    height: '10vh',
                                    width: '100%',
                                    padding: '0 1rem',
                                    position: 'fixed',
                                    overflow: 'hidden',
                                    bottom: 0,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handlePreviousPage}
                                    sx={{
                                        padding: '0.5rem ',
                                    }}
                                >
                                    <ArrowBackIosIcon
                                        fontSize="small"
                                        sx={{
                                            color: page === 1 ? '#C7C6CA' : '#FFFFFF',
                                            cursor: page === 1 ? 'not-allowed' : 'pointer',
                                        }}
                                    />
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handleNextPage}
                                    sx={{
                                        padding: '0.5rem ',
                                        marginLeft: 3,
                                        cursor: question && page !== totalLength ? 'pointer' : 'not-allowed',
                                    }}
                                >
                                    <ArrowForwardIosIcon
                                        sx={{
                                            color: question && page !== totalLength ? '#FFFFFF' : '#C7C6CA',
                                        }}
                                        fontSize="small"
                                    />
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Drawer>
        </div>
    );
};

export default AppSummaryModal;
