import React, { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    // Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    Badge,
    Tooltip,
    Menu,
    Stack,
} from '@mui/material';
import { KeyboardArrowRightRounded } from '@mui/icons-material';
import SidebarListGroupTwo from './SidebarListGroupTwo';

// core component
import ListIcon from './ListIcon';
import { removeSpaces } from 'utils';
import { connect, useSelector } from 'react-redux';
import UserGuideModal from './UserGuideModal';
import { getData as guides } from './UserGuideModal/utils/constants';

const SidebarListItemGroup = (props) => {
    const theme = useTheme();
    // props
    const {
        active,
        icon,
        icon_active,
        text,
        subMenus,
        merchant_info,
        disabled,
        isNew,
        mfa,
        pricing_plan,
        title,
        ref2,
    } = props;
    // state
    const [anchorEl, setAnchorEl] = useState(null);
    const [clicked, setClicked] = useState(false);
    const [guideAnchorEl, setGuideAnchorEl] = useState(null);
    const [guideClicked, setGuideClicked] = useState(false);

    // memos
    const selectedPaidStandard = useMemo(() => {
        const paidStandards = removeSpaces(merchant_info?.paid_standards)?.toLowerCase();
        return merchant_info?.standards?.split(',')?.filter((standard) => paidStandards?.includes(standard));
    }, [merchant_info]);

    // functions
    function handleClick(event) {
        setClicked(true);
        setAnchorEl(event.currentTarget);
    }

    // function handlemouseOver(event) {
    //     setAnchorEl(event.currentTarget);
    // }

    function handleClose() {
        setClicked(false);
        setAnchorEl(null);
    }

    function handleGuideClose() {
        setGuideClicked(false);
    }

    const state = useSelector((state) => state?.userGuideReducers) ?? {};
    const { id: guide_id, isActive } = state;

    const modal_data = guides('john').filter((guide) => guide.title.toLowerCase() === text.toLowerCase())?.[0];

    const is_guide = isActive && modal_data?.id === guide_id;

    useEffect(() => {
        if (is_guide) {
            setGuideAnchorEl(document.getElementById(`${text}-tab`));
            if (document.getElementById(`${text}-tab`)) {
                document.getElementById(`${text}-tab`).style.border = '2px solid #6e8edf';
            }
        } else {
            if (document.getElementById(`${text}-tab`)) {
                document.getElementById(`${text}-tab`).style.border = 'none';
            }
        }
    }, [is_guide]);

    const filtered_subMenus =
        text === 'Framework'
            ? merchant_info?.merchant_plan?.tag === 'free'
                ? subMenus
                : subMenus.filter((sub) => selectedPaidStandard?.includes(sub.name.toLowerCase()))
            : subMenus;

    // constants
    let extraStyles = {};
    if (active) {
        extraStyles = {
            backgroundColor: theme.palette.primary.new,
            color: theme.palette.white.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.new,
                color: theme.palette.white.main,
            },
        };
    } else {
        extraStyles = {
            backgroundColor: title === text ? theme.palette.primary.new : 'transparent',
            color: theme.palette.white.main,
            zIndex: title === text ? 20000000 : 0,
            border: title === text ? '1px solid #6E8EDF' : '',
            '&:hover': {
                color: theme.palette.white.main,
            },
        };
    }
    return (
        <Box
            sx={{
                mx: 1,
                borderRadius: '4px',
                overflow: 'hidden',
                ...extraStyles,
            }}
        >
            <ListItemButton
                id={`${text}-tab`}
                onClick={handleClick}
                // onMouseOverCapture={handlemouseOver}
                sx={{
                    pl: 1,
                    pr: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '&:hover': {
                        backgroundColor: 'inherit',
                    },
                }}
                disabled={disabled}
            >
                <Stack direction="row" alignItems="center" ref={ref2}>
                    <ListItemIcon className="listItem__icon" sx={{ minWidth: 40 }}>
                        {active ? icon_active : icon}
                    </ListItemIcon>
                    <Badge variant="dot" invisible={!isNew} color="error">
                        <ListItemText primary={text} sx={{ '& span': { fontSize: '13px', fontWeight: 400 } }} />
                    </Badge>
                </Stack>
                <KeyboardArrowRightRounded sx={{ color: theme.palette.white.main }} />
            </ListItemButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onMouseLeave={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ '& .MuiMenu-paper': { maxWidth: '270px', width: '100%', px: 2 } }}
                MenuListProps={{ onMouseLeave: !clicked ? handleClose : '' }}
            >
                <List sx={{ py: 0 }}>
                    {filtered_subMenus.map((subMenu) => {
                        if (subMenu?.subMenus) {
                            const activeChild = subMenu.subMenus.find(
                                (submenu) => location.pathname === submenu.layout + subMenu.path + submenu.path
                            );
                            return (
                                <SidebarListGroupTwo
                                    key={subMenu.name}
                                    subMenus={subMenu.subMenus}
                                    active={activeChild}
                                    text={subMenu.name}
                                    icon={<ListIcon Component={subMenu.icon} />}
                                    merchant_info={merchant_info}
                                    route={subMenu.path}
                                    isNew={subMenu.isNew}
                                    disabled={!mfa && subMenu.layout + subMenu.path !== '/merchant/settings'}
                                    plan={pricing_plan?.tag}
                                    handleClose_={handleClose}
                                />
                            );
                        } else {
                            return !subMenu?.subRoute ? (
                                <SidebarListGroupItem
                                    active={subMenu.name === active?.name}
                                    parentActive={active}
                                    secondaryTitle={subMenu.name}
                                    primaryTitle={subMenu.name}
                                    link={subMenu.layout + subMenu.path}
                                    icon={<ListIcon Component={subMenu.icon} />}
                                    key={subMenu.name}
                                    subMenus={subMenus}
                                    disabled={disabled}
                                    isNew={subMenu.isNew}
                                    handleClose={handleClose}
                                />
                            ) : null;
                        }
                    })}
                </List>
            </Menu>

            <Menu
                anchorEl={guideAnchorEl}
                // open={Boolean(anchorEl)}
                open={is_guide}
                onClose={handleGuideClose}
                // onMouseLeave={handleClose}
                anchorOrigin={{
                    vertical: modal_data?.position ? modal_data?.position : 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: modal_data?.position ? modal_data?.position : 'top',
                    horizontal: 'left',
                }}
                sx={{
                    background: 'rgba(0, 0, 0, 0.2)',
                    '& .MuiMenu-paper': {
                        width: 'auto',
                        ml: 0.5,
                        p: 0,
                        backgroundColor: 'transparent !important',
                        boxShadow: 'none !important',
                        '& ul': { p: 0 },
                    },
                }}
                MenuListProps={{ onMouseLeave: !guideClicked ? handleClose : '' }}
            >
                <UserGuideModal
                    corner={modal_data?.position === 'bottom' ? 'mb-3' : 'mt-3'}
                    align={modal_data?.position === 'bottom' ? 'end' : 'start'}
                />
            </Menu>
        </Box>
    );
};

const SidebarListGroupItem = (props) => {
    const theme = useTheme();
    const history = useHistory();
    const { active, parentActive, icon, primaryTitle, secondaryTitle, link, disabled, isNew, handleClose } = props;
    let extraStyles = {};

    if (parentActive) {
        if (active) {
            extraStyles = {
                '&, &:hover': {
                    backgroundColor: '#F8FAFC',
                    color: theme.palette.primary[900],
                },
            };
        } else {
            extraStyles = {
                '&, &:hover': {
                    color: theme.palette.primary[900],
                },
            };
        }
    } else {
        extraStyles = {
            '&, &:hover': {
                color: theme.palette.white.main,
            },
        };
    }
    return (
        <Tooltip
            title={
                primaryTitle?.toLowerCase() === 'kdpa'
                    ? 'Kenya Data Protection Act'
                    : primaryTitle?.toLowerCase() === 'gdpa'
                    ? 'Ghana Data Protection Act & Regulations'
                    : primaryTitle?.toLowerCase() === 'cii'
                    ? 'Cii & Risk Management Directives'
                    : primaryTitle?.toLowerCase() === 'iso27017+iso27032'
                    ? 'Cloud Security, Cybersecurity, and Project Management'
                    : ''
            }
            placement="right-start"
            arrow
        >
            <span>
                <ListItemButton
                    sx={{
                        pl: 1,
                        py: 1,
                        position: 'relative',
                        ...extraStyles,
                        '&:hover': {
                            backgroundColor: '#F8FAFC !important',
                        },
                    }}
                    disabled={disabled}
                    onClick={() => {
                        history.push(link);
                        handleClose();
                    }}
                >
                    <ListItemIcon
                        className="listItem__icon"
                        sx={{
                            minWidth: 30,
                            backgroundColor: '#F1F5F9',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: 2,
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    <ListItemText
                        sx={{
                            '& span': { fontSize: '13.5px', fontWeight: 500 },
                        }}
                    >
                        <Typography
                            sx={{ fontSize: 12, fontWeight: 600, lineHeight: '20px', color: theme.palette.gray[700] }}
                        >
                            {primaryTitle === 'CII' ? 'CII DIRECTIVE' : primaryTitle}
                        </Typography>
                        <Typography
                            sx={{ fontSize: 11, fontWeight: 400, lineHeight: '20px', color: theme.palette.gray[500] }}
                        >
                            {secondaryTitle}
                        </Typography>
                    </ListItemText>
                    {isNew ? (
                        <Typography
                            sx={{
                                textTransform: 'uppercase',
                                position: 'absolute',
                                right: '24%',
                                top: '5%',
                                fontSize: 10,
                                px: 0.4,
                                fontWeight: 500,
                                borderRadius: '4px',
                                background: theme.palette.error[500],
                                // color: theme.palette.primary.white,
                                color: '#fff',
                            }}
                        >
                            New
                        </Typography>
                    ) : null}
                </ListItemButton>
            </span>
        </Tooltip>
    );
};

const mapStateToProps = (state) => {
    return {
        pricing_plan: state?.authReducers.pricing_plan,
        mfa: state?.generalReducers?.user_info?.mfa,
    };
};
export default connect(mapStateToProps, {})(SidebarListItemGroup);
