import { ForkOutlined } from '@ant-design/icons';
import { Divider, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import ConditionalItem from './ConditionalItem';

import { connect } from 'react-redux';
import { GetAllCiiConditionalItems } from 'store/actions/adminActions';
import { toast } from 'react-toastify';
import ConditionalModal from './ConditionalModal';
import EmptyComponent from 'components/EmptyComponents/EmptyComponent';

const ConditionalItems = (props) => {
    const {
        admin_cii_conditional_items,
        GetAllCiiConditionalItems,
        subsector,
        openModal,
        closeModal,
        modal,
        data,
        all_documents,
    } = props;

    // states
    const [loading, setLoading] = useState(false);

    // memos
    const conditionalItemsBySubcriteria = useMemo(() => {
        return admin_cii_conditional_items?.filter((item) => item?.cii_subsector === subsector?.id);
    }, [admin_cii_conditional_items]);

    // functions
    const getAllCiiConditionalItems = async () => {
        setLoading(true);
        const res = await GetAllCiiConditionalItems();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        getAllCiiConditionalItems();
    }, []);

    return (
        <>
            <Divider orientation="left">
                <span className="flex items-center">
                    <ForkOutlined /> Conditional Item
                </span>
            </Divider>
            <Row gutter={16}>
                {loading ? (
                    <>loading...</>
                ) : conditionalItemsBySubcriteria?.length ? (
                    conditionalItemsBySubcriteria
                        ?.sort((a, b) => a?.id - b?.id)
                        ?.map((item) => {
                            const document = all_documents?.find((doc) => item?.document_id === doc?.id);
                            return (
                                <ConditionalItem
                                    item={item}
                                    key={item?.id}
                                    openModal={openModal}
                                    documentUrl={document?.default}
                                />
                            );
                        })
                ) : (
                    <div className="w-full">
                        <EmptyComponent text="No Conditional Items" />
                    </div>
                )}
            </Row>
            <ConditionalModal open={modal} handleClose={closeModal} modalData={data} subsector={subsector} />
        </>
    );
};
const mapStateToProps = (state) => ({
    admin_cii_conditional_items: state?.adminReducers?.admin_cii_conditional_items,
    all_documents: state?.adminReducers?.subsector_documents,
});
export default connect(mapStateToProps, { GetAllCiiConditionalItems })(ConditionalItems);
