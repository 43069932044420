import React from 'react';

import { Switch, Route } from 'react-router-dom';

import IntegrationDashboard from './IntegrationsDashboard';
import IntegrationsPage from './IntegrationsPage';
import FieldCreation from './Operations/FieldCreation';

const Integration = () => {
    return (
        <Switch>
            <Route path="/admin/integrations" exact component={IntegrationDashboard} />
            <Route path="/admin/integrations/integration" exact component={IntegrationsPage} />
            <Route path="/admin/integrations/integration/fieldCreation/:id" exact component={FieldCreation} />
        </Switch>
    );
};

export default Integration;
