import { Divider } from '@mui/material';
import React, { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
    ArcElement,
    Chart as ChartJS,
    Legend,
    Tooltip,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
} from 'chart.js';

import { useSelector } from 'react-redux';
import PolygonMedium from 'assets/img/medium_polygon.png';
import PolygonLow from 'assets/img/ciso_positive_angle.png';
import PolygonHigh from 'assets/img/LowPolygon.svg';

ChartJS.register(ArcElement, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);
ChartJS.defaults.plugins.tooltip.backgroundColor = '#395BA9';
ChartJS.defaults.plugins.tooltip.titleColor = '#fff';
ChartJS.defaults.plugins.tooltip.titleAlign = 'center';
ChartJS.defaults.plugins.tooltip.bodyAlign = 'center';
ChartJS.defaults.plugins.tooltip.displayColors = false;
ChartJS.defaults.plugins.tooltip.titleFont = {
    family: 'Poppins, sans-serif',
    weight: 'bold',
};
ChartJS.defaults.plugins.tooltip.bodyFont = {
    family: 'Poppins, sans-serif',
    weight: 'normal',
};
ChartJS.defaults.plugins.tooltip.boxPadding = 4;
ChartJS.defaults.plugins.tooltip.xAlign = 'center';
ChartJS.defaults.plugins.tooltip.yAlign = 'bottom';

const zeroDataBackgroundPlugin = {
    id: 'zeroDataBackground',
    beforeDraw: (chart) => {
        const { datasets } = chart.data;
        const ctx = chart.ctx;

        // Check if datasets exist and contain data
        if (!datasets || !datasets.length || !datasets[0].data.length) {
            return; // Exit early if no data
        }

        // Check if all data values are zero
        const allDataZero = datasets[0].data.every((value) => value === 0);

        if (allDataZero) {
            const { chartArea } = chart;
            const centerX = (chartArea.left + chartArea.right) / 2;
            const centerY = (chartArea.top + chartArea.bottom) / 2;

            // Ensure the dataset meta is available and has data
            const meta = chart.getDatasetMeta(0);
            if (!meta || !meta.data || !meta.data.length) {
                return; // Exit if meta data is not available
            }

            const { outerRadius, innerRadius } = meta.data[0].getProps(['outerRadius', 'innerRadius'], true);

            ctx.save();
            ctx.fillStyle = '#f0f0f0'; // Set your desired background color here
            ctx.beginPath();
            ctx.arc(centerX, centerY, outerRadius, 0, 2 * Math.PI); // Outer circle
            ctx.arc(centerX, centerY, innerRadius, 0, 2 * Math.PI, true); // Inner circle (inverted)

            ctx.fill();
            ctx.restore();

            // Optional: Add a message on the chart
            ctx.font = '20px Poppins';
            ctx.fillStyle = '#666';
            ctx.textAlign = 'center';
            // ctx.fillText('No Data Available', centerX, centerY);
        }
    },
};

ChartJS.register(zeroDataBackgroundPlugin);

const InverntoryScan_ = () => {
    const { inventory_scan_summary } = useSelector((state) => state?.CISOReducers);

    const data_3 = useMemo(
        () => ({
            labels: ['Needs Attention', 'Looks Okay'],
            datasets: [
                {
                    data: [
                        inventory_scan_summary?.failed_code_reviews_count,
                        inventory_scan_summary?.passed_code_reviews_count,
                    ],
                    backgroundColor: ['#FF5449', '#7AD3FF'],
                    hoverOffset: 4,
                    borderWidth: 0,
                },
            ],
        }),
        [inventory_scan_summary]
    );

    const data_4 = useMemo(
        () => ({
            labels: ['Needs Attention', 'Looks Okay'],
            datasets: [
                {
                    data: [
                        inventory_scan_summary?.api_compliance_need_attention,
                        inventory_scan_summary?.api_compliance_need_attention,
                    ],
                    backgroundColor: ['#FF5449', '#7AD3FF'],
                    hoverOffset: 4,
                    borderWidth: 0,
                },
            ],
        }),
        [inventory_scan_summary]
    );

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            },
            zeroDataBackground: {}, // Enable the plugin
            tooltip: {
                font: {
                    weight: 'bold',
                    family: 'Poppins, sans-serif',
                },
            },
        },
        datalabels: {
            display: false, // Turn off datalabels
        },
    };
    return (
        <div className="mt-[5rem]">
            <div className="flex items-center gap-4 mt-[1.5rem]">
                <h4 className="text-[#2B3674] text-lg font-bold text-center">Inventory Scan</h4>
                <div className="flex items-center gap-1 border border-[#E2E8F0] p-1.5 rounded">
                    <img
                        src={
                            inventory_scan_summary?.total_percentage_passed < 50
                                ? PolygonHigh
                                : inventory_scan_summary?.total_percentage_passed >= 50 &&
                                  inventory_scan_summary?.total_percentage_passed <= 70
                                ? PolygonMedium
                                : PolygonLow
                        }
                        alt="PolygonMedium"
                    />
                    <p className="text-gray-500 text-sm font-semibold mt-[-1rem]">
                        {inventory_scan_summary?.total_percentage_passed}%
                    </p>
                </div>
                <div className="w-[74%]">
                    <Divider orientation="horizontal" sx={{ backgroundColor: '#E2E8F0' }} />
                </div>
            </div>

            <div className="flex justify-center items-center w-full mt-[4rem]">
                <div className="flex justify-between items-center w-[90%]">
                    <div>
                        <div className="flex items-center gap-5">
                            <div className="w-[170px]">
                                <Doughnut data={data_3} options={options} />
                            </div>
                            <div>
                                <div className="flex items-center gap-2">
                                    <div className="w-[15px] h-[15px] rounded bg-[#7AD3FF]"></div>
                                    <p className="text-[#64748B] text-[13px] font-medium">
                                        {inventory_scan_summary?.passed_code_reviews_count} Code review looks okay
                                    </p>
                                </div>
                                <div className="flex items-center gap-2 mt-[1rem]">
                                    <div className="w-[15px] h-[15px] rounded bg-[#FF5449]"></div>
                                    <p className="text-[#64748B] text-[13px] font-medium">
                                        {inventory_scan_summary?.failed_code_reviews_count} code review{' '}
                                        <span className="text-[#FF5449]">needs attention</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p className="text-[#2B3674] text-base font-bold mt-[2rem]">Codebase Security Scan</p>
                    </div>
                    <div>
                        <div className="flex items-center gap-5">
                            <div className="w-[170px]">
                                <Doughnut data={data_4} options={options} />
                            </div>
                            <div>
                                <div className="flex items-center gap-2">
                                    <div className="w-[15px] h-[15px] rounded bg-[#7AD3FF]"></div>
                                    <p className="text-[#64748B] text-[13px] font-medium">
                                        {inventory_scan_summary?.api_compliance_need_attention} APIs looks okay{' '}
                                    </p>
                                </div>
                                <div className="flex items-center gap-2 mt-[1rem]">
                                    <div className="w-[15px] h-[15px] rounded bg-[#FF5449]"></div>
                                    <p className="text-[#64748B] text-[13px] font-medium">
                                        {inventory_scan_summary?.api_compliance_need_attention} APIs{' '}
                                        <span className="text-[#FF5449]">needs attention </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p className="text-[#2B3674] text-base font-bold mt-[2rem]">API Compliance</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InverntoryScan_;
