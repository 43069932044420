import { validation } from 'validate';

export const requestModalValidation = (values) => {
    const errors = {};
    const nameValidate = validation(values.name, 'Scan name', true);
    const ipAddressValidate = validation(values.ip_address, 'IP Address', true);
    const scheduleValidate = validation(values.schedule, 'select', true);

    if (!nameValidate.isValid) {
        errors.name = nameValidate.errorMessage;
    }
    if (!ipAddressValidate.isValid) {
        errors.ip_address = ipAddressValidate.errorMessage;
    }
    if (!scheduleValidate.isValid) {
        errors.schedule = scheduleValidate.errorMessage;
    }

    return errors;
};

export const uploadModalValidation = (values) => {
    const errors = {};
    const nameValidate = validation(values.name, 'Scan name', true);
    const ipAddressValidate = validation(values.ip_address, 'Ip Address', true);
    const reportValidate = validation(values.file_field, 'report', true);
    const statusValidate = validation(values.status, 'select', true);
    const uploadedByValidate = validation(values.uploaded_by, 'Platform name', true);
    const conductedDateValidate = validation(values.conducted_date, 'Date', true);

    if (!nameValidate.isValid) {
        errors.name = nameValidate.errorMessage;
    }
    if (!ipAddressValidate.isValid) {
        errors.ip_address = ipAddressValidate.errorMessage;
    }
    if (!statusValidate.isValid) {
        errors.status = statusValidate.errorMessage;
    }
    if (!reportValidate.isValid) {
        errors.file_field = reportValidate.errorMessage;
    }
    if (!uploadedByValidate.isValid) {
        errors.uploaded_by = uploadedByValidate.errorMessage;
    }
    if (!conductedDateValidate.isValid) {
        errors.conducted_date = conductedDateValidate.errorMessage;
    }
    return errors;
};
