import { Box, Button, Chip, Typography, useTheme } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppForm, AppFormInput, AppFormSelect, AppSubmitButton } from 'components/new_components/forms';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllCompanyEmployees } from 'store/actions/generalActions';
import { AssignComplianceTask } from 'store/actions/taskActions';
import { assignTaskValidation } from '../validation';
import CloseIcon from '@mui/icons-material/Close';
import Bulb from 'assets/img/Light.svg';

const AssignTaskModal = (props) => {
    const {
        open,
        onClose,
        GetAllCompanyEmployees,
        AssignComplianceTask,
        all_employees,
        type,
        user_id,
        employee_id,
        taskInfo,
    } = props;
    const theme = useTheme();

    // state
    const [loading, setLoading] = useState({});
    const [payload, setPayload] = useState({ employees: [], comments: '' });

    // memos
    const employees = useMemo(() => {
        return all_employees
            ?.filter((emp) => emp?.id !== employee_id)
            ?.map((user) => ({
                name: user?.user_detail?.first_name
                    ? `${user?.user_detail?.first_name} ${user?.user_detail?.last_name}`
                    : user?.user_detail?.email,
                value: user?.id,
            }));
    }, [all_employees]);
    // functions
    const handleClose = () => {
        onClose();
        setTimeout(() => setPayload({}), 500);
    };
    const handleSubmit = async (values) => {
        const users = values.employees?.map((employee) => parseInt(employee));
        setLoading({ ...loading, submit: true });
        const res = await AssignComplianceTask({
            assigned_to: users,
            comments: values?.comments,
            assigned_by: user_id,
            tag: 'compliance',
            completion_status: 'not_completed',
            mark_as_completed: false,
            ...taskInfo,
        });
        setLoading({ ...loading, submit: false });
        if (res?.success) {
            toast.success('Task has been assigned to the employee(s)');
            handleClose();
        } else {
            toast.error("Something went wrong, couldn't assign task to employee(s)");
        }
    };
    //async action
    const getAllEmployees = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAllCompanyEmployees();
        setLoading({ ...loading, content: false });
        if (!res?.status) {
            toast.error(res?.status);
        }
    };
    // useEffect
    useEffect(() => {
        if (open) {
            setPayload({ employees: '', comments: '' });
        }
    }, [open]);

    useEffect(() => {
        getAllEmployees();
    }, []);
    return (
        <AppForm initialValues={payload} onSubmit={handleSubmit} validate={assignTaskValidation}>
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                title={
                    <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                        Assign {type === 'doc' ? 'Policy Upload' : type === 'form' ? 'Question' : 'Control Objective'}
                    </Typography>
                }
                icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
                noShadow
                noClose
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center',
                            backgroundColor: '#F8FAFC',
                            py: 2,
                            pr: 3,
                            mx: '-2rem',
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit' }}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <AppSubmitButton
                            text="Assign"
                            variant="contained"
                            color="primary"
                            loading={loading.submit}
                            loadingPosition="center"
                        />
                    </Box>
                }
                sx={{ borderRadius: 1 }}
                headerAction={
                    <Box
                        sx={{
                            border: '1px solid #E2E8F0',
                            borderRadius: 30,
                            width: 20,
                            height: 20,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={onClose}
                    >
                        <CloseIcon sx={{ width: 12, height: 12, color: '#334155' }} />
                    </Box>
                }
                width="550px"
            >
                <Box sx={{ py: 0.4, pb: 2 }}>
                    <Box>
                        <AppFormSelect
                            label={
                                <Typography sx={{ fontSize: '13px', fontWeight: 500, color: '#64748B' }}>
                                    User
                                </Typography>
                            }
                            multiple
                            options={employees}
                            name="employees"
                            loading={loading.content}
                            noDataLabel="No employees at the moment"
                            renderValue={(selected) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 1.2,
                                    }}
                                >
                                    {selected?.map((value) => {
                                        const employee = employees?.find((employee) => employee?.value == value);
                                        return (
                                            <Chip
                                                sx={{
                                                    backgroundColor: theme.palette.gray[95],
                                                    borderRadius: 1,
                                                    p: 0.5,
                                                    color: theme.palette.gray[40],
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                }}
                                                key={value}
                                                label={employee?.name}
                                            />
                                        );
                                    })}
                                </Box>
                            )}
                        />
                        <AppFormInput type="text" name="comments" multiline rows={4} placeholder="Comment" />
                    </Box>
                </Box>
                <Box
                    sx={{
                        backgroundColor: '#F8FAFC',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'start',
                        gap: 2,
                        my: 1.5,
                        py: 1,
                        px: 2,
                        borderRadius: 1,
                        border: '1px solid #F0F0FA',
                    }}
                >
                    <img src={Bulb} alt="Bulb" />
                    <Typography sx={{ color: '#64748B', fontWeight: 400, fontSize: '13px' }}>
                        You can view tasks you assigned to users on the activity page of the smartcomply application.
                    </Typography>
                </Box>
            </AppCenteredModal>
        </AppForm>
    );
};
const mapStateToProps = (state) => ({
    all_employees: state?.generalReducers?.all_employees,
    user_id: state?.authReducers?.user_id,
    employee_id: state?.authReducers?.user_info?.employee?.id,
    user_type: state?.authReducers?.user_type,
});
export default connect(mapStateToProps, { GetAllCompanyEmployees, AssignComplianceTask })(AssignTaskModal);
