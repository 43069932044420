import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Button, Typography, Card, Row, Col, Input, Space, Spin, Empty, Modal } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

//Redux
import { connect } from 'react-redux';
import { CreateQuestonnaireForm, DeleteFormField, EditQuestonnaireForm } from 'store/actions/vendorActions';

//Components
import FieldModal from './FieldModal';
import FieldItem from './components/FormFieldItem';

// sub components
const { confirm } = Modal;
const { Title } = Typography;

const FormCreation = (props) => {
    const { questionnaire_forms, DeleteFormField, EditQuestonnaireForm, match } = props;
    // memo
    const formId = useMemo(() => parseInt(match.params.id), [match.params]);
    // state
    const [openModal, setModal] = useState(false);
    const [form, setForm] = useState({});
    const [modalMode, setModalMode] = useState('');
    const [loading, setLoading] = useState({ form: false, content: false });
    const [one_field, setOneField] = useState({});

    // functions
    const openAddFieldModal = (mode, data) => {
        setModalMode(mode);
        setOneField(data);
        setModal(true);
    };
    const closeAddFieldModal = () => {
        setModalMode('');
        setOneField({});
        setModal(false);
    };
    const getFormData = () => {
        const one_form = questionnaire_forms?.find((form) => form.id === formId);
        setForm(one_form || {});
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading({ ...loading, form: true });
        const res = await EditQuestonnaireForm({ title: form.title }, formId);
        setLoading({ ...loading, form: false });
        if (res.success) {
            toast.success(res?.message);
        } else {
            toast.error(res.message);
        }
    };
    const deleteFormField = (field) => {
        confirm({
            title: "Are you sure youw want to delete this field? Note: Deleting this field means you're deeleting this field and records of merchant data associated with this field",
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                setLoading({ ...loading, content: true });
                const res = await DeleteFormField(field?.id);
                setLoading({ ...loading, content: false });
                if (res?.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };
    const handleTitleChange = (e) => {
        setForm((form) => ({ ...form, title: e.target.value }));
    };
    // useEffect
    useEffect(() => {
        getFormData();
    }, [questionnaire_forms, formId]);
    return (
        <>
            <Row justify="space-between">
                <Col>
                    <Title level={2}>Fields</Title>
                </Col>
                <Col>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => openAddFieldModal('add')}>
                        Add Field
                    </Button>
                </Col>
            </Row>
            <form onSubmit={handleFormSubmit}>
                <Row style={{ margin: '1rem 0' }}>
                    <Col xs={20}>
                        <Input
                            placeholder="Form Title"
                            size="large"
                            value={form.title}
                            onChange={handleTitleChange}
                            required
                        />
                    </Col>
                    <Col xs={4}>
                        <Button type="primary" size="large" loading={loading?.form} htmlType="submit">
                            Edit Title
                        </Button>
                    </Col>
                </Row>
            </form>
            <section className="my-3">
                {loading?.content ? (
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                ) : form.form_fields && form.form_fields?.length ? (
                    <Row gutter={16}>
                        {form.form_fields
                            ?.sort((a, b) => a?.id - b?.id)
                            ?.map((field) => {
                                return (
                                    <Col xs={24} md={12} style={{ marginBottom: '1rem' }} key={field.name}>
                                        <Card
                                            extra={
                                                <Space>
                                                    <Button
                                                        shape="circle"
                                                        icon={<EditOutlined />}
                                                        onClick={() => openAddFieldModal('edit', field)}
                                                    />
                                                    <Button
                                                        shape="circle"
                                                        danger
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => deleteFormField(field)}
                                                    />
                                                </Space>
                                            }
                                        >
                                            <FieldItem field={field} />
                                        </Card>
                                    </Col>
                                );
                            })}
                    </Row>
                ) : (
                    <Row justify="center">
                        <Col>
                            <Empty
                                description={
                                    <div>
                                        <h3>No Fields</h3>
                                        <span>Add a new field </span>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                )}
            </section>
            <FieldModal
                mode={modalMode}
                open={openModal}
                onClose={closeAddFieldModal}
                one_field={one_field}
                form_id={form?.id}
            />
        </>
    );
};

const mapStateToProp = (state) => {
    return {
        questionnaire_forms: state?.vendorReducers?.questionnaire_forms,
    };
};
export default connect(mapStateToProp, {
    CreateQuestonnaireForm,
    DeleteFormField,
    EditQuestonnaireForm,
})(FormCreation);
