import { Button, Col, Input, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { TOOLS_CATEGORY } from '../db';

// redux
import { connect } from 'react-redux';
import { CreateCiiToolsItem, EditCiiToolsItem } from 'store/actions/adminActions';
import { toast } from 'react-toastify';

const ToolsModal = (props) => {
    const { open, handleClose, modalData, CreateCiiToolsItem, EditCiiToolsItem, subsector } = props;
    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // functions
    const closeAll = () => {
        handleClose();
        setData({});
    };
    const handleCategoryChange = (value, name) => {
        setData({ ...data, [name]: value });
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = open === 'Add' ? await CreateCiiToolsItem(data) : await EditCiiToolsItem(data, data?.id);
        setLoading(false);
        if (res?.success) {
            toast.success(res?.success);
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (open) {
            const category = modalData?.category;
            setData({ ...modalData, category, cii_subsector: subsector?.id, cii_sector: subsector?.cii_sector });
        }
    }, [open]);
    return (
        <Modal open={open} onCancel={closeAll} footer={null} destroyOnClose={true} title={`${open} a Tool Item`}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24}>
                        <div className="mb-4">
                            <label>Select Tool Type</label>
                            <Select
                                name="category"
                                id="category"
                                size="large"
                                style={{ width: '100%' }}
                                value={data?.category}
                                onChange={(value) => handleCategoryChange(value, 'category')}
                                className="capitalize"
                            >
                                {TOOLS_CATEGORY.map((category) => {
                                    return (
                                        <Select.Option value={category} className="capitalize">
                                            {category}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="mb-4">
                            <label>Title</label>
                            <Input
                                id="title"
                                type="text"
                                size="large"
                                value={data?.title || ''}
                                onChange={handleTextChange}
                                name="title"
                            />
                        </div>
                    </Col>
                    <Col className="mt-2">
                        <Button htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, { CreateCiiToolsItem, EditCiiToolsItem })(ToolsModal);
