import React, { useState, useEffect, useMemo, useContext } from 'react';
import { toast } from 'react-toastify';
//components
import { Stack, Box } from '@mui/material';
import AppSelectInput from 'components/new_components/AppSelectInput';
import RiskLevelCard from '../components/RiskLevelCard';
import RiskAssetCard from '../components/RiskAssetCard';
import RiskDistributionCard from '../components/RiskDistributionCard';
//constants
import { StatusOption, StatusOptionFr, AssetTypesOption, AssetTypesOptionFr } from '../utils/constants';
//redux
import { connect } from 'react-redux';
import { GetRiskAssessmentSummary, GetAllMerchantRiskAssessments } from 'store/actions/merchantActions';

//translations
import { useTranslation } from 'react-i18next';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

const RiskOverview = (props) => {
    const {
        GetRiskAssessmentSummary,
        GetAllMerchantRiskAssessments,
        risk_assessment_summary,
        all_merchant_risk_assessments,
    } = props;

    const [status, setStatus] = useState('');
    const [risk_owner, setRisk_owner] = useState('');
    const [asset_type, setAssetType] = useState('');

    //translation
    const { t } = useTranslation('riskAssessment');
    const language = localStorage.getItem('i18nextLng');

    //functions
    const onStatusSelect = (e) => {
        setStatus(e.target.value);
    };
    const onRiskOwnersSelect = (e) => {
        setRisk_owner(e.target.value);
    };
    const onAssetSelect = (e) => {
        setAssetType(e.target.value);
    };

    //context api for auditor's selected merchant
    const { activeMerchant } = useContext(SelectedMerchantContext);

    const query_params = useMemo(() => {
        const params = {
            ...(status.length && { status: status === 'All' ? '' : status }),
            ...(risk_owner?.length && { risk_owner: risk_owner === 'All' ? '' : risk_owner }),
            ...(asset_type?.length && { asset_type: asset_type === 'All' ? '' : asset_type }),
        };
        return params;
    }, [status, risk_owner, asset_type]);

    useEffect(() => {
        const getRiskAssessmentSummary = async () => {
            const res = await GetRiskAssessmentSummary(query_params, activeMerchant);
            if (!res.success) {
                toast.error(res?.message);
            }
        };
        getRiskAssessmentSummary();
    }, [query_params, activeMerchant]);

    useEffect(() => {
        const getAllMerchantRiskAssessments = async () => {
            const res = await GetAllMerchantRiskAssessments(activeMerchant);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllMerchantRiskAssessments();
    }, [activeMerchant]);

    const RiskOwners = useMemo(() => {
        return all_merchant_risk_assessments?.results?.map((owner) => {
            return { name: owner?.risk_owner, value: owner?.risk_owner };
        });
    }, [all_merchant_risk_assessments?.results]);

    RiskOwners?.splice(0, 0, { name: 'All', value: 'All' });
    const filteredRiskOwner = RiskOwners?.reduce((unique, o) => {
        if (!unique.some((obj) => obj?.value === o.value && obj?.name === o?.name)) {
            unique.push(o);
        }
        return unique;
    }, []);

    return (
        <Box>
            <Stack direction="row" sx={{ width: { md: '100%', lg: '30%' }, my: 4 }}>
                <AppSelectInput
                    name="status"
                    options={language?.includes('fr') ? StatusOptionFr : StatusOption}
                    defaultValue={t('overview.status')}
                    sx={{ mr: 1, borderRadius: '8px', height: 38, fontSize: 12 }}
                    onChange={onStatusSelect}
                    value={status}
                    size="small"
                />
                <AppSelectInput
                    name="riskOwners"
                    options={filteredRiskOwner}
                    defaultValue={t('overview.riskOwner')}
                    sx={{ mr: 1, borderRadius: '8px', height: 38, fontSize: 12 }}
                    onChange={onRiskOwnersSelect}
                    value={risk_owner}
                />
                <AppSelectInput
                    name="asset"
                    options={language?.includes('fr') ? AssetTypesOptionFr : AssetTypesOption}
                    defaultValue={t('overview.assetType')}
                    sx={{ mr: 1, borderRadius: '8px', height: 38, fontSize: 12 }}
                    onChange={onAssetSelect}
                    value={asset_type}
                    size="small"
                />
            </Stack>
            <Stack>
                <RiskDistributionCard
                    fiveByFiveMatrix={risk_assessment_summary?.['5x5']?.top_risks}
                    threeBythreeMatrix={risk_assessment_summary?.['3x3']?.top_risks}
                />
            </Stack>
            <Stack direction={{ md: 'column', xl: 'row', lg: 'column' }} spacing={2} sx={{ mt: 4 }}>
                <RiskLevelCard
                    Data={{
                        fiveByFiveMatrix: risk_assessment_summary?.['5x5']?.risk_level,
                        threeBythreeMatrix: risk_assessment_summary?.['3x3']?.risk_level,
                    }}
                    fiveByFiveMatrix={risk_assessment_summary?.['5x5']?.top_risks}
                    threeBythreeMatrix={risk_assessment_summary?.['3x3']?.top_risks}
                />
                <RiskAssetCard
                    fiveByFiveMatrix={risk_assessment_summary?.['5x5']?.risk_assets}
                    threeBythreeMatrix={risk_assessment_summary?.['3x3']?.risk_assets}
                    fiveByFiveMatrix_={risk_assessment_summary?.['5x5']?.top_risks}
                    threeBythreeMatrix_={risk_assessment_summary?.['3x3']?.top_risks}
                />
            </Stack>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        risk_assessment_summary: state?.merchantReducers?.risk_assessment_summary,
        all_merchant_risk_assessments: state?.merchantReducers?.all_merchant_risk_assessments,
    };
};
export default connect(mapStateToProps, { GetRiskAssessmentSummary, GetAllMerchantRiskAssessments })(RiskOverview);
