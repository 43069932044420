import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Col, Button, Empty, Card, Typography, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { BiUnlink } from 'react-icons/bi';
import { PlusOutlined, EditOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { EditDocument, DeleteDocument } from 'store/actions/adminActions';
import AddDocumentModal from '../Operations/AddDocumentModal';
import EditDocumentModal from '../Operations/EditDocumentModal';
import { GetAllDocumentsByTags } from 'store/actions/adminActions';
import { GetDocumentLink } from 'store/actions/complianceActions';

// sub-components
const { Title } = Typography;
const { confirm } = Modal;

const Soc2DocumentsView = (props) => {
    const { subcontrol } = props;
    const all_admin_documents_by_management = useSelector(
        (state) => state?.adminReducers?.all_admin_documents_by_management
    );
    const [loading, setLoading] = useState(true);

    // state
    const [modal, setModal] = useState({ add: false, edit: false });
    const [oneDoc, setOneDoc] = useState({});
    const dispatch = useDispatch();

    // functions
    const openAddDocumentModal = () => setModal({ ...modal, add: true });
    const closeAddDocumentModal = () => setModal({ ...modal, add: false });
    const openEditDocumentModal = (doc) => {
        setModal({ ...modal, edit: true });
        setOneDoc(doc);
    };
    const closeEditDocumentModal = () => setModal({ ...modal, edit: false });

    const getAllDocumentsByTags = async () => {
        const res = await dispatch(GetAllDocumentsByTags('iso9001'));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getDocumentLink = async (id) => {
        const res = await dispatch(GetDocumentLink('document', id));
        if (res?.success) {
            window.open(res?.data, '_blank');
        } else {
            toast.error('Something went wrong!');
        }
    };
    //Actions Array
    const documentActions = (document) => {
        let actions = [
            <Tooltip title="Download Document" color="blue">
                <div onClick={() => getDocumentLink(document?.id)}>
                    <DownloadOutlined />
                </div>
            </Tooltip>,
            <Tooltip title="Edit this Document" color="blue">
                <EditOutlined key="edit" title="Edit this Document" onClick={() => openEditDocumentModal(document)} />
            </Tooltip>,
            <Tooltip title="Delete this Document" color="red">
                <DeleteOutlined key="delete" title="Delete this document" onClick={() => deleteModal(document)} />
            </Tooltip>,
        ];
        if (document?.tags?.split(',').length > 1 && document?.tags?.split(',').includes('iso9001')) {
            actions.push(
                <Tooltip title="Unlink this Document" color="blue">
                    <BiUnlink key="unlink" title="Unlink this document" onClick={() => unlinkModal(document)} />
                </Tooltip>
            );
        }
        return actions;
    };
    const deleteModal = (document) => {
        confirm({
            title: 'Do you want to delete this document?',
            content:
                'If this document is linked to other standards, you will be deleting it across all standards by clicking yes. If you intend to remove it from this standard alone and it is linked, just unlink it to avoid loss of data.',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await dispatch(DeleteDocument(document?.id, document?.quality_mgt_sub_no, 'iso9001'));
                if (res.success) {
                    toast.success('Document Deleted Successfully');
                } else {
                    toast.error(res.message);
                }
            },
        });
    };
    const unlinkModal = (document) => {
        confirm({
            title: 'Do you want to unlink this document?',
            icon: <ExclamationCircleOutlined />,
            content: 'Unlinking this document will detach this document from this compliance standard',
            okText: 'Yes',
            cancelText: 'No',
            async onOk() {
                const newTags = document.tags
                    .split(',')
                    .filter((tag) => tag !== 'iso9001')
                    .join();
                const formData = new FormData();
                formData.append('tags', newTags);
                const res = await dispatch(EditDocument(formData, document?.id, 'iso9001', document?.sub_control));
                if (res.success) {
                    toast.success('Document unlinked successfully');
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    useEffect(() => {
        setLoading(true);
        getAllDocumentsByTags();
        setLoading(false);
    }, []);
    return (
        <>
            <Row justify="end">
                <Button type="primary" icon={<PlusOutlined />} onClick={openAddDocumentModal}>
                    Add Document
                </Button>
            </Row>
            <section className="my-3">
                {loading.content ? (
                    'loading'
                ) : (
                    <>
                        {all_admin_documents_by_management && all_admin_documents_by_management?.length > 0 ? (
                            <Row gutter={16}>
                                {all_admin_documents_by_management
                                    ?.sort((a, b) => a?.id - b?.id)
                                    .map((document) => {
                                        return (
                                            <Col
                                                xs={24}
                                                sm={12}
                                                md={8}
                                                lg={6}
                                                style={{ marginBottom: '1rem' }}
                                                key={document.id}
                                            >
                                                <Card loading={false} actions={documentActions(document)}>
                                                    <Title level={5} style={{ textAlign: 'center' }}>
                                                        {document.quality_mgt_title}
                                                    </Title>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Document</h3>
                                                <span>Add a new document </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section>
            {modal?.add && (
                <AddDocumentModal
                    open={modal.add}
                    handleClose={closeAddDocumentModal}
                    tag="iso9001"
                    payload={{
                        quality_mgt: subcontrol?.parent_quality,
                        quality_mgt_sub_no: subcontrol?.number,
                    }}
                    titleLabel="quality_mgt_title"
                    descLabel="quality_mgt_description"
                    groupIdLabel="quality_mgt_sub_no"
                />
            )}
            {modal.edit && (
                <EditDocumentModal
                    open={modal.edit}
                    handleClose={closeEditDocumentModal}
                    one_document={oneDoc}
                    tag="iso9001"
                    payload={{
                        quality_mgt: subcontrol?.parent_quality,
                        quality_mgt_sub_no: subcontrol?.number,
                    }}
                    titleLabel="quality_mgt_title"
                    descLabel="quality_mgt_description"
                    groupIdLabel="quality_mgt_sub_no"
                />
            )}
        </>
    );
};

export default Soc2DocumentsView;
