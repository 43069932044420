import React from 'react';
import { Dropdown, Menu } from 'antd';
import {
    //  DeleteOutlined,
    MoreOutlined,
    UploadOutlined,
} from '@ant-design/icons';

export const PentestOperations = (props) => {
    const { record, handleOpenModal } = props;
    const menu = (
        <Menu className="tableaction">
            <Menu.Item key="0" onClick={() => handleOpenModal(record)}>
                <UploadOutlined /> Upload Report
            </Menu.Item>
            {/* <Menu.Item
                key="1"
                style={{ color: 'red' }}
                // onClick={() => deleteModal(record)}
            >
                <DeleteOutlined /> Delete
            </Menu.Item> */}
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <a
                href="#"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                onKeyDown={(e) => e.preventDefault()}
            >
                <MoreOutlined />
            </a>
        </Dropdown>
    );
};
