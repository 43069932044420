import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row } from 'antd';
import BreadCrumb from 'components/Breadcrumb';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { EditAccessorCategory } from 'store/actions/auditActions';

// const { confirm } = Modal;

const CategorySettings = (props) => {
    const { location, all_accessor_categories, EditAccessorCategory } = props;

    // state
    const [questions_, setQuestions] = useState([]);
    const [loading, setLoading] = useState(false);

    // memos
    const category = useMemo(
        () => all_accessor_categories.find((category) => category?.id == location?.state?.category_id),
        [location, all_accessor_categories]
    );

    const questions = useMemo(() => {
        try {
            return JSON.parse(category.questions).sort((a, b) => a?.id - b?.id);
        } catch {
            return [];
        }
    }, [category]);

    // useEffects
    useEffect(() => {
        if (questions?.length === 0) {
            setQuestions([{ 0: '' }]);
        } else {
            setQuestions(questions);
        }
    }, [questions]);

    const addItem = (item) => {
        const totalItems = Object.keys(item).map((el) => el);
        const lastItem = Number(totalItems[totalItems.length - 1]);
        const prev_array = [...questions_];
        const update_element = prev_array?.map((element) => ({
            ...element,
            [lastItem + 1]: '',
        }));
        setQuestions(update_element);
    };

    const removeItem = (index) => {
        const prev_array = [...questions_];
        const update_element = prev_array?.map((el) => {
            delete el[index];
            return el;
        });
        setQuestions(update_element);
    };

    const handleChange = (event, index) => {
        const prev_array = [...questions_];
        const update_element = prev_array?.map((element) => ({
            ...element,
            [index]: event?.target?.value,
        }));
        setQuestions(update_element);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await EditAccessorCategory({ questions: JSON.stringify(questions_) }, category?.id);
        setLoading(false);
        if (res.success) {
            return toast.success(res?.message);
        } else {
            return toast.error(res.message);
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Accessor Categories', link: '/accessor-categories' }]} />
                </Col>
            </Row>
            <form onSubmit={handleSubmit}>
                <section className="my-[5rem]">
                    {questions_?.map((value, index) => (
                        <div key={index} className="py-[1rem]">
                            <h4 className="mb-2">section{index + 1}</h4>
                            <Row justify="space-between">
                                {Object?.keys(value)?.map((val, idx) => (
                                    <Col key={idx} xs={24} xl={7} lg={7} className="mb-4">
                                        <Input
                                            id="name"
                                            type="text"
                                            size="large"
                                            value={value[val]}
                                            onChange={(e) => handleChange(e, idx)}
                                        />
                                        {Object?.keys(value)?.length > 1 && (
                                            <Button onClick={() => removeItem(idx)} className="mt-1">
                                                remove
                                            </Button>
                                        )}
                                    </Col>
                                ))}
                            </Row>
                            {index < 1 && (
                                <Button
                                    icon={<PlusOutlined />}
                                    size="large"
                                    className="flex justify-between items-center"
                                    style={{ position: 'absolute', top: 40, right: 0 }}
                                    onClick={() => addItem(value)}
                                >
                                    add
                                </Button>
                            )}
                        </div>
                    ))}
                </section>

                <Button
                    className="ant-btn"
                    htmlType="submit"
                    type="primary"
                    loading={loading}
                    style={{ marginTop: '1rem', float: 'right' }}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </form>
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_accessor_categories: state?.auditReducers?.all_accessor_categories,
});
export default connect(mapStateToProps, { EditAccessorCategory })(CategorySettings);
