import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// lib components
import { Link } from 'react-router-dom';
import { Button, Row, Col, Typography, Card, Modal, Spin, Empty, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

// core
import BreadCrumb from 'components/Breadcrumb';
import CommonControlModal from './PCISLCActions/CommonControlModal';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { GetAllLifecycles, DeleteLifeCycle } from 'store/actions/pci_slcActions';

// sub-components
const { Title } = Typography;
const { confirm } = Modal;

const Soc2Dashboard = () => {
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState({
        content: false,
    });
    const [one_control, setOneControl] = useState({});
    const dispatch = useDispatch();

    const all_Lifecycle = useSelector((state) => state?.pcislcReducers?.all_Lifecycle);
    // functions
    const openModal = (mode, data) => {
        setModal(mode);
        setOneControl(data);
    };
    const closeModal = () => {
        setModal(null);
    };
    const deleteCommonControl = (id) => {
        confirm({
            title: 'Are you sure you want to delete this objective? Note: Deleting this objective means you will be deleting this objective, the documents, audit questions and merchant data associated with this objective',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await dispatch(DeleteLifeCycle(id));
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };
    const getAllControls = async () => {
        setLoading({ ...loading, content: true });
        const res = await dispatch(GetAllLifecycles());
        setLoading({ ...loading, content: false });
        if (!res?.message) {
            toast.error(res?.message);
        }
    };
    // useeffect
    useEffect(() => {
        getAllControls();
    }, []);
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'PCI SLC' }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal('Add')}
                    >
                        Add Control Objective
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {loading.content ? (
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                ) : (
                    <>
                        {all_Lifecycle && all_Lifecycle?.length > 0 ? (
                            <Row wrap gutter={24}>
                                {all_Lifecycle?.map((objective, index) => (
                                    <Col
                                        xs={24}
                                        md={12}
                                        lg={8}
                                        xxl={6}
                                        key={objective.id}
                                        style={{ marginBottom: '1rem' }}
                                    >
                                        <Card
                                            title={`#${index + 1}`}
                                            loading={false}
                                            actions={[
                                                <Tooltip title="View this objective" color="blue">
                                                    <Link
                                                        key="preview"
                                                        to={{
                                                            pathname: '/admin/pcislc/subobjective',
                                                            state: {
                                                                objective_id: objective?.id,
                                                            },
                                                        }}
                                                    >
                                                        <EyeOutlined key="preview" />
                                                    </Link>
                                                </Tooltip>,
                                                <Tooltip title="Edit this objective" color="blue">
                                                    <EditOutlined
                                                        key="edit"
                                                        onClick={() => openModal('Edit', objective)}
                                                    />
                                                </Tooltip>,
                                                <Tooltip title="Delete this objective" color="blue">
                                                    <DeleteOutlined
                                                        key="delete"
                                                        onClick={() => deleteCommonControl(objective.id)}
                                                    />
                                                </Tooltip>,
                                            ]}
                                        >
                                            <Title level={5} style={{ textAlign: 'center' }}>
                                                {objective.lifecycle_title}
                                            </Title>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Control Objective</h3>
                                                <span>Add a new control Objective</span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section>
            <CommonControlModal open={modal} handleCancel={closeModal} one_control={one_control} />
        </div>
    );
};
export default Soc2Dashboard;
