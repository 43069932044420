import React from 'react';
import { useHistory } from 'react-router-dom';

//translations
import { useTranslation } from 'react-i18next';

const UpgradeAction = () => {
    //translation
    const { t } = useTranslation('common');

    const router = useHistory();
    const upgradeAccount = () => {
        router.push('/merchant/settings/account?action=plans');
    };
    return (
        <div
            className="bg-[#0E2C66] w-fit m-auto px-4 py-3 h-12 flex items-center justify-center upgrade_bg cursor-pointer"
            onClick={upgradeAccount}
        >
            <span className="text-white font-bold text-[14px]">{t('upgrade')}</span>
        </div>
    );
};

export default UpgradeAction;
