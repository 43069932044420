import React, { useState } from 'react';
import { CardContainer } from '../../dashboard-utils/DashboardUtils';
import GroupComputerIcon from '../../../../assets/img/dashboard/GroupComputerIcon.svg';
import GroupServerIcon from '../../../../assets/img/dashboard/GroupServerIcon.svg';
import GroupServer from '../../../../assets/img/dashboard/GroupServer.svg';

//translations
import { useTranslation } from 'react-i18next';
import { isFullFunctionPlan } from 'utils';
import { useSelector } from 'react-redux';
import DisabledFeatureMsg from 'components/Dashboard/OtherReports/DisabledFeatureMsg';

const ServerReports = ({ dashboardResult, pricing_plan }) => {
    const [indexValue, setIndexValue] = useState(1);
    const user_type = useSelector((state) => state?.authReducers?.user_type);

    //translation
    const { t } = useTranslation('overview');

    //constant
    const tabList = [
        {
            id: 1,
            name: t('serverReport.criticalServers'),
            items: ``,
        },
        {
            id: 2,
            name: t('serverReport.infrastructure'),
        },
    ];
    const onChangeTab = (val) => {
        setIndexValue(val);
    };
    return (
        <div>
            <CardContainer cardStyle="h-[360px]">
                <div className="px-4 py-2 border-b border-b-gray-300 lg:text-[20px] text-lg textbrandColor font-bold">
                    {t('serverReport.title')}
                </div>
                <div className="bg-[#F2F0F4] flex text-[#46464A] justify-between px-2 py-2 employee_card">
                    {tabList.map((res, index) => (
                        <React.Fragment key={index}>
                            <div
                                className={`inner_tab ${indexValue === res.id ? 'active' : ''} w-1/2 px-2 py-1`}
                                onClick={() => onChangeTab(res.id)}
                            >
                                {res.name}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
                {indexValue === 1 && (
                    <div>
                        <div className="p-4 h-[295px] relative">
                            <h3>{t('serverReport.allCritical')}</h3>
                            <div className="flex items-center">
                                <img src={GroupServerIcon} alt="GroupServerIcon" />
                                <div className="ml-3">
                                    <p className="!mb-0 text-lg textbrandColor">{t('serverReport.server')}</p>
                                    <h3 className="font-bold text-[24px] text-[#334D80]">
                                        {dashboardResult?.critical_servers}
                                    </h3>
                                </div>
                            </div>
                            <div className="absolute bottom-16 text-[13px]">
                                <span className="p-2 text-[13px] bg-[#E4E2E6] font-bold rounded-[11px] w-fit">
                                    {dashboardResult?.critical_servers_without_agent}
                                </span>
                                <span className="text-[#EB7575] font-[600] pl-2">
                                    {t('serverReport.serverWithoutAgent')}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                {indexValue === 2 && (
                    <div>
                        {isFullFunctionPlan(pricing_plan?.tag) || user_type === 'auditor' ? (
                            <div className="p-4 h-[295px] relative">
                                <div className="textbrandColor mb-2">
                                    <span className=" font-[600] pr-2">{t('serverReport.totalInfrastructure')}</span>
                                    <span className="p-2 text-[13px] bg-[#E4E2E6] font-bold rounded-[11px]">
                                        {dashboardResult?.infrastructure_count}
                                    </span>
                                </div>
                                <div className="flex items-center">
                                    <img src={GroupComputerIcon} alt="GroupComputerIcon" />
                                    <div className="ml-3">
                                        <p className="!mb-0 text-lg textbrandColor">{t('serverReport.computers')}</p>
                                        <h3 className="font-bold text-[24px] text-[#334D80]">
                                            {dashboardResult?.total_computer_count}
                                        </h3>
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <img src={GroupServer} alt="GroupServerIcon" />
                                    <div className="ml-3">
                                        <p className="!mb-0 text-lg textbrandColor">{t('serverReport.server')}</p>
                                        <h3 className="font-bold text-[24px] text-[#334D80]">
                                            {dashboardResult?.total_server_count}
                                        </h3>
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <img src={GroupServer} alt="GroupServerIcon" />
                                    <div className="ml-3">
                                        <p className="!mb-0 text-lg textbrandColor">{t('serverReport.integration')}</p>
                                        <h3 className="font-bold text-[24px] text-[#334D80]">
                                            {dashboardResult?.total_integration}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="h-[230px] px-4">
                                <DisabledFeatureMsg />
                            </div>
                        )}
                    </div>
                )}
            </CardContainer>
        </div>
    );
};

export default ServerReports;
