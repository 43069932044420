import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { WarningAmberOutlined } from '@mui/icons-material';
// import DeleteIcon from "assets/img/DeleteIcon.svg";

// core components
import AppCenteredModal from './AppCenteredModal';
import AppButton from './AppButton';
import AppLoadingButton from './AppLoadingButton';
import { useTranslation } from 'react-i18next';

const AppDeleteModal = (props) => {
    const { open, handleClose, deleting, onDelete, title, subtitle, deleteText, actionsPosition } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('common');

    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            width={450}
            actions={
                <Stack
                    direction="row"
                    justifyContent={actionsPosition || 'flex-end'}
                    alignItems="center"
                    spacing={2}
                    sx={{ mt: 3, mb: 1.5 }}
                >
                    <AppButton
                        name={t('cancel')}
                        variant="text"
                        sx={{ color: theme.palette.gray[900] }}
                        onClick={handleClose}
                    />
                    <AppLoadingButton
                        text={deleteText || t('delete')}
                        variant="contained"
                        color="error"
                        loading={deleting}
                        loadingPosition="center"
                        onClick={onDelete}
                    />
                </Stack>
            }
        >
            <Box
                sx={{
                    fontWeight: 500,
                    color: theme.palette.gray[800],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                }}
            >
                <WarningAmberOutlined sx={{ fontSize: '90px', color: theme.palette.error[500], mb: 1 }} />
                {title && <span style={{ fontSize: '18px', fontWeight: 700 }}>{title}</span>}
                {subtitle && <p style={{ fontSize: '14px', fontWeight: 500, marginTop: '1rem' }}>{subtitle}</p>}
            </Box>
        </AppCenteredModal>
    );
};

export default AppDeleteModal;
