import { Box } from '@mui/material';

import CenteredModal from 'components/new_components/CenteredModal';
import EmptyComponent from 'components/EmptyComponents/EmptyComponent';

const VideoModal = (props) => {
    const { open, handleClose, videos, videoIndex, decodedLink = '' } = props;

    return (
        <CenteredModal open={open} handleClose={handleClose} width={500}>
            {videos?.find((video) => !video?.is_deactivated) ? (
                videos
                    ?.filter((video) => !video?.is_deactivated)
                    ?.map((video, idx) => {
                        return idx === videoIndex ? (
                            <Box sx={{ maxWidth: 1100, minHeight: 470, height: 470 }}>
                                <video
                                    width="100%"
                                    height="470"
                                    controlsList="play timeline volume nodownload"
                                    controls
                                    key={decodedLink}
                                    className="h-[470px]"
                                >
                                    <source src={video?.file ? decodedLink || video?.file : ''} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </Box>
                        ) : null;
                    })
            ) : (
                <EmptyComponent text="No security video has been uploaded yet. Try again later." />
            )}
        </CenteredModal>
    );
};

export default VideoModal;
