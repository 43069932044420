import React, { useEffect, useState } from 'react';

import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { Box, Typography, useTheme } from '@mui/material';
// import { BsInfoCircle } from 'react-icons/bs';
import { CancelRounded } from '@mui/icons-material';
import { connect } from 'react-redux';
import { AppForm, AppSubmitButton, AppFormInput } from 'components/new_components/forms';
// import { useHistory } from 'react-router-dom';
import { requestValidation } from '../utils';
import { GetAuthorizationUrl } from 'store/actions/integrationsActions';
import { toast } from 'react-toastify';

const IntegrationFormModal = (props) => {
    const theme = useTheme();
    // const history = useHistory();

    // props
    const { open, handleClose, integration, GetAuthorizationUrl, authorization_url } = props;
    // states
    const [loading, setLoading] = useState(false);

    const getAuthorizationUrl = async () => {
        const response = await GetAuthorizationUrl(integration?.name);
        if (!response?.success) {
            toast.error(response?.message);
        }
    };

    useEffect(() => {
        if (open) getAuthorizationUrl();
    }, [open]);

    const onSubmit = (values) => {
        setLoading(true);
        localStorage.setItem('integration_name', values?.integration_name);
        localStorage.setItem('org_name', values?.org);
        localStorage.setItem('domain', values?.domain);
        localStorage.setItem('one_integration', integration?.name);
        localStorage.setItem('email', values?.email);
        window.location.href = authorization_url?.authorisation_url;
        setLoading(false);
    };

    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            headerAction={
                <button onClick={handleClose}>
                    <CancelRounded sx={{ color: theme.palette.neutral[60] }} />
                </button>
            }
            width={550}
        >
            <Box>
                <Typography
                    sx={{ color: theme.palette.primary[700], fontSize: '16px', fontWeight: 700, textAlign: 'center' }}
                >
                    Let's authenticate you
                </Typography>
                <Box sx={{ mb: 6, mt: 2 }}>
                    <AppForm
                        initialValues={{
                            integration_name: '',
                            org: '',
                            domain: '',
                            email: '',
                        }}
                        onSubmit={onSubmit}
                        validate={requestValidation}
                    >
                        <AppFormInput
                            type="text"
                            label="Integration Name"
                            placeholder="Enter Preferred Integration Name"
                            name="integration_name"
                        />
                        {integration?.name === 'github' ? (
                            <AppFormInput
                                type="text"
                                label="Organization Name"
                                placeholder="Enter Your Organization Name"
                                name="org"
                            />
                        ) : integration?.name === 'jira' ? (
                            <AppFormInput type="text" label="Domain" placeholder="Enter Domain Name" name="domain" />
                        ) : null}
                        {integration?.name === 'jira' && (
                            <AppFormInput type="text" label="Eamil" placeholder="ayo@gmail.com" name="email" />
                        )}
                        <AppSubmitButton
                            text="Continue"
                            variant="contained"
                            color="primary"
                            loadingPosition="center"
                            sx={{ width: '100%', mt: 3 }}
                            loading={loading}
                        />
                    </AppForm>
                </Box>
            </Box>
        </AppCenteredModal>
    );
};

const mapStateToProps = (state) => {
    return {
        authorization_url: state.integrationsReducers?.authorization_url,
    };
};
export default connect(mapStateToProps, { GetAuthorizationUrl })(IntegrationFormModal);
