import { Box, IconButton, List, MenuItem, Popover, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// core component
import Hello from 'assets/img/Hello.svg';
import Brand from '../../Brand';
import ListIcon from './ListIcon';
import SidebarDivider from './SidebarDivider';
import SidebarListGroup from './SidebarListGroup';
import SidebarListItem from './SidebarListItem';
// import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
//redux
import { Close } from '@mui/icons-material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SearchIcon from 'assets/img/Searchicon.svg';
import Selector from 'assets/img/selector.svg';
import AppProgressBar from 'components/new_components/AppProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ClientId, GetRegisteredClients } from 'store/actions/ImplementerAction';
// import UserGuideModal from './UserGuideModal';
// import { getData as guides } from './UserGuideModal/utils/constants';

const SidebarContent = (props) => {
    // hooks
    const location = useLocation();
    const { routes, merchant_info, title, ref2 } = props;
    const [closeSetup, setCloseUp] = useState(true);
    const [openSwitch, setOpenSwitch] = useState(null);
    const [search, setSearch] = useState('');
    const close_setup = localStorage.getItem('close_setup');
    const language = localStorage.getItem('i18nextLng');
    // const [anchorEl, setAnchorEl] = useState(null);
    // const [clicked, setClicked] = useState(false);
    // // functions

    // function handleClose() {
    //     console.log('click');
    //     setClicked(false);
    //     // setAnchorEl(null);
    // }

    // const state = useSelector((state) => state?.userGuideReducers) ?? {};
    // const { id: guide_id, isActive } = state;

    // const modal_data = guides('john')[8];

    // const is_guide = isActive && modal_data?.id === guide_id;

    // useEffect(() => {
    //     if (is_guide) {
    //         setAnchorEl(document.getElementById('setup_progress'));
    //         if (document.getElementById('setup_progress')) {
    //             document.getElementById('setup_progress').style.border = '2px solid #6e8edf';
    //         }
    //     }
    // }, [is_guide]);

    // react router
    const history = useHistory();

    // Redux
    const dispatch = useDispatch();
    const user_info = useSelector((state) => state?.generalReducers?.user_info);
    const mfa = useSelector((state) => state?.generalReducers?.user_info?.mfa);
    const registered_clients = useSelector((state) => state?.implementerReducer?.registered_clients);
    const client_id = useSelector((state) => state?.implementerReducer?.client_id);

    // mui
    const theme = useTheme();

    // effect
    useEffect(() => {
        if (close_setup) {
            setCloseUp(false);
        }
    });

    // functions
    const showSwitch = (event) => {
        setOpenSwitch(event.currentTarget);
    };

    const hideSwitch = () => {
        setOpenSwitch(null);
    };
    const open = Boolean(openSwitch);
    const id = open ? 'simple-popover' : undefined;

    const handleMenuItemClick = (event, selected) => {
        dispatch(ClientId(selected?.id));
        history.push('/merchant/compliance/data-room');
        setOpenSwitch(null);
    };

    const top_routes = routes?.filter(
        (route) =>
            route?.name !== (language?.includes('fr') ? 'Intégrations' : 'Integrations') &&
            route?.name !== (language?.includes('fr') ? 'Paramètres' : 'Settings') &&
            route?.name !== (language?.includes('fr') ? 'Activités' : 'Activities')
    );
    const bottom_routes = routes?.filter(
        (route) =>
            route?.name === (language?.includes('fr') ? 'Intégrations' : 'Integrations') ||
            route?.name === (language?.includes('fr') ? 'Paramètres' : 'Settings') ||
            route?.name === (language?.includes('fr') ? 'Activités' : 'Activities')
    );

    // Async
    useEffect(() => {
        const getRegisteredClients = async () => {
            const res = await dispatch(GetRegisteredClients());
            if (!res?.status) {
                toast.error(res?.message);
            }
        };

        getRegisteredClients();
    }, []);

    const filteredClients = registered_clients?.filter((client) =>
        client.name.toLowerCase().includes(search.toLowerCase())
    );
    const current_merchant = filteredClients?.find((client) => client?.id === client_id);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Brand />
            {user_info?.user_type === 'implementer' && (
                <div
                    className="flex justify-between items-center bg-white rounded p-2 mx-2 cursor-pointer"
                    aria-describedby={id}
                    onClick={showSwitch}
                >
                    <div className="flex items-center gap-2">
                        <FiberManualRecordIcon
                            color="success"
                            sx={{ width: '10px', height: '10px', borderRadius: '30px' }}
                        />
                        <p className="text-[13px] text-[#395BA9] font-medium">
                            {current_merchant?.name.substring(0, 40)}
                            {current_merchant?.name?.length > 40 ? '...' : ''}
                        </p>
                    </div>
                    <img src={Selector} alt="Selector" />
                </div>
            )}
            <Stack direction="column" justifyContent="space-between" sx={{ height: 'calc(100vh - 70px)' }}>
                <List
                    sx={{
                        mr: 0.8,
                        // height: '100%',
                        height: 'calc(100vh - 108px)',
                        // overflow: 'hidden',
                        // mt: 'auto',
                        // overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary[100] + '30',
                            borderRadius: '10px',
                        },
                    }}
                >
                    {top_routes
                        .filter((res) => !res.subRoute)
                        .map((route) => {
                            if (route.subMenus) {
                                const activeChild = route.subMenus.find(
                                    (submenu) => location.pathname === submenu.layout + submenu.path
                                );
                                return (
                                    <SidebarListGroup
                                        key={route.name}
                                        subMenus={route.subMenus}
                                        active={activeChild}
                                        text={route.name}
                                        icon={<ListIcon Component={route?.icon} />}
                                        icon_active={<ListIcon Component={route?.icon_active || route?.icon} />}
                                        merchant_info={merchant_info}
                                        route={route}
                                        isNew={route.isNew}
                                        disabled={
                                            !mfa &&
                                            user_info?.user_type !== 'implementer' &&
                                            route.layout + route.path !== '/merchant/settings'
                                        }
                                        title={title}
                                        ref2={ref2}
                                    />
                                );
                            } else {
                                const isActive = location.pathname === route.layout + route.path;
                                return user_info?.role === 'user' &&
                                    route?.name === 'Risk Management' ? null : !route?.subRoute ? (
                                    <SidebarListItem
                                        active={isActive}
                                        icon={<ListIcon Component={route.icon} />}
                                        icon_active={<ListIcon Component={route?.icon_active || route.icon} />}
                                        text={route.name}
                                        link={route.layout + route.path}
                                        key={route.name + route.path}
                                        isNew={route.isNew}
                                        subMenus={route.subMenus}
                                        title={title}
                                        disabled={
                                            !mfa &&
                                            user_info?.user_type !== 'implementer' &&
                                            route.layout + route.path !== '/merchant/settings'
                                        }
                                    />
                                ) : null;
                            }
                        })}
                </List>
                <Box>
                    <List
                        sx={{
                            mr: 0.8,
                            // height: '200px',
                            // overflow: 'hidden',
                            // overflowY: 'auto',
                            mb: user_info?.complete_setup?.percentage === 100 ? 2 : 0,
                            '&::-webkit-scrollbar': {
                                width: '8px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'transparent',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.primary[100] + '30',
                                borderRadius: '10px',
                            },
                        }}
                    >
                        {bottom_routes
                            .filter((res) => !res.subRoute)
                            .map((route) => {
                                if (route.subMenus) {
                                    const activeChild = route.subMenus.find(
                                        (submenu) => location.pathname === submenu.layout + submenu.path
                                    );

                                    return (
                                        <SidebarListGroup
                                            key={route.name}
                                            subMenus={route.subMenus}
                                            active={activeChild}
                                            text={route.name}
                                            icon={<ListIcon Component={route.icon} />}
                                            merchant_info={merchant_info}
                                            route={route}
                                            isNew={route.isNew}
                                            title={title}
                                            disabled={!mfa && route.layout + route.path !== '/merchant/settings'}
                                            ref2={ref2}
                                        />
                                    );
                                } else if (route.divider) {
                                    return <SidebarDivider title={route.title} key={route.title} />;
                                } else {
                                    const isActive = location.pathname === route.layout + route.path;
                                    return (
                                        <SidebarListItem
                                            active={isActive}
                                            icon={<ListIcon Component={route.icon} />}
                                            icon_active={<ListIcon Component={route?.icon_active || route.icon} />}
                                            text={route.name}
                                            link={route.layout + route.path}
                                            key={route.name + route.path}
                                            isNew={route.isNew}
                                            subMenus={route.subMenus}
                                            title={title}
                                            disabled={!mfa && route.layout + route.path !== '/merchant/settings'}
                                        />
                                    );
                                }
                            })}
                    </List>
                    {user_info?.complete_setup?.percentage < 100 && (
                        <>
                            {user_info?.user_type !== 'auditor' && user_info?.user_type !== 'support' ? (
                                <>
                                    {closeSetup && (
                                        <div
                                            className={`px-3 pb-3 rounded ${
                                                title === 'Setup Task' ? 'z-index-10' : ''
                                            }`}
                                        >
                                            <>
                                                <SetupProgress setCloseUp={setCloseUp} />
                                                {/* <Menu
                                                    anchorEl={anchorEl}
                                                    // open={Boolean(anchorEl)}
                                                    open={is_guide}
                                                    onClose={handleClose}
                                                    // onMouseLeave={handleClose}
                                                    anchorOrigin={{
                                                        vertical: modal_data?.position ? modal_data?.position : 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: modal_data?.position ? modal_data?.position : 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    sx={{
                                                        background: 'rgba(0, 0, 0, 0.2)',
                                                        '& .MuiMenu-paper': {
                                                            width: 'auto',
                                                            ml: 0.5,
                                                            p: 0,
                                                            backgroundColor: 'transparent !important',
                                                            boxShadow: 'none !important',
                                                            '& ul': { p: 0 },
                                                        },
                                                        // display: 'flex',
                                                    }}
                                                    MenuListProps={{ onMouseLeave: !clicked ? handleClose : '' }}
                                                >
                                                    <UserGuideModal
                                                        corner={modal_data?.position === 'bottom' ? 'mb-3' : 'mt-3'}
                                                        align={modal_data?.position === 'bottom' ? 'end' : 'start'}
                                                    />
                                                </Menu> */}
                                            </>
                                        </div>
                                    )}
                                </>
                            ) : null}
                        </>
                    )}
                </Box>
            </Stack>
            <Popover
                id={id}
                open={open}
                anchorEl={openSwitch}
                onClose={hideSwitch}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        width: 250,
                        minHeight: 100,
                        maxHeight: 400,
                        overflow: 'scroll',
                        padding: '10px',
                    },
                }}
            >
                <Typography sx={{ color: theme.palette.neutral[500], fontSize: '13px', fontWeight: 500, mb: 1 }}>
                    Switch to client:
                </Typography>
                <div className="relative">
                    <input
                        value={search}
                        onChange={(event) => setSearch(event?.target?.value)}
                        className="border border-[#E2E8F0] rounded pl-4 text-[#94A3B8] text-sm font-normal w-full py-1 outline-none"
                        placeholder="search"
                    />
                    <img src={SearchIcon} alt="SearchIcon" className="absolute top-2 left-1" />
                </div>
                {filteredClients?.length ? (
                    <div className="my-2">
                        {filteredClients?.map((client) => (
                            <MenuItem
                                key={client.id}
                                sx={{
                                    color: '#475569',
                                    fontWeight: 'medium',
                                    fontSize: '14px',
                                    mx: 0,
                                    py: 0.5,
                                    my: 0.5,
                                    '&:hover': {
                                        backgroundColor: '#F1F5F9',
                                    },
                                }}
                                onClick={(event) => handleMenuItemClick(event, client)}
                            >
                                {client.name.substring(0, 40)}
                                {client.name?.length > 40 ? '...' : ''}
                            </MenuItem>
                        ))}
                    </div>
                ) : (
                    <p className="flex justify-center items-center my-3 text-[#94A3B8] text-sm font-normal">
                        No Client found
                    </p>
                )}
            </Popover>
        </Box>
    );
};

const SetupProgress = (props) => {
    const { setCloseUp } = props;
    const user_info = useSelector((state) => state?.generalReducers?.user_info);
    const location = useLocation();
    const theme = useTheme();
    return (
        <Box
            sx={{
                pr: 1,
                pl: 2,
                py: 2,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#2D3F8F',
                width: '100%',
                borderRadius: '4px',
                marginTop: 0.5,
            }}
            id="setup_progress"
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
                <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    onClick={() => {
                        if (localStorage.getItem('setup_complete')) {
                            localStorage.removeItem('setup_complete');
                        }
                        if (location.pathname === '/merchant/index') {
                            window.location.reload();
                        } else {
                            window.location = '/merchant/index';
                        }
                    }}
                    sx={{ cursor: 'pointer' }}
                >
                    <img className="w-[28px] h-[28px] pt-1" src={Hello} alt="Hello" />
                    <Typography
                        sx={{ color: theme.palette.white.main, fontSize: 12, fontWeight: 500, letterSpacing: '0.1px' }}
                    >
                        Complete your setup
                    </Typography>
                </Stack>

                <IconButton
                    sx={{
                        backgroundColor: 'transparent',
                        color: theme.palette.white.main,
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }}
                    onClick={() => {
                        setCloseUp(false);
                        localStorage.setItem('close_setup', true);
                    }}
                >
                    <Close sx={{ fontSize: 14 }} />
                </IconButton>
            </Stack>
            <Box
                sx={{ mt: 2, width: '80%', pl: 1.5, cursor: 'pointer' }}
                onClick={() => {
                    if (localStorage.getItem('setup_complete')) {
                        localStorage.removeItem('setup_complete');
                    }
                    if (location.pathname === '/merchant/index') {
                        window.location.reload();
                    } else {
                        window.location = '/merchant/index';
                    }
                }}
            >
                <AppProgressBar
                    sx={{
                        '&.MuiLinearProgress-colorPrimary': {
                            backgroundColor: theme.palette.primary.lightNew,
                        },
                    }}
                    value={user_info?.complete_setup?.percentage}
                    color="primary"
                />
                <Typography sx={{ fontSize: 11, mt: 1, color: theme.palette.white.main }}>
                    <span>{user_info?.complete_setup?.percentage}%</span> done
                </Typography>
            </Box>
        </Box>
    );
};

export default SidebarContent;
