import { Dialog, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
// import moment from 'moment';

export default function AddReviewPeriod({ setContinousMonitoringFrequency, continuous_monitoring_frequency }) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [formData, setFormData] = useState({
        firstReviewData: 'Every',
        secondReviewData: '',
        thirdReviewData: '',
        comment: '',
    });
    const [firstReviewDataAnchorEl, setFirstReviewDataAnchorEl] = useState(null);

    function RenderSecondReview() {
        switch (formData.firstReviewData) {
            case 'Daily':
                return <DailySecondReview formData={formData} setFormData={setFormData} />;
            case 'Weekly':
                return <WeeklySecondReview formData={formData} setFormData={setFormData} />;
            case 'Annually':
                return <AnnuallySecondReview formData={formData} setFormData={setFormData} />;
            case 'Every':
                return <EverySecondReview formData={formData} setFormData={setFormData} />;
            case 'Quarterly':
                return <QuarterlySecondReview formData={formData} setFormData={setFormData} />;
            default:
                return <EverySecondReview formData={formData} setFormData={setFormData} />;
        }
    }

    function RenderThirdReview() {
        switch (formData.firstReviewData) {
            case 'Weekly':
                return <WeeklyThirdReview formData={formData} setFormData={setFormData} />;
            case 'Annually':
                return <AnnuallyThirdReview formData={formData} setFormData={setFormData} />;
            case 'Every':
                return <EveryThirdReview formData={formData} setFormData={setFormData} />;
            default:
                return <EveryThirdReview formData={formData} setFormData={setFormData} />;
        }
    }

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            secondReviewData: '',
            thirdReviewData: '',
        }));
    }, [formData.firstReviewData]);

    return (
        <>
            {!continuous_monitoring_frequency?.frequency && (
                <button
                    className="flex items-center gap-1 transition-all hover:brightness-90"
                    onClick={() => setIsDialogOpen(true)}
                >
                    <img
                        src="/img/risk-assessment/PlusCircle-Review-Period.svg"
                        alt="Plus Icon"
                        className="object-contain"
                    />

                    <p className="font-medium text-sm text-[#395BA9]">Click to add review period</p>
                </button>
            )}
            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                PaperProps={{
                    sx: {
                        borderRadius: '4px !important',
                        padding: 0,
                        boxShadow: '0px 16px 24px 0px #00000026',
                        width: '511px',
                    },
                }}
            >
                <div className="flex items-center justify-between px-6 !pt-6">
                    <h5 className="text-lg font-semibold text-[#202D66]">Add review period</h5>

                    <button
                        className="border-[#E2E8F0] border rounded-full !p-1 h-6 w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                        onClick={() => setIsDialogOpen(false)}
                    >
                        <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                    </button>
                </div>

                <div className="!p-6 flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <p className="font-medium text-[13px] text-[#64748B]">Review period</p>

                        <div className="flex gap-2">
                            <div className="flex-1">
                                <button
                                    aria-controls={firstReviewDataAnchorEl ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={firstReviewDataAnchorEl ? 'true' : undefined}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setFirstReviewDataAnchorEl(event.currentTarget);
                                    }}
                                    className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                                >
                                    <p
                                        className={`font-normal text-sm ${
                                            formData.firstReviewData
                                                ? 'capitalize text-[hsl(215,20%,40%)]'
                                                : 'text-[#94A3B8]'
                                        }`}
                                    >
                                        {formData.firstReviewData ? formData.firstReviewData : 'Select frequency'}
                                    </p>

                                    <img
                                        src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                        alt="Arrow Down Icon"
                                        className="object-contain"
                                    />
                                </button>

                                <Menu
                                    anchorEl={firstReviewDataAnchorEl}
                                    open={Boolean(firstReviewDataAnchorEl)}
                                    onClose={() => setFirstReviewDataAnchorEl(null)}
                                    sx={{
                                        '& .MuiPaper-root': {
                                            borderRadius: '4px',
                                            boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                        },
                                        '& .MuiList-root': {
                                            padding: 0,
                                        },
                                    }}
                                >
                                    <div className="flex flex-col min-w-[149px]">
                                        {['Daily', 'Weekly', 'Annually', 'Every', 'Quarterly'].map(
                                            (duration, index) => (
                                                <MenuItem
                                                    key={index}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setFormData((prev) => ({
                                                            ...prev,
                                                            firstReviewData: duration,
                                                        }));
                                                        setFirstReviewDataAnchorEl(null);
                                                    }}
                                                    sx={{
                                                        color: '#64748B',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        textTransform: 'capitalize',
                                                        padding: '8px 16px',
                                                    }}
                                                >
                                                    {duration}
                                                </MenuItem>
                                            )
                                        )}
                                    </div>
                                </Menu>
                            </div>

                            <div className="flex-1">
                                <RenderSecondReview />
                            </div>

                            <div
                                className="flex-1"
                                hidden={
                                    formData.firstReviewData === 'Daily' || formData.firstReviewData === 'Quarterly'
                                }
                            >
                                <RenderThirdReview />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-end !p-6 bg-[#F8FAFC] gap-2">
                    <button
                        className="bg-[#fff] border border-[#E2E8F0] rounded-sm !px-4 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                        onClick={() => setIsDialogOpen(false)}
                    >
                        Cancel
                    </button>

                    <button
                        className="bg-[#202D66] rounded-sm !px-4 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none"
                        disabled={!formData.firstReviewData || !formData.secondReviewData}
                        onClick={() => {
                            if (formData.firstReviewData === 'Every') {
                                setContinousMonitoringFrequency((prev) => ({
                                    ...prev,
                                    frequency: formData.firstReviewData,
                                    day_of_week: '',
                                    number_of_units: formData?.secondReviewData,
                                    day_of_month: '',
                                    month: '',
                                    time: '',
                                    unit: formData?.thirdReviewData,
                                }));
                            } else if (formData.firstReviewData === 'Weekly') {
                                setContinousMonitoringFrequency((prev) => ({
                                    ...prev,
                                    frequency: formData.firstReviewData,
                                    day_of_week: formData?.secondReviewData,
                                    number_of_units: '',
                                    day_of_month: '',
                                    month: '',
                                    time: formData?.thirdReviewData,
                                    unit: '',
                                }));
                            } else if (formData.firstReviewData === 'Annually') {
                                setContinousMonitoringFrequency((prev) => ({
                                    ...prev,
                                    frequency: formData.firstReviewData,
                                    day_of_month: formData?.thirdReviewData,
                                    month: formData?.secondReviewData,
                                }));
                            } else if (formData.firstReviewData === 'Daily') {
                                setContinousMonitoringFrequency((prev) => ({
                                    ...prev,
                                    frequency: formData.firstReviewData,
                                    time: formData?.secondReviewData,
                                }));
                            } else if (formData.firstReviewData === 'Quarterly') {
                                setContinousMonitoringFrequency((prev) => ({
                                    ...prev,
                                    frequency: formData.firstReviewData,
                                }));
                            }

                            setIsDialogOpen(false);
                        }}
                    >
                        Save
                    </button>
                </div>
            </Dialog>
        </>
    );
}

function DailySecondReview({ formData, setFormData }) {
    const [localTime, setLocalTime] = useState(formData.secondReviewData);

    return (
        <input
            type="time"
            placeholder="Enter time"
            value={localTime}
            onChange={(event) => {
                setLocalTime(event.target.value);
            }}
            onBlur={(event) => {
                setFormData((prev) => ({
                    ...prev,
                    secondReviewData: event.target.value,
                }));
            }}
            className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-within:ring-2 focus-within:ring-[#395BA9] focus-within:ring-opacity-75"
        />
    );
}

function WeeklySecondReview({ formData, setFormData }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const localeDays = dayjs.extend(localeData).weekdays() || [];

    return (
        <>
            <button
                aria-controls={anchorEl ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
                onClick={(event) => {
                    event.stopPropagation();
                    setAnchorEl(event.currentTarget);
                }}
                className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
            >
                <p
                    className={`font-normal text-sm ${
                        formData.secondReviewData ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                    }`}
                >
                    {formData.secondReviewData ? formData.secondReviewData : 'Select'}
                </p>

                <img
                    src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                    alt="Arrow Down Icon"
                    className="object-contain"
                />
            </button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '4px',
                        boxShadow: '0px 11.76px 22.06px 0px #00000014',
                    },
                    '& .MuiList-root': {
                        padding: 0,
                    },
                }}
            >
                <div className="flex flex-col min-w-[149px]">
                    {localeDays.map((duration, index) => (
                        <MenuItem
                            key={index}
                            onClick={(event) => {
                                event.stopPropagation();
                                setFormData((prev) => ({
                                    ...prev,
                                    secondReviewData: duration,
                                }));
                                setAnchorEl(null);
                            }}
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                padding: '8px 16px',
                            }}
                        >
                            {duration}
                        </MenuItem>
                    ))}
                </div>
            </Menu>
        </>
    );
}

function WeeklyThirdReview({ formData, setFormData }) {
    const [localTime, setLocalTime] = useState(formData.thirdReviewData);

    return (
        <input
            type="time"
            placeholder="Enter time"
            value={localTime}
            onChange={(event) => {
                setLocalTime(event.target.value);
            }}
            onBlur={(event) => {
                setFormData((prev) => ({
                    ...prev,
                    thirdReviewData: event.target.value,
                }));
            }}
            className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-within:ring-2 focus-within:ring-[#395BA9] focus-within:ring-opacity-75"
        />
    );
}

function AnnuallySecondReview({ formData, setFormData }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const localeMonths = dayjs.extend(localeData).months() || [];

    return (
        <>
            <button
                aria-controls={anchorEl ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
                onClick={(event) => {
                    event.stopPropagation();
                    setAnchorEl(event.currentTarget);
                }}
                className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
            >
                <p
                    className={`font-normal text-sm ${
                        formData.secondReviewData ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                    }`}
                >
                    {formData.secondReviewData ? formData.secondReviewData : 'Select'}
                </p>

                <img
                    src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                    alt="Arrow Down Icon"
                    className="object-contain"
                />
            </button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '4px',
                        boxShadow: '0px 11.76px 22.06px 0px #00000014',
                    },
                    '& .MuiList-root': {
                        padding: 0,
                    },
                }}
            >
                <div className="flex flex-col min-w-[149px] max-h-[259px] overflow-y-auto">
                    {localeMonths.map((duration, index) => (
                        <MenuItem
                            key={index}
                            onClick={(event) => {
                                event.stopPropagation();
                                setFormData((prev) => ({
                                    ...prev,
                                    secondReviewData: duration,
                                }));
                                setAnchorEl(null);
                            }}
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                padding: '8px 16px',
                            }}
                        >
                            {duration}
                        </MenuItem>
                    ))}
                </div>
            </Menu>
        </>
    );
}

function AnnuallyThirdReview({ formData, setFormData }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const localeMonthNumber = formData.secondReviewData
        ? dayjs.extend(localeData).months().indexOf(formData.secondReviewData) + 1
        : 1;

    const localeDays = new Array(
        dayjs(`${dayjs().year()}-${localeMonthNumber ? localeMonthNumber : 1}`).daysInMonth()
    ).fill(0);

    return (
        <>
            <button
                aria-controls={anchorEl ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
                onClick={(event) => {
                    event.stopPropagation();
                    setAnchorEl(event.currentTarget);
                }}
                className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
            >
                <p
                    className={`font-normal text-sm ${
                        formData.thirdReviewData ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                    }`}
                >
                    {formData.thirdReviewData ? formData.thirdReviewData : 'Select'}
                </p>

                <img
                    src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                    alt="Arrow Down Icon"
                    className="object-contain"
                />
            </button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '4px',
                        boxShadow: '0px 11.76px 22.06px 0px #00000014',
                    },
                    '& .MuiList-root': {
                        padding: 0,
                    },
                }}
            >
                <div className="flex flex-col min-w-[149px] max-h-[259px] overflow-y-auto">
                    {localeDays.map((_, index) => (
                        <MenuItem
                            key={index}
                            onClick={(event) => {
                                event.stopPropagation();
                                setFormData((prev) => ({
                                    ...prev,
                                    thirdReviewData: index + 1,
                                }));
                                setAnchorEl(null);
                            }}
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                padding: '8px 16px',
                            }}
                        >
                            {index + 1}
                        </MenuItem>
                    ))}
                </div>
            </Menu>
        </>
    );
}

function EverySecondReview({ formData, setFormData }) {
    return (
        <input
            type="number"
            inputMode="numeric"
            placeholder="Enter timeline"
            min={0}
            value={formData.secondReviewData}
            onChange={(event) => {
                setFormData((prev) => ({
                    ...prev,
                    secondReviewData: event.target.value,
                }));
            }}
            className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
        />
    );
}

function EveryThirdReview({ formData, setFormData }) {
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <>
            <button
                aria-controls={anchorEl ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
                onClick={(event) => {
                    event.stopPropagation();
                    setAnchorEl(event.currentTarget);
                }}
                className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
            >
                <p
                    className={`font-normal text-sm ${
                        formData.thirdReviewData ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                    }`}
                >
                    {formData.thirdReviewData ? formData.thirdReviewData : 'Select'}
                </p>

                <img
                    src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                    alt="Arrow Down Icon"
                    className="object-contain"
                />
            </button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '4px',
                        boxShadow: '0px 11.76px 22.06px 0px #00000014',
                    },
                    '& .MuiList-root': {
                        padding: 0,
                    },
                }}
            >
                <div className="flex flex-col min-w-[149px]">
                    {['Days', 'Weeks', 'Months', 'Years'].map((duration, index) => (
                        <MenuItem
                            key={index}
                            onClick={(event) => {
                                event.stopPropagation();
                                setFormData((prev) => ({
                                    ...prev,
                                    thirdReviewData: duration,
                                }));
                                setAnchorEl(null);
                            }}
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                padding: '8px 16px',
                            }}
                        >
                            {duration}
                        </MenuItem>
                    ))}
                </div>
            </Menu>
        </>
    );
}

function QuarterlySecondReview({ formData, setFormData }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const localeMonths = ['Jan - Mar', 'Apr - Jun', 'Jul - Sep', 'Oct - Dec'];

    return (
        <>
            <button
                aria-controls={anchorEl ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
                onClick={(event) => {
                    event.stopPropagation();
                    setAnchorEl(event.currentTarget);
                }}
                className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
            >
                <p
                    className={`font-normal text-sm ${
                        formData.secondReviewData ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                    }`}
                >
                    {formData.secondReviewData ? formData.secondReviewData : 'Select'}
                </p>

                <img
                    src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                    alt="Arrow Down Icon"
                    className="object-contain"
                />
            </button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '4px',
                        boxShadow: '0px 11.76px 22.06px 0px #00000014',
                    },
                    '& .MuiList-root': {
                        padding: 0,
                    },
                }}
            >
                <div className="flex flex-col min-w-[149px] max-h-[259px] overflow-y-auto">
                    {localeMonths.map((duration, index) => (
                        <MenuItem
                            key={index}
                            onClick={(event) => {
                                event.stopPropagation();
                                setFormData((prev) => ({
                                    ...prev,
                                    secondReviewData: duration,
                                }));
                                setAnchorEl(null);
                            }}
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                padding: '8px 16px',
                            }}
                        >
                            {duration}
                        </MenuItem>
                    ))}
                </div>
            </Menu>
        </>
    );
}
