import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const SidebarDivider = (props) => {
    const theme = useTheme();
    const { title } = props;
    return (
        <Box
            sx={{
                textTransform: 'uppercase',
                mt: 4,
                mb: 1,
                p: 1,
                pl: 3,
                color: theme.palette.white.main,
                backgroundColor: theme.palette.primary[500],
                fontWeight: 500,
                fontSize: '13.5px',
            }}
        >
            {title}
        </Box>
    );
};

export default SidebarDivider;
