import { validation } from '../../../../validate';

export const integrationValidate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
        const valid = validation(values[key], key?.replace(/_/g, ' '), true);
        if (!valid.isValid) {
            errors[key] = valid.errorMessage;
        }
    });
    return errors;
};

export const requestValidation = (values) => {
    const errors = {};
    const nameValidation = validation(values.integration_name, 'Integration Name', true);

    if (!nameValidation.isValid) {
        errors.integration_name = nameValidation.errorMessage;
    }

    return errors;
};

export const requestToolValidation = (values) => {
    const errors = {};
    const nameValidation = validation(values.name, 'Integration Name', true);
    const categoryValidation = validation(values.category, 'Integration Category', true);

    if (!nameValidation.isValid) {
        errors.name = nameValidation.errorMessage;
    }
    if (!nameValidation.isValid) {
        errors.category = categoryValidation.errorMessage;
    }

    return errors;
};
