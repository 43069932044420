import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

//redux
import { connect } from 'react-redux';

//translation
import { useTranslation } from 'react-i18next';

const ConnectButton = (props) => {
    const theme = useTheme();
    const { connected, handleConnect, mfa, mfa_type } = props;

    const { t } = useTranslation('accounts');
    return (
        <Box>
            {!connected ? (
                <Button
                    sx={{
                        py: 1,
                        px: 3,
                        fontSize: 15,
                        fontWeight: 600,
                        borderRadius: 1,
                        textTransform: 'upperCase',
                        color: theme.palette.white.main,
                        boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.1)',
                        '&, &:hover': {
                            backgroundColor: theme.palette.primary.main,
                        },
                    }}
                    variant="contained"
                    onClick={handleConnect}
                    disabled={!mfa && mfa_type}
                >
                    {t('twoFactorAuth.connect')}
                </Button>
            ) : null}
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        mfa: state?.generalReducers?.user_info?.mfa,
    };
};
export default connect(mapStateToProps, {})(ConnectButton);
