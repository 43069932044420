import { validation } from 'validate';

export const runScanValidation = (values) => {
    const errors = {};
    const urlValidate = validation(values.url, 'Url', true);
    const tokenValidate = validation(values.token, 'Token', true);

    if (!urlValidate.isValid) {
        errors.url = urlValidate.errorMessage;
    }

    if (!tokenValidate.isValid) {
        errors.token = tokenValidate.errorMessage;
    }

    return errors;
};
