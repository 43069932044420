import React from 'react';
import { styled } from '@mui/material/styles';
import { ExpandMore } from '@mui/icons-material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';
import SubgroupChip from '../SubgroupChip';
import { useTheme } from '@mui/material/styles';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.gray[300]}`,
    margin: '0.3rem 0',
    borderRadius: '4px',
    overflow: 'hidden',
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMore sx={{ fontSize: 24 }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.white.main,
    transition: 'background-color 0.85s, color 0.85s',
    '& .title': {
        color: theme.palette.primary[900],
    },
    '& .subtitle': {
        color: theme.palette.gray[600],
    },
    '&.Mui-expanded': {
        backgroundColor: theme.palette.primary[700],
        '& .title': {
            color: theme.palette.white.main,
        },
        '& .subtitle': {
            color: theme.palette.white.main,
        },
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: theme.palette.gray[900],
        '&.Mui-expanded': {
            transform: 'rotate(-180deg)',
            color: theme.palette.white.main,
        },
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    padding: '0.5rem 0',
    color: theme.palette.gray[400],
}));

const ExceptionSubgroupAccordion = (props) => {
    const { active, handleChange, id, handleSubgroupChange, group, activeSubgroup, subgroupInfo } = props;

    return (
        <Accordion expanded={active === id} onChange={handleChange(id)}>
            <AccordionSummary>
                <Stack sx={{ width: '95%' }}>
                    <Typography
                        variant="h4"
                        component="h4"
                        sx={{ fontSize: 16, fontWeight: 500, mb: 0 }}
                        className="title"
                    >
                        {group?.title}
                    </Typography>
                    <Typography
                        variant="span"
                        component="span"
                        sx={{ fontSize: 13, fontWeight: 500 }}
                        className="subtitle"
                    >
                        {group?.description}
                    </Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: 14,
                        p: 1,
                    }}
                >
                    {subgroupInfo}
                </Typography>
                <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                    {group?.subgroups?.map((subgroup) => (
                        <ExceptionSubgroupChip
                            label={subgroup?.label}
                            id={subgroup?.id}
                            onClick={handleSubgroupChange}
                            active={activeSubgroup === subgroup?.id}
                            key={subgroup?.id}
                        />
                    ))}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};

const ExceptionSubgroupChip = (props) => {
    const { label, id, active, onClick } = props;
    const theme = useTheme();
    return (
        <Box sx={{ margin: 1 }}>
            <SubgroupChip
                label={label}
                id={id}
                onClick={onClick}
                active={active}
                showCheck={false}
                bordered={true}
                sx={{
                    typography: { color: theme.palette.primary[900] },
                    root: {
                        '&, &:hover': {
                            backgroundColor: active ? theme.palette.secondary[300] : theme.palette.white.main,
                        },
                    },
                }}
            />
        </Box>
    );
};

export default ExceptionSubgroupAccordion;
