import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Modal, Row, Spin, Typography, Tooltip } from 'antd';
import BreadCrumb from 'components/Breadcrumb';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// redux
import { connect } from 'react-redux';
import { GetAllSector, DeleteSector } from 'store/actions/adminActions';
import { toast } from 'react-toastify';
import SectorModal from '../components/SectorModal';

// sub-components
const { Title } = Typography;
const { confirm } = Modal;

const Sector = (props) => {
    const { GetAllSector, all_sector, DeleteSector } = props;

    // hooks
    const history = useHistory();

    // state
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(null);
    const [modalData, setModalData] = useState({});

    // functions
    const goToSubsector = (id) => {
        history.push({ pathname: '/admin/cii-directive/subsector', state: { sector: id } });
    };
    const openSectorModal = (mode, data = {}) => {
        setModal(mode);
        setModalData(data);
    };
    const closeSectorModal = () => setModal(null);

    // async functions
    const getAllSector = async () => {
        setLoading(true);
        const res = await GetAllSector();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const deleteCriterion = (id) => {
        confirm({
            title: "Are you sure you want to delete this sector? Note: Deleting this sector means that you are deleting this sector, subsector and whatsover associated with sector alongside merchant's data",
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteSector(id, false);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    // useEffect
    useEffect(() => {
        getAllSector();
    }, []);

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Cii Directive' }]} />
                </Col>
                {all_sector?.length < 4 && (
                    <Col>
                        <Button
                            type="primary"
                            size="large"
                            icon={<PlusOutlined />}
                            className="flex justify-between items-center"
                            onClick={() => openSectorModal('Add')}
                        >
                            Add Sector
                        </Button>
                    </Col>
                )}
            </Row>
            <section className="my-3">
                {loading ? (
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                ) : (
                    <>
                        {all_sector && (
                            <Row wrap gutter={24}>
                                {all_sector
                                    ?.sort((a, b) => a?.id - b?.id)
                                    .map((criterion) => {
                                        return (
                                            <Col xs={24} md={12} lg={8} xxl={6} key={criterion.id} className="mb-4">
                                                <Card
                                                    title={criterion.name}
                                                    actions={[
                                                        <Tooltip title="View this Sector" color="blue">
                                                            <Button
                                                                type="link"
                                                                onClick={() => goToSubsector(criterion?.id)}
                                                                icon={<EyeOutlined key="open" />}
                                                            />
                                                        </Tooltip>,
                                                        <Tooltip title="Edit this Sector" color="blue">
                                                            <Button
                                                                type="link"
                                                                icon={<EditOutlined />}
                                                                onClick={() => openSectorModal('Edit', criterion)}
                                                            />
                                                        </Tooltip>,
                                                        <Tooltip title="Delete this Sector" color="red">
                                                            <Button
                                                                type="link"
                                                                icon={<DeleteOutlined />}
                                                                danger
                                                                onClick={() => deleteCriterion(criterion?.id)}
                                                            />
                                                        </Tooltip>,
                                                    ]}
                                                >
                                                    <Title level={5}> {criterion.description}</Title>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        )}
                    </>
                )}
            </section>
            <SectorModal open={modal} modalData={modalData} onClose={closeSectorModal} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    all_sector: state?.adminReducers?.all_sector,
});

export default connect(mapStateToProps, { GetAllSector, DeleteSector })(Sector);
