import React from 'react';
import { stepQuestionList_report, stepQuestionList_reportFr } from '../../../utils/constants';
import HeightConnector from 'assets/img/heightConnector.png';
import HeightConnectorDisabled from 'assets/img/heightConnectorDisabled.png';
import { CheckCircle } from '@mui/icons-material';

const StepQuestion = (props) => {
    const { currentStep } = props;

    //translation
    const language = localStorage.getItem('i18nextLng');

    //function
    const stepQuestion = language?.includes('fr') ? stepQuestionList_reportFr : stepQuestionList_report;
    return (
        <div className="py-4 overflow-y-auto">
            <ol className="relative text-[#0E2C66]">
                {stepQuestion?.map((item) => {
                    // const isPrevious = currentStep === item.id;
                    // const answer = answers?.find((answer) => answer?.category === item?.id);
                    return (
                        <React.Fragment key={item.id}>
                            {item.id > 1 && (
                                <React.Fragment>
                                    {currentStep === item?.id ? (
                                        <img src={HeightConnector} className="ml-3" />
                                    ) : (
                                        <img src={HeightConnectorDisabled} className="ml-3" />
                                    )}
                                </React.Fragment>
                            )}
                            <button
                                className="flex items-center text-[#C7C6CA] text-left"
                                // disabled={!answer && !item?.isDone}
                            >
                                <div className="d-block">
                                    <div
                                        className={`w-10 h-10 rounded-full border-2 ${
                                            currentStep === item?.id
                                                ? 'border-[#0E2C66]'
                                                : currentStep > item?.id
                                                ? 'border-[#0E2C66]'
                                                : 'border-[#C7C6CA]'
                                        } flex items-center justify-center`}
                                    >
                                        {currentStep > item?.id ? (
                                            <CheckCircle
                                                color={currentStep > item?.id ? 'primary' : 'disabled'}
                                                sx={{ width: '15px', height: '15px' }}
                                            />
                                        ) : (
                                            <span
                                                className={`font-[600] text-[14px] ${
                                                    currentStep === item?.id ? 'text-[#0E2C66]' : 'text-[#C7C6CA]'
                                                }`}
                                            >
                                                {item?.id}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <h3
                                    className={`font-[600] leading-tight text-[14px] pl-3 ${
                                        currentStep === item?.id ? 'text-[#0E2C66]' : 'text-[#C7C6CA]'
                                    }`}
                                >
                                    {item.name}
                                </h3>
                            </button>
                        </React.Fragment>
                    );
                })}
            </ol>
        </div>
    );
};

export default StepQuestion;
