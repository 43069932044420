import React from 'react';
import { FormHelperText } from '@mui/material';
// formik
import { useFormikContext } from 'formik';
// core components
import AppInput from '../AppInput';

import { formatSentence } from 'utils';

const AppFormTagInput = (props) => {
    const { name, regex, multiline, adornment, ...restProps } = props;

    // get the formik context values
    const { setFieldTouched, handleChange, touched, values, errors } = useFormikContext();

    // const handleKeyPress = (e) => {
    //     if (e.charCode !== 13 || multiline) return;
    // handleSubmit();
    // };
    const handleInputChange = (e) => {
        if (!regex || regex?.test(e.target.value)) {
            handleChange(name)(e);
            setTimeout(() => setFieldTouched(name), 400);
        }
    };

    return (
        <>
            <AppInput
                value={values[name] || ''}
                onChange={handleInputChange}
                onBlur={() => setFieldTouched(name)}
                error={touched[name] && errors[name]}
                // onKeyPress={handleKeyPress}
                multiline={multiline}
                adornment={adornment}
                {...restProps}
            />
            {touched[name] && errors[name] && <FormHelperText error>{formatSentence(errors[name])}</FormHelperText>}
        </>
    );
};

export default AppFormTagInput;
