import React, { useEffect, useState, useMemo } from 'react';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { useTheme } from '@mui/styles';
import { Close } from '@mui/icons-material';
import {
    Typography,
    IconButton,
    Box,
    Chip,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormHelperText,
} from '@mui/material';
import AppSelectInput from 'components/new_components/AppSelectInput';

//redux
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { GetMerchantPeople } from 'store/actions/merchantActions';
import AppButton from 'components/new_components/AppButton';

const EmployeeSelect = (props) => {
    const { open, handleClose, GetMerchantPeople, all_employees, notifyList, handleNotifyListSelect, setNotifyList } =
        props;

    const [fetching, setFetching] = useState(false);
    const [notifyAll, setNotifyAll] = useState(false);
    const [showError, setShowError] = useState(false);

    // const { setFieldValue } = useFormikContext();

    const theme = useTheme();
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!notifyList?.length) {
            setShowError(true);
        } else {
            setShowError(false);
            handleClose();
        }
        console.log(notifyList);
    };

    const notifyAllCheck = (e) => {
        setNotifyAll(e.target.checked);
        if (e.target.checked) {
            let all_employeeArr = employees?.map((emp) => {
                return emp?.value;
            });
            setNotifyList(all_employeeArr);
        }
    };
    // memos
    const employees = useMemo(() => {
        return all_employees?.map((employee) => ({
            name: `${employee?.first_name} ${employee?.last_name}`,
            value: employee?.id,
        }));
    }, [all_employees]);
    useEffect(() => {
        const getAllEmployees = async () => {
            setFetching(true);
            const res = await GetMerchantPeople();
            setFetching(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllEmployees();
    }, []);

    useEffect(() => {
        if (!notifyAll) {
            setNotifyList([]);
        }
    }, [notifyAll]);

    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            title={
                <Typography sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.gray[30] }}>
                    Employees to be informed
                </Typography>
            }
            headerAction={
                <IconButton color="disabled" onClick={handleClose}>
                    <Close />
                </IconButton>
            }
            titleStyle={{ mx: 3, px: 0, borderBottom: `1px solid ${theme.palette.neutral[95]}` }}
            width={540}
        >
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox checked={notifyAll} size="small" onChange={notifyAllCheck} />}
                    sx={{
                        '& .MuiTypography-root ': {
                            fontSize: 12,
                            fontWeight: 500,
                            color: theme.palette.gray[40],
                        },
                    }}
                    label="All employees should be informed"
                />
            </FormGroup>
            <form onSubmit={handleSubmit}>
                <FormControl error={showError} variant="standard" sx={{ width: '100%' }}>
                    <AppSelectInput
                        label="Employees"
                        options={employees}
                        name="notify_list"
                        loading={fetching}
                        onChange={handleNotifyListSelect}
                        noDataLabel="No employees avaialable"
                        value={notifyList}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.2 }}>
                                {selected?.map((value) => {
                                    const employee = employees?.find((employee) => employee?.value == value);
                                    return (
                                        <Chip
                                            sx={{
                                                backgroundColor: theme.palette.gray[95],
                                                borderRadius: 1,
                                                p: 0.5,
                                                color: theme.palette.gray[40],
                                                fontSize: 12,
                                                fontWeight: 500,
                                            }}
                                            key={value}
                                            label={employee?.name}
                                        />
                                    );
                                })}
                            </Box>
                        )}
                        multiple
                    />
                    {showError ? <FormHelperText>Please Select an employee</FormHelperText> : null}
                </FormControl>
                <AppButton
                    name="Change"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, width: '100%', mb: 4 }}
                    type="submit"
                />
            </form>
        </AppCenteredModal>
    );
};

const mapStateToProps = (state) => {
    return {
        all_employees: state?.merchantReducers?.all_merchant_people,
    };
};
export default connect(mapStateToProps, { GetMerchantPeople })(EmployeeSelect);
