import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Card, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';

import ConnectButton from './ConnectButton';
import DisconnectModal from './DisconnectModal';
import ScopeViewModal from './ScopeViewModal';
import ConnectWithModal from './ConnectWith';

//translations
import { useTranslation } from 'react-i18next';
import { MoreVert } from '@mui/icons-material';
import { getType } from 'utils';

const IntegrationCard = (props) => {
    const theme = useTheme();
    const { integration, groupName, setSelected, environments } = props;

    // state
    const [disconnectModal, setDisconnectModal] = useState(false);
    const [scopeModal, setScopeModal] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const [connectModal, setConnectModal] = useState(false);
    const role = getType('role');

    //translation
    const { t } = useTranslation('setups');
    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);
    const openConnectModal = () => {
        setConnectModal(true);
        closeMenu();
    };
    const closeConnectModal = () => setConnectModal(false);

    const openDisconnectModal = () => setDisconnectModal(true);
    const closeDisconnectModal = () => setDisconnectModal(false);
    const openScopeModal = () => {
        setScopeModal(true);
        closeMenu();
    };
    const closeScopeModal = () => setScopeModal(false);
    // const oauth_types = ['gitlab', 'heroku', 'digitalocean', 'github', 'gcp', 'sage', 'jira', 'slack'];
    return (
        <Box component={Card} variant="outlined" sx={{ borderRadius: 4, height: '100%', position: 'relative' }}>
            <Stack alignItems="center" justifyContent="space-between" sx={{ py: 1, px: 2.5, height: '100%' }}>
                <Box
                    sx={{
                        height: 65,
                        maxWidth: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <img
                        style={{ width: 'auto', height: 'auto', maxHeight: 65, maxWidth: '100%' }}
                        src={integration.logo}
                    />
                </Box>
                <Typography sx={{ fontWeight: 700, color: theme.palette.primary[900], mb: 2 }}>
                    {integration.name}
                </Typography>
                <Typography
                    sx={{
                        fontSize: 12,
                        color: theme.palette.gray[500],
                        fontWeight: 500,
                        my: 1,
                    }}
                >
                    {t('integrationsCard.connectTo')} {integration.name} {t('integrationsCard.toAutomate')} {groupName}.
                </Typography>
                <ConnectButton
                    connected={integration?.manual_configured || integration?.oauth_configured}
                    onConnect={() => setSelected(integration.id)}
                    onDisconnect={openDisconnectModal}
                    envCount={environments?.length}
                    integration={integration}
                    openConnectModal={openConnectModal}
                    connectModal={connectModal}
                />
            </Stack>

            <Box sx={{ position: 'absolute', right: '5%', top: 10 }}>
                {role !== 'auditor' && (
                    <IconButton
                        sx={{
                            color: theme.palette.gray[900],
                            backgroundColor: '#FFFFFF',
                            border: '1px solid #E2E8F0',
                            padding: 0,
                            borderRadius: 1,
                            '&:hover': {
                                border: 'none',
                            },
                        }}
                        onClick={openMenu}
                    >
                        <MoreVert />
                    </IconButton>
                )}
                <Menu
                    open={Boolean(anchor)}
                    anchorEl={anchor}
                    onClose={closeMenu}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                >
                    <MenuItem
                        sx={{ fontSize: '13px', fontWeight: 500, color: '#77777A' }}
                        onClick={() => {
                            // if (oauth_types?.includes(integration?.name)) {
                            //     openConnectModal();
                            // } else {
                            setSelected(integration.id);
                            // }
                        }}
                    >
                        Add enviroment
                        {environments?.length > 0 && (
                            <Box
                                component="p"
                                sx={{
                                    fontSize: 10,
                                    fontWeight: 500,
                                    color: theme.palette.primary[900],
                                    backgroundColor: '#e1e2ec',
                                    width: 18,
                                    height: 25,
                                    borderRadius: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginLeft: 1,
                                }}
                            >
                                <span>{environments?.length}</span>
                            </Box>
                        )}
                    </MenuItem>
                    <MenuItem sx={{ fontSize: '13px', fontWeight: 500, color: '#77777A' }} onClick={openScopeModal}>
                        Scope
                    </MenuItem>
                </Menu>
            </Box>
            <DisconnectModal
                open={disconnectModal}
                handleClose={closeDisconnectModal}
                integration={integration}
                environments={environments}
            />

            <ConnectWithModal
                open={connectModal}
                handleClose={closeConnectModal}
                onConnect={() => setSelected(integration.id)}
                integration={integration}
            />
            <ScopeViewModal
                open={scopeModal}
                handleClose={closeScopeModal}
                integration={integration}
                environments={environments}
            />
        </Box>
    );
};

export default IntegrationCard;
