export const steps = [
    {
        title: 'Scan IP',
        description: 'To begin a scan, click Scan IP button, fill in the necessary details and then submit',
    },
    {
        title: 'Scan Result',
        description: 'Click the Scan result button to examine the results of your scan for open and closed ports.',
    },
];
