import { Stack } from '@mui/material';
import React, { useContext, useEffect, useMemo } from 'react';
import AppInput from 'components/new_components/AppInput';
import { useTheme } from '@mui/material/styles';
import { StatusOption_ } from '../../utils/constants';
import AppSelectInput from 'components/new_components/AppSelectInput';
import { connect } from 'react-redux';
import { GetAllMerchantIntelligenceReport } from 'store/actions/merchantActions';
import { toast } from 'react-toastify';

//translations
import { useTranslation } from 'react-i18next';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

const IntelligenceTitle = (props) => {
    const {
        onSearch,
        risk_status,
        setStatusFilter,
        risk_owner,
        setRiskOwnerFilter,
        all_merchant_intelligence_report,
        GetAllMerchantIntelligenceReport,
    } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('riskAssessment');

    //context api for auditor's selected merchant
    const { activeMerchant } = useContext(SelectedMerchantContext);

    //functions
    const handleStatusChange = (e) => {
        setStatusFilter(e.target.value);
    };
    const onRiskOwnersSelect = (e) => {
        setRiskOwnerFilter(e.target.value);
    };
    useEffect(() => {
        const getAllMerchantIntelligenceReport = async () => {
            const res = await GetAllMerchantIntelligenceReport(activeMerchant);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllMerchantIntelligenceReport();
    }, [activeMerchant]);

    const RiskOwners = useMemo(() => {
        return all_merchant_intelligence_report?.results?.map((owner) => {
            return { name: owner?.risk_owner, value: owner?.risk_owner };
        });
    }, [all_merchant_intelligence_report?.results]);
    return (
        <Stack direction="row" alignItems="center">
            <AppInput
                placeholder={t('riskRegisterTab.table.search')}
                fullWidth
                sx={{
                    mr: 3,
                    borderColor: theme.palette.gray[30],
                    '& input::placeholder': {
                        color: theme.palette.gray[40],
                        fontWeight: 600,
                    },
                }}
                onChange={onSearch}
            />
            <AppSelectInput
                name="status"
                options={StatusOption_}
                defaultValue={t('riskRegisterTab.table.column5')}
                sx={{ mr: 1, ml: 1, borderRadius: '8px', height: 38, fontSize: 12 }}
                onChange={handleStatusChange}
                value={risk_status}
                size="small"
            />
            <AppSelectInput
                name="riskOwners"
                options={RiskOwners}
                defaultValue={t('riskRegisterTab.table.column3')}
                sx={{ mr: 1, borderRadius: '8px', height: 38, fontSize: 12 }}
                onChange={onRiskOwnersSelect}
                value={risk_owner}
            />
        </Stack>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_intelligence_report: state?.merchantReducers?.all_merchant_intelligence_report,
    };
};
export default connect(mapStateToProps, { GetAllMerchantIntelligenceReport })(IntelligenceTitle);
