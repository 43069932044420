import { Stack } from '@mui/material';
import React from 'react';
import AppInput from 'components/new_components/AppInput';
import { useTheme } from '@mui/material/styles';
import { ReportTypeOption, ReportTypeOptionFr } from '../utils/constants';
import AppSelectInput from 'components/new_components/AppSelectInput';

//translations
import { useTranslation } from 'react-i18next';

const RiskTitle = (props) => {
    const { onSearch, type, setType } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('riskAssessment');
    const language = localStorage.getItem('i18nextLng');

    //functions
    const handleStatusChange = (e) => {
        setType(e.target.value);
    };

    return (
        <Stack direction="row" alignItems="center">
            <AppInput
                placeholder={t('reportTable.search')}
                fullWidth
                sx={{
                    mr: 3,
                    borderColor: theme.palette.gray[30],
                    height: 38,
                    '& input::placeholder': {
                        color: theme.palette.gray[40],
                        fontWeight: 600,
                    },
                }}
                onChange={onSearch}
            />
            <AppSelectInput
                name="type"
                options={language?.includes('fr') ? ReportTypeOptionFr : ReportTypeOption}
                defaultValue={t('reportTable.type')}
                sx={{ mt: 2, ml: 1, borderRadius: '8px', height: 38, fontSize: 12, width: '80px' }}
                onChange={handleStatusChange}
                value={type}
                size="small"
            />
        </Stack>
    );
};

export default RiskTitle;
