import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Row, Card, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

//
import FormItem from './components/FormItem';
import FormModal from './components/FormModal';

//Redux
import { connect } from 'react-redux';
import { GetQuestionnaireForms, DeleteQuestonnaireForm } from 'store/actions/vendorActions';

const FormView = (props) => {
    // const history = useHistory();

    //Props
    const { GetQuestionnaireForms, questionnaire_forms, DeleteQuestonnaireForm } = props;

    // state
    const [modalOpen, setModalOpen] = useState(false);

    // functions
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    useEffect(() => {
        const getQuestionnaireForms = async () => {
            const res = await GetQuestionnaireForms();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };

        getQuestionnaireForms();
    }, []);

    return (
        <>
            <Card className="my-3">
                <Row justify="space-between" style={{ marginBottom: '1rem' }}>
                    <h4>Vendor Questionaire</h4>
                    <Button type="primary" icon={<PlusOutlined />} onClick={openModal}>
                        Create Section
                    </Button>
                </Row>
                {questionnaire_forms && questionnaire_forms?.length
                    ? questionnaire_forms
                          ?.sort((a, b) => a?.id - b?.id)
                          ?.map((form) => {
                              return <FormItem form={form} key={form.id} DeleteForm={DeleteQuestonnaireForm} />;
                          })
                    : 'No forms to display'}
            </Card>
            <FormModal open={modalOpen} handleClose={closeModal} />
        </>
    );
};

const mapStateToProp = (state) => {
    return {
        questionnaire_forms: state?.vendorReducers?.questionnaire_forms,
    };
};
export default connect(mapStateToProp, {
    GetQuestionnaireForms,
    DeleteQuestonnaireForm,
})(FormView);
