import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, List } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { formatAmount } from 'utils';
import { CurrencySign } from 'utils';
import PaymentMethodModal from './components/PaymentMethodModal';

// redux
import { connect } from 'react-redux';
import { GetAllPaymentMethods } from 'store/actions/subscriptionActions';
import { useMemo } from 'react';

const PaymentMethods = (props) => {
    const { GetAllPaymentMethods, all_payment_methods } = props;
    // state
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [loading, setLoading] = useState(false);

    // functions
    const openModal = (data = {}) => {
        setModalOpen(true);
        setModalData(data);
    };
    const closeModal = () => {
        setModalOpen(false);
        setModalData({});
    };

    // memos
    const payment_methods = useMemo(() => {
        return all_payment_methods
            ? all_payment_methods?.map((method) => {
                  const newMethod = { ...method };
                  const first_percent = JSON.parse(method?.first_percent);
                  if (typeof first_percent === 'number') {
                      newMethod.first_percent = first_percent;
                  } else {
                      newMethod.small_company_percent = first_percent?.smallCompany;
                      newMethod.large_company_percent = first_percent?.largeCompany;
                  }
                  return newMethod;
              })
            : [];
    }, [all_payment_methods]);

    // async
    const getAllPaymentMethods = async () => {
        setLoading(true);
        const res = await GetAllPaymentMethods();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        getAllPaymentMethods();
    }, []);

    return (
        <>
            <Card title="Payment Methods" className="my-4">
                <List
                    dataSource={payment_methods?.sort((a, b) => a?.id - b?.id)}
                    loading={loading}
                    rowKey={(method) => method?.id}
                    renderItem={(item, idx) => {
                        return (
                            <List.Item>
                                <div className={`flex-1 ${idx ? 'mt-4' : ''}`}>
                                    <header className="my-1 flex justify-between items-center">
                                        <h4 className="font-bold text-sm mb-1">{item?.name}</h4>
                                        <Button type="link" icon={<EditOutlined />} onClick={() => openModal(item)} />
                                    </header>
                                    <PaymentOptionProp
                                        title="Min. eligiblity cost"
                                        value={`${CurrencySign} ${formatAmount(item?.min_cost)}`}
                                    />
                                    {item?.company_size && item?.company_size !== 'null' ? (
                                        <>
                                            <PaymentOptionProp
                                                title="Employee count limit"
                                                value={item?.company_size || '-'}
                                            />

                                            <PaymentOptionProp
                                                title="Small sized company starting payment percentage"
                                                value={`${item?.small_company_percent}%`}
                                            />

                                            <PaymentOptionProp
                                                title="Large sized company starting payment percentage"
                                                value={`${item?.large_company_percent}%`}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <PaymentOptionProp
                                                title="Max. eligiblity cost"
                                                value={`${CurrencySign} ${formatAmount(item?.max_cost)}`}
                                            />
                                            <PaymentOptionProp
                                                title="Starting payment percentage"
                                                value={`${item?.first_percent}%`}
                                            />

                                            <PaymentOptionProp
                                                title="Balance payment percentage"
                                                value={`${item?.second_percent}%`}
                                            />
                                        </>
                                    )}
                                </div>
                            </List.Item>
                        );
                    }}
                />
            </Card>
            <PaymentMethodModal open={modalOpen} handleClose={closeModal} modalData={modalData} />
        </>
    );
};

const PaymentOptionProp = (props) => {
    const { title, value } = props;
    return (
        <div className="flex justify-between items-center py-1">
            <span className="font-medium">{title}:</span>
            <span>{value}</span>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        all_payment_methods: state?.subscriptionReducers?.all_payment_methods,
    };
};
export default connect(mapStateToProps, { GetAllPaymentMethods })(PaymentMethods);
