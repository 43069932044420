import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

//translation
import { useTranslation } from 'react-i18next';

const UserTableActions = (props) => {
    const theme = useTheme();
    const { row, openModal, openDeleteModal } = props;

    // state
    const [anchor, setAnchor] = useState(null);

    //transaltion
    const { t } = useTranslation('user');

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);
    const openEditModal = () => {
        const payload = {
            email: row?.user_detail.email,
            role: row?.role,
            id: row?.id,
            routes: row?.routes?.split(','),
            support_user: row?.id,
            auditor_standards: row?.auditor_standards?.split(','),
        };
        openModal('edit', payload);
        closeMenu();
    };
    const handleOpenDeleteModal = () => {
        closeMenu();
        openDeleteModal(row);
    };

    return (
        <>
            <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                <MenuItem onClick={openEditModal}>{t('table.tableAction.edit')}</MenuItem>
                <MenuItem onClick={handleOpenDeleteModal}>{t('table.tableAction.delete')}</MenuItem>
            </Menu>
        </>
    );
};

export default UserTableActions;
