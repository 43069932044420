import React from 'react';
import { Grid, Box } from '@mui/material';
import AuthMethodItem from './AuthMethodItem';
import { useTranslation } from 'react-i18next';

//utils
// import { twoFAAuthOptions } from './utils/constants';

const AuthMethods = (props) => {
    const { onClick, mfa_type, handleDisconnect } = props;

    const { t } = useTranslation('accounts');
    return (
        <Box sx={{ flexGrow: 1, my: 5 }}>
            <Grid container spacing={2}>
                {t('twoFactorAuth.twoFAAuthOptions', { returnObjects: true })?.map((item) => {
                    return (
                        <Grid item lg={12} sm={12} md={12} key={item.id}>
                            <AuthMethodItem
                                icon={item.icon}
                                type={item.type}
                                header={item.header}
                                description={item.description}
                                handleConnect={onClick}
                                handleDisconnect={handleDisconnect}
                                mfa_type={mfa_type}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default AuthMethods;
