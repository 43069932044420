import React from 'react';
import { MenuList, Box, Paper, MenuItem } from '@mui/material';

import { useTheme } from '@mui/styles';
//i18n
import { useTranslation } from 'react-i18next';
import AppSlide from 'components/new_components/AppSlide';
import AppBackdrop from 'components/new_components/AppBackdrop';

const LanguageMenu = ({ closeLanguageMenu, open, anchorEl, menuListStyles, language }) => {
    const { i18n } = useTranslation();

    const theme = useTheme();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        window.location.reload();
        closeLanguageMenu();
    };
    return (
        <div>
            {' '}
            <AppBackdrop onClose={closeLanguageMenu} open={open} />
            <AppSlide direction="down" in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        zIndex: '102',
                        top: 70,
                        right: '19%',
                        ...menuListStyles?.parent,
                    }}
                >
                    <Paper
                        sx={{
                            maxWidth: '100%',
                            borderRadius: '8px',
                            boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                            overflow: 'hidden',
                            width: 90,
                        }}
                    >
                        <MenuList
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={closeLanguageMenu}
                            sx={{ p: 0 }}
                        >
                            {language !== 'English' && (
                                <MenuItem
                                    text="English"
                                    sx={{
                                        borderBottom: '1px solid #E1E2EC',
                                        textTransform: 'uppercase',
                                        fontWeight: 500,
                                        fontSize: 12,
                                        px: 0,
                                        pl: 1.5,
                                        py: 1,
                                        color: theme.palette.neutral[50],
                                        justifyContent: 'flex-start',
                                    }}
                                    onClick={() => changeLanguage('en')}
                                >
                                    English
                                </MenuItem>
                            )}
                            {language !== 'French' && (
                                <MenuItem
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 500,
                                        fontSize: 12,
                                        px: 0,
                                        py: 1,
                                        pl: 1.5,
                                        justifyContent: 'flex-start',
                                    }}
                                    onClick={() => changeLanguage('fr')}
                                >
                                    French
                                </MenuItem>
                            )}
                        </MenuList>
                    </Paper>
                </Box>
            </AppSlide>
        </div>
    );
};

export default LanguageMenu;
