import React from 'react';
// import { InfoOutlined } from '@mui/icons-material';
//core component
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { Box, Button, Card, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const OverviewModal = ({ open, handleClose, updateModuleIntroModal }) => {
    const theme = useTheme();

    const closeAll = () => {
        updateModuleIntroModal();
        handleClose();
    };
    return (
        <AppCenteredModal
            open={open}
            handleClose={closeAll}
            width={500}
            height="42%"
            overflow="hidden"
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Button sx={{ borderRadius: 1.5, textTransform: 'inherit' }} variant="contained" onClick={closeAll}>
                        Close
                    </Button>
                </Box>
            }
        >
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        backgroundColor: '#F8F8F8',
                        height: '100px',
                        position: 'relative',
                    }}
                >
                    <Card
                        elevation={0}
                        sx={{
                            backgroundColor: '#ffffff',
                            padding: 1,
                            fontSize: '11px',
                            fontWeight: 400,
                            boxShadow: '4px 0px 32px 0px rgba(175, 178, 178, 0.5)',
                        }}
                    >
                        Looks okay
                    </Card>

                    <Card
                        elevation={0}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: '#ffffff',
                            px: 1.5,
                            py: 1,
                            mr: 12,
                            zIndex: 10,
                            boxShadow: '4px 0px 32px 0px rgba(175, 178, 178, 0.5)',
                        }}
                    >
                        <Typography sx={{ fontSize: '11px', fontWeight: 400 }}>Needs Attention</Typography>
                    </Card>
                    <Card
                        sx={{
                            padding: 0.5,
                            backgroundColor: '#55BE8B',
                            position: 'absolute',
                            left: '17%',
                            top: 20,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '10px',
                                fontWeight: 'bold',
                                color: '#ffffff',
                            }}
                        >
                            0
                        </Typography>
                    </Card>
                    <Card
                        sx={{
                            px: 1,
                            py: 0.7,
                            backgroundColor: '#FF5449',
                            position: 'absolute',
                            right: 85,
                            top: 18,
                            zIndex: 100,
                            borderRadius: 0.5,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '10px',
                                fontWeight: 'bold',
                                color: '#ffffff',
                            }}
                        >
                            Severity
                        </Typography>
                    </Card>

                    <Typography
                        sx={{
                            position: 'absolute',
                            right: 50,
                            top: 60,
                            fontSize: '10px',
                            fontWeight: 'bold',
                            border: '0.8px solid #E1E2EC',
                            color: '#5E5E62',
                            px: 0.9,
                            py: 1,
                            borderRadius: 1,
                        }}
                    >
                        <FiberManualRecordIcon sx={{ color: '#FF5449', width: 15, height: 15 }} /> 2 issues found
                    </Typography>
                </Box>
                <Box sx={{ my: 5 }}>
                    <Typography sx={{ color: '#395BA9', fontWeight: 600, fontSize: '16px' }}>
                        {' '}
                        Introducing
                        <Typography
                            component={'span'}
                            sx={{
                                color: '#395BA9',
                                fontWeight: 600,
                                fontSize: '16px',
                                ml: 1,
                                borderBottom: `3px solid ${theme.palette.warning[700]}`,
                            }}
                        >
                            Source Code Review
                        </Typography>
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                        <Typography sx={{ color: theme.palette.gray[30], fontWeight: 400, fontSize: '13px' }}>
                            Source Code Review aids to identify and correct errors, enhance code quality, and ensure
                            adherence to coding standards and best practices, along with providing recommendations.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </AppCenteredModal>
    );
};

export default OverviewModal;
