import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StepQuestions from './components/SecurityStepQuestions';
import { AppForm, AppSubmitButton } from 'components/new_components/forms';
import AppButton from 'components/new_components/AppButton';
import RiskIdentificationForm from './components/RiskIdentificationForm';
import RiskControlForm from './components/RiskControlForm.js';
import RecoveryMeasureForm from './components/RecoveryMeasureForm';
import PostTreatmentForm from './components/PostTreatmentForm';
import {
    riskIdentificationValidation,
    // riskIdentificationValidation_,
    existingControlValidation,
    recoveryMeasuresValidation,
    postRiskTreatmentValidation,
} from '../../utils/validation';
import {
    steps,
    LikelihoodOptionBy5,
    ImpactOptionBy5,
    ImpactOptionBy3,
    LikelihoodOptionBy3,
} from '../../utils/constants';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
    GetAllComputers,
    GetAllInfrastructures,
    GetMerchantDocumentVersions,
    AddMerchantRiskAssessments,
    UpdateMerchantRiskAssessments,
} from 'store/actions/merchantActions';
import { AppFormContext } from 'components/new_components/forms';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

//translations
import { useTranslation } from 'react-i18next';

const CyberSecurityAssessment = (props) => {
    const { payload, setPayload, AddMerchantRiskAssessments, UpdateMerchantRiskAssessments, handleGoBack, closeForm } =
        props;
    const [treatment_option, setTreatment_option] = useState('mitigate');
    const [matrix, setMatrix] = useState('3x3');
    const [owner, setOwner] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [new_document, setNew_document] = useState('');
    const [existing_, setExisting_] = useState(null);
    const [completed, setCompleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({});
    const [residualLikelihood3, setResidualLikelihood3] = useState([]);
    const [residualImpact3, setResidualImpact3] = useState([]);
    const [residualLikelihood5, setResidualLikelihood5] = useState([]);
    const [residualImpact5, setResidualImpact5] = useState([]);

    //translation
    const { t } = useTranslation('riskAssessment');

    // By 3 Residual
    useEffect(() => {
        if (matrix === '3x3' && values?.inherent_impact === '1' && residualImpact3?.length <= 2) {
            const filteredArray = ImpactOptionBy3?.filter((impact) => impact?.value === '1');
            setResidualImpact3(filteredArray);
        } else if (matrix === '3x3' && values?.inherent_impact === '2' && residualImpact3?.length <= 2) {
            const filteredArray = ImpactOptionBy3?.filter((impact) => impact?.value === '1');
            setResidualImpact3(filteredArray);
        } else if (matrix === '3x3' && values?.inherent_impact === '3' && residualImpact3?.length <= 1) {
            const filteredArray = ImpactOptionBy3?.filter((impact) => impact?.value !== '3');
            setResidualImpact3(filteredArray);
        }
    }, [values?.inherent_impact]);

    useEffect(() => {
        if (matrix === '3x3' && values?.inherent_likelihood === '1' && residualLikelihood3?.length <= 2) {
            const filteredArray = LikelihoodOptionBy3?.filter((likelihood) => likelihood?.value === '1');
            setResidualLikelihood3(filteredArray);
        } else if (matrix === '3x3' && values?.inherent_likelihood === '2' && residualLikelihood3?.length <= 2) {
            const filteredArray = LikelihoodOptionBy3?.filter((likelihood) => likelihood?.value === '1');
            setResidualLikelihood3(filteredArray);
        } else if (matrix === '3x3' && values?.inherent_likelihood === '3' && residualLikelihood3?.length <= 1) {
            const filteredArray = LikelihoodOptionBy3?.filter((likelihood) => likelihood?.value !== '3');
            setResidualLikelihood3(filteredArray);
        }
    }, [values?.inherent_likelihood]);

    // By 5 Residual
    useEffect(() => {
        if (matrix === '5x5' && values?.inherent_impact === '1' && residualImpact5?.length <= 4) {
            const filteredArray = ImpactOptionBy5?.filter((impact) => impact?.value === '1');
            setResidualImpact5(filteredArray);
        } else if (matrix === '5x5' && values?.inherent_impact === '2' && residualImpact5?.length <= 4) {
            const filteredArray = ImpactOptionBy5?.filter((impact) => impact?.value === '1');
            setResidualImpact5(filteredArray);
        } else if (matrix === '5x5' && values?.inherent_impact === '3' && residualImpact5?.length <= 4) {
            const filteredArray = ImpactOptionBy5?.filter(
                (impact) => impact?.value !== '3' && impact?.value !== '4' && impact?.value !== '5'
            );
            setResidualImpact5(filteredArray);
        } else if (matrix === '5x5' && values?.inherent_impact === '4' && residualImpact5?.length <= 4) {
            const filteredArray = ImpactOptionBy5?.filter((impact) => impact?.value !== '4' && impact?.value !== '5');
            setResidualImpact5(filteredArray);
        } else if (matrix === '5x5' && values?.inherent_impact === '5' && residualImpact5?.length <= 4) {
            const filteredArray = ImpactOptionBy5?.filter((impact) => impact?.value !== '5');
            setResidualImpact5(filteredArray);
        }
    }, [values?.inherent_impact]);

    useEffect(() => {
        if (matrix === '5x5' && values?.inherent_likelihood === '1' && residualLikelihood5?.length <= 4) {
            const filteredArray = LikelihoodOptionBy5?.filter((likelihood) => likelihood?.value === '1');
            setResidualLikelihood5(filteredArray);
        } else if (matrix === '5x5' && values?.inherent_likelihood === '2' && residualLikelihood5?.length <= 4) {
            const filteredArray = LikelihoodOptionBy5?.filter((likelihood) => likelihood?.value === '1');
            setResidualLikelihood5(filteredArray);
        } else if (matrix === '5x5' && values?.inherent_likelihood === '3' && residualLikelihood5?.length <= 4) {
            const filteredArray = LikelihoodOptionBy5?.filter(
                (likelihood) => likelihood?.value !== '3' && likelihood?.value !== '4' && likelihood?.value !== '5'
            );
            setResidualLikelihood5(filteredArray);
        } else if (matrix === '5x5' && values?.inherent_likelihood === '4' && residualLikelihood5?.length <= 4) {
            const filteredArray = LikelihoodOptionBy5?.filter(
                (likelihood) => likelihood?.value !== '4' && likelihood?.value !== '5'
            );
            setResidualLikelihood5(filteredArray);
        } else if (matrix === '5x5' && values?.inherent_likelihood === '5' && residualLikelihood5?.length <= 4) {
            const filteredArray = LikelihoodOptionBy5?.filter((likelihood) => likelihood?.value !== '5');
            setResidualLikelihood5(filteredArray);
        }
    }, [values?.inherent_likelihood]);

    const goBack = () => {
        history.goBack();
    };

    const handleBack = () => {
        setCurrentStep((prevActiveStep) => prevActiveStep - 1);
        setCompleted(false);
    };

    useEffect(() => {
        if (values?.id) {
            setMatrix(payload?.matrix);
        }
    }, [values?.id]);
    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const getCurrentValues = (values) => setValues(values);
    //async functions
    const getAllComputers = async () => {
        const res = await GetAllComputers();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllInfrastructures = async () => {
        const res = await GetAllInfrastructures();
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (payload?.asset) {
            getAllComputers();
            getAllInfrastructures();
        }
    }, [payload?.asset]);

    const handleReset = () => {
        setCurrentStep(1);
        setCompleted(false);
    };

    const onClose = () => {
        setOwner('');
        handleReset();
    };

    useEffect(() => {
        if (currentStep === steps?.length) {
            setCompleted(true);
        }
    }, [currentStep, steps]);

    const handleSubmit = async (values) => {
        if (completed) {
            const formdata = new FormData();
            const risk_status_ = values?.risk_status !== '' ? values?.risk_status : 'active';
            const risk_owner = values?.risk_owner !== '' ? values?.risk_owner : owner;
            formdata.append('matrix', matrix);
            formdata.append('asset_type', values?.asset_type);
            formdata.append('asset', values?.asset);
            formdata.append('threat', values?.threat);
            formdata.append('threat_description', values?.threat_description);
            formdata.append('vulnerabilities', values?.vulnerabilities?.toString());
            formdata.append('risk_owner', risk_owner);
            formdata.append('inherent_likelihood', JSON.parse(values?.inherent_likelihood));
            formdata.append('inherent_impact', JSON.parse(values?.inherent_impact));
            formdata.append('existing_controls', values?.existing_controls);
            formdata.append('treatment_option', treatment_option);
            formdata.append('control_comments', values.control_comments);
            formdata.append('recommended_controls', values?.recommended_controls);
            formdata.append('timeline', values.timeline);
            formdata.append('responsibility', values?.responsibility);
            formdata.append('control_requirement', values?.control_requirement);
            formdata.append('existing_document', existing_?.value !== undefined ? existing_?.value : '');
            formdata.append('new_document', new_document);
            formdata.append('risk_status', risk_status_);
            formdata.append('residual_likelihood', JSON.parse(values?.residual_likelihood));
            formdata.append('residual_impact', JSON.parse(values?.residual_impact));
            formdata.append('comments', values?.comments);
            setLoading(true);
            const hasDocument = formdata.get('new_document') === '' ? 'new_document' : null;
            formdata.delete(hasDocument);

            const res = values?.id
                ? await UpdateMerchantRiskAssessments(formdata, values?.id, 1)
                : await AddMerchantRiskAssessments(formdata, 1);
            setLoading(false);
            if (res?.success) {
                toast.success(values?.id ? 'Risk Assessment Updated Successfully' : 'Risk Assessment Ran Successfully');
                if (completed) {
                    onClose();
                    setCurrentStep(1);
                }
                if (values?.id) {
                    handleGoBack('self');
                }
                setCurrentStep(1);
                setCompleted(false);
                setPayload({});
                setExisting_('');
            } else {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    toast.error(res?.message);
                }
            }
        } else {
            handleNext();
        }
    };
    return (
        <Box>
            <Typography onClick={closeForm} sx={{ cursor: 'pointer', fontWeight: 600, mt: 3, mx: 4 }}>
                <ArrowBackIosNewIcon color="primary" sx={{ width: 20, height: 20 }} />{' '}
                {t('cyberSecurityAssessment.goBack')}
            </Typography>
            <Grid container spacing={2} sx={{ mx: 2, mt: -3 }}>
                <Grid item lg={3.5} md={3.5} sm={12} xs={12} sx={{ marginTop: '2rem' }}>
                    <StepQuestions goBack={goBack} currentStep={currentStep} />
                </Grid>
                <AppForm
                    initialValues={payload}
                    onSubmit={handleSubmit}
                    validate={
                        currentStep === 1
                            ? riskIdentificationValidation
                            : currentStep === 2
                            ? existingControlValidation
                            : currentStep === 3
                            ? recoveryMeasuresValidation
                            : postRiskTreatmentValidation
                    }
                >
                    <Grid item lg={7.5} md={7.5} sm={12} xs={12} sx={{ marginTop: 1 }}>
                        <AppFormContext getValues={getCurrentValues}>
                            {currentStep === 1 ? (
                                <RiskIdentificationForm
                                    values={values}
                                    matrix={matrix}
                                    setMatrix={setMatrix}
                                    owner={owner}
                                    setOwner={setOwner}
                                    setValues={setValues}
                                />
                            ) : currentStep === 2 ? (
                                <RiskControlForm
                                    treatment_type={treatment_option}
                                    setTreatment_type={setTreatment_option}
                                    matrix={matrix}
                                    payload={values}
                                />
                            ) : currentStep === 3 ? (
                                <RecoveryMeasureForm
                                    new_document={new_document}
                                    setNew_document={setNew_document}
                                    existing_={existing_}
                                    setExisting_={setExisting_}
                                />
                            ) : (
                                <PostTreatmentForm
                                    matrix={matrix}
                                    residualLikelihood3={residualLikelihood3}
                                    residualImpact3={residualImpact3}
                                    residualLikelihood5={residualLikelihood5}
                                    residualImpact5={residualImpact5}
                                />
                            )}
                        </AppFormContext>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'left',
                                alignItems: 'center',
                                my: 2,
                            }}
                        >
                            {currentStep > 1 && (
                                <AppButton
                                    name={t('cyberSecurityAssessment.previous')}
                                    variant="text"
                                    color="primary"
                                    onClick={handleBack}
                                />
                            )}
                            {completed ? (
                                <AppSubmitButton
                                    text={t('cyberSecurityAssessment.finish')}
                                    variant="contained"
                                    color="primary"
                                    loading={loading}
                                    loadingPosition="center"
                                />
                            ) : (
                                <AppSubmitButton
                                    text={t('cyberSecurityAssessment.next')}
                                    variant="contained"
                                    color="primary"
                                    loading={loading}
                                    loadingPosition="center"
                                />
                            )}
                        </Box>
                    </Grid>
                </AppForm>
            </Grid>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_infrastructures: state?.merchantReducers?.all_merchant_infrastructures,
        all_computers: state?.merchantReducers?.all_computers,
    };
};
export default connect(mapStateToProps, {
    GetAllComputers,
    GetAllInfrastructures,
    GetMerchantDocumentVersions,
    AddMerchantRiskAssessments,
    UpdateMerchantRiskAssessments,
})(CyberSecurityAssessment);
