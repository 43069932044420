import React from 'react';
import './style.css';

const Rectangle = (props) => {
    const { value, likelihood, impact, level, tab, cols } = props;

    return (
        <>
            {likelihood ? (
                <div className="legend">{likelihood}</div>
            ) : impact ? (
                <div className={impact !== ' ' ? 'impact' : 'legend'}>{impact}</div>
            ) : (
                <div
                    className={tab === 1 ? 'rectangle' : 'rectangle1'}
                    style={{
                        backgroundColor: level(value).color,
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    {cols?.map((row, index) => (
                        <span
                            key={index}
                            className={
                                row.className && row.isRR
                                    ? `name-${row.className}-RR ${value}`
                                    : row.className && !row.isRR
                                    ? `startingPoint name-${row.className}-IR ${value}`
                                    : ''
                            }
                            style={{
                                backgroundColor: row?.name?.includes('IR') ? '#002C72' : '#395BA9',
                                color: '#ffffff',
                                fontWeight: 500,
                                fontSize: '12px',
                                display: 'inline',
                                cursor: !row?.isRR ? 'pointer' : '',
                            }}
                        >
                            {row?.name}
                        </span>
                    ))}
                </div>
            )}
        </>
    );
};

export default Rectangle;
