import { Button, Col, DatePicker, Modal, Row, Input } from 'antd';
import React, { useEffect, useState } from 'react';
// import { AiOutlineInbox } from 'react-icons/ai';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

// redux
import { connect } from 'react-redux';
import { UpdateAuditRequest, UploadReportFile } from 'store/actions/auditActions';
import { toast } from 'react-toastify';
import { AUDIT_STATUS } from 'views/merchant/audit/utils';

// const { Dragger } = Upload;
const { RangePicker } = DatePicker;

const CertificationModal = (props) => {
    const { open, handleClose, UpdateAuditRequest, UploadReportFile, modalData } = props;

    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [documents, setDocuments] = useState([]);

    // FUNCTIONS
    const closeModal = () => {
        handleClose();
        setData({});
        setDocuments([]);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let payload = {};
        const rocDocument = modalData?.uploaded_docs?.filter((docs) => docs?.doc_name?.toLowerCase()?.includes('roc'));
        payload = {
            ...data,
            effective_from: data?.effective_from?.map((date) => date?.format())?.toString(),
            merchant: modalData?.merchant,
            status: AUDIT_STATUS.COMPLETE,
            uploaded_docs:
                modalData.uploaded_docs?.length > 1
                    ? [...documents, ...rocDocument]
                    : [...modalData.uploaded_docs, ...documents],
            compliance: modalData?.compliance,
        };
        const res = await UpdateAuditRequest(payload, modalData?.id);
        setLoading(false);
        if (res?.success) {
            toast.success(`You have uploaded the certifications for this audit.`);
            closeModal();
        } else {
            toast.error(res?.message);
        }
    };
    const handleDateChange = (name, value) => {
        setData({ ...data, [name]: value.map((date) => dayjs(date)) });
    };
    const addDoc = () => {
        const docLength = documents?.length + 1;
        setDocuments((current) => [...current, { id: docLength + 1, doc_name: '', doc: '' }]);
    };
    const deleteDoc = (index) => {
        const copiedDocs = [...documents];
        copiedDocs?.splice(index);
        setDocuments(copiedDocs);
    };
    const getCurrentDoc = (id) => {
        const allDocs = Array.isArray(documents) ? documents : [];
        return allDocs?.find((docs) => docs?.id === id);
    };
    const handleFileUpload = async (index, file) => {
        setUploading(true);
        const formData = new FormData();
        formData.append('report', file);
        const res = await UploadReportFile(formData);
        setUploading(false);
        if (res?.success) {
            const newDocs = documents || [];
            newDocs[index] = {
                ...newDocs[index],
                doc: res?.report,
            };
            setDocuments([...newDocs]);
        } else {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (!modalData?.effective_from) return;
        setData({ ...data, effective_from: modalData?.effective_from?.split(',')?.map((time) => dayjs(time)) });
    }, [modalData]);
    useEffect(() => {
        if (open) {
            const documentsWithOutRoc = modalData?.uploaded_docs?.filter(
                (docs) => !docs?.doc_name?.toLowerCase()?.includes('roc')
            );
            setDocuments(
                documentsWithOutRoc?.length
                    ? [...documents, ...documentsWithOutRoc]
                    : [...documents, { id: modalData?.uploaded_docs?.length + 1, doc_name: '', doc: '' }]
            );
        }
    }, [open]);

    // constants
    // const draggers = {
    //     action: null,
    //     multiple: false,
    //     maxCount: 1,
    // };

    return (
        <Modal title="Upload Certification" open={open} onCancel={closeModal} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2">
                        <label htmlFor="effective_from">Effective From</label>
                        <RangePicker
                            onChange={(_, str) => handleDateChange('effective_from', str)}
                            size="large"
                            name="effective_from"
                            id="effective_from"
                            required
                            style={{ width: '100%' }}
                            value={data?.effective_from}
                        />
                    </Col>
                    <Col span={24} className="my-2">
                        <label htmlFor="uploaded_documents">Upload Other Documents</label>
                        <small className="pl-1">(Add Mulitiple documents and include their names)</small>
                        {documents?.map((document, idx) => {
                            return (
                                <Row gutter={8} key={document.id}>
                                    <Col span={24} className="my-2">
                                        <Input
                                            name="doc_name"
                                            size="large"
                                            placeholder="Document name"
                                            value={document?.doc_name}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                const newDocs = documents || [];
                                                newDocs[idx] = {
                                                    ...newDocs[idx],
                                                    doc_name: value,
                                                };
                                                setDocuments([...newDocs]);
                                            }}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <input
                                            type="file"
                                            onChange={async (e) => {
                                                const newDocs = documents || [];
                                                newDocs[idx] = {
                                                    ...newDocs[idx],
                                                    doc: e.target.files[0],
                                                };
                                                setDocuments([...newDocs]);
                                                handleFileUpload(idx, e.target.files[0]);
                                            }}
                                            className="my-2"
                                        />
                                        <div className="flex flex-row justify-between">
                                            {getCurrentDoc(document?.id)?.doc?.name ||
                                            getCurrentDoc(document?.id)?.doc ? (
                                                <p className="break-all">
                                                    {getCurrentDoc(document?.id)?.doc?.name ||
                                                        getCurrentDoc(document?.id)?.doc?.split('/')?.[4]}
                                                </p>
                                            ) : (
                                                '-'
                                            )}
                                            <Button
                                                type="primary"
                                                danger
                                                shape="circle"
                                                onClick={() => deleteDoc(idx)}
                                                icon={<DeleteOutlined />}
                                                disabled={documents?.length <= 1}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            );
                        })}

                        <div className="flex flex-row justify-end my-2 gap-2">
                            <Button icon={<PlusCircleOutlined />} onClick={addDoc} />
                        </div>
                    </Col>

                    <Col className="mt-2">
                        <Button htmlType="submit" type="primary" loading={loading} disabled={uploading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, { UpdateAuditRequest, UploadReportFile })(CertificationModal);
