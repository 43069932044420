import React, { useEffect, useState } from 'react';
import { Box, Button, Menu, Typography, MenuItem } from '@mui/material';
// import { Close } from '@mui/icons-material';

//redux
import { useDispatch, useSelector } from 'react-redux';

//translations
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import AppCenteredModalNew from 'components/new_components/AppCenteredModalNew';
import { UpdateRiskiskAssessment } from 'store/actions/riskAssessmentActions';
import moment from 'moment';
import { GetAllMerchantUsers } from 'store/actions/riskAssessmentActions';

const ExtendTimelineModal = (props) => {
    const { open, handleClose, details, params } = props;
    const [updating, setUpdating] = useState(false);
    const [timeline_start_date, setTimelineStartDate] = useState(null);
    const [timeline_end_date, setTimelineEndDate] = useState(null);
    const [solution_owner, setSolution_owner] = useState('');
    const [search, setSearch] = useState('');
    const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);

    // Redux
    const dispatch = useDispatch();
    const { all_merchant_users } = useSelector((state) => state.riskAssessmentReducers);

    // users
    const all_users = all_merchant_users?.people?.concat(all_merchant_users?.users);
    const filtered_employees = all_users?.filter((user) => user?.email.toLowerCase().includes(search.toLowerCase()));

    // Async functions
    const getAllMerchantUsers = async () => {
        const res = await dispatch(GetAllMerchantUsers());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const UpdateRisk = async () => {
        setUpdating(true);
        const contingency_body = {
            contingency_end_date: timeline_end_date,
            contingency_start_dat: timeline_start_date,
            solution_owner,
        };
        const body_1 = {
            timeline_end_date,
            timeline_start_date,
            solution_owner,
        };
        const body = details?.mitigation_plan === 'accept' ? contingency_body : body_1;
        const res = await dispatch(UpdateRiskiskAssessment(body, details?.id, params));
        setUpdating(false);
        if (res?.success) {
            toast.success('Risk timeline updated successfully.');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    const onClose = () => {
        handleClose();
    };

    useEffect(() => {
        if (details) {
            setTimelineStartDate(
                details?.mitigation_plan === 'accept' ? details?.contingency_start_date : details.timeline_start_date
            );
            setTimelineEndDate(
                details?.mitigation_plan === 'accept' ? details?.contingency_start_end : details?.timeline_end_date
            );
            setSolution_owner(details?.solution_owner);
        }
    }, [details]);
    useEffect(() => {
        getAllMerchantUsers;
    }, []);
    return (
        <AppCenteredModalNew
            open={open}
            handleClose={onClose}
            title={
                <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                    Extend mitigation timeline
                </Typography>
            }
            icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
            noShadow
            noClose
            headerAction={
                <Box
                    sx={{
                        border: '1px solid #E2E8F0',
                        borderRadius: 30,
                        width: 30,
                        height: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon sx={{ width: 20, height: 20 }} />
                </Box>
            }
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        mt: 3,
                        backgroundColor: '#F8FAFC',
                        height: '80px',
                        mx: '-2rem',
                        mb: '-2rem',
                        px: '2rem',
                        pt: '1rem',
                        pb: '4.5rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit', color: '#334155' }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ textTransform: 'inherit', fontWeight: 600 }}
                        onClick={UpdateRisk}
                        disabled={updating}
                    >
                        Save
                    </Button>
                </Box>
            }
            width="450px"
            height="420px"
        >
            <div className="mt-[2rem]">
                <div className="flex-1 mb-3">
                    <p className="font-medium text-sm text-[#64748B] mb-1">Timeline (From)</p>
                    <input
                        type="date"
                        inputMode="numeric"
                        placeholder="Enter timeline"
                        value={moment(new Date(timeline_start_date)).format('YYYY-MM-DD')}
                        onChange={(event) => {
                            setTimelineStartDate(event.target.value);
                        }}
                        onBlur={(event) => setTimelineStartDate(event.target.value)}
                        className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                    />
                </div>
                <div className="flex-1 mb-3">
                    <p className="font-medium text-sm text-[#64748B] mb-1">Timeline (To)</p>
                    <input
                        type="date"
                        inputMode="numeric"
                        placeholder="Enter timeline"
                        min={moment(new Date(timeline_start_date)).format('YYYY-MM-DD')}
                        value={moment(new Date(timeline_end_date)).format('YYYY-MM-DD')}
                        onChange={(event) => {
                            setTimelineEndDate(event.target.value);
                        }}
                        onBlur={(event) => setTimelineStartDate(event.target.value)}
                        className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                    />
                </div>
                <div className="flex-1 mb-3">
                    <p className="font-medium text-sm text-[#64748B]">Mitigation Owner</p>

                    <div>
                        <button
                            aria-controls={categoryAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={categoryAnchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setCategoryAnchorEl(event.currentTarget);
                            }}
                            className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                        >
                            <p
                                className={`font-normal text-sm ${
                                    solution_owner ? 'text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                                }`}
                            >
                                {solution_owner ? solution_owner : 'Select owner'}
                            </p>

                            <img
                                src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                alt="Arrow Down Icon"
                                className="object-contain"
                            />
                        </button>

                        <Menu
                            anchorEl={categoryAnchorEl}
                            open={Boolean(categoryAnchorEl)}
                            onClose={() => setCategoryAnchorEl(null)}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                        >
                            {' '}
                            <div className="!py-[5px] !px-3 flex flex-col gap-2.5">
                                <p className="border-b border-[#F1F5F9] !py-1 !px-3 text-[#64748B] font-medium text-xs">
                                    Search email
                                </p>

                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="w-full !py-2.5 !pr-2.5 !pl-8 !border !border-[#E2E8F0] h-7 text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                        value={search}
                                        onChange={(event) => {
                                            event.stopPropagation();
                                            setSearch(event.target.value);
                                        }}
                                    />

                                    <img
                                        src="/img/risk-assessment/ri_search-line-small.svg"
                                        alt="Search Icon"
                                        className="absolute object-contain top-1/2 -translate-y-1/2 left-[10px]"
                                    />
                                </div>
                            </div>
                            {filtered_employees?.length ? (
                                <div className="!p-2 flex flex-col min-w-[224px]">
                                    {filtered_employees?.map((category) => (
                                        <MenuItem
                                            key={category?.id}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setSolution_owner(category?.email);
                                                setCategoryAnchorEl(null);
                                            }}
                                            sx={{
                                                color: '#64748B',
                                                fontWeight: 500,
                                                fontSize: '13px',
                                                padding: '6px 8px',
                                            }}
                                        >
                                            {category?.email}
                                        </MenuItem>
                                    ))}
                                </div>
                            ) : (
                                <p className="flex items-center justify-center my-4 text-sm text-[#64748B] font-medium">
                                    User not found
                                </p>
                            )}
                        </Menu>
                    </div>
                </div>
            </div>
        </AppCenteredModalNew>
    );
};

export default ExtendTimelineModal;
