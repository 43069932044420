import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Modal, Row, Spin, Tag, Typography } from 'antd';
import BreadCrumb from 'components/Breadcrumb';
import GlossaryModal from './components/GlossaryModal';

// redux
import { connect } from 'react-redux';
import { GetAllGlossaries, DeleteGlossaryItem } from 'store/actions/adminActions';

const { Title } = Typography;
const { confirm } = Modal;

const Glossary = (props) => {
    const { GetAllGlossaries, all_glossaries, DeleteGlossaryItem } = props;

    // state
    const [modal, setModal] = useState(null);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // functions
    const openModal = (mode, data = {}) => {
        setModal(mode);
        setData(data);
    };
    const closeModal = () => setModal(null);
    // async functions
    const getAllGlossaries = async () => {
        setLoading(true);
        const res = await GetAllGlossaries();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const deleteItem = (id) => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteGlossaryItem(id);
                if (res.success) {
                    toast.success('Item deleted successfully.');
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    // useEffect
    useEffect(() => {
        getAllGlossaries();
    }, []);

    return (
        <>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Glossary' }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal('Add')}
                    >
                        Add Glossary item
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {loading ? (
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                ) : all_glossaries && all_glossaries?.length > 0 ? (
                    <Row wrap gutter={24}>
                        {all_glossaries
                            ?.sort((a, b) => a?.id - b?.id)
                            ?.map((item) => (
                                <Col xs={24} md={12} lg={8} xxl={6} key={item.id} style={{ marginBottom: '1rem' }}>
                                    <Card
                                        title={`${item.word}`}
                                        loading={false}
                                        actions={[
                                            <EditOutlined key="edit" onClick={() => openModal('Edit', item)} />,
                                            <DeleteOutlined key="delete" onClick={() => deleteItem(item.id)} />,
                                        ]}
                                    >
                                        <Title level={5} style={{ fontWeight: 400 }}>
                                            {item.meaning}
                                        </Title>
                                        {item.standard && (
                                            <div className="flex items-center mt-3">
                                                {item.standard?.split(',').map((standard) => {
                                                    return (
                                                        <Tag color="blue" className="text-[10px] px-1" key={standard}>
                                                            {standard}
                                                        </Tag>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                ) : (
                    <Row justify="center">
                        <Col>
                            <Empty
                                description={
                                    <div>
                                        <h3>No Glossaries</h3>
                                        <span>Add a glossary item </span>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                )}
            </section>
            <GlossaryModal open={modal} modalData={data} onClose={closeModal} />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_glossaries: state?.adminReducers?.all_glossaries,
    };
};
export default connect(mapStateToProps, { GetAllGlossaries, DeleteGlossaryItem })(Glossary);
