import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EmptyScan from '../../../../assets/img/emptyFile.png';
import Play from '../../../../assets/img/Play.png';

import AppModal from 'components/new_components/AppModal';

// Redux
import { RequestCodeReview } from 'store/actions/merchantActions';
import { connect } from 'react-redux';

//translation
import { useTranslation } from 'react-i18next';
import { GetDownloadLink } from 'store/actions/generalActions';
import { toast } from 'react-toastify';
import LoadingState from 'components/new_components/LoadingState';
import { useLocation } from 'react-router-dom';

const ExemptionReasonModal = (props) => {
    const { open, handleClose, exceptionReason } = props;
    const [loadingLink, setLoadingLink] = useState(false);

    const PathContains = (key) => useLocation().pathname.split('/').includes(key);
    const modelName = PathContains('blockchain')
        ? 'blockchain_exception'
        : PathContains('cii_directive')
        ? 'cii_exception'
        : undefined;

    const theme = useTheme();

    //translation
    const { t } = useTranslation('compliance');

    const getDownloadLink = async () => {
        if (exceptionReason?.reason_document && !loadingLink) {
            setLoadingLink(true);
            const res = await GetDownloadLink('compliance', modelName, exceptionReason.id)();
            setLoadingLink(false);
            if (res?.success) {
                // open decoded url
                window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
                // open original file url
                window.open(exceptionReason?.reason_document, '_blank');
            }
        }
    };

    return (
        <AppModal
            open={open}
            handleClose={handleClose}
            title={
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: 24,
                    }}
                >
                    {t('reasonForExempting')}
                </Typography>
            }
            hasCloseBtn
            height="400px"
            maxHeight="800"
        >
            <Box sx={{ marginTop: '2rem', mx: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img src={Play} alt="" />
                    <Typography
                        sx={{
                            color: theme.palette.gray[800],
                            fontWeight: 400,
                            fontSize: 16,
                            marginLeft: '1rem',
                        }}
                    >
                        {exceptionReason?.reason}
                    </Typography>
                </Box>
                {exceptionReason?.reason_document !== null ? (
                    <Box sx={{ marginTop: '2rem', marginLeft: '2rem' }}>
                        <Typography
                            sx={{
                                color: theme.palette.gray[800],
                                fontWeight: 400,
                                fontSize: 16,
                                textDecoration: 'underline',
                                display: 'ruby',
                                cursor: loadingLink ? 'default' : 'pointer',
                            }}
                            onClick={getDownloadLink}
                        >
                            {t('viewEvidence')}
                            {loadingLink && (
                                <LoadingState
                                    size={13}
                                    styles={{ ml: 1.5, justifyContent: 'start', width: 'fit-content' }}
                                />
                            )}
                        </Typography>
                    </Box>
                ) : (
                    <Box sx={{ marginTop: '2rem', display: 'flex', alignItems: 'center', marginLeft: '1.6rem' }}>
                        <img src={EmptyScan} />
                        <Typography
                            sx={{
                                color: theme.palette.gray[800],
                                fontWeight: 400,
                                fontSize: 16,
                                marginLeft: '1rem',
                            }}
                        >
                            {t('noEvidence')}
                        </Typography>
                    </Box>
                )}
            </Box>
        </AppModal>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { RequestCodeReview })(ExemptionReasonModal);
