import React, { useState } from 'react';
import { toast } from 'react-toastify';

import AppDeleteModal from 'components/new_components/AppDeleteModalNew';

// redux
import { useDispatch } from 'react-redux';
import { DeleteTemplate } from 'store/actions/riskAssessmentActions';

const DeleteModal = (props) => {
    const { open, handleClose, id } = props;

    const [deleting, setDeleting] = useState(false);
    const dispatch = useDispatch();

    //function
    const handleDelete = async () => {
        setDeleting(true);
        const res = await dispatch(DeleteTemplate(id));
        setDeleting(false);
        if (res?.success) {
            toast.success('Successfully deleted a risk template.');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <AppDeleteModal
            open={open}
            handleClose={handleClose}
            deleting={deleting}
            onDelete={handleDelete}
            actionsPosition="center"
            title="Delete Template?"
            sub_title="This action cannot be undone. Are you sure you want to proceed??"
        />
    );
};
export default DeleteModal;
