import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Divider, Input, Modal, Row, Tabs } from 'antd';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';

// redux
import { connect } from 'react-redux';
import { RegisterUser, UpdateUserDetails } from 'store/actions/authActions';
import { UpdateAuditorDetails } from 'store/actions/adminActions';
import UploadSignature from 'views/merchant/audit/AuditSignature/UploadSignature';
import DrawSignature from 'views/merchant/audit/AuditSignature/DrawSignature';
import { dataURLtoFile } from 'utils';
import { Cancel } from '@mui/icons-material';

const AuditorModal = (props) => {
    const { open, mode, modalData, closeModal, RegisterUser, UpdateUserDetails, UpdateAuditorDetails } = props;

    // hooks
    const signatureRef = useRef();

    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState('0');
    const [user, setUserData] = useState({});
    const [company_name, setCompany_name] = useState({});
    const [image, setImage] = useState(null);

    // functions
    const closeAll = () => {
        closeModal();
        setCurrentTab('0');
        clearSignature();
        setImage(null);
    };

    /*
     **Handles the modal form submission which includes user info creation/update, auditor info update, and signature update
     */
    const handleSubmit = async (e) => {
        e.preventDefault();

        // the signature image
        const signatureImage = currentTab === '0' ? image : getSignatureImg();

        // for add mode, signature must be present
        if (mode === 'Add' && !signatureImage) return toast.error('Please, input a signature for this auditor');

        const payload = {
            first_name: user?.first_name,
            last_name: user?.last_name,
            email: user?.email,
            company_name,
            password: user?.password,
        };
        let res;
        if (mode === 'Add') {
            // create user and patch auditor
            setLoading(true);
            res = await RegisterUser({ ...payload, user_type: 'auditor' });
            if (!res?.success) {
                setLoading(false);
                return toast.error(res?.message);
            } else {
                const id = res?.data?.organization?.id || data?.id;
                updateAuditor(id, signatureImage);
            }
        } else {
            // patch user and auditor
            setLoading(true);
            res = await UpdateUserDetails(payload, user?.id);
            if (!res?.success) {
                setLoading(false);
                return toast.error(res?.message);
            } else {
                const id = res?.data?.organization?.id || data?.id;
                updateAuditor(id, signatureImage);
            }
        }
    };
    const updateAuditor = async (id, signature) => {
        const payload = new FormData();
        payload.append('mailing_address', data?.mailing_address);
        payload.append('phone_number', data?.phone_number);
        payload.append('website', data?.website);
        payload.append('assessor_pci_credentials', data?.assessor_pci_credentials);
        payload.append('auditor_signature', signature);
        payload.delete(typeof payload.get('auditor_signature') === 'string' ? 'auditor_signature' : null);
        const res = await UpdateAuditorDetails(payload, id);
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };
    const handleTextChange = (e) => {
        const { value, name } = e.target;
        setData({ ...data, [name]: value });
    };
    const handleUserDataChange = (e) => {
        const { value, name } = e.target;
        setUserData({ ...user, [name]: value });
    };
    const getSignatureImg = () => {
        if (!signatureRef.current) return;
        const signURL = signatureRef.current.toDataURL();
        const image = dataURLtoFile(signURL, 'signature.png');
        return image;
    };
    const clearSignature = () => {
        if (!signatureRef.current) return;
        signatureRef.current.clear();
    };

    // useEffect
    useEffect(() => {
        if (open && modalData) {
            const { name, auditor_signature, owner_detail, ...otherData } = modalData;
            setUserData(owner_detail || {});
            setCompany_name(name);
            setData(otherData || {});
            setImage(auditor_signature);
        }
    }, [open, modalData]);

    // constants
    const tabs = [
        { key: '0', label: 'Upload signature' },
        { key: '1', label: 'Draw Signature' },
    ];

    return (
        <Modal title={`${mode} an auditor`} open={open} onCancel={closeAll} footer={null} destroyOnClose width={1100}>
            <form onSubmit={handleSubmit}>
                <Row className="">
                    <Col span={8}>
                        <div className="ant-menu-item-group-title flex justify-start items-center text-[#acaaaf] space-x-2">
                            <span className="flex h-5 w-5 justify-center items-center border rounded-full">1</span>
                            <span>Add signature</span>
                        </div>
                        <Tabs
                            items={tabs}
                            defaultActiveKey={currentTab}
                            onChange={(e) => setCurrentTab(e)}
                            className="mt-3"
                        />
                        <div className="border-2 border-dashed border-sky-500 rounded-lg relative h-64">
                            <AppTabPanel value={currentTab} index="0">
                                <UploadSignature image={image} setImageFile={setImage} />
                            </AppTabPanel>
                            <AppTabPanel value={currentTab} index="1">
                                <DrawSignature ref={signatureRef} />
                            </AppTabPanel>
                        </div>
                        {currentTab === '1' && (
                            <button
                                className="flex justify-end items-center ml-auto"
                                onClick={clearSignature}
                                type="button"
                            >
                                <span>Clear signature</span>
                                <Cancel sx={{ fontSize: 14, m: 'auto' }} />
                            </button>
                        )}
                    </Col>
                    <Col span={16} className="flex">
                        <Divider type="vertical" className="h-full" />
                        <div className="mb-10 ml-2">
                            <div className="ant-menu-item-group-title flex justify-start items-center text-[#acaaaf] space-x-2">
                                <span className="flex h-5 w-5 justify-center items-center border rounded-full">2</span>
                                <span>Fill neccesary details</span>
                            </div>
                            <Row gutter={16} className="mt-3">
                                <Col span={24} className="my-1">
                                    <label htmlFor="company_name">Company Name</label>
                                    <Input
                                        type="text"
                                        size="large"
                                        name="company_name"
                                        id="company_name"
                                        onChange={(event) => setCompany_name(event.target.value)}
                                        value={company_name || ''}
                                        required
                                        disabled={mode !== 'Add'}
                                    />
                                </Col>
                                <Col span={12} className="my-1">
                                    <label htmlFor="company_website">Company Website</label>
                                    <Input
                                        type="text"
                                        size="large"
                                        name="website"
                                        id="company_website"
                                        onChange={handleTextChange}
                                        value={data.website || ''}
                                        required
                                    />
                                </Col>
                                <Col span={12} className="my-1">
                                    <label htmlFor="mailing_address">Mailing Address</label>
                                    <Input
                                        type="text"
                                        size="large"
                                        name="mailing_address"
                                        id="mailing_address"
                                        onChange={handleTextChange}
                                        value={data.mailing_address || ''}
                                        required
                                    />
                                </Col>
                                <Col span={12} className="my-1">
                                    <label htmlFor="first_name">Lead Assessor First Name</label>
                                    <Input
                                        type="text"
                                        size="large"
                                        name="first_name"
                                        id="first_name"
                                        onChange={handleUserDataChange}
                                        value={user.first_name || ''}
                                        required
                                        disabled={mode !== 'Add'}
                                    />
                                </Col>
                                <Col span={12} className="my-1">
                                    <label htmlFor="last_name">Lead Assessor Last Name</label>
                                    <Input
                                        type="text"
                                        size="large"
                                        name="last_name"
                                        id="last_name"
                                        onChange={handleUserDataChange}
                                        value={user.last_name || ''}
                                        required
                                        disabled={mode !== 'Add'}
                                    />
                                </Col>
                                <Col span={12} className="my-1">
                                    <label htmlFor="assessor_phone_number">Lead assessor phone</label>
                                    <Input
                                        type="text"
                                        size="large"
                                        name="phone_number"
                                        id="assessor_phone_number"
                                        onChange={handleTextChange}
                                        value={data.phone_number || ''}
                                        required
                                    />
                                </Col>
                                <Col span={12} className="my-1">
                                    <label htmlFor="user_email">Lead assessor email</label>
                                    <Input
                                        type="text"
                                        size="large"
                                        name="email"
                                        id="user_email"
                                        onChange={handleUserDataChange}
                                        value={user.email || ''}
                                        required
                                        disabled={mode !== 'Add'}
                                    />
                                </Col>
                                <Col span={12} className="my-1">
                                    <label htmlFor="assessor_pci_credentials">Lead assessor pci credentials</label>
                                    <Input
                                        type="text"
                                        size="large"
                                        name="assessor_pci_credentials"
                                        id="assessor_pci_credentials"
                                        onChange={handleTextChange}
                                        value={data.assessor_pci_credentials || ''}
                                        required
                                    />
                                </Col>
                                {mode === 'Add' && (
                                    <Col span={12} className="my-1">
                                        <label htmlFor="password">Password</label>
                                        <Input
                                            type="password"
                                            size="large"
                                            name="password"
                                            id="password"
                                            onChange={handleUserDataChange}
                                            value={user.password || ''}
                                            required
                                        />
                                    </Col>
                                )}
                                <Col className="mt-4" span={24}>
                                    <Button type="primary" htmlType="submit" loading={loading}>
                                        {mode === 'Add' ? 'Submit' : 'Update'}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { RegisterUser, UpdateUserDetails, UpdateAuditorDetails })(AuditorModal);
