export const PREVIEW_COURSE = {
    title: ' General Data Protection Regulation (GDPR)',
    description:
        'Data protection regulations can be difficult to comprehend and apply. As a result, firms that fail to comply with GDPR may face penalties. \nThis free online course provided by the Smartcomply will teach you everything you need to know about GDPR to protect yourself and your company from financial loss.',
    videos: [
        {
            id: 0,
            title: 'Introduction to Data Protection',
            description: 'Course description 1',
            video_number: 1,
            file: 'https://smartcomply.s3.amazonaws.com/production_ID_3833491.mp4',
        },
        {
            id: 1,
            title: 'Introduction to GDPR',
            description: 'Course description 2',
            video_number: 2,
            file: 'https://smartcomply.s3.amazonaws.com/training/Sample_Videos___Dummy_Videos_For_Demo_Use.mp4',
        },
    ],
};
