import React from 'react';

//core component
import {
    Box,
    Card,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemButton,
    Stack,
    CircularProgress,
} from '@mui/material';
import AppPagination from 'components/new_components/AppPagination';
import releaseIcon from 'assets/img/releaseIcon.svg';
import { KeyboardArrowRight } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { releaseDateFormat } from 'utils';
//translation
import { useTranslation } from 'react-i18next';
const ReleasesAside = ({ data, selected, onClick, page, dataLength, onPageChange, loading }) => {
    const theme = useTheme();

    //translation
    const { t } = useTranslation('common');

    const asideContent = (
        <>
            <Typography
                sx={{
                    px: 2,
                    mb: 4,
                    color: '#000000',
                    fontSize: '24px',
                    fontWeight: 700,
                }}
                variant="h5"
            >
                {t('releases')}
            </Typography>
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 3,
                        width: '100%',
                        height: '85%',
                    }}
                >
                    <CircularProgress sx={{ color: theme.palette.gray[400], transition: 'color .3s' }} />
                </Box>
            ) : (
                <>
                    <List>
                        {data?.map((release) => {
                            return (
                                <ListItem sx={{ p: 0 }} key={release?.id}>
                                    <ListItemButton
                                        sx={{
                                            pl: 1,
                                            backgroundColor:
                                                selected === release?.id ? theme.palette.secondary[100] : '#ffffff',
                                            '&.MuiListItemButton-root': {
                                                '&:hover': {
                                                    backgroundColor: theme.palette.secondary[100],
                                                },
                                            },
                                        }}
                                        onClick={() => onClick(release?.id)}
                                    >
                                        <ListItemIcon>
                                            <img src={releaseIcon} alt="releaseIcon" />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    sx={{ flex: 1 }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: theme.palette.primary[900],
                                                            fontWeight: 700,
                                                            fontSize: '16px',
                                                        }}
                                                    >
                                                        {`${t('releaseNote')} ${releaseDateFormat(
                                                            release?.date_created
                                                        )}`}
                                                    </Typography>
                                                    <KeyboardArrowRight sx={{ color: theme.palette.primary[900] }} />
                                                </Stack>
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                    <Stack alignItems="center" justifyContent="center">
                        <AppPagination page={page} dataLength={dataLength} noPerPage={10} onPageChange={onPageChange} />
                    </Stack>
                </>
            )}
        </>
    );

    return (
        <>
            <Box
                component={Card}
                sx={{
                    width: '75%',
                    background: '#FFFFFF',
                    boxShadow:
                        '-23px 170px 68px rgba(209, 209, 209, 0.01), -13px 95px 58px rgba(209, 209, 209, 0.05), -6px 42px 43px rgba(209, 209, 209, 0.09), -1px 11px 24px rgba(209, 209, 209, 0.1), 0px 0px 0px rgba(209, 209, 209, 0.1)',
                    borderRadius: '10px',
                    display: { xs: 'none', md: 'block' },
                    height: '100%',
                    ml: 0,
                }}
            >
                {asideContent}
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' }, ml: '0 !important' }}>{asideContent}</Box>
        </>
    );
};

export default ReleasesAside;
