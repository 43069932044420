import {
    ALL_PLANS,
    ALL_FEATURES,
    ALL_STANDARDS_PRICES,
    ALL_PAYMENT_METHODS,
    ALL_PLAN_REQUESTS,
    ALL_CUSTOM_PLAN_REQUESTS,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case ALL_PLANS:
            return {
                ...state,
                all_plans: action.payload,
            };
        case ALL_FEATURES:
            return {
                ...state,
                all_features: action.payload,
            };
        case ALL_STANDARDS_PRICES:
            return {
                ...state,
                all_standards_prices: action.payload,
            };
        case ALL_PAYMENT_METHODS:
            return {
                ...state,
                all_payment_methods: action.payload,
            };
        case ALL_PLAN_REQUESTS:
            return {
                ...state,
                all_plan_requests: action.payload,
            };
        case ALL_CUSTOM_PLAN_REQUESTS:
            return {
                ...state,
                all_custom_plan_requests: action.payload,
            };
        case 'CLEAR_DATA':
            return {};
        default:
            return state;
    }
};
