import React, { forwardRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, MenuItem, Stack, Typography } from '@mui/material';

// core component
import GroupCard from '../../common/GroupCard';

//translations
import { useTranslation } from 'react-i18next';
import { MoreVert } from '@mui/icons-material';
import AppTableActionMenu from 'components/new_components/app-table/AppTableActionMenu';
import { getType } from 'utils';

const RequirementCard = forwardRef((props, ref) => {
    const theme = useTheme();
    const {
        id,
        requirement_number,
        title,
        metric,
        resourceLabel,
        onViewChange,
        openTaskAssignModal,
        isRequirementAssigned,
        isSubRequirementAssigned,
        resourceAssigned,
    } = props;
    const [anchor, setAnchor] = useState(null);

    //translation
    const { t } = useTranslation('compliance');

    const openMenu = (e) => {
        e.stopPropagation();
        setAnchor(e.target);
    };
    const closeMenu = () => setAnchor(null);
    const role = getType('role');

    const handleOpenTaskModal = (e) => {
        e.stopPropagation();
        openTaskAssignModal('requirement', {
            requirement: id,
            is_document: resourceLabel !== 'Questions Answered',
            standard: 'pcidss',
        });
        closeMenu();
    };

    const handleCardClick = () => {
        if (metric?.totalValue) {
            onViewChange(id);
        }
    };
    return (
        <>
            <GroupCard
                percentCompleted={
                    !metric?.totalValue && !metric?.totalAttendedTo
                        ? 100
                        : (metric?.totalAttendedTo / metric?.totalValue) * 100
                }
                totalValue={metric?.totalValue}
                totalAttendedTo={metric?.totalAttendedTo}
                resourceLabel={resourceLabel}
                onCardClick={handleCardClick}
                empty={metric?.totalValue == 0}
                isCategoryAssigned={isRequirementAssigned}
                isSubCatgoryAssigned={isSubRequirementAssigned}
                isResourceAssigned={resourceAssigned?.includes(requirement_number)}
                ref={ref}
            >
                <Stack direction="row" justifyContent="space-between">
                    <Typography
                        variant="h3"
                        component="h3"
                        sx={{ fontWeight: 600, mb: 0, color: theme.palette.black.main }}
                    >
                        {t('requirement')} {requirement_number}
                    </Typography>
                    <>
                        {!isRequirementAssigned && role !== 'auditor' ? (
                            <>
                                <IconButton
                                    sx={{ color: theme.palette.gray[900], minWidth: 'unset', p: 0 }}
                                    onClick={openMenu}
                                >
                                    <MoreVert />
                                </IconButton>
                                <AppTableActionMenu
                                    anchor={anchor}
                                    closeMenu={closeMenu}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem sx={{ fontSize: 12 }} onClick={handleOpenTaskModal}>
                                        Assign to:
                                    </MenuItem>
                                </AppTableActionMenu>
                            </>
                        ) : null}
                    </>
                </Stack>

                <Typography component="p" sx={{ fontSize: 13, fontWeight: 500, color: theme.palette.gray[600] }}>
                    {title}
                </Typography>
            </GroupCard>
        </>
    );
});

export default RequirementCard;
