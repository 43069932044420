import { Slide, Drawer, Typography, Divider, Box } from '@mui/material';
import React, { useState } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { tabs } from '../utils/constants';
import moment from 'moment';
import { by5riskLevel } from 'utils';
import { by3riskLevel } from 'utils';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const ViewDetailsDrawer = (props) => {
    const { open, handleClose, details } = props;
    const [currentTab, setCurrentTab] = useState(0);

    const amountFormat = (value) =>
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: details?.mitigation_cost_currency ? details?.mitigation_cost_currency : 'NGN',
        })
            .format(value)
            .trim();
    return (
        <Drawer
            open={open}
            anchor="right"
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={handleClose}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    flex: 1,
                    width: '100%',
                    overflow: 'scroll',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <div className="flex justify-between items-center border border-[#F1F5F9] px-[2rem] py-[0.5rem]">
                <div className="cursor-pointer flex items-center gap-3" onClick={handleClose}>
                    <KeyboardBackspaceIcon />
                    <p className="text-[#002C72] text-lg font-bold">Risk Template Details</p>
                </div>
            </div>
            <div className="px-[4rem]">
                <Box>
                    <Box sx={{ display: 'flex', gap: 5, alignItems: 'center', mt: 4 }}>
                        {tabs?.map((tab) => {
                            return (
                                <Box key={tab.index} sx={{ cursor: 'pointer' }}>
                                    <Typography
                                        sx={{
                                            color: currentTab === tab.index ? '#202D66' : '#94A3B8',
                                            fontSize: '15px',
                                            fontWeight: 600,
                                            textAlign: 'right',
                                            cursor: 'pointer',
                                            mt: currentTab === tab.index ? 0 : -0.5,
                                        }}
                                        onClick={() => setCurrentTab(tab.index)}
                                    >
                                        {tab.label}
                                    </Typography>
                                    {currentTab === tab.index && (
                                        <Box
                                            sx={{
                                                backgroundColor: '#202D66',
                                                width: '100%',
                                                mt: 0.4,
                                                pt: 0.5,
                                                borderTopLeftRadius: '10px',
                                                borderTopRightRadius: '10px',
                                            }}
                                        ></Box>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                    <Divider sx={{ mx: '-3.5rem' }} />
                </Box>
            </div>
            <div className="px-[4rem] mt-[2rem]">
                {currentTab === 0 && (
                    <>
                        <div className="flex items-center gap-5">
                            <div className="flex items-center gap-5">
                                <p className="text-[#64748B] text-sm font-semibold">Risk Mitigation Status</p>
                                <div className="w-fit flex items-center gap-1 h-7 !rounded !py-1 !px-2 !border !border-[#E2E8F0] capitalize">
                                    {details?.risk_assessment_status ? (
                                        <>
                                            <div
                                                className={`w-2 h-2 rounded-full ${
                                                    details?.risk_assessment_status?.toLowerCase() === 'resolved'
                                                        ? 'bg-[#55BE8B]'
                                                        : details?.risk_assessment_status?.toLowerCase() ===
                                                          'not_resolved'
                                                        ? 'bg-[#B28C00]'
                                                        : 'bg-[#FF5449]'
                                                }`}
                                            />
                                            <p className="text-[#64748B] font-medium text-[13px]">
                                                {details?.risk_assessment_status?.replace(/_/g, ' ')}
                                            </p>
                                        </>
                                    ) : (
                                        '__'
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center gap-5">
                                <p className="text-[#64748B] text-sm font-semibold">Inherent Risk</p>
                                <p
                                    className="text-white text-sm font-semibold p-1.5 rounded"
                                    style={{
                                        backgroundColor:
                                            details?.matrix === '3x3'
                                                ? by3riskLevel(details?.inherent_risk).color
                                                : by5riskLevel(details?.inherent_risk).color,
                                    }}
                                >
                                    {details?.matrix === '3x3'
                                        ? `${by3riskLevel(details?.inherent_risk).type}`
                                        : `${by5riskLevel(details?.inherent_risk).type}`}
                                </p>
                            </div>
                        </div>
                        <div className="my-[1rem]">
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Risk Template</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    {details?.template_name}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Risk Matrix</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-bold text-[#64748B] pl-4">
                                    {details?.matrix}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Category</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    {details?.category_detail}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Threat</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    {details?.threat_detail?.threat_category_detail?.name} -{' '}
                                    {details?.threat_detail?.name}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Vulnerabilities</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] flex items-center gap-2 pl-4">
                                    {details?.vulnerability_detail?.map((vulnerability, index) => (
                                        <span
                                            className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal p-1 rounded"
                                            key={index}
                                        >
                                            {vulnerability?.name}
                                        </span>
                                    ))}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Existing Controls</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B pl-4">
                                    {details?.existing_controls}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Inherent Likelihood</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    <span className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal p-1 rounded">
                                        {details?.matrix === '3x3'
                                            ? `${by3riskLevel(details?.inherent_likelihood).type}`
                                            : `${by5riskLevel(details?.inherent_likelihood).type}`}
                                    </span>
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Risk Impact on CIA</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    {details?.vulnerabilities}
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Inherent Impact</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    <span className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal p-1 rounded">
                                        {details?.matrix === '3x3'
                                            ? `${by3riskLevel(details?.inherent_impact).type}`
                                            : `${by5riskLevel(details?.inherent_impact).type}`}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </>
                )}
                {currentTab === 1 && (
                    <>
                        <div className="flex items-center gap-5">
                            <p className="text-[#64748B] text-sm font-semibold">Residual Risk</p>
                            <p
                                className="text-white text-sm font-semibold p-1.5 rounded"
                                style={{
                                    backgroundColor:
                                        details?.matrix === '3x3'
                                            ? by3riskLevel(details?.residual_risk).color
                                            : by5riskLevel(details?.residual_risk).color,
                                }}
                            >
                                {details?.matrix === '3x3'
                                    ? `${by3riskLevel(details?.residual_risk).type}`
                                    : `${by5riskLevel(details?.residual_risk).type}`}
                            </p>
                        </div>
                        <div className="my-[1rem]">
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Report risk to</p>
                                {details?.report_risk_to ? (
                                    <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-bold text-[#64748B] pl-4 capitalize">
                                        {details?.report_risk_to}
                                    </p>
                                ) : (
                                    <div className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-bold text-[#64748B] pl-4 capitalize">
                                        <div className="w-fit flex items-center gap-1 h-7 !rounded !py-1 !px-2 !border !border-[#E2E8F0]">
                                            --
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Mitigation Plan</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-bold text-[#64748B] pl-4 capitalize">
                                    {details?.mitigation_plan}
                                </p>
                            </div>
                            {details?.mitigation_plan === 'mitigate' && (
                                <>
                                    <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                            Mitigation Owner
                                        </p>
                                        <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                            {details?.mitigation_owner}
                                        </p>
                                    </div>
                                    <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                            Mitigation Controls
                                        </p>
                                        <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                            {details?.mitigation_controls}
                                        </p>
                                    </div>
                                    <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                            Mitigation timeline
                                        </p>
                                        <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#475569] pl-4">
                                            {moment(details?.timeline_start_date)?.format('DD MMMM, YYYY')} -{' '}
                                            {moment(details?.timeline_end_date)?.format('DD MMMM, YYYY')}
                                        </p>
                                    </div>
                                    <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                        <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">
                                            Mitigation cost ({details?.mitigation_cost_currency})
                                        </p>
                                        <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                            {amountFormat(details?.mitigation_cost_amount)}
                                        </p>
                                    </div>
                                </>
                            )}
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Residual Likelihood</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    <span className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal p-1 rounded">
                                        {details?.matrix === '3x3'
                                            ? `${by3riskLevel(details?.residual_likelihood)?.type}`
                                            : `${by5riskLevel(details?.residual_likelihood)?.type}`}
                                    </span>
                                </p>
                            </div>
                            <div className="items center flex justify-between border-t border-[#F1F5F9]">
                                <p className="w-[40%] py-2 text-sm font-normal text-[#475569]">Residual Impact</p>
                                <p className="w-[60%] border-l border-[#F1F5F9] py-2 pl-1 text-[14px] font-medium text-[#64748B] pl-4">
                                    <span className="border border-[#E2E8F0] text-[#475569] text-[13px font-normal p-1 rounded">
                                        {details?.matrix === '3x3'
                                            ? `${by3riskLevel(details?.residual_impact).type}`
                                            : `${by5riskLevel(details?.residual_impact).type}`}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Drawer>
    );
};

export default ViewDetailsDrawer;
