import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

//core components
import { Grid, Box, Typography } from '@mui/material';
import theme from 'assets/theme/theme';
import AssignTaskModal from './Modals/AssignTaskModal';
import { getType } from 'utils';

const ScanGroupItem = (props) => {
    const { name, passed, failed, total, img, onClick, isScan, group, handleCategory, category } = props;
    const [anchor, setAnchor] = useState(null);
    const [assignTask, setAssignTask] = useState(false);
    const theme = useTheme();

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const role = getType('role');
    return (
        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
            <Box
                sx={{
                    border: `1px solid ${theme.palette.neutral[95]}`,
                    py: 3,
                    px: 1,
                    // boxShadow:
                    //     '-111px 221px 99px rgba(230, 230, 230, 0.01), -62px 124px 83px rgba(230, 230, 230, 0.05), -28px 55px 62px rgba(230, 230, 230, 0.09), -7px 14px 34px rgba(230, 230, 230, 0.1), 0px 0px 0px rgba(230, 230, 230, 0.1)',
                    borderRadius: '5px',
                    height: '100%',
                    maxHeight: '180px',
                    flex: 1,
                    cursor: total ? 'pointer' : 'not-allowed',
                }}
                disabled={!total}
            >
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <Box
                            sx={{ display: 'flex', height: '80px' }}
                            onClick={total ? () => onClick(isScan ? 'scans' : 'exceptions', group) : null}
                        >
                            <img src={img} style={{ width: '2.5rem', height: '2.5rem', objectFit: 'fill' }} />
                            <Box sx={{ ml: 2 }}>
                                <Typography
                                    sx={{
                                        textTransform: 'capitalize',
                                        fontSize: '14',
                                        fontWeight: 600,
                                        lineHeight: '22px',
                                    }}
                                >
                                    {name}
                                </Typography>
                                <Typography
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        fontSize: '13',
                                        fontWeight: 600,
                                        lineHeight: '22px',
                                        mb: 3,
                                    }}
                                >
                                    {total}
                                </Typography>
                            </Box>
                        </Box>
                        {!group.is_assigned_to_current_user && isScan && role !== 'auditor' ? (
                            <Box>
                                <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                                    <MoreVert />
                                </IconButton>
                                <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                                    <MenuItem
                                        sx={{ fontSize: 12 }}
                                        onClick={() => {
                                            closeMenu();
                                            setAssignTask(true);
                                            handleCategory(group);
                                        }}
                                    >
                                        Assign to:
                                    </MenuItem>
                                </Menu>
                            </Box>
                        ) : null}
                    </Box>
                    <Box
                        sx={{ display: 'flex', justifyContent: 'space-between !important', alignItems: 'center' }}
                        onClick={total ? () => onClick(isScan ? 'scans' : 'exceptions', group) : null}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ScanStatus type="passed" count={passed ? passed : 0} />
                            <ScanStatus type="failed" count={failed ? failed : 0} />
                        </Box>
                        {group.is_assigned_to_current_user && (
                            <Typography
                                sx={{
                                    backgroundColor: '#D2A500',
                                    color: '#FFFFFF',
                                    fontWeight: 900,
                                    fontSize: '9px',
                                    float: 'right',
                                    px: 1,
                                    py: 0.5,
                                    borderRadius: '2px',
                                }}
                            >
                                Assigned to you
                            </Typography>
                        )}
                    </Box>
                </Box>
                <AssignTaskModal
                    open={assignTask}
                    onClose={() => setAssignTask(false)}
                    type="Scan constraint"
                    category={category}
                />
            </Box>
        </Grid>
    );
};

const ScanStatus = ({ type, count }) => {
    return (
        <Box
            sx={{
                border: '0.5px solid #B8BCCC',
                borderRadius: '5px',
                px: 0.5,
                py: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '10px',
                mr: 1,
                mt: 1,
            }}
        >
            <span
                style={{
                    height: '10px',
                    width: '10px',
                    borderRadius: '50%',
                    marginRight: '8px',
                    backgroundColor: type === 'passed' ? theme.palette.success[300] : theme.palette.error[500],
                    display: 'inline-block',
                    fontSize: '12px',
                }}
            ></span>
            <Typography sx={{ letterSpacing: '1.2px', fontSize: '10px' }}>
                {count} {type === 'passed' ? 'passed' : 'failed'} instances
            </Typography>
        </Box>
    );
};

export default ScanGroupItem;
