import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FormCreation from '../Operations/FormCreation';

import Criteria from './views/Criteria';
import Items from './views/Items';
import Subcriteria from './views/Subcriteria';

const Blockchain = () => {
    return (
        <Switch>
            <Route path="/admin/blockchain" exact component={Criteria} />
            <Route path="/admin/blockchain/subcriteria/" exact component={Subcriteria} />
            <Route path="/admin/blockchain/subcriteria/:id" exact component={Items} />
            <Route
                path="/admin/blockchain/subcriteria/:id/form"
                exact
                component={(props) => <FormCreation tag="blockchain" {...props} />}
            />
        </Switch>
    );
};

export default Blockchain;
