import { REGISTERED_CLIENTS, CLIENT_ID } from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case REGISTERED_CLIENTS:
            return {
                ...state,
                registered_clients: action.payload,
            };
        case CLIENT_ID:
            return {
                ...state,
                client_id: action.payload,
            };
        case 'CLEAR_DATA':
            return {};
        default:
            return state;
    }
};
