import React from 'react';
import { Dropdown, Menu } from 'antd';
import {
    DeleteOutlined,
    MoreOutlined,
    EyeOutlined,
    CheckSquareOutlined,
    EditOutlined,
    // UserSwitchOutlined
} from '@ant-design/icons';

export const AuditorOperations = (props) => {
    const { openViewDrawer, deleteModal, record, approveAuditor, openModal } = props;
    const menu = (
        <Menu className="tableaction">
            <Menu.Item key="0" onClick={() => openViewDrawer(record)}>
                <EyeOutlined /> View
            </Menu.Item>
            <Menu.Item key="4" onClick={() => openModal('Edit', record)}>
                <EditOutlined /> Edit
            </Menu.Item>
            <Menu.Item key="1" disabled={record?.is_approved} onClick={() => approveAuditor(record?.id)}>
                <CheckSquareOutlined /> Approve
            </Menu.Item>
            <Menu.Item key="3" style={{ color: 'red' }} onClick={() => deleteModal(record)}>
                <DeleteOutlined /> Delete
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <a
                href="#"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                onKeyDown={(e) => e.preventDefault()}
            >
                <MoreOutlined />
            </a>
        </Dropdown>
    );
};
