import React, { useState } from 'react';
import { Box } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import ReporttDeleteModal from './ReportDeleteModal';
import { toast } from 'react-toastify';
import { GetDownloadLink } from 'store/actions/generalActions';
import LoadingState from 'components/new_components/LoadingState';

const TableAction = (props) => {
    const { row } = props;
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [loadingLink, setLoadingLink] = useState(false);

    const openDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    const getDownloadLink = async () => {
        if (row?.report) {
            setLoadingLink(true);
            const res = await GetDownloadLink('scans', 'risk_assessment_report', row.id)();
            setLoadingLink(false);
            if (res?.success) {
                // open decoded url
                window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
                // open original file url
                window.open(row?.report, '_blank');
            }
        }
    };

    return (
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'end', alignItems: 'center' }}>
            <span onClick={getDownloadLink} className="cursor-pointer">
                {loadingLink ? (
                    <LoadingState size={20} />
                ) : (
                    <FileDownloadIcon color="primary" sx={{ width: '20px', height: '20px' }} />
                )}
            </span>
            <DeleteIcon
                color="primary"
                sx={{ width: '20px', height: '20px', cursor: 'pointer' }}
                onClick={openDeleteModal}
            />
            <ReporttDeleteModal open={deleteModalOpen} handleClose={closeDeleteModal} id={row?.id} />
        </Box>
    );
};

export default TableAction;
