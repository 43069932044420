import React, { useState } from 'react';
import { toast } from 'react-toastify';

// mui component
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

// core components
import AuthViewContainer from './components/AuthViewContainer';
import { AppForm, AppFormInput, AppSubmitButton, AppFormContext } from 'components/new_components/forms';

// validation
import { forgotPasswordValidate } from './utils/authValidation';

// redux
import { connect } from 'react-redux';
import { PasswordReset } from 'store/actions/authActions';
import PageHeader from 'components/new_components/PageHeader';
import { useTranslation } from 'react-i18next';

const ForgotPassword = (props) => {
    const { PasswordReset } = props;
    const theme = useTheme();

    const { t } = useTranslation('auth');
    // state
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({});

    // function
    const handleSubmit = async (values) => {
        setLoading(true);
        const res = await PasswordReset(values);
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            setPage(2);
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <>
            <PageHeader browserTitle="Reset Password | Smartcomply" />
            <AuthViewContainer
                title={t('forgotPassword.header')}
                subtitle={t('forgotPassword.subtitle')}
                altLink={{ text: t('login.header'), to: '/auth/login' }}
                sx={{ width: { xs: '100%', md: '50%' }, mx: 'auto' }}
            >
                {page === 1 ? (
                    <AppForm
                        initialValues={{ email: '' }}
                        onSubmit={handleSubmit}
                        validate={(values) => forgotPasswordValidate(values)}
                    >
                        <AppFormContext getValues={setValues}>
                            <AppFormInput
                                type="email"
                                label={t('register.inputLabel4')}
                                placeholder={t('register.inputLabel4')}
                                name="email"
                            />
                            <AppSubmitButton
                                text={t('forgotPassword.header')}
                                variant="contained"
                                color="primary"
                                loadingPosition="center"
                                sx={{ width: '100%', mt: 3 }}
                                loading={loading}
                            />
                        </AppFormContext>
                    </AppForm>
                ) : (
                    <Typography
                        sx={{
                            color: theme.palette.black.main,
                            textAlign: 'center',
                        }}
                    >
                        {t('forgotPassword.content.part1')}{' '}
                        <Typography component="span" sx={{ fontWeight: 600 }}>
                            {values?.email}
                        </Typography>
                        . {t('forgotPassword.content.part2')}
                    </Typography>
                )}
            </AuthViewContainer>
        </>
    );
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps, {
    PasswordReset,
})(ForgotPassword);
