import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Container, Card, Box, Stack } from '@mui/material';

// core components
import AppTabsBar from 'components/new_components/tabs/AppTabsBar';
import AppStyledTab from 'components/new_components/tabs/AppStyledTab';

const TabLayout = (props) => {
    const theme = useTheme();
    const { children, onTabChange, tab, tabs, link, tabAction, tabVariant, tabFullWidth, ref1, ref2 } = props;

    const handleTabChange = (_, tab) => {
        onTabChange(tab);
    };
    return (
        <Container
            component={Card}
            maxWidth={false}
            elevation={0}
            sx={{
                p: '0 !important',
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
            }}
        >
            <Box
                sx={{
                    borderBottom: '2px solid',
                    borderColor: theme.palette.gray[600] + '20',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <AppTabsBar
                    value={tab}
                    onChange={handleTabChange}
                    sx={{
                        flex: 1,
                    }}
                    variant={tabFullWidth ? 'fullWidth' : 'scrollable'}
                >
                    {tabs.map((tab, index) => (
                        <AppStyledTab
                            label={tab.name}
                            id={tab.id}
                            tabVariant={tabVariant}
                            key={tab.id}
                            disabled={tab?.disabled}
                            link={link}
                            tab={tab}
                            ref={index === 0 ? ref1 : index === 1 ? ref2 : null}
                        />
                    ))}
                </AppTabsBar>
                {tabAction && <Box sx={{ mr: 3 }}>{tabAction}</Box>}
            </Box>
            <Stack sx={{ flex: 1 }}>{children}</Stack>
        </Container>
    );
};

export default TabLayout;
