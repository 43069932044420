export const getAvailableUserRoles = (hasSupport, isAdd) => {
    const roles = [
        { value: 'user', name: 'User' },
        { value: 'admin', name: 'Admin' },
        { value: 'auditor', name: 'Auditor' },
        // { value: "support", name: "Support" }
    ];
    if (isAdd) {
        roles.push({ value: 'support', name: 'Support' });
    }
    return roles;
};
export const initialUserValues = {
    email: '',
    role: '',
    auditor_standards: [],
    support_user: '',
    routes: [],
};
// mock data
export const users = [
    {
        name: 'Young Abey',
        avatar: '',
        email: 'abiodunadebambo44@gmail.com',
        role: 'user',
        id: 1,
    },
    {
        name: '',
        avatar: '',
        email: 'abiodunadebambo44+1@gmail.com',
        role: 'admin',
        id: 2,
    },
    {
        name: '',
        avatar: '',
        email: 'abiodunadebambo44+10@gmail.com',
        role: 'support',
        id: 3,
    },
    {
        name: '',
        avatar: '',
        email: 'abiodunadebambo44+10@gmail.com',
        role: 'auditor',
        id: 3,
    },
];
