import { SELECT_GUIDE_STEPS, ENABLE_GUIDE_STEPS, USERGUIDE_INFO } from '../constants';
import axiosInstance from '../../utils/https';

// merchant
export const ToggleEnableGuideSteps = (enable, page) => (dispatch) => {
    dispatch({ type: ENABLE_GUIDE_STEPS, payload: enable });
    dispatch({ type: SELECT_GUIDE_STEPS, payload: page });
};

export const GetUserGuideDetails = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/user_guide`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: USERGUIDE_INFO, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UpdateOneUserGuide = (credentials) => async (dispatch, getState) => {
    const id = getState()?.guideReducers?.userguide_info?.[0]?.id;
    try {
        const res = await axiosInstance().patch(`/user_mgt/user_guide/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetUserGuideDetails());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
