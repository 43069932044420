//calculate
export const GetRemaining = (allowedNumber, addedNumber) => {
    const configNumber = 2; //alert display if allowedNumber is remaining 2
    let reminderCount;
    let showReminder;
    const calculatedReminder = allowedNumber - addedNumber;
    if (calculatedReminder <= 0) {
        reminderCount = 0;
    } else {
        reminderCount = calculatedReminder;
    }
    showReminder = reminderCount <= configNumber;
    return { reminderCount, showReminder };
};
