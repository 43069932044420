import { VideoCameraAddOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Row, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { UpdateCourseVideo, AddCourseVideo } from 'store/actions/trainingActions';

const { Dragger } = Upload;

const VideosModal = (props) => {
    const { mode, open, handleClose, record, UpdateCourseVideo, AddCourseVideo, course } = props;

    // states
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // functions
    const closeModal = () => {
        handleClose();
        setData({});
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (mode === 'Add' && (!data?.file || !data?.file?.type?.includes('video/')))
            return toast.info('Please, upload a video file.');
        setLoading(true);
        const payload = { ...data, course };
        const formData = new FormData();
        for (let key in payload) {
            formData.append(key, payload[key]);
        }
        const res = mode === 'Add' ? await AddCourseVideo(formData) : await UpdateCourseVideo(formData, record?.id);
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            closeModal();
        } else {
            toast.error(res?.message);
        }
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    // useEffect
    useEffect(() => {
        if (open) {
            setData({ title: record?.title, description: record?.description });
        }
    }, [record, open]);

    // constants
    const draggers = {
        name: 'file',
        action: null,
        multiple: false,
        accept: 'video/*',
        maxCount: 1,
        onChange: (info) => {
            setData((current) => ({ ...current, file: info.fileList[0]?.originFileObj }));
        },
    };

    return (
        <Modal open={open} onCancel={closeModal} title={`${mode} a video`} footer={null} destroyOnClose>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="title">Course Title</label>
                            <Input
                                size="large"
                                name="title"
                                id="title"
                                onChange={handleTextChange}
                                value={data?.title || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="description">Course Description</label>
                            <Input.TextArea
                                size="large"
                                name="description"
                                id="description"
                                onChange={handleTextChange}
                                value={data?.description || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="mb-4">
                            <label>Upload Certification</label>
                            <Dragger {...draggers}>
                                <span className="flex justify-center ant-upload-drag-icon">
                                    <VideoCameraAddOutlined />
                                </span>
                                <p className="ant-upload-text">Click or drag video file to this area to upload</p>
                            </Dragger>
                        </div>
                    </Col>
                    <Button htmlType="submit" type="primary" loading={loading}>
                        Submit
                    </Button>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, { UpdateCourseVideo, AddCourseVideo })(VideosModal);
