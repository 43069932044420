import React, { useEffect, useState } from 'react';
import { Box, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

//redux
import { connect } from 'react-redux';
import { UserAssignedAutomatedScan, UpdateAssignAutomatedScan } from 'store/actions/automatedScansActions';

//core components
import AppViewModal from 'components/new_components/AppViewModal';
import { toast } from 'react-toastify';
import CircularLoader from 'components/Loaders/CircularLoader';
import moment from 'moment';
import { MoreVert } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const AllTasksModal = (props) => {
    const { open, onClose, UserAssignedAutomatedScan, user_assigned_tasks, user_id, UpdateAssignAutomatedScan } = props;
    const [loading, setLoading] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const theme = useTheme();

    // function
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    // async functions
    const assignedAutomatedScan = async () => {
        setLoading(true);
        const res = await UserAssignedAutomatedScan(user_id);
        setLoading(false);
        if (!res.message) {
            toast.error(res.message);
        }
    };

    const updateAssignedAutomatedScan = async (item) => {
        const body = {
            mark_as_completed: true,
            user_completion_date: new Date(),
            completion_status: 'waiting_approval',
            assigned_to: [user_id],
            assigned_by: item?.assigned_by,
            category: item?.category ? item?.category : '',
            subcategory: item?.subcategory ? item?.subcategory : '',
            scans: item?.scans ? item?.scans : '',
        };
        const res = await UpdateAssignAutomatedScan(body, item?.id, user_id);
        closeMenu();
        if (res.success) {
            toast.success(res.message);
        } else {
            toast.error(res.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (open) {
            assignedAutomatedScan();
        }
    }, [open]);

    return (
        <AppViewModal open={open} handleClose={onClose} width={600}>
            <Box sx={{ my: 1.5 }}>
                <Box sx={{ display: 'flex', mx: 3, pb: 2 }}>
                    <ArrowBackIosIcon
                        onClick={onClose}
                        sx={{ width: 20, height: 20, color: theme.palette.gray[30], cursor: 'pointer' }}
                    />
                    <Typography sx={{ fontSize: '13px', fontWeight: 600, color: '#46464A', ml: 2 }}>
                        All task assigned to you
                    </Typography>
                </Box>
                <Divider sx={{ color: theme.palette.neutral[95] }} />
                <Box
                    sx={{
                        mx: 2,
                        my: 3,
                        border: `1px solid ${theme.palette.neutral[95]}`,
                        borderRadius: '5px',
                        p: 2,
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: '12px',
                            color: '#303034',
                            pb: 1,
                            borderBottom: `1px solid ${theme.palette.neutral[95]}`,
                        }}
                    >
                        Tasks
                    </Typography>
                    {loading ? (
                        <CircularLoader />
                    ) : (
                        <>
                            {user_assigned_tasks?.length > 0 ? (
                                <Box sx={{ mt: 3 }}>
                                    {user_assigned_tasks?.map((item) => {
                                        return (
                                            <Box key={item.id}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'flex-start',
                                                        my: 2,
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'flex-start',
                                                        }}
                                                    >
                                                        {item?.completion_status === 'not_completed' ? (
                                                            <ErrorIcon color="error" sx={{ width: 18, height: 18 }} />
                                                        ) : item?.completion_status === 'waiting_approval' ? (
                                                            <CheckCircleIcon
                                                                color="primary"
                                                                sx={{ width: 18, height: 18 }}
                                                            />
                                                        ) : (
                                                            <CheckCircleIcon
                                                                color="success"
                                                                sx={{ width: 18, height: 18 }}
                                                            />
                                                        )}
                                                        <Box sx={{ ml: 1.5 }}>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: 400,
                                                                    fontSize: '12px',
                                                                    color: '#77777A',
                                                                }}
                                                            >
                                                                <strong>{item?.assigned_by_name}</strong> assigned a
                                                                task to you
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: 500,
                                                                    fontSize: '11px',
                                                                    color: '#46464A',
                                                                    borderLeft: '2px solid #5E5E62',
                                                                    backgroundColor: '#F8F8F8',
                                                                    my: 0.5,
                                                                    py: 0.5,
                                                                    px: 1,
                                                                }}
                                                            >
                                                                {item?.automated_scans_tasks?.subcategory === null &&
                                                                item?.automated_scans_tasks?.scans === null
                                                                    ? `Everything in ${item?.automated_scans_tasks?.category}`
                                                                    : null}
                                                                {item?.automated_scans_tasks?.category &&
                                                                item?.automated_scans_tasks?.subcategory
                                                                    ? `${
                                                                          item?.automated_scans_tasks?.category
                                                                      } > ${item?.automated_scans_tasks?.subcategory?.replace(
                                                                          /_/g,
                                                                          ' '
                                                                      )}`
                                                                    : null}
                                                                {item?.automated_scans_tasks?.category &&
                                                                item?.automated_scans_tasks?.subcategory &&
                                                                item?.automated_scans_tasks?.scans
                                                                    ? `${
                                                                          item?.automated_scans_tasks?.category
                                                                      } > ${item?.automated_scans_tasks?.subcategory?.replace(
                                                                          /_/g,
                                                                          ' '
                                                                      )} > ${item?.automated_scans_tasks?.scans?.replace(
                                                                          /_/g,
                                                                          ' '
                                                                      )}`
                                                                    : null}
                                                            </Typography>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: 500,
                                                                        fontSize: '10px',
                                                                        color: '8F9099',
                                                                    }}
                                                                >
                                                                    {moment(item?.date_created).fromNow()}
                                                                </Typography>
                                                                <Typography
                                                                    component={'span'}
                                                                    sx={{
                                                                        fontSize: '10px',
                                                                        fontWeight: 400,
                                                                        border:
                                                                            item?.completion_status ===
                                                                                'waiting_approval' &&
                                                                            '1px solid #002C72',
                                                                        padding:
                                                                            item?.completion_status ===
                                                                                'waiting_approval' && 0.2,
                                                                        ml: 1,
                                                                        py: 0.3,
                                                                        px: 0.5,
                                                                        borderRadius: 1,
                                                                        textTransform: 'capitalize',
                                                                        color:
                                                                            item?.completion_status === 'not_completed'
                                                                                ? '#DE3730'
                                                                                : item?.completion_status ===
                                                                                  'completed'
                                                                                ? '005234'
                                                                                : '#002C72',
                                                                        backgroundColor:
                                                                            item?.completion_status === 'not_completed'
                                                                                ? '#FFEDEA'
                                                                                : item?.completion_status ===
                                                                                  'completed'
                                                                                ? '#F4FFF5'
                                                                                : '',
                                                                    }}
                                                                >
                                                                    {item?.completion_status === 'waiting_approval'
                                                                        ? 'Awaiting Approval'
                                                                        : item?.completion_status?.replace(/_/g, ' ')}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    {item?.completion_status !== 'waiting_approval' && (
                                                        <Box>
                                                            <IconButton onClick={openMenu}>
                                                                <MoreVert sx={{ width: 20, height: 20 }} />
                                                            </IconButton>
                                                            <Menu
                                                                open={Boolean(anchor)}
                                                                anchorEl={anchor}
                                                                onClose={closeMenu}
                                                                PaperProps={{
                                                                    sx: { backgroundColor: '#FFFFFF', boxShadow: 0 },
                                                                }}
                                                            >
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        updateAssignedAutomatedScan(item);
                                                                    }}
                                                                    sx={{ fontWeight: 500, fontSize: '13px' }}
                                                                >
                                                                    Mark as completed
                                                                </MenuItem>
                                                            </Menu>
                                                        </Box>
                                                    )}
                                                </Box>
                                                <Divider sx={{ color: theme.palette.neutral[95] }} />
                                            </Box>
                                        );
                                    })}
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        mt: 20,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: '12px',
                                            color: '#303034',
                                            pb: 1,
                                        }}
                                    >
                                        No Task assigned
                                    </Typography>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </AppViewModal>
    );
};

const mapStateToProps = (state) => {
    return {
        user_assigned_tasks: state?.automatedScansReducers?.user_assigned_tasks,
        user_id: state?.generalReducers?.user_info?.employee?.id,
    };
};
export default connect(mapStateToProps, { UserAssignedAutomatedScan, UpdateAssignAutomatedScan })(AllTasksModal);
