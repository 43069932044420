import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { StackedLineChart } from '@mui/icons-material';
import { Divider, List, ListItem, Typography } from '@mui/material';

// core components
import AppViewModal from 'components/new_components/AppViewModal';
import TabLayout from 'components/new_components/tabs/TabLayout';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
// utils
import { formatSentence } from 'utils';

const ActivitiesViewModal = (props) => {
    const { open, handleClose, activity } = props;
    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [details, setDetails] = useState({});

    // logic function
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };

    useEffect(() => {
        if (open) {
            try {
                setDetails(JSON.parse(JSON.parse(activity?.detail)?.data?.request));
                console.log(JSON.parse(activity?.detail));
            } catch (e) {
                console.log(e);
                setDetails({});
            }
        }
    }, [open]);

    return (
        <AppViewModal
            open={open}
            handleClose={handleClose}
            title="View Details"
            width={500}
            icon={<StackedLineChart />}
        >
            <TabLayout
                onTabChange={handleTabChange}
                tab={currentTab}
                tabs={[
                    { name: 'Activity details', id: 0 },
                    { name: 'Action details', id: 1 },
                ]}
                tabFullWidth
            >
                <AppTabPanel value={currentTab} index={0}>
                    <List sx={{ px: 1.5, py: 0 }}>
                        {[
                            { name: 'User', key: 'name' },
                            { name: 'Email', key: 'email' },
                            { name: 'Activity', key: 'activity' },
                            { name: 'Activity IP Address', key: 'ip' },
                            { name: 'Activity Time', key: 'date' },
                        ]?.map((act) => {
                            return <ViewModalItem name={act.name} value={activity[act?.key]} key={act?.key} />;
                        })}
                    </List>
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={1}>
                    <List sx={{ px: 1.5, py: 0 }}>
                        {Object.keys(details)?.map((act) => {
                            const paramsToExempt = [
                                'merchant',
                                'merchant_id',
                                'auditor',
                                'auditor_id',
                                'password',
                                'answer',
                                'form_id',
                                'subcriteria',
                                'user',
                                'organization',
                            ];
                            return paramsToExempt?.includes(act) ? null : (
                                <ViewModalItem name={formatSentence(act)} value={details?.[act]} key={act} />
                            );
                        })}
                    </List>
                </AppTabPanel>
            </TabLayout>
        </AppViewModal>
    );
};
const ViewModalItem = (props) => {
    const theme = useTheme();
    const { name, value } = props;
    return (
        <>
            <ListItem
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    py: 2,
                }}
            >
                <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                    {name?.replace('_', ' ')}:
                </Typography>
                <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                    {value === 'updated a employee' ? 'updated an employee' : value}
                </Typography>
            </ListItem>
            <Divider sx={{ color: theme.palette.gray[300] }} />
        </>
    );
};
export default ActivitiesViewModal;
