import KonsultLogo from 'assets/img/360_consult_logo.svg';
// import CobaltLogo from 'assets/img/brand/cobalt.svg';

export const requestIntialvalues = {
    name: '',
    ip_address: '',
    half: '',
    asset_type: '',
    technology_stack: '',
    objectives: '',
    is_cloud: '',
    sensitive_data: '',
    target_env: '',
};

export const uploadIntialValues = {
    name: '',
    severity: '',
    uploaded_by: '',
    conducted_date: '',
    ip_address: '',
    file_upload: '',
};

export const pentestVendors = [
    {
        name: '386Konsult',
        logo: KonsultLogo,
    },
    // {
    //     name: 'Cobalt',
    //     logo: CobaltLogo,
    // },
];

export const assetsOptions = [
    {
        name: 'API',
        value: 'API',
    },
    {
        name: 'Cloud Config',
        value: 'Cloud Config',
    },
    {
        name: 'External Network',
        value: 'External Network',
    },
    {
        name: 'Internal Network',
        value: 'Internal Network',
    },
    {
        name: 'Mobile',
        value: 'Mobile',
    },
    {
        name: 'Web',
        value: 'Web',
    },
    {
        name: 'Web + API',
        value: 'Web + API',
    },
    {
        name: 'Web + Mobile',
        value: 'Web + Mobile',
    },
];

export const booleanOptions = [
    {
        name: 'Yes',
        value: true,
    },
    {
        name: 'No',
        value: false,
    },
];

export const targetEnvironmentOptions = [
    {
        name: 'Production',
        value: 'production',
    },
    {
        name: 'Staging',
        value: 'staging',
    },
    {
        name: 'Development',
        value: 'development',
    },
];

export const scheduleOptions = [
    {
        name: 'Run Now',
        value: 'run_now',
    },
    {
        name: 'Monthly',
        value: 'monthly',
    },
    {
        name: 'Biannually',
        value: 'biannual ',
    },
];

export const testTimeOptions = [
    { name: 'During Business Hours', value: 'during business hours' },
    { name: 'After Business Hours', value: 'after business hours' },
    { name: 'Weekend Hours', value: 'weekend hours' },
    { name: 'System Maintenance Window', value: 'system maintenance window' },
];

export const severityOptions = [
    {
        name: 'Low',
        value: 'low',
    },
    {
        name: 'Medium',
        value: 'medium',
    },
    {
        name: 'High',
        value: 'high',
    },
    {
        name: 'Critical',
        value: 'Critical',
    },
];
