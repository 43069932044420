import React, { useState } from 'react';
import { Modal } from 'antd';
import { Checkbox, FormControlLabel } from '@mui/material';
import AppButton from '../new_components/AppButton';
import { toast } from 'react-toastify';

const InHouseAuditModal = ({ isOpen, onClose }) => {
    const [formValue, setFormValue] = useState({ standard: [], isAgreed: false });
    const [errorMsg, setErrorMsg] = useState({});

    const onCancelModal = () => {
        setFormValue((prevState) => ({
            ...prevState,
            standard: [],
        }));
        onClose();
    };

    const handleCheckBox = (e, value) => {
        if (formValue.standard.some((item) => item.id === value.id)) {
            const newCart = formValue.standard.filter((doc) => {
                return doc.id !== value.id;
            });
            setFormValue((prevState) => ({
                ...prevState,
                standard: newCart,
            }));
            return;
        }
        let newArray = [...formValue.standard];
        const newSampleObj = {
            ...value,
        };
        if (e.target.checked) {
            newArray.push(newSampleObj);
        } else {
            for (let i = 0; i < newArray.length; i++) {
                const selectedSample = newArray[i];
                if (selectedSample.id === newSampleObj.id) {
                    newArray.splice(i, 1);
                    break;
                }
            }
        }
        setErrorMsg((prevState) => ({
            ...prevState,
            standardError: '',
        }));
        setFormValue((prevState) => ({
            ...prevState,
            standard: newArray,
        }));
    };
    // console.log('form', formValue);
    const tickConsent = (e) => {
        setFormValue((prevState) => ({
            ...prevState,
            isAgreed: e.target.checked,
        }));
        setErrorMsg((prevState) => ({
            ...prevState,
            consentError: '',
        }));
    };

    const requestAuditor = () => {
        if (formValue.standard.length <= 0) {
            setErrorMsg((prevState) => ({
                ...prevState,
                standardError: 'Please, select at least one standard',
            }));
            return;
        }
        if (!formValue.isAgreed) {
            setErrorMsg((prevState) => ({
                ...prevState,
                consentError: "Oops! We can't proceed without your consent, please, tick the box",
            }));
            return;
        }
        toast.info('Implementation in progress, please try again');
    };

    const standardOptions = [
        {
            id: 1,
            name: 'NDPR',
        },
        {
            id: 2,
            name: 'ISO27001',
        },
        {
            id: 3,
            name: 'SOC2',
        },
        {
            id: 4,
            name: 'PCIDSSS',
        },
        {
            id: 5,
            name: 'BLOCKCHAIN',
        },
        {
            id: 6,
            name: 'ISO22301',
        },
    ];

    return (
        <div>
            <Modal
                open={isOpen}
                closable={true}
                onCancel={onCancelModal}
                footer={null}
                destroyOnClose={true}
                maskClosable={false}
            >
                <div>
                    <h3 className="font-bold textbrandColor text-[18px]">Request For An Auditor</h3>
                </div>
                <div className="pt-8">
                    <p className="text-[#161B28] pl-[11px]">Choose your auditing compliance standard:</p>
                    <form>
                        <div className="w-full pl-[11px]">
                            <div className="grid grid-cols-3 gap-1">
                                {standardOptions?.map((res, index) => (
                                    <React.Fragment key={index}>
                                        <FormControlLabel
                                            control={<Checkbox name="checkedI" color="primary" />}
                                            label={res.name}
                                            onChange={(e) => handleCheckBox(e, res)}
                                        />
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        {errorMsg.standardError !== '' && (
                            <p className="text-red-400 text-sm pl-[11px]">{errorMsg.standardError}</p>
                        )}
                        <div className="flex w-full items-start mt-4">
                            <FormControlLabel
                                control={<Checkbox name="isAgreed" />}
                                label={''}
                                onChange={tickConsent}
                            />
                            <label htmlFor="isAgreed" className="text-[#77777A] text-[12px] font-[400] ml-1">
                                I agree to receive marketing communications from Smartcomply and also subjected to our{' '}
                                <strong>
                                    <a href="https://smartcomplyapp.com/privacy" target="_blank" rel="noreferrer">
                                        privacy
                                    </a>
                                </strong>{' '}
                                and{' '}
                                <strong>
                                    <a href="https://smartcomplyapp.com/terms" target="_blank" rel="noreferrer">
                                        terms.
                                    </a>
                                </strong>
                            </label>
                        </div>

                        {errorMsg.consentError !== '' && (
                            <p className="text-red-400 text-[11px] pb-2">{errorMsg.consentError}</p>
                        )}
                        <AppButton
                            name="Continue"
                            color="primary"
                            variant="contained"
                            sx={{
                                margintTop: '1rem',
                                fontWeight: '500',
                                width: '100%',
                            }}
                            onClick={requestAuditor}
                        />
                    </form>
                </div>
            </Modal>
        </div>
    );
};

export default InHouseAuditModal;
