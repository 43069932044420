import { validation } from 'validate';

export const validateForm = (values, required) => {
    const errors = {};
    if (required) {
        Object?.keys(values)?.forEach((key) => {
            const valid = validation(values[key], 'Field', required[key]);
            if (!valid.isValid) {
                errors[key] = valid.errorMessage;
            }
        });
    } else {
        Object?.keys(values)?.forEach((key) => {
            const valid = validation(values[key], 'Field', true);
            if (!valid.isValid) {
                errors[key] = valid.errorMessage;
            }
        });
    }
    return errors;
};

export const publicCodeReviewValidation = (values) => {
    const errors = {};
    const nameVersionControl = validation(values.version_control_tool, 'Version control tool', true);
    const linkValidate = validation(values.link, 'Repository Link', true);

    if (!nameVersionControl.isValid) {
        errors.version_control_tool = nameVersionControl.errorMessage;
    }
    if (!linkValidate.isValid) {
        errors.link = linkValidate.errorMessage;
    } else {
        return null;
    }

    return errors;
};

export const PrivateCodeReviewValidation = (values) => {
    const errors = {};
    const nameVersionControl = validation(values.version_control_tool, 'Version control tool', true);

    if (!nameVersionControl.isValid) {
        errors.version_control_tool = nameVersionControl.errorMessage;
    } else {
        return null;
    }

    return errors;
};
