import React, { useState } from 'react';

const EliminatioMethod = ({ localStates, setLocalStates, setRiskIdentificationTabStates }) => {
    // const [isInputFocused, setIsInputFocused] = useState(false);
    // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [tempMethod, setTempMethod] = useState('');

    // useEffect(() => {
    //     if (isInputFocused) {
    //         if (defaultMethods.length === 0) {
    //             setIsDropdownOpen(false);
    //         } else {
    //             setIsDropdownOpen(true);
    //         }
    //     } else {
    //         setIsDropdownOpen(false);
    //     }
    // }, [isInputFocused, defaultMethods.length]);

    return (
        <div className="relative" onMouseLeave={() => setTempMethod('')}>
            <div className="relative">
                <textarea
                    type="text"
                    placeholder="Enter an elimination method"
                    value={localStates.risk_elimination_method}
                    onChange={(event) => {
                        setLocalStates((prev) => ({
                            ...prev,
                            risk_elimination_method: event.target.value,
                        }));

                        setTempMethod('');
                    }}
                    onBlur={(event) => {
                        setRiskIdentificationTabStates((prev) => ({
                            ...prev,
                            risk_elimination_method: event.target.value || tempMethod,
                        }));
                    }}
                    className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 !pr-5 !min-h-[90px]  !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                />
            </div>
        </div>
    );
};
export default EliminatioMethod;
