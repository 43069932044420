import React from 'react';
import { Box, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

const CardItem = (props) => {
    const { handleOpenModal, group, all_exempted_evidences } = props;
    const get_all_exceptions = all_exempted_evidences?.filter((item) => item?.evidence_detail?.lifecycle === group?.id);
    return (
        <div onClick={() => handleOpenModal(group)}>
            <Box
                sx={{
                    textAlign: 'left',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Typography
                    sx={{
                        color: '#475569',
                        fontWeight: 600,
                        fontSize: '15px',
                    }}
                >
                    {group?.title}
                </Typography>
                <Box
                    sx={{
                        border: '1px solid #E2E8F0',
                        borderRadius: 1,
                        cursor: 'pointer',
                    }}
                >
                    <ChevronRight
                        sx={{
                            color: '#64748B',
                            fontSize: 32,
                        }}
                    />
                </Box>
            </Box>
            <Typography
                sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#64748B',
                }}
            >
                {get_all_exceptions?.length} exempted
            </Typography>
        </div>
    );
};

export default CardItem;
