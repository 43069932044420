import React, { useState } from 'react';
//validate
import { connect } from 'react-redux';
import { UploadVendorQuestionnaire } from 'store/actions/vendorActions';
import { toast } from 'react-toastify';
import { accept } from 'validate';

//translations
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AppCenteredModalNew from 'components/new_components/AppCenteredModalNew';
import FolderIcon from 'assets/img/folder.svg';
import XslIcon from 'assets/img/xsl.svg';

const UploadQuestionnaireModal = ({ open, handleClose, UploadVendorQuestionnaire }) => {
    const [loadingState, setloadingState] = useState(false);
    const [file_upload, setFile_upload] = useState(null);
    const [error, setError] = useState(false);

    //translation
    const { t } = useTranslation('vendorManagement');

    //accept props
    const { excel } = accept;

    const onClose = () => {
        handleClose();
    };
    const dragEnter = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#C4C4C4';
        target.style.backgroundColor = '#A58980';
    };

    const dragLeave = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#A58980';
        target.style.backgroundColor = 'transparent';
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type.includes('image')) {
            const reader = new FileReader();
            reader.onload = () => {
                setFile_upload(reader.result);
            };
            reader.readAsDataURL(file);
            convertedBase64(file).then(() => {});
        } else {
            // enqueueSnackbar('Invalid Data Dropped', { variant: 'error' });
        }
        dragLeave();
    };

    const uploadImage = async (event) => {
        const input = event.target;
        const file = input.files?.item(0);
        const fileSize = file.size / 1000 / 1000;
        if (fileSize > 2) {
            toast.info('File too large, minimum of 5MB');
            setFile_upload('');
        } else {
            // const base64 = await convertedBase64(file);
            setFile_upload(file);
        }
    };
    const convertedBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const filReader = new FileReader();
            filReader.readAsDataURL(file);
            filReader.onload = () => {
                resolve(filReader.result);
            };
            filReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleSubmit = async () => {
        if (file_upload === null) {
            setError(true);
        } else {
            const formdata = new FormData();
            formdata.append('file_upload', file_upload);
            setloadingState(true);
            const res = await UploadVendorQuestionnaire(formdata);
            setloadingState(false);
            if (res?.success) {
                toast.success(res?.message);
                onClose();
            } else {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    toast.error(res?.message);
                }
            }
        }
    };
    return (
        <AppCenteredModalNew
            open={open}
            handleClose={onClose}
            title={
                <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                    {' '}
                    {t('uploadQuestionnaireModal.upload')}
                </Typography>
            }
            width="500px"
            height="470px"
            noShadow
            noClose
            headerAction={
                <Box
                    sx={{
                        border: '1px solid #E2E8F0',
                        borderRadius: 30,
                        width: 30,
                        height: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon sx={{ width: 20, height: 20 }} />
                </Box>
            }
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        mt: 1.5,
                        backgroundColor: '#F8FAFC',
                        height: '80px',
                        mx: '-2rem',
                        mb: '-2rem',
                        px: '2rem',
                        pt: '3rem',
                        pb: '4.5rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit', color: '#334155' }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ textTransform: 'inherit', fontWeight: 600 }}
                        onClick={handleSubmit}
                        disabled={loadingState}
                    >
                        Upload
                    </Button>
                </Box>
            }
        >
            <Box sx={{ my: 2 }}>
                <Typography
                    sx={{ backgroundColor: '#F8FAFC', color: '#64748B', fontSize: '13px', fontWeight: '400', p: 1.5 }}
                >
                    Please make sure the file been uploaded is the specified file format.
                </Typography>

                <Box
                    sx={{
                        // border: `3px dashed ${errors[name] ? '#D91B1B' : theme.palette.primary[900]}`,
                        border: '1px dashed #CBD5E1',
                        borderRadius: 2,
                        padding: 1,
                        minHeight: 150,
                        position: 'relative',
                        mt: 2,
                    }}
                >
                    <Box
                        id="drop-zone"
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={handleDrop}
                        onDragOver={dragOver}
                        sx={{ height: '100%', p: 2 }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <img src={FolderIcon} alt={FolderIcon} />
                            <Typography sx={{ color: '#64748B', fontSize: '12px', fontWeight: 500 }}>
                                Drop your files or click to upload
                            </Typography>
                            <Typography sx={{ color: '#94A3B8', fontSize: '12px', fontWeight: 400 }}>
                                Supported file types: CSV:
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                            <label htmlFor="csv-input">
                                <Box
                                    sx={{
                                        border: '1px solid #E2E8F0',
                                        cursor: 'pointer',
                                        padding: 0.5,
                                    }}
                                >
                                    <Typography sx={{ color: '#475569', fontWeight: 400, fontSize: 12 }}>
                                        Browse
                                    </Typography>
                                </Box>
                            </label>
                            <input
                                type="file"
                                accept={`${excel}`}
                                onChange={(e) => uploadImage(e)}
                                hidden
                                id="csv-input"
                            />
                        </Box>
                        <input type="file" accept={`${excel}`} hidden id="csv-input" />
                        {/* {errors[name] && <FormHelperText error>{formatSentence(errors[name])}</FormHelperText>} */}
                    </Box>
                </Box>
                {error && !file_upload && (
                    <Typography sx={{ fontSize: '10px', fontWeight: 400, color: '#D91B1B', mt: 0.5 }}>
                        Please upload file
                    </Typography>
                )}
                {file_upload && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: 1,
                        }}
                    >
                        <img src={XslIcon} alt="icon" />
                        <Box sx={{ ml: 1 }}>
                            <Typography
                                sx={{ color: '#475569', fontWeight: 400, fontSize: '14px', wordBreak: 'break-word' }}
                            >
                                {file_upload.name}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        </AppCenteredModalNew>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { UploadVendorQuestionnaire })(UploadQuestionnaireModal);
