import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Input, Modal, Row, Button, Select } from 'antd';
// redux
import { connect } from 'react-redux';
import { AddGlossaryItem, UpdateGlossaryItem } from 'store/actions/adminActions';

const GlossaryModal = (props) => {
    const { open, onClose, modalData, UpdateGlossaryItem, AddGlossaryItem } = props;

    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // functions
    const handleClose = () => {
        onClose();
        setData({});
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const payload = { ...data, standard: data?.standard?.join(',') };
        const res = open === 'Add' ? await AddGlossaryItem(payload) : await UpdateGlossaryItem(payload, payload?.id);
        setLoading(false);
        if (res?.success) {
            toast.success('Item added.');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };
    const handleTextChange = (e) => {
        const { value, name } = e.target;
        setData({ ...data, [name]: value });
    };
    const handleTagChange = (value) => {
        setData({ ...data, standard: value });
    };

    // useEffect
    useEffect(() => {
        if (open) {
            setData({ ...modalData, standard: modalData?.standard ? modalData?.standard?.split(',') : [] });
        }
    }, [open]);

    const STANDARDS = ['SOC2', 'PCIDSS', 'NDPR', 'ISO27001', 'General'];

    return (
        <Modal title={`${open} Glossary Item`} open={Boolean(open)} onCancel={handleClose} destroyOnClose footer={null}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24}>
                        <div className="form-group">
                            <label htmlFor="word">Word</label>
                            <Input
                                size="large"
                                name="word"
                                id="word"
                                onChange={handleTextChange}
                                value={data.word || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="form-group">
                            <label htmlFor="meaning">Meaning</label>
                            <Input.TextArea
                                size="large"
                                name="meaning"
                                id="meaning"
                                onChange={handleTextChange}
                                value={data.meaning || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24} className="mb-4">
                        <label htmlFor="standard">Tag</label>
                        <Select
                            size="large"
                            name="standard"
                            id="standard"
                            onChange={handleTagChange}
                            value={data.standard || ''}
                            required
                            style={{ width: '100%' }}
                            mode="multiple"
                        >
                            {STANDARDS.map((standard) => {
                                return (
                                    <Select.Option value={standard} key={standard}>
                                        {standard}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Col>
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, { AddGlossaryItem, UpdateGlossaryItem })(GlossaryModal);
