import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Empty } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const Videos = (props) => {
    const { items, openEditModal, deleteModal } = props;
    const [video, setVideo] = useState('');
    useEffect(() => {
        if (items?.[0]?.file) {
            setVideo(items?.[0]?.file);
        }
    }, [items?.[0]?.file]);
    return (
        <>
            {items?.length ? (
                <Row wrap gutter={24}>
                    {items
                        ?.sort((a, b) => a?.id - b?.id)
                        ?.map((item, index) => (
                            <React.Fragment key={index}>
                                <Col xs={24} md={12} style={{ marginBottom: '1rem' }}>
                                    <Card
                                        loading={false}
                                        actions={[
                                            <EditOutlined key="edit" onClick={() => openEditModal(item)} />,
                                            <DeleteOutlined key="delete" onClick={() => deleteModal(item)} />,
                                        ]}
                                        title={item?.name}
                                        bodyStyle={{ padding: 0 }}
                                    >
                                        <video width="100%" controls key={video}>
                                            <source src={video ? video : ''} />
                                        </video>
                                    </Card>
                                </Col>
                            </React.Fragment>
                        ))}
                </Row>
            ) : (
                <Row justify="center">
                    <Col>
                        <Empty
                            description={
                                <div>
                                    <h3>No Item</h3>
                                    <span>Add a new item </span>
                                </div>
                            }
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};

export default Videos;
