import React, { useState } from 'react';
import { useTheme } from '@mui/styles';
import { Stack, Box } from '@mui/material';
import { WarningAmberOutlined } from '@mui/icons-material';
//core components
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import AppButton from 'components/new_components/AppButton';
import AppLoadingButton from 'components/new_components/AppLoadingButton';

//redux
import { connect } from 'react-redux';
import { RequestASVscan } from 'store/actions/merchantActions';
import { toast } from 'react-toastify';

//translations
import { useTranslation } from 'react-i18next';

const RerunScanModal = ({ open, handleClose, title, subtitle, payload, RequestASVscan }) => {
    const [rerunloading, setRerunLoading] = useState(false);
    const theme = useTheme();

    //translation
    const { t } = useTranslation('pciscans');

    const RerunScanRequest = async () => {
        setRerunLoading(true);
        const res = await RequestASVscan(payload);
        setRerunLoading(false);
        if (res?.success) {
            toast.success('Rerun Scan Request initiated');
            handleClose();
        } else {
            if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                toast.error(res?.message);
            }
        }
    };
    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            width={500}
            actions={
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ my: 1.5 }}>
                    <AppButton
                        name={t('rerunScanModal.button1')}
                        variant="text"
                        sx={{ color: theme.palette.gray[900] }}
                        onClick={handleClose}
                    />
                    <AppLoadingButton
                        text={t('rerunScanModal.button2')}
                        variant="contained"
                        color="primary"
                        loading={rerunloading}
                        loadingPosition="center"
                        onClick={RerunScanRequest}
                    />
                </Stack>
            }
        >
            <Box
                sx={{
                    fontWeight: 500,
                    color: theme.palette.gray[800],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                }}
            >
                <WarningAmberOutlined sx={{ fontSize: '90px', color: theme.palette.primary.main, mb: 1 }} />
                {title && <span style={{ fontSize: '18px', fontWeight: 700 }}>{title}</span>}
                {subtitle && <p style={{ fontSize: '14px', fontWeight: 500, marginTop: '1rem' }}>{subtitle}</p>}
            </Box>
        </AppCenteredModal>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { RequestASVscan })(RerunScanModal);
