import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Row, Button, Col, Select } from 'antd';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { GetAllAuditors, AssignAuditorToMerchant } from 'store/actions/adminActions';
import { toast } from 'react-toastify';

const AssignAuditorModal = (props) => {
    const { open, closeModal, merchant, standard } = props;
    const [loading, setLoading] = useState({});
    const [data, setData] = useState();

    const dispatch = useDispatch();

    //accessing values from the redux store
    const all_auditors = useSelector((state) => state?.adminReducers?.all_auditors);

    const closeAll = () => {
        setData('');
        closeModal();
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const new_auditor = {
            id: data,
            standard: [standard?.toLowerCase()],
        };

        const isAuditorMatching = (auditor, newAuditor) => auditor?.id === newAuditor?.id;

        if (merchant?.smart_auditors?.length) {
            const matchedAuditor = merchant?.smart_auditors.find((auditor) => isAuditorMatching(auditor, new_auditor));

            if (matchedAuditor) {
                const updatedSmartAuditors = merchant.smart_auditors.map((auditor) => {
                    if (isAuditorMatching(auditor, matchedAuditor)) {
                        return {
                            ...auditor,
                            standard: [...auditor.standard, standard?.toLowerCase()],
                        };
                    } else {
                        return auditor;
                    }
                });

                merchant.smart_auditors = updatedSmartAuditors;
            } else {
                merchant.smart_auditors = [...merchant.smart_auditors, new_auditor];
            }
        } else {
            merchant.smart_auditors = [new_auditor];
        }

        setLoading({ ...loading, approving: true });
        const res = await dispatch(AssignAuditorToMerchant(merchant?.id, { smart_auditors: merchant.smart_auditors }));
        setLoading({ ...loading, approving: false });
        if (res?.success) {
            toast.success('Auditor has been assigned to merchant');
            closeModal();
        } else {
            toast.error(res?.message);
        }
    };

    const getAllAuditors = async () => {
        setLoading({ ...loading, content: true });
        const res = await dispatch(GetAllAuditors());
        setLoading({ ...loading, content: false });
        if (!res.success) {
            toast.error(res.message);
        }
    };
    // useEffect
    useEffect(() => {
        getAllAuditors();
    }, []);

    const available_auditors = useMemo(() => {
        if (!open) {
            return all_auditors; // Return all auditors if not open
        }

        const filteredSmartAuditors = merchant?.smart_auditors?.filter((smart_aud) =>
            smart_aud?.standard?.includes(standard?.toLowerCase())
        );
        const filteredAuditors = all_auditors?.filter((aud) =>
            filteredSmartAuditors?.every((smart_aud) => smart_aud?.id !== aud?.id)
        );
        return filteredAuditors;
    }, [open, merchant?.smart_auditors, standard, all_auditors]);

    return (
        <Modal
            title={merchant?.seeked_auditor ? 'Assign Auditor To Merchant' : 'Select Auditor to Assign'}
            visible={open}
            onCancel={closeAll}
            footer={null}
            destroyOnClose={true}
        >
            {/* {merchant?.seeked_auditor ? (
                <Row justify="end">
                    <Col span={24}>
                        {`Merchant "${merchant?.owner_detail?.first_name} ${merchant?.owner_detail?.last_name}" is requesting for auditor "${merchant?.seeked_auditor_detail?.owner_detail?.first_name} ${merchant?.seeked_auditor_detail?.owner_detail?.last_name}". Click on the "Approve" button to approve this request otherwise click on the "Disapprove" button. `}
                    </Col>
                    <Col style={{ marginTop: '1rem' }}>
                        <Button
                            className="ant-btn"
                            loading={loading.disapproving}
                            style={{ margin: '5px' }}
                            onClick={() => handleAuditorAssignmentToMerchant(false)}
                        >
                            Disapprove
                        </Button>
                        <Button
                            className="ant-btn"
                            type="primary"
                            loading={loading.approving}
                            style={{ margin: '5px' }}
                            onClick={() => handleAuditorAssignmentToMerchant(true)}
                        >
                            Approve
                        </Button>
                    </Col>
                </Row>
            ) : ( */}
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="auditor">Choose Auditor</label>
                        <Select
                            size="large"
                            name="data"
                            id="data"
                            // mode="multiple"
                            allowClear
                            onChange={(value) => setData(value)}
                            value={data || ''}
                            required
                            style={{ width: '100%' }}
                            options={available_auditors?.map((auditor) => {
                                return {
                                    label: auditor?.name,
                                    value: auditor?.id,
                                };
                            })}
                        />
                    </Col>
                </Row>
                <Row justify="end" className="mt-3">
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading?.submit}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
            {/* )} */}
        </Modal>
    );
};

export default AssignAuditorModal;
