import React from 'react';
import { useTheme } from '@mui/styles';
//components
import { Stack, Box } from '@mui/material';
import AppButton from 'components/new_components/AppButton';
import AppModal from 'components/new_components/AppModal';
import { HiBadgeCheck } from 'react-icons/hi';

//translation
import { useTranslation } from 'react-i18next';

const AIAutoFillResponseModal = (props) => {
    const { open, handleClose, title } = props;

    const theme = useTheme();

    //translation
    const { t } = useTranslation('compliance');

    return (
        <AppModal
            open={open}
            handleClose={handleClose}
            width={380}
            height="300px"
            actions={
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ my: 1.5 }}>
                    <AppButton
                        name="Close"
                        variant="outlined"
                        sx={{ color: theme.palette.gray[900], maxHeight: 'unset' }}
                        onClick={handleClose}
                    />
                </Stack>
            }
        >
            <Box
                sx={{
                    fontWeight: 500,
                    color: theme.palette.gray[800],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    // textAlign: 'center',
                }}
            >
                <HiBadgeCheck size="50px" style={{ marginBottom: '8px' }} color="#37A372" />
                <span style={{ fontSize: '18px', fontWeight: 700 }}>{t('askAI')}</span>
                <p style={{ fontSize: '14px', fontWeight: 500 }}>
                    {title === 'allTrue' ? t('allBlank') : title === 'someTrue' ? t('onlyBlank') : t('noticed')}
                </p>
            </Box>
        </AppModal>
    );
};

export default AIAutoFillResponseModal;
