import { Button, CardHeader, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//translations
import AppInput from 'components/new_components/AppInput';
import { useTranslation } from 'react-i18next';

const TableTopbar = (props) => {
    const { title, actions, rowSpacing, search, onSearch, tabs, toggleTabs, tabState, ref1, ref2 } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('common');

    return (
        <CardHeader
            title={
                <Stack direction="row" alignItems="center" sx={{ mt: '-0.3rem' }}>
                    {tabs && (
                        <>
                            <Button
                                sx={{
                                    textTransform: 'capitalize',
                                    py: 0.9,
                                    borderRadius: '8px',
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    color:
                                        tabState === 'requested' ? theme.palette.white.main : theme.palette.primary[20],
                                    background: tabState === 'requested' ? '#395BA9' : theme.palette.white.main,
                                    '&:hover': {
                                        background: tabState === 'requested' ? '#395BA9' : theme.palette.white.main,
                                        border: `0.3px solid ${theme.palette.primary[20]}`,
                                    },
                                }}
                                variant="outlined"
                                onClick={() => toggleTabs('requested')}
                                ref={ref1}
                            >
                                {t('requested')}
                            </Button>
                            <Button
                                sx={{
                                    textTransform: 'capitalize',
                                    py: 0.9,
                                    borderRadius: '8px',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    mr: 3,
                                    color:
                                        tabState === 'uploaded' ? theme.palette.white.main : theme.palette.primary[20],
                                    background: tabState === 'uploaded' ? '#395BA9' : theme.palette.white.main,
                                    border: `1px solid ${theme.palette.primary[20]}`,
                                    '&:hover': {
                                        background: tabState === 'uploaded' ? '#395BA9' : theme.palette.white.main,
                                        border: `0.3px solid ${theme.palette.primary[20]}`,
                                    },
                                }}
                                variant="outlined"
                                onClick={() => toggleTabs('uploaded')}
                                ref={ref2}
                            >
                                {t('uploaded')}
                            </Button>
                        </>
                    )}

                    {search ? (
                        <AppInput
                            placeholder={t('search')}
                            sx={{
                                maxWidth: 200,
                                borderColor: theme.palette.gray.new60,
                                '& input::placeholder': {
                                    color: theme.palette.gray[40],
                                    fontWeight: 600,
                                },
                            }}
                            controlStyle={{ mt: -'0.8px', width: 'fit-content' }}
                            onChange={onSearch}
                        />
                    ) : (
                        <Typography variant="h3" component="h3" sx={{ marginBottom: '0!important', color: 'primary' }}>
                            {title}
                        </Typography>
                    )}
                </Stack>
            }
            action={
                <Stack direction="row" spacing={1}>
                    {/* {search && (
                        <AppInput
                            placeholder="Search..."
                            sx={{
                                maxWidth: 200,
                                borderColor: theme.palette.gray[30],
                                '& input::placeholder': {
                                    color: theme.palette.gray[40],
                                    fontWeight: 600,
                                },
                            }}
                            controlStyle={{ mt: 0, width: 'fit-content' }}
                            onChange={onSearch}
                        />
                    )} */}
                    {actions}
                </Stack>
            }
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', pr: 4, pb: rowSpacing ? 0 : 2.5 }}
        />
    );
};

export default TableTopbar;
