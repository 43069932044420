import React, { useContext, useEffect, useMemo, useState } from 'react';
import RiskRegister from './SelfAssessment/RiskRegister';
import IntelligenceAssessment from './IntelligenceAssessment/IntelligenceReport';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import { GetFilteredMerchantRiskAssessments, GetFilteredIntelligenceReport } from 'store/actions/merchantActions';

//translations
import { useTranslation } from 'react-i18next';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

const RegisterIndex = (props) => {
    const {
        GetFilteredMerchantRiskAssessments,
        GetFilteredIntelligenceReport,
        filtered_status_merchant_risk_assessment,
        filtered_status_merchant_intelligence,
        handleType,
    } = props;
    const [page, setPage] = useState(1);
    const [page_, setPage_] = useState(1);
    const [indexValue, setIndexValue] = useState(1);
    const [risk_status, setRisk_status] = useState('');
    const [risk_owner, setRisk_owner] = useState('');
    const [matrix, setMatrix] = useState('');
    const [loading, setloading] = useState(false);

    //translation
    const { t } = useTranslation('riskAssessment');

    //context api for auditor's selected merchant
    const { activeMerchant } = useContext(SelectedMerchantContext);

    const onChangeTab = async (val) => {
        setIndexValue(val);
    };

    const query_params = useMemo(() => {
        const params = {
            ...(risk_status.length && { risk_status: risk_status === 'All' ? '' : risk_status }),
            ...(risk_owner.length && { risk_owner }),
            ...(matrix.length && { matrix: matrix === 'All' ? '' : matrix }),
        };
        return params;
    }, [risk_status, risk_owner, matrix]);

    useEffect(() => {
        const getfilteredlMerchantAssessments = async () => {
            setloading(true);
            const res = await GetFilteredMerchantRiskAssessments(page, query_params, activeMerchant);
            setloading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getfilteredlMerchantAssessments();
    }, [query_params, page, activeMerchant]);

    useEffect(() => {
        const getfilteredlMerchantAssessments = async () => {
            setloading(true);
            const res = await GetFilteredIntelligenceReport(page_, query_params, activeMerchant);
            setloading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getfilteredlMerchantAssessments();
    }, [query_params, page_, activeMerchant]);

    const tabList = [
        {
            id: 1,
            name: t('riskRegisterTab.self'),
            length: filtered_status_merchant_risk_assessment?.results?.length,
        },
        {
            id: 2,
            name: t('riskRegisterTab.intelligence'),
            length: filtered_status_merchant_intelligence?.results?.length,
        },
    ];
    return (
        <div sx={{ mt: 5 }}>
            <div className="flex items-center mt-5">
                {tabList?.map((res, index) => (
                    <React.Fragment key={index}>
                        <div
                            className={`flex items-center cursor-pointer mr-3 transition pb-1 ${
                                indexValue === res.id ? 'border-b-2 border-[#395BA9]' : ''
                            }`}
                            onClick={() => onChangeTab(res.id)}
                        >
                            <span
                                className={`text-[13px] ${indexValue === res.id ? 'textbrandColor' : 'text-[#919094]'}`}
                            >
                                {res.name}
                            </span>
                            <span
                                className={`ml-2 text-[10px] text-[#ffffff] px-[0.5rem] rounded ${
                                    indexValue === res.id ? 'bg-[#395BA9]' : 'bg-[#919094]'
                                }`}
                            >
                                {res.length}
                            </span>
                        </div>
                    </React.Fragment>
                ))}
            </div>
            {indexValue === 1 && (
                <RiskRegister
                    risk_status={risk_status}
                    risk_owner={risk_owner}
                    matrix={matrix}
                    setRisk_status={setRisk_status}
                    setRisk_owner={setRisk_owner}
                    setMatrix={setMatrix}
                    setPage={setPage}
                    page={page}
                    loading={loading}
                    handleType={handleType}
                />
            )}
            {indexValue === 2 && (
                <IntelligenceAssessment
                    risk_status={risk_status}
                    risk_owner={risk_owner}
                    setRisk_status={setRisk_status}
                    setRisk_owner={setRisk_owner}
                    setPage={setPage_}
                    page={page_}
                    loading={loading}
                    handleType={handleType}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        filtered_status_merchant_intelligence: state?.merchantReducers?.filtered_status_intelligence_report,
        filtered_status_merchant_risk_assessment: state?.merchantReducers?.filtered_status_merchant_risk_assessment,
    };
};

export default connect(mapStateToProps, {
    GetFilteredMerchantRiskAssessments,
    GetFilteredIntelligenceReport,
})(RegisterIndex);
