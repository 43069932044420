import React from 'react';
import { Drawer } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { Chip } from '@mui/material';

const ViewSurveyModal = (props) => {
    const { open, handleClose, modalData } = props;
    return (
        <>
            <Drawer
                title={`${modalData?.merchant}  Survey`}
                open={open}
                width="600px"
                getContainer={false}
                onClose={handleClose}
                destroyOnClose={true}
            >
                <div>
                    {modalData?.view_more?.single?.length ? (
                        <div>
                            <h4 className="mb-3 text-[#000000] font-[500]">Single Select Question:</h4>
                            {modalData?.view_more?.single?.map((details, index_) => (
                                <div className="flex items-start mb-3" key={index_}>
                                    <MinusOutlined />
                                    <div className="ml-5">
                                        <h6 className="text-[#000000] text-[14px] font-[600]">{details?.question}</h6>
                                        <div className="mt-3">
                                            {details?.answers?.map((answer, index) => (
                                                <Chip
                                                    key={index}
                                                    variant={'outlined'}
                                                    sx={{ marginLeft: '0.5rem', marginBottom: '1rem' }}
                                                    label={
                                                        <div className="flex justify-between items-center">
                                                            <span className="text-[#000000] text-[12px] font-[500]">
                                                                {answer[0]}
                                                            </span>
                                                            <span className="ml-3 text-[#000000] text-[12px] font-[500]">
                                                                ({answer[1]})
                                                            </span>
                                                        </div>
                                                    }
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}
                    {modalData?.view_more?.multiple?.length ? (
                        <div className="mt-10">
                            <h4 className="mb-3 text-[#000000] font-[500]">Multiple Select Question:</h4>
                            {modalData?.view_more?.multiple?.map((details, index_) => (
                                <div className="flex items-start mb-3" key={index_}>
                                    <MinusOutlined />
                                    <div className="ml-5">
                                        <h6 className="text-[#000000] text-[14px] font-[600]">{details?.question}</h6>
                                        <div className="mt-3">
                                            {details?.answers?.map((answer, index) => (
                                                <Chip
                                                    key={index}
                                                    variant={'outlined'}
                                                    sx={{ marginLeft: '0.5rem', marginBottom: '1rem' }}
                                                    label={
                                                        <div className="flex justify-between items-center">
                                                            <span className="text-[#000000] text-[12px] font-[500]">
                                                                {answer[0]}
                                                            </span>
                                                            <span className="ml-3 text-[#000000] text-[12px] font-[500]">
                                                                ({answer[1]})
                                                            </span>
                                                        </div>
                                                    }
                                                />
                                            ))}
                                        </div>
                                        {details?.other_answers?.length ? (
                                            <div>
                                                <h6 className="text-[#000000] text-[14px] font-[600] mt-3">
                                                    Other Answers..
                                                </h6>
                                                <div className="mt-3">
                                                    {details?.other_answers?.map((answer, index) => (
                                                        <Chip
                                                            key={index}
                                                            variant={'outlined'}
                                                            sx={{ marginLeft: '0.5rem', marginBottom: '1rem' }}
                                                            label={
                                                                <div className="flex justify-between items-center">
                                                                    <span className="text-[#000000] text-[12px] font-[500]">
                                                                        {answer}
                                                                    </span>
                                                                </div>
                                                            }
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}
                    {modalData?.view_more?.text?.length ? (
                        <div className="mt-10">
                            <h4 className="mb-3 text-[#000000] font-[500]">Text Question:</h4>
                            {modalData?.view_more?.text?.map((details, index_) => (
                                <div className="flex items-start flex-wrap" key={index_}>
                                    <MinusOutlined />
                                    <div className="ml-5">
                                        <h6 className="text-[#000000] text-[14px] font-[600]">{details?.question}</h6>
                                        <div className="mt-3">
                                            {details?.answers?.map((answer, index) => (
                                                <Chip
                                                    key={index}
                                                    variant={'outlined'}
                                                    sx={{ marginLeft: '0.5rem' }}
                                                    label={
                                                        <div className="flex justify-between items-center">
                                                            <span className="text-[#000000] text-[12px] font-[500]">
                                                                {answer}
                                                            </span>
                                                        </div>
                                                    }
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
            </Drawer>
        </>
    );
};
export default ViewSurveyModal;
