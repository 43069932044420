import React from 'react';
import { LoadingButton } from '@mui/lab';

const AppLoadingButton = (props) => {
    const { text, sx, ...restProps } = props;
    return (
        <LoadingButton
            {...restProps}
            sx={{
                textTransform: 'unset',
                py: 1,
                px: 2.4,
                fontWeight: '600',
                ...sx,
            }}
        >
            {text}
        </LoadingButton>
    );
};

export default AppLoadingButton;
