import { validation } from 'validate';

export const planRequestValidate = (values) => {
    const errors = {};
    const amountOfEmployee = validation(values.amountOfEmployee, 'Company Size', true);
    const standardsValidate = validation(values.standards, 'select', true);

    if (!amountOfEmployee.isValid) {
        errors.amountOfEmployee = amountOfEmployee.errorMessage;
    }
    if (!standardsValidate.isValid) {
        errors.standards = standardsValidate.errorMessage;
    }
    return errors;
};
