import React from 'react';
import RiskAssessmentSection from './thirdSection/RiskAssessmentSection';
import EmployeeReportSection from './thirdSection/EmployeeReportSection';
import SetupTaskReport from './thirdSection/SetupTaskReport';

const ThirdLayerReport = ({ pricing_plan, dashboardResult }) => {
    return (
        <div className="my-4">
            <div className="flex gap-4 lg:flex-row flex-col">
                <div className="w-full md:w-2/5">
                    <RiskAssessmentSection pricing_plan={pricing_plan} dashboardResult={dashboardResult} />
                </div>
                <div className="w-full md:w-1/3 pl-0 md:pl-3">
                    <EmployeeReportSection pricing_plan={pricing_plan} dashboardResult={dashboardResult} />
                </div>
                <div className="w-full md:w-1/3 pl-0 md:pl-3">
                    <SetupTaskReport dashboardResult={dashboardResult} />
                </div>
            </div>
        </div>
    );
};

export default ThirdLayerReport;
