import React, { useState } from 'react';
// import { toast } from 'react-toastify';

import AppDeleteModal from 'components/new_components/AppDeleteModal';

// redux
import { connect } from 'react-redux';
import { DeleteCodeReviewReport } from 'store/actions/apiComplianceActions';
import { toast } from 'react-toastify';

const ScanDeleteModal = (props) => {
    const { open, handleClose, payload, DeleteCodeReviewReport } = props;

    const [deleting, setDeleting] = useState(false);

    const handleDelete = async () => {
        setDeleting(true);
        const res = await DeleteCodeReviewReport(payload?.id);
        setDeleting(false);
        if (res?.success) {
            toast.success('Successfully deleted a scan.');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <AppDeleteModal
            open={open}
            handleClose={handleClose}
            title="Permanently delete code review?"
            subtitle="All report for this Code review will be lost"
            deleteText="Yes, Delete"
            deleting={deleting}
            onDelete={handleDelete}
            actionsPosition="center"
        />
    );
};
const mapStateToProps = (state) => {
    return { ...state };
};
export default connect(mapStateToProps, { DeleteCodeReviewReport })(ScanDeleteModal);
