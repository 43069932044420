import { Box } from '@mui/material';
import React from 'react';
import LoadingState from 'components/new_components/LoadingState';

import QuestionItem from './QuestionItem';

//redux
import { connect } from 'react-redux';

const SurveyQuestions = (props) => {
    const {
        page,
        setPage,
        status,
        setStatus,
        survey_responses,
        setAnswer,
        answer,
        other_answer,
        setOtheranswer,
        setCompleted,
        setQuestion,
        allSurveys,
        loading,
        perPage,
        merchant_id,
        user,
    } = props;
    return (
        <Box>
            {loading ? (
                <LoadingState />
            ) : (
                <Box
                    sx={{
                        marginTop: '2rem',
                        marginLeft: '2rem',
                    }}
                >
                    {allSurveys?.slice(page * perPage - perPage, page * perPage).map((item, index) => (
                        <QuestionItem
                            key={index}
                            item={item}
                            page={page}
                            setPage={setPage}
                            status={status}
                            setStatus={setStatus}
                            answer={answer}
                            setAnswer={setAnswer}
                            setOtheranswer={setOtheranswer}
                            other_answer={other_answer}
                            setCompleted={setCompleted}
                            allSurveys={allSurveys}
                            survey_responses={survey_responses}
                            setQuestion={setQuestion}
                            merchant_id={merchant_id}
                            user={user}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        all_survey: state?.adminReducers?.all_survey,
        survey_responses: state?.merchantReducers?.survey_responses,
        merchant_info: state?.merchantReducers?.merchant_info,
    };
};
export default connect(mapStateToProps, {})(SurveyQuestions);
