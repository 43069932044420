import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Modal, Row, Spin, Typography } from 'antd';
import BreadCrumb from 'components/Breadcrumb';
import FAQModal from './components/FAQModal';

// redux
import { connect } from 'react-redux';
import { GetAllFAQs, DeleteFAQItem } from 'store/actions/adminActions';

const { Title } = Typography;
const { confirm } = Modal;

const FAQs = (props) => {
    const { GetAllFAQs, all_faqs, DeleteFAQItem } = props;
    // state
    const [modal, setModal] = useState(null);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // functions
    const openModal = (mode, data = {}) => {
        setModal(mode);
        setData(data);
    };
    const closeModal = () => setModal(null);
    // async functions
    const getAllFAQs = async () => {
        setLoading(true);
        const res = await GetAllFAQs();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const deleteItem = (id) => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteFAQItem(id);
                if (res.success) {
                    toast.success('Item deleted successfully.');
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    // useEffect
    useEffect(() => {
        getAllFAQs();
    }, []);

    return (
        <>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'FAQs' }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal('Add')}
                    >
                        Add FAQ item
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {loading ? (
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                ) : (
                    <>
                        <>
                            {all_faqs && all_faqs?.length > 0 ? (
                                <Row wrap gutter={24}>
                                    {all_faqs
                                        ?.sort((a, b) => a?.id - b?.id)
                                        ?.map((item) => (
                                            <Col
                                                xs={24}
                                                md={12}
                                                lg={8}
                                                xxl={6}
                                                key={item.id}
                                                style={{ marginBottom: '1rem' }}
                                            >
                                                <Card
                                                    title={`${item.question}`}
                                                    loading={false}
                                                    actions={[
                                                        <EditOutlined
                                                            key="edit"
                                                            onClick={() => openModal('Edit', item)}
                                                        />,
                                                        <DeleteOutlined
                                                            key="delete"
                                                            onClick={() => deleteItem(item.id)}
                                                        />,
                                                    ]}
                                                >
                                                    <Title level={5} style={{ fontWeight: 400 }}>
                                                        {item.answer}
                                                    </Title>
                                                </Card>
                                            </Col>
                                        ))}
                                </Row>
                            ) : (
                                <Row justify="center">
                                    <Col>
                                        <Empty
                                            description={
                                                <div>
                                                    <h3>No FAQs</h3>
                                                    <span>Add a FAQ item </span>
                                                </div>
                                            }
                                        />
                                    </Col>
                                </Row>
                            )}
                        </>
                    </>
                )}
            </section>
            <FAQModal open={modal} modalData={data} onClose={closeModal} />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_faqs: state?.adminReducers?.all_faqs,
    };
};
export default connect(mapStateToProps, { GetAllFAQs, DeleteFAQItem })(FAQs);
