import { validation } from 'validate';
export const codeValidation = (values) => {
    const errors = {};
    const Code = validation(values.code, 'Code', true);

    if (!Code.isValid) {
        errors.code = Code.errorMessage;
    }
    return errors;
};

export const passwordValidation = (values) => {
    const errors = {};
    const passwordValidate = validation(values.password, 'password', true);
    if (!passwordValidate.isValid) {
        errors.password = passwordValidate.errorMessage;
    }
    return errors;
};
