import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

// core components
import {
    Card,
    Row,
    Col,
    Button,
    Input,
    Space,
    Select,
    TimePicker,
    DatePicker,
    Modal,
    Empty,
    Spin,
    Tooltip,
} from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

// redux
import { connect } from 'react-redux';
import { GetAllFormsByAnnexTwo, DeleteForm } from 'store/actions/adminActions';

// sub-components
const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;

const ClauseFormsView = (props) => {
    const history = useHistory();
    const { clause_id, GetAllFormsByAnnexTwo, all_iso_forms, DeleteForm } = props;

    const [loading, setLoading] = useState(false);

    //useEffect
    useEffect(() => {
        setLoading(true);
        getAllForms();
        setLoading(false);
    }, []);

    // functions
    const getAllForms = async () => {
        const res = await GetAllFormsByAnnexTwo(clause_id);
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    const deleteForm = (form) => {
        confirm({
            title: 'Are you sure you want to delete this form? Note: Deleting this form means you will be deleting all questions and merchant responses associated with this form',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteForm(form?.id, form?.annex_two, 'isms');
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    return (
        <>
            <Row justify="end">
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() =>
                        history.push({
                            pathname: '/admin/iso27001_2022/form',
                            state: {
                                mode: 'create',
                                titleLabel: 'iso_two_title',
                                groupIdLabel: 'annex_two',
                                payload: { annex_two: clause_id },
                            },
                        })
                    }
                >
                    Create Form
                </Button>
            </Row>
            <section className="my-3">
                {loading ? (
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                ) : (
                    <>
                        {all_iso_forms && all_iso_forms?.length > 0 ? (
                            <Row gutter={16}>
                                {all_iso_forms
                                    ?.sort((a, b) => b?.id - a?.id)
                                    ?.map((form) => {
                                        return (
                                            <Col xs={24} style={{ marginBottom: '1rem' }} key={form?.id}>
                                                <Card
                                                    title={form?.iso_two_title}
                                                    extra={
                                                        <Space>
                                                            <Tooltip title="Edit this form" color="blue">
                                                                <Button
                                                                    shape="circle"
                                                                    icon={<EditOutlined />}
                                                                    onClick={() =>
                                                                        history.push({
                                                                            pathname: '/admin/iso27001_2022/form',
                                                                            state: {
                                                                                mode: 'edit',
                                                                                form,
                                                                                titleLabel: 'iso_two_title',
                                                                                groupIdLabel: 'annex_two',
                                                                                payload: { annex_two: clause_id },
                                                                            },
                                                                        })
                                                                    }
                                                                />
                                                            </Tooltip>
                                                            <Tooltip title="Delete this form" color="blue">
                                                                <Button
                                                                    shape="circle"
                                                                    danger
                                                                    icon={<DeleteOutlined />}
                                                                    onClick={() => deleteForm(form)}
                                                                />
                                                            </Tooltip>
                                                        </Space>
                                                    }
                                                    style={{ marginBottom: '1rem' }}
                                                >
                                                    <Row gutter={16}>
                                                        {form.form_fields
                                                            ?.sort((a, b) => a?.id - b?.id)
                                                            ?.map((field) => {
                                                                return (
                                                                    <Col xs={24} md={12} key={field.name}>
                                                                        <div
                                                                            className="form-group"
                                                                            style={{ marginBottom: '1rem' }}
                                                                        >
                                                                            <label>
                                                                                {field?.name}{' '}
                                                                                {field?.required && (
                                                                                    <span style={{ color: 'red' }}>
                                                                                        *
                                                                                    </span>
                                                                                )}
                                                                            </label>
                                                                            {field.type === 'text' ? (
                                                                                <Input type="text" size="large" />
                                                                            ) : field.type === 'number' ? (
                                                                                <Input
                                                                                    type="number"
                                                                                    size="large"
                                                                                    required={field?.required}
                                                                                />
                                                                            ) : field?.type === 'dropdown' ? (
                                                                                <Select
                                                                                    size="large"
                                                                                    style={{ width: '100%' }}
                                                                                    required={field?.required}
                                                                                >
                                                                                    {field?.options
                                                                                        ?.split(',')
                                                                                        ?.map((option, index) => (
                                                                                            <Option
                                                                                                value={option}
                                                                                                key={index}
                                                                                            >
                                                                                                {option}
                                                                                            </Option>
                                                                                        ))}
                                                                                </Select>
                                                                            ) : field?.type === 'time' ? (
                                                                                <TimePicker
                                                                                    defaultValue={moment(
                                                                                        '00:00:00',
                                                                                        'HH:mm:ss'
                                                                                    )}
                                                                                    size="large"
                                                                                    style={{ width: '100%' }}
                                                                                    required={field?.required}
                                                                                />
                                                                            ) : field?.type === 'date' ? (
                                                                                <DatePicker
                                                                                    defaultValue={moment(
                                                                                        '00:00:00',
                                                                                        'HH:mm:ss'
                                                                                    )}
                                                                                    size="large"
                                                                                    style={{ width: '100%' }}
                                                                                    required={field?.required}
                                                                                />
                                                                            ) : (
                                                                                <TextArea
                                                                                    rows={4}
                                                                                    size="large"
                                                                                    required={field?.required}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                );
                                                            })}
                                                    </Row>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Audit Questions</h3>
                                                <span>Add a new Audit Question</span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_iso_forms: state?.adminReducers?.all_isotwo_forms,
    };
};
export default connect(mapStateToProps, { GetAllFormsByAnnexTwo, DeleteForm })(ClauseFormsView);
