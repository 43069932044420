import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
// @mui components
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//Redux
import { connect } from 'react-redux';
import { CompleteBackgroundCheck, GetOneMerchantPerson, UpdateOneMerchantPerson } from 'store/actions/merchantActions';
import { GetAllMerchantOnboardingItems, GetAllOnboardingItemsForMerchant } from 'store/actions/onboardingActions';

//logo
import logo from 'assets/img/brand/logo.svg';

import AppSideBar from 'components/new_components/AppSideBar';
import Steps from './Stepper/Steps';
import AcknowledgementPolicy from './StepperViews/AcknowledgementPolicy';
import BackgroundCheck from './StepperViews/BackgroundCheck';
import BackgroundCheckForm from './StepperViews/BackgroundCheckForm';
import SecurityAwarenessTraining from './StepperViews/SecurityAwarenessTraining';
import WelcomeEmployee from './WelcomeEmployee';

//translation
import { useTranslation } from 'react-i18next';
const VerifySecurityTraining = (props) => {
    const {
        GetOneMerchantPerson,
        match,
        one_merchant_person,
        UpdateOneMerchantPerson,
        CompleteBackgroundCheck,
        GetAllMerchantOnboardingItems,
        all_merchant_onboarding_items,
        GetAllOnboardingItemsForMerchant,
        all_onboarding_items_for_merchant,
    } = props;

    const { t } = useTranslation('onboardingPolicy');
    const [loading, setLoading] = useState({});
    const [merchantItems, setMerchantItems] = useState([]);
    const [adminItems, setadminItems] = useState([]);
    const [onboardingPolicy, setOnboardingPolicy] = useState(0);
    const theme = useTheme();
    const history = useHistory();
    const midColour = '#fff';

    const increaseOnboardingPolicy = () => {
        setOnboardingPolicy(onboardingPolicy + 1);
    };

    const handleVideoFinish = async (data) => {
        if (one_merchant_person?.[0]?.training) return;
        setLoading({ ...loading, video: true });
        const res = await UpdateOneMerchantPerson(data, one_merchant_person?.[0]?.id, match?.params?.id);
        setLoading({ ...loading, video: false });
        if (res?.success) {
            toast.success('Security Awareness Training completed successfully.');
            increaseOnboardingPolicy();
        } else {
            toast.error(res?.message);
        }
    };

    const handleAcknowledge = async (data) => {
        setLoading({ ...loading, policy: true });
        const res = await UpdateOneMerchantPerson(data, one_merchant_person?.[0]?.id, match?.params?.id);
        setLoading({ ...loading, policy: false });
        if (res?.success) {
            toast.success('All policies acknowledged successfully.');
            increaseOnboardingPolicy();
        } else {
            toast.error(res?.message);
        }
    };
    const handleBackgroundCheck = async (data) => {
        setLoading({ ...loading, background: true });
        const res = await CompleteBackgroundCheck({ ...data, background_checktype: 'nin' }, match?.params?.id);
        setLoading({ ...loading, background: false });
        if (res?.success) {
            toast.success('Background Check Completed Successfully');
        } else {
            toast.error(res?.message);
        }
    };

    const getPersonDetails = async () => {
        const res = await GetOneMerchantPerson(match?.params?.id);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getMerchantOnboardingItems = async (merchant) => {
        const res = await GetAllMerchantOnboardingItems(merchant);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllOnboardingItems = async () => {
        const res = await GetAllOnboardingItemsForMerchant();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        if (match?.params?.id?.toString()?.length >= 4) {
            getPersonDetails();
        }
    }, [match?.params?.id]);
    useEffect(() => {
        if (one_merchant_person?.[0]?.merchant) {
            getMerchantOnboardingItems(one_merchant_person?.[0]?.merchant);
            getAllOnboardingItems();
        }
    }, [one_merchant_person?.[0]?.merchant]);
    useEffect(() => {
        if (all_merchant_onboarding_items?.length) {
            setMerchantItems(all_merchant_onboarding_items);
        }
        if (all_onboarding_items_for_merchant?.length) {
            setadminItems(all_onboarding_items_for_merchant);
        }
    }, [all_merchant_onboarding_items, all_onboarding_items_for_merchant]);

    return (
        <>
            <Box sx={{ backgroundColor: theme.palette.white.main }}>
                <Box
                    sx={{
                        display: 'flex',
                        backgroundColor: theme.palette.white.main,
                        height: '100vh',
                    }}
                >
                    <AppSideBar midColour={midColour} />
                    <Stack sx={{ width: '97.3%' }}>
                        <Box
                            sx={{
                                mx: '75px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    my: '45px',
                                }}
                            >
                                <img src={logo} alt="SmartComply logo" width={200} />
                                {match?.params?.id?.toString()?.length <= 4 ? (
                                    <Typography
                                        sx={{
                                            cursor: 'pointer',
                                            color: theme.palette.primary.main,
                                            fontWeight: 700,
                                            fontSize: '16px',
                                        }}
                                        onClick={() => history.goBack()}
                                    >
                                        {t('employeeView.goToDashboard')}
                                    </Typography>
                                ) : null}
                            </Box>
                            {onboardingPolicy === 0 && (
                                <WelcomeEmployee
                                    match={match}
                                    one_merchant_person={one_merchant_person}
                                    increaseOnboardingPolicy={increaseOnboardingPolicy}
                                />
                            )}{' '}
                            {onboardingPolicy > 0 && onboardingPolicy <= 4 ? (
                                <Grid container>
                                    <Steps
                                        onboardingPolicy={onboardingPolicy}
                                        setOnboardingPolicy={setOnboardingPolicy}
                                        check={one_merchant_person?.[0]}
                                    />
                                    {onboardingPolicy === 1 ? (
                                        <AcknowledgementPolicy
                                            increaseOnboardingPolicy={increaseOnboardingPolicy}
                                            handleAcknowledge={handleAcknowledge}
                                            loading={loading.policy}
                                            check={one_merchant_person?.[0]?.policy}
                                            policies={adminItems
                                                ?.filter((item) => item?.tag === 'document')
                                                ?.map((item) => {
                                                    const one_item = merchantItems?.find(
                                                        (merchant_item) => merchant_item?.name === item?.name
                                                    );
                                                    return one_item || item;
                                                })}
                                            videos={adminItems
                                                ?.filter((item) => item?.tag === 'video')
                                                ?.map((item) => {
                                                    const one_item = all_merchant_onboarding_items?.find(
                                                        (merchant_item) => merchant_item?.name === item?.name
                                                    );
                                                    return one_item || item;
                                                })}
                                            id={match?.params?.id}
                                        />
                                    ) : onboardingPolicy === 2 ? (
                                        <SecurityAwarenessTraining
                                            increaseOnboardingPolicy={increaseOnboardingPolicy}
                                            handleVideoFinish={handleVideoFinish}
                                            check={one_merchant_person?.[0]?.training}
                                            loading={loading?.video}
                                            videos={adminItems
                                                ?.filter((item) => item?.tag === 'video')
                                                ?.map((item) => {
                                                    const one_item = all_merchant_onboarding_items?.find(
                                                        (merchant_item) => merchant_item?.name === item?.name
                                                    );
                                                    return one_item || item;
                                                })}
                                            id={match?.params?.id}
                                        />
                                    ) : onboardingPolicy === 3 ? (
                                        <BackgroundCheck
                                            increaseOnboardingPolicy={increaseOnboardingPolicy}
                                            match={match}
                                            one_merchant_person={one_merchant_person}
                                            id={match?.params?.id}
                                        />
                                    ) : onboardingPolicy === 4 ? (
                                        <BackgroundCheckForm
                                            loading={loading?.background}
                                            check={one_merchant_person?.[0]?.background_check}
                                            handleBackgroundCheck={handleBackgroundCheck}
                                            id={match?.params?.id}
                                        />
                                    ) : null}
                                </Grid>
                            ) : null}
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        one_merchant_person: state?.merchantReducers?.one_merchant_person,
        all_merchant_onboarding_items: state?.merchantReducers?.all_merchant_onboarding_items,
        all_onboarding_items_for_merchant: state?.merchantReducers?.all_onboarding_items_for_merchant,
    };
};
export default connect(mapStateToProps, {
    GetOneMerchantPerson,
    UpdateOneMerchantPerson,
    CompleteBackgroundCheck,
    GetAllMerchantOnboardingItems,
    GetAllOnboardingItemsForMerchant,
})(VerifySecurityTraining);
