import { validation } from 'validate';

export const requestModalValidation = (values) => {
    const errors = {};
    const nameValidate = validation(values.request_name, 'Scan name', true);
    const publicIPValidate = validation(values.public_ip, 'Domain name', true);

    if (!nameValidate.isValid) {
        errors.request_name = nameValidate.errorMessage;
    }
    if (!publicIPValidate.isValid) {
        errors.public_ip = publicIPValidate.errorMessage;
    }
    return errors;
};
