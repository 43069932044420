import React, { useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import Play from '../../../../../assets/img/bullet.svg';
import { toast } from 'react-toastify';
import ExemptTag from '../../common/ExemptTag';

// core components
import ItemSubheader from '../../common/ItemSubheader';

// redux
import { connect } from 'react-redux';
import { RevertBlockchainCompliance } from 'store/actions/complianceActions';

//translation
import { useTranslation } from 'react-i18next';

const DocumentationLifeCycleItem = (props) => {
    const {
        category,
        getAllExemptedBlockchain,
        onResourceChange,
        RevertBlockchainCompliance,
        exception,
        all_background_check_items,
    } = props;
    const [loading, setLoading] = useState({ upload: false, exempt: false });
    const theme = useTheme();

    //translation
    const { t } = useTranslation('compliance');

    // memo
    // get the current document in view
    const selectedCategory = useMemo(() => {
        return all_background_check_items?.find((doc) => doc?.id === category?.id);
    }, [category, all_background_check_items]);

    const removeExempt = async () => {
        setLoading({ ...loading, exempt: true });
        const res = await RevertBlockchainCompliance(category?.id);
        setLoading({ ...loading, exempt: false });
        getAllExemptedBlockchain();
        onResourceChange();
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    const payload = {
        subcriteria: category?.subcriteria,
        criteria: category?.criteria,
        tool: category?.id,
        reason: '',
    };

    return (
        <Box sx={{ p: 2, py: 4 }}>
            <ItemSubheader
                exempting={loading.exempt}
                exceptionReason={category}
                noResource
                type={'vulnerability management'}
                payload={payload}
                blockchain
                onResourceChange={onResourceChange}
                exception={exception}
            />
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ py: 5 }} spacing={3}>
                <Box sx={{ marginTop: 0.8 }}>
                    <img src={Play} alt="" />
                </Box>
                <Stack>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            sx={{
                                color: theme.palette.gray[800],
                                fontWeight: 500,
                                fontSize: 16,
                            }}
                        >
                            {category?.title || category?.tool_detail?.title}
                        </Typography>
                        {exception ? (
                            <Box
                                sx={{
                                    marginLeft: '1.5rem',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                }}
                            >
                                <ExemptTag
                                    onClick={removeExempt}
                                    loading={loading?.exempt}
                                    loadingLabel={t('reverting')}
                                    label={t('revert')}
                                />
                            </Box>
                        ) : null}
                    </Box>
                    <Typography
                        sx={{
                            fontWeight: 300,
                            fontSize: 12,
                        }}
                    >
                        {t('blockchain.noScanConnect')}
                        <Typography
                            component={'span'}
                            sx={{
                                fontWeight: 600,
                                fontSize: 12,
                                textDecoration: 'underline',
                                color: theme.palette.primary[800],
                                pointerEvents: exception ? 'none' : 'hover',
                                fontStyle: 'italic',
                            }}
                        >
                            <Link
                                to={{
                                    pathname: '/merchant/automated-scans',
                                    state: {
                                        tab_: 'documentation lifecycle',
                                    },
                                }}
                            >
                                {t('blockchain.connectTools')}
                            </Link>
                        </Typography>
                        .
                    </Typography>
                    <Stack direction="row" spacing={3} sx={{ pt: 4 }}>
                        <ScanStatus
                            type="passed"
                            count={selectedCategory?.category_details?.documentation_lifecycle?.passed}
                            theme={theme}
                        />
                        <ScanStatus
                            type="failed"
                            count={selectedCategory?.category_details?.documentation_lifecycle?.failed}
                            theme={theme}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
};

const ScanStatus = ({ type, count, theme }) => {
    //translation
    const { t } = useTranslation('compliance');

    return (
        <Box
            sx={{
                border: '0.5px solid #B8BCCC',
                borderRadius: '20px',
                px: 1.6,
                py: 0.3,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '12px',
            }}
        >
            <span
                style={{
                    height: '10px',
                    width: '10px',
                    borderRadius: '50%',
                    marginRight: '8px',
                    backgroundColor: type === 'passed' ? theme.palette.success[300] : theme.palette.error[500],
                    display: 'inline-block',
                    fontSize: '12px',
                }}
            ></span>
            <Typography sx={{ letterSpacing: '1.2px', fontSize: '12px', fontWeight: 600 }}>
                {count} {type === 'passed' ? t('blockchain.passed') : t('blockchain.failed')}
            </Typography>
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        all_background_check_items: state?.adminReducers?.all_background_check_items,
    };
};

export default connect(mapStateToProps, {
    RevertBlockchainCompliance,
})(DocumentationLifeCycleItem);
