import React from 'react';
//core component
import { styled } from '@mui/material/styles';
import { Switch } from '@mui/material';

const AppCustomSwitch = (props) => {
    const { onChange, checked, disabled } = props;
    // { theme }
    const StyledSwitch = styled(Switch)(() => ({
        width: 84,
        height: 48,
        padding: 8,
        '& .MuiSwitch-switchBase': {
            padding: 11,
            color: '#ff6a00',
            '&.Mui-checked': {
                color: '#185a9d',
                transform: 'translateX(35px)',
                '&:hover': {
                    backgroundColor: 'rgba(24,90,257,0.08)',
                },
                '& .MuiSwitch-thumb': {
                    backgroundColor: '#fff',
                },
                '& + .MuiSwitch-track': {
                    background: 'theme.palette.primary.main',
                    '&:before': {
                        opacity: 1,
                    },
                    '&:after': {
                        opacity: 0,
                    },
                },
            },
        },
        '& .MuiSwitch-thumb': {
            width: 26,
            height: 26,
            backgroundColor: '#fff',
        },
        '& .MuiSwitch-track': {
            background: '#D9D9D9',
            opacity: '1 !important',
            borderRadius: 20,
            position: 'relative',
            '&:before, &:after': {
                display: 'inline-block',
                position: 'absolute',
                top: '50%',
                width: '50%',
                transform: 'translateY(-50%)',
                color: '#fff',
                textAlign: 'center',
            },
            '&:before': {
                content: '"ON"',
                left: 4,
                opacity: 0,
            },
            '&:after': {
                content: '"OFF"',
                right: 4,
            },
        },
    }));
    return <StyledSwitch checked={checked} onChange={onChange} disabled={disabled} />;
};

export default AppCustomSwitch;
