import {
    ALL_PLANS,
    ALL_FEATURES,
    ALL_STANDARDS_PRICES,
    ALL_PAYMENT_METHODS,
    ALL_PLAN_REQUESTS,
    ALL_CUSTOM_PLAN_REQUESTS,
} from '../constants';
import axiosInstance from '../../utils/https';

// features
export const GetAllFeatures = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/features/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_FEATURES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddFeature = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/features/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllFeatures());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditFeature = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/features/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllFeatures());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteFeature = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/features/${id}/`);
        console.log(res);
        dispatch(GetAllFeatures());
        return {
            success: true,
            message: 'Feature Deleted Successfully',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// plans
export const GetAllPlans = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/plan/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_PLANS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AddPlan = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/plan/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllPlans());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UpdatePlan = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/plan/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllPlans());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const DeletePlan = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/plan/${id}/`);
        console.log(res);
        dispatch(GetAllPlans());
        return {
            success: true,
            message: 'Plan Deleted Successfully',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// Standard Pricing
export const GetStandardsPrices = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/standard_prices/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_STANDARDS_PRICES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddStandardPrice = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/standard_prices/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetStandardsPrices());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditStandardPrice = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/standard_prices/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetStandardsPrices());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteStandardPrice = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/standard_prices/${id}/`);
        console.log(res);
        dispatch(GetStandardsPrices());
        return {
            success: true,
            message: 'Standard price Deleted Successfully',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// payment method
export const GetAllPaymentMethods = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/payments/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_PAYMENT_METHODS, payload: res?.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditPaymentMethod = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/payments/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllPaymentMethods());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// Plan Requests
export const RequestAPlan = (credentials) => async (_, getState) => {
    const merchant = getState()?.authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/user_mgt/request_plan/`, { ...credentials, merchant });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const RequestCustomPlan = (credentials) => async (_, getState) => {
    const merchant = getState()?.authReducers?.company_id;
    const name = 'Custom' + merchant?.split('-')?.[0];
    try {
        const res = await axiosInstance().post(`/user_mgt/request_custom_plan/`, { ...credentials, merchant, name });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AdminCreateCustomPlanRequest = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/request_custom_plan/`, { ...credentials });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCustomPlanRequests());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditCustomPlanRequest = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/request_custom_plan/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCustomPlanRequests());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllPlanRequests = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/request_plan/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_PLAN_REQUESTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllCustomPlanRequests = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/request_custom_plan/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_CUSTOM_PLAN_REQUESTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const ApprovePlanRequest = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/planapproval/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllPlanRequests());
        dispatch(GetAllCustomPlanRequests());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
