import { Divider } from '@mui/material';
import React, { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
    ArcElement,
    Chart as ChartJS,
    Legend,
    Tooltip,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
} from 'chart.js';

import PolygonMedium from 'assets/img/medium_polygon.png';
import PolygonLow from 'assets/img/ciso_positive_angle.png';
import PolygonHigh from 'assets/img/LowPolygon.svg';
import { useSelector } from 'react-redux';

ChartJS.register(ArcElement, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);
ChartJS.defaults.plugins.tooltip.backgroundColor = '#395BA9';
ChartJS.defaults.plugins.tooltip.titleColor = '#fff';
ChartJS.defaults.plugins.tooltip.titleAlign = 'center';
ChartJS.defaults.plugins.tooltip.bodyAlign = 'center';
ChartJS.defaults.plugins.tooltip.displayColors = false;
ChartJS.defaults.plugins.tooltip.titleFont = {
    family: 'Poppins, sans-serif',
    weight: 'bold',
};
ChartJS.defaults.plugins.tooltip.bodyFont = {
    family: 'Poppins, sans-serif',
    weight: 'normal',
};
ChartJS.defaults.plugins.tooltip.boxPadding = 4;
ChartJS.defaults.plugins.tooltip.xAlign = 'center';
ChartJS.defaults.plugins.tooltip.yAlign = 'bottom';

const zeroDataBackgroundPlugin = {
    id: 'zeroDataBackground',
    beforeDraw: (chart) => {
        const { datasets } = chart.data;
        const ctx = chart.ctx;

        // Check if datasets exist and contain data
        if (!datasets || !datasets.length || !datasets[0].data.length) {
            return; // Exit early if no data
        }

        // Check if all data values are zero
        const allDataZero = datasets[0].data.every((value) => value === 0);

        if (allDataZero) {
            const { chartArea } = chart;
            const centerX = (chartArea.left + chartArea.right) / 2;
            const centerY = (chartArea.top + chartArea.bottom) / 2;

            // Ensure the dataset meta is available and has data
            const meta = chart.getDatasetMeta(0);
            if (!meta || !meta.data || !meta.data.length) {
                return; // Exit if meta data is not available
            }

            const { outerRadius, innerRadius } = meta.data[0].getProps(['outerRadius', 'innerRadius'], true);

            ctx.save();
            ctx.fillStyle = '#f0f0f0'; // Set your desired background color here
            ctx.beginPath();
            ctx.arc(centerX, centerY, outerRadius, 0, 2 * Math.PI); // Outer circle
            ctx.arc(centerX, centerY, innerRadius, 0, 2 * Math.PI, true); // Inner circle (inverted)

            ctx.fill();
            ctx.restore();

            // Optional: Add a message on the chart
            ctx.font = '20px Poppins';
            ctx.fillStyle = '#666';
            ctx.textAlign = 'center';
            // ctx.fillText('No Data Available', centerX, centerY);
        }
    },
};

ChartJS.register(zeroDataBackgroundPlugin);

const Employees = () => {
    const { people_info_summary } = useSelector((state) => state?.CISOReducers);
    let colors = ['#FF5449', '#7AD3FF'];

    const data_agent = useMemo(
        () => ({
            labels: ['Without agent', 'With agent'],
            datasets: [
                {
                    data: [people_info_summary?.not_installed_agent_count, people_info_summary?.installed_agent_count],
                    backgroundColor: ['#FF5449', '#7AD3FF'],
                    hoverOffset: 4,
                    borderWidth: 0,
                },
            ],
        }),
        [people_info_summary]
    );

    const options_agent = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                font: {
                    family: 'Poppins, sans-serif',
                },
            },
        },
        datalabels: {
            display: false, // Turn off datalabels
        },
    };

    const data_background = useMemo(
        () => ({
            labels: ['Without background check', 'With background check'],
            datasets: [
                {
                    data: [people_info_summary?.no_background_check, people_info_summary?.has_background_check],
                    backgroundColor: [colors[0], colors[1]],
                    hoverOffset: 4,
                    borderWidth: 0,
                },
            ],
        }),
        [people_info_summary]
    );

    const options_background = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                font: {
                    family: 'Poppins, sans-serif',
                },
            },
        },
        datalabels: {
            display: false, // Turn off datalabels
        },
    };

    const data_onboarding = useMemo(
        () => ({
            labels: ['Policy not accepted', 'Policy accepted'],
            datasets: [
                {
                    data: [
                        people_info_summary?.onboarding_policy_not_accepted,
                        people_info_summary?.onboarding_policy_accepted,
                    ],
                    backgroundColor: [colors[0], colors[1]],
                    hoverOffset: 4,
                    borderWidth: 0,
                },
            ],
        }),
        [people_info_summary]
    );

    const options_onboarding = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                font: {
                    family: 'Poppins, sans-serif',
                },
            },
        },
        datalabels: {
            display: false, // Turn off datalabels
        },
    };
    const data = useMemo(
        () => ({
            labels: ['Not completed trainig', 'Completed training'],
            datasets: [
                {
                    data: [people_info_summary?.training_not_complete, people_info_summary?.training_complete],
                    backgroundColor: [colors[0], colors[1]],
                    hoverOffset: 4,
                    borderWidth: 0,
                },
            ],
        }),
        [people_info_summary]
    );

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                font: {
                    family: 'Poppins, sans-serif',
                },
            },
        },
        datalabels: {
            display: false, // Turn off datalabels
        },
    };
    return (
        <div className="mt-[5rem]">
            <div className="flex items-center gap-4 mt-[1.5rem] mb-[3rem]">
                <h4 className="text-[#2B3674] text-lg font-bold text-center">Employees</h4>
                <div className="flex items-center gap-1 border border-[#E2E8F0] p-1 rounded">
                    <img
                        src={
                            people_info_summary?.total_percentage_passed < 50
                                ? PolygonHigh
                                : people_info_summary?.total_percentage_passed >= 50 &&
                                  people_info_summary?.total_percentage_passed <= 70
                                ? PolygonMedium
                                : PolygonLow
                        }
                        alt="PolygonMedium"
                    />
                    <p className="text-gray-500 text-sm font-semibold">
                        {people_info_summary?.total_percentage_passed?.toFixed(2)}%
                    </p>
                </div>
                <div className="w-[74%]">
                    <Divider orientation="horizontal" sx={{ backgroundColor: '#E2E8F0' }} />
                </div>
            </div>
            <div className="flex justify-center items-center w-full mt-[5rem]">
                <div className="flex justify-between items-center w-[90%]">
                    <div>
                        <div className="xl:flex items-center md:block gap-5">
                            <div className="w-[170px]">
                                <Doughnut data={data_agent} options={options_agent} />
                            </div>
                            <div className="xl:mt-0 md:mt-3">
                                <div className="flex items-center gap-2">
                                    <div className="w-[15px] h-[15px] rounded bg-[#7AD3FF]"></div>
                                    <p className="text-[#64748B] text-[13px] font-medium">
                                        {people_info_summary?.installed_agent_count} with Agent
                                    </p>
                                </div>
                                <div className="flex items-center gap-2 mt-[1rem]">
                                    <div className="w-[15px] h-[15px] rounded bg-[#FF5449]"></div>
                                    <p className="text-[#64748B] text-[13px] font-medium">
                                        {people_info_summary?.not_installed_agent_count}{' '}
                                        <span className="text-[#FF5449]">without Agent</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p className="text-[#2B3674] text-base font-bold mt-[2rem]">Employees’ computer</p>
                    </div>
                    <div>
                        <div className="xl:flex items-center md:block gap-5">
                            <div className="w-[170px]">
                                <Doughnut data={data_background} options={options_background} />
                            </div>
                            <div className="xl:mt-0 md:mt-3">
                                <div className="flex items-center gap-2">
                                    <div className="w-[15px] h-[15px] rounded bg-[#7AD3FF]"></div>
                                    <p className="text-[#64748B] text-[13px] font-medium">
                                        {people_info_summary?.has_background_check} With background check
                                    </p>
                                </div>
                                <div className="flex items-center gap-2 mt-[1rem]">
                                    <div className="w-[15px] h-[15px] rounded bg-[#FF5449]"></div>
                                    <p className="text-[#64748B] text-[13px] font-medium">
                                        {people_info_summary?.no_background_check}{' '}
                                        <span className="text-[#FF5449]">With no background check</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p className="text-[#2B3674] text-base font-bold mt-[2rem]">Employees’ Background</p>
                    </div>
                </div>
            </div>

            <div className="flex justify-center items-center w-full mt-[4rem]">
                <div className="flex justify-between items-center w-[90%]">
                    <div>
                        <div className="xl:flex items-center md:block gap-5">
                            <div className="w-[170px]">
                                <Doughnut data={data_onboarding} options={options_onboarding} />
                            </div>
                            <div className="xl:mt-0 md:mt-3">
                                <div className="flex items-center gap-2">
                                    <div className="w-[15px] h-[15px] rounded bg-[#7AD3FF]"></div>
                                    <p className="text-[#64748B] text-[13px] font-medium">
                                        {people_info_summary?.onboarding_policy_accepted} onboarding policy accepted
                                    </p>
                                </div>
                                <div className="flex items-center gap-2 mt-[1rem]">
                                    <div className="w-[15px] h-[15px] rounded bg-[#FF5449]"></div>
                                    <p className="text-[#64748B] text-[13px] font-medium">
                                        {people_info_summary?.onboarding_policy_not_accepted}{' '}
                                        <span className="text-[#FF5449]">onboarding policy not accepted</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p className="text-[#2B3674] text-base font-bold mt-[2rem]">Onboarding Policy</p>
                    </div>
                    <div>
                        <div className="xl:flex items-center md:block gap-5">
                            <div className="w-[170px]">
                                <Doughnut data={data} options={options} />
                            </div>
                            <div className="xl:mt-0 md:mt-3">
                                <div className="flex items-center gap-2">
                                    <div className="w-[15px] h-[15px] rounded bg-[#7AD3FF]"></div>
                                    <p className="text-[#64748B] text-[13px] font-medium">
                                        {people_info_summary?.training_complete} Completed employee training{' '}
                                    </p>
                                </div>
                                <div className="flex items-center gap-2 mt-[1rem]">
                                    <div className="w-[15px] h-[15px] rounded bg-[#FF5449]"></div>
                                    <p className="text-[#64748B] text-[13px] font-medium">
                                        {people_info_summary?.training_not_complete}{' '}
                                        <span className="text-[#FF5449]">Uncompleted </span> employee training
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p className="text-[#2B3674] text-base font-bold mt-[2rem]">Employees Training</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Employees;
