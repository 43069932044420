import React, { useState } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { Upload, InfoOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
//core components
// import AppModal from 'components/new_components/AppModal';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import {
    AppFormInput,
    AppFormSelect,
    AppSubmitButton,
    // AppFormFileInput,
    AppForm,
} from 'components/new_components/forms';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import { AppFormDragFileUpload } from 'components/new_components/forms/AppFormDragFileUpload';
//validate
import { uploadModalValidation } from './utils/validation';
import { connect } from 'react-redux';
import { CreateASVScan } from 'store/actions/merchantActions';
import { toast } from 'react-toastify';
import { statusOptions } from './utils/constant';
import { accept } from 'validate';
import AppFormDateInput from 'components/new_components/forms/AppFormDateInput';

//translations
import { useTranslation } from 'react-i18next';

const UploadReportModal = ({ open, handleClose, CreateASVScan, payload }) => {
    const [authorization, setAuthorization] = useState(false);
    const [loadingState, setloadingState] = useState(false);

    const theme = useTheme();

    //translation
    const { t } = useTranslation('pciscans');

    //functions
    const today = new Date().toISOString().split('T')[0];

    const handleAuthorizationCheck = () => {
        setAuthorization(!authorization);
    };

    const handleSubmit = async (values) => {
        if (authorization === true) {
            const formdata = new FormData();
            formdata.append('name', values?.name);
            formdata.append('ip_address', values?.ip_address);
            formdata.append('uploaded_by', values?.uploaded_by);
            formdata.append('status', values?.status);
            formdata.append('file_field', values?.file_field);
            formdata.append('authorization', authorization);
            formdata.append('conducted_date', values?.conducted_date);
            setloadingState(true);
            const res = await CreateASVScan(formdata);
            setloadingState(false);
            if (res?.success) {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    toast.success(res?.message);
                    handleClose();
                }
            } else {
                toast.error(res?.message);
            }
        } else {
            toast.info('You have to authorize this request!');
        }
    };
    return (
        <AppForm initialValues={payload} onSubmit={handleSubmit} validate={uploadModalValidation}>
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                title={
                    <Typography sx={{ fontSize: '24px', fontWeight: 500, color: theme.palette.gray[800] }}>
                        {t('uploadReportModal.title')}
                    </Typography>
                }
                subtitle={
                    <Stack
                        flexDirection="row"
                        justifyContent="center"
                        sx={{
                            py: 1,
                            pr: 2,
                            fontSize: 12,
                            fontWeight: 400,
                            color: theme.palette.gray[400],
                        }}
                    >
                        <InfoOutlined sx={{ fontSize: 11.8, mr: 1, mt: 0.4 }} /> {t('uploadReportModal.subtitle')}
                    </Stack>
                }
                width={600}
                height="70%"
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <AppSubmitButton
                            text={t('uploadReport')}
                            startIcon={<Upload />}
                            variant="contained"
                            color="primary"
                            loading={loadingState}
                            loadingPosition="center"
                            sx={{ width: '50%' }}
                        />
                    </Box>
                }
            >
                <AppFormInput
                    type="text"
                    label={t('uploadReportModal.scanName')}
                    name="name"
                    placeholder={t('uploadReportModal.scanName')}
                />
                <AppFormSelect
                    name="status"
                    label={t('uploadReportModal.status')}
                    options={statusOptions}
                    defaultValue={t('uploadReportModal.statusValue')}
                />
                <AppFormInput
                    type="text"
                    label={t('uploadReportModal.doneBy')}
                    name="uploaded_by"
                    placeholder={t('uploadReportModal.platformName')}
                />
                <AppFormInput
                    type="text"
                    label={t('uploadReportModal.ipOrURL')}
                    name="ip_address"
                    placeholder={t('uploadReportModal.ipAddress')}
                    // regex={pattern.ip_address}
                />
                <AppFormDateInput name="conducted_date" label={t('uploadReportModal.pciDate')} max={today} />
                <AppFormDragFileUpload label={t('uploadReportModal.report')} name="file_field" accept={accept} />
                <AppCheckboxInput
                    name="authorization"
                    label={t('uploadReportModal.authorization')}
                    onChange={handleAuthorizationCheck}
                    value={authorization}
                />
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { CreateASVScan })(UploadReportModal);
