import React, { useMemo, useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { Stack } from '@mui/material';

// core components
import LoadingState from 'components/new_components/LoadingState';
import ExceptionsTabs from '../../common/exceptions/ExceptionsTabs';
import EmptyState from 'components/new_components/EmptyState';
import ExceptionGroups from '../../common/exceptions/ExceptionGroups';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { GetAllExemptedDocuments, GetAllExemptedForms } from 'store/actions/complianceActions';

//translations
import { useTranslation } from 'react-i18next';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

const CategorySelectionAside = (props) => {
    const { resource, handleResourceChange, handleCategoryIdChange, selectedCategory } = props;

    // state
    const [loading, setLoading] = useState({ content: false });

    // redux
    const dispatch = useDispatch();
    const all_categories = useSelector((state) => state?.adminReducers?.all_categories);
    const exempted_documents = useSelector((state) => state?.complianceReducers?.all_exempted_documents);
    const exempted_forms = useSelector((state) => state?.complianceReducers?.all_exempted_forms);

    //translation
    const { t } = useTranslation('compliance');

    const { activeMerchant } = useContext(SelectedMerchantContext);

    // logic functions
    const getFilteredCategories = (items = []) => {
        const categories = Array.isArray(all_categories) ? all_categories : [];
        const filteredCategories = [...categories]?.filter((category) => {
            const cat_id = category?.id;
            const categoryHasException = items?.some((exception) => {
                const exceptionCategoryId =
                    resource === 'doc' ? exception?.document_detail?.category : exception?.form_detail?.category;
                return exceptionCategoryId === cat_id;
            });
            return categoryHasException;
        });
        const formattedCategory = filteredCategories.map((category) => {
            const exceptionCount = items?.reduce((prev, current) => {
                const exceptionCategoryId =
                    resource === 'doc' ? current?.document_detail?.category : current?.form_detail?.category;
                return exceptionCategoryId === category?.id ? prev + 1 : prev;
            }, 0);
            return {
                id: category?.id,
                title: category?.title,
                description: `${exceptionCount} Exceptions`,
            };
        });
        return formattedCategory;
    };

    // async functions
    // get all excempted document for this standard
    const getAllExemptedDocuments = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await dispatch(GetAllExemptedDocuments('ndpr', activeMerchant));
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exceptions.");
        }
    };
    const getAllExemptedQuestions = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await dispatch(GetAllExemptedForms('ndpr', activeMerchant));
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exceptions.");
        }
    };

    // memos
    const categoriesWithExceptions = useMemo(() => {
        if (resource === 'doc') {
            return getFilteredCategories(exempted_documents);
        } else {
            return getFilteredCategories(exempted_forms);
        }
    }, [resource, exempted_documents, exempted_forms]);

    // useEffect
    useEffect(() => {
        if (resource === 'doc') {
            getAllExemptedDocuments();
        } else {
            getAllExemptedQuestions();
        }
    }, [resource, activeMerchant]);

    return (
        <Stack sx={{ width: '100%' }}>
            <ExceptionsTabs onTabChange={handleResourceChange} currentTab={resource} />
            {!loading?.content ? (
                !categoriesWithExceptions?.length ? (
                    <EmptyState description={t('noException')} />
                ) : (
                    <ExceptionGroups
                        hasSubgroup={false}
                        groups={categoriesWithExceptions}
                        selectedSubgroup={selectedCategory}
                        standard="npdr"
                        handleSubgroupIdChange={handleCategoryIdChange}
                    />
                )
            ) : (
                <LoadingState />
            )}
        </Stack>
    );
};

export default CategorySelectionAside;
