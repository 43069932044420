import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button } from 'antd';

// redux
import { connect } from 'react-redux';
import { CreateEvidence, UpdateEvidence } from 'store/actions/adminActions';

const AddEvidenceModal = (props) => {
    const {
        open,
        handleClose,
        tag,
        CreateEvidence,
        UpdateEvidence,
        payload,
        titleLabel,
        descLabel,
        // segmentLabel,
        // groupIdLabel,
        oneEvidence,
        type,
        title,
    } = props;
    // state
    const [loading, setLoading] = useState(false);
    const [segments, setSegments] = useState(['']);
    const [data, setData] = useState({});

    // functions
    const closeAll = () => {
        setData({});
        handleClose();
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        // const groupId = payload?.[groupIdLabel];
        setLoading(true);
        const res = oneEvidence
            ? await UpdateEvidence(
                  {
                      ...data,
                      tags: tag,
                      card4: payload?.card4,
                      card4_sub_no: payload?.card4_sub_no,
                      segments: segments?.map((segment, index) => {
                          return {
                              [`segment_${index + 1}`]: segment?.[`segment_${index + 1}`] || JSON?.parse(segment),
                          };
                      }),
                  },
                  oneEvidence?.id,
                  tag,
                  title
              )
            : await CreateEvidence(
                  {
                      ...data,
                      tags: tag,
                      card4: payload?.card4,
                      card4_sub_no: payload?.card4_sub_no,
                      segments: segments?.map((segment, index) => {
                          return {
                              [`segment_${index + 1}`]: JSON?.parse(segment),
                          };
                      }),
                  },
                  tag,
                  title
              );
        setLoading(false);
        if (res.success) {
            toast.success(oneEvidence ? 'Evidence Updated Successfully' : 'Evidence Added Successfully');
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    useEffect(() => {
        if (oneEvidence) {
            setData({
                card4_title: oneEvidence?.card4_title,
                card4_description: oneEvidence?.card4_description,
                card4_number: oneEvidence?.card4_number,
            });
        }
    }, [oneEvidence]);

    const handleSegmentChange = (event, index) => {
        const newSegments = segments || [];
        newSegments[index] = event?.target?.value;
        setSegments([...newSegments]);
    };

    useEffect(() => {
        if (oneEvidence?.segments?.length > 0) {
            setSegments(oneEvidence?.segments);
        }
    }, [oneEvidence]);
    return (
        <Modal
            title={`${type === 'add' ? 'Add' : 'Edit'} Evidence`}
            open={open}
            onCancel={closeAll}
            footer={null}
            destroyOnClose={true}
        >
            <form encType="multipart/form-data" onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor={titleLabel}>Evidence title</label>
                            <Input
                                type="text"
                                size="large"
                                name={titleLabel}
                                id={titleLabel}
                                onChange={handleTextChange}
                                value={data?.[titleLabel] || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor={descLabel}>Evidence description</label>
                            <Input.TextArea
                                type="text"
                                size="large"
                                name={descLabel}
                                id={descLabel}
                                onChange={handleTextChange}
                                value={data?.[descLabel] || ''}
                                required
                            />
                        </div>
                    </Col>{' '}
                    {segments?.map((segment, index) => {
                        return (
                            <Col span={24} key={index}>
                                <div className="form-group" style={{ marginBottom: '1rem' }}>
                                    <label htmlFor={`segment ${index + 1}`}>No of evidences</label>
                                    <Input
                                        type="number"
                                        size="large"
                                        name={`Segment_${index + 1}`}
                                        id={`Segment_${index + 1}`}
                                        placeholder="No of evidences"
                                        onChange={(event) => handleSegmentChange(event, index)}
                                        value={segment?.[`segment_${index + 1}`]}
                                        required
                                    />
                                </div>
                            </Col>
                        );
                    })}
                    <Col>
                        <Button
                            className="ant-btn"
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                            style={{ marginTop: '3rem' }}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = (state) => {
    return {
        all_documents_by_alttags: state?.adminReducers?.all_documents_by_alttags,
        all_pcidss4point0_documents_by_alttags: state?.adminReducers?.all_pcidss4point0_documents_by_alttags,
    };
};
export default connect(mapStateToProps, { CreateEvidence, UpdateEvidence })(AddEvidenceModal);
