import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
//Components
import { Table, Button, Modal } from 'antd';
import { ReleaseOperations } from './ReleaseOperations/ReleaseOperations';
import ReleaseModal from './ReleaseOperations/ReleaseModal';
import EditModal from './ReleaseOperations/EditModal';

//Icons
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { truncateText, formatDateObjectHandler, GetFromNowDate } from 'utils';
//Redux
import { connect } from 'react-redux';
import { GetAllReleases, DeleteRelease } from '../../../store/actions/adminActions';

const Releases = (props) => {
    const [loading, setloading] = useState(false);
    const [AddModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [record, setrecord] = useState(null);
    const { GetAllReleases, all_releases, DeleteRelease } = props;

    const columns = [
        {
            title: 'Date',
            render: (record) => (
                <>
                    <div>{formatDateObjectHandler(record?.date_created)}</div>
                    <small style={{ color: 'green' }}>
                        {GetFromNowDate(record?.date_created, 'YYYY-MM-DDTHH:mm:ss', 'fromNow')}
                    </small>
                </>
            ),
        },
        {
            title: 'Release Note',
            render: (record) => truncateText(record?.details?.replace(/<[^>]+>/g, '')),
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => (
                <ReleaseOperations record={record} openUpdateModal={openEditModal} deleteModal={deleteModal} />
            ),
        },
    ];
    const openAddModal = () => {
        setAddModal(true);
    };
    const closeAddModal = () => {
        setAddModal(false);
    };

    //Edit Modal
    const openEditModal = (data) => {
        setrecord(data);
        setEditModal(true);
    };
    const closeEditModal = () => {
        setEditModal(false);
    };

    const deleteModal = (release) => {
        Modal.confirm({
            title: 'Do you want to delete this release list?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await DeleteRelease(release?.id);
                if (res?.success) {
                    toast.success('Release Note Deleted Successfully');
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };
    useEffect(() => {
        const getAllReleases = async () => {
            setloading(true);
            const res = await GetAllReleases();
            if (!res.success) {
                setloading(false);
                toast.error(res?.message);
            }
            setloading(false);
        };

        getAllReleases();
    }, []);
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    onClick={openAddModal}
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: '#1677ff',
                    }}
                >
                    Add Release Notes
                </Button>
            </div>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(all_releases) => all_releases?.id}
                    columns={columns}
                    loading={loading}
                    dataSource={all_releases}
                    pagination={{
                        total: all_releases?.length,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                    }}
                />
            </section>
            <ReleaseModal open={AddModal} handleClose={closeAddModal} />
            <EditModal open={editModal} handleClose={closeEditModal} record={record} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_releases: state?.adminReducers?.all_releases?.results,
    };
};
export default connect(mapStateToProps, { GetAllReleases, DeleteRelease })(Releases);
