import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, MenuItem, Stack } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import AppTableActionMenu from 'components/new_components/app-table/AppTableActionMenu';

const ComplianceTableActions = (props) => {
    const theme = useTheme();
    const { row, openModal } = props;

    // state
    const [anchor, setAnchor] = useState(null);

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const handleOpenModal = () => {
        openModal(row);
        closeMenu();
    };
    return (
        <Stack direction="row">
            <IconButton
                sx={{
                    color: theme.palette.neutral[500],
                    height: '24px',
                    width: '24px',
                    borderRadius: 1,
                    fontSize: 16,
                }}
                onClick={openMenu}
            >
                <MoreHoriz />
            </IconButton>
            <AppTableActionMenu anchor={anchor} closeMenu={closeMenu}>
                <MenuItem
                    sx={{ fontSize: 13, fontWeight: 500, letterSpacing: '0.1px', color: theme.palette.neutral[50] }}
                    onClick={handleOpenModal}
                >
                    Update Info
                </MenuItem>
            </AppTableActionMenu>
        </Stack>
    );
};

export default ComplianceTableActions;
