import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, List, Typography } from '@mui/material';

// core components
import AuditorListItem from './components/AuditorListItem';

// redux
import { connect } from 'react-redux';

const AuditorsInfo = (props) => {
    const theme = useTheme();
    const { auditors_info, user_auditor } = props;

    // console.log(auditors_info);
    // console.log(user_auditor);
    return auditors_info?.[0]?.name ? (
        <Box sx={{ p: 3 }}>
            <Typography
                variant="h2"
                component="h2"
                sx={{
                    color: theme.palette.primary[900],
                    fontSize: 18,
                    fontWeight: 700,
                    mb: 2.5,
                }}
            >
                Auditor(s) Information
            </Typography>
            <List component={Grid} container spacing={2} sx={{ p: 0 }}>
                {auditors_info?.map((auditor, index) => (
                    <React.Fragment key={index}>
                        <AuditorListItem
                            key={auditor?.owner_detail?.id}
                            name={`${auditor?.owner_detail?.first_name} ${auditor?.owner_detail?.last_name}`}
                            picture={auditor?.owner_detail?.picture}
                            standards={auditor?.auditor_standards?.toUpperCase()?.replaceAll(',', ', ') || 'N/A'}
                        />
                    </React.Fragment>
                ))}
                {user_auditor?.map((auditor, index) => (
                    <React.Fragment key={index}>
                        <AuditorListItem
                            key={auditor?.id}
                            name={`${auditor?.user_detail?.first_name} ${auditor?.user_detail?.last_name}`}
                            picture={auditor?.user_detail?.picture}
                            standards={auditor?.auditor_standards?.toUpperCase()?.replaceAll(',', ', ') || 'N/A'}
                        />
                    </React.Fragment>
                ))}
            </List>
        </Box>
    ) : null;
    // <Box sx={{ p: 3 }}>
    //     <Typography
    //         variant="h2"
    //         component="h2"
    //         sx={{
    //             color: theme.palette.primary[900],
    //             fontSize: 18,
    //             fontWeight: 700,
    //             mb: 2.5,
    //         }}
    //     >
    //         Auditor(s) Information
    //     </Typography>
    //     <List component={Grid} container spacing={2} sx={{ p: 0 }}>
    //         {auditors_info?.map((auditor, index) => (
    //             <React.Fragment key={index}>
    //                 <AuditorListItem
    //                     key={auditor?.owner_detail?.id}
    //                     name={`${auditor?.owner_detail?.first_name} ${auditor?.owner_detail?.last_name}`}
    //                     picture={auditor?.owner_detail?.picture}
    //                     standards={auditor?.auditor_standards?.toUpperCase()?.replaceAll(',', ', ') || 'N/A'}
    //                 />
    //             </React.Fragment>
    //         ))}
    //         {user_auditor?.map((auditor, index) => (
    //             <React.Fragment key={index}>
    //                 <AuditorListItem
    //                     key={auditor?.id}
    //                     name={`${auditor?.user_detail?.first_name} ${auditor?.user_detail?.last_name}`}
    //                     picture={auditor?.user_detail?.picture}
    //                     standards={auditor?.auditor_standards?.toUpperCase()?.replaceAll(',', ', ') || 'N/A'}
    //                 />
    //             </React.Fragment>
    //         ))}
    //     </List>
    // </Box>
};

const mapStateToProps = (state) => {
    return {
        auditors_info: [state?.merchantReducers?.merchant_info?.auditor_detail],
        user_auditor: state?.merchantReducers?.all_merchant_users?.filter((user) => user?.role === 'auditor'),
    };
};
export default connect(mapStateToProps, {})(AuditorsInfo);
