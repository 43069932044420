export const FakeAudit = [
    {
        id: 4,
        name: 'SOC2',
        status: 'completed',
        dateAdded: '2023-01-22',
        effectiveFrom: 'Nov 22, 2021 - Dec 21, 2023',
    },
    {
        id: 1,
        name: 'PCIDSS',
        status: 'review',
        dateAdded: '2023-04-22',
        effectiveFrom: '2023-04-24',
    },
    {
        id: 2,
        name: 'NDPR',
        status: 'auditing',
        dateAdded: '2023-05-22',
        effectiveFrom: '2023-05-24',
    },
    {
        id: 3,
        name: 'ISO27001',
        status: 'draft',
        dateAdded: '2023-06-22',
        effectiveFrom: '2023-06-24',
    },
];

export const questionsList = [
    {
        id: 1,
        question: 'Contact Information',
        summary: 'Assessed Entity\n',
    },
    {
        id: 2,
        question: 'Description of Entity’s payment card business',
        summary: "Provide an overview of the entity's payment card business, including:\n",
    },
    {
        id: 3,
        question: 'Storage of account data',
        summary: 'Identify all databases, tables, and files storing account data and provide the following details:',
    },
    {
        id: 4,
        question: 'Storage of SAD',
        summary:
            'Identify all databases, tables, and files storing Sensitive Account Data (SAD) and provide the following details',
    },
    {
        id: 5,
        question: 'In-scope third-party service providers (TPSPs)',
        summary: 'Third-party service providers include, but are not limited to third parties that:. \n' + '\n',
        options: [
            "Store, process, or transmit account data on the entity's behalf (for example, payment gateways, payment processors,payment service providers [PSPs]). ",
            "Manage system components included in the entity's PCI DSSassessment (for example, via etwork security control services, anti-malware services, security incident and event management [SIEM], web-hosing companies, laaS, PaaS,SaaS, FaaS, etc.).",
            "Could impact the security of the entity's account data (for example, vendors providing support via remote access, and/or bespoke software developers).",
        ],
        sub_option: 'These entities are subject to PCI DSS Requirement 12.8.',
        sub_summary: 'For each service provider or third party, provide:',
    },
    {
        id: 6,
        question: 'In-scope Networks',
        summary:
            'Identify all in-scope networks including the type of network (for example, weird, Wi-Fi, cloud, etc).',
    },
    {
        id: 7,
        question: 'In-scope Locations/Facilities',
        summary:
            'Identify and provide details for all the types of physical locations/facilities (for example, retail locations, corporate offices, data centers, all centers and mail rooms) in scope. Add rows, as needed.\n',
    },
    {
        id: 8,
        question: 'In-scope Business Functions',
        summary:
            'Identify and provide details for all business and operational functions in scope. Functions could include telephone transaction handling, mail transaction handling, change control, development, system build and hardening, etc. Add rows as needed.\n',
    },
    {
        id: 9,
        question: 'In-scope System components Types',
        summary:
            'Identify all types of system components in scope:"System components" include network devices, servers, computing devices, virtual components, cloud components, and software. Examples of system components include but are not limited to:',
        details: '',
    },
];
