import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row } from 'antd';
import BreadCrumb from 'components/Breadcrumb';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { EditAccessorSubCategory } from 'store/actions/auditActions';

// const { confirm } = Modal;

const CategorySettings = (props) => {
    const { location, all_accessor_categories, EditAccessorSubCategory } = props;
    // state
    const [sections, setSections] = useState([]);
    const [loading, setLoading] = useState(false);

    // memos
    const category = useMemo(
        () => all_accessor_categories.find((category) => category?.id == location?.state?.category_id),
        [location, all_accessor_categories]
    );
    const section = useMemo(
        () => category?.subcategory.find((section) => section?.id == location?.state?.id),
        [location, category]
    );

    // useEffects
    useEffect(() => {
        const sections = section?.sections;
        if (Array.isArray(sections)) {
            setSections([{ 0: '' }]);
        } else {
            setSections(JSON.parse(sections));
        }
    }, [section]);

    const addOneSection = () => {
        const oneItem = sections?.find((item) => item);
        setSections((current) => [...current, oneItem]);
    };
    const addItem = (item) => {
        const totalItems = Object.keys(item).map((el) => el);
        const lastItem = Number(totalItems[totalItems.length - 1]);
        const prev_array = [...sections];
        const update_element = prev_array?.map((element) => ({
            ...element,
            [lastItem + 1]: '',
        }));
        setSections(update_element);
    };

    const removeItem = (index) => {
        const prev_array = [...sections];
        const update_element = prev_array?.map((el) => {
            delete el[index];
            return el;
        });
        setSections(update_element);
    };

    const handleChange = (event, index) => {
        const prev_array = [...sections];
        const update_element = prev_array?.map((element) => ({
            ...element,
            [index]: event?.target?.value,
        }));
        setSections(update_element);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await EditAccessorSubCategory({ sections: JSON.stringify(sections) }, location?.state?.id);
        setLoading(false);
        if (res.success) {
            return toast.success(res?.message);
        } else {
            return toast.error(res.message);
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[
                            { label: 'Accessor Categories', link: '/accessor-categories' },
                            {
                                label: category.name,
                                link: '/accessor-categories/single-duplicate',
                                state: {
                                    category_id: location?.state?.category_id,
                                },
                            },
                            { label: section?.name },
                        ]}
                    />
                </Col>
                <Col className="flex justify-end items-center space-x-2">
                    <Button
                        size="large"
                        type="link"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center"
                        onClick={addOneSection}
                    >
                        Add Section
                    </Button>
                </Col>
            </Row>
            <form onSubmit={handleSubmit}>
                <section className="my-[5rem]">
                    {sections?.map((value, index) => (
                        <div key={index} className="py-[1rem]">
                            <h4 className="mb-2">section{index + 1}</h4>
                            <Row justify="space-between">
                                {Object?.keys(value)?.map((val, idx) => (
                                    <Col key={idx} xs={24} xl={7} lg={7}>
                                        <Input
                                            id="name"
                                            type="text"
                                            size="large"
                                            value={value[val]}
                                            onChange={(e) => handleChange(e, idx)}
                                        />
                                        {Object?.keys(value)?.length > 1 && (
                                            <Button onClick={() => removeItem(idx)} className="mt-1">
                                                remove
                                            </Button>
                                        )}
                                    </Col>
                                ))}
                            </Row>
                            {index < 1 && (
                                <Button
                                    icon={<PlusOutlined />}
                                    size="large"
                                    className="flex justify-between items-center"
                                    style={{ position: 'absolute', top: 40, right: 0 }}
                                    onClick={() => addItem(value)}
                                >
                                    add
                                </Button>
                            )}
                        </div>
                    ))}
                </section>

                <Button
                    className="ant-btn"
                    htmlType="submit"
                    type="primary"
                    loading={loading}
                    style={{ marginTop: '1rem', float: 'right' }}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </form>
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_accessor_categories: state?.auditReducers?.all_accessor_categories,
});
export default connect(mapStateToProps, { EditAccessorSubCategory })(CategorySettings);
