import axios from 'axios';
import { baseUrl } from 'config';
import axiosInstance from '../../utils/https';
import {
    ALL_MERCHANT_EMPLOYEES,
    APPROVED_AUDITORS,
    AUDITOR_POOL_CERTIFICATIONS,
    AUDITOR_POOL_PORTFOLIOS,
    COMPANY_DETAILS,
    COMPANY_ID,
    FIRST_LOGIN,
    GET_ALL_EMPLOYEES,
    GET_ALL_MERCHANT_USERS,
    MODULE_GUIDE,
    RISK_CURRENT_PERMISSION,
    RISK_PERMISSION,
    RISK_SELF_PORTAL_EMAIL,
    RISK_SELF_PORTAL_TOKEN,
    RISK_SELF_SERVICE,
    USER_DETAILS,
    USER_ID,
    USER_INFO,
    USER_PRICING_PLANS,
    USER_TYPE,
} from '../constants';
import { setType, setToken, setDomain, getType, urlKeys } from 'utils';

export const GetUserDetails = () => async (dispatch, getState) => {
    const user_id = getState()?.authReducers?.user_id || getType('id');
    try {
        const res = await axiosInstance().get(`/user_mgt/users/${user_id}/`, {});
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: USER_INFO, payload: res?.data });
        dispatch({ type: USER_PRICING_PLANS, payload: res?.data?.organization?.merchant_plan });
        dispatch({
            type: USER_TYPE,
            payload: res?.data?.user_type,
        });
        dispatch({ type: USER_ID, payload: res?.data?.id });
        dispatch({
            type: COMPANY_ID,
            payload: res?.data?.organization?.id,
        });
        dispatch({
            type: COMPANY_DETAILS,
            payload: res?.data?.organization,
        });
        return {
            success: true,
            message: res?.message,
            user_info: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateUserDetails = (credentials) => async (dispatch, getState) => {
    const user_id = getState()?.authReducers?.user_id;
    try {
        const res = await axiosInstance().patch(`/user_mgt/users/${user_id}/`, credentials);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetUserDetails());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const ChangePhoto = (credentials) => async (dispatch, getState) => {
    const user_id = getState()?.authReducers?.user_id;
    try {
        const res = await axiosInstance().patch(`/user_mgt/users/${user_id}/`, credentials);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetUserDetails());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdatePassword = (credentials) => async (_, getState) => {
    const user_id = getState()?.authReducers?.user_id;
    try {
        const res = await axiosInstance().put(`/setup/change_password/${user_id}/`, credentials);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const ConfirmPassword = (credentials) => async () => {
    try {
        const res = await axiosInstance().post(`/user_mgt/confirm_password/`, credentials);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const Initiate2FA = (credentials) => async () => {
    try {
        const res = await axiosInstance().post(`/user_mgt/mfa_connect/`, credentials);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        return credentials?.mfa_type === 'authenticator'
            ? {
                  success: true,
                  message: res?.message,
                  url: res?.data?.url,
                  secret: res?.data?.secret,
              }
            : { success: true, message: res?.message };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const Finalize2FASetup = (credentials) => async (dispatch, getState) => {
    const user_id = getState()?.authReducers?.user_id;
    try {
        const res = await axiosInstance().put(`/user_mgt/finalize_mfa_connect/${user_id}/`, credentials);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetUserDetails());
        return { success: true, message: res?.message };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
// auditor pool
export const GetApprovedAuditors = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/auditors/?is_approved=true`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: APPROVED_AUDITORS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAuditorCertifications = (auditor_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/certificates/?auditor=${auditor_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: AUDITOR_POOL_CERTIFICATIONS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAuditorPortfolio = (auditor_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/portfolios/?auditor=${auditor_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: AUDITOR_POOL_PORTFOLIOS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const RequestAuditor = (credentials) => async (dispatch, getState) => {
    const merchant_id = getState()?.generalReducers?.user_info?.organization?.id;
    try {
        const res = await axiosInstance().patch(`/user_mgt/merchants/${merchant_id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetApprovedAuditors());
        dispatch(GetUserDetails());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//M O D U L E   G U I D E   M O D A L S
export const GetModuleGuides = () => async (dispatch, getState) => {
    const user_id = getState()?.generalReducers?.user_info?.id;
    try {
        const res = await axiosInstance().get(`/user_mgt/user_modal/?user=${user_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: MODULE_GUIDE, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const CreateModuleGuide = (credentials) => async (dispatch, getState) => {
    const user = getState()?.generalReducers?.user_info?.id;
    try {
        const res = await axiosInstance().post(`/user_mgt/user_modal/`, { ...credentials, user });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetModuleGuides());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UpdateModuleGuide = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/user_modal/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetModuleGuides());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllMerchantEmployees = (id) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || id;
    try {
        const res = await axiosInstance().get(`/user_mgt/get_employees/?organization_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_EMPLOYEES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return {
            success: false,
            message: err?.message,
        };
    }
};
export const GetAllCompanyEmployees = () => async (dispatch, getState) => {
    const company_id = getState()?.authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/user_mgt/get_employees/?organization_id=${company_id}`);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: GET_ALL_EMPLOYEES, payload: res?.data });
        return { success: true, message: res?.message };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllMerchantUsers = () => async (dispatch, getState) => {
    const company_id = getState()?.authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/user_mgt/merchant_users/?merchant_id=${company_id}`);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: GET_ALL_MERCHANT_USERS, payload: res?.data });
        return { success: true, message: res?.message };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const SelfServiceLogin = (credentials) => async (dispatch) => {
    try {
        const res = await axios.post(`${baseUrl}/scans/risk_auth/`, credentials);
        if (res?.data?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: RISK_SELF_PORTAL_EMAIL, payload: res?.data?.data });
        setToken('');
        setType('');
        setDomain();
        setType('');
        dispatch({ type: FIRST_LOGIN, payload: null });
        dispatch({
            type: USER_TYPE,
            payload: null,
        });
        dispatch({ type: USER_ID, payload: null });
        dispatch({
            type: COMPANY_ID,
            payload: null,
        });
        dispatch({ type: USER_DETAILS, payload: null });
        dispatch({ type: USER_INFO, payload: null });
        dispatch({ type: USER_PRICING_PLANS, payload: null });
        dispatch({
            type: COMPANY_DETAILS,
            payload: null,
        });
        return { success: true, message: res?.data?.message };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const SelfServiceVerification = (credentials) => async (dispatch) => {
    try {
        const res = await axios.post(`${baseUrl}/scans/risk_verify/`, credentials);
        if (res?.data?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: RISK_SELF_PORTAL_TOKEN, payload: res?.data?.token });
        return { success: true, message: res?.data?.message };
    } catch (err) {
        return { success: false, message: err?.response?.data?.message };
    }
};

export const RiskSelfAccess = () => async (dispatch, getState) => {
    const token = getState()?.generalReducers?.risk_self_portal_token;
    const email = getState()?.generalReducers?.risk_self_portal_email;
    const body = {
        token,
        email,
    };
    try {
        const res = await axios.post(`${baseUrl}/scans/risk_access/`, body);
        if (res?.data?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: RISK_PERMISSION, payload: res?.data?.data });
        return { success: true, message: res?.data?.message };
    } catch (err) {
        return { success: false, message: err?.response?.data?.message };
    }
};

export const RiskcurrentPermission = (permission) => (dispatch) => {
    dispatch({ type: RISK_CURRENT_PERMISSION, payload: permission });
};

export const GetSelfServiceData = (params) => async (dispatch, getState) => {
    const token = getState()?.generalReducers?.risk_self_portal_token;
    try {
        const res = await axios.get(`${baseUrl}/scans/selfServiceData/?token=${token}`, {
            params,
        });
        if (res?.data?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: RISK_SELF_SERVICE, payload: res?.data?.data });
        return { success: true, message: res?.data?.message };
    } catch (err) {
        return { success: false, message: err?.response?.data?.message };
    }
};

export const UpdateSelfServiceData = (credentials, params) => async (dispatch, getState) => {
    const token = getState()?.generalReducers?.risk_self_portal_token;
    const email = getState()?.generalReducers?.risk_self_portal_email;
    const body = {
        ...credentials,
        token,
        email,
    };
    try {
        const res = await axios.post(`${baseUrl}/scans/selfServiceData/`, body);
        if (res?.data?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetSelfServiceData(params));
        return { success: true, message: res?.data?.message };
    } catch (err) {
        return { success: false, message: err?.response?.data?.message };
    }
};

export const GetDownloadLink = (module, type, id, segmented) => async () => {
    const getFirstValidUrl = (res) => {
        return res?.url ?? urlKeys.map((key) => res?.urls?.[0]?.[key]).find(Boolean);
    };

    try {
        const res = await axiosInstance().get(`/${module}/${type}${id ? `/${id}` : ''}/download/`);
        const url = segmented ? res.urls : getFirstValidUrl(res);

        return {
            success: true,
            message: 'Success',
            data: url,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetDownloadLinkUnauthorized = (module, type, id, segmented) => async () => {
    const getFirstValidUrl = (res) => {
        return res?.url ?? urlKeys.map((key) => res?.data?.urls?.[0]?.[key]).find(Boolean);
    };

    try {
        const res = await axios.get(`${baseUrl}/${module}/${type}${id ? `/${id}` : ''}/download_file/`);
        const url = segmented ? res.data?.urls : getFirstValidUrl(res);

        return {
            success: true,
            message: 'Success',
            data: url,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
