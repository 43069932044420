import {
    ALL_GROUP_AUTOMATED_SCANS,
    ALL_SCAN_EXCEPTIONS,
    ALL_EXEMPTED_SCANS,
    ALL_AUTOMATED_SCAN_GROUPS,
    ALL_GROUP_AUTOMATED_SCANS_ITEM,
    ALL_ASSIGNED_TASK,
    USER_ASSIGNED_TASK,
    ALL_SCANS,
    SCAN_AI,
} from '../constants';
import axiosInstance from '../../utils/https';

export const GetAutomatedScanGroups = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/integrations/category/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_AUTOMATED_SCAN_GROUPS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetGroupScanResults = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/integrations/subcategory/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_GROUP_AUTOMATED_SCANS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetGroupScanResultsDetails = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/integrations/scan_info/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_GROUP_AUTOMATED_SCANS_ITEM, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllcans = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/integrations/scans`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_SCANS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetScans = (subcategory, account_name) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(
            `/integrations/scans/?subcategory=${subcategory}&account_name=${account_name}`
        );
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_SCANS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetExemptedScans = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/re_exempted_scans/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_EXEMPTED_SCANS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllExceptions = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/integrations/exempted_info`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_SCAN_EXCEPTIONS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateScanException = (credentials, file, subcategory, account_name) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    const body = file !== '' ? credentials?.append('merchant', merchant) : { ...credentials, merchant };
    try {
        const res = await axiosInstance().post(`/integrations/excempted_scans/`, body);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetGroupScanResults());
        dispatch(GetGroupScanResultsDetails(subcategory));
        dispatch(GetScans(subcategory, account_name));
        return {
            success: true,
            message: 'Scan added to exception list',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetScanException = () => async () => {
    try {
        const res = await axiosInstance().get(`/integrations/excempted_scans`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: 'Scan removed from exception list',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const RemoveScanException = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/integrations/excempted_scans/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetExemptedScans());
        dispatch(GetAllExceptions());
        dispatch(GetScanException());
        dispatch(GetGroupScanResults());
        dispatch(GetAutomatedScanGroups());
        dispatch(GetAllcans());
        return {
            success: true,
            message: 'Scan removed from exception list',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetScanAi = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/integrations/scans_ai/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: SCAN_AI, payload: res?.data });
        return {
            success: true,
            message: 'Scan added to exception list',
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AssignedAutomatedScan = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/assign_task`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ASSIGNED_TASK, payload: res?.data });
        return {
            success: true,
            message: res.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UserAssignedAutomatedScan = (user_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/assign_task/?assigned_to=${user_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: USER_ASSIGNED_TASK, payload: res?.data });
        return {
            success: true,
            message: res.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const AssignAutomatedScan = (credentials) => async (dispatch, getState) => {
    const user_id = getState()?.authReducers?.user_id;
    try {
        const res = await axiosInstance().post(`/compliance/assign_task/`, { ...credentials, assigned_by: user_id });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAutomatedScanGroups());
        dispatch(AssignedAutomatedScan());
        return {
            success: true,
            message: res.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UpdateAssignAutomatedScan = (credentials, id, user_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/assign_task/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAutomatedScanGroups());
        dispatch(AssignedAutomatedScan());
        dispatch(UserAssignedAutomatedScan(user_id));
        return {
            success: true,
            message: res.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
