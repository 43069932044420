import React from 'react';
import { Typography, Stack } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { useTheme } from '@mui/styles';
import AppButton from 'components/new_components/AppButton';

const TaskIntroductionModal = (props) => {
    const { open, handleClose, updateModuleIntroModal } = props;
    const theme = useTheme();

    const closeAll = () => {
        updateModuleIntroModal();
        handleClose();
    };
    return (
        <AppCenteredModal
            open={open}
            handleClose={closeAll}
            width="540px"
            actions={
                <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2, mb: 2 }}>
                    <AppButton
                        name="Close"
                        variant="contained"
                        sx={{ minWidth: 'unset', width: 'fit-content' }}
                        onClick={closeAll}
                    />
                </Stack>
            }
        >
            <img src="/img/task-assign-compliance.svg" alt="API compliance" />

            <Typography
                sx={{
                    my: 3,
                    fontSize: 14,
                    fontWeight: 600,
                    color: theme.palette.gray[20],
                }}
                component="span"
            >
                Task for you
            </Typography>

            <Typography
                sx={{ fontSize: 13, fontWeight: 400, letterSpacing: '0.1px', color: theme.palette.gray[30], mt: 1 }}
            >
                You may be given a specific task related to compliance. Such a task is visible in the upper right corner
                of your screen. Task indicators have <span className="text-[#B28C00]">gold color</span>
            </Typography>
        </AppCenteredModal>
    );
};

export default TaskIntroductionModal;
