import React, { useState } from 'react';
import { Stack } from '@mui/material';

// core components
import SectionCard from '../common/exceptions/SectionCard';
import SubcontrolSelectionAside from './components/SubcontrolSelectionAside';
import ResourceAside from '../common/exceptions/ResourceAside';

//translations
import { useTranslation } from 'react-i18next';

const Exceptions = (props) => {
    // props
    const { getControls } = props;

    // state
    const [resource, setResource] = useState('doc');
    const [selectedSubcontrol, setSelectedSubcontrol] = useState(null);

    //translation
    const { t } = useTranslation('compliance');

    // logic functions
    const handleResourceChange = (resource) => {
        setResource(resource);
        setSelectedSubcontrol(null);
    };
    const handleSubgroupIdChange = (id) => {
        setSelectedSubcontrol(id);
    };

    return (
        <Stack direction="row" sx={{ px: 4, py: 5, flex: 1 }} spacing={3}>
            <SectionCard>
                <SubcontrolSelectionAside
                    resource={resource}
                    handleResourceChange={handleResourceChange}
                    handleSubgroupIdChange={handleSubgroupIdChange}
                    selectedSubgroup={selectedSubcontrol}
                />
            </SectionCard>
            <SectionCard>
                <ResourceAside
                    selectedSubgroup={selectedSubcontrol}
                    resource={resource}
                    subgroupIdKey="sub_control"
                    subgroupTitle={`${t('sub')} ${selectedSubcontrol}`}
                    standard="soc2"
                    onExempted={getControls}
                />
            </SectionCard>
        </Stack>
    );
};

export default Exceptions;
