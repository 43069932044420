import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Modal, Row, Table } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

// app components
import BreadCrumb from 'components/Breadcrumb';
import SubsectorActions from '../components/SubsectorActions';

// redux
import { connect } from 'react-redux';
import { DeleteSubsector } from 'store/actions/adminActions';
import SubsectorModal from '../components/SubsectorModal';
import { useHistory } from 'react-router-dom';

const { confirm } = Modal;

const Subsector = (props) => {
    const history = useHistory();
    // props
    const { all_sector, location, DeleteSubsector } = props;

    // state
    const [modal, setModal] = useState(null);
    const [modalData, setModalData] = useState({});

    // memos
    const sector = useMemo(
        () => all_sector.find((criterion) => criterion.id === location.state.sector),
        [location, all_sector]
    );
    // functions
    const openModal = (mode, data = {}) => {
        setModal(mode);
        setModalData(data);
    };
    const closeModal = () => {
        setModal(null);
        setModalData({});
    };
    const deleteSubsector = (id) => {
        confirm({
            title: "Are you sure you want to delete this subsector? Note: Deleting this subsector means that you are deleting this subsector and records of the sector associated with alongside merchant's data",
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteSubsector(id);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };
    const goToItems = (id) => {
        history.push({ pathname: `/admin/cii-directive/subsector/${id}`, state: { sector_title: sector?.name } });
    };

    // constants
    const columns = [
        { title: 'S/N', render: (row) => row.sn },
        { title: 'Name', render: (row) => row.name },
        { title: 'Description', render: (row) => row.description },
        {
            title: 'Actions',
            align: 'right',
            render: (row) => <SubsectorActions row={row} deleteSubsector={deleteSubsector} openModal={openModal} />,
        },
    ];
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Cii Directive', link: '/cii-directive' }, { label: sector?.name }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center"
                        onClick={() => openModal('Add', { cii_sector: sector?.id })}
                    >
                        Add Subsector
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Table
                    columns={columns}
                    dataSource={sector?.subsectors
                        ?.sort((a, b) => a?.id - b?.id)
                        ?.map((subsector, index) => ({ ...subsector, sn: index + 1 }))}
                    rowKey={(subsector) => subsector?.id}
                    onRow={(record) => {
                        return {
                            onDoubleClick: () => goToItems(record?.id),
                        };
                    }}
                    rowClassName="cursor-pointer"
                />
            </section>
            <SubsectorModal open={modal} onClose={closeModal} modalData={modalData} />
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_sector: state?.adminReducers?.all_sector,
});
export default connect(mapStateToProps, { DeleteSubsector })(Subsector);
