import React, { useState } from 'react';
import { Stack } from '@mui/material';

// core components
import SubrequirementSelectionAside from './components/SubrequirementSelectionAside';
import SectionCard from '../common/exceptions/SectionCard';
import ResourceAside from '../common/exceptions/ResourceAside';

//translations
import { useTranslation } from 'react-i18next';

const Exceptions = (props) => {
    // props
    const { getRequirements } = props;

    // state
    const [resource, setResource] = useState('doc');
    const [selectedSubrequirement, setSelectedSubrequirement] = useState(null);

    //translation
    const { t } = useTranslation('compliance');

    // logic functions
    const handleResourceChange = (resource) => {
        setResource(resource);
        setSelectedSubrequirement(null);
    };
    const handleSubgroupIdChange = (id) => {
        setSelectedSubrequirement(id);
    };

    return (
        <Stack direction="row" sx={{ px: 4, py: 5, flex: 1 }} spacing={3}>
            <SectionCard>
                <SubrequirementSelectionAside
                    resource={resource}
                    handleResourceChange={handleResourceChange}
                    handleSubgroupIdChange={handleSubgroupIdChange}
                    selectedSubgroup={selectedSubrequirement}
                />
            </SectionCard>
            <SectionCard>
                <ResourceAside
                    selectedSubgroup={selectedSubrequirement}
                    resource={resource}
                    subgroupIdKey="card4_sub_no"
                    subgroupTitle={`${t('sub')} ${selectedSubrequirement}`}
                    standard="card4"
                    onExempted={getRequirements}
                />
            </SectionCard>
        </Stack>
    );
};

export default Exceptions;
