import { Box, Button, Divider, Typography } from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const PlanCard = (props) => {
    const { name, description, employee_range, features, onPlanSelect, active, isPendingPlan, id, uncheck_features } =
        props;
    return (
        <Box sx={{ mx: 1, position: 'relative' }}>
            {name === 'Growth' && (
                <Typography
                    sx={{
                        backgroundColor: '#395BA9',
                        color: '#FFFFFF',
                        textAlign: 'center',
                        py: 1,
                        borderRadius: '5px',
                        fontSize: '13px',
                        fontWeight: 700,
                        marginTop: '1rem',
                        position: 'absolute',
                        top: '-9%',
                        width: '260px',
                        border: '1px solid #395BA9',
                    }}
                >
                    Best Pick
                </Typography>
            )}
            <Box
                sx={{
                    backgroundColor: name !== 'You’re on Free' ? '#FFFFFF' : '',
                    border:
                        name === 'You’re on Free'
                            ? '1.5px solid #E2E8F0'
                            : name === 'Start up'
                            ? '1px solid #B1C5FF'
                            : name === 'Growth'
                            ? '1px solid #395BA9'
                            : name === 'Enterprise'
                            ? '1px solid #6E8EDF'
                            : '1px solid #395BA9',
                    px: 1.5,
                    py: 3,
                    borderRadius: '5px',
                    width: '260px',
                    height: '500px',
                    position: 'relative',
                }}
            >
                <Typography sx={{ fontSize: '18px', fontWeight: '700', color: '#011947', textTransform: 'capitalize' }}>
                    {name === 'Max' ? 'Enterprise' : name?.replace(/_/g, ' ')}
                </Typography>
                <Typography sx={{ fontSize: '11px', fontWeight: '400', color: '#77777A', mt: 1 }}>
                    {name === 'Start Up'
                        ? 'Perfect for small businesses and startups'
                        : name === 'Growth'
                        ? 'Good for businesses willing to scale'
                        : name === 'Max'
                        ? 'Perfect for small or large'
                        : name === 'Custom'
                        ? 'Perfect for small or large'
                        : description}
                </Typography>
                <Typography sx={{ fontSize: '10px', fontWeight: '500', color: '#77777A', mt: 1 }}>
                    {employee_range} employees
                </Typography>
                <Divider sx={{ mt: 3, mb: 2 }} />
                <Typography sx={{ fontSize: '12px', fontWeight: '500', color: '#395BA9' }}>
                    {name === 'Start Up'
                        ? 'Get started with:'
                        : name === 'Growth'
                        ? 'All the goodness of Start-up plan, with more range plus:'
                        : name === 'Max'
                        ? 'All the beauty of Growth, unlimited and:'
                        : name === 'Custom'
                        ? 'Be the boss'
                        : 'Limited to:'}
                </Typography>
                <Box sx={{ mt: 2 }}>
                    {features?.map((feature, idx) => (
                        <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <CheckIcon color="primary" sx={{ width: 15, height: 15 }} />
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: '#011947',
                                    ml: 1,
                                }}
                            >
                                {feature}
                            </Typography>
                        </Box>
                    ))}
                    {uncheck_features?.map((feature, idx) => (
                        <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <CloseIcon color="error" sx={{ width: 15, height: 15 }} />
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: '#011947',
                                    ml: 1,
                                }}
                            >
                                {feature}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                {!active && (
                    <Box sx={{ position: 'absolute', bottom: '5%' }}>
                        <Typography sx={{ color: '#77777A', fontSize: '10px', fontWeight: '400' }}>
                            Certification Audit available on paid request
                        </Typography>
                        {isPendingPlan ? (
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: 'inherit',
                                    width: '100%',
                                    fontWeight: 600,
                                    backgroundColor: name === 'Growth' ? '#395BA9' : '#002C72',
                                }}
                                disabled
                            >
                                {isPendingPlan ? 'Pending Plan' : 'Current Plan'}
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: 'inherit',
                                    width: '100%',
                                    fontWeight: 600,
                                    backgroundColor: name === 'Growth' ? '#395BA9' : '#002C72',
                                }}
                                onClick={() => onPlanSelect(id)}
                            >
                                Get Started
                            </Button>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default PlanCard;
