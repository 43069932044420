import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { Upload, InfoOutlined } from '@mui/icons-material';
//core component
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppFormInput, AppFormSelect, AppSubmitButton, AppForm } from 'components/new_components/forms';
import AppFormDateInput from 'components/new_components/forms/AppFormDateInput';
import { AppFormDragFileUpload } from 'components/new_components/forms/AppFormDragFileUpload';
import { useTheme } from '@mui/styles';
import { toast } from 'react-toastify';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import { uploadModalValidation } from './utils/validation';
import { accept } from 'validate';
import { severityOptions } from './utils/constants';
//redux
import { connect } from 'react-redux';
import { CreatePentest } from 'store/actions/merchantActions';

//translations
import { useTranslation } from 'react-i18next';

const UploadReportModal = ({ open, handleClose, payload, CreatePentest }) => {
    const [authorization, setAuthorization] = useState(false);
    const [loading, setLoading] = useState(false);

    const theme = useTheme();

    //translation
    const { t } = useTranslation('pentest');

    //function
    const today = new Date().toISOString().split('T')[0];

    const handleAuthorizationCheck = () => {
        setAuthorization(!authorization);
    };

    const handleSubmit = async (values) => {
        if (authorization) {
            const formData = new FormData();
            formData.append('name', values?.name);
            formData.append('severity', values?.severity);
            formData.append('authorization', authorization);
            formData.append('uploaded_by', values?.uploaded_by);
            formData.append('conducted_date', values?.conducted_date);
            formData.append('ip_address', values?.ip_address);
            formData.append('file_upload', values.file_upload);
            setLoading(true);
            const res = await CreatePentest(formData);
            setLoading(false);
            if (res?.success) {
                toast.success('Report Uploaded Successfully');
                handleClose();
            } else {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    toast.error(res?.message);
                }
            }
        } else {
            toast.info('You have to authorize this request!');
        }
    };
    return (
        <AppForm initialValues={payload} onSubmit={handleSubmit} validate={uploadModalValidation}>
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                title={t('uploadReportModal.title')}
                width={600}
                height="70%"
                subtitle={
                    <Stack
                        flexDirection="row"
                        justifyContent="center"
                        sx={{
                            py: 1,
                            pr: 2,
                            fontSize: 12,
                            fontWeight: 400,
                            color: theme.palette.gray[400],
                        }}
                    >
                        <InfoOutlined sx={{ fontSize: 11.8, mr: 1, mt: 0.4 }} />
                        {t('uploadReportModal.subtitle')}
                    </Stack>
                }
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <AppSubmitButton
                            text={t('uploadReportModal.title')}
                            startIcon={<Upload />}
                            variant="contained"
                            color="primary"
                            loading={loading}
                            loadingPosition="center"
                            sx={{ width: '50%' }}
                        />
                    </Box>
                }
            >
                <AppFormInput
                    type="text"
                    label={t('uploadReportModal.scanName')}
                    name="name"
                    placeholder={t('uploadReportModal.testName')}
                />
                <AppFormInput
                    type="text"
                    label={t('uploadReportModal.ipAddress')}
                    name="ip_address"
                    placeholder={t('uploadReportModal.ipAddress')}
                />
                <AppFormSelect
                    name="severity"
                    label={t('uploadReportModal.severityLevel')}
                    options={severityOptions}
                    defaultValue={t('uploadReportModal.severityLevelValue')}
                    toolTip={true}
                    tips={t('uploadReportModal.severityLevelTip')}
                />
                <AppFormInput
                    type="text"
                    label={t('uploadReportModal.doneBy')}
                    name="uploaded_by"
                    placeholder={t('uploadReportModal.platformName')}
                />
                <AppFormDateInput name="conducted_date" label={t('uploadReportModal.pentestDate')} max={today} />
                <AppFormDragFileUpload label={t('uploadReportModal.report')} name="file_upload" accept={accept} />
                <AppCheckboxInput
                    name="authorization"
                    label={t('uploadReportModal.authorization')}
                    onChange={handleAuthorizationCheck}
                    value={authorization}
                />
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { CreatePentest })(UploadReportModal);
