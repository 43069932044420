import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import AppAvatar from 'components/new_components/AppAvatar';
import pfp from 'assets/img/avatar.jpg';
// redux
import { connect, useSelector } from 'react-redux';
import { getType, truncateText } from 'utils';
import { GetDownloadLink } from 'store/actions/generalActions';

const UserDropdownTrigger = (props) => {
    const { showDropdown, company_info, employee_details } = props;
    const theme = useTheme();
    const userType = useMemo(() => getType('type'), []);
    const [imageLink, setImageLink] = useState('');

    const user_info = useSelector((state) => state?.generalReducers?.user_info);

    useEffect(() => {
        if (company_info?.company_img) {
            const user_type =
                user_info.user_type === 'implementer'
                    ? 'implementer'
                    : user_info.user_type === 'auditor'
                    ? 'auditor'
                    : 'merchant';

            // set loading icon on start of fetch request
            GetDownloadLink('user_mgt', user_type, user_info.organization.id)().then((res) => {
                if (res.success) {
                    setImageLink(res.data);
                } else {
                    setImageLink('');
                }
            });
        }
    }, [user_info?.picture, company_info?.company_img]);

    return (
        <Box
            component="button"
            onClick={showDropdown}
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                // backgroundColor: 'transparent',
                width: 'fit-content',
                border: 'none',
                backgroundColor: 'inherit',
                color: theme.palette.primary[900],
            }}
        >
            <AppAvatar src={imageLink || pfp} size={40} />
            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                <Typography
                    component="span"
                    variant="subtitle2"
                    sx={{ textTransform: 'capitalize', pl: 1, fontWeight: 700 }}
                >
                    {userType === 'employee'
                        ? employee_details?.first_name
                            ? `${employee_details?.first_name} ${employee_details?.last_name}`
                            : truncateText(employee_details?.email, '15')
                        : company_info?.name}
                </Typography>
                <ExpandMore color="primary" />
            </Box>
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        company_info: state?.generalReducers?.user_info?.organization,
        employee_details: state?.generalReducers?.user_info?.employee?.user_detail,
    };
};
export default connect(mapStateToProps, {})(UserDropdownTrigger);
