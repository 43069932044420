import React from 'react';
import { Dropdown, Menu } from 'antd';
import { DeleteOutlined, MoreOutlined, EditOutlined } from '@ant-design/icons';

export const ThreatCategoryOperations = (props) => {
    const { openUpdateModal, deleteModal, record } = props;
    const menu = (
        <Menu className="tableaction">
            <Menu.Item key="0" onClick={() => openUpdateModal(record)}>
                <EditOutlined /> Edit
            </Menu.Item>
            <Menu.Item key="1" style={{ color: 'red' }} onClick={() => deleteModal(record)}>
                <DeleteOutlined /> Delete
            </Menu.Item>
        </Menu>
    );

    return (
        record?.tag === 'admin' && (
            <Dropdown overlay={menu} trigger={['click']}>
                <a
                    href="#"
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                    onKeyDown={(e) => e.preventDefault()}
                >
                    <MoreOutlined />
                </a>
            </Dropdown>
        )
    );
};
