import React from 'react';

//mui component
import { Box, Chip } from '@mui/material';

const AppChip = (props) => {
    const { onChange, label, selected, id, name, type, isClickable = true } = props;

    return (
        <Box component="label" htmlFor={id} sx={{ my: 1 }}>
            <input type={type} name={name} id={id} onChange={onChange} value={id} style={{ display: 'none' }} />
            <Chip
                clickable={isClickable}
                label={label}
                variant={selected ? 'filled' : 'outlined'}
                color="primary"
                sx={{
                    transition: 'color 0.5s, background-color 0.5s',
                    fontSize: 14,
                    border: '1px solid',
                }}
            />
        </Box>
    );
};

export default AppChip;
