import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Grid, Typography } from '@mui/material';

// core components
import AuthViewContainer from './components/AuthViewContainer';
import { AppForm, AppFormInput, AppSubmitButton } from 'components/new_components/forms';
import PageHeader from 'components/new_components/PageHeader';

// validation
import { registerValidation } from './utils/authValidation';

// redux
import { RegisterUser } from 'store/actions/authActions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AppButton from 'components/new_components/AppButton';
import { useTheme } from '@mui/styles';
const Register = (props) => {
    const { RegisterUser, history } = props;

    const { t } = useTranslation('auth');
    const theme = useTheme();
    // state
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {
        setLoading(true);
        const payload = {
            first_name: values?.first_name,
            last_name: values?.last_name,
            email: values?.work_email,
            phone_number: values?.phone_number,
            password: values?.password,
            user_type: 'merchant',
            company_name: values.company,
            is_superuser: false,
        };
        const res = await RegisterUser(payload);
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            setTimeout(() => {
                history.push('/auth/login');
            }, 2000);
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <>
            <PageHeader browserTitle="Create account | Smartcomply" />
            <AuthViewContainer
                title={t('register.header')}
                subtitle={t('register.subtitle')}
                altLink={{ text: t('login.header'), to: '/auth/login' }}
                sx={{ width: { xs: '100%', md: '50%' }, mx: 'auto' }}
                divider={false}
            >
                <AppForm
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        work_email: '',
                        phone_number: '',
                        password: '',
                        user_type: '',
                        company: '',
                    }}
                    onSubmit={handleSubmit}
                    validate={(values) => registerValidation(values)}
                >
                    <Grid container spacing={{ xs: 0, md: 2 }}>
                        <Grid item xs={12} md={6}>
                            <AppFormInput
                                type="text"
                                label={t('register.inputLabel1')}
                                placeholder={t('register.inputLabel1')}
                                name="first_name"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AppFormInput
                                type="text"
                                label={t('register.inputLabel2')}
                                placeholder={t('register.inputLabel2')}
                                name="last_name"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={{ xs: 0, md: 2 }}>
                        <Grid item xs={12} md={6}>
                            <AppFormInput
                                type="text"
                                id="company"
                                label={t('register.inputLabel3')}
                                placeholder={t('register.inputLabel3')}
                                name="company"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AppFormInput
                                type="email"
                                id="email"
                                label={t('register.inputLabel4')}
                                placeholder={t('register.inputLabel4')}
                                name="work_email"
                            />
                        </Grid>
                    </Grid>
                    <AppFormInput
                        type="text"
                        id="phone"
                        label={t('register.inputLabel5')}
                        placeholder={t('register.inputLabel5')}
                        name="phone_number"
                    />
                    <AppFormInput
                        type="password"
                        label={t('register.inputLabel6')}
                        placeholder="••••••••"
                        name="password"
                    />
                    {/* <AppFormSelect
                        name="user_type"
                        label={t('register.input7.label')}
                        options={[
                            { name: 'Auditor', value: 'auditor' },
                            { name: 'Merchant', value: 'merchant' },
                        ]}
                        defaultValue={t('register.input7.defaultValue')}
                    /> */}
                    <AppSubmitButton
                        text={t('register.header')}
                        variant="contained"
                        color="primary"
                        loadingPosition="center"
                        sx={{ width: '100%', mt: 3 }}
                        loading={loading}
                    />
                    <Typography sx={{ fontSize: 13, fontWeight: 400, mt: 1, color: theme.palette.neutral[40] }}>
                        By signing up, you agree to the terms of service and privacy notice, including{' '}
                        <AppButton
                            name="cookie notice"
                            variant="text"
                            sx={{
                                minWidth: 'unset',
                                fontSize: 13,
                                fontWeight: 400,
                                p: 0,
                                color: theme.palette.primary[30],
                                '&:hover': {
                                    backgroundColor: 'transparent !important',
                                },
                            }}
                        />
                    </Typography>
                </AppForm>
            </AuthViewContainer>
        </>
    );
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps, { RegisterUser })(Register);
