import { Button, Col, Input, InputNumber, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { CurrencySign } from 'utils';

// redux
import { connect } from 'react-redux';
import { EditPaymentMethod } from 'store/actions/subscriptionActions';
import { toast } from 'react-toastify';

const PaymentMethodModal = (props) => {
    const { open, handleClose, modalData = {}, EditPaymentMethod } = props;

    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // function
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    const handleNumberChange = (name, value) => {
        setData({ ...data, [name]: value });
    };
    const closeAll = () => {
        setData({});
        handleClose();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let payload;
        payload = { ...data };
        if (typeof data?.first_percent !== 'number') {
            payload.first_percent = JSON.stringify({
                smallCompany: payload?.small_company_percent,
                largeCompany: payload?.large_company_percent,
            });
        }
        console.log(payload);
        setLoading(true);
        const res = await EditPaymentMethod(payload, payload?.id);
        setLoading(false);
        if (res?.success) {
            toast.success('You have successfully updated your payment method settings.');
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        setData(modalData);
    }, [modalData]);
    return (
        <Modal
            visible={open}
            onCancel={closeAll}
            title="Update payment method settings"
            footer={null}
            destroyOnClose={true}
        >
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col span={24} style={{ margin: '1rem 0' }}>
                        <label htmlFor="name">Method Name</label>
                        <Input
                            type="text"
                            size="large"
                            name="name"
                            id="name"
                            value={data.name || ''}
                            onChange={handleTextChange}
                            required
                            disabled
                        />
                    </Col>
                    <Col span={24} style={{ margin: '1rem 0' }}>
                        <label>Cost Range (Min - Max)</label>
                        <Row justify="flex-start">
                            <Col span={10}>
                                <InputNumber
                                    size="large"
                                    name="min_cost"
                                    id="min_cost"
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\\s?|(,*)/g, '')}
                                    placeholder="Min. cost"
                                    onChange={(value) => handleNumberChange('min_cost', value)}
                                    value={data?.min_cost || ''}
                                    required
                                    min={0}
                                    style={{ width: '100%' }}
                                    prefix={CurrencySign}
                                />
                            </Col>
                            <Col style={{ display: 'flex' }} className="flex justify-center items-center" span={4}>
                                -
                            </Col>
                            <Col span={10}>
                                {data.name?.toLowerCase()?.includes('standard') ? (
                                    <InputNumber
                                        size="large"
                                        name="max_cost"
                                        id="max_cost"
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\\s?|(,*)/g, '')}
                                        placeholder="Max. cost"
                                        onChange={(value) => handleNumberChange('max_cost', value)}
                                        value={data?.max_cost || ''}
                                        required
                                        min={data?.min_cost + 1}
                                        style={{ width: '100%' }}
                                        prefix={CurrencySign}
                                    />
                                ) : (
                                    <Input
                                        size="large"
                                        name="max_cost"
                                        id="maxCost"
                                        placeholder="Max. cost"
                                        value="-"
                                        className="w-full"
                                        disabled
                                        prefix={CurrencySign}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    {data?.name?.toLowerCase()?.includes('standard') ? (
                        <>
                            <Col span={24} className="my-2">
                                <label htmlFor="first_percent">First Payment Percent</label>
                                <InputNumber
                                    size="large"
                                    name="first_percent"
                                    id="first_percent"
                                    onChange={(value) => handleNumberChange('first_percent', value)}
                                    value={data?.first_percent || ''}
                                    required
                                    min={1}
                                    style={{ width: '100%' }}
                                    addonAfter="%"
                                />
                            </Col>
                            <Col span={24} className="my-2">
                                <label htmlFor="second_percent">Second Payment Percent</label>
                                <InputNumber
                                    size="large"
                                    name="second_percent"
                                    id="second_percent"
                                    onChange={(value) => handleNumberChange('second_percent', value)}
                                    value={data?.second_percent || ''}
                                    required
                                    min={1}
                                    style={{ width: '100%' }}
                                    addonAfter="%"
                                />
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col span={24} className="my-2">
                                <label htmlFor="company_size">Employee count limit</label>
                                <InputNumber
                                    size="large"
                                    name="company_size"
                                    id="company_size"
                                    value={data?.company_size || ''}
                                    onChange={(value) => handleNumberChange('company_size', value)}
                                    required
                                    min={1}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                            <Col span={24} className="my-2">
                                <label htmlFor="small_company_percent">Small Company Percent</label>
                                <InputNumber
                                    size="large"
                                    name="small_company_percent"
                                    id="small_company_percent"
                                    onChange={(value) => handleNumberChange('small_company_percent', value)}
                                    value={data?.small_company_percent || ''}
                                    required
                                    min={1}
                                    style={{ width: '100%' }}
                                    addonAfter="%"
                                />
                            </Col>
                            <Col span={24} className="my-2">
                                <label htmlFor="large_company_percent">Large Company Percent</label>
                                <InputNumber
                                    size="large"
                                    name="large_company_percent"
                                    id="large_company_percent"
                                    onChange={(value) => handleNumberChange('large_company_percent', value)}
                                    value={data?.large_company_percent || ''}
                                    required
                                    min={1}
                                    style={{ width: '100%' }}
                                    addonAfter="%"
                                />
                            </Col>
                        </>
                    )}
                </Row>
                <Row justify="end">
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = (state) => {
    return state;
};
export default connect(mapStateToProps, { EditPaymentMethod })(PaymentMethodModal);
