import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

//translations
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { DeleteDocumentExemption } from 'store/actions/complianceActions';
import { DeleteFormExemption } from 'store/actions/complianceActions';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { DeleteEvidenceExemption } from 'store/actions/complianceActions';

const RevertModal = (props) => {
    const { open, handleClose, id, onExempted, resource } = props;
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    // functions
    const removeException = async () => {
        setLoading(true);
        const res =
            resource === 'doc'
                ? await dispatch(DeleteDocumentExemption(id, 'sslc'))
                : resource === 'form'
                ? await dispatch(DeleteFormExemption(id, 'sslc'))
                : await dispatch(DeleteEvidenceExemption(id, 'sslc'));
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            onExempted();
            handleClose();
        } else {
            toast.error('Something went wrong!');
        }
    };
    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            noShadow
            noClose
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#F8FAFC',
                        gap: 2,
                        py: 2,
                        pr: 3,
                        mx: '-2rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit' }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        sx={{
                            mr: 2,
                            backgroundColor: '#37A372',
                            textTransform: 'inherit',
                            color: '#FFFFFF',
                            px: 2,
                            '&:hover': {
                                backgroundColor: '#37A372',
                                color: '#FFFFFF',
                            },
                        }}
                        loading={loading}
                        loadingPosition="center"
                        variant="contained"
                        color="primary"
                        onClick={removeException}
                    >
                        Yes, revert
                    </LoadingButton>
                </Box>
            }
            sx={{ borderRadius: 1, overflowX: 'scroll' }}
            width="500px"
        >
            <Box sx={{ mt: 2 }}>
                <Typography sx={{ color: '#475569', fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>
                    Revert document?
                </Typography>
                <Typography sx={{ color: '#475569', fontSize: '13px', fontWeight: 400, textAlign: 'center', my: 2 }}>
                    Reverting this document policy means it will be amongst your compliance implementation process. Are
                    you sure?
                </Typography>
            </Box>
        </AppCenteredModal>
    );
};

export default RevertModal;
