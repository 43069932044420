import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';

//translations
import { useTranslation } from 'react-i18next';

const BackgroundCheckGraphReport = ({ dashboardResult }) => {
    // const labels = ['Low', 'Medium', 'High', 'Unclassified'];
    const { employee_with_backgroundcheck_count, people_without_background_check } = dashboardResult;

    //translation
    const { t } = useTranslation('overview');

    //constants
    const data = {
        labels: [t('employeeReportSection.backgroundCheck'), t('employeeReportSection.noBackgroundCheck')],
        datasets: [
            {
                label: '',
                // data: [12, 19],
                data: [employee_with_backgroundcheck_count, people_without_background_check],
                backgroundColor: ['#05DEEC', '#0A1A4A'],
                borderWidth: 0,
                borderRadius: '10px',
                boxShadow: '0px 4px 4px rgba(10, 26, 74, 0.42)',
            },
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: '',
            },
            datalabels: {
                display: false,
            },
        },
    };
    ChartJS.register(ArcElement, Tooltip, Legend);
    return (
        <div>
            <Doughnut options={options} data={data} type="doughnut" className="!h-[250px] !w-[250px] m-auto" />
        </div>
    );
};

export default BackgroundCheckGraphReport;
