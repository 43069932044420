import React from 'react';
import FirstLayerReport from './FirstLayerReport';
import SummaryReportCard from '../SummaryReportCard';

//translations
import { useTranslation } from 'react-i18next';

const NDPRIndex = ({ percentage, kdpaSummary = {}, kdpaGeneral = {} }) => {
    //translation
    const { t } = useTranslation('overview');

    //function
    const toListEntry = Object.entries(kdpaSummary);
    const newList = toListEntry?.map((res) => {
        const { user_doc, user_form, total_form, total_doc } = res?.[1] || {};
        const steps = ((user_doc + user_form) * 100) / (total_doc + total_form) || 0;
        return {
            title: res?.[0],
            ...res?.[1],
            stepCount: parseInt(steps.toString()),
        };
    });
    return (
        <div className="">
            <FirstLayerReport kdpaGeneral={kdpaGeneral} percentage={percentage} />
            <div className="py-8">
                <h3 className="textbrandColor font-bold text-[23px]">{t('standardTab.summary')}</h3>
                <div className="pt-4">
                    {newList?.map((res, index) => (
                        <React.Fragment key={index}>
                            <SummaryReportCard details={res} />
                        </React.Fragment>
                    ))}
                    {/*<SummaryReportCard title="Requirement 1 - Install and maintain a firewall configuration to protect cardholder data" />*/}
                    {/*<SummaryReportCard title="Requirement 2 - Do not use vendor-supplied defaults for system passwords and other security parameters" />*/}
                    {/*<SummaryReportCard title="Requirement 3 - Install and maintain a firewall configuration to protect cardholder data" />*/}
                    {/*<SummaryReportCard title="Requirement 4 - Install and maintain a firewall configuration to protect cardholder data" />*/}
                </div>
            </div>
        </div>
    );
};

export default NDPRIndex;
