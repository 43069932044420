import React, { useState } from 'react';
import { toast } from 'react-toastify';

import AppDeleteModal from 'components/new_components/AppDeleteModalNew';

// redux
import { useDispatch } from 'react-redux';
import { DeleteAssetGroup } from 'store/actions/merchantActions';

const DeleteGroupModal = (props) => {
    const { open, handleClose, id, closeGroup } = props;

    const [deleting, setDeleting] = useState(false);
    const dispatch = useDispatch();

    //function
    const handleDelete = async () => {
        setDeleting(true);
        const res = await dispatch(DeleteAssetGroup(id));
        setDeleting(false);
        if (res?.success) {
            toast.success('Successfully deleted an asset group.');
            handleClose();
            closeGroup();
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <AppDeleteModal
            open={open}
            handleClose={handleClose}
            title={'Delete Group'}
            subtitle={
                <div className="mt-3">
                    <ul className="list-disc">
                        <li className="text-[13px] text-[#64748B] font-normal">
                            All assets under this group will be ungrouped.
                        </li>
                        <li className="text-[13px] text-[#64748B] font-normal">
                            All risk assessments ran on this group will be deleted.
                        </li>
                    </ul>
                    <p className="text-[13px] text-[#64748B] font-normal mt-4 ml-[-1.2rem]">
                        Are you sure you want to proceed?
                    </p>
                </div>
            }
            deleting={deleting}
            onDelete={handleDelete}
        />
    );
};
export default DeleteGroupModal;
