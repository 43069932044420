import React, { useEffect, useState } from 'react';
//core components
import { List, ListItem, ListItemText, ListItemAvatar, Typography, Box, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DescriptionIcon from '@mui/icons-material/Description';
import AppModal from 'components/new_components/AppModal';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { GetCodeReviewDocument } from 'store/actions/adminActions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { GetMonthFormatDate } from 'utils';
import EmptyState from 'components/new_components/EmptyState';
import CircularLoader from 'components/Loaders/CircularLoader';

const CodeReviewDownload = (props) => {
    //theme
    const theme = useTheme();
    //props
    const { open, handleClose, row, GetCodeReviewDocument, codereview_uploaded_documents } = props;
    const [loading, setLoading] = useState(false);

    // async functions
    const getallCodeReviewDocument = async () => {
        setLoading(true);
        const res = await GetCodeReviewDocument(row?.codereview_id, row?.merchant);
        setLoading(false);
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };
    useEffect(() => {
        getallCodeReviewDocument();
    }, []);
    return (
        <AppModal open={open} title="Download Report" handleClose={handleClose} width={500}>
            {loading ? (
                <CircularLoader />
            ) : (
                <List sx={{ marginLeft: '-1rem' }}>
                    {codereview_uploaded_documents && codereview_uploaded_documents?.length ? (
                        codereview_uploaded_documents
                            ?.sort((a, b) => b?.id - a?.id)
                            .map((item) => (
                                <ListItem sx={{ alignItems: 'flex-start' }} key={item?.id}>
                                    <ListItemAvatar>
                                        <DescriptionIcon color="primary" sx={{ fontSize: 45 }} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            fontSize: '16px',
                                            color: theme.palette.gray[800],
                                            marginLeft: '0.5rem',
                                        }}
                                    >
                                        <Typography
                                            color={theme.palette.gray[900]}
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: '18px',
                                                marginTop: '-8px',
                                            }}
                                        >
                                            Code reviewReport1{item?.codereview_id}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginTop: 1,
                                            }}
                                        >
                                            <Typography sx={{ fontSize: 12 }}>
                                                <CalendarTodayIcon sx={{ fontSize: 14, marginRight: 1 }} />{' '}
                                                {GetMonthFormatDate(item?.date_created)}
                                            </Typography>
                                            <Chip
                                                sx={{ marginRight: 1, marginBottom: 1 }}
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: 600,
                                                            color: theme.palette.gray[600],
                                                            textTransform: 'capitalize',
                                                        }}
                                                    >
                                                        <FiberManualRecordIcon
                                                            sx={{
                                                                width: 12,
                                                                height: 12,
                                                                marginRight: theme.spacing(0.5),
                                                            }}
                                                            color={
                                                                item?.severity === 'high'
                                                                    ? 'error'
                                                                    : item?.severity === 'medium'
                                                                    ? 'warning'
                                                                    : 'success'
                                                            }
                                                        />
                                                        {item?.severity}
                                                    </Typography>
                                                }
                                                variant="outlined"
                                                size="small"
                                            />
                                            <Chip
                                                sx={{ marginRight: 1, marginBottom: 1 }}
                                                onClick={() => {
                                                    ('ehh');
                                                }}
                                                label={
                                                    <Typography
                                                        component={'a'}
                                                        href={item?.file}
                                                        sx={{
                                                            fontSize: 12,
                                                            fontWeight: 600,
                                                            color: theme.palette.gray[600],
                                                            textTransform: 'capitalize',
                                                        }}
                                                    >
                                                        <FileDownloadIcon
                                                            sx={{
                                                                width: 12,
                                                                height: 12,
                                                                marginRight: theme.spacing(0.5),
                                                            }}
                                                        />
                                                        Download
                                                    </Typography>
                                                }
                                                variant="outlined"
                                                size="small"
                                            />
                                        </Box>
                                    </ListItemText>
                                </ListItem>
                            ))
                    ) : (
                        <EmptyState description={'No Report Yet'} />
                    )}
                </List>
            )}
        </AppModal>
    );
};

const mapStateToProps = (state) => {
    return {
        codereview_uploaded_documents: state?.adminReducers?.codereview_uploaded_documents,
    };
};

export default connect(mapStateToProps, { GetCodeReviewDocument })(CodeReviewDownload);
