import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Col, Modal, Row } from 'antd';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { toast } from 'react-toastify';

import VideosModal from './components/VideosModal';
import BreadCrumb from 'components/Breadcrumb';

// redux
import { connect } from 'react-redux';
import { GetAllCoursesVideos, DeleteCourseVideo, UpdateCourseVideo } from 'store/actions/trainingActions';
import { GetDownloadLink } from 'store/actions/generalActions';

const { confirm } = Modal;

const CourseVideos = (props) => {
    const { all_training_courses, all_courses_videos, GetAllCoursesVideos, DeleteCourseVideo, UpdateCourseVideo } =
        props;
    const { course_id } = useParams();

    // state
    const [modal, setModal] = useState(false);
    const [record, setRecord] = useState(null);
    const [loading, setLoading] = useState();

    // memos
    const course = useMemo(() => {
        return all_training_courses?.find((course) => course?.id === course_id);
    }, [all_training_courses]);
    const videos = useMemo(
        () =>
            all_courses_videos
                ?.filter((video) => video?.course === course_id)
                ?.sort((a, b) => a?.video_number - b?.video_number),
        [all_courses_videos]
    );

    // functions
    const openModal = (mode, data = {}) => {
        setModal(mode);
        setRecord(data);
    };
    const closeModal = () => {
        setModal(null);
        setRecord({});
    };
    const getAllCoursesVideos = async () => {
        setLoading(true);
        const res = await GetAllCoursesVideos();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const deleteVideo = (video) => {
        confirm({
            title: 'Are you sure delete this video?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteCourseVideo(video?.id);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };
    const moveVideo = async (video_id, position) => {
        await UpdateCourseVideo({ video_number: position }, video_id);
    };

    // useEffect
    useEffect(() => {
        getAllCoursesVideos();
    }, []);

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Course', link: '/training' }, { label: course?.title }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center"
                        onClick={() => openModal('Add')}
                    >
                        Add Video
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {loading ? (
                    'loading...'
                ) : videos && videos?.length ? (
                    <Row wrap gutter={24}>
                        {videos?.map((video, index) => {
                            const actions = [
                                <EditOutlined title="Edit" onClick={() => openModal('Edit', video)} />,
                                <DeleteOutlined title="Delete" onClick={() => deleteVideo(video)} />,
                            ];
                            if (index)
                                actions.push(
                                    <ArrowUpOutlined
                                        title="Move up"
                                        onClick={() => moveVideo(video?.id, videos?.[index - 1]?.video_number)}
                                    />
                                );
                            if (index < videos?.length - 1)
                                actions.push(
                                    <ArrowDownOutlined
                                        title="Move down"
                                        onClick={() => moveVideo(video?.id, videos?.[index + 1]?.video_number)}
                                    />
                                );

                            return <Video video={video} actions={actions} key={video.id} />;
                        })}
                    </Row>
                ) : (
                    'empty'
                )}
            </section>
            <VideosModal
                mode={modal}
                open={Boolean(modal)}
                record={record}
                handleClose={closeModal}
                course={course_id}
            />
        </div>
    );
};

const Video = ({ video, actions }) => {
    const [viewLink, setViewLink] = useState({ id: null, loading: false, activeLink: '' });

    const getDownloadLink = async (id) => {
        if (id && !viewLink.loading) {
            setViewLink({ ...viewLink, id, loading: true });
            const res = await GetDownloadLink('compliance', 'course_video', id)();
            setViewLink({ ...viewLink, id, loading: false });
            if (res?.success) {
                setViewLink({ id, loading: false, activeLink: res.data });
            } else {
                toast.error('Something went wrong!');
            }
        }
    };

    useEffect(() => {
        getDownloadLink(video.id);
    }, []);

    return (
        <Col xs={24} md={12} key={video?.id} className="mb-4">
            <Card
                cover={
                    <video controls width="100%" height="380" style={{ height: 380 }} key={viewLink.activeLink}>
                        <source src={viewLink.activeLink || video?.file} />
                    </video>
                }
                actions={actions}
            >
                <Card.Meta title={video?.title} description={video?.description} />
            </Card>
        </Col>
    );
};

const mapStateToProps = (state) => ({
    all_training_courses: state?.trainingReducers?.all_training_courses,
    all_courses_videos: state?.trainingReducers?.all_courses_videos,
});
export default connect(mapStateToProps, { GetAllCoursesVideos, DeleteCourseVideo, UpdateCourseVideo })(CourseVideos);
