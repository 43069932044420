import React, { useState } from 'react';
import { Box, Card, Dialog, DialogContent, Grid, Grow, Typography, IconButton, useTheme } from '@mui/material';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { GetCertificateValidity } from 'store/actions/auditActions';
import { AppForm, AppFormInput, AppSubmitButton } from 'components/new_components/forms';
import { validateForm } from './validation';
import moment from 'moment';
import Countdown from 'react-countdown';
import CheckIcon from '@mui/icons-material/Check';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const CertificateCountdown = (props) => {
    const theme = useTheme();
    const { GetCertificateValidity, merchant_info } = props;
    const [valid_certificate, setValid_certificate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    // functions
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const handleSubmit = async (values) => {
        setLoading(true);
        const response = await GetCertificateValidity(values);
        setLoading(false);
        if (response?.success) {
            toast.success('Successful');
            setValid_certificate(response?.data);
            handleOpen();
        } else {
            toast.error(response?.message);
        }
    };

    // countdown function + constants
    const renderer = ({ hours, minutes, seconds, completed, days }) => {
        if (completed) {
            return (
                <Typography
                    variant="h3"
                    sx={{ color: theme.palette.primary[20], fontWeight: 600, mb: 3, textTransform: 'uppercase' }}
                >
                    This certificate is no longer valid.
                </Typography>
            );
        } else {
            return (
                <Typography
                    variant="h3"
                    sx={{ color: theme.palette.primary[20], fontWeight: 600, mb: 3, textTransform: 'uppercase' }}
                >
                    {days} days . {hours}:{minutes}:{seconds}
                </Typography>
            );
        }
    };
    const date = new Date();

    // seconds are in milliseconds
    const secondsInADay = 86400000;
    const secondsSpentToday = (date.getHours() * 60 + date.getMinutes()) * 60 * 1000;

    const daysLeft = valid_certificate?.days_remaining ?? 0;
    const secondsLeftToday = secondsInADay - secondsSpentToday;

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >
            <Grid item xs={3}>
                <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#000000' }}>
                    Please enter your barcode to check your validity
                </Typography>
                <AppForm
                    initialValues={{
                        barcode_number: '',
                    }}
                    onSubmit={handleSubmit}
                    validate={validateForm}
                >
                    <AppFormInput
                        label="Barcode Number"
                        placeholder="Enter barcode Number"
                        name="barcode_number"
                        fullWidth
                        inputProps={{ maxLength: 13 }}
                    />
                    <AppSubmitButton
                        loading={loading}
                        loadingPosition="center"
                        text="submit"
                        variant="contained"
                        sx={{ ml: 2, mt: 5, float: 'right' }}
                        onClick={handleSubmit}
                    />
                </AppForm>

                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    transitionDuration={1000}
                    keepMounted
                    onClose={handleClose}
                    sx={{
                        '& .MuiDialog-container': {
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                    }}
                    PaperProps={{
                        sx: {
                            boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                            position: 'relative',
                            maxHeight: 600,
                            m: 0,
                            zIndex: 1002,
                        },
                    }}
                    scroll="paper"
                >
                    <DialogContent sx={{ p: 0 }}>
                        <Card
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                padding: '4rem 2rem',
                            }}
                        >
                            <CheckIcon sx={{ color: '#0CB732', width: '100px', height: '100px' }} />
                            <Box>
                                <Countdown
                                    date={Date.now() + (secondsLeftToday + daysLeft * secondsInADay)}
                                    renderer={renderer}
                                />
                            </Box>
                            <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#000000' }}>
                                This certificate was issued under the <span>{merchant_info?.cert_body}</span> merchant
                                compliance scheme by 386konsult Limited, implemented by Smartcomply
                            </Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#000000', mt: 3 }}>
                                <strong>Name of Company</strong>: {valid_certificate?.company_name}
                            </Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#000000', mt: 2 }}>
                                <strong>Issued Date</strong>:{' '}
                                {moment(valid_certificate?.issued_date).format('MM-DD-YYYY')}
                            </Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#000000', mt: 2 }}>
                                <strong>Expiry Date</strong>:{' '}
                                {moment(valid_certificate?.expiry_date).format('MM-DD-YYYY')}
                            </Typography>
                        </Card>
                    </DialogContent>
                    <IconButton sx={{ position: 'absolute', right: 0, top: 2 }} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Dialog>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        valid_certificate: state?.auditReducers?.valid_certificate,
        merchant_info: state?.merchantReducers?.merchant_info,
    };
};
export default connect(mapStateToProps, {
    GetCertificateValidity,
})(CertificateCountdown);
