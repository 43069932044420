import React, { useState, useMemo } from 'react';
import { Stack } from '@mui/material';

// core components
import ResourceAside from '../common/exceptions/ResourceAside';
import SectionCard from '../common/exceptions/SectionCard';

// redux
import { connect } from 'react-redux';
import IsogroupSelectionAside from './components/IsogroupSelectionAside';

const Exceptions = (props) => {
    // props
    const { getIsogroups, all_isogroups, category, loading } = props;

    // state
    const [resource, setResource] = useState('doc');
    const [selectedIsogroupId, setSelectedIsogroupId] = useState(null);

    // logic functions
    const handleResourceChange = (resource) => {
        setResource(resource);
        setSelectedIsogroupId(null);
    };
    const handleIsogroupChange = (id) => {
        setSelectedIsogroupId(id);
    };
    const handleGetIsogroups = () => {
        getIsogroups(category, true);
    };

    // memos
    const selectedIsogroupTitle = useMemo(() => {
        return all_isogroups?.find((isogroup) => isogroup?.id === selectedIsogroupId)?.title;
    }, [selectedIsogroupId]);

    return (
        <Stack direction="row" sx={{ px: 4, py: 5, flex: 1 }} spacing={3}>
            <SectionCard>
                <IsogroupSelectionAside
                    resource={resource}
                    handleResourceChange={handleResourceChange}
                    handleIsogroupChange={handleIsogroupChange}
                    selectedIsogroup={selectedIsogroupId}
                    loading={loading}
                />
            </SectionCard>
            <SectionCard>
                <ResourceAside
                    selectedSubgroup={selectedIsogroupId}
                    resource={resource}
                    subgroupIdKey="annex_two"
                    subgroupTitle={selectedIsogroupTitle}
                    standard="isms"
                    onExempted={handleGetIsogroups}
                />
            </SectionCard>
        </Stack>
    );
};
const mapStateToProps = (state) => {
    return {
        all_isogroups: state?.adminReducers?.all_isogroupstwo,
    };
};
export default connect(mapStateToProps, {})(Exceptions);
