import { Box, Stack } from '@mui/material';
import LoadingState from 'components/new_components/LoadingState';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GetAllAccessorCategories, GetAllAccessorAnswers } from 'store/actions/auditActions';
import SidebarStepQuestion from './SidebarStepQuestion';
import { questionsList } from '../testdata';
import QuestionOne from '../accessorQuestions/QuestionOne';
import FormItem from '../accessorQuestions/FormItem';

const Questions = (props) => {
    // props
    const {
        GetAllAccessorCategories,
        GetAllAccessorAnswers,
        all_accessor_categories,
        all_accessor_answers,
        all_merchants,
        all_audit_requests,
    } = props;
    const { id } = useParams();

    // state
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({});
    const history = useHistory();
    const merchant_accessor_answers = all_accessor_answers?.filter((answer) => answer?.merchant === id);
    const getAllAccessorCategories = async () => {
        setLoading(true);
        const res = await GetAllAccessorCategories();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.success);
        }
    };

    const getAllAccessorAnswers = async () => {
        setLoading(true);
        const res = await GetAllAccessorAnswers(id);
        setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong, couldn't fetch questions.");
        }
    };

    // useEffect
    useEffect(() => {
        getAllAccessorCategories();
        getAllAccessorAnswers();
    }, []);

    // sort audit categories by id, the array should start with the default "contact information" category.
    const standardCategories = useMemo(() => {
        if (!all_accessor_categories || !id) return [];
        const sortedStandardCategories = all_accessor_categories?.sort((a, b) => a?.id - b?.id);
        const standardsWithIndex = [
            { name: 'Auditor Contact Information', description: 'Assessed Entity', isDone: true },
            ...sortedStandardCategories,
        ].map((category, idx) => ({
            ...category,
            index: idx + 1,
        }));
        return standardsWithIndex;
    }, [id, all_accessor_categories]);

    const activeCategory = useMemo(() => {
        return standardCategories?.find((category) => category.index === currentStep);
    }, [currentStep, standardCategories]);

    // functions
    const goBack = () => {
        if (merchant_accessor_answers?.length) {
            history.push(`/admin/accessor`);
        } else {
            history.push(`/admin/accessor/review/${id}`);
        }
    };
    const goToStep = (sn) => {
        setFormData({});
        setCurrentStep(sn);
    };
    // navigate the categories
    const prev = () => {
        if (currentStep === 1) return;
        setCurrentStep((prevState) => prevState - 1);
    };
    const next = () => {
        setCurrentStep((prevState) => prevState + 1);
    };

    const handleCategoryNavigation = async () => {
        if (isLastCategory()) {
            return history.push('/admin/accessor');
        } else {
            return next();
        }
    };

    const isLastCategory = () => {
        return standardCategories?.length === currentStep;
    };
    const merchant = all_merchants?.find((merchant) => merchant?.id === id);
    const answer = useMemo(() => {
        return (
            merchant_accessor_answers &&
            merchant_accessor_answers?.find(
                (answer) => answer?.category === activeCategory?.id && answer?.merchant === merchant?.id
            )
        );
    }, [merchant_accessor_answers, activeCategory?.id, merchant?.id]);

    useEffect(() => {
        if (answer?.category === activeCategory?.id) {
            setCurrentStep(merchant_accessor_answers?.length + 2);
        }
    }, [merchant_accessor_answers?.length]);
    const find_roc_request = all_audit_requests?.find(
        (report) => report?.merchant === id && report?.compliance === 'pcidss4.0'
    );
    return (
        <Box sx={{ position: 'relative', height: '100%' }}>
            {loading ? (
                <LoadingState />
            ) : (
                <Box className="flex transition rounded-[8px] h-full overflow-hidden">
                    <SidebarStepQuestion
                        questionsList={questionsList}
                        standard={merchant?.name}
                        goBack={goBack}
                        goToStep={goToStep}
                        currentStep={currentStep}
                        categories={standardCategories}
                        answers={merchant_accessor_answers}
                    />
                    <div className="bg-[#FFFFFF] w-full">
                        <h3 className="audit_title">{activeCategory?.name}</h3>
                        <div className="h-[calc(100%_-_53.59px)] overflow-y-auto flex flex-col">
                            <div className="w-full py-3 border-b border-[#B8BCCC80] px-4">
                                <h3 className="text-[#46464A] font-normal text-lg">{activeCategory?.description}</h3>
                            </div>
                            <Stack sx={{ p: 3, flex: 1 }}>
                                {currentStep === 1 ? (
                                    <QuestionOne goNext={next} />
                                ) : (
                                    <FormItem
                                        category={activeCategory}
                                        goPrev={prev}
                                        answers={merchant_accessor_answers}
                                        navigateCategory={handleCategoryNavigation}
                                        isLast={isLastCategory}
                                        merchant={merchant}
                                        roc_id={find_roc_request?.id}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                )}
                            </Stack>
                        </div>
                    </div>
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = (state) => ({
    all_accessor_categories: state?.auditReducers?.all_accessor_categories,
    all_accessor_answers: state?.auditReducers?.all_accessor_answers,
    all_merchants: state?.adminReducers?.all_merchants,
    all_audit_requests: state?.auditReducers?.all_audit_requests,
});
export default connect(mapStateToProps, { GetAllAccessorCategories, GetAllAccessorAnswers })(Questions);
