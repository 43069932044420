import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material/';

import { AppForm, AppFormInput, AppSubmitButton } from 'components/new_components/forms';
import AppCard from 'components/new_components/AppCard';
import { validation } from 'validate';

const NINValidate = (values) => {
    const errors = {};
    const checkNumberValidate = validation(values.background_checknumber, 'NIN', true);
    if (!checkNumberValidate.isValid) {
        errors.background_checknumber = checkNumberValidate.errorMessage;
    }

    return errors;
};
const BackgroundCheckForm = (props) => {
    const { check, id, loading, handleBackgroundCheck } = props;
    const theme = useTheme();

    return (
        <Grid item md={7} lg={7}>
            <AppCard sx={{ p: 5, width: 700 }}>
                <Stack>
                    <Box
                        sx={{
                            fontSize: '12px',
                            color: theme.palette.gray[40],
                            fontWeight: 400,
                            border: '0.3px solid #C7C6CA',
                            p: 0.5,
                            mx: 1,
                            my: 1,
                            borderRadius: '4px',
                            width: 610,
                        }}
                    >
                        <Box sx={{ display: 'flex' }}>
                            <ErrorOutline color={theme.palette.gray[40]} fontSize="small" />
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    color: '#77777A',
                                }}
                            >
                                Tips
                            </Typography>
                        </Box>
                        <ul
                            style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                listStyleType: 'disc',
                                marginLeft: '1rem',
                                color: '#77777A',
                            }}
                        >
                            <li>
                                On your mobile device, dial <b>*346#</b> and then follow the instructions on how to get
                                your NIN Number
                            </li>
                            <li>Only enter the NIN number on your printed NIN slip / National ID card.</li>
                        </ul>
                    </Box>
                    {/* <Box
                        sx={{
                            fontSize: '12px',
                            color: theme.palette.gray[40],
                            fontWeight: 400,
                            border: '0.3px solid #C7C6CA',
                            p: 0.5,
                            mx: 1,
                            my: 1,
                            borderRadius: '4px',
                            width: 610,
                        }}
                    >
                        <Box sx={{ display: 'flex' }}>
                            <ErrorOutline color={theme.palette.gray[40]} fontSize="small" />
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    color: '#77777A',
                                }}
                            >
                                Tips
                            </Typography>
                        </Box>
                        <ul
                            style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                listStyleType: 'disc',
                                marginLeft: '1rem',
                                color: '#77777A',
                            }}
                        >
                            <li>
                                On your mobile device, dial <b>*346*3*Your NIN *715461#</b>
                            </li>
                            <li>You will receive an SMS containing the virtual NIN which you will input below.</li>
                        </ul>
                    </Box> */}
                    <Box sx={{ mt: '55px', width: '485px' }}>
                        <AppForm
                            initialValues={{ background_checknumber: '' }}
                            onSubmit={handleBackgroundCheck}
                            validate={(values) => NINValidate(values)}
                        >
                            <AppFormInput type="text" label="NIN" placeholder="NIN" name="background_checknumber" />
                            <AppSubmitButton
                                text="Submit"
                                variant="contained"
                                color="primary"
                                loading={loading}
                                loadingPosition="center"
                                disabled={check || id?.toString()?.length <= 4}
                                sx={{
                                    width: '485px',
                                    height: '50px',
                                    marginTop: '30px',
                                }}
                            />
                        </AppForm>
                    </Box>
                </Stack>
            </AppCard>
        </Grid>
    );
};

export default BackgroundCheckForm;
