import React, { useMemo } from 'react';
//core components
import { Grid, Box } from '@mui/material';
import ScanGroupItem from './ScanGroupItem';
import NetworkIcon from 'assets/img/network-icon.svg';
import IdentityManagementIcon from 'assets/img/identity_mgt.svg';
import DataManagementIcon from 'assets/img/data_mgt-icon.svg';
import SecurityMonitoingIcon from 'assets/img/security_monitoring-icon.svg';
import ServerManagementIcon from 'assets/img/server_management_icon.svg';

//utils
import EmptyComponent from './EmptyComponent';

const ScanGroups = ({ handleChangeView, groups, isScan, handleCategory, category }) => {
    const exception_categories = useMemo(() => {
        return (!isScan ? groups : [])?.map((group, index) => {
            return {
                id: index,
                name: group?.category,
                subcategory: group?.subcategory,
                passed: group?.total_passed_scan_count,
                failed: group?.total_failed_scan_count,
                total: group?.total_scan_count,
                icon:
                    group?.category === 'identity and access management'
                        ? IdentityManagementIcon
                        : group?.category === 'data management'
                        ? DataManagementIcon
                        : group?.category === 'system & security monitoring'
                        ? SecurityMonitoingIcon
                        : group?.category === 'server configuration'
                        ? ServerManagementIcon
                        : NetworkIcon,
            };
        });
    }, [groups]);
    const scan_categories = useMemo(() => {
        return (groups || [])?.map((group) => {
            const { id, name, date_created, date_updated, failed, passed, total, is_assigned_to_current_user } =
                group || {};

            const getIcon = (groupName) => {
                switch (groupName?.toLowerCase()) {
                    case 'identity and access management':
                        return '/img/automated-scan/identity-icon.svg';
                    case 'data management':
                        return '/img/automated-scan/data-management-icon.svg';
                    case 'system & security monitoring':
                        return '/img/automated-scan/monitoring-icon.svg';
                    case 'server configuration':
                        return '/img/automated-scan/configuration_icon.svg';
                    case 'configuration management':
                        return '/img/automated-scan/configuration_icon.svg';
                    case 'software documentation lifecycle':
                        return '/img/automated-scan/documentation-icon.svg';
                    case 'vulnerability management':
                        return '/img/automated-scan/vulnerability-icon.svg';
                    default:
                        return '/img/automated-scan/network-icon.svg';
                }
            };

            return {
                id,
                name,
                date_created,
                date_updated,
                failed,
                passed,
                total,
                icon: getIcon(name),
                is_assigned_to_current_user,
            };
        });
    }, [groups]);

    const categories = isScan ? scan_categories : exception_categories;
    return (
        <Box sx={{ px: '1rem', py: '2rem', flexGrow: 1, height: '100%', overflowY: 'auto' }}>
            {categories?.length === 0 ? (
                <Box sx={{ width: '100%' }}>
                    <EmptyComponent text="No Scan has been exempted" />
                </Box>
            ) : (
                <Grid container spacing={2}>
                    {categories?.map((group) => {
                        return (
                            <ScanGroupItem
                                name={group?.name}
                                key={group?.id}
                                id={group?.id}
                                failed={group.failed}
                                passed={group.passed}
                                total={group.total}
                                isScan={isScan}
                                img={group?.icon}
                                group={group}
                                onClick={handleChangeView}
                                handleCategory={handleCategory}
                                category={category}
                            />
                        );
                    })}
                </Grid>
            )}
        </Box>
    );
};

export default ScanGroups;
