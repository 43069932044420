import React from 'react';
import { toast } from 'react-toastify';
import { DeleteOutlined, DownloadOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Modal, Row, Select, Space } from 'antd';

// redux
import { connect } from 'react-redux';
import { DeleteCondtionalItem } from 'store/actions/adminActions';

const { confirm } = Modal;

const ConditionalItem = (props) => {
    const { item, openModal, documentUrl, DeleteCondtionalItem } = props;

    // functions
    const deleteModal = (conditional) => {
        confirm({
            title: 'Do you want delete this item?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await DeleteCondtionalItem(conditional?.id);
                if (res?.success) {
                    toast.success('Item deleted successfully.');
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    return (
        <Col xs={24} className="mb-4">
            <Card
                title={item?.question}
                extra={
                    <Space>
                        <Button shape="circle" icon={<EditOutlined />} onClick={() => openModal('Edit', item)} />
                        <Button shape="circle" icon={<DeleteOutlined />} onClick={() => deleteModal(item)} danger />
                    </Space>
                }
                className="mb-4"
            >
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <div className="mb-4">
                            <label>Selected an option:</label>
                            <Select className="w-full" size="large">
                                <Select.Option value="yes">Yes</Select.Option>
                                <Select.Option value="no">No</Select.Option>
                            </Select>
                        </div>
                    </Col>
                    <Col xs={24} md={12}>
                        <div className="mb-4">
                            <label>If yes, upload the document</label>
                            <Button
                                icon={<DownloadOutlined />}
                                type="primary"
                                size="large"
                                block
                                href={documentUrl}
                                download
                                target="_blank"
                                rel="noreferrer"
                            >
                                Download
                            </Button>
                        </div>
                    </Col>
                    <Col xs={24} md={12}>
                        <div className="mb-4">
                            <label>{item?.no_label}</label>
                            <Input.TextArea className="w-full" size="large" rows={4} />
                        </div>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, { DeleteCondtionalItem })(ConditionalItem);
