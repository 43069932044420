import React from 'react';
// import { useTheme } from '@mui/material/styles';
import { Dialog, DialogContent, Grow } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const WelcomeCenteredModal = (props) => {
    const { open, handleClose, children, width } = props;
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={handleClose}
            sx={{
                '& .MuiDialog-container': {
                    justifyContent: 'center',
                    alignItems: 'center',
                },
            }}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 1080,
                    borderRadius: 8,
                    position: 'relative',
                    top: 0,
                    width,
                    maxHeight: 600,
                    m: 0,
                    zIndex: 1002,
                },
            }}
            scroll="paper"
        >
            <DialogContent sx={{ padding: 0 }}>{children}</DialogContent>
        </Dialog>
    );
};

export default WelcomeCenteredModal;
