import { Stack } from '@mui/material';
import AppFilterSelect from 'components/new_components/AppFilterSelect';
import React from 'react';
import { STATUS_OPTIONS } from '../../utils/constants';

const TaskLogFilters = (props) => {
    const { handleFilter, filters, data } = props;
    // Extract assigned_to_name from each object
    const assignedToNames = data?.flatMap((item) => item.assigned_to_name);

    // Remove duplicates
    const uniqueAssignedToNames = [...new Set(assignedToNames)];

    const ASSIGNEE_OPTIONS = uniqueAssignedToNames?.map((name) => ({ name, value: name }));
    return (
        <Stack direction="row" alignItems="center" gap={1} sx={{ width: '350px' }}>
            <AppFilterSelect
                options={[{ name: 'Status', value: '' }, ...STATUS_OPTIONS]}
                name="status"
                value={filters?.status}
                onChange={handleFilter}
                sx={{ mt: -2 }}
            />
            <AppFilterSelect
                options={[{ name: 'Assignee', value: '' }, ...ASSIGNEE_OPTIONS]}
                name="assignee"
                value={filters?.assignee}
                onChange={handleFilter}
                sx={{ mt: -2 }}
            />
        </Stack>
    );
};

export default TaskLogFilters;
