import React from 'react';
import { Dropdown, Menu } from 'antd';
import { DeleteOutlined, MoreOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const IntegrationTableOperations = (props) => {
    const { record, deleteIntegration, openEditModal } = props;
    const menu = (
        <Menu className="tableaction">
            <Menu.Item key="0">
                <Link
                    to={{
                        pathname: `/admin/integrations/integration/fieldCreation/${record?.id}`,
                        state: {
                            fields: record?.fields,
                        },
                    }}
                >
                    <EyeOutlined /> View Fields
                </Link>
            </Menu.Item>
            <Menu.Item key="1" onClick={() => openEditModal(record)}>
                <EditOutlined /> Edit Integration
            </Menu.Item>
            <Menu.Item key="2" style={{ color: 'red' }} onClick={() => deleteIntegration(record?.id)}>
                <DeleteOutlined /> Delete
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <a
                href="#"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                onKeyDown={(e) => e.preventDefault()}
            >
                <MoreOutlined />
            </a>
        </Dropdown>
    );
};

export default IntegrationTableOperations;
