import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
// core components
import BreadCrumb from '../../../components/Breadcrumb';
import HeaderModal from './ArticleActions/HeaderModal';

// antd components
import { Button, Row, Col, Typography, Card, Modal, Spin, Empty, Tooltip } from 'antd';

// icons
import { DeleteOutlined, EditOutlined, EyeOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

//redux
import { useDispatch, useSelector } from 'react-redux';
import {
    GetAllArticleHeader,
    CreateArticleHeader,
    EditArticleHeader,
    DeleteArticleHeader,
} from 'store/actions/articlesActions';

// sub-components
const { Title } = Typography;
const { confirm } = Modal;

const ArticleDashboard = () => {
    // state
    const [loading, setLoading] = useState({
        content: false,
    });
    const [modal, setModal] = useState(null);
    const [oneArticle, setOneArticle] = useState({});

    // redux
    const dispatch = useDispatch();
    const all_article_header = useSelector((state) => state?.articlesReducers?.all_article_header);

    useEffect(() => {
        getAllArticleHeader();
    }, []);

    // functions
    const openModal = (mode, data) => {
        setModal(mode);
        setOneArticle(data);
    };
    const closeModal = () => {
        setModal(null);
    };
    const getAllArticleHeader = async () => {
        setLoading({ ...loading, content: true });
        const res = await dispatch(GetAllArticleHeader());
        if (!res.success) {
            toast.error(res?.message);
        }
        setLoading({ ...loading, content: false });
    };
    const deleteArticleHeader = (id) => {
        confirm({
            title: 'Are you sure you want to delete this requirement? Note: Deleting this requirement means you want to delete all the subrequirements, documents and audit questions that exists in this requirement',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await dispatch(DeleteArticleHeader(id));
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Articles' }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal('Add')}
                    >
                        Add Article Header
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {loading.content ? (
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                ) : (
                    <>
                        {all_article_header && all_article_header?.length > 0 ? (
                            <Row wrap gutter={24}>
                                {all_article_header
                                    ?.sort((a, b) => a?.id - b?.id)
                                    ?.map((article) => (
                                        <Col
                                            xs={24}
                                            md={12}
                                            lg={8}
                                            xxl={6}
                                            key={article.id}
                                            style={{ marginBottom: '1rem' }}
                                        >
                                            <Card
                                                title={`#${article.id}`}
                                                loading={false}
                                                actions={[
                                                    <Tooltip title="View this Article" color="blue">
                                                        <Link
                                                            key="preview"
                                                            to={{
                                                                pathname: '/admin/articles/article',
                                                                state: {
                                                                    article_header_id: article?.id,
                                                                    title: article?.name,
                                                                },
                                                            }}
                                                        >
                                                            <EyeOutlined key="preview" />
                                                        </Link>
                                                    </Tooltip>,
                                                    <Tooltip title="Edit this Article" color="blue">
                                                        <EditOutlined
                                                            key="edit"
                                                            onClick={() => openModal('Edit', article)}
                                                        />
                                                    </Tooltip>,
                                                    <Tooltip title="Delete this Article" color="red">
                                                        <DeleteOutlined
                                                            key="delete"
                                                            onClick={() => deleteArticleHeader(article.id)}
                                                        />
                                                    </Tooltip>,
                                                ]}
                                            >
                                                <Title level={5} style={{ textAlign: 'center' }}>
                                                    {article.name}
                                                </Title>
                                            </Card>
                                        </Col>
                                    ))}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Article Header</h3>
                                                <span>Add a new Article Header </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section>
            <HeaderModal
                open={modal}
                handleCancel={closeModal}
                one_article={oneArticle}
                CreateArticle={CreateArticleHeader}
                EditArticle={EditArticleHeader}
            />
        </div>
    );
};

export default ArticleDashboard;
