import { Box, Typography } from '@mui/material';
import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AppTag from 'components/new_components/AppTags';
import { useTheme } from '@mui/styles';

const VendorDetails = (props) => {
    const theme = useTheme();
    const { details } = props;
    return (
        <Box sx={{ mt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ color: '#64748B', fontSize: '12px', fontWeight: 600 }}>Status</Typography>

                <Box
                    component="span"
                    sx={{
                        color: '#64748B',
                        textTransform: 'capitalize',
                        fontWeight: 500,
                        padding: '4px 8px',
                        border: details?.has_responded
                            ? `1px solid ${theme.palette.success[500] + '20'}`
                            : `1px solid ${theme.palette.primary[100] + 80}`,
                        borderRadius: 1,
                        width: details?.has_responded ? '100px' : '130px',
                        fontSize: 12,
                        margin: '0 8px 12px 0',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <span>
                        <FiberManualRecordIcon
                            sx={{
                                width: 10,
                                height: 10,
                                color: details?.has_responded
                                    ? theme.palette.success[70]
                                    : theme.palette.primary[100] + 80,
                                mr: 1,
                            }}
                        />
                        {details?.has_responded ? 'Responded' : 'Not Responded'}
                    </span>
                </Box>
            </Box>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        Vendor name
                    </Typography>
                    <Typography
                        sx={{
                            color: '#395BA9',
                            fontWeight: 400,
                            fontSize: '14px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                        }}
                    >
                        {details?.name}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        Vendor Type
                    </Typography>
                    <Typography
                        sx={{
                            color: '#395BA9',
                            fontWeight: 400,
                            fontSize: '14px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                        }}
                    >
                        {details?.is_aml_vendor ? 'AML vendor' : 'Non AML Vendor'}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        Vendor email
                    </Typography>
                    <Typography
                        sx={{
                            color: '#395BA9',
                            fontWeight: 400,
                            fontSize: '14px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                        }}
                    >
                        {details?.email}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        Data shared
                    </Typography>
                    <Typography
                        sx={{
                            color: '#395BA9',
                            fontWeight: 400,
                            fontSize: '14px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                        }}
                    >
                        {details?.data}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        Products
                    </Typography>
                    <Typography
                        sx={{
                            color: '#395BA9',
                            fontWeight: 400,
                            fontSize: '14px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                        }}
                    >
                        {details?.products}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        Description
                    </Typography>
                    <Typography
                        sx={{
                            color: '#395BA9',
                            fontWeight: 400,
                            fontSize: '14px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                        }}
                    >
                        {details?.description}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        Risk level
                    </Typography>
                    <Typography
                        sx={{
                            color: '#395BA9',
                            fontWeight: 400,
                            fontSize: '14px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                        }}
                    >
                        {details?.risk_level?.toLowerCase() === 'low' ? (
                            <AppTag
                                text="Low"
                                icon={
                                    <FiberManualRecordIcon
                                        sx={{
                                            width: 12,
                                            height: 12,
                                            color: theme.palette.success[500],
                                            mr: 0.5,
                                        }}
                                    />
                                }
                                type="default"
                                noRounded
                                noItalic
                                sx={{
                                    padding: '4px 8px',
                                }}
                            />
                        ) : details?.risk_level?.toLowerCase() === 'medium' ? (
                            <AppTag
                                text="Medium"
                                icon={
                                    <FiberManualRecordIcon
                                        sx={{
                                            width: 12,
                                            height: 12,
                                            color: theme.palette.warning[900],
                                            mr: 0.5,
                                        }}
                                    />
                                }
                                noRounded
                                noItalic
                                type="default"
                                sx={{
                                    padding: '4px 8px',
                                }}
                            />
                        ) : details?.risk_level?.toLowerCase() === 'high' ? (
                            <AppTag
                                text="High"
                                icon={
                                    <FiberManualRecordIcon
                                        sx={{
                                            width: 12,
                                            height: 12,
                                            color: theme.palette.error[500],
                                            mr: 0.5,
                                        }}
                                    />
                                }
                                noRounded
                                noItalic
                                type="default"
                                sx={{
                                    padding: '4px 8px',
                                }}
                            />
                        ) : details?.risk_level?.toLowerCase() === 'critical' ? (
                            <AppTag
                                text="Critical"
                                icon={
                                    <FiberManualRecordIcon
                                        sx={{
                                            width: 12,
                                            height: 12,
                                            color: theme.palette.error[300],
                                            mr: 0.5,
                                        }}
                                    />
                                }
                                noRounded
                                noItalic
                                type="default"
                                sx={{
                                    padding: '4px 8px',
                                }}
                            />
                        ) : (
                            <AppTag
                                text={details?.risk_level?.toUpperCase()}
                                icon={
                                    <FiberManualRecordIcon sx={{ width: 12, height: 12, color: '#77777A', mr: 0.5 }} />
                                }
                                noRounded
                                noItalic
                                sx={{
                                    padding: '2px 8px',
                                }}
                            />
                        )}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        Risk alert level
                    </Typography>
                    <Typography
                        sx={{
                            color: '#395BA9',
                            fontWeight: 400,
                            fontSize: '14px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                        }}
                    >
                        {details?.min_risk_level ? (
                            details?.min_risk_level
                        ) : (
                            <Typography component="span" sx={{ border: '2px solid #F1F5F9', px: 2, borderRadius: 1 }}>
                                --
                            </Typography>
                        )}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        Scorecard
                    </Typography>
                    <Typography
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '12px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                            pt: 2,
                        }}
                    >
                        <Typography
                            component="span"
                            sx={{
                                border: '2px solid #F1F5F9',
                                fontWeight: 500,
                                fontSize: '12px',
                                color: '#64748B',
                                px: 1,
                                py: 0.5,
                                borderRadius: 1,
                            }}
                        >
                            {details?.overall_score_percent > 0
                                ? `${details?.overall_score_percent?.toFixed(2)}/100`
                                : '---'}
                        </Typography>
                        {details?.overall_score_percent > 0 && details?.risk_level_alert && (
                            <Typography
                                component="span"
                                sx={{
                                    backgroundColor: '#FFEFCF',
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '12px',
                                    p: 1,
                                    ml: 2,
                                }}
                            >
                                {details?.risk_level_alert}
                            </Typography>
                        )}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default VendorDetails;
