import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Visibility } from '@mui/icons-material';
import { Box } from '@mui/material';

const PreviewLink = (props) => {
    const theme = useTheme();
    const { to, text, sx, ref3 } = props;
    return (
        <Box
            component={Link}
            to={to}
            sx={{
                border: '1px solid ' + theme.palette.primary[900],
                backgroundColor: theme.palette.primary[900] + '10',
                '&, &:hover': {
                    color: theme.palette.primary[900],
                    textDecoration: 'none',
                },
                px: 2,
                py: 0.7,
                borderRadius: 25,
                fontWeight: 600,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                ...sx,
            }}
            ref={ref3}
        >
            <Visibility />
            {text}
        </Box>
    );
};

export default PreviewLink;
