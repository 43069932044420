import React, { useState } from 'react';
import { Modal, Row, Col, Select, Button, Input, Upload, Checkbox } from 'antd';
import { AiOutlineInbox } from 'react-icons/ai';
import { toast } from 'react-toastify';

//redux
import { connect } from 'react-redux';
import { UploadAdminDocs } from 'store/actions/adminActions';

const UploadDocument = (props) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    //props
    const { open, handleClose, all_merchants, UploadAdminDocs } = props;

    const { TextArea } = Input;
    const { Dragger } = Upload;

    const draggers = {
        name: 'default',
        action: null,
        multiple: false,
        maxCount: 1,
        onChange(info) {
            setData({ ...data, file: info.fileList[0]?.originFileObj });
        },
    };

    const closeAll = () => {
        handleClose();
        setData({});
    };
    const onTextChange = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value });
    };
    const onSelect = (value) => {
        setData({ ...data, merchant: value });
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        Object.keys(data)?.forEach((key) => {
            formData.append(key, data[key]);
        });
        const res = await UploadAdminDocs(formData);
        setLoading(false);

        if (res?.success) {
            toast.success('Document Added Successfully');
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <div>
            <Modal title="Upload Document" open={open} onCancel={closeAll} footer={null} destroyOnClose={true}>
                <form onSubmit={handleSubmit}>
                    <Row justify="end" className="overflow-y-auto custom-scroll pr-3">
                        <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="plan">Choose Merchant</label>
                            <Select
                                showSearch
                                size="large"
                                placeholder="Select Merchant"
                                optionFilterProp="children"
                                onChange={onSelect}
                                onSearch={onSearch}
                                style={{ width: '100%' }}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={all_merchants?.map((merchant) => {
                                    return {
                                        value: merchant?.id,
                                        label: merchant?.name || merchant?.owner_detail?.first_name,
                                    };
                                })}
                            />
                        </Col>
                        <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="plan">Doc Name</label>
                            <Input size="large" name="name" onChange={onTextChange} placeholder="Doc Name" allowClear />
                        </Col>
                        <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="plan">Doc Description</label>
                            <TextArea
                                rows={4}
                                name="description"
                                onChange={onTextChange}
                                placeholder="Doc Description"
                                allowClear
                            />
                        </Col>
                        <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                            <Checkbox onChange={(e) => setData({ ...data, response_needed: e.target.checked })}>
                                Choose whether you require the merchant to reply to this document.
                            </Checkbox>
                        </Col>

                        <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="description">Upload Report</label>
                            <Dragger {...draggers}>
                                <p className="ant-upload-drag-icon flex justify-center">
                                    <AiOutlineInbox />
                                </p>
                                <p className="ant-upload-text text-[14px]">Click or drag file to this area to upload</p>
                            </Dragger>
                        </Col>
                    </Row>
                    <Row justify="end" className="mt-3">
                        <Col>
                            <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </form>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchants: state?.adminReducers?.all_merchants,
    };
};
export default connect(mapStateToProps, { UploadAdminDocs })(UploadDocument);
