import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import AppTable from 'components/new_components/app-table2';
import useSearch from 'hooks/useSearch';
import Heading from './FraudRegisterHeading';
import { MoreHoriz } from '@mui/icons-material';
import Actions from './TableAction';
import DetailsModal from './DetailsModal';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme } from '@mui/styles';
import DeleteRecordModal from './DeleteRecordModal';
import { useSelector } from 'react-redux';

const NO_PER_PAGE = 10;

const FraudRegister = (props) => {
    const theme = useTheme();
    const { match_status, setMatch_Status, entity_type, setEntityType, loading } = props;
    const [pageData, setPageData] = useState([]);
    const [page, setPage] = useState(1);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [viewModal, setViewModalOpen] = useState(false);
    const [payload, setPayload] = useState({});
    const all_fraud_monitoring = useSelector((state) => state?.fraudGuardianReducers?.all_fraud_monitoring);

    const openDeleteModal = (payload) => {
        setPayload(payload);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setPayload({});
        setDeleteModalOpen(false);
    };

    const openViewModal = (payload) => {
        setPayload(payload);
        setViewModalOpen(true);
    };
    const closeViewModal = () => {
        setPayload({});
        setViewModalOpen(false);
    };

    const { data, handleSearch } = useSearch(all_fraud_monitoring, ['name', 'source_bank', 'transaction_status']);

    const columns = [
        {
            title: 'Name',
            key: 'name',
            render: (row) => (
                <Typography sx={{ color: '#5E5E62', fontSize: '14px', cursor: 'pointer' }}>
                    {row?.name || 'N/A'}
                </Typography>
            ),
        },
        {
            title: 'Source Bank',
            key: 'source_bank',
            render: (row) => (
                <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>
                    {row?.source_bank?.replace(/_/g, ' ')}
                </Typography>
            ),
        },
        {
            title: 'Fraud Percentage',
            key: 'fraud_percentage',
            render: (row) => (
                <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>
                    {row?.fraud_percent?.replace(/_/g, ' ')}%
                </Typography>
            ),
        },
        {
            title: 'Transaction Status',
            key: 'transaction_status',
            render: (row) => (
                <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>
                    {row?.transaction_status?.replace(/_/g, ' ')}
                </Typography>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            render: (row) => (
                <Typography
                    component="span"
                    sx={{
                        border: '2px solid #F1F5F9',
                        color: theme.palette.gray[40],
                        fontWeight: 400,
                        fontSize: '14px',
                        py: 0.5,
                        px: 0.7,
                        borderRadius: 1,
                        width: 'contain',
                    }}
                >
                    {row?.status !== 'null' && (
                        <FiberManualRecordIcon
                            sx={{
                                width: 12,
                                height: 12,
                                color: row?.status === 'clean' ? '#136207' : '#FF5449',
                                mr: 0.2,
                            }}
                        />
                    )}{' '}
                    {row?.status === 'null' ? 'N/A' : row?.status}
                </Typography>
            ),
        },
        {
            title: <MoreHoriz />,
            align: 'center',
            key: 'action',
            render: (row) => <Actions row={row} openDeleteModal={openDeleteModal} openViewModal={openViewModal} />,
        },
    ];

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        setPageData(data?.length ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);

        return data;
    }, [page, data]);

    const handlePageChange = (page) => {
        setPage(page);
    };
    return (
        <Box sx={{ m: 3, p: 1.5, backgroundColor: '#FFFFFF', borderRadius: 1 }}>
            <AppTable
                columns={columns}
                data={pageData}
                sorter={(a, b) => b?.id - a?.id}
                loading={loading}
                title={
                    <Heading
                        onSearch={handleSearch}
                        entity_type={entity_type}
                        setEntityType={setEntityType}
                        match_status={match_status}
                        setMatch_Status={setMatch_Status}
                    />
                }
                dataLength={all_fraud_monitoring?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
            />

            <DetailsModal open={viewModal} handleClose={closeViewModal} payload={payload} />
            <DeleteRecordModal open={deleteModalOpen} handleClose={closeDeleteModal} payload={payload} />
        </Box>
    );
};

export default FraudRegister;
