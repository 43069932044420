import React from 'react';

const ListIcon = (props) => {
    const { Component } = props;

    if (!Component) return null;

    return <Component sx={{ color: 'inherit' }} />;
};

export default ListIcon;
