import React from 'react';
import { Tabs } from 'antd';
import RiskCategory from './RiskCategory/RiskCategory';
import ThreatCategory from './ThreatCategory/ThreatCategory';
import Threats from './Threat/Threat';
import Vulnerabilities from './vulnerabilities/Vulnerabilities';

const RiskAssessment = () => {
    return (
        <Tabs
            defaultActiveKey="1"
            items={[
                {
                    label: 'Risk Categories',
                    key: '1',
                    children: <RiskCategory />,
                },
                {
                    label: 'Threat Categories',
                    key: '2',
                    children: <ThreatCategory />,
                },
                {
                    label: 'Threats',
                    key: '3',
                    children: <Threats />,
                },
                {
                    label: 'Vulnerabilities',
                    key: '4',
                    children: <Vulnerabilities />,
                },
            ]}
        />
    );
};

export default RiskAssessment;
