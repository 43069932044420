import React, { useEffect, useState } from 'react';
import AppTag from 'components/new_components/AppTags';
import AppColorTag from 'components/new_components/AppColorTag';
import AppTable from 'components/new_components/app-table';
import { ArrowBackIosNew } from '@mui/icons-material';
import useSearch from 'hooks/useSearch';
import { Box, Chip, Typography } from '@mui/material';
import { GetAllCodeReview } from 'store/actions/merchantActions';
import { GetMonthFormatDate, GetTimeFormatDate } from 'utils';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import CodeReviewAction from './CodeReviewAction';
import AppInput from 'components/new_components/AppInput';
import severity from '../../../../../../assets/img/severity.png';

// core components
import AppViewModal from 'components/new_components/AppViewModal';

// redux
import { connect } from 'react-redux';

const CodeReview = (props) => {
    const { open, handleClose, GetAllCodeReview, all_code_review } = props;
    const [pageData, setPageData] = useState([]);
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const NO_PER_PAGE = 8;
    // hooks
    const { data, handleSearch } = useSearch(all_code_review, [
        'version_control_tool',
        'status',
        'codereview_id',
        'severity',
    ]);

    // async functions
    const getallCodeReview = async () => {
        setLoading(true);
        const res = await GetAllCodeReview();
        setLoading(false);
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };
    useEffect(() => {
        getallCodeReview();
    }, []);
    const columns = [
        {
            title: 'Code Repository',
            render: (row) => (
                <>
                    <Typography
                        sx={{
                            color: theme.palette.gray[800],
                            ml: 0.2,
                            fontSize: 14,
                            fontWeight: 400,
                        }}
                    >
                        {row?.version_control_tool}
                    </Typography>
                </>
            ),
        },
        {
            title: 'Severity Level',
            key: 'server',
            render: (row) => (
                <>
                    {row?.severity ? (
                        <AppColorTag
                            text={row?.severity}
                            type={
                                row?.severity === 'high' ? 'error' : row?.severity === 'medium' ? 'warning' : 'success'
                            }
                        />
                    ) : (
                        <img src={severity} alt="" />
                    )}
                </>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            render: (row) => (
                <AppTag
                    sx={{
                        textTransform: 'capitalize',
                        color: theme.palette.gray[800],
                        fontWeight: 400,
                        border: `1px solid ${theme.palette.gray[400]}`,
                    }}
                    text={row?.status === 'completed' ? row?.status : row?.status}
                    type={row?.status === 'completed' ? 'success' : ''}
                />
            ),
        },
        {
            title: 'Code Review ID',
            key: 'codereview_id',
            render: (row) => (
                <>
                    <Typography
                        sx={{
                            color: theme.palette.gray[800],
                            ml: 0.2,
                            fontSize: 14,
                            fontWeight: 400,
                        }}
                    >
                        {row?.codereview_id || 'Nil'}
                    </Typography>
                </>
            ),
        },
        {
            title: 'Date',
            key: 'date_created',
            render: (row) => (
                <>
                    <Typography
                        sx={{
                            color: theme.palette.gray[800],
                            ml: 0.2,
                            fontSize: 14,
                            fontWeight: 400,
                        }}
                    >
                        {GetMonthFormatDate(row?.date_created)}
                    </Typography>
                    <Box
                        component="div"
                        sx={{
                            color: theme.palette.gray[800],
                            ml: 0.2,
                            fontSize: 14,
                        }}
                    >
                        ({GetTimeFormatDate(row?.date_created)})
                    </Box>
                </>
            ),
        },
        {
            title: 'Frequency',
            key: 'frequency',
            render: (row) => (
                <>
                    <Chip
                        sx={{ marginRight: 1, marginBottom: 1 }}
                        onClick={() => {
                            ('ehh');
                        }}
                        label={row?.frequency}
                        variant="outlined"
                        size="small"
                    />
                </>
            ),
        },
        {
            title: 'Action',
            align: 'center',
            key: 'action',
            render: (row) => (
                <>
                    <CodeReviewAction row={row} />
                </>
            ),
        },
    ];

    const handlePageChange = (page) => {
        setPage(page);
    };

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;

        setPageData(data?.length > 0 ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);

        return data;
    }, [page, data]);
    // state

    return (
        <AppViewModal
            open={open}
            handleClose={handleClose}
            backIcon={
                <ArrowBackIosNew
                    sx={{
                        width: 35,
                        height: 35,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#DAE2FF',
                        color: theme.palette.gray[500],
                        p: 1,
                        mr: 2,
                        borderRadius: '50%',
                        cursor: 'pointer',
                    }}
                    onClick={handleClose}
                />
            }
            noClose
            width={900}
            title={
                <Typography
                    sx={{
                        color: '#15161A',
                        fontWeight: '700',
                        fontSize: '18px',
                    }}
                >
                    Code Reviews
                </Typography>
            }
            anchor="right"
        >
            <AppTable
                columns={columns}
                data={pageData}
                sorter={(a, b) => b?.id - a?.id}
                loading={loading}
                dataLength={data?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                pagination={{
                    total: data?.length,
                    showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    showSizeChanger: false,
                }}
                title={
                    <AppInput
                        placeholder="Search file..."
                        name
                        sx={{
                            maxWidth: 300,
                            maxHeight: 40,
                            borderColor: theme.palette.gray[30],
                            '& input::placeholder': {
                                color: theme.palette.gray[40],
                                fontWeight: 600,
                            },
                        }}
                        controlStyle={{ mt: 0, width: 'fit-content' }}
                        onChange={handleSearch}
                    />
                }
            />
        </AppViewModal>
    );
};
const mapStateToProps = (state) => {
    return {
        all_code_review: state?.merchantReducers?.all_code_review,
    };
};

export default connect(mapStateToProps, { GetAllCodeReview })(CodeReview);
