import { CardHeader, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//translations
import AppInput from 'components/new_components/AppInput';
import { useTranslation } from 'react-i18next';

const TaskTableTopbar = (props) => {
    const { title, actions, rowSpacing, search, onSearch, filters } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('common');

    return (
        <CardHeader
            title={
                <Stack direction="row" alignItems="center" gap={2} sx={{ mt: '-0.3rem' }}>
                    {search ? (
                        <AppInput
                            placeholder={t('search')}
                            sx={{
                                maxWidth: 200,
                                borderColor: theme.palette.gray.new60,
                                '& input::placeholder': {
                                    color: theme.palette.gray[40],
                                    fontWeight: 600,
                                },
                            }}
                            controlStyle={{ mt: -'0.8px', width: 'fit-content' }}
                            onChange={onSearch}
                        />
                    ) : (
                        <Typography variant="h3" component="h3" sx={{ marginBottom: '0!important', color: 'primary' }}>
                            {title}
                        </Typography>
                    )}
                    {filters}
                </Stack>
            }
            action={
                <Stack direction="row" spacing={1}>
                    {actions}
                </Stack>
            }
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', pr: 4, pb: rowSpacing ? 0 : 2.5 }}
        />
    );
};

export default TaskTableTopbar;
