import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { Upload, Scanner } from '@mui/icons-material';
import { Typography, Stack, Box, useTheme } from '@mui/material';
import { toast } from 'react-toastify';

//Core components
import AppTable from 'components/new_components/app-table';
import AppTag from 'components/new_components/AppTags';
import AppButton from 'components/new_components/AppButton';
import PentestTableActions from './PentestTableActions';
import RequestModal from './RequestModal';
import UploadReportModal from './UploadReportModal';
import PentestDeleteModal from './PentestDeleteModal';
import PentestReportModal from './PentestReportModal';
import AppColorTag from 'components/new_components/AppColorTag';
import useSearch from 'hooks/useSearch';
//redux
import { connect, useSelector } from 'react-redux';
import { GetMerchantPentestRequests } from 'store/actions/merchantActions';

//utils
import { uploadIntialValues } from './utils/constants';
import PageHeader from 'components/new_components/PageHeader';
import SubscriptionAlert from '../../../components/Pricing/SubscriptionAlert';
import { GetRemaining } from '../../../components/Pricing/usePricingHook';
import { formatDateObjectHandler } from 'utils';
// import { pentests } from 'data';
// import SubscriptionRoutingAlert from "../../../components/Pricing/SubscriptionRoutingAlert";
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

//Userguide
import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';

//translations
import { useTranslation } from 'react-i18next';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';

const NO_PER_PAGE = 8;

const PenetrationTest = ({
    GetMerchantPentestRequests,
    merchant_pentests_requests,
    pricing_plan,
    userguide_info,
    all_article_header,
}) => {
    const [openRequestModal, setOpenRequestModal] = useState(false);
    const [modalPayload, setModalPayload] = useState({});
    const [mode, setMode] = useState('');
    const [uploadPayload, setUploadPayload] = useState(uploadIntialValues);
    const [openUploadReportModal, setOpenUploadReportModal] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deletePayload, setDeletePayload] = useState({});
    const [modalPayloadId, setModalPayloadId] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [openDowloadReportModal, setOpenDownloadReportModal] = useState(false);
    const [report, setReport] = useState('');
    const [tableTab, setTableTab] = useState('requested');
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    //Userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    const theme = useTheme();

    const STEPS_LENGTH = 4;

    const steps = [
        {
            title: <UserGuideHeader title="Requested" id={1} length={STEPS_LENGTH} />,
            description: 'This shows the list of all requested pentest',
            target: () => ref1.current,
            placement: 'leftTop',
        },
        {
            title: <UserGuideHeader title="Upload" id={2} length={STEPS_LENGTH} />,
            description: 'This shows the list of all uploaded pentest report',
            target: () => ref2.current,
            placement: 'leftTop',
        },
        {
            title: <UserGuideHeader title="Request Pentest" id={3} length={STEPS_LENGTH} />,
            description: 'Run penetration testing by making a request here.',
            target: () => ref3.current,
            placement: 'rightTop',
        },
        {
            title: <UserGuideHeader title="Upload Report" id={4} length={STEPS_LENGTH} />,
            description: 'You can Upload penetration report if you already performed the test elsewhere',
            target: () => ref4.current,
            placement: 'rightTop',
        },
    ];

    //accessing values from the redux store
    const role = useSelector((state) => state?.generalReducers?.user_info?.role);

    //context api for auditor's selected merchant
    const { activeMerchant } = useContext(SelectedMerchantContext);

    //translation
    const { t } = useTranslation('pentest');
    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Penetration Testing'),
        [all_article_header]
    );

    const uploadedPentests = useMemo(() => {
        return merchant_pentests_requests?.filter(
            (pentest) => pentest?.uploaded_by !== 'wired assurance' && pentest?.uploaded_by !== '386konsult'
        );
    }, [merchant_pentests_requests]);

    const requestedPentests = useMemo(() => {
        return merchant_pentests_requests?.filter(
            (pentest) => pentest?.uploaded_by === 'wired assurance' || pentest?.uploaded_by === '386konsult'
        );
    }, [merchant_pentests_requests]);

    const { data, handleSearch } = useSearch(tableTab === 'requested' ? requestedPentests : uploadedPentests, [
        'name',
        'uploaded_by',
        'status',
    ]);

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ pentest: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, pentest: false });
        setOpenArticle(false);
    };

    //functions
    const openRequestActionModal = (mode, payload) => {
        setOpenRequestModal(true);
        setMode(mode);
        if (mode === 'view' || mode === 'rerun') {
            setModalPayload(payload);
        }
    };
    const closeRequestActionModal = () => {
        setOpenRequestModal(false);
        setMode('');
        setTimeout(() => {
            setModalPayload({});
        }, 2000);
    };
    const openUploadPentestReportModal = (payload = uploadIntialValues) => {
        setOpenUploadReportModal(true);
        setUploadPayload(payload);
    };
    const closeUploadPentestReportModal = () => {
        setOpenUploadReportModal(false);
        setTimeout(() => setUploadPayload({}), 1000);
    };
    // initialEmployeeValues TODO://to check
    const openDeleteModal = (payload = {}, id) => {
        setDeleteModalOpen(true);
        setDeletePayload(payload);
        setModalPayloadId(id);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setTimeout(() => setDeletePayload({}), 1000);
    };
    const handlePageChange = (page) => {
        setPage(page);
    };

    const openDownloadPentestReportModal = (id) => {
        setOpenDownloadReportModal(true);
        setReport(id);
    };
    const closeDownloadPentestReportModal = () => {
        setOpenDownloadReportModal(false);
        setReport('');
    };

    const currentMonthForPentest = (currentDate) => {
        const date = new Date(currentDate);
        const month = date.getMonth() + 1;

        if (month >= 1 && month <= 6) {
            return 'January-June';
        } else if (month > 6 && month <= 12) {
            return 'July - December';
        } else {
            return null;
        }
    };

    const requestedColumns = [
        {
            title: t('table.column1'),
            render: (row) => <span className="capitalize">{row?.name}</span>,
        },
        {
            title: t('table.column2'),
            key: 'severity_level',
            render: (row) => {
                return !row?.severity ? (
                    <Box sx={{ height: '6px', width: '80px', background: ' #D9D9D9', borderRadius: '8px' }}></Box>
                ) : (
                    <AppColorTag
                        text={row?.severity}
                        type={
                            row?.severity?.toLowerCase() === 'high'
                                ? 'high'
                                : row?.severity?.toLowerCase() === 'medium'
                                ? 'warning'
                                : row?.severity?.toLowerCase() === 'critical'
                                ? 'error'
                                : 'success'
                        }
                    />
                );
            },
        },
        {
            title: t('table.column3'),
            key: 'progress',
            render: (row) => (
                <AppTag text={row?.progress} type={row?.progress === 'completed' ? 'success' : 'default'} />
            ),
        },
        {
            title: t('table.column4'),
            key: 'uploaded_by',
            render: (row) => <span className="capitalize">{row?.uploaded_by || 'Nil'}</span>,
        },
        {
            title: t('table.column5'),
            key: 'date',
            render: (row) => (
                <>
                    {formatDateObjectHandler(row?.date_created, 'MMMM Do, YYYY') || 'Nil'}
                    <Typography sx={{ fontSize: 14 }}>({currentMonthForPentest(row?.date_created)})</Typography>
                </>
            ),
        },
        {
            title: t('table.column6'),
            key: 'frequency',
            render: (row) => (
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        height: '20px',
                        width: '20px',
                        borderRadius: '90px',
                        border: `0.8px solid ${theme.palette.gray[300]}`,
                        padding: 1.6,
                        fontSize: 12,
                    }}
                >
                    {row?.frequency}
                </Stack>
            ),
        },
        {
            title: t('table.column7'),
            align: 'center',
            key: 'action',
            render: (row) => (
                <PentestTableActions
                    row={row}
                    openDeleteModal={openDeleteModal}
                    tableTab={tableTab}
                    openDownloadPentestReportModal={openDownloadPentestReportModal}
                    openViewModal={openRequestActionModal}
                />
            ),
        },
    ];
    const uploadedColumns = [
        {
            title: t('table.column1'),
            render: (row) => <>{row?.name}</>,
        },
        {
            title: t('table.column2'),
            key: 'severity',
            render: (row) => (
                <AppColorTag
                    text={row?.severity}
                    type={
                        row?.severity?.toLowerCase() === 'high'
                            ? 'high'
                            : row?.severity?.toLowerCase() === 'medium'
                            ? 'warning'
                            : row?.severity?.toLowerCase() === 'critical'
                            ? 'error'
                            : 'success'
                    }
                />
            ),
        },
        {
            title: t('table.column4'),
            key: 'uploaded_by',
            render: (row) => <>{row?.uploaded_by || 'Nil'}</>,
        },
        {
            title: 'Date',
            key: 'date',
            render: (row) => (
                <>
                    {formatDateObjectHandler(row?.date_created, 'MMMM Do, YYYY') || 'Nil'}
                    <Typography sx={{ fontSize: 14 }}>({currentMonthForPentest(row?.date_created)})</Typography>
                </>
            ),
        },
        {
            title: t('table.column7'),
            align: 'center',
            key: 'action',
            render: (row) => (
                <PentestTableActions
                    row={row}
                    openDeleteModal={openDeleteModal}
                    tableTab={tableTab}
                    openDownloadPentestReportModal={openDownloadPentestReportModal}
                />
            ),
        },
    ];

    useEffect(() => {
        const getMerchantPentestsRequests = async () => {
            setLoading(true);
            const res = await GetMerchantPentestRequests(activeMerchant);
            setLoading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getMerchantPentestsRequests();
    }, [activeMerchant]);

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;

        setPageData(data?.length > 0 ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);

        return data;
    }, [page, data, activeMerchant]);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.pentest ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.pentest]);

    const { reminderCount, showReminder } = GetRemaining(pricing_plan?.pentest_scan, pageData?.length);
    return (
        <>
            <PageHeader browserTitle="Penetration Test | Smartcomply" />
            {showReminder && <SubscriptionAlert isDashboardPage={false} limitedNumber={`${reminderCount} scans`} />}
            <AppTable
                columns={tableTab === 'requested' ? requestedColumns : uploadedColumns}
                data={pageData}
                sorter={(a, b) => b?.id - a?.id}
                loading={loading}
                title={t('title')}
                dataLength={data?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                tabs={true}
                toggleTabs={setTableTab}
                tabState={tableTab}
                onPageChange={handlePageChange}
                ref1={ref1}
                ref2={ref2}
                actions={[
                    <AppButton
                        icon={<Scanner />}
                        name={t('requestPentest')}
                        color="primary"
                        variant="contained"
                        disabled={pricing_plan?.pentest_scan === pageData?.length || role === 'auditor'}
                        disableElevation
                        onClick={() => openRequestActionModal('request')}
                        key={2}
                        ref={ref3}
                    />,
                    <AppButton
                        icon={<Upload />}
                        name={t('uploadReport')}
                        color="primary"
                        variant="contained"
                        disabled={role === 'auditor'}
                        disableElevation
                        onClick={() => openUploadPentestReportModal()}
                        key={1}
                        ref={ref4}
                    />,
                ]}
                search={true}
                onSearch={handleSearch}
            />

            <RequestModal
                open={openRequestModal}
                handleClose={closeRequestActionModal}
                payload={modalPayload}
                mode={mode}
            />
            <UploadReportModal
                open={openUploadReportModal}
                handleClose={closeUploadPentestReportModal}
                payload={uploadPayload}
            />
            <PentestDeleteModal
                open={deleteModalOpen}
                handleClose={closeDeleteModal}
                payload={deletePayload}
                id={modalPayloadId}
            />

            <PentestReportModal
                open={openDowloadReportModal}
                handleClose={closeDownloadPentestReportModal}
                title={t('pentestReportModal.title')}
                subtitle={t('pentestReportModal.subtitle')}
                report={report}
            />

            <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} targets={steps.target} />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Penetration Testing"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant_pentests_requests: state?.merchantReducers?.merchant_pentests_requests,
        pricing_plan: state?.authReducers.pricing_plan,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.pentest,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};
export default connect(mapStateToProps, { GetMerchantPentestRequests })(PenetrationTest);
