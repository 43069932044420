import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SwitchToSecure } from 'store/actions/merchantActions';
import { useLocation, useHistory } from 'react-router-dom';
import { getDomain } from 'utils';
import { toast } from 'react-toastify';

const Switch = () => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const token = searchParams.get('token');
    const history = useHistory();
    const handleSwitch = async () => {
        const res = await dispatch(SwitchToSecure(token));
        const { company_details, user_type } = res;
        if (res?.success) {
            if (company_details?.enterprise) {
                const domain_name = getDomain('domain');
                const route = `https://${company_details?.enterprise_name}.${domain_name}/merchant/index`;
                window.location.assign(route);
            } else {
                setTimeout(() => {
                    history.push(
                        user_type === 'merchant' || user_type === 'employee'
                            ? '/merchant/index'
                            : user_type === 'admin' || user_type === 'aud_admin' || user_type === 'aud_admin'
                            ? '/admin'
                            : user_type === 'auditor'
                            ? '/auditor'
                            : '/merchant/index'
                    );
                }, 3000);
            }
        } else {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        handleSwitch();
    });
    return (
        <div className="flex h-screen flex-col items-center justify-center gap-2">
            <p className="text-sm opacity-80">Loading...</p>
        </div>
    );
};

export default Switch;
