import {
    ALL_APPROVED_USER,
    ALL_KYC_RESULTS,
    KYC_RESULTS,
    ALL_FRAUD_MONITORING,
    ALL_COUNTRIES,
    ALL_SOURCES,
    ALL_SOURCES_LIST,
    FRAUD_SETTINGS,
    FRAUD_SUMMARY,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case ALL_APPROVED_USER:
            return {
                ...state,
                all_approved_user: action.payload,
            };
        case FRAUD_SETTINGS:
            return {
                ...state,
                fraud_settings: action.payload,
            };
        case ALL_KYC_RESULTS:
            return {
                ...state,
                all_kyc_result: action.payload,
            };
        case KYC_RESULTS:
            return {
                ...state,
                kyc_results: action.payload,
            };
        case ALL_FRAUD_MONITORING:
            return {
                ...state,
                all_fraud_monitoring: action.payload,
            };
        case FRAUD_SUMMARY: {
            return {
                fraud_summary: action.payload,
            };
        }
        case ALL_COUNTRIES:
            return {
                ...state,
                all_countries: action.payload,
            };
        case ALL_SOURCES:
            return {
                ...state,
                all_sources: action.payload,
            };
        case ALL_SOURCES_LIST: {
            return {
                ...state,
                all_sources_list: action.payload,
            };
        }
        case 'CLEAR_DATA':
            return {};
        default:
            return state;
    }
};
