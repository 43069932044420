import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ExemptTag = forwardRef((props, ref) => {
    const { loading, onClick, loadingLabel, label } = props;
    const theme = useTheme();

    return (
        <Box
            component="button"
            sx={{
                fontSize: 12,
                color: loading ? theme.palette.gray[500] : theme.palette.error[500],
                fontWeight: 400,
                border: `1px solid ${loading ? theme.palette.gray[500] : theme.palette.error[500]}`,
                backgroundColor: loading ? theme.palette.gray[100] : theme.palette.error[100] + '50',
                borderRadius: 1,
                px: 0.5,
                py: 0.25,
            }}
            onClick={onClick}
            ref={ref}
        >
            {loading ? loadingLabel : label}
        </Box>
    );
});

export default ExemptTag;
