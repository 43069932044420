import React from 'react';
import { FormHelperText } from '@mui/material';
// formik
import { useFormikContext } from 'formik';
//core component
import AppCheckboxInput from '../AppCheckboxInput';
import { formatSentence } from 'utils';

const AppFormCheckbox = (props) => {
    const { name, ...restProps } = props;

    // get the formik context values
    const { handleChange, setFieldTouched, touched, values, errors } = useFormikContext();
    const handleInputChange = (e) => {
        handleChange(name)(e);
    };
    return (
        <>
            <AppCheckboxInput
                value={values[name]}
                onChange={handleInputChange}
                error={touched[name] && errors[name]}
                onBlur={() => setFieldTouched(name)}
                {...restProps}
            />
            {touched[name] && <FormHelperText error>{formatSentence(errors[name])}</FormHelperText>}
        </>
    );
};

export default AppFormCheckbox;
