import { List, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as VSecure } from 'assets/img/brand/vsecureLogo.svg';
import ListIcon from '../sidebar/ListIcon';
import { useTheme } from '@mui/material/styles';

const Sidebar = (props) => {
    const { routes } = props;
    const theme = useTheme();
    const location = useLocation();

    function RenderLink({ menu }) {
        const active = location.pathname === menu.layout + menu.path;
        let extraStyles = {};
        if (active) {
            extraStyles = {
                backgroundColor: theme.palette.primary.new,
                color: theme.palette.white.main,
                '&:hover': {
                    backgroundColor: theme.palette.primary.new,
                    color: theme.palette.white.main,
                },
            };
        } else {
            extraStyles = {
                backgroundColor: 'transparent',
                color: theme.palette.white.main,
                '&:hover': {
                    color: theme.palette.white.main,
                },
            };
        }

        return (
            <ListItemButton
                sx={{
                    p: 0.8,
                    pl: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: '4px',
                    zIndex: 1300,
                    '&, & *': {
                        transitionProperty: 'color, background-color, border-bottom, fill',
                        transitionDuration: '0.45s',
                    },
                    ...extraStyles,
                }}
                to={menu.layout + menu.path}
                component={Link}
            >
                <Stack direction="row" alignItems="center">
                    <ListItemIcon className="listItem__icon" sx={{ minWidth: 40 }}>
                        {active ? <ListIcon Component={menu.icon_active} /> : <ListIcon Component={menu.icon} />}
                    </ListItemIcon>
                    <ListItemText primary={menu.name} sx={{ '& span': { fontSize: '13px', fontWeight: 400 } }} />
                </Stack>
            </ListItemButton>
        );
    }
    return (
        <div className="flex flex-col gap-6 border-r bg-[#202D66] px-2 py-8  lg:w-[220px] xl:w-[260px]">
            <div className="mx-2">
                <VSecure className="w-[120px]" />
            </div>
            <List>
                {routes.map((menu) => (
                    <RenderLink menu={menu} key={menu.name} />
                ))}
            </List>
        </div>
    );
};

export default Sidebar;
