import React, { useState } from 'react';
import { Box } from '@mui/material';

// app component
import PlansPage from './PlansPage';
import FeaturesPage from './FeaturesPage';

const Plans = () => {
    const [plan, setPlan] = useState('');

    const handlePlanSelect = (plan) => {
        setPlan(plan);
    };
    const backToPlanPage = () => setPlan('');

    return (
        <Box sx={{ maxHeight: 'calc(100vh - 195px)', overflowY: 'auto' }}>
            {plan === '' ? (
                <PlansPage handlePlanSelect={handlePlanSelect} />
            ) : (
                <FeaturesPage goBack={backToPlanPage} planId={plan} />
            )}
        </Box>
    );
};

export default Plans;
