import React from 'react';
// import { useTheme } from '@mui/material/styles';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

// app components
import AppFormControl from './forms/AppFormControl';

const AppCheckboxGroup = (props) => {
    const { label, name, labelStyle, groupStyle, sx, options, onChange } = props;
    // const theme = useTheme();

    return options ? (
        <AppFormControl sx={sx} label={label} name={name} labelStyle={labelStyle}>
            <FormGroup sx={groupStyle}>
                {options?.map((option, index) => {
                    return (
                        <FormControlLabel
                            control={<Checkbox name={option} onChange={onChange} value={option} />}
                            disableTypography
                            label={option}
                            key={index}
                        />
                    );
                })}
            </FormGroup>
        </AppFormControl>
    ) : null;
};

export default AppCheckboxGroup;
