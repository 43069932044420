import { Button, Col, Input, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { EditAuditCategory, CreateAuditCategory } from 'store/actions/auditActions';
import { AVAILABLE_STANDARDS } from 'utils';

const CategoryModal = (props) => {
    const { open, handleClose, modalData, EditAuditCategory, CreateAuditCategory } = props;

    // states
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // functions
    const closeAll = () => {
        handleClose();
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = open === 'Add' ? await CreateAuditCategory(data) : await EditAuditCategory(data, modalData?.id);
        setLoading(false);
        if (res?.success) {
            closeAll();
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (open) {
            setData(modalData);
        }
    }, [open]);

    return (
        <Modal open={open} onCancel={closeAll} title={`${open} audit category`} footer={null} destroyOnClose>
            <form onSubmit={handleSubmit}>
                <Row justify="end" gutter={12}>
                    <Col span={24}>
                        <div className="mb-2">
                            <label htmlFor="name">Name of Category</label>
                            <Input
                                type="text"
                                size="large"
                                name="name"
                                id="name"
                                onChange={handleTextChange}
                                value={data?.name || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col xs={24} md={12}>
                        <div className="mb-2">
                            <label htmlFor="compliance">Compliance Standard</label>
                            <Select
                                size="large"
                                id="compliance"
                                name="compliance"
                                onChange={(value) => setData({ ...data, compliance: value })}
                                value={data?.compliance}
                                required
                                className="w-full"
                            >
                                {AVAILABLE_STANDARDS.map((standard) => (
                                    <Select.Option value={standard.toLowerCase()} key={standard}>
                                        {standard}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    </Col>
                    <Col xs={24} md={12}>
                        <div className="mb-2">
                            <label htmlFor="question_type">Form Reccurence Type</label>
                            <Select
                                size="large"
                                id="question_type"
                                name="question_type"
                                onChange={(value) => setData({ ...data, question_type: value })}
                                value={data?.question_type}
                                required
                                className="w-full"
                            >
                                {['Single', 'Multiple'].map((type) => (
                                    <Select.Option value={type.toLowerCase()} key={type}>
                                        {type}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="mb-2">
                            <label htmlFor="description">Category Description</label>
                            <Input.TextArea
                                type="text"
                                size="large"
                                name="description"
                                id="description"
                                onChange={handleTextChange}
                                value={data?.description || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col>
                        <Button htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, { CreateAuditCategory, EditAuditCategory })(CategoryModal);
