import React from 'react';
import SearchIcon from 'assets/img/Searchicon.svg';
import {
    RiskTemplatesTab,
    categoryOptions,
    plans,
    residual3x3Risks,
    residual5x5Risks,
    risk_sources,
} from '../utils/constants';
import FilterLinesIcon from 'assets/img/filter-lines.svg';
import ArrowDownIcon from 'assets/img/material-symbols_keyboard-arrow-down-rounded.svg';
import CheckedIcon from 'assets/img/checkbox-buuton-checked.svg';
import UnCheckedIcon from 'assets/img/checkbox-buuton-unchecked.svg';
import { Menu, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';

const AssetHeading = (props) => {
    // Props
    const {
        onSearch,
        categoryAnchorEl,
        setCategoryAnchorEl,
        category,
        setCategory,
        setPlanAnchorEl,
        planAnchorEl,
        plan,
        setPlan,
        currentTab,
        setResidualRiskAnchorEl,
        residualRiskAnchorEl,
        setResidualRisk,
        residualRisk,
        sources,
        setSources,
        sourcesAnchorEl,
        setSourcesAnchorEl,
    } = props;
    const category_ids = category?.map((category) => category.value);

    const { risk_assessment_categories } = useSelector((state) => state.riskAssessmentReducers);

    return (
        <div className="flex items-center gap-5">
            <div className="relative">
                <input
                    placeholder="Search..."
                    onChange={onSearch}
                    className="text-gray-400 text-sm font-normal placeholder:text-gray-400 border border-gray-200 py-2 rounded-[4px] w-[230px] px-[30px]"
                />
                <img src={SearchIcon} alt="SearchIcon" className="absolute left-2 top-2.5 w-[17px]" />
            </div>
            <div className="flex items-center gap-2">
                <img src={FilterLinesIcon} alt="Filter Icon" className="object-contain" />

                <div>
                    <button
                        aria-controls={categoryAnchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={categoryAnchorEl ? 'true' : undefined}
                        onClick={(event) => {
                            event.stopPropagation();
                            setCategoryAnchorEl(event.currentTarget);
                        }}
                        className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 transition-colors"
                    >
                        <p className="text-[#64748B] font-medium text-[13px]">
                            Category:{' '}
                            {category.length === 0
                                ? 'None'
                                : category.length === 1
                                ? category[0]
                                : category.length === categoryOptions.length
                                ? 'All'
                                : `${category.length} selected`}
                        </p>

                        <img src={ArrowDownIcon} alt="Arrow Down Icon" className="object-contain" />
                    </button>

                    <Menu
                        anchorEl={categoryAnchorEl}
                        open={Boolean(categoryAnchorEl)}
                        onClose={() => setCategoryAnchorEl(null)}
                        sx={{
                            '& .MuiPaper-root': {
                                borderRadius: '4px',
                                boxShadow:
                                    '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                            },
                            '& .MuiList-root': {
                                padding: 0,
                            },
                        }}
                    >
                        {risk_assessment_categories?.map((data, index) => (
                            <MenuItem
                                key={index}
                                onClick={(event) => {
                                    event.stopPropagation();

                                    if (category_ids.includes(data?.value)) {
                                        setCategory(category.filter((item) => item?.value !== data?.value));
                                    } else {
                                        setCategory([...category, data]);
                                    }
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    borderBottom: `1px solid ${
                                        index === categoryOptions.length - 1 ? 'transparent' : '#F1F5F9'
                                    }`,
                                    padding: '8px 16px',
                                }}
                            >
                                <div className="flex items-center gap-1">
                                    {category_ids.includes(data?.value) ? (
                                        <img src={CheckedIcon} alt="Checkbox icon" className="object-contain" />
                                    ) : (
                                        <img src={UnCheckedIcon} alt="Checkbox icon" className="object-contain" />
                                    )}

                                    <p className="text-[#64748B] font-medium text-sm">{data?.name}</p>
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
                <div>
                    <button
                        aria-controls={sourcesAnchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={sourcesAnchorEl ? 'true' : undefined}
                        onClick={(event) => {
                            event.stopPropagation();
                            setSourcesAnchorEl(event.currentTarget);
                        }}
                        className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 transition-colors"
                    >
                        <p className="text-[#64748B] font-medium text-[13px]">
                            Risk Source:{' '}
                            {sources.length === 0
                                ? 'None'
                                : sources.length === risk_sources.length
                                ? 'All'
                                : `${sources.length} selected`}
                        </p>

                        <img
                            src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                            alt="Arrow Down Icon"
                            className="object-contain"
                        />
                    </button>

                    <Menu
                        anchorEl={sourcesAnchorEl}
                        open={Boolean(sourcesAnchorEl)}
                        onClose={() => setSourcesAnchorEl(null)}
                        sx={{
                            '& .MuiPaper-root': {
                                borderRadius: '4px',
                                boxShadow:
                                    '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                            },
                            '& .MuiList-root': {
                                padding: 0,
                            },
                        }}
                    >
                        {risk_sources.map((name, index) => (
                            <MenuItem
                                key={index}
                                onClick={(event) => {
                                    event.stopPropagation();

                                    if (sources.includes(name)) {
                                        setSources(sources.filter((item) => item !== name));
                                    } else {
                                        setSources([...sources, name]);
                                    }
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    borderBottom: `1px solid ${index === plans.length - 1 ? 'transparent' : '#F1F5F9'}`,
                                    padding: '8px 16px',
                                }}
                            >
                                <div className="flex items-center gap-1">
                                    {sources.includes(name) ? (
                                        <img
                                            src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    ) : (
                                        <img
                                            src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    )}

                                    <p className="text-[#64748B] font-medium text-sm">{name}</p>
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
                <div>
                    <button
                        aria-controls={planAnchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={planAnchorEl ? 'true' : undefined}
                        onClick={(event) => {
                            event.stopPropagation();
                            setPlanAnchorEl(event.currentTarget);
                        }}
                        className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 transition-colors"
                    >
                        <p className="text-[#64748B] font-medium text-[13px]">
                            Plan:{' '}
                            {plan.length === 0
                                ? 'None'
                                : plan.length === 1
                                ? plan[0]
                                : plan.length === plans.length
                                ? 'All'
                                : `${plan.length} selected`}
                        </p>

                        <img src={ArrowDownIcon} alt="Arrow Down Icon" className="object-contain" />
                    </button>

                    <Menu
                        anchorEl={planAnchorEl}
                        open={Boolean(planAnchorEl)}
                        onClose={() => setPlanAnchorEl(null)}
                        sx={{
                            '& .MuiPaper-root': {
                                borderRadius: '4px',
                                boxShadow:
                                    '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                            },
                            '& .MuiList-root': {
                                padding: 0,
                            },
                        }}
                    >
                        {plans.map((name, index) => (
                            <MenuItem
                                key={index}
                                onClick={(event) => {
                                    event.stopPropagation();

                                    if (plan.includes(name)) {
                                        setPlan(plan.filter((item) => item !== name));
                                    } else {
                                        setPlan([...plan, name]);
                                    }
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    borderBottom: `1px solid ${index === plans.length - 1 ? 'transparent' : '#F1F5F9'}`,
                                    padding: '8px 16px',
                                }}
                            >
                                <div className="flex items-center gap-1">
                                    {plan.includes(name) ? (
                                        <img src={CheckedIcon} alt="Checkbox icon" className="object-contain" />
                                    ) : (
                                        <img src={UnCheckedIcon} alt="Checkbox icon" className="object-contain" />
                                    )}

                                    <p className="text-[#64748B] font-medium text-sm">{name}</p>
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
                {currentTab !== 'all' && (
                    <>
                        {currentTab === RiskTemplatesTab.FiveByFive ? (
                            <div>
                                <button
                                    aria-controls={residualRiskAnchorEl ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={residualRiskAnchorEl ? 'true' : undefined}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setResidualRiskAnchorEl(event.currentTarget);
                                    }}
                                    className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 transition-colors"
                                >
                                    <p className="text-[#64748B] font-medium text-[13px]">
                                        Risk:{' '}
                                        {residualRisk.length === 0
                                            ? 'None'
                                            : residualRisk.length === 1
                                            ? residualRisk[0]
                                            : residualRisk.length === residual5x5Risks.length
                                            ? 'All'
                                            : `${residualRisk.length} selected`}
                                    </p>

                                    <img src={ArrowDownIcon} alt="Arrow Down Icon" className="object-contain" />
                                </button>

                                <Menu
                                    anchorEl={residualRiskAnchorEl}
                                    open={Boolean(residualRiskAnchorEl)}
                                    onClose={() => setResidualRiskAnchorEl(null)}
                                    sx={{
                                        '& .MuiPaper-root': {
                                            borderRadius: '4px',
                                            boxShadow:
                                                '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                        },
                                        '& .MuiList-root': {
                                            padding: 0,
                                        },
                                    }}
                                >
                                    {residual5x5Risks.map((name, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={(event) => {
                                                event.stopPropagation();

                                                if (residualRisk.includes(name)) {
                                                    setResidualRisk(residualRisk.filter((item) => item !== name));
                                                } else {
                                                    setResidualRisk([...residualRisk, name]);
                                                }
                                            }}
                                            sx={{
                                                color: '#64748B',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                textTransform: 'capitalize',
                                                borderBottom: `1px solid ${
                                                    index === residual5x5Risks.length - 1 ? 'transparent' : '#F1F5F9'
                                                }`,
                                                padding: '8px 16px',
                                            }}
                                        >
                                            <div className="flex items-center gap-1">
                                                {residualRisk.includes(name) ? (
                                                    <img
                                                        src={CheckedIcon}
                                                        alt="Checkbox icon"
                                                        className="object-contain"
                                                    />
                                                ) : (
                                                    <img
                                                        src={UnCheckedIcon}
                                                        alt="Checkbox icon"
                                                        className="object-contain"
                                                    />
                                                )}

                                                <p className="text-[#64748B] font-medium text-sm">{name}</p>
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                        ) : (
                            <div>
                                <button
                                    aria-controls={residualRiskAnchorEl ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={residualRiskAnchorEl ? 'true' : undefined}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setResidualRiskAnchorEl(event.currentTarget);
                                    }}
                                    className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 transition-colors"
                                >
                                    <p className="text-[#64748B] font-medium text-[13px]">
                                        Risk:{' '}
                                        {residualRisk.length === 0
                                            ? 'None'
                                            : residualRisk.length === 1
                                            ? residualRisk[0]
                                            : residualRisk.length === residual3x3Risks.length
                                            ? 'All'
                                            : `${residualRisk.length} selected`}
                                    </p>

                                    <img src={ArrowDownIcon} alt="Arrow Down Icon" className="object-contain" />
                                </button>

                                <Menu
                                    anchorEl={residualRiskAnchorEl}
                                    open={Boolean(residualRiskAnchorEl)}
                                    onClose={() => setResidualRiskAnchorEl(null)}
                                    sx={{
                                        '& .MuiPaper-root': {
                                            borderRadius: '4px',
                                            boxShadow:
                                                '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                        },
                                        '& .MuiList-root': {
                                            padding: 0,
                                        },
                                    }}
                                >
                                    {residual3x3Risks.map((name, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={(event) => {
                                                event.stopPropagation();

                                                if (residualRisk.includes(name)) {
                                                    setResidualRisk(residualRisk.filter((item) => item !== name));
                                                } else {
                                                    setResidualRisk([...residualRisk, name]);
                                                }
                                            }}
                                            sx={{
                                                color: '#64748B',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                textTransform: 'capitalize',
                                                borderBottom: `1px solid ${
                                                    index === residual3x3Risks.length - 1 ? 'transparent' : '#F1F5F9'
                                                }`,
                                                padding: '8px 16px',
                                            }}
                                        >
                                            <div className="flex items-center gap-1">
                                                {residualRisk.includes(name) ? (
                                                    <img
                                                        src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                                        alt="Checkbox icon"
                                                        className="object-contain"
                                                    />
                                                ) : (
                                                    <img
                                                        src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                                        alt="Checkbox icon"
                                                        className="object-contain"
                                                    />
                                                )}

                                                <p className="text-[#64748B] font-medium text-sm">{name}</p>
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default AssetHeading;
