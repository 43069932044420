import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Requests from './Requests';
import Questions from './AssessorQuestions';
import MerchantResponse from './AuditStandards/index';
import PreviewReport from '../AuditPreview/index';

const Index = () => {
    return (
        <Switch>
            <Route path="/admin/accessor" exact component={Requests} />
            <Route path="/admin/accessor/review/:id" exact component={MerchantResponse} />
            <Route path="/admin/accessor/:id" component={Questions} />
            <Route path="/admin/accessor/preview/:id" component={PreviewReport} />
        </Switch>
    );
};

export default Index;
