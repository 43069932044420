import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
//translation
import { useTranslation } from 'react-i18next';
const GenerateCodeButton = (props) => {
    const theme = useTheme();
    const { authType, generateText } = props;

    //translation hook
    const { t } = useTranslation('accounts');
    return (
        <>
            <Box
                sx={{
                    fontSize: 14,
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                    background: 'transparent',
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: '8px',
                    p: 0.5,
                }}
                component="button"
                onClick={authType !== 'email' && generateText}
            >
                {authType === 'email'
                    ? t('twoFactorAuth.authConnectionView.generateCodeButton.email')
                    : t('twoFactorAuth.authConnectionView.generateCodeButton.authenticator')}
            </Box>
        </>
    );
};

export default GenerateCodeButton;
