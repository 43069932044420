import { validation } from 'validate';

export const requestModalValidation = (values) => {
    const errors = {};
    const nameValidate = validation(values.name, 'Scan name', true);
    const ipAddressValidate = validation(values.ip_address, 'Ip Address', true);
    const halfValidate = validation(values.half, 'select', true);

    if (!nameValidate.isValid) {
        errors.name = nameValidate.errorMessage;
    }
    if (!ipAddressValidate.isValid) {
        errors.ip_address = ipAddressValidate.errorMessage;
    }
    if (!halfValidate.isValid) {
        errors.half = halfValidate.errorMessage;
    }
    return errors;
};
export const uploadModalValidation = (values) => {
    const errors = {};
    const nameValidate = validation(values.name, 'Scan name', true);
    const severityValidate = validation(values.severity, 'select', true);
    const uploadedByValidate = validation(values.uploaded_by, 'platform name', true);
    const conductedDateValidate = validation(values.conducted_date, 'Date', true);
    const ipAddressValidate = validation(values.ip_address, 'Ip Address', true);
    const reportValidate = validation(values.file_upload, 'report', true);

    if (!nameValidate.isValid) {
        errors.name = nameValidate.errorMessage;
    }
    if (!severityValidate.isValid) {
        errors.severity = severityValidate.errorMessage;
    }
    if (!uploadedByValidate.isValid) {
        errors.uploaded_by = uploadedByValidate.errorMessage;
    }
    if (!conductedDateValidate.isValid) {
        errors.conducted_date = conductedDateValidate.errorMessage;
    }
    if (!reportValidate.isValid) {
        errors.file_upload = reportValidate.errorMessage;
    }
    if (!ipAddressValidate.isValid) {
        errors.ip_address = ipAddressValidate.errorMessage;
    }
    return errors;
};
