import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Typography, Stack, Box } from '@mui/material';

//core components
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppForm, AppFormInput, AppSubmitButton } from 'components/new_components/forms';
import AppButton from 'components/new_components/AppButton';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
//utils
import { passwordValidation } from '../utils/validation';
//redux
import { connect } from 'react-redux';
import { ConfirmPassword } from 'store/actions/generalActions';

const ConfirmPasswordModal = (props) => {
    const { open, handleClose, ConfirmPassword, openAuthConnectionView, type, handleTwoFASwitch } = props;
    const [loadingState, setLoadingState] = useState(false);
    const theme = useTheme();
    //translation hook
    const { t } = useTranslation('accounts');
    const handleSubmit = async (values) => {
        setLoadingState(true);
        const res = await ConfirmPassword(values);
        setLoadingState(false);
        if (res?.success) {
            toast.success('Password Confirmed Successfully');
            handleClose();
            if (type) {
                setTimeout(() => openAuthConnectionView(type), 2000);
            } else {
                handleTwoFASwitch();
            }
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            title={
                <Typography sx={{ color: theme.palette.gray[40], fontSize: '14px' }}>
                    {t('twoFactorAuth.confirmPasswordModal.header')}
                </Typography>
            }
            width="520px"
        >
            <Box sx={{ width: '89%' }}>
                <AppForm
                    initialValues={{
                        password: '',
                    }}
                    onSubmit={handleSubmit}
                    validate={(values) => passwordValidation(values)}
                >
                    <AppFormInput
                        type="password"
                        label={t('twoFactorAuth.confirmPasswordModal.input1.label')}
                        placeholder={t('twoFactorAuth.confirmPasswordModal.input1.placeholder')}
                        name="password"
                    />
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ my: 3, width: '20%' }}>
                        <AppButton
                            name={t('twoFactorAuth.cancel')}
                            sx={{ color: theme.palette.error[500] }}
                            onClick={handleClose}
                        />
                        <AppSubmitButton
                            text={t('twoFactorAuth.confirm')}
                            loadingPosition="center"
                            sx={{
                                color: theme.palette.white.main,
                                ml: 2,
                                px: 2,
                                minWidth: 'unset',
                                '&, &:hover': {
                                    backgroundColor: theme.palette.error[500],
                                },
                            }}
                            loading={loadingState}
                        />
                    </Stack>
                </AppForm>
            </Box>
        </AppCenteredModal>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { ConfirmPassword })(ConfirmPasswordModal);
