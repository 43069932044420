import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button } from 'antd';

//Redux
import { connect } from 'react-redux';
import { EditSupportUser } from 'store/actions/adminActions';

const EditModal = (props) => {
    const [loading, setloading] = useState(false);
    const [data, setData] = useState({});
    const { open, handleClose, EditSupportUser, record } = props;

    const handleTextChange = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value });
    };

    const onClose = () => {
        setData({});
        handleClose();
    };

    useEffect(() => {
        if (record) {
            setData({
                first_name: record?.user_detail?.first_name,
                last_name: record?.user_detail?.last_name,
                id: record?.id,
            });
        }
    }, [record]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setloading(true);

        const res = await EditSupportUser(data, data?.id);
        setloading(false);
        if (res?.success) {
            toast.success('Support User Record Updated Successfully!');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <Modal
            title="Edit Support Information"
            visible={open}
            onCancel={handleClose}
            footer={null}
            destroyOnClose={true}
        >
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="name">First Name</label>
                        <Input
                            type="text"
                            size="large"
                            name="first_name"
                            id="first_name"
                            onChange={handleTextChange}
                            value={data.first_name || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="last_name">Last Name</label>
                        <Input
                            type="text"
                            size="large"
                            name="last_name"
                            id="last_name"
                            onChange={handleTextChange}
                            value={data.last_name || ''}
                            required
                        />
                    </Col>
                    <Col style={{ marginTop: '5rem' }}>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Update
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { EditSupportUser })(EditModal);
