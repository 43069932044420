import React from 'react';
import { FormHelperText } from '@mui/material';
// formik
import { useFormikContext } from 'formik';
// core components
import AppCheckboxGroup from '../AppCheckboxGroup';

import { formatSentence } from 'utils';

const AppFormCheckboxGroup = (props) => {
    const { name, ...restProps } = props;

    // get the formik context values
    const { setFieldTouched, handleChange, touched, values, errors } = useFormikContext();

    return (
        <>
            <AppCheckboxGroup
                value={values[name]}
                onChange={handleChange(name)}
                onBlur={() => setFieldTouched(name)}
                error={touched[name] && errors[name]}
                name={name}
                {...restProps}
            />
            {touched[name] && errors[name] && <FormHelperText error>{formatSentence(errors[name])}</FormHelperText>}
        </>
    );
};

export default AppFormCheckboxGroup;
