import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

function CustomSwitch({ checked, onChange, disabled }) {
    const CustomizedSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 52,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(26px)',
                color: '#fff',
                '& .MuiSwitch-thumb': {
                    backgroundColor: '#F1F5F9',
                    '&:: before': {
                        backgroundImage: `url('data:image/svg+xml;utf8,<svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.36665 9.00104L0.56665 5.20104L1.51665 4.25104L4.36665 7.10104L10.4833 0.984375L11.4333 1.93437L4.36665 9.00104Z" fill="${encodeURIComponent(
                            '#334155'
                        )}"/></svg>')`,
                    },
                },
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#62DF98',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            width: 22,
            height: 22,
            backgroundColor: '#FFFFFF',
            '&::before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.26659 9.66537L0.333252 8.73203L4.06659 4.9987L0.333252 1.26536L1.26659 0.332031L4.99992 4.06536L8.73325 0.332031L9.66659 1.26536L5.93325 4.9987L9.66659 8.73203L8.73325 9.66537L4.99992 5.93203L1.26659 9.66537Z" fill="${encodeURIComponent(
                    '#94A3B8'
                )}"/></svg>')`,
            },
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E2E8F0' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    return (
        <FormControlLabel
            control={<CustomizedSwitch sx={{ m: 0 }} />}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            sx={{ m: 0 }}
        />
    );
}
export default CustomSwitch;
