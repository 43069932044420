import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';
import ReadDocument from '../../../../assets/img/ReadDocument.svg';

const ReadDocumentation = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                border: '1px solid #E2E8F0',
                px: 3,
                py: 1.5,
                borderRadius: 1,
            }}
        >
            {' '}
            <Typography sx={{ color: theme.palette.primary[40], fontWeight: 600, fontSize: '14px', mb: 3 }}>
                API Documentation
            </Typography>
            <Typography sx={{ color: theme.palette.neutral[500], fontWeight: 400, fontSize: '13px', mb: 3 }}>
                Take a look at Fraud guardian documentation to ease implementation
            </Typography>
            <a
                href="https://documenter.getpostman.com/view/24557562/2sA3JQ4zH6#041f9e73-04a3-4b8f-a1ab-b5a19794e968"
                target="_blank"
                rel="noopener noreferrer"
                className="no-underline"
            >
                <Box
                    sx={{
                        mt: 2,
                        height: '100px',
                        backgroundColor: '#F8FAFC',
                        display: 'flex',
                        alignItems: 'center',
                        px: 3,
                        textDecoration: 'none',
                        width: '100%',
                        [theme.breakpoints.down('xl')]: {
                            width: '100%',
                        },
                    }}
                >
                    <img src={ReadDocument} alt="ReadDocument" />
                    <Box sx={{ ml: 4 }}>
                        <Typography sx={{ fontSize: '13px', fontWeight: 600, color: theme.palette.neutral[600] }}>
                            Read the documentation
                        </Typography>
                    </Box>
                </Box>{' '}
            </a>
        </Box>
    );
};

export default ReadDocumentation;
