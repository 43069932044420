import React, { useState } from 'react';
import { toast } from 'react-toastify';

import AppDeleteModal from 'components/new_components/AppDeleteModal';

// redux
import { connect } from 'react-redux';
import { DeleteAuditRequest } from 'store/actions/auditActions';

const AuditDeleteModal = (props) => {
    const { open, handleClose, DeleteAuditRequest, data } = props;

    const [deleting, setDeleting] = useState(false);

    const handleDelete = async () => {
        setDeleting(true);
        const res = await DeleteAuditRequest(data?.id);
        setDeleting(false);
        if (res?.success) {
            toast.success('Successfully deleted.');
            handleClose();
        } else {
            toast.error('Something went wrong. Kindl reload this page and try again.');
        }
    };

    return (
        <AppDeleteModal
            open={open}
            handleClose={handleClose}
            title="Permanently delete this audit?"
            subtitle="Please note that this action will delete all records of your audit including your certification of compliance."
            deleting={deleting}
            onDelete={handleDelete}
        />
    );
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, {
    DeleteAuditRequest,
})(AuditDeleteModal);
