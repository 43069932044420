import React, { useState } from 'react';

import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { Box, Typography, useTheme } from '@mui/material';
// import { BsInfoCircle } from 'react-icons/bs';
import { CancelRounded } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { connect } from 'react-redux';
import IntegrationFormModal from './IntegrationFormModal';

const ConnectWithModal = (props) => {
    const theme = useTheme();

    // props
    const { open, handleClose, onConnect, integration } = props;
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            headerAction={
                <button onClick={handleClose}>
                    <CancelRounded sx={{ color: theme.palette.neutral[60] }} />
                </button>
            }
        >
            <Box>
                <Typography
                    sx={{ color: theme.palette.primary[700], fontSize: '16px', fontWeight: 700, textAlign: 'center' }}
                >
                    Connect With
                </Typography>
                <Box sx={{ my: 4 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2,
                            border: '1.5px solid #F0F0FA',
                            borderRadius: '5px',
                            px: 1,
                            py: 1.5,
                            cursor: 'pointer',
                        }}
                        onClick={handleOpenModal}
                    >
                        <Typography sx={{ color: theme.palette.gray[40], fontSize: '14px', fontWeight: 500 }}>
                            OAUTH
                        </Typography>
                        <Box sx={{ backgroundColor: '#EBEDF5', borderRadius: '50%' }}>
                            <ChevronRightIcon />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2,
                            border: '1.5px solid #F0F0FA',
                            borderRadius: '5px',
                            px: 0.5,
                            py: 1.5,
                            cursor: 'pointer',
                        }}
                        onClick={onConnect}
                    >
                        <Typography sx={{ color: theme.palette.gray[40], fontSize: '14px', fontWeight: 500 }}>
                            Credentials
                        </Typography>
                        <Box sx={{ backgroundColor: '#EBEDF5', borderRadius: '50%' }}>
                            <ChevronRightIcon />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <IntegrationFormModal open={openModal} handleClose={handleCloseModal} integration={integration} />
        </AppCenteredModal>
    );
};

const mapStateToProps = (state) => {
    return {
        authorization_url: state.integrationsReducers?.authorization_url,
    };
};
export default connect(mapStateToProps, {})(ConnectWithModal);
