import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import ScannerIcon from '@mui/icons-material/Scanner';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { InputAdornment, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import AppModal from 'components/new_components/AppModal';
import { AppForm, AppFormInput, AppFormSelect, AppSubmitButton } from 'components/new_components/forms';
import AppRadioGroup from 'components/new_components/AppRadioGroup';

//validation
import { versionControlOptions } from '../utils/constants';
import { codeReviewInitialValues, radioValues } from '../utils/constants';
import { copierHelper } from 'utils';

// Redux
import { RequestCodeReview } from 'store/actions/merchantActions';
import { GetAllCodeReview } from 'store/actions/merchantActions';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import { connect } from 'react-redux';
import { PrivateCodeReviewValidation, publicCodeReviewValidation } from '../utils/validation';

//translation
import { useTranslation } from 'react-i18next';

const CodeReviewModal = (props) => {
    const { GetAllCodeReview, all_code_review } = props;
    const theme = useTheme();
    const { open, handleClose, payload, RequestCodeReview } = props;
    const [authorization, setAuthorization] = useState(false);
    const [value, setValue] = React.useState(codeReviewInitialValues.repo_type);
    const [loadingState, setLoadingState] = useState(false);

    //translation
    const { t } = useTranslation('compliance');

    const handleRadioChange = (event) => {
        setValue(event.target.value);
    };

    const handleAuthorizationCheck = () => {
        setAuthorization(!authorization);
    };

    const filteredCodeReview = all_code_review?.filter(
        (code_review) => code_review?.status === 'running' || code_review?.status === 'rerunning'
    );

    // async functions
    const getallCodeReview = async () => {
        const res = await GetAllCodeReview();
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };
    useEffect(() => {
        getallCodeReview();
    }, []);

    const handleSubmit = async (values) => {
        if (authorization === false) {
            toast.info('You have to authorize this request!');
        } else {
            setLoadingState(true);
            const res = await RequestCodeReview({
                version_control_tool: values?.version_control_tool,
                repo_type: value,
                link: value === 'public' ? values?.link : '',
            });
            setLoadingState(false);
            if (res?.success) {
                toast.success('Successful request');
                handleClose();
            } else {
                toast.error(res?.message);
            }
        }
    };

    return (
        <AppForm
            initialValues={payload}
            onSubmit={handleSubmit}
            validate={value === 'public' ? publicCodeReviewValidation : PrivateCodeReviewValidation}
        >
            <AppModal
                open={open}
                handleClose={handleClose}
                title={t('blockchain.functionality')}
                sub_title={filteredCodeReview?.length > 0 ? t('blockchain.pending') : ''}
                width="800px"
                height="800"
                maxHeight="800"
                top={50}
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: 10,
                            marginBottom: 3,
                        }}
                    >
                        <AppCheckboxInput
                            name="authorization"
                            label={t('blockchain.authorization')}
                            onChange={handleAuthorizationCheck}
                            value={authorization}
                        />
                        <AppSubmitButton
                            text={t('blockchain.request')}
                            startIcon={<ScannerIcon />}
                            variant="contained"
                            color="primary"
                            loading={loadingState}
                            loadingPosition="center"
                            sx={{ py: 1.5, px: 5 }}
                        />
                    </Box>
                }
            >
                <AppFormSelect
                    name="version_control_tool"
                    label={t('blockchain.versionControl')}
                    options={versionControlOptions}
                    defaultValue={t('blockchain.selectVersion')}
                    required
                />
                <Box sx={{ marginTop: 3 }}>
                    <AppRadioGroup
                        label={radioValues.name}
                        options={radioValues.options}
                        sx={{ my: 1 }}
                        value={value}
                        onChange={handleRadioChange}
                        codereview
                        hasBorder={'none'}
                        required
                    />
                </Box>
                {value === 'public' ? (
                    <AppFormInput
                        type="text"
                        label={t('blockchain.repositories')}
                        name="link"
                        placeholder={t('blockchain.publicRepo')}
                    />
                ) : value === 'private' ? (
                    <AppFormInput
                        type="text"
                        disabled
                        value={'codereview@wiredassurance.com'}
                        label={
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontStyle: 'italic',
                                }}
                            >
                                {t('blockchain.beginCodeReview')}
                            </Typography>
                        }
                        name="access"
                        placeholder={t('blockchain.email')}
                        adornment={
                            <InputAdornment position="end">
                                <IconButton edge="end">
                                    <ContentCopyIcon sx={{ color: theme.palette.primary[900] }} />
                                </IconButton>
                            </InputAdornment>
                        }
                        onClick={() => copierHelper('codereview@wiredassurance.com')}
                    />
                ) : null}
            </AppModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        all_code_review: state?.merchantReducers?.all_code_review,
    };
};

export default connect(mapStateToProps, { RequestCodeReview, GetAllCodeReview })(CodeReviewModal);
