import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Grid, Typography } from '@mui/material';
import WelcomePicture from '../../../src/assets/img/WelcomePicture.svg';
import TourIcon from '../../../src/assets/img/TourIcon.svg';
import CheckmarkDoneIcon from '../../../src/assets/img/CheckmarkDoneIcon.svg';
import Number1Icon from '../../../src/assets/img/Number1Icon.svg';
import Number2Icon from '../../../src/assets/img/Number2Icon.svg';
import Number3Icon from '../../../src/assets/img/Number3Icon.svg';
import SetUpCompany from '../../../src/assets/img/SetUpCompany.svg';
import Arrow1 from '../../../src/assets/img/Arrow1.svg';
import InputBusinessName from '../../../src/assets/img/InputBusinessName.svg';
import Arrow2 from '../../../src/assets/img/Arrow2.svg';
import UpdateButton from '../../../src/assets/img/UpdateButton.svg';
import SetUpPicture from '../../../src/assets/img/SetUpPicture.svg';
import Arrow3 from '../../../src/assets/img/Arrow3.svg';
import IntegrationPicture from '../../../src/assets/img/IntegrationPicture.svg';
import SnykPicture from '../../../src/assets/img/SnykPicture.svg';
import Arrow4 from '../../../src/assets/img/Arrow4.svg';
import UserPicture from '../../../src/assets/img/UserPicture.svg';
import CreateUserPicture from '../../../src/assets/img/CreateUserPicture.svg';
import CreateUserInput from '../../../src/assets/img/CreateUserInput.svg';

// core components
import WelcomeCenteredModal from '../new_components/WelcomeCenteredModal';

const WelcomeModal = (props) => {
    const [activeState, SetActiveState] = useState(0);
    const { open, handleClose } = props;
    const theme = useTheme();

    const increaseActiveState = () => {
        SetActiveState(activeState + 1);
    };

    const decreaseActiveState = () => {
        SetActiveState(activeState - 1);
    };

    return (
        <WelcomeCenteredModal open={open} handleClose={handleClose} width="500px" padding={0}>
            <Grid container spacing={0} sx={{ display: 'flex', height: 550, padding: 0 }}>
                <Grid
                    item
                    xs={0}
                    lg={5}
                    sx={{
                        display: { xs: 'none', lg: 'flex' },
                        backgroundColor: theme.palette.primary.main,
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 10,
                    }}
                >
                    <Box alignItems="center">
                        <img src={WelcomePicture} alt="Welcome to Smart Comply App" />
                    </Box>
                </Grid>
                <Grid item xs={12} lg={7} sx={{ backgroundColor: '#EEF0FF' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center',
                            padding: '16px',
                        }}
                    >
                        <Box
                            onClick={handleClose}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '36px',
                                height: '36px',
                                cursor: 'pointer',
                                color: '#858999',
                                border: '4px solid #ffffff',
                                borderRadius: '50%',
                            }}
                            className="exit-modal"
                        >
                            🗙
                        </Box>
                    </Box>
                    {activeState === 0 ? (
                        <Stack sx={{ height: '87%', px: '75px', paddingBottom: '40px' }} justifyContent="space-between">
                            <Stack justifyContent="center" alignItems="center">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.primary[900] }}>
                                        Before you get <Box sx={{ fontSize: 24, display: 'inline' }}>overwhelmed</Box>
                                    </Box>
                                    <Typography sx={{ fontSize: 16, fontWeight: 400, color: '#3E414D' }}>
                                        We have some simple set up to get you started
                                    </Typography>
                                </Box>
                                <Box
                                    onClick={increaseActiveState}
                                    sx={{
                                        display: 'flex',
                                        backgroundColor: theme.palette.white.main,
                                        height: 60,
                                        width: 400,
                                        padding: 5,
                                        paddingLeft: 2,
                                        borderRadius: 5,
                                        my: '50px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            marginRight: '7px',
                                        }}
                                    >
                                        <img src={TourIcon} alt="Click to tour" />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'left',
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.primary[900] }}
                                        >
                                            Take a brief tour
                                        </Typography>
                                        <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#3E414D' }}>
                                            Getting started with Smartcomply
                                        </Typography>
                                    </Box>
                                </Box>
                            </Stack>
                            <Box
                                onClick={handleClose}
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    color: theme.palette.primary[700],
                                    display: 'flex',
                                    justifyContent: 'right',
                                    cursor: 'pointer',
                                }}
                            >
                                Dismiss
                            </Box>
                        </Stack>
                    ) : activeState === 1 ? (
                        <Stack sx={{ height: '87%', px: '55px', paddingBottom: '40px' }} justifyContent="space-between">
                            <Stack>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'top',
                                    }}
                                >
                                    <Box sx={{ display: 'inline' }}>
                                        <img
                                            src={Number1Icon}
                                            alt="Number 1"
                                            style={{ display: 'inline', marginTop: '-45px' }}
                                        />
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontWeight: 700,
                                            color: theme.palette.primary[900],
                                            marginTop: '-35px',
                                            marginLeft: '-7px',
                                        }}
                                    >
                                        Setting up your Company Information
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        color: '#3E414D',
                                        marginTop: '-30px',
                                        textAlign: 'justify',
                                    }}
                                >
                                    Here, you get to choose <b>audit standards</b>, fill some necessary information and
                                    upload a logo suitable for your company. Easy-peasy!
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'left',
                                        marginTop: '10px',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#0E2C66' }}>
                                        Navigate to Company Menu
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                            alignItems: 'left',
                                        }}
                                    >
                                        <Box sx={{ display: 'inline' }}>
                                            <img src={SetUpCompany} alt="Set up company image on sidebar" />
                                        </Box>
                                        <Box sx={{ display: 'inline', marginTop: '10px' }}>
                                            <img src={Arrow1} alt="Arrow" />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#0E2C66' }}>
                                        Fill the necessary information
                                    </Typography>
                                    <Box sx={{ marginTop: '-25px' }}>
                                        <img src={InputBusinessName} alt="Imput business name image" />
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'left',
                                        alignItems: 'left',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                            alignItems: 'left',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                color: '#0E2C66',
                                                marginTop: '-5px',
                                            }}
                                        >
                                            And click <b>update</b>
                                        </Typography>
                                        <Box sx={{ marginTop: '-31px', marginRight: '45px', marginLeft: '2px' }}>
                                            <img src={Arrow2} alt="Arrow" />
                                        </Box>
                                    </Box>
                                    <Box sx={{ marginLeft: '45px' }}>
                                        <img src={UpdateButton} alt="Update button image" style={{ width: '95px' }} />
                                    </Box>
                                </Box>
                            </Stack>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Box
                                        sx={{
                                            width: '26px',
                                            height: '6px',
                                            borderRadius: '3px',
                                            backgroundColor: theme.palette.primary.main,
                                        }}
                                    ></Box>
                                    <Box
                                        onClick={increaseActiveState}
                                        sx={{
                                            width: '26px',
                                            height: '6px',
                                            mx: '8px',
                                            borderRadius: '3px',
                                            backgroundColor: '#c7c6ca',
                                            cursor: 'pointer',
                                        }}
                                    ></Box>
                                    <Box
                                        onClick={() => {
                                            SetActiveState(3);
                                        }}
                                        sx={{
                                            width: '26px',
                                            height: '6px',
                                            borderRadius: '3px',
                                            backgroundColor: '#c7c6ca',
                                            cursor: 'pointer',
                                        }}
                                    ></Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'right',
                                    }}
                                >
                                    <Box
                                        onClick={handleClose}
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            marginRight: '30px',
                                            color: theme.palette.primary[700],
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Dismiss
                                    </Box>
                                    <Box
                                        onClick={increaseActiveState}
                                        sx={{
                                            backgroundColor: theme.palette.primary[900],
                                            color: theme.palette.white.main,
                                            borderRadius: '20px',
                                            fontWeight: 600,
                                            fontSize: '16px',
                                            padding: '8px',
                                            width: '80px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Next
                                    </Box>
                                </Box>
                            </Box>
                        </Stack>
                    ) : activeState === 2 ? (
                        <Stack sx={{ height: '87%', px: '55px', paddingBottom: '40px' }} justifyContent="space-between">
                            <Stack>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'top',
                                    }}
                                >
                                    <Box sx={{ display: 'inline' }}>
                                        <img
                                            src={Number2Icon}
                                            alt="Number 2"
                                            style={{ display: 'inline', marginTop: '-45px' }}
                                        />
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontWeight: 700,
                                            color: theme.palette.primary[900],
                                            marginTop: '-35px',
                                            marginLeft: '-7px',
                                        }}
                                    >
                                        Connecting your tools
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        color: '#3E414D',
                                        marginTop: '-30px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Connect your tools to automate security
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'left',
                                        marginTop: '45px',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#0E2C66' }}>
                                        Go to Setups Menu
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <img src={SetUpPicture} alt="Set up image on sidebar" />
                                        </Box>
                                        <Box
                                            sx={{
                                                marginTop: '25px',
                                            }}
                                        >
                                            <img src={Arrow3} alt="Arrow" />
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                color: '#0E2C66',
                                                marginTop: '25px',
                                            }}
                                        >
                                            Navigate to Integrations tab
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
                                    <img src={IntegrationPicture} alt="Integrations tab image" />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                    }}
                                >
                                    <Box sx={{ marginTop: '30px' }}>
                                        <img src={SnykPicture} alt="Synk integrations image" />
                                    </Box>
                                    <Box>
                                        <Box>
                                            <img src={Arrow4} alt="Arrow" />
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                color: '#0E2C66',
                                                marginLeft: '-30px',
                                            }}
                                        >
                                            Find your tools and <b>connect</b>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Stack>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Box
                                        onClick={decreaseActiveState}
                                        sx={{
                                            width: '26px',
                                            height: '6px',
                                            borderRadius: '3px',
                                            backgroundColor: '#c7c6ca',
                                            cursor: 'pointer',
                                        }}
                                    ></Box>
                                    <Box
                                        sx={{
                                            width: '26px',
                                            height: '6px',
                                            mx: '8px',
                                            borderRadius: '3px',
                                            backgroundColor: theme.palette.primary.main,
                                        }}
                                    ></Box>
                                    <Box
                                        onClick={increaseActiveState}
                                        sx={{
                                            width: '26px',
                                            height: '6px',
                                            borderRadius: '3px',
                                            backgroundColor: '#c7c6ca',
                                            cursor: 'pointer',
                                        }}
                                    ></Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'right',
                                    }}
                                >
                                    <Box
                                        onClick={handleClose}
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            marginRight: '30px',
                                            color: theme.palette.primary[700],
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Dismiss
                                    </Box>
                                    <Box
                                        onClick={increaseActiveState}
                                        sx={{
                                            backgroundColor: theme.palette.primary[900],
                                            color: theme.palette.white.main,
                                            borderRadius: '20px',
                                            fontWeight: 600,
                                            fontSize: '16px',
                                            padding: '8px',
                                            width: '80px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Next
                                    </Box>
                                </Box>
                            </Box>
                        </Stack>
                    ) : activeState === 3 ? (
                        <Stack sx={{ height: '87%', px: '55px', paddingBottom: '40px' }} justifyContent="space-between">
                            <Stack>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'top',
                                    }}
                                >
                                    <Box sx={{ display: 'inline' }}>
                                        <img
                                            src={Number3Icon}
                                            alt="Number 3"
                                            style={{ display: 'inline', marginTop: '-45px' }}
                                        />
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontWeight: 700,
                                            color: theme.palette.primary[900],
                                            marginTop: '-35px',
                                            marginLeft: '-7px',
                                        }}
                                    >
                                        Adding your users (Employees)
                                    </Typography>
                                </Box>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        color: '#3E414D',
                                        marginTop: '-30px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Add your users/Employees
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'left',
                                        marginTop: '35px',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#0E2C66' }}>
                                        Go to Users Menu
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            marginTop: '10px',
                                            justifyContent: 'space-around',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <img src={UserPicture} alt="User image on sidebar" />
                                        </Box>
                                        <Box
                                            sx={{
                                                marginTop: '15px',
                                            }}
                                        >
                                            <img src={Arrow3} alt="Arrow" />
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                color: '#0E2C66',
                                                marginTop: '15px',
                                            }}
                                        >
                                            Click on Create User
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
                                    <img src={CreateUserPicture} alt="Create User button image" />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        height: '160px',
                                    }}
                                >
                                    <Box sx={{ marginTop: '30px', marginLeft: '-65px' }}>
                                        <img src={CreateUserInput} alt="Create User Input image" />
                                    </Box>
                                    <Box>
                                        <Box sx={{ marginLeft: '40px' }}>
                                            <img src={Arrow4} alt="Arrow" />
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                color: '#0E2C66',
                                                marginLeft: '-10px',
                                            }}
                                        >
                                            Fill the required details and create a user
                                        </Typography>
                                    </Box>
                                </Box>
                            </Stack>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Box
                                        onClick={() => {
                                            SetActiveState(1);
                                        }}
                                        sx={{
                                            width: '26px',
                                            height: '6px',
                                            borderRadius: '3px',
                                            backgroundColor: '#c7c6ca',
                                            cursor: 'pointer',
                                        }}
                                    ></Box>
                                    <Box
                                        onClick={decreaseActiveState}
                                        sx={{
                                            width: '26px',
                                            height: '6px',
                                            mx: '8px',
                                            borderRadius: '3px',
                                            backgroundColor: '#c7c6ca',
                                            cursor: 'pointer',
                                        }}
                                    ></Box>
                                    <Box
                                        sx={{
                                            width: '26px',
                                            height: '6px',
                                            borderRadius: '3px',
                                            backgroundColor: theme.palette.primary.main,
                                        }}
                                    ></Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'right',
                                    }}
                                >
                                    <Box
                                        onClick={handleClose}
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            marginRight: '30px',
                                            color: theme.palette.primary[700],
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Dismiss
                                    </Box>
                                    <Box
                                        onClick={increaseActiveState}
                                        sx={{
                                            backgroundColor: theme.palette.primary[900],
                                            color: theme.palette.white.main,
                                            borderRadius: '20px',
                                            fontWeight: 600,
                                            fontSize: '16px',
                                            padding: '8px',
                                            width: '80px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Next
                                    </Box>
                                </Box>
                            </Box>
                        </Stack>
                    ) : activeState === 4 ? (
                        <Stack sx={{ height: '87%', px: '75px', paddingBottom: '40px' }} justifyContent="space-between">
                            <Stack justifyContent="center" alignItems="center">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: theme.palette.white.main,
                                        height: 350,
                                        width: 470,
                                        borderRadius: 5,
                                    }}
                                >
                                    <img src={CheckmarkDoneIcon} alt="Checkmark Done" />
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontWeight: 700,
                                            marginTop: '14px',
                                            color: theme.palette.primary[900],
                                        }}
                                    >
                                        You are done
                                    </Typography>
                                </Box>
                            </Stack>
                            <Box
                                onClick={handleClose}
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    color: theme.palette.primary[700],
                                    display: 'flex',
                                    justifyContent: 'right',
                                    cursor: 'pointer',
                                }}
                            >
                                Dismiss
                            </Box>
                        </Stack>
                    ) : null}
                </Grid>
            </Grid>
        </WelcomeCenteredModal>
    );
};

export default WelcomeModal;
