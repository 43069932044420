import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { RiskLevelOption } from './utils/constants';
import { connect } from 'react-redux';
import { GetAllMerchantRiskAssessments } from 'store/actions/merchantActions';
import SearchIcon from '../../../assets/img/Searchicon.svg';
import VectorDown from 'assets/img/vector-down.svg';

const KycHead = (props) => {
    // Props
    const { onSearch, risk_level, setRisk_level } = props;

    // Hook States
    const [riskanchorEl, setRiskAnchorEl] = useState(null);

    // Functions
    const riskOpenMenu = (e) => setRiskAnchorEl(e.target);
    const riskCloseMenu = () => setRiskAnchorEl(null);

    const handleRiskChange = (value) => {
        setRisk_level(value);
    };

    return (
        <div className="flex items-center gap-4 ml-[-1rem]">
            <div className="relative">
                <input
                    placeholder="Search..."
                    onChange={onSearch}
                    className="text-gray-400 text-sm font-400 placeholder:text-gray-400 border border-gray-200 py-2.5 rounded-[4px] w-[230px] px-[30px]"
                />
                <img src={SearchIcon} alt="SearchIcon" className="absolute left-2 top-3 w-[17px]" />
            </div>
            <div className="relative">
                <button
                    className={`border border-gray-200 py-2.5 ${
                        risk_level?.value === 'medium'
                            ? 'text-[#395BA9] w-[160px]'
                            : risk_level?.value !== 'all'
                            ? 'text-[#395BA9] w-[140px]'
                            : 'text- gray-500 w-[123px]'
                    } text-[13px] font-medium rounded-[4px] flex justify-between items-center px-2`}
                    onClick={riskOpenMenu}
                >
                    Risk Level: {risk_level?.name}
                </button>
                <img
                    src={VectorDown}
                    alt="VectorDown"
                    className="text-neutral-40 w-[6px] h-[4px] absolute right-3 top-5 font-normal"
                />
            </div>
            <Menu open={Boolean(riskanchorEl)} anchorEl={riskanchorEl} onClose={riskCloseMenu}>
                {RiskLevelOption?.map((option) => (
                    <MenuItem
                        sx={{ color: '#64748B', fontSize: '14px', fontWeight: 500 }}
                        onClick={() => {
                            handleRiskChange(option);
                            riskCloseMenu();
                        }}
                    >
                        {option.name}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_risk_assessments: state?.merchantReducers?.all_merchant_risk_assessments,
    };
};
export default connect(mapStateToProps, { GetAllMerchantRiskAssessments })(KycHead);
