import React from 'react';
import moment from 'moment';
import { Input, Select, TimePicker, DatePicker, Button, Checkbox, Radio, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

// sub-components
const { Option } = Select;
const { TextArea } = Input;
const { Group } = Checkbox;

const FieldItem = (props) => {
    const { field } = props;
    return (
        <div className="form-group" style={{ marginBottom: '1rem' }}>
            <label>
                {field?.name} {field?.required && <span style={{ color: 'red' }}>*</span>}
            </label>
            {field.type?.toLowerCase() === 'text' ? (
                <Input type="text" size="large" />
            ) : field.type?.toLowerCase() === 'number' ? (
                <Input type="number" size="large" required={field?.required} />
            ) : field?.type?.toLowerCase() === 'dropdown' || field?.type?.toLowerCase() === 'multi-field' ? (
                <Select size="large" style={{ width: '100%' }} required={field?.required}>
                    {field?.options?.split(',')?.map((option, index) => (
                        <Option value={option} key={index}>
                            {option}
                        </Option>
                    ))}
                </Select>
            ) : field?.type?.toLowerCase() === 'time' ? (
                <TimePicker
                    defaultValue={moment('00:00:00', 'HH:mm:ss')}
                    size="large"
                    style={{ width: '100%' }}
                    required={field?.required}
                />
            ) : field?.type?.toLowerCase() === 'date' ? (
                <DatePicker
                    defaultValue={moment('00:00:00', 'HH:mm:ss')}
                    size="large"
                    style={{ width: '100%' }}
                    required={field?.required}
                />
            ) : field?.type?.toLowerCase() === 'checkboxes' ? (
                <Group
                    style={{ display: 'block', marginTop: '.8rem' }}
                    options={field?.options?.split(',')}
                    defaultValue={field?.options?.split(',')?.[0]}
                    // onChange={onChange}
                />
            ) : field?.type?.toLowerCase() === 'radio' ? (
                <Radio.Group style={{ display: 'block', marginTop: '.8rem' }}>
                    {field?.options?.split(',')?.map((item, index) => {
                        return (
                            <Radio value={item} key={index}>
                                {item}
                            </Radio>
                        );
                    })}
                </Radio.Group>
            ) : field?.type?.toLowerCase() === 'file' ? (
                <div>
                    <Upload>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </div>
            ) : (
                <TextArea rows={4} size="large" required={field?.required} />
            )}
        </div>
    );
};

export default FieldItem;
