import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import {
    plans,
    // residual3x3Risks, residual5x5Risks
} from '../utils/constants';
import { useSelector } from 'react-redux';
import { templateTypes } from '../utils/constants';

const RiskAction = (props) => {
    const {
        categoryAnchorEl,
        setCategoryAnchorEl,
        category,
        setCategory,
        setPlanAnchorEl,
        planAnchorEl,
        plan,
        setPlan,
        // setResidualRiskAnchorEl,
        // residualRiskAnchorEl,
        // setResidualRisk,
        // residualRisk,
        templateTypeAnchorEl,
        setTemplateTypeAnchorEl,
        templateType,
        setTemplateType,
        // mitigationOwner,
        // setMitigationOwner,
        // setMitigationOwnerAnchorEl,
        // mitigationOwnerAnchorEl,
        // mitigationOwnerSearchTerm,
        // setMitigationOwnerSearchTerm,
    } = props;
    const { risk_assessment_categories } = useSelector((state) => state.riskAssessmentReducers);
    const category_ids = category?.map((category) => category?.id);

    return (
        <div className="flex items-center gap-2">
            <img src="/img/risk-assessment/filter-lines.svg" alt="Filter Icon" className="object-contain" />

            <div>
                <button
                    aria-controls={templateTypeAnchorEl ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={templateTypeAnchorEl ? 'true' : undefined}
                    onClick={(event) => {
                        event.stopPropagation();
                        setTemplateTypeAnchorEl(event.currentTarget);
                    }}
                    className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 hover:bg-[#E2E8F0] transition-colors"
                >
                    <p className="text-[#64748B] font-medium text-[13px]">Template type: {templateType}</p>

                    <img
                        src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                        alt="Arrow Down Icon"
                        className="object-contain"
                    />
                </button>

                <Menu
                    anchorEl={templateTypeAnchorEl}
                    open={Boolean(templateTypeAnchorEl)}
                    onClose={() => setTemplateTypeAnchorEl(null)}
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '4px',
                            boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                        },
                        '& .MuiList-root': {
                            padding: 0,
                        },
                    }}
                >
                    {templateTypes.map((type, index) => (
                        <MenuItem
                            key={index}
                            onClick={(event) => {
                                event.stopPropagation();
                                setTemplateType(type);
                                setTemplateTypeAnchorEl(null);
                            }}
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                borderBottom: `1px solid ${
                                    index === templateTypes.length - 1 ? 'transparent' : '#F1F5F9'
                                }`,
                                padding: '8px 16px',
                            }}
                        >
                            {type}
                        </MenuItem>
                    ))}
                </Menu>
            </div>

            <div>
                <button
                    aria-controls={categoryAnchorEl ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={categoryAnchorEl ? 'true' : undefined}
                    onClick={(event) => {
                        event.stopPropagation();
                        setCategoryAnchorEl(event.currentTarget);
                    }}
                    className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 hover:bg-[#E2E8F0] transition-colors"
                >
                    <p className="text-[#64748B] font-medium text-[13px]">
                        Category:{' '}
                        {category?.length === 0
                            ? 'None'
                            : category?.length === risk_assessment_categories?.length
                            ? 'All'
                            : `${category.length} selected`}
                    </p>

                    <img
                        src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                        alt="Arrow Down Icon"
                        className="object-contain"
                    />
                </button>

                <Menu
                    anchorEl={categoryAnchorEl}
                    open={Boolean(categoryAnchorEl)}
                    onClose={() => setCategoryAnchorEl(null)}
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '4px',
                            boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                        },
                        '& .MuiList-root': {
                            padding: 0,
                        },
                    }}
                >
                    {risk_assessment_categories.map((data, index) => (
                        <MenuItem
                            key={index}
                            onClick={(event) => {
                                event.stopPropagation();

                                if (category_ids?.includes(data?.id)) {
                                    setCategory(category?.filter((item) => item?.id !== data?.id));
                                } else {
                                    setCategory([...category, data]);
                                }
                            }}
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                borderBottom: `1px solid ${
                                    index === risk_assessment_categories.length - 1 ? 'transparent' : '#F1F5F9'
                                }`,
                                padding: '8px 16px',
                            }}
                        >
                            <div className="flex items-center gap-1">
                                {category_ids.includes(data?.id) ? (
                                    <img
                                        src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                        alt="Checkbox icon"
                                        className="object-contain"
                                    />
                                ) : (
                                    <img
                                        src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                        alt="Checkbox icon"
                                        className="object-contain"
                                    />
                                )}

                                <p className="text-[#64748B] font-medium text-sm">{data?.name}</p>
                            </div>
                        </MenuItem>
                    ))}
                </Menu>
            </div>

            <div>
                <button
                    aria-controls={planAnchorEl ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={planAnchorEl ? 'true' : undefined}
                    onClick={(event) => {
                        event.stopPropagation();
                        setPlanAnchorEl(event.currentTarget);
                    }}
                    className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 hover:bg-[#E2E8F0] transition-colors"
                >
                    <p className="text-[#64748B] font-medium text-[13px]">
                        Plan:{' '}
                        {plan.length === 0 ? 'None' : plan.length === plans.length ? 'All' : `${plan.length} selected`}
                    </p>

                    <img
                        src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                        alt="Arrow Down Icon"
                        className="object-contain"
                    />
                </button>

                <Menu
                    anchorEl={planAnchorEl}
                    open={Boolean(planAnchorEl)}
                    onClose={() => setPlanAnchorEl(null)}
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '4px',
                            boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                        },
                        '& .MuiList-root': {
                            padding: 0,
                        },
                    }}
                >
                    {plans.map((name, index) => (
                        <MenuItem
                            key={index}
                            onClick={(event) => {
                                event.stopPropagation();

                                if (plan.includes(name)) {
                                    setPlan(plan.filter((item) => item !== name));
                                } else {
                                    setPlan([...plan, name]);
                                }
                            }}
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                borderBottom: `1px solid ${index === plans.length - 1 ? 'transparent' : '#F1F5F9'}`,
                                padding: '8px 16px',
                            }}
                        >
                            <div className="flex items-center gap-1">
                                {plan.includes(name) ? (
                                    <img
                                        src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                        alt="Checkbox icon"
                                        className="object-contain"
                                    />
                                ) : (
                                    <img
                                        src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                        alt="Checkbox icon"
                                        className="object-contain"
                                    />
                                )}

                                <p className="text-[#64748B] font-medium text-sm">{name}</p>
                            </div>
                        </MenuItem>
                    ))}
                </Menu>
            </div>

            {/* <div>
                <button
                    aria-controls={residualRiskAnchorEl ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={residualRiskAnchorEl ? 'true' : undefined}
                    onClick={(event) => {
                        event.stopPropagation();
                        setResidualRiskAnchorEl(event.currentTarget);
                    }}
                    className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 hover:bg-[#E2E8F0] transition-colors"
                >
                    <p className="text-[#64748B] font-medium text-[13px]">
                        Residual risk:{' '}
                        {residualRisk.length === 0
                            ? 'None'
                            : residualRisk.length === residual5x5Risks.length
                            ? 'All'
                            : `${residualRisk.length} selected`}
                    </p>

                    <img
                        src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                        alt="Arrow Down Icon"
                        className="object-contain"
                    />
                </button>

                <Menu
                    anchorEl={residualRiskAnchorEl}
                    open={Boolean(residualRiskAnchorEl)}
                    onClose={() => setResidualRiskAnchorEl(null)}
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '4px',
                            boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                        },
                        '& .MuiList-root': {
                            padding: 0,
                        },
                    }}
                >
                    {residual3x3Risks.map((name, index) => (
                        <MenuItem
                            key={index}
                            onClick={(event) => {
                                event.stopPropagation();

                                if (residualRisk.includes(name)) {
                                    setResidualRisk(residualRisk.filter((item) => item !== name));
                                } else {
                                    setResidualRisk([...residualRisk, name]);
                                }
                            }}
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                borderBottom: `1px solid ${
                                    index === residual3x3Risks.length - 1 ? 'transparent' : '#F1F5F9'
                                }`,
                                padding: '8px 16px',
                            }}
                        >
                            <div className="flex items-center gap-1">
                                {residualRisk.includes(name) ? (
                                    <img
                                        src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                        alt="Checkbox icon"
                                        className="object-contain"
                                    />
                                ) : (
                                    <img
                                        src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                        alt="Checkbox icon"
                                        className="object-contain"
                                    />
                                )}

                                <p className="text-[#64748B] font-medium text-sm">{name}</p>
                            </div>
                        </MenuItem>
                    ))}
                </Menu>
            </div> */}
        </div>
    );
};

export default RiskAction;
