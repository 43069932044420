import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

// core components
import Categories from './components/Criterias';
import SubCategory from './components/SubException';

// redux
import { GetAllCiiConditionalItems } from 'store/actions/adminActions';
import { GetAllCiiExceptions } from 'store/actions/complianceActions';
import { connect } from 'react-redux';

const Exceptions = (props) => {
    const { all_cii_exceptions, GetAllCiiExceptions, GetAllCiiConditionalItems } = props;

    // state
    const [view, setView] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [loading, setLoading] = useState({ content: false });

    // async functions
    const getAllExemptedCii = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllCiiExceptions();
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exceptions.");
            console.log('Error::::', res?.message);
        }
    };

    const getConditionalForms = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllCiiConditionalItems();
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };

    // logic functions
    const handleViewChange = (categoryId) => {
        setView(1);
        setSelectedCategory(categoryId);
    };
    const goBack = () => {
        setView(0);
        setSelectedCategory(null);
    };

    // useEffect
    useEffect(() => {
        getAllExemptedCii();
        getConditionalForms();
    }, []);
    const filtered_exceptions = all_cii_exceptions?.filter((exception) => exception?.id !== 2);
    return view === 0 ? (
        <Categories loading={loading.content} onViewChange={handleViewChange} data={filtered_exceptions} exception />
    ) : (
        <SubCategory
            exception={selectedCategory}
            goBack={goBack}
            loading={loading}
            all_cii_exceptions={all_cii_exceptions}
            getAllExemptedCii={getAllExemptedCii}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        all_cii_exceptions: state?.complianceReducers?.all_cii_exceptions,
    };
};
export default connect(mapStateToProps, {
    GetAllCiiExceptions,
    GetAllCiiConditionalItems,
})(Exceptions);
