import React, { useState } from 'react';
import { toast } from 'react-toastify';
// core components
import { AppForm, AppFormInput, AppSubmitButton } from 'components/new_components/forms';
import { Stack, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//utils
import { codeValidation } from './utils/validation';
//translation
import { useTranslation } from 'react-i18next';

//redux
import { connect } from 'react-redux';
import { Finalize2FASetup } from 'store/actions/generalActions';
import { setType } from 'utils';

const AuthConnectionViewForm = (props) => {
    const { authType, goBack, Finalize2FASetup } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const theme = useTheme();

    const { t } = useTranslation('accounts');

    const submitCode = async (values) => {
        setIsSubmitting(true);
        const payload = {
            otp_code: values.code,
            mfa_type: authType,
        };
        const res = await Finalize2FASetup(payload);
        setIsSubmitting(false);
        if (res?.success) {
            setType('mfa', true);
            toast.success('Two Factor Authenticaton Activated');
            setTimeout(() => goBack(), 2000);
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <Box sx={{ width: '65%', px: 2.4, my: '2rem' }}>
            <Box>
                <Typography
                    sx={{
                        color: theme.palette.gray[30],
                        fontWeight: 700,
                        fontSize: '16px',
                    }}
                >
                    {authType === 'email'
                        ? t('twoFactorAuth.authConnectionView.authConnectionViewForm.header.email')
                        : t('twoFactorAuth.authConnectionView.authConnectionViewForm.header.authenticator')}
                </Typography>
                <Typography
                    sx={{
                        color: theme.palette.primary[700],
                        fontWeight: 500,
                        fontSize: '14px',
                    }}
                >
                    {authType === 'email'
                        ? t('twoFactorAuth.authConnectionView.authConnectionViewForm.subtitle.email')
                        : t('twoFactorAuth.authConnectionView.authConnectionViewForm.subtitle.authenticator')}
                </Typography>
            </Box>
            <AppForm
                initialValues={{
                    code: '',
                }}
                onSubmit={submitCode}
                validate={(values) => codeValidation(values)}
            >
                <AppFormInput
                    type="text"
                    placeholder={t('twoFactorAuth.authConnectionView.authConnectionViewForm.input.placeholder')}
                    name="code"
                    autoComplete="one-time-code"
                />
                <Stack direction="row" alignItems="center" sx={{ my: '2rem' }}>
                    <AppSubmitButton
                        text={t('twoFactorAuth.authConnectionView.authConnectionViewForm.cta')}
                        variant="contained"
                        color="primary"
                        loadingPosition="center"
                        loading={isSubmitting}
                        sx={{ width: '200px' }}
                    />
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: theme.palette.primary.main,
                            fontWeight: 600,
                            py: 1.25,
                            px: 1.6,
                            width: '200px',
                            background: 'transparent',
                            border: 'none',
                            outline: 'none',
                            borderRadius: '4px',
                            ml: '0.8rem',
                            // boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.1)',
                        }}
                        component="button"
                        onClick={goBack}
                    >
                        {t('twoFactorAuth.cancel')}
                    </Typography>
                </Stack>
            </AppForm>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { Finalize2FASetup })(AuthConnectionViewForm);
