import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Input, Row, Table, Tag } from 'antd';
import BreadCrumb from 'components/Breadcrumb';

import PlanRequestAction from './components/PlanRequestAction';

// redux
import { connect } from 'react-redux';
import { GetAllPlanRequests, GetAllPlans, GetAllCustomPlanRequests } from 'store/actions/subscriptionActions';
import { GetAllMerchants } from 'store/actions/adminActions';
import ViewMerchantDrawer from './components/ViewMerchantDrawer';

// utils
import { formatAmount, CurrencySign } from 'utils';
import ApproveRequestModal from './components/ApproveRequestModal';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import PlanModal from '../Plans/PlansOperations/PlanModal';
import useSearch from 'hooks/useSearch';

const PlanRequests = (props) => {
    const {
        GetAllMerchants,
        GetAllPlanRequests,
        all_merchants,
        all_plan_requests = [],
        GetAllPlans,
        all_plans,
        GetAllCustomPlanRequests,
        all_custom_plan_requests = [],
    } = props;

    // state
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState(null);
    const [requestModal, setRequestModal] = useState(false);
    const [customPlanModal, setCustomPlanModal] = useState(false);
    const [planRequests, setPlanRequests] = useState([]);

    // hooks
    const { data, handleSearch } = useSearch(
        planRequests,
        ['compliance_type', 'merchant.name', 'plan.name', 'plan.tag', 'company_size'],
        (record) => {
            const merchant = all_merchants?.find((merchant) => merchant?.id === record?.merchant);
            const plan = record?.tag ? record : getPlan(record?.plan);
            return { merchant, plan };
        }
    );

    // functions
    const getAllMerchants = async () => {
        setLoading(true);
        const res = await GetAllMerchants();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllPlanRequests = async () => {
        setLoading(true);
        const res = await GetAllPlanRequests();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllCustomPlanRequests = async () => {
        setLoading(true);
        const res = await GetAllCustomPlanRequests();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllPlans = async () => {
        setLoading(true);
        const res = await GetAllPlans();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const openViewDrawer = (record) => {
        setRecord(record);
        setVisible(true);
    };
    const closeViewDrawer = () => {
        setVisible(false);
    };
    const openRequestModal = (data) => {
        setRequestModal(true);
        setRecord(data);
    };
    const closeRequestModal = () => {
        setRequestModal(false);
        setRecord(null);
    };
    const openCustomPlanModal = (mode, data = {}) => {
        setCustomPlanModal(mode);
        setRecord(data);
    };
    const closeCustomPlanModal = () => {
        setCustomPlanModal(null);
        setRecord({});
    };
    // logic
    const getPlanTypeColor = (plan) => {
        switch (plan) {
            case 'main':
                return 'blue';
            case 'custom':
                return 'cyan';
            default:
                return 'volcano';
        }
    };
    const getPlan = (planId) => {
        return all_plans?.find((plan) => plan?.id === planId);
    };

    // useEffect
    useEffect(() => {
        getAllMerchants();
        getAllPlanRequests();
        getAllPlans();
        getAllCustomPlanRequests();
    }, []);
    useEffect(() => {
        setPlanRequests([...all_custom_plan_requests, ...all_plan_requests]);
    }, [all_custom_plan_requests, all_plan_requests]);

    // constant
    const columns = [
        {
            title: 'S/N',
            render: (record) => record?.index + 1,
        },
        {
            title: 'Subscriber Name',
            render: (record) => {
                const merchant = all_merchants?.find((merchant) => merchant?.id === record?.merchant);
                return <>{merchant?.name}</>;
            },
        },
        {
            title: 'Plan Name',
            render: (record) => {
                const plan = getPlan(record?.plan);
                return <>{plan?.name || 'Nil'}</>;
            },
        },
        {
            title: 'Plan Type',
            render: (record) => {
                const plan = record?.tag ? record : getPlan(record?.plan);
                return (
                    <Tag color={getPlanTypeColor(plan?.tag)} className="capitalize">
                        {plan?.tag}
                    </Tag>
                );
            },
            filters: [
                { text: 'Main', value: 'main' },
                { text: 'Custom', value: 'custom' },
                { text: 'Free', value: 'free' },
            ],
            onFilter: (value, record) => {
                const plan = record?.tag ? record : getPlan(record?.plan);
                return plan?.tag === value;
            },
        },
        {
            title: 'Comp. Standards',
            render: (record) => <>{record?.compliance_type}</>,
        },
        {
            title: 'Company Size',
            render: (record) => <>{record?.company_size}</>,
        },
        {
            title: 'Total Cost',
            render: (record) => <>{record?.total_cost ? CurrencySign + formatAmount(record?.total_cost) : 'N/A'}</>,
        },
        {
            title: 'Request Status',
            render: (record) => (
                <Tag color={record?.status === 'processing' ? 'gold' : 'green'} className="capitalize">
                    {record?.status === 'processing' ? 'pending' : record?.status}
                </Tag>
            ),
            filters: [
                { text: 'Approved', value: 'approved' },
                { text: 'Pending', value: 'processing' },
            ],
            onFilter: (value, record) => record?.status === value,
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => (
                <PlanRequestAction
                    record={record}
                    openViewDrawer={openViewDrawer}
                    openRequestModal={openRequestModal}
                    openCustomPlanModal={openCustomPlanModal}
                />
            ),
        },
    ];

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Plan Requests' }]} />
                </Col>
                <Col span={12} className="flex items-center">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                    <Button
                        onClick={() => openCustomPlanModal('Add')}
                        type="primary"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center ml-3"
                    >
                        Request Custom Plan
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(request) => request.index}
                    loading={loading}
                    columns={columns}
                    dataSource={data
                        ?.sort((a, b) => (b?.date_created > a?.date_created ? 1 : -1))
                        ?.map((feature, index) => ({
                            ...feature,
                            index,
                        }))}
                    pagination={{
                        total: data?.length,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                    }}
                />
            </section>
            <ViewMerchantDrawer visible={visible} closeViewDrawer={closeViewDrawer} record={record} />
            <ApproveRequestModal open={requestModal} handleClose={closeRequestModal} modalData={record} />
            <PlanModal mode={customPlanModal} handleClose={closeCustomPlanModal} modalData={record} type="custom" />
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        all_plan_requests: state?.subscriptionReducers?.all_plan_requests,
        all_merchants: state?.adminReducers?.all_merchants,
        all_plans: state?.subscriptionReducers?.all_plans,
        all_custom_plan_requests: state?.subscriptionReducers?.all_custom_plan_requests,
    };
};
export default connect(mapStateToProps, {
    GetAllPlanRequests,
    GetAllMerchants,
    GetAllPlans,
    GetAllCustomPlanRequests,
})(PlanRequests);
