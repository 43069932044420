import React from 'react';
import { Tabs } from '@mui/material';

const AppTabsBar = (props) => {
    const { currentTab, handleTabChange, sx, variant = 'scrollable', children, ...otherProps } = props;
    return (
        <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant={variant}
            sx={{
                '& .MuiTabs-indicator': {
                    display: 'none',
                },
                ...sx,
            }}
            {...otherProps}
        >
            {children}
        </Tabs>
    );
};

export default AppTabsBar;
