import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Input, Modal, Row, Button } from 'antd';

// redux
import { connect } from 'react-redux';
import { UpdateFAQItem, AddFAQItem } from 'store/actions/adminActions';

const FAQModal = (props) => {
    const { open, onClose, modalData, UpdateFAQItem, AddFAQItem } = props;

    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // functions
    const handleClose = () => {
        onClose();
        setData({});
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = open === 'Add' ? await AddFAQItem(data) : await UpdateFAQItem(data, data?.id);
        setLoading(false);
        if (res?.success) {
            toast.success('Item added.');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };
    const handleTextChange = (e) => {
        const { value, name } = e.target;
        setData({ ...data, [name]: value });
    };

    // useEffect
    useEffect(() => {
        if (open) {
            setData(modalData);
        }
    }, [open]);

    return (
        <Modal title={`${open} FAQ Item`} open={Boolean(open)} onCancel={handleClose} destroyOnClose footer={null}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24}>
                        <div className="form-group">
                            <label htmlFor="question">Question</label>
                            <Input
                                size="large"
                                name="question"
                                id="question"
                                onChange={handleTextChange}
                                value={data.question || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="form-group">
                            <label htmlFor="answer">Answer</label>
                            <Input.TextArea
                                size="large"
                                name="answer"
                                id="answer"
                                onChange={handleTextChange}
                                value={data.answer || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, { AddFAQItem, UpdateFAQItem })(FAQModal);
