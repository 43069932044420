import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import BreadCrumb from 'components/Breadcrumb';

//antd
import { Row, Col, Button, Empty, Card, Typography, Modal, Tooltip } from 'antd';
import { PlusOutlined, EditOutlined, EyeOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import CloudSecurityModal from '../IsoActions/CloudSecurityModal';

//redux
import { connect } from 'react-redux';
import { GetAllCloudSecurities, CreateAnnexNew, EditAnnexNew, DeleteAnnexNew } from 'store/actions/adminActions';

// sub-components
const { Title } = Typography;
const { confirm } = Modal;

const CloudSecurityView = (props) => {
    //state
    const [modalOpen, setModalOpen] = useState(null);
    const [oneReq, setOneReq] = useState({});

    //props
    const { GetAllCloudSecurities, CreateAnnexNew, EditAnnexNew, DeleteAnnexNew, all_cloud_securities } = props;
    //functions
    const openAddModal = (mode, data) => {
        setModalOpen(mode);
        setOneReq(data);
    };
    const closeAddModal = () => {
        setModalOpen(false);
    };

    const deleteAnnex = (id, category) => {
        confirm({
            title: 'Are you sure you want to delete this cloud security? Note: Deleting this cloud security means you will be deleting this cloud security, the documents, audit questions and merchant data associated with this cloud security',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteAnnexNew(id, category);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };
    useEffect(() => {
        const getAllCybersecurities = async () => {
            const res = await GetAllCloudSecurities();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllCybersecurities();
    }, []);
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[{ label: 'ISO27017 + 27032', link: '/iso27017+27032' }, { label: 'Cloud Securities' }]}
                    />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openAddModal('Add')}
                    >
                        Add Cybersecurity
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {all_cloud_securities && all_cloud_securities?.length > 0 ? (
                    <Row wrap gutter={24}>
                        {all_cloud_securities
                            ?.sort((a, b) => a.id - b.id)
                            ?.map((cloud_security, index) => (
                                <Col
                                    xs={24}
                                    md={12}
                                    lg={8}
                                    xxl={6}
                                    key={cloud_security.id}
                                    style={{ marginBottom: '1rem' }}
                                >
                                    <Card
                                        title={`#${index}`}
                                        loading={false}
                                        actions={[
                                            <Tooltip title="View this cloud security" color="blue">
                                                <Link
                                                    key="preview"
                                                    to={{
                                                        pathname: '/admin/iso27017+27032/cloud_security',
                                                        state: {
                                                            cloud_security,
                                                        },
                                                    }}
                                                >
                                                    <EyeOutlined key="preview" />
                                                </Link>
                                            </Tooltip>,
                                            <Tooltip title="Edit this cloud security" color="blue">
                                                <EditOutlined
                                                    key="edit"
                                                    onClick={() => openAddModal('Edit', cloud_security)}
                                                />
                                            </Tooltip>,
                                            <Tooltip title="Delete this cloud security" color="red">
                                                <DeleteOutlined
                                                    key="delete"
                                                    onClick={() =>
                                                        deleteAnnex(cloud_security.id, cloud_security.category)
                                                    }
                                                />
                                            </Tooltip>,
                                        ]}
                                    >
                                        <Title level={5} style={{ textAlign: 'center' }}>
                                            {cloud_security.title}
                                        </Title>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                ) : (
                    <Row justify="center">
                        <Col>
                            <Empty
                                description={
                                    <div>
                                        <h3>No Cybersecurity</h3>
                                        <span>Add a new Cybersecurity </span>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                )}
            </section>
            <CloudSecurityModal
                open={modalOpen}
                handleCancel={closeAddModal}
                CreateClause={CreateAnnexNew}
                EditClause={EditAnnexNew}
                one_req={oneReq}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_cloud_securities: state?.adminReducers?.all_cloud_securities,
    };
};

export default connect(mapStateToProps, {
    GetAllCloudSecurities,
    CreateAnnexNew,
    EditAnnexNew,
    DeleteAnnexNew,
})(CloudSecurityView);
