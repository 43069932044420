// import { MoreOutlined } from '@ant-design/icons';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Button, IconButton, Menu, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MoreVert } from '@mui/icons-material';
// import { Dropdown, Menu } from 'antd';
import React, { useState } from 'react';
import UploadModal from './UploadResultModal';
import DownloadModal from './DownloadReport';

// sub component

const SubcriteriaActions = (props) => {
    const theme = useTheme();
    const { row } = props;

    // // state
    const [anchor, setAnchor] = useState(null);
    const [open, setOpen] = useState('');

    // // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const closeModal = () => {
        setOpen(false);
    };

    const openUpload = () => {
        setOpen('upload');
    };
    const openDownloadModal = () => {
        setOpen('download');
    };
    return (
        <>
            <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <Menu elevation={10} open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                <Box
                    onClick={openUpload}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        textAlign: 'left',
                        padding: '0.5rem 1rem',
                        cursor: 'pointer',
                    }}
                >
                    <Button
                        variant="text"
                        startIcon={<FileUploadIcon sx={{ fontSize: 14, marginRight: 1 }} />}
                        disabled={row?.status === 'completed' ? true : false}
                    >
                        Upload Document
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        textAlign: 'left',
                        padding: '0.5rem 1rem',
                        cursor: 'pointer',
                    }}
                    onClick={openDownloadModal}
                >
                    <Typography
                        component={'h6'}
                        align="left"
                        sx={{ color: theme.palette.gray[900], fontWeight: 'bold' }}
                    >
                        Download Report
                    </Typography>
                    <Typography sx={{ color: theme.palette.gray[900], fontSize: 10 }} component={'p'}>
                        See the report of a code review
                    </Typography>
                </Box>

                {open === 'upload' && <UploadModal open={open} onClose={closeModal} modalData={row} />}
                {open === 'download' && <DownloadModal open={open} handleClose={closeModal} row={row} />}
            </Menu>
        </>
    );
};

export default SubcriteriaActions;
