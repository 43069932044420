import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import AuthNotificationBar from 'views/merchant/AccountSetup/TwoFactorAuth/AuthNotificationBar';
import AuthMethods from 'views/merchant/AccountSetup/TwoFactorAuth/AuthMethods';
import AuthControlSwitch from 'views/merchant/AccountSetup/TwoFactorAuth/AuthControlSwitch';
import AuthConnectionView from 'views/merchant/AccountSetup/TwoFactorAuth/AuthConnectionView';
import AuthChangeConfirmModal from 'views/merchant/AccountSetup/TwoFactorAuth/Components/AuthChangeConfirmModal';
import ConfirmPasswordModal from 'views/merchant/AccountSetup/TwoFactorAuth/Components/ConfirmPasswordModal';

//react-redux
import { connect } from 'react-redux';
import { GetUserDetails, UpdateUserDetails } from 'store/actions/generalActions';
import { toast } from 'react-toastify';

const TwoFactorAuth = (props) => {
    const [authType, setAuthType] = useState('');
    const [toggled, setToggled] = useState();
    const [confirmChangeModalOpen, setConfirmChangeModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [confirmPasswordModalOpen, setConfirmPasswordModalOpen] = useState(false);

    const { GetUserDetails, UpdateUserDetails, mfa, mfa_type } = props;

    const handleConnect = (type) => {
        if (mfa && mfa_type) {
            openConfirmChangeModal(type);
        } else {
            openAuthConnectionView(type);
        }
    };
    const handleDisconnect = (type) => {
        let typeOpposite = type === 'email' ? 'authenticator' : 'email';
        openConfirmChangeModal(typeOpposite);
    };
    const handleToggleSwitch = () => {
        openConfirmPasswordModal();
    };

    const handleTwoFASwitch = async () => {
        const res = await UpdateUserDetails({ mfa: !toggled });
        if (res?.success) {
            setToggled(!toggled);
            toast.success(`Two Factor Authentication turned ${!toggled === true ? 'on' : 'off'}`);
        } else {
            toast.error(res?.message);
        }
    };
    const goBack = () => {
        setAuthType('');
        setModalType('');
    };
    const openConfirmChangeModal = (type) => {
        setConfirmChangeModalOpen(true);
        setModalType(type);
    };
    const closeConfirmChangeModal = () => {
        setConfirmChangeModalOpen(false);
    };
    const openAuthConnectionView = (type) => {
        setAuthType(type);
    };
    const openConfirmPasswordModal = () => {
        setConfirmPasswordModalOpen(true);
    };
    const closeConfirmPasswordModal = () => {
        setConfirmPasswordModalOpen(false);
    };

    //async actions
    useEffect(() => {
        const getUserDetails = async () => {
            const res = await GetUserDetails();
            if (!res.success) {
                toast.error(res?.message);
            }
        };
        getUserDetails();
    }, []);

    useEffect(() => {
        if (mfa) {
            setToggled(mfa);
        }
    }, [mfa]);

    return (
        <>
            {!authType && (
                <Box sx={{ m: 2 }}>
                    {!mfa && mfa_type ? <AuthNotificationBar /> : null}
                    <AuthControlSwitch checked={toggled} onChange={handleToggleSwitch} mfa={mfa} mfa_type={mfa_type} />
                    <AuthMethods onClick={handleConnect} handleDisconnect={handleDisconnect} mfa_type={mfa_type} />
                </Box>
            )}
            <Box>{authType && <AuthConnectionView authType={authType} goBack={goBack} mfa_type={mfa_type} />}</Box>
            <AuthChangeConfirmModal
                open={confirmChangeModalOpen}
                handleClose={closeConfirmChangeModal}
                title={`You are about to change ${
                    modalType === 'authenticator' ? 'Email Authenticaton' : 'Google Authenticator(TOTP)'
                } to ${modalType === 'authenticator' ? 'Google Authenticator(TOTP)' : 'Email Authentication'}`}
                subtitle={`Please note that, by doing this, ${
                    modalType !== 'authenticator' ? 'Google Authenticator(TOTP)' : 'Email Authenticaton'
                } won’t work anymore.`}
                openConfirmPasswordModal={openConfirmPasswordModal}
            />
            <ConfirmPasswordModal
                open={confirmPasswordModalOpen}
                handleClose={closeConfirmPasswordModal}
                type={modalType}
                openAuthConnectionView={openAuthConnectionView}
                handleTwoFASwitch={handleTwoFASwitch}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        mfa: state?.generalReducers?.user_info?.mfa,
        mfa_type: state?.generalReducers?.user_info?.mfa_type,
    };
};
export default connect(mapStateToProps, { GetUserDetails, UpdateUserDetails })(TwoFactorAuth);
