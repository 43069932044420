import React from 'react';

//mui component
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import AppChip from './AppChip';

const AppChipGroup = (props) => {
    const theme = useTheme();
    const { multiple, chipGroup, label, selectedChips, onTouch, onChange, name, isClickable } = props;

    const handleChange = (e) => {
        onTouch();
        onChange(e);
    };

    return (
        <Box>
            <Typography sx={{ color: theme.palette.gray[900], fontSize: 14, mt: 1.5, mb: 0.5 }}>{label}</Typography>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ flexWrap: 'wrap', width: 'fit-content' }}>
                {chipGroup?.map((chip) => {
                    return (
                        <AppChip
                            label={chip.name || chip}
                            id={chip.value || chip}
                            key={chip.value || chip}
                            onChange={handleChange}
                            selected={
                                multiple
                                    ? selectedChips?.includes(chip.value || chip)
                                    : selectedChips === (chip.value || chip)
                            }
                            type={multiple ? 'checkbox' : 'radio'}
                            name={name}
                            isClickable={isClickable}
                        />
                    );
                })}
            </Stack>
        </Box>
    );
};

export default AppChipGroup;
