import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

import AppCenteredModalNew from 'components/new_components/AppCenteredModalNew';
import CloseIcon from '@mui/icons-material/Close';

//translations
import { useTranslation } from 'react-i18next';
import { UpdateSelfServiceData } from 'store/actions/generalActions';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

const SuggestionModal = (props) => {
    const { open, handleClose, suggestionComment, setSuggestionComment, details, params } = props;

    // state
    const [loading, setLoading] = useState(false);

    //translation
    const { t } = useTranslation('riskAssessment');

    // Redux
    const dispatch = useDispatch();

    const onClose = () => {
        setSuggestionComment('');
        handleClose();
    };

    const handleSubmit = async () => {
        setLoading(true);
        const body = {
            risk_assessment_id: details?.id,
            risk_assessment_status: 'suggested',
            comment: suggestionComment,
        };
        const res = await dispatch(UpdateSelfServiceData(body, params));
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message || 'Risk resolved successfully');
            onClose();
        } else {
            toast.error(res?.message || 'Something went wrong');
        }
    };

    return (
        <AppCenteredModalNew
            open={open}
            handleClose={handleClose}
            noShadow
            noClose
            title={
                <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                    {' '}
                    {t('suggestionModal.title')}
                </Typography>
            }
            headerAction={
                <Box
                    sx={{
                        border: '1px solid #E2E8F0',
                        borderRadius: 30,
                        width: 30,
                        height: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon sx={{ width: 20, height: 20 }} />
                </Box>
            }
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        mt: 1,
                        backgroundColor: '#F8FAFC',
                        height: '60px',
                        pb: '-2rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit', color: '#334155' }}
                        onClick={handleClose}
                    >
                        {t('suggestionModal.cancel')}
                    </Button>
                    <LoadingButton
                        variant="contained"
                        sx={{
                            textTransform: 'inherit',
                            fontWeight: 600,
                        }}
                        loading={loading}
                        loadingPosition="center"
                        onClick={handleSubmit}
                        disabled={!suggestionComment}
                    >
                        Send
                    </LoadingButton>
                </Box>
            }
            width="420px"
            height="320px"
        >
            <Box sx={{ mt: 2 }}>
                <div>
                    <p className="text-[#64748B] text-sm font-medium mb-1.5">{t('suggestionModal.description')}</p>
                    <textarea
                        rows={3}
                        placeholder="Comment"
                        value={suggestionComment}
                        onChange={(event) => setSuggestionComment(event?.target?.value)}
                        className="text-[#64748B] text-sm font-normal placeholder:text-gray-400 border border-gray-200 py-2 rounded-[4px] w-full px-2"
                    />
                </div>
            </Box>
        </AppCenteredModalNew>
    );
};

export default SuggestionModal;
