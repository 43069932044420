import React from 'react';
import { Stack } from '@mui/material';

const RowContainer = ({ children }) => {
    return (
        <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{ border: '1px solid #E1E2EC', borderRadius: '4px', p: '8px 24px', mb: 1.5 }}
        >
            {children}
        </Stack>
    );
};

export default RowContainer;
