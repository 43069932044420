import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import { Button, Drawer, Popconfirm, Row, Spin } from 'antd';

// redux
import { connect } from 'react-redux';
import { UpdateAuditRequest } from 'store/actions/auditActions';

const ROCPreview = (props) => {
    const { open, handleClose, modalData, UpdateAuditRequest, all_merchants } = props;

    // states
    const [iFrameLoading, setIFrameLoading] = useState(true);

    // memos
    const merchant = useMemo(() => {
        return all_merchants?.find((merchant) => merchant?.id === modalData?.merchant);
    }, [all_merchants, modalData]);

    // functions
    const closeAll = () => {
        handleClose();
        setIFrameLoading(false);
    };
    const signROC = async () => {
        const res = await UpdateAuditRequest({ admin_signature: true, merchant: merchant?.id }, modalData?.id);
        if (res?.success) {
            return toast.success(
                'The auditor signature has been attached to the ROC document, the merchant will be notified immediately.'
            );
        } else {
            return toast.error(res?.message);
        }
    };

    return (
        <>
            <Drawer
                title={`Preview ROC`}
                open={open}
                width="900px"
                getContainer={false}
                onClose={closeAll}
                destroyOnClose
                extra={
                    <Popconfirm
                        placement="bottomRight"
                        title="Do you want to attach the auditor's signature to this ROC?"
                        description={`This will send a notification to ${merchant?.name} to proceed with the audit.`}
                        onConfirm={signROC}
                        disabled={modalData?.admin_signature || !modalData?.merchant_signature}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" disabled={modalData?.admin_signature || !modalData?.merchant_signature}>
                            Sign ROC
                        </Button>
                    </Popconfirm>
                }
            >
                <>
                    {iFrameLoading && (
                        <Row justify="center">
                            <Spin size="large" />
                        </Row>
                    )}
                    <Box
                        sx={{
                            backgroundColor: '#ffffff',
                            width: '90%',
                            maxWidth: 800,
                            height: '100%',
                            m: 'auto',
                            display: iFrameLoading ? 'none' : 'block',
                        }}
                        component="iframe"
                        src={`https://docs.google.com/gview?url=${modalData?.spoolreport}&embedded=true`}
                        onLoad={() => setIFrameLoading(false)}
                    />
                </>
            </Drawer>
        </>
    );
};
const mapStateToProps = (state) => ({
    all_merchants: state?.adminReducers?.all_merchants,
});
export default connect(mapStateToProps, { UpdateAuditRequest })(ROCPreview);
