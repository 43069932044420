import { Button } from '@mui/material';
import React from 'react';

const ExceptionHeader = (props) => {
    const { active_tab, setActive_tab, handleResourceChange } = props;
    const tabs = [
        {
            index: 0,
            name: 'Documents',
            resource: 'doc',
        },
        {
            index: 1,
            name: 'Evidences',
            resource: 'evidence',
        },
        {
            index: 2,
            name: 'Audit questions',
            resource: 'form',
        },
    ];
    return (
        <div className="flex items-center gap-3">
            {tabs.map((tab, index) => (
                <Button
                    key={tab.index}
                    variant={index === active_tab ? 'contained' : 'outlined'}
                    sx={{
                        borderRadius: 5,
                        backgroundColor: index === active_tab ? '#395BA9' : '',
                        color: index === active_tab ? 'white' : '#475569',
                        border: index === active_tab ? '' : '1px solid #E2E8F0',
                        fontSize: '13px',
                        fontWeight: 400,
                        textTransform: 'inherit',
                        '&:hover': {
                            backgroundColor: index === active_tab ? '#395BA9' : '',
                            color: index === active_tab ? 'white' : '#475569',
                        },
                    }}
                    onClick={() => {
                        setActive_tab(index);
                        handleResourceChange(tab?.resource);
                    }}
                >
                    {tab.name}
                </Button>
            ))}
        </div>
    );
};

export default ExceptionHeader;
