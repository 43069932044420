import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

// core components
import Categories from './components/Criterias';
import SubCategory from './components/SubException';

// redux
import { GetAllConditionalItems } from 'store/actions/adminActions';
import { GetAllBlockchainExceptions } from 'store/actions/complianceActions';
import { connect } from 'react-redux';

const Exceptions = (props) => {
    const { all_blockchain_exception, GetAllBlockchainExceptions, GetAllConditionalItems } = props;

    // state
    const [view, setView] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [loading, setLoading] = useState({ content: false });

    // async functions
    const getAllExemptedBlockchain = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllBlockchainExceptions();
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exceptions.");
            console.log('Error::::', res?.message);
        }
    };

    const getConditionalForms = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllConditionalItems();
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };

    // logic functions
    const handleViewChange = (categoryId) => {
        setView(1);
        setSelectedCategory(categoryId);
    };
    const goBack = () => {
        setView(0);
        setSelectedCategory(null);
    };

    // useEffect
    useEffect(() => {
        getAllExemptedBlockchain();
        getConditionalForms();
    }, []);

    return view === 0 ? (
        <Categories
            loading={loading.content}
            onViewChange={handleViewChange}
            data={all_blockchain_exception}
            exception
        />
    ) : (
        <SubCategory
            exception={selectedCategory}
            goBack={goBack}
            loading={loading}
            all_blockchain_exception={all_blockchain_exception}
            getAllExemptedBlockchain={getAllExemptedBlockchain}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        all_blockchain_exception: state?.complianceReducers?.all_blockchain_exception,
    };
};
export default connect(mapStateToProps, {
    GetAllBlockchainExceptions,
    GetAllConditionalItems,
})(Exceptions);
