import { ALL_ASSIGNED_TASKS, ALL_ASSIGNED_TASKS_BY_STANDARD } from '../constants';

import axiosInstance from '../../utils/https';
import { GetUserDetails } from './generalActions';

// ALL ASSIGNED TASKS
export const GetAllTasks = () => async (dispatch, getState) => {
    const user_id = getState()?.authReducers?.user_id;
    try {
        const res = await axiosInstance().get(`/compliance/assign_task/?assigned_by=${user_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ASSIGNED_TASKS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const GetAllTasksByStandard = (standard, assigned_to) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(
            `/compliance/assign_task/?tag=compliance&standard=${standard}&assigned_to=${assigned_to}`
        );
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ASSIGNED_TASKS_BY_STANDARD, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const AssignComplianceTask = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/assign_task/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetUserDetails());
        dispatch(GetAllTasks());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const UpdateTask = (credentials, id, standard, userId) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/assign_task/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        if (standard) {
            dispatch(GetAllTasksByStandard(standard, userId));
        }
        dispatch(GetAllTasks());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const DeleteTask = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/assign_task/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllTasks());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
