import { ArrowBackIos } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import Light from 'assets/img/Light.svg';
import FolderIcon from 'assets/img/folder.svg';
import XslIcon from 'assets/img/xsl.svg';
import AppDrawer from 'components/new_components/AppDrawer';
import { AppForm, AppFormInput, AppFormSelect, AppSubmitButton } from 'components/new_components/forms';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { UploadResponse } from 'store/actions/vendorActions';
import { accept } from 'validate';
import { amlOptions, riskLevelOptions, vendorResponseInitialValues } from '../../utils/constants';
import { uploadVendorResponseValidation } from '../../utils/validation';
// import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import { useTheme } from '@mui/styles';
import { AppFormContext } from 'components/new_components/forms';

const ResponseDrawer = (props) => {
    const { open, handleClose, payload } = props;
    const dispatch = useDispatch();
    const theme = useTheme();

    const [loading, setLoading] = useState(false);
    const [responseDocument, setResponseDocument] = useState(null);
    const [values, setValues] = useState(payload);
    // const [is_aml_vendor, setis_aml_vendor] = useState(false);
    const [error, setError] = useState(false);
    const { excel } = accept;

    const dragEnter = () => {
        const target = document.getElementById('response-drop-zone');
        target.style.borderColor = '#C4C4C4';
        target.style.backgroundColor = '#A58980';
    };

    const dragLeave = () => {
        const target = document.getElementById('response-drop-zone');
        target.style.borderColor = '#A58980';
        target.style.backgroundColor = 'transparent';
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type.includes('image')) {
            const reader = new FileReader();
            reader.onload = () => {
                setResponseDocument(reader.result);
            };
            reader.readAsDataURL(file);
            convertedBase64(file).then(() => {});
        } else {
            // enqueueSnackbar('Invalid Data Dropped', { variant: 'error' });
        }
        dragLeave();
    };

    const uploadImage = async (event) => {
        const input = event.target;
        const file = input.files?.item(0);
        const fileSize = file.size / 1000 / 1000;
        if (fileSize > 2) {
            toast.info('File too large, minimum of 5MB');
            setResponseDocument('');
        } else {
            // const base64 = await convertedBase64(file);
            setResponseDocument(file);
            event.target.value = null;
        }
    };
    const convertedBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const filReader = new FileReader();
            filReader.readAsDataURL(file);
            filReader.onload = () => {
                resolve(filReader.result);
            };
            filReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        if (responseDocument === null) {
            setError(true);
            setLoading(false);
        } else {
            const formData = new FormData();
            formData.append('full_name', values.full_name);
            formData.append('service_description', values.service_description);
            formData.append('data_shared', values.data_shared);
            formData.append('products', values.products);
            formData.append('email', values.email);
            formData.append('is_aml_vendor', JSON.parse(values.is_aml_vendor));
            formData.append('file_upload', responseDocument);
            formData.append('risk_level', values?.risk_level);
            formData.append('min_risk_level', values?.min_risk_level);
            const res = await dispatch(UploadResponse(formData));
            setLoading(false);
            if (res?.success) {
                toast.success('Vendor responses Successfully uploaded');
                setResponseDocument(null);
                setValues(vendorResponseInitialValues);
                handleClose();
            } else {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    toast.error(res?.message);
                }
            }
        }
    };
    const onClose = () => {
        setResponseDocument(null);
        setValues(vendorResponseInitialValues);
        handleClose();
    };
    const getCurrentValues = (values) => setValues(values);

    return (
        <AppForm initialValues={values} onSubmit={handleSubmit} validate={uploadVendorResponseValidation}>
            <AppFormContext getValues={getCurrentValues}>
                <AppDrawer
                    open={open}
                    handleClose={onClose}
                    title={'Upload Response'}
                    icon={<ArrowBackIos sx={{ width: 20, height: 20, color: '#000000' }} />}
                    noShadow
                    noClose
                >
                    <Stack
                        sx={{
                            p: 2,
                            maxWidth: '480px',
                            height: '1100px',
                            maxHeigt: '1100px',
                            [theme.breakpoints.down('lg')]: {
                                height: '1100px',
                            },
                            overflowY: 'auto',
                        }}
                    >
                        <Stack
                            flexDirection="row"
                            sx={{
                                border: `1px solid #F0F0FA`,
                                backgroundColor: '#F8FAFC',
                                borderRadius: '4px',
                                p: '12px',
                            }}
                        >
                            <img src={Light} alt="file question" />
                            <Typography
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 400,
                                    fontSize: '13px',
                                    mt: 1,
                                    ml: 1,
                                }}
                            >
                                Use this option only when the vendor answered the <br /> vendor questionnaire in a
                                document sent to them by you.
                            </Typography>
                        </Stack>
                        <Box
                            sx={{
                                mt: 2,
                            }}
                        >
                            <div>
                                <p className="text-[13px] text-gray-500 font-medium">Vendor Name</p>
                                <AppFormInput type="text" name="full_name" placeholder="Vendor Name" sx={{ mt: -2 }} />
                            </div>
                            <div className="mt-3">
                                <p className="text-[13px] text-gray-500 font-medium">Vendor Email</p>
                                <AppFormInput type="text" name="email" placeholder="Vendor Email" sx={{ mt: -2 }} />
                            </div>
                            <div className="mt-3">
                                <p className="text-[13px] text-gray-500 font-medium">Data Shared</p>
                                <AppFormInput
                                    type="text"
                                    name="data_shared"
                                    placeholder="Data Shared"
                                    sx={{ mt: -2 }}
                                />
                            </div>
                            <div className="mt-3">
                                <p className="text-[13px] text-gray-500 font-medium">Products</p>
                                <AppFormInput type="text" name="products" placeholder="Products" sx={{ mt: -2 }} />
                            </div>
                            <div className="mt-3">
                                <p className="text-[13px] text-gray-500 font-medium">Risk Level</p>
                                <AppFormSelect
                                    name="risk_level"
                                    options={riskLevelOptions}
                                    defaultValue="Risk level"
                                    sx={{ height: '40px', mt: -2 }}
                                />
                            </div>

                            <div className="mt-3">
                                <p className="text-[13px] text-gray-500 font-medium">Minimum Risk Level</p>
                                <AppFormInput
                                    type="number"
                                    name="min_risk_level"
                                    placeholder="Vendor minimum risk alert level"
                                    sx={{ mt: -2 }}
                                />
                            </div>
                            <div className="mt-3">
                                <p className="text-[13px] text-gray-500 font-medium">Questionaire Type</p>
                                <AppFormSelect
                                    name="is_aml_vendor"
                                    options={amlOptions}
                                    defaultValue="Vendor Questionnaire Type"
                                    sx={{ height: '40px', mt: -2 }}
                                />
                            </div>
                            <AppFormInput
                                type="text"
                                name="service_description"
                                placeholder={'Description of service'}
                                multiline={true}
                                rows={2}
                            />
                        </Box>
                        <Box>
                            <Box
                                sx={{
                                    // border: `3px dashed ${errors[name] ? '#D91B1B' : theme.palette.primary[900]}`,
                                    border: '1px dashed #CBD5E1',
                                    borderRadius: 2,
                                    padding: 1,
                                    minHeight: 150,
                                    position: 'relative',
                                    mt: 2,
                                }}
                            >
                                <Box
                                    id="response-drop-zone"
                                    onDragEnter={dragEnter}
                                    onDragLeave={dragLeave}
                                    onDrop={handleDrop}
                                    onDragOver={dragOver}
                                    sx={{ height: '100%', p: 2 }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <img src={FolderIcon} alt={FolderIcon} />
                                        <Typography sx={{ color: '#64748B', fontSize: '12px', fontWeight: 500 }}>
                                            Drop your files or click to upload
                                        </Typography>
                                        <Typography sx={{ color: '#94A3B8', fontSize: '12px', fontWeight: 400 }}>
                                            Supported file types: EXCEL:
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}
                                    >
                                        <label htmlFor="response-input">
                                            <Box
                                                sx={{
                                                    border: '1px solid #E2E8F0',
                                                    cursor: 'pointer',
                                                    padding: 0.5,
                                                }}
                                            >
                                                <Typography sx={{ color: '#475569', fontWeight: 400, fontSize: 12 }}>
                                                    Browse
                                                </Typography>
                                            </Box>
                                        </label>
                                        <input
                                            type="file"
                                            accept={`${excel}`}
                                            onChange={(e) => uploadImage(e)}
                                            hidden
                                            id="response-input"
                                        />
                                    </Box>
                                    <input type="file" accept={`${excel}`} hidden id="response-input" />
                                    {/* {errors[name] && <FormHelperText error>{formatSentence(errors[name])}</FormHelperText>} */}
                                </Box>
                            </Box>
                            {error && !responseDocument && (
                                <Typography sx={{ fontSize: '10px', fontWeight: 400, color: '#D91B1B', mt: 0.5 }}>
                                    Please upload file
                                </Typography>
                            )}
                            {responseDocument && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mt: 1,
                                    }}
                                >
                                    <img src={XslIcon} alt="icon" />
                                    <Box sx={{ ml: 1 }}>
                                        <Typography
                                            sx={{
                                                color: '#475569',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                wordBreak: 'break-word',
                                            }}
                                        >
                                            {responseDocument.name}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}{' '}
                        </Box>
                    </Stack>
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            paddingLeft: '19rem',
                            backgroundColor: '#F8FAFC',
                            width: '100% !important',
                            py: 2.5,
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit' }}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <AppSubmitButton
                            text="Upload"
                            variant="contained"
                            color="primary"
                            loading={loading}
                            loadingPosition="center"
                            sx={{ borderRadius: 1 }}
                        />
                    </Box>
                </AppDrawer>
            </AppFormContext>
        </AppForm>
    );
};

export default ResponseDrawer;
