import React from 'react';
import { Tabs } from 'antd';
import { AiOutlineVideoCamera } from 'react-icons/ai';
import { HiOutlineDocumentAdd } from 'react-icons/hi';
import CourseVideos from './CourseVideos';
import CourseDocuments from './CourseDocuments';

const CourseResourcesView = () => {
    // const onChange = (key) => {
    //     console.log(key);
    // };
    const items = [
        {
            key: '1',
            label: (
                <span className="flex flex-row items-center gap-2">
                    <AiOutlineVideoCamera /> Videos
                </span>
            ),
            children: <CourseVideos />,
        },
        {
            key: '2',
            label: (
                <span className="flex flex-row items-center gap-2">
                    <HiOutlineDocumentAdd /> Documents
                </span>
            ),
            children: <CourseDocuments />,
        },
    ];
    return <Tabs defaultActiveKey="1" items={items} />;
};

export default CourseResourcesView;
