import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

//redux
import { connect } from 'react-redux';

//translations
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import CloseIcon from '@mui/icons-material/Close';
import { accept } from 'validate';
import { toast } from 'react-toastify';
import { AppForm } from 'components/new_components/forms';
import FolderIcon from 'assets/img/folder.svg';
import { AppFormSelect } from 'components/new_components/forms';
import { UploadMerchantDocument, ExemptMerchantDocument } from 'store/actions/complianceActions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AppSubmitButton } from 'components/new_components/forms';
import FileTypes from 'assets/img/Filetypes.svg';
import Docx from 'assets/img/docx.svg';
import Png from 'assets/img/png.svg';
import Xsl from 'assets/img/xsl.svg';
import Img from 'assets/img/img.svg';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import ReminderModal from './ReminderModal';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { RemoveReminder } from 'store/actions/complianceActions';
import LoadingState from 'components/new_components/LoadingState';
import { GetAllMerchantEmployees } from 'store/actions/generalActions';

const UploadDocumentModal = (props) => {
    const {
        open,
        handleClose,
        GetAllMerchantEmployees,
        all_employees,
        UploadMerchantDocument,
        standard,
        doc_id,
        user_id,
        RemoveReminder,
    } = props;
    // state
    const [loading, setLoading] = useState({ content: false, delete: false, submit: false });
    const [document, setDocument] = useState(null);
    const [notify_employees, setNotifyEmployees] = useState(false);
    const [reminderModal, setReminderModal] = useState(false);
    const [error, setError] = useState(false);
    const [reminders, setReminders] = useState([]);
    const [reminders_ids, setReminders_ids] = useState([]);
    const [values, setValues] = useState({
        approver: '',
    });

    const { excel, pdf, png, img, docx, txt, doc } = accept;

    // memos
    const employees = useMemo(() => {
        return all_employees?.map((user) => ({
            name:
                user?.user_detail?.first_name !== ''
                    ? `${user?.user_detail?.first_name} ${user?.user_detail?.last_name}`
                    : user?.user_detail?.email,
            value: user?.user_detail?.id,
        }));
    }, [all_employees]);

    const getAllEmployees = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAllMerchantEmployees();
        setLoading({ ...loading, content: false });
        if (!res?.status) {
            toast.error(res?.status);
        }
    };

    useEffect(() => {
        getAllEmployees();
    }, []);

    const dragEnter = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#C4C4C4';
        target.style.backgroundColor = '#A58980';
    };

    const dragLeave = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#A58980';
        target.style.backgroundColor = 'transparent';
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type.includes('image')) {
            const reader = new FileReader();
            reader.onload = () => {
                setDocument(reader.result);
            };
            reader.readAsDataURL(file);
            convertedBase64(file).then(() => {});
        } else {
            // enqueueSnackbar('Invalid Data Dropped', { variant: 'error' });
        }
        dragLeave();
    };

    const uploadImage = async (event) => {
        const input = event.target;
        const file = input.files?.item(0);
        const fileSize = file.size / 1000 / 1000;
        if (fileSize > 5) {
            toast.info('File too large, minimum of 5MB');
            setDocument('');
        } else {
            // const base64 = await convertedBase64(file);
            setDocument(file);
            event.target.value = null;
        }
    };
    const convertedBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const filReader = new FileReader();
            filReader.readAsDataURL(file);
            filReader.onload = () => {
                resolve(filReader.result);
            };
            filReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleSubmit = async (values) => {
        if (document === null) {
            setError(true);
        } else {
            const doc = new FormData();
            doc.append('document', document);
            doc.append('document_id', doc_id);
            doc.append('approved_by', values?.approver);
            doc.append('tag', standard);
            doc.append('notify_employees', notify_employees);
            doc.append('requested_by', user_id);
            doc.append('reminder_ids', JSON.stringify(reminders_ids));

            setLoading({ ...loading, submit: true });
            const res = await UploadMerchantDocument(doc);
            setLoading({ ...loading, submit: false });
            //handling response
            if (res?.success) {
                toast.success(res?.message);
                setDocument(null);
                setReminders([]);
                setReminders_ids([]);
                setValues({});
                handleClose();
            } else {
                toast.error(res?.message);
            }
        }
    };

    const handleRemoveReminder = async (index) => {
        setLoading({ ...loading, delete: true });
        const new_reminders = [...reminders];
        const new_reminders_ids = [...reminders_ids];
        const item_index = new_reminders.indexOf(index);
        new_reminders.splice(item_index, 1);
        new_reminders_ids.splice(item_index, 1);
        const res = await RemoveReminder(reminders_ids[index]);
        setLoading({ ...loading, delete: false });
        if (res?.success) {
            toast.success('Reminder removed successfully!');
            setReminders(new_reminders);
            setReminders_ids(new_reminders_ids);
        } else {
            toast.error(res?.message);
        }
    };
    const onClose = () => {
        setDocument(null);
        handleClose();
    };
    return (
        <AppForm initialValues={values} onSubmit={handleSubmit}>
            <AppCenteredModal
                open={open}
                handleClose={onClose}
                title={
                    <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                        Upload policy document
                    </Typography>
                }
                icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
                noShadow
                noClose
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center',
                            backgroundColor: '#F8FAFC',
                            py: 2,
                            pr: 3,
                            mx: '-2rem',
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit' }}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <AppSubmitButton
                            text="Submit"
                            variant="contained"
                            color="primary"
                            loading={loading.submit}
                            loadingPosition="center"
                        />
                    </Box>
                }
                sx={{ borderRadius: 1, overflowX: 'hidden' }}
                headerAction={
                    <Box
                        sx={{
                            border: '1px solid #E2E8F0',
                            borderRadius: 30,
                            width: 20,
                            height: 20,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={onClose}
                    >
                        <CloseIcon sx={{ width: 12, height: 12, color: '#334155' }} />
                    </Box>
                }
                width="550px"
                height="590px"
                maxHeight={'590px'}
            >
                <Box sx={{ my: 2 }}>
                    <Box
                        sx={{
                            // border: `3px dashed ${errors[name] ? '#D91B1B' : theme.palette.primary[900]}`,
                            border: '1px dashed #CBD5E1',
                            borderRadius: 2,
                            padding: 1,
                            minHeight: 150,
                            position: 'relative',
                            mt: 2,
                        }}
                    >
                        <Box
                            id="drop-zone"
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={handleDrop}
                            onDragOver={dragOver}
                            sx={{ height: '100%', p: 2 }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <img src={FolderIcon} alt={FolderIcon} />
                                <Typography sx={{ color: '#64748B', fontSize: '12px', fontWeight: 500, mt: 2 }}>
                                    Drop your files or click to upload
                                </Typography>
                                <Typography sx={{ color: '#94A3B8', fontSize: '12px', fontWeight: 400 }}>
                                    Supported file types: CSV, PDF, IMG, DOCX, PNG
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                                <label htmlFor="new-file-input">
                                    <Box
                                        sx={{
                                            border: '1px solid #E2E8F0',
                                            cursor: 'pointer',
                                            padding: 0.5,
                                        }}
                                    >
                                        <Typography sx={{ color: '#475569', fontWeight: 400, fontSize: 12 }}>
                                            Browse
                                        </Typography>
                                    </Box>
                                </label>
                                <input
                                    type="file"
                                    accept={`${excel},${pdf},${png},${img},${docx},${txt},${doc}`}
                                    onChange={(e) => uploadImage(e)}
                                    hidden
                                    id="new-file-input"
                                />
                            </Box>
                            <input
                                type="file"
                                accept={`${excel},${pdf},${png},${img},${docx},${txt},${doc}`}
                                hidden
                                id="new-file-input"
                            />
                        </Box>
                    </Box>
                    {error && !document && (
                        <Typography sx={{ fontSize: '10px', fontWeight: 400, color: '#D91B1B' }}>
                            Please add a document
                        </Typography>
                    )}
                    {document && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: 3,
                            }}
                        >
                            <img
                                src={
                                    document.name?.split('.').pop() === 'xlsx'
                                        ? Xsl
                                        : document.name?.split('.').pop() === 'docx'
                                        ? Docx
                                        : document.name?.split('.').pop() === 'doc'
                                        ? Docx
                                        : document.name?.split('.').pop() === 'png'
                                        ? Png
                                        : document.name?.split('.').pop() === 'img'
                                        ? Img
                                        : FileTypes
                                }
                                alt="PdfIcon"
                            />
                            <Box sx={{ ml: 1 }}>
                                <Typography sx={{ color: '#475569', fontWeight: 400, fontSize: '14px' }}>
                                    {document.name}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    <AppFormSelect
                        name="approver"
                        label={'Approver'}
                        options={employees}
                        defaultValue={'Select an approver'}
                        loading={loading.content}
                        required
                    />

                    <Typography sx={{ color: '#64748B', fontSize: '12px', fontWeight: 400, mt: 1 }}>
                        Policy needs to be approved by an approver who oversees policy enforcement
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: '#F0F0FA',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            my: 1.5,
                            py: 1,
                            px: 2,
                            borderRadius: 1,
                        }}
                    >
                        <Typography sx={{ color: '#64748B', fontWeight: 400, fontSize: '13px' }}>
                            The approver will receive an email notification about this request and will be able to
                            approve it once logged in.
                        </Typography>
                    </Box>
                    <Button
                        startIcon={<AddCircleIcon sx={{ color: '#395BA9' }} />}
                        variant="text"
                        sx={{
                            color: '#395BA9',
                            fontSize: '13px',
                            fontWeight: 500,
                            textTransform: 'inherit',
                            ml: -0.5,
                        }}
                        onClick={() => setReminderModal(true)}
                    >
                        Add a reminder
                    </Button>
                    {reminders?.length > 0 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mt: 1, width: '100%' }}>
                            <NotificationsActiveOutlinedIcon />
                            <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                {reminders?.map((reminder, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mx: 1,
                                            backgroundColor: '#F8FAFC',
                                            py: 0.5,
                                            px: 1,
                                        }}
                                    >
                                        <Typography sx={{ color: '#64748B', fontWeight: 400, fontSize: '14px' }}>
                                            {reminder}
                                        </Typography>
                                        {loading?.delete ? (
                                            <LoadingState size={15} />
                                        ) : (
                                            <CloseIcon
                                                sx={{ ml: 1, cursor: 'pointer' }}
                                                onClick={() => handleRemoveReminder(index)}
                                            />
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    )}
                    <AppCheckboxInput
                        name="notify_employees"
                        value={notify_employees}
                        onChange={(event) => setNotifyEmployees(event.target.checked)}
                        label={
                            <Typography sx={{ color: '#395BA9', fontWeight: 500, fontSize: '13px' }}>
                                Auto-notify employee for policy acceptance once approved
                            </Typography>
                        }
                    />
                </Box>
                <ReminderModal
                    open={reminderModal}
                    handleClose={() => setReminderModal(false)}
                    reminders={reminders}
                    setReminders={setReminders}
                    reminders_ids={reminders_ids}
                    setReminders_ids={setReminders_ids}
                    standard={standard}
                />
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        all_employees: state?.generalReducers?.all_employees,
        employee_id: state?.authReducers?.user_info?.employee?.id,
        user: state?.authReducers?.user_info,
        user_id: state?.authReducers?.user_id,
    };
};

export default connect(mapStateToProps, {
    GetAllMerchantEmployees,
    UploadMerchantDocument,
    ExemptMerchantDocument,
    RemoveReminder,
})(UploadDocumentModal);
