import React, { useState } from 'react';
import { toast } from 'react-toastify';
// redux
import { connect } from 'react-redux';
import { DeleteMerchantIntegration } from 'store/actions/integrationsActions';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { Box, Button, Popover, Stack, Typography, useTheme } from '@mui/material';
import { BsInfoCircle } from 'react-icons/bs';
import { CancelRounded } from '@mui/icons-material';
import AppLoadingButton from 'components/new_components/AppLoadingButton';

//translations
import { useTranslation } from 'react-i18next';

const DisconnectModal = (props) => {
    const theme = useTheme();

    // props
    const { open, handleClose, environments, DeleteMerchantIntegration } = props;

    // states
    const [deleting, setDeleting] = useState(false);
    const [popoverAnchor, setPopoverAnchor] = useState(false);
    const [record, setRecord] = useState({});

    //translation
    const { t } = useTranslation('setups');

    // functions
    const openPopover = (e, record = {}) => {
        setPopoverAnchor(e.currentTarget);
        setRecord(record);
    };
    const closePopover = () => {
        setPopoverAnchor(null);
        setRecord({});
    };
    const handleDisconnect = async (integration) => {
        setDeleting(true);
        const res = await DeleteMerchantIntegration(integration?.id);
        setDeleting(false);
        if (res?.success) {
            toast.success(`You have successfully disconnected ${integration?.name} from your dashboard`);
            closePopover();
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            headerAction={
                <button onClick={handleClose}>
                    <CancelRounded sx={{ color: theme.palette.neutral[60] }} />
                </button>
            }
            title={
                <Typography sx={{ color: theme.palette.primary[900], fontWeight: 700, fontSize: 16 }}>
                    {t('disconnectModal.title')}
                </Typography>
            }
            subtitle={
                <Typography
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        border: `1px solid ${theme.palette.primary[900] + '30'}`,
                        borderRadius: 1,
                        p: 1.8,
                        mt: 1.5,
                        mb: 3,
                        fontSize: 12,
                        color: theme.palette.neutral[50],
                        fontWeight: 400,
                        gap: 1.5,
                    }}
                >
                    <BsInfoCircle />
                    <span>{t('disconnectModal.subtitle')}</span>
                </Typography>
            }
            width={600}
            height="60%"
        >
            <Typography
                sx={{ color: theme.palette.neutral[50], fontWeight: 400, fontSize: 14, textDecoration: 'underline' }}
            >
                {t('disconnectModal.allEnvironments')}
            </Typography>
            <Stack>
                {environments?.map((environment) => {
                    return (
                        <Stack
                            key={environment?.id}
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                border: `1px solid ${theme.palette.gray[100] + 'ee'}`,
                                my: 1.5,
                                borderRadius: 2,
                                py: 1.5,
                                px: 3,
                            }}
                        >
                            <Stack>
                                <Typography
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: 16,
                                        color: theme.palette.primary[900],
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {environment?.name}
                                </Typography>
                            </Stack>
                            <Stack
                                component="button"
                                sx={{
                                    fontSize: 12,
                                    color: theme.palette.error[50],
                                    fontWeight: 500,
                                    border: 'none',
                                    gap: 0.5,
                                }}
                                direction="row"
                                alignItems="center"
                                onClick={(e) => openPopover(e, environment)}
                            >
                                <CancelRounded sx={{ fontSize: 16 }} />
                                <Box component="span">{t('disconnectModal.disconnect')}</Box>
                            </Stack>
                        </Stack>
                    );
                })}
            </Stack>
            <Popover
                open={Boolean(popoverAnchor)}
                anchorEl={popoverAnchor}
                onClose={closePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        border: `1px solid ${theme.palette.neutral[80]}`,
                        boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.1)',
                        p: 1.6,
                        borderRadius: 2,
                    },
                }}
            >
                <Typography sx={{ fontSize: 12, fontWeight: 500 }}>Disconnect {record?.name}?</Typography>
                <Stack direction="row" sx={{ mt: 1.5 }} spacing={1}>
                    <Button
                        sx={{
                            borderRadius: '90px',
                            fontSize: 12,
                            textTransform: 'capitalize',
                            minWidth: '30px',
                        }}
                        onClick={closePopover}
                    >
                        {t('disconnectModal.no')}
                    </Button>
                    <AppLoadingButton
                        sx={{
                            borderRadius: '90px',
                            fontSize: 12,
                            minWidth: '30px',
                        }}
                        color="error"
                        variant="contained"
                        onClick={() => handleDisconnect(record)}
                        text={t('disconnectModal.yes')}
                        loading={deleting}
                    />
                </Stack>
            </Popover>
        </AppCenteredModal>
    );
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps, { DeleteMerchantIntegration })(DisconnectModal);
