import {
    ALL_TECH_SEC_METRICS,
    ALL_TECH_SEC_GROUPS,
    ALL_TECH_SEC_DOCUMENTS,
    ALL_TECH_SEC_CATEGORIES,
} from 'store/constants';
import axiosInstance from '../../utils/https';
import { ALL_TECH_SEC_FORMS } from 'store/constants';

// I S O 2 7 0 0 1 + I S O 2 7 0 3 5
export const GetTechSecMetrics = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/tech_sec_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_TECH_SEC_METRICS, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAllTechCategories = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/tech_sec/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_TECH_SEC_CATEGORIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllTechSecgroups = (category) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/tech_sec/?category=${category}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_TECH_SEC_GROUPS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllDocumentsByTechSec = (tech_sec) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?tech_sec=${tech_sec}&tags=tech_sec`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_TECH_SEC_DOCUMENTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            documents: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllFormsByTechSec = (tech_sec) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?tech_sec=${tech_sec}&tags=tech_sec`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: ALL_TECH_SEC_FORMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
            forms: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
