import { validation } from 'validate';

export const trainingValidation = (values) => {
    const errors = {};
    const employeeValidate = validation(values.employees, 'Employees', true);

    if (!employeeValidate.isValid) {
        errors.employees = employeeValidate.errorMessage;
    }

    return errors;
};
