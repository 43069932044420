import React, { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { ErrorOutline, FileDownload } from '@mui/icons-material/';

import AppButton from 'components/new_components/AppButton';
import AppCard from 'components/new_components/AppCard';

import PlayIcon from 'assets/img/PlayIcon.svg';
import FileIcon from 'assets/img/FileIcon.svg';
import calendarIcon from 'assets/img/calendarIcon.svg';
import EmptyComponent from 'components/EmptyComponents/EmptyComponent';
import { formatDateObjectHandler } from 'utils';
import { toast } from 'react-toastify';
import LoadingState from 'components/new_components/LoadingState';
import { GetDownloadLinkUnauthorized } from 'store/actions/generalActions';

const AcknowledgementPolicy = (props) => {
    const { policies, handleAcknowledge, check, id, loading } = props;
    const [loadingLink, setLoadingLink] = useState({ id: null, loading: false });
    const theme = useTheme();

    const getDownloadLink = async (id) => {
        if (id && !loadingLink.loading) {
            setLoadingLink({ id, loading: true });
            const res = await GetDownloadLinkUnauthorized('compliance', 'onboarding', id)();
            setLoadingLink({ id, loading: false });
            if (res?.success) {
                // open decoded url
                window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
            }
        }
    };

    return (
        <>
            <Grid item md={7} lg={7}>
                <Stack>
                    <Box
                        sx={{
                            fontSize: '12px',
                            color: theme.palette.gray[40],
                            fontWeight: 400,
                            border: '0.3px solid #C7C6CA',
                            p: 0.5,
                            mx: 1,
                            my: 1,
                            mb: '45px',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <ErrorOutline color="#77777A" fontSize="small" />
                        <Typography sx={{ fontSize: '12px', color: '#77777A', marginLeft: '10px' }}>
                            Watch the video, download and read the document, and acknowledge them all at once by
                            clicking the acknowledge <br /> button below of this section. You can only acknowledge these
                            policies once.
                        </Typography>
                    </Box>
                    {policies?.find((policy) => !policy?.is_deactivated) ? (
                        policies
                            ?.filter((policy) => !policy?.is_deactivated)
                            ?.map((policy) => {
                                return (
                                    <React.Fragment key={policy?.id}>
                                        <AppCard
                                            key={policy?.id}
                                            sx={{
                                                p: 3,
                                                display: 'flex',
                                                alignItems: 'center',
                                                mb: '25px',
                                            }}
                                        >
                                            {policy?.tag === 'document' ? (
                                                <Box sx={{ mr: '25px' }}>
                                                    <img src={FileIcon} alt="File Icon" />
                                                </Box>
                                            ) : (
                                                <Box sx={{ mr: '25px' }}>
                                                    <img src={PlayIcon} alt="Play Icon" />
                                                </Box>
                                            )}
                                            <Stack>
                                                <Typography
                                                    sx={{
                                                        color: theme.palette.primary[900],
                                                        fontSize: '18px',
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {policy?.name}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: '#77777A',
                                                        fontSize: '12px',
                                                        fontWeight: 500,
                                                        mt: '15px',
                                                        mb: '55px',
                                                    }}
                                                >
                                                    {policy?.description}
                                                </Typography>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={calendarIcon} alt="calendar icon" />
                                                        <Typography
                                                            sx={{
                                                                color: '#77777A',
                                                                fontSize: '12px',
                                                                fontWeight: 400,
                                                                ml: '8px',
                                                                mr: '30px',
                                                            }}
                                                        >
                                                            {formatDateObjectHandler(
                                                                policy?.date_created,
                                                                'DD-MM-YYYY'
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                    <AppButton
                                                        icon={
                                                            <Stack
                                                                flexDirection="row"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                {loadingLink.loading &&
                                                                    loadingLink.id === policy.id && (
                                                                        <LoadingState size={13} styles={{ mr: 1.5 }} />
                                                                    )}
                                                                <FileDownload />
                                                            </Stack>
                                                        }
                                                        name="Download"
                                                        variant="contained"
                                                        color="white"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        sx={{
                                                            height: '20px',
                                                            border: '1px solid #C7C6CA',
                                                            borderRadius: '20px',
                                                            p: 2,
                                                            bacgroundColor: '#ffffff',
                                                            color: '#77777A',
                                                            boxShadow: '0 0 0 0 !important',
                                                        }}
                                                        onClick={() => getDownloadLink(policy?.id)}
                                                    />
                                                </Box>
                                            </Stack>
                                        </AppCard>
                                    </React.Fragment>
                                );
                            })
                    ) : (
                        <EmptyComponent text="No policy document have been uploaded yet. Check again later." />
                    )}
                    <AppButton
                        name="Acknowledge"
                        color="primary"
                        variant="contained"
                        onClick={() => handleAcknowledge({ policy: true })}
                        loading={loading}
                        disabled={check || id?.toString()?.length <= 4}
                        sx={{
                            width: '215px',
                            height: '55px',
                            mb: '20px',
                        }}
                    />
                </Stack>
            </Grid>
        </>
    );
};

export default AcknowledgementPolicy;
