export const TABS = [
    { name: 'Policy Documents', id: 0 },
    { name: 'Awareness Videos', id: 1 },
];

export const notifyOptions = [
    {
        value: true,
        label: {
            title: 'Yes, inform my employees to accept updated policy',
            description:
                'Use this option only if you have made substantial changes that may constitute a new addition to the existing policy.',
        },
    },
    {
        value: false,
        label: {
            title: 'No, don’t inform my employees to accept updated policy',
            description:
                'Use this option only if you have made minor changes like grammatical error and typos which doesn’t affect the policy.',
        },
    },
];
