import React, { useMemo, useState } from 'react';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { IconButton, Typography, Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { AppForm, AppSubmitButton } from 'components/new_components/forms';
import { CreateScanException } from 'store/actions/automatedScansActions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { AppFormInput } from 'components/new_components/forms';
import { assignmentModalValidation } from '../../utils/validation';
import { AppFormSelect } from 'components/new_components/forms';
import { AssignAutomatedScan } from 'store/actions/automatedScansActions';

const AssignTaskModal = (props) => {
    const {
        open,
        onClose,
        category,
        type,
        AssignAutomatedScan,
        sub_category,
        scans,
        all_employees,
        // user_id,
        all_assigned_task,
    } = props;

    const theme = useTheme();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values, action) => {
        const category_payload = {
            assigned_to: values?.assigned_to?.map((assignee) => JSON.parse(assignee)),
            comments: values.comment,
            tag: 'automated_scan',
            category: category?.id,
            mark_as_completed: false,
        };
        const sub_category_payload = {
            assigned_to: values?.assigned_to?.map((assignee) => JSON.parse(assignee)),
            comments: values.comment,
            tag: 'automated_scan',
            subcategory: sub_category?.id,
            mark_as_completed: false,
        };
        const scan_payload = {
            assigned_to: values?.assigned_to?.map((assignee) => JSON.parse(assignee)),
            comments: values.comment,
            tag: 'automated_scan',
            scans: scans?.id,
            mark_as_completed: false,
        };
        const body = category ? category_payload : sub_category ? sub_category_payload : scan_payload;
        setLoading(true);
        const res = await AssignAutomatedScan(body);
        setLoading(false);
        if (res?.success) {
            action.resetForm();
            toast.success(res.message);
            onClose();
        } else {
            toast.error(res?.message);
        }
    };

    const modified_users = all_employees?.map((user) => {
        return {
            name: user?.user_detail?.email,
            value: user?.id,
        };
    });

    const find_category_task = all_assigned_task?.find((task) => task?.category === category?.id);
    const find_sub_category_task = all_assigned_task?.find((task) => task?.subcategory === sub_category?.id);
    const find_scans = all_assigned_task?.find((task) => task?.scans === scans?.id);

    const findCategoryUsers = useMemo(() => {
        return modified_users?.filter((user) => {
            return !find_category_task?.assigned_to?.includes(user.value);
        });
    }, [modified_users, find_category_task]);

    const findSubCategoryUsers = useMemo(() => {
        const concat_assigned_to = find_category_task?.assigned_to.concat(find_sub_category_task?.assigned_to || []);
        return modified_users?.filter((user) => {
            return !concat_assigned_to?.includes(user.value);
        });
    }, [modified_users, find_sub_category_task, find_category_task]);

    const findScansUsers = useMemo(() => {
        const concat_assigned_to = find_category_task?.assigned_to.concat(
            find_sub_category_task?.assigned_to || [],
            find_scans?.assigned_to || []
        );
        return modified_users?.filter((user) => {
            return !concat_assigned_to?.includes(user.value);
        });
    }, [modified_users, find_scans, find_sub_category_task, find_category_task]);

    const filtered_users =
        type === 'Scan constraint'
            ? findCategoryUsers
            : type === 'Scan Sub-constraint'
            ? findSubCategoryUsers
            : findScansUsers;

    return (
        <AppForm
            initialValues={{
                assigned_to: '',
                comment: '',
            }}
            validate={assignmentModalValidation}
            onSubmit={handleSubmit}
        >
            <AppCenteredModal
                open={open}
                handleClose={onClose}
                title={
                    <Typography sx={{ fontSize: '18px', fontWeight: 700, color: theme.palette.gray[30] }}>
                        {`Assign ${type} to:`}
                    </Typography>
                }
                headerAction={
                    <IconButton color="disabled" onClick={onClose}>
                        <Close />
                    </IconButton>
                }
                titleStyle={{ borderBottom: `1px solid ${theme.palette.neutral[90]}` }}
                width={540}
                noBorder
            >
                <Box sx={{ mb: 3 }}>
                    <AppFormSelect
                        label={'User'}
                        multiple
                        name="assigned_to"
                        options={filtered_users}
                        placeholder={'Select User'}
                    />
                    <AppFormInput type="text" name="comment" placeholder="Comment" multiline rows={2} />
                    <AppSubmitButton
                        text={'Assign'}
                        variant="contained"
                        color="primary"
                        loading={loading}
                        loadingPosition="center"
                        sx={{ mt: 5, width: '100%' }}
                    />
                </Box>
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        all_automated_scan_groups: state?.automatedScansReducers?.all_automated_scan_group,
        all_users: state?.merchantReducers?.all_merchant_users,
        all_employees: state?.generalReducers?.all_employees,
        user_id: state?.generalReducers?.user_info?.employee?.id,
        all_assigned_task: state?.automatedScansReducers?.all_assigned_task,
    };
};

export default connect(mapStateToProps, {
    CreateScanException,
    AssignAutomatedScan,
})(AssignTaskModal);
