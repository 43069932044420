import React, { useState, useEffect } from 'react';

import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import TabLayout from 'components/new_components/tabs/TabLayout';
import UserInfo from './UserInfo';
import PasswordMgt from './PasswordMgt';
import TwoFactorAuth from './TwoFactorAuth/index';
import PageHeader from 'components/new_components/PageHeader';
import Plans from './plans';
import { useLocation } from 'react-router-dom';
import Compliance from './Compliance';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const AccountSetup = () => {
    // state
    const [currentTab, setCurrentTab] = useState(0);

    const { search, state } = useLocation();

    //translation hook
    const { t } = useTranslation('accounts');

    // logic function
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };

    const user_type = useSelector((state) => state?.authReducers?.user_type);

    useEffect(() => {
        const getTab = () => {
            const getString = search?.split('=')[1];
            if (search && getString === 'plans') {
                setCurrentTab(3);
            } else if (state?.tab === 'compliance') {
                setCurrentTab(4);
            } else if (state?.tab === '2fa') {
                setCurrentTab(2);
            } else {
                setCurrentTab(0);
            }
        };
        getTab();
    }, [search, state?.tab]);

    // constants
    // const TABS = [
    //     { name: 'User Information', id: 0 },
    //     { name: 'Password Management', id: 1 },
    //     { name: 'Two-factor Authentication', id: 2 },
    //     { name: 'Plans', id: 3 },
    //     { name: 'Compliance', id: 4 },
    // ];

    return (
        <>
            <PageHeader browserTitle="Account Setup | Smartcomply" />
            <TabLayout
                onTabChange={handleTabChange}
                tab={currentTab}
                tabs={
                    user_type === 'auditor'
                        ? t('auditorTabs', { returnObjects: true })
                        : t('tabs', { returnObjects: true })
                }
            >
                <AppTabPanel value={currentTab} index={0}>
                    <UserInfo />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={1}>
                    <PasswordMgt />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={2}>
                    <TwoFactorAuth />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={3}>
                    <Plans />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={4}>
                    <Compliance />
                </AppTabPanel>
            </TabLayout>
        </>
    );
};

export default AccountSetup;
