import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const AppFormContext = (props) => {
    const { children, getValues, getTouched } = props;
    // other hooks
    const { values, touched } = useFormikContext();

    useEffect(() => {
        getValues && getValues(values);
    }, [values]);
    useEffect(() => {
        getTouched && getTouched(touched);
    }, [touched]);

    return children;
};
export default AppFormContext;
