import React, { useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Cancel, Info } from '@mui/icons-material';

import AppModal from 'components/new_components/AppModal';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import TabLayout from 'components/new_components/tabs/TabLayout';
import UploadSignature from '../AuditSignature/UploadSignature';
import DrawSignature from '../AuditSignature/DrawSignature';
import AppButton from 'components/new_components/AppButton';

import { dataURLtoFile } from 'utils';

// redux
import { connect } from 'react-redux';
import { UpdateAuditRequest } from 'store/actions/auditActions';
import AppLoadingButton from 'components/new_components/AppLoadingButton';

const AuditSignatureModal = (props) => {
    const theme = useTheme();
    const signatureRef = useRef();

    // props
    const { open, handleClose, UpdateAuditRequest, standard, all_audit_requests, goBack } = props;

    // state
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);

    // memos
    const auditRequest = useMemo(() => {
        return all_audit_requests?.find((request) => request?.compliance === standard);
    }, [standard, all_audit_requests]);

    // logic functions
    const handleTabChange = (tab) => {
        setTab(tab);
    };
    const handleSubmit = async () => {
        setLoading(true);
        const signatureImage = tab === 0 ? image : getSignatureImg();
        const formData = new FormData();
        formData.append('merchant_signature', signatureImage);
        formData.append('merchant', auditRequest?.merchant);
        const res = await UpdateAuditRequest(formData, auditRequest?.id);
        setLoading(false);
        if (res?.success) {
            toast.success(
                'Your signature has been attached to your ROC document, you will be notified for an update soonest!'
            );
            goBack();
        } else {
            toast.error("Something when wrong, couldn't append your signature.");
        }
    };
    const getSignatureImg = () => {
        if (!signatureRef.current) return;
        const signURL = signatureRef.current.toDataURL();
        const image = dataURLtoFile(signURL, 'signature.png');
        return image;
    };
    const clearSignature = () => {
        if (!signatureRef.current) return;
        signatureRef.current.clear();
    };

    return (
        <AppModal
            open={open}
            handleClose={handleClose}
            title="Attach Signature"
            subtitle=""
            width="900px"
            hasCloseBtn
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        mb: 2.5,
                    }}
                >
                    <AppButton color="primary" variant="text" name="Cancel" onClick={handleClose} />
                    <AppLoadingButton
                        color="primary"
                        variant="contained"
                        text="Submit"
                        sx={{ marginLeft: 2 }}
                        onClick={handleSubmit}
                        loading={loading}
                    />
                </Box>
            }
        >
            <div className="">
                <div className="flex items-center">
                    <Info sx={{ width: 20, height: 20, marginLeft: '-0.5rem', color: '#E2B208' }} />
                    <span className="text-[#5E5E62] text-[14px] pl-2">
                        We advice you review your report before attaching your signature
                    </span>
                </div>
                <Box sx={{ mt: 2 }}>
                    <TabLayout
                        tabVariant="outlined"
                        tab={tab || 0}
                        onTabChange={handleTabChange}
                        tabs={[
                            { name: 'Upload Signature', id: 0 },
                            { name: 'Draw Signature', id: 1 },
                        ]}
                    >
                        <Box
                            sx={{
                                border: `2px dashed ${theme.palette.primary[900]}`,
                                mt: 4,
                                borderRadius: 2,
                                height: 230,
                                position: 'relative',
                            }}
                        >
                            <AppTabPanel value={tab} index={0}>
                                <UploadSignature image={image} setImageFile={setImage} />
                            </AppTabPanel>
                            <AppTabPanel value={tab} index={1}>
                                <DrawSignature ref={signatureRef} />
                            </AppTabPanel>
                        </Box>
                        <Box sx={{ height: 22 }}>
                            {tab === 1 && (
                                <Box
                                    component="button"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        color: theme.palette.primary[900],
                                        fontSize: 14,
                                        width: 'fit-content',
                                        ml: 'auto',
                                        my: 0.3,
                                        gap: 0.3,
                                    }}
                                    onClick={clearSignature}
                                >
                                    <span>Clear Signature</span>
                                    <Cancel sx={{ fontSize: 14, m: 'auto' }} />
                                </Box>
                            )}
                        </Box>
                    </TabLayout>
                </Box>
            </div>
        </AppModal>
    );
};
const mapStateToProps = (state) => {
    return { all_audit_requests: state?.auditReducers?.all_audit_requests };
};
export default connect(mapStateToProps, {
    UpdateAuditRequest,
})(AuditSignatureModal);
