import React, { useState, useMemo } from 'react';
import { Stack } from '@mui/material';

// core components
import ResourceAside from '../common/exceptions/ResourceAside';
import SectionCard from '../common/exceptions/SectionCard';
import CategorySelectionAside from './components/CategorySelectionAside';

// redux
import { connect } from 'react-redux';

const Exceptions = (props) => {
    // props
    const { getCategories, all_categories } = props;

    // state
    const [resource, setResource] = useState('doc');
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);

    //memos
    const selectedCategoryTitle = useMemo(() => {
        return all_categories?.find((category) => category?.id === selectedCategoryId)?.title;
    }, [selectedCategoryId]);

    // logic functions
    const handleResourceChange = (resource) => {
        setResource(resource);
        setSelectedCategoryId(null);
    };
    const handleCategoryIdChange = (id) => {
        setSelectedCategoryId(id);
    };

    return (
        <Stack direction="row" sx={{ px: 4, py: 5, flex: 1 }} spacing={3}>
            <SectionCard>
                <CategorySelectionAside
                    resource={resource}
                    handleResourceChange={handleResourceChange}
                    handleCategoryIdChange={handleCategoryIdChange}
                    selectedCategory={selectedCategoryId}
                />
            </SectionCard>
            <SectionCard>
                <ResourceAside
                    selectedSubgroup={selectedCategoryId}
                    resource={resource}
                    subgroupIdKey="gdpa_category"
                    subgroupTitle={selectedCategoryTitle}
                    standard="gdpa"
                    onExempted={getCategories}
                />
            </SectionCard>
        </Stack>
    );
};
const mapStateToProps = (state) => {
    return {
        all_categories: state?.adminReducers?.all_gdpa_categories,
    };
};
export default connect(mapStateToProps, {})(Exceptions);
