import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button, Select } from 'antd';
// import AppTagInputField from 'components/new_components/AppTagInputField';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

//redux
import { connect } from 'react-redux';
import { CreateSurveyQuestion, EditSurveyQuestion } from 'store/actions/adminActions';

const QuestionModal = (props) => {
    const { open, handleCancel, CreateSurveyQuestion, EditSurveyQuestion, survey, options, setOptions } = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const body = {
            ...data,
            answer_choices: data?.ans_type === 'text' ? '' : options,
        };
        const res =
            open === 'Add'
                ? await await CreateSurveyQuestion(body, false)
                : await EditSurveyQuestion(survey?.id, body, false);
        setLoading(false);
        if (res.success) {
            setData({});
            handleCancel();
            setOptions(['']);
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (survey?.answer_choices) {
            setOptions(survey?.answer_choices);
        }
    }, [survey?.answer_choices]);

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleTypeChange = (value, name) => {
        setData({ ...data, [name]: value });
    };

    // useEffect
    useEffect(() => {
        if (survey) {
            setData(survey);
        } else {
            setData({});
        }
    }, [survey]);

    const addItem = () => {
        const newOptions = [...options];
        newOptions.push('');
        setOptions(newOptions);
    };

    // Remove Item
    const removeItem = (index) => {
        const newOptions = [...options];
        newOptions.splice(index, 1);
        setOptions(newOptions);
    };

    const handleTagChange = (event, index) => {
        const newOptions = [...options];
        newOptions[index] = event.target.value;
        setOptions([...newOptions]);
    };
    return (
        <Modal
            title={`${open} Survey Question`}
            open={open}
            onCancel={handleCancel}
            footer={null}
            destroyOnClose={true}
        >
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="question">Survey Question</label>
                        <Input
                            type="text"
                            size="large"
                            name="question"
                            id="question"
                            onChange={handleTextChange}
                            value={data.question || ''}
                            required
                        />
                    </Col>
                    <Col span={24}>
                        <div className="mb-4">
                            <label htmlFor="ans_type">Answer Type</label>
                            <Select
                                name="ans_type"
                                id="ans_type"
                                size="large"
                                style={{ width: '100%' }}
                                value={data.ans_type || ''}
                                onChange={(value) => handleTypeChange(value, 'ans_type')}
                                className="capitalize"
                                required
                            >
                                <Select.Option value={'single'} className="capitalize">
                                    Single
                                </Select.Option>
                                <Select.Option value={'multiple'} className="capitalize">
                                    Multiple
                                </Select.Option>
                                <Select.Option value={'text'} className="capitalize">
                                    Text
                                </Select.Option>
                            </Select>
                        </div>
                    </Col>
                    {data?.ans_type === 'single' || data?.ans_type === 'multiple' ? (
                        <React.Fragment>
                            <Col span={24}>
                                <label htmlFor="ans_type">Answer Option</label>
                            </Col>
                            {options?.map((tag, index) => (
                                <Col span={24} className="mb-3" key={index}>
                                    <Input
                                        value={tag}
                                        className="mb-3"
                                        onChange={(event) => handleTagChange(event, index)}
                                        required
                                    />
                                    {options?.length > 1 ? (
                                        <DeleteOutlined
                                            key="delete"
                                            onClick={() => removeItem(index)}
                                            className="float-right mb-3"
                                        />
                                    ) : null}
                                </Col>
                            ))}
                        </React.Fragment>
                    ) : null}
                    {data?.ans_type === 'single' || data?.ans_type === 'multiple' ? (
                        <Col span={24}>
                            <Button
                                type="primary"
                                size="large"
                                icon={<PlusOutlined />}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'left',
                                }}
                                onClick={addItem}
                            >
                                Add option
                            </Button>
                        </Col>
                    ) : null}
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = () => {
    return {};
};
export default connect(mapStateToProps, {
    CreateSurveyQuestion,
    EditSurveyQuestion,
})(QuestionModal);

// CreateSurveyQuestion
