import React from 'react';
import { Box, Card, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EmptyReleaseContent from './EmptyReleaseContent';
import { ChevronLeft } from '@mui/icons-material';
import ListImageIcon from 'assets/img/listicon.png';

const ReleaseContent = ({ releaseDetails, selected, removeSelected }) => {
    const theme = useTheme();

    const content = (
        <Box sx={{ width: '100%' }}>
            <Button onClick={removeSelected} sx={{ display: { xs: 'block', md: 'none' } }}>
                <ChevronLeft sx={{ fontSize: '2rem' }} />
            </Button>
            <Box
                sx={{
                    color: theme.palette.primary[900],
                    fontWeight: 500,
                    '& p': {
                        px: 1,
                        mt: 3,
                    },
                    '& ul': {
                        p: 1,
                        '& li': {
                            listStyle: `url(${ListImageIcon})`,
                            ml: 2,
                            padding: '0.4rem',
                        },
                    },
                }}
                dangerouslySetInnerHTML={{
                    __html: releaseDetails,
                }}
            ></Box>
        </Box>
    );

    return (
        <>
            <Box
                component={Card}
                sx={{
                    width: '100%',
                    background: '#FFFFFF',
                    boxShadow:
                        '-23px 170px 68px rgba(209, 209, 209, 0.01), -13px 95px 58px rgba(209, 209, 209, 0.05), -6px 42px 43px rgba(209, 209, 209, 0.09), -1px 11px 24px rgba(209, 209, 209, 0.1), 0px 0px 0px rgba(209, 209, 209, 0.1)',
                    borderRadius: '10px',
                    display: { xs: 'none', md: 'block' },
                }}
            >
                {selected === null ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <EmptyReleaseContent />
                    </Box>
                ) : (
                    content
                )}
            </Box>
            <Box
                sx={{
                    display: { xs: 'flex', md: 'none' },
                    maxWidth: '100%',
                    width: '100%',
                    position: 'absolute',
                    left: selected === null ? '100%' : 0,
                    top: 0,
                    backgroundColor: theme.palette.white.main,
                    height: '100%',
                    m: '0 !important',
                    transition: 'left 0.5s',
                }}
            >
                {content}
            </Box>
        </>
    );
};

export default ReleaseContent;
