import React, { useState } from 'react';
import { CardContainer } from '../../dashboard-utils/DashboardUtils';
import VendorGraphReport from './VendorGraphReport';
import RespondedVendorGraphReport from './RespondedVendorGraphReport';

//translations
import { useTranslation } from 'react-i18next';

const VendorReportSection = ({ dashboardResult }) => {
    const [indexValue, setIndexValue] = useState(1);

    //translation
    const { t } = useTranslation('overview');

    //constant
    const tabList = [
        {
            id: 1,
            name: t('vendorReportSection.vendorClassification'),
            items: ``,
        },
        {
            id: 2,
            name: t('vendorReportSection.respondedVendors'),
        },
    ];
    const onChangeTab = (val) => {
        setIndexValue(val);
    };
    return (
        <div>
            <CardContainer cardStyle="">
                <div className="px-4 py-2 border-b border-b-gray-300 lg:text-[20px] text-lg textbrandColor font-bold">
                    {t('vendorReportSection.title')}
                </div>
                <div className="bg-[#F2F0F4] flex text-[#46464A] justify-between px-2 py-2 employee_card">
                    {tabList.map((res, index) => (
                        <React.Fragment key={index}>
                            <div
                                className={`inner_tab ${indexValue === res.id ? 'active' : ''} w-1/2 px-2 py-1`}
                                onClick={() => onChangeTab(res.id)}
                            >
                                {res.name}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
                {indexValue === 1 && (
                    <div className="p-4">
                        <VendorGraphReport dashboardResult={dashboardResult} />
                    </div>
                )}
                {indexValue === 2 && (
                    <div className="p-4">
                        <RespondedVendorGraphReport dashboardResult={dashboardResult} />
                    </div>
                )}
            </CardContainer>
        </div>
    );
};

export default VendorReportSection;
