import React, {
    useState,
    useEffect,
    // useMemo
} from 'react';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { Box, CircularProgress } from '@mui/material';
import QuestionnaireHeader from './Components/QuestionnaireHeader';
import QuestionsLayout from './Components/QuestionsLayout';
// import { AppForm } from 'components/new_components/forms';
import TipsModal from './Components/TipsModal';

// validation
// import { validation } from 'validate';

//redux
import { connect } from 'react-redux';
import { SaveApprovedQuestionnaireAnswers, GetOneUploadedVendorQuestionnareDetails } from 'store/actions/vendorActions';

const QuestionnaireView = (props) => {
    const [showTips, setShowTips] = useState(false);
    const [fieldValues, setFieldValues] = useState({});
    // const [fieldErrors, setFieldErrors] = useState({});
    // const [showPredictionScore, setShowPredictionScore] = useState(null);
    const [questionnaireDetails, setQuestionnaireDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [actionsLoading, setActionsLoading] = useState({ submit: false, approveAll: false });
    const {
        goBack,
        selectedQuestionnaire,
        vendor_uploaded_questionnaire,
        one_questionnare_details,
        SaveApprovedQuestionnaireAnswers,
        GetOneUploadedVendorQuestionnareDetails,
    } = props;
    const theme = useTheme();

    //functions
    const openTipsModal = () => {
        setShowTips(true);
    };
    const closeTipsModal = () => {
        setShowTips(false);
    };

    const getInitialValues = (questions, response, deletedQuestions) => {
        const initialValues = {};
        const questionsDifference = questions?.filter((x) => !deletedQuestions.includes(x));
        questionsDifference?.forEach((field) => {
            const question = one_questionnare_details?.predictions?.Question?.[field];
            const fieldValue = response?.[question];
            initialValues[question] = {
                answer: fieldValue?.answer || '',
                comment: fieldValue?.comment || '',
            };
        });
        return initialValues;
    };

    // const validate = (values, questions) => {
    //     const errors = {};

    //     questions.forEach((field) => {
    //         const fieldValidate = validation(values?.[field]?.answer, 'Field', true);
    //         if (!fieldValidate.isValid) {
    //             errors[field] = { answer: fieldValidate.errorMessage };
    //         }
    //     });

    //     return errors;
    // };
    const handleInputs = (field, value, field_name) => {
        // field, value, field_name
        const values = JSON.parse(JSON.stringify(fieldValues));
        const fieldValue = values[field];
        fieldValue[field_name] = value;
        values[field] = fieldValue;
        setFieldValues(values);
    };
    const handleAnswerInput = (e, field) => {
        handleInputs(field, e.target.value, 'answer');
    };
    const handleAnswerSelect = (answer, field) => {
        handleInputs(field, answer, 'answer');
    };
    const handleCommentSelect = (answer, field) => {
        handleInputs(field, answer, 'comment');
    };
    const handleCommentInput = (e, field) => {
        handleInputs(field, e.target.value, 'comment');
    };

    const getMaxIndex = (responseConfidences) => {
        let maxConfidence = Math.max(...responseConfidences);
        return responseConfidences?.indexOf(maxConfidence);
    };
    const handleSingleApproveAction = async (field, values) => {
        const payload = {
            accepted: {
                ...fieldValues,
                [field]: values,
            },
        };
        const res = await SaveApprovedQuestionnaireAnswers(selectedQuestionnaire, payload);
        if (res?.success) {
            toast.success(`Answer Approved Successfully`);
        } else {
            toast.error('Something Occurred');
        }
    };
    const handleDeleteQuestion = async (credentials) => {
        const deletedQuestions = one_questionnare_details?.deleted || {};
        let payload = { deleted: { ...deletedQuestions, ...credentials } };
        const res = await SaveApprovedQuestionnaireAnswers(selectedQuestionnaire, payload);
        if (res?.success) {
            toast.success('Question Deleted Successfully');
        } else {
            toast.error(res?.message);
        }
    };
    // const removeEmptyValues = (payload) => {
    //     const accepted = { ...payload.accepted };
    //     const newPayload = {};
    //     for (let category in accepted) {
    //         newPayload[category] = {};
    //         const fields = accepted?.[category];
    //         for (let field in fields) {
    //             if (fields?.[field]?.answer || fields?.[field]?.comment) {
    //                 newPayload[category][field] = fields?.[field];
    //             }
    //         }
    //     }
    //     return { accepted: newPayload };
    // };
    const handleDisapproveAction = async (field) => {
        delete fieldValues?.[field];
        let payload = {
            accepted: {
                ...fieldValues,
                [field]: { answer: '', comment: '' },
            },
        };
        const res = await SaveApprovedQuestionnaireAnswers(selectedQuestionnaire, payload);
        if (res?.success) {
            toast.success(`Answer disapproved Successfully`);
        } else {
            toast.error('Something Occurred');
        }
    };
    const handleCheckAndApprove = async () => {
        setActionsLoading({ ...actionsLoading, approveAll: true });
        const {
            Question: questions,
            Response: responses,
            Confidence: confidences,
        } = one_questionnare_details?.predictions || {};
        let deletedQuestions = Object.keys(one_questionnare_details?.deleted || {});
        let avaialableQuestions = Object.keys(questions || {});
        let payload = {};
        const questionsDifference = avaialableQuestions?.filter((x) => !deletedQuestions.includes(x));
        questionsDifference.forEach((questionId) => {
            const qAndAPairs = { ...fieldValues };
            const confidencesForQuestion = confidences[questionId];
            const maxConfidenceIdx = getMaxIndex(confidencesForQuestion);
            const confidentResponse = responses[questionId][maxConfidenceIdx];
            const question = one_questionnare_details?.predictions?.Question[questionId];
            qAndAPairs[question] =
                qAndAPairs[question]?.answer === '' ? { answer: confidentResponse, comment: '' } : qAndAPairs[question];
            payload[question] = { ...qAndAPairs[question] };
        });

        setFieldValues(payload);
        const values = { accepted: payload };
        const res = await SaveApprovedQuestionnaireAnswers(selectedQuestionnaire, values);
        setActionsLoading({ ...actionsLoading, approveAll: false });
        if (res?.success) {
            toast.success(
                `Successfully checked and approved all options. You can choose to review the check answers then click on the "Submit All" button to get your answers downloaded`
            );
        } else {
            toast.error('Something Occurred');
        }
        return payload;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setActionsLoading({ ...actionsLoading, submit: true });
        let payload = { accepted: fieldValues, submit_all: true };
        const res = await SaveApprovedQuestionnaireAnswers(selectedQuestionnaire, payload);
        setActionsLoading({ ...actionsLoading, submit: false });
        if (res?.success) {
            toast.success('Success');
        } else {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (Object.keys(one_questionnare_details)?.length) {
            setFieldValues(one_questionnare_details?.accepted);
        } else {
            setFieldValues({});
        }
    }, [one_questionnare_details, selectedQuestionnaire]);

    useEffect(() => {
        if (selectedQuestionnaire) {
            const getOneUploadedVendorQuestionnareDetails = async () => {
                setLoading(true);
                const res = await GetOneUploadedVendorQuestionnareDetails(selectedQuestionnaire);
                setLoading(false);
                if (!res?.success) {
                    toast.error(res?.message);
                }
            };
            getOneUploadedVendorQuestionnareDetails();
        }
    }, [selectedQuestionnaire]);
    useEffect(() => {
        const details = vendor_uploaded_questionnaire?.find(
            (questionnaire) => questionnaire?.id === selectedQuestionnaire
        );
        setQuestionnaireDetails(details);
    }, [selectedQuestionnaire, vendor_uploaded_questionnaire]);
    useEffect(() => {
        const availableQuestions = Object.keys(one_questionnare_details?.predictions?.Question || {});
        const deletedQuestions = Object.keys(one_questionnare_details?.deleted || {});
        const responses = one_questionnare_details?.accepted || {};
        setFieldValues(getInitialValues(availableQuestions, responses, deletedQuestions));
        return () => {};
    }, [one_questionnare_details?.predictions?.Question, one_questionnare_details?.accepted]);
    return loading ? (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 3,
                width: '100%',
                height: '100%',
            }}
        >
            <CircularProgress sx={{ color: theme.palette.gray[400], transition: 'color .3s' }} />
        </Box>
    ) : (
        <Box sx={{ width: '100%' }} component="form" onSubmit={handleSubmit}>
            <QuestionnaireHeader
                goBack={goBack}
                openTipsModal={openTipsModal}
                showTips={showTips}
                handleCheckAndApprove={handleCheckAndApprove}
                title={one_questionnare_details?.upload_filename}
                fileURL={one_questionnare_details?.file}
                handleApprove={handleSubmit}
                questionnaireDetails={{
                    ...one_questionnare_details,
                    question_count: questionnaireDetails?.question_count,
                    accepted_count: questionnaireDetails?.accepted_count,
                }}
                loading={actionsLoading}
            />
            <QuestionsLayout
                selectedQuestionnaire={one_questionnare_details}
                acceptedAnswers={one_questionnare_details?.accepted}
                handleAnswerInput={handleAnswerInput}
                handleAnswerSelect={handleAnswerSelect}
                handleCommentSelect={handleCommentSelect}
                handleCommentInput={handleCommentInput}
                fieldValues={fieldValues}
                handleSingleApproveAction={handleSingleApproveAction}
                handleDisapproveAction={handleDisapproveAction}
                handleDeleteQuestion={handleDeleteQuestion}
                // fieldErrors={fieldErrors}
            />
            {/* 
                showPredictionScore={showPredictionScore}
                handleShowPredictionScore={handleShowPredictionScore}
                handleHidePredictionScore={handleHidePredictionScore}
                handleApprove={handleCheckAndApprove}
            /> */}

            <TipsModal open={showTips} handleClose={closeTipsModal} />
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        vendor_uploaded_questionnaire: state?.vendorReducers?.vendor_uploaded_questionnaire,
        one_questionnare_details: state?.vendorReducers?.one_vendor_uploaded_questionnaire || {},
    };
};
export default connect(mapStateToProps, {
    GetOneUploadedVendorQuestionnareDetails,
    SaveApprovedQuestionnaireAnswers,
})(QuestionnaireView);
