import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Modal, Row, Spin } from 'antd';
import BreadCrumb from 'components/Breadcrumb';
import { Link } from 'react-router-dom';
import CategoryModal from './components/CategoryModal';

// redux
import { connect } from 'react-redux';
import { GetAllAccessorCategories, DeleteAccessorCategory } from 'store/actions/auditActions';

const { confirm } = Modal;

const CategoryCards = (props) => {
    // props
    const { GetAllAccessorCategories, all_accessor_categories, DeleteAccessorCategory } = props;

    // state
    const [modal, setModal] = useState(null);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // modal control
    const openModal = (mode, data = {}) => {
        setModal(mode);
        setData(data);
    };
    const closeModal = () => {
        setModal(null);
        setData({});
    };
    const getAllAccessorCategories = async () => {
        setLoading(true);
        const res = await GetAllAccessorCategories();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.success);
        }
    };
    const deleteAccessorCategory = (id) => {
        confirm({
            title: 'Are you sure delete this Category?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteAccessorCategory(id);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    // useEffect
    useEffect(() => {
        getAllAccessorCategories();
    }, []);

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Accessor Categories' }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        className="flex jsutify-between items-center"
                        onClick={() => openModal('Add')}
                    >
                        Add Accessor Categories
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {loading ? (
                    <Row justify="center" className="justify-center">
                        <Spin size="large" />
                    </Row>
                ) : all_accessor_categories && all_accessor_categories?.length ? (
                    <Row wrap gutter={24} justify="start">
                        {all_accessor_categories
                            ?.sort((a, b) => a?.id - b?.id)
                            .map((category) => (
                                <Col xs={24} md={12} lg={8} xxl={6} style={{ marginBottom: '1rem' }} key={category.id}>
                                    <Card
                                        title={category.name}
                                        loading={false}
                                        actions={[
                                            <Link
                                                key="preview"
                                                to={{
                                                    pathname:
                                                        category?.question_type === 'single'
                                                            ? `/admin/accessor-categories/single`
                                                            : category?.question_type === 'multiple'
                                                            ? `/admin/accessor-categories/multiple`
                                                            : category?.question_type === 'duplicate'
                                                            ? `/admin/accessor-categories/duplicate`
                                                            : `/admin/accessor-categories/single-duplicate`,
                                                    state: {
                                                        category_id: category?.id,
                                                    },
                                                }}
                                            >
                                                <EyeOutlined key="preview" />
                                            </Link>,
                                            <EditOutlined key="edit" onClick={() => openModal('Edit', category)} />,
                                            <DeleteOutlined
                                                key="delete"
                                                onClick={() => deleteAccessorCategory(category?.id)}
                                            />,
                                        ]}
                                    >
                                        <div className="flex justify-start items-start py-1 ">
                                            <span>Category Type: {category?.question_type}</span>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                ) : (
                    <Row justify="center" className="justify-center">
                        <Col>
                            <Empty
                                description={
                                    <div>
                                        <h3>No Accessor Category</h3>
                                        <span>Add a new category </span>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                )}
            </section>
            <CategoryModal open={modal} handleClose={closeModal} modalData={data} />
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_accessor_categories: state?.auditReducers?.all_accessor_categories,
});
export default connect(mapStateToProps, { GetAllAccessorCategories, DeleteAccessorCategory })(CategoryCards);
