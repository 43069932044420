import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Tabs, Row, Col, Spin } from 'antd';

// core component
import NpdrDocumentCardsView from './NpdrDocumentsCardsView';
import BreadCrumb from 'components/Breadcrumb';
import NpdrFormsView from './NpdrFormsView';

// icons
import { FileOutlined, FormOutlined } from '@ant-design/icons';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { GetAllDocumentsByCategory } from 'store/actions/ndprActions';

// sub compliance
const { TabPane } = Tabs;

const FormsAndDocumentsView = (props) => {
    const { location } = props;
    const [loading, setLoading] = useState(false);

    // redux
    const dispatch = useDispatch();
    const all_ndpr_documents = useSelector((state) => state?.ndprReducers?.all_ndpr_documents);

    useEffect(() => {
        const getAllNdprDocuments = async () => {
            setLoading(true);
            const res = await dispatch(GetAllDocumentsByCategory(location?.state?.category?.id));
            if (res?.success) {
                setLoading(false);
            } else {
                setLoading(false);
                toast.error(res?.message);
            }
        };
        getAllNdprDocuments();
    }, [location?.state?.category?.id]);

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[{ label: 'NDPR', link: '/ndpr' }, { label: location?.state?.category?.title }]}
                    />
                </Col>
            </Row>
            <section className="my-3">
                <Tabs defaultActiveKey="1">
                    <TabPane
                        tab={
                            <span>
                                <FileOutlined />
                                Documents
                            </span>
                        }
                        key="1"
                    >
                        {loading ? (
                            <Row justify="center">
                                <Spin size="large" />
                            </Row>
                        ) : (
                            <NpdrDocumentCardsView
                                category_id={location?.state?.category?.id}
                                all_documents_by_subreq={all_ndpr_documents}
                            />
                        )}
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <FormOutlined />
                                Audit Questions
                            </span>
                        }
                        key="2"
                    >
                        {loading ? (
                            <Row justify="center">
                                <Spin size="large" />
                            </Row>
                        ) : (
                            <NpdrFormsView category_id={location?.state?.category?.id} />
                        )}
                    </TabPane>
                </Tabs>
            </section>
        </div>
    );
};

export default FormsAndDocumentsView;
