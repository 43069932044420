import React from 'react';
import AppDrawer from 'components/new_components/AppDrawer';
import { Box, Typography, Divider } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { useTheme } from '@mui/styles';

const DescriptionPreviewDrawer = (props) => {
    const { open, handleClose, category } = props;

    const theme = useTheme();
    return (
        <AppDrawer open={open} handleClose={handleClose} width={500}>
            <Box sx={{ my: 1.5 }}>
                <Box sx={{ display: 'flex', mx: 3, pb: 2, alignItems: 'center' }}>
                    <ArrowBackIos
                        onClick={handleClose}
                        sx={{ width: 20, height: 20, color: '#000000', cursor: 'pointer' }}
                    />
                    <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#202D66', ml: 2 }}>
                        {category?.title}
                    </Typography>
                </Box>
                <Divider sx={{ color: theme.palette.neutral[95] }} />
                <Box>
                    <Box sx={{ m: 3 }}>
                        <Typography sx={{ color: '#64748B', fontWeight: 400, fontSize: '14px', mt: 0.5 }}>
                            {category?.description}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </AppDrawer>
    );
};

export default DescriptionPreviewDrawer;
