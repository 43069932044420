import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
//core component
import { Modal, Row, Col, Input, Button, Select, InputNumber, Checkbox } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

//redux
import { connect } from 'react-redux';
import {
    AddPlan,
    GetAllFeatures,
    UpdatePlan,
    AdminCreateCustomPlanRequest,
    EditCustomPlanRequest,
} from 'store/actions/subscriptionActions';

// util
import { FEATURE_KEYS } from 'views/admin/features/util';
import { AVAILABLE_STANDARDS } from 'utils';
import { removeSpaces } from 'utils';

const { Option } = Select;

const PlanModal = (props) => {
    const {
        mode,
        handleClose,
        AddPlan,
        GetAllFeatures,
        all_features,
        modalData,
        UpdatePlan,
        type = 'main',
        all_merchants,
        AdminCreateCustomPlanRequest,
        EditCustomPlanRequest,
    } = props;

    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingFeatures, setLoadingFeatures] = useState(false);
    const [featuresValues, setFeatureValues] = useState([{}]);
    const [rangeOfEmployees, setRangeOfEmployees] = useState({
        from: null,
        to: null,
    });
    const [standards, setStandards] = useState([]);

    // functions
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    const handleNumberChange = (name, value) => {
        setData({ ...data, [name]: value });
    };
    const handleSelectChange = (value) => {
        setData({ ...data, merchant: value });
    };
    const handleStandardChange = (event) => {
        const { name } = event.target;
        ``;
        const newStandards = [...standards];
        const indexOfStandard = newStandards.indexOf(name);
        if (indexOfStandard < 0) {
            newStandards.push(name);
        } else {
            newStandards.splice(indexOfStandard, 1);
        }
        setStandards(newStandards);
    };
    // const handleSelectPlanTag = (value) => {
    //     setData({ ...data, tag: value });
    // };
    const onClose = () => {
        setData({});
        setFeatureValues([{}]);
        handleClose();
    };

    // functions to handle features
    const onFeatureChange = (index, feature) => {
        const featuresValueCopy = [...featuresValues];
        const newFeature = all_features?.find((feat) => feat?.key === feature);
        featuresValueCopy[index] = {
            field: newFeature?.key,
            value: newFeature?.value_type === 'check' ? true : null,
        };
        setFeatureValues(featuresValueCopy);
    };
    const handleFeatureValueChange = (index, value) => {
        const featureValueCopy = [...featuresValues];
        const featureValueObject = { ...featureValueCopy[index] };
        featureValueObject.value = value;
        featureValueCopy[index] = featureValueObject;
        setFeatureValues(featureValueCopy);
    };
    const addNewFeatureField = () => {
        setFeatureValues((current) => [...current, {}]);
    };
    const deleteFeatureField = (index) => {
        const featuresValueCopy = [...featuresValues];
        featuresValueCopy?.splice(index, 1);
        setFeatureValues(featuresValueCopy);
    };

    // functions to handle employee range
    const handleEmployeeRange = (key, value) => {
        setRangeOfEmployees({ ...rangeOfEmployees, [key]: value });
    };

    // async functions
    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = { ...data };
        featuresValues?.forEach((featureValue) => {
            payload[featureValue?.field] = featureValue?.value;
        });
        setLoading(true);
        let res;
        if (type === 'main') {
            payload.employee_range = `${rangeOfEmployees?.from} - ${rangeOfEmployees?.to}`;
            payload.custom = false;
            res = mode === 'Add' ? await AddPlan(payload) : await UpdatePlan(payload, modalData?.id);
        } else if (type === 'custom') {
            payload.name = 'custom-' + payload?.merchant?.slice(0, 5);
            payload.tag = 'custom';
            payload.compliance_type = standards?.join(',');
            res =
                mode === 'Add'
                    ? await AdminCreateCustomPlanRequest(payload)
                    : await EditCustomPlanRequest(payload, modalData?.id);
        }
        setLoading(false);
        if (res?.success) {
            toast.success('Plan Added Successfully');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };
    const getAllFeatures = async () => {
        setLoadingFeatures(true);
        const res = await GetAllFeatures();
        setLoadingFeatures(false);
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch features");
        }
    };

    useEffect(() => {
        if (mode) {
            getAllFeatures();
        }
    }, [mode]);
    useEffect(() => {
        if (mode) {
            setData(modalData);
            // handle range of employee
            const rangeOfEmployeesArr = modalData?.employee_range ? modalData?.employee_range?.split(' - ') : ['', ''];
            setRangeOfEmployees({
                from: parseInt(rangeOfEmployeesArr[0]),
                to: parseInt(rangeOfEmployeesArr[1]),
            });

            // handle features values
            const featuresValues = [];
            FEATURE_KEYS?.forEach((key) => {
                if (modalData?.[key]) {
                    featuresValues.push({ field: key, value: modalData?.[key] });
                }
            });
            setFeatureValues(featuresValues);
            if (modalData?.compliance_type) {
                const standards = removeSpaces(modalData?.compliance_type).split(',');
                setStandards(standards);
            }
        }
    }, [modalData]);

    return (
        <Modal
            title={`${mode} a ${type !== 'main' ? 'custom' : ''} plan`}
            open={Boolean(mode)}
            onCancel={onClose}
            footer={null}
            destroyOnClose={true}
        >
            <form onSubmit={handleSubmit}>
                <Row
                    style={{
                        maxHeight: '500px',
                        overflowY: 'auto',
                        marginBottom: '1rem',
                    }}
                >
                    {type === 'custom' && (
                        <Col span={24} className="my-2">
                            <label htmlFor="merchant">Select Merchant</label>
                            <Select
                                size="large"
                                required
                                style={{ width: '100%' }}
                                placeholder="Select a merchant"
                                value={data?.merchant}
                                onChange={handleSelectChange}
                                disabled={mode === 'Edit'}
                            >
                                {all_merchants?.map((merchant, index) => {
                                    const name = `${merchant?.owner_detail?.first_name} ${merchant?.owner_detail?.last_name}`;
                                    return (
                                        <Option value={merchant?.id} key={index}>
                                            {name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                    )}
                    <Col span={24} className="my-2">
                        <label htmlFor="name">Name</label>
                        <Input
                            type="text"
                            size="large"
                            name="name"
                            id="name"
                            onChange={handleTextChange}
                            value={type === 'custom' ? 'custom-' + (data?.merchant?.slice(0, 5) || '') : data?.name}
                            required
                            disabled={type === 'custom'}
                        />
                    </Col>
                    {type === 'custom' && (
                        <>
                            <Col span={24} className="my-2">
                                <label className="block">Select Compliance Standards:</label>
                                {AVAILABLE_STANDARDS.map((standard) => {
                                    return (
                                        <Checkbox
                                            name={standard}
                                            onChange={handleStandardChange}
                                            key={standard}
                                            className="ml-0 mr-2 mb-1"
                                            checked={standards.includes(standard)}
                                        >
                                            {standard}
                                        </Checkbox>
                                    );
                                })}
                            </Col>
                            <Col span={24} className="my-2">
                                <label htmlFor="company_size">Company Size</label>
                                <InputNumber
                                    size="large"
                                    name="company_size"
                                    id="company_size"
                                    placeholder="How many employees does the company have?"
                                    onChange={(value) => handleNumberChange('company_size', value)}
                                    value={data?.company_size || ''}
                                    required
                                    min={1}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                        </>
                    )}
                    {type === 'main' && (
                        <>
                            <Col span={24} className="my-2">
                                <label htmlFor="description">Plan Description</label>
                                <Input
                                    type="text"
                                    size="large"
                                    name="description"
                                    id="description"
                                    placeholder="Description of the plan"
                                    onChange={handleTextChange}
                                    value={data?.description || ''}
                                />
                            </Col>
                            <Col span={24} className="my-2">
                                <label>Number of Employees</label>
                                <Row justify="flex-start">
                                    <InputNumber
                                        size="large"
                                        name="from"
                                        id="from"
                                        placeholder="From"
                                        onChange={(value) => handleEmployeeRange('from', value)}
                                        value={rangeOfEmployees?.from || ''}
                                        required={mode === 'Add'}
                                        min={0}
                                    />
                                    <Col className="flex justify-center items-center w-10">-</Col>
                                    <InputNumber
                                        size="large"
                                        name="to"
                                        id="to"
                                        placeholder="To"
                                        onChange={(value) => handleEmployeeRange('to', value)}
                                        value={rangeOfEmployees?.to || ''}
                                        required={mode === 'Add'}
                                        min={rangeOfEmployees.from + 1}
                                    />
                                </Row>
                            </Col>
                            <Col span={24} className="my-2">
                                <label htmlFor="price">Price</label>
                                <InputNumber
                                    size="large"
                                    name="price"
                                    id="price"
                                    placeholder="Price"
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\\s?|(,*)/g, '')}
                                    onChange={(value) => handleNumberChange('price', value)}
                                    value={data?.price || ''}
                                    min={1}
                                    style={{ width: '100%' }}
                                    prefix="₦"
                                    required={mode === 'Add'}
                                />
                            </Col>
                        </>
                    )}
                    <Col span={24} className="my-2">
                        <Row align="middle" justify="space-between">
                            <label className="m-0">Features</label>
                            <Button icon={<PlusOutlined />} onClick={addNewFeatureField} />
                        </Row>
                        {featuresValues?.map((feature, index) => (
                            <React.Fragment key={index}>
                                <Row justify="flex-start" className="my-1">
                                    <Col span={8}>
                                        <Select
                                            size="large"
                                            required
                                            style={{ width: '100%' }}
                                            placeholder="Select a feature"
                                            loading={loadingFeatures}
                                            value={feature?.field}
                                            onChange={(value) => onFeatureChange(index, value)}
                                        >
                                            {all_features?.map((feature, index) => (
                                                <React.Fragment key={index}>
                                                    <Option value={feature?.key}>{feature?.name}</Option>
                                                </React.Fragment>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col span={13}>
                                        {feature?.value !== true ? (
                                            <InputNumber
                                                size="large"
                                                placeholder="Enter the number of items"
                                                required
                                                min={1}
                                                style={{ width: '100%' }}
                                                onChange={(value) => handleFeatureValueChange(index, value)}
                                                value={feature?.value}
                                            />
                                        ) : (
                                            <Input value="Checked" size="large" type="text" disabled />
                                        )}
                                    </Col>
                                    <Col className="flex flex-1 justify-end items-center">
                                        <Button
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={() => deleteFeatureField(index)}
                                        />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        ))}
                    </Col>
                    {type === 'main' && (
                        <Col span={24} className="my-2">
                            <label htmlFor="features">Extra Features</label>
                            <Select
                                size="large"
                                name="features"
                                id="features"
                                onChange={(value) => setData({ ...data, features: value })}
                                value={data?.features}
                                required
                                style={{ width: '100%' }}
                                loading={loadingFeatures}
                                mode="multiple"
                                allowClear
                            >
                                {featuresValues?.map((feature) => {
                                    const selectedFeat = all_features?.find((feat) => feat?.key === feature?.field);
                                    return (
                                        selectedFeat && (
                                            <Option value={selectedFeat?.name} key={selectedFeat?.id}>
                                                {selectedFeat?.name}
                                            </Option>
                                        )
                                    );
                                })}
                            </Select>
                        </Col>
                    )}
                </Row>
                <Row justify="end">
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        all_features: state?.subscriptionReducers?.all_features,
        all_merchants: state?.adminReducers?.all_merchants,
    };
};
export default connect(mapStateToProps, {
    AddPlan,
    GetAllFeatures,
    UpdatePlan,
    AdminCreateCustomPlanRequest,
    EditCustomPlanRequest,
})(PlanModal);
