export const initialInfrastructureValues = {
    location: '',
    ip_address: '',
    name: '',
    vul_scan: false,
    pci_scan: false,
    api_compliance: false,
};

export const initialGroupValues = {
    name: '',
};

export const sensitivityOptions = [
    {
        name: 'Low',
        value: 'low',
    },
    {
        name: 'Medium',
        value: 'medium',
    },
    {
        name: 'Critical',
        value: 'critical',
    },
];

export const infrastructureTypesOption = [
    {
        name: 'Local',
        value: 'local',
    },
    {
        name: 'Public',
        value: 'public',
    },
];

export const StatusOption = [
    { name: 'All', value: 'all' },
    { name: 'Active', value: 'active' },
    { name: 'Inactive', value: 'inactive' },
];

export const TypeOption = [
    { name: 'All', value: 'all' },
    { name: 'Local', value: 'local' },
    { name: 'Public', value: 'public' },
];

export const AssetOption = [
    { name: 'All', value: 'all' },
    { name: 'Grouped', value: 'grouped' },
    { name: 'Ungrouped', value: 'un-grouped' },
];

export const SensitivityOption = [
    { name: 'All', value: 'all' },
    { name: 'Low', value: 'low' },
    { name: 'Medium', value: 'medium' },
    { name: 'High', value: 'high' },
];

export const Sensitivity1Option = [
    { name: 'Low', value: 'low' },
    { name: 'Medium', value: 'medium' },
    { name: 'High', value: 'high' },
];

export const groupOption = [];

export const OwnerOption = [
    { name: 'Adetayo', value: 'Adetayo' },
    { name: 'Ayobami', value: 'Adetayo' },
    { name: 'Halim', value: 'Adetayo' },
];

export const mainAssetCategories = [
    'information_asset',
    'application_inventory',
    'service_provider',
    'people',
    'laptops_desktops',
    'servers_appliances',
];

export const AssetCategories = [
    'information_asset',
    'application_inventory',
    'service_provider',
    'people',
    'laptops_desktops',
    'servers_appliances',
];

export const TechnologicalTypeOptions = ['local', 'public'];
export const InformationTypeOptions = ['digital', 'physical'];
