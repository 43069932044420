import React from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';

const AppIconButton = (props) => {
    const theme = useTheme();
    const { onClick, children, color, sx } = props;
    return (
        <IconButton
            onClick={onClick}
            color={color}
            sx={{
                boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.12), inset 0px -2px 4px rgba(14, 44, 102, 0.14)',
                width: 40,
                height: 40,
                '&, &:hover': {
                    backgroundColor: theme.palette.white.main,
                },
                ...sx,
            }}
        >
            {children}
        </IconButton>
    );
};

export default AppIconButton;
