import { styled } from '@mui/material';
import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const AppTooltip = styled(({ className, ...props }) => <Tooltip classes={{ popper: className }} {...props} arrow />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.primary[900],
            color: theme.palette.white.main,
            boxShadow: theme.shadows[1],
            fontSize: 11,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.primary[900],
            fontSize: 16,
        },
    })
);

export default AppTooltip;
