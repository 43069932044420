export const vendorsInitialValues = {
    description: '',
    data: '',
    risk_level: '',
    products: '',
    email: '',
    is_aml_vendor: true,
};

export const EntityTypeOption = [
    { name: 'All', value: '' },
    { name: 'Person', value: 'person' },
    { name: 'Company', value: 'company' },
    { name: 'Organisation', value: 'organisation' },
    { name: 'Vessel', value: 'vessel' },
    { name: 'Aircraft', value: 'aircraft' },
];

export const riskLevelOptions = [
    {
        name: 'Low',
        value: 'low',
    },
    {
        name: 'Medium',
        value: 'medium',
    },
    {
        name: 'High',
        value: 'high',
    },
    {
        name: 'Critical',
        value: 'critical',
    },
];

export const RiskLevelOption = [
    { name: 'All', value: 'all' },
    { name: 'Low', value: 'low' },
    { name: 'Medium', value: 'medium' },
    { name: 'High', value: 'high' },
];

export const DATE_OPTIONS = [
    {
        name: 'Any Time',
        value: 'all',
    },
    {
        name: 'Today',
        value: 'today',
    },
    {
        name: 'Yesterday',
        value: 'yesterday',
    },
    {
        name: 'Last 7 days',
        value: 'last_seven_days',
    },
    {
        name: 'Last 30 days',
        value: 'last_thirty_days',
    },
    {
        name: 'Last 3 months',
        value: 'last_three_months',
    },
    {
        name: 'Last 12 months',
        value: 'last_twelve_months',
    },
];
