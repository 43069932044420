import React, { useMemo, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Box } from '@mui/material';

// app components
import EmployeeAmountField from './components/EmployeeAmountField';
import StandardCheckboxGroup from './components/StandardCheckboxGroup';
import PaymentMethodRadio from './components/PaymentMethodRadio';
import AcknowledgementCheckbox from './components/AcknowledgementCheckbox';
import FeatureTable from './components/FeatureTable';
import FeaturePageHeader from './components/FeaturePageHeader';
import { AppForm, AppFormContext } from 'components/new_components/forms';
import { AppSubmitButton } from 'components/new_components/forms';

// utils
import { formatComma, generatePaymentNote } from './utils';
import { planRequestValidate } from './utils/validation';

// redux
import { connect } from 'react-redux';
import {
    GetAllFeatures,
    GetStandardsPrices,
    GetAllPaymentMethods,
    RequestAPlan,
    RequestCustomPlan,
} from 'store/actions/subscriptionActions';
//translation
import { useTranslation } from 'react-i18next';
const FeaturesPage = (props) => {
    const {
        goBack,
        planId,
        all_plans,
        GetAllFeatures,
        all_features,
        all_standards_prices,
        GetStandardsPrices,
        GetAllPaymentMethods,
        all_payment_methods,
        RequestAPlan,
        RequestCustomPlan,
    } = props;
    const theme = useTheme();
    //translation hook
    const { t } = useTranslation('accounts');
    // state
    const [checkboxVal, setCheckboxVal] = useState(false);
    const [amountOfEmployee, setAmountOfEmployee] = useState('');
    const [standards, setStandards] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [customPlanPayload, setCustomPlanPayload] = useState({});

    // memos
    const planDetails = useMemo(
        () =>
            all_plans?.find((plan) => plan?.id === planId) || {
                id: 'custom',
                name: 'Custom',
            },
        [planId, all_plans]
    );
    const totalCost = useMemo(() => {
        return standards.reduce((prev, current) => {
            const currentCost = all_standards_prices?.find((standard) => standard?.standard_type === current)?.amount;
            return prev + currentCost;
        }, planDetails?.price);
    }, [planDetails?.price, standards]);
    const paymentNote = useMemo(() => {
        if (paymentMethod) {
            const selectedMethod = all_payment_methods?.find((method) => method?.name === paymentMethod);
            return generatePaymentNote(selectedMethod, amountOfEmployee);
        } else {
            return null;
        }
    }, [paymentMethod, amountOfEmployee]);
    const paymentMethodsOptions = useMemo(() => {
        return all_payment_methods
            ? all_payment_methods
                  ?.sort((a, b) => a?.id - b?.id)
                  ?.map((method) => {
                      return {
                          label: method?.name,
                          disabled: totalCost < method?.min_cost,
                          value: method?.name,
                      };
                  })
            : [];
    }, [all_payment_methods, totalCost]);

    //  functions
    const handleCheckboxChange = () => {
        setCheckboxVal((value) => !value);
    };
    const handleStandardsChange = (value) => {
        let idx,
            newStandards = [...standards];
        for (let i = 0; i < newStandards.length; i++) {
            if (newStandards[i] === value) idx = i;
        }
        if (isNaN(idx)) {
            newStandards.push(value);
        } else {
            newStandards.splice(idx, 1);
        }
        setStandards(newStandards);
    };
    const handleEmployeeCount = (e) => {
        const { value } = e.target;
        setAmountOfEmployee(value);
    };
    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
    };
    const getCurrentValues = (values) => {
        setAmountOfEmployee(values?.amountOfEmployee);
        setStandards(values?.standards);
    };

    // async functions
    const getAllFeatures = async () => {
        // setLoading(true);
        const res = await GetAllFeatures();
        // setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't get the updated features.");
        }
    };
    const getStandardsPrices = async () => {
        // setLoading(true);
        const res = await GetStandardsPrices();
        // setLoading(false);
        if (!res.success) {
            toast.error('Something went wrong!');
        }
    };
    const getAllPaymentMethods = async () => {
        // setLoading(true);
        const res = await GetAllPaymentMethods();
        // setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const handlePlanRequest = async (values) => {
        const payload = {};
        payload.compliance_type = values?.standards?.join(', ');
        payload.company_size = values?.amountOfEmployee;
        if (planId !== 'custom') {
            payload.plan = planId;
            payload.payment_method = paymentMethod;
            payload.total_cost = totalCost;
        } else {
            payload.tag = 'custom';
            payload.status = 'processing';
            for (let key in customPlanPayload) {
                if (customPlanPayload[key]) {
                    payload[key] = customPlanPayload[key];
                }
            }
        }
        setSubmitting(true);
        const res = planId !== 'custom' ? await RequestAPlan(payload) : await RequestCustomPlan(payload);
        setSubmitting(false);
        if (res?.success) {
            toast.success(
                `You have successfully requested to subscribe to the ${planDetails?.name} plan. The management will contact you soon.`
            );
            goBack();
        } else {
            toast.error(res?.message);
        }
    };

    // constants
    const labelStyle = { fontWeight: 500, color: '#161B28' };

    // useEffect
    useEffect(() => {
        // const { standard, flexi } = paymentMethods;
        const paymentMethod = all_payment_methods?.find((method) => {
            if (totalCost >= method.min_cost && totalCost < (method?.max_cost || Infinity)) {
                return true;
            }
        });

        setPaymentMethod(paymentMethod?.name || '');
    }, [totalCost, planId]);
    useEffect(() => {
        getAllFeatures();
        getStandardsPrices();
        getAllPaymentMethods();
    }, []);

    return (
        <>
            <FeaturePageHeader goBack={goBack} plan={planDetails} />
            <Stack direction="row" spacing={5} sx={{ m: 5 }}>
                <Stack sx={{ width: '60%', maxWidth: 700 }}>
                    <FeatureTable
                        all_features={all_features}
                        plan={planDetails}
                        customPlanPayload={customPlanPayload}
                        setCustomPlanPayload={setCustomPlanPayload}
                    />
                </Stack>
                <Box sx={{ maxWidth: '40%' }}>
                    <AppForm
                        initialValues={{ amountOfEmployee: '', standards: [] }}
                        onSubmit={handlePlanRequest}
                        validate={planRequestValidate}
                    >
                        <AppFormContext getValues={getCurrentValues}>
                            <EmployeeAmountField
                                labelStyle={labelStyle}
                                onChange={handleEmployeeCount}
                                value={amountOfEmployee}
                            />
                            <StandardCheckboxGroup
                                labelStyle={labelStyle}
                                all_standards={all_standards_prices}
                                value={standards}
                                onChange={handleStandardsChange}
                            />
                            {planId !== 'custom' && (
                                <>
                                    <PaymentMethodRadio
                                        labelStyle={labelStyle}
                                        options={paymentMethodsOptions}
                                        value={paymentMethod}
                                        onChange={handlePaymentMethodChange}
                                    />
                                    <Stack sx={{ my: 3 }}>
                                        <Typography color="primary" sx={{ fontWeight: 600 }}>
                                            {t('plans.totalCost')}
                                            <Box component="span" sx={{ fontWeight: 700, ml: 1 }}>
                                                ₦ {formatComma(totalCost)}
                                            </Box>
                                        </Typography>
                                        {paymentMethod && (
                                            <Typography
                                                sx={{
                                                    color: theme.palette.gray[40],
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: 700,
                                                        color: theme.palette.primary[900],
                                                    }}
                                                >
                                                    {paymentMethod}:
                                                </span>{' '}
                                                {paymentNote}
                                            </Typography>
                                        )}
                                    </Stack>
                                </>
                            )}
                            <AcknowledgementCheckbox value={checkboxVal} onChange={handleCheckboxChange} />
                            <AppSubmitButton
                                text={t('plans.cta')}
                                variant="contained"
                                color="primary"
                                sx={{ width: '100%', p: 1 }}
                                loading={submitting}
                            />
                        </AppFormContext>
                    </AppForm>
                </Box>
            </Stack>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_plans: state?.subscriptionReducers?.all_plans,
        all_features: state?.subscriptionReducers?.all_features,
        all_standards_prices: state?.subscriptionReducers?.all_standards_prices,
        all_payment_methods: state?.subscriptionReducers?.all_payment_methods,
    };
};

export default connect(mapStateToProps, {
    GetAllFeatures,
    GetStandardsPrices,
    GetAllPaymentMethods,
    RequestAPlan,
    RequestCustomPlan,
})(FeaturesPage);
