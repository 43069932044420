import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const WelcomePage = (props) => {
    const { setPageType } = props;
    return (
        <Box>
            <Typography variant={'h2'} sx={{ marginTop: '3rem', fontSize: '24px', fontWeight: '700' }}>
                Hello,
            </Typography>
            <Typography sx={{ color: '#303034', fontSize: '16px', fontWeight: 500 }}>
                We want to hear from you. Please take a minute to fill out our survey.
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '6rem',
                }}
            >
                <Button
                    endIcon={<ArrowForwardIcon />}
                    variant="contained"
                    onClick={() => setPageType('partially_filled')}
                    sx={{ width: '400px', borderRadius: '4px', boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.1)' }}
                    size="large"
                >
                    Proceed to Survey
                </Button>
            </Box>
        </Box>
    );
};

export default WelcomePage;
