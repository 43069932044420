import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';

const InfoListItem = (props) => {
    const theme = useTheme();
    const { label, value } = props;
    return (
        <Stack sx={{ my: 1 }}>
            <Typography variant="span" sx={{ fontSize: 14, fontWeight: 400, color: theme.palette.gray[900] }}>
                {label}
            </Typography>
            <Typography variant="span" sx={{ fontWeight: 500, color: theme.palette.gray[900] }}>
                {value}
            </Typography>
        </Stack>
    );
};

export default InfoListItem;
