import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';

import ImplementerSidebar from 'components/new_components/merchant-layout/implementerLayout/Sidebar';
import ImplemneterHeader from 'components/new_components/merchant-layout/implementerLayout/Header';

// routes
import getRoutes from 'routes/implementerRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { SendDeviceToken, UpdateDeviceToken } from 'store/actions/merchantActions';
import { GetUserDetails } from 'store/actions/generalActions';
import { GetAllMerchantNotifications } from 'store/actions/notificationActions';
import { GetUserGuideDetails } from 'store/actions/guideActions';
import { messaging } from 'utils/firebase-config';
import { getToken, onMessage } from 'firebase/messaging';
import { GetUserDeviceToken } from 'store/actions/merchantActions';

const ImplementerLayout = () => {
    const [routes, setRoutes] = useState(undefined);

    //redux hooks
    const dispatch = useDispatch();

    const user_id = useSelector((state) => state?.generalReducers?.user_info?.id);
    const user_device_token = useSelector((state) => state?.merchantReducers?.user_device_token);

    // generate the routes
    const generateRoutes = (routes) => {
        const routesList = routes?.map((prop, key) => {
            if (prop.layout === '/implementer') {
                return <Route exact path={prop.layout + prop.path} component={prop.component} key={key} />;
            } else if (prop.subMenus) {
                return prop.subMenus.map((menu, key) => {
                    return <Route path={menu.layout + menu.path} component={menu.component} key={key} exact />;
                });
            } else {
                return null;
            }
        });
        if (routesList) {
            routesList.push(<Redirect to="/implementer/clients" key="redirect" />);
        }
        return routesList;
    };

    const askUserPermission = async () => {
        await Notification.requestPermission();

        // If user_device_token exists, return early
        if (user_device_token?.length) {
            const currentToken = await getToken(messaging, {
                vapidKey: 'BIRasTPC0RXXRBB7QyoYjjNvQ5M6KmfcIZEAjZwLL6F4bCRuLf-Ll0SjPSEuIV8cPyGAZ_Vl43I5OSPZjLbSDPk',
            });
            if (user_device_token?.[0]?.device_id !== currentToken) {
                await dispatch(UpdateDeviceToken({ device_id: currentToken }, user_device_token?.[0]?.id));
            }
            return;
        }

        // Get registration token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        getToken(messaging, {
            vapidKey: 'BIRasTPC0RXXRBB7QyoYjjNvQ5M6KmfcIZEAjZwLL6F4bCRuLf-Ll0SjPSEuIV8cPyGAZ_Vl43I5OSPZjLbSDPk',
        })
            .then(async (currentToken) => {
                if (currentToken) {
                    SendDeviceToken({ device_id: currentToken, user: user_id });
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
    };

    useEffect(() => {
        const getUserDetails = async () => {
            const res = await dispatch(GetUserDetails());
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getUserDetails();
    }, []);

    // useEffect(() => {
    //     const getAllMerchants = async () => {
    //         const res = await dispatch(getAuditorMerchants(auditor_id));
    //         if (!res?.success) {
    //             toast.error(res?.message);
    //         }
    //     };
    //     getAllMerchants();
    // }, [auditor_id]);

    useEffect(() => {
        const routes = getRoutes();
        setRoutes(routes);
    }, [localStorage.getItem('i18nextLng')]);

    useEffect(() => {
        const getUserGuideDetails = async () => {
            const res = await dispatch(GetUserGuideDetails());
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getUserGuideDetails();
    }, []);
    useEffect(() => {
        const getAllMerchantNotifications = async () => {
            const res = await dispatch(GetAllMerchantNotifications());
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllMerchantNotifications();
    }, []);
    useEffect(() => {
        const getUserDeviceToken = async () => {
            const res = await dispatch(GetUserDeviceToken());
            if (!res.success) {
                toast.error(res?.message);
            }
        };
        getUserDeviceToken();
    }, []);
    useEffect(() => {
        askUserPermission();
        // Handle incoming messages. Called when:
        // - a message is received while the app has focus
        // - the user clicks on an app notification created by a service worker
        //   `messaging.onBackgroundMessage` handler.

        onMessage(messaging, (payload) => {
            // ...
            toast.info(payload?.notification?.body);
        });
    }, [user_device_token]);
    return routes ? (
        <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
            <ImplementerSidebar routes={routes} />
            <div className="flex flex-1 flex-col bg-[#F1F5F9]">
                <ImplemneterHeader />
                <Box
                    // ref={sectionRef}
                    component="main"
                    id="main_section_container"
                    sx={{
                        flexGrow: 1,
                        p: {
                            xs: 0,
                            md: 0,
                        },
                        width: { sm: `calc(100% - 275px)` },
                        mt: { xs: '70px', md: '90px' },
                        maxWidth: '100%',
                        mb: '80px',
                        height: { xs: 'calc(100% - 70px)', md: 'calc(100% - 90px)', lg: 'calc(100% - 90px)' },
                        overflow: 'auto',
                        position: 'fixed',
                        right: 0,
                    }}
                >
                    <Box sx={{ height: '100%', ml: 1.5, mr: 3 }}>
                        <Switch>{generateRoutes(routes)}</Switch>
                    </Box>
                </Box>
            </div>
        </Box>
    ) : null;
};

export default ImplementerLayout;
