import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getType, getUserToken } from 'utils';

// components
import AdminSidebar from 'components/Sidebar/AdminSidebar';
import AdminHeader from 'components/Headers/AdminHeader';
import SessionTimeout from 'components/sessionTimeout/SessionTimeout';

// styles
// import 'antd/dist/antd.css';
import '../../assets/css/admin.css';

// routes
import getRoutes from 'routes/adminRoutes';
import twofactorAuth from './twofactorAuth';
import Setup2FaModal from 'views/merchant/AccountSetup/TwoFactorAuth/Setup2FaModal';

//redux
import { connect, useSelector } from 'react-redux';
import { GetUserDetails } from 'store/actions/generalActions';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const { Content } = Layout;

const AdminDashboard = (props) => {
    const { mfa, GetUserDetails } = props;
    const location = useLocation();
    const [collapsed, setcollapsed] = useState(false);
    const [twoFactorModal, setTwoFactorModal] = useState(false);
    const userDetails = useSelector((state) => state?.generalReducers?.user_info);

    const isAuthenticated = getUserToken('token');
    // const isAuthenticated = getUserToken();

    const toggle = () => {
        setcollapsed(!collapsed);
    };
    //async actions
    useEffect(() => {
        const getUserDetails = async () => {
            const res = await GetUserDetails();
            if (!res.success) {
                toast.error(res?.message);
            }
        };
        getUserDetails();
    }, []);
    console.log({ mfa, userDetails });
    useEffect(() => {
        if (location?.pathname !== '/admin/setup-two-factor-authenication' && !mfa) {
            setTwoFactorModal(true);
        } else {
            setTwoFactorModal(false);
        }
    }, [location?.pathname, mfa]);

    const role = getType('type');

    const routes = getRoutes(role);
    return (
        <Layout style={{ height: '100vh' }} className="admin-dashboard">
            <AdminSidebar routes={routes} collapsed={collapsed} />
            <Layout className="site-layout">
                <AdminHeader toggle={toggle} collapsed />
                <Content
                    className="site-layout-background"
                    style={{
                        padding: 24,
                        height: '85vh',
                        overflowY: 'auto',
                        background: 'rgba(211, 211, 211, 0.3)',
                    }}
                >
                    <Switch>
                        {routes.map((route, index) => {
                            const { layout, path, Component, subMenus } = route;
                            return !subMenus ? (
                                <Route
                                    path={`${layout}/${path}`}
                                    key={index}
                                    component={Component}
                                    exact={path === '' ? true : false}
                                />
                            ) : (
                                subMenus?.map((menu, id) => {
                                    return (
                                        <Route
                                            path={`${menu.layout}/${menu.path}`}
                                            key={id}
                                            component={menu.Component}
                                            exact={menu.path === '' ? true : false}
                                        />
                                    );
                                })
                            );
                        })}
                        <Route path="/admin/setup-two-factor-authenication" component={twofactorAuth} exact={false} />
                        <Redirect to="/admin" />
                    </Switch>
                </Content>
            </Layout>
            {isAuthenticated ? <SessionTimeout isAuthenticated={isAuthenticated} /> : null}

            <Setup2FaModal open={twoFactorModal} />
        </Layout>
    );
};

const mapStateToProps = (state) => {
    return {
        mfa: state?.generalReducers?.user_info?.mfa,
    };
};
export default connect(mapStateToProps, { GetUserDetails })(AdminDashboard);
