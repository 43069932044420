import React, { useEffect, useState } from 'react';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { IconButton, Typography, Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { GetScanAi } from 'store/actions/automatedScansActions';
import CircularLoader from 'components/Loaders/CircularLoader';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

const ExemptScanModal = (props) => {
    const { open, onClose, GetScanAi, scan, setScan } = props;

    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [scan_ai, setScan_ai] = useState([]);
    const [text, setText] = useState('');
    const [fullText, setFullText] = useState({});
    const [index, setIndex] = useState(0);

    const getScanAi = async () => {
        const body = { scan_id: scan?.id };
        setLoading(true);
        const res = await GetScanAi(body);
        setLoading(false);
        if (res?.success) {
            setScan_ai(res?.data);
            setFullText(res?.data[0]);
        } else {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (open && scan?.id) {
            setFullText({});
            setText('');
            setIndex(0);
            getScanAi();
        }
    }, [open && scan]);

    useEffect(() => {
        if (open && index < fullText?.length) {
            setTimeout(() => {
                setText(text + fullText[index]);
                setIndex(index + 1);
            }, 5);
        }
    }, [open, index, fullText]);

    const handleClose = () => {
        onClose();
        setFullText({});
        setText('');
        setIndex(0);
        setScan_ai([]);
        setScan({});
    };

    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            title={
                <Box>
                    <Typography
                        sx={{
                            fontSize: '11px',
                            fontWeight: 600,
                            color: '#FFFFFF',
                            backgroundColor: '#55BE8B',
                            p: 0.3,
                            borderRadius: '3px',
                        }}
                    >
                        How To fix
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '11px',
                            fontWeight: 400,
                            color: '#5E5E62',
                            p: 0.3,
                            borderRadius: '3px',
                        }}
                    >
                        {scan?.scan_name}
                    </Typography>
                </Box>
            }
            headerAction={
                <IconButton color="disabled" onClick={handleClose}>
                    <Close />
                </IconButton>
            }
            titleStyle={{ borderBottom: `1px solid ${theme.palette.neutral[90]}` }}
            width={540}
            noBorder
        >
            {loading ? (
                <CircularLoader message={'Generating response...'} />
            ) : (
                <>
                    {scan_ai?.length > 0 ? (
                        <Box sx={{ my: 3 }}>
                            {text?.split('\n')?.map((item, index) => (
                                <Typography
                                    key={index}
                                    sx={{
                                        fontSize: '11px',
                                        fontWeight: 400,
                                        color: '#5E5E62',
                                        p: 0.3,
                                        borderRadius: '3px',
                                    }}
                                >
                                    {item}
                                </Typography>
                            ))}
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                            <Typography sx={{ color: '#5E5E62', fontSize: '14px', fontWeight: 700 }}>
                                No Fix Found
                            </Typography>
                        </Box>
                    )}
                </>
            )}
        </AppCenteredModal>
    );
};

const mapStateToProps = (state) => {
    return {
        scan_ai: state?.automatedScansReducers?.scan_ai,
    };
};

export default connect(mapStateToProps, { GetScanAi })(ExemptScanModal);
