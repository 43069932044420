import { Box } from '@mui/material';
import React from 'react';
// import { BiDownload } from 'react-icons/bi';
import { connect } from 'react-redux';
// import AppButtonGroup from 'components/new_components/AppButtonGroup';
import { baseUrl } from 'config';
import AppButton from 'components/new_components/AppButton';

//translations
import { useTranslation } from 'react-i18next';

const IntelligenceAction = (props) => {
    const { company_id } = props;

    //translation
    const { t } = useTranslation('riskAssessment');

    return (
        <Box sx={{ paddingTop: 2.5 }}>
            <a
                href={`${baseUrl}${
                    window.localStorage.i18nextLng?.includes('en') ? 'en' : window.localStorage.i18nextLng
                }/api/scans/spool_intelligence_report/?merchant_id=${company_id}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <AppButton name={t('riskRegisterTab.spoolCBNReport')} variant="outlined" />
            </a>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        company_id: state?.authReducers?.company_id,
    };
};

export default connect(mapStateToProps, {})(IntelligenceAction);
