import { validation } from 'validate';

export const statusUpdateValidation = (values) => {
    const errors = {};
    const commentValidate = validation(values.comment, 'Comment');

    if (!commentValidate.isValid) {
        errors.comment = commentValidate.errorMessage;
    }

    return errors;
};
