import React, { useEffect, useState } from 'react';
// import AppViewModal from 'components/new_components/AppViewModal';
import { ArrowBackIosNew } from '@mui/icons-material';
import { Box, Drawer, Slide, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import AppSelectInput from 'components/new_components/AppSelectInput';
import AppSwitch from 'components/new_components/AppSwitch';
import { connect } from 'react-redux';
import { GetGroupScanResults } from 'store/actions/automatedScansActions';
import { UpdateUserScope } from 'store/actions/integrationsActions';
import { toast } from 'react-toastify';
import CircularLoader from 'components/Loaders/CircularLoader';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const ScopeViewModal = (props) => {
    const theme = useTheme();
    const {
        open,
        handleClose,
        all_group_automated_scans,
        GetGroupScanResults,
        environments,
        UpdateUserScope,
        integration,
    } = props;
    const [checked, setChecked] = useState(false);
    // const [checked_, setChecked_] = useState(false);
    const [current_value, setCurrrent_value] = useState('');
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');

    // Current enviroment
    const current_enviroment = environments?.find((current) => current?.id === Number(current_value));
    // filtered integrations
    const filtered_scan_sub_categories = all_group_automated_scans?.filter((item) =>
        integration?.integration_scopes?.includes(item?.id)
    );
    // modified integrations
    const all_integrations = filtered_scan_sub_categories?.map((integration) => {
        return {
            name: integration?.name,
            value: integration?.id,
        };
    });
    // Modified enviroments
    const modified_environments = environments?.map((environment) => {
        return {
            name: environment?.name,
            value: environment?.id,
        };
    });

    // functions
    const checkFunction = (a, b) => b?.every(Set.prototype.has, new Set(a));
    const check_all = checkFunction(current_enviroment?.user_scopes, integration?.integration_scopes);

    const handleDetailsChange = (e) => {
        setCurrrent_value(e.target.value);
        const enviroment_name = environments?.find((environment) => environment?.id === JSON.parse(e.target.value));
        setName(enviroment_name?.name);
    };
    const handleCheck = async (event, id) => {
        setChecked(!checked);
        const user_scopes = current_enviroment?.user_scopes;
        const filtered_integration = user_scopes?.filter((int) => int !== id);
        const body = {
            user_scopes: event.target.checked === false ? filtered_integration : [...user_scopes, id],
        };
        const res = await UpdateUserScope(body, current_value);
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };
    const handleCheckAll = async () => {
        const all_scopes = all_group_automated_scans?.map((scope) => scope?.id);
        const body = {
            user_scopes: all_scopes,
        };
        const res = await UpdateUserScope(body, current_value);
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };
    const getAllAutomatedScanGroups = async () => {
        setLoading(true);
        const res = await GetGroupScanResults();
        setLoading(false);
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (open) {
            getAllAutomatedScanGroups();
            setCurrrent_value(environments[0]?.id);
            const enviroment_name = environments?.find((environment) => environment?.id === environments[0]?.id);
            setName(enviroment_name?.name);
        }
        return () => {
            setLoading(false);
        };
    }, [open]);
    return (
        <Drawer
            width={600}
            open={open}
            handleClose={handleClose}
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={handleClose}
            anchor={'right'}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 370,
                    borderRadius: 4,
                    width: 600,
                    flex: 1,
                    overflow: 'auto',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <Box
                sx={{
                    borderBottom: `1px solid ${theme.palette.primary[100]}`,
                }}
            >
                <Stack
                    sx={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: theme.palette.primary[900],
                        padding: 2,
                        width: '100%',
                    }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ArrowBackIosNew
                            sx={{
                                width: 20,
                                height: 20,
                                color: theme.palette.gray[30],
                                mr: 3,
                                borderRadius: '50%',
                                cursor: 'pointer',
                            }}
                            onClick={handleClose}
                        />
                        <Typography
                            variant="h2"
                            sx={{
                                color: '#46464A',
                                fontWeight: 700,
                                fontSize: '16px',
                                marginTop: 1,
                            }}
                        >
                            Scope this resource
                        </Typography>
                    </Box>
                    {(integration?.manual_configured || integration?.oauth_configured) && (
                        <Box>
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '10px',
                                    color: theme.palette.gray[40],
                                    float: 'right',
                                }}
                            >
                                {environments?.length} Environments
                            </Typography>

                            <AppSelectInput
                                name="Environments"
                                options={modified_environments}
                                defaultValue={name}
                                sx={{ borderRadius: '8px', fontSize: 12, mt: '-1rem' }}
                                value={current_value}
                                onChange={handleDetailsChange}
                                size="small"
                            />
                        </Box>
                    )}
                </Stack>
            </Box>
            <Box sx={{ mt: 4, mx: 2 }}>
                <Box sx={{ backgroundColor: '#F8F8F8', pl: 3, pr: 8, py: 1.5, borderRadius: '10px', mb: 2 }}>
                    <Typography sx={{ fontWeight: 400, fontSize: '12px', color: theme.palette.gray[40] }}>
                        Scoping a resource assists the system in deciding which regions need to be automatically
                        scanned.
                    </Typography>
                </Box>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography
                            sx={{
                                color: theme.palette.gray[40],
                                fontSize: '1ppx',
                                fontWeight: 600,
                                textTransform: 'capitalize',
                            }}
                        >
                            {name} Scopes
                        </Typography>
                        {(integration?.manual_configured || integration?.oauth_configured) && (
                            <AppCheckboxInput
                                label="Check All"
                                onChange={handleCheckAll}
                                indeterminate={check_all}
                                disabled={check_all}
                            />
                        )}
                    </Box>
                </Box>
                {loading ? (
                    <CircularLoader />
                ) : (
                    <Box sx={{ mt: 1, maxHeight: '500px', overflow: 'auto' }}>
                        {all_integrations?.map((integration) => (
                            <Box
                                key={integration?.value}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderBottom: '1px solid #F0F0FA',
                                    padding: environments?.length > 0 ? 0 : '1rem 0',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: theme.palette.gray[40],
                                        fontSize: '11px',
                                        fontWeight: 400,
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {integration?.name.replace(/_/g, ' ')}
                                </Typography>
                                {environments?.length > 0 && (
                                    <AppSwitch
                                        checked={current_enviroment?.user_scopes?.includes(integration?.value)}
                                        // checked={checked_}
                                        // onChange={(event) => setChecked_(event.target.checked)}
                                        onChange={(event) => handleCheck(event, integration?.value)}
                                    />
                                )}
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>
        </Drawer>
    );
};

const mapStateToProps = (state) => {
    return {
        all_group_automated_scans: state?.automatedScansReducers?.all_group_automated_scans,
    };
};

export default connect(mapStateToProps, { GetGroupScanResults, UpdateUserScope })(ScopeViewModal);
