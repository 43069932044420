import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { Cancel } from '@mui/icons-material';

//translation
import { useTranslation } from 'react-i18next';

const EmptyReleaseContent = () => {
    const theme = useTheme();

    //translation
    const { t } = useTranslation('common');
    return (
        <Stack alignItems="center" justifyContent="center" sx={{ color: theme.palette.gray[300], my: 'auto' }}>
            <Cancel sx={{ fontSize: 60 }} />
            <Box sx={{ fontSize: 25, fontWeight: 600 }}>{t('noRelease')}</Box>
        </Stack>
    );
};

export default EmptyReleaseContent;
