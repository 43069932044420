import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Stack } from '@mui/material';
import { Download } from '@mui/icons-material';
import { MoreVert } from '@mui/icons-material';
import RequestMenuItem from 'components/new_components/RequestMenuItem';
import AppTableActionMenu from 'components/new_components/app-table/AppTableActionMenu';

//redux
import { connect, useSelector } from 'react-redux';
// import { GetMerchantAsvscanReportById } from 'store/actions/merchantActions';

//translations
import { useTranslation } from 'react-i18next';

const PCIScansTableActions = (props) => {
    const theme = useTheme();
    const { row, openDownloadPCIReportModal, openRerunModal, openChangeScheduleModal } = props;

    // state
    const [anchor, setAnchor] = useState(null);
    //accessing values from the redux store
    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const role = useSelector((state) => state?.generalReducers?.user_info?.role);

    //translation
    const { t } = useTranslation('pciscans');

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);
    const handleOpenReportModal = () => {
        closeMenu();
        openDownloadPCIReportModal(row?.id);
    };

    const handleOpenRerunModal = () => {
        const payload = {
            name: row?.name,
            ip_address: row?.ip_address,
        };
        closeMenu();
        openRerunModal(payload);
    };

    const openUpdateScheduleModal = () => {
        closeMenu();
        const payload = {
            name: row?.name,
            ip_address: row?.ip_address,
            schedule: row?.runschedule,
        };
        openChangeScheduleModal('update', payload, row?.id);
    };

    return (
        <Stack direction="row">
            <IconButton
                sx={{ color: theme.palette.gray[900] }}
                // component="a"
                // target="_blank"
                // href={row?.file_field}
                onClick={handleOpenReportModal}
                disabled={row?.progress === 'running'}
                // disabled={!row?.file_field}
            >
                <Download />
            </IconButton>
            <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <AppTableActionMenu anchor={anchor} closeMenu={closeMenu}>
                {user_type !== 'auditor' && role !== 'auditor' && (
                    <RequestMenuItem
                        header={t('pciscansTableAction.header1')}
                        subheader={t('pciscansTableAction.subheader1')}
                        onClick={handleOpenRerunModal}
                        disabled={
                            row?.progress === 'running' ||
                            row?.progress === 'rerunning' ||
                            row?.uploaded_by?.toLowerCase() !== 'clone systems'
                        }
                    />
                )}

                <RequestMenuItem
                    header={t('pciscansTableAction.header2')}
                    subheader={t('pciscansTableAction.subheader2')}
                    onClick={handleOpenReportModal}
                    disabled={row?.progress === 'running'}
                />
                {user_type !== 'auditor' && role !== 'auditor' && (
                    <RequestMenuItem
                        header={t('pciscansTableAction.header3')}
                        subheader={t('pciscansTableAction.subheader3')}
                        onClick={openUpdateScheduleModal}
                        disabled={row?.uploaded_by?.toLowerCase() !== 'clone systems'}
                    />
                )}
            </AppTableActionMenu>
        </Stack>
    );
};

export default connect()(PCIScansTableActions);
