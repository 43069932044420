import AppDrawer from 'components/new_components/AppDrawer';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllFraudGuardianList } from 'store/actions/vendorActions';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import LoadingState from 'components/new_components/LoadingState';
import AppLoadingButton from 'components/new_components/AppLoadingButton';

const AMLSettingsModal = (props) => {
    const { open, handleClose, sources, setSources, continous_monitoring, setcontinous_monitoring } = props;
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const all_fraudguardian_list = useSelector((state) => state?.vendorReducers?.all_fraudguardian_list);

    const getAllFraudList = async () => {
        const res = await dispatch(GetAllFraudGuardianList());
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        getAllFraudList();
    }, [open]);

    useEffect(() => {
        const all_ids = all_fraudguardian_list?.map((item) => item?.id);
        setSources(all_ids);
    }, [open, all_fraudguardian_list]);

    const handleChange = (id) => {
        const modified_sources = [...sources];
        const one_item = modified_sources?.findIndex((item) => item === id);
        if (sources?.includes(id)) {
            modified_sources.splice(one_item, 1);
            setSources(modified_sources);
        } else {
            modified_sources.push(id);
            setSources(modified_sources);
        }
    };
    return (
        <AppDrawer
            open={open}
            handleClose={handleClose}
            title={
                <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '16px' }}>AML Check settings</Typography>
            }
            icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
            noShadow
            noClose
            width="600px"
            height="520px"
            action={
                <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    <Button
                        variant="contained"
                        sx={{
                            textTransform: 'inherit',
                            backgroundColor: '#FFFFFF',
                            borderRadius: 0,
                            color: '#334155',
                            '&:hover': {
                                backgroundColor: '#FFFFFF',
                                color: '#334155',
                            },
                        }}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                    <AppLoadingButton
                        variant="contained"
                        text="Save"
                        sx={{
                            marginLeft: '2rem',
                            backgroundColor: '#202D66',
                            borderRadius: 0,
                            '&:hover': {
                                backgroundColor: '#202D66',
                            },
                        }}
                        onClick={handleClose}
                    />
                </Box>
            }
        >
            {loading ? (
                <LoadingState />
            ) : (
                <Box sx={{ mx: 3.5, mt: 3 }}>
                    <Typography sx={{ color: '#202D66', fontWeight: 700, fontSize: '14px' }}>Sources</Typography>
                    <Grid container>
                        {all_fraudguardian_list?.map((sub_module) => {
                            return (
                                <Grid item xl={6} lg={6} xs={6} sm={6}>
                                    <AppCheckboxInput
                                        value={sources?.includes(sub_module?.id)}
                                        onChange={() => handleChange(sub_module?.id)}
                                        label={
                                            <span
                                                style={{
                                                    fontSize: '11px',
                                                    color: '#5E5E62',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {sub_module?.name}
                                            </span>
                                        }
                                        size="5"
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>

                    <Box>
                        <AppCheckboxInput
                            value={continous_monitoring}
                            onChange={() => setcontinous_monitoring(!continous_monitoring)}
                            label={
                                <Typography sx={{ color: '#395BA9', fontSize: '13px', fontWeight: 500 }}>
                                    {' '}
                                    Activate continuous monitoring
                                </Typography>
                            }
                        />
                    </Box>
                </Box>
            )}
        </AppDrawer>
    );
};

export default AMLSettingsModal;
