import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { TypeOption } from '../utils/constants';
import { connect } from 'react-redux';
import { GetAllMerchantRiskAssessments } from 'store/actions/merchantActions';
import SearchIcon from '../../../../../assets/img/Searchicon.svg';
import VectorDown from 'assets/img/vector-down.svg';

const AssetHeading = (props) => {
    // Props
    const { onSearch, type, setType } = props;

    const [typeAnchorEl, setTypeAnchorEl] = useState(null);
    // Functions
    const typeOpenMenu = (e) => setTypeAnchorEl(e.target);
    const typeCloseMenu = () => setTypeAnchorEl(null);

    const handleTypeChange = (value) => {
        setType(value);
    };
    return (
        <div className="flex items-center gap-4 ml-[-1rem]">
            <div className="relative">
                <input
                    placeholder="Search..."
                    onChange={onSearch}
                    className="text-gray-400 text-sm font-normal placeholder:text-gray-400 border border-gray-200 py-2 rounded-[4px] w-[230px] px-[30px]"
                />
                <img src={SearchIcon} alt="SearchIcon" className="absolute left-2 top-2.5 w-[17px]" />
            </div>
            <div>
                <button
                    className={`border border-gray-200 py-2 w-fit ${
                        type?.value === 'local'
                            ? 'text-[#395BA9]'
                            : type?.value !== 'all'
                            ? 'text-[#395BA9]'
                            : 'text-gray-500'
                    } text-[13px] font-medium rounded-[4px] flex justify-between items-center px-2 gap-2`}
                    onClick={typeOpenMenu}
                >
                    <p className="text-[#64748B] font-medium text-[13px]">Type: {type?.name}</p>
                    <img src={VectorDown} alt="VectorDown" className="text-neutral-40 w-[6px] h-[4px] font-normal" />
                </button>
            </div>
            <Menu open={Boolean(typeAnchorEl)} anchorEl={typeAnchorEl} onClose={typeCloseMenu}>
                {TypeOption?.map((option) => (
                    <MenuItem
                        sx={{ color: '#64748B', fontSize: '14px', fontWeight: 500 }}
                        onClick={() => {
                            handleTypeChange(option);
                            typeCloseMenu();
                        }}
                    >
                        {option?.name}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_risk_assessments: state?.merchantReducers?.all_merchant_risk_assessments,
    };
};
export default connect(mapStateToProps, { GetAllMerchantRiskAssessments })(AssetHeading);
