import React, { useState, useRef, useEffect } from 'react';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import { Box, Typography, Fade, Stack, Paper, IconButton, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { BsSend } from 'react-icons/bs';
import { AVAILABLE_PROMPTS } from './util';
import { connect } from 'react-redux';
import { GetAIAnswersToPrompt } from 'store/actions/dashboardActions';
import Typewriter from 'typewriter-effect';

const SmartDashboardAI = (props) => {
    const typewriterRef = useRef(null);

    const { open, handleClose, GetAIAnswersToPrompt } = props;
    const [userInput, setUserInput] = useState('');
    const [conversation, setConversation] = useState([]);
    const [fetching, setFetching] = useState(false);
    const theme = useTheme();

    //handle Saving of Prompts
    const handlePromptSave = (message) => {
        setConversation([
            ...conversation,
            {
                id: conversation?.length + 1,
                text: message,
                type: 'prompt',
            },
        ]);

        if (userInput) {
            setUserInput('');
        }
    };

    //handle input change
    const handleUserInput = (e) => {
        setUserInput(e.target.value);
    };
    //close and clear all prompts and inputs state
    const onCloseAll = () => {
        handleClose();
        setUserInput('');
        setConversation([]);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            // 👇 Get input value
            handlePromptSave(userInput);
        }
    };

    const scrollIntoView = () => {
        if (typewriterRef?.current) {
            typewriterRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    //async action
    const getAIAnswersToPrompt = async (payload) => {
        setFetching(true);
        const res = await GetAIAnswersToPrompt(payload);
        setFetching(false);
        if (res?.success) {
            setConversation([
                ...conversation,
                { id: conversation?.length + 1, text: res?.data?.[0], type: 'response' },
            ]);
        }
    };

    useEffect(() => {
        if (conversation?.[conversation.length - 1]?.type === 'prompt') {
            // as long after prompt is saved, make request; don't watch for change in user prompt
            getAIAnswersToPrompt({ question: conversation?.[conversation.length - 1]?.text });
        }

        // scrolls into view if is response and is the last convo item
        if (
            conversation?.[conversation.length - 1]?.type === 'response' &&
            conversation?.[conversation.length - 1].id === conversation.length
        ) {
            scrollIntoView();
        }
    }, [conversation]);

    const replaceWithLinkeBreakers = (text) => text?.replaceAll(/\n/g, '<br>') ?? null;

    return (
        <TrapFocus open disableAutoFocus disableEnforceFocus>
            <Fade appear={true} in={open}>
                <Paper
                    role="dialog"
                    aria-modal="false"
                    aria-label="Cookie banner"
                    square
                    variant="outlined"
                    tabIndex={-1}
                    sx={{
                        position: 'fixed',
                        bottom: '7%',
                        right: '3%',
                        m: 0,
                        width: '454px',
                        maxHeight: '75vh',
                        height: '100%',
                        minHeight: '300px',
                        borderRadius: '8px',
                    }}
                >
                    <div className="flex flex-column h-full">
                        <div>
                            <Box
                                sx={{
                                    backgroundColor: theme.palette.primary[20],
                                    pt: 2,
                                    pb: 3,
                                    px: 1.5,
                                    borderTopLeftRadius: '8px',
                                    borderTopRightRadius: '8px',
                                    height: '110px',
                                }}
                            >
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <img
                                            src="/img/dashboard-ai-white.svg"
                                            className="w-[50px]"
                                            alt="Dashboard AI icon"
                                        />
                                        <Typography
                                            sx={{ fontSize: 14, fontWeight: 600, color: theme.palette.neutral[90] }}
                                        >
                                            Ask Vifly
                                        </Typography>
                                    </Stack>

                                    <Box>
                                        <IconButton
                                            onClick={onCloseAll}
                                            sx={{
                                                backgroundColor: theme.palette.primary[20],
                                                color: theme.palette.neutral[90],
                                            }}
                                        >
                                            <Close />
                                        </IconButton>
                                    </Box>
                                </Stack>
                                <Typography
                                    sx={{ mt: 1, fontSize: 13, color: theme.palette.neutral[90], fontWeight: 500 }}
                                >
                                    Hello, I am here to answer any of your questions
                                </Typography>
                            </Box>
                            {/* <Box> */}
                            <Stack
                                direction="row"
                                flexWrap="wrap"
                                columnGap={0.7}
                                rowGap={0.7}
                                sx={{ borderBottom: `1px solid ${theme.palette.neutral[95]}`, p: 1.5 }}
                            >
                                {AVAILABLE_PROMPTS?.map((prompt, index) => (
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            minWidth: 'unset',
                                            p: 1,
                                            fontSize: 11,
                                            textTransform: 'capitalize',
                                            whiteSpace: 'nowrap',
                                            color: theme.palette.gray[40],
                                            border: `1px solid #E1E2EC`,
                                            '&:hover': {
                                                border: `1px solid #E1E2EC`,
                                            },
                                        }}
                                        disabled={fetching}
                                        key={index}
                                        onClick={() => handlePromptSave(prompt)}
                                    >
                                        {prompt}
                                    </Button>
                                ))}
                            </Stack>
                        </div>

                        <Box
                            sx={{
                                flexGrow: 1,
                                overflowY: 'auto',
                                px: 1.5,
                                pt: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: theme.palette.primary[900] + '30',
                                    borderRadius: '10px',
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'grid',
                                    flexDirection: 'column',
                                    '&::-webkit-scrollbar': {
                                        width: '8px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        backgroundColor: 'transparent',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: theme.palette.primary[900] + '30',
                                        borderRadius: '10px',
                                    },
                                }}
                            >
                                {conversation?.map((convo, index) => {
                                    return (
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            sx={{ justifySelf: convo.type === 'prompt' ? 'flex-end' : 'flex-start' }}
                                            ref={
                                                convo.type === 'response' && index === conversation.length - 1
                                                    ? typewriterRef
                                                    : null
                                            }
                                        >
                                            {convo.type === 'response' && (
                                                <img
                                                    src="/img/dashboard-ai-white.svg"
                                                    className="w-[15px]"
                                                    alt="Dashboard AI icon"
                                                />
                                            )}
                                            <Box
                                                sx={{
                                                    backgroundColor:
                                                        convo.type === 'prompt' ? '#F5F6F6' : theme.palette.primary[50],
                                                    mb: 1.5,
                                                    borderRadius:
                                                        convo.type === 'prompt'
                                                            ? '12px 12px 0px 12px'
                                                            : '12px 12px 12px 0px',
                                                    width: 'fit-content',
                                                    minHeight: '33px',
                                                    height: 'fit-content',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        p: 1,
                                                        fontSize: 11,
                                                        fontWeight: 500,
                                                        color:
                                                            convo.type === 'prompt'
                                                                ? theme.palette.neutral[50]
                                                                : theme.palette.white.main,
                                                    }}
                                                    key={index}
                                                >
                                                    {convo.type !== 'prompt' ? (
                                                        <Typewriter
                                                            onInit={(typewriter) => {
                                                                typewriter
                                                                    .typeString(replaceWithLinkeBreakers(convo?.text))
                                                                    .callFunction(() => {
                                                                        console.log('String typed out!');
                                                                    })
                                                                    .changeDelay(10)
                                                                    .start();
                                                            }}
                                                            options={{
                                                                loop: false,
                                                                delay: '10',
                                                                deleteSpeed: 0,
                                                            }}
                                                        />
                                                    ) : (
                                                        convo.text
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    );
                                })}
                            </Box>
                        </Box>
                        <Stack
                            direction="row"
                            sx={{
                                alignSelf: 'end',
                                px: 1.5,
                                py: 1,
                                borderTop: `1px solid ${theme.palette.neutral[95]}`,
                                maxHeight: '43px',
                                width: '100%',
                            }}
                            gap={2}
                        >
                            <input
                                placeholder="enter a prompt"
                                value={userInput}
                                className="basis-[90%] text-[13px] outline-none"
                                onChange={handleUserInput}
                                onKeyDown={handleKeyDown}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ minWidth: 'unset', px: 1, basis: '10%' }}
                                onClick={() => handlePromptSave(userInput)}
                                disabled={fetching}
                            >
                                <BsSend />
                            </Button>
                        </Stack>
                    </div>
                    {/* </Box> */}
                </Paper>
            </Fade>
        </TrapFocus>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { GetAIAnswersToPrompt })(SmartDashboardAI);
