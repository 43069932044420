import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button, Upload, Select } from 'antd';
import { AiOutlineInbox } from 'react-icons/ai';

// utils
import { getOptionLabel, removeNullFields } from 'utils';

// redux
import { connect } from 'react-redux';
import { EditDocument, DeleteDocument } from 'store/actions/adminActions';

const { Option } = Select;

const EditDocumentModal = (props) => {
    //Props
    const {
        open,
        handleClose,
        one_document,
        all_documents_by_alttags,
        tag,
        EditDocument,
        DeleteDocument,
        payload,
        titleLabel,
        descLabel,
        groupIdLabel,
    } = props;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [mode, setMode] = useState('new');
    const [extendingDocID, setExtendingDocID] = useState();
    const [islinked, setisLinked] = useState(false);

    // state
    const { Dragger } = Upload;
    const draggers = {
        name: 'default',
        action: null,
        multiple: false,
        maxCount: 1,
        onChange(info) {
            setData({ ...data, default: info.fileList[0]?.originFileObj });
        },
    };

    // functions
    const onSearch = (value) => {
        console.log('search:', value);
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const closeAll = () => {
        setData({});
        setExtendingDocID(null);
        handleClose();
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // if the record is not to be linked to another document
        if (mode === 'new' || (mode === 'extend' && !extendingDocID)) {
            handleEditDocument();
        } else {
            // if it is to be linked to another document
            handleDocumentExtension();
        }
    };
    const createFormData = () => {
        const formData = new FormData();
        const newPayload = { ...payload, ...data };
        for (let field in newPayload) {
            if (!field?.includes('_en') && !field?.includes('_fr')) {
                formData.append(field, newPayload[field]);
            }
        }
        return formData;
    };
    const handleEditDocument = async () => {
        //collating the form data
        const formData = createFormData();
        // if the a file was uploaded the append it to the form data.
        if (typeof data?.default === 'object') {
            formData.append('default', data?.default);
        }
        // get the group ID (requirement no, category id or annex id) based on the tag
        const groupID = payload?.[groupIdLabel];
        setLoading(true);
        // send the request
        const res = await EditDocument(formData, data?.id, tag, groupID);
        setLoading(false);
        if (res.success) {
            toast.success('Document Updated Successfully');
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };
    const handleDocumentExtension = async () => {
        // check if the document is linked
        // if linked, unlink and relink
        // if not linked, deleted record and link to new document
        if (islinked) {
            handleUnlinkAndRelink();
        } else {
            handleDeleteAndLink();
        }
    };
    const handleUnlinkAndRelink = async () => {
        const res = await unlinkDocument();
        if (res?.success) {
            // then relink
            linkDocument();
        } else {
            toast.error(res?.message);
        }
    };
    const handleDeleteAndLink = async () => {
        const res = await deleteDocument();
        if (res?.success) {
            linkDocument();
        } else {
            toast.error(res?.message);
        }
    };
    const unlinkDocument = async () => {
        // remove the tag for the current standard from the list of tags
        const newTags = data.tags
            .split(',')
            .filter((newtag) => newtag !== tag)
            .join();
        const formData = new FormData();
        // append the tag list to the form data
        formData.append('tags', newTags);

        // get the group ID (requirement no, category id or annex id) based on the tag
        const groupID = payload?.[groupIdLabel];

        // send request and return promise
        return await EditDocument(formData, data?.id, tag, groupID);
    };
    const linkDocument = async () => {
        const formData = createFormData();
        // adding the new tag.
        const extendingDoc = all_documents_by_alttags?.find((doc) => doc.id === extendingDocID);
        const newTags = extendingDoc?.tags.split(',');
        newTags.push(tag);
        formData.append('tags', newTags.join(','));

        // get the group ID
        const groupID = payload?.[groupIdLabel];
        setLoading(true);
        const res = await EditDocument(formData, extendingDocID, tag, groupID);
        setLoading(false);
        if (res.success) {
            toast.success('Document Updated Successfully');
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };
    const deleteDocument = async () => {
        // get the group ID
        const groupID = payload?.[groupIdLabel];
        return await DeleteDocument(data?.id, groupID, tag);
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    // use effect
    useEffect(() => {
        if (one_document) {
            const documentWithValidField = removeNullFields(one_document, 'default');
            setData(documentWithValidField);
            if (
                // if the csv has a length > 1 and the tag is part of the values then this document is linked to another document
                one_document?.tags?.split(',').length > 1 &&
                one_document?.tags?.includes(tag)
            ) {
                setisLinked(true);
                setMode('extend');
            } else {
                setisLinked(false);
                setMode('new');
            }
        }
    }, [one_document, open]);

    return (
        <Modal title={'Edit Document'} open={open} onCancel={closeAll} footer={null} destroyOnClose={true}>
            <form encType="multipart/form-data" onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor={titleLabel}>Document title</label>
                            <Input
                                type="text"
                                size="large"
                                name={titleLabel}
                                id={titleLabel}
                                onChange={handleTextChange}
                                value={data?.[titleLabel]}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor={descLabel}>Document description</label>
                            <Input.TextArea
                                type="text"
                                size="large"
                                name={descLabel}
                                id={descLabel}
                                onChange={handleTextChange}
                                value={data?.[descLabel]}
                                required
                            />
                        </div>
                    </Col>
                    {!one_document?.type && (
                        <>
                            <Col span={24}>
                                <div className="form-group" style={{ marginBottom: '1rem' }}>
                                    <label htmlFor="creation_mode">Select creation mode</label>
                                    <Select
                                        defaultValue="new"
                                        size="large"
                                        id="creation_mode"
                                        onChange={(value) => setMode(value)}
                                        value={mode}
                                        required
                                        style={{ width: '100%' }}
                                    >
                                        <Option value="new">Upload a new document</Option>
                                        <Option value="extend">Extend an existing document</Option>
                                    </Select>
                                </div>
                            </Col>
                            {mode === 'new' ? (
                                <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                                    <label htmlFor="template_file">Upload Template</label>
                                    <Dragger {...draggers}>
                                        <p className="ant-upload-drag-icon">
                                            <AiOutlineInbox />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    </Dragger>
                                </Col>
                            ) : (
                                <Col span={24}>
                                    <div className="form-group" style={{ marginBottom: '1rem' }}>
                                        <label htmlFor="extending_doc">Select the document to extend</label>
                                        <Select
                                            size="large"
                                            id="extending_doc"
                                            required
                                            style={{ width: '100%' }}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={filterOption}
                                            onSearch={onSearch}
                                            onChange={(value) => setExtendingDocID(value)}
                                            options={
                                                all_documents_by_alttags?.filter(
                                                    (doc) => !doc.tags.split(',').includes(tag)
                                                )?.length
                                                    ? all_documents_by_alttags
                                                          ?.filter((doc) => !doc.tags.split(',').includes(tag))
                                                          ?.map((doc) => {
                                                              return {
                                                                  value: doc.id,

                                                                  label: getOptionLabel(doc, tag),
                                                              };
                                                          })
                                                    : [
                                                          {
                                                              value: '',
                                                              label: 'Select Document',
                                                          },
                                                      ]
                                            }
                                            value={extendingDocID}
                                        />
                                    </div>
                                </Col>
                            )}
                        </>
                    )}
                    <Col>
                        <Button
                            className="ant-btn"
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                            style={{ marginTop: '3rem' }}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = (state) => {
    return {
        all_documents_by_alttags: state?.adminReducers?.all_documents_by_alttags,
    };
};
export default connect(mapStateToProps, { EditDocument, DeleteDocument })(EditDocumentModal);
