import React from 'react';

//core components
import AppModal from 'components/new_components/AppModal';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//icons
import ListImageIcon from 'assets/img/listicon.png';
import { ContentPasteGoOutlined } from '@mui/icons-material';
import AlertIcon from 'assets/img/alert-icon.svg';

const TipsModal = (props) => {
    const { open, handleClose } = props;

    const theme = useTheme();
    return (
        <AppModal
            open={open}
            handleClose={handleClose}
            title={
                <Typography
                    color="inherit"
                    sx={{
                        textAlign: 'center',
                        fontWeight: 700,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <img src={AlertIcon} width="20px" style={{ marginRight: '8px' }} /> Getting Started with Acting as a
                    vendor
                </Typography>
            }
            sx={{ zIndex: '9999999 !important' }}
        >
            <Box
                sx={{
                    color: theme.palette.gray[40],
                    fontWeight: 500,
                    fontSize: 15,
                    m: '0 !important',
                    px: 1,
                    '& p': {
                        px: 0.5,
                        mt: 3,
                    },
                    '& ul': {
                        p: 1,
                        m: 0,
                        '& li': {
                            listStyle: `url(${ListImageIcon})`,
                            ml: 2,
                            padding: '0.4rem',
                        },
                    },
                }}
            >
                <ul>
                    <li>
                        Use the <strong>"Submit All"</strong> button only when you're done choosing & approving all
                        answers
                    </li>
                    <li>
                        <strong>Trust our AI</strong>?. Use the "Check & approve all" button to enable our AI select &
                        approve trusted answers for each question. Awesome, right?
                    </li>
                    <li>
                        Question not assigned correctly?. Be the Boss. Assign a question to a category you feel is right
                        and allow our AI sort it out for others
                        <ul>
                            <li>Click the question you want to assign to a new category.</li>
                            <li>
                                Then click the assign icon <ContentPasteGoOutlined /> at the top right of your screen.
                            </li>
                            <li>Choose a category and click the assign button. Viola!</li>
                        </ul>
                    </li>
                </ul>
            </Box>
        </AppModal>
    );
};

export default TipsModal;
