import React, { useState } from 'react';

// core components
import UserDropdownTrigger from './UserDropdownTrigger';
import UserDropdownMenu from './UserDropdownMenu';
import LanguageMenu from './menus/LanguageMenu';

const UserDropdown = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    // functions
    const showDropdown = () => setDropdownVisible(true);
    const hideDropdown = () => setDropdownVisible(false);
    const open = Boolean(anchorEl);
    const openLanguageMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeLanguageMenu = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <UserDropdownTrigger showDropdown={showDropdown} />
            <UserDropdownMenu
                menuOpen={dropdownVisible}
                handleClose={hideDropdown}
                openLanguageMenu={openLanguageMenu}
                closeLanguageMenu={closeLanguageMenu}
            />
            <LanguageMenu
                open={open}
                closeLanguageMenu={closeLanguageMenu}
                anchorEl={anchorEl}
                language={
                    window.localStorage.i18nextLng?.includes('en')
                        ? 'English'
                        : window.localStorage.i18nextLng?.includes('fr')
                        ? 'French'
                        : window.localStorage.i18nextLng
                }
            />
        </>
    );
};

export default UserDropdown;
