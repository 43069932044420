import React, { useState } from 'react';
import { toast } from 'react-toastify';

import AppDeleteModal from 'components/new_components/AppDeleteModal';

// redux
import { connect } from 'react-redux';
import { ExemptControl, DeleteFromExemptedControlList } from 'store/actions/complianceActions';

const ControlExemptModal = (props) => {
    const { open, handleClose, ExemptControl, DeleteFromExemptedControlList, type, ControlId } = props;

    const [deleting, setDeleting] = useState(false);

    const handleDelete = async () => {
        setDeleting(true);
        const res =
            type === 'exempt'
                ? await ExemptControl({ soc_id: ControlId })
                : await DeleteFromExemptedControlList(ControlId);
        setDeleting(false);
        if (res?.success) {
            toast.success(
                type === 'exempt' ? 'Control Exempted Successfully' : 'Removed control from exemption list successfully'
            );
            handleClose();
        } else {
            toast.error('Something went wrong. Kindly reload this page and try again.');
        }
    };

    return (
        <AppDeleteModal
            open={open}
            handleClose={handleClose}
            title={type === 'exempt' ? 'Exempt this Control?' : 'Remove from the Exemption List?'}
            subtitle={`Please note that this action will ${
                type === 'exempt'
                    ? 'add this control to the exemption list'
                    : 'remove this control from the exemption list'
            }`}
            deleting={deleting}
            onDelete={handleDelete}
            deleteText={type === 'exempt' ? 'Exempt' : 'Remove'}
        />
    );
};
const mapStateToProps = (state) => ({
    ...state,
});
export default connect(mapStateToProps, {
    ExemptControl,
    DeleteFromExemptedControlList,
})(ControlExemptModal);
