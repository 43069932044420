import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

//Components
import { Table, Button, Modal, Tag, Row, Col, Input } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { PlansOperations } from './PlansOperations/PlansOperations';
import PlanModal from './PlansOperations/PlanModal';

//redux
import { connect } from 'react-redux';
import { GetAllPlans, DeletePlan } from 'store/actions/subscriptionActions';
import ViewPlanDetails from './PlansOperations/ViewPlanDetails';
import useSearch from 'hooks/useSearch';
import BreadCrumb from 'components/Breadcrumb';

const Plans = (props) => {
    // props
    const { GetAllPlans, DeletePlan, all_plans } = props;

    // state
    const [loading, setloading] = useState(false);
    const [planModalOpen, setPlanModalOpen] = useState(null);
    const [viewModal, setViewModal] = useState(false);
    const [record, setRecord] = useState(null);

    // hooks
    const { data, handleSearch } = useSearch(all_plans, [
        'name',
        'tag',
        'price',
        'description',
        'employee_range',
        'features',
    ]);

    //functions
    const openPlanModal = (mode = 'Add', data = {}) => {
        setPlanModalOpen(mode);
        setRecord(data);
    };
    const closePlanModal = () => {
        setPlanModalOpen(null);
        setRecord({});
    };

    //View Modal
    const openViewModal = (data) => {
        setRecord(data);
        setViewModal(true);
    };

    const closeViewModal = () => {
        setRecord({});
        setViewModal(false);
    };
    // logic
    const getPlanTypeColor = (plan) => {
        switch (plan) {
            case 'main':
                return 'blue';
            case 'custom':
                return 'cyan';
            default:
                return 'volcano';
        }
    };

    const deleteModal = (plan) => {
        Modal.confirm({
            title: 'Do you want to delete this plan?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await DeletePlan(plan?.id);
                if (res?.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    useEffect(() => {
        const getAllPlans = async () => {
            setloading(true);
            const res = await GetAllPlans();
            setloading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllPlans();
    }, []);

    // constant
    const columns = [
        {
            title: 'Name',
            render: (record) => record?.name,
        },
        {
            title: 'Type',
            render: (record) => {
                return (
                    <Tag color={getPlanTypeColor(record?.tag)} className="capitalize">
                        {record?.tag}
                    </Tag>
                );
            },
            filters: [
                { text: 'Main', value: 'main' },
                { text: 'Custom', value: 'custom' },
                { text: 'Free', value: 'free' },
            ],
            onFilter: (value, record) => record?.tag === value,
        },
        {
            title: 'Price',
            render: (record) => record?.price || 'Nil',
        },
        {
            title: 'Description',
            render: (record) => record?.description || 'Nil',
        },
        {
            title: 'No of Employees',
            render: (record) => record?.employee_range || 'Nil',
        },
        {
            title: 'Extra Features',
            render: (record) => {
                return (
                    record?.features?.map((feature, index) => (
                        <React.Fragment key={index}>
                            <p>{feature}</p>
                        </React.Fragment>
                    )) || 'Nil'
                );
            },
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => (
                <PlansOperations
                    openEditModal={openPlanModal}
                    deleteModal={deleteModal}
                    openViewModal={openViewModal}
                    record={record}
                />
            ),
        },
    ];

    return (
        <>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'All Plans' }]} />
                </Col>
                <Col span={12} className="flex items-center">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                    <Button
                        onClick={() => openPlanModal()}
                        type="primary"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center ml-3"
                    >
                        Add Plan
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(plan) => plan?.id}
                    columns={columns}
                    loading={loading}
                    dataSource={data?.sort((a, b) => (a?.date_created > b?.date_created ? -1 : 1))}
                    pagination={{
                        total: data?.length,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                    }}
                />
            </section>

            <PlanModal mode={planModalOpen} handleClose={closePlanModal} modalData={record} type="main" />
            <ViewPlanDetails open={viewModal} handleClose={closeViewModal} record={record} />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_plans: state?.subscriptionReducers.all_plans,
    };
};
export default connect(mapStateToProps, { GetAllPlans, DeletePlan })(Plans);
