import { Button, Col, DatePicker, InputNumber, Modal, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

// connect
import { connect } from 'react-redux';
import { ApprovePlanRequest } from 'store/actions/subscriptionActions';
import { toast } from 'react-toastify';

const ApproveRequestModal = (props) => {
    const { open, handleClose, modalData, all_plans, ApprovePlanRequest } = props;

    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // memos
    const plan = useMemo(() => {
        return all_plans?.find((plan) => plan?.id === modalData?.plan);
    }, [modalData?.plan, open]);
    // const merchant = useMemo(() => {
    //     return all_merchants?.find((merchant) => merchant?.id === modalData?.merchant);
    // }, [modalData?.merchant, open]);

    // functions
    const closeAll = () => {
        handleClose();
    };
    const handleDateChange = (name, string) => {
        setData({ ...data, [name]: dayjs(string).format('YYYY-MM-DD') });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await ApprovePlanRequest(data);
        setLoading(false);
        if (res?.success) {
            toast.success('Plan request approved successfully.');
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (open) {
            setData(() => {
                return {
                    type: plan?.name ? 'main' : 'custom',
                    merchant: modalData?.merchant,
                    price: modalData?.total_cost || '',
                    plan_id: modalData?.plan || null,
                    paid_standards: modalData?.compliance_type,
                };
            });
        }
    }, [open, plan]);

    return (
        <Modal title="Approve Merchant Plan Request" open={open} onCancel={closeAll} footer={null} destroyOnClose>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2">
                        <label htmlFor="plan_start_date">Plan Start Date</label>
                        <DatePicker
                            onChange={(_, str) => handleDateChange('plan_start_date', str)}
                            size="large"
                            name="plan_start_date"
                            id="plan_start_date"
                            required
                            style={{ width: '100%' }}
                            value={data?.plan_start_date ? dayjs(data?.plan_start_date) : null}
                        />
                    </Col>
                    <Col span={24} className="my-2">
                        <label htmlFor="plan_end_date">Plan End Date</label>
                        <DatePicker
                            onChange={(_, str) => handleDateChange('plan_end_date', str)}
                            size="large"
                            name="plan_end_date"
                            id="plan_end_date"
                            required
                            style={{ width: '100%' }}
                            value={data?.plan_end_date ? dayjs(data?.plan_end_date) : null}
                        />
                    </Col>
                    <Col span={24} className="my-2">
                        <label htmlFor="price">Price</label>
                        <InputNumber
                            size="large"
                            name="price"
                            id="price"
                            required
                            className="w-full"
                            value={data?.price}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\\s?|(,*)/g, '')}
                            onChange={(price) => setData((current) => ({ ...current, price }))}
                        />
                    </Col>
                    <Col className="mt-3">
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = (state) => ({
    all_plans: state?.subscriptionReducers?.all_plans,
    all_merchants: state?.adminReducers?.all_merchants,
});
export default connect(mapStateToProps, { ApprovePlanRequest })(ApproveRequestModal);
