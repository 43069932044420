import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/img/brand/logo.svg';
import { getType } from 'utils';
import { connect } from 'react-redux';

const { Sider } = Layout;
const { Item, SubMenu } = Menu;

const AdminSidebar = ({ routes, collapsed, mfa }) => {
    const location = useLocation();

    const [currentView, setCurrentView] = useState('');
    const role = getType('type');

    useEffect(() => {
        const view = location.pathname.split('/')[2] || '';
        routes.forEach((route) => {
            if (route.subMenus) {
                route.subMenus?.map((menu) => {
                    if (menu.path === view) {
                        setCurrentView(`${menu.key}`);
                    }
                });
            } else {
                if (route.path === view) {
                    setCurrentView(`${route.key}`);
                }
            }
        });
    }, [location]);

    return (
        <Sider trigger={null} collapsible collapsed={collapsed}>
            <img src={logo} className="logo" alt="logo" />
            <Menu
                mode="inline"
                defaultSelectedKeys={['0']}
                selectedKeys={[currentView]}
                className="max-h-[calc(100vh_-_100px)] overflow-y-auto"
            >
                {routes.map((route) =>
                    route.subMenus ? (
                        <SubMenu key={route.key} icon={<route.icon />} title={route?.name}>
                            {route?.subMenus
                                ?.filter((sub) => sub?.hasAccess?.includes(role))
                                ?.map((menu) => (
                                    <Item key={menu.key} icon={<menu.icon />}>
                                        <Link to={`${menu.layout}/${menu.path}`}>{menu.name}</Link>
                                    </Item>
                                ))}
                        </SubMenu>
                    ) : (
                        <Item key={route.key} icon={<route.icon />}>
                            <Link style={{ pointerEvents: !mfa ? 'none' : '' }} to={`${route.layout}/${route.path}`}>
                                {route.name}
                            </Link>
                        </Item>
                    )
                )}
            </Menu>
        </Sider>
    );
};

const mapStateToProps = (state) => {
    return {
        mfa: state?.generalReducers?.user_info?.mfa,
    };
};
export default connect(mapStateToProps, {})(AdminSidebar);
