import React, { useState } from 'react';
import { toast } from 'react-toastify';
import AppDeleteModal from 'components/new_components/AppDeleteModal';

//translations
import { useTranslation } from 'react-i18next';

const DeleteQuestionnaireModal = (props) => {
    const { open, handleClose, DeleteUploadedVendorQuestionnaire, id } = props;

    const [deleting, setDeleting] = useState(false);

    //translation
    const { t } = useTranslation('vendorManagement');

    //function
    const handleDelete = async () => {
        setDeleting(true);
        const res = await DeleteUploadedVendorQuestionnaire(id);
        setDeleting(false);
        if (res?.success) {
            toast.success('Successfully deleted a record.');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <AppDeleteModal
            open={open}
            handleClose={handleClose}
            title={t('deleteQuestionnaireModal.title')}
            subtitle={t('deleteQuestionnaireModal.subtitle')}
            deleting={deleting}
            onDelete={handleDelete}
        />
    );
};

export default DeleteQuestionnaireModal;
