import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
//translations files
import authTranslationEN from 'utils/locales/en/auth.json';
import authTranslationFR from 'utils/locales/fr/auth.json';
import userTranslationEN from 'utils/locales/en/user.json';
import userTranslationFR from 'utils/locales/fr/user.json';
import commonTranslationEN from 'utils/locales/en/common.json';
import commonTranslationFR from 'utils/locales/fr/common.json';
import activitiesTranslationEN from 'utils/locales/en/activities.json';
import activitiesTranslationFR from 'utils/locales/fr/activities.json';
import pciScansTranslationEN from 'utils/locales/en/pciscans.json';
import pciScansTranslationFR from 'utils/locales/fr/pciscans.json';
import accountsTranslationEN from 'utils/locales/en/accounts.json';
import accountsTranslationFR from 'utils/locales/fr/accounts.json';
import companyTranslationEN from 'utils/locales/en/company.json';
import companyTranslationFR from 'utils/locales/fr/company.json';
import onboardingPolicyTranslationEN from 'utils/locales/en/onboardingPolicy.json';
import onboardingPolicyTranslationFR from 'utils/locales/fr/onboardingPolicy.json';
import setupsTranslationEN from 'utils/locales/en/setups.json';
import setupsTranslationFR from 'utils/locales/fr/setups.json';
import inventoryTranslationEN from 'utils/locales/en/inventory.json';
import inventoryTranslationFR from 'utils/locales/fr/inventory.json';
import pentestTranslationEN from 'utils/locales/en/pentest.json';
import pentestTranslationFR from 'utils/locales/fr/pentest.json';
import trainingTranslationEN from 'utils/locales/en/training.json';
import trainingTranslationFR from 'utils/locales/fr/training.json';
import vulnerabilityManagementTranslationEN from 'utils/locales/en/vulnerabilityMgt.json';
import vulnerabilityManagementTranslationFR from 'utils/locales/fr/vulnerabilityMgt.json';
import vendorManagementTranslationEN from 'utils/locales/en/vendorManagement.json';
import vendorManagementTranslationFR from 'utils/locales/fr/vendorManagement.json';
import riskAssessmentTranslationEN from 'utils/locales/en/riskAssessment.json';
import riskAssessmentTranslationFR from 'utils/locales/fr/riskAssessment.json';
import overviewTranslationEN from 'utils/locales/en/overview.json';
import overviewTranslationFR from 'utils/locales/fr/overview.json';
import auditTranslationEN from 'utils/locales/en/audit.json';
import auditTranslationFR from 'utils/locales/fr/audit.json';
import dataroomTranslationEN from 'utils/locales/en/dataroom.json';
import dataroomTranslationFR from 'utils/locales/fr/dataroom.json';
import complianceTranslationEN from 'utils/locales/en/compliance.json';
import complianceTranslationFR from 'utils/locales/fr/compliance.json';
import fraudGuardianEN from 'utils/locales/en/fraudGuardian.json';
import fraudGuardianFR from 'utils/locales/fr/fraudGuardian.json';

i18n.use(detector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                //namespaces
                auth: authTranslationEN,
                user: userTranslationEN,
                common: commonTranslationEN,
                activities: activitiesTranslationEN,
                pciscans: pciScansTranslationEN,
                accounts: accountsTranslationEN,
                company: companyTranslationEN,
                onboardingPolicy: onboardingPolicyTranslationEN,
                setups: setupsTranslationEN,
                inventory: inventoryTranslationEN,
                pentest: pentestTranslationEN,
                training: trainingTranslationEN,
                vulnerabilityManagement: vulnerabilityManagementTranslationEN,
                vendorManagement: vendorManagementTranslationEN,
                riskAssessment: riskAssessmentTranslationEN,
                overview: overviewTranslationEN,
                audit: auditTranslationEN,
                dataroom: dataroomTranslationEN,
                compliance: complianceTranslationEN,
                fraudGuardian: fraudGuardianEN,
            },
            fr: {
                //namespaces
                auth: authTranslationFR,
                user: userTranslationFR,
                common: commonTranslationFR,
                activities: activitiesTranslationFR,
                pciscans: pciScansTranslationFR,
                accounts: accountsTranslationFR,
                company: companyTranslationFR,
                onboardingPolicy: onboardingPolicyTranslationFR,
                setups: setupsTranslationFR,
                inventory: inventoryTranslationFR,
                pentest: pentestTranslationFR,
                training: trainingTranslationFR,
                vulnerabilityManagement: vulnerabilityManagementTranslationFR,
                vendorManagement: vendorManagementTranslationFR,
                riskAssessment: riskAssessmentTranslationFR,
                overview: overviewTranslationFR,
                audit: auditTranslationFR,
                dataroom: dataroomTranslationFR,
                compliance: complianceTranslationFR,
                fraudGuardian: fraudGuardianFR,
            },
        },
    });

export default i18n;
