import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import CompletedSvg from '../../../assets/img/CompletedSvg.svg';
import LoadingState from 'components/new_components/LoadingState';

import AppSummaryModal from '../../new_components/AppSummaryModal';
import QuestionItem from './QuestionItem';
import CancelIcon from '@mui/icons-material/Cancel';
//redux
import { connect } from 'react-redux';
import { useTheme } from '@mui/material/styles';

const Index = (props) => {
    const theme = useTheme();
    const { open, onClose, all_survey, survey_responses, loading } = props;
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState(false);
    const [answer, setAnswer] = useState([]);
    const [completed, setCompleted] = useState(false);
    const [other_answer, setOtheranswer] = useState('');
    const [question, setQuestion] = useState('');
    const perPage = 1;

    const allSurveys = !loading ? all_survey : [];

    return (
        <AppSummaryModal
            open={open}
            handleClose={onClose}
            width="800px"
            height="600"
            maxHeight="800"
            page={page}
            setPage={setPage}
            totalLength={allSurveys?.length}
            allSurvey={allSurveys}
            response={survey_responses}
            setStatus={setStatus}
            setAnswer={setAnswer}
            answer={answer}
            completed={completed}
            question={question}
            setQuestion={setQuestion}
            setOtheranswer={setOtheranswer}
        >
            <Box>
                {completed ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginTop: '10rem',
                            overflow: 'hidden !important',
                        }}
                    >
                        <img src={CompletedSvg} alt="CompletedSvg" />
                        <Typography
                            sx={{ color: theme.palette.primary[900], fontWeight: 600, fontSize: '20px', marginTop: 5 }}
                        >
                            Survey Completed
                        </Typography>
                        <Typography
                            sx={{
                                color: theme.palette.primary[900],
                                fontWeight: 500,
                                fontSize: '12px',
                                marginTop: 1.5,
                            }}
                        >
                            Thank you for your time. We value your input
                        </Typography>
                        <Button
                            sx={{ color: '#919094', textTransform: 'capitalize', marginTop: 4 }}
                            variant="text"
                            onClick={onClose}
                            endIcon={<CancelIcon />}
                        >
                            Close
                        </Button>
                    </Box>
                ) : (
                    <>
                        {loading ? (
                            <LoadingState />
                        ) : (
                            <Box
                                sx={{
                                    marginTop: '2rem',
                                }}
                            >
                                {allSurveys?.slice(page * perPage - perPage, page * perPage).map((item, index) => (
                                    <QuestionItem
                                        key={index}
                                        item={item}
                                        page={page}
                                        setPage={setPage}
                                        onClose={onClose}
                                        status={status}
                                        setStatus={setStatus}
                                        answer={answer}
                                        setAnswer={setAnswer}
                                        setOtheranswer={setOtheranswer}
                                        other_answer={other_answer}
                                        setCompleted={setCompleted}
                                        allSurveys={allSurveys}
                                        survey_responses={survey_responses}
                                        setQuestion={setQuestion}
                                    />
                                ))}
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </AppSummaryModal>
    );
};
const mapStateToProps = (state) => {
    return {
        all_survey: state?.adminReducers?.all_survey,
        survey_responses: state?.merchantReducers?.survey_responses,
        merchant_info: state?.merchantReducers?.merchant_info,
    };
};
export default connect(mapStateToProps, {})(Index);
