import * as types from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case types.ALL_CATEGORIES:
            return {
                ...state,
                all_categories: action.payload,
            };
        case types.ALL_NDPR_DOCUMENTS:
            return {
                ...state,
                all_ndpr_documents: action.payload,
            };
        case types.ALL_ADMIN_NDPR_FORMS:
            return {
                ...state,
                all_ndpr_forms: action.payload,
            };
        case types.ALL_CATEGORY_METRICS:
            return {
                ...state,
                all_categories_metrics: action.payload,
            };
        case 'CLEAR_DATA':
            return {};
        default:
            return state;
    }
};
