import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

//translations
import { useTranslation } from 'react-i18next';

const InfrastructureTableActions = (props) => {
    const theme = useTheme();
    const { row, openViewModal, openDeleteModal, openEditModal } = props;

    // state
    const [anchor, setAnchor] = useState(null);

    //translation
    const { t } = useTranslation('inventory');

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const handleOpenViewModal = () => {
        openViewModal(row);
        closeMenu();
    };

    const handleOpenEditModal = () => {
        openEditModal('edit', row, row?.id);
        closeMenu();
    };
    const handleOpenDeleteModal = () => {
        openDeleteModal(row?.id);
        closeMenu();
    };
    return (
        <>
            <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                <MenuItem
                    sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B', py: 0.2 }}
                    onClick={handleOpenViewModal}
                >
                    {t('infrastructureTable.tableAction.view')}
                </MenuItem>
                <Divider />
                <>
                    <MenuItem
                        sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B', py: 0.2 }}
                        onClick={handleOpenEditModal}
                    >
                        {t('infrastructureTable.tableAction.edit')}
                    </MenuItem>
                    <Divider />
                </>
                <MenuItem
                    sx={{ fontSize: '14px', fontWeight: 500, color: '#FF5449', py: 0.2 }}
                    onClick={handleOpenDeleteModal}
                >
                    {t('infrastructureTable.tableAction.delete')}
                </MenuItem>
            </Menu>
        </>
    );
};

export default InfrastructureTableActions;
