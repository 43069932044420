import React from 'react';
import { useSelector } from 'react-redux';

const EmployeesTable = ({ employees_options }) => {
    const { people_summary, people_scan_summary, people_info_summary, people_training_summary } = useSelector(
        (state) => state?.CISOReducers
    );
    return (
        <div className="mt-[5rem]">
            <div hidden={people_summary?.length === 0 || !employees_options?.includes('Onboarding Policy')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">Employees</p>
                </div>
                {people_info_summary?.employees ? (
                    <table className="w-full w-full">
                        <thead className="w-full">
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                    Onboarding Policy
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                    Security Agent
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                    Identity Check
                                </th>
                            </tr>
                        </thead>
                        {Object?.keys(people_info_summary?.employees)?.map((option, index) => {
                            return (
                                <tbody key={index}>
                                    <tr>
                                        <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                            {option}
                                        </th>
                                        <th
                                            className={`${
                                                people_info_summary?.employees[option]?.policy_completed ===
                                                people_info_summary?.employees[option]?.policy_not_completed
                                                    ? 'text-[#55BE8B]'
                                                    : 'text-[#FF5449]'
                                            } text-xs font-normal p-3 text-center border-2 border-black`}
                                        >
                                            {people_info_summary?.employees[option]?.policy_completed}/
                                            {people_info_summary?.employees[option]?.policy_not_completed}
                                            {''} Completed
                                        </th>
                                        <th
                                            scope="row"
                                            className={`${
                                                people_info_summary?.employees[option]?.ran_agent
                                                    ? 'text-[#55BE8B]'
                                                    : 'text-[#FF5449]'
                                            } text-xs font-normal p-3 text-center border-2 border-black`}
                                        >
                                            {people_info_summary?.employees[option]?.ran_agent
                                                ? 'Scanned'
                                                : 'Not Scanned'}
                                        </th>

                                        <th
                                            scope="row"
                                            className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black"
                                        >
                                            {people_info_summary?.employees[option]?.security_check
                                                ? 'Completed'
                                                : 'Pending'}
                                        </th>
                                    </tr>
                                </tbody>
                            );
                        })}
                    </table>
                ) : null}
            </div>
            <div hidden={people_scan_summary?.length === 0 || !employees_options?.includes('Computer scan')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">Computers</p>
                </div>
                <table className="w-full w-full">
                    <thead className="w-full">
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                Owner
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Computer Name
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Password Manager
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                HD Encryption
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Anti-virus Installled
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Screenlock
                            </th>
                        </tr>
                    </thead>
                    {people_scan_summary?.map((option, index) => (
                        <tbody key={index}>
                            <tr>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {option?.name}
                                </th>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {option?.systemname}
                                </th>
                                <th
                                    className={`${
                                        option?.pwmanager ? 'text-[#55BE8B]' : 'text-[#FF5449]'
                                    } text-xs font-normal p-3 text-center border-2 border-black`}
                                >
                                    {option?.pwmanager ? 'Yes' : 'No'}
                                </th>
                                <th
                                    scope="row"
                                    className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black"
                                >
                                    {option?.diskencryption ? 'Yes' : 'No'}
                                </th>

                                <th
                                    className={`${
                                        option?.avstatus ? 'text-[#55BE8B]' : 'text-[#FF5449]'
                                    } text-xs font-normal p-3 text-center border-2 border-black`}
                                >
                                    {option?.avstatus ? 'Yes' : 'No'}
                                </th>
                                <th
                                    scope="row"
                                    className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black"
                                >
                                    {option?.lockstatus ? 'Yes' : 'No'}
                                </th>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
            <div hidden={people_training_summary?.length === 0 || !employees_options?.includes('Employee Training')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">Employee Training</p>
                </div>
                <table className="w-full w-full">
                    <thead className="w-full">
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                course Name
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Employee Name
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Employee Completed Training
                            </th>
                        </tr>
                    </thead>
                    {people_training_summary &&
                        Array.isArray(people_training_summary) &&
                        people_training_summary?.map((option, index) => (
                            <tbody key={index}>
                                <tr>
                                    <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                        {option?.course_name}
                                    </th>
                                    <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black capitalize">
                                        {option?.employee_name}
                                    </th>
                                    <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                        {option?.is_complete ? 'Completed' : 'Not Completed'}
                                    </th>
                                </tr>
                            </tbody>
                        ))}
                </table>
            </div>
        </div>
    );
};

export default EmployeesTable;
