import { Container, Stack, useTheme } from '@mui/material';
import AppTabsBarNew from 'components/new_components/tabs/AppTabsBarNew';
import React from 'react';
import { getType } from 'utils';

const Layout = (props) => {
    const theme = useTheme();
    const { currentTab, handleTabChange, children } = props;

    const tabs = [
        {
            index: 0,
            label: 'Profile',
            link: '',
        },
        {
            index: 1,
            label: 'Users',
            hasNoAccess: ['user'],
        },
        {
            index: 2,
            label: 'Subscriptions',
        },
        {
            index: 3,
            label: '2FA',
        },
        {
            index: 4,
            label: 'Advanced',
        },
        {
            index: 5,
            label: 'Company Info',
            hasNoAccess: ['user', 'auditor'],
        },
        {
            index: 6,
            label: 'Password Management',
        },
    ];

    const role = getType('role') ?? '';

    const generateTabs = () => {
        return tabs.filter((tab) => !tab.hasNoAccess?.includes(role));
    };

    return (
        <Container
            maxWidth={false}
            elevation={0}
            sx={{
                p: '0 !important',
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: 'transparent',
                borderTop: '1px solid #F1F5F9',
                mb: 5,
            }}
        >
            <AppTabsBarNew
                currentTab={currentTab}
                width="70%"
                smwidth="100%"
                tabs={generateTabs()}
                onChange={handleTabChange}
                sx={{
                    width: '70%',
                    [theme.breakpoints.down('xl')]: {
                        width: '100%',
                    },
                }}
                // sx={{ width: '100%' }}
            />
            <Stack px={{ flex: 1 }}>{children}</Stack>
        </Container>
    );
};

export default Layout;
