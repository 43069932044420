import React, { useState, useEffect, useContext } from 'react';
import { Stack, IconButton, Badge, Tooltip } from '@mui/material';
import NotificationMessage from './components/NotificationMessage';
import RowContainer from './components/RowContainer';
import { History, FileUpload, CheckCircleOutline } from '@mui/icons-material';
import { toast } from 'react-toastify';
import FileIcon from 'assets/img/FileIcon.svg';
import UploadHistoryDrawer from './components/UploadHistoryDrawer';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import AppDeleteModal from 'components/new_components/AppDeleteModal';
//redux
import { connect, useSelector } from 'react-redux';
import {
    GetAllNeededDocs,
    UploadMerchantDocumentResponse,
    GetAllMerchantDocumentResponse,
    DeleteMerchantDocumentResponse,
} from 'store/actions/merchantActions';
import { UpdateAdminDocs } from 'store/actions/adminActions';
import EmptyState from 'components/new_components/EmptyState';
import LoadingState from 'components/new_components/LoadingState';
import { formatDateObjectHandler } from 'utils';

//translations
import { useTranslation } from 'react-i18next';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';
import { GetDownloadLink } from 'store/actions/generalActions';

const NeededDoc = (props) => {
    const [historyModalOpen, setHistoryModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState({});
    const [deleting, setDeleting] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deletePayload, setDeletePayload] = useState(null);
    const [selectedDoc, setSelectedDoc] = useState({});

    const {
        currentTab,
        GetAllNeededDocs,
        UploadMerchantDocumentResponse,
        GetAllMerchantDocumentResponse,
        DeleteMerchantDocumentResponse,
        UpdateAdminDocs,
        all_docs,
        all_merchant_docs_responses,
        merchant_id,
    } = props;

    //translation
    const { t } = useTranslation('dataroom');

    //accessing values from the redux store  //accessing values from the redux store
    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const client_id = useSelector((state) => state?.implementerReducer?.client_id);

    //context api for auditor's selected merchant
    const { activeMerchant } = useContext(SelectedMerchantContext);

    //functions
    const openHistoryModal = (doc) => {
        setSelectedDoc(doc);
        setHistoryModalOpen(true);
    };
    const closeHistoryModal = () => {
        setHistoryModalOpen(false);
        setTimeout(() => setSelectedDoc({}), 1000);
    };
    const openDeleteModal = (id) => {
        setDeletePayload(id);
        setDeleteModalOpen(true);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setDeletePayload(null);
    };
    const onChange = async (e, id, updateNeeded) => {
        const formData = new FormData();
        const imageName = e.target.files[0];
        formData.append('response_file', imageName);
        formData.append('doc', id);
        formData.append('merchant', merchant_id);
        setUploading({ [id]: true });
        const res = await UploadMerchantDocumentResponse(formData);
        setUploading({ [id]: false });
        if (res?.success) {
            toast.success('Document Uploaded successfully');
            if (updateNeeded) {
                const response = await UpdateAdminDocs({ update_needed: false }, id);
                if (response?.success) {
                    getAllNeededDocs();
                } else {
                    console.log(response?.message);
                }
            }
        } else {
            toast.error(res?.message);
        }
    };
    const handleUpload = (id) => {
        document.getElementById(id?.toString()).click();
    };
    const isDocumentUploaded = (id) => {
        const docExists = all_merchant_docs_responses?.some((document) => document?.doc === id);
        return docExists;
    };
    //async actions
    const getAllNeededDocs = async () => {
        setLoading(true);
        const res = await GetAllNeededDocs(user_type === 'implementer' ? client_id : activeMerchant);
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllMerchantDocumentResponse = async () => {
        const res = await GetAllMerchantDocumentResponse(user_type === 'implementer' ? client_id : activeMerchant);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const handleDeleteDocument = async () => {
        setDeleting(true);
        const res = await DeleteMerchantDocumentResponse(deletePayload);
        setDeleting(false);
        if (res?.success) {
            toast.success('Document Deleted Successfully');
            closeDeleteModal();
        } else {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        const fetchDocs = () => {
            getAllNeededDocs();
            getAllMerchantDocumentResponse();
        };

        if (activeMerchant && (user_type === 'auditor' || user_type === 'implementer')) {
            fetchDocs();
        } else {
            fetchDocs();
        }
    }, [activeMerchant, user_type, client_id]);

    return (
        <>
            <Stack sx={{ width: '80%', margin: '0 auto', mt: 2 }}>
                <NotificationMessage currentTab={currentTab} />
                {loading ? (
                    <LoadingState />
                ) : all_docs?.length ? (
                    all_docs
                        ?.sort((a, b) => b?.id - a?.id)
                        ?.map((doc) => {
                            return (
                                <RowContainer key={doc?.id}>
                                    <DataItem
                                        doc={doc}
                                        openHistoryModal={openHistoryModal}
                                        isDocumentUploaded={isDocumentUploaded}
                                        uploading={uploading}
                                        handleUpload={handleUpload}
                                        onChange={onChange}
                                    />
                                </RowContainer>
                            );
                        })
                ) : (
                    <EmptyState description={t('noDocument')} />
                )}
            </Stack>
            <UploadHistoryDrawer
                open={historyModalOpen}
                handleClose={closeHistoryModal}
                selectedDoc={selectedDoc}
                all_merchant_docs_responses={all_merchant_docs_responses}
                openDeleteModal={openDeleteModal}
            />
            <AppDeleteModal
                open={deleteModalOpen}
                handleClose={closeDeleteModal}
                title={t('title')}
                subtitle={t('subtitle')}
                deleting={deleting}
                onDelete={handleDeleteDocument}
            />
        </>
    );
};

const DataItem = (props) => {
    const { doc, openHistoryModal, isDocumentUploaded, uploading, handleUpload, onChange } = props;
    const [loadingLink, setLoadingLink] = useState(false);
    //translation
    const { t } = useTranslation('dataroom');

    const getDownloadLink = async () => {
        if (doc.file) {
            setLoadingLink(true);
            const res = await GetDownloadLink('compliance', 'data_room', doc.id)();
            setLoadingLink(false);
            if (res?.success) {
                // open decoded url
                window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
                // open original file url
                window.open(doc.file, '_blank');
            }
        }
    };

    return (
        <>
            <Stack flexDirection="row" alignItems="flex-start" gap={1}>
                <img src={FileIcon} alt="file icon" className="w-[26px]" />
                <div>
                    <div className="flex flex-row items-center">
                        <p className="text-[13px] font-medium text-[#3E414D] m-0">{doc?.name}</p>
                        <span className="text-[11px] font-semibold  pl-1">
                            ({formatDateObjectHandler(doc?.date_created, 'MMMM Do, YYYY') || 'Nil'})
                        </span>
                    </div>

                    <p className="text-[11px] font-normal text-[#77777A] m-0">{doc?.description}</p>
                    <span
                        onClick={getDownloadLink}
                        className={`text-[11px] font-semibold -mt-3 underline ${
                            doc.file ? 'cursor-pointer text-[#5474C4]' : ''
                        } text-left`}
                    >
                        {loadingLink ? (
                            <LoadingState size={20} styles={{ justifyContent: 'start', alignItems: 'start' }} />
                        ) : (
                            t('download')
                        )}
                    </span>
                </div>
            </Stack>
            <Stack flexDirection="row" alignItems="center">
                <Tooltip title="View Documents history" placement="top">
                    <IconButton aria-label="history" onClick={() => openHistoryModal(doc)}>
                        <History />
                    </IconButton>
                </Tooltip>
                {doc?.response_needed ? (
                    <Badge variant="dot" invisible={!doc?.update_needed} color="error">
                        <AppLoadingButton
                            startIcon={
                                isDocumentUploaded(doc?.id) && !doc?.update_needed ? (
                                    <CheckCircleOutline />
                                ) : (
                                    <FileUpload />
                                )
                            }
                            text={
                                isDocumentUploaded(doc?.id) && !doc?.update_needed
                                    ? t('uploaded')
                                    : isDocumentUploaded(doc?.id) && doc?.update_needed
                                    ? t('updateNeeded')
                                    : t('uploadNeeded')
                            }
                            variant="outlined"
                            disableElevation
                            sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: isDocumentUploaded(doc?.id) && !doc?.update_needed ? '#37A372' : '#77777A',
                                borderRadius: '4px',
                                width: '180px',
                            }}
                            loading={uploading?.[doc?.id]}
                            onClick={() => handleUpload(doc?.id, doc?.update_needed)}
                        />
                    </Badge>
                ) : (
                    <AppLoadingButton
                        className="text-[12px] text-[#002C72]"
                        variant="text"
                        text={t('noUploadNeeded')}
                        disableElevation
                        sx={{
                            fontSize: '12px',
                            fontWeight: 500,
                            color: '#002C72',
                            borderRadius: '4px',
                            width: '180px',
                        }}
                    />
                )}

                <input
                    id={doc?.id?.toString()}
                    name={doc?.id}
                    type="file"
                    //   accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(e) => onChange(e, doc?.id)}
                />
            </Stack>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_docs: state?.merchantReducers?.all_needed_docs,
        all_merchant_docs_responses: state?.merchantReducers?.all_merchant_docs_responses,
        merchant_id: state?.merchantReducers?.merchant_info?.id,
    };
};

export default connect(mapStateToProps, {
    GetAllNeededDocs,
    UploadMerchantDocumentResponse,
    GetAllMerchantDocumentResponse,
    DeleteMerchantDocumentResponse,
    UpdateAdminDocs,
})(NeededDoc);
