import { Box, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { BiDownload } from 'react-icons/bi';
import { connect } from 'react-redux';
import AppButtonGroup from 'components/new_components/AppButtonGroup';
import { baseUrl } from 'config';

//translations
import { useTranslation } from 'react-i18next';

const RiskAction = (props) => {
    const { company_id } = props;
    const [anchor, setAnchor] = useState(null);

    //translation
    const { t } = useTranslation('riskAssessment');

    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);
    const dropdown_options = [
        {
            label: t('riskRegisterTab.cbnReport'),
            link: `${baseUrl}/scans/spool_risk_assessment/?merchant_id=${company_id}`,
        },
        {
            label: t('riskRegisterTab.smartcomplyReport'),
            action: openMenu,
            // link: `${baseUrl}${window.localStorage.i18nextLng?.includes('en') ? 'en' : window.localStorage.i18nextLng}/api/scans/spool_smartcomplyapp_riskassessment/?merchant_id=${company_id}&matrix=${matrix}`,
        },
    ];
    return (
        <Box sx={{ paddingTop: 2.5 }}>
            <AppButtonGroup
                mainBtnProps={{ name: t('riskRegisterTab.spoolReport'), icon: <BiDownload /> }}
                dropdownItems={dropdown_options}
            />
            <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    left: '-8rem',
                    padding: '1rem 8rem 1rem 2rem',
                }}
                PaperProps={{
                    style: {
                        width: 140,
                    },
                }}
            >
                <a
                    href={`${baseUrl}/scans/spool_smartcomplyapp_riskassessmentpdf/?merchant_id=${company_id}&matrix=${'5x5'}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <MenuItem
                        sx={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: '#46464A',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '0.3rem',
                        }}
                    >
                        5X5
                    </MenuItem>
                </a>
                <a
                    href={`${baseUrl}/scans/spool_smartcomplyapp_riskassessmentpdf/?merchant_id=${company_id}&matrix=${'3x3'}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <MenuItem
                        sx={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: '#46464A',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '0.1rem',
                        }}
                    >
                        3X3
                    </MenuItem>
                </a>
            </Menu>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        company_id: state?.authReducers?.company_id,
    };
};

export default connect(mapStateToProps, {})(RiskAction);
