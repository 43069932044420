import React, { useEffect, useMemo, useState } from 'react';
import { Close } from '@mui/icons-material';
import { Box, Chip, IconButton, Typography, useTheme } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppSubmitButton } from 'components/new_components/forms';
import { AppFormSelect } from 'components/new_components/forms';
import { AppForm } from 'components/new_components/forms';
import { toast } from 'react-toastify';

//redux
import { connect } from 'react-redux';
import { GetAllCompanyEmployees } from 'store/actions/generalActions';
import { UpdateTask } from 'store/actions/taskActions';
import { reassignTaskValidation } from '../utils/validation';
// import { toast } from 'react-toastify';

const ReassignTaskModal = (props) => {
    const { open, onClose, GetAllCompanyEmployees, UpdateTask, all_employees, data, employee_id } = props;
    const theme = useTheme();

    // state
    const [loading, setLoading] = useState({});
    const [payload, setPayload] = useState({ employees: [] });

    // memos
    const employees = useMemo(() => {
        return all_employees
            ?.filter((emp) => emp?.id !== employee_id)
            ?.map((user) => ({
                name: user?.user_detail?.first_name
                    ? `${user?.user_detail?.first_name} ${user?.user_detail?.last_name}`
                    : user?.user_detail?.email,
                value: user?.id,
            }));
    }, [all_employees]);

    // functions
    const handleClose = () => {
        onClose();
        setTimeout(() => setPayload({}), 500);
    };
    const handleSubmit = async (values) => {
        const users = values.employees?.map((employee) => parseInt(employee));
        setLoading({ ...loading, submit: true });
        const { id, assigned_by, standard } = data;

        const res = await UpdateTask(
            {
                assigned_to: users,
                reassign: true,
            },
            id,
            standard,
            assigned_by
        );
        setLoading({ ...loading, submit: false });
        if (res?.success) {
            toast.success('Task reassigned successfully');
            handleClose();
        } else {
            toast.error("Something went wrong, couldn't assign task to employee(s)");
        }
    };

    //async action
    const getAllEmployees = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAllCompanyEmployees();
        setLoading({ ...loading, content: false });
        if (!res?.status) {
            toast.error(res?.status);
        }
    };

    // useEffect
    useEffect(() => {
        if (open) {
            setPayload({ employees: data?.assigned_to });
        }
    }, [open, data]);

    useEffect(() => {
        getAllEmployees();
    }, []);

    return (
        <AppForm initialValues={payload} onSubmit={handleSubmit} validate={reassignTaskValidation}>
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                title={
                    <Typography sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.gray[800] }}>
                        Re-assign task
                    </Typography>
                }
                headerAction={
                    <IconButton color="disabled" onClick={handleClose}>
                        <Close />
                    </IconButton>
                }
                titleStyle={{ borderBottom: `1px solid ${theme.palette.neutral[90]}` }}
                width={540}
                padding="0px"
            >
                <Box sx={{ py: 0.4, pb: 6, px: 3 }}>
                    <Box>
                        <AppFormSelect
                            label="Select Employees(s)"
                            multiple
                            options={employees}
                            name="employees"
                            loading={loading.content}
                            noDataLabel="No employees at the moment"
                            renderValue={(selected) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 1.2,
                                    }}
                                >
                                    {selected?.map((value) => {
                                        const employee = employees?.find((employee) => employee?.value == value);
                                        return (
                                            <Chip
                                                sx={{
                                                    backgroundColor: theme.palette.gray[95],
                                                    borderRadius: 1,
                                                    p: 0.5,
                                                    color: theme.palette.gray[40],
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                }}
                                                key={value}
                                                label={employee?.name}
                                            />
                                        );
                                    })}
                                </Box>
                            )}
                        />
                        <AppSubmitButton
                            text="Save"
                            variant="contained"
                            color="primary"
                            loadingPosition="center"
                            loading={loading?.submit}
                            sx={{ width: '100%', mt: 4.5 }}
                        />
                    </Box>
                </Box>
            </AppCenteredModal>
        </AppForm>
    );
};
const mapStateToProps = (state) => ({
    all_employees: state?.generalReducers?.all_employees,
    employee_id: state?.authReducers?.user_info?.employee?.id,
});
export default connect(mapStateToProps, { GetAllCompanyEmployees, UpdateTask })(ReassignTaskModal);
