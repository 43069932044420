import React, { useState } from 'react';
import { RequestCodeReview } from 'store/actions/merchantActions';
import AppRerunModal from 'components/new_components/AppCodereviewReportModal';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

const CodeReviewRerun = (props) => {
    const { open, handleClose, RequestCodeReview, row } = props;
    const [rerunning, setRerunning] = useState(false);

    const handleRerun = async () => {
        setRerunning(true);
        const res = await RequestCodeReview({
            version_control_tool: row?.version_control_tool,
            repo_type: row?.repo_type,
            link: row?.link,
            codereview_id: row?.codereview_id,
        });
        setRerunning(false);
        if (res?.success) {
            toast.success('Review Rerun Succcessful');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <AppRerunModal
            open={open}
            handleClose={handleClose}
            title="Are you sure you want to rerun this code review?"
            subtitle="Please note that this action can not be reversed."
            rerunning={rerunning}
            disabled={row?.status === 'rerunning' || row?.status === 'running' ? true : false}
            onRun={handleRerun}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { RequestCodeReview })(CodeReviewRerun);
