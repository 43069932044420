import { Stack, Typography, Box, Select, MenuItem } from '@mui/material';
import { useMemo, useState } from 'react';
import { ReactComponent as TrendUpIcon } from 'assets/img/trend-down.svg';
import { ReactComponent as TrendDownIcon } from 'assets/img/trend-up.svg';
import { useSelector } from 'react-redux';
import { Meter } from './Meter';

export default function RiskPosture() {
    const [percentage, setPercentage] = useState(0);
    const [category, setCategory] = useState('all');

    const handleChange = (e) => {
        setCategory(e.target.value);
    };

    const getAllData = (risk_posture_by_category) => {
        const totalPercentage = risk_posture_by_category?.reduce((total, el) => el.current_percentage + total, 0) ?? 0;
        const averagePercentage =
            totalPercentage && risk_posture_by_category?.length
                ? totalPercentage / risk_posture_by_category?.length
                : 0;

        return {
            category: 'all',
            current_percentage: averagePercentage,
            trend: 'stable',
            percentage_change: 0,
        };
    };

    // constants
    const overview = useSelector((state) => state?.riskAssessmentReducers?.overview);

    const categories = useMemo(() => {
        const result = overview?.risk_posture_by_category?.map((el) => el?.category) ?? [];
        return ['all', ...result];
    }, [overview]);

    const activeCategory = useMemo(() => {
        return category == 'all'
            ? getAllData(overview?.risk_posture_by_category)
            : category
            ? overview?.risk_posture_by_category?.filter((el) => el.category === category)[0] ?? {}
            : {};
    }, [category]);

    const percentType = useMemo(() => {
        setPercentage(activeCategory?.current_percentage?.toFixed() ?? 0);
        return activeCategory.trend === 'increase'
            ? 'increase'
            : activeCategory.trend === 'decrease'
            ? 'decrease'
            : null;
    }, [activeCategory]);

    const getPercentageChange = useMemo(() => {
        if (activeCategory.category === 'all') return ``;

        return percentType
            ? `${percentType === 'increase' ? '+' : '-'} ${activeCategory.percentage_change ?? 0}% ${percentType}`
            : null;
    }, [overview, activeCategory]);
    return (
        <div className="col-span-6 bg-white rounded-md border-[#f1f5f9] px-3 py-3">
            <Stack
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        color: '#2b3674',
                        fontWeight: 600,
                    }}
                >
                    Risk posture by category
                </Typography>

                <Select
                    labelId="category-select-label"
                    id="category-select"
                    value={category}
                    label="Category"
                    onChange={handleChange}
                    size="small"
                    sx={{
                        textTransform: 'capitalize',
                        backgroundColor: '#F8FAFC',
                        border: '1px solid #E2E8F0',

                        '& .MuiSelect-select': {
                            fontSize: 11,
                            paddingLeft: '10px',
                            paddingBlock: '3px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #E2E8F0 !important',
                        },
                    }}
                >
                    {categories.map((value, key) => (
                        <MenuItem sx={{ fontSize: 11, textTransform: 'capitalize' }} key={key} value={value}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </Stack>

            <div className="flex flex-col gap-4">
                <Box sx={{ mt: 3 }}>
                    <Meter percentage={percentage} />
                </Box>

                <div className="flex items-center justify-center gap-5">
                    <div className="flex gap-6 items-center">
                        {category !== 'all' && percentType && (
                            <div className="flex gap-1 items-center">
                                {percentType === 'increase' ? (
                                    <TrendUpIcon className="-scale-y-100" />
                                ) : (
                                    <TrendDownIcon className="-scale-y-100" />
                                )}

                                <span className="text-[#64748B] text-xs">{getPercentageChange} this month</span>
                            </div>
                        )}

                        <span className="flex gap-2 items-center justify-start">
                            <p className="text-xs font-semibold text-[#475569]">Target risk level</p>
                            <span className="bg-[#395BA9] text-white px-1 py-0.5 text-[13px] rounded-md">
                                {(overview?.target_level ?? 0) + '%'}
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            {false && (
                <div className="flex gap-2 items-center">
                    <button onClick={() => percentage < 100 && setPercentage(percentage + 10)}>increase</button>
                    <button onClick={() => percentage > 0 && setPercentage(percentage - 10)}>decrease</button>
                </div>
            )}
        </div>
    );
}
