import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button } from 'antd';

const AnnexModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const { open, handleCancel, one_req, CreateAnnex, EditAnnex } = props;
    // function
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res =
            open === 'Add' ? await CreateAnnex({ ...data, category: 'annex_two' }) : await EditAnnex(data, one_req.id);
        setLoading(false);
        if (res.success) {
            setData({});
            handleCancel();
            open === 'Add' ? toast.success('Annex Added Successfully') : toast.success('Annex Updated Successfully');
        } else {
            toast.error(res?.message);
        }
    };

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    // useEffect
    useEffect(() => {
        if (one_req) {
            setData(one_req);
        } else {
            setData({});
        }
    }, [one_req]);
    return (
        <Modal title={`${open} Annex`} open={open} onCancel={handleCancel} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="title">Annex Title</label>
                        <Input
                            type="text"
                            size="large"
                            name="title"
                            id="title"
                            onChange={handleTextChange}
                            value={data.title || ''}
                            required
                        />
                    </Col>
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

export default AnnexModal;
