import React from 'react';
import CheckGreen from '../../../../assets/img/dashboard/CheckDone.svg';
import percentProgress from '../../../../assets/img/dashboard/percentProgress.svg';
import { ComplianceCardReport, ComplianceCardReportProgress } from '../../dashboard-utils/DashboardUtils';
import AuditGreenIcon from '../../../../assets/img/dashboard/AuditGreenIcon.svg';
import AppButton from '../../../new_components/AppButton';

//translations
import { useTranslation } from 'react-i18next';

const PCISLCReport = ({ percentage }) => {
    //translation
    const { t } = useTranslation('overview');

    const step = 1;
    return (
        <>
            {step === 1 && (
                <div>
                    <ComplianceCardReport title="PCISLC" subTitle={t('implementation')} percentage={percentage} />
                </div>
            )}
            {step === 2 && (
                <ComplianceCardReportProgress>
                    <div>
                        <h3 className="textbrandColor font-bold lg:text-[18px] text-[11px] pt-2">PCIDSS 4.0</h3>
                        <div className="mb-2 pt-1">
                            <div className="flex items-center justify-between">
                                <div className="text-[12px] font-medium">{t('implementationCompleted')}</div>
                                <img src={CheckGreen} alt="CheckGreen" className="lg:w-[14px] w-[10px]" />
                            </div>
                        </div>
                        <div className="mb-2">
                            <div className="flex items-center justify-between">
                                <div className="text-[12px] font-medium">{t('auditing')}</div>
                                <img src={percentProgress} alt="percentProgress" className="lg:w-[14px] w-[10px]" />
                            </div>
                        </div>
                    </div>
                </ComplianceCardReportProgress>
            )}
            {step === 3 && (
                <ComplianceCardReportProgress>
                    <div>
                        <h3 className="textbrandColor font-bold lg:text-[18px] text-[11px] pt-2">PCIDSS 4.0</h3>
                        <div className="mb-2 pt-1">
                            <div className="flex items-center justify-between">
                                <div className="text-[12px] font-medium">{t('implementationCompleted')}</div>
                                <img src={CheckGreen} alt="CheckGreen" className="lg:w-[14px] w-[10px]" />
                            </div>
                        </div>
                        <div className="mb-2">
                            <div className="flex items-center justify-between">
                                <div className="text-[12px] font-medium">{t('auditing')}</div>
                                <img src={CheckGreen} alt="CheckGreen" className="lg:w-[14px] w-[10px]" />
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center justify-between">
                                <div className="text-[12px] font-medium italic">{t('certification')}</div>
                                <img src={percentProgress} alt="CheckGreen" className="lg:w-[16px] w-[10px]" />
                            </div>
                        </div>
                    </div>
                </ComplianceCardReportProgress>
            )}
            {step === 4 && (
                <ComplianceCardReportProgress>
                    <div className="text-center">
                        <div className="textbrandColor font-bold lg:text-[18px] text-[11px] pt-2 !pb-0">PCIDSS 4.0</div>
                        <div className="">
                            <div className="flex items-center justify-center">
                                <img src={AuditGreenIcon} alt="AuditGreenIcon" className="lg:w-[35px] w-[30px]" />
                                <div className="text-lg font-bold textbrandColor pl-3">{t('compliant')}</div>
                            </div>
                        </div>
                        <p className="text-[9px]">February 12th, 2021 - February 13th, 2022</p>
                        <div className="mt-1">
                            <AppButton
                                name="Download Certificate"
                                color="primary"
                                variant="contained"
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    height: '30px',
                                }}
                            />
                        </div>
                    </div>
                </ComplianceCardReportProgress>
            )}
        </>
    );
};

export default PCISLCReport;
