export const NO_PER_PAGE = 10;
export const getNoOfPages = (count) => {
    return Math.ceil(count / NO_PER_PAGE);
};

export const STATUS_OPTIONS = [
    { name: 'Awaiting approval', value: 'waiting_approval' },
    { name: 'Completed', value: 'completed' },
    { name: 'Not Completed', value: 'not_completed' },
];
