import { useState } from 'react';
import { toast } from 'react-toastify';

import AppDeleteModal from 'components/new_components/AppDeleteModalNew';

// redux
import { useDispatch } from 'react-redux';
import { DeleteMultipleRiskAssessment, DeleteRiskAssessment } from 'store/actions/riskAssessmentActions';

const DeleteModal = (props) => {
    const { open, handleClose, id, ids, params } = props;

    const [deleting, setDeleting] = useState(false);
    const dispatch = useDispatch();

    //function
    const handleDelete = async () => {
        if (id) {
            setDeleting(true);
            const res = await dispatch(DeleteRiskAssessment(id, params));
            setDeleting(false);
            if (res?.success) {
                toast.success('Successfully deleted a risk.');
                handleClose();
            } else {
                toast.error(res?.message);
            }
        } else {
            setDeleting(true);
            const res = await dispatch(DeleteMultipleRiskAssessment(ids));
            setDeleting(false);
            if (res?.success) {
                toast.success(`Successfully deleted ${ids?.length} risks.`);
                handleClose();
            } else {
                toast.error(res?.message);
            }
        }
    };

    return (
        <AppDeleteModal
            open={open}
            handleClose={handleClose}
            deleting={deleting}
            onDelete={handleDelete}
            actionsPosition="center"
            title={id ? 'Delete Risk?' : `Delete ${ids?.length} Risks?`}
            sub_title="This action cannot be undone. Are you sure you want to proceed??"
        />
    );
};
export default DeleteModal;
