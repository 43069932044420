import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { WarningAmberOutlined } from '@mui/icons-material';
// import DeleteIcon from "assets/img/DeleteIcon.svg";

// core components
import CenteredModal from './CenteredModal';
import AppButton from './AppButton';
import AppLoadingButton from './AppLoadingButton';

const AppInactivityModal = (props) => {
    const { open, handleClose, loading, onLogout, onContinue, countdown } = props;
    const theme = useTheme();

    return (
        <CenteredModal open={open} handleClose={handleClose} width={450} height={300}>
            <Box
                sx={{
                    fontWeight: 500,
                    color: theme.palette.gray[800],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    p: 1,
                }}
            >
                <WarningAmberOutlined sx={{ fontSize: '90px', color: theme.palette.error[500], mb: 1 }} />
                <span style={{ fontSize: '18px', fontWeight: 700 }}>Notice of Inactivity</span>

                <p style={{ fontSize: '14px', fontWeight: 500, marginTop: '1rem' }}>
                    Your session is about to expire in {countdown} seconds due to inactivity. You will be redirected to
                    the login page.
                </p>
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ my: 1.5 }}>
                    <AppButton
                        name="Stay Logged In"
                        variant="text"
                        sx={{ color: theme.palette.gray[900] }}
                        onClick={onContinue}
                    />
                    <AppLoadingButton
                        text="Log Out"
                        variant="contained"
                        color="error"
                        loading={loading}
                        loadingPosition="center"
                        onClick={onLogout}
                    />
                </Stack>
            </Box>
        </CenteredModal>
    );
};

export default AppInactivityModal;
