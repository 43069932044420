import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Button, Input, Select } from 'antd';

//Redux
import { connect } from 'react-redux';
import { SendPushNotification, EditPushNotification } from 'store/actions/adminActions';

const { Option } = Select;
const PushNotificationModal = (props) => {
    const { open, handleClose, mode, modalData, SendPushNotification, EditPushNotification, all_users } = props;
    const [loading, setloading] = useState(false);
    const [data, setData] = useState({ title: '', body: '', schedule: '', users_list: '' });

    const handleTextChange = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value });
    };

    const handleSelectChange = (value) => {
        setData({ ...data, schedule: value });
    };
    const handleMultiSelectChange = (value) => {
        setData({ ...data, users_list: value });
    };
    const onClose = () => {
        setData({});
        handleClose();
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };

    //filter all users and take out smartcomply admin and support
    const handleSubmit = async (e) => {
        e.preventDefault();
        setloading(true);
        const payload = { ...data, users_list: data?.users_list?.length ? data?.users_list?.join(',') : '' };
        const res =
            mode === 'add' ? await SendPushNotification(payload) : await EditPushNotification(payload, modalData?.id);
        setloading(false);
        if (res?.success) {
            toast.success(`${mode === 'add' ? 'Sent' : 'Updated'} Successfully!`);
            onClose();
        } else {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (mode === 'edit') {
            const users_list = modalData?.users_list?.split(',')?.map((id) => parseInt(id));
            setData({
                title: modalData?.title,
                body: modalData?.body,
                schedule: modalData?.schedule,
                users_list: users_list,
            });
        }
    }, [mode, modalData]);
    return (
        <Modal
            title={mode === 'add' ? 'Send Push Notification' : 'Edit Push Notification details'}
            open={open}
            onCancel={onClose}
            footer={null}
            destroyOnClose={true}
        >
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="title">Title</label>
                        <Input
                            type="text"
                            size="large"
                            name="title"
                            id="title"
                            onChange={handleTextChange}
                            value={data.title || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="body">Body</label>
                        <Input
                            type="text"
                            size="large"
                            name="body"
                            id="body"
                            onChange={handleTextChange}
                            value={data.body || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="schedule">Schedule</label>
                        <Select
                            type="text"
                            size="large"
                            name="schedule"
                            id="schedule"
                            className="block"
                            onChange={handleSelectChange}
                            value={data.schedule || ''}
                            options={[
                                {
                                    value: 'once',
                                    label: 'Once',
                                },
                                {
                                    value: 'daily',
                                    label: 'Daily',
                                },
                                {
                                    value: 'weekly',
                                    label: 'Weekly',
                                },
                                {
                                    value: 'monthly',
                                    label: 'Monthly',
                                },
                                {
                                    value: 'quarterly',
                                    label: 'Quarterly',
                                },
                                {
                                    value: 'annually',
                                    label: 'Annually',
                                },
                            ]}
                            required
                        ></Select>
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="users_list">Users</label>{' '}
                        <small className="text-[#cacaca]">(Note: To send to all users, do not select any user)</small>
                        <Select
                            mode="multiple"
                            allowClear
                            size="large"
                            showSearch
                            name="users_list"
                            id="users_list"
                            className="block"
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            onChange={handleMultiSelectChange}
                            defaultValue={data?.users_list || undefined}
                        >
                            {all_users?.map((user) => {
                                const { first_name, last_name, email, id, user_type, organization } = user ?? {};
                                return email === 'admin1@cga.com' ||
                                    email === 'vf.olofinlade2@386konsult.com' ||
                                    first_name === 'Admin' ||
                                    user_type === 'employee' ? null : (
                                    <Option value={id} key={id} label={`${first_name} ${last_name}`}>
                                        {first_name
                                            ? `${first_name} ${last_name} ${
                                                  organization?.name
                                                      ? `(${organization?.name})`
                                                      : organization?.owner_detail?.first_name
                                                      ? `(${
                                                            organization?.owner_detail?.first_name +
                                                            ' ' +
                                                            organization?.owner_detail?.last_name
                                                        })`
                                                      : ''
                                              }`
                                            : email}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Col>
                    <Col style={{ marginTop: '5rem' }}>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { SendPushNotification, EditPushNotification })(PushNotificationModal);
