import React from 'react';
import { Switch, Route } from 'react-router-dom';
import IsoDashboard from './IsoDashboard';
import FormsAndDocumentsView from './Annex/FormsAndDocumentsView';
import IsoFormCreation from '../Operations/FormCreation';
import AnnexesView from './Annex/AnnexesView';
import ManagementClausesView from './ManagementClause/ManagementClausesView';
import ClauseFormsAndDocumentsView from './ManagementClause/ClauseFormsAndDocumentView';

const Iso27001 = () => {
    return (
        <Switch>
            <Route path="/admin/iso27001" exact component={IsoDashboard} />
            <Route path="/admin/iso27001/annexes" exact component={AnnexesView} />
            <Route path="/admin/iso27001/clauses" exact component={ManagementClausesView} />
            <Route path="/admin/iso27001/clause" exact component={ClauseFormsAndDocumentsView} />
            <Route path="/admin/iso27001/annex" exact component={FormsAndDocumentsView} />
            <Route path="/admin/iso27001/form" component={(props) => <IsoFormCreation tag="iso27001" {...props} />} />
        </Switch>
    );
};

export default Iso27001;
