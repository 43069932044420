import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import ComplianceTip from '../ComplianceTip';

//translation
import { useTranslation } from 'react-i18next';

const ExceptionHeader = (props) => {
    const { count, title, titleFontSize } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('compliance');
    return (
        <Stack
            sx={{
                borderBottom: `1px solid ${theme.palette.gray[100]}`,
                p: 2,
                pb: 0,
                alignItems: 'flex-start',
            }}
        >
            <Stack direction="row" spacing={2.5} alignItems="center">
                <Typography
                    sx={{
                        fontWeight: 700,
                        fontSize: titleFontSize || 24,
                        color: theme.palette.primary[900],
                    }}
                >
                    {title}
                </Typography>
                <Box
                    sx={{
                        border: `2px solid ${theme.palette.warning[900]}`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '3px',
                        color: theme.palette.primary[900] + 'f2',
                        fontWeight: 500,
                        fontSize: 14,
                        px: 0.7,
                        whiteSpace: 'nowrap',
                    }}
                >
                    {count} {t('exceptions')}
                </Box>
            </Stack>
            <ComplianceTip tip={t('toggle')} />
        </Stack>
    );
};

export default ExceptionHeader;
