import React from 'react';
import SearchIcon from 'assets/img/Searchicon.svg';

const RiskTitle = (props) => {
    const { onSearch } = props;

    return (
        <div className="relative">
            <input
                placeholder="Search..."
                onChange={onSearch}
                className="text-gray-400 text-sm font-400 placeholder:text-gray-400 border border-gray-200 py-2.5 rounded-[4px] w-[230px] px-[30px]"
            />
            <img src={SearchIcon} alt="SearchIcon" className="absolute left-2 top-3 w-[17px]" />
        </div>
    );
};

export default RiskTitle;
