import React from 'react';
//components
import { Stack } from '@mui/material';
import { useTheme } from '@mui/styles';
import { pentestVendors } from '../utils/constants';

const PentestVendorTab = ({ onClick, view }) => {
    const theme = useTheme();
    return (
        <Stack
            alignItems="center"
            sx={{
                width: '100%',
                mx: 0.4,
                borderRadius: '4px',
                // backgroundColor: theme.palette.gray[50],
                py: 0.25,
            }}
        >
            <Stack
                direction="row"
                justifyContent="center"
                spacing={1}
                sx={{
                    py: 0.3,
                    mx: 0.5,
                    flex: 1,
                    width: '100%',
                }}
            >
                {pentestVendors.map((vendor) => {
                    return (
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={3}
                            sx={{
                                borderRadius: '4px !important',
                                color: theme.palette.primary[900],
                                background: view === vendor.name ? '#fff !important' : '#E4E2E680 !important',
                                textTransform: 'capitalize',
                                fontSize: '14px',
                                fontWeight: 700,
                                py: 1,
                                px: 2.5,
                                // width: '50%',
                                boxShadow: 'none',
                            }}
                            onClick={() => onClick(vendor.name)}
                            key={vendor.name}
                            disabled={vendor.name === 'Cobalt'}
                        >
                            <img src={vendor.logo} alt="wired Assurance" className="mr-1" /> {vendor.name}
                        </Stack>
                    );
                })}
            </Stack>
        </Stack>
    );
};

export default PentestVendorTab;
