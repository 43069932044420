import { INTEGRATIONS_GROUPS, MERCHANT_INTEGRATIONS, ONE_INTEGRATION, AUTHORIZATION_URL } from '../constants';
import axiosInstance from '../../utils/https';

export const GetAllIntegrationsGroups = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/integrations/groups/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: INTEGRATIONS_GROUPS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetOneIntegration = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/integrations/integration/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ONE_INTEGRATION, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllMerchantIntegrations = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/integrations/userintegration/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: MERCHANT_INTEGRATIONS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateMerchantIntegration = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/integrations/userintegration/`, {
            ...credentials,
            merchant: company_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllIntegrationsGroups());
        dispatch(GetAllMerchantIntegrations());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// file upload
export const UploadFile = (formData) => async () => {
    try {
        const res = await axiosInstance().post(`/integrations/integration_file_upload/`, formData);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res?.message,
            file_url: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteMerchantIntegration = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/integrations/userintegration/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllIntegrationsGroups());
        dispatch(GetAllMerchantIntegrations());
        return {
            success: true,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//  R E Q U E S T   A   T O O L
export const RequestATool = (credentials) => async (_, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/integrations/request_integration/`, { ...credentials, merchant });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAuthorizationUrl = (integration) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(
            `/integrations/oauth_authorisation/?merchant_id=${merchant}&integration_name=${integration}`
        );
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: AUTHORIZATION_URL, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const CreateOauthIntegration = (credentials, oauth_route) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/integrations/${oauth_route}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllIntegrationsGroups());
        dispatch(GetAllMerchantIntegrations());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UpdateUserScope = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/integrations/userintegration/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantIntegrations());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
