import React, { useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Box, Stack, Typography, Card } from '@mui/material';
import Play from '../../../../../assets/img/bullet.svg';
import EmptyScan from '../../../../../assets/img/emptyFile.png';
import { toast } from 'react-toastify';
import ExemptTag from '../../common/ExemptTag';

// core components
import ItemSubheader from '../../common/ItemSubheader';

// redux
import { connect } from 'react-redux';
import { RevertBlockchainCompliance } from 'store/actions/complianceActions';

//translation
import { useTranslation } from 'react-i18next';

const ConnectToolsItem = (props) => {
    const {
        category,
        getAllExemptedBlockchain,
        onResourceChange,
        RevertBlockchainCompliance,
        exception,
        all_background_check_items,
    } = props;
    const [loading, setLoading] = useState({ upload: false, exempt: false });
    const theme = useTheme();

    //translation
    const { t } = useTranslation('compliance');

    // memo
    // get the current document in view
    const selectedCategory = useMemo(() => {
        return all_background_check_items?.find((doc) => doc?.id === category?.id);
    }, [category, all_background_check_items]);

    const removeExempt = async () => {
        setLoading({ ...loading, exempt: true });
        const res = await RevertBlockchainCompliance(category?.id);
        setLoading({ ...loading, exempt: false });
        getAllExemptedBlockchain();
        onResourceChange();
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    const payload = {
        subcriteria: category?.subcriteria,
        criteria: category?.criteria,
        tool: category?.id,
        reason: '',
    };

    return (
        <Box sx={{ p: 2, py: 4 }}>
            {exception ? (
                <Box sx={{ marginLeft: '1.5rem', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <ExemptTag
                        onClick={removeExempt}
                        loading={loading?.exempt}
                        loadingLabel={t('reverting')}
                        label={t('revert')}
                    />
                </Box>
            ) : (
                <ItemSubheader
                    exempting={loading.exempt}
                    noResource
                    type={'code_repository'}
                    payload={payload}
                    blockchain
                    onResourceChange={onResourceChange}
                />
            )}
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ py: 5 }} spacing={3}>
                <Box sx={{ marginTop: 0.8 }}>
                    <img src={Play} alt="" />
                </Box>
                <Stack>
                    <Typography
                        sx={{
                            color: theme.palette.gray[800],
                            fontWeight: 500,
                            fontSize: 16,
                        }}
                    >
                        {category?.title || category?.tool_detail?.title}
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 300,
                            fontSize: 12,
                        }}
                    >
                        {t('blockchain.noScan')}
                        <Typography
                            component={'span'}
                            sx={{
                                fontWeight: 500,
                                fontSize: 12,
                                textDecoration: 'underline',
                                color: theme.palette.primary[800],
                                pointerEvents: exception ? 'none' : 'hover',
                            }}
                        >
                            <Link
                                to={{
                                    pathname: '/merchant/settings/server',
                                    state: {
                                        tab_: 1,
                                    },
                                }}
                            >
                                {t('blockchain.connectTools')}
                            </Link>
                        </Typography>
                        .
                    </Typography>
                    {selectedCategory?.category_details?.github?.length > 0 ||
                    selectedCategory?.category_details?.jira?.length > 0 ||
                    selectedCategory?.category_details?.shortcut?.length > 0 ? (
                        <Stack direction="column" spacing={3} sx={{ pt: 4 }}>
                            {selectedCategory?.category_details?.github?.length > 0 && (
                                <Card
                                    variant={'outlined'}
                                    sx={{
                                        p: 1,
                                        borderRadius: '10px',
                                        height: '100%',
                                        alignContent: 'space-between',
                                        padding: theme.spacing(2, 3, 4, 3),
                                        width: '600px',
                                        boxShadow:
                                            '-111px 221px 99px rgba(230, 230, 230, 0.01), -62px 124px 83px rgba(230, 230, 230, 0.05), -28px 55px 62px rgba(230, 230, 230, 0.09), -7px 14px 34px rgba(230, 230, 230, 0.1), 0px 0px 0px rgba(230, 230, 230, 0.1)',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: 18,
                                            color: theme.palette.primary[900],
                                        }}
                                    >
                                        {t('blockchain.github')}
                                    </Typography>
                                    <Stack direction="row" sx={{ pt: 4, flexWrap: 'wrap', marginRight: 1 }}>
                                        {selectedCategory?.category_details?.github?.map((github, index) => (
                                            <Box key={index} sx={{ marginRight: 1, marginBottom: 1 }}>
                                                <ScanStatus
                                                    status={github?.status}
                                                    description={github?.scan}
                                                    theme={theme}
                                                />
                                            </Box>
                                        ))}
                                    </Stack>
                                </Card>
                            )}
                            {selectedCategory?.category_details?.gitlab?.length > 0 && (
                                <Card
                                    variant={'outlined'}
                                    sx={{
                                        p: 1,
                                        borderRadius: '10px',
                                        height: '100%',
                                        alignContent: 'space-between',
                                        padding: theme.spacing(2, 3, 4, 3),
                                        width: '600px',
                                        boxShadow:
                                            '-111px 221px 99px rgba(230, 230, 230, 0.01), -62px 124px 83px rgba(230, 230, 230, 0.05), -28px 55px 62px rgba(230, 230, 230, 0.09), -7px 14px 34px rgba(230, 230, 230, 0.1), 0px 0px 0px rgba(230, 230, 230, 0.1)',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: 18,
                                            color: theme.palette.primary[900],
                                        }}
                                    >
                                        {t('blockchain.github')}
                                    </Typography>
                                    <Stack direction="row" spacing={3} sx={{ pt: 4, flexWrap: 'wrap' }}>
                                        {selectedCategory?.category_details?.gitlab?.map((github, index) => (
                                            <Box key={index} sx={{ marginRight: 1, marginBottom: 1 }}>
                                                <ScanStatus
                                                    status={github?.status}
                                                    description={github?.scan}
                                                    theme={theme}
                                                />
                                            </Box>
                                        ))}
                                    </Stack>
                                </Card>
                            )}
                            {selectedCategory?.category_details?.jira?.length > 0 && (
                                <Card
                                    variant={'outlined'}
                                    sx={{
                                        p: 1,
                                        borderRadius: '10px',
                                        height: '100%',
                                        alignContent: 'space-between',
                                        padding: theme.spacing(2, 3, 4, 3),
                                        width: '600px',
                                        boxShadow:
                                            '-111px 221px 99px rgba(230, 230, 230, 0.01), -62px 124px 83px rgba(230, 230, 230, 0.05), -28px 55px 62px rgba(230, 230, 230, 0.09), -7px 14px 34px rgba(230, 230, 230, 0.1), 0px 0px 0px rgba(230, 230, 230, 0.1)',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: 18,
                                            color: theme.palette.primary[900],
                                        }}
                                    >
                                        {t('blockchain.jira')}
                                    </Typography>
                                    <Stack direction="row" spacing={3} sx={{ pt: 4, flexWrap: 'wrap' }}>
                                        {selectedCategory?.category_details?.jira?.map((github, index) => (
                                            <Box key={index} sx={{ marginRight: 1, marginBottom: 1 }}>
                                                <ScanStatus
                                                    status={github?.status}
                                                    description={github?.scan}
                                                    theme={theme}
                                                />
                                            </Box>
                                        ))}
                                    </Stack>
                                </Card>
                            )}
                        </Stack>
                    ) : (
                        <Typography sx={{ display: 'flex', marginTop: '2rem', alignItems: 'center' }}>
                            {t('blockchain.noScans')} <img src={EmptyScan} />
                        </Typography>
                    )}
                </Stack>
            </Stack>
        </Box>
    );
};

const ScanStatus = ({ status, description, theme }) => {
    return (
        <Box
            sx={{
                border: '0.5px solid #B8BCCC',
                borderRadius: '20px',
                px: 1.6,
                py: 0.3,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '12px',
            }}
        >
            <span
                style={{
                    height: '10px',
                    width: '10px',
                    borderRadius: '50%',
                    marginRight: '8px',
                    backgroundColor: status === 'success' ? theme.palette.success[300] : theme.palette.error[500],
                    display: 'inline-block',
                    fontSize: '12px',
                }}
            ></span>
            <Typography sx={{ letterSpacing: '1.2px', fontSize: '12px' }}>{description}</Typography>
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        all_background_check_items: state?.adminReducers?.all_background_check_items,
    };
};

export default connect(mapStateToProps, {
    RevertBlockchainCompliance,
})(ConnectToolsItem);
