import React, { useState } from 'react';
import { toast } from 'react-toastify';

import AppDeleteModal from 'components/new_components/AppDeleteModal';

// redux
import { connect } from 'react-redux';
import { DeletePentestRequest } from 'store/actions/merchantActions';

//translations
import { useTranslation } from 'react-i18next';

const PentestDeleteModal = (props) => {
    const { open, handleClose, payload, DeletePentestRequest, id } = props;

    const [deleting, setDeleting] = useState(false);

    //translation
    const { t } = useTranslation('pentest');

    //function
    const handleDelete = async () => {
        setDeleting(true);
        const res = await DeletePentestRequest(id);
        setDeleting(false);
        if (res?.success) {
            toast.success('Successfully deleted a pentest record.');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <AppDeleteModal
            open={open}
            handleClose={handleClose}
            title={t('pentestDeleteModal.title')}
            subtitle={`${t('pentestDeleteModal.subtitle1')}
            '${payload?.name}' ${t('pentestDeleteModal.subtitle2')}`}
            deleting={deleting}
            onDelete={handleDelete}
        />
    );
};
const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, {
    DeletePentestRequest,
})(PentestDeleteModal);
