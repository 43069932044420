import React, { useState } from 'react';
import { toast } from 'react-toastify';

import AppDeleteModal from 'components/new_components/AppDeleteModal';

// redux
import { connect } from 'react-redux';
import { DeleteVendor } from 'store/actions/vendorActions';

//translations
import { useTranslation } from 'react-i18next';

const VendorDeleteModal = (props) => {
    const { open, handleClose, DeleteVendor, id } = props;

    const [deleting, setDeleting] = useState(false);

    //translation
    const { t } = useTranslation('vendorManagement');

    //function
    const handleDelete = async () => {
        setDeleting(true);
        const res = await DeleteVendor(id);
        setDeleting(false);
        if (res?.success) {
            toast.success('Successfully deleted a Vendor.');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <AppDeleteModal
            open={open}
            handleClose={handleClose}
            title={t('vendorDeleteModal.title')}
            subtitle={t('vendorDeleteModal.subtitle')}
            deleting={deleting}
            onDelete={handleDelete}
        />
    );
};
const mapStateToProps = (state) => {
    return { ...state };
};
export default connect(mapStateToProps, {
    DeleteVendor,
})(VendorDeleteModal);
