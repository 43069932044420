import { Dialog } from '@mui/material';
import { Loader2 } from 'lucide-react';
import React, { useState } from 'react';

function NewPerson() {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
    });

    return (
        <>
            <button
                className="flex items-center gap-1 !py-1.5 bg-white !px-2 hover:brightness-95 transition-all"
                onClick={(event) => {
                    event.stopPropagation();
                    setIsDialogOpen(true);
                }}
            >
                <img src="/img/risk-assessment/add-light.svg" alt="Plus Icon" className="object-contain" />

                <p className="font-medium text-[13px] text-[#395BA9]">Add person</p>
            </button>

            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                PaperProps={{
                    sx: {
                        borderRadius: '4px !important',
                        padding: 0,
                        boxShadow: '0px 16px 24px 0px #00000026',
                        width: '406px',
                    },
                }}
            >
                <div className="flex items-center justify-between px-6 !pt-6">
                    <h5 className="text-lg font-semibold text-[#202D66]">New person</h5>

                    <button
                        className="border-[#E2E8F0] border rounded-full !p-1 h-6 w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                        onClick={() => setIsDialogOpen(false)}
                    >
                        <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                    </button>
                </div>

                <div className="!p-6 flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <p className="font-medium text-[13px] text-[#64748B]">Full Name</p>

                        <input
                            type="text"
                            placeholder="Enter full name"
                            value={formData.fullName}
                            onChange={(event) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    fullName: event.target.value,
                                }));
                            }}
                            className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                        />
                    </div>

                    <div className="flex flex-col gap-2">
                        <p className="font-medium text-[13px] text-[#64748B]">Email</p>

                        <input
                            type="email"
                            placeholder="Enter correct email"
                            value={formData.email}
                            onChange={(event) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    email: event.target.value,
                                }));
                            }}
                            className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                        />
                    </div>
                </div>

                <div className="flex items-center justify-end !p-6 bg-[#F8FAFC] gap-2">
                    <button
                        className="bg-[#fff] border border-[#E2E8F0] rounded-sm !px-4 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                        onClick={() => setIsDialogOpen(false)}
                    >
                        Cancel
                    </button>

                    <button
                        className="bg-[#202D66] rounded-sm !px-4 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none flex items-center"
                        disabled={!formData.fullName || !formData.email}
                        onClick={() => {}}
                    >
                        {false && <Loader2 className="mr-2 animate-spin h-4 w-4" />}
                        Add
                    </button>
                </div>
            </Dialog>
        </>
    );
}
export default NewPerson;
