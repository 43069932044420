import { Stack, Typography, Box } from '@mui/material';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
// import { ReactComponent as DateIcon } from 'assets/img/calendar.svg';
// import { CategoryScale } from 'chart.js';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useSelector } from 'react-redux';

// Chart.register(CategoryScale);
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function TopThreats() {
    // const yearUserSignedUp = 2020;

    // const [type, setType] = useState(yearUserSignedUp);
    // const handleChange = (e) => {
    //     setType(e.target.value);
    // };

    const overview = useSelector((state) => state?.riskAssessmentReducers?.overview);

    const threats = useMemo(() => {
        return overview?.top_threats ? overview?.top_threats?.map((el) => el.threat__name) : [];
    }, [overview]);

    const values = useMemo(() => {
        return overview?.top_threats ? overview?.top_threats?.map((el) => el.count) : [];
    }, [overview]);

    const maxValue = useMemo(
        () =>
            values.reduce((max, item) => {
                return item.value > max ? item.value : max;
            }, 0),
        [values]
    );
    const MAX_ITEMS = 5;

    const data = {
        labels: threats.slice(0, 6),
        datasets: [
            {
                axis: 'y',
                data: values.slice(0, 6),
                fill: false,
                backgroundColor: ['#395BA9', '#395BA9', '#395BA9', '#395BA9', '#395BA9'],
                borderColor: ['#395BA9', '#395BA9', '#395BA9', '#395BA9', '#395BA9'],
                borderWidth: 1,
                borderRadius: 20, // Rounded corners
                maxBarThickness: 33,
            },
        ],
    };

    const options = useMemo(
        () => ({
            indexAxis: 'y', // makes the chart horizontal
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: true,
                    // text: 'Threats between 2016-2020',
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    font: {
                        family: 'Poppins, sans-serif',
                    },
                },
                indexAxis: 'y',
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    border: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                        color: '#64748B',
                        padding: 6,
                        font: {
                            family: 'Poppins, sans-serif',
                        },
                    },
                },
                y: {
                    min: 0,
                    // max: 100,
                    ticks: {
                        // stepsize should ensure there's max data of y is divided into 8
                        stepSize: Math.round(maxValue / MAX_ITEMS),
                        color: '#64748B',
                        padding: 4,
                        font: {
                            fontWeight: 'bold',
                            family: 'Poppins, sans-serif',
                        },
                    },
                    grid: {
                        // borderDash: [10]
                        display: false,
                    },
                    border: {
                        display: true,
                        borderColor: '#cbd5e1',
                    },
                },
            },
        }),
        [overview]
    );

    // function getYearsSinceJoining() {
    //     const currentYear = new Date().getFullYear();
    //     const years = [];

    //     for (let year = yearUserSignedUp; year <= currentYear; year++) {
    //         years.push(year);
    //     }

    //     return years;
    // }

    return (
        <div className="col-span-6 bg-white rounded-md border-[#f1f5f9] px-3 py-3">
            <Stack
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        color: '#2b3674',
                        fontWeight: 600,
                    }}
                >
                    Top 5 Threats
                </Typography>

                {/* <Select
                    labelId="year-select-label"
                    id="year-select"
                    value={type}
                    label="Years"
                    onChange={handleChange}
                    size="small"
                    sx={{
                        textTransform: 'capitalize',
                        backgroundColor: '#F8FAFC',
                        border: '1px solid #E2E8F0',

                        '& .MuiSelect-select': {
                            fontSize: 11,
                            paddingLeft: '10px',
                            paddingBlock: '3px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #E2E8F0 !important',
                        },
                    }}
                    defaultValue={yearUserSignedUp}
                    renderValue={(value) => (
                        <span className="flex gap-1 items-center">
                            <DateIcon className="w-4 h-4" />
                            {value}
                        </span>
                    )}
                >
                    {getYearsSinceJoining().map((value, key) => (
                        <MenuItem sx={{ fontSize: 11, textTransform: 'capitalize' }} key={key} value={value}>
                            {value}
                        </MenuItem>
                    ))}
                </Select> */}
            </Stack>

            <div className="flex flex-col gap-8">
                <Box sx={{ mt: 3 }}>
                    <div>
                        <Bar data={data} options={options} />
                    </div>
                </Box>
            </div>
        </div>
    );
}
