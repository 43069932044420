import {
    ALL_MERCHANT_ONBOARDING_ITEMS,
    ALL_MERCHANT_ONBOARDING_RESOURCE,
    ALL_ONBOARDING_ITEMS_FOR_MERCHANTS,
} from '../constants';
import axiosInstance from '../../utils/https';

// onboarding
export const GetAllOnboardingItemsForMerchant = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/onboarding/?admin=true`);
        if (res?.status === 'fail') throw new Error(res?.message);
        dispatch({
            type: ALL_ONBOARDING_ITEMS_FOR_MERCHANTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllMerchantOnboardingItems = (merchant) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/onboarding/?merchant=${merchant || company_id}`);
        if (res?.status === 'fail') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_ONBOARDING_ITEMS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllMerchantOnboardingResource = (token) => async (dispatch) => {
    // const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/employee_onboarding_resources/?employee_token=${token}`);
        if (res?.status === 'fail') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_ONBOARDING_RESOURCE, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateMerchantOnboardingResource = (credentials, token) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/employee_onboarding/`, {
            ...credentials,
            employee_token: token,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantOnboardingResource(token));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateMerchantOnboardingItem = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    credentials.append('merchant', company_id);
    try {
        const res = await axiosInstance().post(`/compliance/onboarding/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantOnboardingItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditMerchantOnboardingItem = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/onboarding/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantOnboardingItems());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const NotifyMerchantEmployees = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/click_admin_policy/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantOnboardingItems());
        dispatch(GetAllOnboardingItemsForMerchant());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteOnboardingItem = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/onboarding/${id}/`);
        console.log(res);
        dispatch(GetAllMerchantOnboardingItems());
        return {
            success: true,
            message: 'Item deleted successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const ToggleOnboardingItemActive = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/deactivate_video/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantOnboardingItems());
        dispatch(GetAllOnboardingItemsForMerchant());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const RequestNameChange = (credentials) => async () => {
    try {
        const res = await axiosInstance().post(`compliance/request_name_change/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
