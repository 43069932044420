import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import BreadCrumb from 'components/Breadcrumb';
import { Row, Col, Button, Empty, Card, Typography, Modal } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, EyeOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import IntegrationGroupModal from './Operations/IntegrationGroupModal';

//react-router
import { Link } from 'react-router-dom';
//redux
import { connect } from 'react-redux';
import { GetAllIntegrationsGroups, DeleteIntegrationGroup } from 'store/actions/adminActions';

const { Title } = Typography;
const { confirm } = Modal;
const IntegrationDashboard = (props) => {
    const { GetAllIntegrationsGroups, DeleteIntegrationGroup, all_integrations_groups } = props;

    const [loading, setloading] = useState(false);
    const [ModalOpen, setModalOpen] = useState(false);
    const [mode, setMode] = useState('');
    const [payload, setPayload] = useState({});

    const openModal = (modalMode, data) => {
        setModalOpen(true);
        setMode(modalMode);
        setPayload(data);
    };
    const closeModal = () => {
        setModalOpen(false);
        setMode('');
        setPayload({});
    };

    const deleteIntegrationGroup = (id) => {
        confirm({
            title: 'Are you sure you want to delete this integration group?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteIntegrationGroup(id);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    useEffect(() => {
        const getAllIntegrationsGroups = async () => {
            setloading(true);
            const res = await GetAllIntegrationsGroups();
            setloading(false);
            if (!res.success) {
                toast.error(res?.message);
            }
        };

        getAllIntegrationsGroups();
    }, []);
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Integrations' }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal('Add')}
                    >
                        Add Group
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {!loading && (
                    <>
                        {all_integrations_groups && all_integrations_groups?.length > 0 ? (
                            <Row wrap gutter={24}>
                                {all_integrations_groups
                                    ?.sort((a, b) => a?.id - b?.id)
                                    ?.map((group) => (
                                        <Col
                                            xs={24}
                                            md={12}
                                            lg={8}
                                            xxl={6}
                                            key={group.id}
                                            style={{ marginBottom: '1rem' }}
                                        >
                                            <Card
                                                loading={false}
                                                actions={[
                                                    <Link
                                                        key="preview"
                                                        to={{
                                                            pathname: '/admin/integrations/integration',
                                                            state: {
                                                                group_id: group?.id,
                                                                name: group?.name,
                                                            },
                                                        }}
                                                    >
                                                        <EyeOutlined key="preview" />
                                                    </Link>,
                                                    <EditOutlined
                                                        key="edit"
                                                        onClick={() => openModal('Edit', group)}
                                                    />,
                                                    <DeleteOutlined
                                                        key="delete"
                                                        onClick={() => deleteIntegrationGroup(group.id)}
                                                    />,
                                                ]}
                                            >
                                                <Title level={5} style={{ textAlign: 'center' }}>
                                                    {group.name}
                                                </Title>
                                            </Card>
                                        </Col>
                                    ))}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Requirements</h3>
                                                <span>Add a new Requirement </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section>

            <IntegrationGroupModal open={ModalOpen} handleClose={closeModal} mode={mode} payload={payload} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_integrations_groups: state?.adminReducers?.all_integrations_groups,
    };
};
export default connect(mapStateToProps, {
    GetAllIntegrationsGroups,
    DeleteIntegrationGroup,
})(IntegrationDashboard);
