import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Divider, List, ListItem, Typography, Box, Stack, Grid } from '@mui/material';
import { FactCheck } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { FiHelpCircle } from 'react-icons/fi';

//core components
import AppViewModal from 'components/new_components/AppViewModal';
import AppPopover from 'components/new_components/AppPopover';
import AppProgressBar from 'components/new_components/AppProgressBar';
//redux
import { connect } from 'react-redux';
import { GetVendorResponses } from 'store/actions/vendorActions';

//translations
import { useTranslation } from 'react-i18next';

const ScoreCardModal = (props) => {
    const { GetVendorResponses, id, vendor_responses, open, handleClose } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('vendorManagement');

    const popoverContent = (
        <Box sx={{ color: theme.palette.primary.main }}>
            <Typography sx={{ mb: 2, fontSize: 18, fontWeight: 700 }}>Grade Scale</Typography>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} lg={6}>
                        <Typography>67-100 (LOW)</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} lg={6}>
                        <Typography>34-66% (MEDIUM)</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} lg={6}>
                        <Typography>0-33% (HIGH)</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
    //async actions
    const getVendorRepsonses = async () => {
        const res = await GetVendorResponses(id);
        if (!res.success) {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        if (id) {
            getVendorRepsonses();
        }
    }, [id]);

    return (
        <AppViewModal
            open={open}
            handleClose={handleClose}
            title={
                <Stack direction="row" justifyContent="center">
                    {t('scoreCardModal.title')}
                    <AppPopover text={popoverContent} name={<FiHelpCircle size="14px" />} />
                </Stack>
            }
            width={550}
            icon={<FactCheck />}
        >
            <Box sx={{ fontSize: '12px !important' }}>
                <List sx={{ px: 1.5, py: 0 }}>
                    {vendor_responses
                        ?.sort((a, b) => a?.id - b?.id)
                        ?.map((resp) => {
                            return (
                                <ViewModalItem
                                    name={resp?.form_title}
                                    value={resp?.score}
                                    key={resp?.id}
                                    risk_level={resp?.risk_level}
                                    score_percent={resp?.score_percent}
                                />
                            );
                        })}
                </List>
            </Box>
        </AppViewModal>
    );
};
const ViewModalItem = (props) => {
    const theme = useTheme();
    const { name, risk_level, score_percent } = props;

    return (
        <>
            <ListItem
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    py: 2,
                }}
            >
                <Typography
                    sx={{
                        fontWeight: '400',
                        color: theme.palette.gray[900],
                    }}
                >
                    {name}:
                </Typography>
                <Typography
                    sx={{
                        fontWeight: '500',
                        color: theme.palette.gray[900],
                        pl: 5,
                        whiteSpace: 'pre',
                        textTransform: 'uppercase',
                    }}
                >
                    {score_percent}% ({risk_level})
                </Typography>
            </ListItem>
            <Box sx={{ mx: 2, mb: 2 }}>
                <AppProgressBar
                    value={score_percent}
                    color={risk_level === 'medium' ? 'warning' : risk_level === 'low' ? 'success' : 'error'}
                />
            </Box>
            <Divider sx={{ color: theme.palette.gray[300] }} />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
        vendor_responses: state?.vendorReducers?.vendor_responses,
    };
};
export default connect(mapStateToProps, { GetVendorResponses })(ScoreCardModal);
