import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// @antd
import { Card, Col, Divider, Modal, Row, Spin, Tooltip, Typography } from 'antd';
import {
    DownloadOutlined,
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    FileTextOutlined,
    EyeOutlined,
} from '@ant-design/icons';
import { BiUnlink } from 'react-icons/bi';

import AddDocumentModal from '../../Operations/AddDocumentModal';
import EditDocumentModal from '../../Operations/EditDocumentModal';

import { connect, useDispatch } from 'react-redux';
import { GetAllDocumentsBySubsector, DeleteDocument, EditDocument } from 'store/actions/adminActions';
import EmptyComponent from 'components/EmptyComponents/EmptyComponent';
import { useHistory } from 'react-router-dom';
import { GetDocumentLink } from 'store/actions/complianceActions';

// sub components
const { Title } = Typography;
const { confirm } = Modal;

const Documents = (props) => {
    // props
    const {
        GetAllDocumentsBySubsector,
        subsector_documents,
        DeleteDocument,
        EditDocument,
        subsector,
        openModal,
        closeModal,
        modal,
        data,
    } = props;

    // state
    const [loading, setLoading] = useState({ content: false });
    const [documents, setDocuments] = useState([]);
    const history = useHistory();

    const dispatch = useDispatch();

    const getDocumentLink = async (id) => {
        const res = await dispatch(GetDocumentLink('document', id));
        if (res?.success) {
            window.open(res?.data, '_blank');
        } else {
            toast.error('Something went wrong!');
        }
    };
    //Actions Array
    const documentActions = (document) => {
        let actions = [
            <Tooltip title="Download Document" color="blue">
                <div onClick={() => getDocumentLink(document?.id)}>
                    <DownloadOutlined />
                </div>
            </Tooltip>,
            <EditOutlined key="edit" title="Edit this Document" onClick={() => openModal('edit', document)} />,
            <DeleteOutlined key="delete" title="Delete this document" onClick={() => deleteModal(document)} />,
            document?.type === 'codereview' ? <EyeOutlined onClick={handleView} key="open" /> : null,
        ];
        if (document?.tags?.split(',').length > 1) {
            actions.push(<BiUnlink key="unlink" title="Unlink this document" onClick={() => unlinkModal(document)} />);
        }
        return actions;
    };

    const handleView = () => {
        history?.push(`/admin/cii-directive/subsector/${subsector?.id}/codereview`);
    };
    const deleteModal = (document) => {
        confirm({
            title: 'Do you want to delete this document?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await DeleteDocument(document?.id, document?.sector, 'cii');
                if (res.success) {
                    toast.success('Document Deleted Successfully');
                } else {
                    toast.error(res.message);
                }
            },
        });
    };
    const unlinkModal = (document) => {
        confirm({
            title: 'Do you want to unlink this document?',
            icon: <ExclamationCircleOutlined />,
            content: 'Unlinking this document will detach this document from this compliance standard',
            okText: 'Yes',
            cancelText: 'No',
            async onOk() {
                const newTags = document.tags
                    .split(',')
                    .filter((tag) => tag !== 'blockchain')
                    .join();
                const formData = new FormData();
                formData.append('tags', newTags);
                const res = await EditDocument(formData, document?.id, 'blockchain', document?.criteria);
                if (res.success) {
                    toast.success('Document unlinked successfully');
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    // async functions
    const getDocumentBySubsector = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAllDocumentsBySubsector(subsector?.id);
        setLoading({ ...loading, content: false });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        getDocumentBySubsector();
    }, []);
    useEffect(() => {
        setDocuments(
            subsector_documents?.filter(
                (document) => document?.cii_subsector === subsector?.id && document.type !== 'conditional'
            )
        );
    }, [subsector_documents]);
    return (
        <div>
            <Divider orientation="left">
                <span className="flex items-center space-x-8">
                    <FileTextOutlined className="mr-1" />
                    Document
                </span>
            </Divider>
            {loading.content ? (
                <Row justify="center">
                    <Spin size="large" />
                </Row>
            ) : (
                <Row gutter={16}>
                    {documents?.length ? (
                        documents
                            ?.sort((a, b) => a?.id - b?.id)
                            ?.map((document) => {
                                return (
                                    <Col xs={24} sm={12} md={8} lg={6} className="mb-4" key={document?.id}>
                                        <Card actions={documentActions(document)}>
                                            <Title level={5} className="text-center">
                                                {document?.cii_sector_title}
                                            </Title>
                                        </Card>
                                    </Col>
                                );
                            })
                    ) : (
                        <div className="w-full">
                            <EmptyComponent text="No Documents" />
                        </div>
                    )}
                </Row>
            )}
            <AddDocumentModal
                open={modal === 'add'}
                handleClose={closeModal}
                tag="cii"
                one_document={data}
                payload={{
                    cii_sector: subsector?.cii_sector,
                    cii_subsector: subsector?.id,
                }}
                titleLabel="cii_sector_title"
                descLabel="cii_sector_description"
                groupIdLabel="cii_subsector"
            />
            <EditDocumentModal
                open={modal === 'edit'}
                handleClose={closeModal}
                one_document={data}
                tag="blockchain"
                payload={{
                    sector: subsector?.sector,
                    subsector: subsector?.id,
                }}
                titleLabel="cii_sector_title"
                descLabel="sector_description"
                groupIdLabel="cii_subsector"
            />
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_criteria: state?.adminReducers?.all_criteria,
    subsector_documents: state?.adminReducers?.subsector_documents,
});
export default connect(mapStateToProps, { GetAllDocumentsBySubsector, DeleteDocument, EditDocument })(Documents);
