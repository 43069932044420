import React from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const AppListItems = ({ items }) => {
    const theme = useTheme();
    return (
        <>
            <List sx={{ color: theme.palette.gray[900] }}>
                {items.map((item) => {
                    return (
                        <ListItem key={item.id} disablePadding>
                            <ListItemButton onClick={item.onClick}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        sx: {
                                            fontWeight: 600,
                                        },
                                    }}
                                    primary={item.name}
                                />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </>
    );
};

export default AppListItems;
