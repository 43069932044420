import React from 'react';
import { Switch, Route } from 'react-router-dom';
import FormView from './FormView';
import FormCreation from './FormCreation';

const QuestionaireDashboard = () => {
    return (
        <Switch>
            <Route path="/admin/questionaire" exact component={FormView} />
            <Route path="/admin/questionaire/form/:id" component={FormCreation} />
        </Switch>
    );
};

export default QuestionaireDashboard;
