import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CategoriesCards from './CategoriesCards';
import CategoryQuestions from './single/CategoryQuestions';
import CategorySettings from './multiple/CategorySettings';
import CategoryCards from './multiple/CategoryCards';
import SingleDuplicate from './single-duplicate/index';
import SingleDuplicateQuestions from './single-duplicate/CategorySettings';
import DuplicateQuestions from './duplicate/Categories';

const Categories = () => {
    return (
        <Switch>
            <Route path="/admin/accessor-categories" exact component={CategoriesCards} />
            <Route path="/admin/accessor-categories/single" component={CategoryQuestions} />
            <Route path="/admin/accessor-categories/multiple" exact component={CategoryCards} />
            <Route path="/admin/accessor-categories/multiple/:id" component={CategorySettings} />
            <Route path="/admin/accessor-categories/single-duplicate" exact component={SingleDuplicate} />
            <Route path="/admin/accessor-categories/single-duplicate/:id" component={SingleDuplicateQuestions} />
            <Route path="/admin/accessor-categories/duplicate" exact component={DuplicateQuestions} />
        </Switch>
    );
};

export default Categories;
