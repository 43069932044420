import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import { ArrowBackIosNew } from '@mui/icons-material';
// import { AiFillAppstore } from 'react-icons/ai';
import RequestToolModal from './RequestToolModal';
// import { TbAppsFilled } from 'react-icons/tb';

//translations
import { useTranslation } from 'react-i18next';

const IntegrationsHeading = (props) => {
    const theme = useTheme();
    const { selectedIntegrationId, goBack } = props;

    // state
    const [modal, setModal] = useState(false);

    //translation
    const { t } = useTranslation('setups');

    // functions
    // const openModal = () => setModal(true);
    const closeModal = () => setModal(false);

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                sx={{
                    py: 1,
                    px: 2.5,
                    pb: 2,
                    borderBottom: `1px solid ${theme.palette.gray[200]}`,
                }}
            >
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                    {selectedIntegrationId && (
                        <ArrowBackIosNew
                            sx={{
                                width: 42,
                                height: 42,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: theme.palette.gray[50],
                                color: theme.palette.gray[500],
                                p: 1,
                                mr: 2,
                                borderRadius: '50%',
                                cursor: 'pointer',
                            }}
                            onClick={goBack}
                        />
                    )}
                    <Box component="header">
                        <Typography
                            sx={{
                                color: theme.palette.primary[900],
                                fontWeight: 700,
                                fontSize: 20,
                            }}
                        >
                            {t('integrationsView.connectTools')}
                        </Typography>
                        <Typography
                            sx={{
                                color: theme.palette.primary[700],
                                fontWeight: 500,
                                fontSize: 16,
                            }}
                        >
                            {t('integrationsView.integrations')}
                        </Typography>
                    </Box>
                </Stack>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        fontWeight: 600,
                        fontSize: 16,
                        gap: 1,
                        color: theme.palette.primary[900],
                    }}
                >
                    {/* <AiFillAppstore /> */}
                    {/* <Typography component="span" sx={{ fontWeight: 'inherit', fontSize: 'inherit' }}>
                        {t('integrationsView.findTools')}
                    </Typography>
                    <Box component="button" sx={{ textDecorationLine: 'underline' }} onClick={openModal}>
                        {t('integrationsView.request')}
                    </Box> */}
                </Box>
            </Stack>
            <RequestToolModal open={modal} handleClose={closeModal} />
        </>
    );
};

export default IntegrationsHeading;
