import React from 'react';

import { useTheme } from '@mui/material/styles';
import { Drawer } from '@mui/material';

// core components
import SidebarContent from './SidebarContent';

const SidebarDrawer = (props) => {
    const theme = useTheme();
    const { largeScreen, open, onClose, routes, merchant_info, title, ref2 } = props;
    return (
        <Drawer
            variant={largeScreen ? 'permanent' : 'temporary'}
            open={open}
            onClose={onClose}
            ModalProps={{ keepMounted: true }}
            sx={{
                display: largeScreen ? { xs: 'none', md: 'block' } : { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: '275px',
                    border: 'none',
                    // height: '100%',
                    backgroundColor: theme.palette.primary[900],
                },
            }}
        >
            <SidebarContent routes={routes} merchant_info={merchant_info} title={title} ref2={ref2} />
        </Drawer>
    );
};

export default SidebarDrawer;
