import React from 'react';
//core component
import { Typography, Stack } from '@mui/material';
import AppCustomSwitch from 'components/new_components/AppCustomSwitch';
import { useTranslation } from 'react-i18next';

const AuthControlSwitch = (props) => {
    const { checked, onChange, mfa_type } = props;
    const { t } = useTranslation('accounts');
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ background: '#F2F0F4', py: 3, px: 2, borderRadius: '8px' }}
        >
            <Typography sx={{ pr: 4, fontSize: 15, fontWeight: 400 }}>{t('twoFactorAuth.switchText')}</Typography>
            {mfa_type && <AppCustomSwitch checked={checked} onChange={onChange} />}
        </Stack>
    );
};

export default AuthControlSwitch;
