import React from 'react';
import { Menu } from '@mui/material';

const AppTableActionMenu = ({ anchor, children, closeMenu, sx, ...props }) => {
    return (
        <Menu
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={closeMenu}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '4px',
                    boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                    ...sx,
                },
            }}
            {...props}
        >
            {children}
        </Menu>
    );
};

export default AppTableActionMenu;
