import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// lib components
import { Link } from 'react-router-dom';
import { Button, Row, Col, Typography, Card, Modal, Spin, Empty, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

// core
import BreadCrumb from 'components/Breadcrumb';
import CommonControlModal from './Soc2Actions/CommonControlModal';

// redux
import { connect } from 'react-redux';
import { GetAllControls, CreateControl, EditControl, DeleteControl } from 'store/actions/adminActions';

// sub-components
const { Title } = Typography;
const { confirm } = Modal;

const Soc2Dashboard = (props) => {
    const { GetAllControls, all_controls, CreateControl, EditControl, DeleteControl } = props;
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState({
        content: false,
    });
    const [one_control, setOneControl] = useState({});

    // functions
    const openModal = (mode, data) => {
        setModal(mode);
        setOneControl(data);
    };
    const closeModal = () => {
        setModal(null);
    };
    const deleteCommonControl = (id) => {
        confirm({
            title: 'Are you sure you want to delete this control? Note: Deleting this control means you will be deleting this control, the documents, audit questions and merchant data associated with this control',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteControl(id);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };
    const getAllControls = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAllControls();
        setLoading({ ...loading, content: false });
        if (!res?.message) {
            toast.error(res?.message);
        }
    };
    // useeffect
    useEffect(() => {
        getAllControls();
    }, []);
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'SOC2' }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal('Add')}
                    >
                        Add Common Control
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {loading.content ? (
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                ) : (
                    <>
                        {all_controls && all_controls?.length > 0 ? (
                            <Row wrap gutter={24}>
                                {all_controls?.map((control, index) => (
                                    <Col
                                        xs={24}
                                        md={12}
                                        lg={8}
                                        xxl={6}
                                        key={control.id}
                                        style={{ marginBottom: '1rem' }}
                                    >
                                        <Card
                                            title={`#${index + 1}`}
                                            loading={false}
                                            actions={[
                                                <Tooltip title="View this control" color="blue">
                                                    <Link
                                                        key="preview"
                                                        to={{
                                                            pathname: '/admin/soc2/subcontrols',
                                                            state: {
                                                                control_id: control?.id,
                                                            },
                                                        }}
                                                    >
                                                        <EyeOutlined key="preview" />
                                                    </Link>
                                                </Tooltip>,
                                                <Tooltip title="Edit this control" color="blue">
                                                    <EditOutlined
                                                        key="edit"
                                                        onClick={() => openModal('Edit', control)}
                                                    />
                                                </Tooltip>,
                                                <Tooltip title="Delete this control" color="blue">
                                                    <DeleteOutlined
                                                        key="delete"
                                                        onClick={() => deleteCommonControl(control.id)}
                                                    />
                                                </Tooltip>,
                                            ]}
                                        >
                                            <Title level={5} style={{ textAlign: 'center' }}>
                                                [{control.service_category}] {control.title}
                                            </Title>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Common controls</h3>
                                                <span>Add a new common control </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section>
            <CommonControlModal
                open={modal}
                handleCancel={closeModal}
                one_control={one_control}
                CreateControl={CreateControl}
                EditControl={EditControl}
            />
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        all_controls: state?.adminReducers?.all_controls,
    };
};
export default connect(mapStateToProps, {
    GetAllControls,
    CreateControl,
    EditControl,
    DeleteControl,
})(Soc2Dashboard);
