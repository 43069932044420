import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import onboardingLines from 'assets/img/onboardingLines.svg';
import AppButton from 'components/new_components/AppButton';

import WelcomePicture from 'assets/img/WelcomePicture.svg';

//translation
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const WelcomeEmployee = (props) => {
    const { match, one_merchant_person, increaseOnboardingPolicy } = props;

    const theme = useTheme();

    const merchantInfo = useSelector((state) => state?.merchantReducers?.merchant_info);

    console.log(merchantInfo);

    //translatio hook
    const { t } = useTranslation('onboardingPolicy');
    return (
        <div>
            <Typography
                sx={{
                    fontWeight: 700,
                    fontSize: '16px',
                    color: theme.palette.primary.main,
                }}
            >
                {t('employeeView.welcomeEmployee.welcome')}{' '}
                {match?.params?.id?.toString()?.length >= 4
                    ? `${one_merchant_person?.[0]?.first_name} ${one_merchant_person?.[0]?.last_name}`
                    : merchantInfo?.name}
                !
            </Typography>
            <Grid container spacing={0} sx={{ display: 'flex', height: 550 }}>
                <Grid item xs={12} lg={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                            marginTop: 15,
                        }}
                    >
                        <Stack>
                            <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#77777a' }}>
                                {t('employeeView.welcomeEmployee.paragraph1')}
                            </Typography>
                            <Typography sx={{ fontSize: '48px', fontWeight: 700, color: '#5E5E62' }}>
                                {t('employeeView.welcomeEmployee.paragraph2')}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '48px',
                                    fontWeight: 700,
                                    color: theme.palette.primary.main,
                                }}
                            >
                                {t('employeeView.welcomeEmployee.paragraph3')}
                            </Typography>
                            <Box
                                sx={{
                                    marginTop: '-40px',
                                    marginRight: '20px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src={onboardingLines} alt="Lines" />
                            </Box>
                            <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#77777a' }}>
                                {t('employeeView.welcomeEmployee.paragraph4')}
                            </Typography>
                            <Typography
                                sx={{ my: '30px' }}
                                dangerouslySetInnerHTML={{ __html: t('employeeView.welcomeEmployee.paragraph5') }}
                            ></Typography>
                            <AppButton
                                name={t('employeeView.welcomeEmployee.cta')}
                                color="primary"
                                variant="contained"
                                onClick={increaseOnboardingPolicy}
                                sx={{
                                    width: '250px',
                                    height: '55px',
                                }}
                            />
                        </Stack>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={0}
                    lg={6}
                    sx={{
                        display: { xs: 'none', lg: 'flex' },
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box alignItems="center">
                        <img src={WelcomePicture} alt="Welcome to Smart Comply App" />
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default WelcomeEmployee;
