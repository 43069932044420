import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Row, Col, Input, Button, Modal } from 'antd';

//redux
import { connect } from 'react-redux';
import { AddIntegrationGroup, EditIntegrationGroup } from 'store/actions/adminActions';

const IntegrationGroupModal = (props) => {
    const { open, handleClose, mode, payload, AddIntegrationGroup, EditIntegrationGroup } = props;
    const [values, setValues] = useState({});
    const [loadingState, setLoadingState] = useState(false);

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const closeAll = () => {
        handleClose();
        setValues({});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(values);
        setLoadingState(true);
        const res =
            mode === 'Add' ? await AddIntegrationGroup(values) : await EditIntegrationGroup(payload?.id, values);
        setLoadingState(false);
        if (res?.success) {
            toast.success(
                mode == 'Add' ? 'Integration Group Added Successfully' : 'Integration Group Updated Successfully'
            );
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (payload) {
            setValues({ name: payload?.name });
        }
    }, [payload]);
    return (
        <>
            <Modal
                title={mode === 'Add' ? 'Add Group' : 'Edit Group'}
                visible={open}
                onCancel={closeAll}
                footer={null}
                destroyOnClose={true}
            >
                <form onSubmit={handleSubmit}>
                    <Row justify="end">
                        <Col span={24}>
                            <div className="form-group" style={{ marginBottom: '1rem' }}>
                                <label htmlFor="name">Name</label>
                                <Input
                                    type="text"
                                    size="large"
                                    name="name"
                                    id="name"
                                    onChange={handleTextChange}
                                    value={values.name || ''}
                                    required
                                />
                            </div>
                        </Col>
                        <Col>
                            <Button
                                className="ant-btn"
                                htmlType="submit"
                                type="primary"
                                loading={loadingState}
                                style={{ marginTop: '3rem' }}
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </form>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, {
    AddIntegrationGroup,
    EditIntegrationGroup,
})(IntegrationGroupModal);
