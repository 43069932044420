import React, { useEffect, useState } from 'react';
import { Box, Button, Menu, MenuItem, Tooltip, Typography, useTheme } from '@mui/material';
import VectorDown from 'assets/img/vector-down-2.svg';

//translations
import CloseIcon from '@mui/icons-material/Close';
import AppCenteredModalNew from 'components/new_components/AppCenteredModalNew';
import { AppForm, AppFormContext, AppFormInput } from 'components/new_components/forms';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SearchIcon from '../../../../../assets/img/Searchicon.svg';
import { AddAssetGroup } from 'store/actions/merchantActions';
import { UpdateAssetGroup } from 'store/actions/merchantActions';
import { AppSubmitButton } from 'components/new_components/forms';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { assetGroupValidation } from '../utils/validation';
import { GetAllMerchantUsers } from 'store/actions/generalActions';
import { HiOutlineTrash } from 'react-icons/hi';

const NewGroupModal = (props) => {
    const {
        open,
        handleClose,
        isAddModal,
        id,
        payload,
        owner,
        setOwner,
        custodian,
        setCustodian,
        openDeleteGroupModal,
    } = props;
    const [values, setValues] = useState(payload);
    const [loadingState, setLoadingState] = useState(false);
    const [ownerAnchorEl, setOwnerAnchorEl] = useState(null);
    const [error, setError] = useState(false);
    const [search, setSearch] = useState('');
    const theme = useTheme();

    // redux
    const dispatch = useDispatch();
    const all_merchant_users = useSelector((state) => state?.generalReducers?.all_merchant_users);

    const ownerOpenMenu = (e) => setOwnerAnchorEl(e.target);
    const ownerCloseMenu = () => setOwnerAnchorEl(null);

    const handleOwnerChange = (value) => {
        setOwner(value);
    };

    //async action
    const getAllEmployees = async () => {
        const res = await dispatch(GetAllMerchantUsers());
        if (!res?.status) {
            toast.error(res?.status);
        }
    };

    useEffect(() => {
        if (open) {
            getAllEmployees();
        }
    }, [open]);

    const handleSubmit = async (data) => {
        if (owner?.name === '' && custodian?.name === '') {
            setError(true);
        } else {
            const body = {
                ...data,
                owner_email: owner?.value,
            };
            setLoadingState(true);
            const res = isAddModal ? await dispatch(AddAssetGroup(body)) : await dispatch(UpdateAssetGroup(body, id));
            if (res?.success) {
                setLoadingState(false);
                toast.success(isAddModal ? 'Group Added Successfully' : 'Group Credentials Updated Successfully');
                setValues({
                    name: '',
                });
                setOwner({
                    name: '',
                    value: '',
                });
                handleClose();
            } else {
                setLoadingState(false);
                toast.error(res?.message);
            }
        }
    };
    const getCurrentValues = (values) => setValues(values);
    const all_menployees = all_merchant_users?.users.concat(all_merchant_users?.people);

    const mapped_employees = all_menployees?.map((employee) => {
        return {
            name: employee?.email,
            value: employee?.email,
        };
    });
    const filtered_employees = mapped_employees?.filter((option) =>
        option?.name?.toLowerCase().includes(search.toLowerCase())
    );

    const onClose = () => {
        setValues({ name: '' });
        handleClose();
    };

    useEffect(() => {
        if (open && !isAddModal) {
            setValues({
                name: payload?.name,
            });
        }
    }, [open, isAddModal]);

    return (
        <AppForm initialValues={values} onSubmit={handleSubmit} validate={assetGroupValidation}>
            <AppFormContext getValues={getCurrentValues}>
                <AppCenteredModalNew
                    open={open}
                    handleClose={onClose}
                    title={
                        <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                            {isAddModal ? 'New Group' : 'Update Group'}
                        </Typography>
                    }
                    noShadow
                    noClose
                    headerAction={
                        <Box
                            sx={{
                                border: '1px solid #E2E8F0',
                                borderRadius: 30,
                                width: 30,
                                height: 30,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={onClose}
                        >
                            <CloseIcon sx={{ width: 20, height: 20 }} />
                        </Box>
                    }
                    actions={
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'right',
                                alignItems: 'center',
                                mt: 3,
                                backgroundColor: '#F8FAFC',
                                height: '50px',
                                mx: '-2rem',
                                mb: '-2rem',
                                px: '2rem',
                                pt: '1rem',
                                pb: '4.5rem',
                            }}
                        >
                            <Button
                                variant="outlined"
                                sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit', color: '#334155' }}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <AppSubmitButton
                                text={isAddModal ? 'Create' : 'Save'}
                                variant="contained"
                                color="primary"
                                loading={loadingState}
                                loadingPosition="center"
                                sx={{ borderRadius: 1 }}
                            />
                        </Box>
                    }
                    width="460px"
                    height="400px"
                >
                    <Box sx={{ my: 1 }}>
                        <Box>
                            <AppFormInput
                                label="Group Name"
                                type="text"
                                name="name"
                                placeholder="Enter group name (eg All laptops)"
                                disabled={values?.name?.length === 40}
                            />
                            <p className="float-right text-[#64748B] font-normal text-xs mt-1">
                                {values?.name?.length} of 40 characters left
                            </p>
                        </Box>
                        <div className="mt-4">
                            <div className="flex items-center gap-2">
                                <Typography
                                    sx={{
                                        color: theme.palette.neutral[500],
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        mb: 0.5,
                                    }}
                                >
                                    Asset Owner
                                </Typography>
                                <Tooltip
                                    title={
                                        'The person in charge of the grouped asset or legal control and rights over the grouped asset.'
                                    }
                                    placement="bottom-start"
                                    arrow
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: '#000000',
                                                color: 'white',
                                            },
                                            '& .MuiTooltip-arrow': {
                                                background: '#000000',
                                            },
                                        },
                                    }}
                                    sx={{
                                        width: '100px',
                                        '& .MuiTooltip-arrow': {
                                            background: '#000000',
                                        },
                                    }}
                                >
                                    <HelpOutlineIcon sx={{ width: 15, mt: -0.5, cursor: 'pointer' }} />
                                </Tooltip>
                            </div>
                            <div
                                className="border border-[#E2E8F0] rounded flex justify-between items-center py-2.5 px-2 cursor-pointer"
                                onClick={ownerOpenMenu}
                            >
                                <Typography sx={{ color: theme.palette.gray[400], fontWeight: 400, fontSize: '14px' }}>
                                    {owner?.value !== '' ? owner?.name : 'Select Owner'}
                                </Typography>
                                <img
                                    src={VectorDown}
                                    alt="VectorDown"
                                    className="text-neutral-40 w-[12px] h-[12px] font-normal"
                                />
                            </div>
                            {error && owner?.name === '' && (
                                <span className="text-xs text-[#FF0000] font-normal">Group is required</span>
                            )}
                        </div>{' '}
                        <Menu
                            open={Boolean(ownerAnchorEl)}
                            anchorEl={ownerAnchorEl}
                            onClose={ownerCloseMenu}
                            PaperProps={{
                                style: {
                                    width: 400,
                                },
                            }}
                        >
                            <div className="px-3 py-1">
                                <p className="text-[#64748B] font-medium text-[13px] mt-2">Search name</p>
                            </div>
                            <div className="relative px-3">
                                <input
                                    placeholder="Search..."
                                    value={search}
                                    onChange={(event) => setSearch(event?.target?.value)}
                                    className="text-gray-400 text-sm font-normal placeholder:text-gray-400 border border-gray-200 py-2 rounded-[4px] w-[230px] px-[30px] w-full"
                                />
                                <img src={SearchIcon} alt="SearchIcon" className="absolute left-6 top-2.5 w-[17px]" />
                            </div>
                            {filtered_employees?.length ? (
                                <div className="mt-3">
                                    {filtered_employees?.map((option) => (
                                        <MenuItem
                                            sx={{
                                                color: owner?.value === option?.value ? '#FFFFFF' : '#64748B',
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                mx: 2,
                                                backgroundColor: owner?.value === option?.value ? '#395BA9' : '',
                                                '&:hover': {
                                                    color: '#64748B',
                                                },
                                            }}
                                            onClick={() => {
                                                handleOwnerChange(option);
                                                setCustodian({
                                                    name: '',
                                                    value: '',
                                                });
                                                ownerCloseMenu();
                                            }}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </div>
                            ) : (
                                <p className="flex justify-center items-center text-[#64748B] font-medium text-[13px] mt-2">
                                    No Owner Found
                                </p>
                            )}
                        </Menu>{' '}
                        {!isAddModal && (
                            <div
                                className="flex items-center gap-1.5 cursor-pointer mt-2"
                                onClick={() => openDeleteGroupModal(id)}
                            >
                                <HiOutlineTrash color="#FF5449" />
                                <p className="text-sm text-[#FF5449] font-medium">Delete Group</p>
                            </div>
                        )}
                    </Box>
                </AppCenteredModalNew>
            </AppFormContext>
        </AppForm>
    );
};

export default NewGroupModal;
