import { REGISTERED_CLIENTS, CLIENT_ID } from '../constants';
import axiosInstance from '../../utils/https';

// new endpoints
export const GetRegisteredClients = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/merchants/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: REGISTERED_CLIENTS, payload: res?.data });
        return {
            status: true,
            message: res?.message,
        };
    } catch (err) {
        return {
            success: false,
            message: err?.message,
        };
    }
};

export const CreateClient = (credentials) => async (dispatch, getState) => {
    const implementer_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/user_mgt/register_client/`, {
            ...credentials,
            implementer: implementer_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetRegisteredClients());
        return {
            status: true,
            message: res?.message,
        };
    } catch (err) {
        return {
            success: false,
            message: err?.message,
        };
    }
};

export const UpdateClient = (credentials, id) => async (dispatch, getState) => {
    const implementer_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().patch(`/user_mgt/merchants/${id}/`, {
            ...credentials,
            implementer: implementer_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetRegisteredClients());
        return {
            status: true,
            message: res?.message,
        };
    } catch (err) {
        return {
            success: false,
            message: err?.message,
        };
    }
};

export const ClientId = (id) => (dispatch) => {
    dispatch({ type: CLIENT_ID, payload: id });
};
