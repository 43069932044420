import { Divider } from '@mui/material';
import PolygonMedium from 'assets/img/medium_polygon.png';
import PolygonLow from 'assets/img/ciso_positive_angle.png';
import PolygonHigh from 'assets/img/LowPolygon.svg';
import React from 'react';
import { useSelector } from 'react-redux';

const AutomatedScans = () => {
    const { all_automated_scan_summary } = useSelector((state) => state?.CISOReducers);
    return (
        <div className="mt-[5rem]">
            <h4 className="text-[#2B3674] text-[22px] font-bold text-center">Details of the report</h4>
            <div className="flex items-center gap-4 mt-[5rem]">
                <h4 className="text-[#2B3674] text-lg font-bold text-center">Automated Scans</h4>
                <div className="flex items-center gap-1 border border-[#E2E8F0] p-1 rounded">
                    <img
                        src={
                            all_automated_scan_summary?.percent_passed < 50
                                ? PolygonHigh
                                : all_automated_scan_summary?.percent_passed >= 50 &&
                                  all_automated_scan_summary?.percent_passed <= 70
                                ? PolygonMedium
                                : PolygonLow
                        }
                        alt="PolygonMedium"
                    />
                    <p className="text-gray-500 text-sm font-semibold">
                        {all_automated_scan_summary?.percent_passed?.toFixed(2)}%
                    </p>
                </div>
                <div className="w-[74%]">
                    <Divider orientation="horizontal" sx={{ backgroundColor: '#E2E8F0' }} />
                </div>
            </div>
            <div className="mt-[3rem]">
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">Automated Scans</p>
                </div>
                <table className="w-full w-full">
                    <thead className="w-full">
                        <tr>
                            <th
                                rowspan="2"
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black"
                            >
                                Constraints{' '}
                            </th>
                            <th
                                rowspan="2"
                                className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black"
                            >
                                Instances
                            </th>
                            <th
                                colspan="3"
                                className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black"
                            >
                                Status
                            </th>
                        </tr>
                        <tr>
                            <td className="text-[#64748B] text-xs  font-semibold p-2 text-center border-2 border-black">
                                Pass
                            </td>
                            <td className="text-[#64748B] text-xs  font-semibold p-2 text-center border-2 border-black">
                                Fail
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {all_automated_scan_summary &&
                            Object?.keys(all_automated_scan_summary)?.length &&
                            Object?.keys(all_automated_scan_summary)?.map((option, optionIndex) => {
                                return (
                                    all_automated_scan_summary[option]?.length > 0 && (
                                        <React.Fragment key={optionIndex}>
                                            {all_automated_scan_summary[option]?.map((sub, subIndex) => (
                                                <React.Fragment key={subIndex}>
                                                    <tr>
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                                rowSpan={all_automated_scan_summary[option]?.length * 2}
                                                            >
                                                                {option}
                                                            </td>
                                                        )}
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border-2 border-black capitalize"
                                                            rowSpan="2"
                                                        >
                                                            {sub?.name?.replace(/_/g, ' ')}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="text-[#55BE8B] text-xs  font-medium p-2 text-center border-2 border-black"
                                                            rowSpan="2"
                                                        >
                                                            {sub?.passed} Passed
                                                        </td>
                                                        <td className="text-[#FF5449] text-xs  font-medium p-2 text-center border-2 border-black">
                                                            {sub.failed} Failed
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    )
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AutomatedScans;
