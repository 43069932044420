import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { StatusOption } from '../../utils/constants';
//redux
import { connect } from 'react-redux';
import { UpdateMerchantRiskAssessments } from 'store/actions/merchantActions';
import { toast } from 'react-toastify';

//translations
import { useTranslation } from 'react-i18next';
import { getType } from 'utils';

const TableAction = (props) => {
    const theme = useTheme();
    const { row, openDeleteModal, UpdateMerchantRiskAssessments, openViewModal, page, handleType } = props;
    const role = getType('role');

    // state
    const [anchor, setAnchor] = useState(null);
    const [anchor_status, setAnchor_status] = useState(null);

    //translation
    const { t } = useTranslation('riskAssessment');

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);
    const openStatus = (e) => setAnchor_status(e.target);
    const closeStatus = () => setAnchor_status(null);
    const editPayload = {
        asset_type: row?.asset_type,
        asset: row?.asset,
        asset_: row?.asset,
        matrix: row?.matrix,
        threat: row?.threat_detail?.id?.toString(),
        threat_description: row?.threat_description,
        risk_owner: row?.risk_owner,
        vulnerabilities: row?.vulnerabilities?.split(','),
        pre_control: row?.pre_control,
        inherent_likelihood: row?.inherent_likelihood?.toString(),
        inherent_impact: row?.inherent_impact?.toString(),
        treatment_option: row?.treatment_option,
        existing_controls: row?.existing_controls,
        control_requirement: row?.control_requirement,
        control_comments: row?.control_comments,
        control_requirements: row?.control_requirement,
        recommended_controls: row?.recommended_controls,
        responsibility: row?.responsibility,
        link_document: row?.document_detail?.document_type?.iso_title || '',
        residual_likelihood: row?.residual_likelihood?.toString(),
        residual_impact: row?.residual_impact?.toString(),
        risk_status: row?.risk_status,
        comments: row?.comments,
        existing_document: row?.existing_document,
        timeline: row?.timeline,
        id: row?.id,
    };

    const handleViewModal = () => {
        openViewModal(row);
        closeMenu();
    };

    const handleOpenDeleteModal = () => {
        closeMenu();
        openDeleteModal(row?.id);
    };

    const handleStatusUpdate = async (value) => {
        const formdata = new FormData();
        formdata.append('risk_status', value);
        const res = await UpdateMerchantRiskAssessments(formdata, row?.id, page);
        closeStatus();
        closeMenu();
        if (res?.success) {
            toast.success('Risk Status Updated Successfully');
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <>
            <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                <MenuItem
                    onClick={handleViewModal}
                    sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: theme.palette.gray[20],
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    {t('riskRegisterTab.viewRisk')}
                </MenuItem>
                {role !== 'auditor' && (
                    <>
                        <MenuItem
                            sx={{
                                fontSize: 14,
                                fontWeight: 500,
                                color: theme.palette.gray[20],
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                            onClick={() => handleType('self', editPayload)}
                        >
                            {t('riskRegisterTab.updateRisk')}
                        </MenuItem>
                        <MenuItem
                            sx={{
                                fontSize: 14,
                                fontWeight: 500,
                                color: theme.palette.gray[20],
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                            onClick={openStatus}
                        >
                            {t('riskRegisterTab.updateStatus')}
                        </MenuItem>
                        <MenuItem
                            onClick={handleOpenDeleteModal}
                            sx={{
                                fontSize: 14,
                                fontWeight: 500,
                                color: '#46464A',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            {t('riskRegisterTab.deleteAssessment')}
                        </MenuItem>
                    </>
                )}
            </Menu>
            <Menu
                open={Boolean(anchor_status)}
                anchorEl={anchor_status}
                onClose={closeStatus}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    left: '-8rem',
                    padding: '1rem 8rem 1rem 2rem',
                }}
                PaperProps={{
                    style: {
                        width: 140,
                    },
                }}
            >
                <MenuItem
                    sx={{
                        fontSize: 12,
                        fontWeight: 500,
                        color: '#46464A',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '0.3rem',
                    }}
                >
                    {t('riskRegisterTab.setStatus')}
                </MenuItem>
                {StatusOption?.map((status) => (
                    <MenuItem
                        sx={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: '#46464A',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '0.1rem',
                        }}
                        onClick={() => handleStatusUpdate(status.value)}
                        key={status?.value}
                    >
                        {status?.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

const mapStateToProps = () => {
    return {};
};
export default connect(mapStateToProps, {
    UpdateMerchantRiskAssessments,
})(TableAction);
