import {
    COMPLIANCE_SUMMARY,
    PCIDSS4POINT0_COMPLIANCE_SUMMARY,
    GET_DASHBOARD_RESULT,
    ISO_GENERAL_SUMMARY,
    ISO_SUMMARY_BY_ANNEX,
    ISO_TWO_GENERAL_SUMMARY,
    ISO_TWO_SUMMARY_BY_ANNEX,
    NDPR_GENERAL_SUMMARY,
    GDPA_GENERAL_SUMMARY,
    KDPA_GENERAL_SUMMARY,
    CONTINUITY_GENERAL_SUMMARY,
    NDPR_SUMMARY_BY_CATEORY,
    KDPA_SUMMARY_BY_CATEGORY,
    GDPA_SUMMARY_BY_CATEGORY,
    OVERVIEW_SUMMARY,
    PCIDSS4POINT0_OVERVIEW_SUMMARY,
    SOC2_GENERAL_SUMMARY,
    SOC2_SUMMARY_BY_CONTROL,
    SMART_COMPLIANCE_REPORT,
    SMART_COMPLIANCE_AI_RECOMMENDATIONS,
    CII_GENERAL_SUMMARY,
    PCISLC_GENERAL_SUMMARY,
    ISO9001_GENERAL_SUMMARY,
    ISO9901_SUMMARY_BY_CATEGORY,
    GET_AUDITOR_DASHBOARD_RESULT,
    GET_SMARTCOMPLY_AUDITOR_DASHBOARD_RESULT,
    TECH_SEC_GENERAL_SUMMARY,
    TECH_SEC_SUMMARY_BY_TECHSEC,
    PCISLC_SUMMARY_BY_CATEGORY,
    GET_ALL_CISO_SUMMARY,
    PEOPLE_INFO_SUMMARY,
    PEOPLE_SUMMARY,
    PEOPLE_TRAINING_SUMMARY,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case GET_DASHBOARD_RESULT:
            return {
                ...state,
                dashboardResult: action.payload,
            };
        case NDPR_GENERAL_SUMMARY:
            return {
                ...state,
                ndprGeneral: action.payload,
            };
        case NDPR_SUMMARY_BY_CATEORY:
            return {
                ...state,
                ndprSummary: action.payload,
            };
        case KDPA_GENERAL_SUMMARY:
            return {
                ...state,
                kdpaGeneral: action.payload,
            };
        case KDPA_SUMMARY_BY_CATEGORY:
            return {
                ...state,
                kdpaSummary: action.payload,
            };
        case GDPA_GENERAL_SUMMARY:
            return {
                ...state,
                gdpaGeneral: action.payload,
            };
        case GDPA_SUMMARY_BY_CATEGORY:
            return {
                ...state,
                gdpaSummary: action.payload,
            };
        //SOC_2
        case SOC2_GENERAL_SUMMARY:
            return {
                ...state,
                socGeneral: action.payload,
            };
        case SOC2_SUMMARY_BY_CONTROL:
            return {
                ...state,
                socSummary: action.payload,
            };
        case COMPLIANCE_SUMMARY:
            return {
                ...state,
                pciSummary: action.payload,
            };
        case OVERVIEW_SUMMARY:
            return {
                ...state,
                pciGeneral: action.payload,
            };
        case PCIDSS4POINT0_COMPLIANCE_SUMMARY:
            return {
                ...state,
                pcidss4Point0Summary: action.payload,
            };
        case PCIDSS4POINT0_OVERVIEW_SUMMARY:
            return {
                ...state,
                pcidss4Point0General: action.payload,
            };
        case ISO_GENERAL_SUMMARY:
            return {
                ...state,
                isoGeneral: action.payload,
            };
        case ISO_TWO_GENERAL_SUMMARY:
            return {
                ...state,
                isotwoGeneral: action.payload,
            };
        case TECH_SEC_GENERAL_SUMMARY:
            return {
                ...state,
                techSecGeneral: action.payload,
            };
        case CONTINUITY_GENERAL_SUMMARY:
            return {
                ...state,
                continuityGeneral: action.payload,
            };
        case ISO_SUMMARY_BY_ANNEX:
            return {
                ...state,
                isoSummary: action.payload,
            };
        case ISO_TWO_SUMMARY_BY_ANNEX:
            return {
                ...state,
                isotwoSummary: action.payload,
            };
        case TECH_SEC_SUMMARY_BY_TECHSEC:
            return {
                ...state,
                techSecSummary: action.payload,
            };
        case SMART_COMPLIANCE_REPORT:
            return {
                ...state,
                smartComplianceReport: action.payload,
            };
        case SMART_COMPLIANCE_AI_RECOMMENDATIONS:
            return {
                ...state,
                smart_compliance_ai_recommendations: action.payload,
            };
        case CII_GENERAL_SUMMARY:
            return {
                ...state,
                cii_general_summary: action.payload,
            };
        case GET_AUDITOR_DASHBOARD_RESULT:
            return {
                ...state,
                auditor_dashboard_result: action.payload,
            };
        case GET_SMARTCOMPLY_AUDITOR_DASHBOARD_RESULT:
            return {
                ...state,
                smartcomply_auditor_dashboard_result: action.payload,
            };
        case PCISLC_GENERAL_SUMMARY:
            return {
                ...state,
                pcislc_general_summary: action.payload,
            };
        case PCISLC_SUMMARY_BY_CATEGORY:
            return {
                ...state,
                pcislc_summary_by_control: action.payload,
            };
        case ISO9001_GENERAL_SUMMARY:
            return {
                ...state,
                iso9001_general_summary: action.payload,
            };
        case ISO9901_SUMMARY_BY_CATEGORY:
            return {
                ...state,
                iso9001_summary_by_control: action.payload,
            };
        case GET_ALL_CISO_SUMMARY:
            return {
                ciso_report_summary: action.payload,
            };
        case PEOPLE_INFO_SUMMARY:
            return {
                people_info_summary: action.payload,
            };
        case PEOPLE_SUMMARY:
            return {
                people_summary: action.payload,
            };
        case PEOPLE_TRAINING_SUMMARY:
            return {
                people_training_summary: action.payload,
            };
        case 'CLEAR_DATA':
            return {};
        default:
            return state;
    }
};
