import React from 'react';

import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material/';

import CenteredModal from 'components/new_components/CenteredModal';
import { AppForm, AppFormInput, AppSubmitButton } from 'components/new_components/forms';

const NameModal = (props) => {
    const { open, handleClose, id, loading, handleNameChange } = props;
    const theme = useTheme();
    return (
        <CenteredModal open={open} handleClose={handleClose}>
            <Box sx={{ p: '30px' }}>
                <Stack>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: 700,
                            color: '#0E2C66',
                        }}
                    >
                        Change of Name
                    </Typography>
                    <Box
                        sx={{
                            fontSize: '12px',
                            color: theme.palette.gray[40],
                            fontWeight: 400,
                            border: '0.3px solid #C7C6CA',
                            p: 0.5,
                            mx: 1,
                            my: 1,
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            width: '500px',
                        }}
                    >
                        <ErrorOutline color="#980303" fontSize="small" />
                        <Typography
                            sx={{
                                fontSize: '12px',
                                color: '#77777A',
                                marginLeft: '10px',
                            }}
                        >
                            The super administrator will receive a notification to update your data and will let <br />
                            you know when the changes have been made.
                        </Typography>
                    </Box>
                    <Box sx={{ mt: '45px', width: '485px' }}>
                        <AppForm initialValues={{ first_name: '', last_name: '' }} onSubmit={handleNameChange}>
                            <AppFormInput
                                type="text"
                                label="Correct First Name"
                                placeholder="First Name"
                                name="first_name"
                            />
                            <AppFormInput
                                type="text"
                                label="Correct Last Name"
                                placeholder="Last Name"
                                name="last_name"
                            />
                            <AppSubmitButton
                                text="Request for Change"
                                variant="contained"
                                color="primary"
                                loading={loading}
                                loadingPosition="center"
                                disabled={id?.toString()?.length <= 4}
                                sx={{
                                    width: '205px',
                                    height: '50px',
                                    mt: '30px',
                                    mb: '50px',
                                }}
                            />
                        </AppForm>
                    </Box>
                </Stack>
            </Box>
        </CenteredModal>
    );
};

export default NameModal;
