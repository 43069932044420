import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

// @antd
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Divider, Modal, Row, Spin } from 'antd';

// app components
import FormItem from '../../Operations/FormItem';

// redux
import { connect } from 'react-redux';
import { GetAllFormsBySubcriteria, DeleteForm } from 'store/actions/adminActions';
import EmptyComponent from 'components/EmptyComponents/EmptyComponent';

const { confirm } = Modal;

const AuditQuestions = (props) => {
    const history = useHistory();
    // props
    const { GetAllFormsBySubcriteria, subcriteria, subcriteria_forms, DeleteForm } = props;

    // state
    const [loading, setLoading] = useState({ content: false });

    // functions
    // async functions
    const getFormsBySubcriteria = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAllFormsBySubcriteria(subcriteria?.id);
        setLoading({ ...loading, content: false });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const deleteForm = (form) => {
        confirm({
            title: 'Are you sure you want to delete this form? Note: Deleting this form means you will be deleting all questions and merchant responses associated with this form',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteForm(form?.id, form?.subcriteria, 'blockchain');
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    // useEffect
    useEffect(() => {
        getFormsBySubcriteria();
    }, []);

    return (
        <>
            <Divider orientation="left">
                <span className="flex items-center space-x-8">
                    <QuestionCircleOutlined className="mr-1" />
                    Audit Questions
                </span>
            </Divider>
            {loading.content ? (
                <Row justify="center">
                    <Spin size="large" />
                </Row>
            ) : (
                <Row gutter={16}>
                    {subcriteria_forms?.length ? (
                        subcriteria_forms
                            ?.sort((a, b) => b?.id - a?.id)
                            ?.map((form) => {
                                return (
                                    <>
                                        <FormItem
                                            form={form}
                                            title={form?.criteria_title}
                                            onEdit={() =>
                                                history.push({
                                                    pathname: `/admin/blockchain/subcriteria/${subcriteria?.id}/form`,
                                                    state: {
                                                        mode: 'edit',
                                                        form,
                                                        titleLabel: 'criteria_title',
                                                        groupIdLabel: 'subcriteria',
                                                        payload: {
                                                            criteria: subcriteria?.criteria,
                                                            subcriteria: subcriteria?.id,
                                                        },
                                                    },
                                                })
                                            }
                                            onDelete={() => deleteForm(form)}
                                            key={form?.id}
                                        />
                                    </>
                                );
                            })
                    ) : (
                        <div className="w-full">
                            <EmptyComponent text="No Forms" />
                        </div>
                    )}
                </Row>
            )}
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        subcriteria_forms: state?.adminReducers?.all_subcriteria_forms,
    };
};
export default connect(mapStateToProps, { GetAllFormsBySubcriteria, DeleteForm })(AuditQuestions);
