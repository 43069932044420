import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import AppDownloadViewModal from 'components/new_components/AppVDownloadViewModal.js';
import { useTheme } from '@mui/styles';
import { by3riskLevel, by5riskLevel } from 'utils';
import moment from 'moment';

//translations
import { useTranslation } from 'react-i18next';

const RiskAssessmentViewModal = (props) => {
    const { open, handleClose, payload } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('riskAssessment');

    const onClose = () => {
        handleClose();
    };

    return (
        <AppDownloadViewModal
            open={open}
            anchor={'right'}
            handleClose={onClose}
            title={`${t('riskAssessmentViewModal.title2')} (${payload.matrix})`}
        >
            <Box sx={{ backgroundColor: '#F8F8F8' }}>
                <Box sx={{ mt: 1, backgroundColor: '#FFFFFF' }}>
                    <Box sx={{ pl: 2, pr: 3 }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ borderBottom: `1px solid #E1E2EC`, height: '44px' }}
                        >
                            <Typography sx={{ color: theme.palette.primary[20], fontWeight: 700, fontSize: '16px' }}>
                                {t('riskAssessmentViewModal.threatID')}
                            </Typography>
                            <Typography sx={{ color: theme.palette.gray[40], fontSize: '11px', fontWeight: 600 }}>
                                {t('riskAssessmentViewModal.riskStatus')}:{' '}
                                <span className="text-[#37A372] text-capitalize">{payload?.risk_status}</span>
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                borderBottom: `1px solid #E1E2EC`,
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.gray[30],
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    py: 1,
                                }}
                            >
                                {t('riskAssessmentViewModal.assetType')}
                            </Typography>
                            <Typography
                                sx={{
                                    backgroundColor: '#E1E2EC',
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.neutral[50],
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    width: '50%',
                                    py: 1,
                                }}
                            >
                                {payload?.asset_type}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                borderBottom: `1px solid #E1E2EC`,
                                py: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.gray[30],
                                    fontSize: '13px',
                                    fontWeight: 600,
                                }}
                            >
                                {t('riskAssessmentViewModal.targettedAsset')}
                            </Typography>
                            <Typography
                                sx={{
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.neutral[50],
                                    fontSize: '13px',
                                    fontWeight: 700,
                                    width: '50%',
                                }}
                            >
                                {payload?.asset}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                borderBottom: `1px solid #E1E2EC`,
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.gray[30],
                                    fontSize: '13px',
                                    fontWeight: 600,
                                }}
                            >
                                {t('riskAssessmentViewModal.threat')}
                            </Typography>
                            <Typography
                                sx={{
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.neutral[50],
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    width: '50%',
                                    py: 1,
                                }}
                            >
                                {payload?.threat_detail?.name} {payload?.threat_detail?.sub_category}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                borderBottom: `1px solid #E1E2EC`,
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.gray[30],
                                    fontSize: '13px',
                                    fontWeight: 600,
                                }}
                            >
                                {t('riskAssessmentViewModal.threatDescription')}
                            </Typography>
                            <Typography
                                sx={{
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.neutral[50],
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    width: '60%',
                                    borderLeft: `1px solid #E1E2EC`,
                                    py: 1,
                                }}
                            >
                                {payload?.threat_description}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                borderBottom: `1px solid #E1E2EC`,
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.gray[30],
                                    fontSize: '13px',
                                    fontWeight: 600,
                                }}
                            >
                                {t('riskAssessmentViewModal.riskOwner')}
                            </Typography>
                            <Typography
                                sx={{
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.neutral[50],
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    width: '50%',
                                    py: 1,
                                }}
                            >
                                {payload?.risk_owner}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                borderBottom: `1px solid #E1E2EC`,
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.gray[30],
                                    fontSize: '13px',
                                    fontWeight: 600,
                                }}
                            >
                                {t('riskAssessmentViewModal.dateDetected')}
                            </Typography>
                            <Typography
                                sx={{
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.neutral[50],
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    width: '50%',
                                    py: 1,
                                }}
                            >
                                {moment(payload?.date_detected).format('Do MMMM, YYYY')}
                            </Typography>
                        </Stack>

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                borderBottom: `1px solid #E1E2EC`,
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.gray[30],
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    py: 1,
                                }}
                            >
                                {t('riskAssessmentViewModal.inherentLikelihood')}
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#002C72',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    py: 1.3,
                                    width: '25%',
                                }}
                            >
                                {payload?.matrix === '3x3'
                                    ? payload?.inherent_likelihood === 1
                                        ? t('riskAssessmentViewModal.low')
                                        : payload?.inherent_likelihood === 2
                                        ? t('riskAssessmentViewModal.medium')
                                        : t('riskAssessmentViewModal.high')
                                    : payload?.inherent_likelihood === 1
                                    ? t('riskAssessmentViewModal.rare')
                                    : payload?.inherent_likelihood === 2
                                    ? t('riskAssessmentViewModal.unlikely')
                                    : payload?.inherent_likelihood === 3
                                    ? t('riskAssessmentViewModal.possible')
                                    : payload?.inherent_likelihood === 4
                                    ? t('riskAssessmentViewModal.likely')
                                    : t('riskAssessmentViewModal.almostCertain')}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                borderBottom: `1px solid #E1E2EC`,
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.gray[30],
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    py: 1,
                                }}
                            >
                                {t('riskAssessmentViewModal.inherentImpact')}
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#002C72',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    py: 1.3,
                                    width: '25%',
                                }}
                            >
                                {payload?.matrix === '3x3'
                                    ? payload?.inherent_impact === 1
                                        ? t('riskAssessmentViewModal.low')
                                        : payload?.inherent_impact === 2
                                        ? t('riskAssessmentViewModal.medium')
                                        : t('riskAssessmentViewModal.high')
                                    : payload?.inherent_impact === 1
                                    ? t('riskAssessmentViewModal.negligible')
                                    : payload?.inherent_impact === 2
                                    ? t('riskAssessmentViewModal.minor')
                                    : payload?.inherent_impact === 3
                                    ? t('riskAssessmentViewModal.moderate')
                                    : payload?.inherent_impact === 4
                                    ? t('riskAssessmentViewModal.major')
                                    : t('riskAssessmentViewModal.catastrophic')}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                borderBottom: `1px solid #E1E2EC`,
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.gray[30],
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    py: 1,
                                }}
                            >
                                {t('riskAssessmentViewModal.inherentRiskLevel')}
                            </Typography>
                            <Typography
                                sx={{
                                    backgroundColor:
                                        payload?.matrix === '3x3'
                                            ? by3riskLevel(payload?.inherent_risk_level)?.color
                                            : by5riskLevel(payload?.inherent_risk_level)?.color,
                                    color: '#FFFFFF',
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    py: 1.3,
                                    width: '25%',
                                }}
                            >
                                {payload?.matrix === '3x3'
                                    ? by3riskLevel(payload.inherent_risk_level)?.type
                                    : by5riskLevel(payload.inherent_risk_level)?.type}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box sx={{ pl: 2, pr: 3 }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ borderBottom: `1px solid #E1E2EC`, height: '44px' }}
                        >
                            <Typography sx={{ color: theme.palette.primary[20], fontWeight: 700, fontSize: '16px' }}>
                                {t('riskAssessmentViewModal.existing')}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                borderBottom: `1px solid #E1E2EC`,
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.gray[30],
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    py: 1,
                                }}
                            >
                                {t('riskAssessmentViewModal.control')}
                            </Typography>
                            <Typography
                                sx={{
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.neutral[50],
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    width: '50%',
                                    py: 1,
                                }}
                            >
                                {payload?.existing_controls}
                            </Typography>
                        </Stack>

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ borderBottom: `1px solid #E1E2EC`, height: '44px' }}
                        >
                            <Typography sx={{ color: theme.palette.primary[20], fontWeight: 700, fontSize: '16px' }}>
                                {t('riskAssessmentViewModal.postRiskAssessment')}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                borderBottom: `1px solid #E1E2EC`,
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.gray[30],
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    py: 1,
                                }}
                            >
                                {t('riskAssessmentViewModal.residualInherent')}
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#002C72',
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    py: 1.3,
                                    width: '25%',
                                }}
                            >
                                {payload?.matrix === '3x3'
                                    ? payload?.residual_likelihood === 1
                                        ? t('riskAssessmentViewModal.low')
                                        : payload?.residual_likelihood === 2
                                        ? t('riskAssessmentViewModal.medium')
                                        : t('riskAssessmentViewModal.high')
                                    : payload?.residual_likelihood === 1
                                    ? t('riskAssessmentViewModal.rare')
                                    : payload?.residual_likelihood === 2
                                    ? t('riskAssessmentViewModal.unlikely')
                                    : payload?.residual_likelihood === 3
                                    ? t('riskAssessmentViewModal.possible')
                                    : payload?.residual_likelihood === 4
                                    ? t('riskAssessmentViewModal.likely')
                                    : t('riskAssessmentViewModal.almostCertain')}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                borderBottom: `1px solid #E1E2EC`,
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.gray[30],
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    py: 1,
                                }}
                            >
                                {t('riskAssessmentViewModal.residualImpact')}
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#002C72',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    py: 1.3,
                                    width: '25%',
                                }}
                            >
                                {payload?.matrix === '3x3'
                                    ? payload?.residual_impact === 1
                                        ? t('riskAssessmentViewModal.low')
                                        : payload?.residual_impact === 2
                                        ? t('riskAssessmentViewModal.medium')
                                        : t('riskAssessmentViewModal.high')
                                    : payload?.residual_impact === 1
                                    ? t('riskAssessmentViewModal.negligible')
                                    : payload?.residual_impact === 2
                                    ? t('riskAssessmentViewModal.minor')
                                    : payload?.residual_impact === 3
                                    ? t('riskAssessmentViewModal.moderate')
                                    : payload?.residual_impact === 4
                                    ? t('riskAssessmentViewModal.major')
                                    : t('riskAssessmentViewModal.catastrophic')}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                borderBottom: `1px solid #E1E2EC`,
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.gray[30],
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    py: 1,
                                }}
                            >
                                {t('riskAssessmentViewModal.residualRiskLevel')}
                            </Typography>
                            <Typography
                                sx={{
                                    backgroundColor:
                                        payload?.matrix === '3x3'
                                            ? by3riskLevel(payload?.residual_risk_level)?.color
                                            : payload?.residual_impact === 2 && payload?.residual_likelihood === 2
                                            ? '#006C46'
                                            : by5riskLevel(payload?.residual_risk_level)?.color,
                                    color: '#FFFFFF',
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    py: 1.3,
                                    width: '25%',
                                }}
                            >
                                {payload?.matrix === '3x3'
                                    ? by3riskLevel(payload.residual_risk_level)?.type
                                    : payload?.residual_impact === 2 && payload?.residual_likelihood === 2
                                    ? t('riskAssessmentViewModal.low')
                                    : by5riskLevel(payload.residual_risk_level)?.type}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                borderBottom: `1px solid #E1E2EC`,
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.gray[30],
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    py: 1,
                                }}
                            >
                                {t('riskAssessmentViewModal.comment')}
                            </Typography>
                            <Typography
                                sx={{
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: theme.palette.neutral[50],
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    width: '50%',
                                    py: 1,
                                }}
                            >
                                {payload?.comments}
                            </Typography>
                        </Stack>
                    </Box>
                </Box>
            </Box>
        </AppDownloadViewModal>
    );
};

export default RiskAssessmentViewModal;
