import React from 'react';
import { Typography, Box } from '@mui/material';

const LabelledChipCircularTag = ({ label, value }) => {
    return (
        <Box
            sx={{
                my: 1,
                mr: 1,
                height: '25px',
                display: 'flex',
                alignItems: 'center',
                border: '0.8px solid #B8BCCC',
                borderRadius: '90px',
                p: 1,
            }}
        >
            <Typography
                sx={{
                    color: '#0E2C66',
                    fontSize: '12px',
                    lineHeight: '15px',
                    fontWeight: 500,
                    display: 'flex',
                    alignItems: 'center',
                    mr: '4px',
                    // mt: 1,
                }}
            >
                {label}
            </Typography>
            <Typography
                sx={{
                    border: '0.8px solid #B8BCCC',
                    // py: 2,
                    // px: 2.5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '22px',
                    height: '16px',
                    color: '#ffff',
                    borderRadius: '50%',
                    lineHeight: '17px',
                    fontSize: 10,
                    fontWeight: 600,
                    background: '#8F9099',
                    // mt: 1,
                }}
            >
                {value}
            </Typography>
        </Box>
    );
};

export default LabelledChipCircularTag;
