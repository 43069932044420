import React, { useMemo, useState } from 'react';

const EliminatioMethod = ({ localStates, setLocalStates, setRiskAvoid }) => {
    // const [isInputFocused, setIsInputFocused] = useState(false);
    // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [tempMethod, setTempMethod] = useState('');

    const defaultMethods = useMemo(
        () =>
            [].filter((item) => {
                if (!localStates.eliminationMethod) return item;

                return item.toLowerCase().includes(localStates.eliminationMethod.toLowerCase());
            }),
        [localStates.eliminationMethod]
    );

    // useEffect(() => {
    //     if (isInputFocused) {
    //         if (defaultMethods.length === 0) {
    //             setIsDropdownOpen(false);
    //         } else {
    //             setIsDropdownOpen(true);
    //         }
    //     } else {
    //         setIsDropdownOpen(false);
    //     }
    // }, [isInputFocused, defaultMethods.length]);

    return (
        <div className="relative" onMouseLeave={() => setTempMethod('')}>
            <div className="relative">
                <textarea
                    type="text"
                    placeholder="Enter an elimination method"
                    value={localStates.risk_elimination_method}
                    onChange={(event) => {
                        setLocalStates((prev) => ({
                            ...prev,
                            risk_elimination_method: event.target.value,
                        }));

                        setTempMethod('');
                    }}
                    onBlur={(event) => {
                        setRiskAvoid((prev) => ({
                            ...prev,
                            risk_elimination_method: event.target.value || tempMethod,
                        }));
                    }}
                    className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 !pr-5 !min-h-[90px]  !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                />
            </div>

            <div
                className="absolute top-[105%] !w-full !rounded !border !border-[#F1F5F9] !p-2 bg-white shadow-[0px,11.76px,22.06px,0px,#00000014] flex flex-col max-h-[176px] overflow-y-auto"
                // hidden={!isDropdownOpen}
            >
                {defaultMethods.map((method, index) => (
                    <button
                        key={index}
                        className="!py-1.5 !px-2 font-medium text-[13px] bg-white capitalize text-[#64748B] transition-all hover:brightness-95 !text-left"
                        onMouseEnter={() => setTempMethod(method)}
                    >
                        {method}
                    </button>
                ))}
            </div>
        </div>
    );
};
export default EliminatioMethod;
