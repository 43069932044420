import React, { useState } from 'react';
import Users from '../Users';
import Subscription from '../AccountSetup/plans/index';
import Profile from '../AccountSetup/UserInfo';
import Twofa from '../AccountSetup/TwoFactorAuth/index';
import Advanced from '../AccountSetup/Compliance/index';
import CompanyInfo from '../CompanySetup/index';
import PasswordMgt from '../AccountSetup/PasswordMgt';
import Layout from './Layout';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import { useLocation } from 'react-router-dom';
import { getType } from 'utils';
// import PageHeader from 'components/Headers/PageHeader';

const Settings = () => {
    const location = useLocation();
    const { tab_ } = location?.state ?? {};
    const [currentTab, setCurrentTab] = useState(tab_ !== undefined ? tab_ : 0);

    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };

    const role = getType('role');

    return (
        <>
            {/* <PageHeader browserTitle="Settings" /> */}
            <Layout currentTab={currentTab} handleTabChange={handleTabChange}>
                <AppTabPanel value={currentTab} index={0}>
                    <Profile />
                </AppTabPanel>
                {role !== 'user' && (
                    <AppTabPanel value={currentTab} index={1}>
                        <Users />
                    </AppTabPanel>
                )}
                <AppTabPanel value={currentTab} index={2}>
                    <Subscription />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={3}>
                    <Twofa />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={4}>
                    <Advanced />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={5}>
                    <CompanyInfo />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={6}>
                    <PasswordMgt />
                </AppTabPanel>
            </Layout>
        </>
    );
};

export default Settings;
