import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Modal, Row } from 'antd';
import BreadCrumb from 'components/Breadcrumb';
import { Link } from 'react-router-dom';
import SubCategoryModal from '../components/SubCategoryModal';

// redux
import { connect } from 'react-redux';
import { DeleteAccessorSubCategory } from 'store/actions/auditActions';

const { confirm } = Modal;

const CategoryCards = (props) => {
    // props
    const { location, all_accessor_categories, DeleteAccessorSubCategory } = props;

    // state
    const [modal, setModal] = useState(null);
    const [data, setData] = useState({});

    const category = useMemo(
        () => all_accessor_categories.find((category) => category?.id == location?.state?.category_id),
        [location, all_accessor_categories]
    );

    // modal control
    const openModal = (mode, data = {}) => {
        setModal(mode);
        setData(data);
    };
    const closeModal = () => {
        setModal(null);
        setData({});
    };
    const deleteAccessorCategory = (id) => {
        confirm({
            title: 'Are you sure delete this Category?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteAccessorSubCategory(id);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[
                            { label: 'Accessor Categories', link: '/accessor-categories' },
                            { label: category?.name },
                        ]}
                    />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        className="flex jsutify-between items-center"
                        onClick={() => openModal('Add')}
                    >
                        Add Sub Categories
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {category?.subcategory && category?.subcategory?.length ? (
                    <Row wrap gutter={24} justify="start">
                        {category?.subcategory
                            ?.sort((a, b) => a?.id - b?.id)
                            .map((cat) => (
                                <Col xs={24} md={12} lg={8} xxl={6} style={{ marginBottom: '1rem' }} key={cat.id}>
                                    <Card
                                        title={cat.name}
                                        loading={false}
                                        actions={[
                                            <Link
                                                key="preview"
                                                to={{
                                                    pathname: `/admin/accessor-categories/multiple/${cat?.id}`,
                                                    state: {
                                                        category_id: cat?.category,
                                                        id: cat?.id,
                                                    },
                                                }}
                                            >
                                                <EyeOutlined key="preview" />
                                            </Link>,
                                            <EditOutlined key="edit" onClick={() => openModal('Edit', cat)} />,
                                            <DeleteOutlined
                                                key="delete"
                                                onClick={() => deleteAccessorCategory(cat?.id)}
                                            />,
                                        ]}
                                    ></Card>
                                </Col>
                            ))}
                    </Row>
                ) : (
                    <Row justify="center" className="justify-center">
                        <Col>
                            <Empty
                                description={
                                    <div>
                                        <h3>No Sub Category</h3>
                                        <span>Add a new sub category </span>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                )}
            </section>
            <SubCategoryModal
                open={modal}
                handleClose={closeModal}
                modalData={data}
                category_id={location?.state?.category_id}
            />
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_accessor_categories: state?.auditReducers?.all_accessor_categories,
});
export default connect(mapStateToProps, { DeleteAccessorSubCategory })(CategoryCards);
