import React from 'react';
import SubAlertIcon from '../../assets/img/dashboard/subAlertIcon.png';
import RoutingAlertIcon from '../../assets/img/dashboard/RoutingIcon.png';
import UpgradeAction from './UpgradeAction';

const SubscriptionRoutingAlert = () => {
    return (
        <div className="items-center flex justify-center mb-4 h-screen overflow-y-hidden">
            <div className="routing_bg bg-white w-1/3 px-12 py-8">
                <div className="flex items-center">
                    <img src={SubAlertIcon} alt="SubAlertIcon" />
                    <div className="pr-10 pl-3 text-center">
                        <h4 className="text-[#E2B208]">You are on free trial</h4>
                    </div>
                </div>
                <div className="text-center py-8">
                    <img src={RoutingAlertIcon} alt="SubAlertIcon" className="m-auto" />
                    <p className="text-[#707380] font-medium py-3">You can’t view this page</p>
                </div>
                <UpgradeAction />
            </div>
        </div>
    );
};

export default SubscriptionRoutingAlert;
