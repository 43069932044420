import { validation } from 'validate';

export const validateForm = (values) => {
    const errors = {};
    const barcodeValidation = validation(values.barcode_number, 'Barcode Number', true);

    if (!barcodeValidation.isValid) {
        errors.barcode_number = barcodeValidation.errorMessage;
    }
    return errors;
};
