import { FormControlLabel, Radio, RadioGroup, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import AppFormControl from './forms/AppFormControl';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PublicIcon from '@mui/icons-material/Public';

const AppRadioGroup = (props) => {
    const {
        options,
        label,
        name,
        sx = {},
        labelStyle = {},
        groupStyle = {},
        value,
        onChange,
        radioLabelStyle = {},
        radioBorder = true,
        error,
        codereview,
        hasBorder,
        ...restProps
    } = props;

    const theme = useTheme();

    return options ? (
        <AppFormControl sx={sx} label={label} name={name} labelStyle={labelStyle} error={error}>
            <RadioGroup name={name} sx={groupStyle} value={value} onChange={onChange} {...restProps}>
                {options.map((option) => {
                    return (
                        <FormControlLabel
                            disableTypography
                            sx={{
                                ...radioLabelStyle,
                                transition: 'border-color 0.3s',
                                border: hasBorder ? 'none' : '2px solid',
                                borderColor: value === option.value ? theme.palette.primary[900] : '#00000000',
                                borderStyle: radioBorder ? '' : 'none',
                            }}
                            value={option.value}
                            control={<Radio />}
                            label={
                                codereview ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {option.value === 'private' ? (
                                            <LockOutlinedIcon sx={{ width: 26, height: 26 }} />
                                        ) : (
                                            <PublicIcon sx={{ width: 26, height: 26 }} />
                                        )}
                                        <Box sx={{ marginLeft: 1 }}>
                                            <Typography
                                                sx={{
                                                    color: theme.palette.primary[500],
                                                    fontSize: 16,
                                                }}
                                            >
                                                {option.label}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: theme.palette.gray[700],
                                                    fontSize: 12,
                                                }}
                                            >
                                                {option.sub_label}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ) : (
                                    option.label
                                )
                            }
                            key={option.value}
                            disabled={option.disabled}
                        />
                    );
                })}
            </RadioGroup>
        </AppFormControl>
    ) : null;
};

export default AppRadioGroup;
