import React, { useState, useMemo, useEffect } from 'react';

// core components
import LoadingState from 'components/new_components/LoadingState';
import DescriptionLayerNew from '../../common/DescriptionLayerNew';
import DocumentItem from '../../common/document/DocumentItem';
import QuestionItem from '../../common/audit_questions/QuestionItem';
// import SubgroupTabs from '../../common/SubgroupTabs';
import SubgroupView from '../../common/SubgroupView';
import DescriptionPreviewDrawer from '../../common/DescriptionPreviewDrawer';

// redux
import { connect, useDispatch, useSelector } from 'react-redux';

//translations
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GetEvidencesByQualityManagement } from 'store/actions/iso9001';

const SubControls = (props) => {
    const {
        goBack,
        controlId,
        resource,
        merchantResources,
        subcontrolResources,
        onSubcontrolChange,
        loading,
        setFormPayload,
        formPayload,
        // touchedFields,
        submitting,
        handleResourceExempted,
        openTaskAssignModal,
        all_compliance_assigned_tasks,
        all_merchant_evidences,
        handleSubmitAnswers,
        setOthers,
        error,
        all_assigned_task,
        setPreviousPayload,
        diffCheck,
    } = props;
    // state
    const [page, setPage] = useState(1);
    const [selectedSubcontrolTitle, setSelectedSubcontrolTitle] = useState(null);
    const [descriptionDrawer, setDescriptionDrawer] = useState(false);
    const [openPolicyHistory, setOpenPolicyHistory] = useState(false);
    const dispatch = useDispatch();

    const all_quality_management = useSelector((state) => state?.ISO9001Reducers?.all_quality_management);
    const all_quality_management_summary = useSelector(
        (state) => state?.ISO9001Reducers?.all_quality_management_summary
    );
    const evidences_by_management = useSelector((state) => state?.ISO9001Reducers?.evidence_by_control);

    const handleOpenPolicyHistory = () => {
        setOpenPolicyHistory(true);
    };

    const handleClosePolicyHistory = () => {
        setOpenPolicyHistory(false);
    };

    //translation
    const { t } = useTranslation('compliance');

    // memos
    // get the selected control
    const selectedControl = useMemo(() => {
        return all_quality_management?.find((control) => control?.id === controlId);
    }, [all_quality_management, controlId]);
    // overall metric for the selected control
    const overallMetric = useMemo(() => {
        const metrics = all_quality_management_summary?.[selectedControl?.title];

        return resource === 'doc'
            ? `${metrics?.user_doc}/${metrics?.total_doc} ${t('documentsUploaded')}`
            : `${metrics?.user_form}/${metrics?.total_form} ${t('questionsAnswered')}`;
    }, [selectedControl, all_quality_management_summary]);
    const subcontrols = useMemo(() => {
        return selectedControl?.sub_quality_mgt
            ?.sort((a, b) => a?.id - b?.id)
            ?.map((subcontrol) => ({
                id: subcontrol?.number,
                description: subcontrol?.description,
                label: subcontrol?.title,
                title: subcontrol?.number,
                has_resource: resource === 'doc' ? subcontrol?.have_doc : subcontrol?.have_question,
                completed: resource === 'doc' ? subcontrol?.document_completed : subcontrol?.question_completed,
            }));
    }, [selectedControl]);
    // get the selected subcontrol from the the subcontrols
    const selectedSubcontrol = useMemo(() => {
        return subcontrols?.find((subcontrol) => subcontrol?.title === selectedSubcontrolTitle);
    }, [selectedSubcontrolTitle]);
    // get a list of the status of each resource in the subcontrol
    const resourcesHaveBeenAttendedTo = useMemo(() => {
        return subcontrolResources?.map((subcontrolDoc) => {
            return merchantResources?.some((merchDoc) =>
                resource === 'doc'
                    ? merchDoc?.document_id === subcontrolDoc?.id
                    : merchDoc?.form_id === subcontrolDoc?.id
            );
        });
    }, [merchantResources, subcontrolResources]);
    // get the percentage completion for the selected subcontrol
    const subcontrolCompletionPercent = useMemo(() => {
        return (
            (resourcesHaveBeenAttendedTo?.reduce((prev, curr) => prev + (curr ? 1 : 0), 0) * 100) /
            resourcesHaveBeenAttendedTo?.length
        );
    }, [resourcesHaveBeenAttendedTo]);

    // get the selected document for the current page
    const selectedResource = useMemo(() => {
        return subcontrolResources?.[page - 1];
    }, [subcontrolResources, page]);

    // move to a particular resource
    const goToResource = (page) => {
        if (page > subcontrolResources?.length) {
            setPage(subcontrolResources?.length);
        } else if (page < 1) {
            setPage(1);
        } else {
            setPage(page);
        }
    };
    const handleSubgroupChange = (title) => {
        setSelectedSubcontrolTitle(title);
        goToResource(1);
    };
    const onResourceChange = () => {
        handleResourceExempted(selectedResource?.quality_mgt_sub_no);
    };

    // useEffect
    // to get the first sub control that has a resource and set it as active
    useEffect(() => {
        if (!subcontrols) return;
        // check if the current subcontrol has any resource
        const currentSubHasResource = subcontrols?.find((sub) => sub?.title === selectedSubcontrolTitle)?.has_resource;
        if (currentSubHasResource) return;
        // get the first active resource
        const activeSubcontrol = subcontrols?.find((sub) => sub?.has_resource);
        if (activeSubcontrol) {
            setSelectedSubcontrolTitle(activeSubcontrol?.title);
        } else {
            goBack();
        }
    }, [subcontrols]);
    // when ever the selected sub control chnage
    useEffect(() => {
        if (selectedSubcontrolTitle) {
            onSubcontrolChange(selectedSubcontrolTitle);
        }
    }, [selectedSubcontrolTitle]);
    useEffect(() => {
        if (!selectedResource) {
            if (subcontrolResources) {
                return goToResource(subcontrolResources?.length);
            }
        }
    }, [selectedResource]);
    useEffect(() => {
        if (resource === 'form') {
            // get the admin resource for that page
            const adminResource = selectedResource;
            // get the merchant resource corresponding to that page
            const merchantResource = merchantResources
                ?.sort((a, b) => b?.id - a?.id)
                .find((resource) => resource?.form_id === adminResource?.id);
            // set the answers
            let initialValues = {},
                requiredValues = {};
            adminResource?.form_fields?.forEach((field) => {
                initialValues[field?.id] = merchantResource?.answer?.[field?.id] || '';
                requiredValues[field?.id] = field?.type === 'dropdown' ? false : field?.required;
            });
            setFormPayload(initialValues);
            setPreviousPayload(initialValues);
            setOthers({
                form_id: adminResource?.id,
                requiredValues,
                subcontrol: adminResource?.quality_mgt_sub_no,
            });
        }
    }, [page, selectedResource, merchantResources]);

    //open description preview modal
    const openDescriptionDrawer = () => {
        setDescriptionDrawer(true);
    };
    const closeDescriptionDrawer = () => {
        setDescriptionDrawer(false);
    };
    useEffect(() => {
        if (controlId) {
            const getControlEvidences = async () => {
                const res = await dispatch(GetEvidencesByQualityManagement(controlId));
                if (!res?.success) {
                    toast.error('Something went wrong!');
                }
            };
            getControlEvidences();
        }
    }, []);
    const subgroup_evidences = evidences_by_management?.filter(
        (evidence) => evidence?.quality_mgt_sub_no === selectedSubcontrol?.id
    );

    const user_subgroup_evidences = all_merchant_evidences?.filter(
        (evidence) => evidence?.quality_mgt_sub_no === selectedSubcontrol?.title
    );
    const task_assigned_documents = all_assigned_task
        ?.filter((task) => task?.document === selectedResource?.id)
        ?.map((item) => item?.document);
    const task_assigned_forms = all_assigned_task
        ?.filter((task) => task?.form === selectedResource?.id)
        ?.map((item) => item?.form);

    const task_assigned_sub_control = all_assigned_task
        ?.filter(
            (task) => task?.quality_mgt_sub_no === selectedSubcontrol?.id && task?.quality_mgt === selectedControl?.id
        )
        ?.map((item) => item?.quality_mgt_sub_no);
    return (
        <>
            <SubgroupView
                goBack={goBack}
                title={selectedControl?.title}
                groupMetric={overallMetric}
                goToNextResource={goToResource}
                currentResourcePage={page}
                data={resourcesHaveBeenAttendedTo}
                resource={resource}
                handleSubmitAnswers={handleSubmitAnswers}
                formPayload={formPayload}
                submitting={submitting}
                selectedSubgroup={selectedSubcontrol?.title}
                assigned_task_documents_info={task_assigned_documents}
                assigned_task_questions_info={task_assigned_forms}
                standard="iso9001"
                selectedSubgroupId={selectedSubcontrol?.title}
                subgroups={subcontrols}
                onSubgroupChange={handleSubgroupChange}
                diffCheck={diffCheck}
                assignedSubrequirements={
                    resource === 'doc'
                        ? all_compliance_assigned_tasks?.documents?.sub_category?.soc2
                        : all_compliance_assigned_tasks?.questions?.sub_category?.soc2
                }
            >
                {loading ? (
                    <LoadingState />
                ) : (
                    <>
                        <DescriptionLayerNew
                            percentCompletion={subcontrolCompletionPercent}
                            label={selectedSubcontrol?.label}
                            description={selectedSubcontrol?.description}
                            openTaskAssignModal={() =>
                                openTaskAssignModal('quality_mgt_sub_no', {
                                    quality_mgt_sub_no: selectedSubcontrol?.title,
                                    standard: 'iso9001',
                                    is_document: resource === 'doc',
                                    quakity_mgt: selectedControl?.id,
                                })
                            }
                            resource={resource}
                            type="sub-control"
                            subCategory={selectedSubcontrol?.title}
                            title={`${selectedSubcontrol?.title}`}
                            category={selectedControl?.id}
                            standard="iso9001"
                            isTaskAssignedToSelectSubRequirement={task_assigned_sub_control}
                            openDescriptionDrawer={openDescriptionDrawer}
                            subgroup_evidences={subgroup_evidences}
                            user_subgroup_evidences={user_subgroup_evidences}
                        />
                        {resource === 'doc' ? (
                            <DocumentItem
                                document={selectedResource}
                                title_key={'quality_mgt_title'}
                                standard="iso9001"
                                resource="doc"
                                openTaskAssignModal={openTaskAssignModal}
                                onResourceChange={onResourceChange}
                                openPolicyHistory={openPolicyHistory}
                                handleOpenPolicyHistory={handleOpenPolicyHistory}
                                handleClosePolicyHistory={handleClosePolicyHistory}
                                assignedDocuments={task_assigned_documents}
                            />
                        ) : (
                            <QuestionItem
                                form={selectedResource}
                                title={'quality_mgt_title'}
                                standard="iso9001"
                                openTaskAssignModal={openTaskAssignModal}
                                onResourceChange={onResourceChange}
                                formPayload={formPayload}
                                setFormPayload={setFormPayload}
                                error={error}
                                assignedForms={task_assigned_forms}
                            />
                        )}
                    </>
                )}
            </SubgroupView>
            <DescriptionPreviewDrawer
                open={descriptionDrawer}
                handleClose={closeDescriptionDrawer}
                category={selectedSubcontrol}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_assigned_task: state?.tasksReducers?.all_assigned_tasks_by_standard,
        all_merchant_evidences: state?.complianceReducers?.all_merchant_evidences,
    };
};
export default connect(mapStateToProps, {})(SubControls);
