import React, { useState } from 'react';
import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

//translations
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const InfrastructureTableActions = (props) => {
    const { row, openResolvedmodal, openApprovedModal, openSuggestionModal, openViewModal, setApprovalType } = props;

    // state
    const [anchor, setAnchor] = useState(null);

    const { risk_current_permission } = useSelector((state) => state.generalReducers);
    //translation
    const { t } = useTranslation('riskAssessment');

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);
    return (
        <>
            <IconButton sx={{ color: '#64748B' }} onClick={openMenu}>
                <MoreHoriz />
            </IconButton>
            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                <MenuItem
                    sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B', py: 0.5 }}
                    onClick={() => {
                        closeMenu();
                        openViewModal(row);
                    }}
                >
                    {t('tableAction.view')}
                </MenuItem>
                {(row?.risk_assessment_status === 'not_resolved' ||
                    row?.risk_assessment_status === 'approved' ||
                    row?.risk_assessment_status === 'no response') && (
                    <div>
                        <Divider />
                        <MenuItem
                            sx={{ fontSize: '14px', fontWeight: 500, color: '#37A372', py: 0.5 }}
                            onClick={() => {
                                closeMenu();
                                openResolvedmodal(row);
                            }}
                        >
                            {t('tableAction.resolved')}
                        </MenuItem>
                    </div>
                )}
                {risk_current_permission === 'escalator' && row?.report_risk_to && (
                    <div>
                        <Divider />
                        <MenuItem
                            sx={{ fontSize: '14px', fontWeight: 500, color: '#395BA9', py: 0.5 }}
                            onClick={() => {
                                closeMenu();
                                openSuggestionModal(row);
                            }}
                        >
                            {t('tableAction.suggestion')}
                        </MenuItem>
                    </div>
                )}
                {/* {risk_current_permission === 'approval' && (
                    <>
                        <Divider />
                        <MenuItem
                            sx={{ fontSize: '14px', fontWeight: 500, color: '#395BA9', py: 0.2 }}
                            onClick={() => {
                                closeMenu();
                                openApprovedModal(row);
                            }}
                        >
                            {t('tableAction.approved')}
                        </MenuItem>
                    </>
                )} */}
                {risk_current_permission === 'approval' && row?.risk_assessment_status === 'awaiting_approval' && (
                    <div>
                        <Divider />
                        <MenuItem
                            sx={{ fontSize: '14px', fontWeight: 500, color: '#395BA9', py: 0.5 }}
                            onClick={() => {
                                closeMenu();
                                setApprovalType('approve');
                                openApprovedModal(row);
                            }}
                        >
                            {t('tableAction.approved')}
                        </MenuItem>
                    </div>
                )}

                {risk_current_permission === 'approval' && row?.risk_assessment_status === 'awaiting_approval' && (
                    <div>
                        <Divider />
                        <MenuItem
                            sx={{ fontSize: '14px', fontWeight: 500, color: '#FF0000', py: 0.5 }}
                            onClick={() => {
                                closeMenu();
                                setApprovalType('disapprove');
                                openApprovedModal(row);
                            }}
                        >
                            {t('tableAction.dis_approved')}
                        </MenuItem>
                    </div>
                )}
            </Menu>
        </>
    );
};

export default InfrastructureTableActions;
