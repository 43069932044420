import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
// import { useTheme } from '@mui/material/styles';

//redux
import { connect } from 'react-redux';

//core components
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { UpdateMerchantDocument } from 'store/actions/complianceActions';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import { toast } from 'react-toastify';

const ApprovalDeclinePolicyModal = (props) => {
    //props
    const { open, handleClose, type, policy_id, UpdateMerchantDocument } = props;
    const [loading, setLoading] = useState(false);

    const handlePolicyApproval = async () => {
        const body = {
            approved_status: type === 'approve' ? 'approved' : 'declined',
        };
        setLoading(true);
        const res = await UpdateMerchantDocument(body, policy_id);
        setLoading(false);

        if (res?.success) {
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    // const theme = useTheme();
    return (
        <AppCenteredModal open={open} handleClose={handleClose} width={520} height={270}>
            <Box sx={{ my: 1.5, posiiton: 'relaitve' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#475569' }}>
                        {type === 'approve' ? 'Approve policy?' : 'Decline policy?'}
                    </Typography>
                    <Typography
                        sx={{ fontSize: '13px', fontWeight: 400, color: '#64748B', textAlign: 'center', mt: 2 }}
                    >
                        {type === 'approve'
                            ? 'This policy will be utilized by the company as an employee guideline after it is approved.'
                            : 'Declining this policy means it will not be available as a company policy.'}
                    </Typography>
                    <Box
                        sx={{
                            mt: 2,
                            backgroundColor: '#F8FAFC',
                            position: 'absolute',
                            bottom: 20,
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                my: 4,
                            }}
                        >
                            <Button
                                sx={{
                                    textTransform: 'inherit',
                                    color: '#334155',
                                    border: '1px solid #E2E8F0',
                                    borderRadius: 1,
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    '&:hover': {
                                        border: '1px solid #E2E8F0',
                                    },
                                }}
                                variant="outlined"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <AppLoadingButton
                                sx={{
                                    ml: 1.5,
                                    textTransform: 'inherit',
                                    borderRadius: 0.5,
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    backgroundColor: type === 'approve' ? '#37A372' : '#FF5449',
                                    '&:hover': {
                                        backgroundColor: type === 'approve' ? '#37A372' : '#FF5449',
                                    },
                                }}
                                loading={loading}
                                loadingPosition="center"
                                onClick={handlePolicyApproval}
                                variant="contained"
                                text={type === 'approve' ? 'Yes, approve' : 'Yes, decline'}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </AppCenteredModal>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};
export default connect(mapStateToProps, { UpdateMerchantDocument })(ApprovalDeclinePolicyModal);
