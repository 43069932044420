import Clients from 'views/implementer/Clients/Clients';
import { ReactComponent as HomeActiveIcon } from 'assets/img/HomeActive.svg';
import { ReactComponent as HomeInactiveIcon } from 'assets/img/HomeInactive.svg';

const getRoutes = () => {
    const premiumRoutes = [
        {
            path: '/clients',
            name: 'Clients',
            icon: HomeInactiveIcon,
            icon_active: HomeActiveIcon,
            component: Clients,
            layout: '/implementer',
            subRoute: false,
        },
    ];

    const premiumRoutesFr = [
        {
            path: '/clients',
            name: 'Clients',
            icon: HomeInactiveIcon,
            icon_active: HomeActiveIcon,
            component: Clients,
            layout: '/implementer',
            subRoute: false,
        },
    ];

    const language = window.localStorage.i18nextLng;

    if (language?.includes('fr')) {
        return premiumRoutesFr;
    } else return premiumRoutes;
};
export default getRoutes;
