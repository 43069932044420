import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button } from 'antd';

//Redux
import { connect, useDispatch } from 'react-redux';
import { AddThreat } from 'store/actions/adminActions';
import { AddRiskCategory } from 'store/actions/adminActions';

const RiskCategoryModal = (props) => {
    const [loading, setloading] = useState(false);
    const [data, setData] = useState({});
    const { open, handleClose } = props;

    const dispatch = useDispatch();

    const handleTextChange = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value });
    };

    const onClose = () => {
        setData({});
        handleClose();
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setloading(true);
        const payload = {
            name: data?.name,
        };

        const res = await dispatch(AddRiskCategory(payload));
        setloading(false);
        if (res?.success) {
            toast.success('Risk caregory Added Successfully!');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <Modal title="Add Risk Category" open={open} onCancel={onClose} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="name">Risk Category</label>
                        <Input
                            type="text"
                            size="large"
                            name="name"
                            id="name"
                            onChange={handleTextChange}
                            value={data.name || ''}
                            // required
                        />
                    </Col>
                    <Col style={{ marginTop: '5rem' }}>
                        <Button
                            className="ant-btn"
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                            disabled={!data?.name}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { AddThreat })(RiskCategoryModal);
