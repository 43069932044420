import React from 'react';
import { Stack, Typography, Badge } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//translations
// import { useTranslation } from 'react-i18next';
import { QuestionAnswer } from '@mui/icons-material';

const AnswerItem = (props) => {
    const { answer, number, form_answer, formAnswers } = props;
    const theme = useTheme();

    //translation
    // const { t } = useTranslation('compliance');

    return (
        <Stack
            direction="row"
            alignItems="center"
            flex={1}
            gap={1.5}
            sx={{
                pb: 1,
                pt: 2,
                borderBottom:
                    formAnswers?.length === 1 || formAnswers?.[formAnswers?.length - 1] === number - 1
                        ? ''
                        : `1px solid ${theme.palette.neutral[95]}`,
            }}
        >
            <Badge
                sx={{
                    '& .MuiBadge-badge': {
                        right: 'auto',
                        left: '-10px',
                        top: '-3px',
                        background: theme.palette.primary[50],
                        fontSize: 10,
                        justifyContent: 'center',
                        borderRadius: '4px',
                        color: theme.palette.white.main,
                        height: '14px',
                        width: '12px',
                        minWidth: 'unset',
                        p: '0 2px',
                    },
                }}
                badgeContent={number}
            >
                <QuestionAnswer fontSize="18" />
            </Badge>
            <Typography
                sx={{
                    fontSize: 11,
                    fontWeight: 500,
                    letterSpacing: '0.1px',
                    color: theme.palette.neutral.main,
                }}
            >
                {form_answer || answer}
            </Typography>
        </Stack>
        // <Stack direction="row" spacing={2} sx={{ width: '90%' }}>
        //     <Box
        //         sx={{
        //             width: 24,
        //             height: 24,
        //             minWidth: 24,
        //             minHeight: 24,
        //             borderRadius: '50%',
        //             backgroundColor: theme.palette.primary[900],
        //             color: theme.palette.white.main,
        //             display: 'flex',
        //             justifyContent: 'center',
        //             alignItems: 'center',
        //             fontSize: 13,
        //             fontWeight: 700,
        //         }}
        //     >
        //         {number}
        //     </Box>
        //     <Box>
        //         <Typography
        //             sx={{
        //                 fontSize: 13,
        //                 color: theme.palette.gray[800],
        //                 fontWeight: 500,
        //                 marginBottom: 2,
        //             }}
        //         >
        //             {t('question')} {answer}
        //         </Typography>
        //         {form_answer ? (
        //             <Typography
        //                 sx={{
        //                     fontSize: 13,
        //                     color: theme.palette.gray[800],
        //                     fontWeight: 500,
        //                 }}
        //             >
        //                 {t('formAnswer')} {form_answer}
        //             </Typography>
        //         ) : null}
        //     </Box>
        // </Stack>
    );
};

export default AnswerItem;
