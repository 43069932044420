import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Col, Button, Typography, Card, Input, Empty, Space, Modal } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

//react-router-dom
import FieldModal from './FieldModal';

//redux
import { connect } from 'react-redux';
import { GetIntegrationByID, EditIntegrationFields } from 'store/actions/adminActions';

const { Title } = Typography;
const { confirm } = Modal;
const FieldCreation = (props) => {
    const { GetIntegrationByID, EditIntegrationFields, match, fields } = props;
    const [formFields, setFormFields] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [mode, setMode] = useState('');
    const [payload, setPayload] = useState({});

    const openModal = (modalMode, modalPayload) => {
        setModalOpen(true);
        setMode(modalMode);
        setPayload(modalPayload);
    };
    const closeModal = () => {
        setModalOpen(false);
        setMode('');
        setPayload({});
    };

    const deleteField = (name) => {
        const payload = formFields?.filter((field) => field?.name !== name);
        confirm({
            title: 'Are you sure you want to delete this field?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await EditIntegrationFields(match?.params?.id, {
                    fields: payload,
                });
                if (res.success) {
                    toast.success('Field Deleted Successfully');
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    useEffect(() => {
        const getIntegrationByID = async () => {
            const res = await GetIntegrationByID(match?.params?.id);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getIntegrationByID();
    }, [match?.params?.id]);

    useEffect(() => {
        if (fields) {
            setFormFields(fields);
        } else {
            setFormFields([]);
        }
        console.log(fields);
    }, [fields]);
    return (
        <>
            <Row justify="space-between">
                <Col>
                    <Title level={2}>Fields</Title>
                </Col>
                <Col>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => openModal('add')}>
                        Add Field
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {formFields && formFields?.length > 0 ? (
                    <Row gutter={16}>
                        {formFields
                            ?.sort((a, b) => a?.name - b?.name)
                            ?.map((field) => {
                                return (
                                    <Col xs={24} md={12} style={{ marginBottom: '1rem' }} key={field?.name}>
                                        <Card
                                            extra={
                                                <Space>
                                                    <Button
                                                        shape="circle"
                                                        icon={<EditOutlined />}
                                                        onClick={() => openModal('edit', field)}
                                                    />
                                                    <Button
                                                        shape="circle"
                                                        danger
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => deleteField(field?.name)}
                                                    />
                                                </Space>
                                            }
                                        >
                                            <div className="form-group" style={{ marginBottom: '1rem' }}>
                                                <label>
                                                    {field?.name}
                                                    {field?.required && <span style={{ color: 'red' }}>*</span>}
                                                </label>
                                                {field.type === 'text' ||
                                                    (field.type === 'input' && <Input type="text" size="large" />)}
                                            </div>
                                        </Card>
                                    </Col>
                                );
                            })}
                    </Row>
                ) : (
                    <Row justify="center">
                        <Col>
                            <Empty
                                description={
                                    <div>
                                        <h3>No Fields</h3>
                                        <span>Add a new field </span>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                )}
            </section>
            <FieldModal
                open={modalOpen}
                handleClose={closeModal}
                mode={mode}
                payload={payload}
                fields={formFields}
                id={match?.params?.id}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        fields: state?.adminReducers?.integration_by_id?.fields,
    };
};
export default connect(mapStateToProps, {
    GetIntegrationByID,
    EditIntegrationFields,
})(FieldCreation);
