import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, Col, Divider, Modal, Row, Spin, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, ToolOutlined } from '@ant-design/icons';

// redux
import { connect } from 'react-redux';
import { GetAllCiiToolsItems, DeleteCiiToolsItem } from 'store/actions/adminActions';
import EmptyComponent from 'components/EmptyComponents/EmptyComponent';
import { SCAN_CATEGORY } from '../db';
import ToolsModal from './ToolsModal';

const { Title } = Typography;
const { confirm } = Modal;

const Tools = (props) => {
    // props
    const { GetAllCiiToolsItems, all_tools_items, subsector, DeleteCiiToolsItem, openModal, modal, closeModal, data } =
        props;

    // state
    const [loading, setLoading] = useState({});
    // memos
    const toolsItemsBySubcriteria = useMemo(() => {
        return all_tools_items?.filter((item) => item?.cii_subsector === subsector?.id);
    }, [all_tools_items]);

    // functions
    const getToolsItems = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAllCiiToolsItems(subsector?.id);
        setLoading({ ...loading, content: false });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const deleteModal = (item) => {
        confirm({
            title: 'Do you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await DeleteCiiToolsItem(item?.id);
                if (res.success) {
                    toast.success('Item Deleted Successfully');
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    // useEffect
    useEffect(() => {
        getToolsItems();
    }, []);
    return (
        <div>
            <Divider orientation="left">
                <span className="flex items-center space-x-8">
                    <ToolOutlined /> Tools
                </span>
            </Divider>
            {loading.content ? (
                <Row justify="center">
                    <Col>
                        <Spin size="large" />
                    </Col>
                </Row>
            ) : (
                <Row gutter={16}>
                    {toolsItemsBySubcriteria?.length ? (
                        toolsItemsBySubcriteria?.map((item) => {
                            return (
                                <Col xs={24} sm={12} md={8} lg={6} className="mb-4" key={item?.id}>
                                    <Card
                                        actions={[
                                            <EditOutlined
                                                key="edit"
                                                title="Edit this item"
                                                onClick={() => openModal('Edit', item)}
                                            />,
                                            <DeleteOutlined
                                                key="delete"
                                                title="Delete this item"
                                                onClick={() => deleteModal(item)}
                                            />,
                                        ]}
                                        title={item.title}
                                    >
                                        <Title level={5} className="capitalize">
                                            {item.category} {SCAN_CATEGORY.includes(item.category) && '(scans)'}
                                        </Title>
                                    </Card>
                                </Col>
                            );
                        })
                    ) : (
                        <div className="w-full">
                            <EmptyComponent text="No tools items" />
                        </div>
                    )}
                </Row>
            )}
            <ToolsModal open={modal} handleClose={closeModal} modalData={data} subsector={subsector} />
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_tools_items: state?.adminReducers?.all_cii_tools_items,
});
export default connect(mapStateToProps, { GetAllCiiToolsItems, DeleteCiiToolsItem })(Tools);
