import * as types from '../constants';
import axiosInstance from '../../utils/https';

export const GetAllCategories = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/category/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.ALL_CATEGORIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateCategory = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/category/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditCategory = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/category/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteCategory = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/category/${id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCategories());
        return {
            success: true,
            message: 'Deleted Successful.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllCategoryMetrics = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/ndpr_get_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: types.ALL_CATEGORY_METRICS, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAllDocumentsByCategory = (category_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/documents/?category=${category_id}&tags=ndpr`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: types.ALL_NDPR_DOCUMENTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllFormsByCategory = (category) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/forms/?category=${category}&tags=ndpr`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: types.ALL_ADMIN_NDPR_FORMS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
