import { Box, Button, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// core components
import AppCenteredModal from './AppCenteredModalNew';
import AppLoadingButton from './AppLoadingButton';

const AppDeleteModalNew = (props) => {
    const { open, handleClose, deleting, onDelete, deleteText, title, subtitle, width } = props;
    const theme = useTheme();
    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            width={width || 450}
            actions={
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mt: 3, mb: 1.5, gap: 1 }}>
                    <Button
                        variant="text"
                        sx={{
                            backgroundColor: '#FFFFFF !important',
                            color: '#334155 !important',
                            textTransform: 'inherit !Important',
                            border: '1px solid #E2E8F0 !important',
                            px: 2,
                            py: 1,
                            borderRadius: 1,
                            '&:hover': {
                                backgroundColor: '#FFFFFF !important',
                            },
                        }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                    <AppLoadingButton
                        text={deleteText || 'Yes, Delete'}
                        variant="contained"
                        loading={deleting}
                        loadingPosition="center"
                        onClick={onDelete}
                        sx={{
                            backgroundColor: '#F43F5E',
                            color: '#FFFFFF !important',
                            textTransform: 'inherit',
                            '&:hover': {
                                backgroundColor: '#F43F5E',
                            },
                        }}
                    />
                </Stack>
            }
        >
            <Box
                sx={{
                    fontWeight: 500,
                    color: theme.palette.gray[800],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    // mt: 2,
                    mt: 0.5,
                }}
            >
                <span style={{ color: '#475569', fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>
                    {title ? title : 'Delete Record?'}
                </span>
                {subtitle ? (
                    subtitle
                ) : (
                    <p
                        style={{
                            color: '#64748B',
                            fontSize: '13px',
                            fontWeight: 400,
                            marginTop: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        Deleting record is irreversible.
                        <br />
                        Are you sure you want to go ahead?
                    </p>
                )}
            </Box>
        </AppCenteredModal>
    );
};

export default AppDeleteModalNew;
