import React, { useState, useEffect } from 'react';
//core components
import ScanGroups from './Components/ScanGroups';
import ScansView from './Components/ScansView';
import CircularLoader from 'components/Loaders/CircularLoader';

// redux
import { connect } from 'react-redux';
import { GetExemptedScans, GetAllExceptions } from 'store/actions/automatedScansActions';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';

const Exceptions = (props) => {
    const { all_scan_exceptions, GetAllExceptions, view, changeView, category, setCategory } = props;

    // state
    const [loading, setLoading] = useState(false);
    // functions

    const getAllScansExceptions = async () => {
        setLoading(true);
        const res = await GetAllExceptions();
        setLoading(false);
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        getAllScansExceptions();
        return () => {
            setLoading(false);
        };
    }, []);

    const handleChangeView = (viewType, category) => {
        changeView(viewType);
        setCategory(category);
    };

    return (
        <>
            {loading ? (
                <CircularLoader />
            ) : (
                <Box sx={{ height: 'calc(100vh - 150px)' }}>
                    {view === 'groups' ? (
                        <ScanGroups handleChangeView={handleChangeView} groups={all_scan_exceptions} isScan={false} />
                    ) : (
                        <ScansView
                            goBack={handleChangeView}
                            category={category}
                            isScan={false}
                            view={view}
                            loading={loading}
                        />
                    )}
                </Box>
            )}
            ,
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_scan_exceptions: state?.automatedScansReducers?.all_scan_exceptions,
        all_exempted_scans: state?.automatedScansReducers?.all_exempted_scans,
    };
};
export default connect(mapStateToProps, { GetExemptedScans, GetAllExceptions })(Exceptions);
