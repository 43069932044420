import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { numExtraction } from 'utils';
import { Link } from 'react-router-dom';

// lib
import { Row, Col, Button, Table, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// core components
import BreadCrumb from 'components/Breadcrumb';
import SubcontrolsModal from './Soc2Actions/SubcontrolsModal';
import SubcontrolOperations from './Soc2Actions/SubcontrolOperations';

// redux
import { connect } from 'react-redux';
import { CreateControl, EditControl, DeleteControl } from 'store/actions/adminActions';

// subcomponents
const { confirm } = Modal;

const SubcontrolsView = (props) => {
    const { location, all_controls, CreateControl, EditControl, DeleteControl } = props;
    const [modal, setModal] = useState(false);
    const [control, setControl] = useState({});
    const [subcontrol, setSubcontrol] = useState({});

    const openModal = (mode, data) => {
        setModal(mode);
        setSubcontrol(mode === 'Edit' ? data : {});
    };
    const closeModal = () => {
        setModal(false);
        setSubcontrol(null);
    };
    const deleteSubcontrol = (id) => {
        confirm({
            title: 'Are you sure you want to delete this subcontrol? Note: Deleting this subcontrol means you will be deleting this subcontrol, the documents, audit questions and merchant data associated with this subcontrol',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteControl(id, true);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    // data
    const columns = [
        {
            title: 'Title',
            render: (record) => (
                <Link
                    to={{
                        pathname: '/admin/soc2/subcontrols/items',
                        state: { subcontrol: record },
                    }}
                >
                    {record.title}
                </Link>
            ),
            sorter: (a, b) => numExtraction(a.title) - numExtraction(b.title),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Description',
            render: (record) => record.description,
            width: '70%',
        },
        {
            title: 'Actions',
            align: 'right',
            render: (record) => (
                <SubcontrolOperations
                    openEditModal={openModal}
                    subcontrol={record}
                    deleteSubcontrol={deleteSubcontrol}
                    control={control}
                    // parent_req_num={location?.state?.req_num}
                />
            ),
        },
    ];

    // useEffect
    useEffect(() => {
        const { control_id } = location.state || {};
        const one_control = all_controls?.find((control) => control?.id === control_id);
        setControl(one_control);
    }, [all_controls, location?.state]);
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'SOC2', link: '/soc2' }, { label: control?.title }]} />
                </Col>

                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal('Add')}
                    >
                        Add Subcontrol
                    </Button>
                </Col>
            </Row>

            <section className="my-3">
                <Table columns={columns} dataSource={control?.sub_controls} rowKey={(sub_control) => sub_control?.id} />
            </section>
            <SubcontrolsModal
                open={modal}
                handleCancel={closeModal}
                parent_control_id={control?.id}
                one_subcontrol={subcontrol}
                CreateControl={CreateControl}
                EditControl={EditControl}
            />
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        all_controls: state?.adminReducers?.all_controls,
    };
};
export default connect(mapStateToProps, {
    CreateControl,
    EditControl,
    DeleteControl,
})(SubcontrolsView);
