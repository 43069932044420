/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import { Typography, Button, Box, MenuItem, Menu } from '@mui/material';

import { truncateText } from 'utils';
import { Progress } from 'antd';
import { MoreHoriz } from '@mui/icons-material';
import EvidencesDetailsModal from './EvidencesDetailsModal';
import { getType } from 'utils';
import { useTheme } from '@mui/styles';

const DescriptionLayerNew = (props) => {
    const {
        description,
        percentCompletion,
        // type,
        // subCategory,
        label,
        standard,
        openDescriptionDrawer,
        resource,
        category,
        isTaskAssignedToSelectSubRequirement,
        openTaskAssignModal,
        title,
        subgroup_evidences,
        user_subgroup_evidences,
        type_,
    } = props;
    const [anchor, setAnchor] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const role = getType('role');
    const theme = useTheme();

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const totalEvidences = subgroup_evidences
        ?.map((item) => item?.segments)
        ?.flat(1)
        ?.map((itm) =>
            Object?.keys(itm)?.reduce((prev, current) => {
                const reduced_items = prev + itm[current];
                return reduced_items;
            }, 0)
        )
        ?.reduce((prev, current) => {
            const reduced_items = prev + current;
            return reduced_items;
        }, 0);

    const user_docs = user_subgroup_evidences
        ?.map((item) => {
            return item?.data?.map((itm, idx) => {
                return itm?.[`segment_${idx + 1}`]?.map((dat) => dat);
            });
        })
        ?.flat(1)
        .flat(1);

    const expectedEvidences = subgroup_evidences
        ?.map((item) => (type_ === 'soc2' ? item?.segments[0] : item?.segments))
        ?.flat(1)
        ?.map((itm) =>
            Object?.keys(itm)?.reduce((prev, current) => {
                const reduced_items = prev + itm[current];
                return reduced_items;
            }, 0)
        )
        ?.reduce((prev, current) => {
            const reduced_items = prev + current;
            return reduced_items;
        }, 0);
    return (
        <Box
            sx={{
                p: 2,
                backgroundColor: '#F8FAFC',
                borderRadius: 1,
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '70%' }} gap={1}>
                <Progress
                    type="circle"
                    strokeLinecap="butt"
                    width={33}
                    percent={Math.round(percentCompletion)}
                    strokeWidth={12}
                    strokeColor={'#0E2C66'}
                />
                <Box sx={{ ml: 1 }}>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: 14,
                            color: '#64748B',
                        }}
                    >
                        {title}-{label}
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: 14,
                            color: '#64748B',
                        }}
                    >
                        Controls:
                    </Typography>

                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: 14,
                            color: '#64748B',
                        }}
                    >
                        {description?.length > 300 ? (
                            <>
                                {truncateText(description, '250')}{' '}
                                <Button
                                    variant="text"
                                    sx={{
                                        textDecoration: 'underline',
                                        color: '#5474C4',
                                        textTransform: 'none',
                                        minWidth: 'unset',
                                        p: 0,
                                    }}
                                    onClick={openDescriptionDrawer}
                                >
                                    see more...
                                </Button>{' '}
                            </>
                        ) : (
                            description
                        )}
                    </Typography>
                </Box>
            </Box>
            {resource === 'doc' ? (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    {/* {totalEvidences > 0 && (
                        <Button
                            variant="outlined"
                            sx={{
                                textTransform: 'inherit',
                                px: 0.8,
                                py: 0.5,
                                color: '#395BA9',
                                fontWeight: 600,
                                fontSize: '13px',
                            }}
                            onClick={handleOpenModal}
                        >
                            Evidences:: {user_docs?.length}/{type_ === 'soc2' ? expectedEvidences : totalEvidences}
                        </Button>
                    )} */}
                    {!(
                        standard === 'ndpr' ||
                        standard === 'kdpa' ||
                        standard === 'gdpa' ||
                        standard === 'iso27001' ||
                        standard === 'iso22301'
                    ) && role !== 'auditor' ? (
                        <Box>
                            {isTaskAssignedToSelectSubRequirement?.length ? (
                                <Button
                                    sx={{
                                        color: theme.palette.white.main,
                                        background: theme.palette.tertiary[70],
                                        fontSize: 9,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        p: 0.5,
                                        fontWeight: 500,
                                        borderRadius: 1,
                                        textTransform: 'inherit',
                                        ml: 2,
                                        cursor: 'not-allowed',
                                        '&:hover': {
                                            color: theme.palette.white.main,
                                            background: theme.palette.tertiary[70],
                                        },
                                    }}
                                >
                                    Assigned to you
                                </Button>
                            ) : (
                                <Box
                                    sx={{
                                        border: '1px solid #E1E2EC',
                                        borderRadius: 0.5,
                                        ml: 2,
                                        p: 0.3,
                                        cursor: 'pointer',
                                    }}
                                    onClick={openMenu}
                                >
                                    <MoreHoriz />
                                </Box>
                            )}
                            <Menu
                                open={Boolean(anchor)}
                                anchorEl={anchor}
                                onClose={closeMenu}
                                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                            >
                                <MenuItem
                                    disabled={isTaskAssignedToSelectSubRequirement}
                                    sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B' }}
                                    onClick={() => {
                                        openTaskAssignModal();
                                        closeMenu();
                                    }}
                                >
                                    Assign to
                                </MenuItem>
                            </Menu>
                        </Box>
                    ) : null}
                </Box>
            ) : (
                <>
                    {isTaskAssignedToSelectSubRequirement?.length ? (
                        <Button
                            sx={{
                                color: theme.palette.white.main,
                                background: theme.palette.tertiary[70],
                                fontSize: 9,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                p: 0.5,
                                fontWeight: 500,
                                borderRadius: 1,
                                textTransform: 'inherit',
                                cursor: 'not-allowed',
                                '&:hover': {
                                    color: theme.palette.white.main,
                                    background: theme.palette.tertiary[70],
                                },
                            }}
                        >
                            Assigned to you
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            sx={{
                                textTransform: 'inherit',
                                px: 0.8,
                                py: 0.5,
                                color: '#475569',
                                fontWeight: 500,
                                fontSize: '13px',
                                border: '1px solid #CBD5E1',
                            }}
                            onClick={openTaskAssignModal}
                        >
                            Assign {title}
                        </Button>
                    )}
                </>
            )}
            <EvidencesDetailsModal
                open={openModal}
                handleClose={handleCloseModal}
                title={title}
                subgroup_evidences={subgroup_evidences}
                evidence_type={type_}
                category={category}
                standard={standard}
            />
        </Box>
    );
};

export default DescriptionLayerNew;
