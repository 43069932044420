import React from 'react';
//components
import { Drawer, Stack, Button, Box, Typography } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useTheme } from '@mui/styles';

const PentestDrawer = ({ open, handleClose, children, title }) => {
    const theme = useTheme();
    return (
        <Drawer
            variant="temporary"
            anchor="right"
            open={open}
            onClose={handleClose}
            sx={{
                width: '100%',
                overflowX: 'hidden',
                [`& .MuiDrawer-paper`]: {
                    width: { lg: '70%', xs: '95%' },
                },
            }}
        >
            <Box sx={{ borderBottom: `0.5px solid ${theme.palette.primary.main}` }}>
                <Stack sx={{ width: '50%' }} direction="row" justifyContent="space-between" alignItems="center">
                    <Button
                        sx={{
                            display: 'flex',
                            backgroundColor: '#DAE2FF',
                            color: '#455A6472',
                            fontSize: '16px',
                            width: 'fit-content',
                            minWidth: 'unset',
                            borderRadius: '50%',
                            // px: 0.5,
                            ml: 1,
                            justifyContent: 'center',
                            alignitems: 'center',
                        }}
                        onClick={handleClose}
                    >
                        <KeyboardArrowLeft />
                    </Button>
                    <Typography sx={{ fontSize: '18px', fontWeight: 700, py: 1.5, color: theme.palette.gray[900] }}>
                        {title}
                    </Typography>
                </Stack>
            </Box>

            {children}
        </Drawer>
    );
};

export default PentestDrawer;
