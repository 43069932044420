import React, { useState, useEffect } from 'react';
import BreadCrumb from 'components/Breadcrumb';
import { toast } from 'react-toastify';
import { truncateText } from 'utils';

import { Table, Button, Modal } from 'antd';
import { PushNotificationsOperations } from './operations/PushNotificationsOperations';
import { SendOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

//redux
import { connect } from 'react-redux';
import { GetAllPushNotifications, DeletePushNotification, GetAllUsers } from 'store/actions/adminActions';
import PushNotificationModal from './operations/PushNotificationModal';

const PushNotifications = (props) => {
    const [loading, setLoading] = useState({ content: false });
    const [openModal, setOpenModal] = useState(false);
    const [mode, setMode] = useState('');
    const [modalData, setModalData] = useState({});

    const { GetAllPushNotifications, DeletePushNotification, all_push_notifications, GetAllUsers, all_users } = props;

    const columns = [
        {
            title: 'Title',
            render: (record) => record?.title,
        },
        {
            title: 'Body',
            render: (record) => truncateText(record?.body),
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => (
                <PushNotificationsOperations
                    record={record}
                    deleteModal={deleteModal}
                    openEditModal={openMessageModal}
                />
            ),
        },
    ];

    const openMessageModal = (mode = 'add', payload) => {
        setOpenModal(true);
        setMode(mode);
        setModalData(payload);
    };
    const closeMessageModal = () => {
        setOpenModal(false);
    };
    const deleteModal = (message) => {
        Modal.confirm({
            title: 'Do you want to delete this push notification message?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await DeletePushNotification(message?.id);
                if (res?.success) {
                    toast.success('Deleted Successfully');
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };
    useEffect(() => {
        const getAllPushNotifications = async () => {
            setLoading({ ...loading, content: true });
            const res = await GetAllPushNotifications();
            setLoading({ ...loading, content: false });
            if (!res.success) {
                toast.error(res?.message);
            }
        };
        getAllPushNotifications();
    }, []);
    useEffect(() => {
        const getAllUsers = async () => {
            setLoading({ ...loading, data: true });
            const res = await GetAllUsers();
            setLoading({ ...loading, data: false });
            if (!res.success) {
                toast.error(res?.message);
            }
        };
        getAllUsers();
    }, []);

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <BreadCrumb view="Auditors" views={['Dashboard', 'Auditors']} />
                <Button
                    onClick={() => openMessageModal('add')}
                    type="primary"
                    icon={<SendOutlined />}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: '#1677ff',
                    }}
                >
                    Send Push Notifications
                </Button>
            </div>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(all_push_notifications) => all_push_notifications.id}
                    columns={columns}
                    dataSource={all_push_notifications?.sort((a, b) => b?.id - a?.id)}
                    sortDirections="descend"
                    pagination={{
                        total: all_push_notifications?.length,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                    }}
                    loading={loading.content || false}
                />
            </section>
            <PushNotificationModal
                open={openModal}
                handleClose={closeMessageModal}
                all_users={all_users}
                mode={mode}
                modalData={modalData}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_push_notifications: state?.adminReducers?.all_push_notifications,
        all_users: state?.adminReducers?.all_users,
    };
};
export default connect(mapStateToProps, { GetAllPushNotifications, DeletePushNotification, GetAllUsers })(
    PushNotifications
);
