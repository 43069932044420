import React from 'react';
import { Switch, Route } from 'react-router-dom';
import FormCreation from '../Operations/FormCreation';
import FormsAndDocumentsView from './FormsAndDocumentsView';
import Soc2Dashboard from './PCISLCDocument';
import SubcontrolsView from './SubcontrolsView';

const PCISLC = () => {
    return (
        <Switch>
            <Route path="/admin/pcislc" exact component={Soc2Dashboard} />
            <Route path="/admin/pcislc/subobjective" exact component={SubcontrolsView} />
            <Route path="/admin/pcislc/subobjective/items" exact component={FormsAndDocumentsView} />
            <Route
                path="/admin/pcislc/subobjective/items/form"
                component={(props) => <FormCreation tag="sslc" {...props} />}
            />
        </Switch>
    );
};

export default PCISLC;
