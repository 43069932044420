import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Logo } from 'assets/img/brand/logo.svg';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import AutomatedScans from './AutomatedScans_';
import { Divider } from '@mui/material';
import Employees from './Employees_';
import PolygonMedium from 'assets/img/medium_polygon.png';
import PolygonLow from 'assets/img/ciso_positive_angle.png';
import PolygonHigh from 'assets/img/LowPolygon.svg';
import { EmployeesComputers, EmployeesOnboarding, EmployeesOnboarding2, EmployeesTraining } from './EmployeesTable_';
import InverntoryScan from './InventoryScan_';
import {
    InventoryScansAPICompliance,
    InventoryScansCoddebase,
    InventoryScansPci,
    InventoryScansPentest,
} from './InventoryScansTable_';
import RiskBy3 from './RiskBy3_';
import RiskBy5 from './RiskBy5_';
import ThirdPartyChart from './ThirdPartyChart_';
import {
    InverntoryScanActingAsVendor,
    InverntoryScanAml,
    InverntoryScanTransactionmonitoring,
    InverntoryScanVendorMangement,
} from './ThirdPartyTable_';
import moment from 'moment';
import StandardSummary from './StandardSummary';

function SpoolReportPrint({
    open,
    standards,
    ciso_percentage_ciso_summary,
    automated_scans,
    view,
    inventory_options,
    third_party_options,
    employees_options,
    risk_options,
    setItemLength,
    filterYear,
}) {
    const all_automated_scan_group = useSelector((state) => state?.automatedScansReducers?.all_automated_scan_group);
    const {
        ndpr_ciso_summary,
        all_pcislc_table_ciso_summary,
        pcidss_ciso_summary,
        soc2_ciso_summary,
        pcidss4_ciso_summary,
        kdpa_ciso_summary,
        iso_9001_ciso_summary,
        iso_ciso_summary,
        iso_two_ciso_summary,
        tech_sec_ciso_summary,
        iso22301_summary,
        gdpa_ciso_summary,
        framework_percentage_ciso_summary,
        all_automated_scan_summary,
        all_third_party_summary,
        all_pentest_summary,
        pci_scan_summary,
        api_security_scan_summary,
        source_code_scan_summary,
        people_summary,
        people_scan_summary,
        all_risk_assessment_summary,
        people_info_summary,
    } = useSelector((state) => state?.CISOReducers);
    const scorecard = ciso_percentage_ciso_summary?.total_percentage?.toFixed(2);
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);
    const company_info = useSelector((state) => state?.generalReducers?.user_info?.organization);

    const [items, setItems] = useState([]);

    const date_joined = useSelector((state) => state?.authReducers?.user_details?.user?.date_joined) ?? null;
    const yearUserSignedUp = date_joined ? moment(date_joined)?.year() : moment()?.year();

    const filtered_risk_by_3 = all_risk_assessment_summary
        ?.filter(
            (risk) =>
                risk?.inherent_risk_level_slug !== 'NULL' &&
                risk?.inherent_risk_level_slug !== 'low' &&
                risk?.residual_risk_level_slug !== 'NULL' &&
                risk?.residual_risk_level_slug !== 'low'
        )
        ?.filter((item) => item?.matrix === '3x3');

    const filtered_risk_by_5 = all_risk_assessment_summary
        ?.filter(
            (risk) =>
                risk?.inherent_risk_level_slug !== 'NULL' &&
                risk?.inherent_risk_level_slug !== 'low' &&
                risk?.residual_risk_level_slug !== 'NULL' &&
                risk?.residual_risk_level_slug !== 'low'
        )
        ?.filter((item) => item?.matrix === '5x5');
    const pdfPages = [
        <section className="relative h-screen p-5 rounded-md mb-4" style={{ zIndex: 1 }} hidden={false}>
            <div className="absolute top-10 left-10">
                <Logo className="w-[180px]" />
            </div>

            <div className="absolute top-1/2 text-center left-1/2 -translate-y-1/2 -translate-x-1/2 transform w-full">
                <h2 className="text-[#334155] font-bold text-[36px] w-full">CISO REPORT AND COMPLIANCE HEALTH</h2>

                {filterYear === yearUserSignedUp ? (
                    <p className="text-[#475569] font-medium text-xl mt-9">
                        {moment(merchant_info?.date_created).format('MMMM Do, YYYY')} - December 31, {yearUserSignedUp}
                    </p>
                ) : filterYear === JSON?.parse(moment(new Date()).format('YYYY')) ? (
                    <p className="text-[#475569] font-medium text-xl mt-9">
                        January 1, {filterYear} - {moment(new Date()).format('MMMM Do, YYYY')}
                    </p>
                ) : filterYear !== 'All' ? (
                    <p className="text-[#475569] font-medium text-xl mt-9">
                        January 1, {filterYear} - December 31, {filterYear}
                    </p>
                ) : (
                    <p className="text-[#475569] font-medium text-xl mt-9">
                        {moment(merchant_info?.date_created).format('MMMM Do, YYYY')} -{' '}
                        {moment(new Date()).format('MMMM Do, YYYY')}
                    </p>
                )}
                <h3 className="mt-20 text-[#475569] font-semibold text-[28px]">{merchant_info?.name}</h3>
            </div>
        </section>,
        <section className="relative p-5 rounded-md" hidden={false}>
            <h4 className="text-[#2B3674] text-[22px] font-bold text-center">Summary of the Report</h4>
            <div className="mt-[2rem] w-full">
                <div className="flex justify-center items-center flex-col mb-[3rem]" hidden={view === 'Report View'}>
                    <h6 className="text-[#2B3674] text-lg font-bold text-center">Compliance Readiness</h6>
                    <p className="text-gray-500 text-xs font-normal text-center">
                        This measures your overall compliance readiness for all processes in place
                    </p>
                    <div className="relative h-[180px] w-full mt-5 flex justify-center items-center">
                        <div className="absolute top-0 left-[40%]">
                            <div className="absolute w-[140px] h-[140px] rounded-full bg-[#E2E8F0]">
                                <div
                                    className={`absolute rounded-full w-[91px] h-[91px] border-[76px] border-transparent rotate-45 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform
                                    ${
                                        scorecard > 70
                                            ? 'border-y-[#55BE8B] border-x-[#55BE8B]'
                                            : scorecard > 69
                                            ? 'border-y-[#55BE8B] border-r-[#55BE8B]'
                                            : scorecard > 50
                                            ? 'border-t-[#F2C021] border-r-[#F2C021]'
                                            : scorecard > 49
                                            ? 'border-t-[#FF5449] border-r-[#FF5449]'
                                            : 'border-t-[#FF5449]'
                                    }`}
                                >
                                    <div className=" rounded-full w-[91px] h-[91px] flex justify-center items-center -translate-y-1/2 -translate-x-1/2 transform -rotate-45">
                                        <p className="text-[#202D66] font-bold text-[34px]">{Math.round(scorecard)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-5">
                        <div className="flex items-center gap-2">
                            <div className="bg-[#FF5449] h-[16px] w-[16px] rounded-sm" />

                            <p className="text-[#64748B] font-medium text-[13px] whitespace-nowrap mt-[-0.8rem]">
                                0 - 49
                            </p>
                        </div>

                        <div className="flex items-center gap-2">
                            <div className="bg-[#F2C021] h-[16px] w-[16px] rounded-sm" />

                            <p className="text-[#64748B] font-medium text-[13px] whitespace-nowrap mt-[-0.8rem]">
                                50 - 70
                            </p>
                        </div>

                        <div className="flex items-center gap-2">
                            <div className="bg-[#55BE8B] h-[16px] w-[16px] rounded-sm" />

                            <p className="text-[#64748B] font-medium text-[13px] whitespace-nowrap mt-[-0.8rem]">
                                71 - 100
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="border border-[#F1F5F9] rounded py-2" hidden={automated_scans?.length === 0}>
                        <div className="flex justify-between items-center px-3">
                            <h4 className="text-[#2B3674] text-base font-bold">Automated Scans</h4>
                            <div className="flex items-center gap-1">
                                <FiberManualRecordIcon
                                    sx={{
                                        color:
                                            all_automated_scan_summary?.percent_passed < 50
                                                ? '#FF5449'
                                                : all_automated_scan_summary?.percent_passed >= 50 &&
                                                  all_automated_scan_summary?.percent_passed < 70
                                                ? '#F2C021'
                                                : '#55BE8B',
                                        width: 12,
                                        height: 12,
                                    }}
                                />
                                <p className="text-gray-600 text-xs font-medium mt-[-0.8rem]">
                                    {all_automated_scan_summary?.percent_passed?.toFixed(2)}%
                                </p>
                            </div>
                        </div>
                        {all_automated_scan_group?.map((option, index) => {
                            return (
                                automated_scans?.includes(option?.name) && (
                                    <div
                                        className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center"
                                        key={index}
                                    >
                                        <div className="flex items-center gap-1">
                                            {option.passed === option?.total && option?.total !== 0 ? (
                                                <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                                            ) : (
                                                <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                                            )}
                                            <p className="text-gray-600 text-xs font-normal capitalize mt-[-0.8rem]">
                                                {option.name}
                                            </p>
                                        </div>
                                        <p className="border border-[#E2E8F0] px-1 pb-[1rem] text-gray-500 text-xs font-medium mt-[-0.8rem]">
                                            {option.passed} /{option.total}
                                        </p>
                                    </div>
                                )
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>,
        <section className="relative p-5 rounded-md" hidden={standards?.length === 0}>
            <div className="border border-[#F1F5F9] rounded py-2 mt-[1.5rem]">
                <div className="flex justify-between items-center px-3 mb-[3rem]">
                    <h4 className="text-[#2B3674] text-base font-bold">Frameworks</h4>
                    <div className="flex items-center gap-1">
                        <FiberManualRecordIcon sx={{ color: '#55BE8B', width: 12, height: 12 }} />
                        <p className="text-gray-600 text-xs font-medium pt-[-2rem]">
                            {framework_percentage_ciso_summary?.total_compliance_percentage?.toFixed(2)}%
                        </p>
                    </div>
                </div>
                <StandardSummary standards={standards} />
            </div>
        </section>,
        <section
            className="relative p-3 rounded-md"
            hidden={view === 'Graphical Presentation' || automated_scans?.length === 0}
        >
            <div className="mt-[5rem]">
                <h4 className="text-[#2B3674] text-[22px] font-bold text-center">Details of the report</h4>
                <div className="flex items-center gap-4 mt-[5rem]">
                    <h4 className="text-[#2B3674] text-lg font-bold text-center">Automated Scans</h4>
                    <div className="flex items-center gap-1 border border-[#E2E8F0] p-1.5 rounded mt-[0.8rem]">
                        <img
                            src={
                                all_automated_scan_summary?.percent_passed < 50
                                    ? PolygonHigh
                                    : all_automated_scan_summary?.percent_passed >= 50 &&
                                      all_automated_scan_summary?.percent_passed <= 70
                                    ? PolygonMedium
                                    : PolygonLow
                            }
                            alt="PolygonMedium"
                        />
                        <p className="text-gray-500 text-sm font-semibold mt-[-1rem]">
                            {all_automated_scan_summary?.percent_passed?.toFixed(2)}%
                        </p>
                    </div>
                    <div className="w-[74%] mt-[1.2rem]">
                        <Divider orientation="horizontal" sx={{ backgroundColor: '#E2E8F0' }} />
                    </div>
                </div>
                <div className="mt-[3rem]">
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">Automated Scans</p>
                    </div>
                    <table className="w-full w-full">
                        <thead className="w-full">
                            <tr>
                                <th
                                    rowspan="2"
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                >
                                    Constraints{' '}
                                </th>
                                <th
                                    rowspan="2"
                                    className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black"
                                >
                                    Instances
                                </th>
                                <th
                                    colspan="3"
                                    className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black"
                                >
                                    Status
                                </th>
                            </tr>
                            <tr>
                                <td className="text-[#64748B] text-xs  font-semibold p-2 text-center border border-black">
                                    Pass
                                </td>
                                <td className="text-[#64748B] text-xs  font-semibold p-2 text-center border border-black">
                                    Fail
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {all_automated_scan_summary &&
                                Object?.keys(all_automated_scan_summary)?.length &&
                                Object?.keys(all_automated_scan_summary)
                                    ?.slice(0, 3)
                                    ?.map((option, optionIndex) => {
                                        return (
                                            all_automated_scan_summary[option]?.length > 0 && (
                                                <React.Fragment key={optionIndex}>
                                                    {all_automated_scan_summary[option]?.map((sub, subIndex) => (
                                                        <React.Fragment key={subIndex}>
                                                            <tr>
                                                                {subIndex === 0 && (
                                                                    <td
                                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                                        rowSpan={
                                                                            all_automated_scan_summary[option]?.length *
                                                                            2
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </td>
                                                                )}
                                                                <td
                                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black capitalize"
                                                                    rowSpan="2"
                                                                >
                                                                    {sub?.name?.replace(/_/g, ' ')}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    className="text-[#55BE8B] text-xs  font-medium p-2 text-center border border-black"
                                                                    rowSpan="2"
                                                                >
                                                                    {sub?.passed} Passed
                                                                </td>
                                                                <td className="text-[#FF5449] text-xs  font-medium p-2 text-center border border-black">
                                                                    {sub.failed} Failed
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))}
                                                </React.Fragment>
                                            )
                                        );
                                    })}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>,
        // <section
        //     className="relative p-3 rounded-md"
        //     hidden={
        //         view === 'Graphical Presentation' ||
        //         automated_scans?.length === 0 ||
        //         (Object?.keys(all_automated_scan_summary)?.length &&
        //             Object?.keys(all_automated_scan_summary)?.slice(2, 4)?.length === 0)
        //     }
        // >
        //     <div className="mt-[5rem]">
        //         <div className="mt-[3rem]">
        //             <table className="w-full w-full">
        //                 <thead className="w-full">
        //                     <tr>
        //                         <th
        //                             rowspan="2"
        //                             className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
        //                         >
        //                             Constraints{' '}
        //                         </th>
        //                         <th
        //                             rowspan="2"
        //                             className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black"
        //                         >
        //                             Instances
        //                         </th>
        //                         <th
        //                             colspan="3"
        //                             className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black"
        //                         >
        //                             Status
        //                         </th>
        //                     </tr>
        //                     <tr>
        //                         <td className="text-[#64748B] text-xs  font-semibold p-2 text-center border border-black">
        //                             Pass
        //                         </td>
        //                         <td className="text-[#64748B] text-xs  font-semibold p-2 text-center border border-black">
        //                             Fail
        //                         </td>
        //                     </tr>
        //                 </thead>
        //                 <tbody>
        //                     {all_automated_scan_summary &&
        //                         Object?.keys(all_automated_scan_summary)?.length &&
        //                         Object?.keys(all_automated_scan_summary)
        //                             ?.slice(2, 4)
        //                             ?.map((option, optionIndex) => {
        //                                 return (
        //                                     all_automated_scan_summary[option]?.length > 0 && (
        //                                         <React.Fragment key={optionIndex}>
        //                                             {all_automated_scan_summary[option]?.map((sub, subIndex) => (
        //                                                 <React.Fragment key={subIndex}>
        //                                                     <tr>
        //                                                         {subIndex === 0 && (
        //                                                             <td
        //                                                                 className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
        //                                                                 rowSpan={
        //                                                                     all_automated_scan_summary[option]?.length *
        //                                                                     2
        //                                                                 }
        //                                                             >
        //                                                                 {option}
        //                                                             </td>
        //                                                         )}
        //                                                         <td
        //                                                             className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black capitalize"
        //                                                             rowSpan="2"
        //                                                         >
        //                                                             {sub?.name?.replace(/_/g, ' ')}
        //                                                         </td>
        //                                                     </tr>
        //                                                     <tr>
        //                                                         <td
        //                                                             className="text-[#55BE8B] text-xs  font-medium p-2 text-center border border-black"
        //                                                             rowSpan="2"
        //                                                         >
        //                                                             {sub?.passed} Passed
        //                                                         </td>
        //                                                         <td className="text-[#FF5449] text-xs  font-medium p-2 text-center border border-black">
        //                                                             {sub.failed} Failed
        //                                                         </td>
        //                                                     </tr>
        //                                                 </React.Fragment>
        //                                             ))}
        //                                         </React.Fragment>
        //                                     )
        //                                 );
        //                             })}
        //                 </tbody>
        //             </table>
        //         </div>
        //     </div>
        // </section>,

        // <section
        //     className="relative p-3 rounded-md"
        //     hidden={
        //         view === 'Graphical Presentation' ||
        //         automated_scans?.length === 0 ||
        //         (Object?.keys(all_automated_scan_summary)?.length &&
        //             Object?.keys(all_automated_scan_summary)?.slice(4, 6)?.length === 0)
        //     }
        // >
        //     <div className="mt-[5rem]">
        //         <div className="mt-[3rem]">
        //             <table className="w-full w-full">
        //                 <thead className="w-full">
        //                     <tr>
        //                         <th
        //                             rowspan="2"
        //                             className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
        //                         >
        //                             Constraints{' '}
        //                         </th>
        //                         <th
        //                             rowspan="2"
        //                             className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black"
        //                         >
        //                             Instances
        //                         </th>
        //                         <th
        //                             colspan="3"
        //                             className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black"
        //                         >
        //                             Status
        //                         </th>
        //                     </tr>
        //                     <tr>
        //                         <td className="text-[#64748B] text-xs  font-semibold p-2 text-center border border-black">
        //                             Pass
        //                         </td>
        //                         <td className="text-[#64748B] text-xs  font-semibold p-2 text-center border border-black">
        //                             Fail
        //                         </td>
        //                     </tr>
        //                 </thead>
        //                 <tbody>
        //                     {all_automated_scan_summary &&
        //                         Object?.keys(all_automated_scan_summary)?.length &&
        //                         Object?.keys(all_automated_scan_summary)
        //                             ?.slice(4, 6)
        //                             ?.map((option, optionIndex) => {
        //                                 return (
        //                                     all_automated_scan_summary[option]?.length > 0 && (
        //                                         <React.Fragment key={optionIndex}>
        //                                             {all_automated_scan_summary[option]?.map((sub, subIndex) => (
        //                                                 <React.Fragment key={subIndex}>
        //                                                     <tr>
        //                                                         {subIndex === 0 && (
        //                                                             <td
        //                                                                 className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
        //                                                                 rowSpan={
        //                                                                     all_automated_scan_summary[option]?.length *
        //                                                                     2
        //                                                                 }
        //                                                             >
        //                                                                 {option}
        //                                                             </td>
        //                                                         )}
        //                                                         <td
        //                                                             className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black capitalize"
        //                                                             rowSpan="2"
        //                                                         >
        //                                                             {sub?.name?.replace(/_/g, ' ')}
        //                                                         </td>
        //                                                     </tr>
        //                                                     <tr>
        //                                                         <td
        //                                                             className="text-[#55BE8B] text-xs  font-medium p-2 text-center border border-black"
        //                                                             rowSpan="2"
        //                                                         >
        //                                                             {sub?.passed} Passed
        //                                                         </td>
        //                                                         <td className="text-[#FF5449] text-xs  font-medium p-2 text-center border border-black">
        //                                                             {sub.failed} Failed
        //                                                         </td>
        //                                                     </tr>
        //                                                 </React.Fragment>
        //                                             ))}
        //                                         </React.Fragment>
        //                                     )
        //                                 );
        //                             })}
        //                 </tbody>
        //             </table>
        //         </div>
        //     </div>
        // </section>,
        <section
            className="relative p-3 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                automated_scans?.length === 0 ||
                (all_automated_scan_summary &&
                    typeof all_automated_scan_summary === 'object' &&
                    Object?.keys(all_automated_scan_summary)?.length &&
                    Object?.keys(all_automated_scan_summary)?.slice(6, 8)?.length === 0)
            }
        >
            <div className="mt-[5rem]">
                <div className="mt-[3rem]">
                    <table className="w-full w-full">
                        <thead className="w-full">
                            <tr>
                                <th
                                    rowspan="2"
                                    className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                >
                                    Constraints{' '}
                                </th>
                                <th
                                    rowspan="2"
                                    className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black"
                                >
                                    Instances
                                </th>
                                <th
                                    colspan="3"
                                    className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black"
                                >
                                    Status
                                </th>
                            </tr>
                            <tr>
                                <td className="text-[#64748B] text-xs  font-semibold p-2 text-center border border-black">
                                    Pass
                                </td>
                                <td className="text-[#64748B] text-xs  font-semibold p-2 text-center border border-black">
                                    Fail
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {all_automated_scan_summary &&
                                Object?.keys(all_automated_scan_summary)?.length &&
                                Object?.keys(all_automated_scan_summary)
                                    ?.slice(6, 8)
                                    ?.map((option, optionIndex) => {
                                        return (
                                            all_automated_scan_summary[option]?.length > 0 && (
                                                <React.Fragment key={optionIndex}>
                                                    {all_automated_scan_summary[option]?.map((sub, subIndex) => (
                                                        <React.Fragment key={subIndex}>
                                                            <tr>
                                                                {subIndex === 0 && (
                                                                    <td
                                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                                        rowSpan={
                                                                            all_automated_scan_summary[option]?.length *
                                                                            2
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </td>
                                                                )}
                                                                <td
                                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black capitalize"
                                                                    rowSpan="2"
                                                                >
                                                                    {sub?.name?.replace(/_/g, ' ')}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    className="text-[#55BE8B] text-xs  font-medium p-2 text-center border border-black"
                                                                    rowSpan="2"
                                                                >
                                                                    {sub?.passed} Passed
                                                                </td>
                                                                <td className="text-[#FF5449] text-xs  font-medium p-2 text-center border border-black">
                                                                    {sub.failed} Failed
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))}
                                                </React.Fragment>
                                            )
                                        );
                                    })}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>,
        <section
            className="relative p-5 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('pcidss')}
        >
            <div className="flex items-center gap-4 mb-[1rem]">
                <h4 className="text-[#2B3674] text-lg font-bold text-center">Frameworks</h4>
                <div className="flex items-center gap-1 border border-[#E2E8F0] p-1.5 rounded mt-[0.5rem]">
                    <img
                        src={
                            framework_percentage_ciso_summary < 50
                                ? PolygonHigh
                                : framework_percentage_ciso_summary >= 50 && framework_percentage_ciso_summary <= 70
                                ? PolygonMedium
                                : PolygonLow
                        }
                        alt="PolygonMedium"
                    />
                    <p className="text-gray-500 text-sm font-semibold mt-[-0.8rem]">
                        {all_automated_scan_summary?.percent_passed?.toFixed(2)}%
                    </p>
                </div>
                <div className="w-[74%] mt-[1rem]">
                    <Divider orientation="horizontal" sx={{ backgroundColor: '#E2E8F0' }} />
                </div>
            </div>
            <div hidden={!standards?.includes('pcidss')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">PCI DSS</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {pcidss_ciso_summary &&
                            (Object?.keys(pcidss_ciso_summary)?.length
                                ? Object?.keys(pcidss_ciso_summary)?.sort((a, b) => a - b)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {pcidss_ciso_summary[option]?.sub_req_info
                                            ?.sort((a, b) => a?.sub_requirement_number - b?.sub_requirement_number)
                                            ?.map((sub, subIndex) => (
                                                <React.Fragment key={subIndex}>
                                                    <tr>
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                                rowSpan={
                                                                    pcidss_ciso_summary[option]?.sub_req_info?.length *
                                                                    2
                                                                }
                                                            >
                                                                {pcidss_ciso_summary[option]?.title}
                                                            </td>
                                                        )}
                                                        <td
                                                            className="text-[#64748B] text-xs  font-normal p-2 text-center border border-black"
                                                            rowSpan="2"
                                                        >
                                                            {sub?.sub_requirement_number}
                                                        </td>
                                                        {subIndex === 0 && (
                                                            <td
                                                                className="text-[#64748B] text-xs  font-normal p-2 text-center border border-black"
                                                                rowSpan={
                                                                    pcidss_ciso_summary[option]?.sub_req_info?.length *
                                                                    2
                                                                }
                                                            >
                                                                {pcidss_ciso_summary[option]?.user_form}/
                                                                {pcidss_ciso_summary[option]?.total_form}
                                                            </td>
                                                        )}
                                                    </tr>

                                                    <tr>
                                                        <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                            {sub?.doc_info?.map((info, index) => (
                                                                <p
                                                                    key={index}
                                                                    className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                        index < sub?.doc_info?.length - 1
                                                                            ? 'border-b'
                                                                            : ''
                                                                    } border-black py-2`}
                                                                >
                                                                    {info?.doc_name || 'N/A'}
                                                                </p>
                                                            ))}
                                                        </td>
                                                        <td
                                                            className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                        >
                                                            {sub?.doc_info?.map((info, index) => (
                                                                <p
                                                                    key={index}
                                                                    className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                        index < sub?.doc_info?.length - 1
                                                                            ? 'border-b'
                                                                            : ''
                                                                    } border-black py-2`}
                                                                    style={{
                                                                        color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                                    }}
                                                                >
                                                                    {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                                </p>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                    </React.Fragment>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-5 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('pcidss')}
        >
            <div hidden={!standards?.includes('pcidss')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {pcidss_ciso_summary &&
                            (Object?.keys(pcidss_ciso_summary)?.length
                                ? Object?.keys(pcidss_ciso_summary)?.slice(3, 5)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {pcidss_ciso_summary[option]?.sub_req_info?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                pcidss_ciso_summary[option]?.sub_req_info?.length * 2
                                                            }
                                                        >
                                                            {pcidss_ciso_summary[option]?.title}
                                                        </td>
                                                    )}
                                                    <td
                                                        className="text-[#64748B] text-xs  font-normal p-2 text-center border border-black"
                                                        rowSpan="2"
                                                    >
                                                        {sub?.sub_requirement_number}
                                                    </td>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-normal p-2 text-center border border-black"
                                                            rowSpan={
                                                                pcidss_ciso_summary[option]?.sub_req_info?.length * 2
                                                            }
                                                        >
                                                            {pcidss_ciso_summary[option]?.user_form}/
                                                            {pcidss_ciso_summary[option]?.total_form}
                                                        </td>
                                                    )}
                                                </tr>

                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                        {sub?.doc_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                    index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                                } border-black py-2`}
                                                            >
                                                                {info?.doc_name || 'N/A'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                    <td
                                                        className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                    >
                                                        {sub?.doc_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                    index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                                } border-black py-2`}
                                                                style={{
                                                                    color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                                }}
                                                            >
                                                                {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-5 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('pcidss')}
        >
            <div hidden={!standards?.includes('pcidss')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {pcidss_ciso_summary &&
                            (Object?.keys(pcidss_ciso_summary)?.length
                                ? Object?.keys(pcidss_ciso_summary)?.slice(5, 7)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {pcidss_ciso_summary[option]?.sub_req_info?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                pcidss_ciso_summary[option]?.sub_req_info?.length * 2
                                                            }
                                                        >
                                                            {pcidss_ciso_summary[option]?.title}
                                                        </td>
                                                    )}
                                                    <td
                                                        className="text-[#64748B] text-xs  font-normal p-2 text-center border border-black"
                                                        rowSpan="2"
                                                    >
                                                        {sub?.sub_requirement_number}
                                                    </td>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-normal p-2 text-center border border-black"
                                                            rowSpan={
                                                                pcidss_ciso_summary[option]?.sub_req_info?.length * 2
                                                            }
                                                        >
                                                            {pcidss_ciso_summary[option]?.user_form}/
                                                            {pcidss_ciso_summary[option]?.total_form}
                                                        </td>
                                                    )}
                                                </tr>

                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                        {sub?.doc_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                    index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                                } border-black py-2`}
                                                            >
                                                                {info?.doc_name || 'N/A'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                    <td
                                                        className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                    >
                                                        {sub?.doc_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                    index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                                } border-black py-2`}
                                                                style={{
                                                                    color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                                }}
                                                            >
                                                                {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-5 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('pcidss')}
        >
            <div hidden={!standards?.includes('pcidss')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {pcidss_ciso_summary &&
                            (Object?.keys(pcidss_ciso_summary)?.length
                                ? Object?.keys(pcidss_ciso_summary)?.slice(7, 10)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {pcidss_ciso_summary[option]?.sub_req_info?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                pcidss_ciso_summary[option]?.sub_req_info?.length * 2
                                                            }
                                                        >
                                                            {pcidss_ciso_summary[option]?.title}
                                                        </td>
                                                    )}
                                                    <td
                                                        className="text-[#64748B] text-xs  font-normal p-2 text-center border border-black"
                                                        rowSpan="2"
                                                    >
                                                        {sub?.sub_requirement_number}
                                                    </td>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-normal p-2 text-center border border-black"
                                                            rowSpan={
                                                                pcidss_ciso_summary[option]?.sub_req_info?.length * 2
                                                            }
                                                        >
                                                            {pcidss_ciso_summary[option]?.user_form}/
                                                            {pcidss_ciso_summary[option]?.total_form}
                                                        </td>
                                                    )}
                                                </tr>

                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                        {sub?.doc_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                    index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                                } border-black py-2`}
                                                            >
                                                                {info?.doc_name || 'N/A'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                    <td
                                                        className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                    >
                                                        {sub?.doc_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                    index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                                } border-black py-2`}
                                                                style={{
                                                                    color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                                }}
                                                            >
                                                                {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-5 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('pcidss')}
        >
            <div hidden={!standards?.includes('pcidss')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {pcidss_ciso_summary &&
                            (Object?.keys(pcidss_ciso_summary)?.length
                                ? Object?.keys(pcidss_ciso_summary)?.slice(10, 12)
                                : []
                            )?.map((option, optionIndex) => {
                                return (
                                    <React.Fragment key={optionIndex}>
                                        {pcidss_ciso_summary[option]?.sub_req_info?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                pcidss_ciso_summary[option]?.sub_req_info?.length * 2
                                                            }
                                                        >
                                                            {pcidss_ciso_summary[option]?.title}
                                                        </td>
                                                    )}
                                                    <td
                                                        className="text-[#64748B] text-xs  font-normal p-2 text-center border border-black"
                                                        rowSpan="2"
                                                    >
                                                        {sub?.sub_requirement_number}
                                                    </td>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-normal p-2 text-center border border-black"
                                                            rowSpan={
                                                                pcidss_ciso_summary[option]?.sub_req_info?.length * 2
                                                            }
                                                        >
                                                            {pcidss_ciso_summary[option]?.user_form}/
                                                            {pcidss_ciso_summary[option]?.total_form}
                                                        </td>
                                                    )}
                                                </tr>

                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                        {sub?.doc_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                    index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                                } border-black py-2`}
                                                            >
                                                                {info?.doc_name || 'N/A'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                    <td
                                                        className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                    >
                                                        {sub?.doc_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                    index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                                } border-black py-2`}
                                                                style={{
                                                                    color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                                }}
                                                            >
                                                                {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-m"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('ndpr')}
        >
            <div hidden={!standards?.includes('ndpr')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">NDPR</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(ndpr_ciso_summary && Object?.keys(ndpr_ciso_summary)?.length
                            ? Object?.keys(ndpr_ciso_summary)
                                  ?.slice(0, 3)
                                  ?.sort((a, b) => a - b)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {ndpr_ciso_summary[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={ndpr_ciso_summary[option]?.doc_info?.length * 2}
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={ndpr_ciso_summary[option]?.doc_info?.length * 2}
                                                    >
                                                        {ndpr_ciso_summary[option]?.user_form}/
                                                        {ndpr_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-m"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('ndpr')}
        >
            <div hidden={!standards?.includes('ndpr')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(ndpr_ciso_summary && Object?.keys(ndpr_ciso_summary)?.length
                            ? Object?.keys(ndpr_ciso_summary)
                                  ?.slice(3, 4)
                                  ?.sort((a, b) => a - b)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {ndpr_ciso_summary[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={ndpr_ciso_summary[option]?.doc_info?.length * 2}
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={ndpr_ciso_summary[option]?.doc_info?.length * 2}
                                                    >
                                                        {ndpr_ciso_summary[option]?.user_form}/
                                                        {ndpr_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(0, 2)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(2, 3)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.slice(0, 4)?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>

                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(2, 3)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.slice(4, 5)?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>

                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>

                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(3, 4)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.slice(0, 2)?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(3, 4)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.slice(2, 4)?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(3, 4)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.slice(4, 5)?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.slice(0, 18)?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.slice(0, 18)?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(3, 4)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.slice(5, 6)?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.slice(0, 18)?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.slice(0, 18)?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        // <section
        //     className="relative p-4 rounded-md"
        //     hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        // >
        //     <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
        //         <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
        //             <thead>
        //                 <tr>
        //                     <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
        //                         Category
        //                     </th>
        //                     <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
        //                         Sub-cat
        //                     </th>
        //                     <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
        //                         Questions
        //                     </th>
        //                     <th
        //                         className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
        //                         colSpan="2"
        //                     >
        //                         Policies
        //                     </th>
        //                     <th
        //                         className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
        //                         colSpan="2"
        //                     >
        //                         Evidences
        //                     </th>
        //                 </tr>
        //                 <tr>
        //                     <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
        //                     <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
        //                     <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
        //                         Status
        //                     </th>
        //                     <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
        //                         Name
        //                     </th>
        //                     <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
        //                         Status
        //                     </th>
        //                     <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
        //                         Name
        //                     </th>
        //                     <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
        //                         Status
        //                     </th>
        //                 </tr>
        //             </thead>
        //             <tbody>
        //                 {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
        //                     ? Object?.keys(soc2_ciso_summary)?.slice(3, 4)
        //                     : []
        //                 )?.map((option, optionIndex) => {
        //                     return (
        //                         <React.Fragment key={optionIndex}>
        //                             {soc2_ciso_summary[option]?.sub_control_info?.slice(5, 6)?.map((sub, subIndex) => (
        //                                 <React.Fragment key={subIndex}>
        //                                     <tr>
        //                                         {subIndex === 0 && (
        //                                             <td
        //                                                 className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
        //                                                 rowSpan={
        //                                                     soc2_ciso_summary[option]?.sub_control_info?.length * 2
        //                                                 }
        //                                             >
        //                                                 {option}
        //                                             </td>
        //                                         )}
        //                                         <td
        //                                             className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
        //                                             rowSpan="2"
        //                                         >
        //                                             {sub?.sub_control_title}
        //                                         </td>
        //                                         {subIndex === 0 && (
        //                                             <td
        //                                                 className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
        //                                                 rowSpan={
        //                                                     soc2_ciso_summary[option]?.sub_control_info?.length * 2
        //                                                 }
        //                                             >
        //                                                 {soc2_ciso_summary[option]?.user_form}/
        //                                                 {soc2_ciso_summary[option]?.total_form}
        //                                             </td>
        //                                         )}
        //                                     </tr>

        //                                     <tr>
        //                                         <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
        //                                             {sub?.doc_info?.map((info, index) => (
        //                                                 <p
        //                                                     key={index}
        //                                                     className={`text-[#64748B] text-xs  font-normal text-center ${
        //                                                         index < sub?.doc_info?.length - 1 ? 'border-b' : ''
        //                                                     } border-black py-2`}
        //                                                 >
        //                                                     {info?.doc_name || 'N/A'}
        //                                                 </p>
        //                                             ))}
        //                                         </td>
        //                                         <td
        //                                             className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
        //                                         >
        //                                             {sub?.doc_info?.map((info, index) => (
        //                                                 <p
        //                                                     key={index}
        //                                                     className={`text-[#64748B] text-xs  font-normal text-center ${
        //                                                         index < sub?.doc_info?.length - 1 ? 'border-b' : ''
        //                                                     } border-black py-2`}
        //                                                     style={{
        //                                                         color: info?.uploaded ? '#55BE8B' : '#FF5449',
        //                                                     }}
        //                                                 >
        //                                                     {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
        //                                                 </p>
        //                                             ))}
        //                                         </td>
        //                                         <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
        //                                             {sub?.evidence_info
        //                                                 ?.slice(18, sub?.evidence_info?.length)
        //                                                 ?.map((info, index) => (
        //                                                     <p
        //                                                         key={index}
        //                                                         className={`text-[#64748B] text-xs  font-normal text-center ${
        //                                                             index < sub?.evidence_info?.length - 1
        //                                                                 ? 'border-b'
        //                                                                 : ''
        //                                                         } border-black py-2`}
        //                                                     >
        //                                                         {info?.evidence_title || 'N/A'}
        //                                                     </p>
        //                                                 ))}
        //                                         </td>
        //                                         <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
        //                                             {sub?.evidence_info
        //                                                 ?.slice(18, sub?.evidence_info?.length)
        //                                                 ?.map((info, index) => (
        //                                                     <p
        //                                                         key={index}
        //                                                         className={`text-[#64748B] text-xs  font-normal text-center ${
        //                                                             index < sub?.evidence_info?.length - 1
        //                                                                 ? 'border-b'
        //                                                                 : ''
        //                                                         } border-black py-2`}
        //                                                         style={{
        //                                                             color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
        //                                                         }}
        //                                                     >
        //                                                         {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
        //                                                     </p>
        //                                                 ))}
        //                                         </td>
        //                                     </tr>
        //                                 </React.Fragment>
        //                             ))}
        //                         </React.Fragment>
        //                     );
        //                 })}
        //             </tbody>
        //         </table>
        //     </div>
        // </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(3, 4)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.slice(6, 8)?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(4, 5)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.slice(4, 5)?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(5, 6)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(6, 7)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(7, 8)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.slice(0, 2)?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(7, 8)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.slice(2, 3)?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(8, 9)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.slice(0, 3)?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(8, 9)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.slice(3, 5)?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(9, 10)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.slice(0, 3)?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(9, 10)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.slice(3, 4)?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(10, 11)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('soc2')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('soc2')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">SOC 2</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(soc2_ciso_summary && Object?.keys(soc2_ciso_summary)?.length
                            ? Object?.keys(soc2_ciso_summary)?.slice(11, 12)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {soc2_ciso_summary[option]?.sub_control_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_control_title}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            soc2_ciso_summary[option]?.sub_control_info?.length * 2
                                                        }
                                                    >
                                                        {soc2_ciso_summary[option]?.user_form}/
                                                        {soc2_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.evidence_data ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.evidence_data ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001')}
        >
            <div hidden={!standards?.includes('iso27001')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">ISO 27001</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(iso_ciso_summary?.new_ciso_info?.annex &&
                        Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.length
                            ? Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.slice(0, 2)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.user_form}/
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                    <tbody>
                        {(iso_ciso_summary?.new_ciso_info?.annex &&
                        Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.length
                            ? Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.slice(3, 4)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.user_form}/
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                    <tbody>
                        {(iso_ciso_summary?.new_ciso_info?.annex &&
                        Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.length
                            ? Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.slice(8, 9)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.user_form}/
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001')}
        >
            <div hidden={!standards?.includes('iso27001')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(iso_ciso_summary?.new_ciso_info?.annex &&
                        Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.length
                            ? Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.slice(2, 3)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.user_form}/
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001')}
        >
            <div hidden={!standards?.includes('iso27001')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(iso_ciso_summary?.new_ciso_info?.annex &&
                        Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.length
                            ? Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.slice(3, 5)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.user_form}/
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001')}
        >
            <div hidden={!standards?.includes('iso27001')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(iso_ciso_summary?.new_ciso_info?.annex &&
                        Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.length
                            ? Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.slice(4, 6)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.user_form}/
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001')}
        >
            <div hidden={!standards?.includes('iso27001')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(iso_ciso_summary?.new_ciso_info?.annex &&
                        Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.length
                            ? Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.slice(8, 11)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.user_form}/
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001')}
        >
            <div hidden={!standards?.includes('iso27001')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(iso_ciso_summary?.new_ciso_info?.annex &&
                        Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.length
                            ? Object?.keys(iso_ciso_summary?.new_ciso_info?.annex)?.slice(11, 13)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.user_form}/
                                                        {iso_ciso_summary?.new_ciso_info?.annex[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001')}
        >
            <div hidden={!standards?.includes('iso27001')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(iso_ciso_summary?.new_ciso_info?.clause &&
                        Object?.keys(iso_ciso_summary?.new_ciso_info?.clause)?.length
                            ? Object?.keys(iso_ciso_summary?.new_ciso_info?.clause)?.slice(0, 3)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_ciso_summary?.new_ciso_info?.clause[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.clause[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.clause[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {iso_ciso_summary?.new_ciso_info?.clause[option]?.user_form}/
                                                        {iso_ciso_summary?.new_ciso_info?.clause[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001')}
        >
            <div hidden={!standards?.includes('iso27001')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <tbody>
                        {(iso_ciso_summary?.new_ciso_info?.clause &&
                        Object?.keys(iso_ciso_summary?.new_ciso_info?.clause)?.length
                            ? Object?.keys(iso_ciso_summary?.new_ciso_info?.clause)?.slice(3, 6)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_ciso_summary?.new_ciso_info?.clause[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.clause[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.clause[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {iso_ciso_summary?.new_ciso_info?.clause[option]?.user_form}/
                                                        {iso_ciso_summary?.new_ciso_info?.clause[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>{' '}
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001')}
        >
            <div hidden={!standards?.includes('iso27001')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <tbody>
                        {(iso_ciso_summary?.new_ciso_info?.clause &&
                        Object?.keys(iso_ciso_summary?.new_ciso_info?.clause)?.length
                            ? Object?.keys(iso_ciso_summary?.new_ciso_info?.clause)?.slice(6, 7)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_ciso_summary?.new_ciso_info?.clause[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.clause[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_ciso_summary?.new_ciso_info?.clause[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {iso_ciso_summary?.new_ciso_info?.clause[option]?.user_form}/
                                                        {iso_ciso_summary?.new_ciso_info?.clause[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>{' '}
                </table>
            </div>
        </section>,
        <section>
            <div
                className="relative p-4 rounded-md"
                hidden={
                    view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso22301')
                }
            >
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">ISO 22301</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(iso22301_summary?.new_ciso_info?.clause &&
                        Object?.keys(iso22301_summary?.new_ciso_info?.clause)?.length
                            ? Object?.keys(iso22301_summary?.new_ciso_info?.clause)?.slice(0, 4)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso22301_summary?.new_ciso_info?.clause[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso22301_summary?.new_ciso_info?.clause[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso22301_summary?.new_ciso_info?.clause[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {iso22301_summary?.new_ciso_info?.clause[option]?.user_form}/
                                                        {iso22301_summary?.new_ciso_info?.clause[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section>
            <div
                className="relative p-4 rounded-md"
                hidden={
                    view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso22301')
                }
            >
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(iso22301_summary?.new_ciso_info?.clause &&
                        Object?.keys(iso22301_summary?.new_ciso_info?.clause)?.length
                            ? Object?.keys(iso22301_summary?.new_ciso_info?.clause)?.slice(4, 7)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso22301_summary?.new_ciso_info?.clause[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso22301_summary?.new_ciso_info?.clause[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso22301_summary?.new_ciso_info?.clause[option]?.doc_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {iso22301_summary?.new_ciso_info?.clause[option]?.user_form}/
                                                        {iso22301_summary?.new_ciso_info?.clause[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-3 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('pcidss4.0')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('pcidss4.0')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">PCI DSS 4.0</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(pcidss4_ciso_summary && Object?.keys(pcidss4_ciso_summary)?.length
                            ? Object?.keys(pcidss4_ciso_summary)
                                  ?.sort((a, b) => a - b)
                                  ?.slice(0, 4)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {pcidss4_ciso_summary[option]?.sub_req_info
                                        ?.sort((a, b) => a?.sub_requirement_number - b?.sub_requirement_number)
                                        ?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                            rowSpan={
                                                                pcidss4_ciso_summary?.[option]?.sub_req_info?.length * 2
                                                            }
                                                        >
                                                            {pcidss_ciso_summary?.[option]?.title}
                                                        </td>
                                                    )}
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan="2"
                                                    >
                                                        {sub?.sub_requirement_number}
                                                    </td>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                pcidss4_ciso_summary?.[option]?.sub_req_info?.length * 2
                                                            }
                                                        >
                                                            {pcidss4_ciso_summary?.[option]?.user_form}/
                                                            {pcidss4_ciso_summary?.[option]?.total_form}
                                                        </td>
                                                    )}
                                                </tr>

                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                        {sub?.doc_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className="text-[#64748B] text-xs  font-normal text-center border-b border-black py-2"
                                                            >
                                                                {info?.doc_name || 'N/A'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                    <td className="text-[#64748B] text-xs  font-normaltext-center border border-black">
                                                        {sub?.doc_info?.map((info, index) => (
                                                            <p
                                                                key={index}
                                                                className="text-[#64748B] text-xs  font-normal text-center border-b border-black py-2"
                                                                style={{
                                                                    color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                                }}
                                                            >
                                                                {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                            </p>
                                                        ))}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-3 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('pcidss4.0')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('pcidss4.0')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">PCI DSS 4.0</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(pcidss4_ciso_summary && Object?.keys(pcidss4_ciso_summary)?.length
                            ? Object?.keys(pcidss4_ciso_summary)
                                  ?.sort((a, b) => a - b)
                                  ?.slice(4, 7)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {pcidss4_ciso_summary[option]?.sub_req_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            pcidss4_ciso_summary?.[option]?.sub_req_info?.length * 2
                                                        }
                                                    >
                                                        {pcidss_ciso_summary?.[option]?.title}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_requirement_number}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            pcidss4_ciso_summary?.[option]?.sub_req_info?.length * 2
                                                        }
                                                    >
                                                        {pcidss4_ciso_summary?.[option]?.user_form}/
                                                        {pcidss4_ciso_summary?.[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className="text-[#64748B] text-xs  font-normal text-center border-b border-black py-2"
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normaltext-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className="text-[#64748B] text-xs  font-normal text-center border-b border-black py-2"
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-3 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('pcidss4.0')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('pcidss4.0')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">PCI DSS 4.0</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(pcidss4_ciso_summary && Object?.keys(pcidss4_ciso_summary)?.length
                            ? Object?.keys(pcidss4_ciso_summary)
                                  ?.sort((a, b) => a - b)
                                  ?.slice(7, 10)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {pcidss4_ciso_summary[option]?.sub_req_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            pcidss4_ciso_summary?.[option]?.sub_req_info?.length * 2
                                                        }
                                                    >
                                                        {pcidss_ciso_summary?.[option]?.title}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_requirement_number}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            pcidss4_ciso_summary?.[option]?.sub_req_info?.length * 2
                                                        }
                                                    >
                                                        {pcidss4_ciso_summary?.[option]?.user_form}/
                                                        {pcidss4_ciso_summary?.[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className="text-[#64748B] text-xs  font-normal text-center border-b border-black py-2"
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normaltext-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className="text-[#64748B] text-xs  font-normal text-center border-b border-black py-2"
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-3 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('pcidss4.0')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('pcidss4.0')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">PCI DSS 4.0</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(pcidss4_ciso_summary && Object?.keys(pcidss4_ciso_summary)?.length
                            ? Object?.keys(pcidss4_ciso_summary)
                                  ?.sort((a, b) => a - b)
                                  ?.slice(10, 12)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {pcidss4_ciso_summary[option]?.sub_req_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            pcidss4_ciso_summary?.[option]?.sub_req_info?.length * 2
                                                        }
                                                    >
                                                        {pcidss_ciso_summary?.[option]?.title}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_requirement_number}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            pcidss4_ciso_summary?.[option]?.sub_req_info?.length * 2
                                                        }
                                                    >
                                                        {pcidss4_ciso_summary?.[option]?.user_form}/
                                                        {pcidss4_ciso_summary?.[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className="text-[#64748B] text-xs  font-normal text-center border-b border-black py-2"
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normaltext-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className="text-[#64748B] text-xs  font-normal text-center border-b border-black py-2"
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-3 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('kdpa')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('kdpa')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">KDPA</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(kdpa_ciso_summary && Object?.keys(kdpa_ciso_summary)?.length
                            ? Object?.keys(kdpa_ciso_summary)?.slice(0, 2)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {kdpa_ciso_summary[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={kdpa_ciso_summary[option]?.doc_info?.length * 2}
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={kdpa_ciso_summary[option]?.doc_info?.length * 2}
                                                    >
                                                        {kdpa_ciso_summary[option]?.user_form}/
                                                        {kdpa_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-3 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('kdpa')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('kdpa')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(kdpa_ciso_summary && Object?.keys(kdpa_ciso_summary)?.length
                            ? Object?.keys(kdpa_ciso_summary)?.slice(2, 3)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {kdpa_ciso_summary[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={kdpa_ciso_summary[option]?.doc_info?.length * 2}
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={kdpa_ciso_summary[option]?.doc_info?.length * 2}
                                                    >
                                                        {kdpa_ciso_summary[option]?.user_form}/
                                                        {kdpa_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-m"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('gdpa')}
        >
            <div hidden={!standards?.includes('gdpa')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">GDPA</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(gdpa_ciso_summary && Object?.keys(gdpa_ciso_summary)?.length
                            ? Object?.keys(gdpa_ciso_summary)?.slice(1, 2)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {gdpa_ciso_summary[option]?.doc_info?.slice(0, 17)?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={gdpa_ciso_summary[option]?.doc_info?.length * 2}
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={gdpa_ciso_summary[option]?.doc_info?.length * 2}
                                                    >
                                                        {gdpa_ciso_summary[option]?.user_form}/
                                                        {gdpa_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                    <tbody>
                        {(gdpa_ciso_summary && Object?.keys(gdpa_ciso_summary)?.length
                            ? Object?.keys(gdpa_ciso_summary)?.slice(3, 4)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {gdpa_ciso_summary[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={gdpa_ciso_summary[option]?.doc_info?.length * 2}
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={gdpa_ciso_summary[option]?.doc_info?.length * 2}
                                                    >
                                                        {gdpa_ciso_summary[option]?.user_form}/
                                                        {gdpa_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>{' '}
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-m"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('gdpa')}
        >
            <div hidden={!standards?.includes('gdpa')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <tbody>
                        {(gdpa_ciso_summary && Object?.keys(gdpa_ciso_summary)?.length
                            ? Object?.keys(gdpa_ciso_summary)?.slice(1, 2)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {gdpa_ciso_summary[option]?.doc_info?.slice(17, 24)?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={gdpa_ciso_summary[option]?.doc_info?.length * 2}
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={gdpa_ciso_summary[option]?.doc_info?.length * 2}
                                                    >
                                                        {gdpa_ciso_summary[option]?.user_form}/
                                                        {gdpa_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                    <tbody>
                        {(gdpa_ciso_summary && Object?.keys(gdpa_ciso_summary)?.length
                            ? Object?.keys(gdpa_ciso_summary)?.slice(0, 1)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {gdpa_ciso_summary[option]?.doc_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={gdpa_ciso_summary[option]?.doc_info?.length * 2}
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={gdpa_ciso_summary[option]?.doc_info?.length * 2}
                                                    >
                                                        {gdpa_ciso_summary[option]?.user_form}/
                                                        {gdpa_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                    {sub?.doc_name || 'N/A'}
                                                </td>
                                                <td
                                                    className="text-xs  font-medium p-2 text-center border border-black"
                                                    style={{
                                                        color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                    }}
                                                >
                                                    {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>{' '}
                </table>
            </div>
        </section>,

        <section
            className="relative p-3 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('pcislc')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('pcislc')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">PCI SLC</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(all_pcislc_table_ciso_summary && Object?.keys(all_pcislc_table_ciso_summary)?.length
                            ? Object?.keys(all_pcislc_table_ciso_summary)?.slice(0, 3)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {all_pcislc_table_ciso_summary[option]?.sub_cycle_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            all_pcislc_table_ciso_summary[option]?.sub_cycle_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_cycle_number}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            all_pcislc_table_ciso_summary[option]?.sub_cycle_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {all_pcislc_table_ciso_summary[option]?.user_form}/
                                                        {all_pcislc_table_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-3 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('pcislc')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('pcislc')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(all_pcislc_table_ciso_summary && Object?.keys(all_pcislc_table_ciso_summary)?.length
                            ? Object?.keys(all_pcislc_table_ciso_summary)?.slice(3, 6)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {all_pcislc_table_ciso_summary[option]?.sub_cycle_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            all_pcislc_table_ciso_summary[option]?.sub_cycle_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_cycle_number}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            all_pcislc_table_ciso_summary[option]?.sub_cycle_info
                                                                ?.length * 2
                                                        }
                                                    >
                                                        {all_pcislc_table_ciso_summary[option]?.user_form}/
                                                        {all_pcislc_table_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001-2022')
            }
        >
            <div hidden={!standards?.includes('iso27001-2022')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">ISO 27001-2022</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {(iso_two_ciso_summary?.new_ciso_info?.annex_two &&
                        Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex_two)?.length
                            ? Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex_two)?.slice(0, 1)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_two_ciso_summary?.new_ciso_info?.annex_two[option]?.doc_info
                                        ?.slice(0, 16)
                                        ?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001-2022')
            }
        >
            <div hidden={!standards?.includes('iso27001-2022')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {(iso_two_ciso_summary?.new_ciso_info?.annex_two &&
                        Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex_two)?.length
                            ? Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex_two)?.slice(0, 1)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_two_ciso_summary?.new_ciso_info?.annex_two[option]?.doc_info
                                        ?.slice(16, 32)
                                        ?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001-2022')
            }
        >
            <div hidden={!standards?.includes('iso27001-2022')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {(iso_two_ciso_summary?.new_ciso_info?.annex_two &&
                        Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex_two)?.length
                            ? Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex_two)?.slice(0, 1)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_two_ciso_summary?.new_ciso_info?.annex_two[option]?.doc_info
                                        ?.slice(32, 42)
                                        ?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001-2022')
            }
        >
            <div hidden={!standards?.includes('iso27001-2022')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {(iso_two_ciso_summary?.new_ciso_info?.annex_two &&
                        Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex_two)?.length
                            ? Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex_two)?.slice(1, 2)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_two_ciso_summary?.new_ciso_info?.annex_two[option]?.doc_info?.map(
                                        (sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001-2022')
            }
        >
            <div hidden={!standards?.includes('iso27001-2022')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {(iso_two_ciso_summary?.new_ciso_info?.annex_two &&
                        Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex_two)?.length
                            ? Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex_two)?.slice(2, 3)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_two_ciso_summary?.new_ciso_info?.annex_two[option]?.doc_info?.map(
                                        (sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001-2022')
            }
        >
            <div hidden={!standards?.includes('iso27001-2022')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {(iso_two_ciso_summary?.new_ciso_info?.annex_two &&
                        Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex_two)?.length
                            ? Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex_two)?.slice(3, 4)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_two_ciso_summary?.new_ciso_info?.annex_two[option]?.doc_info
                                        ?.slice(0, 16)
                                        ?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001-2022')
            }
        >
            <div hidden={!standards?.includes('iso27001-2022')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {(iso_two_ciso_summary?.new_ciso_info?.annex_two &&
                        Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex_two)?.length
                            ? Object?.keys(iso_two_ciso_summary?.new_ciso_info?.annex_two)?.slice(3, 4)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_two_ciso_summary?.new_ciso_info?.annex_two[option]?.doc_info
                                        ?.slice(16, 24)
                                        ?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.annex_two[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001-2022')
            }
        >
            <div hidden={!standards?.includes('iso27001-2022')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(iso_two_ciso_summary?.new_ciso_info?.clause &&
                        Object?.keys(iso_two_ciso_summary?.new_ciso_info?.clause)?.length
                            ? Object?.keys(iso_two_ciso_summary?.new_ciso_info?.clause)?.slice(0, 4)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_two_ciso_summary?.new_ciso_info?.clause[option]?.doc_info?.map(
                                        (sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso27001-2022')
            }
        >
            <div hidden={!standards?.includes('iso27001-2022')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(iso_two_ciso_summary?.new_ciso_info?.clause &&
                        Object?.keys(iso_two_ciso_summary?.new_ciso_info?.clause)?.length
                            ? Object?.keys(iso_two_ciso_summary?.new_ciso_info?.clause)?.slice(4, 7)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_two_ciso_summary?.new_ciso_info?.clause[option]?.doc_info?.map(
                                        (sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                iso_two_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                iso_two_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                standards?.length === 0 ||
                !standards?.includes('iso27017+iso27032')
            }
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('iso27017+iso27032')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">ISO 27017 + ISO 27032</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(tech_sec_ciso_summary?.new_ciso_info?.annex &&
                        Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.annex)?.length
                            ? Object?.keys(tech_sec_ciso_summary.new_ciso_info?.annex)?.slice(0, 1)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {tech_sec_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                        ?.slice(0, 16)
                                        ?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                standards?.length === 0 ||
                !standards?.includes('iso27017+iso27032')
            }
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('iso27017+iso27032')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(tech_sec_ciso_summary?.new_ciso_info?.annex &&
                        Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.annex)?.length
                            ? Object?.keys(tech_sec_ciso_summary.new_ciso_info?.annex)?.slice(0, 1)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {tech_sec_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                        ?.slice(16, 32)
                                        ?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                standards?.length === 0 ||
                !standards?.includes('iso27017+iso27032')
            }
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('iso27017+iso27032')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(tech_sec_ciso_summary?.new_ciso_info?.annex &&
                        Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.annex)?.length
                            ? Object?.keys(tech_sec_ciso_summary.new_ciso_info?.annex)?.slice(0, 1)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {tech_sec_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                        ?.slice(32, 40)
                                        ?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>

                    <tbody>
                        {(tech_sec_ciso_summary?.new_ciso_info?.annex &&
                        Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.annex)?.length
                            ? Object?.keys(tech_sec_ciso_summary.new_ciso_info?.annex)?.slice(1, 2)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {tech_sec_ciso_summary?.new_ciso_info?.annex[option]?.doc_info?.map(
                                        (sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                standards?.length === 0 ||
                !standards?.includes('iso27017+iso27032')
            }
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('iso27017+iso27032')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(tech_sec_ciso_summary?.new_ciso_info?.annex &&
                        Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.annex)?.length
                            ? Object?.keys(tech_sec_ciso_summary.new_ciso_info?.annex)?.slice(3, 4)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {tech_sec_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                        ?.slice(0, 16)
                                        ?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                standards?.length === 0 ||
                !standards?.includes('iso27017+iso27032')
            }
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('iso27017+iso27032')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(tech_sec_ciso_summary?.new_ciso_info?.annex &&
                        Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.annex)?.length
                            ? Object?.keys(tech_sec_ciso_summary.new_ciso_info?.annex)?.slice(3, 4)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {tech_sec_ciso_summary?.new_ciso_info?.annex[option]?.doc_info
                                        ?.slice(16, 23)
                                        ?.map((sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.annex[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                standards?.length === 0 ||
                !standards?.includes('iso27017+iso27032')
            }
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('iso27017+iso27032')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(tech_sec_ciso_summary?.new_ciso_info?.clause &&
                        Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.clause)?.length
                            ? Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.clause)?.slice(0, 3)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {tech_sec_ciso_summary?.new_ciso_info?.clause[option]?.doc_info?.map(
                                        (sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                standards?.length === 0 ||
                !standards?.includes('iso27017+iso27032')
            }
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('iso27017+iso27032')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(tech_sec_ciso_summary?.new_ciso_info?.clause &&
                        Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.clause)?.length
                            ? Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.clause)?.slice(3, 6)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {tech_sec_ciso_summary?.new_ciso_info?.clause[option]?.doc_info?.map(
                                        (sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                standards?.length === 0 ||
                !standards?.includes('iso27017+iso27032')
            }
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('iso27017+iso27032')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(tech_sec_ciso_summary?.new_ciso_info?.clause &&
                        Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.clause)?.length
                            ? Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.clause)?.slice(6, 7)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {tech_sec_ciso_summary?.new_ciso_info?.clause[option]?.doc_info?.map(
                                        (sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.user_form
                                                            }
                                                            /
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.clause[option]
                                                                    ?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                standards?.length === 0 ||
                !standards?.includes('iso27017+iso27032')
            }
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('iso27017+iso27032')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(tech_sec_ciso_summary?.new_ciso_info?.cloud_security &&
                        Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.cloud_security)?.length
                            ? Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.cloud_security)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {tech_sec_ciso_summary?.new_ciso_info?.cloud_security[option]?.doc_info?.map(
                                        (sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.cloud_security[
                                                                    option
                                                                ]?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.cloud_security[
                                                                    option
                                                                ]?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.cloud_security[
                                                                    option
                                                                ]?.user_form
                                                            }
                                                            /
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.cloud_security[
                                                                    option
                                                                ]?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-4 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                standards?.length === 0 ||
                !standards?.includes('iso27017+iso27032')
            }
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('iso27017+iso27032')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(tech_sec_ciso_summary?.new_ciso_info?.cybersecurity &&
                        Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.cybersecurity)?.length
                            ? Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.cybersecurity)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {tech_sec_ciso_summary?.new_ciso_info?.cybersecurity[option]?.doc_info?.map(
                                        (sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.cybersecurity[
                                                                    option
                                                                ]?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info?.cybersecurity[
                                                                    option
                                                                ]?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.cybersecurity[
                                                                    option
                                                                ]?.user_form
                                                            }
                                                            /
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info?.cybersecurity[
                                                                    option
                                                                ]?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>

                    <tbody>
                        {(tech_sec_ciso_summary?.new_ciso_info?.project_management &&
                        Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.project_management)?.length
                            ? Object?.keys(tech_sec_ciso_summary?.new_ciso_info?.project_management)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {tech_sec_ciso_summary?.new_ciso_info?.project_management[option]?.doc_info?.map(
                                        (sub, subIndex) => (
                                            <React.Fragment key={subIndex}>
                                                <tr>
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info
                                                                    ?.project_management[option]?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {option}
                                                        </td>
                                                    )}
                                                    {subIndex === 0 && (
                                                        <td
                                                            className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                            rowSpan={
                                                                tech_sec_ciso_summary?.new_ciso_info
                                                                    ?.project_management[option]?.doc_info?.length * 2
                                                            }
                                                        >
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info
                                                                    ?.project_management[option]?.user_form
                                                            }
                                                            /
                                                            {
                                                                tech_sec_ciso_summary?.new_ciso_info
                                                                    ?.project_management[option]?.total_form
                                                            }
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black">
                                                        {sub?.doc_name || 'N/A'}
                                                    </td>
                                                    <td
                                                        className="text-xs  font-medium p-2 text-center border border-black"
                                                        style={{
                                                            color: sub.uploaded ? '#55BE8B' : '#FF5449',
                                                        }}
                                                    >
                                                        {sub?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-3 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso9001')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('iso9001')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">ISO 9001</p>
                </div>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(iso_9001_ciso_summary && Object?.keys(iso_9001_ciso_summary)?.length
                            ? Object?.keys(iso_9001_ciso_summary)?.slice(0, 2)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_9001_ciso_summary[option]?.sub_quality_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_9001_ciso_summary[option]?.sub_quality_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_cycle_number}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_9001_ciso_summary[option]?.sub_quality_info?.length * 2
                                                        }
                                                    >
                                                        {iso_9001_ciso_summary[option]?.user_form}/
                                                        {iso_9001_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-3 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso9001')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('iso9001')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(iso_9001_ciso_summary && Object?.keys(iso_9001_ciso_summary)?.length
                            ? Object?.keys(iso_9001_ciso_summary)?.slice(2, 4)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_9001_ciso_summary[option]?.sub_quality_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_9001_ciso_summary[option]?.sub_quality_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_cycle_number}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_9001_ciso_summary[option]?.sub_quality_info?.length * 2
                                                        }
                                                    >
                                                        {iso_9001_ciso_summary[option]?.user_form}/
                                                        {iso_9001_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section
            className="relative p-3 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso9001')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('iso9001')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(iso_9001_ciso_summary && Object?.keys(iso_9001_ciso_summary)?.length
                            ? Object?.keys(iso_9001_ciso_summary)?.slice(4, 6)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_9001_ciso_summary[option]?.sub_quality_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_9001_ciso_summary[option]?.sub_quality_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_cycle_number}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_9001_ciso_summary[option]?.sub_quality_info?.length * 2
                                                        }
                                                    >
                                                        {iso_9001_ciso_summary[option]?.user_form}/
                                                        {iso_9001_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,

        <section
            className="relative p-3 rounded-md"
            hidden={view === 'Graphical Presentation' || standards?.length === 0 || !standards?.includes('iso9001')}
        >
            <div className="mt-[1rem]" hidden={!standards?.includes('iso9001')}>
                <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Category
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Sub-cat
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Questions
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Policies
                            </th>
                            <th
                                className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"
                                colSpan="2"
                            >
                                Evidences
                            </th>
                        </tr>
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black"></th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(iso_9001_ciso_summary && Object?.keys(iso_9001_ciso_summary)?.length
                            ? Object?.keys(iso_9001_ciso_summary)?.slice(6, 7)
                            : []
                        )?.map((option, optionIndex) => {
                            return (
                                <React.Fragment key={optionIndex}>
                                    {iso_9001_ciso_summary[option]?.sub_quality_info?.map((sub, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-meidum p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_9001_ciso_summary[option]?.sub_quality_info?.length * 2
                                                        }
                                                    >
                                                        {option}
                                                    </td>
                                                )}
                                                <td
                                                    className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                    rowSpan="2"
                                                >
                                                    {sub?.sub_cycle_number}
                                                </td>
                                                {subIndex === 0 && (
                                                    <td
                                                        className="text-[#64748B] text-xs  font-medium p-2 text-center border border-black"
                                                        rowSpan={
                                                            iso_9001_ciso_summary[option]?.sub_quality_info?.length * 2
                                                        }
                                                    >
                                                        {iso_9001_ciso_summary[option]?.user_form}/
                                                        {iso_9001_ciso_summary[option]?.total_form}
                                                    </td>
                                                )}
                                            </tr>

                                            <tr>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.doc_name || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td
                                                    className={`text-[#64748B] text-xs  font-normal text-center border border-black`}
                                                >
                                                    {sub?.doc_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.doc_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: info?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                        >
                                                            {info?.evidence_title || 'N/A'}
                                                        </p>
                                                    ))}
                                                </td>
                                                <td className="text-[#64748B] text-xs  font-normal text-center border border-black">
                                                    {sub?.evidence_info?.map((info, index) => (
                                                        <p
                                                            key={index}
                                                            className={`text-[#64748B] text-xs  font-normal text-center ${
                                                                index < sub?.evidence_info?.length - 1 ? 'border-b' : ''
                                                            } border-black py-2`}
                                                            style={{
                                                                color: sub?.uploaded ? '#55BE8B' : '#FF5449',
                                                            }}
                                                        >
                                                            {info?.uploaded ? 'Uploaded' : 'Not Uploaded'}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>,
        <section className="relative p-5 rounded-md" hidden={filtered_risk_by_3?.length === 0}>
            <RiskBy3 view={view} risk_options={risk_options} />
        </section>,

        <section className="relative p-5 rounded-md" hidden={filtered_risk_by_5?.length === 0}>
            <RiskBy5 view={view} risk_options={risk_options} />
        </section>,

        <section
            className="relative rounded-md mx-[2rem]"
            hidden={
                view === 'Report View' ||
                (inventory_options?.length === 0 && employees_options?.length === 0) ||
                people_info_summary?.length === 0
            }
        >
            <Employees />
        </section>,

        <section
            className="relative p-3 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                employees_options?.length === 0 ||
                !employees_options?.includes('Onboarding Policy') ||
                people_info_summary?.length === 0
            }
        >
            <div>
                <EmployeesOnboarding employees_options={employees_options} />
            </div>
        </section>,

        <section
            className="relative p-3 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                employees_options?.length === 0 ||
                !employees_options?.includes('Onboarding Policy') ||
                people_info_summary?.length < 17
            }
        >
            <div>
                <EmployeesOnboarding2 employees_options={employees_options} />
            </div>
        </section>,
        <section
            className="relative p-3 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                employees_options?.length === 0 ||
                !employees_options?.includes('Computer scan') ||
                people_scan_summary?.length === 0
            }
        >
            <div>
                <EmployeesComputers employees_options={employees_options} />
            </div>
        </section>,
        <section
            className="relative p-3 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                employees_options?.length === 0 ||
                (people_summary?.length === 0 && people_scan_summary?.length === 0)
            }
        >
            <div>
                <EmployeesTraining employees_options={employees_options} />
            </div>
        </section>,
        <section className="relative p-3 rounded-md">
            <div
                hidden={
                    view === 'Report View' ||
                    inventory_options?.length === 0 ||
                    !employees_options?.includes('Employee Training')
                }
            >
                <InverntoryScan />
            </div>
        </section>,

        <section
            className="relative p-3 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                inventory_options?.length === 0 ||
                !inventory_options?.includes('Penetration testing') ||
                all_pentest_summary?.length == 0
            }
        >
            <InventoryScansPentest inventory_options={inventory_options} />
        </section>,
        <section
            className="relative p-3 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                inventory_options?.length === 0 ||
                !inventory_options?.includes('PCI Scan') ||
                pci_scan_summary?.length == 0
            }
        >
            <InventoryScansPci inventory_options={inventory_options} />
        </section>,
        <section
            className="relative p-3 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                inventory_options?.length === 0 ||
                !inventory_options?.includes('API compliance') ||
                api_security_scan_summary?.length == 0
            }
        >
            <InventoryScansAPICompliance inventory_options={inventory_options} />
        </section>,
        <section
            className="relative p-3 rounded-md"
            hidden={
                view === 'Graphical Presentation' ||
                inventory_options?.length === 0 ||
                !inventory_options?.includes('Codebase Security Scan') ||
                source_code_scan_summary?.length
            }
        >
            <InventoryScansCoddebase inventory_options={inventory_options} />
        </section>,

        <section
            className="relative p-3 rounded-md"
            hidden={
                third_party_options?.length === 0 ||
                (all_third_party_summary?.aml_check_info?.length === 0 &&
                    all_third_party_summary?.vendor_management_info?.length === 0 &&
                    all_third_party_summary?.transaction_fraud_info?.length === 0 &&
                    all_third_party_summary?.acting_as_vendor_info?.length === 0)
            }
        >
            <ThirdPartyChart third_party_options={third_party_options} view={view} />
        </section>,
        <section
            className="relative p-3 rounded-md]"
            hidden={third_party_options?.length === 0 || all_third_party_summary?.aml_check_info?.length === 0}
        >
            <InverntoryScanAml third_party_options={third_party_options} view={view} />
        </section>,
        <section
            className="relative p-3 rounded-md]"
            hidden={third_party_options?.length === 0 || all_third_party_summary?.transaction_fraud_info?.length === 0}
        >
            <InverntoryScanTransactionmonitoring third_party_options={third_party_options} view={view} />
        </section>,
        <section
            className="relative p-3 rounded-md]"
            hidden={third_party_options?.length === 0 || all_third_party_summary?.vendor_management_info?.length === 0}
        >
            <InverntoryScanVendorMangement third_party_options={third_party_options} view={view} />
        </section>,
        <section
            className="relative p-3 rounded-md]"
            hidden={third_party_options?.length === 0 || all_third_party_summary?.acting_as_vendor_info?.length === 0}
        >
            <InverntoryScanActingAsVendor third_party_options={third_party_options} view={view} />
        </section>,
    ];
    useEffect(() => {
        if (pdfPages?.length > 0) {
            const ids = pdfPages
                ?.filter((item) => item?.props?.hidden === false)
                ?.map((_, index) => `page${index + 1}`);
            setItems(pdfPages?.filter((item) => item?.props?.hidden === false));
            setItemLength(ids);
        }
    }, [open, view, inventory_options, standards, employees_options, third_party_options, automated_scans]);

    return (
        open && (
            <div className="flex justify-center items-center">
                <main className="my-4 overflow-scroll w-full">
                    {items?.map((item, index) => (
                        <div key={index} id={`page${index + 1}`} className="h-[2500px]">
                            <div className="flex justify-between items-center mt-[1rem]">
                                <img src={company_info?.company_img} alt="Company Image" className="w-[180px]" />
                                <h4 className="text-black text-lg font-semibold">
                                    Page{''} {index + 1}
                                </h4>
                            </div>
                            <div>{item}</div>
                        </div>
                    ))}
                </main>
            </div>
        )
    );
}
export default SpoolReportPrint;
