import { Box, Button, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { UpdateAddon } from 'store/actions/adminActions';
import { capitalizeFirstLetter } from 'utils';

// core components

const AppDeleteModalNew = (props) => {
    const { open, handleClose, mode, refetch, merchant } = props;
    const theme = useTheme();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        const res = await UpdateAddon({
            smart_intel: { status: mode === 'enable' ? 'approved' : 'disabled' },
            merchant,
        })();
        setLoading(false);
        if (res.success) {
            setLoading(false);
            toast.success(`Feature updated successfully`);
            refetch();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            width={450}
            actions={
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mt: 3, mb: 1.5, gap: 1 }}>
                    <Button
                        variant="text"
                        sx={{
                            backgroundColor: '#FFFFFF !important',
                            color: '#334155 !important',
                            textTransform: 'inherit !Important',
                            border: '1px solid #E2E8F0 !important',
                            px: 2,
                            py: 1,
                            borderRadius: 1,
                            '&:hover': {
                                backgroundColor: '#FFFFFF !important',
                            },
                        }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                    <AppLoadingButton
                        text={'Yes, ' + mode}
                        variant="contained"
                        loading={loading}
                        loadingPosition="center"
                        onClick={handleSubmit}
                        sx={{
                            backgroundColor: mode === 'enable' ? '#55BE8B' : '#F43F5E',
                            color: '#FFFFFF !important',
                            textTransform: 'inherit',
                        }}
                    />
                </Stack>
            }
        >
            <Box
                sx={{
                    fontWeight: 500,
                    color: theme.palette.gray[800],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    // mt: 2,
                    mt: 0.5,
                }}
            >
                <span style={{ color: '#475569', fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>
                    {capitalizeFirstLetter(mode)} feature?
                </span>

                <p
                    style={{
                        color: '#64748B',
                        fontSize: '13px',
                        fontWeight: 400,
                        marginTop: '0.5rem',
                        textAlign: 'center',
                    }}
                >
                    This feature will be marked as <span className="font-bold">{capitalizeFirstLetter(mode)}d</span>.
                    Are you sure you want to go ahead?
                </p>
            </Box>
        </AppCenteredModal>
    );
};

export default AppDeleteModalNew;
