import React, { useEffect } from 'react';
import SOCFirstLayerReport from './blockchainFirstLayer';
import SummaryReportCard from '../SummaryReportCard';
import { toast } from 'react-toastify';

import { GetAllCriteria } from 'store/actions/adminActions';
import { connect } from 'react-redux';

//translations
import { useTranslation } from 'react-i18next';

const BLOCKCHAINIndex = ({ percentage, GetAllCriteria, all_criteria }) => {
    //translation
    const { t } = useTranslation('overview');

    //function
    const getAllCriteria = async () => {
        const res = await GetAllCriteria();
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch Criterias.");
            console.log(res.message);
        }
    };

    useEffect(() => {
        getAllCriteria();
    }, []);

    return (
        <div className="">
            <SOCFirstLayerReport percentage={(percentage || 0)?.toFixed(2)} />
            <div className="py-8">
                <h3 className="textbrandColor font-bold text-[23px]">{t('standardTab.summary')}</h3>
                <div className="pt-4">
                    {all_criteria?.map((res, index) => (
                        <React.Fragment key={index}>
                            <SummaryReportCard details={res} blockchain />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_criteria: state?.adminReducers?.all_criteria,
    };
};

export default connect(mapStateToProps, {
    GetAllCriteria,
})(BLOCKCHAINIndex);
