import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CircularLoader from 'components/Loaders/CircularLoader';
import LightIcon from 'assets/img/Light.svg';

import ViewResponse from './ViewResponse';
import { Progress } from 'antd';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const ResponseBody = (props) => {
    const theme = useTheme();
    const { question, title, answers, score_percent, risk_level } = props;

    //translation
    const language = localStorage.getItem('i18nextLng');
    return (
        <Box
            sx={{
                backgroundColor: theme.palette.white.main,
                borderRadius: 3,
                height: 'calc(100% - 150px)',
                width: '70%',
                py: '1rem',
                px: '2rem',
                // position: 'fixed',
                // left: '500px',
                overflow: 'hidden',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary[900] + '30',
                    borderRadius: '10px',
                },
                [theme.breakpoints.down('xl')]: {
                    width: '80%',
                },
                [theme.breakpoints.down('lg')]: {
                    width: '90%',
                },
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5 }}>
                <Box
                    sx={{
                        backgroundColor: '#F8FAFC',
                        display: 'flex',
                        alignItems: 'flex-start',
                        my: 1.5,
                        py: 2,
                        px: 2,
                        borderRadius: 1,
                    }}
                >
                    <img src={LightIcon} alt={'LightIcon'} />
                    <Typography sx={{ color: '#64748B', fontSize: '13px', fontWeight: 400, ml: 1 }}>
                        Organization & information securrity blah available for this scan constraint. Click any of them
                        to view all instances of the scans performed. You can assign a category to any of your user in
                        the same organization by clicking the more (...) button.
                    </Typography>
                </Box>
                <Box>
                    <Progress
                        type="circle"
                        strokeLinecap="butt"
                        width={50}
                        percent={Math.round(score_percent)}
                        strokeWidth={12}
                        strokeColor={'#0E2C66'}
                    />
                    <Typography
                        sx={{
                            color: '#64748B',
                            fontSize: '12px',
                            fontWeight: 500,
                            display: 'flex',
                            alignItems: 'center',
                            mt: 0.5,
                            textTransform: 'capitalize',
                        }}
                    >
                        <FiberManualRecordIcon
                            sx={{
                                mr: 0.5,
                                width: 12,
                                height: 12,
                                color:
                                    risk_level === 'low' ? '#55BE8B' : risk_level === 'medium' ? '#F2C021' : '#FF5449',
                            }}
                        />
                        <span>{risk_level}</span>
                    </Typography>
                </Box>
            </Box>
            {title === question?.title && (
                <Box sx={{ pt: '15px', pb: '70px', pr: '20px' }}>
                    {question ? (
                        question?.form_fields
                            ?.sort((a, b) => a.id - b.id)
                            ?.map((field) => {
                                const answer = answers[field.id];
                                return (
                                    <ViewResponse
                                        key={field.id}
                                        type={field.type}
                                        options={field?.options}
                                        name={
                                            language?.includes('fr')
                                                ? field.name_fr
                                                : language?.includes('en')
                                                ? field.name_en
                                                : field.name
                                        }
                                        answer={answer}
                                    />
                                );
                            })
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularLoader />
                        </div>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default ResponseBody;
