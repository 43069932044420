export const categoryOptions = [
    {
        name: 'operational',
        value: 'operational',
    },
    {
        name: 'Compliance',
        value: 'Compliance',
    },
    {
        name: 'Security',
        value: 'Security',
    },
    {
        name: 'Strategic',
        value: 'Strategic',
    },
    {
        name: 'Financial',
        value: 'Financial',
    },
    {
        name: 'Technological',
        value: 'Technological',
    },
];

export const planOptions = [
    {
        name: 'No Response',
        value: 'no response',
    },
    {
        name: 'Mitigate',
        value: 'mitigate',
    },
    {
        name: 'Accept',
        value: 'accept',
    },
    {
        name: 'Avoid',
        value: 'avoid',
    },
    {
        name: 'transfer',
        value: 'Transfer',
    },
];

export const riskOptions = [
    {
        name: 'Very low',
        value: 'very low',
    },
    {
        name: 'Low',
        value: 'mitigate',
    },
    {
        name: 'Medium',
        value: 'accept',
    },
    {
        name: 'High',
        value: 'avoid',
    },
    {
        name: 'Very high',
        value: 'Transfer',
    },
];

export const statusOptions = [
    {
        name: 'Within timeline',
        value: 'very low',
    },
    {
        name: 'Timeline expired',
        value: 'mitigate',
    },
    {
        name: 'Approved',
        value: 'accept',
    },
    {
        name: 'Resolved',
        value: 'avoid',
    },
    {
        name: 'No timeline',
        value: 'Transfer',
    },
    {
        name: 'Not resolved',
        value: 'Transfer',
    },
];

export const riskTypeOptions = [
    {
        name: 'Residual Risk',
        value: 'residual_risk',
    },
    {
        name: 'Inherent Risk',
        value: 'inherent_risk',
    },
];

export const riskTable = [
    {
        id: 1,
        risk_subject: 'Attackers can use unauthorized and unmanaged devices to gain access to network',
        risk_owner: 'Emmanuel tanimowo',
        category: 'Operational',
        residual_risk: '',
        mitigation_plan: 'No response',
    },
    {
        id: 2,
        risk_subject: 'Attackers can use unauthorized and unmanaged devices to gain access to network',
        risk_owner: 'Emmanuel tanimowo',
        category: 'Operational',
        residual_risk: '',
        mitigation_plan: 'No response',
        days_left: '40 days',
    },
    {
        id: 3,
        risk_subject: 'Attackers can use unauthorized and unmanaged devices to gain access to network',
        risk_owner: 'Emmanuel tanimowo',
        category: 'Complaince',
        residual_risk: '',
        mitigation_plan: 'Approved',
        days_left: 'Approved',
    },
    {
        id: 4,
        risk_subject: 'Attackers can use unauthorized and unmanaged devices to gain access to network',
        risk_owner: 'Emmanuel tanimowo',
        category: 'Security',
        residual_risk: '',
        mitigation_plan: 'Accept',
    },
    {
        id: 5,
        risk_subject: 'Attackers can use unauthorized and unmanaged devices to gain access to network',
        risk_owner: 'Emmanuel tanimowo',
        category: 'Financial',
        residual_risk: '',
        mitigation_plan: 'Avoid',
    },
    {
        id: 6,
        risk_subject: 'Attackers can use unauthorized and unmanaged devices to gain access to network',
        risk_owner: 'Emmanuel tanimowo',
        category: 'Strategic',
        residual_risk: '',
        mitigation_plan: 'Transfer',
    },
];

export const riskPermission = [
    {
        name: 'Mitigation Owner',
        value: 'risk_migation',
    },
    {
        name: 'Risk Escalator',
        value: 'escalator',
    },
    {
        name: 'Management Approval',
        value: 'approval',
    },
];

export const tabs = [
    {
        index: 0,
        label: 'Risk Identification',
    },
    {
        index: 1,
        label: 'Risk Treatmemt',
    },
];

export const RiskTemplatesTab = {
    FiveByFive: '5x5',
    ThreeByThree: '3x3',
};
export const plans = ['mitigate', 'accept', 'avoid', 'transfer'];

export const residual3x3Risks = ['low', 'medium', 'high'];

export const residual5x5Risks = ['very low', 'low', 'medium', 'high', 'very high'];
export const risk_sources = ['manual', 'auto'];
