import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button, Select } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { AddBulkVulnerabilites } from 'store/actions/adminActions';
import { GetAllThreats } from 'store/actions/adminActions';

const VulnerabilitiesModal = (props) => {
    const [loading, setloading] = useState(false);
    const [data, setData] = useState([
        {
            name: '',
            threat: '',
            tag: 'admin',
        },
    ]);
    const { open, handleClose } = props;

    // redux
    const dispatch = useDispatch();
    const all_threats = useSelector((state) => state.adminReducers.all_threats);

    const onClose = () => {
        setData([
            {
                name: '',
                threat: '',
                tag: 'admin',
            },
        ]);
        handleClose();
    };

    useEffect(() => {
        const getAllThreats = async () => {
            setloading(true);
            const res = await dispatch(GetAllThreats());
            setloading(false);
            if (!res.success) {
                toast.error(res?.message);
            }
        };

        getAllThreats();
    }, []);

    const find_empty_vulnerability = data?.filter((item) => item?.name === '' || item?.threat === '');
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (find_empty_vulnerability?.length) {
            toast.info('Fill all fields!');
        } else {
            setloading(true);
            const vulnerabilities = data?.map((item) => {
                return {
                    name: item?.name,
                    threat: item?.threat,
                    tag: item?.tag,
                };
            });
            const payload = {
                vulnerabilities,
            };
            const res = await dispatch(AddBulkVulnerabilites(payload));
            setloading(false);
            if (res?.success) {
                toast.success('Vulnerabilities Added Successfully!');
                onClose();
            } else {
                toast.error(res?.message);
            }
        }
    };

    const onSelect = (value, index) => {
        const newItems = data || [];
        newItems[index] = { ...newItems?.[index], threat: value };
        newItems[index] = {
            ...newItems?.[index],
        };
        setData([...newItems]);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };

    const handleItemInputChange = (e, index) => {
        const { name, value } = e.target;
        const newItems = data || [];
        newItems[index] = { ...newItems?.[index], [name]: value };
        newItems[index] = {
            ...newItems?.[index],
        };

        setData([...newItems]);
    };

    //handle add and remove assets
    const handleAddItem = () => {
        const newItem = {
            name: '',
            threat_category: '',
            tag: 'admin',
        };
        const dataCopy = [...data];
        dataCopy.push(newItem);
        setData(dataCopy);
    };

    const removeItem = (index) => {
        const newData = [...data];
        newData?.splice(index, 1);
        setData(newData);
    };
    return (
        <Modal title="Add Vulnerabilities" open={open} onCancel={onClose} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                {data?.map((item, index) => (
                    <Row justify="end" key={index}>
                        <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="name">Vulnerabilitiy</label>

                            <Input
                                type="text"
                                size="large"
                                name="name"
                                id="name"
                                value={item.name || ''}
                                // onChange={handleTextChange}
                                onChange={(e) => handleItemInputChange(e, index)}
                                required
                            />
                        </Col>
                        <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="plan">Choose Threat</label>
                            <Select
                                showSearch
                                size="large"
                                placeholder="Select Threat"
                                optionFilterProp="children"
                                value={item?.threat}
                                onChange={(value) => onSelect(value, index)}
                                onSearch={onSearch}
                                style={{ width: '100%' }}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={all_threats?.map((category) => {
                                    return {
                                        value: category?.id,

                                        label: `${category?.name}- ${category?.threat_category_detail?.name}`,
                                    };
                                })}
                            />
                        </Col>

                        <Button
                            type="primary"
                            danger
                            shape="circle"
                            onClick={() => removeItem(index)}
                            icon={<DeleteOutlined />}
                            disabled={data?.length <= 1}
                        />
                    </Row>
                ))}
                <div className="flex flex-row justify-end my-2 gap-2">
                    <Button icon={<PlusCircleOutlined />} onClick={handleAddItem} />
                </div>
                <Col style={{ marginTop: '5rem' }}>
                    <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                        Submit
                    </Button>
                </Col>
            </form>
        </Modal>
    );
};

export default VulnerabilitiesModal;
