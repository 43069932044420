import { Stack, Typography, Box, Select, MenuItem } from '@mui/material';
import { useState, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { ReactComponent as DateIcon } from 'assets/img/calendar.svg';
// import { CategoryScale } from 'chart.js';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { currencies, formatNumber, months } from './utils';
import { useSelector } from 'react-redux';
import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function RiskFinancialLoss() {
    const date_joined = useSelector((state) => state?.authReducers?.user_details?.user?.date_joined) ?? null;
    const yearUserSignedUp = date_joined ? moment(date_joined).year() : moment().year();

    const [filterYear, setFilterYear] = useState(moment().year());
    const [filterCurrency, setFilterCurrency] = useState('₦');

    // constant
    const overview = useSelector((state) => state?.riskAssessmentReducers?.overview);

    const handleYearChange = (e) => {
        setFilterYear(e.target.value);
    };
    const handleCurrencyChange = (e) => {
        setFilterCurrency(e.target.value);
    };

    const getCode = (query) => currencies.find((el) => el.symbol === query)?.code?.toLowerCase();

    const values = useMemo(() => {
        const data = overview?.financial_loss_by_year_month
            ? overview?.financial_loss_by_year_month.filter(
                  (el) =>
                      el?.date_created__year === filterYear &&
                      el?.mitigation_cost_currency?.toLowerCase() === getCode(filterCurrency)
              )
            : [];

        return months.map((_arg, key) => {
            const existingData = data.find((el) => el?.date_created__month === key + 1);
            return existingData ? existingData.total_loss : 0;
        });
    }, [overview, filterYear, filterCurrency]);

    const maxValue = useMemo(
        () =>
            values.reduce((max, item) => {
                return item > max ? item : max;
            }, 0),
        [values]
    );
    const MAX_ITEMS = 5;

    const data = {
        labels: months.map((el) => el.slice(0, 3)),
        datasets: [
            {
                // label: 'Resolved risk',
                data: values,
                backgroundColor: '#395BA9',
                borderWidth: 0,
            },
        ],
    };

    const options = useMemo(
        () => ({
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    font: {
                        size: 18,
                    },
                },
                tooltip: {
                    callbacks: {
                        // Customize the label shown in the tooltip
                        label: function (context) {
                            const month =
                                months.filter((el) => el?.toLowerCase().startsWith(context.label?.toLowerCase()))[0] ??
                                '';
                            return month;
                        },
                        // Optionally customize the title or other parts
                        title: function (context) {
                            return `-${filterCurrency}` + context[0].raw;
                        },
                    },
                    titleColor: '#ffffff',
                    font: {
                        weight: 'bold',
                        family: 'Poppins, sans-serif',
                    },
                    bodyFont: {
                        weight: 'normal',
                        family: 'Poppins, sans-serif',
                    },
                },
            },
            scales: {
                x: {
                    stacked: false,
                    title: {
                        display: false,
                        text: 'Month',
                    },
                    grid: {
                        display: false,
                    },
                    ticks: {
                        color: '#64748B',
                        font: { family: 'Poppins, sans-serif' },
                    },
                    border: {
                        display: false,
                    },
                    barPercentage: 1.0,
                    categoryPercentage: 1.0,
                },
                y: {
                    min: Math.min(...values),
                    stacked: false,
                    title: {
                        display: true,
                        text: `Amount loss(${filterCurrency})`,
                        color: '#475569',
                        font: {
                            weight: 'bolder',
                            family: 'Poppins, sans-serif',
                        },
                    },
                    grid: {
                        display: false,
                    },
                    border: {
                        display: false,
                    },
                    ticks: {
                        stepSize: Math.round(maxValue / MAX_ITEMS),
                        color: '#64748B',
                        font: { family: 'Poppins, sans-serif' },
                        callback: (value) => formatNumber(value),
                    },
                },
            },
        }),
        [overview?.financial_loss_by_year_month, filterCurrency]
    );

    function getYearsSinceJoining() {
        const currentYear = new Date().getFullYear();
        const years = [];

        for (let year = yearUserSignedUp; year <= currentYear; year++) {
            years.push(year);
        }

        return years;
    }

    // #64748B

    return (
        <div className="col-span-6 bg-white rounded-md border-[#f1f5f9] px-3 py-3">
            <Stack
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        color: '#2b3674',
                        fontWeight: 600,
                        width: '100%',
                    }}
                >
                    Financial loss by risk
                </Typography>

                <Stack
                    sx={{
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        gap: 1,
                    }}
                >
                    <Select
                        labelId="year-select-label"
                        id="year-select"
                        value={filterYear}
                        label="Years"
                        onChange={handleYearChange}
                        size="small"
                        sx={{
                            textTransform: 'capitalize',
                            backgroundColor: '#F8FAFC',
                            border: '1px solid #E2E8F0',

                            '& .MuiSelect-select': {
                                fontSize: 11,
                                paddingLeft: '10px',
                                paddingBlock: '3px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid #E2E8F0 !important',
                            },
                        }}
                        defaultValue={filterYear}
                        renderValue={(value) => (
                            <span className="flex gap-1 items-center">
                                <DateIcon className="w-4 h-4" />
                                {value}
                            </span>
                        )}
                    >
                        {getYearsSinceJoining().map((value, key) => (
                            <MenuItem sx={{ fontSize: 11, textTransform: 'capitalize' }} key={key} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>

                    <Select
                        labelId="currency-select-label"
                        id="currency-select"
                        value={filterCurrency}
                        label="Currency"
                        onChange={handleCurrencyChange}
                        size="small"
                        sx={{
                            textTransform: 'capitalize',
                            backgroundColor: '#F8FAFC',
                            border: '1px solid #E2E8F0',

                            '& .MuiSelect-select': {
                                fontSize: 11,
                                paddingLeft: '10px',
                                paddingBlock: '3px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid #E2E8F0 !important',
                            },
                        }}
                        defaultValue={'₦'}
                        renderValue={(value) => <span className="flex gap-1 items-center">{value}</span>}
                    >
                        {currencies.map((value, key) => (
                            <MenuItem sx={{ fontSize: 11, textTransform: 'capitalize' }} key={key} value={value.symbol}>
                                {value.code + ' ' + `(${value.symbol})`}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>
            </Stack>

            <div className="flex flex-col gap-8">
                <Box sx={{ mt: 3 }}>
                    <div>
                        <Bar data={data} options={options} />
                    </div>
                </Box>
            </div>
        </div>
    );
}
