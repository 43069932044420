import React, { useEffect, useState } from 'react';
import { CertificateDetails } from 'store/actions/auditActions';
import CircularLoader from 'components/Loaders/CircularLoader';
import { Card, Grid, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import smartColplyLogo from 'assets/img/icons/logo.svg';
import certificateSuccess from 'assets/img/icons/certificate-success.svg';
import certificateBg from 'assets/img/icons/certificate_bg.png';
import certificateLogo from 'assets/img/icons/certificate_logo.svg';
import greenRibbon from 'assets/img/green_ribbon.png';
import orangeRibbon from 'assets/img/orange_ribbon.png';
import { useTheme } from '@mui/styles';

const CertificateVeriffication = (props) => {
    const { CertificateDetails, certificate_details } = props;
    const { confirmation_token } = useParams();
    const theme = useTheme();

    const [loading, setLoading] = useState(false);
    const certificatesDetails = async () => {
        setLoading(true);
        const response = await CertificateDetails(confirmation_token);
        setLoading(false);
        if (!response?.success) {
            toast.error('Certificate');
        }
    };
    useEffect(() => {
        certificatesDetails();
    }, []);
    const date = moment(certificate_details?.date).format('DD-MM-YYYY').split('-');
    const day = date[0];
    const month =
        date[1] === '01'
            ? 'January'
            : date[1] === '02'
            ? 'February'
            : date[1] === '03'
            ? 'March'
            : date[1] === '04'
            ? 'April'
            : date[1] === '05'
            ? 'May'
            : date[1] === '06'
            ? 'June'
            : date[1] === '07'
            ? 'July'
            : date[1] === '08'
            ? 'August'
            : date[1] === '09'
            ? 'September'
            : date[1] === '10'
            ? 'October'
            : date[1] === '11'
            ? 'November'
            : date[1] === '12'
            ? 'December'
            : 'Invalid month';

    const year = date[2];
    return (
        <div className="w-full flex items-center relative justify-center m-auto overflow-hidden pt-[10vh] pb-[10vh] h-[100vh]">
            <div
                style={{ overflow: 'hidden' }}
                className="w-[80vh] h-[80vh] flex items-center justify-center m-auto overflow-hidden"
            >
                <img src={greenRibbon} className="absolute -left-[100px] top-0" />
                <div className="absolute -right-[100px] -bottom-[100px]">
                    <img src={orangeRibbon} />
                </div>
                <div className="absolute top-[5vh] right-[5vw]">
                    <img src={smartColplyLogo} alt="smartComply Logo" />
                </div>
                <div>
                    {loading ? (
                        <CircularLoader />
                    ) : certificate_details ? (
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ minHeight: '100vh', position: 'relative' }}
                        >
                            <Grid
                                item
                                xs={6}
                                elevation={0}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    transform: 'scale(0.8)',
                                    [theme.breakpoints.down('md')]: {
                                        width: '70%',
                                    },
                                }}
                            >
                                <img
                                    src={certificateBg}
                                    alt="certificate background"
                                    className="w-[754px] absolute left-0 right-0 top-[0px] bottom-0 m-auto -z-10 h-[742px]"
                                />

                                <Card
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        paddingInline: '90px',
                                        width: '754px',
                                        height: '100%',
                                        background: 'transparent',
                                        boxShadow: 'none',
                                        position: 'relative',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '24px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '32px',
                                                fontWeight: '700',
                                                color: '#395ba9',
                                                [theme.breakpoints.down('md')]: {
                                                    fontSize: '24px',
                                                },
                                            }}
                                        >
                                            Certificate Verified
                                        </Typography>
                                        <img src={certificateSuccess} alt="certificate success" />
                                    </Typography>
                                    <img src={certificateLogo} alt="certificate logo" className="mt-[41px] mb-[55px]" />
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#000000',
                                            textAlign: 'center',
                                        }}
                                    >
                                        This certificate was issued under the merchant compliance scheme by{' '}
                                        <strong>386konsult Limited</strong>, implemented by <strong>Smartcomply</strong>
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            color: '#000000',
                                            fontWeight: '500',
                                            mt: '48px',
                                            display: 'flex',
                                            justifySelf: 'flex-start',
                                            alignSelf: 'flex-start',
                                        }}
                                    >
                                        Name of Company:{' '}
                                        <span style={{ fontWeight: '600' }}>{certificate_details?.merchant_name}</span>
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            color: '#000000',
                                            mt: 2,
                                            display: 'flex',
                                            justifySelf: 'flex-start',
                                            alignSelf: 'flex-start',
                                        }}
                                    >
                                        Service Covered:
                                        <span style={{ fontWeight: '600' }}>
                                            {certificate_details?.service_covered}
                                        </span>
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            color: '#000000',
                                            mt: 2,
                                            display: 'flex',
                                            justifySelf: 'flex-start',
                                            alignSelf: 'flex-start',
                                        }}
                                    >
                                        <span className="pr-[3px]">Date Validity:</span>
                                        <span style={{ fontWeight: '600' }}>
                                            {day}, {month} {year}
                                        </span>
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ minHeight: '100vh', position: 'relative' }}
                        >
                            <Grid
                                item
                                xs={6}
                                elevation={0}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    transform: 'scale(0.8)',
                                }}
                            >
                                <img
                                    src={certificateBg}
                                    alt="certificate background"
                                    className="w-[754px] absolute left-0 right-0 top-[0px] bottom-0 m-auto -z-10 h-[742px]"
                                />
                                <Card
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        paddingInline: '90px',
                                        width: '754px',
                                        height: '100%',
                                        background: 'transparent',
                                        boxShadow: 'none',
                                        position: 'relative',
                                    }}
                                >
                                    <CloseIcon sx={{ color: 'red', width: '100px', height: '100px' }} />
                                    <Typography sx={{ fontSize: '32px', fontWeight: '700', color: '#000000' }}>
                                        Invalid Certificate
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        certificate_details: state?.auditReducers?.certificate_details,
        merchant_info: state?.merchantReducers?.merchant_info,
    };
};

export default connect(mapStateToProps, { CertificateDetails })(CertificateVeriffication);
