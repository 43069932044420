import React from 'react';

import AppDrawer from 'components/new_components/AppDrawer';
import AppTag from 'components/new_components/AppTags';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

//core components
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

//translations
import { useTranslation } from 'react-i18next';

const InfrastructureViewModal = (props) => {
    //props
    const { open, handleClose, title, width, getInfrastructureInfo } = props;
    //theme
    const theme = useTheme();

    //translation
    const { t } = useTranslation('inventory');

    return getInfrastructureInfo ? (
        <AppDrawer
            open={open}
            handleClose={handleClose}
            title={title}
            width={width}
            icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
            noShadow
            noClose
        >
            <Box sx={{ mx: 3.5, my: 2 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        {t('infrastructureViewModal.status')}: Scanned
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        {t('infrastructureViewModal.assetId')}:
                    </Typography>
                    <Typography
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '12px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                            textAlign: 'center',
                            backgroundColor: '#F1F5F9',
                        }}
                    >
                        {getInfrastructureInfo?.asset_id || 'N/A'}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        {t('infrastructureViewModal.name')}:
                    </Typography>
                    <Typography
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '12px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                            textAlign: 'center',
                        }}
                    >
                        {getInfrastructureInfo?.name}
                    </Typography>
                </Box>
                {getInfrastructureInfo?.asset_category_name && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderTop: '2px solid #F1F5F9',
                        }}
                    >
                        <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                            {t('infrastructureViewModal.category')}:
                        </Typography>
                        <Typography
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '12px',
                                width: '60%',
                                borderLeft: '2px solid #F1F5F9',
                                py: 1,
                                pl: 1,
                                textAlign: 'center',
                            }}
                        >
                            {getInfrastructureInfo?.asset_category_name}
                        </Typography>
                    </Box>
                )}
                {getInfrastructureInfo?.risk_category_name && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderTop: '2px solid #F1F5F9',
                        }}
                    >
                        <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                            {t('infrastructureViewModal.risk_category')}:
                        </Typography>
                        <Typography
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '12px',
                                width: '60%',
                                borderLeft: '2px solid #F1F5F9',
                                py: 1,
                                pl: 1,
                                textAlign: 'center',
                            }}
                        >
                            {getInfrastructureInfo?.risk_category_name}
                        </Typography>
                    </Box>
                )}
                {getInfrastructureInfo?.os && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderTop: '2px solid #F1F5F9',
                        }}
                    >
                        <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                            {t('infrastructureViewModal.os')}:
                        </Typography>
                        <Typography
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '12px',
                                width: '60%',
                                borderLeft: '2px solid #F1F5F9',
                                py: 1,
                                pl: 1,
                                textAlign: 'center',
                            }}
                        >
                            {getInfrastructureInfo?.os}
                        </Typography>
                    </Box>
                )}
                {getInfrastructureInfo?.owner_email && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderTop: '2px solid #F1F5F9',
                        }}
                    >
                        <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                            {t('infrastructureViewModal.asset_owner')}:
                        </Typography>
                        <Typography
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '12px',
                                width: '60%',
                                borderLeft: '2px solid #F1F5F9',
                                py: 1,
                                pl: 1,
                                textAlign: 'center',
                                textTransform: 'capitalize',
                            }}
                        >
                            {getInfrastructureInfo?.owner_email}
                        </Typography>
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        {t('infrastructureViewModal.asset_type')}:
                    </Typography>
                    <Typography
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '12px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                            textAlign: 'center',
                            textTransform: 'capitalize',
                        }}
                    >
                        {getInfrastructureInfo?.asset_type} Asset
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        {t('infrastructureViewModal.assetIp')}:
                    </Typography>
                    <Typography
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '12px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                            textAlign: 'center',
                        }}
                    >
                        {getInfrastructureInfo?.ip_address}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        {t('infrastructureViewModal.location')}:
                    </Typography>
                    <Typography
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '12px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                            textAlign: 'center',
                        }}
                    >
                        {getInfrastructureInfo?.location || 'N/A'}
                    </Typography>
                </Box>
                {getInfrastructureInfo?.sensitivity && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderTop: '2px solid #F1F5F9',
                        }}
                    >
                        <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                            {t('infrastructureViewModal.sensitivity')}:
                        </Typography>
                        <Typography
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '12px',
                                width: '60%',
                                borderLeft: '2px solid #F1F5F9',
                                py: 1,
                                pl: 1,
                                textAlign: 'center',
                            }}
                        >
                            {getInfrastructureInfo?.sensitivity?.toLowerCase() === 'low' ? (
                                <AppTag
                                    text="Low"
                                    icon={
                                        <FiberManualRecordIcon
                                            sx={{ width: 12, height: 12, color: theme.palette.success[500], mr: 0.5 }}
                                        />
                                    }
                                    type="default"
                                    noRounded
                                    noItalic
                                    sx={{
                                        padding: '4px 8px',
                                        border: 'none',
                                    }}
                                />
                            ) : getInfrastructureInfo?.sensitivity?.toLowerCase() === 'medium' ? (
                                <AppTag
                                    text="Medium"
                                    icon={
                                        <FiberManualRecordIcon
                                            sx={{ width: 12, height: 12, color: theme.palette.warning[900], mr: 0.5 }}
                                        />
                                    }
                                    type="default"
                                    noRounded
                                    noItalic
                                    sx={{
                                        padding: '4px 8px',
                                        border: 'none',
                                    }}
                                />
                            ) : (
                                <AppTag
                                    text="High"
                                    icon={
                                        <FiberManualRecordIcon
                                            sx={{ width: 12, height: 12, color: theme.palette.error[500], mr: 0.5 }}
                                        />
                                    }
                                    type="default"
                                    noRounded
                                    noItalic
                                    sx={{
                                        padding: '4px 8px',
                                        border: 'none',
                                    }}
                                />
                            )}
                        </Typography>
                    </Box>
                )}
                {getInfrastructureInfo?.group && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderTop: '2px solid #F1F5F9',
                        }}
                    >
                        <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                            {t('infrastructureViewModal.asset_group')}:
                        </Typography>
                        <Typography
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '12px',
                                width: '60%',
                                borderLeft: '2px solid #F1F5F9',
                                py: 1,
                                pl: 1,
                                textAlign: 'center',
                            }}
                        >
                            {getInfrastructureInfo?.group}
                        </Typography>
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        {t('infrastructureViewModal.vulScan')}:
                    </Typography>
                    <Typography
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '12px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                            textAlign: 'center',
                        }}
                    >
                        {getInfrastructureInfo?.vul_scan ? 'True' : 'False'}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        {t('infrastructureViewModal.pciScan')}:
                    </Typography>
                    <Typography
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '12px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                            textAlign: 'center',
                        }}
                    >
                        {getInfrastructureInfo?.pci_scan ? 'True' : 'False'}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '40%', py: 1 }}>
                        {t('infrastructureViewModal.apiCompliance')}:
                    </Typography>
                    <Typography
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '12px',
                            width: '60%',
                            borderLeft: '2px solid #F1F5F9',
                            py: 1,
                            pl: 1,
                            textAlign: 'center',
                        }}
                    >
                        {getInfrastructureInfo?.api_compliance ? 'True' : 'False'}
                    </Typography>
                </Box>
            </Box>
        </AppDrawer>
    ) : null;
};

export default InfrastructureViewModal;
