/* eslint-disable no-unused-vars */

import { Dialog, Menu, MenuItem, Select, Tooltip, tooltipClasses } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import https from '../../../../../../../utils/https';

export default function RiskAssessmentSettingsGeneralTab({
    riskGeneralTabStates,
    setRiskGeneralTabStates,
    localManagementApprovers,
    setLocalManagementApprovers,
}) {
    const [localRiskLevel, setLocalRiskLevel] = useState(25);

    function setUserIDs(userIDs) {
        setRiskGeneralTabStates((prev) => ({
            ...prev,
            userIDs,
        }));
    }

    function setPeopleIDs(peopleIDs) {
        setRiskGeneralTabStates((prev) => ({
            ...prev,
            peopleIDs,
        }));
    }

    useEffect(() => {
        setLocalRiskLevel(riskGeneralTabStates.riskLevel);
    }, [riskGeneralTabStates.riskLevel]);

    return (
        <div className="bg-white rounded-lg max-w-[873px] w-full mx-auto">
            <div className="border-b flex items-center justify-between border-[#E2E8F0] !p-6">
                <div className="w-[55%] flex flex-col gap-1">
                    <h3 className="text-[#395BA9] font-semibold">Target risk level</h3>

                    <p className="text-[#64748B] text-sm">
                        Acceptable level of risk that an organization is willing to tolerate for a particular activity
                        or process
                    </p>
                </div>

                <div className="flex items-center gap-3">
                    <div className="flex items-center text-[#395BA9] font-bold text-2xl">
                        <input
                            type="number"
                            inputMode="numeric"
                            value={localRiskLevel}
                            onChange={(event) => {
                                setLocalRiskLevel(
                                    Number(event.target.value) > 100
                                        ? 100
                                        : Number(event.target.value) < 0
                                        ? 0
                                        : Number(event.target.value)
                                );
                            }}
                            onBlur={(event) => {
                                setRiskGeneralTabStates((prev) => ({
                                    ...prev,
                                    riskLevel: event.target.value
                                        ? Number(event.target.value) > 100
                                            ? setRiskGeneralTabStates((prev) => ({
                                                  ...prev,
                                                  riskLevel: 100,
                                              }))
                                            : Number(event.target.value) < 0
                                            ? setRiskGeneralTabStates((prev) => ({
                                                  ...prev,
                                                  riskLevel: 0,
                                              }))
                                            : Number(event.target.value)
                                        : 0,
                                }));
                            }}
                            min={0}
                            max={100}
                            step={1}
                            className="remove-default-input-arrows focus-visible:outline-none text-right"
                        />

                        <p>%</p>
                    </div>

                    <div className="flex flex-col gap-0.5 justify-center items-center">
                        <img
                            src="/img/risk-assessment/arrow-up-icon.png"
                            alt="Arrow Up Icon"
                            className="object-contain hover:no-underline hover:scale-90 transition-transform cursor-pointer aria-disabled:opacity-50 aria-disabled:pointer-events-none"
                            aria-disabled={riskGeneralTabStates.riskLevel === 100}
                            onClick={() =>
                                riskGeneralTabStates.riskLevel < 100 &&
                                setRiskGeneralTabStates((prev) => ({
                                    ...prev,
                                    riskLevel: prev.riskLevel + 1,
                                }))
                            }
                        />

                        <img
                            src="/img/risk-assessment/arrow-down-icon.png"
                            alt="Arrow Down Icon"
                            className="object-contain hover:no-underline hover:scale-90 transition-transform cursor-pointer aria-disabled:opacity-50 aria-disabled:pointer-events-none"
                            aria-disabled={riskGeneralTabStates.riskLevel === 0}
                            onClick={() =>
                                riskGeneralTabStates.riskLevel > 0 &&
                                setRiskGeneralTabStates((prev) => ({
                                    ...prev,
                                    riskLevel: prev.riskLevel - 1,
                                }))
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col gap-2 border-b border-[#E2E8F0] !p-6">
                <div className="flex items-center justify-between">
                    <div className="w-[55%] flex flex-col gap-1">
                        <h3 className="text-[#395BA9] font-semibold">Management Approval</h3>

                        <p className="text-[#64748B] text-sm">
                            Choose who approves risk risk mitigations. You can add multiple approvers
                        </p>
                    </div>

                    <CustomSwitch
                        checked={riskGeneralTabStates.managementApproval}
                        onChange={(event, checked) => {
                            if (!localManagementApprovers.length && checked) {
                                toast.error('Please add at least one approver before enabling management approval');
                                return;
                            }

                            setRiskGeneralTabStates((prev) => ({
                                ...prev,
                                managementApproval: checked,
                            }));
                        }}
                    />
                </div>

                <AddApprovalSection
                    userIDs={riskGeneralTabStates.userIDs}
                    peopleIDs={riskGeneralTabStates.peopleIDs}
                    setUserIDs={setUserIDs}
                    setPeopleIDs={setPeopleIDs}
                    localManagementApprovers={localManagementApprovers}
                    setLocalManagementApprovers={setLocalManagementApprovers}
                    setRiskGeneralTabStates={setRiskGeneralTabStates}
                />
            </div>

            <div className="flex flex-col gap-2 border-b border-[#E2E8F0] !p-6">
                <div className="flex items-center justify-between">
                    <div className="w-[90%] flex flex-col gap-1">
                        <h3 className="text-[#395BA9] font-semibold">Risk Evaluation</h3>

                        <p className="text-[#64748B] text-sm">
                            Assess potential risks using different methods, such as numeric (quantitative) and
                            descriptive (qualitative) approaches, to determine the likelihood and impact on a project or
                            organization.
                        </p>
                    </div>
                </div>
                <div>
                    <Select
                        value={riskGeneralTabStates?.risk_approach}
                        onChange={(event) => {
                            setRiskGeneralTabStates((prev) => ({
                                ...prev,
                                risk_approach: event.target.value,
                            }));
                        }}
                        renderValue={(value) => (
                            <p className="text-[#64748B] font-medium text-sm">
                                <span className="capitalize">{value.replace('_', ' ')}</span>
                            </p>
                        )}
                        sx={{
                            '& .MuiSelect-select': {
                                minHeight: 'auto',
                                padding: '6px 8px',
                                paddingRight: '28px !important',
                            },
                            '& .MuiSelect-icon': {
                                right: '4px',
                                fontSize: '20px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#E2E8F0',
                                borderWidth: '1px !important',
                            },
                        }}
                    >
                        <MenuItem
                            value="qualitative"
                            sx={{
                                padding: '6px 16px',
                                color: '#64748B',
                                fontWeight: '500',
                                fontSize: '13px',
                            }}
                        >
                            Qualitative
                        </MenuItem>

                        <MenuItem
                            value="quantitative"
                            sx={{
                                padding: '6px 16px',
                                color: '#64748B',
                                fontWeight: '500',
                                fontSize: '13px',
                            }}
                        >
                            Quantitative
                        </MenuItem>
                    </Select>
                </div>
                <p className="text-[#64748B] text-sm font-normal">
                    {riskGeneralTabStates?.risk_approach === 'qualitative'
                        ? 'Evaluate risk using descriptive categories like high, medium, or low'
                        : 'Evaluate risk using numerical data, such as assigning scores of 1 to 5 for likelihood and impact'}
                </p>
            </div>
            <div className="flex flex-col gap-4 !px-4 !py-6">
                <h3 className="text-[#395BA9] font-semibold text-[15px]">Risk appetite option</h3>

                <p className="!py-2 text-[#64748B] text-[13px]">
                    Risk appetite is an organization's comfort level with risk. It defines how much risk they're willing
                    to take to achieve their goals.
                </p>

                <MatrixThreeAppetite
                    riskAppetiteMatrixThree={riskGeneralTabStates.riskAppetite.matrixThree}
                    by3Level={riskGeneralTabStates.by3Level}
                    setRiskGeneralTabStates={setRiskGeneralTabStates}
                />

                <MatrixFiveAppetite
                    riskAppetiteMatrixFive={riskGeneralTabStates.riskAppetite.matrixFive}
                    by5Level={riskGeneralTabStates.by5Level}
                    setRiskGeneralTabStates={setRiskGeneralTabStates}
                />
            </div>
        </div>
    );
}

function CustomTooltip({ children, title, placement }) {
    const CustomizedTooltip = styled(({ className, ...props }) => (
        <Tooltip
            {...props}
            placement={placement || 'top'}
            disableFocusListener
            disableTouchListener
            arrow
            classes={{ popper: className }}
        />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            borderRadius: '2px',
            textAlign: 'center',
            padding: '6px 8px',
            maxWidth: '207px',
        },
    }));

    return <CustomizedTooltip title={title}>{children}</CustomizedTooltip>;
}

function CustomSwitch({ checked, onChange }) {
    const CustomizedSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 52,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(26px)',
                color: '#fff',
                '& .MuiSwitch-thumb': {
                    backgroundColor: '#F1F5F9',
                    '&:: before': {
                        backgroundImage: `url('data:image/svg+xml;utf8,<svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.36665 9.00104L0.56665 5.20104L1.51665 4.25104L4.36665 7.10104L10.4833 0.984375L11.4333 1.93437L4.36665 9.00104Z" fill="${encodeURIComponent(
                            '#334155'
                        )}"/></svg>')`,
                    },
                },
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#62DF98',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            width: 22,
            height: 22,
            backgroundColor: '#FFFFFF',
            '&::before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.26659 9.66537L0.333252 8.73203L4.06659 4.9987L0.333252 1.26536L1.26659 0.332031L4.99992 4.06536L8.73325 0.332031L9.66659 1.26536L5.93325 4.9987L9.66659 8.73203L8.73325 9.66537L4.99992 5.93203L1.26659 9.66537Z" fill="${encodeURIComponent(
                    '#94A3B8'
                )}"/></svg>')`,
            },
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E2E8F0' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    return (
        <FormControlLabel
            control={<CustomizedSwitch sx={{ m: 0 }} />}
            checked={checked}
            onChange={onChange}
            sx={{ m: 0 }}
        />
    );
}

function AddApprovalSection({
    userIDs,
    peopleIDs,
    setUserIDs,
    setPeopleIDs,
    localManagementApprovers,
    setLocalManagementApprovers,
    setRiskGeneralTabStates,
}) {
    const [isAddApproverDialogOpen, setIsAddApproverDialogOpen] = useState(false);
    const [localUsers, setLocalUsers] = useState([]);
    const [localPeople, setLocalPeople] = useState([]);
    const [searchApprover, setSearchApprover] = useState('');
    const [selectedApprover, setSelectedApprover] = useState(null);
    const [approversAnchorEl, setApproversAnchorEl] = useState(null);

    const userDetails = useSelector((state) => state?.generalReducers?.user_info);

    const organizationEmployees = useQuery({
        queryKey: ['organizationEmployees', userDetails?.organization?.id],
        queryFn: () => getOrganizationEmployees(userDetails?.organization?.id),
    });

    const defaultApprovers = [...localUsers, ...localPeople]
        .map((approver) => ({
            ...approver,
            label: `${approver?.first_name} ${approver?.last_name}`,
        }))
        .filter((approver) => !localManagementApprovers.includes(approver?.id));

    useEffect(() => {
        if (organizationEmployees?.data) {
            setLocalUsers(
                organizationEmployees.data?.users?.map((user) => ({
                    ...user,
                    type: 'user',
                }))
            );

            setLocalPeople(
                organizationEmployees.data?.people?.map((person) => ({
                    ...person,
                    type: 'person',
                }))
            );
        }
    }, [organizationEmployees?.data]);

    if (organizationEmployees?.isLoading) {
        return null;
    }

    return (
        <>
            <div className="flex flex-col gap-4">
                <button
                    className="flex items-center gap-1 hover:brightness-75 transition-all"
                    onClick={() => setIsAddApproverDialogOpen(true)}
                    hidden={defaultApprovers.length === 0}
                >
                    <img src="/img/risk-assessment/PlusCircle.svg" alt="Plus Icon" className="object-contain" />

                    <p className="font-medium text-[13px] text-[#395BA9]">Add approver</p>
                </button>

                {localManagementApprovers.length ? (
                    <div className="flex items-center gap-4">
                        <img src="/img/risk-assessment/users-01.svg" alt="People Icon" className="object-contain" />

                        <div className="flex items-center flex-wrap gap-2">
                            {localManagementApprovers.map((approver) => {
                                const currentApprover =
                                    localUsers.find((user) => user.id === approver) ||
                                    localPeople.find((person) => person.id === approver);

                                if (!currentApprover) {
                                    return null;
                                }

                                return (
                                    <button
                                        key={currentApprover?.id}
                                        className="flex items-center gap-2 !border !border-[#F1F5F9] bg-[#F8FAFC] rounded !py-1 !px-2 hover:brightness-90 transition-all"
                                        onClick={() => {
                                            setLocalManagementApprovers((prev) =>
                                                prev.filter((prevApprover) => prevApprover !== currentApprover?.id)
                                            );

                                            if (currentApprover?.type === 'user') {
                                                setUserIDs(
                                                    userIDs.filter(
                                                        (prevApprover) => prevApprover !== currentApprover?.id
                                                    )
                                                );
                                            } else {
                                                setPeopleIDs(
                                                    peopleIDs.filter(
                                                        (prevApprover) => prevApprover !== currentApprover?.id
                                                    )
                                                );
                                            }
                                            if (localManagementApprovers?.length === 1) {
                                                setRiskGeneralTabStates((prev) => ({
                                                    ...prev,
                                                    managementApproval: false,
                                                }));
                                            }
                                        }}
                                    >
                                        <p className="font-normal capitalize text-sm text-[#64748B]">
                                            {currentApprover?.first_name} {currentApprover?.last_name}
                                        </p>

                                        <img
                                            src="/img/risk-assessment/x-close.svg"
                                            alt="Delete Icon"
                                            className="object-contain"
                                        />
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                ) : null}
            </div>

            <Dialog
                open={isAddApproverDialogOpen}
                onClose={() => setIsAddApproverDialogOpen(false)}
                PaperProps={{
                    sx: {
                        borderRadius: '4px !important',
                        padding: 0,
                        boxShadow: '0px 16px 24px 0px #00000026',
                        width: '406px',
                    },
                }}
            >
                <div className="flex items-center justify-between px-6 !pt-6">
                    <h5 className="text-lg font-semibold text-[#202D66]">Add approver</h5>

                    <button
                        className="border-[#E2E8F0] border rounded-full !p-1 h-6 w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                        onClick={() => setIsAddApproverDialogOpen(false)}
                    >
                        <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                    </button>
                </div>

                <div className="!p-6 flex flex-col gap-2">
                    <p className="font-medium text-[13px] text-[#64748B]">Name of person</p>

                    <div>
                        <button
                            aria-controls={approversAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={approversAnchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setApproversAnchorEl(event.currentTarget);
                            }}
                            className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                        >
                            <p
                                className={`font-normal text-sm ${
                                    selectedApprover ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                                }`}
                            >
                                {selectedApprover ? selectedApprover?.label : 'Enter name'}
                            </p>
                        </button>

                        <Menu
                            anchorEl={approversAnchorEl}
                            open={Boolean(approversAnchorEl)}
                            onClose={() => setApproversAnchorEl(null)}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow:
                                        '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                        >
                            <div className="flex flex-col !py-2 !min-w-[336px]">
                                <div className="!py-[5px] !px-3 flex flex-col gap-2.5">
                                    <p className="border-b border-[#F1F5F9] !py-1 !px-3 text-[#64748B] font-medium text-xs">
                                        Search name
                                    </p>

                                    <div className="relative">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="w-full !py-2.5 !pr-2.5 !pl-8 !border !border-[#E2E8F0] h-7 text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                            value={searchApprover}
                                            onChange={(event) => setSearchApprover(event.target.value)}
                                        />

                                        <img
                                            src="/img/risk-assessment/ri_search-line-small.svg"
                                            alt="Search Icon"
                                            className="absolute object-contain top-1/2 -translate-y-1/2 left-[10px]"
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col max-h-[180px] overflow-y-auto">
                                    {defaultApprovers
                                        .filter((approver) =>
                                            approver?.label.toLowerCase().includes(searchApprover.toLowerCase())
                                        )
                                        .map((approver, index) => (
                                            <button
                                                key={index}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setSelectedApprover(approver);
                                                    setApproversAnchorEl(null);
                                                }}
                                                className="!py-2 !px-3 text-[#475569] font-medium text-[13px] hover:bg-[#F1F5F9] transition-colors capitalize !text-left"
                                            >
                                                {approver?.label}
                                            </button>
                                        ))}
                                </div>
                            </div>
                        </Menu>
                    </div>
                </div>

                <div className="flex items-center justify-end !p-6 bg-[#F8FAFC] gap-2">
                    <button
                        className="bg-[#fff] border border-[#E2E8F0] rounded-sm !px-4 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                        onClick={() => setIsAddApproverDialogOpen(false)}
                    >
                        Cancel
                    </button>

                    <button
                        className="bg-[#202D66] rounded-sm !px-4 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none"
                        disabled={!selectedApprover}
                        onClick={() => {
                            if (selectedApprover) {
                                setLocalManagementApprovers((prev) => [...prev, selectedApprover?.id]);

                                if (selectedApprover.type === 'user') {
                                    setUserIDs([...userIDs, selectedApprover?.id]);
                                } else {
                                    setPeopleIDs([...peopleIDs, selectedApprover?.id]);
                                }

                                setIsAddApproverDialogOpen(false);
                            }
                        }}
                    >
                        Save
                    </button>
                </div>
            </Dialog>
        </>
    );
}

function MatrixThreeAppetite({ riskAppetiteMatrixThree, by3Level, setRiskGeneralTabStates }) {
    return (
        <div className="flex flex-col gap-4">
            <div className="flex items-center gap-1">
                <p className="text-[#395BA9] font-medium text-[13px]">Select your risk appetite 3x3</p>

                <CustomTooltip
                    title="Available mitigation responses will adjust based on your selected risk appetite."
                    placement="top"
                >
                    <img src="/img/risk-assessment/help-circle.svg" alt="Help Icon" className="object-contain" />
                </CustomTooltip>
            </div>

            <div>
                <Select
                    value={by3Level}
                    onChange={(event) => {
                        setRiskGeneralTabStates((prev) => ({
                            ...prev,
                            by3Level: event.target.value,
                        }));
                    }}
                    renderValue={(value) => (
                        <p className="text-[#64748B] font-medium text-sm">
                            <span className="capitalize">{value.replace('_', ' ')}</span> appetite
                        </p>
                    )}
                    sx={{
                        '& .MuiSelect-select': {
                            minHeight: 'auto',
                            padding: '6px 8px',
                            paddingRight: '28px !important',
                        },
                        '& .MuiSelect-icon': {
                            right: '4px',
                            fontSize: '20px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#E2E8F0',
                            borderWidth: '1px !important',
                        },
                    }}
                >
                    <MenuItem
                        value="low"
                        sx={{
                            padding: '6px 16px',
                            color: '#64748B',
                            fontWeight: '500',
                            fontSize: '13px',
                        }}
                    >
                        Low
                    </MenuItem>

                    <MenuItem
                        value="medium"
                        sx={{
                            padding: '6px 16px',
                            color: '#64748B',
                            fontWeight: '500',
                            fontSize: '13px',
                        }}
                    >
                        Medium
                    </MenuItem>

                    <MenuItem
                        value="high"
                        sx={{
                            padding: '6px 16px',
                            color: '#64748B',
                            fontWeight: '500',
                            fontSize: '13px',
                        }}
                    >
                        High
                    </MenuItem>
                </Select>
            </div>

            <div>
                <CustomTooltip
                    title="Select the actions you want to include in your mitigation plan."
                    placement="bottom"
                >
                    <div className="flex items-center gap-2 w-fit">
                        <button
                            className={`flex items-center gap-1 !py-1.5 !px-3 rounded-3xl border transition-all hover:brightness-90 ${
                                by3Level === 'low' && riskAppetiteMatrixThree?.low?.includes('mitigate')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : by3Level === 'medium' && riskAppetiteMatrixThree?.medium?.includes('mitigate')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : by3Level === 'high' && riskAppetiteMatrixThree?.high?.includes('mitigate')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : 'bg-[#fff] !border-[#E2E8F0]'
                            }`}
                            onClick={() => {
                                if (by3Level === 'low') {
                                    const toggleState = riskAppetiteMatrixThree?.low?.includes('mitigate')
                                        ? riskAppetiteMatrixThree?.low?.filter((state) => state !== 'mitigate')
                                        : [...riskAppetiteMatrixThree.low, 'mitigate'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev.riskAppetite,
                                            matrixThree: {
                                                ...prev.riskAppetite?.matrixThree,
                                                low: toggleState,
                                            },
                                        },
                                    }));
                                } else if (by3Level === 'medium') {
                                    const toggleState = riskAppetiteMatrixThree?.medium?.includes('mitigate')
                                        ? riskAppetiteMatrixThree?.medium?.filter((state) => state !== 'mitigate')
                                        : [...riskAppetiteMatrixThree.medium, 'mitigate'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev.riskAppetite,
                                            matrixThree: {
                                                ...prev.riskAppetite?.matrixThree,
                                                medium: toggleState,
                                            },
                                        },
                                    }));
                                } else {
                                    const toggleState = riskAppetiteMatrixThree?.high?.includes('mitigate')
                                        ? riskAppetiteMatrixThree?.high?.filter((state) => state !== 'mitigate')
                                        : [...riskAppetiteMatrixThree.high, 'mitigate'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixThree: {
                                                ...prev.riskAppetite?.matrixThree,
                                                high: toggleState,
                                            },
                                        },
                                    }));
                                }
                            }}
                        >
                            <img
                                src="/img/risk-assessment/tick-circle.svg"
                                alt="Checkmark Icon"
                                className="object-contain"
                                hidden={
                                    (by3Level === 'low' && !riskAppetiteMatrixThree?.low?.includes('mitigate')) ||
                                    (by3Level === 'medium' && !riskAppetiteMatrixThree?.medium?.includes('mitigate')) ||
                                    (by3Level === 'high' && !riskAppetiteMatrixThree?.high?.includes('mitigate'))
                                }
                            />

                            <p
                                className={`text-sm font-medium ${
                                    by3Level === 'low' && riskAppetiteMatrixThree?.low?.includes('mitigate')
                                        ? 'text-[#059669]'
                                        : by3Level === 'medium' && riskAppetiteMatrixThree?.medium?.includes('mitigate')
                                        ? 'text-[#059669]'
                                        : by3Level === 'high' && riskAppetiteMatrixThree?.high?.includes('mitigate')
                                        ? 'text-[#059669]'
                                        : 'text-[#64748B]'
                                }`}
                            >
                                Mitigate
                            </p>
                        </button>

                        <button
                            className={`flex items-center gap-1 !py-1.5 !px-3 rounded-3xl border transition-all hover:brightness-90 ${
                                by3Level === 'low' && riskAppetiteMatrixThree?.low?.includes('accept')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : by3Level === 'medium' && riskAppetiteMatrixThree?.medium?.includes('accept')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : by3Level === 'high' && riskAppetiteMatrixThree?.high?.includes('accept')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : 'bg-[#fff] !border-[#E2E8F0]'
                            }`}
                            onClick={() => {
                                if (by3Level === 'low') {
                                    const toggleState = riskAppetiteMatrixThree.low.includes('accept')
                                        ? riskAppetiteMatrixThree.low.filter((state) => state !== 'accept')
                                        : [...riskAppetiteMatrixThree.low, 'accept'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev.riskAppetite,
                                            matrixThree: {
                                                ...prev.riskAppetite.matrixThree,
                                                low: toggleState,
                                            },
                                        },
                                    }));
                                } else if (by3Level === 'medium') {
                                    const toggleState = riskAppetiteMatrixThree.medium.includes('accept')
                                        ? riskAppetiteMatrixThree.medium.filter((state) => state !== 'accept')
                                        : [...riskAppetiteMatrixThree.medium, 'accept'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev.riskAppetite,
                                            matrixThree: {
                                                ...prev.riskAppetite.matrixThree,
                                                medium: toggleState,
                                            },
                                        },
                                    }));
                                } else {
                                    const toggleState = riskAppetiteMatrixThree.high.includes('accept')
                                        ? riskAppetiteMatrixThree.high.filter((state) => state !== 'accept')
                                        : [...riskAppetiteMatrixThree.high, 'accept'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev.riskAppetite,
                                            matrixThree: {
                                                ...prev?.riskAppetite?.matrixThree,
                                                high: toggleState,
                                            },
                                        },
                                    }));
                                }
                            }}
                        >
                            <img
                                src="/img/risk-assessment/tick-circle.svg"
                                alt="Checkmark Icon"
                                className="object-contain"
                                hidden={
                                    (by3Level === 'low' && !riskAppetiteMatrixThree?.low?.includes('accept')) ||
                                    (by3Level === 'medium' && !riskAppetiteMatrixThree?.medium?.includes('accept')) ||
                                    (by3Level === 'high' && !riskAppetiteMatrixThree?.high?.includes('accept'))
                                }
                            />

                            <p
                                className={`text-sm font-medium ${
                                    by3Level === 'low' && riskAppetiteMatrixThree?.low?.includes('accept')
                                        ? 'text-[#059669]'
                                        : by3Level === 'medium' && riskAppetiteMatrixThree?.medium?.includes('accept')
                                        ? 'text-[#059669]'
                                        : 'text-[#64748B]'
                                }`}
                            >
                                Accept
                            </p>
                        </button>

                        <button
                            className={`flex items-center gap-1 !py-1.5 !px-3 rounded-3xl border transition-all hover:brightness-90 ${
                                by3Level === 'low' && riskAppetiteMatrixThree?.low?.includes('avoid')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : by3Level === 'medium' && riskAppetiteMatrixThree?.medium?.includes('avoid')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : by3Level === 'high' && riskAppetiteMatrixThree?.high?.includes('avoid')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : 'bg-[#fff] !border-[#E2E8F0]'
                            }`}
                            onClick={() => {
                                if (by3Level === 'low') {
                                    const toggleState = riskAppetiteMatrixThree?.low?.includes('avoid')
                                        ? riskAppetiteMatrixThree?.low?.filter((state) => state !== 'avoid')
                                        : [...riskAppetiteMatrixThree.low, 'avoid'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev.riskAppetite,
                                            matrixThree: {
                                                ...prev.riskAppetite?.matrixThree,
                                                low: toggleState,
                                            },
                                        },
                                    }));
                                } else if (by3Level === 'medium') {
                                    const toggleState = riskAppetiteMatrixThree?.medium.includes('avoid')
                                        ? riskAppetiteMatrixThree?.medium.filter((state) => state !== 'avoid')
                                        : [...riskAppetiteMatrixThree.medium, 'avoid'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixThree: {
                                                ...prev?.riskAppetite?.matrixThree,
                                                medium: toggleState,
                                            },
                                        },
                                    }));
                                } else {
                                    const toggleState = riskAppetiteMatrixThree?.high?.includes('avoid')
                                        ? riskAppetiteMatrixThree?.high?.filter((state) => state !== 'avoid')
                                        : [...riskAppetiteMatrixThree.high, 'avoid'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixThree: {
                                                ...prev.riskAppetite?.matrixThree,
                                                high: toggleState,
                                            },
                                        },
                                    }));
                                }
                            }}
                        >
                            <img
                                src="/img/risk-assessment/tick-circle.svg"
                                alt="Checkmark Icon"
                                className="object-contain"
                                hidden={
                                    (by3Level === 'low' && !riskAppetiteMatrixThree?.low?.includes('avoid')) ||
                                    (by3Level === 'medium' && !riskAppetiteMatrixThree?.medium?.includes('avoid')) ||
                                    (by3Level === 'high' && !riskAppetiteMatrixThree?.high?.includes('avoid'))
                                }
                            />

                            <p
                                className={`text-sm font-medium ${
                                    by3Level === 'low' && riskAppetiteMatrixThree?.low?.includes('avoid')
                                        ? 'text-[#059669]'
                                        : by3Level === 'medium' && riskAppetiteMatrixThree?.medium?.includes('avoid')
                                        ? 'text-[#059669]'
                                        : by3Level === 'high' && riskAppetiteMatrixThree?.high?.includes('avoid')
                                        ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                        : 'text-[#64748B]'
                                }`}
                            >
                                Avoid
                            </p>
                        </button>

                        <button
                            className={`flex items-center gap-1 !py-1.5 !px-3 rounded-3xl border transition-all hover:brightness-90 ${
                                by3Level === 'low' && riskAppetiteMatrixThree?.low?.includes('transfer')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : by3Level === 'medium' && riskAppetiteMatrixThree?.medium?.includes('transfer')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : by3Level === 'high' && riskAppetiteMatrixThree?.high?.includes('transfer')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : 'bg-[#fff] !border-[#E2E8F0]'
                            }`}
                            onClick={() => {
                                if (by3Level === 'low') {
                                    const toggleState = riskAppetiteMatrixThree?.low?.includes('transfer')
                                        ? riskAppetiteMatrixThree?.low?.filter((state) => state !== 'transfer')
                                        : [...riskAppetiteMatrixThree.low, 'transfer'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixThree: {
                                                ...prev?.riskAppetite?.matrixThree,
                                                low: toggleState,
                                            },
                                        },
                                    }));
                                } else if (by3Level === 'medium') {
                                    const toggleState = riskAppetiteMatrixThree.medium.includes('transfer')
                                        ? riskAppetiteMatrixThree.medium.filter((state) => state !== 'transfer')
                                        : [...riskAppetiteMatrixThree.medium, 'transfer'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixThree: {
                                                ...prev?.riskAppetite?.matrixThree,
                                                medium: toggleState,
                                            },
                                        },
                                    }));
                                } else {
                                    const toggleState = riskAppetiteMatrixThree?.high?.includes('transfer')
                                        ? riskAppetiteMatrixThree?.high?.filter((state) => state !== 'transfer')
                                        : [...riskAppetiteMatrixThree.high, 'transfer'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixThree: {
                                                ...prev?.riskAppetite?.matrixThree,
                                                high: toggleState,
                                            },
                                        },
                                    }));
                                }
                            }}
                        >
                            <img
                                src="/img/risk-assessment/tick-circle.svg"
                                alt="Checkmark Icon"
                                className="object-contain"
                                hidden={
                                    (by3Level === 'low' && !riskAppetiteMatrixThree?.low?.includes('transfer')) ||
                                    (by3Level === 'medium' && !riskAppetiteMatrixThree?.medium?.includes('transfer')) ||
                                    (by3Level === 'high' && !riskAppetiteMatrixThree?.high?.includes('transfer'))
                                }
                            />

                            <p
                                className={`text-sm font-medium ${
                                    by3Level === 'low' && riskAppetiteMatrixThree?.low?.includes('transfer')
                                        ? 'text-[#059669]'
                                        : by3Level === 'medium' && riskAppetiteMatrixThree?.medium?.includes('transfer')
                                        ? 'text-[#059669]'
                                        : by3Level === 'high' && riskAppetiteMatrixThree?.high?.includes('transfer')
                                        ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                        : 'text-[#64748B]'
                                }`}
                            >
                                Transfer
                            </p>
                        </button>
                    </div>
                </CustomTooltip>
            </div>
        </div>
    );
}

function MatrixFiveAppetite({ riskAppetiteMatrixFive, by5Level, setRiskGeneralTabStates }) {
    return (
        <div className="flex flex-col gap-4">
            <div className="flex items-center gap-1">
                <p className="text-[#395BA9] font-medium text-[13px]">Select your risk appetite 5x5</p>

                <CustomTooltip
                    title="Available mitigation responses will adjust based on your selected risk appetite."
                    placement="top"
                >
                    <img src="/img/risk-assessment/help-circle.svg" alt="Help Icon" className="object-contain" />
                </CustomTooltip>
            </div>

            <div>
                <Select
                    value={by5Level}
                    onChange={(event) => {
                        setRiskGeneralTabStates((prev) => ({
                            ...prev,
                            by5Level: event.target.value,
                        }));
                    }}
                    renderValue={(value) => (
                        <p className="text-[#64748B] font-medium text-sm">
                            <span className="capitalize">{value.replace('_', ' ')}</span> appetite
                        </p>
                    )}
                    sx={{
                        '& .MuiSelect-select': {
                            minHeight: 'auto',
                            padding: '6px 8px',
                            paddingRight: '28px !important',
                        },
                        '& .MuiSelect-icon': {
                            right: '4px',
                            fontSize: '20px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#E2E8F0',
                            borderWidth: '1px !important',
                        },
                    }}
                >
                    <MenuItem
                        value="low"
                        sx={{
                            padding: '6px 16px',
                            color: '#64748B',
                            fontWeight: '500',
                            fontSize: '13px',
                        }}
                    >
                        Low
                    </MenuItem>

                    <MenuItem
                        value="medium"
                        sx={{
                            padding: '6px 16px',
                            color: '#64748B',
                            fontWeight: '500',
                            fontSize: '13px',
                        }}
                    >
                        Medium
                    </MenuItem>

                    <MenuItem
                        value="high"
                        sx={{
                            padding: '6px 16px',
                            color: '#64748B',
                            fontWeight: '500',
                            fontSize: '13px',
                        }}
                    >
                        High
                    </MenuItem>
                </Select>
            </div>

            <div>
                <CustomTooltip
                    title="Select the actions you want to include in your mitigation plan."
                    placement="bottom"
                >
                    <div className="flex items-center gap-2 w-fit">
                        <button
                            className={`flex items-center gap-1 !py-1.5 !px-3 rounded-3xl border transition-all hover:brightness-90 ${
                                by5Level === 'low' && riskAppetiteMatrixFive?.low?.includes('mitigate')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : by5Level === 'medium' && riskAppetiteMatrixFive?.medium?.includes('mitigate')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : by5Level === 'high' && riskAppetiteMatrixFive?.high?.includes('mitigate')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : 'bg-[#fff] !border-[#E2E8F0]'
                            }`}
                            onClick={() => {
                                if (by5Level === 'low') {
                                    const toggleState = riskAppetiteMatrixFive?.low.includes('mitigate')
                                        ? riskAppetiteMatrixFive?.low?.filter((state) => state !== 'mitigate')
                                        : [...riskAppetiteMatrixFive.low, 'mitigate'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixFive: {
                                                ...prev.riskAppetite?.matrixFive,
                                                low: toggleState,
                                            },
                                        },
                                    }));
                                } else if (by5Level === 'medium') {
                                    const toggleState = riskAppetiteMatrixFive?.medium.includes('mitigate')
                                        ? riskAppetiteMatrixFive?.medium.filter((state) => state !== 'mitigate')
                                        : [...riskAppetiteMatrixFive.medium, 'mitigate'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixFive: {
                                                ...prev?.riskAppetite?.matrixFive,
                                                medium: toggleState,
                                            },
                                        },
                                    }));
                                } else {
                                    const toggleState = riskAppetiteMatrixFive?.high?.includes('mitigate')
                                        ? riskAppetiteMatrixFive?.high?.filter((state) => state !== 'mitigate')
                                        : [...riskAppetiteMatrixFive.high, 'mitigate'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixFive: {
                                                ...prev?.riskAppetite?.matrixFive,
                                                high: toggleState,
                                            },
                                        },
                                    }));
                                }
                            }}
                        >
                            <img
                                src="/img/risk-assessment/tick-circle.svg"
                                alt="Checkmark Icon"
                                className="object-contain"
                                hidden={
                                    (by5Level === 'low' && !riskAppetiteMatrixFive?.low?.includes('mitigate')) ||
                                    (by5Level === 'medium' && !riskAppetiteMatrixFive?.medium?.includes('mitigate')) ||
                                    (by5Level === 'high' && !riskAppetiteMatrixFive?.high?.includes('mitigate'))
                                }
                            />

                            <p
                                className={`text-sm font-medium ${
                                    by5Level === 'low' && riskAppetiteMatrixFive?.low?.includes('mitigate')
                                        ? 'text-[#059669]'
                                        : by5Level === 'medium' && riskAppetiteMatrixFive?.medium?.includes('mitigate')
                                        ? 'text-[#059669]'
                                        : by5Level === 'high' && riskAppetiteMatrixFive?.high?.includes('mitigate')
                                        ? 'text-[#059669]'
                                        : 'text-[#64748B]'
                                }`}
                            >
                                Mitigate
                            </p>
                        </button>

                        <button
                            className={`flex items-center gap-1 !py-1.5 !px-3 rounded-3xl border transition-all hover:brightness-90 ${
                                by5Level === 'low' && riskAppetiteMatrixFive?.low?.includes('accept')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : by5Level === 'medium' && riskAppetiteMatrixFive?.medium?.includes('accept')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : by5Level === 'high' && riskAppetiteMatrixFive?.high?.includes('accept')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : 'bg-[#fff] !border-[#E2E8F0]'
                            }`}
                            onClick={() => {
                                if (by5Level === 'low') {
                                    const toggleState = riskAppetiteMatrixFive?.low?.includes('accept')
                                        ? riskAppetiteMatrixFive?.low?.filter((state) => state !== 'accept')
                                        : [...riskAppetiteMatrixFive.low, 'accept'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixFive: {
                                                ...prev.riskAppetite?.matrixFive,
                                                low: toggleState,
                                            },
                                        },
                                    }));
                                } else if (by5Level === 'medium') {
                                    const toggleState = riskAppetiteMatrixFive?.medium?.includes('accept')
                                        ? riskAppetiteMatrixFive?.medium?.filter((state) => state !== 'accept')
                                        : [...riskAppetiteMatrixFive.medium, 'accept'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixFive: {
                                                ...prev?.riskAppetite?.matrixFive,
                                                medium: toggleState,
                                            },
                                        },
                                    }));
                                } else {
                                    const toggleState = riskAppetiteMatrixFive?.high?.includes('accept')
                                        ? riskAppetiteMatrixFive?.high?.filter((state) => state !== 'accept')
                                        : [...riskAppetiteMatrixFive.high, 'accept'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixFive: {
                                                ...prev?.riskAppetite?.matrixFive,
                                                high: toggleState,
                                            },
                                        },
                                    }));
                                }
                            }}
                        >
                            <img
                                src="/img/risk-assessment/tick-circle.svg"
                                alt="Checkmark Icon"
                                className="object-contain"
                                hidden={
                                    (by5Level === 'low' && !riskAppetiteMatrixFive?.low?.includes('accept')) ||
                                    (by5Level === 'medium' && !riskAppetiteMatrixFive?.medium?.includes('accept')) ||
                                    (by5Level === 'high' && !riskAppetiteMatrixFive?.high?.includes('accept'))
                                }
                            />

                            <p
                                className={`text-sm font-medium ${
                                    by5Level === 'low' && riskAppetiteMatrixFive?.low?.includes('accept')
                                        ? 'text-[#059669]'
                                        : by5Level === 'medium' && riskAppetiteMatrixFive?.medium?.includes('accept')
                                        ? 'text-[#059669]'
                                        : by5Level === 'high' && riskAppetiteMatrixFive?.high?.includes('accept')
                                        ? 'text-[#059669]'
                                        : 'text-[#64748B]'
                                }`}
                            >
                                Accept
                            </p>
                        </button>

                        <button
                            className={`flex items-center gap-1 !py-1.5 !px-3 rounded-3xl border transition-all hover:brightness-90 ${
                                by5Level === 'low' && riskAppetiteMatrixFive?.low?.includes('avoid')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : by5Level === 'medium' && riskAppetiteMatrixFive?.medium?.includes('avoid')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : by5Level === 'high' && riskAppetiteMatrixFive?.high?.includes('avoid')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : 'bg-[#fff] !border-[#E2E8F0]'
                            }`}
                            onClick={() => {
                                if (by5Level === 'low') {
                                    const toggleState = riskAppetiteMatrixFive?.low.includes('avoid')
                                        ? riskAppetiteMatrixFive?.low.filter((state) => state !== 'avoid')
                                        : [...riskAppetiteMatrixFive.low, 'avoid'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixFive: {
                                                ...prev?.riskAppetite?.matrixFive,
                                                low: toggleState,
                                            },
                                        },
                                    }));
                                } else if (by5Level === 'medium') {
                                    const toggleState = riskAppetiteMatrixFive?.medium.includes('avoid')
                                        ? riskAppetiteMatrixFive?.medium?.filter((state) => state !== 'avoid')
                                        : [...riskAppetiteMatrixFive.medium, 'avoid'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixFive: {
                                                ...prev?.riskAppetite?.matrixFive,
                                                medium: toggleState,
                                            },
                                        },
                                    }));
                                } else {
                                    const toggleState = riskAppetiteMatrixFive?.high?.includes('avoid')
                                        ? riskAppetiteMatrixFive?.high?.filter((state) => state !== 'avoid')
                                        : [...riskAppetiteMatrixFive.high, 'avoid'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixFive: {
                                                ...prev?.riskAppetite?.matrixFive,
                                                high: toggleState,
                                            },
                                        },
                                    }));
                                }
                            }}
                        >
                            <img
                                src="/img/risk-assessment/tick-circle.svg"
                                alt="Checkmark Icon"
                                className="object-contain"
                                hidden={
                                    (by5Level === 'low' && !riskAppetiteMatrixFive?.low?.includes('avoid')) ||
                                    (by5Level === 'medium' && !riskAppetiteMatrixFive?.medium?.includes('avoid')) ||
                                    (by5Level === 'high' && !riskAppetiteMatrixFive?.high?.includes('avoid'))
                                }
                            />

                            <p
                                className={`text-sm font-medium ${
                                    by5Level === 'low' && riskAppetiteMatrixFive?.low?.includes('avoid')
                                        ? 'text-[#059669]'
                                        : by5Level === 'medium' && riskAppetiteMatrixFive?.medium?.includes('avoid')
                                        ? 'text-[#059669]'
                                        : by5Level === 'high' && riskAppetiteMatrixFive?.high?.includes('avoid')
                                        ? 'text-[#059669]'
                                        : 'text-[#64748B]'
                                }`}
                            >
                                Avoid
                            </p>
                        </button>

                        <button
                            className={`flex items-center gap-1 !py-1.5 !px-3 rounded-3xl border transition-all hover:brightness-90 ${
                                by5Level === 'low' && riskAppetiteMatrixFive?.low?.includes('transfer')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : by5Level === 'medium' && riskAppetiteMatrixFive?.medium?.includes('transfer')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : by5Level === 'high' && riskAppetiteMatrixFive?.high?.includes('transfer')
                                    ? 'bg-[#ECFDF5] !border-[#D1FAE5]'
                                    : 'bg-[#fff] !border-[#E2E8F0]'
                            }`}
                            onClick={() => {
                                if (by5Level === 'low') {
                                    const toggleState = riskAppetiteMatrixFive?.low?.includes('transfer')
                                        ? riskAppetiteMatrixFive?.low?.filter((state) => state !== 'transfer')
                                        : [...riskAppetiteMatrixFive.low, 'transfer'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixFive: {
                                                ...prev?.riskAppetite?.matrixFive,
                                                low: toggleState,
                                            },
                                        },
                                    }));
                                } else if (by5Level === 'medium') {
                                    const toggleState = riskAppetiteMatrixFive?.medium?.includes('transfer')
                                        ? riskAppetiteMatrixFive?.medium?.filter((state) => state !== 'transfer')
                                        : [...riskAppetiteMatrixFive.medium, 'transfer'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixFive: {
                                                ...prev.riskAppetite?.matrixFive,
                                                medium: toggleState,
                                            },
                                        },
                                    }));
                                } else {
                                    const toggleState = riskAppetiteMatrixFive?.high?.includes('transfer')
                                        ? riskAppetiteMatrixFive?.high.filter((state) => state !== 'transfer')
                                        : [...riskAppetiteMatrixFive.high, 'transfer'];
                                    setRiskGeneralTabStates((prev) => ({
                                        ...prev,
                                        riskAppetite: {
                                            ...prev?.riskAppetite,
                                            matrixFive: {
                                                ...prev?.riskAppetite?.matrixFive,
                                                high: toggleState,
                                            },
                                        },
                                    }));
                                }
                            }}
                        >
                            <img
                                src="/img/risk-assessment/tick-circle.svg"
                                alt="Checkmark Icon"
                                className="object-contain"
                                hidden={
                                    (by5Level === 'low' && !riskAppetiteMatrixFive?.low?.includes('transfer')) ||
                                    (by5Level === 'medium' && !riskAppetiteMatrixFive?.medium?.includes('transfer')) ||
                                    (by5Level === 'high' && !riskAppetiteMatrixFive?.high?.includes('transfer'))
                                }
                            />

                            <p
                                className={`text-sm font-medium ${
                                    by5Level === 'low' && riskAppetiteMatrixFive?.low?.includes('transfer')
                                        ? 'text-[#059669]'
                                        : by5Level === 'medium' && riskAppetiteMatrixFive?.medium?.includes('transfer')
                                        ? 'text-[#059669]'
                                        : by5Level === 'high' && riskAppetiteMatrixFive?.high?.includes('transfer')
                                        ? 'text-[#059669]'
                                        : 'text-[#64748B]'
                                }`}
                            >
                                Transfer
                            </p>
                        </button>
                    </div>
                </CustomTooltip>
            </div>
        </div>
    );
}

async function getOrganizationEmployees(organizationID) {
    const response = await https().get(`/user_mgt/merchant_users/?merchant_id=${organizationID}`);
    return response.data;
}
