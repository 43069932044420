import React, { useState, useMemo } from 'react';

//components
import { Box, Typography, Stack, Skeleton } from '@mui/material';
import CardHeader from './CardHeader';
import LabelledChipCircularTag from './LabelledChipCircularTag';
import { tabList, tabListFr } from '../utils/constants';

//translations
import { useTranslation } from 'react-i18next';

const RiskAssetCard = (props) => {
    const [currentTab, setCurrentTab] = useState(1);

    const { fiveByFiveMatrix, threeBythreeMatrix, fiveByFiveMatrix_, threeBythreeMatrix_ } = props;

    //translation
    const { t } = useTranslation('riskAssessment');
    const language = localStorage.getItem('i18nextLng');

    //functions
    const onChangeTab = async (val) => {
        setCurrentTab(val);
    };

    const totalAssets = useMemo(() => {
        const assetObject = currentTab === 1 ? fiveByFiveMatrix : threeBythreeMatrix;
        const values = Object.values(assetObject || {});
        const sum = values.reduce((accumulator, value) => {
            return accumulator + value;
        }, 0);
        return sum;
    }, [currentTab, fiveByFiveMatrix, threeBythreeMatrix]);
    return (
        <Box
            sx={{
                border: '0.4px solid #C5C6D0',
                boxShadow: '0px 8px 20px rgba(88, 88, 88, 0.05)',
                borderRadius: '8px',
                width: { md: '100%', lg: '100%', xl: '40%' },
                height: '370px',
                p: 2,
                mt: { md: 2, lg: 0 },
                // height: '100%',
            }}
        >
            <CardHeader
                onChangeTab={onChangeTab}
                indexValue={currentTab}
                name={t('riskAssetCard.title')}
                tabList={language?.includes('fr') ? tabListFr : tabList}
            />
            {(currentTab === 1 ? fiveByFiveMatrix_ : threeBythreeMatrix_)?.length > 0 ? (
                <Box>
                    <Stack direction="row" spacing={1} alignItems="baseline" sx={{ ml: 1, mt: 6 }}>
                        <Typography sx={{ fontSize: 40, fontWeight: 700 }}>{totalAssets}</Typography>
                        <Typography sx={{ fontSize: 10, mb: '20px', fontWeight: 500 }}>
                            {t('riskAssetCard.assets')}
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" sx={{ mr: 1, mt: 5, flexWrap: 'wrap' }}>
                        <LabelledChipCircularTag
                            label={t('riskAssetCard.infrastructure')}
                            value={
                                currentTab === 1 ? fiveByFiveMatrix?.infrastructure : threeBythreeMatrix?.infrastructure
                            }
                        />
                        <LabelledChipCircularTag
                            label={t('riskAssetCard.appliance')}
                            value={currentTab === 1 ? fiveByFiveMatrix?.appliances : threeBythreeMatrix?.appliances}
                        />
                        <LabelledChipCircularTag
                            label={t('riskAssetCard.computer')}
                            value={
                                currentTab === 1
                                    ? fiveByFiveMatrix?.employee_computer
                                    : threeBythreeMatrix?.employee_computer
                            }
                        />
                        <LabelledChipCircularTag
                            label={t('riskAssetCard.digital')}
                            value={currentTab === 1 ? fiveByFiveMatrix?.digital : threeBythreeMatrix?.digital}
                        />
                        <LabelledChipCircularTag
                            label={t('riskAssetCard.hardCopy')}
                            value={currentTab === 1 ? fiveByFiveMatrix?.hardcopy : threeBythreeMatrix?.hardcopy}
                        />
                        <LabelledChipCircularTag
                            label={t('riskAssetCard.serviceProvider')}
                            value={
                                currentTab === 1
                                    ? fiveByFiveMatrix?.service_provider
                                    : threeBythreeMatrix?.service_provider
                            }
                        />
                        <LabelledChipCircularTag
                            label={t('riskAssetCard.people')}
                            value={currentTab === 1 ? fiveByFiveMatrix?.people : threeBythreeMatrix?.people}
                        />
                        <LabelledChipCircularTag
                            label={t('riskAssetCard.utilities')}
                            value={currentTab === 1 ? fiveByFiveMatrix?.utilities : threeBythreeMatrix?.utilities}
                        />
                        <LabelledChipCircularTag
                            label={t('riskAssetCard.softwares')}
                            value={currentTab === 1 ? fiveByFiveMatrix?.softwares : threeBythreeMatrix?.softwares}
                        />
                    </Stack>
                </Box>
            ) : (
                <Stack direction="column" justifyContent="center" alignItems="center" sx={{ marginTop: 10 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Skeleton
                            width="35%"
                            height={15}
                            sx={{ marginRight: 2, borderRadius: '20px' }}
                            animation="wave"
                            variant="rounded"
                        />
                        <Skeleton
                            width="30%"
                            height={15}
                            sx={{ marginRight: 2, borderRadius: '20px' }}
                            animation="wave"
                            variant="rounded"
                        />
                        <Skeleton
                            width="25%"
                            height={15}
                            sx={{ marginRight: 2, borderRadius: '20px' }}
                            animation="wave"
                            variant="rounded"
                        />
                    </Box>{' '}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                            mt: 2,
                            width: '100%',
                        }}
                    >
                        <Skeleton
                            width="10%"
                            height={15}
                            sx={{ marginRight: 2, borderRadius: '20px' }}
                            animation="wave"
                            variant="rounded"
                        />
                        <Skeleton
                            width="35%"
                            height={15}
                            sx={{ marginRight: 2, borderRadius: '20px' }}
                            animation="wave"
                            variant="rounded"
                        />
                        <Skeleton
                            width="20%"
                            height={15}
                            sx={{ marginRight: 2, borderRadius: '20px' }}
                            animation="wave"
                            variant="rounded"
                        />
                    </Box>
                    <Typography sx={{ color: '#5C5E67', fontSize: '14px', fontWeight: 600, mt: 4 }}>
                        {t('riskAssetCard.noAsset')}
                    </Typography>
                </Stack>
            )}
        </Box>
    );
};

export default RiskAssetCard;
