import React, { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { IconButton, Stack, Typography, Button, Box } from '@mui/material';
import { Delete, Add, MoreHoriz } from '@mui/icons-material';
import AppUserGuide from 'components/new_components/AppUserGuide';
import { steps } from './steps';

// core components
import AppTable from 'components/new_components/app-table';
import AppTag from 'components/new_components/AppTags';
import AppProgressBar from 'components/new_components/AppProgressBar';
import UploadQuestionnaireModal from './UploadQuestionnaireModal';

// icons
import { ReactComponent as Completed } from 'assets/img/icons/completed.svg';
import { ReactComponent as InProgress } from 'assets/img/icons/inprogress.svg';
import { ReactComponent as Analysed } from 'assets/img/icons/analysed.svg';

// utils
import { formatAddedDate } from '../../utils/utils';
// import { merchantQuestionnaire } from "../../utils/constants";
import { connect } from 'react-redux';

import { UserGuideContext } from 'store/context/UserGuideContextProvider';

import { GetAllUploadedVendorQuestionnaire, DeleteUploadedVendorQuestionnaire } from 'store/actions/vendorActions';
import DeleteQuestionnaireModal from './DeleteQuestionnaireModal';
import { answeredQuestionsPercentage } from 'utils';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import useSearch from 'hooks/useSearch';

//translations
import { useTranslation } from 'react-i18next';
import VendorTitle from './VendorTitle';

const ActingAsAVendorTable = (props) => {
    const {
        handleViewChange,
        GetAllUploadedVendorQuestionnaire,
        vendor_uploaded_questionnaire,
        userguide_info,
        DeleteUploadedVendorQuestionnaire,
    } = props;

    const [openUserGuide, setOpenUserGuide] = useState(false);
    const theme = useTheme();

    const stepsTargetRef = useRef([]);

    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deletePayload, setDeletePayload] = useState(0);
    const [loading, setloading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [status, setStatus] = useState('');

    const { data, handleSearch } = useSearch(vendor_uploaded_questionnaire, ['name', 'status']);

    //translation
    const { t } = useTranslation('vendorManagement');

    //function
    const handleDownload = (e, fileURl) => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        window.open(fileURl, '_blank');
    };
    const handleDelete = (e, id) => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        setDeleteModalOpen(true);
        setDeletePayload(id);
    };
    const handleCloseDeleteModal = () => {
        setDeleteModalOpen(false);
        setDeletePayload(0);
    };
    const openUploadModal = () => {
        setModalOpen(true);
    };
    const closeUploadModal = () => {
        setModalOpen(false);
    };

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ acting_as_a_vendor: true });
        setToggleUserGuide({ ...toggleUserGuide, actingasavendor: false, vendors: false });
        setOpenUserGuide(false);
    };

    useEffect(() => {
        !userguide_info || toggleUserGuide.actingasavendor ? OpenUserGuide() : null;
    }, [userguide_info, toggleUserGuide.actingasavendor]);

    // const answeredQuestionsPercentage = (answeredQuestionsScore, totalQuestions) => {
    //     let result = Math.floor((answeredQuestionsScore / totalQuestions) * 100);

    //     return result;
    // };
    const columns = [
        {
            title: t('actingAsAVendorTable.column1'),
            render: (row) => (
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                    {row.status === 'completed' ? (
                        <Completed />
                    ) : row.status === 'in_progress' ? (
                        <InProgress />
                    ) : (
                        <Analysed />
                    )}
                    <span style={{ wordBreak: 'break-all' }}>{row?.name}</span>
                </Stack>
            ),
        },
        {
            title: t('actingAsAVendorTable.column2'),
            render: (row) => (
                <>
                    {row?.status?.toLowerCase() === 'completed' ? (
                        <AppTag
                            text={t('actingAsAVendorTable.completed')}
                            icon={
                                <FiberManualRecordIcon
                                    sx={{ width: 12, height: 12, color: theme.palette.success[500], mr: 0.5 }}
                                />
                            }
                            type="defaults"
                            noRounded
                            noItalic
                            sx={{
                                padding: '4px 8px',
                            }}
                        />
                    ) : row?.status === 'in_progress' ? (
                        <AppTag
                            text={t('actingAsAVendorTable.inProgress')}
                            icon={
                                <FiberManualRecordIcon
                                    sx={{ width: 12, height: 12, color: theme.palette.warning[900], mr: 0.5 }}
                                />
                            }
                            noRounded
                            noItalic
                            type="default"
                            sx={{
                                padding: '4px 8px',
                            }}
                        />
                    ) : row?.status?.toLowerCase() === 'analysing' ? (
                        <AppTag
                            text={
                                <Typography sx={{ fontStyle: 'italic' }}>{t('actingAsAVendorTable.high')}</Typography>
                            }
                            type="primary"
                        />
                    ) : row?.status?.toLowerCase() === 'analysed' ? (
                        <AppTag
                            text={t('actingAsAVendorTable.analysed')}
                            icon={<FiberManualRecordIcon sx={{ width: 12, height: 12, color: '#395BA9', mr: 0.5 }} />}
                            noRounded
                            noItalic
                            type="default"
                            sx={{
                                padding: '4px 8px',
                            }}
                        />
                    ) : (
                        <AppTag text={row?.status} type="default" />
                    )}
                </>
            ),
        },
        {
            title: t('actingAsAVendorTable.column3'),
            render: (row) => {
                const { date, time } = formatAddedDate(
                    row?.status?.toLowerCase() === 'completed' ? row?.date_updated : row?.date_created
                );
                return (
                    <>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                color: theme.palette.gray[900],
                                fontSize: 14,
                            }}
                        >
                            {date}.
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                color: theme.palette.gray[900],
                                fontSize: 14,
                            }}
                        >
                            ({time})
                        </Typography>
                    </>
                );
            },
        },
        {
            title: <MoreHoriz />,
            align: 'center',
            render: (row) => {
                const isCompleted = row?.status?.toLowerCase() === 'completed';

                return (
                    <Stack direction="row" spacing={1}>
                        <Button
                            variant="contained"
                            disabled={!isCompleted}
                            sx={{
                                backgroundColor: !isCompleted ? '#E2E8F0' : '#F8FAFC',
                                textTransform: 'inherit',
                                px: 1,
                                py: 0.5,
                                '&:hover': {
                                    backgroundColor: !isCompleted ? '#E2E8F0' : '#F8FAFC',
                                },
                            }}
                            onClick={(e) => handleDownload(e, row?.file)}
                        >
                            Download
                        </Button>
                        <IconButton color="error" onClick={(e) => handleDelete(e, row?.id)}>
                            <Delete color="error" />
                        </IconButton>
                    </Stack>
                );
            },
        },
        {
            title: '',
            align: 'center',
            render: (row) => {
                return (
                    <>
                        <AppProgressBar
                            sx={{
                                width: 130,
                                '&.MuiLinearProgress-colorSuccess': {
                                    backgroundColor: row?.status?.includes('analy')
                                        ? 'initial'
                                        : theme.palette.warning[900],
                                },
                            }}
                            value={answeredQuestionsPercentage(row?.accepted_count, row?.question_count)}
                            color={row?.status?.includes('analy') ? 'primary' : 'success'}
                        />
                    </>
                );
            },
        },
    ];
    const handlePageChange = (page) => {
        setPage(page);
    };

    useEffect(() => {
        const getAllUploadedVendorQuestionnaire = async () => {
            setloading(true);
            const res = await GetAllUploadedVendorQuestionnaire();
            if (!res?.success) {
                toast.error(res?.success);
                setloading(false);
            }
            setloading(false);
        };
        getAllUploadedVendorQuestionnaire();
    }, []);

    const allSortedVendorQuestionaires = useMemo(() => {
        return data?.length ? data?.sort((a, b) => b?.id - a?.id) : [];
    }, [data]);
    // console.log(allSortedVendorQuestionaires);
    useEffect(() => {
        const start = (page - 1) * 4;
        const stop = start + 4;
        setPageData(allSortedVendorQuestionaires?.slice(start, stop));

        return () => setPageData([]);
    }, [page, allSortedVendorQuestionaires, data]);
    return (
        <Box sx={{ mt: '3rem', mx: '1.3rem', backgroundColor: '#FFFFFF', px: '2rem' }}>
            <AppTable
                columns={columns}
                data={pageData}
                loading={loading}
                sorter={(a, b) => b?.id - a?.id}
                dataLength={allSortedVendorQuestionaires?.length}
                noPerPage={4}
                onPageChange={handlePageChange}
                title={<VendorTitle onSearch={handleSearch} status={status} setStatus={setStatus} />}
                actions={[
                    <Button
                        startIcon={<Add />}
                        color="primary"
                        variant="contained"
                        key={2}
                        ref={(el) => (stepsTargetRef.current[0] = el)}
                        onClick={openUploadModal}
                        sx={{
                            display: 'flex-inline',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: '600',
                            // borderRadius: '4px',
                            py: 1.2,
                            px: 2.4,
                            textTransform: 'unset',
                            '&.Mui-disabled': {
                                color: theme.palette.white.main,
                                backgroundColor: theme.palette.gray[600],
                            },
                        }}
                    >
                        {t('actingAsAVendorTable.upload')}
                    </Button>,
                ]}
                rowSpacing={true}
                clickableRow={() => true}
                onRowClick={(row) => handleViewChange(row?.id)}
            />

            <UploadQuestionnaireModal open={modalOpen} handleClose={closeUploadModal} />

            <AppUserGuide
                open={openUserGuide}
                onClose={CloseUserGuide}
                steps={steps}
                targets={stepsTargetRef.current}
            />

            <DeleteQuestionnaireModal
                open={deleteModalOpen}
                handleClose={handleCloseDeleteModal}
                DeleteUploadedVendorQuestionnaire={DeleteUploadedVendorQuestionnaire}
                id={deletePayload}
            />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        vendor_uploaded_questionnaire: state?.vendorReducers?.vendor_uploaded_questionnaire,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.acting_as_a_vendor,
    };
};
export default connect(mapStateToProps, { GetAllUploadedVendorQuestionnaire, DeleteUploadedVendorQuestionnaire })(
    ActingAsAVendorTable
);
