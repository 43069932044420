import { Dialog, Menu, MenuItem } from '@mui/material';
import CustomTooltip from 'components/new_components/CustomTooltip';
import { Loader2 } from 'lucide-react';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { AddNewCIA } from 'store/actions/riskAssessmentActions';

const ImpactOnCIA = ({ setRiskFirstPageStates, inherentValues, cia_impact_, setCiaImpact_, setCiaImpact, matrix }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedStates, setSelectedStates] = useState({
        confidentiality: 2,
        integrity: 2,
        availability: 2,
    });
    const [selectedWeights, setSelectedWeights] = useState({
        confidentiality: 0.34,
        integrity: 0.33,
        availability: 0.33,
    });
    const [confidentialityAnchorEl, setConfidentialityAnchorEl] = useState(null);
    const [integrityAnchorEl, setIntegrityAnchorEl] = useState(null);
    const [availabilityAnchorEl, setAvailabilityAnchorEl] = useState(null);

    // redux
    const dispatch = useDispatch();

    const totalWeight = useMemo(() => {
        return Object.values(selectedWeights).reduce((acc, curr) => acc + curr, 0);
    }, [selectedWeights]);

    const handleCreateCIA = async () => {
        setLoading(true);
        const body = { ...selectedStates, matrix };
        const res = await dispatch(AddNewCIA(body));
        setLoading(false);
        if (res?.success) {
            setRiskFirstPageStates((prev) => ({
                ...prev,
                inherent_impact:
                    matrix === '3x3'
                        ? res?.data === 'Low'
                            ? 1
                            : res?.data === 'Medium'
                            ? 2
                            : res?.data === 'High'
                            ? 3
                            : ''
                        : res?.data === 'Very Low'
                        ? 1
                        : res?.data === 'Low'
                        ? 2
                        : res?.data === 'Medium'
                        ? 3
                        : res?.data === 'High'
                        ? 4
                        : res?.data === 'Very High'
                        ? 5
                        : '',
            }));
            setCiaImpact_(true);
            setCiaImpact(selectedStates);
            setIsDialogOpen(false);
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <>
            <button
                className="flex items-center gap-2 transition-all hover:brightness-90"
                onClick={() => {
                    if (!cia_impact_) {
                        setIsDialogOpen(true);
                    } else {
                        setCiaImpact_(false);
                        setRiskFirstPageStates((prev) => ({
                            ...prev,
                            inherent_impact: '',
                        }));
                    }
                }}
            >
                {cia_impact_ ? (
                    <img
                        src="/img/risk-assessment/checkbox-buuton-checked.svg"
                        alt="Checkbox icon"
                        className="object-contain"
                    />
                ) : (
                    <img
                        src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                        alt="Checkbox icon"
                        className="object-contain"
                    />
                )}

                <p className="font-medium text-sm text-[#395BA9]">
                    Risk Impact on CIA (<span className="text-[13px] text-[#64748B]">optional</span>)
                </p>

                <CustomTooltip
                    title="Assess the potential effect on Confidentiality, Integrity, and Availability."
                    placement="top"
                >
                    <img src="/img/risk-assessment/help-circle.svg" alt="Help Icon" className="object-contain" />
                </CustomTooltip>
            </button>

            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                PaperProps={{
                    sx: {
                        borderRadius: '4px !important',
                        padding: 0,
                        boxShadow: '0px 16px 24px 0px #00000026',
                        width: '486px',
                    },
                }}
            >
                <div className="flex items-center justify-between px-6 !pt-6">
                    <h5 className="text-lg font-semibold text-[#202D66]">Impact on CIA</h5>

                    <button
                        className="border-[#E2E8F0] border rounded-full !p-1 h-6 w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                        onClick={() => setIsDialogOpen(false)}
                    >
                        <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                    </button>
                </div>

                <div className="!p-6 flex flex-col gap-4">
                    <div className="bg-[#F8FAFC] !border !border-[#F0F0FA] !rounded flex gap-2 px-4 !py-2 items-start">
                        <img
                            src="/img/automated-scan/bulb-icon.svg"
                            alt="Bulb Icon"
                            className="object-contain mt-0.5"
                        />

                        <p className="text-[#64748B] font-normal text-sm leading-5">
                            The weight assigned to all impact should sum up to <span className="font-bold">one.</span>{' '}
                            <br />
                            <span className="text-[13px] italic">
                                e.g C-weight <span className="font-bold">+</span> I-weight{' '}
                                <span className="font-bold">+</span> A-weight = 1
                            </span>
                        </p>
                    </div>

                    <div className="flex items-center gap-4">
                        <div className="w-[62.5%] flex flex-col gap-2">
                            <div className="flex items-center gap-1">
                                <p className="font-medium text-sm text-[#64748B]">Confidentiality</p>

                                <CustomTooltip
                                    title="Select which CIA (Confidentiality, integrity, availability) impact categories are relevant to this risk"
                                    placement="top"
                                >
                                    <img
                                        src="/img/risk-assessment/help-circle.svg"
                                        alt="Help Icon"
                                        className="object-contain"
                                    />
                                </CustomTooltip>
                            </div>

                            <div>
                                <button
                                    aria-controls={confidentialityAnchorEl ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={confidentialityAnchorEl ? 'true' : undefined}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setConfidentialityAnchorEl(event.currentTarget);
                                    }}
                                    className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                                >
                                    <p
                                        className={`font-normal text-sm ${
                                            selectedStates.confidentiality
                                                ? 'capitalize text-[hsl(215,20%,40%)]'
                                                : 'text-[#94A3B8]'
                                        }`}
                                    >
                                        {selectedStates.confidentiality
                                            ? inherentValues?.find(
                                                  (item) => item?.value === selectedStates?.confidentiality
                                              )?.name
                                            : 'Select impact'}
                                    </p>

                                    <img
                                        src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                        alt="Arrow Down Icon"
                                        className="object-contain"
                                    />
                                </button>

                                <Menu
                                    anchorEl={confidentialityAnchorEl}
                                    open={Boolean(confidentialityAnchorEl)}
                                    onClose={() => setConfidentialityAnchorEl(null)}
                                    sx={{
                                        '& .MuiPaper-root': {
                                            borderRadius: '4px',
                                            boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                        },
                                        '& .MuiList-root': {
                                            padding: 0,
                                        },
                                    }}
                                >
                                    <div className="!p-2 flex flex-col min-w-[273px]">
                                        {inherentValues.map((level, index) => (
                                            <MenuItem
                                                key={index}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setSelectedStates((prev) => ({
                                                        ...prev,
                                                        confidentiality: level?.value,
                                                    }));
                                                    setConfidentialityAnchorEl(null);
                                                }}
                                                sx={{
                                                    color: '#64748B',
                                                    fontWeight: 500,
                                                    fontSize: '13px',
                                                    textTransform: 'capitalize',
                                                    padding: '6px 8px',
                                                }}
                                            >
                                                {level?.name}
                                            </MenuItem>
                                        ))}
                                    </div>
                                </Menu>
                            </div>
                        </div>

                        <div className="flex-1 flex flex-col gap-2">
                            <div className="flex items-center gap-1">
                                <p className="font-medium text-sm text-[#64748B]">C-Weight</p>

                                <CustomTooltip title="How important or valued the confidentiality is" placement="top">
                                    <img
                                        src="/img/risk-assessment/help-circle.svg"
                                        alt="Help Icon"
                                        className="object-contain"
                                    />
                                </CustomTooltip>
                            </div>

                            <input
                                type="number"
                                inputMode="numeric"
                                placeholder="Enter weight"
                                step={0.01}
                                min={0}
                                max={1}
                                value={selectedWeights.confidentiality}
                                onChange={(event) => {
                                    setSelectedWeights((prev) => ({
                                        ...prev,
                                        confidentiality: +event.target.value,
                                    }));
                                }}
                                className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                            />
                        </div>
                    </div>

                    <div className="flex items-center gap-4">
                        <div className="w-[62.5%] flex flex-col gap-2">
                            <div className="flex items-center gap-1">
                                <p className="font-medium text-sm text-[#64748B]">Integrity</p>

                                <CustomTooltip
                                    title="Select which CIA (Confidentiality, integrity, availability) impact categories are relevant to this risk"
                                    placement="top"
                                >
                                    <img
                                        src="/img/risk-assessment/help-circle.svg"
                                        alt="Help Icon"
                                        className="object-contain"
                                    />
                                </CustomTooltip>
                            </div>

                            <div>
                                <button
                                    aria-controls={integrityAnchorEl ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={integrityAnchorEl ? 'true' : undefined}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setIntegrityAnchorEl(event.currentTarget);
                                    }}
                                    className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                                >
                                    <p
                                        className={`font-normal text-sm ${
                                            selectedStates.integrity
                                                ? 'capitalize text-[hsl(215,20%,40%)]'
                                                : 'text-[#94A3B8]'
                                        }`}
                                    >
                                        {selectedStates.integrity
                                            ? inherentValues?.find((item) => item?.value === selectedStates?.integrity)
                                                  ?.name
                                            : 'Select impact'}
                                    </p>

                                    <img
                                        src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                        alt="Arrow Down Icon"
                                        className="object-contain"
                                    />
                                </button>

                                <Menu
                                    anchorEl={integrityAnchorEl}
                                    open={Boolean(integrityAnchorEl)}
                                    onClose={() => setIntegrityAnchorEl(null)}
                                    sx={{
                                        '& .MuiPaper-root': {
                                            borderRadius: '4px',
                                            boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                        },
                                        '& .MuiList-root': {
                                            padding: 0,
                                        },
                                    }}
                                >
                                    <div className="!p-2 flex flex-col min-w-[273px]">
                                        {inherentValues.map((level, index) => (
                                            <MenuItem
                                                key={index}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setSelectedStates((prev) => ({
                                                        ...prev,
                                                        integrity: level?.value,
                                                    }));
                                                    setIntegrityAnchorEl(null);
                                                }}
                                                sx={{
                                                    color: '#64748B',
                                                    fontWeight: 500,
                                                    fontSize: '13px',
                                                    textTransform: 'capitalize',
                                                    padding: '6px 8px',
                                                }}
                                            >
                                                {level.name}
                                            </MenuItem>
                                        ))}
                                    </div>
                                </Menu>
                            </div>
                        </div>

                        <div className="flex-1 flex flex-col gap-2">
                            <div className="flex items-center gap-1">
                                <p className="font-medium text-sm text-[#64748B]">I-Weight</p>

                                <CustomTooltip title="How important or valued the integrity is" placement="top">
                                    <img
                                        src="/img/risk-assessment/help-circle.svg"
                                        alt="Help Icon"
                                        className="object-contain"
                                    />
                                </CustomTooltip>
                            </div>

                            <input
                                type="number"
                                inputMode="numeric"
                                placeholder="Enter weight"
                                step={0.01}
                                min={0}
                                max={1}
                                value={selectedWeights.integrity}
                                onChange={(event) => {
                                    setSelectedWeights((prev) => ({
                                        ...prev,
                                        integrity: +event.target.value,
                                    }));
                                }}
                                className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                            />
                        </div>
                    </div>

                    <div className="flex items-center gap-4">
                        <div className="w-[62.5%] flex flex-col gap-2">
                            <div className="flex items-center gap-1">
                                <p className="font-medium text-sm text-[#64748B]">Availability</p>

                                <CustomTooltip
                                    title="Select which CIA (Confidentiality, integrity, availability) impact categories are relevant to this risk"
                                    placement="top"
                                >
                                    <img
                                        src="/img/risk-assessment/help-circle.svg"
                                        alt="Help Icon"
                                        className="object-contain"
                                    />
                                </CustomTooltip>
                            </div>

                            <div>
                                <button
                                    aria-controls={availabilityAnchorEl ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={availabilityAnchorEl ? 'true' : undefined}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setAvailabilityAnchorEl(event.currentTarget);
                                    }}
                                    className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                                >
                                    <p
                                        className={`font-normal text-sm ${
                                            selectedStates.availability
                                                ? 'capitalize text-[hsl(215,20%,40%)]'
                                                : 'text-[#94A3B8]'
                                        }`}
                                    >
                                        {selectedStates.availability
                                            ? inherentValues?.find(
                                                  (item) => item?.value === selectedStates?.availability
                                              )?.name
                                            : 'Select impact'}
                                    </p>

                                    <img
                                        src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                        alt="Arrow Down Icon"
                                        className="object-contain"
                                    />
                                </button>

                                <Menu
                                    anchorEl={availabilityAnchorEl}
                                    open={Boolean(availabilityAnchorEl)}
                                    onClose={() => setAvailabilityAnchorEl(null)}
                                    sx={{
                                        '& .MuiPaper-root': {
                                            borderRadius: '4px',
                                            boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                        },
                                        '& .MuiList-root': {
                                            padding: 0,
                                        },
                                    }}
                                >
                                    <div className="!p-2 flex flex-col min-w-[273px]">
                                        {inherentValues.map((level, index) => (
                                            <MenuItem
                                                key={index}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setSelectedStates((prev) => ({
                                                        ...prev,
                                                        availability: level?.value,
                                                    }));
                                                    setAvailabilityAnchorEl(null);
                                                }}
                                                sx={{
                                                    color: '#64748B',
                                                    fontWeight: 500,
                                                    fontSize: '13px',
                                                    textTransform: 'capitalize',
                                                    padding: '6px 8px',
                                                }}
                                            >
                                                {level?.name}
                                            </MenuItem>
                                        ))}
                                    </div>
                                </Menu>
                            </div>
                        </div>

                        <div className="flex-1 flex flex-col gap-2">
                            <div className="flex items-center gap-1">
                                <p className="font-medium text-sm text-[#64748B]">A-Weight</p>

                                <CustomTooltip title="How important or valued the availability is" placement="top">
                                    <img
                                        src="/img/risk-assessment/help-circle.svg"
                                        alt="Help Icon"
                                        className="object-contain"
                                    />
                                </CustomTooltip>
                            </div>

                            <input
                                type="number"
                                inputMode="numeric"
                                placeholder="Enter weight"
                                step={0.01}
                                min={0}
                                max={1}
                                value={selectedWeights.availability}
                                onChange={(event) => {
                                    setSelectedWeights((prev) => ({
                                        ...prev,
                                        availability: +event.target.value,
                                    }));
                                }}
                                className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                            />
                        </div>
                    </div>

                    <p className="self-start bg-[#F8FAFC] !rounded !py-2 !px-4 text-[#64748B] font-normal text-sm">
                        Sum up weight:{' '}
                        <span className="text-[#395BA9] text-base font-bold">{totalWeight.toFixed(2)}</span>
                    </p>
                </div>

                <div className="flex items-center justify-end !p-6 bg-[#F8FAFC] gap-2">
                    <button
                        className="bg-[#fff] border border-[#E2E8F0] rounded-sm !px-4 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                        onClick={() => setIsDialogOpen(false)}
                    >
                        Cancel
                    </button>

                    <button
                        className="bg-[#202D66] rounded-sm !px-4 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none flex items-center"
                        disabled={totalWeight !== 1}
                        onClick={handleCreateCIA}
                    >
                        {loading && <Loader2 className="mr-2 animate-spin h-4 w-4" />}
                        Save
                    </button>
                </div>
            </Dialog>
        </>
    );
};
export default ImpactOnCIA;
