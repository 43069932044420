import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { Row, Col, Input, Modal, Button } from 'antd';
import { connect } from 'react-redux';
import { EditIntegrationFields } from 'store/actions/adminActions';

const FieldModal = (props) => {
    const { mode, open, handleClose, payload, EditIntegrationFields, fields, id } = props;
    const [field, setField] = useState({});

    console.log(fields);
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setField({ ...field, [name]: value, type: 'text' });
    };
    const handleModalClose = () => {
        handleClose();
        setField({});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let data;
        if (fields?.length > 0) {
            data =
                mode === 'add'
                    ? [...fields, field]
                    : fields?.map((item) =>
                          item?.name?.toLowerCase() === payload?.name?.toLowerCase()
                              ? { ...item, name: field?.name }
                              : item
                      );
        } else {
            data = [field];
        }
        console.log('data', data);
        const res = await EditIntegrationFields(id, { fields: data });
        if (res?.success) {
            toast.success(mode === 'add' ? 'Field Added Successfully' : 'Field Updated Successfully');
            handleModalClose();
        } else {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (payload) {
            setField(payload);
        }
    }, [payload]);

    return (
        <Modal
            title={`${mode === 'add' ? 'Add' : 'Edit'} Field`}
            visible={open}
            onCancel={handleModalClose}
            footer={null}
            destroyOnClose={true}
        >
            <Row justify="end">
                <Col xs={24}>
                    <div className="form-group" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="name">Name</label>
                        <Input
                            id="name"
                            type="text"
                            size="large"
                            value={field?.name || ''}
                            onChange={handleTextChange}
                            name="name"
                        />
                    </div>
                </Col>
                <Col>
                    <Button
                        className="ant-btn"
                        htmlType="submit"
                        type="primary"
                        // loading={loading}
                        style={{ marginTop: '3rem' }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { EditIntegrationFields })(FieldModal);
