import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import Play from '../../../../../assets/img/bullet.svg';
import ItemSubheader from '../../common/ItemSubheader';
import ExemptTag from '../../common/ExemptTag';

// Redux
import { connect } from 'react-redux';
import { RevertCiiCompliance } from 'store/actions/complianceActions';

//translation
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PciscansItem = (props) => {
    const { pci, onResourceChange, exception, RevertCiiCompliance, user_cii_tools, no_excemption } = props;
    const [loading, setLoading] = useState({ upload: false, exempt: false });
    const theme = useTheme();

    //translation
    const { t } = useTranslation('compliance');

    // memo
    // get the current document in view
    const selectedPci = useMemo(() => {
        return user_cii_tools?.find((doc) => doc?.id === pci?.id);
    }, [pci, user_cii_tools]);

    const removeExempt = async () => {
        setLoading({ ...loading, exempt: true });
        const res = await RevertCiiCompliance(pci?.id);
        setLoading({ ...loading, exempt: false });
        onResourceChange();
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    const payload = {
        cii_subsector: pci?.cii_subsector,
        cii_sector: pci?.cii_sector,
        tool: pci?.id,
        reason: '',
    };

    return (
        <Box sx={{ p: 2, py: 4 }}>
            <ItemSubheader
                hasResource={selectedPci?.category_details?.pci_details?.length > 0}
                exempting={loading.exempt}
                exceptionReason={pci}
                noResource
                type={'vulnerability management'}
                payload={payload}
                standard="cii"
                onResourceChange={onResourceChange}
                exception={exception}
                no_excemption={no_excemption}
                is_tool
            />
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ py: 5 }} spacing={3}>
                <Box sx={{ marginTop: 0.8 }}>
                    <img src={Play} alt="" />
                </Box>
                <Stack>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            sx={{
                                color: theme.palette.gray[800],
                                fontWeight: 400,
                                fontSize: 14,
                            }}
                        >
                            {pci?.title || pci?.tool_detail?.title}
                        </Typography>
                        {exception ? (
                            <Box
                                sx={{
                                    marginLeft: '1.5rem',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                }}
                            >
                                <ExemptTag
                                    onClick={removeExempt}
                                    loading={loading?.exempt}
                                    loadingLabel={t('reverting')}
                                    label={t('revert')}
                                />
                            </Box>
                        ) : null}
                    </Box>
                    {exception ? null : (
                        <Typography
                            sx={{
                                fontWeight: 300,
                                fontSize: 12,
                            }}
                        >
                            Haven't added a Pci scan yet?
                            <Link
                                to={{
                                    pathname: '/merchant/scans/pciscans',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: theme.palette.primary[900],
                                        fontWeight: 500,
                                        fontSize: 12,
                                        textDecoration: 'underline',
                                        pointerEvents: exception ? 'none' : 'hover',
                                    }}
                                    component={'span'}
                                >
                                    Add Pci here
                                </Typography>
                            </Link>
                            .
                        </Typography>
                    )}
                    <Box sx={{ marginTop: 3 }}>
                        <Stack direction="row" justifyContent="space-between" sx={{ flex: 1 }}>
                            <ScanStatus theme={theme} count={selectedPci?.category_details?.pci_details?.length} />
                        </Stack>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
};

const ScanStatus = ({ count, theme }) => {
    return (
        <Box
            sx={{
                border: '0.5px solid #B8BCCC',
                borderRadius: '20px',
                px: 1.6,
                py: 0.3,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '12px',
            }}
        >
            <span
                style={{
                    height: '10px',
                    width: '10px',
                    borderRadius: '50%',
                    marginRight: '8px',
                    backgroundColor: count > 0 ? theme.palette.success[300] : theme.palette.error[500],
                    display: 'inline-block',
                    fontSize: '12px',
                }}
            ></span>
            <Typography sx={{ letterSpacing: '1.2px', fontSize: '12px', fontWeight: 600 }}>
                {count || 0} Pci Scans
            </Typography>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        user_cii_tools: state?.adminReducers?.user_cii_tools,
    };
};

export default connect(mapStateToProps, {
    RevertCiiCompliance,
})(PciscansItem);
