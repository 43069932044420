import { DoNotDisturb } from '@mui/icons-material';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { forwardRef } from 'react';
import ProgressFile from '../../../../assets/img/progressfile.svg';

// core component
import ComplianceProgressBar from './ComplianceProgressBar';

const GroupCard = forwardRef((props, ref) => {
    const theme = useTheme();
    const {
        children,
        percentCompleted = 0,
        totalValue = 0,
        totalAttendedTo = 0,
        resourceLabel = '',
        onCardClick,
        // onHover,
        // onHoverOut,
        empty,
        id,
        exempted,
        isCategoryAssigned,
        isSubCatgoryAssigned,
        isResourceAssigned,
        standard,
        tag,
    } = props;
    return (
        <Grid
            item
            component="span"
            xs={12}
            sm={6}
            lg={standard === 'cii' ? 4 : standard === 'soc2' || standard === 'sslc' ? 4 : 6}
            xl={standard === 'soc2' || standard === 'sslc' ? 4 : 3}
            ref={ref}
            id={id}
        >
            <Box
                variant="outlined"
                sx={{
                    backgroundColor: standard === 'soc2' || standard === 'sslc' ? '#FFFFFF' : '',
                    borderRadius: standard === 'cii' ? 2 : 2,
                    color: '#525f7f',
                    borderColor: theme.palette.gray[300],
                    p: 1.7,
                    height: '100%',
                    display: 'grid',
                    border: standard === 'soc2' || standard === 'sslc' ? '' : '1px solid rgba(0, 0, 0, 0.12)',
                    padding: standard === 'cii' ? '20px' : '13.6px',
                    alignContent: 'space-between',
                    cursor: empty ? 'not-allowed' : exempted ? 'not-allowed' : 'pointer',
                    position: 'relative',
                }}
                onClick={!exempted && onCardClick}
                // onMouseEnter={onHover && (() => onHover(id))}
                // onMouseLeave={onHoverOut && (() => onHoverOut(id))}
            >
                <Box sx={{ pb: standard === 'soc2' || standard === 'sslc' ? 9 : 2 }}>{children}</Box>
                <Box sx={{ position: 'relative' }}>
                    {tag && (
                        <Typography sx={{ fontSize: '11px', fontWeight: 600, color: '#DE3730', position: 'absolute' }}>
                            {tag}
                        </Typography>
                    )}
                    <ComplianceProgressBar
                        percent={percentCompleted}
                        standard={standard}
                        isCategoryAssigned={isCategoryAssigned}
                    />
                    <Stack direction="row" justifyContent="space-between">
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <img src={ProgressFile} alt="ProgressFile" className={`${exempted ? 'mt-[-4px]' : '0'}`} />
                            <Typography
                                sx={{
                                    alignSelf: 'flex-start',
                                    fontSize: 11,
                                    color: '#94A3B8',
                                    fontWeight: 500,
                                }}
                            >
                                <Typography
                                    component="span"
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: 11,
                                        color: '#475569',
                                        pr: 0.5,
                                    }}
                                >
                                    {totalAttendedTo}/{totalValue}
                                </Typography>
                                {standard === 'soc2' || standard === 'sslc' ? 'Control objectives' : resourceLabel}
                            </Typography>
                        </Box>
                        {exempted && standard === 'sslc' && (
                            <Typography
                                sx={{
                                    fontSize: 11,
                                    fontWeight: 600,
                                    color: '#395BA9',
                                    backgroundColor: '#FFDAD6',
                                    px: 0.5,
                                    py: 0.3,
                                    borderRadius: 1,
                                }}
                            >
                                Exempted
                            </Typography>
                        )}

                        {!exempted && standard === 'sslc' && (
                            <Typography
                                sx={{
                                    alignSelf: 'flex-end',
                                    fontWeight: 400,
                                    fontSize: 11,
                                    color: '#64748B',
                                    // top: standard === 'soc2' ? 25 : 0,
                                }}
                            >
                                <Typography
                                    component="span"
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: 11,
                                        color: '#64748B',
                                    }}
                                >
                                    {percentCompleted?.toFixed(2) || 0}%
                                </Typography>{' '}
                                done
                            </Typography>
                        )}
                        {exempted && standard === 'soc2' && (
                            <Typography sx={{ fontSize: 12, fontWeight: 500, color: theme.palette.error.main, mt: 4 }}>
                                <DoNotDisturb sx={{ color: theme.palette.neutral[50] }} fontSize={'12px'} /> Exempted
                            </Typography>
                        )}
                    </Stack>
                </Box>
                {standard === 'soc2' || standard === 'sslc' || standard === 'iso9001' || standard !== 'card4' ? null : (
                    <>
                        {isSubCatgoryAssigned || isResourceAssigned || isCategoryAssigned ? (
                            <Stack
                                sx={{
                                    position: 'absolute',
                                    right: '-1.5%',
                                    top: '-3%',
                                    backgroundColor: theme.palette.shades.white,
                                    width: '12px',
                                    height: '12px',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        backgroundColor: theme.palette.tertiary[70],
                                    }}
                                />
                            </Stack>
                        ) : null}
                    </>
                )}
            </Box>
        </Grid>
    );
});

export default GroupCard;
