import React from 'react';

// core components
import NotificationDropdownTrigger from './NotificationDropdownTrigger';

const NotificationDropdown = ({ openNotificationModal, notificationModalOpen }) => {
    return (
        <>
            <NotificationDropdownTrigger
                openNotificationModal={openNotificationModal}
                notificationModalOpen={notificationModalOpen}
            />
            {/* menu goes down here. */}
        </>
    );
};

export default NotificationDropdown;
