import React, { useState } from 'react';
import { toast } from 'react-toastify';

//core component
import { Box } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppFormInput, AppSubmitButton, AppForm } from 'components/new_components/forms';
import { SettingsInputAntenna } from '@mui/icons-material';

//validation
import { requestModalValidation } from './utils/validation';

//redux
import { connect } from 'react-redux';
import { RequestVulnerabilityScan } from 'store/actions/merchantActions';

//translations
import { useTranslation } from 'react-i18next';

const VulnerabilityScanModal = (props) => {
    const { open, handleClose, RequestVulnerabilityScan, payload } = props;
    const [loading, setLoading] = useState(false);

    //translation
    const { t } = useTranslation('vulnerabilityManagement');

    //function
    const handleSubmit = async (values) => {
        setLoading(true);
        const res = await RequestVulnerabilityScan(values);
        setLoading(false);
        if (res?.success) {
            toast.success('Successful Request');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <AppForm initialValues={payload} onSubmit={handleSubmit} validate={requestModalValidation}>
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                title={t('vulnerabilityScanModal.title')}
                width={450}
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <AppSubmitButton
                            text={t('vulnerabilityScanModal.button')}
                            startIcon={<SettingsInputAntenna />}
                            variant="contained"
                            color="primary"
                            loading={loading}
                            loadingPosition="center"
                            sx={{ width: '50%' }}
                        />
                    </Box>
                }
            >
                <AppFormInput
                    type="text"
                    label={t('vulnerabilityScanModal.scanName')}
                    name="request_name"
                    placeholder={t('vulnerabilityScanModal.scanName')}
                />
                <AppFormInput
                    type="text"
                    label={t('vulnerabilityScanModal.domainName')}
                    name="public_ip"
                    placeholder={t('vulnerabilityScanModal.domainName')}
                />
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { RequestVulnerabilityScan })(VulnerabilityScanModal);
