import { Box, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import React, { forwardRef, useMemo, useState } from 'react';
import GroupCard from './GroupCard';
import { connect } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExceptionModal from '../../common/ExemptionModal';
import { RevertCiiCompliance } from 'store/actions/complianceActions';
import { toast } from 'react-toastify';
import ExemptTag from '../../common/ExemptTag';
import { useTheme } from '@mui/styles';

const CategoryCard = forwardRef((props, ref) => {
    const theme = useTheme();
    const {
        metric,
        resourceLabel,
        onViewChange,
        id,
        title,
        description,
        subcriteria,
        exception,
        all_cii_summary,
        module_exception,
        RevertCiiCompliance,
        module_exception_id,
        index,
    } = props;

    const [anchorEl, setAnchorEl] = useState(false);
    const [ModalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const open = Boolean(anchorEl);
    const filteredSummary = all_cii_summary?.n_data?.filter((summary) => summary?.sector === id);

    const subcriteriaIsValid = (summary) => {
        return summary?.total > 0 || summary?.tools?.length === 0;
    };
    const criteriaIsValid = useMemo(() => {
        return filteredSummary?.some((sub) => subcriteriaIsValid(sub));
    }, [filteredSummary]);

    // functions
    const handleCardClick = () => {
        if (!ModalOpen) {
            if (criteriaIsValid || exception) onViewChange(id, index);
        }
    };

    // aFunctions
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const openModal = (event) => {
        event.stopPropagation();
        handleClose();
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    const empty = exception ? false : filteredSummary?.length > 0 ? !criteriaIsValid : true;

    const payload = {
        cii_sector: id,
        reason: '',
        module: true,
    };
    // Revert exempt
    const removeExempt = async () => {
        setLoading(true);
        const res = await RevertCiiCompliance(module_exception_id);
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <GroupCard
            percentCompleted={
                !metric?.totalValue && !metric?.totalAttendedTo
                    ? 100
                    : (metric?.totalAttendedTo / metric?.totalValue) * 100
            }
            totalValue={metric?.totalValue}
            totalAttendedTo={metric?.totalAttendedTo}
            resourceLabel={resourceLabel}
            onCardClick={handleCardClick}
            empty={empty}
            subcriteria={subcriteria}
            exception={exception}
            id={id}
            all_criteria_summary={all_cii_summary?.n_data}
            ref={ref}
            module_exception={module_exception}
            description={description}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Typography
                    variant="h3"
                    component="h3"
                    sx={{
                        width: '400px',
                        [theme.breakpoints.down('lg')]: {
                            width: '250px',
                        },
                    }}
                >
                    {title}
                </Typography>
                {index === 1 && !exception && (
                    <>
                        {module_exception?.id === id ? (
                            <ExemptTag
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removeExempt();
                                }}
                                loading={loading}
                                loadingLabel={'reverting...'}
                                label={'revert'}
                            />
                        ) : (
                            <Box sx={{}}>
                                <IconButton sx={{ p: 0 }} onClick={handleClick}>
                                    <MoreVertIcon size={30} />
                                </IconButton>
                                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                                    <MenuItem onClick={openModal}>Exempt</MenuItem>
                                </Menu>
                            </Box>
                        )}
                    </>
                )}
            </Box>
            <Typography
                sx={{
                    alignSelf: 'flex-start',
                    fontSize: 12,
                    fontWeight: 500,
                    color: index === 1 ? 'red' : '#000000',
                    width: '400px',
                    [theme.breakpoints.down('lg')]: {
                        width: '250px',
                    },
                }}
            >
                {description}
            </Typography>
            <ExceptionModal open={ModalOpen} handleClose={closeModal} payload={payload} standard={'cii'} />
        </GroupCard>
    );
});

const mapStateToProps = (state) => {
    return {
        all_cii_summary: state?.complianceReducers?.all_cii_summary,
    };
};

export default connect(mapStateToProps, { RevertCiiCompliance })(CategoryCard);
