import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { WarningAmberOutlined } from '@mui/icons-material';
// import DeleteIcon from "assets/img/DeleteIcon.svg";

// core components
import AppModal from './AppModal';
import AppButton from './AppButton';
import AppLoadingButton from './AppLoadingButton';

const AppDeleteModal = (props) => {
    const { open, handleClose, rerunning, onRun, title, subtitle, disabled } = props;
    const theme = useTheme();

    return (
        <AppModal
            open={open}
            handleClose={handleClose}
            width={450}
            actions={
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ my: 1.5 }}>
                    <AppButton
                        name="CANCEL"
                        variant="text"
                        sx={{ color: theme.palette.gray[900] }}
                        onClick={handleClose}
                    />
                    <AppLoadingButton
                        text={'Yes, Rerun'}
                        variant="contained"
                        color="primary"
                        loading={rerunning}
                        loadingPosition="center"
                        disabled={disabled}
                        onClick={onRun}
                    />
                </Stack>
            }
        >
            <Box
                sx={{
                    fontWeight: 500,
                    color: theme.palette.gray[800],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                }}
            >
                <WarningAmberOutlined sx={{ fontSize: '90px', color: theme.palette.primary[500], mb: 1 }} />
                {title && <span style={{ fontSize: '18px', fontWeight: 700 }}>{title}</span>}
                {subtitle && <p style={{ fontSize: '14px', fontWeight: 500, marginTop: '1rem' }}>{subtitle}</p>}
            </Box>
        </AppModal>
    );
};

export default AppDeleteModal;
