import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StepQuestions from './components/SelfStepQuestion';
import { AppForm, AppSubmitButton } from 'components/new_components/forms';
import AppButton from 'components/new_components/AppButton';
import Form1 from './components/ThreatIdentificationForm';
import Form2 from './components/ReportControlForm';
import { threatIdentificationValidation, existing_controlValidation } from '../../utils/validation';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
    GetAllComputers,
    GetAllInfrastructures,
    GetMerchantDocumentVersions,
    AddMerchantIncidentReport,
    UpdateMerchantIncidentReport,
} from 'store/actions/merchantActions';
import { reportSteps, ImpactOptionBy3, LikelihoodOptionBy3 } from '../../utils/constants';
import { AppFormContext } from 'components/new_components/forms';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

//translations
import { useTranslation } from 'react-i18next';

const IntelligenceAssessment = (props) => {
    const {
        payload,
        setPayload,
        AddMerchantIncidentReport,
        GetAllComputers,
        GetAllInfrastructures,
        handleGoBack,
        UpdateMerchantIncidentReport,
        closeForm,
    } = props;
    const [currentStep, setCurrentStep] = useState(1);
    const [matrix, setMatrix] = useState('3x3');
    const [owner, setOwner] = useState('');
    const [completed, setCompleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({});
    const [residualLikelihood, setResidualLikelihood] = useState([]);
    const [residualImpact, setResidualImpact] = useState([]);

    //translation
    const { t } = useTranslation('riskAssessment');

    useEffect(() => {
        if (values?.inherent_impact === '1' && residualImpact?.length <= 2) {
            const filteredArray = ImpactOptionBy3?.filter((impact) => impact?.value === '1');
            setResidualImpact(filteredArray);
        } else if (values?.inherent_impact === '2' && residualImpact?.length <= 2) {
            const filteredArray = ImpactOptionBy3?.filter((impact) => impact?.value === '1');
            setResidualImpact(filteredArray);
        } else if (values?.inherent_impact === '3' && residualImpact?.length <= 1) {
            const filteredArray = ImpactOptionBy3?.filter((impact) => impact?.value !== '3');
            setResidualImpact(filteredArray);
        }
    }, [values?.inherent_impact]);

    useEffect(() => {
        if (values?.inherent_likelihood === '1' && residualLikelihood?.length <= 2) {
            const filteredArray = LikelihoodOptionBy3?.filter((likelihood) => likelihood?.value === '1');
            setResidualLikelihood(filteredArray);
        } else if (values?.inherent_likelihood === '2' && residualLikelihood?.length <= 2) {
            const filteredArray = LikelihoodOptionBy3?.filter((likelihood) => likelihood?.value === '1');
            setResidualLikelihood(filteredArray);
        } else if (values?.inherent_likelihood === '3' && residualLikelihood?.length <= 1) {
            const filteredArray = LikelihoodOptionBy3?.filter((likelihood) => likelihood?.value !== '3');
            setResidualLikelihood(filteredArray);
        }
    }, [values?.inherent_likelihood]);

    const goBack = () => {
        history.goBack();
    };

    const handleBack = () => {
        setCurrentStep((prevActiveStep) => prevActiveStep - 1);
        setCompleted(false);
    };

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };
    const getCurrentValues = (values) => setValues(values);
    //async functions
    const getAllComputers = async () => {
        const res = await GetAllComputers();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllInfrastructures = async () => {
        const res = await GetAllInfrastructures();
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        getAllComputers();
        getAllInfrastructures();
    }, []);

    const handleReset = () => {
        setCurrentStep(1);
        setCompleted(false);
    };

    const onClose = () => {
        setOwner('');
        handleReset();
    };

    useEffect(() => {
        if (currentStep === reportSteps?.length) {
            setCompleted(true);
        }
    }, [currentStep, reportSteps]);

    const handleSubmit = async (values) => {
        const risk_owner = values?.risk_owner !== '' ? values?.risk_owner : owner;
        if (completed) {
            // const formdata = new FormData();
            const todays_date = new Date().toISOString();
            const dateDetectedDate = new Date(values.date_detected).toISOString();
            const date_detected = values.date_detected
                ? dateDetectedDate?.substr(0, dateDetectedDate?.length - 1)
                : todays_date?.substr(0, todays_date?.length - 1);
            const payload = {
                ...values,
                risk_owner: risk_owner,
                date_detected,
                matrix: matrix,
            };
            setLoading(true);
            const res = values?.id
                ? await UpdateMerchantIncidentReport(payload, values?.id, 1)
                : await AddMerchantIncidentReport(payload, 1);
            setLoading(false);
            setPayload({});
            if (res?.success) {
                toast.success('Intelligence Assessment ran Successfully');

                if (completed) {
                    onClose();
                    setCurrentStep(1);
                }
                if (values?.id) {
                    handleGoBack('intelligence');
                }
                setCurrentStep(1);
                setCompleted(false);
                setPayload({});
            } else {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    toast.error(res?.message);
                }
            }
        } else {
            handleNext();
        }
    };
    return (
        <Box>
            <Typography onClick={closeForm} sx={{ cursor: 'pointer', fontWeight: 600, my: 3, mx: 4 }}>
                <ArrowBackIosNewIcon color="primary" sx={{ width: 20, height: 20 }} />{' '}
                {t('intelligenceAssessment.goBack')}
            </Typography>
            <Grid container spacing={2} sx={{ mx: 2, mt: -3 }}>
                <Grid item lg={3.5} md={3.5} sm={12} xs={12} sx={{ marginTop: '2rem' }}>
                    <StepQuestions goBack={goBack} currentStep={currentStep} />
                </Grid>
                <AppForm
                    initialValues={payload}
                    onSubmit={handleSubmit}
                    validate={
                        currentStep === 1
                            ? threatIdentificationValidation
                            : currentStep === 2
                            ? existing_controlValidation
                            : null
                    }
                >
                    <Grid item lg={7.5} md={7.5} sm={12} xs={12} sx={{ marginTop: 1 }}>
                        <AppFormContext getValues={getCurrentValues}>
                            {currentStep === 1 ? (
                                <Form1
                                    values={values}
                                    matrix={matrix}
                                    setMatrix={setMatrix}
                                    owner={owner}
                                    setOwner={setOwner}
                                />
                            ) : (
                                <Form2 residualImpact={residualImpact} residualLikelihood={residualLikelihood} />
                            )}
                        </AppFormContext>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'left',
                                alignItems: 'center',
                                my: 2,
                            }}
                        >
                            {currentStep > 1 && (
                                <AppButton
                                    name={t('intelligenceAssessment.previous')}
                                    variant="text"
                                    color="primary"
                                    onClick={handleBack}
                                />
                            )}
                            {completed ? (
                                <AppSubmitButton
                                    text={t('intelligenceAssessment.finish')}
                                    variant="contained"
                                    color="primary"
                                    loading={loading}
                                    loadingPosition="center"
                                />
                            ) : (
                                <AppSubmitButton
                                    text={t('intelligenceAssessment.next')}
                                    variant="contained"
                                    color="primary"
                                    loading={loading}
                                    loadingPosition="center"
                                />
                            )}
                        </Box>
                    </Grid>
                </AppForm>
            </Grid>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_infrastructures: state?.merchantReducers?.all_merchant_infrastructures,
        all_computers: state?.merchantReducers?.all_computers,
    };
};
export default connect(mapStateToProps, {
    GetAllComputers,
    GetAllInfrastructures,
    GetMerchantDocumentVersions,
    AddMerchantIncidentReport,
    UpdateMerchantIncidentReport,
})(IntelligenceAssessment);
