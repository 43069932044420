import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import { ArrowBackIosNew } from '@mui/icons-material';
//translation
import { useTranslation } from 'react-i18next';
const FeaturePageHeader = (props) => {
    const { goBack, plan } = props;
    const theme = useTheme();

    const { t } = useTranslation('accounts');
    return (
        <Stack
            sx={{
                py: 1,
                px: 2.5,
                pb: 2,
                borderBottom: `1px solid ${theme.palette.gray[200]}`,
            }}
            direction="row"
            alignItems="center"
            component="header"
        >
            <ArrowBackIosNew
                sx={{
                    width: 42,
                    height: 42,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: theme.palette.gray[50],
                    color: theme.palette.gray[500],
                    p: 1,
                    mr: 2,
                    borderRadius: '50%',
                    cursor: 'pointer',
                }}
                onClick={goBack}
            />
            <Stack sx={{ flex: 1, textAlign: 'center' }}>
                <Typography
                    component="h2"
                    variant="h2"
                    sx={{
                        fontSize: 24,
                        fontWeight: 700,
                        m: 0,
                        p: 0,
                    }}
                    color="primary"
                >
                    {t('plans.featurePageHeader.header')} {plan?.name}
                </Typography>
                {plan?.id === 'custom' && (
                    <Typography
                        component="p"
                        variant="subtitle1"
                        sx={{
                            color: theme.palette.gray[30],
                            fontWeight: 500,
                            fontSize: 16,
                            lineHeight: 0.8,
                        }}
                    >
                        {t('plans.featurePageHeader.subtitle')}
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
};

export default FeaturePageHeader;
