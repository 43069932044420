import React from 'react';
import { HelpOutlineOutlined, Close } from '@mui/icons-material';
import { Stack, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//core components
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import AppTag from 'components/new_components/AppTags';
import AppButton from 'components/new_components/AppButton';

//utils
import { vendorRiskLevels } from '../../utils/constants';

//translations
import { useTranslation } from 'react-i18next';

const RiskLevelsModal = (props) => {
    const { open, handleClose } = props;

    const theme = useTheme();

    //translation
    const { t } = useTranslation('vendorManagement');

    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            padding="0px"
            width="430px"
            title={
                <Stack flexDirection="row" alignItems="center" justifyContent="center">
                    <HelpOutlineOutlined sx={{ pr: 0.5 }} />
                    <span>{t('riskLevelModal.title')}</span>
                </Stack>
            }
            headerAction={
                <>
                    <AppButton
                        name={<Close size="14px" />}
                        onClick={handleClose}
                        sx={{
                            fontSize: '12px',
                            fontStyle: 'italic',
                            fontWeight: '400',
                            color: theme.palette.gray[400],
                            borderRadius: '50%',
                            '&:hover': {
                                backgroundColor: theme.palette.white.main,
                            },
                        }}
                    />
                </>
            }
        >
            <Stack>
                {vendorRiskLevels?.map((risk) => {
                    return (
                        <Box
                            sx={{
                                p: 1.5,
                                color: theme.palette.black.main,
                                fontSize: '13px',
                                borderBottom: risk.id <= 2 ? `1px solid ${theme.palette.gray[100]} ` : '',
                            }}
                            key={risk.id}
                        >
                            {risk?.name.toLowerCase() === 'low' ? (
                                <AppTag text={risk?.name} type="success" />
                            ) : risk?.name.toLowerCase() === 'medium' ? (
                                <AppTag text={risk?.name} type="pending" />
                            ) : risk?.name.toLowerCase() === 'high' ? (
                                <AppTag text={risk?.name} type="high" />
                            ) : risk?.name.toLowerCase() === 'critical!' ? (
                                <AppTag text={risk?.name} type="critical" />
                            ) : (
                                <AppTag text={risk?.name} type="default" />
                            )}

                            <Typography sx={{ pt: 1, fontSize: '13px' }}>{risk?.notes}</Typography>
                        </Box>
                    );
                })}
            </Stack>
        </AppCenteredModal>
    );
};

export default RiskLevelsModal;
