import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

//translations
import { useTranslation } from 'react-i18next';

const EmployeeTableActions = (props) => {
    const theme = useTheme();
    const { row, openModal, openViewModal, openDeleteModal, role } = props;

    // state
    const [anchor, setAnchor] = useState(null);

    //translation
    const { t } = useTranslation('inventory');

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const openEditModal = () => {
        const payload = {
            first_name: row?.first_name,
            last_name: row?.last_name,
            email: row?.email,
            position: row?.position,
            phone: row?.phone,
            department: row?.department,
            address: row?.address,
            policy_status: row?.policy_status,
        };
        openModal('edit', payload, row?.id);
        closeMenu();
    };

    const OpenViewModal = () => {
        const payload = row;
        openViewModal(payload);
        closeMenu();
    };

    const OpenDeleteModal = () => {
        const payload = {
            first_name: row?.first_name,
            last_name: row?.last_name,
            email: row?.email,
            position: row?.position,
            phone: row?.phone,
            department: row?.department,
            address: row?.address,
            policy_status: row?.policy_status,
        };
        openDeleteModal(payload, row?.id);
        closeMenu();
    };
    return (
        <>
            <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                <MoreHorizIcon />
            </IconButton>
            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                <MenuItem onClick={OpenViewModal}>{t('employeeTable.tableAction.view')}</MenuItem>
                {role !== 'auditor' ? (
                    <>
                        <MenuItem onClick={openEditModal}>{t('employeeTable.tableAction.edit')}</MenuItem>
                        <MenuItem onClick={OpenDeleteModal}>{t('employeeTable.tableAction.delete')}</MenuItem>
                    </>
                ) : null}
            </Menu>
        </>
    );
};

export default EmployeeTableActions;
