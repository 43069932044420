import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, MenuItem, Stack } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import AppTableActionMenu from 'components/new_components/app-table/AppTableActionMenu';

const ComplianceTableActions = (props) => {
    const theme = useTheme();
    const { row, openDeleteModal, openReportModal, reRunScanAction, rerunning, role } = props;

    // state
    const [anchor, setAnchor] = useState(null);

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const handleOpenDeleteModal = () => {
        openDeleteModal(row);
        closeMenu();
    };

    const handleOpenReportModal = () => {
        openReportModal(row);
        closeMenu();
    };

    const handleScanRerun = () => {
        reRunScanAction({ link: row?.link }, closeMenu);
    };

    return (
        <Stack direction="row">
            <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                <MoreHoriz />
            </IconButton>
            <AppTableActionMenu anchor={anchor} closeMenu={closeMenu}>
                {role !== 'auditor' && (
                    <MenuItem
                        sx={{ fontSize: 13, fontWeight: 500, letterSpacing: '0.1px', color: theme.palette.neutral[50] }}
                        onClick={handleScanRerun}
                        disabled={row?.status === 'running' || row?.status === 're-running' || rerunning}
                    >
                        Rerun scan
                    </MenuItem>
                )}
                <MenuItem
                    sx={{ fontSize: 13, fontWeight: 500, letterSpacing: '0.1px', color: theme.palette.neutral[50] }}
                    onClick={handleOpenReportModal}
                    disabled={row?.status === 'running'}
                >
                    Download report
                </MenuItem>
                {role !== 'auditor' && (
                    <MenuItem
                        sx={{ fontSize: 13, fontWeight: 500, letterSpacing: '0.1px', color: theme.palette.neutral[50] }}
                        onClick={handleOpenDeleteModal}
                    >
                        Delete scan
                    </MenuItem>
                )}
            </AppTableActionMenu>
        </Stack>
    );
};

export default ComplianceTableActions;
