import { Button, Col, Input, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { CreateAccessortSubCategory, EditAccessorSubCategory } from 'store/actions/auditActions';

const SubCategoryModal = (props) => {
    const { open, handleClose, modalData, CreateAccessortSubCategory, EditAccessorSubCategory, category_id } = props;

    // states
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // functions
    const closeAll = () => {
        handleClose();
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const body = {
            ...data,
            category: category_id,
        };
        const res =
            open === 'Add'
                ? await CreateAccessortSubCategory(body)
                : await EditAccessorSubCategory(body, modalData?.id);
        setLoading(false);
        if (res?.success) {
            closeAll();
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (open) {
            setData(modalData);
        }
    }, [open]);

    return (
        <Modal open={open} onCancel={closeAll} title={`${open} sub category`} footer={null} destroyOnClose>
            <form onSubmit={handleSubmit}>
                <Row justify="end" gutter={12}>
                    <Col span={24}>
                        <div className="mb-2">
                            <label htmlFor="name">Name of Sub Category</label>
                            <Input
                                type="text"
                                size="large"
                                name="name"
                                id="name"
                                onChange={handleTextChange}
                                value={data?.name || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col>
                        <Button htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, {
    CreateAccessortSubCategory,
    EditAccessorSubCategory,
})(SubCategoryModal);
