import {
    ALL_TRAINING_COURSES,
    ONE_TRAINING_COURSE,
    ALL_TRAINING,
    ONE_TRAINING,
    ONE_TRAINEE,
    ALL_COURSES_VIDEOS,
    ALL_COURSE_DOCUMENTS,
    ALL_TRAINEE_COURSE_DOCUMENTS,
} from '../constants';
import axiosInstance from '../../utils/https';
import axios from 'axios';
import { baseUrl } from 'config';

// T R A I N I N G   C O U R S E S
export const GetAllTrainingCourses = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/course/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_TRAINING_COURSES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetOneTrainingCourse = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`${baseUrl}/compliance/course/${id}/`);
        const data = res.data;
        if (data?.status?.toLowerCase() !== 'success') throw new Error(data?.message);
        dispatch({ type: ONE_TRAINING_COURSE, payload: data?.data });
        return {
            success: true,
            message: data?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateTrainingCourse = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/course/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllTrainingCourses());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateTrainingCourse = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/course/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllTrainingCourses());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteTrainingCourse = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/course/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllTrainingCourses());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//   T R A I N I N G   R E C O R D S
export const GetTrainingByCourse = (course_id, params) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/training/?course=${course_id}`, {
            params,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_TRAINING, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetTrainingById = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`${baseUrl}/compliance/training/${id}/`);
        const data = res.data;
        if (data?.status?.toLowerCase() !== 'success') throw new Error(data?.message);
        dispatch({ type: ONE_TRAINING, payload: data?.data });
        return {
            success: true,
            message: data?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateTraining = (credentials) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/compliance/training/`, { ...credentials, merchant });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetTrainingByCourse(credentials?.course));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const ResendTraining = (credentials) => async () => {
    try {
        const res = await axiosInstance().post(`/compliance/resend_training_url/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditTraining = (credentials, id) => async (dispatch) => {
    try {
        const res = await axios.patch(`${baseUrl}/compliance/training/${id}/`, credentials);
        const data = res.data;
        if (data?.status?.toLowerCase() !== 'success') throw new Error(data?.message);
        dispatch(GetTrainingById(id));
        return {
            success: true,
            message: data?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteTraining = (id, course) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/training/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetTrainingByCourse(course));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//  V I D E O S
export const GetAllCoursesVideos = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/course_video/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_COURSES_VIDEOS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddCourseVideo = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/course_video/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCoursesVideos());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateCourseVideo = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/course_video/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCoursesVideos());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteCourseVideo = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/course_video/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCoursesVideos());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// D O C U M E N T S
export const GetAllCoursesDocuments = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/course_doc/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_COURSE_DOCUMENTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllTraineeCoursesDocuments = () => async (dispatch) => {
    try {
        const res = await axios.get(`${baseUrl}/compliance/course_doc/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_TRAINEE_COURSE_DOCUMENTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddCourseDocument = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/course_doc/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCoursesDocuments());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateCourseDocument = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/course_doc/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCoursesDocuments());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteCourseDocument = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/course_doc/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllCoursesDocuments());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
//  E N R O L L E E
export const GetTraineeDetails = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`${baseUrl}/compliance/people/${id}/`);
        const data = res.data;
        if (data?.status?.toLowerCase() !== 'success') throw new Error(data?.message);
        dispatch({
            type: ONE_TRAINEE,
            payload: data?.data,
        });
        return {
            success: true,
            message: data?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
