import React from 'react';
import { Grid } from '@mui/material';
import LoadingState from 'components/new_components/LoadingState';
import CategoryCard from './CriteriaCard';
import EmptyState from 'components/new_components/EmptyState';

// Redux
import { connect } from 'react-redux';

//translation
import { useTranslation } from 'react-i18next';

const Categories = (props) => {
    const { resource, loading, onViewChange, data, exception, module_exception, module_exception_id } = props;

    //translation
    const { t } = useTranslation('compliance');

    return !loading ? (
        <Grid container spacing={1.5} sx={{ p: 2, pt: 6 }}>
            {data && data?.length ? (
                data
                    ?.sort((a, b) => a?.id - b?.id)
                    ?.map((category, index) => {
                        const resourceLabel = resource === 'doc' ? t('fileUploaded') : t('questionAnswered');
                        return (
                            <CategoryCard
                                key={category?.id}
                                id={category?.id}
                                index={index}
                                title={category?.name}
                                description={category?.description}
                                chips={category?.chip}
                                resourceLabel={resourceLabel}
                                onViewChange={onViewChange}
                                blockchain
                                subcriteria={category?.subsectors}
                                exception={exception}
                                module_exception={module_exception}
                                module_exception_id={module_exception_id}
                            />
                        );
                    })
            ) : (
                <EmptyState description={exception ? t('blockchain.noException') : 'No CII Sector'} />
            )}
        </Grid>
    ) : (
        <LoadingState />
    );
};
const mapStateToProps = (state) => {
    return {
        all_categories: state?.adminReducers?.all_categories,
        all_categories_metrics: state?.complianceReducers?.all_categories_metrics,
    };
};
export default connect(mapStateToProps, {})(Categories);
