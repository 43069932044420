import React, { useState } from 'react';
import Brand from 'components/new_components/Brand';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import Rectangle from '../../../assets/img/Rectangle.svg';
import { toast } from 'react-toastify';
import WelcomePage from './WelcomePage';
import AgreementImage from '../../../assets/img/Agreement.png';
import CompletedPage from './Completed';
import RespondedPage from './Responded';
import SurveyQuestions from './SurveyQuestions';
import { GetAllSurvey, CheckSurveyCompletion } from 'store/actions/adminActions';
import { GetAllSurveyResponse } from 'store/actions/merchantActions';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import AppLinearProgress from 'components/new_components/AppLinearProgress';
import LoadingState from 'components/new_components/LoadingState';

const SurveyIndex = (props) => {
    const { merchant_id, user } = useParams();
    const theme = useTheme();
    const { GetAllSurvey, all_survey, survey_responses, GetAllSurveyResponse, CheckSurveyCompletion } = props;
    const [completed, setCompleted] = useState(false);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState(false);
    const [answer, setAnswer] = useState([]);
    const [other_answer, setOtheranswer] = useState('');
    const [status_response, setStatus_response] = useState('');
    const [loading, setLoading] = useState(false);
    const [question, setQuestion] = useState('');
    const perPage = 1;

    const allSurveys = !loading ? all_survey : [];
    const handleNextPage = () => {
        if (question && page !== allSurveys?.Length) {
            setPage(page + 1);
            setAnswer([]);
            setStatus(false);
            setQuestion('');
            setOtheranswer('');
        }
    };

    const handlePreviousPage = () => {
        if (page === 1) {
            setPage(page);
        } else {
            setPage(page - 1);
        }

        setStatus(false);
        setQuestion('');
        setOtheranswer('');
    };

    const getAllSurvey = async () => {
        setLoading(true);
        const res = await GetAllSurvey();
        setLoading(false);
        if (!res.success) {
            toast.error(res?.message);
        }
        setLoading(false);
    };

    // async functions
    const getAllSurveyResponse = async () => {
        setLoading(true);
        const res = await GetAllSurveyResponse(user);
        setLoading(false);
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };

    useEffect(() => {
        getAllSurvey();
        getAllSurveyResponse();
    }, []);

    const checkSurveyCompletion = async () => {
        const body = {
            merchant_id,
            user_id: user,
        };
        const res = await CheckSurveyCompletion(body);
        setStatus_response(res?.data?.status);
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };

    useEffect(() => {
        checkSurveyCompletion();
    }, []);

    const percentage = (survey_responses?.length / allSurveys?.length) * 100;

    return (
        <Box
            sx={{
                mx: '2rem',
                height: '100vh',
            }}
        >
            <Brand type="blue" />
            {loading ? (
                <LoadingState />
            ) : (
                <Stack flexDirection={'row'} justifyContent={'center'} alignItems="center" sx={{ marginTop: '5rem' }}>
                    <img src={AgreementImage} alt="agreement" style={{ position: 'absolute', top: '24px' }} />
                    <Card
                        sx={{
                            width: status_response === 'filled' ? '45%' : status_response === 'filled' ? '45%' : '50%',
                            height:
                                status_response === 'filled'
                                    ? '70vh'
                                    : completed === true
                                    ? '70vh'
                                    : status_response === 'partially_filled'
                                    ? '80vh'
                                    : '70vh',
                            borderRadius: '20px',
                            px:
                                status_response === 'not_filled'
                                    ? '4.5rem'
                                    : status_response === 'partially_filled'
                                    ? '0'
                                    : '2rem',
                            position: 'relative',
                            marginBottom: status_response === 'partially_filled' ? '4rem' : 0,
                            overflow: 'scroll',
                            boxShadow:
                                '-189px 200px 110px rgba(209, 209, 209, 0.01), -106px 112px 93px rgba(209, 209, 209, 0.05), -47px 50px 69px rgba(209, 209, 209, 0.09), -12px 12px 38px rgba(209, 209, 209, 0.1), 0px 0px 0px rgba(209, 209, 209, 0.1)',
                            [theme.breakpoints.down('sm')]: {
                                px: '2rem',
                                width: '100%',
                                height: '80vh',
                            },
                        }}
                    >
                        {status_response === 'partially_filled' && (
                            <Box sx={{ padding: -4 }}>
                                <AppLinearProgress value={percentage} survey={true} />
                            </Box>
                        )}
                        <Box
                            sx={{
                                padding: 4,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img src={Rectangle} alt="rectangle" />
                                <Box
                                    sx={{
                                        marginLeft: 1,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '20px',
                                            fontWeight: '700',
                                            color: '#46464A',
                                            marginBottom: '-0.6rem',
                                        }}
                                    >
                                        Survey
                                    </Typography>
                                    <Typography
                                        component={'span'}
                                        sx={{
                                            fontSize: '10px',
                                            fontWeight: '200',
                                            color: '#46464A',
                                        }}
                                    >
                                        The road so far...
                                    </Typography>
                                </Box>
                            </Box>
                            {status_response === 'filled' ? (
                                <RespondedPage />
                            ) : completed === true ? (
                                <CompletedPage />
                            ) : status_response === 'partially_filled' ? (
                                <SurveyQuestions
                                    status={status}
                                    setStatus={setStatus}
                                    answer={answer}
                                    setAnswer={setAnswer}
                                    setOtheranswer={setOtheranswer}
                                    other_answer={other_answer}
                                    setCompleted={setCompleted}
                                    allSurveys={allSurveys}
                                    survey_responses={survey_responses}
                                    setQuestion={setQuestion}
                                    page={page}
                                    setPage={setPage}
                                    perPage={perPage}
                                    loading={loading}
                                    merchant_id={merchant_id}
                                    user={user}
                                />
                            ) : (
                                <WelcomePage setPageType={setStatus_response} />
                            )}
                        </Box>

                        {status_response === 'not_filled' ||
                        completed === true ||
                        status_response === undefined ||
                        status_response === 'filled' ? null : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    height: '10vh',
                                    padding: '0 1rem',
                                }}
                            >
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handlePreviousPage}
                                    sx={{
                                        padding: '0.5rem ',
                                    }}
                                >
                                    <ArrowBackIosIcon
                                        fontSize="small"
                                        sx={{
                                            color: page === 1 ? '#C7C6CA' : '#FFFFFF',
                                            cursor: page === 1 ? 'not-allowed' : 'pointer',
                                        }}
                                    />
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handleNextPage}
                                    sx={{
                                        padding: '0.5rem ',
                                        marginLeft: 3,
                                        cursor:
                                            question === '' && page !== allSurveys?.length ? 'not-allowed' : 'pointer',
                                    }}
                                >
                                    <ArrowForwardIosIcon
                                        sx={{
                                            color:
                                                question === '' && page !== allSurveys?.length ? '#C7C6CA' : '#FFFFFF',
                                        }}
                                        fontSize="small"
                                    />
                                </Button>
                            </Box>
                        )}
                    </Card>
                </Stack>
            )}
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_survey: state?.adminReducers?.all_survey,
        survey_responses: state?.merchantReducers?.survey_responses,
    };
};

export default connect(mapStateToProps, {
    GetAllSurvey,
    GetAllSurveyResponse,
    CheckSurveyCompletion,
})(SurveyIndex);
