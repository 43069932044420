import React from 'react';
import { useTheme } from '@mui/material/styles';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';

const AppMenuItem = (props) => {
    const theme = useTheme();
    const { icon, text, color = theme.palette.gray[900], sx, ...restProps } = props;
    return (
        <MenuItem
            sx={{
                py: 1.8,
                '& *': { color },
                ...sx,
            }}
            {...restProps}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText sx={{ '& span': { fontSize: '13.5px', fontWeight: 600 } }}>{text}</ListItemText>
        </MenuItem>
    );
};

export default AppMenuItem;
