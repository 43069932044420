// import { Box, Button, Divider, Menu, MenuItem, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
// import FileUnCheck from 'assets/img/FileUncheck.svg';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
// import { MoreVert } from '@mui/icons-material';
// import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import { Box, Typography } from '@mui/material';
import { DeleteEvidenceExemption } from 'store/actions/complianceActions';
import { Loader2 } from 'lucide-react';

const EvidenceException = (props) => {
    const { all_exempted_evidences, requirement, loading } = props;
    const [loadingExemption, setLoadingEemption] = useState(false);
    const [current_id, setCurrentId] = useState(false);
    const dispatch = useDispatch();

    const filtered_exceptions = useMemo(() => {
        return all_exempted_evidences?.filter((exception) => exception?.evidence_detail?.card4 === requirement?.id);
    }, [requirement, all_exempted_evidences]);

    // functions
    const removeException = async (id) => {
        setLoadingEemption(true);
        const res = await dispatch(DeleteEvidenceExemption(id, 'card4'));
        setLoadingEemption(false);
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error('Something went wrong!');
        }
    };
    return (
        <div>
            <>
                {loading ? (
                    <div className="flex justify-center items-center">
                        <Loader2 className="h-10 w-10 animate-spin" />
                    </div>
                ) : (
                    <div>
                        {filtered_exceptions?.length > 0 ? (
                            <div>
                                {filtered_exceptions?.map((option) => (
                                    <div className="flex justify-between items-center mb-3 pb-3 border-b border-[#F1F5F9]">
                                        <div className="w-[80%]">
                                            <p className="text-sm font-medium text-[#395BA9]">
                                                {option?.evidence_detail?.card4_title}
                                            </p>
                                            <p className="text-xs font-normal text-gray mt-2">
                                                Exeption Reason: {option?.reason}
                                            </p>
                                        </div>
                                        <div
                                            className="flex itesmc-center gap-2 border border-[#E2E8F0] rounded-md p-1.5 cursor-pointer"
                                            onClick={() => {
                                                setCurrentId(option?.id);
                                                removeException(option.id);
                                            }}
                                        >
                                            {loadingExemption && option?.id === current_id && (
                                                <Loader2 className="h-6 w-6 animate-spin" />
                                            )}{' '}
                                            <p className="text-sm font-medium text-[#395BA9]">Revert</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#475569',
                                    fontSize: '15px',
                                    fontWeight: 600,
                                }}
                            >
                                <Typography>No Evidence Exempted</Typography>
                            </Box>
                        )}
                    </div>
                )}
            </>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        all_exempted_evidences: state?.complianceReducers?.all_exempted_evidences,
    };
};

export default connect(mapStateToProps, {})(EvidenceException);
