import React, { useEffect, useState } from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';

//translations
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppDrawer from 'components/new_components/AppDrawer';
import { GetAllSources } from 'store/actions/FraudGauardianActions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Settings from 'assets/img/red-settings.svg';
import { RequestKyc } from 'store/actions/FraudGauardianActions';
import { AddVendor } from 'store/actions/vendorActions';

const VendorModal = (props) => {
    // Props
    const { open, handleClose, openVendorModal, values } = props;

    // hOOK STATES
    const [type, setType] = useState('Other Entities');
    const [sources, setSources] = useState([]);
    const [continous_monitoring, setContinous_monitoring] = useState(false);
    const [addVendor, setAddVendor] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [sourceType, setSourceType] = useState([]);
    const [entity_name, setEntity_name] = useState('');

    // Theme
    const theme = useTheme();

    // Redux
    const dispatch = useDispatch();
    const { all_sources } = useSelector((state) => state?.fraudGuardianReducers);

    // Async effect
    useEffect(() => {
        const getSources = async () => {
            const res = await dispatch(GetAllSources());
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getSources();
    }, []);

    useEffect(() => {
        function getRunIDs() {
            let entries = all_sources;
            let all_ids = [];
            let source_type = [];
            entries?.forEach((entry) => {
                source_type.push(entry['id']);
                entry['type_lists'].forEach((run) => {
                    all_ids.push(run['id']);
                });
            });
            return setSources(all_ids), setSourceType(source_type);
        }
        getRunIDs();
    }, [open, all_sources]);

    const handleCheckAll = (el) => {
        const sourceParent = all_sources?.find((parent) => parent?.id === el?.id);
        const SourceChildren = sourceParent?.type_lists?.map((child) => child?.id);

        const copyOfTypeIds = [...sourceType];
        if (sourceType.includes(el.id)) {
            const indexOfTypeIDToRemove = sourceType.indexOf(el.id);
            copyOfTypeIds.splice(indexOfTypeIDToRemove, 1);
            setSourceType(copyOfTypeIds);
            const itemsToRemove = sources.filter((id) => !SourceChildren?.includes(id));
            setSources(itemsToRemove);
        } else {
            setSources([...sources, ...SourceChildren]);
            setSourceType([...copyOfTypeIds, el.id]);
        }
    };

    const handleChange = (id) => {
        const modified_sources = [...sources];
        const one_item = modified_sources?.findIndex((item) => item === id);
        if (sources?.includes(id)) {
            modified_sources.splice(one_item, 1);
            setSources(modified_sources);
        } else {
            modified_sources.push(id);
            setSources(modified_sources);
        }
    };
    const onClose = () => {
        setContinous_monitoring(false);
        setAddVendor(false);
        setEntity_name('');
        handleClose();
    };
    const handleSubmit = async (message) => {
        const non_amlValues = {
            name: entity_name,
            description: values.description,
            data: values.data,
            risk_level: values.risk_level,
            products: values.products,
            email: values.email,
            is_aml_vendor: false,
        };

        const amlValues = {
            name: entity_name,
            description: values.description,
            data: values.data,
            risk_level: values.risk_level,
            products: values.products,
            email: values.email,
            min_risk_level: values.min_risk_level,
            is_aml_vendor: true,
            ran_aml: values?.ran_aml,
        };
        const body = type === 'AML' ? amlValues : non_amlValues;
        const res = await dispatch(AddVendor(body));
        if (res?.success) {
            toast.success(message);
            setEntity_name('');
            setContinous_monitoring(false);
            setAddVendor(false);
            onClose();
        } else {
            setLoading(false);
            toast.error(res?.message);
        }
    };

    const HandleKyc = async () => {
        if (entity_name === '') {
            setError(true);
        } else if (sources?.length === 0) {
            return toast.info('Please check atleast one source');
        } else if (type === 'Vendor' && addVendor && values?.data === '') {
            return toast.info('Vendor Settings is required');
        } else {
            setLoading(true);
            const res = await dispatch(RequestKyc({ sources, entity_name, continous_monitoring }));
            setLoading(false);
            if (res?.success) {
                if (type === 'Vendor') {
                    handleSubmit(res.message);
                } else {
                    toast.success(res.message);
                    setEntity_name('');
                    setContinous_monitoring(false);
                    setAddVendor(false);
                    onClose();
                }
            }
            if (!res?.success) {
                toast.error("Something went wrong, couldn't fetch courses.");
            }
        }
    };

    return (
        <AppDrawer
            open={open}
            handleClose={onClose}
            title={<Typography sx={{ color: '#202D66', fontSize: '16px', fontWeight: 600 }}>Run AML check</Typography>}
            icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
            noShadow
            noClose
            width="550px"
            action={
                <div className="flex justify-end items-center gap-2">
                    <Button
                        sx={{
                            backgroundColor: '#FFFFFF',
                            border: `1px solid ${theme.palette.gray[200]}`,
                            textTransform: 'inherit',
                            color: theme.palette.gray[700],
                            fontSize: '14px',
                            fontWeight: 500,
                        }}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                    <LoadingButton variant="contained" onClick={HandleKyc} loading={loading} loadingPosition="center">
                        RUN
                    </LoadingButton>
                </div>
            }
        >
            <Box
                sx={{
                    mx: 3,
                    my: 2,
                    overflow: 'scroll !important',
                    height: '83vh',
                    [theme.breakpoints.down('lg')]: {
                        height: '83vh',
                    },
                }}
            >
                <Box
                    sx={{
                        border: '2px solid #395BA9',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 2,
                    }}
                >
                    <Typography
                        sx={{
                            backgroundColor: type === 'Other Entities' ? '#395BA9' : 'transpparent',
                            width: '50%',
                            color: type === 'Other Entities' ? '#FFFFFF !important' : '#395BA9 !important',
                            textAlign: 'center',
                            py: 1.2,
                            fontWeight: 500,
                            cursor: 'pointer',
                            fontSize: '14px',
                        }}
                        onClick={() => setType('Other Entities')}
                    >
                        Other Entities
                    </Typography>
                    <Typography
                        sx={{
                            backgroundColor: type === 'Vendor' ? '#395BA9' : 'transpparent',
                            width: '50%',
                            color: type === 'Vendor' ? '#FFFFFF !important' : '#395BA9 !important',
                            textAlign: 'center',
                            py: 1.2,
                            fontWeight: 500,
                            cursor: 'pointer',
                            fontSize: '14px',
                        }}
                        onClick={() => setType('Vendor')}
                    >
                        Vendors
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    {type === 'Vendor' && (
                        <div className="flex justify-between items-center">
                            <div>
                                <FormControlLabel
                                    label={
                                        <p className="text-[13px] text-[#395BA9] font-medium ">
                                            Add vendor to vendor management
                                        </p>
                                    }
                                    control={
                                        <Checkbox
                                            checked={addVendor}
                                            onChange={(event) => setAddVendor(event.target.checked)}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                                        />
                                    }
                                />
                            </div>{' '}
                            {addVendor && (
                                <Tooltip
                                    title="Vendor settings is required"
                                    arrow
                                    placement="bottom-end"
                                    sx={{ backgroundColor: 'green' }}
                                >
                                    <div className="flex items-center gap-1 cursor-pointer" onClick={openVendorModal}>
                                        <img src={Settings} alt="Settings" />
                                        <p className="text-xs text-[#FF5449] font-medium underline">settings</p>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    )}
                    {type === 'Other Entities' ? (
                        <div>
                            <p className="text-[13px] font-medium text-gray-500 mb-1">Enter Name</p>
                            <input
                                placeholder="Enter Name"
                                value={entity_name}
                                onChange={(event) => setEntity_name(event?.target.value)}
                                className="font-normal text-[13px] placeholder:text-gray-400 border border-gray-200 py-2.5 px-2 rounded-[4px] w-full"
                            />
                            {entity_name === '' && error && (
                                <p className="mt-1 text-[#FF5449] text-xs font-normal">Please enter entity name</p>
                            )}
                        </div>
                    ) : (
                        <div>
                            <p className="text-[13px] font-medium text-gray-500 mb-1">Vendor Name</p>
                            <input
                                placeholder="Enter Name"
                                value={entity_name}
                                onChange={(event) => setEntity_name(event?.target.value)}
                                className="font-normal text-[13px] placeholder:text-gray-400 border border-gray-200 py-2.5 px-2 rounded-[4px] w-full"
                            />
                            {entity_name === '' && error && (
                                <p className="mt-1 text-[#FF5449] text-xs font-normal">Please enter vendor name</p>
                            )}
                        </div>
                    )}
                    <Box sx={{ mt: 2 }}>
                        <Typography sx={{ color: '#202D66', fontSize: '14px', fontWeight: 700 }}>Sources</Typography>
                        {all_sources && (
                            <div className="mt-2 flex items-start justify-between gap-2">
                                <div className="w-[48%]">
                                    <div>
                                        <FormControlLabel
                                            label={
                                                <p className="text-sm text-gray-600 font-medium">
                                                    {all_sources[0]?.name}
                                                </p>
                                            }
                                            control={
                                                <Checkbox
                                                    checked={sourceType?.includes(all_sources[0]?.id)}
                                                    indeterminate={sourceType?.includes(all_sources[0]?.id)}
                                                    onChange={() => handleCheckAll(all_sources[0])}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 20,
                                                        },
                                                    }}
                                                />
                                            }
                                        />
                                        <div className="ml-3">
                                            {all_sources[0]?.type_lists?.map((list) => (
                                                <FormControlLabel
                                                    label={
                                                        <p className="text-xs text-gray-600 font-normal">
                                                            {list?.name}
                                                        </p>
                                                    }
                                                    key={list?.id}
                                                    control={
                                                        <Checkbox
                                                            checked={sources?.includes(list?.id)}
                                                            onChange={() => handleChange(list?.id)}
                                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                                                        />
                                                    }
                                                />
                                            ))}
                                        </div>
                                    </div>{' '}
                                    <div>
                                        <FormControlLabel
                                            label={
                                                <p className="text-sm text-gray-600 font-medium">
                                                    {all_sources[1]?.name}
                                                </p>
                                            }
                                            control={
                                                <Checkbox
                                                    checked={sourceType?.includes(all_sources[1]?.id)}
                                                    indeterminate={sourceType?.includes(all_sources[1]?.id)}
                                                    onChange={() => handleCheckAll(all_sources[1])}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 20,
                                                        },
                                                    }}
                                                />
                                            }
                                        />
                                        <div className="ml-3">
                                            {all_sources[1]?.type_lists?.map((list) => (
                                                <FormControlLabel
                                                    label={
                                                        <p className="text-xs text-gray-600 font-normal">
                                                            {list?.name}
                                                        </p>
                                                    }
                                                    key={list?.id}
                                                    control={
                                                        <Checkbox
                                                            checked={sources?.includes(list?.id)}
                                                            onChange={() => handleChange(list?.id)}
                                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                                                        />
                                                    }
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className="w-[48%]">
                                    <FormControlLabel
                                        label={
                                            <p className="text-sm text-gray-600 font-medium">{all_sources[2]?.name}</p>
                                        }
                                        control={
                                            <Checkbox
                                                checked={sourceType?.includes(all_sources[2]?.id)}
                                                indeterminate={sourceType?.includes(all_sources[2]?.id)}
                                                onChange={() => handleCheckAll(all_sources[2])}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 20,
                                                    },
                                                }}
                                            />
                                        }
                                    />
                                    <div className="ml-3">
                                        {all_sources[2]?.type_lists?.map((list) => (
                                            <FormControlLabel
                                                label={
                                                    <p className="text-xs text-gray-600 font-normal">{list?.name}</p>
                                                }
                                                key={list?.id}
                                                className="w-full"
                                                control={
                                                    <Checkbox
                                                        checked={sources?.includes(list?.id)}
                                                        onChange={() => handleChange(list?.id)}
                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                                                    />
                                                }
                                            />
                                        ))}
                                    </div>
                                </div>
                                {/* {all_sources?.map((source) => (
                                <div key={source?.id} className="w-[48%]">
                                    <FormControlLabel
                                        label={<p className="text-sm text-gray-600 font-medium">{source?.name}</p>}
                                        control={
                                            <Checkbox
                                                checked={sourceType?.includes(source?.id)}
                                                indeterminate={sourceType?.includes(source?.id)}
                                                onChange={() => handleSourceChange(source?.id)}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 20,
                                                    },
                                                }}
                                            />
                                        }
                                    />
                                    <div className="ml-3">
                                        {source?.type_lists?.map((list) => (
                                            <FormControlLabel
                                                label={
                                                    <p className="text-xs text-gray-400 font-normal italic">
                                                        {list?.name}
                                                    </p>
                                                }
                                                key={list?.id}
                                                control={
                                                    <Checkbox
                                                        checked={sources?.includes(list?.id)}
                                                        onChange={() => handleChange(list?.id)}
                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                                                    />
                                                }
                                            />
                                        ))}
                                    </div>
                                </div>
                            ))} */}
                            </div>
                        )}
                        <div>
                            <FormControlLabel
                                label={
                                    <p className="text-[13px] text-[#395BA9] font-medium ">
                                        Activate continuous monitoring
                                    </p>
                                }
                                control={
                                    <Checkbox
                                        checked={continous_monitoring}
                                        onChange={(event) => setContinous_monitoring(event.target.checked)}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                                    />
                                }
                            />
                        </div>
                    </Box>
                </Box>
            </Box>
        </AppDrawer>
    );
};

export default VendorModal;
