import React, { useEffect } from 'react';
import CiiFirstLayerReport from './CiiFirstLayer';
import SummaryReportCard from '../SummaryReportCard';
import { toast } from 'react-toastify';

import { GetAllSector } from 'store/actions/adminActions';
import { connect } from 'react-redux';

//translations
import { useTranslation } from 'react-i18next';

const BLOCKCHAINIndex = ({ percentage, GetAllSector, all_sector }) => {
    //translation
    const { t } = useTranslation('overview');

    //function
    const getAllSector = async () => {
        const res = await GetAllSector();
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch Criterias.");
            console.log(res.message);
        }
    };

    useEffect(() => {
        getAllSector();
    }, []);
    return (
        <div className="">
            <CiiFirstLayerReport percentage={(percentage || 0)?.toFixed(2)} />
            <div className="py-8">
                <h3 className="textbrandColor font-bold text-[23px]">{t('standardTab.summary')}</h3>
                <div className="pt-4">
                    {all_sector?.map((res, index) => (
                        <React.Fragment key={index}>
                            <SummaryReportCard details={res} cii />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        cii_general_summary: state?.dashboardReducers?.cii_general_summary,
        all_sector: state?.adminReducers?.all_sector,
    };
};

export default connect(mapStateToProps, {
    GetAllSector,
})(BLOCKCHAINIndex);
