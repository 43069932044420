import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Modal, Row, Spin, Typography, Tooltip } from 'antd';
import BreadCrumb from 'components/Breadcrumb';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// redux
import { connect } from 'react-redux';
import { GetAllCriteria } from 'store/actions/adminActions';
import { toast } from 'react-toastify';
import CriteriaModal from '../components/CriteriaModal';
import { DeleteCriteria } from 'store/actions/adminActions';

// sub-components
const { Title } = Typography;
const { confirm } = Modal;

const Criteria = (props) => {
    const { GetAllCriteria, all_criteria, DeleteCriteria } = props;

    // hooks
    const history = useHistory();

    // state
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(null);
    const [modalData, setModalData] = useState({});

    // functions
    const goToSubcriteria = (id) => {
        history.push({ pathname: '/admin/blockchain/subcriteria', state: { criteria: id } });
    };
    const openCriteriaModal = (mode, data = {}) => {
        setModal(mode);
        setModalData(data);
    };
    const closeCriteriaModal = () => setModal(null);

    // async functions
    const getAllCriteria = async () => {
        setLoading(true);
        const res = await GetAllCriteria();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const deleteCriterion = (id) => {
        confirm({
            title: "Are you sure you want to delete this criteria? Note: Deleting this criteria means that you are deleting this criteria, subcriteria and whatsover associated with criteria alongside merchant's data",
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteCriteria(id, false);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    // useEffect
    useEffect(() => {
        getAllCriteria();
    }, []);

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Blockchain' }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center"
                        onClick={() => openCriteriaModal('Add')}
                    >
                        Add Criteria
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {loading ? (
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                ) : (
                    <>
                        {all_criteria && (
                            <Row wrap gutter={24}>
                                {all_criteria
                                    ?.sort((a, b) => a?.id - b?.id)
                                    .map((criterion) => {
                                        return (
                                            <Col xs={24} md={12} lg={8} xxl={6} key={criterion.id} className="mb-4">
                                                <Card
                                                    title={criterion.name}
                                                    actions={[
                                                        <Tooltip title="View this Criteria" color="blue">
                                                            <Button
                                                                type="link"
                                                                onClick={() => goToSubcriteria(criterion?.id)}
                                                                icon={<EyeOutlined key="open" />}
                                                            />
                                                        </Tooltip>,
                                                        <Tooltip title="Edit this Criteria" color="blue">
                                                            <Button
                                                                type="link"
                                                                icon={<EditOutlined />}
                                                                onClick={() => openCriteriaModal('Edit', criterion)}
                                                            />
                                                        </Tooltip>,
                                                        <Tooltip title="Delete this Criteria" color="red">
                                                            <Button
                                                                type="link"
                                                                icon={<DeleteOutlined />}
                                                                danger
                                                                onClick={() => deleteCriterion(criterion?.id)}
                                                            />
                                                        </Tooltip>,
                                                    ]}
                                                >
                                                    <Title level={5}> {criterion.description}</Title>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        )}
                    </>
                )}
            </section>
            <CriteriaModal open={modal} modalData={modalData} onClose={closeCriteriaModal} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    all_criteria: state?.adminReducers?.all_criteria,
});

export default connect(mapStateToProps, { GetAllCriteria, DeleteCriteria })(Criteria);
