import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';

// core components
import IntegrationsHeading from './components/IntegrationsHeading';
import IntegrationGroups from './IntegrationGroups';

// redux
import { connect } from 'react-redux';
import { GetAllIntegrationsGroups, GetAllMerchantIntegrations } from 'store/actions/integrationsActions';
import IntegrationConnect from './IntegrationConnect';
import LoadingState from 'components/new_components/LoadingState';

const Integrations = (props) => {
    const { GetAllIntegrationsGroups, GetAllMerchantIntegrations } = props;
    const [selectedIntegrationId, setSelectedIntegrationId] = useState(null);
    const [loading, setLoading] = useState(false);

    //translation
    const language = localStorage.getItem('i18nextLng');

    const goBack = () => {
        setSelectedIntegrationId(null);
    };
    const getAllIntegrationsGroups = async () => {
        setLoading(true);
        const res = await GetAllIntegrationsGroups();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllMerchantIntegrations = async () => {
        setLoading(true);
        const res = await GetAllMerchantIntegrations();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        getAllIntegrationsGroups();
        getAllMerchantIntegrations();
    }, [language]);

    return (
        <>
            <IntegrationsHeading goBack={goBack} selectedIntegrationId={selectedIntegrationId} />
            <Box sx={{ height: !selectedIntegrationId ? 'calc(100vh - 340px)' : '100%' }}>
                {!loading ? (
                    <>
                        {!selectedIntegrationId ? (
                            <IntegrationGroups setSelected={setSelectedIntegrationId} />
                        ) : (
                            <IntegrationConnect selectedId={selectedIntegrationId} goBack={goBack} />
                        )}
                    </>
                ) : (
                    <Box sx={{ my: 4 }}>
                        <LoadingState />
                    </Box>
                )}
            </Box>
        </>
    );
};

const mapStateToProps = (state) => {
    return state;
};
export default connect(mapStateToProps, {
    GetAllIntegrationsGroups,
    GetAllMerchantIntegrations,
})(Integrations);
