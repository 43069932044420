import React, { useEffect, useState } from 'react';
import EditModal from './VulnerabilitiesOperations/EditModal';
import VulnerabilitiesModal from './VulnerabilitiesOperations/VulnerabilitiesModal';
import { VulnerabilitiesOperations } from './VulnerabilitiesOperations/VulnerabilitiesOperations';
import { Row, Col, Button, Modal, Table, Input } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllVulnerabilities, DeleteVulnerability } from 'store/actions/adminActions';
import { SearchOutlined } from '@ant-design/icons';
import MultipleVulnerabilities from './VulnerabilitiesOperations/MultipleVulnerabilities';
import useSearch from 'hooks/useSearch';

const Vulnerabilities = () => {
    const [loading, setloading] = useState(false);
    const [AddModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [record, setrecord] = useState(null);
    const [AddMultipleModal, setAddMultipleModal] = useState(false);

    // Redux
    const dispatch = useDispatch();
    const all_vulnerabilities = useSelector((state) => state.adminReducers.all_vulnerabilities);
    const all_threats = useSelector((state) => state.adminReducers.all_threats);
    const { data, handleSearch } = useSearch(all_vulnerabilities, ['name']);

    const columns = [
        {
            title: 'Name',
            render: (record) => record?.name,
        },
        {
            title: 'Threat',
            render: (record) => {
                const threat = all_threats?.find((threat) => threat?.id === record?.threat);
                return <p>{threat?.name}</p>;
            },
        },
        {
            title: 'tag',
            render: (record) => record?.tag,
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => (
                <VulnerabilitiesOperations record={record} openUpdateModal={openEditModal} deleteModal={deleteModal} />
            ),
        },
    ];
    const openAddModal = () => {
        setAddModal(true);
    };
    const closeAddModal = () => {
        setAddModal(false);
    };

    //Edit Modal
    const openEditModal = (data) => {
        setrecord(data);
        setEditModal(true);
    };
    const closeEditModal = () => {
        setEditModal(false);
    };

    const openAddMultipleModal = () => {
        setAddMultipleModal(true);
    };
    const closeAddMultipleModal = () => {
        setAddMultipleModal(false);
    };

    const deleteModal = (threat) => {
        Modal.confirm({
            title: 'Do you want to delete this category?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await dispatch(DeleteVulnerability(threat?.id));
                if (res?.success) {
                    toast.success('vulnerability Deleted Successfully');
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    useEffect(() => {
        const getAllVulnerabilities = async () => {
            setloading(true);
            const res = await dispatch(GetAllVulnerabilities());
            setloading(false);
            if (!res.success) {
                toast.error(res?.message);
            }
        };

        getAllVulnerabilities();
    }, []);
    return (
        <div>
            <Row justify="end">
                <Col span={12} className="flex items-center gap-5">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                    <Button
                        onClick={openAddModal}
                        type="primary"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        Add Bulk Vulnerabilities
                    </Button>
                    <Button
                        onClick={openAddMultipleModal}
                        type="primary"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        Add Vulnerabilities
                    </Button>
                </Col>
            </Row>

            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(data) => data?.id}
                    columns={columns}
                    loading={loading}
                    dataSource={data?.sort((a, b) => b?.id - a?.id)}
                    pagination={{
                        total: data?.length,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                    }}
                />
            </section>
            <VulnerabilitiesModal open={AddModal} handleClose={closeAddModal} />
            <MultipleVulnerabilities open={AddMultipleModal} handleClose={closeAddMultipleModal} />
            <EditModal open={editModal} handleClose={closeEditModal} record={record} />
        </div>
    );
};

export default Vulnerabilities;
