import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

// @antd
import { Button, Col, Dropdown, Row } from 'antd';
import {
    PlusOutlined,
    QuestionOutlined,
    ForkOutlined,
    FileTextOutlined,
    CodeOutlined,
    ScanOutlined,
    ToolOutlined,
    SecurityScanOutlined,
} from '@ant-design/icons';

// app component
import BreadCrumb from 'components/Breadcrumb';
import Documents from '../components/Documents';
import AuditQuestions from '../components//AuditQuestions';
import Tools from '../components/Tool';

// redux
import { connect } from 'react-redux';

// data
import ConditionalItems from '../components/ConditionalItems';

const Items = (props) => {
    const history = useHistory();
    // props
    const { match, all_criteria, location } = props;

    // state
    const [documentModal, setDocumentModal] = useState(null);
    const [conditionalModal, setConditionalModal] = useState(null);
    const [toolsModal, setToolsModal] = useState(null);
    const [data, setData] = useState({});

    // memos
    const subcriteria = useMemo(() => {
        let subcriteria;
        all_criteria.forEach(({ subcriterias }) => {
            if (subcriteria) return;
            subcriteria = subcriterias.find((subcriteria) => {
                return subcriteria?.id == parseInt(match?.params?.id);
            });
        });
        return subcriteria;
    }, [match?.params?.id]);

    // functions
    const openDocumentModal = (mode, data = {}) => {
        setDocumentModal(mode);
        setData(data);
    };
    const closeDocumentModal = () => {
        setDocumentModal(null);
        setData({});
    };
    const openConditionalModal = (mode, data = {}) => {
        setConditionalModal(mode);
        setData(data);
    };
    const closeConditionalModal = () => {
        setConditionalModal(null);
        setData({});
    };
    const openToolsModal = (mode, data = {}) => {
        setToolsModal(mode);
        setData(data);
    };
    const closeToolsModal = () => {
        setToolsModal(null);
        setData({});
    };

    // constant
    const items = [
        {
            key: '1',
            icon: <FileTextOutlined />,
            label: 'Document',
            onClick: () => openDocumentModal('add'),
        },
        {
            key: '2',
            icon: <QuestionOutlined />,
            label: 'Question',
            onClick: () =>
                history.push({
                    pathname: `/admin/blockchain/subcriteria/${subcriteria?.id}/form`,
                    state: {
                        mode: 'create',
                        titleLabel: 'criteria_title',
                        groupIdLabel: 'subcriteria',
                        payload: { criteria: subcriteria?.criteria, subcriteria: subcriteria?.id },
                    },
                }),
        },
        {
            key: '3',
            icon: <ForkOutlined />,
            label: 'Conditional Items',
            onClick: () => openConditionalModal('Add'),
        },
        {
            key: '4',
            icon: <CodeOutlined />,
            label: 'Code Review Item',
            onClick: () => openDocumentModal('add', { type: 'codereview' }),
        },
        {
            key: '5',
            icon: <ScanOutlined />,
            label: 'Penetration test Items',
            onClick: () => openDocumentModal('add', { type: 'pentest' }),
        },
        {
            key: '6',
            icon: <SecurityScanOutlined />,
            label: 'PCIScan Items',
            onClick: () => openDocumentModal('add', { type: 'pciscan' }),
        },
        {
            key: '7',
            icon: <ToolOutlined />,
            label: 'Other tools',
            onClick: () => openToolsModal('Add'),
        },
    ];
    return (
        <div>
            <Row justify="space-between" align="middle">
                <Col>
                    <BreadCrumb
                        views={[
                            { label: 'Blockchain', link: '/blockchain' },
                            {
                                label: location?.state?.criteria_title,
                                link: '/blockchain/subcriteria',
                                state: { criteria: subcriteria?.criteria },
                            },
                            { label: subcriteria?.name },
                        ]}
                    />
                </Col>
                <Col>
                    <Dropdown
                        menu={{
                            items,
                        }}
                        placement="bottomRight"
                        trigger={['click']}
                    >
                        <Button type="primary" size="large" icon={<PlusOutlined />}>
                            Add Item
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
            <section className="my-3">
                <Tools
                    subcriteria={subcriteria}
                    openModal={openToolsModal}
                    closeModal={closeToolsModal}
                    modal={toolsModal}
                    data={data}
                />
                <Documents
                    subcriteria={subcriteria}
                    openModal={openDocumentModal}
                    closeModal={closeDocumentModal}
                    modal={documentModal}
                    data={data}
                />
                <AuditQuestions subcriteria={subcriteria} />
                <ConditionalItems
                    subcriteria={subcriteria}
                    openModal={openConditionalModal}
                    closeModal={closeConditionalModal}
                    modal={conditionalModal}
                    data={data}
                />
            </section>
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_criteria: state?.adminReducers?.all_criteria,
});
export default connect(mapStateToProps, {})(Items);
