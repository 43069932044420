import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';

//translations
import { useTranslation } from 'react-i18next';

const RespondedVendorGraphReport = ({ dashboardResult }) => {
    const { vendors_with_response_count, vendors, total_vendors_count } = dashboardResult || {};

    //translation
    const { t } = useTranslation('overview');

    //constant
    const data = {
        labels: [
            `${vendors_with_response_count} ${t('vendorReportSection.respondedVendors')}`,
            `${vendors || total_vendors_count} ${t('vendorReportSection.totalVendors')}`,
        ],
        datasets: [
            {
                label: '',
                data: [vendors_with_response_count, vendors],
                backgroundColor: ['#05DEEC', '#0A1A4A'],
                borderWidth: 0,
                borderRadius: '10px',
                boxShadow: '0px 4px 4px rgba(10, 26, 74, 0.42)',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: '',
            },
            datalabels: {
                display: false,
            },
        },
    };
    ChartJS.register(ArcElement, Tooltip, Legend);
    return (
        <div>
            <Doughnut options={options} data={data} type="doughnut" className="!h-[250px] !w-[250px] m-auto" />
        </div>
    );
};

export default RespondedVendorGraphReport;
