import React, { useState, useMemo, Fragment } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

// core components
import ItemSubheader from '../../common/ItemSubheader';
import FieldItem from './FieldItem';
import AnswerHistoryDrawer from './AnswerHistoryDrawer';
import ExemptTag from '../../common/ExemptTag';

// redux
import { connect } from 'react-redux';
import { RevertCiiCompliance } from 'store/actions/complianceActions';

//translation
import { useTranslation } from 'react-i18next';

const QuestionItem = (props) => {
    const { form, merchant_answers, onResourceChange, exception, RevertCiiCompliance, exceptionId, no_excemption } =
        props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('compliance');

    // states
    const [loading, setLoading] = useState({ exempt: false, answer: false });
    const [drawerOpened, setDrawerOpened] = useState(false);

    // memos
    // get the current question in view
    const currentQuestion = useMemo(() => {
        return merchant_answers?.find((answer) => answer?.form_id === form?.id);
    }, [form, merchant_answers]);

    // controlling the drawer for form versions
    const openDrawer = () => setDrawerOpened(true);
    const closeDrawer = () => setDrawerOpened(false);

    // exempt a Question
    const removeExempt = async () => {
        setLoading({ ...loading, exempt: true });
        const res = await RevertCiiCompliance(exceptionId);
        setLoading({ ...loading, exempt: false });
        onResourceChange();
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    const payload = {
        form: form?.id,
        cii_subsector: form?.cii_subsector,
        cii_sector: form?.cii_sector,
        reason: '',
    };
    return (
        <Box sx={{ p: 2, py: 4 }}>
            <ItemSubheader
                hasResource={Boolean(currentQuestion)}
                exceptionReason={form}
                resource="form"
                exempting={loading.exempt}
                menuItems={[{ label: t('viewAnswer'), action: openDrawer }]}
                payload={payload}
                standard="cii"
                type="question"
                onResourceChange={onResourceChange}
                exception={exception}
                no_excemption={no_excemption}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ ml: 5.25, color: theme.palette.black.main, fontWeight: 700, mb: -2, mt: 2 }}>
                    {form?.cii_sector_title}
                </Typography>
            </Box>
            <Fragment>
                {exception ? (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '3rem' }}>
                        <ExemptTag
                            onClick={removeExempt}
                            loading={loading?.exempt}
                            loadingLabel={t('reverting')}
                            label={t('revert')}
                        />
                    </Box>
                ) : null}
                {(form?.form_fields || form?.form_detail?.form_fields)?.map((field, index) => {
                    return <FieldItem field={field} number={index + 1} key={field.id} exception={exception} />;
                })}
            </Fragment>
            <AnswerHistoryDrawer
                open={drawerOpened}
                handleClose={closeDrawer}
                form={currentQuestion}
                exception={exception}
            />
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        merchant_answers: state?.complianceReducers?.merchant_answers,
    };
};

export default connect(mapStateToProps, {
    RevertCiiCompliance,
})(QuestionItem);
