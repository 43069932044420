import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MinusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table, Tag } from 'antd';
import { SearchOutlined } from '@mui/icons-material';

import { AUDIT_STATUS } from 'views/merchant/audit/utils';
import useSearch from 'hooks/useSearch';

// redux
import { connect } from 'react-redux';
import { GetAllMerchants } from 'store/actions/adminActions';
import { GetAllAuditRequests, DeleteAuditRequest, GetAllAccessorAnswers } from 'store/actions/auditActions';
import { useHistory } from 'react-router-dom';

const Requests = (props) => {
    // props
    const {
        GetAllMerchants,
        GetAllAuditRequests,
        all_audit_requests,
        all_merchants,
        user_info,
        GetAllAccessorAnswers,
        all_accessor_answers,
    } = props;
    const history = useHistory();

    // state
    const [loading, setLoading] = useState(false);

    const { data, handleSearch } = useSearch(all_audit_requests, [
        'name',
        'compliance',
        'owner_detail.first_name',
        'owner_detail.last_name',
    ]);

    const getStatusProps = (status) => {
        switch (status) {
            case AUDIT_STATUS.IN_REVIEW:
                return { color: 'warning', text: 'In Review' };
            case AUDIT_STATUS.AUDITING:
                return { color: 'processing', text: 'Auditing' };
            case AUDIT_STATUS.DISAPPROVED:
                return { color: 'error', text: 'Disapproved' };
            case AUDIT_STATUS.COMPLETE:
                return { color: 'success', text: 'Completed' };
            case AUDIT_STATUS.DRAFT:
                return { color: '#9ea9bd30', text: 'Draft' };
            default:
                return {};
        }
    };
    const getAllMerchants = async () => {
        setLoading(true);
        const res = await GetAllMerchants();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllAuditRequests = async () => {
        setLoading(true);
        const res = await GetAllAuditRequests();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllAccessorAnswers = async () => {
        setLoading(true);
        const res = await GetAllAccessorAnswers();
        setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong, couldn't fetch questions.");
        }
    };

    // useEffect
    useEffect(() => {
        getAllMerchants();
        getAllAuditRequests();
        getAllAccessorAnswers();
    }, []);

    // constant
    const columns = [
        {
            title: 'Company Name',
            render: (record) => {
                const merchant = all_merchants?.find((merchant) => merchant?.id === record?.merchant);
                return <>{merchant?.name}</>;
            },
        },
        {
            title: 'Merchant',
            render: (record) => {
                const merchant = all_merchants?.find((merchant) => merchant?.id === record?.merchant);
                return <>{`${merchant?.owner_detail?.first_name} ${merchant?.owner_detail?.last_name}`}</>;
            },
        },
        {
            title: 'Compliance Standard',
            render: (record) => {
                return <span className="uppercase">{record?.compliance}</span>;
            },
        },
        {
            title: 'Status',
            render: (record) => {
                const status = getStatusProps(record?.status);
                return <Tag color={status.color}>{status.text}</Tag>;
            },
        },
        {
            title: 'Effective From',
            render: (record) => {
                if (!record?.effective_from) {
                    return <MinusOutlined />;
                } else {
                    const [start, end] = record.effective_from.split(',');
                    const startDate = new Date(start),
                        endDate = new Date(end);
                    return (
                        <span>
                            {startDate?.toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                            })}{' '}
                            -{' '}
                            {endDate?.toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                            })}
                        </span>
                    );
                }
            },
        },
        {
            title: 'Actions',
            align: 'right',
            render: (record) => {
                const find_answer = all_accessor_answers?.find((answer) => answer?.merchant === record?.merchant);

                return (
                    <>
                        {find_answer && !record?.assessor_answer_completed ? (
                            <Button onClick={() => history.push(`/admin/accessor/${record?.merchant}`)}>
                                Resume Accessor Questions
                            </Button>
                        ) : find_answer && record?.assessor_answer_completed ? (
                            <Button
                                disabled={
                                    record?.status === 'inreview' ||
                                    record?.status === 'draft' ||
                                    !record?.merchant_signature
                                }
                                onClick={() => history.push(`/admin/accessor/preview/${record?.id}`)}
                            >
                                Preview ROC
                            </Button>
                        ) : (
                            <Button onClick={() => history.push(`/admin/accessor/review/${record?.merchant}`)}>
                                Review Response
                            </Button>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <div>
            <Row justify="space-between" align="center">
                <Col span={12} className="flex items-center">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                </Col>
            </Row>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius overflow-x-auto"
                    rowKey={(request) => request?.id}
                    loading={loading}
                    columns={columns}
                    dataSource={data
                        ?.filter((res) => res?.auditor === user_info?.organization?.id)
                        ?.sort((a, b) => b?.id - a?.id)}
                />
            </section>
        </div>
    );
};

const mapStateToProps = (state) => ({
    all_merchants: state?.adminReducers?.all_merchants,
    all_audit_requests: state?.auditReducers?.all_audit_requests,
    user_info: state?.generalReducers?.user_info,
    all_accessor_answers: state?.auditReducers?.all_accessor_answers,
});
export default connect(mapStateToProps, {
    GetAllMerchants,
    GetAllAuditRequests,
    DeleteAuditRequest,
    GetAllAccessorAnswers,
})(Requests);
