import React, { useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Box, Typography, Stack } from '@mui/material';
import { Scanner, InfoOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
//core component
// import AppModal from 'components/new_components/AppModal';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppFormInput, AppFormSelect, AppSubmitButton, AppForm } from 'components/new_components/forms';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import { scheduleOptions } from './utils/constant';
//validate
import { requestModalValidation } from './utils/validation';
// import { pattern } from 'validate';

//redux
import { RequestASVscan, UpdateASVScanRequest } from 'store/actions/merchantActions';
import { connect } from 'react-redux';
import FormTooltip from 'components/new_components/FormTooltip';

//translations
import { useTranslation } from 'react-i18next';

const RequestModal = ({ open, handleClose, payload, type, RequestASVscan, UpdateASVScanRequest, id }) => {
    const [authorization, setAuthorization] = useState(false);
    const [loadingState, setLoadingState] = useState(false);

    const theme = useTheme();

    //translation
    const { t } = useTranslation('pciscans');

    const sortedScheduleOptions = useMemo(() => {
        let filteredOptions;
        if (type === 'update') {
            filteredOptions = scheduleOptions?.filter((option) => option.name !== 'Run Now');
        } else {
            filteredOptions = scheduleOptions;
        }
        return filteredOptions;
    }, [scheduleOptions, type]);

    //functions
    const handleAuthorizationCheck = () => {
        setAuthorization(!authorization);
    };

    const handleSubmit = async (values) => {
        if (authorization) {
            setLoadingState(true);
            const res =
                type === 'request'
                    ? await RequestASVscan({
                          ...values,
                          authorization: authorization,
                      })
                    : await UpdateASVScanRequest(id, { schedule: values?.schedule });
            setLoadingState(false);
            if (res?.success) {
                toast.success(type === 'request' ? 'Successful request' : 'Schedule Updated Successfully');
                handleClose();
            } else {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    toast.error(res?.message);
                }
            }
        } else {
            toast.info('You have to authorize this request!');
        }
    };
    return (
        <AppForm initialValues={payload} onSubmit={handleSubmit} validate={requestModalValidation}>
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                title={
                    <Typography sx={{ fontSize: '24px', fontWeight: 500, color: theme.palette.gray[800] }}>
                        {type === 'request' ? t('pciRequestModal.title1') : t('pciRequestModal.title2')}
                    </Typography>
                }
                subtitle={
                    <Stack
                        flexDirection="row"
                        justifyContent="flex-start"
                        sx={{
                            py: 1,
                            pr: 2,
                            fontSize: 12,
                            fontWeight: 400,
                            color: theme.palette.gray[40],
                        }}
                    >
                        <InfoOutlined sx={{ fontSize: 11.8, mr: 1, mt: 0.4 }} />{' '}
                        {type === 'request' ? t('pciRequestModal.subtitle1') : t('pciRequestModal.subtitle2')}
                    </Stack>
                }
                width={600}
                height="65%"
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <AppSubmitButton
                            text={type === 'request' ? t('pciRequestModal.button1') : t('pciRequestModal.button2')}
                            startIcon={<Scanner />}
                            variant="contained"
                            color="primary"
                            loading={loadingState}
                            loadingPosition="center"
                            sx={{ width: '48%' }}
                        />
                    </Box>
                }
            >
                <AppFormInput
                    type="text"
                    label={t('pciRequestModal.scanName')}
                    name="name"
                    placeholder={t('pciRequestModal.scanName')}
                    disabled={type !== 'request'}
                />
                <AppFormInput
                    type="text"
                    label={t('pciRequestModal.ipAddress')}
                    name="ip_address"
                    placeholder={t('pciRequestModal.ipOrWeb')}
                    // regex={pattern.ipAndEmail}
                    disabled={type !== 'request'}
                />
                <AppFormSelect
                    name="schedule"
                    label={
                        <>
                            <span className="mr-1">{t('pciRequestModal.schedule')}</span>
                            <FormTooltip placement="top" text={t('pciRequestModal.scheduleTip')} />
                        </>
                    }
                    options={sortedScheduleOptions}
                    defaultValue={t('pciRequestModal.scheduleValue')}
                />
                <AppCheckboxInput
                    name="authorization"
                    label={t('pciRequestModal.authorization')}
                    onChange={handleAuthorizationCheck}
                    value={authorization}
                />
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { RequestASVscan, UpdateASVScanRequest })(RequestModal);
