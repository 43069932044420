import React, { useState } from 'react';
import ActivitiesLog from './ActivitiesLog';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import TabLayout from 'components/new_components/tabs/TabLayout';
import TaskLog from './taskLog';

const tabs = [
    { name: 'Activity Log', id: 0 },
    { name: 'Task Log', id: 1 },
];
const Log = () => {
    // state
    const [currentTab, setCurrentTab] = useState(0);

    // logic function
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };
    return (
        <TabLayout onTabChange={handleTabChange} tab={currentTab} tabs={tabs}>
            <AppTabPanel value={currentTab} index={0}>
                <ActivitiesLog />
            </AppTabPanel>
            <AppTabPanel value={currentTab} index={1}>
                <TaskLog />
            </AppTabPanel>
        </TabLayout>
    );
};

export default Log;
