import React, { useEffect, useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { AppFormSelect, AppFormInput } from 'components/new_components/forms';
import AppFormTagInput from 'components/new_components/forms/AppFormTagInput';
import { connect } from 'react-redux';
import './style.css';

import {
    AssetTypesOption,
    LikelihoodOptionBy5,
    ImpactOptionBy5,
    LikelihoodOptionBy3,
    ImpactOptionBy3,
    vulnerablilityOptions,
} from '../../../utils/constants';

import { GetAllThreats } from 'store/actions/adminActions';

//translations
import { useTranslation } from 'react-i18next';

const RiskIdentification = (props) => {
    const {
        values,
        all_merchant_infrastructures,
        all_computers,
        matrix,
        setMatrix,
        owner,
        setOwner,
        GetAllThreats,
        all_threats,
    } = props;

    //translation
    const { t } = useTranslation('riskAssessment');

    useEffect(() => {
        if (values?.asset !== null) {
            if (values?.asset?.toString()?.length === 4) {
                const assetArray = all_merchant_infrastructures?.find(
                    (infra) => infra?.asset_number === parseInt(values?.asset)
                );
                setOwner(assetArray?.location);
            } else {
                const assetValue = all_computers?.find((computer) => computer?.serialnumber === values?.asset);
                setOwner(assetValue?.name);
            }
        }
    }, [values?.asset]);

    const getAllThreats = async () => {
        const res = await GetAllThreats();
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    //options
    const computerOptions = useMemo(
        () =>
            all_computers
                ?.sort((a, b) => a?.id - b?.id)
                ?.map((computer) => {
                    return {
                        name: `${computer?.serialnumber} ${computer?.name}`,
                        value: computer?.serialnumber,
                    };
                }),
        [all_computers]
    );

    const infrastructureOptions = useMemo(
        () =>
            all_merchant_infrastructures
                ?.sort((a, b) => a?.id - b?.id)
                ?.map((infra) => {
                    return {
                        name: `${infra?.asset_number} - ${infra?.location}`,
                        value: infra?.asset_number,
                    };
                }),
        [all_merchant_infrastructures]
    );

    useEffect(() => {
        getAllThreats();
    }, []);

    const threatOptions = useMemo(
        () =>
            all_threats
                ?.sort((a, b) => a?.id - b?.id)
                ?.map((threat) => {
                    return {
                        name: `${threat?.name} - ${threat?.sub_category}`,
                        value: threat?.id,
                    };
                }),
        [all_threats]
    );

    const LikelihoodOption = matrix === '3x3' ? LikelihoodOptionBy3 : LikelihoodOptionBy5;
    const ImpactOption = matrix === '3x3' ? ImpactOptionBy3 : ImpactOptionBy5;
    const filteredAssetTypesOption_ = AssetTypesOption?.filter((option) => option?.value !== 'All');

    return (
        <Box sx={{ overflow: 'scroll !Important', height: '600px' }}>
            <Box sx={{ marginBottom: '1rem' }}>
                <Box sx={{ display: 'flex', marginTop: '1rem' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            border: matrix === '3x3' ? '2px solid #002C72' : ' 1px solid #AAABB4',
                            color: matrix === '3x3' ? '#002C72' : '#AAABB4',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            width: '67px',
                            height: '60px',
                        }}
                        onClick={() => setMatrix('3x3')}
                    >
                        <Typography sx={{ fontWeight: 700 }}>3 x 3</Typography>
                        <Typography sx={{ fontSize: '12px' }}>{t('cyberSecurityAssessment.matrix')}</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            border: matrix === '5x5' ? '2px solid #002C72' : ' 1px solid #AAABB4',
                            color: matrix === '5x5' ? '#002C72' : '#AAABB4',
                            borderRadius: '4px',
                            width: '67px',
                            height: '60px',
                            marginLeft: 5,
                            cursor: 'pointer',
                        }}
                        onClick={() => setMatrix('5x5')}
                    >
                        <Typography sx={{ fontWeight: 700 }}>5 x 5</Typography>
                        <Typography sx={{ fontSize: '12px' }}>{t('cyberSecurityAssessment.matrix')}</Typography>
                    </Box>
                </Box>
                {matrix === '3x3' ? (
                    <div className="by3">
                        <div className="first-line"></div>
                        <div className="second-line"></div>
                        <div className="third-line"></div>
                        <div className="forth-line"></div>
                    </div>
                ) : (
                    <div className="by5">
                        <div className="first-line"></div>
                        <div className="second-line"></div>
                        <div className="third-line"></div>
                        <div className="forth-line"></div>
                    </div>
                )}
                {matrix === '3x3' && (
                    <Typography component="span" sx={{ fontSize: '12px', color: '#8F9099' }}>
                        {t('cyberSecurityAssessment.cbn')}
                    </Typography>
                )}
            </Box>
            <Grid container spacing={2}>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <AppFormSelect
                        name="asset_type"
                        label={t('cyberSecurityAssessment.assetType')}
                        options={filteredAssetTypesOption_}
                        defaultValue={t('cyberSecurityAssessment.assetTypeValue')}
                    />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    {values?.asset_type === 'employee_computer' ? (
                        <AppFormSelect
                            name="asset"
                            label={t('cyberSecurityAssessment.asset')}
                            options={computerOptions}
                            defaultValue={t('cyberSecurityAssessment.assetValue')}
                        />
                    ) : values?.asset_type === 'infrastructure' ? (
                        <AppFormSelect
                            name="asset"
                            label={t('cyberSecurityAssessment.asset')}
                            options={infrastructureOptions}
                            defaultValue={t('cyberSecurityAssessment.assetValue')}
                        />
                    ) : (
                        <AppFormInput
                            type="text"
                            label={t('cyberSecurityAssessment.asset')}
                            name="asset"
                            placeholder={t('cyberSecurityAssessment.enterAsset')}
                        />
                    )}
                </Grid>
            </Grid>
            <AppFormSelect name="threat" label="Threat" options={threatOptions} defaultValue="Select Threat" />
            <AppFormTagInput
                type="text"
                label={t('cyberSecurityAssessment.description')}
                name="threat_description"
                placeholder={t('cyberSecurityAssessment.describe')}
            />
            <AppFormSelect
                label={t('cyberSecurityAssessment.vulnerabilities')}
                multiple
                name="vulnerabilities"
                options={vulnerablilityOptions}
                placeholder={t('cyberSecurityAssessment.vulnerabilitiesValue')}
            />
            {values?.asset_type === 'employee_computer' || values?.asset_type === 'infrastructure' ? (
                <AppFormInput
                    type="text"
                    label={t('cyberSecurityAssessment.riskOwner')}
                    placeholder={t('cyberSecurityAssessment.riskOwner')}
                    disabled
                    value={owner}
                />
            ) : (
                <AppFormInput
                    type="text"
                    label={t('cyberSecurityAssessment.riskOwner')}
                    name="risk_owner"
                    placeholder={t('cyberSecurityAssessment.riskOwner')}
                />
            )}

            <Grid container spacing={2}>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <AppFormSelect
                        name="inherent_likelihood"
                        label={t('cyberSecurityAssessment.inherentLikelihood')}
                        options={LikelihoodOption}
                        defaultValue={t('cyberSecurityAssessment.inherentLikelihoodValue')}
                    />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <AppFormSelect
                        name="inherent_impact"
                        label={t('cyberSecurityAssessment.inherentImpact')}
                        options={ImpactOption}
                        defaultValue={t('cyberSecurityAssessment.inherentImpactValue')}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_infrastructures: state?.merchantReducers?.all_merchant_infrastructures,
        all_computers: state?.merchantReducers?.all_computers,
        all_threats: state?.adminReducers?.all_threats,
    };
};
export default connect(mapStateToProps, { GetAllThreats })(RiskIdentification);
