import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, List, Typography } from '@mui/material';

// app components
import AppButton from 'components/new_components/AppButton';
import PlanFeatureItem from './PlanFeatureItem';

//translation
import { useTranslation } from 'react-i18next';

const PlanCard = (props) => {
    const {
        name,
        description,
        subDesc,
        features,
        id,
        onPlanSelect,
        // plan,
        employeeRange,
        active,
        isPendingPlan,
    } = props;
    const theme = useTheme();

    //translation hook
    const { t } = useTranslation('accounts');
    return (
        <Box
            sx={{
                border: `0.6px solid ${theme.palette.primary[900]}50`,
                width: 450,
                borderRadius: 5,
                boxShadow:
                    '-189px 200px 110px rgba(209, 209, 209, 0.01), -106px 112px 93px rgba(209, 209, 209, 0.05), -47px 50px 69px rgba(209, 209, 209, 0.09), -12px 12px 38px rgba(209, 209, 209, 0.1), 0px 0px 0px rgba(209, 209, 209, 0.1)',
                m: { md: 3 },
            }}
        >
            <Box component="header" sx={{ p: 2, px: { md: 4 }, pb: 3, borderBottom: `3px solid #eef0ff` }}>
                <Typography
                    component="h4"
                    variant="h3"
                    sx={{ fontWeight: 600, textAlign: 'center', fontSize: 20, m: 0 }}
                    color="primary"
                >
                    {name}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 500,
                        textAlign: 'center',
                        fontSize: 14,
                        color: theme.palette.gray[40],
                        mb: 1,
                        height: 21,
                    }}
                >
                    {employeeRange && (
                        <>
                            {employeeRange} {t('plans.planCard.employees')}
                        </>
                    )}
                </Typography>
                {active || isPendingPlan ? (
                    <AppButton
                        name={active ? t('plans.planCard.cta2.currentPlan') : t('plans.planCard.cta2.requestPending')}
                        onClick={console.log}
                        fullWidth
                        variant="outlined"
                        color="primary"
                        sx={{
                            borderRadius: 2,
                            p: 1.5,
                            '&.Mui-disabled': {
                                backgroundColor: 'transparent',
                                color: theme.palette.gray[40],
                                border: `1px solid ${theme.palette.gray[40]}`,
                            },
                        }}
                        disabled
                        title={isPendingPlan ? t('plans.planCard.pending') : null}
                    />
                ) : (
                    <AppButton
                        name={t('plans.planCard.cta1')}
                        onClick={() => onPlanSelect(id)}
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ borderRadius: 2, p: 1.5 }}
                    />
                )}
            </Box>
            <Box sx={{ p: 2, px: { md: 4 }, mb: 3 }}>
                {description && (
                    <Typography
                        component="h3"
                        variant="h3"
                        sx={{
                            fontWeight: 600,
                            fontSize: 20,
                            my: 2,
                            mt: 4,
                            color: theme.palette.gray[900],
                            textAlign: id === 'custom' ? 'center' : 'left',
                        }}
                    >
                        {description}
                    </Typography>
                )}
                {id === 'custom' && (
                    <Typography
                        component="p"
                        variant="body1"
                        sx={{
                            fontWeight: 600,
                            fontSize: 14,
                            color: theme.palette.gray[900],
                        }}
                    >
                        {subDesc}
                    </Typography>
                )}
                {features && (
                    <List dense>
                        {features.map((feature) => {
                            return <PlanFeatureItem text={feature} key={feature} />;
                        })}
                    </List>
                )}
            </Box>
        </Box>
    );
};

export default PlanCard;
