import { useEffect, useState } from 'react';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import RiskMatrix from './RiskMatrix';
import RiskCategory from './RiskCategory';
import RiskPosture from './RiskPosture';
import RiskAssets from './RiskAssets';
import RiskTrends from './RiskTrends';
import TopThreats from './TopThreats';
import IncidentSummary from './IncidentSummary';
import RiskFinancialLoss from './RiskFinancialLoss';
import { useDispatch, useSelector } from 'react-redux';
import https from 'utils/https';
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as PocketSquareIcon } from 'assets/img/pocket-square.svg';
import { GetOverview } from 'store/actions/riskAssessmentActions';

// image source border-image-source: linear-gradient(180deg, #FFD572 0%, #FEBD38 100%);

export default function RiskAssessmentOverview() {
    const theme = useTheme();
    const dispatch = useDispatch();

    const [matrix, setMatrix] = useState(3);
    const [open, setOpen] = useState(true);
    const get_risk_settings = useSelector((state) => state?.riskAssessmentReducers?.get_risk_settings);

    function getButtonStyle(id) {
        const isActive = matrix === id;

        return `text-[#334155] text-lowercase rounded-2xl text-sm px-3 py-2 font-semibold ${
            isActive ? 'bg-white' : 'bg-[#f1f5f9] cursor-pointer'
        }`;
    }

    useEffect(() => {
        if (get_risk_settings?.length > 0) {
            dispatch(GetOverview(matrix === 3 ? '3x3' : '5x5'));
        }
    }, [get_risk_settings, matrix]);

    return (
        <div className="flex flex-col gap-6 p-6">
            {open && (
                <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        background: '#F1F5F9',
                        border: '1px solid #FFD572',
                        py: 1.8,
                        position: 'relative',
                        pl: 5,
                        borderRadius: '10px',
                    }}
                >
                    <Stack display="flex" flexDirection="row" alignItems="center" justifyContent="start" gap={1.5}>
                        <Typography sx={{ fontSize: 14, fontWeight: 'medium', color: theme.palette.primary[40] }}>
                            To get started, customize risk settings to initiate assessment.
                        </Typography>
                        <Button
                            href="/risk-assessment/settings"
                            size="small"
                            sx={{
                                fontSize: 12,
                                textTransform: 'none',
                                color: theme.palette.primary[40],
                                fontWeight: 600,
                                border: `1px solid ${theme.palette.primary[40]}`,
                            }}
                        >
                            Go to settings
                        </Button>
                    </Stack>
                    <span
                        className="border-[1px] border-solid border-[#E2E8F0] rounded-full mr-6 cursor-pointer"
                        onClick={() => setOpen(!open)}
                    >
                        <CloseOutlined className="bg-white text-[#334155] text-xs rounded-full p-1" />
                    </span>
                    <PocketSquareIcon className="absolute bottom-[-2px] left-0" />
                </Stack>
            )}

            <Stack
                sx={{
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    gap: 2,
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        color: theme.palette.primary[40],
                        fontWeight: 600,
                    }}
                >
                    Risk overview
                </Typography>
                <div
                    variant="contained"
                    aria-label="matrix"
                    className="rounded-2xl shadow-[0_12px_24px_0_rgba(71,80,118,0.04)] border-[#f1f5f9] bg-[#f1f5f9] flex gap-0"
                >
                    <div className={getButtonStyle(3)} onClick={() => setMatrix(3)}>
                        3x3 Matrix
                    </div>
                    <div className={getButtonStyle(5)} onClick={() => setMatrix(5)}>
                        5x5 matrix
                    </div>
                </div>
            </Stack>
            <div className="grid grid-flow-col grid-cols-12 gap-4">
                <RiskPosture />
                <RiskAssets />
            </div>

            <div className="grid grid-flow-col grid-cols-12 gap-4">
                <RiskTrends />
                <TopThreats />
            </div>

            <div className="grid grid-flow-col grid-cols-12 gap-4">
                <RiskMatrix matrix={matrix} />
                <RiskCategory />
            </div>

            <div className="grid grid-flow-col grid-cols-12 gap-4">
                <IncidentSummary />
                <RiskFinancialLoss />
            </div>
        </div>
    );
}

export const GetRiskAssessment = async () => {
    try {
        const res = await https().get(`/scans/risk_assessment/`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);

        return {
            success: true,
            message: res?.message,
            data: res.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
