import React from 'react';

import { Card } from '@mui/material';

const AppCard = (props) => {
    const { sx } = props;
    return (
        <Card
            sx={{
                ...sx,
                boxShadow: '2px 2px 8px rgba(182, 182, 182, 0.15), -2px -2px 8px rgba(182, 182, 182, 0.15)',
                borderRadius: '16px',
            }}
        >
            {props.children}
        </Card>
    );
};

export default AppCard;
