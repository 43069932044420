import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

//translations
import AppCenteredModal from 'components/new_components/AppCenteredModalNew';
import {
    AppForm,
    AppFormInput,
    AppFormSelect,
    // AppFormContext,
    AppSubmitButton,
} from 'components/new_components/forms';
import { AMLVendorsValidation, vendorsValidation } from '../utils/validation';

//translations
import { useTranslation } from 'react-i18next';
import { riskLevelOptions } from '../utils/constants';

const VendorModal = (props) => {
    // Props
    const { open, handleClose, payload, setVendorPayload } = props;

    // Hook States
    const [type, setType] = useState('AML');
    const [send_questionaire, setSend_questionaire] = useState(false);
    // const [values, setValues] = useState(payload);

    //translation
    const { t } = useTranslation('vendorManagement');

    // Theme
    const theme = useTheme();

    const handleSubmit = (data) => {
        setVendorPayload(data);
        handleClose();
    };

    return (
        <AppForm
            initialValues={payload}
            onSubmit={handleSubmit}
            validate={type === 'AML' ? AMLVendorsValidation : vendorsValidation}
        >
            {/* <AppFormContext getValues={getCurrentValues}> */}
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                title={
                    <Typography sx={{ color: '#202D66', fontSize: '16px', fontWeight: 600 }}>
                        Vendor Management settings
                    </Typography>
                }
                width="500px"
                actions={
                    <div className="flex justify-end items-center gap-2">
                        <Button
                            sx={{
                                backgroundColor: '#FFFFFF',
                                border: `1px solid ${theme.palette.gray[200]}`,
                                textTransform: 'inherit',
                                color: theme.palette.gray[700],
                                fontSize: '14px',
                                fontWeight: 500,
                            }}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <AppSubmitButton
                            text="Save"
                            variant="contained"
                            color="primary"
                            sx={{ borderRadius: 1, textTransform: 'inherit' }}
                        />
                    </div>
                }
            >
                <Box
                    sx={{
                        my: 2,
                        overflow: 'scroll !important',
                    }}
                >
                    <Typography sx={{ color: '#64748B', fontSize: '13px', fontWeight: '500' }}>Vendor type</Typography>
                    <Box
                        sx={{
                            border: '2px solid #395BA9',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 2,
                        }}
                    >
                        <Typography
                            sx={{
                                backgroundColor: type === 'AML' ? '#395BA9' : 'transpparent',
                                width: '50%',
                                color: type === 'AML' ? '#FFFFFF !important' : '#395BA9 !important',
                                textAlign: 'center',
                                py: 1.2,
                                fontWeight: 500,
                                cursor: 'pointer',
                            }}
                            onClick={() => setType('AML')}
                        >
                            AML vendor
                        </Typography>
                        <Typography
                            sx={{
                                backgroundColor: type === 'NON-AML' ? '#395BA9' : 'transpparent',
                                width: '50%',
                                color: type === 'NON-AML' ? '#FFFFFF !important' : '#395BA9 !important',
                                textAlign: 'center',
                                py: 1.2,
                                fontWeight: 500,
                                cursor: 'pointer',
                            }}
                            onClick={() => setType('NON-AML')}
                        >
                            NON-AML vendor
                        </Typography>
                    </Box>
                    {type === 'AML' && (
                        <Box>
                            <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#64748B', my: 1 }}>
                                For vendors subjected to anti-money laundering regulations{' '}
                            </Typography>
                            <Box>
                                <AppFormInput type="text" name="email" placeholder={'Vendor Email'} />
                                <AppFormInput type="text" name="data" placeholder={t('vendorModal.dataShared')} />
                                <AppFormInput type="text" name="products" placeholder={'products'} />
                                <AppFormSelect
                                    name="risk_level"
                                    options={riskLevelOptions}
                                    defaultValue="Risk level"
                                    sx={{ height: '40px' }}
                                />
                                <AppFormInput
                                    type="text"
                                    name="description"
                                    placeholder={'Description of service'}
                                    multiline={true}
                                    rows={3}
                                />
                            </Box>
                        </Box>
                    )}{' '}
                    {type === 'NON-AML' && (
                        <Box>
                            <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#64748B', my: 1 }}>
                                For vendors not subject to anti-money laundering regulations
                            </Typography>
                            <Box>
                                <AppFormInput type="text" name="email" placeholder={'Vendor Email'} />
                                <AppFormInput type="text" name="data" placeholder={t('vendorModal.dataShared')} />
                                <AppFormInput type="text" name="products" placeholder={t('vendorModal.product')} />
                                <AppFormSelect
                                    name="risk_level"
                                    options={riskLevelOptions}
                                    defaultValue={t('vendorModal.riskLevelValue')}
                                />

                                <AppFormInput
                                    type="text"
                                    name="description"
                                    placeholder={'Description of service'}
                                    multiline={true}
                                    rows={3}
                                />
                            </Box>
                        </Box>
                    )}
                    <div className="mt-3">
                        <FormControlLabel
                            label={
                                <p className="text-[13px] text-[#395BA9] font-medium ">
                                    Send vendor questionnaire to vendor
                                </p>
                            }
                            control={
                                <Checkbox
                                    checked={send_questionaire}
                                    onChange={(event) => setSend_questionaire(event.target.checked)}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                                />
                            }
                        />
                    </div>
                    <div className="bg-[#F8FAFC] border border-[#F0F0FA] px-4 py-2 rounded mt-1.5">
                        <p className="text-xs textgray-500 font-normal">
                            If you don’t wish to add this vendor to the vendor management, you can simply click the
                            cancel button
                        </p>
                    </div>
                </Box>
            </AppCenteredModal>
            {/* </AppFormContext> */}
        </AppForm>
    );
};

export default VendorModal;
