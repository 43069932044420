/* eslint-disable no-unused-vars */
import { Button, Col, Modal, Row, Select, DatePicker, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { CreateAuditRequest, UpdateAuditRequest, UploadReportFile } from 'store/actions/auditActions';
import { AUDIT_STATUS } from 'views/merchant/audit/utils';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import moment from 'moment';

const { RangePicker } = DatePicker;

const UploadRocModal = (props) => {
    const {
        open,
        mode,
        closeModal,
        all_merchants,
        CreateAuditRequest,
        modalData,
        UpdateAuditRequest,
        UploadReportFile,
    } = props;

    // states
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [error, setError] = useState(false);

    // functions
    const handleModalCancel = () => {
        closeModal();
        setData({});
        setDocuments([{ id: 1, doc_name: '', doc: '' }]);
    };
    const handleSelect = (name, value) => {
        setData({ ...data, [name]: value });
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };
    const getCurrentDoc = (id) => {
        return documents?.find((docs) => docs?.id === id);
    };
    //async functions
    const handleFileUpload = async (index, file) => {
        const formData = new FormData();
        formData.append('report', file);
        setUploading(true);
        const res = await UploadReportFile(formData);
        if (res?.success) {
            const newDocs = documents || [];
            newDocs[index] = {
                ...newDocs[index],
                doc: res?.report,
            };
            setUploading(false);
            setDocuments([...newDocs]);
        } else {
            toast.error(res?.message);
            setUploading(false);
        }
    };

    const handleDateChange = (name, value) => {
        setData({ ...data, [name]: value.map((date) => dayjs(date)) });
    };

    const filtered_documents = documents?.filter((doc) => doc?.doc !== '');

    const validate_fields =
        data?.merchant === undefined ||
        data?.compliance === undefined ||
        data?.effective_from === undefined ||
        filtered_documents?.length === 0;

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate_fields) {
            setError(true);
        } else {
            setLoading(true);
            let payload = {
                ...data,
                effective_from: data?.effective_from?.map((date) => date?.format())?.toString(),
                uploaded_docs: documents,
            };
            if (mode === 'Add') {
                payload = { ...payload, status: AUDIT_STATUS.COMPLETE };
            }
            const res =
                mode === 'Add' ? await CreateAuditRequest(payload) : await UpdateAuditRequest(payload, modalData?.id);
            setLoading(false);
            if (res?.success) {
                toast.success(`You have successfully uploaded ROC Documents for this merchant.`);
                handleModalCancel();
            } else {
                toast.error(res?.message);
            }
        }
    };

    // useEffect
    useEffect(() => {
        if (!open) return;
        const { merchant, compliance, uploaded_docs, effective_from } = modalData;
        const effectiveDate = effective_from?.split(',').map((date) => dayjs(date));
        setData({ merchant, compliance, effective_from: effectiveDate });
        if (mode === 'Add') {
            setDocuments([{ id: 1, doc_name: 'Report', doc: '' }]);
        } else {
            setDocuments(uploaded_docs);
        }
    }, [open, modalData, mode]);

    // const handleCertificateFileUpload = async (index, file) => {
    //     setUploading(true);
    //     const formData = new FormData();
    //     formData.append('report', file);
    //     const res = await UploadReportFile(formData);
    //     setUploading(false);
    //     if (res?.success) {
    //         const newDocs = certificate_documents || [];
    //         newDocs[index] = {
    //             ...newDocs[index],
    //             doc: res?.report,
    //         };
    //         setCertificate_Documents([...newDocs]);
    //     } else {
    //         toast.error(res?.message);
    //     }
    // };

    const addDoc = () => {
        const docLength = documents?.length + 1;
        setDocuments((current) => [...current, { id: docLength + 1, doc_name: '', doc: '' }]);
    };
    const deleteDoc = (index) => {
        const copiedDocs = [...documents];
        copiedDocs?.splice(index, 1);
        setDocuments(copiedDocs);
    };

    // const getCurrentDoc_ = (id) => {
    //     const allDocs = Array.isArray(certificate_documents) ? certificate_documents : [];
    //     return allDocs?.find((docs) => docs?.id === id);
    // };

    return (
        <Modal
            open={open}
            onCancel={handleModalCancel}
            title={`${mode === 'Add' ? 'Upload' : 'Update'} Merchant Document`}
            footer={null}
            destroyOnClose
        >
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2">
                        <label htmlFor="merchant">Merchant</label>
                        <Select
                            id="merchant"
                            name="merchant"
                            showSearch
                            onSearch={onSearch}
                            size="large"
                            value={data?.merchant}
                            className="w-full"
                            onChange={(value) => handleSelect('merchant', value)}
                            filterOption={(input, option) =>
                                (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                            }
                            required
                            disabled={mode === 'Edit'}
                            options={all_merchants?.map((merchant) => {
                                return {
                                    value: merchant?.id,
                                    label: merchant?.name
                                        ? merchant?.name
                                        : `${merchant?.owner_detail?.first_name} ${merchant?.owner_detail?.last_name}`,
                                };
                            })}
                        />
                        {error && data?.merchant === undefined && (
                            <span className="text-[red]">Please select a merchant</span>
                        )}
                    </Col>
                    <Col span={24} className="my-2">
                        <label htmlFor="compliance">Compliance Standard</label>
                        <Select
                            id="compliance"
                            name="compliance"
                            size="large"
                            className="w-full"
                            value={data?.compliance}
                            onChange={(value) => handleSelect('compliance', value)}
                            required
                            disabled={mode === 'Edit'}
                        >
                            {[
                                'pcidss',
                                'pcidss4.0',
                                'ndpr',
                                'soc2',
                                'iso27001',
                                'iso27001-2022',
                                'iso27017+iso27032',
                                'blockchain',
                                'iso22301',
                                'pcislc',
                                'cii',
                                'kdpa',
                                'gdpa',
                                'iso9001',
                            ]?.map((standard) => (
                                <Select.Option value={standard} key={standard}>
                                    {standard?.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                        {error && data?.compliance === undefined && (
                            <span className="text-[red]">Please select a standard</span>
                        )}
                    </Col>
                    <Col span={24} className="my-2">
                        <label htmlFor="uploaded_documents">Upload Report</label>
                        {documents?.map((document, idx) => {
                            return (
                                <Row gutter={8} key={document.id}>
                                    <Col span={24} className="my-2">
                                        <Input
                                            name="doc_name"
                                            size="large"
                                            placeholder="Document name"
                                            value={document?.doc_name}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                const newDocs = documents || [];
                                                newDocs[idx] = {
                                                    ...newDocs[idx],
                                                    doc_name: value,
                                                };
                                                setDocuments([...newDocs]);
                                            }}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <input
                                            type="file"
                                            onChange={async (e) => {
                                                const newDocs = documents || [];
                                                newDocs[idx] = {
                                                    ...newDocs[idx],
                                                    doc: e.target.files[0],
                                                };
                                                setDocuments([...newDocs]);
                                                handleFileUpload(idx, e.target.files[0]);
                                            }}
                                            className="my-2"
                                        />
                                        <div className="flex flex-row justify-between">
                                            <div>
                                                {getCurrentDoc(document?.id)?.doc?.name ||
                                                    (getCurrentDoc(document?.id)?.doc && (
                                                        <p className="break-all">
                                                            {getCurrentDoc(document?.id)?.doc?.name ||
                                                                (typeof getCurrentDoc(document?.id)?.doc === 'string' &&
                                                                    getCurrentDoc(document?.id)?.doc?.split('/')?.[4])}
                                                        </p>
                                                    ))}
                                            </div>
                                            <Button
                                                type="primary"
                                                danger
                                                shape="circle"
                                                onClick={() => deleteDoc(idx)}
                                                icon={<DeleteOutlined />}
                                                disabled={documents?.length <= 1}
                                            />
                                        </div>
                                    </Col>
                                    {error && filtered_documents?.length === 0 && (
                                        <span className="text-[red]">Please add document</span>
                                    )}
                                </Row>
                            );
                        })}
                        <div className="flex flex-row justify-end my-2 gap-2">
                            <Button icon={<PlusCircleOutlined />} onClick={addDoc} />
                        </div>
                    </Col>
                    <Col span={24} className="my-2">
                        <label htmlFor="effective_from">Effective From</label>
                        <RangePicker
                            onChange={(_, str) => handleDateChange('effective_from', str)}
                            size="large"
                            name="effective_from"
                            id="effective_from"
                            required
                            style={{ width: '100%' }}
                            value={data?.effective_from}
                            disabledDate={(current) => {
                                return moment().add(-1, 'days') >= current;
                            }}
                        />
                        {error && data?.effective_from === undefined && (
                            <span className="text-[red]">Please choose date range</span>
                        )}
                    </Col>
                    <Col className="mt-2">
                        <Button htmlType="submit" type="primary" loading={loading} disabled={uploading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = (state) => ({
    all_merchants: state?.adminReducers?.all_merchants,
});
export default connect(mapStateToProps, { CreateAuditRequest, UpdateAuditRequest, UploadReportFile })(UploadRocModal);
