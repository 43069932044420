import { ButtonGroup, Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import AppButton from './AppButton';

import { KeyboardArrowDownRounded } from '@mui/icons-material';
import AppMenu from './app-menu/AppMenu';
import AppMenuItem from './app-menu/AppMenuItem';

const AppButtonGroup = (props) => {
    const { mainBtnProps = {}, dropdownItems } = props;
    const { sx = {}, ...otherMainBtnProps } = mainBtnProps;
    const groupRef = useRef(null);

    // states
    const [anchor, setAnchor] = useState(null);

    // functions
    const openMenu = () => setAnchor(groupRef.current);

    const handleClose = () => setAnchor(null);

    return (
        <>
            <ButtonGroup variant="outlined" ref={groupRef}>
                <AppButton
                    sx={{ py: 0.8, px: 1.7, border: '1px solid #0E2C66', ...sx }}
                    {...otherMainBtnProps}
                    onClick={openMenu}
                />
                {dropdownItems && (
                    <>
                        <AppButton
                            icon={<KeyboardArrowDownRounded />}
                            sx={{
                                px: 1.5,
                                py: 1,
                                border: '1px solid #0E2C66',
                                '& .MuiButton-startIcon': {
                                    m: 0,
                                },
                            }}
                            onClick={openMenu}
                        />
                        <AppMenu anchor={anchor} onClose={handleClose}>
                            {dropdownItems.map((item, idx) => {
                                return (
                                    <Box key={idx}>
                                        {item.link ? (
                                            <a href={item.link} target="_blank" rel="noopener noreferrer">
                                                <AppMenuItem key={idx} icon={item.icon} label={item.label} />
                                            </a>
                                        ) : (
                                            <AppMenuItem
                                                key={idx}
                                                icon={item.icon}
                                                onClick={item.action}
                                                label={item.label}
                                            />
                                        )}
                                    </Box>
                                );
                            })}
                        </AppMenu>
                    </>
                )}
            </ButtonGroup>
        </>
    );
};

export default AppButtonGroup;
