import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
//core components
import { Box, Stack, Typography } from '@mui/material';
// import QRCode from "assets/img/qr-code.svg";
import { useTheme } from '@mui/material/styles';
import QRCode from 'qrcode.react';
import ConnectionViewHeader from './ConnectionViewHeader';
import GenerateCodeButton from './GenerateCodeButton';
import AuthConnectionViewForm from './AuthConnectionViewForm';
import OtpTimer from 'otp-timer';
//redux
import { connect } from 'react-redux';
import { Initiate2FA } from 'store/actions/generalActions';
import GeneratedTextTooltip from './Components/GeneratedTextTooltip';
// import AppCountDownTimer from "components/new_components/AppCountDownTimer";

//translation
import { useTranslation } from 'react-i18next';

const AuthConnectionView = (props) => {
    const theme = useTheme();
    const { authType, goBack, Initiate2FA } = props;
    const [values, setValues] = useState({});
    const [visible, setVisible] = useState(false);
    // translation hook
    const { t } = useTranslation('accounts');
    const generateText = () => {
        setVisible(!visible);
    };

    const initiate2FA = async () => {
        const res = await Initiate2FA({ mfa_type: authType });
        if (res?.success) {
            authType === 'authenticator' ? setValues({ url: res?.url, secret: res?.secret }) : setValues({});
        } else {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        initiate2FA();
    }, [authType]);
    return (
        <>
            <ConnectionViewHeader authType={authType} goBack={goBack} />
            <Box sx={{ m: 2 }}>
                <Typography sx={{ fontWeight: 700, fontSize: '16px', px: 2.4 }}>
                    {authType === 'email'
                        ? t('twoFactorAuth.authConnectionView.emailCode')
                        : t('twoFactorAuth.authConnectionView.scan')}
                </Typography>
                {authType !== 'email' && (
                    <Box
                        sx={{
                            m: 3,
                            p: 1,
                            width: '320px',
                            border: `1.33px solid ${theme.palette.primary.main}`,
                            borderRadius: '5.32px',
                        }}
                    >
                        <QRCode value={values?.url} size={300} />
                    </Box>
                )}
                <Stack direction="row" alignItems="center" sx={{ px: 2.4, my: 2 }}>
                    <Typography sx={{ fontWeight: 500, fontSize: '14px', mr: 1.7 }}>
                        {authType === 'email'
                            ? t('twoFactorAuth.authConnectionView.noCode')
                            : t('twoFactorAuth.authConnectionView.cannotScan')}
                    </Typography>
                    {authType !== 'email' && <GenerateCodeButton authType={authType} generateText={generateText} />}
                    {authType === 'email' && (
                        <>
                            {/* <AppCountDownTimer minSecs={{ minutes: 3, seconds: 0 }} /> */}
                            <OtpTimer
                                seconds={0}
                                minutes={2}
                                buttonText={t('twoFactorAuth.authConnectionView.otpTimer.buttonText')}
                                background={theme.palette.primary.main}
                                resend={initiate2FA}
                                buttonColor="#fff"
                            />
                        </>
                    )}
                </Stack>
                {authType !== 'email' && visible ? (
                    <Stack direction="row">
                        <Box
                            sx={{
                                backgroundColor: theme.palette.gray[100],
                                ml: 2.4,
                                padding: 0.5,
                                borderRadius: 2,
                                width: '300',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontWeight: 600,
                                    fontSize: 16,
                                    letterSpacing: '0.25px',
                                }}
                            >
                                {values?.secret}
                            </Typography>
                        </Box>
                        <GeneratedTextTooltip />
                    </Stack>
                ) : null}
                <AuthConnectionViewForm authType={authType} goBack={goBack} />
            </Box>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { Initiate2FA })(AuthConnectionView);
