import React, { useState, useEffect, useContext } from 'react';
import { Stack, IconButton, Tooltip } from '@mui/material';
import RowContainer from './components/RowContainer';
import NotificationMessage from './components/NotificationMessage';
import { BsDownload } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import FileIcon from 'assets/img/FileIcon.svg';
import AddDocumentModal from './components/AddDocumentModal';
import { toast } from 'react-toastify';
//redux
import { connect, useSelector } from 'react-redux';
import { GetAllMerchantExtraDocs, DeleteMerchantExtraDocument } from 'store/actions/merchantActions';
import LoadingState from 'components/new_components/LoadingState';
import EmptyState from 'components/new_components/EmptyState';
import AppDeleteModal from 'components/new_components/AppDeleteModal';
import { formatDateObjectHandler } from 'utils';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';
import { getType } from 'utils';
import { GetDownloadLink } from 'store/actions/generalActions';

const NewDoc = (props) => {
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deletePayload, setDeletePayload] = useState(null);
    const [deleting, setDeleting] = useState(false);

    const { currentTab, GetAllMerchantExtraDocs, DeleteMerchantExtraDocument, all_merchant_extra_docs } = props;
    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const client_id = useSelector((state) => state?.implementerReducer?.client_id);

    //context api for auditor's selected merchant
    const { activeMerchant } = useContext(SelectedMerchantContext);

    const openAddModal = () => {
        setAddModalOpen(true);
    };
    const closeAddModal = () => {
        setAddModalOpen(false);
    };
    const openDeleteModal = (id) => {
        setDeletePayload(id);
        setDeleteModalOpen(true);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setDeletePayload(null);
    };

    const handleDeleteDocument = async () => {
        setDeleting(true);
        const res = await DeleteMerchantExtraDocument(deletePayload);
        setDeleting(false);
        if (res?.success) {
            toast.success('Document Deleted Successfully');
            closeDeleteModal();
        } else {
            toast.error(res?.message);
        }
    };
    //useeffect
    useEffect(() => {
        const getAllMerchantExtraDocs = async () => {
            setFetching(true);
            const res = await GetAllMerchantExtraDocs(user_type === 'implementer' ? client_id : activeMerchant);
            setFetching(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllMerchantExtraDocs();
    }, [activeMerchant, user_type, client_id]);

    return (
        <>
            <Stack sx={{ width: '80%', margin: '0 auto', mt: 2 }}>
                <NotificationMessage currentTab={currentTab} openAddModal={openAddModal} />
                {fetching ? (
                    <LoadingState />
                ) : all_merchant_extra_docs?.length ? (
                    all_merchant_extra_docs
                        ?.sort((a, b) => b?.id - a?.id)
                        ?.map((data) => <DataItem data={data} key={data.id} openDeleteModal={openDeleteModal} />)
                ) : (
                    <EmptyState description="No Document(s) Available" />
                )}
            </Stack>
            <AddDocumentModal open={addModalOpen} handleClose={closeAddModal} />
            <AppDeleteModal
                open={deleteModalOpen}
                handleClose={closeDeleteModal}
                title="Permanently delete this Document?"
                subtitle="Please note that this action can not be reversed."
                deleting={deleting}
                onDelete={handleDeleteDocument}
            />
        </>
    );
};

const DataItem = ({ data, openDeleteModal }) => {
    const role = getType('role');

    const [loadingLink, setLoadingLink] = useState(false);
    const getDownloadLink = async () => {
        if (data?.doc_file) {
            setLoadingLink(true);
            const res = await GetDownloadLink('compliance', 'doc_file_upload', data.id)();
            setLoadingLink(false);
            if (res?.success) {
                // open decoded url
                window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
                // open original file url
                window.open(data?.doc_file, '_blank');
            }
        }
    };

    return (
        <RowContainer key={data?.id}>
            <Stack flexDirection="row" alignItems="center" gap={1}>
                <img src={FileIcon} alt="file icon" className="w-[26px]" />
                <div className="flex flex-row items-center">
                    <p className="text-[13px] font-medium text-[#3E414D] m-0">{data?.name}</p>
                    <span className="text-[11px] font-semibold  pl-1">
                        ({formatDateObjectHandler(data?.date_created, 'MMMM Do, YYYY') || 'Nil'})
                    </span>
                </div>
            </Stack>
            <Stack flexDirection="row" alignItems="center">
                <Tooltip title="Download Document" placement="top">
                    <IconButton aria-label="download" onClick={getDownloadLink}>
                        {loadingLink ? (
                            <LoadingState size={20} styles={{ justifyContent: 'start', alignItems: 'start' }} />
                        ) : (
                            <BsDownload color="#77777A" size="18px" />
                        )}
                    </IconButton>
                </Tooltip>
                {role !== 'auditor' && (
                    <Tooltip title="Delete Document" placement="top">
                        <IconButton aria-label="delete" onClick={() => openDeleteModal(data?.id)}>
                            <AiOutlineDelete color="#77777A" size="18px" />
                        </IconButton>
                    </Tooltip>
                )}
            </Stack>
        </RowContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_extra_docs: state?.merchantReducers?.all_merchant_extra_docs,
    };
};
export default connect(mapStateToProps, { GetAllMerchantExtraDocs, DeleteMerchantExtraDocument })(NewDoc);
