import React, { useEffect, useState } from 'react';
// library
import { toast } from 'react-toastify';
import { Table, Modal, Row, Col, Input, Button } from 'antd';
// core components
import UserOperations from './UserOperations/UserOperations';
import ViewUserModal from './UserOperations/ViewUserModal';
import ViewUsersModal from './UserOperations/ViewUsersModal';
import BreadCrumb from 'components/Breadcrumb';

// redux
import { connect } from 'react-redux';
import { GetAllMerchants, DeleteMerchant } from 'store/actions/adminActions';
import { GetAllPlans } from 'store/actions/subscriptionActions';
import AssignAuditorModal from './UserOperations/AssignAuditorModal';
import AssignPlanModal from './UserOperations/AssignPlanModal';
import SendBulkSurveyModal from './UserOperations/SendBulkSurvey';
import useSearch from 'hooks/useSearch';
import { SearchOutlined } from '@ant-design/icons';
import AssignCourseModal from './UserOperations/AssignCourseModal';
import { GetAllApprovedUser } from 'store/actions/FraudGauardianActions';
import { GetAllMerchantCertificates } from 'store/actions/auditActions';
import ViewAssignedAuditors from './UserOperations/ViewAssignedAuditors';

const Merchants = (props) => {
    // props
    const {
        all_merchants,
        GetAllMerchants,
        GetAllPlans,
        DeleteMerchant,
        all_plans,
        GetAllApprovedUser,
        all_approved_user,
        GetAllMerchantCertificates,
    } = props;

    // state
    const [visible, setVisible] = useState(false);
    const [viewUsers, setViewUsers] = useState(false);
    const [record, setrecord] = useState({});
    const [standard, setStandard] = useState('');
    const [deleteloading, setdeleteloading] = useState(false);
    const [showAssignModal, setShowAssignmentModal] = useState(false);
    const [showViewAuditorModal, setShowViewAuditorModal] = useState(false);
    const [bulkSurvey, setBulkSurvey] = useState(false);
    const [showPlanModal, setShowPlanModal] = useState(false);
    const [showCourseModal, setShowCourseModal] = useState(false);

    // hooks
    const { data, handleSearch } = useSearch(all_merchants, [
        'owner_detail.first_name',
        'owner_detail.last_name',
        'owner_detail.email',
        'name',
        'mailing_address',
        'phone_number',
    ]);

    // async functions
    const getAllPlans = async () => {
        const res = await GetAllPlans();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllMerchants = async () => {
        const res = await GetAllMerchants();
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    const getApprovedUser = async () => {
        const res = await GetAllApprovedUser();
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    //  logic functions
    const openViewDrawer = (record) => {
        setrecord(record);
        setVisible(true);
    };
    const closeViewDrawer = () => {
        setVisible(false);
        setTimeout(() => setrecord({}), 3000);
    };

    const openViewUsersDrawer = (record) => {
        setrecord(record);
        setViewUsers(true);
    };
    const closeViewUsersDrawer = () => {
        setViewUsers(false);
        setTimeout(() => setrecord({}), 3000);
    };
    const openAssignmentModal = (merchant, standard) => {
        setShowAssignmentModal(true);
        setrecord(merchant);
        setStandard(standard);
    };
    const closeAssignmentModal = () => {
        setShowAssignmentModal(false);
        setrecord(null);
    };

    const openPlanModal = (data) => {
        setrecord(data);
        setShowPlanModal(true);
    };
    const closePlanModal = () => {
        setrecord({});
        setShowPlanModal(false);
    };

    const openBulkSurveyModal = (data) => {
        setrecord(data);
        setBulkSurvey(true);
    };
    const closeBulkSurveyModal = () => {
        setrecord({});
        setBulkSurvey(false);
    };
    const openCourseModal = (data) => {
        setrecord(data);
        setShowCourseModal(true);
    };
    const closeCourseModal = () => {
        setrecord({});
        setShowCourseModal(false);
    };
    const openViewAuditorModal = (data, standard) => {
        setShowViewAuditorModal(true);
        setrecord(data);
        setStandard(standard);
    };
    const closeViewAuditorModal = () => {
        setShowViewAuditorModal(false);
        setrecord({});
        setStandard(null);
    };

    const deleteMerchant = (record) => {
        Modal.confirm({
            title: 'Do you wish to delete this Merchant?',
            content: 'Click yes to proceed',
            okText: 'Delete',
            loading: deleteloading,
            okType: 'danger',
            async onOk() {
                setdeleteloading(true);
                const res = await DeleteMerchant(record?.id);
                if (res?.success) {
                    setdeleteloading(false);
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                    setdeleteloading(false);
                }
            },
            onCancel() {
                setdeleteloading(false);
                return false;
            },
        });
    };

    // constants
    const columns = [
        {
            title: 'Owner Name',
            render: (record) => `${record?.owner_detail?.first_name} ${record?.owner_detail?.last_name}`,
        },
        {
            title: "Owner's Email",
            render: (record) => record?.owner_detail?.email,
        },
        {
            title: 'Company Name',
            render: (record) => record?.name,
        },
        {
            title: 'Company Email',
            render: (record) => record?.mailing_address,
        },
        {
            title: 'Phone Number',
            render: (record) => record?.phone_number || 'N/A',
        },
        // {
        //     title: 'Auditor Request Status',
        //     render: (record) => {
        //         const value = record?.auditor_detail.name ? (
        //             <Tag color="green">APPROVED</Tag>
        //         ) : record?.seek_auditor ? (
        //             <Tag color="yellow">PENDING</Tag>
        //         ) : (
        //             <Tag color="red">NO REQUEST</Tag>
        //         );
        //         return value;
        //     },
        // },
        {
            title: 'Action',
            align: 'right',
            render: (record) => (
                <UserOperations
                    record={record}
                    openViewDrawer={openViewDrawer}
                    openViewUserDrawer={openViewUsersDrawer}
                    deleteModal={(record) => deleteMerchant(record)}
                    openAssignmentModal={openAssignmentModal}
                    openAssignPlanModal={openPlanModal}
                    openCourseModal={openCourseModal}
                    openViewAuditorModal={openViewAuditorModal}
                    all_approved_user={all_approved_user}
                />
            ),
        },
    ];

    const allCertificates = async () => {
        const response = await GetAllMerchantCertificates();
        if (!response?.success) {
            toast.error('Certificate');
        }
    };

    // useEffect
    useEffect(() => {
        getAllMerchants();
        getAllPlans();
        getApprovedUser();
        allCertificates();
    }, []);

    return (
        <div>
            <Row justify="space-between" align="center">
                <Col>
                    <BreadCrumb views={[{ label: 'Merchants' }]} />
                </Col>
                <Col span={12} className="flex items-center">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                    <Button
                        type="primary"
                        className="flex justify-between items-center ml-3"
                        onClick={openBulkSurveyModal}
                    >
                        Send Bulk Survey
                    </Button>
                </Col>
            </Row>

            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(merchant) => merchant?.id}
                    columns={columns}
                    dataSource={data
                        ?.sort((a, b) => b?.owner_detail.id - a?.owner_detail.id)
                        ?.map((merchant, index) => ({
                            ...merchant,
                            index,
                        }))}
                    pagination={{
                        total: data?.length,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                    }}
                />
            </section>
            <ViewUserModal
                visible={visible}
                closeDrawer={closeViewDrawer}
                record={record}
                openAssignmentModal={openAssignmentModal}
            />
            <ViewUsersModal visible={viewUsers} closeDrawer={closeViewUsersDrawer} record={record} />
            <AssignAuditorModal
                open={showAssignModal}
                closeModal={closeAssignmentModal}
                merchant={record}
                standard={standard}
            />
            <AssignPlanModal
                open={showPlanModal}
                handleClose={closePlanModal}
                merchant={record}
                all_plans={all_plans}
            />
            <AssignCourseModal open={showCourseModal} closeModal={closeCourseModal} record={record} />
            <SendBulkSurveyModal open={bulkSurvey} closeModal={closeBulkSurveyModal} merchant={record} />
            <ViewAssignedAuditors
                visible={showViewAuditorModal}
                closeDrawer={closeViewAuditorModal}
                record={record}
                standard={standard}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchants: state?.adminReducers?.all_merchants,
        all_plans: state?.subscriptionReducers.all_plans,
        all_approved_user: state?.fraudGuardianReducers?.all_approved_user,
    };
};

export default connect(mapStateToProps, {
    GetAllMerchants,
    GetAllPlans,
    DeleteMerchant,
    GetAllApprovedUser,
    GetAllMerchantCertificates,
})(Merchants);
