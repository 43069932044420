import React from 'react';
import { useTheme } from '@mui/material/styles';

// app components
// import AppInput from "components/new_components/AppInput";
import { AppFormInput } from 'components/new_components/forms';
//translation
import { useTranslation } from 'react-i18next';
const EmployeeAmountField = (props) => {
    const { labelStyle } = props;
    const theme = useTheme();

    //translation hook
    const { t } = useTranslation('accounts');
    return (
        <AppFormInput
            label={t('plans.employeeAmountFieldLabel')}
            type="number"
            name="amountOfEmployee"
            labelStyle={labelStyle}
            regex={/^[0-9]*$/g}
            min={0}
            sx={{ borderColor: theme.palette.gray[400] }}
        />
    );
};

export default EmployeeAmountField;
