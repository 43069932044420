import React, { useMemo, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

// core components
import SubgroupView from '../../common/SubgroupView';
import SubgroupTabs from '../../common/SubgroupTabs';
import DescriptionLayer from '../../common/DescriptionLayer';
import DocumentItem from '../../common/document/DocumentItem';
import LoadingState from 'components/new_components/LoadingState';
import QuestionItem from '../../common/audit_questions/QuestionItem';

// redux
import { connect } from 'react-redux';
import { GetComplianceAIAnswersBySubReqAndStandard } from 'store/actions/complianceActions';
import { checkTruthyValues } from 'utils';
import AIAutoFillResponseModal from '../../common/AIAutoFillResponseModal';
import DescriptionPreviewDrawer from '../../common/DescriptionPreviewDrawer';

//translations
import { useTranslation } from 'react-i18next';

const Subrequirements = (props) => {
    const {
        goBack,
        requirement,
        resource,
        subrequirementResources,
        merchantResources,
        loading,
        onSubrequirementChange,
        setFormPayload,
        touchedFields,
        submitting,
        handleResourceExempted,
        isComplianceOn,
        formPayload,
        openTaskAssignModal,
        all_compliance_assigned_tasks,
        assigned_task_documents_info,
        assigned_task_questions_info,
        all_requirements,
        all_requirements_metrics,
        compliance_ai_answers,
        // handleSubmitAutoFilledAnswers,
    } = props;
    // state
    const [page, setPage] = useState(1);
    const [fetchingAIAnswers, setFetchingAIAnswers] = useState(false);
    const [autofilled, setAutofilled] = useState({});
    const [selectedSubrequirementNo, setSelectedSubrequirementNo] = useState(null);
    const [autoFillResponseModal, setAutoFilledResponseModal] = useState(false);
    const [responseType, setResponseType] = useState('');
    const [descriptionDrawer, setDescriptionDrawer] = useState(false);

    // console.log({ selectedSubrequirementNo });

    //translation
    const { t } = useTranslation('compliance');

    //functions
    const openResponseModal = (type) => {
        setAutoFilledResponseModal(true);
        setResponseType(type);
    };
    const closeResponseModal = () => {
        setAutoFilledResponseModal(false);
        setTimeout(() => setResponseType(''), 2000);
    };

    // memos
    // get the selected Requirement obj from the requirement ID
    const selectedRequirement = useMemo(
        () => all_requirements?.find((req) => req?.id === requirement),
        [all_requirements, requirement]
    );

    // task assigned
    const isTaskAssignedToSelectSubRequirement = useMemo(() => {
        const assignedSubrequirements =
            resource === 'doc'
                ? all_compliance_assigned_tasks?.documents?.sub_category?.pcidss
                : all_compliance_assigned_tasks?.questions?.sub_category?.pcidss;
        return assignedSubrequirements?.includes(selectedSubrequirementNo);
    }, [selectedSubrequirementNo, resource]);

    // getting the overall metrics anytime the selected requirement changes
    const overallMetric = useMemo(() => {
        const requirement_number = selectedRequirement?.requirement_number;
        const metrics = all_requirements_metrics?.[requirement_number?.toFixed(1)];

        return resource === 'doc'
            ? `${metrics?.user_doc}/${metrics?.total_doc} ${t('documentsUploaded')}`
            : `${metrics?.user_form}/${metrics?.total_form} ${t('questionsAnswered')}`;
    }, [all_requirements_metrics, selectedRequirement]);

    // get the subquirements for the selected requirement
    const subRequirements = useMemo(() => {
        return selectedRequirement?.sub_requirements?.length
            ? selectedRequirement?.sub_requirements
                  ?.sort((a, b) => a?.requirement_number - b?.requirement_number)
                  ?.map((subRequirement) => ({
                      id: subRequirement?.requirement_number,
                      description: subRequirement?.requirement_description,
                      title: subRequirement?.requirement_title,
                      label: `${t('sub')} ${subRequirement?.requirement_number}`,
                      has_resource: resource === 'doc' ? subRequirement?.have_doc : subRequirement?.have_question,
                      completed:
                          resource === 'doc' ? subRequirement?.document_completed : subRequirement?.question_completed,
                  }))
            : [];
    }, [selectedRequirement]);

    // get the sub requirements for the selected sub requirement tab
    const selectedSubrequirement = useMemo(() => {
        return subRequirements?.[0]
            ? subRequirements?.find((subrequirement) => subrequirement?.id === selectedSubrequirementNo)
            : {};
    }, [selectedSubrequirementNo]);

    // get a list of the status of each resource in the subrequirement
    const resourcesHaveBeenAttendedTo = useMemo(() => {
        return subrequirementResources?.length
            ? subrequirementResources?.map((subReqDoc) => {
                  return merchantResources?.some((merchDoc) =>
                      resource === 'doc' ? merchDoc?.document_id === subReqDoc?.id : merchDoc?.form_id === subReqDoc?.id
                  );
              })
            : null;
    }, [merchantResources, subrequirementResources]);

    // get the percentage completion for the selected subrequirement
    const subrequirementCompletionPercent = useMemo(() => {
        return (
            (resourcesHaveBeenAttendedTo?.reduce((prev, curr) => prev + (curr ? 1 : 0), 0) * 100) /
            resourcesHaveBeenAttendedTo?.length
        );
    }, [resourcesHaveBeenAttendedTo]);

    // get the selected document for the current page
    const selectedResource = useMemo(() => {
        return subrequirementResources?.[page - 1];
    }, [subrequirementResources, page]);

    // functions
    const goToResource = (page) => {
        if (page > subrequirementResources?.length) {
            setPage(subrequirementResources?.length);
        } else if (page < 1) {
            setPage(1);
        } else {
            setPage(page);
        }
    };
    // when the sub group is changed set the page to 1 (first stage)
    const handleSubgroupChange = (id) => {
        setSelectedSubrequirementNo(id);
        goToResource(1);
    };

    const onResourceChange = () => {
        handleResourceExempted(selectedResource?.card4_sub_no);
    };

    //Get Compliance AI answers
    const getComplianceAIAnswers = async () => {
        setFetchingAIAnswers(true);
        const res = await GetComplianceAIAnswersBySubReqAndStandard({
            standard: 'card4',
            subreq: selectedSubrequirementNo,
        });
        setFetchingAIAnswers(false);
        if (res?.success) {
            const keysLength = Object.keys(res?.data?.[selectedSubrequirementNo] || {})?.length;
            if (keysLength > 0) {
                setAutofilled({ ...autofilled, [selectedSubrequirementNo]: true });
            }
        } else {
            toast.error('An error occurred while fetching the compliance AI answers ');
        }
    };

    // useEffect
    useEffect(() => {
        if (!subRequirements) return;
        // check if the current sub requirement has any resource

        // // get the first active resource
        // const activeSubrequirement = subRequirements?.find((sub) => sub?.has_resource);

        const currentSubHasResource = subRequirements?.find(
            (sub) => sub?.id === selectedSubrequirementNo
        )?.has_resource;
        if (currentSubHasResource) return;
        // get the first active resource
        const activeSubrequirement = subRequirements?.find((sub) => sub?.has_resource);
        if (activeSubrequirement) {
            setSelectedSubrequirementNo(activeSubrequirement?.id);
        } else {
            goBack();
        }
    }, [subRequirements]);
    useEffect(() => {
        if (selectedSubrequirementNo !== null) {
            onSubrequirementChange(selectedSubrequirementNo);
        }
    }, [selectedSubrequirementNo]);

    // to set the current question's answer to the appform initial value.
    // useEffect(() => {
    //     if (!selectedResource) {
    //         return goToResource(subrequirementResources?.length);
    //     }
    //     if (resource === 'form') {
    //         // get the admin resource for that page
    //         const adminResource = selectedResource;
    //         // get the merchant resource corresponding to that page
    //         const merchantResource = merchantResources?.length
    //             ? merchantResources
    //                   ?.sort((a, b) => b?.id - a?.id)
    //                   ?.find((resource) => resource?.form_id === adminResource?.id)
    //             : {};
    //         // set the answers
    //         let initialValues = {},
    //             requiredValues = {};
    //         adminResource?.form_fields?.forEach((field) => {
    //             const aiAnswersBySubReq = compliance_ai_answers?.[adminResource?.requirement_sub_no] || {};
    //             const answerByFieldId = aiAnswersBySubReq[field?.id] || [];
    //             console.log({ field: field?.id, aiAnswersBySubReq: aiAnswersBySubReq[field?.id] || [] });
    //             let highestPercentage = Math.max(...answerByFieldId.map((answer) => answer?.percentage));
    //             let highestPercentageAnswer = answerByFieldId?.find((x) => x?.percentage === highestPercentage);
    //             // consng getAiBestAnswerForField = aiAnswersBySubReq
    //             initialValues[field?.id] = merchantResource?.answer?.[field?.id]
    //                 ? merchantResource?.answer?.[field?.id]
    //                 : highestPercentageAnswer
    //                 ? highestPercentageAnswer?.answer
    //                 : '';
    //             requiredValues[field?.id] = field?.required;
    //         });
    //         setFormPayload({
    //             values: initialValues,
    //             form_id: adminResource?.id,
    //             subReqNo: adminResource?.requirement_sub_no,
    //             requiredValues,
    //         });
    //     }
    // }, [page, selectedResource, merchantResources, compliance_ai_answers]);
    useEffect(() => {
        if (resource === 'form') {
            // get the admin resource for that page
            const adminResource = selectedResource;

            // get the merchant resource corresponding to that page
            const merchantResource = merchantResources?.length
                ? merchantResources
                      ?.sort((a, b) => b?.id - a?.id)
                      ?.find((resource) => resource?.form_id === adminResource?.id)
                : {};

            // set the answers
            let initialValues = {};
            let requiredValues = {};
            let highestPercentageUsed = {};
            adminResource?.form_fields?.forEach((field) => {
                const aiAnswersBySubReq = compliance_ai_answers?.[adminResource?.requirement_sub_no] || {};
                const answerByFieldId = aiAnswersBySubReq[field?.id] || [];

                let highestPercentage = Math.max(...answerByFieldId.map((answer) => answer?.percentage));
                let highestPercentageAnswer = answerByFieldId?.find((x) => x?.percentage === highestPercentage);

                if (merchantResource?.answer?.[field?.id]) {
                    initialValues[field?.id] = merchantResource?.answer?.[field?.id];
                    highestPercentageUsed[field?.id] = false;
                } else if (field?.type === 'text') {
                    initialValues[field?.id] =
                        !fetchingAIAnswers && autofilled ? highestPercentageAnswer?.answer || '' : '';
                    highestPercentageUsed[field?.id] = true;
                }
                // initialValues[field?.id] = merchantResource?.answer?.[field?.id]
                //     ? merchantResource?.answer?.[field?.id]
                //     : highestPercentageAnswer?.answer || ''; // Use highestPercentageAnswer.answer if available

                requiredValues[field?.id] = field?.required;
            });

            setFormPayload({
                values: initialValues,
                form_id: adminResource?.id,
                subReqNo: adminResource?.card4_sub_no,
                requiredValues,
                highestPercentageUsed, // Pass the flag to the state
            });
        }
    }, [page, selectedResource, merchantResources, compliance_ai_answers, fetchingAIAnswers, autofilled]);

    useEffect(() => {
        if (resource === 'form') {
            const results = checkTruthyValues(formPayload?.highestPercentageUsed || {});
            if (results?.allTrue && autofilled?.[selectedSubrequirementNo]) {
                openResponseModal('allTrue');
            } else if (results?.noneTrue && autofilled?.[selectedSubrequirementNo]) {
                openResponseModal('noneTrue');
            } else if (results?.someTrue && autofilled?.[selectedSubrequirementNo]) {
                openResponseModal('someTrue');
            }
        }
    }, [
        formPayload?.highestPercentageUsed,
        resource,
        selectedSubrequirementNo,
        autofilled?.[selectedSubrequirementNo],
    ]);

    // useEffect(() => {
    //     if (autofilled?.[selectedSubrequirementNo] && !fetchingAIAnswers) {
    //         setTimeout(() => setAutofilled({ ...autofilled, [selectedSubrequirementNo]: false }), 3000);
    //     }
    // }, [autofilled?.[selectedSubrequirementNo]]);

    //open description preview modal
    const openDescriptionDrawer = () => {
        setDescriptionDrawer(true);
    };
    const closeDescriptionDrawer = () => {
        setDescriptionDrawer(false);
    };
    return (
        <>
            <SubgroupView
                goBack={goBack}
                title={`${t('requirement')} ${selectedRequirement?.requirement_number}`}
                groupMetric={overallMetric}
                goToNextResource={goToResource}
                currentResourcePage={page}
                data={resourcesHaveBeenAttendedTo}
                resource={resource}
                touchedFields={touchedFields}
                submitting={submitting}
                selectedSubgroup={selectedSubrequirementNo}
                selectedResource={selectedResource}
                categoryResources={subrequirementResources}
                assigned_task_documents_info={assigned_task_documents_info}
                assigned_task_questions_info={assigned_task_questions_info}
            >
                <SubgroupTabs
                    subgroups={subRequirements}
                    onSubgroupChange={handleSubgroupChange}
                    selectedSubgroup={selectedSubrequirementNo}
                    assignedSubrequirements={
                        resource === 'doc'
                            ? all_compliance_assigned_tasks?.documents?.sub_category?.card4
                            : all_compliance_assigned_tasks?.questions?.sub_category?.card4
                    }
                />
                {loading.content ? (
                    <LoadingState />
                ) : (
                    <>
                        <DescriptionLayer
                            percentCompletion={subrequirementCompletionPercent}
                            label={`${t('sub')} ${selectedSubrequirementNo} ${t('completion')}`}
                            description={selectedSubrequirement?.description}
                            resource={resource}
                            isComplianceOn={isComplianceOn}
                            getComplianceAIAnswers={getComplianceAIAnswers}
                            fetchingAIAnswers={fetchingAIAnswers}
                            autofilled={autofilled?.[selectedSubrequirementNo] || false}
                            openTaskAssignModal={() =>
                                openTaskAssignModal('subrequirement', {
                                    requirement_sub_no: selectedSubrequirementNo,
                                    standard: 'card4',
                                    is_document: resource === 'doc',
                                    card4_requirement: requirement,
                                })
                            }
                            type="subrequirement"
                            subCategory={selectedSubrequirementNo}
                            category={requirement}
                            standard="card4"
                            isTaskAssignedToSelectSubRequirement={isTaskAssignedToSelectSubRequirement}
                            openDescriptionDrawer={openDescriptionDrawer}
                        />
                        {resource === 'doc' ? (
                            <DocumentItem
                                document={selectedResource}
                                title_key={'card4_title'}
                                standard="card4"
                                onResourceChange={onResourceChange}
                                resource="doc"
                                openTaskAssignModal={openTaskAssignModal}
                                taskInfo={{
                                    card4_requirement: requirement,
                                    requirement_sub_no: selectedSubrequirementNo,
                                }}
                                assignedDocuments={assigned_task_documents_info}
                            />
                        ) : (
                            <QuestionItem
                                form={selectedResource}
                                title={'card4_title'}
                                standard="card4"
                                aiAnswers={compliance_ai_answers?.[selectedSubrequirementNo]}
                                onResourceChange={onResourceChange}
                                openTaskAssignModal={openTaskAssignModal}
                                resource="form"
                                taskInfo={{
                                    card4_requirement: requirement,
                                    requirement_sub_no: selectedSubrequirementNo,
                                }}
                                subCategory={selectedSubrequirementNo}
                                category={requirement}
                                assignedForms={assigned_task_questions_info}
                            />
                        )}
                    </>
                )}
                <AIAutoFillResponseModal
                    open={autoFillResponseModal}
                    handleClose={closeResponseModal}
                    title={responseType}
                />
            </SubgroupView>
            <DescriptionPreviewDrawer
                open={descriptionDrawer}
                handleClose={closeDescriptionDrawer}
                category={selectedSubrequirement}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_requirements: state?.adminReducers?.all_pcidss4point0_requirements,
        all_requirements_metrics: state?.complianceReducers?.all_pcidss4point0_requirements_metrics,
        compliance_ai_answers: state?.complianceReducers?.compliance_ai_answers_by_subreq_and_standard,
        assigned_task_documents_info:
            state?.generalReducers?.user_info?.compliance_assigned_tasks?.documents?.forms?.card4,
        assigned_task_questions_info:
            state?.generalReducers?.user_info?.compliance_assigned_tasks?.questions?.forms?.card4,
    };
};

export default connect(mapStateToProps, { GetComplianceAIAnswersBySubReqAndStandard })(Subrequirements);
