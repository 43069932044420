import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Badge, Box, Card, Container, Stack, Typography } from '@mui/material';

// core components
import AppTabsBarNew from 'components/new_components/tabs/AppTabsBarNew';

//translation
import { useTranslation } from 'react-i18next';
import SettingsIcon from '../../../assets/img/icons/SettingsIcon.svg';
import Ploygon from '../../../assets/img/polygon_2.svg';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import { UpdateApprovedUser } from 'store/actions/FraudGauardianActions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';

const ComplianceLayout = (props) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const { children, onTabChange, tab, handleOpenSettings, all_approved_user } = props;
    const dispatch = useDispatch();
    //translation
    const { t } = useTranslation('fraudGuardian');

    const tabs = t('tabs', { returnObjects: true });

    const handleNotificationView = async () => {
        setLoading(true);
        const body = {
            passkey: true,
        };
        const res = await dispatch(UpdateApprovedUser(all_approved_user?.id, body));
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    return (
        <Container
            maxWidth={false}
            elevation={0}
            sx={{
                p: '0 !important',
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: 'transparent',
                mb: 5,
            }}
        >
            <AppTabsBarNew
                currentTab={tab}
                tabs={tabs}
                onChange={onTabChange}
                others={
                    <Box>
                        <Badge
                            variant="dot"
                            invisible={!(all_approved_user?.enabled && !all_approved_user?.passkey)}
                            color="error"
                        >
                            <img
                                src={SettingsIcon}
                                alt="SettingsIcon"
                                style={{
                                    color: theme.palette.neutral[50],
                                    cursor: 'pointer',
                                    positon: 'relative',
                                }}
                                onClick={handleOpenSettings}
                            />
                        </Badge>

                        {all_approved_user?.enabled && !all_approved_user?.passkey && (
                            <Card
                                variant="outlined"
                                sx={{
                                    position: 'absolute',
                                    top: '11%',
                                    right: '1%',
                                    width: '310px',
                                    height: '170px',
                                    p: 2,
                                    zIndex: 1000,
                                    boxShadow: '2px 4px 8px 0px #0000001A',
                                }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography
                                        sx={{
                                            fontSize: '15px',
                                            fontWeight: 600,
                                            color: '#395BA9',
                                            pb: 0.5,
                                            mb: 1,
                                        }}
                                    >
                                        {' '}
                                        Settings
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            border: '1px solid #E2E8F0',
                                            borderRadius: 50,
                                            width: '25px',
                                            height: '25px',
                                        }}
                                        onClick={handleNotificationView}
                                    >
                                        <CloseIcon sx={{ width: 15, height: 15 }} />
                                    </Box>
                                </Box>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 400,
                                        color: '#64748B',
                                        pb: 0.5,
                                        mt: 1,
                                    }}
                                >
                                    Get & update API tokens, customize monitoring setting and read documentations.
                                </Typography>
                                <AppLoadingButton
                                    text="Got it!"
                                    loading={loading}
                                    sx={{ textTransform: 'inherit', float: 'right', my: 2 }}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNotificationView}
                                />
                            </Card>
                        )}
                        {all_approved_user?.enabled && !all_approved_user?.passkey && (
                            <img
                                src={Ploygon}
                                alt="Ploygon"
                                style={{ position: 'absolute', top: '9%', right: '1%', width: '50px' }}
                            />
                        )}
                    </Box>
                }
            />
            <Stack px={{ flex: 1 }}>{children}</Stack>
        </Container>
    );
};

export default ComplianceLayout;
