import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';

// core components
import ComplianceLayoutNew from '../common/NewComplianceLayout';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import Documents from './Documents';
import AuditQuestions from './AuditQuestions';
import Exceptions from './Exceptions';
import PageHeader from 'components/new_components/PageHeader';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { GetAllFormsByTags, GetAllDocumentsByTags } from 'store/actions/adminActions';
import {
    GetAllMerchantDocuments,
    GetAllMerchantAnswers,
    GetAllExemptedControls,
} from 'store/actions/complianceActions';
import { GetModuleGuides, UpdateModuleGuide, CreateModuleGuide } from 'store/actions/generalActions';
import { GetCompanyDetails } from 'store/actions/merchantActions';
import TaskIntroductionModal from '../common/TaskIntroductionModal';
import AssignTaskModal from '../common/AssignTaskModalNew';
import AllTaskModal from '../common/AllTaskModal';
import PolicyApprovalModal from '../common/PolicyApprovalModal';
import DataRoomModal from '../common/DataRoomModal';

//Userguide
import { Tour } from 'antd';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';
import { GetUserDetails } from 'store/actions/generalActions';
import { GetEvidenceMetrics } from 'store/actions/complianceActions';
import { GetAllMerchantEvidences } from 'store/actions/complianceActions';
import { GetAllTasksByStandard } from 'store/actions/taskActions';
import { GetAllQualityManagement } from 'store/actions/iso9001';
import { GetQualityManagementMetrics } from 'store/actions/iso9001';

const ISO9001 = () => {
    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [loading, setLoading] = useState({ controls: false });
    const [introModalOpen, setIntroModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [tasksModalOpen, setTaskModalOpen] = useState(false);
    const [policyModalOpen, setPolicyModalOpen] = useState(false);
    const [dataRoomModalOpen, setDataRoomModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [taskInfo, setTaskInfo] = useState({});
    const [view, setView] = useState(0);
    // const [openAiModal, setOpenAiModal] = useState(false);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    // redux
    const dispatch = useDispatch();
    const all_quality_management = useSelector((state) => state?.ISO9001Reducers?.all_quality_management);
    const all_quality_management_summary = useSelector(
        (state) => state?.ISO9001Reducers?.all_quality_management_summary
    );
    const documents_by_control = useSelector((state) => state?.iso9001Reducers?.documents_by_control);
    const evidences_metrics = useSelector((state) => state?.complianceReducers?.all_evidences_metrics);
    const guideModal = useSelector((state) => state?.generalReducers?.module);
    const company_details = useSelector((state) => state?.authReducers?.company_details);
    const userguide_info = useSelector((state) => state?.guideReducers?.userguide_info?.[0]?.compliance);
    const all_article_header = useSelector((state) => state?.articlesReducers?.all_article_header);
    const all_merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);
    const user_id = useSelector((state) => state?.generalReducers?.user_info?.employee?.id);
    const all_assigned_task = useSelector((state) => state?.tasksReducers?.all_assigned_tasks_by_standard);

    const standards = company_details?.standards?.split(',');
    //userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);

    const STEPS_LENGTH = 3;

    const steps = [
        {
            title: <UserGuideHeader title="Documents" id={1} length={STEPS_LENGTH} />,
            description: 'Here you can Upload compliance doc, evidences and policies.',
            target: () => ref1.current,
            placement: 'rightTop',
        },
        {
            title: <UserGuideHeader title="Audit Questions" id={2} length={STEPS_LENGTH} />,
            description: 'Respond to audit-related queries here.',
            target: () => ref2.current,
            placement: 'rightTop',
        },
        {
            title: <UserGuideHeader title="Exceptions" id={3} length={STEPS_LENGTH} />,
            description: 'This tab displays questions and documents that are exempted',
            target: () => ref3.current,
            placement: 'rightTop',
        },
    ];

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Compliance'),
        [all_article_header]
    );

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ compliance: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, compliance: false });
        setOpenArticle(false);
    };

    // functions
    // logic functions
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };
    // const handleOpenAiModal = () => {
    //     setOpenAiModal(true);
    // };
    // const handleCloseAiModal = () => {
    //     setOpenAiModal(false);
    // };

    //task introduction/explanation modal
    const closeIntroModal = () => {
        setIntroModalOpen(false);
    };
    // async functions
    const getAllTasksByStandard = async () => {
        const res = await dispatch(GetAllTasksByStandard('iso9001', user_id));
        if (!res.message) {
            toast.error(res.message);
        }
    };

    const openTaskAssignModal = (type, info) => {
        setModalOpen(true);
        setModalType(type);
        setTaskInfo(info);
    };
    const closeTaskAssignModal = () => {
        setModalOpen(false);
        setTimeout(() => setModalType(null), 500);
        setTaskInfo({});
    };

    const openTasksModal = () => {
        setTaskModalOpen(true);
    };
    const closeTasksModal = () => {
        setTaskModalOpen(false);
    };

    const openPolicyModal = () => {
        setPolicyModalOpen(true);
    };
    const closePolicyModal = () => {
        setPolicyModalOpen(false);
    };

    const openDataRoomModal = () => {
        setDataRoomModalOpen(true);
    };
    const closeDataRoomModal = () => {
        setDataRoomModalOpen(false);
    };
    // async functions
    // get all the controls
    const getAllQualityManagement = async () => {
        setLoading((current) => ({
            ...current,
            controls: !all_quality_management?.length,
        }));
        const res = await dispatch(GetAllQualityManagement());
        setLoading((current) => ({ ...current, controls: false }));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch requirements.");
        }
    };
    // get all requirement metric for this merchant
    const getControlMetrics = async () => {
        setLoading((current) => ({
            ...current,
            controls: !all_quality_management_summary?.length,
        }));
        const res = await dispatch(GetQualityManagementMetrics());
        setLoading((current) => ({ ...current, controls: false }));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch requirements.");
        }
    };

    const getEvidenceMetrics = async () => {
        setLoading((current) => ({
            ...current,
            controls: !all_quality_management_summary?.length,
        }));
        const res = await dispatch(GetEvidenceMetrics('iso9001'));
        setLoading((current) => ({ ...current, controls: false }));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch requirements.");
        }
    };
    const getAllMerchantDocuments = async () => {
        const res = await dispatch(GetAllMerchantDocuments());
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch your documents.");
        }
    };

    const getAllMerchantEvidences = async () => {
        const res = await dispatch(GetAllMerchantEvidences());
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch your documents.");
        }
    };
    const getMerchantAnswers = async () => {
        const res = await dispatch(GetAllMerchantAnswers());
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch previous answers.");
        }
    };
    //get all exempted controls
    const getAllExemptedControls = async () => {
        const res = await dispatch(GetAllExemptedControls());
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exempted controls.");
        }
    };
    const getUserDetails = async () => {
        const res = await dispatch(GetUserDetails());
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch user details");
        }
    };

    const getCompanyDetails = async () => {
        const res = await dispatch(GetCompanyDetails());
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch company details");
        }
    };

    const getModuleGuides = async () => {
        const res = await dispatch(GetModuleGuides());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllFormsByTags = async () => {
        const res = await dispatch(GetAllFormsByTags('iso9001'));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllDocumentByTags = async () => {
        const res = await dispatch(GetAllDocumentsByTags('iso9001'));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const updateModuleIntroModal = async () => {
        const res =
            guideModal && guideModal[0]
                ? await UpdateModuleGuide({ compliance_task: true }, guideModal[0]?.id)
                : await CreateModuleGuide({ compliance_task: true });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        getAllQualityManagement();
        getControlMetrics();
        getMerchantAnswers();
        getAllMerchantDocuments();
        getAllExemptedControls();
        getCompanyDetails();
        getUserDetails();
        getModuleGuides();
        getAllFormsByTags();
        getAllDocumentByTags();
        getAllMerchantEvidences();
        getEvidenceMetrics();
        getAllTasksByStandard();
    }, [currentTab]);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.compliance ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.compliance]);

    const filter_documents_by_approved = all_merchant_documents?.filter(
        (doc) => doc?.tag === 'iso9001' && doc?.approved_status === 'pending' && doc?.approved_by === user_id
    );
    return (
        <>
            <PageHeader browserTitle="ISO 9001 - Compliance | Smartcomply" />
            <Box sx={{ px: { xs: 0, md: 0.5 }, py: { xs: 0, md: 3 } }}>
                <ComplianceLayoutNew
                    onTabChange={handleTabChange}
                    tab={currentTab}
                    openTasksModal={openTasksModal}
                    openPolicyModal={openPolicyModal}
                    openDataRoomModal={openDataRoomModal}
                    standard="iso9001"
                    standards={standards}
                    view={view}
                    ref1={ref1}
                    ref2={ref2}
                    ref3={ref3}
                    newPolicy={filter_documents_by_approved?.length}
                    newTask={all_assigned_task?.length}
                >
                    <AppTabPanel value={currentTab} index={0}>
                        <Documents
                            loading={loading.controls}
                            getControls={getAllQualityManagement}
                            openTaskAssignModal={openTaskAssignModal}
                            all_metrics={all_quality_management_summary}
                            view={view}
                            setView={setView}
                        />
                    </AppTabPanel>
                    <AppTabPanel value={currentTab} index={1}>
                        <AuditQuestions
                            loading={loading.controls}
                            getControls={getAllQualityManagement}
                            openTaskAssignModal={openTaskAssignModal}
                            view={view}
                            setView={setView}
                        />
                    </AppTabPanel>
                    <AppTabPanel value={currentTab} index={2}>
                        <Exceptions getControls={getAllQualityManagement} />
                    </AppTabPanel>
                </ComplianceLayoutNew>
                {/* <ComplianceAITipsModal open={openAiModal} handleClose={handleCloseAiModal} width="700px" minWidth={900} /> */}
            </Box>
            <TaskIntroductionModal
                open={introModalOpen}
                handleClose={closeIntroModal}
                updateModuleIntroModal={updateModuleIntroModal}
            />

            <AssignTaskModal open={modalOpen} onClose={closeTaskAssignModal} type={modalType} taskInfo={taskInfo} />
            <AllTaskModal open={tasksModalOpen} handleClose={closeTasksModal} standard="iso9001" />
            <PolicyApprovalModal open={policyModalOpen} handleClose={closePolicyModal} standard="iso9001" />
            <DataRoomModal
                open={dataRoomModalOpen}
                handleClose={closeDataRoomModal}
                standard="iso9001"
                all_requirements={all_quality_management}
                all_metrics={all_quality_management_summary}
                all_evidences_metrics={evidences_metrics}
                documents_by_control={documents_by_control}
            />

            {!loading.controls && <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />}
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Compliance"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};

export default ISO9001;
