import React, { useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Input, Modal, Row } from 'antd';

// redux
import { connect } from 'react-redux';
import { UpdateTestimonial, AddTestimonial } from 'store/actions/adminActions';
import { toast } from 'react-toastify';

const TestimonialModal = (props) => {
    const { open, modalData, onClose, UpdateTestimonial, AddTestimonial } = props;

    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState({ person_image: '', company_logo: '' });

    // functions
    const handleClose = () => {
        onClose();
        setData({});
        setImages({});
    };
    const handleTextChange = (e) => {
        const { value, name } = e.target;
        setData({ ...data, [name]: value });
    };
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setData({ ...data, [name]: files[0] });
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = function () {
            setImages({ ...images, [name]: reader.result });
        };
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = new FormData();

        for (let field in data) {
            if (field === 'person_image' || field === 'company_logo') {
                if (typeof data[field] !== 'string') {
                    form.append(field, data[field]);
                }
            } else {
                form.append(field, data[field]);
            }
        }

        setLoading(true);
        const res = open === 'Add' ? await AddTestimonial(form) : await UpdateTestimonial(form, data?.id);
        setLoading(false);
        if (res?.success) {
            toast.success('Item added.');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (open) {
            setData(modalData);
            setImages({ person_image: modalData.person_image, company_logo: modalData?.company_logo });
        }
    }, [open]);

    return (
        <Modal title={`${open} Testimonial`} open={Boolean(open)} onCancel={handleClose} destroyOnClose footer={null}>
            <form onSubmit={handleSubmit}>
                <Row justify="center" gutter={10}>
                    <Col>
                        <Avatar shape="square" size={70} src={images.person_image} />
                    </Col>
                    <Col>
                        <Avatar shape="square" size={70} src={images.company_logo} />
                    </Col>
                </Row>
                <Row justify="end">
                    <Col span={24}>
                        <div className="form-group">
                            <label htmlFor="full_name">Full Name</label>
                            <Input
                                size="large"
                                name="full_name"
                                id="full_name"
                                onChange={handleTextChange}
                                value={data?.full_name}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="form-group">
                            <label htmlFor="company_name">Company Name</label>
                            <Input
                                size="large"
                                name="company_name"
                                id="company_name"
                                onChange={handleTextChange}
                                value={data?.company_name}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={12}>
                                <div className="form-group">
                                    <Button icon={<UploadOutlined />}>
                                        <span>Upload Avatar</span>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            id="person_image"
                                            name="person_image"
                                            className="w-full absolute top-0 left-0 opacity-0"
                                            onChange={handleFileChange}
                                        />
                                    </Button>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="form-group">
                                    <Button icon={<UploadOutlined />}>Upload Logo</Button>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        id="company_logo"
                                        name="company_logo"
                                        className="w-full absolute top-0 left-0 opacity-0"
                                        onChange={handleFileChange}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <div className="form-group">
                            <label htmlFor="role">Role</label>
                            <Input
                                size="large"
                                name="role"
                                id="role"
                                onChange={handleTextChange}
                                value={data?.role}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="form-group">
                            <label htmlFor="testimonial">Testimonial</label>
                            <Input.TextArea
                                size="large"
                                name="testimonial"
                                id="testimonial"
                                onChange={handleTextChange}
                                value={data?.testimonial}
                                required
                                rows={4}
                            />
                        </div>
                    </Col>
                    <Col className="mt-4">
                        <Button htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = (state) => {
    return { ...state };
};
export default connect(mapStateToProps, { AddTestimonial, UpdateTestimonial })(TestimonialModal);
