import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button, Select } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { AddBulkThreats } from 'store/actions/adminActions';

const ThreatModal = (props) => {
    const [loading, setloading] = useState(false);
    const [data, setData] = useState([
        {
            name: '',
            threat_category: '',
            tag: 'admin',
        },
    ]);
    const { open, handleClose } = props;

    // redux
    const dispatch = useDispatch();
    const all_threat_categories = useSelector((state) => state.adminReducers.all_threat_categories);

    // const handleTextChange = (e) => {
    //     const { name, value } = e.target;

    //     setData({ ...data, [name]: value });
    // };

    const onClose = () => {
        setData([
            {
                name: '',
                threat_category: '',
                tag: 'admin',
            },
        ]);
        handleClose();
    };
    const find_empty_threat = data?.filter((item) => item?.name === '' || item?.threat_category === '');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (find_empty_threat?.length) {
            toast.info('Fill all fields!');
        } else {
            setloading(true);
            const threats = data?.map((item) => {
                const risk_category = all_threat_categories?.find((category) => category?.id === item?.threat_category);
                return {
                    name: item?.name,
                    threat_category: item?.threat_category,
                    tag: item?.tag,
                    risk_category: risk_category?.risk_category,
                };
            });
            const payload = {
                threats,
            };

            const res = await dispatch(AddBulkThreats(payload));
            setloading(false);
            if (res?.success) {
                toast.success('Threats Added Successfully!');
                onClose();
            } else {
                toast.error(res?.message);
            }
        }
    };

    const onSelect = (value, index) => {
        const newItems = data || [];
        newItems[index] = { ...newItems?.[index], threat_category: value };
        newItems[index] = {
            ...newItems?.[index],
        };
        setData([...newItems]);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };

    const handleItemInputChange = (e, index) => {
        const { name, value } = e.target;
        const newItems = data || [];
        newItems[index] = { ...newItems?.[index], [name]: value };
        newItems[index] = {
            ...newItems?.[index],
        };

        setData([...newItems]);
    };

    //handle add and remove assets
    const handleAddItem = () => {
        const newItem = {
            name: '',
            threat_category: '',
            tag: 'admin',
        };
        const dataCopy = [...data];
        dataCopy.push(newItem);
        setData(dataCopy);
    };

    const removeItem = (index) => {
        const newData = [...data];
        newData?.splice(index, 1);
        setData(newData);
    };
    return (
        <Modal title="Add Threat" open={open} onCancel={onClose} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                {data?.map((item, index) => (
                    <Row justify="end" key={index}>
                        <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="name">Threat</label>

                            <Input
                                type="text"
                                size="large"
                                name="name"
                                id="name"
                                value={item.name || ''}
                                onChange={(e) => handleItemInputChange(e, index)}
                                required
                            />
                        </Col>
                        <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="plan">Choose Threat Category</label>
                            <Select
                                showSearch
                                size="large"
                                placeholder="Select Threat Castegory"
                                optionFilterProp="children"
                                value={item?.threat_category}
                                onChange={(value) => onSelect(value, index)}
                                onSearch={onSearch}
                                style={{ width: '100%' }}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={all_threat_categories?.map((category) => {
                                    return {
                                        value: category?.id,
                                        label: category?.name,
                                    };
                                })}
                            />
                        </Col>

                        <Button
                            type="primary"
                            danger
                            shape="circle"
                            onClick={() => removeItem(index)}
                            icon={<DeleteOutlined />}
                            disabled={data?.length <= 1}
                        />
                    </Row>
                ))}
                <div className="flex flex-row justify-end my-2 gap-2">
                    <Button icon={<PlusCircleOutlined />} onClick={handleAddItem} />
                </div>
                <Col style={{ marginTop: '5rem' }}>
                    <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                        Submit
                    </Button>
                </Col>
            </form>
        </Modal>
    );
};

export default ThreatModal;
