import React from 'react';

// mui components
import { Box, Button, ButtonGroup, Typography } from '@mui/material';

// core component
// import FieldItem from './FieldItem';
import fileQuestion from 'assets/img/file-question.svg';
import AppInput from 'components/new_components/AppInput';
import { FileUpload, CheckCircle } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import FileTypes from 'assets/img/Filetypes.svg';
import Docx from 'assets/img/docx.svg';
import Png from 'assets/img/png.svg';
import Xsl from 'assets/img/xsl.svg';
import Img from 'assets/img/img.svg';
import CloseIcon from '@mui/icons-material/Close';

//translation
import { useTranslation } from 'react-i18next';

const Questionnaire = (props) => {
    // props
    const { questionnaire, responses, setResponses, readonly, handleFileUpload, uploading, files, setFiles } = props;

    //translation
    const { t } = useTranslation('vendorManagement');
    return (
        <Box
            sx={{
                alignItems: 'flex-start',
                alignContent: 'flex-start',
                paddingBottom: '2rem',
            }}
        >
            <Box sx={{ p: '15px 30px' }}>
                {questionnaire?.form_fields
                    ?.sort((a, b) => a?.id - b?.id)
                    ?.map((field) => {
                        const standard_options = [...field.options.split(','), 'NOT APPLICABLE'];
                        return (
                            <Box
                                key={field.id}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'start',
                                    mb: 3,
                                    pt: 2,
                                    borderTop: '1px solid #F1F5F9',
                                }}
                            >
                                <img src={fileQuestion} alt="fileQuestion" className="mr-2" />
                                <Box>
                                    <Typography sx={{ color: '#64748B', fontSize: '14px', fontWeight: 400 }}>
                                        {field?.name}
                                    </Typography>
                                    <Box sx={{ mt: 1 }}>
                                        {field?.type === 'dropdown' || field?.type === 'multi-field' ? (
                                            <Box>
                                                <ButtonGroup>
                                                    {(field?.name.includes(
                                                        '10. If your answer is Yes, kindly select the standard(s)'
                                                    )
                                                        ? standard_options
                                                        : field?.options?.split(',')
                                                    )?.map((option) => (
                                                        <Button
                                                            sx={{
                                                                backgroundColor:
                                                                    responses?.answer &&
                                                                    responses?.answer?.[field?.id]?.[0] ===
                                                                        option.replace(' ', '')
                                                                        ? '#395BA9'
                                                                        : responses?.answer?.[field?.id]?.[0] ===
                                                                          option.replace('  ', '')
                                                                        ? '#395BA9'
                                                                        : responses?.answer?.[field?.id]?.[0] ===
                                                                          option.replace('   ', '')
                                                                        ? '#395BA9'
                                                                        : responses?.answer?.[field?.id]?.includes(
                                                                              option
                                                                          )
                                                                        ? '#395BA9'
                                                                        : '',
                                                                color:
                                                                    responses?.answer &&
                                                                    responses?.answer?.[field?.id]?.[0] ===
                                                                        option.replace(' ', '')
                                                                        ? '#FFFFFF'
                                                                        : responses?.answer?.[field?.id]?.[0] ===
                                                                          option.replace('  ', '')
                                                                        ? '#FFFFFF'
                                                                        : responses?.answer?.[field?.id]?.[0] ===
                                                                          option.replace('   ', '')
                                                                        ? '#FFFFFF'
                                                                        : responses?.answer?.[field?.id]?.includes(
                                                                              option
                                                                          )
                                                                        ? '#FFFFFF'
                                                                        : '',
                                                                '&:hover': {
                                                                    backgroundColor:
                                                                        responses?.answer &&
                                                                        responses?.answer?.[field?.id]?.[0] ===
                                                                            option.replace(' ', '')
                                                                            ? '#395BA9'
                                                                            : responses?.answer?.[field?.id]?.[0] ===
                                                                              option.replace('  ', '')
                                                                            ? '#395BA9'
                                                                            : responses?.answer?.[field?.id]?.[0] ===
                                                                              option.replace('   ', '')
                                                                            ? '#395BA9'
                                                                            : '',
                                                                    color:
                                                                        responses?.answer &&
                                                                        responses?.answer?.[field?.id]?.[0] ===
                                                                            option.replace(' ', '')
                                                                            ? '#FFFFFF'
                                                                            : responses?.answer?.[field?.id]?.[0] ===
                                                                              option.replace('  ', '')
                                                                            ? '#FFFFFF'
                                                                            : responses?.answer?.[field?.id]?.[0] ===
                                                                              option.replace('   ', '')
                                                                            ? '#FFFFFF'
                                                                            : '',
                                                                },
                                                            }}
                                                            key={option}
                                                            onClick={() => {
                                                                const previous_answer = responses?.answer?.[field?.id];
                                                                const removed_option = responses?.answer?.[
                                                                    field?.id
                                                                ]?.filter((item) => item !== option);
                                                                setResponses({
                                                                    answer: {
                                                                        ...responses?.answer,
                                                                        [field?.id]:
                                                                            field?.type === 'multi-field'
                                                                                ? previous_answer
                                                                                    ? [
                                                                                          ...removed_option,
                                                                                          previous_answer?.includes(
                                                                                              option
                                                                                          )
                                                                                              ? ''
                                                                                              : option,
                                                                                      ]
                                                                                    : [option]
                                                                                : [option],
                                                                    },
                                                                });
                                                            }}
                                                        >
                                                            {option.replace('  ', '')}
                                                        </Button>
                                                    ))}
                                                </ButtonGroup>
                                                {responses?.answer &&
                                                    responses?.answer?.[field?.id]?.filter(
                                                        (item) => item !== null
                                                    )?.[0] === 'Yes' &&
                                                    field?.upload_document === true && (
                                                        <Box>
                                                            {responses?.answer &&
                                                            responses?.answer?.[field?.id]?.filter(
                                                                (item) => item !== null
                                                            )?.[1] ? (
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        gap: 2,
                                                                        alignItems: 'center',
                                                                        mt: 2,
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                files?.[field?.id]?.split('.').pop() ===
                                                                                'xlsx'
                                                                                    ? Xsl
                                                                                    : files?.[field?.id]
                                                                                          ?.split('.')
                                                                                          .pop() === 'docx'
                                                                                    ? Docx
                                                                                    : files?.[field?.id]
                                                                                          ?.split('.')
                                                                                          .pop() === 'png'
                                                                                    ? Png
                                                                                    : files?.[field?.id]
                                                                                          ?.split('.')
                                                                                          .pop() === 'img'
                                                                                    ? Img
                                                                                    : files?.[field?.id]
                                                                                          ?.split('.')
                                                                                          .pop() === 'doc'
                                                                                    ? Docx
                                                                                    : files?.[field?.id]
                                                                                          ?.split('.')
                                                                                          .pop() === 'xls'
                                                                                    ? Xsl
                                                                                    : FileTypes
                                                                            }
                                                                            alt="PdfIcon"
                                                                        />
                                                                        <Box sx={{ ml: 1 }}>
                                                                            <Typography
                                                                                sx={{
                                                                                    color: '#475569',
                                                                                    fontWeight: 400,
                                                                                    fontSize: '14px',
                                                                                }}
                                                                            >
                                                                                {files[field?.id]?.split('/')?.pop()}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    <CloseIcon
                                                                        sx={{ cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            const filtered_array = responses?.answer?.[
                                                                                field?.id
                                                                            ]?.filter((item) => item === 'Yes');
                                                                            setResponses({
                                                                                answer: {
                                                                                    ...responses?.answer,
                                                                                    [field?.id]: filtered_array,
                                                                                },
                                                                            });
                                                                        }}
                                                                    />
                                                                </Box>
                                                            ) : (
                                                                <>
                                                                    <label htmlFor={field?.id}>
                                                                        <input
                                                                            id={field?.id}
                                                                            name={field?.name}
                                                                            type="file"
                                                                            style={{ display: 'none' }}
                                                                            onChange={(e) =>
                                                                                handleFileUpload(
                                                                                    e.target.files[0],
                                                                                    field.id
                                                                                )
                                                                            }
                                                                        />
                                                                        <LoadingButton
                                                                            component="span"
                                                                            loading={uploading[field?.id]}
                                                                            style={{
                                                                                padding: '.6rem',
                                                                                marginTop: '1rem',
                                                                            }}
                                                                            startIcon={
                                                                                files?.[field?.name] ? (
                                                                                    <CheckCircle color="success" />
                                                                                ) : (
                                                                                    <FileUpload />
                                                                                )
                                                                            }
                                                                            loadingPosition="start"
                                                                            variant="outlined"
                                                                            sx={{
                                                                                width: '200px',
                                                                                borderRadius: '10px',
                                                                                mb: 0,
                                                                                textTransform: 'capitalize',
                                                                            }}
                                                                            // onChange={(e) =>
                                                                            //     handleFileUpload(e.target.files[0], field?.id)
                                                                            // }
                                                                        >
                                                                            Upload Evidence
                                                                        </LoadingButton>
                                                                        <Typography
                                                                            sx={{
                                                                                fontWeight: 500,
                                                                                fontSize: '14px',
                                                                                color: '#0E2C66',
                                                                                // mt: '15px',
                                                                            }}
                                                                        >
                                                                            {files?.[field?.name]}
                                                                        </Typography>
                                                                    </label>
                                                                </>
                                                            )}
                                                        </Box>
                                                    )}
                                            </Box>
                                        ) : field?.type === 'file' ? (
                                            <>
                                                {files?.[field?.id] ? (
                                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    files?.[field?.id]?.split('.').pop() === 'xlsx'
                                                                        ? Xsl
                                                                        : files?.[field?.id]?.split('.').pop() ===
                                                                          'docx'
                                                                        ? Docx
                                                                        : files?.[field?.id]?.split('.').pop() === 'png'
                                                                        ? Png
                                                                        : files?.[field?.id]?.split('.').pop() === 'img'
                                                                        ? Img
                                                                        : FileTypes
                                                                }
                                                                alt="PdfIcon"
                                                            />
                                                            <Box sx={{ ml: 1 }}>
                                                                <Typography
                                                                    sx={{
                                                                        color: '#475569',
                                                                        fontWeight: 400,
                                                                        fontSize: '14px',
                                                                    }}
                                                                >
                                                                    {files[field?.id]?.split('/')?.pop()}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <CloseIcon
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => setFiles({})}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <>
                                                        <label htmlFor={field?.id}>
                                                            <input
                                                                id={field?.id}
                                                                name={field?.name}
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                onChange={(e) =>
                                                                    handleFileUpload(e.target.files[0], field.id)
                                                                }
                                                            />
                                                            <LoadingButton
                                                                component="span"
                                                                loading={uploading[field?.id]}
                                                                style={{ padding: '.6rem', marginTop: '1rem' }}
                                                                startIcon={
                                                                    files?.[field?.name] ? (
                                                                        <CheckCircle color="success" />
                                                                    ) : (
                                                                        <FileUpload />
                                                                    )
                                                                }
                                                                loadingPosition="start"
                                                                variant="outlined"
                                                                sx={{ width: '200px', borderRadius: '10px' }}
                                                                onChange={(e) =>
                                                                    handleFileUpload(e.target.files[0], field?.name)
                                                                }
                                                            >
                                                                {files?.[field?.name]
                                                                    ? t('vendorQuestionnaire.uploaded')
                                                                    : t('vendorQuestionnaire.uploadDocument')}
                                                            </LoadingButton>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: 500,
                                                                    fontSize: '14px',
                                                                    color: '#0E2C66',
                                                                    mt: '15px',
                                                                }}
                                                            >
                                                                {files?.[field?.name]}
                                                            </Typography>
                                                        </label>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <AppInput
                                                name={field?.name}
                                                disabled={readonly}
                                                type={field.type}
                                                placeholder={field?.name}
                                                onChange={(event) => {
                                                    setResponses({
                                                        answer: {
                                                            ...responses?.answer,
                                                            [field?.id]: event.target.value,
                                                        },
                                                    });
                                                }}
                                                labelStyle={{
                                                    fontWeight: '500 !important',
                                                    fontSize: '16px !important',
                                                    color: '#46464A !important',
                                                }}
                                                sx={{
                                                    width: '500px',
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        );
                    })}
                {/* {questionnaire?.form_fields
                    ?.sort((a, b) => a?.id - b?.id)
                    ?.map((field) => {
                        return (
                            <Box key={field.id}>
                                <FieldItem
                                    type={field?.type}
                                    required={field?.required}
                                    name={field.id?.toString()}
                                    options={field?.options}
                                    label={
                                        language?.includes('fr')
                                            ? field.name_fr
                                            : language?.includes('en')
                                            ? field.name_en
                                            : field.name
                                    }
                                    disabled={readonly}
                                    uploading={uploading[field?.id]}
                                    handleFileUpload={handleFileUpload}
                                    files={files}
                                />
                            </Box>
                        );
                    })} */}
            </Box>
        </Box>
    );
};

export default Questionnaire;
