import React from 'react';
import WalletIcon from '../../../../assets/img/icons/wallet-02.svg';
import { Box, Typography } from '@mui/material';

const Card = (props) => {
    const { title, value, sx } = props;
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'top',
                backgroundColor: '#FFFFFF',
                width: '100%',
                p: 3,
                borderRadius: 2,
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#EFF4FB',
                    p: 2,
                    borderRadius: '30px',
                    height: '55px',
                }}
            >
                <img src={WalletIcon} alt="icon" />
            </Box>
            <Box sx={{ ml: 2 }}>
                <Typography sx={{ color: '#707EAE', fontSize: '15px', fontWeight: 600 }}>{title}</Typography>
                <Typography sx={{ color: '#2B3674', fontSize: '20px', fontWeight: 600 }}>{value || 0}</Typography>
            </Box>
        </Box>
    );
};

export default Card;
