import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

const AppOptionChip = (props) => {
    const { option, width, setStatus, setAnswer, answer, item } = props;
    const theme = useTheme();
    const handleSelected = (option) => {
        setStatus(true);
        if (item?.ans_type === 'single') {
            setAnswer([option?.value]);
        } else {
            if (answer?.includes(option?.value)) {
                const clonedAnswer = [...answer];
                clonedAnswer.splice(clonedAnswer.indexOf(option.value), 1);
                setAnswer(clonedAnswer);
            } else {
                setAnswer([...answer, option?.value]);
            }
        }
    };
    return (
        <Box
            className="survey_item"
            sx={{
                width: width,
                border: answer?.includes(option?.value)
                    ? `2px solid ${theme.palette.primary[900]}`
                    : `1px solid ${theme.palette.primary[900]}`,
                borderRadius: '15px',
                display: 'flex',
                alignItems: 'center',
                padding: '1rem 1.5rem',
                cursor: 'pointer',
            }}
            onClick={() => handleSelected(option)}
        >
            <Typography
                sx={{
                    border: `1px solid ${theme.palette.primary[900]}`,
                    borderRadius: '50%',
                    padding: '0.1rem 0.5rem',
                    color: answer?.includes(option?.value) ? '#ffffff' : theme.palette.primary[900],
                    background: answer?.includes(option?.value) ? '#0e2c66' : '',
                }}
                id="survey-item-slide"
            >
                {option.tag}
            </Typography>
            <Typography
                id="survey-item-slide"
                sx={{ marginLeft: 1.5, color: theme.palette.primary[900], fontWeight: 600, fontSize: '14px' }}
            >
                {option.value}
            </Typography>
        </Box>
    );
};

export default AppOptionChip;
