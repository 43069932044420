import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import AppButton from 'components/new_components/AppButton';

import AppModal from 'components/new_components/AppModal';
import { AppForm, AppFormInput, AppSubmitButton } from 'components/new_components/forms';
import { accept } from 'validate';

// Redux
import { ExemptBlockchainCompliance, ExemptCiiCompliance, AllCiiExceptions } from 'store/actions/complianceActions';
import { ExceptionValidation } from '../validation';
import { connect } from 'react-redux';

//translation
import { useTranslation } from 'react-i18next';

const ExemptionModal = (props) => {
    const theme = useTheme();
    const {
        open,
        handleClose,
        payload,
        ExemptBlockchainCompliance,
        onResourceChange,
        standard,
        ExemptCiiCompliance,
        AllCiiExceptions,
    } = props;
    const [loading, setLoading] = useState({ upload: false, exempt: false });
    const [reason_document, setreason_document] = useState('');
    const [img, setImg] = useState('');
    const { pdf, docx, excel, txt, ppt } = accept;

    //translation
    const { t } = useTranslation('compliance');

    // UPLOAD IMAGE ONCHANGE
    const uploadImage = async (event) => {
        const input = event.target;
        const file = input.files?.item(0);
        const fileSize = file.size / 1000 / 1000;
        if (fileSize > 2) {
            setreason_document('');
        } else {
            const base64 = await convertedBase64(file);
            setreason_document(file);
            setImg(base64);
        }
    };

    // CONVERT IMAGE TO BASE 64
    const convertedBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const filReader = new FileReader();
            filReader.readAsDataURL(file);
            filReader.onload = () => {
                resolve(filReader.result);
            };
            filReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const dragEnter = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#C4C4C4';
        target.style.backgroundColor = '#A58980';
    };

    const dragLeave = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#A58980';
        target.style.backgroundColor = 'transparent';
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const dragDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type.includes('image')) {
            const reader = new FileReader();
            reader.onload = () => {
                setImg(reader.result);
            };
            reader.readAsDataURL(file);
            convertedBase64(file).then(() => {});
        } else {
            // enqueueSnackbar('Invalid Data Dropped', { variant: 'error' });
        }
        dragLeave();
    };

    const handleCancel = () => {
        setImg('');
    };

    const getAllExceptions = async () => {
        const res = await AllCiiExceptions();
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch Criterias.");
            console.log(res.message);
        }
    };
    const handleSubmit = async (values) => {
        const formdata = new FormData();
        if (standard !== 'cii') {
            formdata.append('criteria', values?.criteria);
        }
        if (standard === 'cii') {
            formdata.append('cii_sector', values?.cii_sector);
        }
        if (values?.form) {
            formdata.append('form', values?.form);
        }
        formdata.append('reason', values?.reason);
        if (values?.document) {
            formdata.append('document', values?.document);
        }
        if (values?.conditional && standard !== 'cii') {
            formdata.append('conditional', values?.conditional);
        }
        if (values?.cii_conditional && standard === 'cii') {
            formdata.append('cii_conditional', values?.cii_conditional);
        }
        if (values?.tool) {
            formdata.append('tool', values?.tool);
        }
        if (values?.module) {
            formdata.append('module', values?.module);
        }

        if (standard === 'cii' && values?.cii_subsector) {
            formdata.append('cii_subsector', values?.cii_subsector);
        }
        if (standard !== 'cii') {
            formdata.append('subcriteria', values?.subcriteria);
        }
        formdata.append('reason_document', reason_document), setLoading({ ...loading, exempt: true });
        const res =
            standard === 'cii' ? await ExemptCiiCompliance(formdata) : await ExemptBlockchainCompliance(formdata);
        setLoading({ ...loading, exempt: false });
        if (res?.success) {
            toast.success(res?.message);
            handleClose();
            getAllExceptions();
            if (onResourceChange()) {
                onResourceChange();
            }
        } else {
            toast.error(res?.message);
        }
    };
    const payloads_ = {
        ...payload,
    };
    return (
        <AppForm initialValues={payloads_} onSubmit={handleSubmit} validate={ExceptionValidation}>
            <AppModal
                open={open}
                handleClose={handleClose}
                title={t('exemptReq')}
                sub_title={t('byExempting')}
                width="900px"
                height="800"
                maxHeight="800"
                top={50}
                blockchain
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            margin: '2rem 0 15px 0',
                        }}
                    >
                        <AppButton color="primary" variant="outlined" name={t('cancel')} onClick={handleClose} />
                        <AppSubmitButton
                            text={t('exempt')}
                            variant="contained"
                            color="error"
                            loading={loading?.exempt}
                            loadingPosition="center"
                            sx={{ marginLeft: 3 }}
                        />
                    </Box>
                }
            >
                <AppFormInput
                    type="text"
                    label={<Typography sx={{ color: '#000000' }}>{t('reason')}</Typography>}
                    name="reason"
                    placeholder={t('explain')}
                />
                <Box sx={{ marginTop: 4 }}>
                    <Typography sx={{ color: '#000000', marginBottom: 1 }}>{t('evidence')}</Typography>
                    <Box
                        sx={{
                            border: `3px dashed ${theme.palette.primary[900]}`,
                            borderRadius: 2,
                            padding: 2,
                            minHeight: 250,
                            position: 'relative',
                        }}
                    >
                        <Box
                            id="drop-zone"
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={dragDrop}
                            onDragOver={dragOver}
                        >
                            {img !== '' ? (
                                <Box
                                    sx={{
                                        width: '80px',
                                        border: `1px solid ${theme.palette.primary[900]}`,
                                        borderRadius: 2,
                                        backgroundImage: `url(${img})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'top center',
                                        height: '80px',
                                        position: 'relative',
                                    }}
                                >
                                    <CloseIcon
                                        onClick={handleCancel}
                                        sx={{
                                            position: 'absolute',
                                            top: '-2px',
                                            right: '-8px',
                                            background: '#858999',
                                            borderRadius: 2,
                                            width: 14,
                                            height: 14,
                                            cursor: 'pointer',
                                        }}
                                    />
                                </Box>
                            ) : (
                                ''
                            )}
                            <Box sx={{ position: 'absolute', bottom: 15, left: '35%', textAlign: 'center' }}>
                                <label htmlFor="file-input">
                                    <Box
                                        sx={{
                                            border: `2px solid ${theme.palette.primary[900]}`,
                                            borderRadius: 2,
                                            padding: 1,
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Typography
                                            sx={{ color: theme.palette.primary[900], fontWeight: 600, fontSize: 16 }}
                                        >
                                            {t('upload')}
                                        </Typography>
                                    </Box>
                                </label>
                                <input
                                    type="file"
                                    accept={`${pdf},${docx},${excel},${txt},${ppt}`}
                                    onChange={(e) => uploadImage(e)}
                                    hidden
                                    id="file-input"
                                />
                                <Typography variant={'body2'} color={'textSecondary'}>
                                    {t('dragAndDrop')}
                                </Typography>
                                <input
                                    type="file"
                                    accept={`${pdf},${docx},${excel},${txt},${ppt}`}
                                    hidden
                                    id="file-input"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </AppModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { ExemptBlockchainCompliance, ExemptCiiCompliance, AllCiiExceptions })(
    ExemptionModal
);
