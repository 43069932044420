import React from 'react';
import RiskBy3 from './RiskBy3';
import RiskBy5 from './RiskBy5';
import { useSelector } from 'react-redux';

const RiskAssessment = ({ view, risk_options }) => {
    const { all_risk_assessment_summary } = useSelector((state) => state?.CISOReducers);

    const filtered_risk_three = all_risk_assessment_summary?.filter(
        (risk) => risk?.inherent_risk_level_slug !== 'NULL' && risk?.residual_risk_level_slug !== 'NULL'
    );
    const filtered_risk_five = all_risk_assessment_summary?.filter(
        (risk) =>
            risk?.inherent_risk_level_slug !== 'NULL' &&
            risk?.inherent_risk_level_slug !== null &&
            risk?.residual_risk_level_slug !== 'NULL' &&
            risk?.residual_risk_level_slug !== null
    );
    return (
        <div className="mt-[5rem]">
            {filtered_risk_three?.filter((item) => item?.matrix === '3x3')?.length > 0 && (
                <RiskBy3 view={view} risk_options={risk_options} />
            )}
            {filtered_risk_five?.filter((item) => item?.matrix === '5x5')?.length > 0 && (
                <RiskBy5 view={view} risk_options={risk_options} />
            )}
        </div>
    );
};

export default RiskAssessment;
