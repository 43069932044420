import React, { useState } from 'react';

// mui lib
// import { useTheme } from '@mui/material/styles';
import {
    AppBar,
    Box,
    Container,
    Divider,
    IconButton,
    Toolbar,
    Menu,
    MenuItem,
    MenuList,
    ListItemIcon,
    ListItemText,
    Stack,
    TextField,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    Chip,
} from '@mui/material';
import {
    Clear,
    // FilterList,
    Home,
    Menu as MenuIcon,
    Login,
    Search,
} from '@mui/icons-material';

// img and styles
import { ReactComponent as Logo } from '../../assets/img/brand/logo.svg';
import { Link } from 'react-router-dom';

const Header = (props) => {
    const { showFilter } = props;
    // const theme = useTheme();
    const [menuAnchor, setMenuAchor] = useState(null);
    const [filterMEnuAnchor, setFilterMenuAnchor] = useState(null);

    const openMenu = (event) => setMenuAchor(event.currentTarget);
    const closeMenu = () => setMenuAchor(null);
    // const openFilterMenu = (event) => setFilterMenuAnchor(event.currentTarget);
    const closeFilterMenu = () => setFilterMenuAnchor(null);

    const logo = (
        <Link to="/auditor-pool" className="auditor-pool-header__logo">
            <Logo />
        </Link>
    );
    return (
        <header className="auditor-pool-header">
            {/* small screen header */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <AppBar position="fixed" color="default" elevation={0} className="auditor-pool-appbar">
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <Container
                            display="flex!important"
                            justifyContent="space-between"
                            alignItems="center"
                            marginTop=".5rem"
                            marginBottom=".5rem"
                            component={Box}
                            maxWidth={false}
                            padding="0!important"
                        >
                            {logo}
                            <IconButton size="large" edge="start" aria-label="menu" sx={{ mr: 2 }} onClick={openMenu}>
                                <MenuIcon color="primary" sx={{ fontSize: '2rem' }} />
                            </IconButton>
                            {/* <IconButton onClick={openFilterMenu}>
                <FilterList sx={{ fontSize: "2rem" }} />
              </IconButton> */}
                        </Container>
                    </Toolbar>
                </AppBar>
                {/* nav menu */}
                <SmScreenMenu anchor={menuAnchor} close={closeMenu} heading={logo}>
                    <NavLinks />
                </SmScreenMenu>
                {/* filter menu */}
                <SmScreenMenu
                    anchor={filterMEnuAnchor}
                    close={closeFilterMenu}
                    heading={<h4 className="menu-heading">Filter</h4>}
                ></SmScreenMenu>
            </Box>

            {/* large screen header */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <AppBar position="fixed" color="default" elevation={0} className="auditor-pool-appbar">
                    <Container>
                        <Stack
                            className="top-header"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            {logo}
                            <NavLinks />
                        </Stack>
                        {showFilter && (
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-end"
                                sx={{ marginTop: '3rem' }}
                                className="auditor-pool-filter"
                            >
                                <div className="filters">
                                    <h5>Filters</h5>
                                    <Stack direction="row">
                                        <TextField label="Location" variant="outlined" />
                                        <StandardSelect />
                                    </Stack>
                                </div>
                                <div className="search-bar">
                                    <TextField
                                        variant="standard"
                                        label="Search"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Search />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </Stack>
                        )}
                    </Container>
                </AppBar>
            </Box>
        </header>
    );
};

// smaller components
const SmScreenMenu = (props) => {
    const { anchor, close, heading, children } = props;
    return (
        <Menu
            anchorEl={anchor}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchor)}
            onClose={close}
            className="auditor-pool-header__sm-screen-menu"
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: '0 1rem' }}>
                {heading}
                <IconButton onClick={close}>
                    <Clear sx={{ fontSize: '1.6rem' }} />
                </IconButton>
            </Box>
            <Divider />
            {children}
        </Menu>
    );
};
const NavLinks = () => {
    return (
        <nav>
            <MenuList>
                <MenuItem component="a" href="https://smartcomplyapp.com">
                    <ListItemIcon>
                        <Home fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>HOME</ListItemText>
                </MenuItem>
                <MenuItem component={Link} to="/merchant/index">
                    <ListItemIcon>
                        <Login fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>DASHBOARD</ListItemText>
                </MenuItem>
            </MenuList>
        </nav>
    );
};
const StandardSelect = () => {
    // state
    const [values, setValues] = useState([]);
    // functions
    const handleChange = (event) => {
        const { value } = event.target;
        setValues(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };
    return (
        <div>
            <FormControl sx={{ minWidth: '120px', marginLeft: '.5rem' }}>
                <InputLabel id="demo-multiple-chip-label">Standard</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={values}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Standard" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} sx={{ height: '100%' }} />
                            ))}
                        </Box>
                    )}
                >
                    <MenuItem value="pcidss">PCIDSS</MenuItem>
                    <MenuItem value="ndpr">NDPR</MenuItem>
                    <MenuItem value="iso27001">ISO27001</MenuItem>
                    <MenuItem value="soc2">SOC2</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
};
export default Header;
