import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
//Components
import { Table, Modal, Row, Col, Input } from 'antd';
import { SupportOperations } from './SupportOperations/SupportOperations';
import EditModal from './SupportOperations/EditModal';
import SupportMerchantModal from './SupportOperations/SupportMerchant';

//Icons
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import avatar from 'assets/img/avatar.png';
//Redux
import { connect } from 'react-redux';
import { DeleteSupportUser, GetAllEmployees } from '../../../store/actions/adminActions';
import BreadCrumb from 'components/Breadcrumb';
import useSearch from 'hooks/useSearch';

const Image = ({ file }) => {
    return <img src={file} style={{ width: '40px', height: '40px', borderRadius: '50%' }} alt="file" />;
};
const Support = (props) => {
    // props
    const { DeleteSupportUser, GetAllEmployees, all_employees } = props;

    // state
    const [loading, setLoading] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [supportModal, setSupportModal] = useState(false);
    const [record, setrecord] = useState(null);

    // hooks
    const { data, handleSearch } = useSearch(all_employees, [
        'user_detail.email',
        'user_detail.first_name',
        'user_detail.last_name',
    ]);

    //Edit Modal
    const openEditModal = (data) => {
        setrecord(data);
        setEditModal(true);
    };
    const closeEditModal = () => {
        setEditModal(false);
    };

    //Edit Modal
    const openSupportModal = (data) => {
        setrecord(data);
        setSupportModal(true);
    };
    const closeSupportModal = () => {
        setSupportModal(false);
    };

    const deleteModal = (user) => {
        Modal.confirm({
            title: 'Do you want to delete this support user?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await DeleteSupportUser(user?.user_detail?.id);
                if (res?.success) {
                    toast.success('Support User Deleted Successfully');
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    // constants
    const columns = [
        {
            title: 'Name',
            render: (record) => `${record?.user_detail?.first_name} ${record?.user_detail?.last_name}`,
        },
        {
            title: 'Email',
            render: (record) => record?.user_detail?.email,
        },
        {
            title: 'Avatar',
            render: (record) =>
                record?.user_detail?.picture ? <Image file={record?.user_detail?.picture} /> : <Image file={avatar} />,
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => (
                <SupportOperations
                    record={record}
                    openUpdateModal={openEditModal}
                    deleteModal={deleteModal}
                    openSupportModal={openSupportModal}
                />
            ),
        },
    ];

    // useEffect
    useEffect(() => {
        const getAllEmployees = async () => {
            setLoading(true);
            const res = await GetAllEmployees();

            if (!res.success) {
                toast.error(res?.message);
            }
            setLoading(false);
        };

        getAllEmployees();
    }, []);
    const support_users = data?.filter((user) => user?.user_detail?.user_type === 'support');
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Support' }]} />
                </Col>
                <Col span={12} className="flex items-center">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                </Col>
            </Row>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(user) => user?.id}
                    columns={columns}
                    loading={loading}
                    dataSource={support_users}
                />
            </section>
            <EditModal open={editModal} handleClose={closeEditModal} record={record} />
            <SupportMerchantModal open={supportModal} handleClose={closeSupportModal} record={record} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_employees: state?.adminReducers?.all_employees,
    };
};
export default connect(mapStateToProps, { DeleteSupportUser, GetAllEmployees })(Support);
