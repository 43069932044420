/* eslint-disable no-unused-vars */

import { Menu, MenuItem } from '@mui/material';
import CustomTooltip from 'components/new_components/CustomTooltip';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ImpactOnCIA from './identificationComponents/ImpactOnCIA';
import NewVulnerability from './identificationComponents/NewVulnerability';
import Threats from './identificationComponents/Threats';
import { inherentLikelihoodOrImpact3x3_, inherentLikelihoodOrImpact5x5_ } from '../../utils/constants';

const inherentValues = [
    { name: 'Low', value: 1 },
    { name: 'Medium', value: 2 },
    { name: 'High', value: 3 },
];
const inherentLikelihoodOrImpact3x3 = [
    { name: 'Low', value: 1 },
    { name: 'Medium', value: 2 },
    { name: 'High', value: 3 },
];

const inherentLikelihoodOrImpact5x5 = [
    { name: 'Very Low', value: 1 },
    { name: 'Low', value: 2 },
    { name: 'Medium', value: 3 },
    { name: 'High', value: 4 },
    { name: 'Very High', value: 4 },
];

export default function RiskIdentification({
    riskIdentificationTabStates,
    setRiskIdentificationTabStates,
    loading,
    error,
    matrix,
    setMatrix,
    details,
    cia_impact_,
    setCiaImpact_,
    setCiaImpact,
    vulnerabilities,
    setVulnerablities,
}) {
    const [localRiskIdentificationStates, setLocalRiskIdentificationStates] = useState({
        template_name: riskIdentificationTabStates.template_name,
        riskSubject: riskIdentificationTabStates.riskSubject,
        threat: riskIdentificationTabStates.threat,
        existing_controls: riskIdentificationTabStates.existing_controls,
    });
    const get_risk_settings = useSelector((state) => state?.riskAssessmentReducers?.get_risk_settings);
    const by5Matrix =
        get_risk_settings[0]?.risk_approach === 'qualitative'
            ? inherentLikelihoodOrImpact5x5
            : inherentLikelihoodOrImpact5x5_ || inherentLikelihoodOrImpact5x5;
    const by3Matrix =
        get_risk_settings[0]?.risk_approach === 'qualitative'
            ? inherentLikelihoodOrImpact3x3
            : inherentLikelihoodOrImpact3x3_ || inherentLikelihoodOrImpact3x3;
    const [inherentLikelihoodOrImpact, setInherentLikelihoodOrImpact] = useState(
        matrix === '5x5' ? [...by5Matrix] : [...by3Matrix]
    );
    const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
    const [threatyAnchorEl, setThreatAnchorEl] = useState(null);
    const [likelihoodAnchorEl, setLikelihoodAnchorEl] = useState(null);
    const [vulnerabilitiesAnchorEl, setVulnerabilitiesAnchorEl] = useState(null);
    const [impactAnchorEl, setImpactAnchorEl] = useState(null);
    const { risk_assessment_categories, risk_assessment_vulnerabilities } = useSelector(
        (state) => state.riskAssessmentReducers
    );

    useEffect(() => {
        if (matrix === '5x5') {
            setInherentLikelihoodOrImpact([...by5Matrix]);
        } else {
            setInherentLikelihoodOrImpact([...by3Matrix]);
        }
    }, [matrix]);

    const filtered_vulnerabilities = vulnerabilities?.filter(function (item, index, inputArray) {
        return inputArray.indexOf(item) == index;
    });
    const filtered_risk_assessment_vulnerabilities = risk_assessment_vulnerabilities
        ?.filter((asset) => !filtered_vulnerabilities?.includes(asset))
        ?.filter((vulnerability) => vulnerability?.threat === riskIdentificationTabStates?.threat);
    return (
        <>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-1">
                        <p className="font-medium text-sm text-[#64748B]">
                            Risk matrix <span className="text-[#DE3730] font-semibold">*</span>
                        </p>

                        <CustomTooltip
                            title="Use this tool to assess and prioritize risks based on their likelihood and impact."
                            placement="top"
                        >
                            <img
                                src="/img/risk-assessment/help-circle.svg"
                                alt="Help Icon"
                                className="object-contain"
                            />
                        </CustomTooltip>
                    </div>

                    <div className="flex gap-4">
                        <button
                            className={`flex flex-col justify-center items-center gap-1 !p-2 bg-white transition-all hover:brightness-95 !rounded h-[77px] w-[75px] ${
                                matrix === '5x5'
                                    ? '!border-2 !border-[#395BA9] text-[#395BA9]'
                                    : '!border !border-[#94A3B8] text-[#94A3B8]'
                            }`}
                            onClick={() => {
                                setMatrix('5x5');
                                setRiskIdentificationTabStates((prev) => ({
                                    ...prev,
                                    inherentLikelihood: '',
                                    iinherent_impact: '',
                                }));
                            }}
                        >
                            <p className="font-bold text-2xl">5x5</p>
                            <p className="font-medium text-sm">Matrix</p>
                        </button>

                        <button
                            className={`flex flex-col justify-center items-center gap-1 !p-2 bg-white transition-all hover:brightness-95 !rounded h-[77px] w-[75px] ${
                                matrix === '3x3'
                                    ? '!border-2 !border-[#395BA9] text-[#395BA9]'
                                    : '!border !border-[#94A3B8] text-[#94A3B8]'
                            }`}
                            onClick={() => {
                                setMatrix('3x3');
                                setRiskIdentificationTabStates((prev) => ({
                                    ...prev,
                                    inherentLikelihood: '',
                                    inherent_impact: '',
                                }));
                            }}
                        >
                            <p className="font-bold text-2xl">3x3</p>

                            <p className="font-medium text-sm">Matrix</p>
                        </button>
                    </div>
                </div>

                <div className="flex flex-col gap-2 relative">
                    <p className="font-medium text-sm text-[#64748B]">
                        Template name <span className="text-[#DE3730] font-semibold">*</span>
                    </p>

                    <input
                        type="text"
                        placeholder="Enter template name"
                        value={localRiskIdentificationStates.template_name}
                        onChange={(event) => {
                            setLocalRiskIdentificationStates((prev) => ({
                                ...prev,
                                template_name: event.target.value,
                            }));
                        }}
                        onBlur={(event) =>
                            setRiskIdentificationTabStates((prev) => ({
                                ...prev,
                                template_name: event.target.value,
                            }))
                        }
                        className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                    />

                    {error && riskIdentificationTabStates?.template_name === '' && (
                        <span className="text-xs text-[#FF0000] font-normal mt-2">Risk subject is required</span>
                    )}
                </div>

                {/* <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-1">
                        <p className="font-medium text-sm text-[#64748B]">
                            Risk subject/name <span className="text-[#DE3730] font-semibold">*</span>
                        </p>

                        <CustomTooltip
                            title="Identify the entity or process that could be affected by risks."
                            placement="top"
                        >
                            <img
                                src="/img/risk-assessment/help-circle.svg"
                                alt="Help Icon"
                                className="object-contain"
                            />
                        </CustomTooltip>
                    </div>

                    <input
                        type="text"
                        placeholder="E.g Data Loss in Cloud Storage..."
                        value={localRiskIdentificationStates.riskSubject}
                        onChange={(event) => {
                            setLocalRiskIdentificationStates((prev) => ({
                                ...prev,
                                riskSubject: event.target.value,
                            }));

                            if (event.target.value) {
                                setErrorStates((prev) => ({
                                    ...prev,
                                    riskSubject: false,
                                }));
                            }
                        }}
                        onBlur={(event) =>
                            setRiskIdentificationTabStates((prev) => ({
                                ...prev,
                                riskSubject: event.target.value,
                            }))
                        }
                        className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                    />

                    {errorStates.riskSubject && (
                        <p className="absolute text-xs top-1.5 right-0 text-red-500">Risk subject/name is required!</p>
                    )}
                </div> */}

                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-1">
                        <p className="font-medium text-sm text-[#64748B]">Category</p>

                        <CustomTooltip title="Classify the risk based on its nature or source." placement="top">
                            <img
                                src="/img/risk-assessment/help-circle.svg"
                                alt="Help Icon"
                                className="object-contain"
                            />
                        </CustomTooltip>
                    </div>

                    <div>
                        <button
                            aria-controls={categoryAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={categoryAnchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setCategoryAnchorEl(event.currentTarget);
                            }}
                            disabled={details}
                            className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                        >
                            <p
                                className={`font-normal text-sm ${
                                    riskIdentificationTabStates.category
                                        ? 'capitalize text-[hsl(215,20%,40%)]'
                                        : 'text-[#94A3B8]'
                                }`}
                            >
                                {riskIdentificationTabStates.category
                                    ? risk_assessment_categories?.find(
                                          (category) => category?.id === riskIdentificationTabStates.category
                                      )?.name
                                    : 'Select category'}
                            </p>

                            <img
                                src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                alt="Arrow Down Icon"
                                className="object-contain"
                            />
                        </button>

                        <Menu
                            anchorEl={categoryAnchorEl}
                            open={Boolean(categoryAnchorEl)}
                            onClose={() => setCategoryAnchorEl(null)}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                        >
                            <div className="!p-2 flex flex-col w-fit h-[350px] overflow-scroll">
                                {risk_assessment_categories?.map((category) => (
                                    <MenuItem
                                        key={category?.id}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setRiskIdentificationTabStates((prev) => ({
                                                ...prev,
                                                category: category?.id,
                                            }));
                                            setCategoryAnchorEl(null);
                                        }}
                                        sx={{
                                            color: '#64748B',
                                            fontWeight: 500,
                                            fontSize: '13px',
                                            textTransform: 'capitalize',
                                            padding: '6px 8px',
                                        }}
                                    >
                                        {category?.name}
                                    </MenuItem>
                                ))}
                            </div>
                        </Menu>
                        {error && riskIdentificationTabStates?.category === '' && (
                            <span className="text-xs text-[#FF0000] font-normal mt-2">Category is required</span>
                        )}
                    </div>
                </div>

                <div className="flex items-start gap-4">
                    <div className="flex-1 flex flex-col gap-2">
                        <div className="flex items-center gap-1">
                            <p className="font-medium text-sm text-[#64748B]">Threat</p>

                            <CustomTooltip
                                title="Identify potential events or actions that could harm the asset."
                                placement="top"
                            >
                                <img
                                    src="/img/risk-assessment/help-circle.svg"
                                    alt="Help Icon"
                                    className="object-contain"
                                />
                            </CustomTooltip>
                        </div>

                        <Threats
                            localRiskIdentificationStates={localRiskIdentificationStates}
                            setLocalRiskIdentificationStates={setLocalRiskIdentificationStates}
                            setRiskIdentificationTabStates={setRiskIdentificationTabStates}
                            riskIdentificationTabStates={riskIdentificationTabStates}
                            loading={loading}
                            threatAnchorEl={threatyAnchorEl}
                            setThreatAnchorEl={setThreatAnchorEl}
                            setVulnerablities={setVulnerablities}
                        />
                    </div>

                    <div className="flex-1 flex flex-col gap-2">
                        <div className="flex items-center gap-1">
                            <p className="font-medium text-sm text-[#64748B]">Vulnerabilities</p>

                            <CustomTooltip
                                title="Highlight weaknesses that could be exploited by threats."
                                placement="top"
                            >
                                <img
                                    src="/img/risk-assessment/help-circle.svg"
                                    alt="Help Icon"
                                    className="object-contain"
                                />
                            </CustomTooltip>
                        </div>
                        <div className="relative w-full">
                            <div
                                className="flex flex-wrap gap-2 !border !border-[#E2E8F0] !rounded-sm !px-2 !py-[5px] focus-within:ring-2 focus-within:ring-[#395BA9] focus-within:ring-opacity-75 cursor-pointer "
                                aria-controls={vulnerabilitiesAnchorEl ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={vulnerabilitiesAnchorEl ? 'true' : undefined}
                                onClick={(event) => setVulnerabilitiesAnchorEl(event.currentTarget)}
                            >
                                {filtered_vulnerabilities?.length ? (
                                    <>
                                        {filtered_vulnerabilities?.map((vulnerability, index) => (
                                            <button
                                                key={index}
                                                className="flex items-center gap-2 !border !border-[#E2E8F0] bg-[#F1F5F9] !rounded py-2 !px-2 hover:brightness-90 transition-all mb-2"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setVulnerablities(
                                                        vulnerabilities?.filter(
                                                            (item) => item?.id !== vulnerability?.id
                                                        )
                                                    );
                                                }}
                                            >
                                                <p className="font-normal capitalize text-[13px] text-[#64748B]">
                                                    {vulnerability?.name}
                                                </p>

                                                <img
                                                    src="/img/risk-assessment/x-close-small.svg"
                                                    alt="Delete Icon"
                                                    className="object-contain"
                                                />
                                            </button>
                                        ))}
                                    </>
                                ) : (
                                    <span className="!w-auto focus-visible:outline-none !rounded-none !p-0 h-6 !border-none text-sm font-normal cursor-pointer self-center flex items-center text-[#94A3B8]">
                                        {vulnerabilities?.length === 0 && 'Choose Vulnerability'}
                                    </span>
                                )}
                            </div>
                            <Menu
                                anchorEl={vulnerabilitiesAnchorEl}
                                open={Boolean(vulnerabilitiesAnchorEl)}
                                onClose={() => setVulnerabilitiesAnchorEl(null)}
                                sx={{
                                    '& .MuiPaper-root': {
                                        borderRadius: '4px',
                                        boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                    },
                                    '& .MuiList-root': {
                                        padding: 0,
                                    },
                                    pb: 3,
                                }}
                            >
                                <div className="flex justify-between items-start p-2 min-h-[100px] max-h-[300px] overflow-scroll w-fit">
                                    <div className="!p-2 flex flex-col min-w-[224px]">
                                        {filtered_risk_assessment_vulnerabilities?.length ? (
                                            <>
                                                {filtered_risk_assessment_vulnerabilities?.map((vulnerability) => (
                                                    <MenuItem
                                                        key={vulnerability?.id}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setVulnerablities(
                                                                [...vulnerabilities, vulnerability]?.filter(Boolean)
                                                            );
                                                        }}
                                                        sx={{
                                                            color: vulnerabilities?.includes(vulnerability)
                                                                ? '#FFFFFF'
                                                                : '#64748B',
                                                            fontWeight: 500,
                                                            fontSize: '13px',
                                                            textTransform: 'capitalize',
                                                            padding: '6px 8px',
                                                            backgroundColor: vulnerabilities?.includes(vulnerability)
                                                                ? '#202D66'
                                                                : '',
                                                            '&:hover': {
                                                                backgroundColor: vulnerabilities?.includes(
                                                                    vulnerability
                                                                )
                                                                    ? '#202D66'
                                                                    : '',
                                                                color: vulnerabilities?.includes(vulnerability)
                                                                    ? '#FFFFFF'
                                                                    : '#64748B',
                                                            },
                                                        }}
                                                    >
                                                        {vulnerability?.name}
                                                    </MenuItem>
                                                ))}
                                            </>
                                        ) : (
                                            <p className="text-[#94A3B8] text-sm mt-5 flex items-center justify-center ">
                                                Vulnerability not found
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="flex justify-center items-center mb-3">
                                    <NewVulnerability
                                        riskIdentificationTabStates={riskIdentificationTabStates}
                                        setRiskIdentificationTabStates={setRiskIdentificationTabStates}
                                    />
                                </div>
                            </Menu>
                            {error && vulnerabilities?.length === 0 && (
                                <span className="text-xs text-[#FF0000] font-normal mt-2">
                                    Please select a vulnerability
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-1">
                        <p className="font-medium text-sm text-[#64748B]">Existing controls</p>

                        <CustomTooltip
                            title="List the measures already in place to mitigate or manage the risk."
                            placement="top"
                        >
                            <img
                                src="/img/risk-assessment/help-circle.svg"
                                alt="Help Icon"
                                className="object-contain"
                            />
                        </CustomTooltip>
                    </div>

                    <textarea
                        placeholder="Enter existing control"
                        value={localRiskIdentificationStates.existing_controls}
                        onChange={(event) => {
                            setLocalRiskIdentificationStates((prev) => ({
                                ...prev,
                                existing_controls: event.target.value,
                            }));
                        }}
                        onBlur={(event) =>
                            setRiskIdentificationTabStates((prev) => ({
                                ...prev,
                                existing_controls: event.target.value,
                            }))
                        }
                        className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 !min-h-[76px] !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                    />
                </div>

                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-1">
                        <p className="font-medium text-sm text-[#64748B]">Inherent likelihood</p>

                        <CustomTooltip
                            title="Estimate the probability of risk occurrence without any controls in place."
                            placement="top"
                        >
                            <img
                                src="/img/risk-assessment/help-circle.svg"
                                alt="Help Icon"
                                className="object-contain"
                            />
                        </CustomTooltip>
                    </div>

                    <div>
                        <button
                            aria-controls={likelihoodAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={likelihoodAnchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setLikelihoodAnchorEl(event.currentTarget);
                            }}
                            className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                        >
                            <p
                                className={`font-normal text-sm ${
                                    riskIdentificationTabStates.inherent_likelihood
                                        ? 'capitalize text-[hsl(215,20%,40%)]'
                                        : 'text-[#94A3B8]'
                                }`}
                            >
                                {riskIdentificationTabStates.inherent_likelihood
                                    ? inherentLikelihoodOrImpact?.find(
                                          (item) => item?.value === riskIdentificationTabStates.inherent_likelihood
                                      )?.name
                                    : 'Select current likelihood'}
                            </p>

                            <img
                                src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                alt="Arrow Down Icon"
                                className="object-contain"
                            />
                        </button>

                        <Menu
                            anchorEl={likelihoodAnchorEl}
                            open={Boolean(likelihoodAnchorEl)}
                            onClose={() => setLikelihoodAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                        >
                            <div className="!p-2 flex flex-col min-w-[421px]">
                                {inherentLikelihoodOrImpact.map((level, index) => (
                                    <MenuItem
                                        key={index}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setRiskIdentificationTabStates((prev) => ({
                                                ...prev,
                                                inherent_likelihood: level?.value,
                                            }));
                                            setLikelihoodAnchorEl(null);
                                        }}
                                        sx={{
                                            color: '#64748B',
                                            fontWeight: 500,
                                            fontSize: '13px',
                                            textTransform: 'capitalize',
                                            padding: '6px 8px',
                                        }}
                                    >
                                        {level?.name}
                                    </MenuItem>
                                ))}
                            </div>
                        </Menu>
                    </div>
                </div>

                {!details && (
                    <ImpactOnCIA
                        setRiskIdentificationTabStates={setRiskIdentificationTabStates}
                        inherentValues={inherentValues}
                        cia_impact_={cia_impact_}
                        setCiaImpact_={setCiaImpact_}
                        setCiaImpact={setCiaImpact}
                        matrix={matrix}
                    />
                )}

                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-1">
                        <p className="font-medium text-sm text-[#64748B]">Inherent impact</p>

                        <CustomTooltip
                            title="Evaluate the potential consequence of the risk occurring without controls."
                            placement="top"
                        >
                            <img
                                src="/img/risk-assessment/help-circle.svg"
                                alt="Help Icon"
                                className="object-contain"
                            />
                        </CustomTooltip>
                    </div>

                    {cia_impact_ ? (
                        <button className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 h-9">
                            <p className="font-normal text-sm capitalize text-[hsl(215,20%,40%)]">
                                {
                                    inherentLikelihoodOrImpact?.find(
                                        (item) => item?.value === riskIdentificationTabStates.inherent_impact
                                    )?.name
                                }
                            </p>
                        </button>
                    ) : (
                        <div>
                            <button
                                aria-controls={impactAnchorEl ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={impactAnchorEl ? 'true' : undefined}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setImpactAnchorEl(event.currentTarget);
                                }}
                                className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                            >
                                <p
                                    className={`font-normal text-sm ${
                                        riskIdentificationTabStates.inherent_impact
                                            ? 'capitalize text-[hsl(215,20%,40%)]'
                                            : 'text-[#94A3B8]'
                                    }`}
                                >
                                    {riskIdentificationTabStates.inherent_impact
                                        ? inherentLikelihoodOrImpact?.find(
                                              (item) => item?.value === riskIdentificationTabStates.inherent_impact
                                          )?.name
                                        : 'Select current impact'}
                                </p>

                                <img
                                    src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                    alt="Arrow Down Icon"
                                    className="object-contain"
                                />
                            </button>

                            <Menu
                                anchorEl={impactAnchorEl}
                                open={Boolean(impactAnchorEl)}
                                onClose={() => setImpactAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    '& .MuiPaper-root': {
                                        borderRadius: '4px',
                                        boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                    },
                                    '& .MuiList-root': {
                                        padding: 0,
                                    },
                                }}
                            >
                                <div className="!p-2 flex flex-col min-w-[421px]">
                                    {inherentLikelihoodOrImpact.map((level, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setRiskIdentificationTabStates((prev) => ({
                                                    ...prev,
                                                    inherent_impact: level?.value,
                                                }));
                                                setImpactAnchorEl(null);
                                            }}
                                            sx={{
                                                color: '#64748B',
                                                fontWeight: 500,
                                                fontSize: '13px',
                                                textTransform: 'capitalize',
                                                padding: '6px 8px',
                                            }}
                                        >
                                            {level?.name}
                                        </MenuItem>
                                    ))}
                                </div>
                            </Menu>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
