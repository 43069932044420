import { useEffect, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllDocumentsByTechSec } from 'store/actions/iso27001+iso27035Actions';
import Isogroups from './components/Isogroups';
import ResourceView from './components/ResourceView';

const Documents = (props) => {
    // props
    const {
        loading: loadingIsogroups,
        getIsogroups,
        category,
        view,
        handleViewChange,
        goBack,
        selectedIsogroup,
    } = props;

    // state
    const [loading, setLoading] = useState({ content: false });

    const dispatch = useDispatch();

    const merchant_documents = useSelector((state) => state?.complianceReducers?.all_merchant_documents);
    const all_tech_sec_documents = useSelector((state) => state?.iso27001plusiso27035Reducers?.all_tech_sec_documents);

    // async functions
    const getIsogroupDocuments = async (techsec_id) => {
        setLoading((curr) => ({ ...curr, content: true }));
        const res = await dispatch(GetAllDocumentsByTechSec(techsec_id));
        setLoading((curr) => ({ ...curr, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log('Error:::', res?.message);
        }
    };
    const handleDocumentExempted = (techsecId) => {
        getIsogroups(category);
        getIsogroupDocuments(techsecId);
    };

    // useeffect
    useEffect(() => {
        if (selectedIsogroup) {
            getIsogroupDocuments(selectedIsogroup);
        }
    }, [selectedIsogroup]);

    return view === 0 ? (
        <Isogroups resource="doc" onViewChange={handleViewChange} loading={loadingIsogroups} />
    ) : (
        <>
            <ResourceView
                goBack={goBack}
                isogroupID={selectedIsogroup}
                resource="doc"
                merchantResources={merchant_documents}
                isogroupResources={all_tech_sec_documents}
                loading={loading?.content}
                handleResourceExempted={handleDocumentExempted}
            />
        </>
    );
};

export default Documents;
