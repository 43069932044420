import React from 'react';
import { CardContainer, TaskCheck } from '../../dashboard-utils/DashboardUtils';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import CheckDone from '../../../../assets/img/dashboard/CheckDone.svg';
import CheckWarning from '../../../../assets/img/dashboard/CheckWarning.svg';

//translations
import { useTranslation } from 'react-i18next';

const SetupTaskReport = ({ dashboardResult }) => {
    const { setup } = dashboardResult || {};

    //translation
    const { t } = useTranslation('overview');

    return (
        <div>
            <CardContainer cardStyle="h-[400px]">
                <div className="px-4 py-3 border-b border-b-gray-300 lg:text-[20px] text-lg textbrandColor font-bold">
                    {t('setupTaskReport.setup')}
                </div>
                <div className="p-4">
                    <div className="rounded-[8px] border-[1.2px] border-[#0E2C66]">
                        <div className="flex items-center px-2 py-1">
                            <AiOutlineInfoCircle size="45px" className="mx-2" />
                            <div className="text-[11px] pb-0 pl-2">{t('setupTaskReport.recommend')}</div>
                        </div>
                    </div>
                    <div className="pt-4">
                        <TaskCheck
                            icon={!setup?.company_info ? CheckWarning : CheckDone}
                            name={t('setupTaskReport.companyInfo')}
                        />
                        <TaskCheck
                            icon={!setup?.tools ? CheckWarning : CheckDone}
                            name={t('setupTaskReport.connectTools')}
                        />
                        <TaskCheck
                            icon={!setup?.user ? CheckWarning : CheckDone}
                            name={t('setupTaskReport.addUsers')}
                        />
                    </div>
                </div>
            </CardContainer>
        </div>
    );
};

export default SetupTaskReport;
