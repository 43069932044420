export const inherentValues = [
    { name: 'Low', value: 1 },
    { name: 'Medium', value: 2 },
    { name: 'High', value: 3 },
];
export const inherentLikelihoodOrImpact3x3 = [
    { name: 'Low', value: 1 },
    { name: 'Medium', value: 2 },
    { name: 'High', value: 3 },
];

export const inherentLikelihoodOrImpact3x3_ = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
];

export const inherentLikelihoodOrImpact5x5 = [
    { name: 'Very Low', value: 1 },
    { name: 'Low', value: 2 },
    { name: 'Medium', value: 3 },
    { name: 'High', value: 4 },
    { name: 'Very High', value: 5 },
];

export const inherentLikelihoodOrImpact5x5_ = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
    { name: 5, value: 5 },
];

export const assetClassificcationOptions = ['local', 'public'];
export const frameworkOptions = ['pcidss', 'ndpr', 'iso27001', 'iso9001', 'pcidss4.0', 'soc2'];
export const filterOptions = ['category', 'source'];

export const risk_sources = ['manual', 'auto'];

export const riskTypeOptions = [
    {
        name: 'All',
        value: 'all',
    },
    {
        name: 'Residual Risk',
        value: 'residual_risk',
    },
    {
        name: 'Inherent Risk',
        value: 'inherent_risk',
    },
];

export const formattedRisks = [
    {
        id: 1,
        riskSubject: 'Attackers can use unauthorized and unmanaged devices to gain access to network',
        riskOwner: 'Emmanuel tanimowo',
        residualRisk: null,
        inherentRisk: 'High',
        mitigationPlan: 'Mitigate',
        dueDate: '40 days left',
        mitigationStatus: 'Approved',
    },
    {
        id: 2,
        riskSubject: 'Attackers can use unauthorized and unmanaged devices to gain access to network',
        riskOwner: 'Emmanuel tanimowo',
        residualRisk: null,
        inherentRisk: 'High',
        mitigationPlan: 'Mitigate',
        dueDate: null,
        mitigationStatus: 'Resolved',
    },
    {
        id: 3,
        riskSubject: 'Attackers can use unauthorized and unmanaged devices to gain access to network',
        riskOwner: 'Queen tanimowo',
        residualRisk: 'Low',
        inherentRisk: 'High',
        mitigationPlan: 'Mitigate',
        dueDate: null,
        mitigationStatus: 'Not resolved',
    },
    {
        id: 4,
        riskSubject: 'Attackers can use unauthorized and unmanaged devices to gain access to network',
        riskOwner: 'Queen tanimowo',
        residualRisk: 'Low',
        inherentRisk: null,
        mitigationPlan: 'Mitigate',
        dueDate: null,
        mitigationStatus: null,
    },
    {
        id: 5,
        riskSubject: 'Attackers can use unauthorized and unmanaged devices to gain access to network',
        riskOwner: 'Queen tanimowo',
        residualRisk: 'Low',
        inherentRisk: 'Medium',
        mitigationPlan: 'Mitigate',
        dueDate: '40 days left',
        mitigationStatus: 'Awaiting approval',
    },
    {
        id: 6,
        riskSubject: 'Attackers can use unauthorized and unmanaged devices to gain access to network',
        riskOwner: 'Queen Srah',
        residualRisk: 'Medium',
        inherentRisk: null,
        mitigationPlan: 'Avoid',
        dueDate: null,
        mitigationStatus: null,
    },
    {
        id: 7,
        riskSubject: 'Attackers can use unauthorized and unmanaged devices to gain access to network',
        riskOwner: 'Queen Srah',
        residualRisk: 'Low',
        inherentRisk: null,
        mitigationPlan: 'Transfer',
        dueDate: null,
        mitigationStatus: null,
    },
];

export const RiskTemplatesTab = {
    FiveByFive: '5x5',
    ThreeByThree: '3x3',
};

export const categories = ['Operational', 'Compliance', 'Security', 'Financial', 'Strategic'];

export const plans = ['no response', 'mitigate', 'accept', 'avoid', 'transfer'];

export const residual3x3Risks = ['low', 'medium', 'high'];

export const residual5x5Risks = ['very low', 'low', 'medium', 'high', 'very high'];

export const defaultAssetOwners = ['John Doe', 'Jane Doe', 'John Smith', 'Jane Smith'];

export const mitigationStatuses = ['approved', 'resolved', 'not_resolved', 'awaiting_approval', 'disapproved'];

export const sources = ['Manually added', 'Auto added'];

export const riskFilters = [
    'Category',
    'Response',
    'Risk',
    'Date',
    'Linked framework',
    'Mitigation owner',
    'Mitigation status',
    'Source',
    'Risk Owner',
];

export const NewRiskTemplateTabs = {
    riskIdentification: 'Risk identification',
    riskMitigation: 'Risk treatment',
};

export const riskFirstPagePayload = {
    name: '',
    category: '',
    threat: '',
    frameworks: [],
    existing_controls: '',
    inherent_likelihood: '',
    inherent_impact: '',
    additional_notes: '',
};

export const riskIdentificationPayload = {
    report_risk_to: '',
    report_to: false,
    solution_owner: '',
    timeline_start_date: null,
    timeline_end_date: null,
    mitigation_cost_amount: '',
    comments: '',
    justification: '',
    contingency_plan: false,
    contingency_plan_text: '',
    contingency_start_date: null,
    contingency_end_date: null,
    risk_source: '',
    transfer_method: '',
    vendor: '',
};

export const riskMitigationPayload_ = {
    report_risk_to: '',
    report_to: false,
    solution_owner: '',
    timeline_start_date: '',
    timeline_end_date: '',
    mitigation_controls: '',
    mitigation_cost_amount: '',
    residual_likelihood: '',
    residual_impact: '',
    comments: '',
};

export const riskAcceptPayload = {
    report_risk_to: '',
    report_to: false,
    solution_owner: '',
    useAssetOwner: false,
    justification: '',
    contingency_plan: false,
    contingency_plan_text: '',
    contingency_start_date: null,
    contingency_end_date: null,
    residual_likelihood: 0,
    residual_impact: 0,
};

export const riskAvoidPayload = {
    report_risk_to: '',
    report_to: false,
    solution_owner: '',
    risk_source: '',
    risk_elimination_method: '',
    timeline_start_date: null,
    timeline_end_date: null,
    comments: '',
};

export const riskTransferPayload = {
    report_risk_to: '',
    report_to: false,
    transfer_method: '',
    solution_owner: '',
    vendor: '',
    timeline_start_date: '',
    timeline_end_date: '',
};

export const tabs = [
    {
        index: 0,
        label: 'Risk Identification',
    },
    {
        index: 1,
        label: 'Risk Treatment',
    },
];

export const currencies = ['NGN (₦)', 'USD ($)', 'EUR (€)', 'GBP (£)', 'KES (Ksh)', 'GHS (₵)'];

export function extractTextInsideBraces(input) {
    if (input) {
        const match = input?.match(/\(([^)]+)\)/);
        return match ? match?.[1] : null;
    }
}

export const mitigation_plans = ['mitigate', 'accept', 'avoid', 'transfer'];
