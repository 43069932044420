import React, { useEffect, useState, useContext, useRef } from 'react';
import PageHeader from 'components/new_components/PageHeader';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AppUserGuide from 'components/new_components/AppUserGuide';

import AppTable from 'components/new_components/app-table';
import RiskTitle from './RiskTitle';
import { Box, Chip, Typography } from '@mui/material';
import RiskAction from './RiskAction';
import Actions from './TableAction';
import RiskAssessmentViewModal from '../components/RiskAssessmentViewModal';
import useSearch from 'hooks/useSearch';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import RiskAssessmentDeleteModal from './RiskAssessmentDeleteModal';
import { steps } from '../../steps';
import { connect } from 'react-redux';
import { isFullFunctionPlan } from 'utils';
import SubscriptionRoutingAlert from '../../../../../components/Pricing/SubscriptionRoutingAlert';
import { by3riskLevel } from 'utils';
import { by5riskLevel } from 'utils';

//translations
import { useTranslation } from 'react-i18next';

const NO_PER_PAGE = 10;

const RiskRegister = (props) => {
    const {
        userguide_info,
        filtered_status_merchant_risk_assessment,
        pricing_plan,
        page,
        setPage,
        risk_owner,
        setRisk_owner,
        matrix,
        setMatrix,
        risk_status,
        setRisk_status,
        loading,
        handleType,
    } = props;
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [viewModalPayload, setViewModalPayload] = useState({});
    const [payloadId, setPayloadId] = useState(0);
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const stepsTargetRef = useRef([]);
    const risk_assessment_data = filtered_status_merchant_risk_assessment?.results;

    //translation
    const { t } = useTranslation('riskAssessment');

    const { data, handleSearch } = useSearch(risk_assessment_data, [
        'asset',
        'risk_owner',
        'threat_detail.sub_category',
    ]);
    const openDeleteModal = (id) => {
        setDeleteModalOpen(true);
        setPayloadId(id);
    };

    const closeDeleteModal = (id) => {
        setDeleteModalOpen(false);
        setPayloadId(id);
    };

    const openViewModal = (payload) => {
        setViewModalOpen(true);
        setViewModalPayload(payload);
    };
    const closeViewModal = () => {
        setViewModalOpen(false);
        setTimeout(() => setViewModalPayload({}), 1000);
    };
    const columns = [
        {
            title: t('riskRegisterTab.table.column1'),
            render: (row) => (
                <Box>
                    <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>{row?.asset}</Typography>
                    <Typography sx={{ color: '#919094', fontSize: '14px' }}>
                        {row?.asset_type?.replace(/_/g, ' ')}
                    </Typography>
                </Box>
            ),
        },
        {
            title: t('riskRegisterTab.table.column2'),
            key: 'severity',
            render: (row) => (
                <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>{row?.threat_detail?.sub_category}</Typography>
            ),
        },
        {
            title: t('riskRegisterTab.table.column3'),
            key: 'uploaded_by',
            render: (row) => <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>{row?.risk_owner}</Typography>,
        },
        {
            title: t('riskRegisterTab.table.column4'),
            key: 'date',
            render: (row) => (
                <Box>
                    <Box>
                        {row?.matrix === '3x3' ? (
                            <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>
                                {t('riskRegisterTab.table.inherent')}:{by3riskLevel(row?.inherent_risk_level)?.type}
                                <FiberManualRecordIcon
                                    sx={{
                                        color: by3riskLevel(row?.inherent_risk_level)?.color,
                                        width: '15px',
                                        height: '15px',
                                    }}
                                />
                            </Typography>
                        ) : (
                            <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>
                                {t('riskRegisterTab.table.inherent')}:{by5riskLevel(row?.inherent_risk_level).type}
                                <FiberManualRecordIcon
                                    sx={{
                                        color: by5riskLevel(row?.inherent_risk_level)?.color,
                                        width: '15px',
                                        height: '15px',
                                    }}
                                />
                            </Typography>
                        )}
                    </Box>
                    <Box>
                        {row?.matrix === '3x3' ? (
                            <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>
                                {t('riskRegisterTab.table.residual')}:{by3riskLevel(row?.residual_risk_level).type}
                                <FiberManualRecordIcon
                                    sx={{
                                        color: by3riskLevel(row?.residual_risk_level).color,
                                        width: '15px',
                                        height: '15px',
                                    }}
                                />
                            </Typography>
                        ) : (
                            <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>
                                {t('riskRegisterTab.table.residual')}:{by5riskLevel(row.residual_risk_level).type}
                                <FiberManualRecordIcon
                                    sx={{
                                        color: by5riskLevel(row.residual_risk_level).color,
                                        width: '15px',
                                        height: '15px',
                                    }}
                                />
                            </Typography>
                        )}
                    </Box>
                </Box>
            ),
        },
        {
            title: t('riskRegisterTab.table.column5'),
            key: 'date',
            render: (row) => <Chip label={row.risk_status} variant={'outlined'} />,
        },
        {
            title: t('riskRegisterTab.table.column7'),
            align: 'center',
            key: 'action',
            render: (row) => (
                <Actions
                    row={row}
                    openDeleteModal={openDeleteModal}
                    openViewModal={openViewModal}
                    page={page}
                    handleType={handleType}
                />
            ),
        },
    ];

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        data ? data?.slice(start, stop) : [];

        return data;
    }, [page, data]);

    const handlePageChange = (page) => {
        setPage(page);
    };

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ risk_assessment: true });
        setToggleUserGuide({ ...toggleUserGuide, riskassessment: false });
        setOpenUserGuide(false);
    };
    useEffect(() => {
        !userguide_info || toggleUserGuide.riskassessment ? OpenUserGuide() : null;
    }, [userguide_info, toggleUserGuide.riskassessment]);

    return (
        <div className="mt-3">
            <PageHeader browserTitle="Risk Assessment | Smartcomply" />
            {!isFullFunctionPlan(pricing_plan?.tag) ? (
                <SubscriptionRoutingAlert />
            ) : (
                <>
                    <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#46464A' }}>
                        {t('riskRegisterTab.table.cybersecurity')}
                    </Typography>
                    <AppTable
                        columns={columns}
                        data={data}
                        sorter={(a, b) => b?.id - a?.id}
                        loading={loading}
                        title={
                            <RiskTitle
                                onSearch={handleSearch}
                                risk_status={risk_status}
                                risk_owner={risk_owner}
                                matrix={matrix}
                                setStatusFilter={setRisk_status}
                                setRiskOwnerFilter={setRisk_owner}
                                setRiskMatrixFilter={setMatrix}
                            />
                        }
                        dataLength={filtered_status_merchant_risk_assessment?.count}
                        noPerPage={NO_PER_PAGE}
                        page={page}
                        onPageChange={handlePageChange}
                        label1={'Status'}
                        label2={'Risk owner'}
                        label3={'Risk matrix'}
                        actions={data && data?.length > 0 ? <RiskAction /> : ''}
                    />

                    <RiskAssessmentViewModal
                        open={viewModalOpen}
                        handleClose={closeViewModal}
                        payload={viewModalPayload}
                    />
                    <RiskAssessmentDeleteModal
                        open={deleteModalOpen}
                        handleClose={closeDeleteModal}
                        id={payloadId}
                        page={page}
                    />
                    <AppUserGuide
                        open={openUserGuide}
                        onClose={CloseUserGuide}
                        steps={steps}
                        targets={stepsTargetRef.current}
                    />
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        filtered_status_merchant_risk_assessment: state?.merchantReducers?.filtered_status_merchant_risk_assessment,
        pricing_plan: state?.authReducers.pricing_plan,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.risk_assessment,
    };
};

export default connect(mapStateToProps, {})(RiskRegister);
