import React from 'react';
import { Typography, Box, ButtonGroup, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import fileQuestion from 'assets/img/file-question.svg';
import FileTypes from 'assets/img/Filetypes.svg';
import Docx from 'assets/img/docx.svg';
import Png from 'assets/img/png.svg';
import Xsl from 'assets/img/xsl.svg';
import Img from 'assets/img/img.svg';
import Download from 'assets/img/download01.svg';

const ViewResponse = (props) => {
    const theme = useTheme();
    const { name, answer, type, options } = props;
    let bulletedName = name.substr(name.indexOf(' ') + 1);

    return (
        <div className="py-3">
            <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <img src={fileQuestion} alt="fileQuestion" />
                <Typography sx={{ ml: 1, color: '#64748B', fontSize: '14px', fontWeight: 400 }}>
                    {bulletedName}
                </Typography>
            </Box>
            {type === 'dropdown' ? (
                <div>
                    <ButtonGroup sx={{ my: 1.5 }}>
                        {options.split(',')?.map((option, index) => (
                            <Button
                                key={index}
                                sx={{
                                    backgroundColor:
                                        answer?.[0] === option.replace(' ', '')
                                            ? '#94A3B8'
                                            : answer?.[0] === option.replace('  ', '')
                                            ? '#94A3B8'
                                            : '',
                                    color:
                                        answer?.[0] === option.replace(' ', '')
                                            ? '#FFFFFF'
                                            : answer?.[0] === option.replace('  ', '')
                                            ? '#FFFFFF'
                                            : '#64748B',
                                    border:
                                        answer?.[0] === option.replace(' ', '')
                                            ? 'none'
                                            : answer?.[0] === option.replace('  ', '')
                                            ? 'none'
                                            : '0.5px solid #E2E8F0',
                                    px: 0.7,
                                }}
                            >
                                {option}
                            </Button>
                        ))}
                    </ButtonGroup>
                    {answer?.[0] === 'Yes' && answer?.[1] !== null && (
                        <div className="flex items-center gap-3">
                            <img
                                src={
                                    answer?.[1]?.split('.').pop() === 'xlsx'
                                        ? Xsl
                                        : answer?.[1]?.split('.').pop() === 'xls'
                                        ? Xsl
                                        : answer?.[1]?.split('.').pop() === 'docx'
                                        ? Docx
                                        : answer?.[1]?.split('.').pop() === 'doc'
                                        ? Docx
                                        : answer?.[1]?.split('.').pop() === 'png'
                                        ? Png
                                        : answer?.[1]?.split('.').pop() === 'img'
                                        ? Img
                                        : FileTypes
                                }
                                alt="FileTypes"
                            />
                            <Typography
                                component="p"
                                sx={{
                                    color: '#475569',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    // maxWidth: '300px !important',
                                    wordWrap: 'break-word',
                                }}
                            >
                                {answer?.[1]?.split('/')?.pop()}
                            </Typography>
                            <Box
                                component="a"
                                href={answer?.[1]}
                                rel="noreferrer"
                                target="_blank"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mr: 2,
                                }}
                            >
                                {' '}
                                <img src={Download} alt={'Download'} />
                            </Box>
                        </div>
                    )}
                </div>
            ) : (
                <Typography
                    variant="h3"
                    noWrap
                    component="h3"
                    sx={{
                        m: 0,
                        pl: { xs: '33px', md: '52px' },
                        pr: 2,
                        pt: 1,
                        fontSize: { xs: '12px', md: '14px' },
                        color: ' #919094',
                        fontWeight: '600',
                    }}
                >
                    {answer?.includes('https:') ? (
                        <Box
                            sx={{
                                color: theme.palette.primary[900],
                                textDecoration: 'underline',
                                paddingRight: '5px',
                                cursor: 'pointer',
                            }}
                            component="a"
                            href={answer}
                            download
                        >
                            Click here to download
                        </Box>
                    ) : (
                        answer
                    )}
                </Typography>
            )}
        </div>
    );
};

export default ViewResponse;
