import React from 'react';
import GroupDocumentClip from '../../../../assets/img/dashboard/GroupDocumentClip.svg';
import GroupServer from '../../../../assets/img/dashboard/GroupServer.svg';
import KDPAReport from 'components/Dashboard/AllReport/report/KDPAReport';

//translations
import { useTranslation } from 'react-i18next';

const FirstLayerReport = ({ percentage, kdpaGeneral }) => {
    const { documents, forms } = kdpaGeneral;

    //translation
    const { t } = useTranslation('overview');

    return (
        <div className="bg-white py-8 px-3">
            <div className="grid grid-cols-3 gap-8">
                <KDPAReport percentage={percentage} />
                <div className="pl-8">
                    <div className="relative">
                        <div className="flex items-center">
                            <img src={GroupDocumentClip} alt="GroupComputerIcon" />
                            <div className="ml-3">
                                <p className="!mb-0 text-[14px] font-medium textbrandColor">
                                    {t('standardTab.documents')}
                                </p>
                                <h3 className="">
                                    {documents?.user}/{documents?.total}
                                </h3>
                            </div>
                        </div>
                        <div className="flex items-center pt-4">
                            <img src={GroupServer} alt="GroupServerIcon" />
                            <div className="ml-3 font-medium">
                                <p className="!mb-0 text-[14px] textbrandColor">{t('standardTab.auditQuestion')}</p>
                                <h3 className="">
                                    {forms?.user}/{forms?.total}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FirstLayerReport;
