import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

// core components
import Categories from './components/Categories';
import ResourceView from './components/ResourceView';
import { AppForm, AppFormContext } from 'components/new_components/forms';

// redux
import { connect } from 'react-redux';
import { GetAllFormsByCategory } from 'store/actions/adminActions';
import { SubmitMerchantAnswers } from 'store/actions/complianceActions';

// validation
import { validateForm } from '../validation';

const AuditQuestions = (props) => {
    const {
        loading: loadingCategory,
        GetAllFormsByCategory,
        merchant_answers,
        forms_by_category,
        SubmitMerchantAnswers,
        getCategories,
        openTaskAssignModal,
        all_compliance_assigned_tasks,
    } = props;

    // state
    const [view, setView] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [loading, setLoading] = useState({ content: false });
    const [formPayload, setFormPayload] = useState({});
    const [touchedFields, setTouchedFields] = useState({});

    // logic functions
    const handleViewChange = (categoryID) => {
        setView(1);
        setSelectedCategory(categoryID);
    };
    const goBack = () => {
        setView(0);
        setSelectedCategory(null);
    };

    // async functions
    const getCategoryQuestions = async (cat_id) => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllFormsByCategory(cat_id);
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };
    const handleSubmitAnswers = async (values) => {
        setLoading((current) => ({ ...current, submit: true }));
        const res = await SubmitMerchantAnswers({
            answer: values,
            form_id: formPayload.form_id,
        });
        setLoading((current) => ({ ...current, submit: false }));
        if (res?.success) {
            toast.success("You have successfully answered this question, press 'NEXT' to go to the nest question.");
        } else {
            toast.error('Something went wrong!!');
            console.log('Check AuditQuestions, error:::::', res?.message);
        }
    };
    const handleQuestionExempted = (cat_id) => {
        getCategories();
        getCategoryQuestions(cat_id);
    };

    useEffect(() => {
        if (selectedCategory) {
            getCategoryQuestions(selectedCategory);
        }
    }, [selectedCategory]);

    return view === 0 ? (
        <Categories
            resource="form"
            onViewChange={handleViewChange}
            loading={loadingCategory}
            openTaskAssignModal={openTaskAssignModal}
            all_compliance_assigned_tasks={all_compliance_assigned_tasks}
        />
    ) : (
        <AppForm
            initialValues={formPayload.values || {}}
            validate={(values) => validateForm(values, formPayload?.requiredValues)}
            onSubmit={handleSubmitAnswers}
        >
            {/* App form and form context aren't put in the resource view because resource view is used for both form and doc. */}
            <AppFormContext getTouched={setTouchedFields}>
                <ResourceView
                    goBack={goBack}
                    categoryID={selectedCategory}
                    resource="form"
                    merchantResources={merchant_answers}
                    categoryResources={forms_by_category}
                    setFormPayload={setFormPayload}
                    loading={loading.content}
                    touchedFields={touchedFields}
                    submitting={loading.submit}
                    handleResourceExempted={handleQuestionExempted}
                    openTaskAssignModal={openTaskAssignModal}
                    all_compliance_assigned_tasks={all_compliance_assigned_tasks}
                />
            </AppFormContext>
        </AppForm>
    );
};
const mapStateToProps = (state) => {
    return {
        merchant_answers: state?.complianceReducers?.merchant_answers,
        forms_by_category: state?.adminReducers?.all_ndpr_forms,
        all_compliance_assigned_tasks: state?.generalReducers?.user_info?.compliance_assigned_tasks,
    };
};
export default connect(mapStateToProps, {
    GetAllFormsByCategory,
    SubmitMerchantAnswers,
})(AuditQuestions);
