import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import BreadCrumb from 'components/Breadcrumb';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { EditAuditCategory } from 'store/actions/auditActions';
import FieldModal from './components/FieldModal';
import SectionCard from './components/SectionCard';

const { confirm } = Modal;

const CategorySettings = (props) => {
    const { match, all_audit_categories, EditAuditCategory } = props;
    // state
    const [sections, setSections] = useState(['']);
    const [fieldModal, setFieldModal] = useState(false);
    const [fieldIndex, setFieldIndex] = useState(null);

    // memos
    const category = useMemo(
        () => all_audit_categories.find((category) => category?.id == match?.params?.id),
        [match, all_audit_categories]
    );
    const questions = useMemo(() => {
        try {
            return JSON.parse(category.questions).sort((a, b) => a?.id - b?.id);
        } catch {
            return [];
        }
    }, [category]);

    // functions
    const openFieldModal = (mode, idx) => {
        setFieldModal(mode);
        setFieldIndex(idx);
    };
    const closeFieldModal = () => {
        setFieldModal(null);
        setFieldIndex(null);
    };
    const addOneSection = () => {
        setSections((current) => [...current, '']);
    };
    const handleTitleChange = (e, index) => {
        const sectionsArray = [...sections];
        sectionsArray.splice(index, 1, e.target.value);
        setSections(sectionsArray);
    };
    const handleTitleSave = (e) => {
        e.preventDefault();
        updateCategoryDetail(sections, 'sections');
    };
    const updateCategoryDetail = async (sections, field) => {
        const res = await EditAuditCategory({ [field]: JSON.stringify(sections) }, category?.id);
        if (res.success) {
            return toast.success(res?.message);
        } else {
            return toast.error(res.message);
        }
    };
    const deleteASection = (index) => {
        confirm({
            title: 'Are you sure remove this sections?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const sectionsArray = [...sections];
                sectionsArray.splice(index, 1);
                await updateCategoryDetail(sectionsArray, 'sections');
            },
        });
    };
    const deleteAField = (index) => {
        confirm({
            title: 'Are you sure remove this field?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const questionsArray = [...questions];
                questionsArray.splice(index, 1);
                await updateCategoryDetail(questionsArray, 'questions');
            },
        });
    };

    // useEffects
    useEffect(() => {
        const sections = category?.sections;
        if (Array.isArray(sections)) {
            setSections(['']);
        } else {
            setSections(JSON.parse(sections));
        }
    }, [category]);

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[{ label: 'Audit Categories', link: '/audit-categories' }, { label: category?.name }]}
                    />
                </Col>
                <Col className="flex justify-end items-center space-x-2">
                    <Button
                        size="large"
                        type="link"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center"
                        onClick={addOneSection}
                    >
                        Add Section
                    </Button>
                    <Button
                        size="large"
                        type="primary"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center"
                        onClick={() => openFieldModal('Add')}
                    >
                        Add Field
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Row gutter={16}>
                    {sections.map((section, index) => {
                        return (
                            <SectionCard
                                enableDelete={sections.length > 1}
                                handleTitleChange={handleTitleChange}
                                handleTitleSave={handleTitleSave}
                                section={section}
                                deleteASection={deleteASection}
                                index={index}
                                questions={questions}
                                hasMore={category?.question_type === 'multiple'}
                                key={index}
                                openFieldModal={openFieldModal}
                                deleteField={deleteAField}
                            />
                        );
                    })}
                </Row>
            </section>

            {/* modal */}
            <FieldModal
                open={fieldModal}
                fieldIndex={fieldIndex}
                closeModal={closeFieldModal}
                category={category?.id}
                questions={questions}
            />
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_audit_categories: state?.auditReducers?.all_audit_categories,
});
export default connect(mapStateToProps, { EditAuditCategory })(CategorySettings);
