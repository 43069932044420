import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { APP_COOKIES } from '../utils/constants';

const CookiesTable = () => {
    const theme = useTheme();

    const columnKeys = Object.keys(APP_COOKIES?.[0]);
    return (
        <Box sx={{ border: `1px solid ${theme.palette.gray[100]}`, borderRadius: '4px', py: 3, px: 2, mb: 3 }}>
            <Stack direction="row" gap={3} sx={{ backgroundColor: '#EEF0FF4D', px: 2, py: 1 }}>
                {columnKeys?.map((key, index) => (
                    <Typography
                        key={index}
                        sx={{
                            flexBasis: index === 1 ? '70%' : '15%',
                            textTransform: 'capitalize',
                            fontSize: 13,
                            fontWeight: 500,
                            color: theme.palette.gray[20],
                        }}
                    >
                        {key}
                    </Typography>
                ))}
            </Stack>
            {APP_COOKIES?.map((item, index) => (
                <Stack
                    direction="row"
                    gap={3}
                    key={index}
                    sx={{
                        px: 2,
                        py: 1,
                        borderBottom:
                            APP_COOKIES?.length - 1 === index ? 'none' : `1px solid ${theme.palette.gray[100]}`,
                    }}
                >
                    <Typography
                        sx={{
                            flexBasis: '15%',
                            fontSize: 12,
                            fontWeight: 400,
                            letterSpacing: '0.4px',
                            color: '#0F172A',
                        }}
                    >
                        {item.domain}
                    </Typography>
                    <Typography
                        sx={{
                            flexBasis: '70%',
                            fontSize: 12,
                            fontWeight: 400,
                            letterSpacing: '0.4px',
                            color: '#0F172A',
                        }}
                    >
                        {item?.description}
                    </Typography>
                    <Typography
                        sx={{
                            flexBasis: '15%',
                            fontSize: 12,
                            fontWeight: 400,
                            letterSpacing: '0.4px',
                            color: '#475569',
                        }}
                    >
                        {item?.type}
                    </Typography>
                </Stack>
            ))}
        </Box>
    );
};

export default CookiesTable;
