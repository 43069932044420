import React from 'react';

import { Box, Grid, Stack, Typography } from '@mui/material';
import { CheckCircle, Pending } from '@mui/icons-material';

import BlueBall from 'assets/img/BlueBall.svg';
import BlueLine from 'assets/img/BlueLine.svg';
import GrayBall from 'assets/img/GrayBall.svg';
import GrayLine from 'assets/img/GrayLine.svg';

const Steps = (props) => {
    const { onboardingPolicy, setOnboardingPolicy, check } = props;

    return (
        <Grid item md={5} lg={5}>
            <Stack>
                <Box
                    onClick={() => {
                        setOnboardingPolicy(1);
                    }}
                    sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                >
                    <img src={onboardingPolicy === 1 ? BlueBall : GrayBall} alt="Ball" />
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: 600,
                            ml: '25px',
                            mr: '5px',
                            color: `${onboardingPolicy === 1 ? '#0E2C66' : '#ACAAAF'}`,
                        }}
                    >
                        Acknowledgement Policy
                    </Typography>
                    {check?.policy ? (
                        <CheckCircle color="success" fontSize="10px" />
                    ) : (
                        <Pending color="disabled" fontSize="10px" />
                    )}
                </Box>
                <Box sx={{ ml: '20px' }}>
                    <img src={onboardingPolicy === 1 ? BlueLine : GrayLine} alt="Line" />
                </Box>
                <Box
                    onClick={() => {
                        setOnboardingPolicy(2);
                    }}
                    sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                >
                    <img src={onboardingPolicy === 2 ? BlueBall : GrayBall} alt="Ball" />
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: 600,
                            ml: '25px',
                            mr: '5px',
                            color: `${onboardingPolicy === 2 ? '#0E2C66' : '#ACAAAF'}`,
                        }}
                    >
                        Security Awareness Training
                    </Typography>
                    {check?.security_check ? (
                        <CheckCircle color="success" fontSize="10px" />
                    ) : (
                        <Pending color="disabled" fontSize="10px" />
                    )}
                </Box>
                <Box sx={{ ml: '20px' }}>
                    <img src={onboardingPolicy === 2 ? BlueLine : GrayLine} alt="Line" />
                </Box>
                <Box
                    onClick={() => {
                        setOnboardingPolicy(3);
                    }}
                    sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                >
                    <img src={onboardingPolicy === 3 || onboardingPolicy === 4 ? BlueBall : GrayBall} alt="Ball" />
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: 600,
                            ml: '25px',
                            mr: '5px',
                            color: `${onboardingPolicy === 3 || onboardingPolicy === 4 ? '#0E2C66' : '#ACAAAF'}`,
                        }}
                    >
                        Background Check
                    </Typography>
                    {check?.background_check ? (
                        <CheckCircle color="success" fontSize="10px" />
                    ) : (
                        <Pending color="disabled" fontSize="10px" />
                    )}
                </Box>
            </Stack>
        </Grid>
    );
};

export default Steps;
