import React from 'react';
import { Slide } from '@mui/material';

const AppSlide = (props) => {
    const { children, ...restProps } = props;
    return (
        <Slide {...restProps} mountOnEnter unmountOnExit>
            {children}
        </Slide>
    );
};

export default AppSlide;
