import React, { useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import { TextSnippet } from '@mui/icons-material';
import FileUploadIcon from '@mui/icons-material/FileUpload';

// core components
import AppUploadButton from 'components/new_components/AppUploadButton';
import ItemSubheader from '../../common/ItemSubheader';
import DocumentDrawer from '../../common/document/DocumentDrawer';
import { accept } from 'validate';
import ExemptTag from '../../common/ExemptTag';

// Redux
import { UploadMerchantDocument, RevertCiiCompliance } from 'store/actions/complianceActions';
import { connect } from 'react-redux';

//translation
import { useTranslation } from 'react-i18next';

const { pdf, docx, excel, txt, ppt } = accept;

const Code_reviewItem = (props) => {
    const {
        onResourceChange,
        title,
        UploadMerchantDocument,
        document,
        all_merchant_documents,
        exception,
        RevertCiiCompliance,
        exceptionId,
        no_excemption,
    } = props;
    const theme = useTheme();
    const [drawerOpenedTab, setDrawerOpenedTab] = useState(null);
    const [loading, setLoading] = useState({ upload: false, exempt: false });
    const inputRef = useRef();

    //translation
    const { t } = useTranslation('compliance');

    const openDrawer = (tab) => setDrawerOpenedTab(tab);
    const closeDrawer = () => setDrawerOpenedTab(null);
    // memo
    // get the current document in view
    const selectedDocument = useMemo(() => {
        return all_merchant_documents?.find((doc) => doc?.document_id === document?.id);
    }, [document, all_merchant_documents]);

    // async functions
    const uploadMerchantDocument = async (e) => {
        // get the form data
        const doc = new FormData();
        doc.append('document', e.target.files[0]);
        doc.append('document_id', document?.id);
        doc.append('cii_subsector', document?.cii_subsector);

        // sending the data
        setLoading({ ...loading, upload: true });
        const res = await UploadMerchantDocument(doc);
        setLoading({ ...loading, upload: false });

        //handling response
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
        inputRef.current.value = null;
    };
    const document_id = {
        doc_id: selectedDocument?.document_id,
        user_doc_id: selectedDocument?.id,
    };

    const removeExempt = async () => {
        setLoading({ ...loading, exempt: true });
        const res = await RevertCiiCompliance(exceptionId);
        setLoading({ ...loading, exempt: false });
        onResourceChange();
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };
    const payload = {
        document: document?.id,
        cii_subsector: document?.cii_subsector,
        cii_sector: document?.cii_sector,
        reason: '',
    };
    return (
        <Box sx={{ p: 2, py: 4 }}>
            <ItemSubheader
                hasResource={Boolean(selectedDocument)}
                exempting={loading.exempt}
                exceptionReason={document}
                menuItems={[
                    { label: t('blockchain.docAndVersions'), action: () => openDrawer(0) },
                    // { label: 'Comments', action: () => openDrawer(1) },
                ]}
                resource="doc"
                payload={payload}
                standard="cii"
                onResourceChange={onResourceChange}
                exception={exception}
                no_excemption={no_excemption}
            />
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ py: 5 }} spacing={3}>
                <TextSnippet color="primary" />
                <Stack>
                    <Box sx={{ display: 'flex' }}>
                        <Typography
                            sx={{
                                color: theme.palette.gray[800],
                                fontWeight: 500,
                                fontSize: 16,
                            }}
                        >
                            {title}
                        </Typography>
                        {exception ? (
                            <Box sx={{ marginLeft: '1.5rem' }}>
                                <ExemptTag
                                    onClick={removeExempt}
                                    loading={loading?.exempt}
                                    loadingLabel={t('reverting')}
                                    label={t('revert')}
                                />
                            </Box>
                        ) : null}
                    </Box>
                    <Typography
                        sx={{
                            fontWeight: 300,
                            fontSize: 12,
                        }}
                    >
                        Want to see a sample?.{' '}
                        <Typography
                            component="a"
                            sx={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 500, fontSize: '12px' }}
                            href={document?.default}
                            target="_blank"
                            rel="noreferer"
                        >
                            Download template
                        </Typography>
                    </Typography>
                    <Stack direction="row" spacing={3} sx={{ pt: 4 }}>
                        <AppUploadButton
                            color="primary"
                            variant="outlined"
                            label={selectedDocument ? t('uploadVersion') : t('uploadDocument')}
                            startIcon={<FileUploadIcon />}
                            id={document?.id}
                            loading={loading.upload}
                            onChange={uploadMerchantDocument}
                            accept={`${pdf},${docx},${excel},${txt},${ppt}`}
                            sx={{ textTransform: 'uppercase', borderRadius: 2, py: 1.5, px: 3 }}
                            inputRef={inputRef}
                            disabled={exception ? true : false}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <DocumentDrawer
                open={Boolean(drawerOpenedTab !== null)}
                tab={drawerOpenedTab}
                handleClose={closeDrawer}
                document={document_id}
                onResourceChange={onResourceChange}
            />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_documents: state?.complianceReducers?.all_merchant_documents,
    };
};

export default connect(mapStateToProps, {
    UploadMerchantDocument,
    RevertCiiCompliance,
})(Code_reviewItem);
