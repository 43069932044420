import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Modal, Row } from 'antd';
import BreadCrumb from 'components/Breadcrumb';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { EditAccessorSubCategory } from 'store/actions/auditActions';
import FieldModal from '../components/FieldModal';
import SectionCard from '../components/SectionCard';

const { confirm } = Modal;

const CategorySettings = (props) => {
    const { location, all_accessor_categories, EditAccessorSubCategory } = props;
    // state
    const [sections, setSections] = useState([]);
    const [fieldModal, setFieldModal] = useState(false);
    const [fieldIndex, setFieldIndex] = useState(null);

    // memos
    const category = useMemo(
        () => all_accessor_categories.find((category) => category?.id == location?.state?.category_id),
        [location, all_accessor_categories]
    );
    const section = useMemo(
        () => category?.subcategory.find((section) => section?.id == location?.state?.id),
        [location, category]
    );
    // functions
    const openFieldModal = (mode, idx) => {
        setFieldModal(mode);
        setFieldIndex(idx);
    };
    const closeFieldModal = () => {
        setFieldModal(null);
        setFieldIndex(null);
    };
    const handleTitleChange = (e, index) => {
        const sectionsArray = [...sections];
        sectionsArray.splice(index, 1, e.target.value);
        setSections(sectionsArray);
    };
    const updateCategoryDetail = async (sections, field) => {
        const res = await EditAccessorSubCategory({ [field]: JSON.stringify(sections) }, section?.id);
        if (res.success) {
            return toast.success(res?.message);
        } else {
            return toast.error(res.message);
        }
    };

    const handleTitleSave = (e) => {
        e.preventDefault();
        updateCategoryDetail(sections, 'sections');
    };
    const deleteAField = (index) => {
        confirm({
            title: 'Are you sure remove this field?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const questionsArray = [...sections];
                questionsArray.splice(index, 1);
                await updateCategoryDetail(questionsArray, 'sections');
            },
        });
    };

    // useEffects
    useEffect(() => {
        const sections = section?.sections;
        if (Array.isArray(sections)) {
            setSections([]);
        } else {
            setSections(JSON.parse(sections));
        }
    }, [section]);

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[
                            { label: 'Accessor Categories', link: '/accessor-categories' },
                            {
                                label: category.name,
                                link: '/accessor-categories/multiple',
                                state: {
                                    category_id: location?.state?.category_id,
                                },
                            },
                            { label: section?.name },
                        ]}
                    />
                </Col>
            </Row>
            <section className="my-3">
                <Row gutter={16}>
                    <SectionCard
                        enableDelete={sections.length > 1}
                        handleTitleChange={handleTitleChange}
                        handleTitleSave={handleTitleSave}
                        section={section}
                        questions={sections}
                        hasMore={category?.question_type === 'multiple'}
                        openFieldModal={openFieldModal}
                        deleteField={deleteAField}
                    />
                </Row>
            </section>

            {/* modal */}
            <FieldModal
                open={fieldModal}
                fieldIndex={fieldIndex}
                closeModal={closeFieldModal}
                category={category?.id}
                subcategory={section?.id}
                questions={sections}
                hasMore={category?.question_type}
            />
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_accessor_categories: state?.auditReducers?.all_accessor_categories,
});
export default connect(mapStateToProps, { EditAccessorSubCategory })(CategorySettings);
