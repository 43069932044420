import { validation } from 'validate';

export const infrastructureValidation = (values) => {
    const errors = {};
    const locationValidate = validation(values.location, 'Location', true);
    const ip_addressValidate = validation(values.ip_address, 'Ip Address', true);
    const nameValidate = validation(values.name, 'Asset Name', true);

    if (!locationValidate.isValid) {
        errors.location = locationValidate.errorMessage;
    }
    if (!ip_addressValidate.isValid) {
        errors.ip_address = ip_addressValidate.errorMessage;
    }
    if (!nameValidate.isValid) {
        errors.name = nameValidate.errorMessage;
    }

    return errors;
};

export const assetGroupValidation = (values) => {
    const errors = {};
    const nameValidate = validation(values.name, 'Group Name', true);

    if (!nameValidate.isValid) {
        errors.name = nameValidate.errorMessage;
    }

    return errors;
};
