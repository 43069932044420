import React, { useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';

// core components
import AnswerItem from '../audit_questions/AnswerItem';
import ExemptTag from '../ExemptTag';

// redux
import { connect, useSelector } from 'react-redux';
import { DeleteFormExemption, DeleteDocumentExemption } from 'store/actions/complianceActions';
import { toast } from 'react-toastify';

//translation
import { useTranslation } from 'react-i18next';

const ExceptionItem = (props) => {
    const { resource, item, standard, DeleteFormExemption, DeleteDocumentExemption, onExempted } = props;
    const theme = useTheme();

    // state
    const [loading, setLoading] = useState();

    //translation
    const { t } = useTranslation('compliance');
    const language = localStorage.getItem('i18nextLng');

    const user_type = useSelector((state) => state?.authReducers?.user_type);

    // memos
    const itemDetails = useMemo(() => {
        return resource === 'doc' ? item?.document_detail : item?.form_detail;
    }, [item, resource]);
    const title_key = useMemo(() => {
        return standard === 'pcidss'
            ? resource === 'doc'
                ? language?.includes('fr')
                    ? 'document_title_fr'
                    : language?.includes('en')
                    ? 'document_title_en'
                    : 'document_title'
                : language?.includes('fr')
                ? 'title_fr'
                : language?.includes('en')
                ? 'title_en'
                : 'title'
            : standard === 'card4'
            ? resource === 'doc'
                ? language?.includes('fr')
                    ? 'card4_title_fr'
                    : language?.includes('en')
                    ? 'card4_title_en'
                    : 'card4_title'
                : language?.includes('fr')
                ? 'card4_title_fr'
                : language?.includes('en')
                ? 'card4_title_en'
                : 'card4_title'
            : standard === 'ndpr'
            ? language?.includes('fr')
                ? 'ndpr_title_fr'
                : language?.includes('en')
                ? 'ndpr_title_en'
                : 'ndpr_title'
            : standard === 'kdpa'
            ? 'kdpa_title'
            : standard === 'gdpa'
            ? 'gdpa_title'
            : standard === 'tech_sec'
            ? 'tech_sec_title'
            : standard === 'iso27001'
            ? language?.includes('fr')
                ? 'iso_title_fr'
                : language?.includes('en')
                ? 'iso_title_en'
                : 'iso_title'
            : standard === 'isms'
            ? 'iso_two_title'
            : language?.includes('fr')
            ? 'soc2_title_fr'
            : language?.includes('en')
            ? 'soc2_title_en'
            : 'soc2_title';
    }, [item]);
    const desc_key = useMemo(() => {
        return standard === 'pcidss'
            ? language?.includes('fr')
                ? 'document_description_fr'
                : language?.includes('en')
                ? 'document_description_en'
                : 'document_description'
            : standard === 'card4'
            ? language?.includes('fr')
                ? 'card4_description_fr'
                : language?.includes('en')
                ? 'card4_description_en'
                : 'card4_description'
            : standard === 'ndpr'
            ? language?.includes('fr')
                ? 'ndpr_document_description_fr'
                : language?.includes('en')
                ? 'ndpr_document_description_en'
                : 'ndpr_document_description'
            : standard === 'kdpa'
            ? 'kdpa_document_description'
            : standard === 'gdpa'
            ? 'gdpa_document_description'
            : standard === 'iso27001'
            ? language?.includes('fr')
                ? 'iso_description_fr'
                : language?.includes('en')
                ? 'iso_description_en'
                : 'iso_description'
            : standard === 'tech_sec'
            ? 'tech_sec_description'
            : standard === 'isms'
            ? 'iso_two_description'
            : language?.includes('fr')
            ? 'soc2_description_fr'
            : language?.includes('en')
            ? 'soc2_description_en'
            : 'soc2_description';
    }, [item]);

    // functions
    const removeException = async () => {
        setLoading(true);
        const res =
            resource === 'doc'
                ? await DeleteDocumentExemption(item?.id, standard)
                : await DeleteFormExemption(item?.id, standard);
        setLoading(false);
        if (res?.success) {
            toast.success('You have successfully reverted this resource');
            onExempted();
        } else {
            toast.error('Something went wrong!');
            console.log('Error:::::', res?.message);
        }
    };

    return (
        <Stack sx={{ py: 5, flex: 1 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
                <Typography
                    sx={{
                        color: theme.palette.gray[600],
                        fontWeight: 500,
                        fontSize: 16,
                        width: '85%',
                    }}
                >
                    {itemDetails?.[title_key] || itemDetails?.[title_key]}
                </Typography>
                {user_type !== 'auditor' && (
                    <ExemptTag
                        label={t('revert')}
                        loadingLabel={t('reverting')}
                        onClick={removeException}
                        loading={loading}
                    />
                )}
            </Stack>
            <Box sx={{ py: 1, px: 2, backgroundColor: theme.palette.gray[100] }}>
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: 18,
                        color: theme.palette.black.main,
                    }}
                >
                    {resource === 'doc' ? t('description') : t('question')}
                </Typography>
            </Box>
            <Stack sx={{ py: 5, px: 2 }} spacing={2.5}>
                {resource === 'doc' ? (
                    <Typography
                        sx={{
                            color: theme.palette.gray[600],
                            fontWeight: 500,
                            fontSize: 16,
                        }}
                    >
                        {itemDetails?.[desc_key]}
                    </Typography>
                ) : (
                    <>
                        {itemDetails?.form_fields?.map((field, index) => {
                            return <AnswerItem number={index + 1} answer={field?.name} key={index + 1} />;
                        })}
                    </>
                )}
            </Stack>
        </Stack>
    );
};
const mapStateToProps = (state) => {
    return state;
};
export default connect(mapStateToProps, {
    DeleteFormExemption,
    DeleteDocumentExemption,
})(ExceptionItem);
