import React from 'react';
import { useTheme } from '@mui/material/styles';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { DoneAll } from '@mui/icons-material';

const PlanFeatureItem = (props) => {
    const { text } = props;
    const theme = useTheme();
    return (
        <ListItem sx={{ px: 0 }}>
            <ListItemIcon sx={{ minWidth: 'fit-content', mr: 1.3 }}>
                <DoneAll color="primary" />
            </ListItemIcon>
            <ListItemText
                primary={text}
                primaryTypographyProps={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: theme.palette.gray[900],
                }}
            />
        </ListItem>
    );
};

export default PlanFeatureItem;
