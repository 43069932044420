import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Avatar, CircularProgress, Typography } from '@mui/material';
import { CameraAlt } from '@mui/icons-material';
// images
import pfp from 'assets/img/pfp.png';

const AppAvatar = (props) => {
    const theme = useTheme();
    const { id, editable, src, onChange, loading, imageFileRef, label, size = 100, alt, borderColor } = props;
    return (
        <>
            {label && (
                <Typography
                    component="span"
                    sx={{
                        color: theme.palette.gray[900],
                        fontSize: 14,
                        display: 'block',
                        mb: 1,
                    }}
                >
                    {label}
                </Typography>
            )}
            <Box
                sx={{
                    position: 'relative',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    m: 0,
                    border: `2px solid ${borderColor || theme.palette.primary[900]}`,
                }}
                component="label"
                htmlFor={id}
            >
                <Avatar src={src || pfp} sx={{ width: size, height: size }} alt={alt || ''} />
                {editable && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0,0,0,0.4)',
                            opacity: loading.upload ? 1 : 0,
                            transition: 'opacity 0.3s',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            '&:hover': editable ? { opacity: 1 } : null,
                        }}
                    >
                        <input
                            id={id}
                            name={id}
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={onChange}
                            disabled={loading}
                            ref={imageFileRef}
                        />
                        {loading ? (
                            <CircularProgress sx={{ color: theme.palette.gray[500] }} />
                        ) : (
                            <CameraAlt sx={{ color: theme.palette.white.main, fontSize: 32 }} />
                        )}
                    </Box>
                )}
            </Box>
        </>
    );
};

export default AppAvatar;
