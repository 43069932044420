import React from 'react';
import { Box } from '@mui/material';

//core components
import AppBackdrop from '../../AppBackdrop';
import AppSlide from 'components/new_components/AppSlide';
import UserMenu from './menus/UserMenu';

const UserDropdownMenu = (props) => {
    const { menuOpen, handleClose, openLanguageMenu, closeLanguageMenu } = props;
    return (
        <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
            <AppBackdrop onClose={handleClose} open={menuOpen} />
            <AppSlide direction="down" in={menuOpen}>
                <Box
                    sx={{
                        position: 'absolute',
                        zIndex: '102',
                        top: 70,
                        right: 20,
                    }}
                >
                    <UserMenu
                        handleClose={handleClose}
                        openLanguageMenu={openLanguageMenu}
                        closeLanguageMenu={closeLanguageMenu}
                    />
                </Box>
            </AppSlide>
        </Box>
    );
};

export default UserDropdownMenu;
