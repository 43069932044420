import React, { createContext, useState } from 'react';

export const SelectedMerchantContext = createContext();

export const SelectedMerchantContextProvider = ({ children }) => {
    const [activeMerchant, setActiveMerchant] = useState(null);
    const [merchantStandards, setMerchantStandards] = useState([]);

    const value = { activeMerchant, setActiveMerchant, setMerchantStandards, merchantStandards };
    return <SelectedMerchantContext.Provider value={value}>{children}</SelectedMerchantContext.Provider>;
};

export default SelectedMerchantContextProvider;
