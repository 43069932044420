import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Row, Col, Button, Tabs, Modal } from 'antd';
import BreadCrumb from 'components/Breadcrumb';
import { PlusOutlined, FileOutlined, VideoCameraOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// core components
import Documents from './Documents';
import Videos from './Videos';
import AddModal from './operations/AddModal';
import EditModal from './operations/EditModal';

// redux
import { connect } from 'react-redux';
import { GetAllOnboardingItems, DeleteOnboardingItem, GetAllMerchants } from 'store/actions/adminActions';
const { TabPane } = Tabs;
// const { Title } = Typography;
const { confirm } = Modal;

const Onboarding = (props) => {
    const { GetAllOnboardingItems, all_onboarding_items, DeleteOnboardingItem, GetAllMerchants } = props;

    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editDetails, setEditDetails] = useState({});

    // useEffect
    useEffect(() => {
        getAllOnboardingItems();
        getAllMerchants();
    }, []);

    // functions
    const openAddModal = () => setAddModal(true);
    const closeAddModal = () => setAddModal(false);
    const openEditModal = (item) => {
        setEditModal(true);
        setEditDetails(item);
    };
    const closeEditModal = () => {
        setEditModal(false);
        setEditDetails({});
    };
    const getAllOnboardingItems = async () => {
        const res = await GetAllOnboardingItems();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllMerchants = async () => {
        const res = await GetAllMerchants();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const deleteModal = (item) => {
        confirm({
            title: 'Do you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await DeleteOnboardingItem(item?.id);
                if (res.success) {
                    toast.success('Item Deleted Successfully');
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Onboarding' }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openAddModal()}
                    >
                        Add Item
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Tabs defaultActiveKey="1">
                    <TabPane
                        tab={
                            <Row align="middle">
                                <FileOutlined />
                                Policy Documents
                            </Row>
                        }
                        key="1"
                    >
                        <Documents
                            items={all_onboarding_items?.filter((item) => item?.tag === 'document')}
                            openEditModal={openEditModal}
                            deleteModal={deleteModal}
                        />
                    </TabPane>
                    <TabPane
                        tab={
                            <Row align="middle">
                                <VideoCameraOutlined />
                                Videos
                            </Row>
                        }
                        key="2"
                    >
                        <Videos
                            items={all_onboarding_items?.filter((item) => item?.tag === 'video')}
                            openEditModal={openEditModal}
                            deleteModal={deleteModal}
                        />
                    </TabPane>
                </Tabs>
            </section>
            <AddModal open={addModal} handleCancel={closeAddModal} />
            <EditModal open={editModal} handleCancel={closeEditModal} details={editDetails} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_onboarding_items: state?.adminReducers?.all_onboarding_items,
    };
};
export default connect(mapStateToProps, {
    GetAllOnboardingItems,
    DeleteOnboardingItem,
    GetAllMerchants,
})(Onboarding);
