import { Stack, Typography, Box, Select, MenuItem } from '@mui/material';
import { useState, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { ReactComponent as DateIcon } from 'assets/img/calendar.svg';
// import { CategoryScale } from 'chart.js';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { months } from './utils';
import { useSelector } from 'react-redux';
import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function IncidentSummary() {
    const date_joined = useSelector((state) => state?.authReducers?.user_details?.user?.date_joined) ?? null;
    const yearUserSignedUp = date_joined ? moment(date_joined).year() : moment().year();

    // state
    const [filter, setFilter] = useState(moment().year());

    // constants
    const overview = useSelector((state) => state?.riskAssessmentReducers?.overview);

    const handleChange = (e) => {
        setFilter(e.target.value);
    };

    const values = useMemo(() => {
        const data = overview?.incident_summary?.filter((el) => el.date_created__year === filter) ?? [];

        const resolvedRisks = months.map((_arg, key) => {
            const existingData = data.find((el) => el?.date_created__month === key + 1);
            return existingData ? existingData?.resolved_risk : 0;
        });

        const unresolvedRisks = months.map((_arg, key) => {
            const existingData = data.find((el) => el?.date_created__month === key + 1);
            return existingData ? existingData?.unresolved_risk : 0;
        });

        return [resolvedRisks, unresolvedRisks];
    }, [overview, filter]);

    const maxValue = useMemo(
        () =>
            values.flat().reduce((max, item) => {
                return item > max ? item : max;
            }, 0),
        [values]
    );
    const MAX_ITEMS = 5;

    const data = useMemo(
        () => ({
            labels: months.map((el) => el.slice(0, 3)),
            datasets: [
                {
                    label: 'Resolved risk',
                    data: values[0],
                    backgroundColor: '#4FBAF0',
                    borderWidth: 0,
                    barPercentage: 1.0,
                },
                {
                    label: 'Unresolved risk',
                    data: values[1],
                    backgroundColor: '#395BA9',
                    borderWidth: 0,
                    barPercentage: 1.0,
                },
            ],
        }),
        [values]
    );

    const options = useMemo(
        () => ({
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'bottom',
                    labels: {
                        usePointStyle: true,
                        boxWidth: 8,
                        boxHeight: 8,
                        padding: 10,
                        font: {
                            size: 12,
                            weight: 'bold',
                            family: 'Poppins, sans-serif',
                        },
                        color: '#475569',
                    },
                },
                title: {
                    display: true,
                    font: {
                        size: 18,
                    },
                },
                tooltip: {
                    callbacks: {
                        // Customize the label shown in the tooltip
                        label: function (context) {
                            const month =
                                months.filter((el) => el.toLowerCase().startsWith(context.label.toLowerCase()))[0] ??
                                '';
                            return month;
                        },
                        // Optionally customize the title or other parts
                        title: function (context) {
                            const label = context[0].dataset.label.toLowerCase() + `${context[0].raw > 1 ? 's' : ''}`;
                            return context[0].raw + ' ' + label;
                        },
                    },
                    titleColor: '#ffffff',
                    font: {
                        weight: 'bold',
                        family: 'Poppins, sans-serif',
                    },
                    bodyFont: {
                        weight: 'normal',
                        family: 'Poppins, sans-serif',
                    },
                },
            },
            scales: {
                x: {
                    stacked: false,
                    title: {
                        display: false,
                        text: 'Month',
                    },
                    grid: {
                        display: false,
                    },
                    ticks: {
                        color: '#64748B',
                        font: {
                            family: 'Poppins, sans-serif',
                        },
                    },
                    border: {
                        display: false,
                    },
                },
                y: {
                    stacked: false,
                    title: {
                        display: true,
                        text: 'No. of Incidents',
                        color: '#475569',
                        font: {
                            weight: 'bolder',
                            family: 'Poppins, sans-serif',
                        },
                    },
                    grid: {
                        display: false,
                    },
                    border: {
                        display: false,
                    },
                    ticks: {
                        stepSize: Math.round(maxValue / MAX_ITEMS),
                        color: '#64748B',
                        font: {
                            family: 'Poppins, sans-serif',
                        },
                    },
                },
            },
        }),
        []
    );

    function getYearsSinceJoining() {
        const currentYear = new Date().getFullYear();
        const years = [];

        for (let year = yearUserSignedUp; year <= currentYear; year++) {
            years.push(year);
        }

        return years;
    }

    // #64748B

    return (
        <div className="col-span-6 bg-white rounded-md border-[#f1f5f9] px-3 py-3">
            <Stack
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        color: '#2b3674',
                        fontWeight: 600,
                    }}
                >
                    Incident Summary
                </Typography>

                <Select
                    labelId="year-select-label"
                    id="year-select"
                    value={filter}
                    label="Years"
                    onChange={handleChange}
                    size="small"
                    sx={{
                        textTransform: 'capitalize',
                        backgroundColor: '#F8FAFC',
                        border: '1px solid #E2E8F0',

                        '& .MuiSelect-select': {
                            fontSize: 11,
                            paddingLeft: '10px',
                            paddingBlock: '3px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #E2E8F0 !important',
                        },
                    }}
                    defaultValue={yearUserSignedUp}
                    renderValue={(value) => (
                        <span className="flex gap-1 items-center">
                            <DateIcon className="w-4 h-4" />
                            {value}
                        </span>
                    )}
                >
                    {getYearsSinceJoining().map((value, key) => (
                        <MenuItem sx={{ fontSize: 11, textTransform: 'capitalize' }} key={key} value={value}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </Stack>

            <div className="flex flex-col gap-8">
                <Box sx={{ mt: 3 }}>
                    <div>
                        <Bar data={data} options={options} />
                    </div>
                </Box>
            </div>
        </div>
    );
}
