import React, { useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, useTheme } from '@mui/material';

//translations
import { useTranslation } from 'react-i18next';

const TrainingActions = (props) => {
    const { row, openDeleteModal, resendTraining } = props;
    const theme = useTheme();

    // states
    const [anchor, setAnchor] = useState(null);
    const [resending, setResending] = useState(false);

    //translation
    const { t } = useTranslation('training');

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);
    const handleRemove = () => {
        openDeleteModal(row);
        closeMenu();
    };
    const handleResend = async () => {
        setResending(true);
        await resendTraining(row?.id);
        setResending(false);
        closeMenu();
    };

    return (
        <>
            <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={closeMenu}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    onClick={handleResend}
                    sx={{ fontSize: 16, fontWeight: 600, color: theme.palette.gray[700] }}
                    disabled={resending}
                >
                    {t('courseView.table.tableAction.resend')}
                </MenuItem>
                <MenuItem onClick={handleRemove} sx={{ fontSize: 16, fontWeight: 600, color: theme.palette.gray[700] }}>
                    {t('courseView.table.tableAction.remove')}
                </MenuItem>
            </Menu>
        </>
    );
};

export default TrainingActions;
