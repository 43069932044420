import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Modal, Row, Table } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

// app components
import BreadCrumb from 'components/Breadcrumb';
import SubcriteriaActions from '../components/SubcriteriaActions';

// redux
import { connect } from 'react-redux';
import { DeleteSubriteria } from 'store/actions/adminActions';
import SubcriteriaModal from '../components/SubcriteriaModal';
import { useHistory } from 'react-router-dom';

const { confirm } = Modal;

const Subcriteria = (props) => {
    const history = useHistory();
    // props
    const { all_criteria, location, DeleteSubriteria } = props;

    // state
    const [modal, setModal] = useState(null);
    const [modalData, setModalData] = useState({});

    // memos
    const criteria = useMemo(
        () => all_criteria.find((criterion) => criterion.id === location.state.criteria),
        [location, all_criteria]
    );
    // functions
    const openModal = (mode, data = {}) => {
        setModal(mode);
        setModalData(data);
    };
    const closeModal = () => {
        setModal(null);
        setModalData({});
    };
    const deleteSubcriteria = (id) => {
        confirm({
            title: "Are you sure you want to delete this subcriteria? Note: Deleting this subcriteria means that you are deleting this subcriteria and records of the criteria associated with alongside merchant's data",
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteSubriteria(id);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };
    const goToItems = (id) => {
        history.push({ pathname: `/admin/blockchain/subcriteria/${id}`, state: { criteria_title: criteria?.name } });
    };

    // constants
    const columns = [
        { title: 'S/N', render: (row) => row.sn },
        { title: 'Name', render: (row) => row.name },
        { title: 'Description', render: (row) => row.description },
        {
            title: 'Actions',
            align: 'right',
            render: (row) => (
                <SubcriteriaActions row={row} deleteSubcriteria={deleteSubcriteria} openModal={openModal} />
            ),
        },
    ];

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Blockchain', link: '/blockchain' }, { label: criteria?.name }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center"
                        onClick={() => openModal('Add', { criteria: criteria?.id })}
                    >
                        Add Subcriteria
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Table
                    columns={columns}
                    dataSource={criteria?.subcriterias
                        ?.sort((a, b) => a?.id - b?.id)
                        ?.map((subcriteria, index) => ({ ...subcriteria, sn: index + 1 }))}
                    rowKey={(subcriteria) => subcriteria?.id}
                    onRow={(record) => {
                        return {
                            onDoubleClick: () => goToItems(record?.id),
                        };
                    }}
                    rowClassName="cursor-pointer"
                />
            </section>
            <SubcriteriaModal open={modal} onClose={closeModal} modalData={modalData} />
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_criteria: state?.adminReducers?.all_criteria,
});
export default connect(mapStateToProps, { DeleteSubriteria })(Subcriteria);
