import React from 'react';
import pfp from 'assets/img/avatar.jpg';

const ActivityCard = ({ details, image }) => {
    return (
        <div className="bg-[#F7F7F7] px-4 py-3 rounded-[8px]">
            <div className="flex">
                <div>
                    <img src={image || pfp} className="w-[40px] h-[40px] rounded-full bg-gray-400 overflow-hidden" />
                </div>
                <div className="pl-3">
                    <h3 className="textbrandColor text-[15px]">{details?.name}</h3>
                    <h3 className="text-[#5E5E62] text-[13px]">
                        {details?.time_elapsed}, {details?.date}
                    </h3>
                    <h4 className="text-[#5E5E62] text-[13px]">
                        <strong>{details?.activity}</strong>
                    </h4>
                </div>
            </div>
        </div>
    );
};

export default ActivityCard;
