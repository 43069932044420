import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Modal, Row, Typography } from 'antd';
import BreadCrumb from 'components/Breadcrumb';
import React, { useEffect, useState } from 'react';
import CoursesModal from './components/CoursesModal';

// redux
import { connect } from 'react-redux';
import { GetAllTrainingCourses, DeleteTrainingCourse } from 'store/actions/trainingActions';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const { Title } = Typography;
const { confirm } = Modal;

const Courses = (props) => {
    const { GetAllTrainingCourses, all_training_courses, DeleteTrainingCourse } = props;
    // state
    const [modal, setModal] = useState(null);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // functions
    const openModal = (mode, data = {}) => {
        setModal(mode);
        setData(data);
    };
    const closeModal = () => {
        setModal(null);
        setData({});
    };
    const getAllTrainingCourses = async () => {
        setLoading(true);
        const res = await GetAllTrainingCourses();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const deleteTrainingCourse = (course) => {
        confirm({
            title: 'Are you sure delete this course?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteTrainingCourse(course?.id);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    // useEffect
    useEffect(() => {
        getAllTrainingCourses();
    }, []);

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Courses' }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        className="flex items-center justify-between"
                        onClick={() => openModal('Add')}
                    >
                        Add Course
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {loading ? (
                    <>loading...</>
                ) : all_training_courses && all_training_courses?.length ? (
                    <Row wrap gutter={24}>
                        {all_training_courses
                            ?.sort((a, b) => a?.id - b?.id)
                            ?.map((course) => (
                                <Col xs={24} md={12} lg={8} xxl={6} key={course.id} className="mb-4">
                                    <Card
                                        title={course?.title}
                                        actions={[
                                            <Link key="preview" to={`/admin/training/${course?.id}`}>
                                                <EyeOutlined key="preview" />
                                            </Link>,
                                            <EditOutlined key="edit" onClick={() => openModal('Edit', course)} />,
                                            <DeleteOutlined
                                                key="delete"
                                                onClick={() => deleteTrainingCourse(course)}
                                            />,
                                        ]}
                                    >
                                        <Title level={5} className="text-center">
                                            {course.description}
                                        </Title>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                ) : (
                    <Row justify="center">
                        <Col>
                            <Empty
                                description={
                                    <div>
                                        <h3>No Training Courses</h3>
                                        <span>Add a new training course </span>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                )}
            </section>
            <CoursesModal open={Boolean(modal)} mode={modal} modalData={data} handleClose={closeModal} />
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_training_courses: state?.trainingReducers?.all_training_courses,
});
export default connect(mapStateToProps, { GetAllTrainingCourses, DeleteTrainingCourse })(Courses);
