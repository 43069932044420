import React from 'react';
//core components
import {
    Box,
    // Stack,
    // Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

//core components
import Fields from './Field';

const Questionnaire = (props) => {
    const theme = useTheme();

    // const [checked, setChecked] = useState(false);
    // const [selectedQuestion, setSelectedQuestion] = useState(null);

    // const handleSelectedQuestion = (id) => {
    //     setChecked(true);
    //     setSelectedQuestion(id);
    // };

    const {
        field,
        responses,
        comments,
        confidence,
        questionId,
        questionsIndex,
        handleAnswerInput,
        handleCommentInput,
        handleAnswerSelect,
        handleCommentSelect,
        fieldValues,
        handleSingleApproveAction,
        acceptedAnswers,
        handleDisapproveAction,
        handleDeleteQuestion,
        completed,
    } = props;

    // const {
    // responses,

    // confidence,
    // selectedQuestionnaire,
    // handleShowPredictionScore,
    // handleHidePredictionScore,
    // showPredictionScore,
    // } = props;

    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: theme.palette.white.main,
                boxShadow:
                    '-23px 170px 68px rgba(209, 209, 209, 0.01), -13px 95px 58px rgba(209, 209, 209, 0.05), -6px 42px 43px rgba(209, 209, 209, 0.09), -1px 11px 24px rgba(209, 209, 209, 0.1), 0px 0px 0px rgba(209, 209, 209, 0.1)',
                minHeight: '400px !important',
            }}
        >
            <Box sx={{ p: '20px', borderRadius: '0 0  6px 6px' }}>
                <Fields
                    field={field}
                    // handleSelectedQuestion={handleSelectedQuestion}
                    // checked={checked}
                    // selectedQuestion={selectedQuestion}
                    predictions={responses?.[questionsIndex]}
                    commentPredictions={comments?.[questionsIndex]}
                    percentageCorrectness={confidence?.[questionsIndex]}
                    questionId={questionId}
                    handleAnswerInput={handleAnswerInput}
                    handleCommentInput={handleCommentInput}
                    handleAnswerSelect={handleAnswerSelect}
                    handleCommentSelect={handleCommentSelect}
                    fieldValues={fieldValues}
                    questionsIndex={questionsIndex}
                    acceptedAnswers={acceptedAnswers}
                    handleSingleApproveAction={handleSingleApproveAction}
                    handleDisapproveAction={handleDisapproveAction}
                    handleDeleteQuestion={handleDeleteQuestion}
                    completed={completed}
                    // selectedQuestionnaire={selectedQuestionnaire}
                />
            </Box>
        </Box>
    );
};

export default Questionnaire;
