import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import AppAvatar from 'components/new_components/AppAvatar';
// images
import pfp from 'assets/img/avatar.jpg';

const AuditorListItem = (props) => {
    const theme = useTheme();
    const { picture, name, standards } = props;
    return (
        <ListItem component={Grid} item xs={12} md={12} lg={6} xl={6} sx={{ px: 0 }}>
            <ListItemAvatar
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mx: 1,
                }}
            >
                <AppAvatar src={picture || pfp} size={50} />
            </ListItemAvatar>
            <ListItemText
                primary={name}
                secondary={standards}
                primaryTypographyProps={{ sx: { fontWeight: 500 } }}
                sx={{ color: theme.palette.gray[900] }}
                secondaryTypographyProps={{ sx: { fontSize: 13 } }}
            />
        </ListItem>
    );
};

export default AuditorListItem;
