import React, { useEffect, useState } from 'react';
import { CardContainer } from '../../dashboard-utils/DashboardUtils';
import { Box, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import ActivityCard from './ActivityCard';
import { formatDateObjectHandler, GetFromNowDate, getType } from '../../../../utils';
//redux
import { connect } from 'react-redux';

//translations
import { useTranslation } from 'react-i18next';

const RecentActivitiesReport = ({ dashboardResult, profile_image, company_image }) => {
    const { top_ten_activities } = dashboardResult?.activity || {};
    const [activities, setActivities] = useState([]);

    //translation
    const { t } = useTranslation('overview');

    const theme = useTheme();

    //function
    const type = getType('type');

    let image = type === 'merchant' ? company_image : profile_image;
    useEffect(() => {
        if (top_ten_activities) {
            const activities = top_ten_activities?.map((log) => {
                const { detail, user, id, ip, date_created } = log;
                const activity = JSON.parse(detail).message;
                const name = `${user?.first_name} ${user?.last_name}`;
                const date = formatDateObjectHandler(date_created, 'HH:mm A');
                const time_elapsed = GetFromNowDate(date_created, 'YYYY-MM-DDTHH:mm:ss', 'fromNow');
                const email = user?.email;
                return { activity, name, date, time_elapsed, id, ip, detail, email };
            });
            setActivities(activities);
        }
    }, [top_ten_activities]);

    return (
        <div>
            <CardContainer cardStyle="h-[360px] overflow-hidden">
                <div className="px-4 py-2 flex justify-between items-center border-b border-b-gray-300 lg:text-[20px] text-lg textbrandColor font-bold">
                    {t('recentActivitiesReport.recentActivities')}
                    <Link to="/merchant/activities" className="text-sm text-[#395BA9]">
                        {t('recentActivitiesReport.viewAll')}
                    </Link>
                </div>
                <Box
                    sx={{
                        p: 4,
                        height: '360px',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary[900] + '30',
                            borderRadius: '10px',
                        },
                    }}
                >
                    <div className="lg:py-0 mb-[5rem]">
                        {activities?.map((res, index) => (
                            <React.Fragment key={index}>
                                <div className="flex">
                                    <div className="flex flex-col items-center mr-4">
                                        <div>
                                            <div className="flex items-center justify-center w-6 h-6 border-2 border-[#0E2C66] bg-[#FFF] rounded-full">
                                                <div className="w-3 h-3 bg-[#0E2C66] rounded-full" />
                                            </div>
                                        </div>
                                        <div className="w-px h-full bg-gray-300" />
                                    </div>
                                    <div className="pt-1 pb-8">
                                        <ActivityCard details={res} image={image} />
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </Box>
            </CardContainer>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        profile_image: state?.generalReducers?.user_info?.picture,
        company_image: state?.generalReducers?.user_info?.organization?.company_img,
    };
};
export default connect(mapStateToProps, {})(RecentActivitiesReport);
