import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button, Select } from 'antd';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { EditThreatCategory } from 'store/actions/adminActions';

const EditModal = (props) => {
    const [loading, setloading] = useState(false);
    const [data, setData] = useState({});
    const { open, handleClose, record } = props;

    // redux
    const dispatch = useDispatch();
    const all_risk_categories = useSelector((state) => state.adminReducers.all_risk_categories);

    const handleTextChange = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value });
    };

    const onClose = () => {
        setData({});
        handleClose();
    };

    useEffect(() => {
        if (record) {
            setData(record);
        }
    }, [record]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setloading(true);
        const payload = { name: data?.name, risk_category: data?.risk_category };

        const res = await dispatch(EditThreatCategory(payload, data?.id));
        setloading(false);
        if (res?.success) {
            toast.success('Threat Category Updated Successfully!');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };
    const onSelect = (value) => {
        setData({ ...data, risk_category: value });
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };
    return (
        <Modal title="Edit Threat Category" open={open} onCancel={onClose} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="name">Threat Category</label>
                        <Input
                            type="text"
                            size="large"
                            name="name"
                            id="name"
                            onChange={handleTextChange}
                            value={data.name || ''}
                            required
                        />
                    </Col>

                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="plan">Choose Category</label>
                        <Select
                            showSearch
                            size="large"
                            placeholder="Select Category"
                            optionFilterProp="children"
                            onChange={onSelect}
                            onSearch={onSearch}
                            style={{ width: '100%' }}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={all_risk_categories?.map((category) => {
                                return {
                                    value: category?.id,
                                    label: category?.name,
                                };
                            })}
                            value={data?.risk_category}
                        />
                    </Col>
                    <Col style={{ marginTop: '5rem' }}>
                        <Button
                            className="ant-btn"
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                            disabled={!data?.name || !data?.risk_category}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

export default EditModal;
