import React, { useState, useEffect, useMemo } from 'react';

//core components
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import CardHeader from './CardHeader';
import Rectangle from './Rectangle';
import './style.css';

//constant
import {
    tabList,
    tabListFr,
    likelihoodBy5,
    likelihoodBy5Fr,
    impactBy5,
    impactBy5Fr,
    likelihoodBy3,
    likelihoodBy3Fr,
    impactBy3,
    impactBy3Fr,
} from '../utils/constants';
import { by5riskLevel, by3riskLevel, ConnectFn } from 'utils';
import { useTheme } from '@mui/styles';
import { Tooltip } from 'antd';

//translations
import { useTranslation } from 'react-i18next';

const RiskDistributionCard = (props) => {
    const { fiveByFiveMatrix, threeBythreeMatrix } = props;

    const theme = useTheme();

    const matrixBy5 = Array.from(Array(5)).map(() => Array.from(Array(5)).map(() => []));
    const matrixBy3 = Array.from(Array(3)).map(() => Array.from(Array(3)).map(() => []));
    const [tab, setTab] = useState(1);
    const [type, setType] = useState('inherent');
    const [lines, setLines] = useState([]);

    //translation
    const { t } = useTranslation('riskAssessment');
    const language = localStorage.getItem('i18nextLng');

    const impactByFive = useMemo(() => {
        return language?.includes('fr') ? impactBy5Fr : impactBy5;
    }, [language]);

    const impactByThree = useMemo(() => {
        return language?.includes('fr') ? impactBy3Fr : impactBy3;
    }, [language]);

    useEffect(() => {
        const startingPoints = document.querySelectorAll('.startingPoint');
        const arr = [];
        if (startingPoints.length) {
            setLines(startingPoints.length);
            startingPoints.forEach((point) => {
                const start = point.classList[1];
                const IRRisk = point.classList[2];
                const ele2 = document.querySelector(`.${start.replace('-IR', '')}-RR`);
                const RRRisk = ele2.classList[1];
                arr.push({ name: start.replace('-IR', ''), IRRisk, RRRisk });
            });
            setLines(arr);
        }
    }, [tab, fiveByFiveMatrix, threeBythreeMatrix]);

    useEffect(() => {
        const startingPoints = document.querySelectorAll('.startingPoint');
        if (tab && startingPoints.length && lines.length && lines.length === startingPoints.length) {
            startingPoints.forEach((point) => {
                const start = point.classList[1];
                const ele = document.querySelector(`.${start}`);
                const ele2 = document.querySelector(`.${start.replace('-IR', '')}-RR`);
                ConnectFn(ele, ele2, '#ffffff', 2.5);
            });
        }
    }, [lines, tab, fiveByFiveMatrix, threeBythreeMatrix]);

    useEffect(() => {
        const container = document.getElementById('main_section_container');
        container.scrollTop = 0;
    }, [tab]);

    fiveByFiveMatrix &&
        fiveByFiveMatrix.forEach((risk, index) => {
            const IR = {
                likelihood: risk?.inherent_likelihood,
                impact: risk?.inherent_impact,
                id: risk?.id,
            };
            const RR = {
                likelihood: risk?.residual_likelihood,
                impact: risk?.residual_impact,
                id: risk?.id,
            };

            matrixBy5[IR.likelihood - 1][IR.impact - 1].push({
                name: `IR(${index + 1})`,
                id: IR.id,
                className: `${index + 1}`,
            });
            matrixBy5[RR.likelihood - 1][RR.impact - 1].push({
                name: `RR(${index + 1})`,
                id: RR.id,
                className: `${index + 1}`,
                isRR: true,
            });
        });

    threeBythreeMatrix &&
        threeBythreeMatrix.forEach((risk, index) => {
            const IR = { likelihood: risk?.inherent_likelihood, impact: risk?.inherent_impact, id: risk?.id };
            const RR = { likelihood: risk?.residual_likelihood, impact: risk?.residual_impact, id: risk?.id };

            matrixBy3[IR.likelihood - 1][IR.impact - 1].push({
                name: `IR(${index + 1})`,
                id: IR.id,
                className: `${index + 1}`,
            });
            matrixBy3[RR.likelihood - 1][RR.impact - 1].push({
                name: `RR(${index + 1})`,
                id: RR.id,
                className: `${index + 1}`,
                isRR: true,
            });
        });

    //function
    const onChangeTab = async (val) => {
        setTab(val);
    };
    const onTypeSelect = (e) => {
        setType(e.target.value);
    };

    return (
        <Box
            sx={{
                border: '0.4px solid #C5C6D0',
                boxShadow: '0px 8px 20px rgba(88, 88, 88, 0.05)',
                borderRadius: '8px',
                width: '100%',
                height: '100%',
                p: 2,
                [theme.breakpoints.down('xl')]: {
                    height: '100%',
                },
            }}
        >
            <CardHeader
                onChangeTab={onChangeTab}
                indexValue={tab}
                name={t('riskDistributionCard.title')}
                tabList={language?.includes('fr') ? tabListFr : tabList}
                type={type}
                onTypeSelect={onTypeSelect}
            />
            {(tab === 1 ? fiveByFiveMatrix : threeBythreeMatrix)?.length > 0 ? (
                <Box>
                    {tab === 1 ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                            }}
                        >
                            <Box sx={{ marginLeft: 4 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'right',
                                        alignItems: 'center',
                                        mb: 1,
                                        marginRight: '2rem',
                                    }}
                                >
                                    <Typography sx={{ color: '#5C5E67', fontWeight: 500, fontSize: '13px' }}>
                                        <span
                                            style={{
                                                backgroundColor: '#011947',
                                                color: '#ffffff',
                                                padding: '1px 0.2rem',
                                            }}
                                        >
                                            IR
                                        </span>{' '}
                                        {t('riskDistributionCard.inherent')}
                                    </Typography>
                                    <Typography
                                        sx={{ color: '#5C5E67', fontWeight: 500, fontSize: '13px', ml: 1, mr: -3.5 }}
                                    >
                                        <span
                                            style={{
                                                backgroundColor: '#395BA9',
                                                color: '#ffffff',
                                                padding: '1px 0.1rem',
                                            }}
                                        >
                                            RR
                                        </span>{' '}
                                        {t('riskDistributionCard.residual')}
                                    </Typography>
                                </Box>
                                <Box>
                                    {' '}
                                    <Box sx={{ position: 'relative' }}>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                transform: 'rotateZ(270deg)',
                                                color: '#8F9099',
                                                position: 'absolute',
                                                left: '-60px',
                                                bottom: '-135px',
                                            }}
                                        >
                                            {t('riskDistributionCard.likelihood')}
                                        </Typography>
                                    </Box>
                                    <div className="rectangle-container">
                                        {lines.length
                                            ? lines.map((line) => (
                                                  <Tooltip
                                                      key={line.name}
                                                      placement="bottomLeft"
                                                      title={
                                                          <p>
                                                              {t('riskDistributionCard.riskFrom')}{' '}
                                                              <strong style={{ fontSize: '12px' }}>
                                                                  {by5riskLevel(line.IRRisk).type}
                                                              </strong>{' '}
                                                              {t('riskDistributionCard.to')}{' '}
                                                              <strong style={{ fontSize: '12px' }}>
                                                                  {by5riskLevel(line.RRRisk).type}
                                                              </strong>
                                                          </p>
                                                      }
                                                  >
                                                      <div className={`line-element ${line.name}`}></div>
                                                  </Tooltip>
                                              ))
                                            : null}
                                        {matrixBy5?.reverse()?.map((rows, rowIndex) => {
                                            const likelihood = 5 - rowIndex;
                                            return (
                                                <div key={likelihood} className="rectangle_row">
                                                    <Rectangle
                                                        tab={tab}
                                                        level={by5riskLevel}
                                                        likelihood={
                                                            language?.includes('fr')
                                                                ? likelihoodBy5Fr[5 - likelihood]?.name
                                                                : likelihoodBy5[5 - likelihood]?.name
                                                        }
                                                    />
                                                    {rows?.map((cols, colIndex) => {
                                                        const impact = colIndex + 1;
                                                        return (
                                                            <Rectangle
                                                                key={impact}
                                                                value={impact * likelihood}
                                                                tab={5}
                                                                cols={cols}
                                                                level={by5riskLevel}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                        <div className="rectangle_row">
                                            {['', ...impactByFive].map((impact, index) => (
                                                <Rectangle
                                                    key={index}
                                                    tab={tab}
                                                    level={by5riskLevel}
                                                    impact={!impact ? ' ' : impact.name}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </Box>
                                <Typography
                                    sx={{ fontSize: 14, textAlign: 'center', color: '#5C5E67', mt: 1, fontWeight: 500 }}
                                >
                                    {t('riskDistributionCard.impact')}
                                </Typography>
                            </Box>
                            {fiveByFiveMatrix?.length > 0 ? (
                                <Box
                                    sx={{
                                        mt: 5,
                                        [theme.breakpoints.down('xl')]: {
                                            width: '100%',
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            mb: 1,
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontWeight: '500',
                                                fontSize: '12px',
                                                color: '#303034',
                                                width: '50px',
                                            }}
                                        >
                                            #
                                        </span>
                                        <span
                                            style={{
                                                fontWeight: '600',
                                                fontSize: '10px',
                                                color: '#303034',
                                                width: '182px',
                                            }}
                                        >
                                            {t('riskDistributionCard.description')}
                                        </span>
                                        <span
                                            style={{
                                                fontWeight: '600',
                                                fontSize: '10px',
                                                color: '#303034',
                                                width: '171px',
                                            }}
                                        >
                                            {t('riskDistributionCard.treatment')}
                                        </span>
                                    </Box>
                                    {fiveByFiveMatrix?.map((item, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                backgroundColor: '#FF5449',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                borderBottom: '0.5px solid #ffffff',
                                                px: 1,
                                                py: 1,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontWeight: '500',
                                                    fontSize: '12px',
                                                    color: '#303034',
                                                    width: '50px',
                                                }}
                                            >
                                                {index + 1}
                                            </span>
                                            <span
                                                style={{
                                                    fontWeight: '600',
                                                    fontSize: '10px',
                                                    color: '#303034',
                                                    width: '182px',
                                                }}
                                            >
                                                {item?.threat_description}
                                            </span>
                                            <span
                                                style={{
                                                    fontWeight: '600',
                                                    fontSize: '10px',
                                                    color: '#303034',
                                                    width: '171px',
                                                }}
                                            >
                                                {item?.treatment_option}
                                            </span>
                                        </Box>
                                    ))}
                                </Box>
                            ) : null}
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                [theme.breakpoints.down('xl')]: {
                                    display: 'block',
                                },
                            }}
                        >
                            <Box sx={{ width: '37%', marginLeft: 3 }}>
                                <Box sx={{ display: 'flex', float: 'right', alignItems: 'center', mb: 1 }}>
                                    <Typography sx={{ color: '#5C5E67', fontWeight: 500, fontSize: '13px' }}>
                                        <span
                                            style={{
                                                backgroundColor: '#011947',
                                                color: '#ffffff',
                                                padding: '1px 0.3rem',
                                            }}
                                        >
                                            IR
                                        </span>{' '}
                                        {t('riskDistributionCard.inherent')}
                                    </Typography>
                                    <Typography
                                        sx={{ color: '#5C5E67', fontWeight: 500, fontSize: '13px', ml: 1.5, mr: -16 }}
                                    >
                                        <span
                                            style={{
                                                backgroundColor: '#395BA9',
                                                color: '#ffffff',
                                                padding: '1px 0.3rem',
                                            }}
                                        >
                                            RR
                                        </span>{' '}
                                        {t('riskDistributionCard.residual')}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box sx={{ position: 'relative' }}>
                                        {' '}
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                transform: 'rotateZ(270deg)',
                                                color: '#8F9099',
                                                position: 'absolute',
                                                left: '-60px',
                                                bottom: '-110px',
                                            }}
                                        >
                                            {t('riskDistributionCard.likelihood')}
                                        </Typography>
                                    </Box>
                                    <div className="rectangle-container1">
                                        {lines.length
                                            ? lines.map((line) => (
                                                  <Tooltip
                                                      key={line.name}
                                                      placement="bottomLeft"
                                                      title={
                                                          <p>
                                                              {t('riskDistributionCard.riskFrom')}{' '}
                                                              <strong style={{ fontSize: '12px' }}>
                                                                  {by3riskLevel(line.IRRisk).type}
                                                              </strong>{' '}
                                                              {t('riskDistributionCard.to')}{' '}
                                                              <strong style={{ fontSize: '12px' }}>
                                                                  {by3riskLevel(line.RRRisk).type}
                                                              </strong>
                                                          </p>
                                                      }
                                                  >
                                                      <div className={`line-element ${line.name}`}></div>
                                                  </Tooltip>
                                              ))
                                            : null}
                                        {matrixBy3?.reverse()?.map((rows, rowIndex) => {
                                            const likelihood = 3 - rowIndex;
                                            return (
                                                <div key={likelihood} className="rectangle_row1">
                                                    <Rectangle
                                                        tab={tab}
                                                        level={by3riskLevel}
                                                        likelihood={
                                                            language?.includes('fr')
                                                                ? likelihoodBy3Fr[3 - likelihood]?.name
                                                                : likelihoodBy3[3 - likelihood]?.name
                                                        }
                                                    />
                                                    {rows?.map((cols, colIndex) => {
                                                        const impact = colIndex + 1;
                                                        return (
                                                            <Rectangle
                                                                key={impact}
                                                                value={impact * likelihood}
                                                                tab={tab}
                                                                cols={cols}
                                                                level={by3riskLevel}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                        <div className="rectangle_row1">
                                            {['', ...impactByThree].map((impact, index) => (
                                                <Rectangle
                                                    key={index}
                                                    tab={tab}
                                                    level={by3riskLevel}
                                                    impact={!impact ? ' ' : impact.name}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </Box>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        textAlign: 'center',
                                        color: '#5C5E67',
                                        mt: 1,
                                        ml: '12rem',
                                        fontWeight: 500,
                                    }}
                                >
                                    {t('riskDistributionCard.impact')}
                                </Typography>
                            </Box>
                            {threeBythreeMatrix?.length > 0 ? (
                                <Box
                                    sx={{
                                        width: '35%',
                                        mt: 5,
                                        [theme.breakpoints.down('xl')]: {
                                            width: '100%',
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            mb: 1,
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontWeight: '500',
                                                fontSize: '12px',
                                                color: '#303034',
                                                width: '50px',
                                            }}
                                        >
                                            #
                                        </span>
                                        <span
                                            style={{
                                                fontWeight: '600',
                                                fontSize: '10px',
                                                color: '#303034',
                                                width: '182px',
                                            }}
                                        >
                                            {t('riskDistributionCard.distribution')}
                                        </span>
                                        <span
                                            style={{
                                                fontWeight: '600',
                                                fontSize: '10px',
                                                color: '#303034',
                                                width: '171px',
                                            }}
                                        >
                                            {t('riskDistributionCard.treatment')}
                                        </span>
                                    </Box>
                                    {threeBythreeMatrix?.map((item, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                backgroundColor: '#FF5449',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                borderBottom: '0.5px solid #ffffff',
                                                px: 1,
                                                py: 1,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontWeight: '500',
                                                    fontSize: '12px',
                                                    color: '#303034',
                                                    width: '50px',
                                                }}
                                            >
                                                {index + 1}
                                            </span>
                                            <span
                                                style={{
                                                    fontWeight: '600',
                                                    fontSize: '10px',
                                                    color: '#303034',
                                                    width: '182px',
                                                }}
                                            >
                                                {item?.threat_description}
                                            </span>
                                            <span
                                                style={{
                                                    fontWeight: '600',
                                                    fontSize: '10px',
                                                    color: '#303034',
                                                    width: '171px',
                                                }}
                                            >
                                                {item?.treatment_option}
                                            </span>
                                        </Box>
                                    ))}
                                </Box>
                            ) : null}
                        </Box>
                    )}
                </Box>
            ) : (
                <Stack direction="column" justifyContent="center" alignItems="center" sx={{ marginTop: 10 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Skeleton
                            variant="rectangular"
                            width={120}
                            height={20}
                            sx={{ marginRight: 2, backgroundColor: '#F0F0FA' }}
                            animation={false}
                        />
                        <Skeleton
                            variant="rectangular"
                            width={120}
                            height={20}
                            sx={{ marginRight: 2, backgroundColor: '#F0F0FA' }}
                            animation={false}
                        />
                        <Skeleton
                            variant="rectangular"
                            width={120}
                            height={20}
                            sx={{ backgroundColor: '#F0F0FA' }}
                            animation={false}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                        <Skeleton
                            variant="rectangular"
                            width={190}
                            height={20}
                            sx={{ marginRight: 2, backgroundColor: '#F0F0FA' }}
                            animation={false}
                        />
                        <Skeleton
                            variant="rectangular"
                            width={190}
                            height={20}
                            sx={{ backgroundColor: '#F0F0FA' }}
                            animation={false}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                        <Typography sx={{ color: '#ACAAAF', fontSize: '10px', fontWeight: 600, width: '100px' }}>
                            {t('riskDistributionCard.low')}
                        </Typography>
                        <Typography sx={{ color: '#ACAAAF', fontSize: '10px', fontWeight: 600, width: '100px' }}>
                            {t('riskDistributionCard.medium')}
                        </Typography>
                        <Typography sx={{ color: '#ACAAAF', fontSize: '10px', fontWeight: 600, width: '100px' }}>
                            {t('riskDistributionCard.high')}
                        </Typography>
                    </Box>
                    <Typography sx={{ color: '#46464A', fontSize: '13px', fontWeight: 600, mt: 2 }}>
                        {t('riskDistributionCard.noRisk')}
                    </Typography>
                </Stack>
            )}
        </Box>
    );
};

export default RiskDistributionCard;
