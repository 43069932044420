import React from 'react';
import { AppFormSelect, AppFormInput } from 'components/new_components/forms';
import { connect } from 'react-redux';
import { Box } from '@mui/material';

//translations
import { useTranslation } from 'react-i18next';

const ReportControlForm = (props) => {
    const { residualImpact, residualLikelihood } = props;

    //translation
    const { t } = useTranslation('riskAssessment');

    return (
        <Box sx={{ overflow: 'scroll !Important' }}>
            <AppFormInput
                type="text"
                label={t('intelligenceAssessment.control')}
                name="existing_controls"
                placeholder={t('intelligenceAssessment.existingControl')}
            />
            <AppFormSelect
                type="text"
                label={t('intelligenceAssessment.residualLikelihood')}
                name="residual_likelihood"
                options={residualLikelihood}
                defaultValue={t('intelligenceAssessment.residualLikelihoodValue')}
            />
            <AppFormSelect
                type="text"
                label={t('intelligenceAssessment.residualImpact')}
                name="residual_impact"
                options={residualImpact}
                defaultValue={t('intelligenceAssessment.residualImpactValue')}
            />
            <AppFormInput
                type="text"
                label={t('intelligenceAssessment.comments')}
                name="comments"
                multiline
                placeholder={t('intelligenceAssessment.recommended')}
            />
        </Box>
    );
};

// const AppTooltip = styled(({ className, ...props }) => (
//     <Tooltip classes={{ popper: className }} {...props} placement="top" arrow />
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: theme.palette.primary[900],
//         color: theme.palette.white.main,
//         boxShadow: theme.shadows[1],
//         fontSize: 11,
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//     },
//     [`& .${tooltipClasses.arrow}`]: {
//         color: '#0E2C66',
//         fontSize: 16,
//     },
// }));

const mapStateToProps = (state) => {
    return {
        all_merchant_infrastructures: state?.merchantReducers?.all_merchant_infrastructures,
        all_computers: state?.merchantReducers?.all_computers,
    };
};
export default connect(mapStateToProps, {})(ReportControlForm);
