import { Close } from '@mui/icons-material';
import { Box, Chip, IconButton, Typography, useTheme } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppSubmitButton } from 'components/new_components/forms';
import { AppFormSelect } from 'components/new_components/forms';
import { AppForm } from 'components/new_components/forms';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { CreateTraining } from 'store/actions/trainingActions';
import { trainingValidation } from '../utils/validation';

//translations
import { useTranslation } from 'react-i18next';

const AssignTrainingModal = (props) => {
    const { open, onClose, all_employees, CreateTraining, course, all_training } = props;
    const theme = useTheme();

    // state
    const [loading, setLoading] = useState({});
    const [payload, setPayload] = useState({ employees: [] });

    //translation
    const { t } = useTranslation('training');

    // memos
    const employees = useMemo(() => {
        return all_employees?.map((employee) => ({
            name: `${employee?.first_name} ${employee?.last_name}`,
            value: employee?.id,
        }));
    }, [all_employees]);

    // functions
    const handleClose = () => {
        onClose();
        setTimeout(() => setPayload({}), 500);
    };
    const handleSubmit = async (values) => {
        const enrollees = values.employees?.map((employee) => parseInt(employee));
        setLoading({ ...loading, submit: true });
        const res = await CreateTraining({ enrollees, course });
        setLoading({ ...loading, submit: false });
        if (res?.success) {
            toast.success('Successfully assign your employee(s) to this training, they will get a mail soon.');
            handleClose();
        } else {
            toast.error("Something went wrong, couldn't assign training to employee(s)");
        }
    };

    // useEffect
    useEffect(() => {
        if (open) {
            setPayload({ employees: '' });
        }
    }, [open]);

    return (
        <AppForm initialValues={payload} onSubmit={handleSubmit} validate={trainingValidation}>
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                title={
                    <Typography sx={{ fontSize: 24, fontWeight: 700, color: theme.palette.gray[800] }}>
                        {t('assignTrainingModal.title')}
                    </Typography>
                }
                headerAction={
                    <IconButton color="disabled" onClick={handleClose}>
                        <Close />
                    </IconButton>
                }
                titleStyle={{ borderBottom: `1px solid ${theme.palette.neutral[90]}` }}
                width={540}
            >
                <Box sx={{ py: 2, pb: 5 }}>
                    <Typography sx={{ fontWeight: 600, fontSize: 14, color: theme.palette.primary[900] }}>
                        {t('assignTrainingModal.introduction')}
                    </Typography>
                    <Box sx={{ py: 3 }}>
                        <AppFormSelect
                            label={t('assignTrainingModal.select')}
                            multiple
                            options={employees?.filter(
                                (employee) => !all_training?.find((training) => employee?.value === training?.enrollee)
                            )}
                            name="employees"
                            loading={loading.content}
                            noDataLabel={t('assignTrainingModal.noData')}
                            renderValue={(selected) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 1.2,
                                    }}
                                >
                                    {selected?.map((value) => {
                                        const employee = employees?.find((employee) => employee?.value == value);
                                        return (
                                            <Chip
                                                sx={{
                                                    backgroundColor: theme.palette.gray[95],
                                                    borderRadius: 1,
                                                    p: 0.5,
                                                    color: theme.palette.gray[40],
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                }}
                                                key={value}
                                                label={employee?.name}
                                            />
                                        );
                                    })}
                                </Box>
                            )}
                        />
                        <AppSubmitButton
                            text={t('assignTrainingModal.addEmployee')}
                            variant="contained"
                            color="primary"
                            loadingPosition="center"
                            loading={loading?.submit}
                            sx={{ mt: 5 }}
                        />
                    </Box>
                </Box>
            </AppCenteredModal>
        </AppForm>
    );
};
const mapStateToProps = (state) => ({
    all_employees: state?.merchantReducers?.all_merchant_people,
    all_training: state?.trainingReducers?.all_training,
});
export default connect(mapStateToProps, { CreateTraining })(AssignTrainingModal);
