import React from 'react';

//components
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { Box, Typography, IconButton, Button } from '@mui/material';
import { useTheme } from '@mui/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Utils
import { AssessmentOptions } from '../../utils/constants';
import { AssessmentOptionsFr } from '../../utils/constants';

//translation
import { useTranslation } from 'react-i18next';

const PageViewModal = (props) => {
    const { open, handleType, handleClose } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('riskAssessment');
    const language = localStorage.getItem('i18nextLng');

    //constant
    const AssessmentOption = language?.includes('fr') ? AssessmentOptionsFr : AssessmentOptions;

    return (
        <AppCenteredModal
            open={open}
            width={'150px'}
            height={275}
            sx={{ overflow: 'scroll' }}
            padding="0px"
            overflow="hidden"
        >
            <Box sx={{ my: '2rem', mx: '2rem' }}>
                <Typography
                    sx={{ color: theme.palette.primary[700], fontSize: '16px', fontWeight: 700, textAlign: 'center' }}
                >
                    {t('runAssessmentTab.runAs')}
                </Typography>
                <Box sx={{ mt: '1rem' }}>
                    {AssessmentOption?.map((assessment, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                border: '1px solid #F0F0FA',
                                marginBottom: '1rem',
                                p: 0.8,
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleType(assessment?.type)}
                        >
                            <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.gray[40] }}>
                                {assessment?.name}
                            </Typography>
                            <IconButton color="primary">
                                <ChevronRightIcon sx={{ fontWeight: 700 }} />
                            </IconButton>
                        </Box>
                    ))}
                    <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                        <Button variant="contained" onClick={handleClose}>
                            {t('runAssessmentTab.close')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </AppCenteredModal>
    );
};

export default PageViewModal;
