import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { useTheme } from '@mui/styles';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import SOC2 from 'assets/img/Soc2.svg';
import ISO22701 from 'assets/img/iso27001.svg';
import ISO22301 from 'assets/img/Iso22301.svg';
import NDPR from 'assets/img/Ndpr.svg';
import PCIDSS from 'assets/img/Pcidss.svg';
import BadgeCheck from 'assets/img/BadgeCheck.svg';
import { connect } from 'react-redux';
import { GetCompanyDetails } from 'store/actions/merchantActions';
import { toast } from 'react-toastify';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const AvailableStandards = (props) => {
    const { scan_items, merchant_info } = props;
    const theme = useTheme();
    const [expanded, setExpanded] = useState('');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const getCompanyDetails = async () => {
        const res = await GetCompanyDetails();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        getCompanyDetails();
    }, []);

    const merchant_standards = merchant_info?.standards?.split(',');

    return (
        <Box sx={{ mx: 2, my: 3, border: `1px solid ${theme.palette.neutral[95]}`, borderRadius: '5px', p: 2 }}>
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: '12px',
                    color: '#303034',
                    pb: 1,
                    borderBottom: `1px solid ${theme.palette.neutral[95]}`,
                }}
            >
                Available Standards
            </Typography>
            <Box sx={{ mt: 2, pb: 2 }}>
                {scan_items?.map((item) => (
                    <Box sx={{ mb: 2 }} key={item?.id}>
                        <Accordion expanded={expanded === item?.id} onChange={handleChange(item?.id)}>
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                                sx={{ background: expanded === item?.id ? '#F8F8F8' : '#FFFFFF' }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        color: theme.palette.gray[30],
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {item?.name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {item?.applicable_standards?.split(',').map((standard, index) => {
                                        return merchant_standards?.includes(
                                            standard?.replace(' ', '')?.toLowerCase()
                                        ) ? (
                                            <Box
                                                key={index}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    mr: 1,
                                                    border: `1px solid ${theme.palette.neutral[95]}`,
                                                    px: 0.5,
                                                    borderRadius: '5px',
                                                    width: '130px',
                                                    backgroundColor: !merchant_standards?.includes(
                                                        standard?.replace(' ', '')?.toLowerCase()
                                                    )
                                                        ? '#DCDEE5'
                                                        : '',
                                                }}
                                            >
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        px: 1,
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            standard?.replace(' ', '') === 'PCIDSS'
                                                                ? PCIDSS
                                                                : standard?.replace(' ', '') === 'ISO27001'
                                                                ? ISO22701?.replace(' ', '')
                                                                : standard?.replace(' ', '') === 'SOC2'
                                                                ? SOC2
                                                                : standard?.replace(' ', '') === 'ISO22301'
                                                                ? ISO22301
                                                                : NDPR
                                                        }
                                                        alt="standard"
                                                        style={{
                                                            width: '50px',
                                                            height: '30px',
                                                            margin: '0.8rem -0.9rem 0 -0.5rem',
                                                        }}
                                                    />
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 500,
                                                            fontSize: '12px',
                                                            color: '#5E5E62',
                                                        }}
                                                    >
                                                        {standard}
                                                    </Typography>
                                                </Box>
                                                <img src={BadgeCheck} alt="BadgeCheck" />
                                            </Box>
                                        ) : null;
                                    })}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant_info: state?.merchantReducers?.merchant_info,
    };
};

export default connect(mapStateToProps, {})(AvailableStandards);
