import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Dialog, DialogContent, DialogTitle, Slide, Stack, DialogActions, Typography, IconButton } from '@mui/material';
import { Close, Info, InfoOutlined } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const AppModal = (props) => {
    const theme = useTheme();
    const {
        open,
        handleClose,
        children,
        title,
        width,
        actions,
        height,
        headerAction,
        padding,
        maxHeight,
        top,
        sub_title,
        hasCloseBtn,
        blockchain,
    } = props;
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={handleClose}
            sx={{
                '& .MuiDialog-container': {
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                },
            }}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 370,
                    borderRadius: 4,
                    position: 'relative',
                    top: top ? top : 105,
                    right: 60,
                    width,
                    height: height ? height : 'unset',
                    maxHeight: maxHeight ? maxHeight : 550,
                    m: 0,
                    zIndex: 1002,
                    padding: padding ? padding : 'unset',
                },
            }}
            scroll="paper"
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: 2, py: 1 }}>
                {title && (
                    <DialogTitle sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.gray[900] }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            {title}
                            {headerAction}
                        </Stack>
                        {sub_title && (
                            <Typography
                                sx={{
                                    color: blockchain ? '' : '#980303',
                                    fontSize: blockchain ? '16px' : '12px',
                                    marginTop: '1rem',
                                    fontWeight: blockchain ? 400 : '',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {blockchain ? (
                                    <Info sx={{ width: 30, height: 30, marginRight: '5px', color: '#E2B208' }} />
                                ) : (
                                    <InfoOutlined sx={{ width: 15, height: 15, marginRight: '5px' }} />
                                )}
                                <Typography component={'span'} sx={{ marginLeft: blockchain ? '0.5rem' : '' }}>
                                    {sub_title}
                                </Typography>
                            </Typography>
                        )}
                    </DialogTitle>
                )}
                {hasCloseBtn && (
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                )}
            </Stack>
            <DialogContent
                sx={{
                    padding: padding ? padding : '20px 32px',
                    pb: 0.2,
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary[900] + '30',
                        borderRadius: '10px',
                    },
                }}
            >
                {children}
            </DialogContent>
            {actions && <DialogActions sx={{ display: 'block', px: 3 }}>{actions}</DialogActions>}
        </Dialog>
    );
};

export default AppModal;
