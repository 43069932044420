import React, { useState } from 'react';
import { Box, IconButton, Menu, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MoreVert } from '@mui/icons-material';
import DownloadReportModal from './DownloadReport';
import RerunReportModal from './CodeReviewRerun';

const CodeReviewAction = (props) => {
    const { row } = props;
    const theme = useTheme();

    // // state
    const [anchor, setAnchor] = useState(null);
    const [open, setOpen] = useState('');

    // // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const closeModal = () => {
        setOpen(false);
    };

    const openRerunModal = () => {
        setOpen('rerun');
        closeMenu();
    };
    const openDownloadModal = () => {
        setOpen('download');
        closeMenu();
    };

    return (
        <>
            <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <Menu elevation={10} open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                <Box
                    onClick={row?.status === 'rerunning' || row?.status === 'running' ? '' : openRerunModal}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        textAlign: 'left',
                        padding: '0.5rem 1rem',
                        cursor: row?.status === 'rerunning' || row?.status === 'running' ? 'not-allowed' : 'pointer',
                    }}
                >
                    <Typography
                        component={'h6'}
                        align="left"
                        sx={{
                            color: row?.status === 'rerunning' || row?.status === 'running' ? '#B8BCCC' : '#2B2D33',
                            fontSize: '14px',
                            fontWeight: 600,
                            letterSpacing: 0.1,
                        }}
                    >
                        Rerun Code Review
                    </Typography>
                    <Typography
                        sx={{
                            color:
                                row?.status === 'rerunning' || row?.status === 'running'
                                    ? '#B8BCCC'
                                    : theme.palette.gray[900],
                            fontSize: 10,
                        }}
                        component={'p'}
                    >
                        Run code review after fixing
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        textAlign: 'left',
                        padding: '0.5rem 1rem',
                        cursor: 'pointer',
                    }}
                    onClick={openDownloadModal}
                >
                    <Typography
                        component={'h6'}
                        align="left"
                        sx={{ color: '#2B2D33', fontSize: '14px', fontWeight: 600, letterSpacing: 0.1 }}
                    >
                        Download Report
                    </Typography>
                    <Typography sx={{ color: theme.palette.gray[900], fontSize: 10 }} component={'p'}>
                        See the report of a code review
                    </Typography>
                </Box>
            </Menu>
            {open === 'download' && <DownloadReportModal open={open} handleClose={closeModal} row={row} />}
            {open === 'rerun' && <RerunReportModal open={open} handleClose={closeModal} row={row} />}
        </>
    );
};

export default CodeReviewAction;
