import {
    ALL_AUDIT_CATEGORIES,
    ALL_ACCESSOR_CATEGORIES,
    ALL_AUDIT_ANSWERS,
    ALL_AUDIT_REQUESTS,
    ALL_ADMIN_AUDITORS,
    ALL_QA_REVIEWERS,
    ALL_ACCESSOR_ANSWERS,
    MERCHANT_CERTIFICATE,
    ALL_MERCHANTS_CERTIFICATE,
    CERTIFICATE_VALIDDATE,
    CERTIFICATE_DETAILS,
} from '../constants';

import axiosInstance from '../../utils/https';
import { baseUrl } from 'config';
import axios from 'axios';

// A U D I T   C A T E G O R I E S
export const GetAllAuditCategories =
    (standard = '') =>
    async (dispatch) => {
        try {
            const res = await axiosInstance().get(`/compliance/audit_category/?compliance=${standard}`);

            if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
            dispatch({ type: ALL_AUDIT_CATEGORIES, payload: res?.data });
            return {
                success: true,
                message: res?.message,
            };
        } catch (err) {
            return { success: false, message: err?.message };
        }
    };
export const CreateAuditCategory = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/audit_category/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAuditCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const UploadReportFile = (credentials) => async () => {
    try {
        const res = await axiosInstance().post(`/compliance/upload_any_report/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);

        return {
            success: true,
            message: res?.message,
            report: res?.data?.report,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const EditAuditCategory = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/audit_category/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAuditCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const DeleteAuditCategory = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/audit_category/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAuditCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//  A U D I T   A N S W E R S
export const GetAllAuditAnswers = (merchantId) => async (dispatch, getState) => {
    const merchant = merchantId || getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/audit_answers?merchant=${merchant}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_AUDIT_ANSWERS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const AnswerAuditCategory = (credentials) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/compliance/audit_answers/`, { ...credentials, merchant });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAuditAnswers());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const UpdateAuditAnswer = (credentials, id) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().patch(`/compliance/audit_answers/${id}/`, { ...credentials, merchant });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAuditAnswers());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//  A U D I T   R E Q U E S T S
export const GetAllAuditRequests = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/report_request/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_AUDIT_REQUESTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAllAuditRequestsImplementer = (company) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/report_request/?merchant=${company}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_AUDIT_REQUESTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const CreateAuditRequest = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/report_request/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAuditRequests());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const UpdateAuditRequest = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/report_request/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAuditRequests());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const DeleteAuditRequest = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/report_request/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAuditRequests());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//   Q A   R E V I W E R   D E T A I L S
export const GetAllQAReviwers = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/qa_details/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_QA_REVIEWERS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const CreateQAReviwer = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/qa_details/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllQAReviwers());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const UpdateQAReviwer = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/qa_details/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllQAReviwers());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const DeleteQAReviwer = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/qa_details/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllQAReviwers());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//   A D M I N   A U D I T O R   D E T A I L S
export const GetAllAdminAuditors = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/admin_details/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ADMIN_AUDITORS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const CreateAdminAuditor = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/user_mgt/admin_details/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAdminAuditors());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const UpdateAdminAuditor = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/admin_details/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAdminAuditors());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const DeleteAdminAuditor = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/admin_details/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAdminAuditors());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const GetAllMerchantCertificates = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/cert`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANTS_CERTIFICATE, payload: res?.data });
        return {
            success: true,
            message: res?.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// A C C E S S O R   C A T E G O R I E S
export const GetAllAccessorCategories = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/aud_category`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ACCESSOR_CATEGORIES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const CreateAccessortCategory = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/aud_category/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAccessorCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantCertificate = (company) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'implementer' ? company : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/cert/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: MERCHANT_CERTIFICATE, payload: res?.data });
        return {
            success: true,
            message: res?.message,
            data: res,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const CreateAccessortSubCategory = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/aud_subcategory/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAccessorCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const GenerateMerchantCertificate = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/cert/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllMerchantCertificates());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const EditAccessorSubCategory = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/aud_subcategory/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAccessorCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetCertificateValidity = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/barcode_verify/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: CERTIFICATE_VALIDDATE, payload: res });
        return {
            success: true,
            message: res?.message,
            data: res?.data,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const EditAccessorCategory = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/aud_category/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAccessorCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const CertificateDetails = (merchant_id) => async (dispatch) => {
    try {
        const res = await axios.get(`${baseUrl}/compliance/cert/?confirmation_token=${merchant_id}`);
        if (res?.data?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: CERTIFICATE_DETAILS, payload: res?.data?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const DeleteAccessorCategory = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/aud_category/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAccessorCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const DeleteAccessorSubCategory = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/aud_subcategory/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAccessorCategories());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// A C C E S S O R   A N S W E R S
export const GetAllAccessorAnswers = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/aud_answers`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_ACCESSOR_ANSWERS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const AnswerAccessorQuestion = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/aud_answers/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAccessorAnswers());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const UpdateAccessorQuestion = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/aud_answers/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllAccessorAnswers());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
