import { validation } from 'validate';

export const exemptionModalValidation = (values) => {
    const errors = {};
    const reasonValidate = validation(values.reason, 'Reason', true);

    if (!reasonValidate.isValid) {
        errors.reason = reasonValidate.errorMessage;
    }

    return errors;
};

export const assignmentModalValidation = (values) => {
    const errors = {};
    const assignValidate = validation(values.assigned_to, 'User', true);

    if (!assignValidate.isValid) {
        errors.assigned_to = assignValidate.errorMessage;
    }

    return errors;
};
