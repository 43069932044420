import React from 'react';
import CenteredModal from 'components/new_components/CenteredModal';
import AppButton from 'components/new_components/AppButton';
import { Box, Stack } from '@mui/material';
import { Info } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { Link } from 'react-router-dom';
//translation
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Setup2FaModal = (props) => {
    const { open } = props;
    const theme = useTheme();

    const { t } = useTranslation('accounts');
    const user_type = useSelector((state) => state?.generalReducers?.user_info?.user_type);

    return (
        <CenteredModal open={open} width={450} height={300}>
            <Box
                sx={{
                    fontWeight: 500,
                    color: theme.palette.gray[800],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    p: 2,
                }}
            >
                <Info sx={{ width: 70, height: 70, color: '#E2B208', mb: 1 }} />
                <span style={{ fontSize: '18px', fontWeight: 700 }}>{t('twoFactorAuth.setup2FaModal.header')}</span>

                <p style={{ fontSize: '14px', fontWeight: 500, marginTop: '1rem' }}>
                    {t('twoFactorAuth.setup2FaModal.subtitle')}
                </p>
            </Box>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ m: 1.5 }}>
                <AppButton
                    name={t('twoFactorAuth.setup2FaModal.cta')}
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={{
                        pathname:
                            user_type === 'auditor'
                                ? '/auditor/settings/account'
                                : user_type === 'admin' && user_type !== 'aud_admin'
                                ? '/admin/setup-two-factor-authenication'
                                : '/merchant/settings',
                        state: { tab: '2fa' },
                    }}
                    sx={{ fontSize: 12 }}
                />
            </Stack>
        </CenteredModal>
    );
};

export default Setup2FaModal;
