import React, { useEffect, useMemo, useState } from 'react';

const TransferMethod = ({ localStates, setLocalStates, setRiskIdentificationTabStates, error }) => {
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [tempMethod, setTempMethod] = useState('');

    const defaultMethods = useMemo(
        () =>
            ['Insurance', 'Outsourcing'].filter((item) => {
                if (!localStates.transferMethod) return item;

                return item.toLowerCase().includes(localStates.transferMethod.toLowerCase());
            }),
        [localStates.transferMethod]
    );

    useEffect(() => {
        if (isInputFocused) {
            if (defaultMethods.length === 0) {
                setIsDropdownOpen(false);
            } else {
                setIsDropdownOpen(true);
            }
        } else {
            setIsDropdownOpen(false);
        }
    }, [isInputFocused, defaultMethods.length]);

    return (
        <div className="relative" onMouseLeave={() => setTempMethod('')}>
            <div className="relative">
                <input
                    type="text"
                    placeholder="Enter or select transfer method"
                    value={localStates.transfer_method}
                    onFocus={() => setIsInputFocused(true)}
                    onChange={(event) => {
                        setLocalStates((prev) => ({
                            ...prev,
                            transfer_method: event.target.value,
                        }));

                        setTempMethod('');
                    }}
                    onBlur={(event) => {
                        setRiskIdentificationTabStates((prev) => ({
                            ...prev,
                            transfer_method: event.target.value || tempMethod,
                        }));
                        setIsInputFocused(false);
                    }}
                    className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 !pr-5 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                />

                <img
                    src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                    alt="Arrow Down Icon"
                    className="object-contain absolute top-1/2 right-[9px] transform -translate-y-1/2"
                />
            </div>

            <div
                className="absolute top-[105%] !w-full !rounded !border !border-[#F1F5F9] !p-2 bg-white flex flex-col max-h-[176px] overflow-y-auto shadow-md"
                hidden={!isDropdownOpen}
            >
                {defaultMethods.map((method, index) => (
                    <button
                        key={index}
                        className="!py-1.5 !px-2 font-medium text-sm bg-white capitalize text-[#64748B] transition-all hover:brightness-95 !text-left"
                        onMouseEnter={() => setTempMethod(method)}
                    >
                        {method}
                    </button>
                ))}
            </div>

            {error && !localStates?.transfer_method && (
                <span className="text-xs text-[#FF0000] font-normal mt-2">Transfer method is required</span>
            )}
        </div>
    );
};
export default TransferMethod;
