import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button, Upload, Select, Radio } from 'antd';
import { AiOutlineInbox } from 'react-icons/ai';
import { accept } from 'validate';

// redux
import { connect } from 'react-redux';
import { CreateOnboardingItem } from 'store/actions/adminActions';

const { Dragger } = Upload;
const { Option } = Select;

const AddModal = (props) => {
    const { open, handleCancel, CreateOnboardingItem, all_merchants } = props;
    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('document');

    // constants
    const { video, pdf, docx, excel, txt, ppt } = accept;
    const options = [
        {
            label: 'Yes, inform the merchants to accept updated policy',
            value: true,
        },
        {
            label: 'No, don’t inform the merchant to accept updated policy',
            value: false,
        },
    ];

    const selectOption = all_merchants?.map((merchant) => ({
        label: merchant?.name || merchant?.owner_detail?.first_name,
        value: merchant?.id,
    }));
    const draggers = {
        name: 'default',
        action: null,
        multiple: false,
        maxCount: 1,
        onChange(info) {
            setData({ ...data, file: info.fileList[0]?.originFileObj });
        },
        accept: type === 'document' ? `${pdf},${docx},${excel},${txt},${ppt}` : video,
    };

    // functions
    const handleClose = () => {
        setData({});
        handleCancel();
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value });
    };
    const handleRadioChange = ({ target: { value } }) => {
        setData({ ...data, notify: value });
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };
    const handleChange = (value) => {
        setData({ ...data, notify_list: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (data?.notify === undefined) {
            toast.info('Specify whether or not to notify merchants');
            return;
        }
        const formData = new FormData();
        formData.append('admin', true);
        formData.append('file', data?.file);
        formData.append('name', data?.name);
        formData.append('tag', type);
        formData.append('notify', data?.notify);
        data?.notify_list?.length && formData.append('notify_list', JSON.stringify(data?.notify_list));

        setLoading(true);
        const res = await CreateOnboardingItem(formData);
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <Modal title="Add Item" open={open} onCancel={handleClose} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="type">Item Type</label>
                        <Select
                            size="large"
                            name="type"
                            id="type"
                            onChange={(value) => setType(value)}
                            value={type}
                            required
                            style={{ width: '100%' }}
                        >
                            <Option value="document">Document</Option>
                            <Option value="video">Video</Option>
                        </Select>
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="name">Item Name</label>
                        <Input
                            type="text"
                            size="large"
                            name="name"
                            id="name"
                            onChange={handleTextChange}
                            value={data.name || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="template_file">Upload Template</label>
                        <Dragger {...draggers}>
                            <p className="ant-upload-drag-icon">
                                <AiOutlineInbox />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                    </Col>
                    <Col span={24} className="mt-14" style={{ marginBottom: '1.7rem' }}>
                        <label htmlFor="notify">Notify</label>
                        <Radio.Group options={options} onChange={handleRadioChange} value={data?.notify} required />
                    </Col>
                    {data?.notify ? (
                        <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="notify">Merchants</label>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Please select"
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={handleChange}
                                options={selectOption}
                            />
                        </Col>
                    ) : null}

                    <Col style={{ marginTop: '5rem' }}>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchants: state?.adminReducers?.all_merchants,
    };
};
export default connect(mapStateToProps, { CreateOnboardingItem })(AddModal);
