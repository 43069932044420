import { Box, Stack } from '@mui/material';
import React, { useState } from 'react';
// import PageHeader from '../../new_components/PageHeader';
import { FaAngleLeft } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from 'components/new_components/AppButton';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import AuditSignatureModal from '../modals/AuditSignatureModal';
import LoadingState from 'components/new_components/LoadingState';
import { useMemo } from 'react';

// redux
import { connect } from 'react-redux';

const AuditPreviewIndex = (props) => {
    const { all_audit_requests } = props;
    const history = useHistory();
    const { standard } = useParams();

    // memos
    const auditRequest = useMemo(() => {
        return all_audit_requests?.find((request) => request?.compliance === standard);
    }, [standard, all_audit_requests]);

    // state
    const [signatureModalOpen, setSignatureModalOpen] = useState(false);
    const [iFrameLoading, setIFrameLoading] = useState(true);

    // functions
    const goBack = () => {
        history.push('/merchant/audits');
    };
    const openSignaturedModal = () => {
        setSignatureModalOpen(true);
    };
    const closeSignautreModal = () => {
        setSignatureModalOpen(false);
    };
    const handleEdit = () => {
        history.push(`/merchant/audits/edit/${standard}`);
    };

    return (
        <div className="-m-6 h-[calc(100%_+_44px)]">
            <div className="flex justify-content-between bg-[#FFFFFF] py-2 px-4 border-b border-[#b8bccc]">
                <div className="flex items-center">
                    <div
                        className="w-8 h-8 flex items-center justify-center bg-[#DAE2FF] cursor-pointer overflow-hidden rounded-full font-bold"
                        onClick={goBack}
                    >
                        <FaAngleLeft className="font-bold" color="#455A64B8" size="25px" />
                    </div>
                    <span className="pl-3 font-bold cursor-pointer">{standard?.toUpperCase()} Report</span>
                </div>
                <Stack direction="row" spacing={2}>
                    <AppButton
                        onClick={openSignaturedModal}
                        variant="contained"
                        name={'Attach Signature'}
                        sx={{ py: 0.5, px: 1.5 }}
                    />
                    <AppButton
                        onClick={handleEdit}
                        icon={<ModeEditOutlineIcon />}
                        variant="outlined"
                        name="Edit"
                        sx={{ py: 0.5, px: 1.5 }}
                    />
                </Stack>
            </div>
            <Box
                sx={{
                    px: 2.5,
                    height: 'calc(100% - 67px)',
                }}
            >
                {iFrameLoading && (
                    <Box
                        sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', my: 3 }}
                    >
                        <LoadingState />
                    </Box>
                )}
                {auditRequest && (
                    <Box
                        sx={{
                            backgroundColor: '#ffffff',
                            width: '90%',
                            maxWidth: 800,
                            height: '100%',
                            m: 'auto',
                            display: iFrameLoading ? 'none' : 'block',
                        }}
                        component="iframe"
                        src={`https://docs.google.com/gview?url=${auditRequest?.spoolreport}&embedded=true`}
                        onLoad={() => setIFrameLoading(false)}
                    />
                )}
            </Box>
            <AuditSignatureModal
                open={signatureModalOpen}
                handleClose={closeSignautreModal}
                standard={standard}
                goBack={goBack}
            />
        </div>
    );
};
const mapStateToProps = (state) => {
    return { all_audit_requests: state?.auditReducers?.all_audit_requests };
};
export default connect(mapStateToProps, {})(AuditPreviewIndex);
