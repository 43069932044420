import React from 'react';
import ReactDOM from 'react-dom';

// theme
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'assets/theme/theme';

import { ToastContainer } from 'react-toastify';

//Css
import './assets/css/tailwind.css';
import 'assets/plugins/nucleo/css/nucleo.css';
// import "@fortawesome/fontawesome-free/css/all.min.css";
import 'assets/scss/argon-dashboard-react.scss';
import 'assets/scss/dashboard.scss';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';

// redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './store';

import { UserGuideContextProvider } from 'store/context/UserGuideContextProvider';
import './i18n';
import SelectedMerchantContextProvider from 'store/context/SelectedMerchantContextProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
export const configuredStore = configureStore();
const queryClient = new QueryClient();

ReactDOM.render(
    <>
        <Provider store={configuredStore.store}>
            <PersistGate loading={null} persistor={configuredStore.persistor}>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={theme}>
                        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                        <CssBaseline />
                        <ToastContainer
                            position="top-right"
                            autoClose={4000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="colored"
                        />
                        <UserGuideContextProvider>
                            <SelectedMerchantContextProvider>
                                <App />
                            </SelectedMerchantContextProvider>
                        </UserGuideContextProvider>
                    </ThemeProvider>
                    <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
                </QueryClientProvider>
            </PersistGate>
        </Provider>
    </>,
    document.querySelector('#root')
);
