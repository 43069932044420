import React, { useState, useEffect } from 'react';
import { Tour } from 'antd';
import { Stack, Typography } from '@mui/material';

const AppUserGuide = (props) => {
    const { open, onClose, steps, targets } = props;
    const [tourSteps, setTourSteps] = useState([]);

    useEffect(() => {
        setTourSteps(steps.map((step, index) => ({ ...step, target: targets[index] })));
    }, [steps, targets]);

    return (
        <>
            <Tour open={open} onClose={onClose} steps={tourSteps} />
        </>
    );
    // return <></>;
};

export const UserGuideHeader = ({ title, id = 1, length = 1 }) => {
    return (
        <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{ color: '#395BA9', fontWeight: 600, fontSize: '16px' }}>{title}</Typography>
            <Typography sx={{ color: '#77777A', fontSize: '14px' }}>
                {id}/{length}
            </Typography>
        </Stack>
    );
};

export default AppUserGuide;
