import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMemo, useState } from 'react';

// core component
// import { Tag } from 'antd';
import GroupCard from '../../common/GroupCard';
import ControlExemptModal from './ControlExemptModal';

//redux
import { connect } from 'react-redux';
import { getType } from 'utils';

const ControlCard = (props) => {
    const theme = useTheme();
    const {
        id,
        title,
        description,
        metric,
        resourceLabel,
        onViewChange,
        exempted,
        all_exempted_controls,
        openTaskAssignModal,
        isControlAssigned,
        isSubControlAssigned,
        resourceAssigned,
    } = props;
    // const [activeHoverCard, setActiveHoverCard] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [activeTitle, setActiveTitle] = useState('');
    const [type, setType] = useState('');
    const [anchor, setAnchor] = useState(null);
    const role = getType('role');

    // const user_type = useSelector((state) => state?.authReducers?.user_type);
    const openMenu = (e) => {
        e.stopPropagation();
        setAnchor(e.target);
    };
    const closeMenu = () => setAnchor(null);

    const handleOpenTaskModal = (e) => {
        e.stopPropagation();
        openTaskAssignModal('requirement', {
            quality_mgt: id,
            is_document: resourceLabel !== 'Questions Answered',
            standard: 'iso9001',
        });
        closeMenu();
    };

    const handleCardClick = () => {
        if (metric?.totalValue && !anchor) {
            onViewChange(id);
        }
    };
    // const onHover = (id) => {
    //     setActiveHoverCard(id);
    // };
    // const onHoverOut = () => {
    //     setActiveHoverCard(null);
    // };

    const closeModal = () => {
        setModalOpen(false);
        setType(null);
        setActiveTitle(null);
    };

    const exemptedControlId = useMemo(() => {
        return exempted
            ? all_exempted_controls?.find((control) => control?.lifecycle_detail?.title === activeTitle)?.id
            : id;
    }, [all_exempted_controls, activeTitle, exempted]);
    return (
        <>
            <GroupCard
                percentCompleted={
                    (metric?.totalValue === 0 ? 100 : (metric?.totalAttendedTo / metric?.totalValue) * 100) || 0
                }
                totalValue={metric?.totalValue}
                totalAttendedTo={metric?.totalAttendedTo}
                resourceLabel={resourceLabel}
                onCardClick={handleCardClick}
                empty={metric?.totalValue == 0}
                // onHover={onHover}
                // onHoverOut={onHoverOut}
                id={id}
                exempted={exempted}
                // activeHoverCard={activeHoverCard}
                isCategoryAssigned={isControlAssigned}
                isSubCatgoryAssigned={isSubControlAssigned}
                isResourceAssigned={resourceAssigned?.includes(title)}
                standard={'iso9001'}
            >
                <Stack direction="row" justifyContent="space-between" className="relative">
                    <Typography
                        sx={{ fontWeight: 600, mb: 0, fontSize: '15px', color: exempted ? '#94A3B8' : '#475569' }}
                    >
                        {/* Control Objective {index + 1} */}
                        {title}
                    </Typography>
                    {/* {activeHoverCard === id && ( */}

                    {metric?.totalValue > 0 && !isControlAssigned && !exempted && role !== 'auditor' && (
                        <>
                            <IconButton
                                sx={{
                                    color: theme.palette.gray[900],
                                    minWidth: 'unset',
                                    height: '25px',
                                    p: 0,
                                    border: '1px solid #E1E2EC',
                                    borderRadius: 1,
                                }}
                                onClick={openMenu}
                            >
                                <MoreVert />
                            </IconButton>
                            <Menu
                                open={Boolean(anchor)}
                                anchorEl={anchor}
                                onClose={closeMenu}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem
                                    sx={{ fontSize: 14, color: '#64748B', fontWeight: 500, py: -2 }}
                                    onClick={handleOpenTaskModal}
                                >
                                    Assign to:
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                    {/* )} */}
                </Stack>
                {/* <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 500,
                        color: '#64748B',
                        textTransform: 'capitalize',
                        mt: 1,
                    }}
                >
                    {description}
                </Typography> */}
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: exempted ? '#94A3B8' : '#64748B',
                        textTransform: 'normal',
                        mt: 1,
                    }}
                >
                    {description}
                </Typography>
            </GroupCard>
            <ControlExemptModal open={modalOpen} handleClose={closeModal} type={type} ControlId={exemptedControlId} />
        </>
    );
};

const mapStateToProps = (state) => ({
    all_exempted_controls: state?.complianceReducers?.all_exempted_controls,
});
export default connect(mapStateToProps, {})(ControlCard);
