import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button, Upload, Select, Radio } from 'antd';
import { AiOutlineInbox } from 'react-icons/ai';
import { accept } from 'validate';

// redux
import { connect } from 'react-redux';
import { EditOnboardingItem } from 'store/actions/adminActions';

const { Dragger } = Upload;

const EditModal = (props) => {
    const { open, handleCancel, EditOnboardingItem, details, all_merchants } = props;
    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // constants
    const { video, pdf, docx, excel, txt, ppt } = accept;
    const options = [
        {
            label: 'Yes, inform the merchants to accept updated policy',
            value: true,
        },
        {
            label: 'No, don’t inform the merchant to accept updated policy',
            value: false,
        },
    ];

    const selectOption = all_merchants?.map((merchant) => ({
        label: merchant?.name || merchant?.owner_detail?.first_name,
        value: merchant?.id,
    }));

    const draggers = {
        name: 'default',
        action: null,
        multiple: false,
        maxCount: 1,
        onChange(info) {
            setData({ ...data, file: info.fileList[0]?.originFileObj });
        },
        accept: details?.tag === 'document' ? `${pdf},${docx},${excel},${txt},${ppt}` : video,
    };

    useEffect(() => {
        if (open) {
            setData(details);
        }
    }, [open]);

    // functions
    const handleClose = () => {
        setData({});
        handleCancel();
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value });
    };
    const handleRadioChange = ({ target: { value } }) => {
        setData({ ...data, notify: value });
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };
    const handleChange = (value) => {
        setData({ ...data, notify_list: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', data?.name);
        formData.append('notify', data?.notify);
        formData.append('tag', data?.tag);
        formData.append('notify_list', JSON.stringify(data?.notify ? data?.notify_list : []));
        if (typeof data?.file === 'object') {
            formData.append('file', data?.file);
        }

        setLoading(true);
        const res = await EditOnboardingItem(formData, details?.id);
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    console.log(data);
    return (
        <Modal title="Edit Item" open={open} onCancel={handleClose} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="name">Item Name</label>
                        <Input
                            type="text"
                            size="large"
                            name="name"
                            id="name"
                            onChange={handleTextChange}
                            value={data?.name || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="template_file">Upload Template</label>
                        <Dragger {...draggers}>
                            <p className="ant-upload-drag-icon">
                                <AiOutlineInbox />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                    </Col>
                    <Col span={24} className="mt-14" style={{ marginBottom: '1.7rem' }}>
                        <label htmlFor="notify">Notify</label>
                        <Radio.Group options={options} onChange={handleRadioChange} value={data?.notify} />
                    </Col>
                    {data?.notify ? (
                        <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="notify">Merchants</label>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Please select"
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                defaultValue={data?.notify_list}
                                onChange={handleChange}
                                options={selectOption}
                            />
                        </Col>
                    ) : null}

                    <Col style={{ marginTop: '5rem' }}>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchants: state?.adminReducers?.all_merchants,
    };
};
export default connect(mapStateToProps, { EditOnboardingItem })(EditModal);
