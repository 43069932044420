import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InactiveLink from '../../../assets/img/InactiveLink.svg';
import ActiveLink from '../../../assets/img/ActiveLink.svg';
import { useTheme } from '@mui/material/styles';
import AppOptionChip from '../../new_components/AppOptionChip';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { SubmitSurveyResponse, UpdateSurveyResponse } from 'store/actions/merchantActions';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import AppInput from 'components/new_components/AppInput';

const QuestionOne = (props) => {
    const {
        item,
        page,
        setPage,
        SubmitSurveyResponse,
        status,
        setStatus,
        UpdateSurveyResponse,
        survey_responses,
        setAnswer,
        answer,
        other_answer,
        setOtheranswer,
        allSurveys,
        setCompleted,
        setQuestion,
    } = props;
    const findAnsweredSurvey = survey_responses?.find((survey) => survey?.question === item?.question);
    const [loadingState, setLoadingState] = useState(false);
    const [answer_not_in_option, setanswer_not_in_option] = useState(false);
    const [error, setError] = useState(false);
    const theme = useTheme();

    const [options_, setOptions_] = useState();

    useEffect(() => {
        if (findAnsweredSurvey) {
            setAnswer(findAnsweredSurvey?.answer);
            setStatus(findAnsweredSurvey?.status);
            setQuestion(item.question);
        }
    }, [findAnsweredSurvey]);

    const handleSubmit = async () => {
        if (item?.ans_type !== 'text' && answer?.length === 0) {
            toast.info('Please select an option!');
        } else if (item?.ans_type === 'text' && other_answer === '') {
            setError(true);
        } else {
            setLoadingState(true);
            const filtered_answer = answer?.filter((item) => item !== '');
            const body = {
                question: item?.question,
                status,
                answer: item?.ans_type === 'text' ? [other_answer] : filtered_answer,
                answer_not_in_option,
            };
            const res = findAnsweredSurvey
                ? await UpdateSurveyResponse(findAnsweredSurvey?.id, body)
                : await SubmitSurveyResponse(body);
            setLoadingState(false);
            if (res?.success) {
                if (allSurveys?.length === page) {
                    setCompleted(true);
                } else {
                    setPage(page + 1);
                }
                setStatus(false);
                setAnswer([]);
                setanswer_not_in_option(false);
                setQuestion('');
                setOtheranswer('');
            } else {
                toast.error(res?.message);
            }
        }
    };

    useEffect(() => {
        if (item?.answer_choices?.length) {
            const options = item?.answer_choices?.map((value, index) => {
                const indexToAlpha = (num = 1) => {
                    const A = 'A'.charCodeAt(0);
                    let numberToCharacter = (number) => {
                        return String.fromCharCode(A + number);
                    };
                    return numberToCharacter(num);
                };
                const alphabet = indexToAlpha(index);
                return {
                    tag: alphabet,
                    value: value,
                };
            });
            setOptions_(options);
        }
    }, [item?.answer_choices]);

    const find_others_option = answer?.find((others) => others === 'others');

    const handleSelected = () => {
        const updatedOptions = options_.map((option) => {
            if (option.value === 'others') {
                return { tag: option.tag, value: other_answer };
            } else {
                return option;
            }
        });
        if (other_answer !== '') {
            setOptions_(updatedOptions);
        }
        // Update answer array
        const index = answer.indexOf('others');
        answer[index] = other_answer;
        // set ststaus
        setStatus(true);
        setanswer_not_in_option(true);
    };
    const find_response = survey_responses?.find((response) => response?.question === item?.question);

    useEffect(() => {
        if (find_response) {
            setOtheranswer(find_response?.answer[0]);
        }
    }, [find_response]);
    return (
        <Box
            sx={{
                display: 'flex',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Typography
                    sx={{
                        color: status === true ? '#0e2c66' : '#919094',
                        border: status === true ? '2px solid #0e2c66' : '2px solid #919094',
                        borderRadius: '50%',
                        padding: '0.2rem 0.8rem',
                        marginBottom: 1,
                    }}
                >
                    {page}
                </Typography>
                {status === true ? (
                    <img src={ActiveLink} alt="ActiveLink" />
                ) : (
                    <img src={InactiveLink} alt="InactiveLink" />
                )}
            </Box>
            <Box
                sx={{
                    marginLeft: 3,
                }}
            >
                <Typography sx={{ color: theme.palette.primary[900], fontWeight: 600, fontSize: '16px' }}>
                    {item?.question}
                </Typography>
                {item?.ans_type === 'multiple' ? (
                    <Typography sx={{ marginTop: 4, color: '#0E2C66', fontSize: '12px' }}>
                        Select as many as you please
                    </Typography>
                ) : null}
                <Box sx={{ marginTop: item?.ans_type === 'multiple' ? '' : 4 }}>
                    {item?.ans_type === 'text' ? (
                        <>
                            <AppInput
                                value={other_answer}
                                onChange={(event) => setOtheranswer(event.target.value)}
                                multiline
                                rows={4}
                                style={{ width: '450px' }}
                            />
                            {error && <span style={{ color: 'red', fontSize: '11px' }}>This field is required</span>}
                        </>
                    ) : (
                        <Box>
                            {options_?.map((option, index) => (
                                <Box key={index} sx={{ marginBottom: 1 }}>
                                    {find_others_option === option?.value ? (
                                        <AppInput
                                            value={other_answer}
                                            onChange={(event) => setOtheranswer(event.target.value)}
                                            adornment={
                                                <CheckCircleOutlineIcon
                                                    onClick={handleSelected}
                                                    sx={{ cursor: 'pointer' }}
                                                />
                                            }
                                        />
                                    ) : (
                                        <AppOptionChip
                                            key={index}
                                            option={option}
                                            setStatus={setStatus}
                                            answer={answer}
                                            setAnswer={setAnswer}
                                            item={item}
                                            other_answer={other_answer}
                                            setOtheranswer={setOtheranswer}
                                        />
                                    )}
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
                <LoadingButton
                    sx={{ marginTop: 2, padding: '0.8rem 1.5rem' }}
                    variant="contained"
                    onClick={handleSubmit}
                    endIcon={<CheckCircleOutlineIcon />}
                    loading={loadingState}
                    loadingPosition="center"
                >
                    Ok
                </LoadingButton>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    survey_responses: state?.merchantReducers?.survey_responses,
});
export default connect(mapStateToProps, { SubmitSurveyResponse, UpdateSurveyResponse })(QuestionOne);
