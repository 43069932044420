import React from 'react';
import { useTheme } from '@mui/material/styles';
import { CardHeader, Stack, Typography } from '@mui/material';
import AppInput from '../AppInput';
import AppSelectInput from '../AppSelectInput';

//translations
import { useTranslation } from 'react-i18next';

const TableTopbar = (props) => {
    const {
        title,
        actions,
        rowSpacing,
        search,
        onSearch,
        StatusOption,
        RiskOwnerOption,
        RiskMetricsOption,
        handleStatuaChange,
        handleRiskOwnerChange,
        handleRiskMetricsChange,
        label1,
        label2,
        label3,
    } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('common');

    return (
        <CardHeader
            title={
                <Stack direction="row" alignItems="center" sx={{ mt: '-0.3rem' }}>
                    {search ? (
                        <AppInput
                            placeholder={t('search')}
                            sx={{
                                maxWidth: 200,
                                borderColor: theme.palette.gray.new60,
                                '& input::placeholder': {
                                    color: theme.palette.gray[40],
                                    fontWeight: 600,
                                },
                            }}
                            controlStyle={{ mt: -'0.8px', width: 'fit-content' }}
                            onChange={onSearch}
                        />
                    ) : (
                        <Typography variant="h3" component="h3" sx={{ marginBottom: '0!important', color: 'primary' }}>
                            {title}
                        </Typography>
                    )}
                    <>
                        <AppSelectInput
                            options={StatusOption}
                            label={label1}
                            sx={{
                                maxWidth: 100,
                                borderColor: theme.palette.gray[30],
                                '& input::placeholder': {
                                    color: theme.palette.gray[40],
                                    fontWeight: 600,
                                },
                            }}
                            name="target_env"
                            onChange={handleStatuaChange}
                        />
                        <AppSelectInput
                            options={RiskOwnerOption}
                            label={label2}
                            sx={{
                                maxWidth: 100,
                                borderColor: theme.palette.gray[30],
                                '& input::placeholder': {
                                    color: theme.palette.gray[40],
                                    fontWeight: 600,
                                },
                            }}
                            name="target_env"
                            onChange={handleRiskOwnerChange}
                        />
                        <AppSelectInput
                            options={RiskMetricsOption}
                            label={label3}
                            sx={{
                                maxWidth: 100,
                                borderColor: theme.palette.gray[30],
                                '& input::placeholder': {
                                    color: theme.palette.gray[40],
                                    fontWeight: 600,
                                },
                            }}
                            name="target_env"
                            onChange={handleRiskMetricsChange}
                        />
                    </>
                </Stack>
            }
            action={
                <Stack direction="row" spacing={1}>
                    {/* {search && (
                        <AppInput
                            placeholder="Search..."
                            sx={{
                                maxWidth: 200,
                                borderColor: theme.palette.gray[30],
                                '& input::placeholder': {
                                    color: theme.palette.gray[40],
                                    fontWeight: 600,
                                },
                            }}
                            controlStyle={{ mt: 0, width: 'fit-content' }}
                            onChange={onSearch}
                        />
                    )} */}
                    {actions}
                </Stack>
            }
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', pr: 4, pb: rowSpacing ? 0 : 2.5 }}
        />
    );
};

export default TableTopbar;
