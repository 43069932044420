import AppSelect from 'components/new_components/AppSelectInput';
import React from 'react';

const AppFilterSelect = (props) => {
    const { sx, menuProps, ...otherProps } = props;
    return (
        <AppSelect
            sx={{
                height: 40,
                borderRadius: 2,
                // width: 'fit-content',
                fontSize: 12,
                '& .MuiSelect-select': {
                    width: '300px',
                    px: 1,
                    py: 0.5,
                },
                '& .MuiSelect-icon': {
                    fontSize: 16,
                },
                ...sx,
            }}
            MenuProps={{
                anchorOrigin: { horizontal: 'left' },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                PaperProps: {
                    sx: {
                        minHeight: '100px',
                        width: '280px',
                        '& .MuiMenuItem-root': {
                            px: 1,
                            py: 0.2,
                        },
                    },
                },
                ...menuProps,
            }}
            {...otherProps}
        />
    );
};

export default AppFilterSelect;
