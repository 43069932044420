import React from 'react';
//core component
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CheckCircle } from '@mui/icons-material';
import ConnectButton from './ConnectButton';

const AuthMethodItem = (props) => {
    const theme = useTheme();
    const { icon, type, header, description, handleConnect, handleDisconnect, mfa_type } = props;
    return (
        <Box
            sx={{
                background: '#ffffff',
                border: `${mfa_type === type ? `2px solid ${theme.palette.primary.main}` : '0.3px solid #C7C6CA'}`,
                boxShadow:
                    '-23px 170px 68px rgba(209, 209, 209, 0.01), -13px 95px 58px rgba(209, 209, 209, 0.05), -6px 42px 43px rgba(209, 209, 209, 0.09), -1px 11px 24px rgba(209, 209, 209, 0.1), 0px 0px 0px rgba(209, 209, 209, 0.1)',
                borderRadius: '8px',
                p: 3,
            }}
        >
            <Stack
                direction={{ lg: 'row', sm: 'column', md: 'column' }}
                justifyContent="space-around"
                alignItems="center"
            >
                <img src={icon} alt={type} style={{ width: '9.68rem', height: '130px', objectFit: 'fill' }} />
                <Box sx={{ width: '54%', ml: '-58px' }}>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: 15,
                            mb: 3,
                            color: theme.palette.gray[20],
                        }}
                    >
                        {header}
                        {mfa_type === type && <CheckCircle color={theme.palette.primary.main} sx={{ ml: 1 }} />}
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: 15,
                            color: theme.palette.gray[40],
                        }}
                    >
                        {description}
                    </Typography>
                </Box>
                <ConnectButton
                    handleConnect={() => handleConnect(type)}
                    connected={mfa_type === type}
                    mfa_type={mfa_type}
                    handleDisconnect={handleDisconnect}
                />
            </Stack>
        </Box>
    );
};

export default AuthMethodItem;
