import { Box, styled } from '@mui/material';
import React, { useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#FFFFFF',
        color: '#011947',
        border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
        border: 0,
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#F8F9FE',
        border: 0,
    },
    '&:nth-of-type(even)': {
        backgroundColor: '#FFFFFF',
        border: 0,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const AuditingFeatures = (props) => {
    const { allPlans } = props;

    const [openAuditing, setOpenAuditing] = useState(true);
    const [openFeatures, setOpenFeatures] = useState(true);
    const [openScan, setOpenScan] = useState(true);
    const filtered_plans = allPlans.filter((plan) => plan?.tag !== 'demo');
    return (
        <Box>
            <TableContainer component={Paper}>
                <Table sx={{ maxWidth: 1000 }} aria-label="customized table">
                    <TableHead sx={{ backgroundColor: 'red' }}>
                        <TableRow>
                            <StyledTableCell
                                sx={{
                                    width: '300px',
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {openAuditing ? (
                                    <Box
                                        sx={{ backgroundColor: '#202D66', p: 0.2, borderRadius: '5px', marginRight: 1 }}
                                        onClick={() => setOpenAuditing(false)}
                                    >
                                        <KeyboardArrowUpIcon sx={{ color: '#FFFFFF' }} />
                                    </Box>
                                ) : (
                                    <Box
                                        sx={{
                                            backgroundColor: '#202D66',
                                            p: 0.2,
                                            borderRadius: '5px',
                                            mr: 1,
                                        }}
                                        onClick={() => setOpenAuditing(true)}
                                    >
                                        <KeyboardArrowDownIcon sx={{ color: '#FFFFFF' }} />
                                    </Box>
                                )}
                                Auditing
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: '300px', fontWeight: 700, fontSize: '14px' }}>
                                Start up
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: '300px', fontWeight: 700, fontSize: '14px' }}>
                                Growth
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: '300px', fontWeight: 700, fontSize: '14px' }}>
                                Enterprise
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {openAuditing && (
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width: '300px', color: '#5E5E62' }}>
                                    Audit and Auditor Collaboration
                                </StyledTableCell>
                                {filtered_plans?.map((row) => (
                                    <StyledTableCell key={row?.id} sx={{ width: '300px' }}>
                                        {' '}
                                        {row?.audits_collaboration ? (
                                            <CheckCircleOutlineIcon color="success" />
                                        ) : (
                                            <RemoveCircleOutlineIcon sx={{ color: '#8F9099' }} />
                                        )}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width: '300px' }}>
                                    Vifly AI
                                    <HelpOutlineIcon sx={{ width: 17, height: 17, color: '#AAABB4', marginLeft: 1 }} />
                                </StyledTableCell>
                                {filtered_plans?.map((row) => (
                                    <StyledTableCell key={row?.id} sx={{ width: '300px' }}>
                                        {' '}
                                        {row?.ai_chat ? (
                                            <CheckCircleOutlineIcon color="success" />
                                        ) : (
                                            <RemoveCircleOutlineIcon sx={{ color: '#8F9099' }} />
                                        )}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width: '300px' }}>In-House Auditor Support</StyledTableCell>
                                {filtered_plans?.map((row) => (
                                    <StyledTableCell key={row?.id} sx={{ width: '300px' }}>
                                        {' '}
                                        {row?.auditor_support ? (
                                            <CheckCircleOutlineIcon color="success" />
                                        ) : (
                                            <RemoveCircleOutlineIcon sx={{ color: '#8F9099' }} />
                                        )}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </TableBody>
                    )}
                </Table>
                <Table sx={{ maxWidth: 1000 }} aria-label="customized table">
                    <TableHead sx={{ backgroundColor: 'red' }}>
                        <TableRow>
                            <StyledTableCell
                                sx={{
                                    width: '300px',
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {openFeatures ? (
                                    <Box
                                        sx={{ backgroundColor: '#202D66', p: 0.2, borderRadius: '5px', marginRight: 1 }}
                                        onClick={() => setOpenFeatures(false)}
                                    >
                                        <KeyboardArrowUpIcon sx={{ color: '#FFFFFF' }} />
                                    </Box>
                                ) : (
                                    <Box
                                        sx={{
                                            backgroundColor: '#202D66',
                                            p: 0.2,
                                            borderRadius: '5px',
                                            mr: 1,
                                        }}
                                        onClick={() => setOpenFeatures(true)}
                                    >
                                        <KeyboardArrowDownIcon sx={{ color: '#FFFFFF' }} />
                                    </Box>
                                )}
                                Features
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: '300px', fontWeight: 700, fontSize: '14px' }}>
                                Start up
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: '300px', fontWeight: 700, fontSize: '14px' }}>
                                Growth
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: '300px', fontWeight: 700, fontSize: '14px' }}>
                                Enterprise
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {openFeatures && (
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width: '300px', color: '#5E5E62' }}>
                                    Pre-Security Assessment
                                </StyledTableCell>
                                {filtered_plans?.map((row) => (
                                    <StyledTableCell key={row?.id} sx={{ width: '300px' }}>
                                        {' '}
                                        {row?.pre_security_assessemnt ? (
                                            <CheckCircleOutlineIcon color="success" />
                                        ) : (
                                            <RemoveCircleOutlineIcon sx={{ color: '#8F9099' }} />
                                        )}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width: '300px' }}>
                                    Trust index
                                    <HelpOutlineIcon sx={{ width: 17, height: 17, color: '#AAABB4', marginLeft: 1 }} />
                                </StyledTableCell>
                                {filtered_plans?.map((row) => (
                                    <StyledTableCell key={row?.id} sx={{ width: '300px' }}>
                                        {' '}
                                        {row?.trust_report ? (
                                            <CheckCircleOutlineIcon color="success" />
                                        ) : (
                                            <RemoveCircleOutlineIcon sx={{ color: '#8F9099' }} />
                                        )}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width: '300px' }}>Dedicated Support</StyledTableCell>
                                {filtered_plans?.map((row) => (
                                    <StyledTableCell key={row?.id} sx={{ width: '300px' }}>
                                        {' '}
                                        {row?.support ? (
                                            <CheckCircleOutlineIcon color="success" />
                                        ) : (
                                            <RemoveCircleOutlineIcon sx={{ color: '#8F9099' }} />
                                        )}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width: '300px' }}>Vendor Management</StyledTableCell>
                                {filtered_plans?.map((row) => (
                                    <StyledTableCell key={row?.id} sx={{ width: '300px' }}>
                                        Up to {row?.vendor_mgt} Vendors
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width: '300px' }}>CISO Report</StyledTableCell>
                                {filtered_plans?.map((row) => (
                                    <StyledTableCell key={row?.id} sx={{ width: '300px' }}>
                                        {' '}
                                        {row?.ciso_report ? (
                                            <CheckCircleOutlineIcon color="success" />
                                        ) : (
                                            <RemoveCircleOutlineIcon sx={{ color: '#8F9099' }} />
                                        )}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width: '300px' }}>Fraud guardian</StyledTableCell>
                                {filtered_plans?.map((row) => (
                                    <StyledTableCell key={row?.id} sx={{ width: '300px' }}>
                                        Contact us
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </TableBody>
                    )}
                </Table>
                <Table sx={{ maxWidth: 1000 }} aria-label="customized table">
                    <TableHead sx={{ backgroundColor: 'red' }}>
                        <TableRow>
                            <StyledTableCell
                                sx={{
                                    width: '300px',
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {openScan ? (
                                    <Box
                                        sx={{ backgroundColor: '#202D66', p: 0.2, borderRadius: '5px', marginRight: 1 }}
                                        onClick={() => setOpenScan(false)}
                                    >
                                        <KeyboardArrowUpIcon sx={{ color: '#FFFFFF' }} />
                                    </Box>
                                ) : (
                                    <Box
                                        sx={{
                                            backgroundColor: '#202D66',
                                            p: 0.2,
                                            borderRadius: '5px',
                                            mr: 1,
                                        }}
                                        onClick={() => setOpenScan(true)}
                                    >
                                        <KeyboardArrowDownIcon sx={{ color: '#FFFFFF' }} />
                                    </Box>
                                )}
                                Scan functionality
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: '300px', fontWeight: 700, fontSize: '14px' }}>
                                Start up
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: '300px', fontWeight: 700, fontSize: '14px' }}>
                                Growth
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: '300px', fontWeight: 700, fontSize: '14px' }}>
                                Enterprise
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {openScan && (
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width: '300px', color: '#5E5E62' }}>
                                    Automated Scan
                                </StyledTableCell>
                                {filtered_plans?.map((row) => (
                                    <StyledTableCell key={row?.id} sx={{ width: '300px' }}>
                                        Up to {row?.automated_scan} Infrastructure
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width: '300px' }}>PCI scan</StyledTableCell>
                                {filtered_plans?.map((row) => (
                                    <StyledTableCell key={row?.id} sx={{ width: '300px' }}>
                                        Scan {row?.pci_scan} + {row?.pci_scan_freq} Uploads{' '}
                                        <InfoOutlinedIcon
                                            sx={{ width: 17, height: 17, color: '#AAABB4', marginLeft: 1 }}
                                        />
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width: '300px' }}>Penetration testing</StyledTableCell>
                                {filtered_plans?.map((row) => (
                                    <StyledTableCell key={row?.id} sx={{ width: '300px' }}>
                                        Scan {row?.pentest_scan} + {row?.pentest_scan_freq} Uploads
                                        <InfoOutlinedIcon
                                            sx={{ width: 17, height: 17, color: '#AAABB4', marginLeft: 1 }}
                                        />
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width: '300px' }}>On-Premises Server Monitoring</StyledTableCell>
                                {filtered_plans?.map((row) => (
                                    <StyledTableCell key={row?.id} sx={{ width: '300px' }}>
                                        {row?.server_monitoring} Servers
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width: '300px' }}>Background Check/KYC</StyledTableCell>
                                {filtered_plans?.map((row) => (
                                    <StyledTableCell key={row?.id} sx={{ width: '300px' }}>
                                        Up to {row?.background_check} Users
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </Box>
    );
};

export default AuditingFeatures;
