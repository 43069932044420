import React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useTheme } from '@mui/styles';

const Progress = styled(LinearProgress)(({ theme }) => ({
    borderRadius: 5,
    height: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.gray[100],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
    },
}));

const AppLinearProgress = (props) => {
    const theme = useTheme();
    const { value = 0, sx, survey } = props;
    return (
        <Progress
            variant="determinate"
            value={value}
            // color={survey ? 'primary' : value < 100 ? 'primary' : 'success'}
            sx={{
                backgroundColor: survey ? 'primary' : value < 100 ? '#395BA9' : '#2DCE89',
                [`&.${linearProgressClasses.colorPrimary}`]: {
                    backgroundColor: theme.palette.gray[100],
                },
                [`& .${linearProgressClasses.bar}`]: {
                    backgroundColor: survey ? 'primary' : value < 100 ? '#395BA9' : '#2DCE89',
                },
                ...sx,
            }}
        />
    );
};

export default AppLinearProgress;
