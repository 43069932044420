export const riskIdentificationPayload = {
    template_name: '',
    category: '',
    threat: '',
    existing_controls: '',
    inherent_likelihood: '',
    inherent_impact: '',
};

export const riskMitigationPayload_ = {
    report_risk_to: '',
    report_to: false,
    mitigation_owner: '',
    mitigation_cost_amount: '',
    mitigation_timeline: {
        time: '',
        count: 0,
    },
};

export const riskAcceptPayload = {
    report_risk_to: '',
    report_to: false,
    mitigation_owner: '',
    useAssetOwner: false,
    justification: '',
    contingency_plan: false,
    contingency_plan_text: '',
    contingency_timeline: {
        time: '',
        count: 0,
    },
};

export const riskAvoidPayload = {
    report_risk_to: '',
    report_to: false,
    mitigation_owner: '',
    risk_source: '',
    risk_elimination_method: '',
    mitigation_timeline: {
        time: '',
        count: 0,
    },
    comments: '',
};

export const riskTransferPayload = {
    transfer_method: '',
    mitigation_owner: '',
    vendor: '',
    mitigation_timeline: {
        time: '',
        count: 0,
    },
};

export const inherentValues = [
    { name: 'Low', value: 1 },
    { name: 'Medium', value: 2 },
    { name: 'High', value: 3 },
];
export const inherentLikelihoodOrImpact3x3 = [
    { name: 'Low', value: 1 },
    { name: 'Medium', value: 2 },
    { name: 'High', value: 3 },
];

export const inherentLikelihoodOrImpact3x3_ = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
];

export const inherentLikelihoodOrImpact5x5 = [
    { name: 'Very Low', value: 1 },
    { name: 'Low', value: 2 },
    { name: 'Medium', value: 3 },
    { name: 'High', value: 4 },
    { name: 'Very High', value: 4 },
];

export const inherentLikelihoodOrImpact5x5_ = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
    { name: 5, value: 5 },
];

export const NewRiskTemplateTabs = {
    riskIdentification: 'Risk identification',
    riskMitigation: 'Risk treatment',
};

export const tabs = [
    {
        index: 0,
        label: 'Risk Identification',
    },
    {
        index: 1,
        label: 'Risk Mitigation',
    },
];

export const categories = ['Operational', 'Compliance', 'Security', 'Financial', 'Strategic'];

export const plans = ['no response', 'mitigate', 'accept', 'avoid', 'transfer'];

export const RiskTemplatesTab = {
    FiveByFive: '5x5',
    ThreeByThree: '3x3',
};
export const mitigationStatuses = ['approved', 'resolved', 'not_resolved', 'awaiting_approval', 'disapproved'];
export const residual3x3Risks = ['low', 'medium', 'high'];

export const residual5x5Risks = ['very low', 'low', 'medium', 'high', 'very high'];
export const templateTypes = ['All', 'custom', 'smartcomply'];
