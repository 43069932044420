import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Close } from '@mui/icons-material';
import { FormHelperText } from '@mui/material';
import { formatSentence } from 'utils';
import { toast } from 'react-toastify';

// formik
import { useFormikContext } from 'formik';
import { accept } from 'validate';

export const AppFormDragFileUpload = ({ name, label }) => {
    const [fileBase64URL, setFileBase64URL] = useState(null);
    const theme = useTheme();
    const { pdf, docx, excel, txt, ppt } = accept;

    // get the formik context values
    const { setFieldValue, errors, values } = useFormikContext();

    // UPLOAD IMAGE ONCHANGE
    // const uploadImage = async (event) => {
    //     const input = event.target;
    //     const file = input.files?.item(0);
    //     const fileSize = file.size / 1000 / 1000;
    //     if (fileSize > 2) {
    //         setFieldValue('');
    //     } else {
    //         const base64 = await convertedBase64(file);
    //         setFieldValue(name, file);
    //         setImg(base64);
    //     }
    // };

    // CONVERT IMAGE TO BASE 64
    // const convertedBase64 = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const filReader = new FileReader();
    //         filReader.readAsDataURL(file);
    //         filReader.onload = () => {
    //             resolve(filReader.result);
    //         };
    //         filReader.onerror = (error) => {
    //             reject(error);
    //         };
    //     });
    // };

    // const dragEnter = () => {
    //     const target = document.getElementById('drop-zone');
    //     target.style.borderColor = '#C4C4C4';
    //     target.style.backgroundColor = '#A58980';
    // };

    // const dragLeave = () => {
    //     const target = document.getElementById('drop-zone');
    //     target.style.borderColor = '#A58980';
    //     target.style.backgroundColor = 'transparent';
    // };

    // const dragOver = (e) => {
    //     e.preventDefault();
    // };

    // const dragDrop = (e) => {
    //     e.preventDefault();
    //     const file = e.dataTransfer.files[0];
    //     if (file && file.type.includes('image')) {
    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             setImg(reader.result);
    //         };
    //         reader.readAsDataURL(file);
    //         convertedBase64(file).then(() => {});
    //     } else {
    //         // enqueueSnackbar('Invalid Data Dropped', { variant: 'error' });
    //     }
    //     dragLeave();
    // };

    // const handleCancel = () => {
    //     setImg('');
    //     setFieldValue(name, null);
    // };

    // // UPLOAD IMAGE ONCHANGE
    const uploadImage = (e) => {
        handleFile(e.target.files[0]);
    };
    const handleDrop = (e) => {
        handleFile(e.dataTransfer.files[0]);
    };
    const handleFile = (file) => {
        const fileSize = file?.size / 1000 / 1000;
        if (fileSize > 5) {
            toast.info('File too large, minimum of 5MB');
        } else {
            setFieldValue(name, file);
        }
    };

    const dragEnter = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#C4C4C4';
        target.style.backgroundColor = '#A58980';
    };

    const dragLeave = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#A58980';
        target.style.backgroundColor = 'transparent';
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const handleCancel = () => {
        setFieldValue(name, null);
    };

    // useEffect
    useEffect(() => {
        if (!values?.[name]) return setFileBase64URL(null);
        const reader = new FileReader();
        reader.onload = () => {
            setFileBase64URL(reader.result);
        };
        reader.readAsDataURL(values?.[name]);
    }, [values?.[name]]);
    return (
        <>
            <label
                htmlFor={label}
                className={`block text-[14px] ${errors[name] ? 'text-[#D91B1B]' : 'text-[#15161A]'} mt-3`}
            >
                {label}
            </label>
            <Box
                sx={{
                    border: `3px dashed ${errors[name] ? '#D91B1B' : theme.palette.primary[900]}`,
                    borderRadius: 2,
                    padding: 2,
                    minHeight: 250,
                    position: 'relative',
                }}
            >
                <Box
                    id="drop-zone"
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={handleDrop}
                    onDragOver={dragOver}
                    sx={{ height: '100%', p: 2 }}
                >
                    {!!fileBase64URL && (
                        <Box
                            sx={{
                                width: '80px',
                                border: `1px solid ${errors[name] ? '#D91B1B' : theme.palette.primary[900]}`,
                                borderRadius: 2,
                                backgroundImage: `url(${fileBase64URL})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'top center',
                                height: '80px',
                                position: 'relative',
                            }}
                        >
                            <Close
                                onClick={handleCancel}
                                sx={{
                                    position: 'absolute',
                                    top: '-2px',
                                    right: '-8px',
                                    background: '#858999',
                                    borderRadius: 2,
                                    width: 14,
                                    height: 14,
                                    cursor: 'pointer',
                                }}
                            />
                        </Box>
                    )}
                    <Box sx={{ position: 'absolute', bottom: 15, left: '35%', textAlign: 'center' }}>
                        <label htmlFor="file-input">
                            <Box
                                sx={{
                                    border: `2px solid ${theme.palette.primary[900]}`,
                                    borderRadius: 2,
                                    padding: 1,
                                    cursor: 'pointer',
                                }}
                            >
                                <Typography sx={{ color: theme.palette.primary[900], fontWeight: 600, fontSize: 16 }}>
                                    Upload a file
                                </Typography>
                            </Box>
                        </label>
                        <input
                            type="file"
                            accept={`${pdf},${docx},${excel},${txt},${ppt}`}
                            onChange={uploadImage}
                            hidden
                            id="file-input"
                        />
                        <Typography variant={'body2'} color={'textSecondary'}>
                            or drag and drop a file
                        </Typography>
                        <input type="file" accept={`${pdf},${docx},${excel},${txt},${ppt}`} hidden id="file-input" />
                    </Box>
                </Box>
            </Box>

            {errors[name] && <FormHelperText error>{formatSentence(errors[name])}</FormHelperText>}
        </>
    );
};
