import React, { useMemo, useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { Stack } from '@mui/material';

// core components
import ExceptionsTabs from '../../common/exceptions/ExceptionsTabs';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { GetAllExemptedDocuments, GetAllExemptedForms } from 'store/actions/complianceActions';
import EmptyState from 'components/new_components/EmptyState';
import ExceptionGroups from '../../common/exceptions/ExceptionGroups';
import LoadingState from 'components/new_components/LoadingState';

//translations
import { useTranslation } from 'react-i18next';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

const IsogroupSelectionAside = (props) => {
    const { selectedIsogroup, resource, handleResourceChange, handleIsogroupChange, loading: loadingIsogroups } = props;

    // state
    const [loading, setLoading] = useState({ content: false });

    const dispatch = useDispatch();

    //translation
    const { t } = useTranslation('compliance');

    const { activeMerchant } = useContext(SelectedMerchantContext);

    const all_techsecgroups = useSelector((state) => state?.iso27001plusiso27035Reducers?.all_tech_sec_groups);
    const exempted_forms = useSelector((state) => state?.complianceReducers?.all_exempted_forms);
    const exempted_documents = useSelector((state) => state?.complianceReducers?.all_exempted_documents);

    // logic functions
    const getFilteredIsogroups = (items = []) => {
        const filteredIsogroups = [...all_techsecgroups]?.filter((isogroup) => {
            const isogroup_id = isogroup?.id;
            const isogroupHasException = items?.some((exception) => {
                const exceptionIsogroupId =
                    resource === 'doc' ? exception?.document_detail?.tech_sec : exception?.form_detail?.tech_sec;
                return exceptionIsogroupId === isogroup_id;
            });
            return isogroupHasException;
        });
        const formattedCategory = filteredIsogroups.map((isogroup) => {
            const exceptionCount = items?.reduce((prev, current) => {
                const exceptionIsogroupId =
                    resource === 'doc' ? current?.document_detail?.tech_sec : current?.form_detail?.tech_sec;
                return exceptionIsogroupId === isogroup?.id ? prev + 1 : prev;
            }, 0);
            return {
                id: isogroup?.id,
                title: isogroup?.title,
                description: `${exceptionCount} ${t('exceptions')}`,
            };
        });
        return formattedCategory;
    };

    // async functions
    // get all excempted document for this standard
    const getAllExemptedDocuments = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await dispatch(GetAllExemptedDocuments('tech_sec', activeMerchant));
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exceptions.");
            console.log('Error::::', res?.message);
        }
    };
    const getAllExemptedQuestions = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await dispatch(GetAllExemptedForms('tech_sec', activeMerchant));
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exceptions.");
            console.log('Error::::', res?.message);
        }
    };

    // memos
    const isogroupWithExceptions = useMemo(() => {
        if (resource === 'doc') {
            return getFilteredIsogroups(exempted_documents);
        } else {
            return getFilteredIsogroups(exempted_forms);
        }
    }, [resource, exempted_documents, exempted_forms, all_techsecgroups]);

    // useEffect
    useEffect(() => {
        if (resource === 'doc') {
            getAllExemptedDocuments();
        } else {
            getAllExemptedQuestions();
        }
    }, [resource, activeMerchant]);

    return (
        <Stack sx={{ width: '100%' }}>
            <ExceptionsTabs onTabChange={handleResourceChange} currentTab={resource} />
            {!loading?.content && !loadingIsogroups ? (
                !isogroupWithExceptions?.length ? (
                    <EmptyState description={t('noException')} />
                ) : (
                    <ExceptionGroups
                        hasSubgroup={false}
                        groups={isogroupWithExceptions}
                        selectedSubgroup={selectedIsogroup}
                        standard="tech_sec"
                        handleSubgroupIdChange={handleIsogroupChange}
                    />
                )
            ) : (
                <LoadingState />
            )}
        </Stack>
    );
};

export default IsogroupSelectionAside;
