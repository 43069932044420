import React, { useState } from 'react';
import TransferMethod from './TransferMethod';
import { Menu, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';

const TransferForm = ({ riskTransfer, setRiskTransfer, error }) => {
    const [localStates, setLocalStates] = useState({
        transfer_method: riskTransfer.transfer_method,
        count: riskTransfer?.mitigation_timeline?.count,
        time: riskTransfer?.mitigation_timeline?.time,
    });
    const [vendorSearch, setVendorSearch] = useState('');
    const [assignToSearch, setAssignToSearch] = useState('');
    const [vendorAnchorEl, setVendorAnchorEl] = useState(null);
    const [assignToAnchorEl, setAssignToAnchorEl] = useState(null);
    const [firstReviewDataAnchorEl, setFirstReviewDataAnchorEl] = useState(null);

    // redux
    const { all_merchant_users } = useSelector((state) => state.riskAssessmentReducers);
    const all_vendors = useSelector((state) => state?.vendorReducers?.all_vendors);

    const all_users = all_merchant_users?.people?.concat(all_merchant_users?.users);
    const filtered_employees = all_users?.filter((user) =>
        user?.email.toLowerCase().includes(assignToSearch.toLowerCase())
    );

    return (
        <>
            <div className="flex flex-col gap-2">
                <p className="font-medium text-sm text-[#64748B]">Transfer method</p>

                <TransferMethod
                    localStates={localStates}
                    setLocalStates={setLocalStates}
                    setRiskTransfer={setRiskTransfer}
                />
            </div>

            <div className="flex flex-col gap-2">
                <p className="font-medium text-sm text-[#64748B]">Vendor/partner</p>

                <div>
                    <button
                        aria-controls={vendorAnchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={vendorAnchorEl ? 'true' : undefined}
                        onClick={(event) => {
                            event.stopPropagation();
                            setVendorAnchorEl(event.currentTarget);
                        }}
                        className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                    >
                        <p
                            className={`font-normal text-sm ${
                                riskTransfer?.vendor ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                            }`}
                        >
                            {riskTransfer?.vendor ? riskTransfer?.vendor : 'Select vendor/partner'}
                        </p>

                        <img
                            src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                            alt="Arrow Down Icon"
                            className="object-contain"
                        />
                    </button>

                    <Menu
                        anchorEl={vendorAnchorEl}
                        open={Boolean(vendorAnchorEl)}
                        onClose={() => setVendorAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        sx={{
                            '& .MuiPaper-root': {
                                borderRadius: '4px',
                                boxShadow:
                                    '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                            },
                            '& .MuiList-root': {
                                padding: 0,
                            },
                        }}
                    >
                        <div className="flex flex-col !py-2 !min-w-[336px]">
                            <div className="!py-[5px] !px-3 flex flex-col gap-2.5">
                                <p className="border-b border-[#F1F5F9] !py-1 !px-3 text-[#64748B] font-medium text-xs">
                                    Vendor
                                </p>

                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="w-full !py-2.5 !pr-2.5 !pl-8 !border !border-[#E2E8F0] h-7 text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                        value={vendorSearch}
                                        onChange={(event) => setVendorSearch(event.target.value)}
                                    />

                                    <img
                                        src="/img/risk-assessment/ri_search-line-small.svg"
                                        alt="Search Icon"
                                        className="absolute object-contain top-1/2 -translate-y-1/2 left-[10px]"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col max-h-[180px] overflow-y-auto">
                                {all_vendors
                                    .filter((person) => person?.name.toLowerCase().includes(vendorSearch.toLowerCase()))
                                    .map((person, index) => (
                                        <button
                                            key={index}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setRiskTransfer((prev) => ({
                                                    ...prev,
                                                    vendor: person?.name,
                                                }));
                                                setVendorAnchorEl(null);
                                            }}
                                            className="!py-2 !px-3 text-[#475569] font-medium text-[13px] hover:bg-[#F1F5F9] transition-colors capitalize !text-left"
                                        >
                                            {person?.name}
                                        </button>
                                    ))}
                            </div>
                        </div>
                    </Menu>
                </div>
            </div>

            <div className="flex flex-col gap-2">
                <p className="font-medium text-sm text-[#64748B]">Assign to:</p>

                <div>
                    <button
                        aria-controls={assignToAnchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={assignToAnchorEl ? 'true' : undefined}
                        onClick={(event) => {
                            event.stopPropagation();
                            setAssignToAnchorEl(event.currentTarget);
                        }}
                        className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                    >
                        <p
                            className={`font-normal text-sm ${
                                riskTransfer?.solution_owner ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                            }`}
                        >
                            {riskTransfer?.solution_owner ? riskTransfer?.solution_owner : 'Select assign to'}
                        </p>

                        <img
                            src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                            alt="Arrow Down Icon"
                            className="object-contain"
                        />
                    </button>

                    <Menu
                        anchorEl={assignToAnchorEl}
                        open={Boolean(assignToAnchorEl)}
                        onClose={() => setAssignToAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        sx={{
                            '& .MuiPaper-root': {
                                borderRadius: '4px',
                                boxShadow:
                                    '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                            },
                            '& .MuiList-root': {
                                padding: 0,
                            },
                        }}
                    >
                        <div className="flex flex-col !py-2 !min-w-[336px]">
                            <div className="!py-[5px] !px-3 flex flex-col gap-2.5">
                                <p className="border-b border-[#F1F5F9] !py-1 !px-3 text-[#64748B] font-medium text-xs">
                                    Entity
                                </p>

                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="w-full !py-2.5 !pr-2.5 !pl-8 !border !border-[#E2E8F0] h-7 text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                        value={assignToSearch}
                                        onChange={(event) => setAssignToSearch(event.target.value)}
                                    />

                                    <img
                                        src="/img/risk-assessment/ri_search-line-small.svg"
                                        alt="Search Icon"
                                        className="absolute object-contain top-1/2 -translate-y-1/2 left-[10px]"
                                    />
                                </div>
                            </div>

                            {filtered_employees?.length ? (
                                <div className="flex flex-col max-h-[180px] overflow-y-auto">
                                    {filtered_employees.map((person, index) => (
                                        <button
                                            key={index}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setRiskTransfer((prev) => ({
                                                    ...prev,
                                                    solution_owner: person?.email,
                                                }));
                                                setAssignToAnchorEl(null);
                                            }}
                                            className="!py-2 !px-3 text-[#475569] font-medium text-[13px] hover:bg-[#F1F5F9] transition-colors capitalize !text-left"
                                        >
                                            {person?.email}
                                        </button>
                                    ))}
                                </div>
                            ) : (
                                <p className="flex items-center justify-center my-4 text-sm text-[#64748B] font-medium">
                                    User not found
                                </p>
                            )}
                        </div>
                    </Menu>
                </div>
            </div>

            <div className="flex flex-col gap-2">
                <p className="font-medium text-sm text-[#64748B] mb-1">Transfer within (timeline)</p>
                <div className="flex gap-4">
                    <div className="flex-1">
                        <div className="flex justify-between items-center !border !border-[#E2E8F0] pl-1 pr-3 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75">
                            <input
                                type="number"
                                inputMode="numeric"
                                placeholder="Enter timeline"
                                value={localStates?.count}
                                onChange={(event) => {
                                    setLocalStates((prev) => ({
                                        ...prev,
                                        count: event.target.value,
                                    }));
                                }}
                                onBlur={(event) =>
                                    setRiskTransfer((prev) => ({
                                        ...prev,
                                        mitigation_timeline: {
                                            ...prev.mitigation_timeline,
                                            count: event.target.value,
                                        },
                                    }))
                                }
                                min={0}
                                max={100}
                                step={1}
                                className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none outline-none bg-transparent !rounded-sm !p-2 h-9 border-none placeholder:opacity-95"
                            />
                            <div className="flex flex-col gap-0.5 justify-center items-center">
                                <img
                                    src="/img/risk-assessment/arrow-up-icon.png"
                                    alt="Arrow Up Icon"
                                    className="object-contain hover:no-underline hover:scale-90 transition-transform cursor-pointer aria-disabled:opacity-50 aria-disabled:pointer-events-none"
                                    aria-disabled={localStates.count === 100}
                                    onClick={() =>
                                        setLocalStates((prev) => ({
                                            ...prev,
                                            count: localStates.count + 1,
                                        }))
                                    }
                                />

                                <img
                                    src="/img/risk-assessment/arrow-down-icon.png"
                                    alt="Arrow Down Icon"
                                    className="object-contain hover:no-underline hover:scale-90 transition-transform cursor-pointer aria-disabled:opacity-50 aria-disabled:pointer-events-none"
                                    aria-disabled={localStates.count === 0}
                                    onClick={() =>
                                        setLocalStates((prev) => ({
                                            ...prev,
                                            count: localStates.count - 1,
                                        }))
                                    }
                                />
                            </div>
                        </div>
                        {error && localStates?.count === 0 && (
                            <span className="text-xs text-[#FF0000] font-normal mt-2">Count is required</span>
                        )}
                    </div>
                    <div className="flex-1 mt-3">
                        <button
                            aria-controls={firstReviewDataAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={firstReviewDataAnchorEl ? 'true' : undefined}
                            onClick={(event) => {
                                event.stopPropagation();
                                setFirstReviewDataAnchorEl(event.currentTarget);
                            }}
                            className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                        >
                            <p
                                className={`font-normal text-sm ${
                                    localStates?.time ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                                }`}
                            >
                                {localStates?.time ? localStates?.time : 'Select frequency'}
                            </p>

                            <img
                                src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                alt="Arrow Down Icon"
                                className="object-contain"
                            />
                        </button>

                        <Menu
                            anchorEl={firstReviewDataAnchorEl}
                            open={Boolean(firstReviewDataAnchorEl)}
                            onClose={() => setFirstReviewDataAnchorEl(null)}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '4px',
                                    boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                },
                                '& .MuiList-root': {
                                    padding: 0,
                                },
                            }}
                        >
                            <div className="flex flex-col min-w-[149px]">
                                {['Days', 'Weeks', 'Months'].map((duration, index) => (
                                    <MenuItem
                                        key={index}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setLocalStates((prev) => ({
                                                ...prev,
                                                time: duration,
                                            }));
                                            setRiskTransfer((prev) => ({
                                                ...prev,
                                                mitigation_timeline: {
                                                    ...prev.mitigation_timeline,
                                                    time: duration,
                                                },
                                            }));
                                            setFirstReviewDataAnchorEl(null);
                                        }}
                                        sx={{
                                            color: '#64748B',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            textTransform: 'capitalize',
                                            padding: '8px 16px',
                                        }}
                                    >
                                        {duration}
                                    </MenuItem>
                                ))}
                            </div>
                        </Menu>
                        {error && localStates?.time === 0 && (
                            <span className="text-xs text-[#FF0000] font-normal mt-2">Please select an option</span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TransferForm;
