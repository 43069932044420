import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FormCreation from '../Operations/FormCreation';

import Sector from './views/Sector';
import Items from './views/Items';
import Subsector from './views/Subsector';

const CiiDirective = () => {
    return (
        <Switch>
            <Route path="/admin/cii-directive" exact component={Sector} />
            <Route path="/admin/cii-directive/subsector/" exact component={Subsector} />
            <Route path="/admin/cii-directive/subsector/:id" exact component={Items} />
            <Route
                path="/admin/cii-directive/subsector/:id/form"
                exact
                component={(props) => <FormCreation tag="cii" {...props} />}
            />
        </Switch>
    );
};

export default CiiDirective;
