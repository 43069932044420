import { InputAdornment, Box } from '@mui/material';
import React from 'react';
import AppInput from 'components/new_components/AppInput';
import { useTheme } from '@mui/material/styles';
import { TransactionStatusption, StatusOption } from '../utils/constants';
import AppSelectInput from 'components/new_components/AppSelectInput';
import { connect } from 'react-redux';
import { GetAllMerchantRiskAssessments } from 'store/actions/merchantActions';
import SearchIcon from '../../../../assets/img/Searchicon.svg';

const KycHead = (props) => {
    const { onSearch, match_status, entity_type, setEntityType, setMatch_Status } = props;
    const theme = useTheme();

    const handleStatusChange = (e) => {
        setMatch_Status(e.target.value);
    };
    const handleEntityChange = (e) => {
        setEntityType(e.target.value);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <AppInput
                placeholder="Search."
                fullWidth
                sx={{
                    mr: 3,
                    borderColor: theme.palette.gray[30],
                    height: 38,
                    width: '250px',
                    '& input::placeholder': {
                        color: theme.palette.gray[40],
                        fontWeight: 600,
                    },
                }}
                startAdornment={
                    <InputAdornment position="start">
                        <img src={SearchIcon} alt="SearchIcon" width="25px" />
                    </InputAdornment>
                }
                onChange={onSearch}
            />
            <AppSelectInput
                name="Transaction Status"
                options={TransactionStatusption}
                defaultValue="Transaction Status"
                sx={{ ml: 4.5, mr: 1, borderRadius: '8px', height: 38, fontSize: 12, width: '200px' }}
                value={match_status}
                onChange={handleStatusChange}
                size="small"
            />
            <AppSelectInput
                name="status"
                options={StatusOption}
                defaultValue="Status"
                sx={{ ml: 3, borderRadius: '8px', height: 38, fontSize: 12 }}
                value={entity_type}
                onChange={handleEntityChange}
                size="small"
            />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_risk_assessments: state?.merchantReducers?.all_merchant_risk_assessments,
    };
};
export default connect(mapStateToProps, { GetAllMerchantRiskAssessments })(KycHead);
