import React, { useEffect, useState } from 'react';
import AppInput from 'components/new_components/AppInput';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import { Box, Stack } from '@mui/material';

// redux
import { connect } from 'react-redux';
import { GetCompanyDetails, UpdateCompanyDetails } from 'store/actions/merchantActions';
import { toast } from 'react-toastify';

const QuestionOne = (props) => {
    const { goNext, GetCompanyDetails, merchant_info, UpdateCompanyDetails } = props;

    // state
    const [loading, setLoading] = useState({ content: false });
    const [data, setData] = useState({});
    const [formIsTouched, setFormIsTouched] = useState(false);

    // functions
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formIsTouched) {
            setLoading({ ...loading, submit: true });
            const res = await UpdateCompanyDetails(data);
            setLoading({ ...loading, submit: false });
            if (res?.success) {
                toast.success('Details update successful.');
                setFormIsTouched(false);
                goNext();
            } else {
                toast.error('Something went wrong, try again!');
            }
        } else {
            goNext();
        }
    };
    const getCompanyDetails = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetCompanyDetails();
        setLoading({ ...loading, content: false });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
        setFormIsTouched(true);
    };

    // useEffect
    useEffect(() => {
        getCompanyDetails();
    }, []);
    useEffect(() => {
        const { name, dba, mailing_address, phone_number, website } = merchant_info;
        setData({ name, dba, mailing_address, phone_number, website });

        return () => setData({});
    }, [merchant_info]);
    return (
        <Stack component="form" onSubmit={handleSubmit} justifyContent="space-between" className="flex-1">
            <Box sx={{ px: 3, pb: 3 }}>
                <div className="w-full">
                    <AppInput
                        label="Company Name"
                        name="name"
                        value={data?.name || ''}
                        onChange={handleTextChange}
                        required
                    />
                </div>
                <div className="flex w-full lg:flex-row flex-col">
                    <div className="lg:w-1/2 w-full lg:mr-4">
                        <AppInput
                            label="DBA (Doing business as)"
                            name="dba"
                            value={data?.dba || ''}
                            onChange={handleTextChange}
                            required
                        />
                    </div>
                    <div className="lg:w-1/2 w-full lg:ml-4">
                        <AppInput
                            label="Mailing Address"
                            name="mailing_address"
                            value={data?.mailing_address || ''}
                            onChange={handleTextChange}
                            required
                        />
                    </div>
                </div>
                <div className="flex w-full lg:flex-row flex-col">
                    <div className="lg:w-1/2 w-full lg:mr-4">
                        <AppInput
                            label="Contact Name"
                            name="contact_name"
                            value={data?.contact_name || ''}
                            onChange={handleTextChange}
                            // required
                        />
                    </div>
                    <div className="lg:w-1/2 w-full lg:ml-4">
                        <AppInput
                            label="Contact Title"
                            name="contact_title"
                            value={data?.contact_title || ''}
                            onChange={handleTextChange}
                            // required
                        />
                    </div>
                </div>
                <div className="flex w-full lg:flex-row flex-col">
                    <div className="lg:w-1/2 w-full lg:mr-4">
                        <AppInput
                            label="Contact Phone number"
                            name="phone_number"
                            value={data?.phone_number || ''}
                            onChange={handleTextChange}
                            required
                        />
                    </div>
                    <div className="lg:w-1/2 w-full lg:ml-4">
                        <AppInput
                            label="Contact email address"
                            name="contact_email_address"
                            value={data?.contact_email_address || ''}
                            onChange={handleTextChange}
                            // required
                        />
                    </div>
                </div>
                <div className="w-full">
                    <AppInput
                        label="Company main website"
                        name="website"
                        value={data?.website || ''}
                        onChange={handleTextChange}
                        required
                    />
                </div>
            </Box>
            <Stack direction="row" sx={{ px: 3 }}>
                <AppLoadingButton text="Next" variant="contained" color="primary" type="submit" />
            </Stack>
        </Stack>
    );
};
const mapStateToProps = (state) => ({
    merchant_info: state?.merchantReducers?.merchant_info,
});
export default connect(mapStateToProps, { GetCompanyDetails, UpdateCompanyDetails })(QuestionOne);
