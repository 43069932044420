import { ADD_MERCHANT_NOTIFICATION, ALL_MERCHANT_NOTIFICATIONS } from '../constants';
import { wsBaseUrl } from 'config';
import axiosInstance from '../../utils/https';
import { GetAllUploadedVendorQuestionnaire } from './vendorActions';
import { GetAllAPIScans } from './apiComplianceActions';
import { GetAllTasks } from './taskActions';
import { GetUserDetails } from './generalActions';
import { getType } from 'utils';

// merchant
// an object containing all web socket
export const websockets = {};
// web socket connection
const GetNewMerchantNotifications = () => async (dispatch, getState) => {
    const user_id = getState()?.authReducers?.user_id;
    try {
        const socket = new WebSocket(`${wsBaseUrl}/ws/notifications/${user_id}/`);
        // save it to a state so it can be accessed anywhere in the file.
        websockets.notifications = socket;
        socket.addEventListener('message', (res) => {
            const data = JSON.parse(res.data);
            const payload = data.notification;
            if (payload) {
                dispatch({ type: ADD_MERCHANT_NOTIFICATION, payload });
                dispatch(GetAllUploadedVendorQuestionnaire());
                dispatch(GetAllAPIScans());
                dispatch(GetAllTasks());
                dispatch(GetUserDetails());
            }
        });
        return {
            success: true,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllMerchantNotifications = () => async (dispatch, getState) => {
    const user_id = getState()?.authReducers?.user_id || getType('id');
    try {
        const res = await axiosInstance().get(`/asvc_requests/notifications/?user=${user_id}`);
        if (res?.status === 'fail') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_NOTIFICATIONS, payload: res?.data });
        if (!websockets.notifications) {
            dispatch(GetNewMerchantNotifications());
        }
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const ReadAllMerchantNotifications = () => async (dispatch, getState) => {
    const user = getState()?.authReducers?.user_id;
    try {
        const res = await axiosInstance().post(`/asvc_requests/set_notification_status/`, { user });
        if (res?.status === 'fail') throw new Error(res?.message);
        dispatch(GetAllMerchantNotifications());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
