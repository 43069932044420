import { styled, Tooltip, tooltipClasses } from '@mui/material';
import React from 'react';

function CustomTooltip({ children, title, placement }) {
    const CustomizedTooltip = styled(({ className, ...props }) => (
        <Tooltip
            {...props}
            placement={placement || 'top'}
            disableFocusListener
            disableTouchListener
            arrow
            classes={{ popper: className }}
        />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            borderRadius: '2px',
            textAlign: 'center',
            padding: '6px 8px',
            maxWidth: '207px',
        },
    }));

    return <CustomizedTooltip title={title}>{children}</CustomizedTooltip>;
}

export default CustomTooltip;
