import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Stack } from '@mui/material';
import VideosNavigator from './VideosNavigator';
import VideoView from './VideoView';
// redux
import { connect } from 'react-redux';
import { EditTraining } from 'store/actions/trainingActions';
import moment from 'moment';
import { PREVIEW_COURSE } from '../utils/data';

const TrainingVideos = (props) => {
    const { training, EditTraining, loading: loadingContent, goToCertificate, readOnly } = props;

    // state
    const [currentVideoIdx, setCurrentVideoIdx] = useState(0);
    const [loading, setLoading] = useState(false);

    // memos
    const videos = useMemo(
        () => (readOnly ? PREVIEW_COURSE.videos : training?.videos?.sort((a, b) => a?.video_number - b?.video_number)),
        [training?.videos]
    );
    const documents = useMemo(
        () => (readOnly ? PREVIEW_COURSE.videos : training?.documents?.sort((a, b) => a?.doc_number - b?.doc_number)),
        [training?.documents]
    );
    const course = useMemo(() => (readOnly ? PREVIEW_COURSE : training?.course_info), [training]);

    // function
    const goToNext = () => {
        if (currentVideoIdx >= videos?.length - 1) return;
        handleVideoChange(currentVideoIdx + 1);
    };
    const handleVideoChange = (id) => {
        setLoading(true);
        setCurrentVideoIdx(id);
        setTimeout(() => setLoading(false), 100);
    };
    const handleVideoWatched = async () => {
        const currentVideoId = videos?.[currentVideoIdx]?.id;
        const watched = new Set(training?.watched);
        if (readOnly || watched.has(currentVideoId)) return goToNext();
        watched.add(currentVideoId);
        const is_complete = !videos?.some((video) => !watched?.has(video?.id));
        const res = await EditTraining(
            {
                is_complete,
                certificate_date: is_complete ? moment().format('YYYY-MM-DD') : null,
                watched: [...watched],
            },
            training?.id
        );
        if (res?.success) {
            goToNext();
        } else {
            toast.error("Something went wrong, couldn't update video progress.");
        }
    };

    return (
        <Stack direction="row" sx={{ height: '100vh', backgroundColor: '#f8f9fe' }}>
            <VideosNavigator
                current={currentVideoIdx}
                onVideoChange={handleVideoChange}
                videos={videos}
                watched={readOnly ? [] : training?.watched}
                documents={documents}
            />
            <VideoView
                course={course}
                currentVideo={videos?.[currentVideoIdx]}
                disabledCert={!training?.certificate_date}
                readOnly={readOnly}
                loading={loading || loadingContent}
                onVideoWatched={handleVideoWatched}
                documents={training?.documents}
                goToCertificate={goToCertificate}
            />
        </Stack>
    );
};
const mapStateToProps = (state) => ({
    one_trainee: state?.trainingReducers?.one_trainee,
});
export default connect(mapStateToProps, { EditTraining })(TrainingVideos);
