import React from 'react';
import { Box } from '@mui/material';
import ResponseItem from './components/ResponseItem';

const ResponseItemContainer = (props) => {
    const { all_questions, activeTab, setActiveTab } = props;
    return (
        <>
            <Box sx={{ backgroundColor: '#F1F5F9', height: '100%', ml: 3, pt: 3, px: 1 }}>
                {all_questions ? (
                    all_questions?.map((response) => {
                        return (
                            <ResponseItem
                                key={response?.id}
                                title={response?.form_title}
                                id={response?.id}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                            />
                        );
                    })
                ) : (
                    <div></div>
                )}
            </Box>
        </>
    );
};

export default ResponseItemContainer;
