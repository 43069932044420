import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Col, Input, Button, Modal } from 'antd';

// redux
import { connect } from 'react-redux';
import { CreateQuestonnaireForm } from 'store/actions/vendorActions';

const FormModal = (props) => {
    const { open, handleClose, CreateQuestonnaireForm } = props;

    // state
    const [loading, setLoading] = useState(false);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const title = e.target.title.value;
        setLoading(true);
        const res = await CreateQuestonnaireForm({ title });
        setLoading(false);
        if (res.success) {
            toast.success(res?.message);
            handleClose();
        } else {
            toast.error(res.message);
        }
    };

    return (
        <Modal title="Create Form" open={open} onCancel={handleClose} footer={null} destroyOnClose={true}>
            <form onSubmit={handleFormSubmit}>
                <Row justify="end" gutter={16}>
                    <Col xs={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="title">Form Title</label>
                            <Input id="title" type="text" size="large" name="title" />
                        </div>
                    </Col>
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProp = (state) => {
    return state;
};
export default connect(mapStateToProp, { CreateQuestonnaireForm })(FormModal);
