import { DeleteOutlined, EditOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Input, Select, Space, TimePicker, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';

const { Option } = Select;
const { TextArea } = Input;

const FieldItem = (props) => {
    const { question, openFieldModal, deleteField } = props;
    return (
        <Col xs={24} md={question.fullWidth ? 24 : 12} className="my-2" key={question.name}>
            <Card
                extra={
                    <Space className="my-2">
                        <Button
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={openFieldModal}
                            className="flex justify-center items-center"
                        />
                        <Button
                            shape="circle"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={deleteField}
                            className="flex justify-center items-center"
                        />
                    </Space>
                }
            >
                <label className="w-full">
                    {question?.name}
                    {question?.required && <span style={{ color: 'red' }}>*</span>}
                    {question?.tooltip && (
                        <Tooltip placement="top" title={question.tooltip} arrow={true} className="ml-1">
                            <InfoCircleFilled />
                        </Tooltip>
                    )}
                </label>
                {question.type === 'text' ? (
                    <Input type="text" size="large" />
                ) : question.type === 'number' ? (
                    <Input type="number" size="large" required={question?.required} />
                ) : question?.type === 'dropdown' ? (
                    <Select size="large" style={{ width: '100%' }} required={question?.required}>
                        {question?.options?.map((option, index) => (
                            <Option value={option} key={index}>
                                {option}
                            </Option>
                        ))}
                    </Select>
                ) : question?.type === 'time' ? (
                    <TimePicker
                        defaultValue={moment('00:00:00', 'HH:mm:ss')}
                        size="large"
                        style={{ width: '100%' }}
                        required={question?.required}
                    />
                ) : question?.type === 'date' ? (
                    <DatePicker
                        defaultValue={moment('00:00:00', 'HH:mm:ss')}
                        size="large"
                        style={{ width: '100%' }}
                        required={question?.required}
                    />
                ) : (
                    <TextArea rows={4} size="large" required={question?.required} />
                )}
            </Card>
        </Col>
    );
};

export default FieldItem;
