import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { DeleteOutlined, MoreOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

const SubrequirementOperations = ({ openEditModal, sub_requirement, deleteSubReq, parent_req_num }) => {
    // const history = useHistory();
    const menu = (
        <Menu className="tableaction">
            <Menu.Item key="0">
                <Link
                    to={{
                        pathname: '/admin/pcidss4.0/requirement/subrequirement',
                        state: { sub_requirement, parent_req_num },
                    }}
                >
                    <EyeOutlined /> View
                </Link>
            </Menu.Item>
            <Menu.Item key="1" onClick={() => openEditModal('Edit', sub_requirement)}>
                <EditOutlined /> Edit
            </Menu.Item>
            <Menu.Item key="2" style={{ color: 'red' }} onClick={() => deleteSubReq(sub_requirement?.id)}>
                <DeleteOutlined /> Delete
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <a href="#" className="ant-dropdown-link">
                <MoreOutlined />
            </a>
        </Dropdown>
    );
};

export default SubrequirementOperations;
