import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';

// app components
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
//translation
import { useTranslation } from 'react-i18next';
const AcknowledgementCheckbox = (props) => {
    const { value, onChange } = props;
    const theme = useTheme();

    //translation hook
    const { t } = useTranslation('accounts');
    return (
        <AppCheckboxInput
            label={
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: 12,
                        color: theme.palette.gray[40],
                    }}
                >
                    {t('plans.acknowledgementCheckboxLabel.pre')}
                    <a
                        className="text-[14px] text-[#5E5E62] no-underline ml-1"
                        component="a"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.smartcomplyapp.com/terms"
                    >
                        {t('plans.acknowledgementCheckboxLabel.post')}
                    </a>
                </Typography>
            }
            sx={{ mb: 1 }}
            checkboxProps={{
                icon: <RadioButtonUnchecked sx={{ fontSize: 14 }} />,
                checkedIcon: <CheckCircle sx={{ fontSize: 14 }} />,
            }}
            value={value}
            onChange={onChange}
        />
    );
};

export default AcknowledgementCheckbox;
