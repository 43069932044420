import React from 'react';
import { Backdrop } from '@mui/material';

const AppBackdrop = (props) => {
    const { onClose, open, sx = {} } = props;
    return (
        <Backdrop
            sx={{
                zIndex: 101,
                backgroundColor: 'rgba(0,0,0,0.2)',
                ...sx,
            }}
            open={open}
            onClick={onClose}
        ></Backdrop>
    );
};

export default AppBackdrop;
