import React from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import HeightConnector from 'assets/img/heightConnector.png';
import HeightConnectorDisabled from 'assets/img/heightConnectorDisabled.png';
import { CheckCircle } from '@mui/icons-material';
// import HeightIcon from '@mui/icons-material/Height';

const SidebarStepQuestion = ({ currentStep, merchant, goBack, goToStep, categories, answers }) => {
    return (
        <div className="w-[382px] bg-[#EEF0FF]  h-full">
            <div className="flex items-center px-4 py-3">
                <button
                    className="w-8 h-8 flex items-center justify-center bg-[#DAE2FF] cursor-pointer overflow-hidden rounded-full font-bold"
                    onClick={goBack}
                >
                    <FaAngleLeft className="font-bold" color="#455A64B8" size="25px" />
                </button>
                <span className="pl-3 font-bold cursor-pointer">{merchant?.name?.toUpperCase()}</span>
            </div>
            <div className="px-4 py-4 h-[calc(100%_-_64px)] overflow-y-auto">
                <ol className="relative text-[#0E2C66]">
                    {categories?.map((item) => {
                        const isPrevious = currentStep >= item.index;
                        const answer = answers?.find((answer) => answer?.category === item?.id);
                        return (
                            <React.Fragment key={item.index}>
                                {item.index > 1 && (
                                    <React.Fragment>
                                        {isPrevious ? (
                                            <img src={HeightConnector} className="ml-3" />
                                        ) : (
                                            <img src={HeightConnectorDisabled} className="ml-3" />
                                        )}
                                    </React.Fragment>
                                )}
                                <button
                                    className="flex items-center text-[#C7C6CA] text-left"
                                    onClick={() => {
                                        goToStep(item.index);
                                    }}
                                    disabled={!answer && !item?.isDone}
                                >
                                    <div className="d-block">
                                        <div
                                            className={`w-10 h-10 rounded-full border-2 ${
                                                isPrevious ? 'border-[#0E2C66]' : 'border-[#C7C6CA]'
                                            } flex items-center justify-center`}
                                        >
                                            {answer || item?.isDone ? (
                                                <CheckCircle
                                                    color={isPrevious ? 'primary' : 'disabled'}
                                                    fontSize="small"
                                                />
                                            ) : (
                                                <span
                                                    className={`font-[600] text-lg ${
                                                        isPrevious ? 'text-[#0E2C66]' : 'text-[#C7C6CA]'
                                                    }`}
                                                >
                                                    {item?.index}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <h3
                                        className={`font-[600] leading-tight text-[14px] pl-3 ${
                                            isPrevious ? 'text-[#0E2C66]' : 'text-[#C7C6CA]'
                                        }`}
                                    >
                                        {item.name}
                                    </h3>
                                </button>
                            </React.Fragment>
                        );
                    })}
                </ol>
            </div>
        </div>
    );
};

export default SidebarStepQuestion;
