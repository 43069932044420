import React from 'react';
import { Dropdown } from 'antd';
import {
    DeleteOutlined,
    MoreOutlined,
    EyeOutlined,
    UsergroupAddOutlined,
    FileAddOutlined,
    BookOutlined,
    DownloadOutlined,
    // UserOutlined,
} from '@ant-design/icons';
import { SendMerchantSurvey } from 'store/actions/adminActions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { UpdateApprovedUser } from 'store/actions/FraudGauardianActions';
import { GenerateMerchantCertificate } from 'store/actions/auditActions';

const UserOperations = (props) => {
    const {
        openViewDrawer,
        deleteModal,
        record,
        openAssignmentModal,
        openViewUserDrawer,
        openAssignPlanModal,
        SendMerchantSurvey,
        openCourseModal,
        openViewAuditorModal,
        all_approved_user,
        UpdateApprovedUser,
        GenerateMerchantCertificate,
        all_merchants_certificates,
    } = props;

    const handleSubmit = async () => {
        const body = {
            merchant_ids: [record?.id],
        };
        const res = await SendMerchantSurvey(body);
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    const handleApproval = async (user) => {
        const body = {
            enabled: true,
        };
        const res = await UpdateApprovedUser(user?.id, body);
        if (res?.success) {
            toast.success('Fraud API request successful!');
        } else {
            toast.error(res?.message);
        }
    };

    const generateMerchantCertificate = async () => {
        const body = {
            merchant: record?.id,
        };
        const res = await GenerateMerchantCertificate(body);
        if (res?.success) {
            toast.success('Certificate successfully geerated!');
        } else {
            toast.error(res?.message);
        }
    };

    const find_merchant =
        all_approved_user?.length === undefined
            ? all_approved_user?.merchant === record?.id
            : all_approved_user?.find((merchant) => merchant?.merchant === record?.id);

    const find_merchant_certificate = all_merchants_certificates?.find(
        (certificate) => certificate?.merchant === record?.id
    );

    const paid_standards = record?.paid_standards?.split(',');

    const items = [
        {
            key: 0,
            icon: <EyeOutlined />,
            label: 'View',
            onClick: () => openViewDrawer(record),
        },
        {
            key: 1,
            icon: <UsergroupAddOutlined />,
            label: 'Assign Auditor',

            children: paid_standards?.length
                ? paid_standards?.map((standard, idx) => {
                      return {
                          key: idx + '.',
                          label: standard,
                          onClick: () => openAssignmentModal(record, standard),
                      };
                  })
                : [],
        },
        {
            key: 2,
            icon: <EyeOutlined />,
            label: 'View assigned Auditors',
            children: paid_standards?.length
                ? paid_standards?.map((standard, idx) => {
                      return {
                          key: ++idx + '_',
                          label: standard,
                          onClick: () => openViewAuditorModal(record, standard),
                      };
                  })
                : [],
        },
        {
            key: 3,
            icon: <FileAddOutlined />,
            label: 'Plan',
            onClick: () => openAssignPlanModal(record),
        },
        {
            key: 4,
            icon: <UsergroupAddOutlined />,
            label: 'View Users',
            onClick: () => openViewUserDrawer(record),
        },
        {
            key: 5,
            icon: <FileAddOutlined />,
            label: 'Send Survey',
            onClick: handleSubmit,
        },
        {
            key: 6,
            icon: <FileAddOutlined />,
            label: 'Assign Course',
            onClick: () => openCourseModal(record),
        },
        find_merchant &&
            find_merchant?.enabled === false && {
                key: 7,
                icon: <BookOutlined />,
                label: 'Approve Fraud Guardian',
                onClick: () => handleApproval(find_merchant),
            },
        {
            key: 8,
            icon: <DownloadOutlined />,
            label: 'Generate Certificate',
            disabled: find_merchant_certificate,
            onClick: generateMerchantCertificate,
        },
        {
            key: 9,
            icon: <DeleteOutlined />,
            label: 'Delete',
            onClick: () => deleteModal(record),
        },
    ];

    // const menu = (
    //     <Menu className="tableaction">
    //         <Item key="0" onClick={() => openViewDrawer(record)}>
    //             <EyeOutlined /> View
    //         </Item>
    //         {/* {record?.seek_auditor && ( */}
    //         <Item key="1" onClick={() => openAssignmentModal(record)}>
    //             <UsergroupAddOutlined /> Assign
    //         </Item>
    //         {/* )} */}
    //         <Item key="2" onClick={() => openAssignPlanModal(record)}>
    //             <FileAddOutlined /> Plan
    //         </Item>
    //         <Item key="3" onClick={() => openViewUserDrawer(record)}>
    //             <UsergroupAddOutlined /> View Users
    //         </Item>
    //         <Item key="4" onClick={handleSubmit}>
    //             <FileAddOutlined /> Send Survey
    //         </Item>
    //         <Item key="5" onClick={() => openCourseModal(record)}>
    //             <BookOutlined /> Assign Course
    //         </Item>
    //         {find_merchant && find_merchant?.enabled === false && (
    //             <Item key="6" onClick={() => handleApproval(find_merchant)}>
    //                 <BookOutlined /> Approve Fraud Guardian
    //             </Item>
    //         )}
    //         <Item key="7" disabled={find_merchant_certificate} onClick={generateMerchantCertificate}>
    //             <DownloadOutlined /> Generate Certificate
    //         </Item>
    //         <Item key="8" onClick={() => console.log('assign auditor')}>
    //             <UserOutlined /> Assign Auditor
    //         </Item>
    //         <Item key="9" style={{ color: 'red' }} onClick={() => deleteModal(record)}>
    //             <DeleteOutlined /> Delete
    //         </Item>
    //     </Menu>
    // );

    return (
        <Dropdown
            menu={{
                items,
            }}
            trigger={['click']}
        >
            <a
                href="#"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                onKeyDown={(e) => e.preventDefault()}
            >
                <MoreOutlined />
            </a>
        </Dropdown>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchants: state?.adminReducers?.all_merchants,
        all_merchants_certificates: state?.auditReducers?.all_merchants_certificate,
    };
};
export default connect(mapStateToProps, {
    UpdateApprovedUser,
    SendMerchantSurvey,
    GenerateMerchantCertificate,
})(UserOperations);
