import { ReactComponent as MeterPin } from 'assets/img/meter-pin.svg';
import LowImage from 'assets/img/low.svg';
import HighImage from 'assets/img/high.svg';
// import { toast } from 'react-toastify';

export const Meter = ({ percentage = 0 }) => {
    function percentageToDegree(percentage) {
        // if (percentage < 0 || percentage > 100) {
        //     toast.info('Percentage must be between 0 and 100.');
        // }
        return -90 + (percentage / 50) * 90; // Maps 0-50% to -90 to 0 degrees
    }

    const degree = percentageToDegree(percentage);

    // Inline style for SVG rotation
    const pinStyle = {
        transform: `rotate(${degree}deg)`,
        // transformOrigin: 'bottom center',
        transition: 'transform 0.5s ease',
        // position: 'absolute',
        transformOrigin: 'center bottom',
        height: '113px',
    };
    return (
        <div className="bg-white flex flex-col gap-0 items-center">
            <div className="flex relative">
                <svg width="270" height="136" viewBox="0 0 355 179" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M355 177.5C355 130.424 336.299 85.2763 303.011 51.9885C269.724 18.7008 224.576 3.59603e-06 177.5 4.18976e-08C130.424 -3.51224e-06 85.2763 18.7008 51.9886 51.9885C18.7009 85.2762 7.34905e-06 130.424 2.40788e-07 177.5H44.375C44.375 142.193 58.4006 108.332 83.3664 83.3664C108.332 58.4006 142.193 44.375 177.5 44.375C212.807 44.375 246.668 58.4006 271.634 83.3664C296.599 108.332 310.625 142.193 310.625 177.5H355Z"
                        fill="#BA1A1A"
                    />
                    <path
                        d="M91.5335 22.2067C63.7206 37.6032 40.5531 60.1867 24.4517 87.5975C8.35022 115.008 -0.0941815 146.24 0.000792367 178.03L44.3756 177.898C44.3044 154.055 50.6377 130.631 62.7138 110.073C74.7898 89.515 92.1655 72.5774 113.025 61.03L91.5335 22.2067Z"
                        fill="#37A372"
                    />
                    <path
                        d="M266.25 23.7805C239.334 8.24044 208.809 0.0402515 177.729 0.000147807C146.649 -0.0399558 116.103 8.08143 89.147 23.552L111.235 62.039C131.452 50.4361 154.362 44.345 177.672 44.3751C200.982 44.4052 223.875 50.5553 244.062 62.2104L266.25 23.7805Z"
                        fill="#FDDB65"
                    />
                </svg>
                <img src={LowImage} alt={'Low'} className="absolute top-[30%] left-1 w-[60px]" />

                <h4 className="absolute top-[5%] left-[37%] text-[#475569] text-base font-semibold tracking-wider">
                    Medium
                </h4>
                <img src={HighImage} alt={'High'} className="absolute top-[30%] right-1 w-[60px]" />

                <div className="absolute w-[270px] h-[136px] flex justify-center items-end top-0">
                    <MeterPin style={pinStyle} />
                </div>
            </div>
            <span className={`font-bold text-[28px] ${percentage >= 50 ? 'text-[#de3730]' : 'text-[#334155]'}`}>
                {percentage || 0}%
            </span>
        </div>
    );
};
