import React from 'react';
import { Box } from '@mui/material';

const SectionCard = (props) => {
    const { children, ...otherProps } = props;
    return (
        <Box
            {...otherProps}
            sx={{
                flex: 1,
                boxShadow:
                    '-23px 170px 68px rgba(209, 209, 209, 0.01), -13px 95px 58px rgba(209, 209, 209, 0.05), -6px 42px 43px rgba(209, 209, 209, 0.09), -1px 11px 24px rgba(209, 209, 209, 0.1), 0px 0px 0px rgba(209, 209, 209, 0.1)',
                borderRadius: 2.5,
                p: 2,
                display: 'flex',
            }}
        >
            {children}
        </Box>
    );
};

export default SectionCard;
