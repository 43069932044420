import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React from 'react';

// sub component
const { Item } = Menu;

const SubcriteriaActions = (props) => {
    const { row, deleteSubcriteria, openModal } = props;
    const menu = (
        <Menu>
            <Item key="1" onClick={() => openModal('Edit', row)}>
                <span className="flex items-center">
                    <EditOutlined />
                    Edit
                </span>
            </Item>
            <Item key="0" onClick={() => deleteSubcriteria(row?.id)}>
                <span className="flex items-center text-red-500">
                    <DeleteOutlined />
                    Delete
                </span>
            </Item>
        </Menu>
    );
    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <MoreOutlined />
        </Dropdown>
    );
};

export default SubcriteriaActions;
