import React from 'react';
import { Switch, Route } from 'react-router-dom';
import KdpaDashboard from './KdpaDashboard';
import FormsAndDocumentsView from './FormsAndDocumentsView';
import KdpaFormCreation from '../Operations/FormCreation';

const Kdpa = () => {
    return (
        <Switch>
            <Route path="/admin/kdpa" exact component={KdpaDashboard} />
            <Route path="/admin/kdpa/category" exact component={FormsAndDocumentsView} />
            <Route path="/admin/kdpa/form" component={(props) => <KdpaFormCreation tag="kdpa" {...props} />} />
        </Switch>
    );
};

export default Kdpa;
