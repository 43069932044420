import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

export const InverntoryScanAml = ({ third_party_options, view }) => {
    const { all_third_party_summary } = useSelector((state) => state?.CISOReducers);
    return (
        <div>
            <div>
                <div
                    hidden={
                        !third_party_options?.includes('Anti-Money Laundering Check') ||
                        view === 'Graphical Presentation' ||
                        all_third_party_summary?.aml_check_info?.length === 0
                    }
                >
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">Anti-Money Laundering Check</p>
                    </div>
                    <table className="w-full w-full">
                        <thead className="w-full">
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black">
                                    Risk Level
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black">
                                    Date
                                </th>
                            </tr>
                        </thead>
                        {all_third_party_summary?.aml_check_info?.map((option, index) => (
                            <tbody key={index}>
                                <tr>
                                    <th className="text-gray-500 text-xs font-normal p-3 text-center border border-black">
                                        {option?.name}
                                    </th>
                                    <th
                                        className={`${
                                            option?.risk_level === 'Low' ? 'text-[#55BE8B]' : 'text-[#FF5449]'
                                        } text-xs font-normal p-3 text-center border border-black`}
                                    >
                                        {option?.risk_level || 'N/A'}
                                    </th>
                                    <th
                                        scope="row"
                                        className={`text-xs font-normal p-3 text-center border border-black`}
                                    >
                                        {moment(option?.date_created)?.format('Do MMM, YYYY')}
                                    </th>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>
        </div>
    );
};

export const InverntoryScanTransactionmonitoring = ({ third_party_options, view }) => {
    const { all_third_party_summary } = useSelector((state) => state?.CISOReducers);
    return (
        <div>
            <div>
                <div
                    hidden={
                        !third_party_options?.includes('Transaction Fraud Monitoring') ||
                        view === 'Graphical Presentation' ||
                        all_third_party_summary?.transaction_fraud_info?.length === 0
                    }
                >
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">Transaction Fraud Monitoring</p>
                    </div>
                    <table className="w-full w-full">
                        <thead className="w-full">
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black">
                                    Risk Level
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black">
                                    Date
                                </th>
                            </tr>
                        </thead>
                        {all_third_party_summary?.transaction_fraud_info?.map((option, index) => (
                            <tbody key={index}>
                                <tr>
                                    <th className="text-gray-500 text-xs font-normal p-3 text-center border border-black">
                                        {option?.name}
                                    </th>
                                    <th
                                        scope="row"
                                        className={`text-xs font-normal p-3 text-center border border-black`}
                                    >
                                        {option.percentage}%
                                    </th>
                                    <th
                                        className={`${
                                            option?.risk_level === 'Low' ? 'text-[#55BE8B]' : 'text-[#FF5449]'
                                        } text-xs font-normal p-3 text-center border border-black`}
                                    >
                                        {option?.statue}
                                    </th>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>
        </div>
    );
};

export const InverntoryScanVendorMangement = ({ third_party_options, view }) => {
    const { all_third_party_summary } = useSelector((state) => state?.CISOReducers);
    return (
        <div>
            <div>
                <div
                    hidden={
                        !third_party_options?.includes('Vendor Management') ||
                        view === 'Graphical Presentation' ||
                        all_third_party_summary?.vendor_management_info?.filter((item) => item?.status === false)
                            ?.length === 0
                    }
                >
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">Vendor Management(Vendors)</p>
                    </div>
                    <table className="w-full w-full">
                        <thead className="w-full">
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black">
                                    Risk Level
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black">
                                    Date
                                </th>
                            </tr>
                        </thead>
                        {all_third_party_summary?.vendor_management_info
                            ?.filter((item) => item?.status === false)
                            ?.map((option, index) => (
                                <tbody key={index}>
                                    <tr>
                                        <th className="text-gray-500 text-xs font-normal p-3 text-center border border-black">
                                            {option?.name}
                                        </th>
                                        <th className="text-gray-500 text-xs font-normal p-3 text-center border border-black">
                                            {option?.risk_level}
                                        </th>
                                        <th
                                            className={`${
                                                option?.status ? 'text-[#55BE8B]' : 'text-[#FF5449]'
                                            } text-xs font-normal p-3 text-center border border-black`}
                                        >
                                            {option?.status ? 'Responded' : 'Not Responded'}
                                        </th>
                                        <th
                                            scope="row"
                                            className={`text-xs font-normal p-3 text-center border border-black`}
                                        >
                                            {moment(option?.date_created)?.format('Do MMM, YYYY')}
                                        </th>
                                    </tr>
                                </tbody>
                            ))}
                    </table>
                </div>
            </div>
        </div>
    );
};

export const InverntoryScanActingAsVendor = ({ third_party_options, view }) => {
    const { all_third_party_summary } = useSelector((state) => state?.CISOReducers);
    return (
        <div>
            <div>
                <div
                    hidden={
                        !third_party_options?.includes('Vendor Management') ||
                        view === 'Graphical Presentation' ||
                        all_third_party_summary?.acting_as_vendor_info?.length === 0
                    }
                >
                    <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                        <p className="text-white text-sm font-semibold">Vendor Management(Acting as a vendor)</p>
                    </div>
                    <table className="w-full w-full">
                        <thead className="w-full">
                            <tr>
                                <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border border-black">
                                    Name
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black">
                                    Status
                                </th>
                                <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border border-black">
                                    Date
                                </th>
                            </tr>
                        </thead>
                        {all_third_party_summary?.acting_as_vendor_info?.map((option, index) => (
                            <tbody key={index}>
                                <tr>
                                    <th className="text-gray-500 text-xs font-normal p-3 text-center border border-black">
                                        {option?.file_name}
                                    </th>
                                    <th
                                        className={`${
                                            option?.status === 'completed'
                                                ? 'text-[#55BE8B]'
                                                : option?.status === 'in_progress'
                                                ? 'text-[#F2C021]'
                                                : 'text-[#395BA9]'
                                        } text-xs font-normal p-3 text-center border border-black capitalize`}
                                    >
                                        {option?.status?.replace(/_/g, ' ')}
                                    </th>
                                    <th
                                        scope="row"
                                        className={`text-xs font-normal p-3 text-center border border-black`}
                                    >
                                        {moment(option?.date_added)?.format('Do MMM, YYYY')}
                                    </th>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>
        </div>
    );
};
