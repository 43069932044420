import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import BreadCrumb from 'components/Breadcrumb';
import AnnexModal from '../IsoActions/AnnexModal';

// antd components
import { Button, Row, Col, Typography, Card, Modal, Spin, Empty, Tooltip } from 'antd';

// icons
import { DeleteOutlined, EditOutlined, EyeOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

// utils
import { numExtraction } from 'utils';

//redux
import { connect } from 'react-redux';
import { GetAllAnnexesNew, CreateAnnexNew, EditAnnexNew, DeleteAnnexNew } from 'store/actions/adminActions';

// sub-components
const { Title } = Typography;
const { confirm } = Modal;

const AnnexesView = (props) => {
    const { GetAllAnnexesNew, CreateAnnexNew, EditAnnexNew, DeleteAnnexNew, all_annexes } = props;
    // state
    const [loading, setLoading] = useState({
        content: false,
    });
    const [modal, setModal] = useState(null);
    const [oneReq, setOneReq] = useState({});

    // functions
    const openModal = (mode, data) => {
        setModal(mode);
        setOneReq(data);
    };
    const closeModal = () => {
        setModal(null);
    };

    const deleteAnnex = (id) => {
        confirm({
            title: 'Are you sure you want to delete this category? Note: Deleting this category means you will be deleting this category, the documents, audit questions and merchant data associated with this category',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteAnnexNew(id);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    const extractNum = (title) => {
        const titleNum = numExtraction(title.split('.')[1]);
        return titleNum;
    };
    useEffect(() => {
        const getAllAnnex = async () => {
            setLoading(true);
            const res = await GetAllAnnexesNew();
            if (res?.success) {
                setLoading(false);
            } else {
                setLoading(false);
                toast.error(res?.message);
            }
        };
        getAllAnnex();
    }, []);
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[{ label: 'ISO27017 + 27032', link: '/iso27017+27032' }, { label: 'Annexes' }]}
                    />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal('Add')}
                    >
                        Add Annex
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {loading.content ? (
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                ) : (
                    <>
                        {all_annexes && all_annexes?.length > 0 ? (
                            <Row wrap gutter={24}>
                                {all_annexes
                                    ?.sort((a, b) => extractNum(a.title) - extractNum(b.title))
                                    ?.map((annex, index) => (
                                        <Col
                                            xs={24}
                                            md={12}
                                            lg={8}
                                            xxl={6}
                                            key={annex.id}
                                            style={{ marginBottom: '1rem' }}
                                        >
                                            <Card
                                                title={`#${index}`}
                                                loading={false}
                                                actions={[
                                                    <Tooltip title="View Annex" color="blue">
                                                        <Link
                                                            key={index + 1}
                                                            to={{
                                                                pathname: '/admin/iso27017+27032/annex',
                                                                state: {
                                                                    annex,
                                                                },
                                                            }}
                                                        >
                                                            <EyeOutlined key="preview" />
                                                        </Link>
                                                    </Tooltip>,
                                                    <Tooltip title="Edit Annex" color="blue">
                                                        <EditOutlined
                                                            key="edit"
                                                            onClick={() => openModal('Edit', annex)}
                                                        />
                                                    </Tooltip>,
                                                    <Tooltip title="Delete Annex" color="blue">
                                                        <DeleteOutlined
                                                            key="delete"
                                                            onClick={() => deleteAnnex(annex.id, annex.category)}
                                                        />
                                                    </Tooltip>,
                                                ]}
                                            >
                                                <Title level={5} style={{ textAlign: 'center' }}>
                                                    {annex.title}
                                                </Title>
                                            </Card>
                                        </Col>
                                    ))}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Annexes</h3>
                                                <span>Add a new Annex </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section>
            <AnnexModal
                open={modal}
                handleCancel={closeModal}
                one_req={oneReq}
                CreateAnnex={CreateAnnexNew}
                EditAnnex={EditAnnexNew}
            />
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        all_annexes: state?.adminReducers?.all_annexes_new,
    };
};
export default connect(mapStateToProps, {
    GetAllAnnexesNew,
    CreateAnnexNew,
    EditAnnexNew,
    DeleteAnnexNew,
})(AnnexesView);
