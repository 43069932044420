import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

//core components
import BreadCrumb from 'components/Breadcrumb';
import IntegrationTableOperations from './Operations/IntegrationTableOperations';

//antd components
import { Col, Row, Button, Table, Modal } from 'antd';

// icons
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { useLocation } from 'react-router-dom';
//redux
import { connect } from 'react-redux';
import { GetAllIntegrationsByGroup, DeleteIntegration } from 'store/actions/adminActions';
import IntegrationModal from './Operations/IntegrationModal';
import { truncateText } from 'utils';
import EditIntegrationModal from './Operations/EditIntegrationModal';

const { confirm } = Modal;
const IntegrationsPage = (props) => {
    const { GetAllIntegrationsByGroup, DeleteIntegration, all_integrations_by_group } = props;
    const [loading, setloading] = useState(false);
    const [AddModalOpen, setAddModalOpen] = useState(false);
    const [EditModalOpen, setEditModalOpen] = useState(false);
    const [payload, setPayload] = useState({});
    const [id, setID] = useState(0);
    const location = useLocation();

    // data
    const columns = [
        {
            title: 'Name',
            render: (record) => record?.name,
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Logo',
            render: (record) => (
                <>
                    <img src={record?.logo} alt={record?.logo} width="100px" />
                </>
            ),
        },
        {
            title: 'Tips',
            render: (record) => truncateText(record?.tips?.replace(/<[^>]+>/g, '')),
        },
        {
            title: 'Actions',
            align: 'right',
            render: (record) => (
                <IntegrationTableOperations
                    record={record}
                    openEditModal={openEditModal}
                    deleteIntegration={deleteIntegration}
                />
            ),
        },
    ];

    const openModal = () => {
        setAddModalOpen(true);
    };
    const closeModal = () => {
        setAddModalOpen(false);
    };

    const openEditModal = (record) => {
        setEditModalOpen(true);
        setPayload(record);
        setID(record?.id);
    };
    const closeEditModal = () => {
        setEditModalOpen(false);
        setPayload({});
    };

    const deleteIntegration = (id) => {
        confirm({
            title: 'Are you sure you want to delete this field?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteIntegration(id, location?.state?.group_id, location?.state?.name);
                if (res.success) {
                    toast.success('Field Deleted Successfully');
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    useEffect(() => {
        const getAllIntegrationsByGroup = async () => {
            setloading(true);
            const res = await GetAllIntegrationsByGroup(location?.state?.group_id, location?.state?.name);
            setloading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllIntegrationsByGroup();
    }, [location?.state?.group_id, location?.state?.name]);
    return (
        <>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[{ label: 'Integrations', link: '/integrations' }, { label: location?.state?.name }]}
                    />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal()}
                    >
                        Add Integration
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Table
                    columns={columns}
                    dataSource={all_integrations_by_group?.[location?.state?.name]}
                    loading={loading}
                    rowKey={(integration) => integration?.id}
                />
            </section>

            <IntegrationModal
                open={AddModalOpen}
                handleClose={closeModal}
                group_id={location?.state?.group_id}
                group_name={location?.state?.name}
            />
            <EditIntegrationModal
                open={EditModalOpen}
                handleClose={closeEditModal}
                payload={payload}
                id={id}
                group_id={location?.state?.group_id}
                group_name={location?.state?.name}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_integrations_by_group: state?.adminReducers?.all_integrations_by_group,
    };
};
export default connect(mapStateToProps, {
    GetAllIntegrationsByGroup,
    DeleteIntegration,
})(IntegrationsPage);
