import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Stack, Divider } from '@mui/material';
import { Link } from 'react-router-dom';

const AuthViewContainer = (props) => {
    const theme = useTheme();
    const { children, title, subtitle, altLink2, altLink = {}, divider, sx, type } = props;
    return (
        <Stack sx={{ ...sx }}>
            <Stack component="header" sx={{ pt: 2, mb: 1 }}>
                <Typography variant="h1" component="h1" fontSize="28px" margin={0} fontWeight={700} textAlign="center">
                    {title}
                </Typography>
                {type ? (
                    subtitle
                ) : (
                    <Typography
                        variant="subtitle2"
                        component="span"
                        fontSize="18px"
                        color={theme.palette.gray[600]}
                        textAlign="center"
                    >
                        {subtitle}
                    </Typography>
                )}
            </Stack>
            <Box sx={{ pb: 2, pt: 1 }}>{children}</Box>
            <Stack>
                {divider ? (
                    <Divider
                        orientation="horizontal"
                        sx={{
                            borderColor: theme.palette.primary[900],
                            width: '90%',
                            maxWidth: 360,
                        }}
                    />
                ) : null}
                <Stack direction="row" alignItems="center">
                    {location.pathname !== '/auth/add-merchant' && (
                        <Typography
                            component="span"
                            color="#303034"
                            sx={{
                                textDecoration: 'none',
                                marginRight: '3px',
                                fontSize: '14px',
                                fontWeight: '400',
                                ':hover': { textDecoration: 'none' },
                            }}
                        >
                            {altLink2}
                        </Typography>
                    )}
                    <Box
                        component={Link}
                        to={altLink?.to}
                        sx={{
                            color: theme.palette.primary[900],
                            fontWeight: 500,
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {altLink?.text}
                    </Box>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AuthViewContainer;
