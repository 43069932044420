import React from 'react';
import { Drawer, Row, Col, Tag } from 'antd';

//utils
import { formatDateObjectHandler } from 'utils';

const ViewPlanDetails = (props) => {
    const { open, handleClose, record } = props;
    return (
        <div>
            <Drawer
                title={`Plan Details for ${record?.name} Plan`}
                open={open}
                width="800px"
                getContainer={false}
                onClose={handleClose}
                destroyOnClose={true}
                contentWrapperStyle={{
                    overflow: 'auto',
                    // paddingBottom: '14px',
                }}
            >
                <div className="userdataContainer">
                    {Object.prototype.toString.call(record) === '[object Object]'
                        ? Object.keys(record)?.map((item) => {
                              return item === 'id' || item === 'date_updated' ? null : (
                                  <div className="item" key={item}>
                                      <Row gutter={28} align="middle">
                                          <Col xs={12} xl={8} lg={8}>
                                              <span style={{ textTransform: 'capitalize' }}>
                                                  {item?.replace('_', ' ')}:
                                              </span>
                                          </Col>
                                          <Col xs={12} xl={12} lg={12}>
                                              {typeof record?.[item] === 'boolean' ? (
                                                  record?.[item] ? (
                                                      <Tag color="green">Yes</Tag>
                                                  ) : (
                                                      <Tag color="red">No</Tag>
                                                  )
                                              ) : item === 'date_created' ? (
                                                  formatDateObjectHandler(record?.[item])
                                              ) : (
                                                  record?.[item] || 'Not Available'
                                              )}
                                          </Col>
                                      </Row>
                                  </div>
                              );
                          })
                        : null}
                </div>
            </Drawer>
        </div>
    );
};

export default ViewPlanDetails;
