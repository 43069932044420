import { getType } from 'utils';
import axiosInstance from '../../utils/https';
import {
    NDPR_CISO_SUMMARY,
    KDPA_CISO_SUMMARY,
    GDPA_CISO_SUMMARY,
    SOC2_CISO_SUMMARY,
    PCIDSS_CISO_SUMMARY,
    PCIDSS4POINT0_CISO_SUMMARY,
    ISO_CISO_SUMMARY,
    ISO_TWO_CISO_SUMMARY,
    TECH_SEC_CISO_SUMMARY,
    ISO9001_CISO_SUMMARY,
    PCISLC_CISO_SUMMARY,
    PEOPLE_INFO_SUMMARY,
    PEOPLE_SUMMARY,
    PEOPLE_TRAINING_SUMMARY,
    ISO22301_CISO_SUMMARY,
    PEOPLE_SCAN_SUMMARY,
    INVENTORY_SCAN_SUMMARY,
    SOURCE_CODE_SCAN_SUMMARY,
    API_SECURITY_SCAN_SUMMARY,
    PCI_SCAN_SUMMARY,
    VUL_SCAN_SUMMARY,
    ALL_VENDORS_SUMMARY,
    ALL_KYC_SUMMARY,
    ALL_FRAUD_SUMMARY,
    ALL_MERCHANT_RISK_ASSESSMENTS_SUMMARY,
    ALL_RISK_ASSESSMENTS_SUMMARY,
    ALL_PENTESTS_SUMMARY,
    ALL_AUTOMATEDSCANS_SUMMARY,
    ALL_THIRD_PARTY_SUMMARY,
    PCISLC_TABLE_CISO_SUMMARY,
    BLOCKCHAIN_CISO_SUMMARY,
    CII_CISO_SUMMARY,
    BLOCKCHAIN_GENERAL_CISO_SUMMARY,
    CII_GENERAL_CISO_SUMMARY,
    FRAMEWORK_PERCENTAGE_CISO_SUMMARY,
    CISO_PERCENTAGE_CISO_SUMMARY,
} from '../constants';

export const GetMerchantNDPRSummaryByCategory = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/ndpr_get_summary/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: NDPR_CISO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//K D P A
export const GetMerchantKDPASummaryByCategory = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/kdpa_get_summary/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: KDPA_CISO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//G D P A
export const GetMerchantGDPASummaryByCategory = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/gdpa_get_summary/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: GDPA_CISO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//SOC 2
export const GetMerchantSoc2SummaryByControl = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/soc2_get_summary/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: SOC2_CISO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//PCIDSS
export const GetMerchantOverviewSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/get_summary/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PCIDSS_CISO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//PCIDSS4POINT0
export const GetMerchantPcidss4point0OverviewSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/card4_summary/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PCIDSS4POINT0_CISO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//BLOCKCHAIN
export const GetMerchantBlockhainSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/criteria_summary/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: BLOCKCHAIN_CISO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantGeneralBlockhainSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/general_criteria_summary/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: BLOCKCHAIN_GENERAL_CISO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//CII DIRECTIVE
export const GetMerchantCiiDirectiveSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/cii_summary/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: CII_CISO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantGeneralCiiDirectiveSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/general_cii_summary/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: CII_GENERAL_CISO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// ISO27001

export const GetMerchantIsoSummaryByAnnex = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/iso_get_summary/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ISO_CISO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantTechSecSummaryByTechSec = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/tech_sec_get_summary/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: TECH_SEC_CISO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// ISO27001-20222

export const GetMerchantIsoTwoSummaryByAnnex = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/iso_two_get_summary/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ISO_TWO_CISO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
// PCI SSLC
export const GetMerchantPCISLCGeneralSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/sslc_general_summary/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PCISLC_CISO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetMerchantPCISLCSummary = (params) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/sslc_get_summary/`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PCISLC_TABLE_CISO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

// ISO9001
export const GetMerchantISO9001SummaryByManagement = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/iso9001_get_summary/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ISO9001_CISO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

//I S 0 2 2 3 0 1
export const GetContinuityGroupSummary = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/continuity_management_summary/?merchant_id=${company_id}`);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ISO22301_CISO_SUMMARY, payload: res?.data });

        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetPeopleInfoSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/people_info?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PEOPLE_INFO_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetPeopleSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/people/?merchan_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PEOPLE_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetPeopleTrainingInfoSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`compliance/employee_training_info/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PEOPLE_TRAINING_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetPeopleScanSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`scans/scripts/?merchant=${company_id}`, {
            params,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PEOPLE_SCAN_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetInventoryScanSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/inventory_info/?merchant_id=${company_id}`, {
            params,
        });
        dispatch({ type: INVENTORY_SCAN_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetSourceCodeScanSummary = (params) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/asvc_requests/source_code/`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: SOURCE_CODE_SCAN_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAPISecurityScanSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/compliance/api_security?merchant=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: API_SECURITY_SCAN_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetPCIcanSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/asvc_requests/avscansrequest?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: PCI_SCAN_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetVulScanSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || getType('org_id');
    try {
        const res = await axiosInstance().get(`/scans/vunerabilty_scan/?merchant_id=${company_id}`, {
            params,
        });

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: VUL_SCAN_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const GetAllVendorSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/vendor/?merchant=${company_id}`, {
            params,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_VENDORS_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllKycSummary = (params) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/v1/fraudguardian/kyc_result/`, {
            params,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_KYC_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllFraudMonitoringSummary = (params) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/v1/fraudguardian/fraud_monitoring/`, {
            params,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_FRAUD_SUMMARY, payload: res?.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllMerchantRiskAssessments = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/scans/risk_assessment/?merchant_id=${company_id}`);
        dispatch({ type: ALL_MERCHANT_RISK_ASSESSMENTS_SUMMARY, payload: res });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllRiskAssessmentsSummary = (merchant) => async (dispatch, getState) => {
    const user_type = getState().authReducers?.user_type;
    const company_id = user_type === 'auditor' ? merchant : getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/scans/risk_assessment_summary/?merchant_id=${company_id}`);
        dispatch({ type: ALL_RISK_ASSESSMENTS_SUMMARY, payload: res });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllPentestSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/asvc_requests/pentest_request/?merchant_id=${company_id}`, {
            params,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_PENTESTS_SUMMARY, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAutomatedScansSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/integrations/scans_summary/?merchant_id=${company_id}`, {
            params,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_AUTOMATEDSCANS_SUMMARY, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetThirdPartyInfoSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/third_party_info/?merchant_id=${company_id}`, {
            params,
        });
        dispatch({ type: ALL_THIRD_PARTY_SUMMARY, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetFrameworkPercentageSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/compliance_percentage/?merchant_id=${company_id}`, {
            params,
        });
        dispatch({ type: FRAMEWORK_PERCENTAGE_CISO_SUMMARY, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetCisoPercentageSummary = (params) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/ciso_compliance_percentage/?merchant_id=${company_id}`, {
            params,
        });
        dispatch({ type: CISO_PERCENTAGE_CISO_SUMMARY, payload: res.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
