export function sortObjectByValueDescending(obj) {
    const entries = Object.entries(obj);

    entries.sort((a, b) => b[1] - a[1]);

    const sortedObj = Object.fromEntries(entries);

    return sortedObj;
}

export function separateAndAccumulate(obj) {
    const entries = Object.entries(sortObjectByValueDescending(obj)).sort((a, b) => b[1] - a[1]);

    const result = {};

    for (let i = 0; i < 3 && i < entries.length; i++) {
        result[entries[i][0]] = entries[i][1];
    }

    let othersTotal = 0;
    for (let i = 3; i < entries.length; i++) {
        othersTotal += entries[i][1];
    }

    if (entries.length > 3) {
        result['Others'] = othersTotal;
    }

    return result;
}

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export function formatNumber(num) {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'; // Format in millions
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'; // Format in thousands
    } else {
        return num.toString(); // Return the number as is for less than 1000
    }
}

export const keyExists = (obj, query) => {
    const keys = Object.keys(obj);
    return keys.includes(query);
};

export const ReduceValues = (data) => {
    if (Array.isArray(data) && data.length > 0) {
        return data.reduce((total, el) => el + total, 0);
    }

    return 0;
};

export const Matrix3Data = [
    {
        0: 0,
    },
    {
        1: 0,
    },
    {
        2: 0,
    },
    {
        3: 0,
    },
    {
        4: 0,
    },
    {
        5: 0,
    },
    {
        6: 0,
    },
    {
        7: 0,
    },
    {
        8: 0,
    },
];

export const Matrix5Data = [
    { 0: 0 },
    { 1: 0 },
    { 2: 0 },
    { 3: 0 },
    { 4: 0 },
    { 5: 0 },
    { 6: 0 },
    { 7: 0 },
    { 8: 0 },
    { 9: 0 },
    { 10: 0 },
    { 11: 0 },
    { 12: 0 },
    { 13: 0 },
    { 14: 0 },
    { 15: 0 },
    { 16: 0 },
    { 17: 0 },
    { 18: 0 },
    { 19: 0 },
    { 20: 0 },
    { 21: 0 },
    { 22: 0 },
    { 23: 0 },
    { 24: 0 },
];

export const currencies = [
    { code: 'NGN', symbol: '₦' },
    { code: 'USD', symbol: '$' },
    { code: 'EUR', symbol: '€' },
    { code: 'GBP', symbol: '£' },
    { code: 'KES', symbol: 'Ksh' },
    { code: 'GHS', symbol: '₵' },
];
