import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ArticleDashboard from './ArticleDashboard';
import ArticlePage from './ArticlePage';

const Article = () => {
    return (
        <Switch>
            <Route path="/admin/articles" exact component={ArticleDashboard} />
            <Route path="/admin/articles/article/" exact component={ArticlePage} />
        </Switch>
    );
};

export default Article;
