import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ContinuityView from './ContinuityView';
import FormsAndDocumentsView from './IsoActions/FormsAndDocuments';
import IsoFormCreation from '../Operations/FormCreation';

const Iso22301 = () => {
    return (
        <Switch>
            <Route path="/admin/iso22301" exact component={ContinuityView} />
            <Route path="/admin/iso22301/clause_details" exact component={FormsAndDocumentsView} />
            <Route path="/admin/iso22301/form" component={(props) => <IsoFormCreation tag="continuity" {...props} />} />
        </Switch>
    );
};

export default Iso22301;
