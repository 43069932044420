import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    Avatar,
    Box,
    Button,
    Card,
    Container,
    Divider,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    List,
    ListItem,
    ListItemText,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';

import Header from './AuditorPoolHeader';

// imgs and styles
import avatar from 'assets/img/avatar.jpg';
import react from 'assets/img/theme/react.jpg';

// redux
import { connect } from 'react-redux';
import { GetAuditorCertifications, GetAuditorPortfolio, RequestAuditor } from 'store/actions/generalActions';
import RequestAuditorModal from './components/RequestAuditorModal';

const AuditorProfile = (props) => {
    const {
        location,
        GetAuditorCertifications,
        auditor_pool_certifications,
        GetAuditorPortfolio,
        auditor_pool_portfolio,
        approved_auditors,
        RequestAuditor,
        merchant,
    } = props;

    const [auditor, setAuditor] = useState(null);
    const [loading, setLoading] = useState({ content: false, request: false });
    const [requestModal, setRequestModal] = useState(false);
    const [seekedAuditor, setSeekedAuditor] = useState(null);

    // functions
    const getAuditorCertifications = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAuditorCertifications(auditor?.id);
        setLoading({ ...loading, content: false });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAuditorPortfolio = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAuditorPortfolio(auditor?.id);
        setLoading({ ...loading, content: false });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const requestAuditor = async () => {
        const payload = {
            seeked_auditor: auditor?.id,
            seek_auditor: true,
        };
        setLoading({ ...loading, request: true });
        const res = await RequestAuditor(payload);
        setLoading({ ...loading, request: false });
        if (res?.success) {
            toast.success(
                `You have successfully requested for ${auditor?.owner_detail?.first_name} ${auditor?.owner_detail?.last_name} as your auditor.`
            );
            closeRequestModal();
        } else {
            toast.error(res?.message);
        }
    };
    const openRequestModal = (seeked_auditor) => {
        setRequestModal(true);
        setSeekedAuditor(seeked_auditor);
    };
    const closeRequestModal = () => {
        setRequestModal(false);
        setSeekedAuditor(null);
    };

    // useEffects
    useEffect(() => {
        const one_auditor = approved_auditors?.find(
            (approved_auditor) => approved_auditor?.id === location.state?.auditor
        );
        setAuditor(one_auditor);
    }, [location.state?.auditor, approved_auditors]);
    useEffect(() => {
        if (auditor) {
            getAuditorCertifications();
            getAuditorPortfolio();
        }
    }, [auditor]);

    return (
        <>
            <section className="auditor-pool one-auditor-view">
                <Header showFilter={false} />
                <main className="section-body">
                    <Container className="one-auditor-view__body">
                        <section className="section-card-container">
                            <h2 className="section-card-heading">Personal Information</h2>
                            <Card className="section-card personality-card">
                                <Stack
                                    direction={{ xs: 'column', xl: 'row' }}
                                    spacing={{ xs: 2, xl: 3 }}
                                    sx={{ width: '100%' }}
                                >
                                    <Avatar
                                        src={auditor?.owner_detail?.picture || avatar}
                                        sx={{ width: 100, height: 100 }}
                                    />
                                    <Stack
                                        direction={{ xs: 'column', lg: 'row' }}
                                        sx={{
                                            width: { xs: '100%', xl: 'calc(100% - 100px)' },
                                            marginTop: '.8rem',
                                        }}
                                    >
                                        <Box
                                            className="personal-info"
                                            sx={{
                                                width: { xs: '100%', lg: '48%' },
                                                marginRight: { xs: 0, lg: '4rem' },
                                            }}
                                        >
                                            <h1>{`${auditor?.owner_detail?.first_name} ${auditor?.owner_detail?.last_name}`}</h1>
                                            <div className="bio-container">
                                                <span>bio</span>
                                                <article>{auditor?.description}</article>
                                            </div>
                                            <div className="buttons">
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    startIcon={<FileDownloadOutlined />}
                                                >
                                                    Resume
                                                </Button>
                                                {merchant?.auditor_detail?.id ||
                                                merchant?.seeked_auditor_detail?.id === auditor?.id ? (
                                                    <Tooltip
                                                        title={
                                                            merchant?.auditor_detail?.id
                                                                ? 'You already have an auditor assigned to you.'
                                                                : 'You have a pending request for this auditor.'
                                                        }
                                                        placement="top"
                                                    >
                                                        <span>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth
                                                                disabled
                                                            >
                                                                Request Auditor
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() =>
                                                            openRequestModal(merchant?.seeked_auditor_detail)
                                                        }
                                                    >
                                                        Request Auditor
                                                    </Button>
                                                )}
                                            </div>
                                        </Box>
                                        <Divider
                                            orientation="vertical"
                                            className="divider vertical"
                                            sx={{ display: { xs: 'none', lg: 'block' } }}
                                        />
                                        <Divider
                                            sx={{
                                                display: { xs: 'block', lg: 'none' },
                                                margin: '2rem 0',
                                            }}
                                            className="divider"
                                        />
                                        <Box
                                            className="contact-info"
                                            sx={{
                                                width: { xs: '100%', lg: '48%' },
                                                marginLeft: { xs: 0, lg: '2.5rem' },
                                            }}
                                        >
                                            <ContactListItem
                                                label="phone"
                                                value={
                                                    <a href={`tel:${auditor?.phone_number}`}>{auditor?.phone_number}</a>
                                                }
                                            />
                                            <ContactListItem
                                                label="Email"
                                                value={
                                                    <a href={`mailto:${auditor?.owner_detail?.email}`}>
                                                        {auditor?.owner_detail?.email}
                                                    </a>
                                                }
                                            />
                                            <ContactListItem label="address" value={auditor?.address} />
                                            <ContactListItem label="country" value={auditor?.country} />
                                            <ContactListItem
                                                label="facebook"
                                                value={<a href={auditor?.facebook}>{auditor?.facebook}</a>}
                                            />
                                            <ContactListItem
                                                label="linkedin"
                                                value={<a href={auditor?.linkedin}>{auditor?.linkedin}</a>}
                                            />
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Card>
                        </section>
                        <section className="section-card-container">
                            <h2 className="section-card-heading">Licences & Certifications</h2>
                            <Card className="section-card cert-license-card">
                                <List>
                                    {auditor_pool_certifications?.map((certification, index) => {
                                        return (
                                            <CertsAndLicencesItem
                                                certification={certification}
                                                showDivider={index < auditor_pool_certifications?.length - 1}
                                                key={certification?.id}
                                            />
                                        );
                                    })}
                                </List>
                            </Card>
                        </section>
                        <section className="section-card-container">
                            <h2 className="section-card-heading">Customers & Portfolio</h2>
                            <Card className="section-card portfolio-card">
                                <ImageList
                                    sx={{
                                        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr)) !important',
                                    }}
                                    gap={40}
                                >
                                    {auditor_pool_portfolio?.map((portfolio) => {
                                        return <PortfolioItem portfolio={portfolio} key={portfolio.id} />;
                                    })}
                                </ImageList>
                            </Card>
                        </section>
                    </Container>
                </main>
            </section>
            <RequestAuditorModal
                open={requestModal}
                handleClose={closeRequestModal}
                auditor={auditor}
                requestAuditor={requestAuditor}
                seekedAuditor={seekedAuditor}
            />
        </>
    );
};

const ContactListItem = (props) => {
    const { label, value } = props;
    return (
        <div className="list-item">
            <span className="label">{label}: </span>
            <span className="value">{value}</span>
        </div>
    );
};
const CertsAndLicencesItem = (props) => {
    const { certification, showDivider } = props;
    return (
        <>
            <ListItem alignItems="flex-start" className="cert-list-item">
                <ListItemText
                    primary={
                        <>
                            <Typography>{certification?.name}</Typography>
                            <Typography>{certification?.organization}</Typography>
                        </>
                    }
                    secondary={`${certification?.issue_date} - ${certification?.expiry_date}`}
                />
            </ListItem>
            {showDivider && <Divider className="divider" />}
        </>
    );
};
const PortfolioItem = (props) => {
    const { portfolio } = props;
    return (
        <ImageListItem>
            <img src={portfolio.file || react} alt="company logo" />
            <ImageListItemBar title={portfolio?.title} position="below" />
        </ImageListItem>
    );
};

const mapStateToProps = (state) => {
    return {
        auditor_pool_certifications: state?.generalReducers?.auditor_pool_certifications,
        auditor_pool_portfolio: state?.generalReducers?.auditor_pool_portfolio,
        approved_auditors: state?.generalReducers?.approved_auditors,
        merchant: state?.generalReducers?.user_info?.organization,
    };
};

export default connect(mapStateToProps, {
    GetAuditorCertifications,
    GetAuditorPortfolio,
    RequestAuditor,
})(AuditorProfile);
