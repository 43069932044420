import { Button, Modal, Row, Col, Input, InputNumber } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

// redux
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { AddStandardPrice, EditStandardPrice } from 'store/actions/subscriptionActions';

const StandardPriceModal = (props) => {
    const { mode, handleClose, modalData = {}, AddStandardPrice, EditStandardPrice } = props;

    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // functions
    const closeAll = () => {
        handleClose();
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    const handleNumberChange = (name, value) => {
        setData({ ...data, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const { standard_type, amount, id } = data;
        const payload = { standard_type, amount };
        setLoading(true);
        const res = mode === 'Add' ? await AddStandardPrice(payload) : await EditStandardPrice(payload, id);
        setLoading(false);
        if (res?.success) {
            toast.success(`Standard Price ${mode === 'Add' ? 'added' : 'updated'} successfully`);
            closeAll();
        } else {
            toast.error(res?.message);
        }
        console.log(e);
    };

    // useEffect
    useEffect(() => {
        setData(modalData);
    }, [modalData]);

    return (
        <Modal
            visible={Boolean(mode)}
            onCancel={closeAll}
            title={`${mode} a Standard`}
            footer={null}
            destroyOnClose={true}
        >
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col span={24} style={{ margin: '1rem 0' }}>
                        <label htmlFor="standard_type">Name of Standard</label>
                        <Input
                            type="text"
                            size="large"
                            name="standard_type"
                            id="standard_type"
                            value={data.standard_type || ''}
                            onChange={handleTextChange}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-2">
                        <label htmlFor="amount">Price</label>
                        <InputNumber
                            size="large"
                            name="amount"
                            id="amount"
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\\s?|(,*)/g, '')}
                            onChange={(value) => handleNumberChange('amount', value)}
                            value={data?.amount || ''}
                            required
                            min={1}
                            style={{ width: '100%' }}
                            prefix="₦"
                        />
                    </Col>
                </Row>
                <Row justify="end">
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = (state) => {
    return state;
};
export default connect(mapStateToProps, {
    AddStandardPrice,
    EditStandardPrice,
})(StandardPriceModal);
