import React from 'react';
import { Box, Button, Typography } from '@mui/material';
// import { Close } from '@mui/icons-material';

//redux
import { connect } from 'react-redux';

//translations
import CloseIcon from '@mui/icons-material/Close';
import AppCenteredModalNew from 'components/new_components/AppCenteredModalNew';
import { UploadBulkKycRequests } from 'store/actions/FraudGauardianActions';

const UploadVendorModal = (props) => {
    const { open, handleClose, allSources } = props;

    return (
        <AppCenteredModalNew
            open={open}
            handleClose={handleClose}
            title={<Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>Source Type</Typography>}
            noShadow
            noClose
            headerAction={
                <Box
                    sx={{
                        border: '1px solid #E2E8F0',
                        borderRadius: 30,
                        width: 30,
                        height: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon sx={{ width: 20, height: 20 }} />
                </Box>
            }
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        mt: 3,
                        backgroundColor: '#F8FAFC',
                        height: '80px',
                        mx: '-2rem',
                        mb: '-2rem',
                        px: '2rem',
                        pt: '3rem',
                        pb: '4.5rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{
                            mr: 2,
                            border: '1px solid #E2E8F0',
                            textTransform: 'inherit',
                            color: '#334155',
                            backgroundColor: '#FFFFFF',
                        }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Box>
            }
            width="550px"
            height="450px"
        >
            <Box sx={{ my: 2 }}>
                <div className="flex items-center gap-1 flex-wrap">
                    {' '}
                    {allSources?.map((item, index) => (
                        <Typography
                            sx={{
                                m: 0.5,
                                border: '1px solid #395BA9',
                                color: '#395BA9',
                                fontSize: '11px',
                                fontWeight: 600,
                                borderRadius: '05px',
                                p: 1,
                            }}
                            key={index}
                        >
                            {item}
                        </Typography>
                    ))}
                </div>
            </Box>
        </AppCenteredModalNew>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { UploadBulkKycRequests })(UploadVendorModal);
