import { Stack, Typography, Box, Select, MenuItem } from '@mui/material';
import { useMemo, useState } from 'react';
import { ReactComponent as TrendUpIcon } from 'assets/img/trend-down.svg';
import { useSelector } from 'react-redux';
import { ReactComponent as TrendDownIcon } from 'assets/img/trend-up.svg';
import { Meter } from './Meter';

export default function RiskAssets() {
    const [percentage, setPercentage] = useState(0);
    const [category, setCategory] = useState('all assets');

    const handleChange = (e) => {
        setCategory(e.target.value);
    };

    const getAllData = (risk_by_assets_type) => {
        const totalPercentage = risk_by_assets_type?.reduce((total, el) => el.current_percentage + total, 0) ?? 0;
        const averagePercentage =
            totalPercentage && risk_by_assets_type?.length ? totalPercentage / risk_by_assets_type?.length : 0;
        return {
            category: 'all assets',
            current_percentage: averagePercentage,
            trend: 'stable',
            percentage_change: 0,
        };
    };

    // overview
    const overview = useSelector((state) => state?.riskAssessmentReducers?.overview);

    const categories = useMemo(() => {
        const result = overview?.risk_by_assets_type?.map((el) => el.asset_type) ?? [];
        return ['all assets', ...result];
    }, [overview]);

    const activeCategory = useMemo(() => {
        return category === 'all assets'
            ? getAllData(overview?.risk_by_assets_type)
            : category
            ? overview?.risk_by_assets_type?.filter((el) => el.asset_type === category)[0] ?? {}
            : {};
    }, [category]);

    const percentType = useMemo(() => {
        setPercentage(activeCategory?.current_percentage?.toFixed() ?? 0);
        return activeCategory.trend === 'increase'
            ? 'increase'
            : activeCategory.trend === 'decrease'
            ? 'decrease'
            : null;
    }, [activeCategory]);

    const getPercentageChange = useMemo(() => {
        if (activeCategory.category === 'all assets') return ``;

        return percentType
            ? `${percentType === 'increase' ? '+' : '-'} ${activeCategory.percentage_change ?? 0}% ${percentType}`
            : null;
    }, [overview, activeCategory]);

    return (
        <div className="col-span-6 bg-white rounded-md border-[#f1f5f9] px-3 py-3">
            <Stack
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        color: '#2b3674',
                        fontWeight: 600,
                    }}
                >
                    Risk by assets type
                </Typography>

                <Select
                    labelId="categories-select-label"
                    id="categories-select"
                    value={category}
                    label="categories"
                    onChange={handleChange}
                    size="small"
                    sx={{
                        textTransform: 'capitalize',
                        backgroundColor: '#F8FAFC',
                        border: '1px solid #E2E8F0',

                        '& .MuiSelect-select': {
                            fontSize: 11,
                            paddingLeft: '10px',
                            paddingBlock: '3px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #E2E8F0 !important',
                        },
                    }}
                    defaultValue="all assets"
                >
                    {categories
                        ?.filter((item) => item !== 'digital')
                        .map((value, key) => (
                            <MenuItem sx={{ fontSize: 11, textTransform: 'capitalize' }} key={key} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                </Select>
            </Stack>

            <div className="flex flex-col gap-4">
                <Box sx={{ mt: 3 }}>
                    <Meter percentage={percentage} />
                </Box>

                <div className="flex items-center justify-center gap-5">
                    <div className="flex gap-6 items-center">
                        {category !== 'all' && percentType && (
                            <div className="flex gap-1 items-center">
                                {percentType === 'increase' ? (
                                    <TrendUpIcon className="-scale-y-100" />
                                ) : (
                                    <TrendDownIcon className="-scale-y-100" />
                                )}

                                <span className="text-[#64748B] text-xs">{getPercentageChange} this month</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
