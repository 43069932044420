import React from 'react';
import { Row, Col, Tabs } from 'antd';

// core component
import BreadCrumb from 'components/Breadcrumb';

// icons
import { FileOutlined, FormOutlined } from '@ant-design/icons';
import Soc2DocumentsView from './Soc2DocumentsView';
import Soc2EvidencesView from './Soc2EvidencesView';
import Soc2FormsView from './Soc2FormsView';

// sub compliance
const { TabPane } = Tabs;

const FormsAndDocumentsView = (props) => {
    const { location } = props;

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[
                            { label: 'SOC2', link: '/soc2' },
                            {
                                label: location.state?.control_title,
                                link: '/soc2/subcontrols',
                                state: { control_id: location?.state?.subcontrol?.parent_control },
                            },
                            { label: location?.state?.subcontrol?.title },
                        ]}
                    />
                </Col>
            </Row>
            <section className="my-3">
                <Tabs defaultActiveKey="1">
                    <TabPane
                        tab={
                            <span>
                                <FileOutlined />
                                Documents
                            </span>
                        }
                        key="1"
                    >
                        <Soc2DocumentsView subcontrol={location?.state?.subcontrol} />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <FormOutlined />
                                Audit Questions
                            </span>
                        }
                        key="2"
                    >
                        <Soc2FormsView subcontrol={location?.state?.subcontrol} />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <FileOutlined />
                                Evidences
                            </span>
                        }
                        key="3"
                    >
                        <Soc2EvidencesView subcontrol={location?.state?.subcontrol} />
                    </TabPane>
                </Tabs>
            </section>
        </div>
    );
};

export default FormsAndDocumentsView;
