import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const OutlinedLayout = (props) => {
    const { tabList, indexValue, onChangeTab, tab_more, border, handlePagechange, all_approved_user, color } = props;
    return (
        <div
            className={`flex justify-between items-center ${
                border ? 'border-b border-gray-[#B8BCCC] px-[3rem]' : 'px-[2rem]'
            } flex font-bold textbrandColor dash_tab_ mx-[-2rem]`}
        >
            <div className={`py-2 flex`}>
                {tabList?.map((res, index) => (
                    <React.Fragment key={index}>
                        {res?.length ? (
                            <div
                                className={`flex items-center cursor-pointer mx-2 transition py-1 ${
                                    indexValue === res.id ? 'border-b-2 border-[#395BA9]' : ''
                                }`}
                                onClick={() => onChangeTab(res.id)}
                            >
                                <span
                                    className={`text-[13px] ${
                                        indexValue === res.id ? `${color ? color : 'textbrandColor'}` : 'text-[#919094]'
                                    }`}
                                >
                                    {res.name}
                                </span>
                                <span
                                    className={`ml-2 text-[10px] text-[#ffffff] px-[0.5rem] rounded ${
                                        indexValue === res.id ? 'bg-[#395BA9]' : 'bg-[#919094]'
                                    }`}
                                >
                                    {res.length}
                                </span>
                            </div>
                        ) : (
                            <div
                                className={`flex items-center cursor-pointer mx-2 transition tab_ ${
                                    indexValue === res.id && 'active'
                                }`}
                                onClick={() => {
                                    onChangeTab(res.id);
                                    if (all_approved_user) {
                                        if (all_approved_user?.created_status) {
                                            handlePagechange('overview');
                                        } else {
                                            handlePagechange('welcome');
                                        }
                                    }
                                }}
                            >
                                <span
                                    className={`text-[13px] ${
                                        indexValue === res.id ? 'textbrandColor' : 'text-[#919094]'
                                    }`}
                                >
                                    {res?.name === 'Api settings' && <ArrowBackIosIcon sx={{ width: '20px' }} />}{' '}
                                    {res.name}
                                </span>
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
            {tab_more}
        </div>
    );
};

export default OutlinedLayout;
