import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import NewThreat from './NewThreat';

const Threats = ({
    localRiskIdentificationStates,
    setRiskFirstPageStates,
    setThreatAnchorEl,
    threatAnchorEl,
    riskFirstPageStates,
    setVulnerablities,
}) => {
    const [tempThreat, setTempThreat] = useState('');
    const [search, setSearch] = useState('');
    const { risk_assessment_threats } = useSelector((state) => state.riskAssessmentReducers);
    const filtered_threats = risk_assessment_threats
        ?.filter((threat) => threat?.risk_category === riskFirstPageStates?.category)
        ?.filter(
            (threat) =>
                threat?.name.toLowerCase().includes(search.toLowerCase()) ||
                threat?.threat_category_detail?.name.toLowerCase().includes(search.toLowerCase())
        );
    return (
        <div className="relative" onMouseLeave={() => setTempThreat('')}>
            <button
                aria-controls={tempThreat ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={tempThreat ? 'true' : undefined}
                onClick={(event) => {
                    event.stopPropagation();
                    setThreatAnchorEl(event.currentTarget);
                }}
                className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
            >
                <p
                    className={`font-normal text-sm ${
                        localRiskIdentificationStates.threat ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                    }`}
                >
                    {localRiskIdentificationStates.threat
                        ? `${
                              risk_assessment_threats?.find(
                                  (threat) => threat?.id === localRiskIdentificationStates.threat
                              )?.threat_category_detail?.name
                          } -
                          ${
                              risk_assessment_threats?.find(
                                  (threat) => threat?.id === localRiskIdentificationStates.threat
                              )?.name
                          }`
                        : 'Select threat'}
                </p>

                <img
                    src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                    alt="Arrow Down Icon"
                    className="object-contain"
                />
            </button>

            <Menu
                anchorEl={threatAnchorEl}
                open={Boolean(threatAnchorEl)}
                onClose={() => setThreatAnchorEl(null)}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '4px',
                        boxShadow: '0px 11.76px 22.06px 0px #00000014',
                        pb: -2,
                    },
                    '& .MuiList-root': {
                        padding: 0,
                    },
                }}
            >
                <div className="!py-[5px] !px-3 flex flex-col gap-2.5">
                    <p className="border-b border-[#F1F5F9] !py-1 !px-3 text-[#64748B] font-medium text-xs">
                        Search name
                    </p>

                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Search"
                            className="w-full !py-2.5 !pr-2.5 !pl-8 !border !border-[#E2E8F0] h-7 text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                            value={search}
                            onChange={(event) => {
                                event.stopPropagation();
                                setSearch(event.target.value);
                            }}
                        />

                        <img
                            src="/img/risk-assessment/ri_search-line-small.svg"
                            alt="Search Icon"
                            className="absolute object-contain top-1/2 -translate-y-1/2 left-[10px]"
                        />
                    </div>
                </div>
                {filtered_threats?.length ? (
                    <div className="!p-2 flex flex-col min-w-[224px]">
                        {filtered_threats?.map((threat) => (
                            <MenuItem
                                key={threat?.id}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setRiskFirstPageStates((prev) => ({
                                        ...prev,
                                        threat: threat?.id,
                                    }));
                                    if (riskFirstPageStates?.threat !== threat?.id) {
                                        setVulnerablities([]);
                                    }
                                    setThreatAnchorEl(null);
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '13px',
                                    textTransform: 'capitalize',
                                    padding: '6px 8px',
                                }}
                            >
                                {threat?.threat_category_detail?.name} - {threat?.name}
                            </MenuItem>
                        ))}
                    </div>
                ) : (
                    <p className="flex items-center justify-center my-4 text-sm text-[#64748B] font-medium">
                        Threats not found
                    </p>
                )}
                <NewThreat riskFirstPageStates={riskFirstPageStates} />
            </Menu>
        </div>
    );
};

export default Threats;
