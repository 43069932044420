import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Button, Select } from 'antd';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { AddBulkVulnerabilites } from 'store/actions/adminActions';
import { GetAllThreats } from 'store/actions/adminActions';

const VulnerabilitiesModal = (props) => {
    const [loading, setloading] = useState(false);
    const [vul_list, setVul_list] = useState([]);
    const [threat, setThreat] = useState('');
    const { open, handleClose } = props;

    // redux
    const dispatch = useDispatch();
    const all_threats = useSelector((state) => state.adminReducers.all_threats);

    const onClose = () => {
        setVul_list([]);
        setThreat('');
        handleClose();
    };

    useEffect(() => {
        const getAllThreats = async () => {
            setloading(true);
            const res = await dispatch(GetAllThreats());
            setloading(false);
            if (!res.success) {
                toast.error(res?.message);
            }
        };

        getAllThreats();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setloading(true);
        const payload = {
            threat: threat,
            vul_list,
        };
        const res = await dispatch(AddBulkVulnerabilites(payload));
        setloading(false);
        if (res?.success) {
            toast.success('Vulnerabilities Added Successfully!');
            onClose();
        } else {
            console.log({ res });
            toast.error(res?.message);
        }
    };

    const onSelect = (value) => {
        setThreat(value);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };
    return (
        <Modal title="Add Vulnerabilities" open={open} onCancel={onClose} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="name">Vulnerabilities</label>

                        <div className="w-full flex flex-wrap gap-2 !border !border-[#E2E8F0] rounded-md !px-2 !py-[8px] focus-within:ring-2 focus-within:ring-[#395BA9] focus-within:ring-opacity-75">
                            {vul_list?.map((control, index) => (
                                <button
                                    key={index}
                                    className="flex items-center gap-2 !border !border-[#F1F5F9] bg-[#F1F5F9] !rounded !py-0 !px-2 hover:brightness-90 transition-all h-6"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setVul_list(vul_list?.filter((item) => item !== control));
                                    }}
                                >
                                    <p className="font-normal capitalize text-[13px] text-[#64748B]">{control}</p>

                                    <img
                                        src="/img/risk-assessment/x-close-small.svg"
                                        alt="Delete Icon"
                                        className="object-contain"
                                    />
                                </button>
                            ))}
                            <span
                                className="!w-auto focus-visible:outline-none !rounded-none !p-0 h-6 !border-none text-sm font-normal cursor-text self-center flex items-center text-[#94A3B8]"
                                contentEditable
                                suppressContentEditableWarning
                                onFocus={(event) => {
                                    if (event.target.innerText === 'Add one or more vulnerability') {
                                        event.target.innerText = '';
                                    }

                                    event.target.style.color = 'hsl(215,20%,40%)';
                                }}
                                onInput={(event) => {
                                    if (event?.target?.innerText?.includes(',,') && event.target.innerText.length > 2) {
                                        setVul_list(
                                            [...vul_list, ...event.target.innerText.split(',,')].filter(Boolean)
                                        );
                                        event.target.innerText = '';
                                    }
                                }}
                            >
                                {vul_list?.length === 0 && 'Add one or more vulnerability'}
                            </span>
                        </div>
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="plan">Choose Threat</label>
                        <Select
                            showSearch
                            size="large"
                            placeholder="Select Threat"
                            optionFilterProp="children"
                            value={threat}
                            onChange={onSelect}
                            onSearch={onSearch}
                            style={{ width: '100%' }}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={all_threats?.map((category) => {
                                return {
                                    value: category?.id,
                                    label: `${category?.name}- ${category?.threat_category_detail?.name}`,
                                };
                            })}
                        />
                    </Col>
                </Row>
                <Col style={{ marginTop: '5rem' }}>
                    <Button
                        className="ant-btn"
                        htmlType="submit"
                        type="primary"
                        loading={loading}
                        disbaled={vul_list?.length === 0 || !threat}
                    >
                        Submit
                    </Button>
                </Col>
            </form>
        </Modal>
    );
};

export default VulnerabilitiesModal;
