import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import {
    DeleteOutlined,
    MoreOutlined,
    EyeOutlined,
    EditFilled,
    FileAddOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import LoadingState from 'components/new_components/LoadingState';
import { GetDownloadLink } from 'store/actions/generalActions';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';

const { Item } = Menu;

const UploadedDocOperations = (props) => {
    const { record, openUpdateModal, deleteModal, requestForUpdate, openViewModal } = props;
    const [loadingLink, setLoadingLink] = useState(false);

    const getDownloadLink = async () => {
        if (record.file) {
            setLoadingLink(true);
            const res = await GetDownloadLink('compliance', 'data_room', record.id)();
            setLoadingLink(false);
            if (res?.success) {
                // open decoded url
                window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
            }
        }
    };
    const menu = (
        <Menu className="tableaction">
            <Item key="0" onClick={() => openUpdateModal(record)}>
                <EditFilled /> Update Doc
            </Item>
            <Item key="1" onClick={() => openViewModal(record)}>
                <EyeOutlined /> View Response
            </Item>
            <Item
                key="2"
                onClick={() => requestForUpdate(record)}
                style={{ color: record?.update_needed ? 'green' : '' }}
                disabled={!record?.response_needed}
            >
                <FileAddOutlined /> Require update
            </Item>
            <Box className="pl-[0.6rem] flex items-center gap-2 cursor-pointer" onClick={getDownloadLink}>
                {loadingLink && <LoadingState size={13} styles={{ width: 'auto' }} />}
                <DownloadOutlined /> Download Doc
            </Box>
            <Item key="4" style={{ color: 'red' }} onClick={() => deleteModal(record)}>
                <DeleteOutlined /> Delete
            </Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <a
                href="#"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                onKeyDown={(e) => e.preventDefault()}
            >
                <MoreOutlined />
            </a>
        </Dropdown>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchants: state?.adminReducers?.all_merchants,
    };
};
export default connect(mapStateToProps, {})(UploadedDocOperations);
