import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

import AppCenteredModalNew from 'components/new_components/AppCenteredModalNew';
import AppOptInput from 'components/new_components/AppOptInput';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SelfServiceVerification } from 'store/actions/generalActions';
import {
    OpenAutoRiskMitigation,
    OpenAutoRiskApproval,
    OpenAutoRiskExcalation,
} from 'store/actions/riskAssessmentActions';
import { Loader2 } from 'lucide-react';

const AuthenticateModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState(new Array(6).fill(undefined));
    const { open, handleClose } = props;

    const risk_self_portal_email = useSelector((state) => state?.generalReducers?.risk_self_portal_email);

    const history = useHistory();
    const dispatch = useDispatch();

    const handleSubmit = async () => {
        setLoading(true);
        const body = {
            otp: otp.join(''),
            email: risk_self_portal_email,
        };
        const res = await dispatch(SelfServiceVerification(body));
        setLoading(false);
        if (res?.success) {
            dispatch(OpenAutoRiskMitigation(true));
            dispatch(OpenAutoRiskExcalation(true));
            dispatch(OpenAutoRiskApproval(true));
            history.push('/risk-self-service-dashboard');
        } else {
            toast.error(res?.message || "Something went wrong, couldn't log you in");
        }
    };
    const onClose = () => {
        handleClose();
    };

    const handleOtpChange = (otp) => {
        setOtp(otp);
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('Text').trim()?.toString()?.slice(0, 6);
        const pinArray = pastedData?.split('').length > 0 ? pastedData?.split('') : Array(6).fill(undefined);
        setOtp(pinArray);
    };

    return (
        <AppCenteredModalNew
            open={open}
            title={
                <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                    Let’s Authenticate you
                </Typography>
            }
            noShadow
            noClose
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        mt: 3,
                        backgroundColor: '#F8FAFC',
                        height: '80px',
                        mx: '-2rem',
                        mb: '-2rem',
                        px: '2rem',
                        pt: '3rem',
                        pb: '4.5rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit', color: '#334155' }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            textTransform: 'inherit',
                            fontWeight: 600,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}
                        onClick={handleSubmit}
                    >
                        {loading && <Loader2 className="h-6 w-6 animate-spin" />} Continue
                    </Button>
                </Box>
            }
            width="500px"
            height="430px"
        >
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                }}
            >
                <Typography sx={{ color: '#64748B', fontSize: '13px', fontWeight: 500 }}>
                    A code has been to your email ({risk_self_portal_email}). Please check your inbox and enter the code
                    to proceed.
                </Typography>
                <div className="mt-[2rem]">
                    <Typography sx={{ color: '#64748B', fontSize: '13px', fontWeight: 500 }}>
                        Enter the 6-digit sent to your email
                    </Typography>
                    <AppOptInput pinArray={otp} onChange={handleOtpChange} handlePaste={handlePaste} />
                    <Typography sx={{ color: '#64748B', fontSize: '13px', fontWeight: 500, mt: 2 }}>
                        Didn’t see code? <span className="text-[#395BA9] cursor-pointer underline">Resend Code</span>
                    </Typography>
                </div>
            </Box>
        </AppCenteredModalNew>
    );
};

export default AuthenticateModal;
