import { Language, LightbulbOutlined, Login, Settings } from '@mui/icons-material';
import { Button, MenuItem, MenuList, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, useHistory } from 'react-router-dom';

// core components
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import AppMenuItem from 'components/new_components/AppMenuItem';

//
import { connect, useSelector } from 'react-redux';
import { LogoutUser } from 'store/actions/authActions';

const UserMenu = (props) => {
    const theme = useTheme();
    const history = useHistory();
    const { handleClose, LogoutUser, openLanguageMenu, closeLanguageMenu } = props;
    const user_info = useSelector((state) => state?.generalReducers?.user_info);

    const logout = async () => {
        await LogoutUser();
        handleClose();
        history.push('/auth/login');
    };

    const SwitchtoMainDashboard = () => {
        history.push('/implementer/client');
    };

    return (
        <>
            <Paper
                sx={{
                    maxWidth: '100%',
                    borderRadius: '24px',
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    overflow: 'hidden',
                    width: 270,
                }}
            >
                <MenuList sx={{ p: 0 }}>
                    <AppMenuItem icon={<Language fontSize="small" />} text="Language" onClick={openLanguageMenu} />
                    {user_info?.user_type !== 'implementer' && (
                        <AppMenuItem
                            icon={<Settings fontSize="small" />}
                            text="Settings"
                            component={Link}
                            // to="/merchant/settings/account"
                            to="/merchant/settings/"
                            onClick={() => {
                                handleClose();
                                closeLanguageMenu();
                            }}
                        />
                    )}
                    {user_info?.user_type === 'implementer' && (
                        <MenuItem onClick={SwitchtoMainDashboard}>
                            <Button
                                variant="text"
                                endIcon={<SwitchAccessShortcutIcon />}
                                sx={{
                                    textTransform: 'inherit',
                                    color: '#395BA9',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                }}
                            >
                                Switch to main dashboard
                            </Button>
                        </MenuItem>
                    )}
                    <AppMenuItem
                        icon={<LightbulbOutlined fontSize="small" />}
                        text="Page Tips"
                        onClick={handleClose}
                        sx={{ display: { xs: 'flex', md: 'none' } }}
                    />
                    {/* {user_info?.user_type !== 'implementer' && (
                        <AppMenuItem
                            icon={<TextSnippet fontSize="small" />}
                            text="Release notes"
                            to="/merchant/release-notes"
                            component={Link}
                            onClick={() => {
                                handleClose();
                                closeLanguageMenu();
                            }}
                        />
                    )} */}
                    <AppMenuItem
                        icon={<Login fontSize="small" />}
                        text="Log out"
                        color={theme.palette.error[700]}
                        sx={{
                            borderTop: '1px solid' + theme.palette.gray[600],
                            '&, &:hover': {
                                backgroundColor: theme.palette.error[100] + '80',
                            },
                        }}
                        onClick={logout}
                    />
                </MenuList>
            </Paper>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { LogoutUser })(UserMenu);
