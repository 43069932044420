import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllThreatCategories, DeleteThreatCategory } from 'store/actions/adminActions';
import { Table, Button, Modal, Input, Col, Row } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ThreatCategoryModal from './ThreatCategoryOperations/ThreatCategoryModal';
import EditModal from './ThreatCategoryOperations/EditModal';
import { ThreatCategoryOperations } from './ThreatCategoryOperations/ThreatCategoryOperations';
import useSearch from 'hooks/useSearch';
import { SearchOutlined } from '@mui/icons-material';

const ThreatCategory = () => {
    const [loading, setloading] = useState(false);
    const [AddModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [record, setrecord] = useState(null);

    // Redux
    const dispatch = useDispatch();
    const all_threat_categories = useSelector((state) => state.adminReducers.all_threat_categories);
    const all_risk_categories = useSelector((state) => state.adminReducers.all_risk_categories);
    const { data, handleSearch } = useSearch(all_threat_categories, ['name']);

    const columns = [
        {
            title: 'Threat Category',
            render: (record) => record?.name,
        },
        {
            title: 'Risk Category',
            render: (record) => {
                const risk_category = all_risk_categories?.find((category) => category?.id === record?.risk_category);
                return <p>{risk_category?.name}</p>;
            },
        },
        {
            title: 'Tag',
            render: (record) => record?.tag,
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => (
                <ThreatCategoryOperations record={record} openUpdateModal={openEditModal} deleteModal={deleteModal} />
            ),
        },
    ];
    const openAddModal = () => {
        setAddModal(true);
    };
    const closeAddModal = () => {
        setAddModal(false);
    };

    //Edit Modal
    const openEditModal = (data) => {
        setrecord(data);
        setEditModal(true);
    };
    const closeEditModal = () => {
        setEditModal(false);
    };

    const deleteModal = (threat) => {
        Modal.confirm({
            title: 'Do you want to delete this category?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await dispatch(DeleteThreatCategory(threat?.id));
                if (res?.success) {
                    toast.success('Threat Category Deleted Successfully');
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    useEffect(() => {
        const getAllThreatCategories = async () => {
            setloading(true);
            const res = await dispatch(GetAllThreatCategories());
            setloading(false);
            if (!res.success) {
                toast.error(res?.message);
            }
        };

        getAllThreatCategories();
    }, []);
    return (
        <div>
            <Row justify="end">
                <Col span={12} className="flex items-center gap-5">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                    <Button
                        onClick={openAddModal}
                        type="primary"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        Add Threat Category
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(data) => data?.id}
                    columns={columns}
                    loading={loading}
                    dataSource={data?.sort((a, b) => b?.id - a?.id)}
                    pagination={{
                        total: data?.length,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                    }}
                />
            </section>
            <ThreatCategoryModal open={AddModal} handleClose={closeAddModal} />
            <EditModal open={editModal} handleClose={closeEditModal} record={record} />
        </div>
    );
};

export default ThreatCategory;
