import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button, Select } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

const CommonControlModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const { open, handleCancel, one_control, CreateControl, EditControl } = props;

    // function
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = open === 'Add' ? await CreateControl(data) : await EditControl(data, one_control.id);
        setLoading(false);
        if (res.success) {
            setData({});
            handleCancel();
            open === 'Add'
                ? toast.success('Control Added Successfully')
                : toast.success('Control Updated Successfully');
        } else {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (one_control) {
            setData(one_control);
        } else {
            setData({});
        }
    }, [one_control]);

    return (
        <Modal
            title={`${open} Common Control`}
            visible={open}
            onCancel={handleCancel}
            footer={null}
            destroyOnClose={true}
        >
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="service_category">Control Category</label>
                        <Select
                            size="large"
                            style={{ width: '100%' }}
                            value={data?.service_category || ''}
                            onChange={(val) => setData({ ...data, service_category: val })}
                            name="service_category"
                            id="service_category"
                            required
                        >
                            <Option value="security">Security</Option>
                            <Option value="availability">Availability</Option>
                            <Option value="integrity">Integrity</Option>
                            <Option value="confidentiality">Confidentiality</Option>
                            <Option value="privacy">Privacy</Option>
                        </Select>
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="title">Control Title</label>
                        <Input
                            type="text"
                            size="large"
                            name="title"
                            id="title"
                            onChange={handleTextChange}
                            value={data.title || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="description">Control Description</label>
                        <TextArea
                            type="text"
                            name="description"
                            id="description"
                            onChange={handleTextChange}
                            value={data.description || ''}
                            rows={4}
                            size="large"
                            required
                        />
                    </Col>
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

export default CommonControlModal;
