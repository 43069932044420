import React from 'react';
import SubAlertIcon from '../../assets/img/dashboard/subAlertIcon.png';
import UpgradeAction from './UpgradeAction';
// import { useSelector } from 'react-redux';
// import { formatDateObjectHandler } from '../../utils';

//translations
import { useTranslation } from 'react-i18next';

export const dateDiff = (first, second) => {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
};

export const parseDate = (str) => {
    let mdy = str.split('-');
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
};

const SubscriptionAlert = ({ limitedNumber, isDashboardPage = true }) => {
    //translation
    const { t } = useTranslation('common');

    // const { user_info } = useSelector((state) => state.authReducers);
    /*const getRemainDays = () => {
        const currentDate = new Date(user_info?.date_joined);
        const currentDateFormat = formatDateObjectHandler(currentDate, 'DD-MM-YYYY');
        let finalDay;
        let expires;
        currentDate.setDate(currentDate.getDate() + 30);
        finalDay = dateDiff(
            parseDate(formatDateObjectHandler(currentDate, 'DD-MM-YYYY')),
            parseDate(currentDateFormat)
        );
        if (finalDay === 0) {
            expires = true;
        }
        return expires ? 'already' : 'in ' + finalDay + ` day${finalDay === 1 ? '' : 's'}`;
    };*/
    return (
        <div>
            <div className="alert_bg px-4 py-3 mb-6 lg:w-fit w-full flex items-center">
                <div>
                    <img src={SubAlertIcon} alt="SubAlertIcon" />
                </div>
                <div className="pr-10 pl-3">
                    <h4 className="text-[#E2B208] text-[13px]">
                        {isDashboardPage ? (
                            t('freeTrial')
                        ) : (
                            <>
                                {t('leftWith')} <strong>{limitedNumber}</strong>
                            </>
                        )}
                    </h4>
                    {/* <h5 className="textbrandColor text-[11px]">
                        Free trial Expires <strong>{getRemainDays()}</strong>
                    </h5>*/}
                    <h5 className="textbrandColor text-[11px] font-medium">{t('limited')}</h5>
                </div>
                <UpgradeAction />
            </div>
        </div>
    );
};

export default SubscriptionAlert;
