import React, { useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { useTheme } from '@mui/styles';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const CorrectiveMeasures = (props) => {
    const { scan_items } = props;
    const theme = useTheme();
    const [expanded, setExpanded] = useState('');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Box sx={{ mx: 2, my: 3, border: `1px solid ${theme.palette.neutral[95]}`, borderRadius: '5px', p: 2 }}>
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: '12px',
                    color: '#303034',
                    pb: 1,
                    borderBottom: `1px solid ${theme.palette.neutral[95]}`,
                }}
            >
                Recommendations
            </Typography>
            <Box sx={{ mt: 2, pb: 2 }}>
                {scan_items?.map((item) => (
                    <Box sx={{ mb: 2 }} key={item?.id}>
                        <Accordion expanded={expanded === item?.id} onChange={handleChange(item?.id)}>
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                                sx={{ background: expanded === item?.id ? '#F8F8F8' : '#FFFFFF' }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        color: theme.palette.gray[30],
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {item?.name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {item?.recommendations ? (
                                    <Box>
                                        {item?.recommendations?.map((recommendation, index) => (
                                            <Box key={index}>
                                                <Typography sx={{ color: '#5E5E62', fontSize: '14px', my: 2 }}>
                                                    <strong>Description:</strong> {recommendation?.Description}
                                                </Typography>
                                                <ul style={{ display: 'list-item', padding: '0 1rem' }}>
                                                    {recommendation?.Recommendations?.map((item, idx) => (
                                                        <li
                                                            key={idx}
                                                            style={{
                                                                fontWeight: 500,
                                                                fontSize: '12px',
                                                                color: '#5E5E62',
                                                                listStyleType: 'disc',
                                                                marginBottom: '0.5rem',
                                                            }}
                                                        >
                                                            {item}
                                                        </li>
                                                    ))}
                                                </ul>
                                                <Divider />
                                            </Box>
                                        ))}
                                    </Box>
                                ) : (
                                    <Typography sx={{ fontWeight: 500, fontSize: '12px', color: '#5E5E62' }}>
                                        No available measures
                                    </Typography>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default CorrectiveMeasures;
