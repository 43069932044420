import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import BreadCrumb from 'components/Breadcrumb';

//antd
import { Row, Col, Button, Empty, Card, Typography, Modal, Tooltip } from 'antd';
import { PlusOutlined, EditOutlined, EyeOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ClauseModal from '../IsoActions/ClauseModal';

//redux
import { connect } from 'react-redux';
import { GetAllManagementClausesNew, CreateAnnexNew, EditAnnexNew, DeleteAnnexNew } from 'store/actions/adminActions';

// sub-components
const { Title } = Typography;
const { confirm } = Modal;

const ManagementClausesView = (props) => {
    //state
    const [modalOpen, setModalOpen] = useState(null);
    const [oneReq, setOneReq] = useState({});

    //props
    const { GetAllManagementClausesNew, CreateAnnexNew, EditAnnexNew, DeleteAnnexNew, all_clauses } = props;
    //functions
    const openAddModal = (mode, data) => {
        setModalOpen(mode);
        setOneReq(data);
    };
    const closeAddModal = () => {
        setModalOpen(false);
    };

    const deleteAnnex = (id, category) => {
        confirm({
            title: 'Are you sure you want to delete this annex? Note: Deleting this annex means you will be deleting this annex, the documents, audit questions and merchant data associated with this annex',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteAnnexNew(id, category);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };
    useEffect(() => {
        const getAllManagementClauses = async () => {
            const res = await GetAllManagementClausesNew();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllManagementClauses();
    }, []);
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[{ label: 'ISO27017 + 27032', link: '/iso27017+27032' }, { label: 'Clauses' }]}
                    />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openAddModal('Add')}
                    >
                        Add Management Clause
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {all_clauses && all_clauses?.length > 0 ? (
                    <Row wrap gutter={24}>
                        {all_clauses
                            ?.sort((a, b) => a.id - b.id)
                            ?.map((clause, index) => (
                                <Col xs={24} md={12} lg={8} xxl={6} key={clause.id} style={{ marginBottom: '1rem' }}>
                                    <Card
                                        title={`#${index}`}
                                        loading={false}
                                        actions={[
                                            <Tooltip title="View this clause" color="blue">
                                                <Link
                                                    key="preview"
                                                    to={{
                                                        pathname: '/admin/iso27017+27032/clause',
                                                        state: {
                                                            clause,
                                                        },
                                                    }}
                                                >
                                                    <EyeOutlined key="preview" />
                                                </Link>
                                            </Tooltip>,
                                            <Tooltip title="Edit this clause" color="blue">
                                                <EditOutlined key="edit" onClick={() => openAddModal('Edit', clause)} />
                                            </Tooltip>,
                                            <Tooltip title="Delete this clause" color="red">
                                                <DeleteOutlined
                                                    key="delete"
                                                    onClick={() => deleteAnnex(clause.id, clause.category)}
                                                />
                                            </Tooltip>,
                                        ]}
                                    >
                                        <Title level={5} style={{ textAlign: 'center' }}>
                                            {clause.title}
                                        </Title>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                ) : (
                    <Row justify="center">
                        <Col>
                            <Empty
                                description={
                                    <div>
                                        <h3>No Management Clauses</h3>
                                        <span>Add a new Management Clause </span>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                )}
            </section>
            <ClauseModal
                open={modalOpen}
                handleCancel={closeAddModal}
                CreateClause={CreateAnnexNew}
                EditClause={EditAnnexNew}
                one_req={oneReq}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_clauses: state?.adminReducers?.all_management_clauses_new,
    };
};

export default connect(mapStateToProps, {
    GetAllManagementClausesNew,
    CreateAnnexNew,
    EditAnnexNew,
    DeleteAnnexNew,
})(ManagementClausesView);
