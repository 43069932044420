import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

const ContentBody = (props) => {
    const theme = useTheme();
    const { title, children, sx } = props;
    return (
        <>
            <Typography
                component="h4"
                sx={{ fontSize: 16, fontWeight: 700, letterSpacing: '0.1px', color: theme.palette.primary[20] }}
            >
                {title}
            </Typography>
            <Typography
                sx={{
                    fontSize: 12,
                    fontWeight: 400,
                    letterSpacing: '0.1px',
                    // mt: 0.5,
                    lineHeight: '24px',
                    color: theme.palette.gray[40],
                    pt: 2,
                    pb: 3,
                    ...sx,
                }}
                component="div"
            >
                {children}
            </Typography>
        </>
    );
};

export default ContentBody;
