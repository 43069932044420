import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTheme, Box } from '@mui/material';

// core components
import BreadCrumb from '../../../components/Breadcrumb';
import ArticleModal from './ArticleActions/ArticleModal';

// antd components
import { Button, Row, Col, Typography, Card, Modal, Spin, Empty, Tooltip } from 'antd';

// icons
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ThumbDown, ThumbUp } from '@mui/icons-material';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { GetAllArticles, CreateArticle, EditArticle, DeleteArticle } from 'store/actions/articlesActions';

// sub-components
const { Title } = Typography;
const { confirm } = Modal;

const ArticlePage = (props) => {
    const { location } = props;
    const theme = useTheme();
    // state
    const [loading, setLoading] = useState({
        content: false,
    });
    const [modal, setModal] = useState(null);
    const [oneArticle, setOneArticle] = useState({});

    // redux
    const dispatch = useDispatch();
    const all_articles = useSelector((state) => state?.articlesReducers?.all_articles);

    const header_id = location?.state?.article_header_id;

    useEffect(() => {
        getAllArticles(header_id);
    }, []);

    // functions
    const openModal = (mode, data) => {
        setModal(mode);
        setOneArticle(data);
    };
    const closeModal = () => {
        setModal(null);
    };
    const getAllArticles = async (id) => {
        setLoading({ ...loading, content: true });
        const res = await dispatch(GetAllArticles(id));
        if (!res.success) {
            toast.error(res?.message);
        }
        setLoading({ ...loading, content: false });
    };
    const deleteArticle = (id, header_id) => {
        confirm({
            title: 'Are you sure you want to delete this requirement? Note: Deleting this requirement means you want to delete all the subrequirements, documents and audit questions that exists in this requirement',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await dispatch(DeleteArticle(id, header_id));
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[
                            { label: 'Articles', link: '/articles' },
                            { label: location?.state?.title || 'Article' },
                        ]}
                    />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal('Add')}
                    >
                        Add Article
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {loading.content ? (
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                ) : (
                    <>
                        {all_articles && all_articles?.length > 0 ? (
                            <Row wrap gutter={24}>
                                {all_articles
                                    ?.sort((a, b) => a?.id - b?.id)
                                    ?.map((article) => (
                                        <Col
                                            xs={24}
                                            md={12}
                                            lg={12}
                                            xxl={12}
                                            key={article.id}
                                            style={{ marginBottom: '1rem' }}
                                        >
                                            <Card
                                                // title={`#${article.id}`}
                                                loading={false}
                                                actions={[
                                                    <Tooltip title="Article Helpful" color="blue">
                                                        <ThumbUp fontSize="small" />
                                                        <Typography style={{ marginLeft: '1rem' }}>{}</Typography>
                                                    </Tooltip>,
                                                    <Tooltip title="Article Not Helpful" color="blue">
                                                        <ThumbDown fontSize="small" />
                                                    </Tooltip>,
                                                    <Tooltip title="Edit this Article" color="blue">
                                                        <EditOutlined
                                                            key="edit"
                                                            onClick={() => openModal('Edit', article, header_id)}
                                                        />
                                                    </Tooltip>,
                                                    <Tooltip title="Delete this Article" color="red">
                                                        <DeleteOutlined
                                                            key="delete"
                                                            onClick={() => deleteArticle(article.id, header_id)}
                                                        />
                                                    </Tooltip>,
                                                ]}
                                            >
                                                <Title
                                                    level={5}
                                                    style={{
                                                        textAlign: 'left',
                                                        fontSize: '18px',
                                                        lineHeight: '22px',
                                                        fontWeight: 700,
                                                        color: theme.palette.primary[40],
                                                    }}
                                                >
                                                    {article.title}
                                                </Title>
                                                <Title
                                                    level={5}
                                                    style={{
                                                        textAlign: 'left',
                                                        fontSize: '16px',
                                                        lineHeight: '16px',
                                                        fontWeight: 500,
                                                        color: theme.palette.neutral[600],
                                                        marginBlock: '0 20px',
                                                    }}
                                                >
                                                    {article.sub_title}
                                                </Title>
                                                <Box
                                                    sx={{
                                                        color: theme.palette.neutral[600],
                                                        fontSize: '14px',
                                                        '& img': {
                                                            margin: 'auto',
                                                        },
                                                        '& ul': {
                                                            paddingInline: 4,
                                                            listStyle: 'disc',
                                                            marginBottom: 0.5,
                                                        },
                                                    }}
                                                    dangerouslySetInnerHTML={{ __html: article?.body }}
                                                ></Box>
                                            </Card>
                                        </Col>
                                    ))}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Articles</h3>
                                                <span>Add a new Article </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section>
            <ArticleModal
                open={modal}
                handleCancel={closeModal}
                one_article={oneArticle}
                CreateArticle={CreateArticle}
                EditArticle={EditArticle}
                header_id={header_id}
            />
        </div>
    );
};

export default ArticlePage;
