import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { numExtraction } from 'utils';
import { Link } from 'react-router-dom';

// lib
import { Row, Col, Button, Table, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// core components
import BreadCrumb from 'components/Breadcrumb';
import SubcontrolsModal from './ISO9001Actions/SubcontrolsModal';
import SubcontrolOperations from './ISO9001Actions/SubcontrolOperations';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { CreateQualityManagement, EditQualityManagement, DeleteQualityManagement } from 'store/actions/iso9001';

// subcomponents
const { confirm } = Modal;

const SubcontrolsView = (props) => {
    const { location } = props;
    const [modal, setModal] = useState(false);
    const [control, setControl] = useState({});
    const [subcontrol, setSubcontrol] = useState({});
    const dispatch = useDispatch();
    const all_quality_management = useSelector((state) => state?.ISO9001Reducers?.all_quality_management);

    const openModal = (mode, data) => {
        setModal(mode);
        setSubcontrol(mode === 'Edit' ? data : {});
    };
    const closeModal = () => {
        setModal(false);
        setSubcontrol(null);
    };
    const deleteSubcontrol = (id) => {
        confirm({
            title: 'Are you sure you want to delete this sub management? Note: Deleting this sub management means you will be deleting this sub management, the documents, audit questions and merchant data associated with this sub management',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await dispatch(DeleteQualityManagement(id, true));
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    // data
    const columns = [
        {
            title: 'Sub Management Number',
            render: (record) => `QM ${record.number}`,
            sorter: (a, b) => a.number - b.number,
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Title',
            render: (record) => (
                <Link
                    to={{
                        pathname: '/admin/iso9001/submanagement/items',
                        state: { subcontrol: record },
                    }}
                >
                    {record.title}
                </Link>
            ),
            sorter: (a, b) => numExtraction(a.title) - numExtraction(b.title),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Description',
            render: (record) => record.description,
            width: '70%',
        },
        {
            title: 'Actions',
            align: 'right',
            render: (record) => (
                <SubcontrolOperations
                    openEditModal={openModal}
                    subcontrol={record}
                    deleteSubcontrol={deleteSubcontrol}
                    control={control}
                    parent_man_num={location?.state?.man_num}
                />
            ),
        },
    ];

    // useEffect
    useEffect(() => {
        const { management_id } = location.state || {};
        const one_control = all_quality_management?.find((control) => control?.id === management_id);
        setControl(one_control);
    }, [all_quality_management, location?.state]);
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'ISO9001', link: '/iso9001' }, { label: control?.title }]} />
                </Col>

                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal('Add')}
                    >
                        Add Sub Qualtiy Management
                    </Button>
                </Col>
            </Row>

            <section className="my-3">
                <Table
                    columns={columns}
                    dataSource={control?.sub_quality_mgt}
                    rowKey={(sub_control) => sub_control?.id}
                />
            </section>
            <SubcontrolsModal
                open={modal}
                handleCancel={closeModal}
                parent_control_id={control?.id}
                one_subcontrol={subcontrol}
                CreateControl={CreateQualityManagement}
                EditControl={EditQualityManagement}
            />
        </div>
    );
};
export default SubcontrolsView;
