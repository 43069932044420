import { DeleteOutlined, EditOutlined, FolderViewOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import LoadingState from 'components/new_components/LoadingState';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GetDownloadLink } from 'store/actions/generalActions';

const RequestActions = (props) => {
    // const history = useHistory();
    // props
    const { record, deleteModal, openEditModal } = props;
    const [loadingLink, setLoadingLink] = useState({ loading: false, links: [] });
    const [isOpen, setIsOpen] = useState(false);

    const getDownloadLink = async () => {
        setLoadingLink({ ...loadingLink, loading: true });
        const res = await GetDownloadLink('compliance', 'spool_report', record.id, true)();
        setLoadingLink({ ...loadingLink, loading: false });

        if (res?.success) {
            setLoadingLink({ loading: false, links: res.data });
        } else {
            toast.error('Something went wrong!');
        }
    };

    useEffect(() => {
        isOpen && getDownloadLink();
    }, [isOpen]);

    const ViewFile = (url) => url && window.open(url, '_blank');

    const getUrl = (name = '') => {
        return loadingLink.links.find((el) => Object.keys(el)?.[0]?.toLowerCase() === name.toLowerCase())?.[name];
    };

    const filesArray = () => {
        const uploadedDocs = Array.isArray(record?.uploaded_docs) ? record?.uploaded_docs : [];
        return uploadedDocs
            ?.sort((a, b) => a.id - b.id)
            ?.map((docs, index) => ({
                key: `5-${index + 1}`,
                label: (
                    <>
                        {docs?.doc_name}{' '}
                        {loadingLink.id === document.id && loadingLink.loading && (
                            <LoadingState size={13} styles={{ mr: 1.5 }} />
                        )}
                    </>
                ),
                onClick: () => ViewFile(getUrl(docs?.doc_name)),
            }));
    };
    // constant
    const items1 = [
        {
            label: 'Edit ROC',
            key: '2',
            icon: <EditOutlined />,
            onClick: () => openEditModal('Edit', record),
        },
        {
            label: 'Delete',
            key: '4',
            icon: <DeleteOutlined />,
            onClick: () => deleteModal(record?.id),
        },
        {
            type: 'divider',
        },
        {
            label: 'View Files',
            key: '5',
            icon: <FolderViewOutlined />,
            children: filesArray(),
        },
    ];

    // const items2 = [
    //     {
    //         label: 'Add Reviewers',
    //         key: '2',
    //         icon: <EditOutlined />,
    //         disabled: record?.auditor || record?.status === 'completed' || record?.status === 'draft',
    //         onClick: () => openReviewersModal(record),
    //     },
    //     {
    //         label: 'Review Report',
    //         key: '2',
    //         icon: <EditOutlined />,
    //         disabled: record?.status === 'inreview' || record?.status === 'draft' || !record?.merchant_signature,
    //         onClick: () => history.push(`/admin/audit-requests/${record?.id}`),
    //     },
    // ];

    // const items3 = [
    //     {
    //         label: 'View Files',
    //         key: '5',
    //         icon: <FolderViewOutlined />,
    //         children: filesArray(),
    //     },
    // ];

    // const items =
    //     record?.compliance === 'pcidss4.0'
    //         ? items2
    //         : record?.complaince === 'pcidss4.0' && record?.status === 'completed'
    //         ? items3
    //         : items1;
    const items = items1;
    return (
        <Dropdown trigger={['click']} menu={{ items }} onOpenChange={(open) => setIsOpen(open)}>
            <MoreOutlined />
        </Dropdown>
    );
};

export default RequestActions;
