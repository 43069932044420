import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Button, Select } from 'antd';

//Redux
import { connect } from 'react-redux';
import { UpdateSupportUser, GetAllMerchants } from 'store/actions/adminActions';

const SupportMerchantModal = (props) => {
    const [loading, setloading] = useState(false);
    const [organization, setOrganization] = useState('');
    const [error, setError] = useState('');
    const { open, handleClose, UpdateSupportUser, GetAllMerchants, all_merchants, record } = props;

    const onClose = () => {
        setOrganization('');
        handleClose();
    };

    const company = all_merchants?.find((merchant) => merchant?.id === organization);
    const onSearch = (value) => {
        console.log('search:', value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (organization === '') {
            setError('error');
        } else {
            setloading(true);
            const body = {
                role: 'admin',
                organization,
            };

            const res = await UpdateSupportUser(body, record?.id);
            setloading(false);
            setError('');
            if (res?.success) {
                toast.success(
                    `support user successfully added to ${company?.name || company?.owner_detail?.first_name}`
                );
                onClose();
            } else {
                toast.error(res?.message);
            }
        }
    };

    // useEffect
    useEffect(() => {
        const getAllMerchants = async () => {
            const res = await GetAllMerchants();

            if (!res.success) {
                toast.error(res?.message);
            }
        };

        getAllMerchants();
    }, []);
    return (
        <Modal title="Support Merchant" visible={open} onCancel={handleClose} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="last_name">Select Merchant</label>
                        <Select
                            showSearch
                            name="organization"
                            id="organization"
                            size="large"
                            style={{ width: '100%' }}
                            value={organization || ''}
                            className="capitalize"
                            optionFilterProp="children"
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            onChange={(value) => setOrganization(value)}
                            required
                            options={all_merchants?.map((merchant) => {
                                return {
                                    value: merchant?.id,
                                    label: merchant?.name || merchant?.owner_detail?.first_name,
                                };
                            })}
                        />

                        {organization === '' && error && (
                            <span className="text-[#ff0000]">Please select an Organization</span>
                        )}
                    </Col>
                    <Col style={{ marginTop: '5rem' }}>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchants: state?.adminReducers?.all_merchants,
    };
};

export default connect(mapStateToProps, { UpdateSupportUser, GetAllMerchants })(SupportMerchantModal);
