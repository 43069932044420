import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const AppCheckboxInput = ({ name, label, onChange, value, sx, checkboxProps, indeterminate, disabled }) => {
    const theme = useTheme();
    return (
        <FormControlLabel
            name={name}
            sx={{
                mt: 2,
                color: theme.palette.gray[900],
                mb: 0.5,
                fontSize: '14px',
                ...sx,
                '& .MuiTypography-root': { fontSize: '14px' },
            }}
            control={
                <Checkbox
                    indeterminate={indeterminate || false}
                    onChange={onChange}
                    checked={value || false}
                    size="small"
                    disabled={disabled || false}
                    {...checkboxProps}
                />
            }
            label={label}
        />
    );
};

export default AppCheckboxInput;
