import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

// core components
import BreadCrumb from 'components/Breadcrumb';
import useSearch from 'hooks/useSearch';

// icons
import { SearchOutlined } from '@ant-design/icons';

//redux
import { connect } from 'react-redux';
import { GetAllSurveyResponses } from 'store/actions/adminActions';

// antd components
import { Row, Col, Input, Table, Button } from 'antd';

import ViewSurveyModal from './ViewSurveyModal';

const Index = (props) => {
    // props
    const { GetAllSurveyResponses, all_survey_response } = props;
    // state
    const [loading, setLoading] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [datas, setDatas] = useState({});
    // hooks
    const { data, handleSearch } = useSearch(all_survey_response, ['merchant']);

    // functions
    const openViewModal = (data) => {
        setViewModal(true);
        setDatas(data);
    };
    const closeViewModal = () => {
        setViewModal(false);
        setDatas({});
    };

    const getAllSurveyResponse = async () => {
        setLoading(true);
        const res = await GetAllSurveyResponses();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    // useEffect
    useEffect(() => {
        getAllSurveyResponse();
    }, []);

    // constants
    const columns = [
        {
            title: 'Merchant Name',
            render: (record) => record?.merchant,
        },
        {
            title: 'Response count',
            render: (record) => record?.responded_users_count,
        },
        {
            title: 'View Respoonses',
            render: (record) => <Button onClick={() => openViewModal(record)}>View</Button>,
        },
    ];

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Survey Responses' }]} />
                </Col>
                <Col span={12} className="flex items-center">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                </Col>
            </Row>
            <section className="my-3">
                <Table columns={columns} dataSource={data} loading={loading} />
            </section>
            <ViewSurveyModal open={viewModal} modalData={datas} handleClose={closeViewModal} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_survey_response: state?.adminReducers?.all_survey_response,
    };
};
export default connect(mapStateToProps, { GetAllSurveyResponses })(Index);
