import { Button, Col, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

// redux
import { connect } from 'react-redux';
import { UpdateAuditRequest, UploadReportFile, GetAllQAReviwers } from 'store/actions/auditActions';
import { GetAllAuditors } from 'store/actions/adminActions';
import { toast } from 'react-toastify';

const CertificationModal = (props) => {
    const {
        open,
        handleClose,
        UpdateAuditRequest,
        modalData,
        GetAllAuditors,
        // GetAllQAReviwers,
        all_auditors,
        all_qa_reviewers,
    } = props;

    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // FUNCTIONS
    const closeModal = () => {
        handleClose();
        setData({});
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const payload = { ...data, status: 'auditing' };
        const res = await UpdateAuditRequest(payload, modalData?.id);
        setLoading(false);
        if (res?.success) {
            toast.success(`You have added reviewers Successfully!`);
            closeModal();
        } else {
            toast.error(res?.message);
        }
    };

    const getAllAuditors = async () => {
        const res = await GetAllAuditors();
        if (!res.success) {
            toast.error(res.message);
        }
    };

    // const getAllQAReviewers = async () => {
    //     const res = await GetAllQAReviwers();
    //     if (!res?.success) {
    //         toast.error(res?.message);
    //     }
    // };

    // useEffect
    useEffect(() => {
        if (!modalData?.effective_from) return;
        setData({ ...data, effective_from: modalData?.effective_from?.split(',')?.map((time) => dayjs(time)) });
    }, [modalData]);

    useEffect(() => {
        getAllAuditors();
        // getAllQAReviewers();
    }, []);

    const handleSelect = (name, value) => {
        setData({ ...data, [name]: value });
    };
    return (
        <Modal title="Add ROC Reviewers" open={open} onCancel={closeModal} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2">
                        <label htmlFor="auditor">Select Auditor</label>
                        <Select
                            id="auditor"
                            name="auditor"
                            size="large"
                            className="w-full"
                            value={data?.auditor}
                            onChange={(value) => handleSelect('auditor', value)}
                            required
                        >
                            {all_auditors?.map((auditor) => (
                                <>
                                    <Select.Option value={auditor?.id} key={auditor.id}>
                                        {auditor?.owner_detail?.first_name
                                            ? `${auditor?.owner_detail?.first_name} ${auditor?.owner_detail?.last_name}`
                                            : auditor?.owner_detail?.email}
                                    </Select.Option>
                                </>
                            ))}
                        </Select>
                    </Col>
                    <Col span={24} className="my-2">
                        <label htmlFor="qa_reviewer">Select Reviewer</label>
                        <Select
                            id="qa_reviewer"
                            name="qa_reviewer"
                            size="large"
                            className="w-full"
                            value={data?.qa_reviewer}
                            onChange={(value) => handleSelect('qa_reviewer', value)}
                            required
                        >
                            {all_qa_reviewers?.map((reviewer) => (
                                <Select.Option value={reviewer?.id} key={reviewer.id}>
                                    {reviewer.qa_reviewer_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col className="mt-2">
                        <Button htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = (state) => {
    return {
        all_auditors: state?.adminReducers?.all_auditors,
        all_qa_reviewers: state?.auditReducers?.all_qa_reviewers,
    };
};
export default connect(mapStateToProps, { GetAllAuditors, UpdateAuditRequest, UploadReportFile, GetAllQAReviwers })(
    CertificationModal
);
