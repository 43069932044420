export const codeReviewInitialValues = {
    version_control_tool: '',
    repo_type: 'public',
    link: '',
    access: 'codereview@wiredassurance.com',
    codereview_id: '',
};

export const versionControlOptions = [
    {
        name: 'Github',
        value: 'Github',
    },
    {
        name: 'Gitlab',
        value: 'Gitlab',
    },
    {
        name: 'Bitbucket',
        value: 'Bitbucket',
    },
    {
        name: 'CVS',
        value: 'CVS',
    },
    {
        name: 'Apache sub version',
        value: 'Apache sub version',
    },
    {
        name: 'Mercurial',
        value: 'Mercurial',
    },
    {
        name: 'Monotone',
        value: 'Monotone',
    },
    {
        name: 'Team founder Server',
        value: 'Team founder Server',
    },
    {
        name: 'Bazar',
        value: 'Bazar',
    },
];

export const radioValues = {
    name: 'Repository state',
    options: [
        {
            label: 'Private',
            sub_label: 'You choose who sees your repository',
            value: 'private',
        },
        {
            label: 'Public',
            sub_label: 'Repositories that can be seen by anyone.',
            value: 'public',
        },
    ],
};
