import React from 'react';

// core components
import AppTable from 'components/new_components/app-table';
import OnboardTableActions from './OnboardTableActions';
import AppTag from 'components/new_components/AppTags';
//translation
import { useTranslation } from 'react-i18next';
const OnboardTable = (props) => {
    const { data, loading, type } = props;

    const { t } = useTranslation('onboardingPolicy');
    const TABLE_COLUMNS = [
        { title: t('table.column1'), render: (row) => <>{row?.name}</> },
        {
            title: t('table.column2'),
            align: 'center',
            render: (row) => {
                const type = row?.file?.split('.')?.slice(-1)?.[0];
                return <AppTag text={type} sx={{ border: 'none', width: 'fit-content', fonWeight: 600, py: 1 }} />;
            },
        },
        {
            title: t('table.column3'),
            align: 'center',
            render: (row) => <>{row.admin ? 'Admin' : 'Merchant'}</>,
        },
        {
            title: t('table.column4'),
            align: 'center',
            render: (row) => <OnboardTableActions row={row} type={type} />,
        },
    ];

    return (
        <AppTable
            columns={TABLE_COLUMNS}
            data={data}
            showTitleBar={false}
            sx={{ container: { p: '16px 0px !important', flex: 1 } }}
            sorter={(a, b) => (a.name > b.name ? 1 : -1)}
            loading={loading}
        />
    );
};

export default OnboardTable;
