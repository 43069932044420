import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Button, Select } from 'antd';
// redux
import { connect } from 'react-redux';
import { GetAllMerchants, AssignAuditorToMerchant } from 'store/actions/adminActions';

const { Option } = Select;

const MerchantList = (props) => {
    const [merchantList, setMerchantList] = useState([]);
    const [merchant, setMerchant] = useState('');
    const [loading, setLoading] = useState(false);
    const { open, closeModal, all_merchants, GetAllMerchants, auditor, AssignAuditorToMerchant } = props;

    // useEffect
    useEffect(() => {
        getAllMerchants();
    }, []);
    useEffect(() => {
        if (all_merchants) {
            setMerchantList(all_merchants);
        }
    }, [all_merchants]);

    // function
    const getAllMerchants = async () => {
        const res = await GetAllMerchants();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const assignAuditorToMerchant = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await AssignAuditorToMerchant(merchant, { auditor });
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            closeModal();
        } else {
            toast.error(res?.message);
        }
    };
    const handleSelectChange = (id) => {
        setMerchant(id);
    };
    return (
        <>
            <Modal
                title="Select Merchant to Assign"
                visible={open}
                onCancel={closeModal}
                footer={null}
                destroyOnClose={true}
            >
                <form onSubmit={assignAuditorToMerchant}>
                    <Row justify="end">
                        <Col span={24}>
                            <div className="form-group" style={{ marginBottom: '1rem' }}>
                                <label htmlFor="merchant">Select Merchant</label>
                                <Select
                                    id="merchant"
                                    size="large"
                                    style={{ width: '100%' }}
                                    onChange={handleSelectChange}
                                >
                                    {merchantList?.map((merchant) => {
                                        return (
                                            <Option value={merchant?.id} key={merchant?.id}>
                                                {merchant?.owner_detail?.first_name} {merchant?.owner_detail?.last_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </div>
                        </Col>

                        <Col>
                            <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </form>
            </Modal>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_merchants: state?.adminReducers?.all_merchants,
    };
};
export default connect(mapStateToProps, {
    GetAllMerchants,
    AssignAuditorToMerchant,
})(MerchantList);
