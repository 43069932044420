import { TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// core components
import AppTableCell from './AppTableCell';

const TableHeader = (props) => {
    const theme = useTheme();
    const { columns, noBackground, implementer } = props;
    return (
        <TableHead
            sx={{
                backgroundColor: noBackground ? '' : implementer ? '#F8FAFC' : '#F8F8F8',
                // borderCollapse: 'collapse',
                border: implementer ? '1px solid #E2E8F0' : '',
                borderBottom: noBackground ? '1px solid #B8BCCC' : '1px solid #F1F5F9',
            }}
        >
            <TableRow>
                {columns
                    ? columns.map((column, idx) => {
                          return (
                              <AppTableCell
                                  align={column.align}
                                  sx={{
                                      fontWeight: noBackground ? 400 : implementer ? 600 : 600,
                                      color: noBackground
                                          ? theme.palette.gray[30]
                                          : implementer
                                          ? theme.palette.neutral[500]
                                          : '#64748B',
                                  }}
                                  key={column.title + 'Head' + idx}
                              >
                                  {column.title}
                              </AppTableCell>
                          );
                      })
                    : null}
            </TableRow>
        </TableHead>
    );
};

export default TableHeader;
