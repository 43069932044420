import { validation } from 'validate';

export const userValidate = (values) => {
    const errors = {};
    const emailValidate = validation(values.email, 'email', true);
    const roleValidate = validation(values.role, 'select', true);
    const standardsValidate = validation(values.auditor_standards, 'standards', true);
    const supportUserValidate = validation(values.support_user, 'Support User', true);
    const routesValidate = validation(values.routes, 'routes', true);

    if (values.role !== 'support') {
        if (!emailValidate.isValid) {
            errors.email = emailValidate.errorMessage;
        }
        if (!roleValidate.isValid) {
            errors.role = roleValidate.errorMessage;
        }
        if (values.role === 'auditor') {
            if (!standardsValidate.isValid) {
                errors.auditor_standards = standardsValidate.errorMessage;
            }
        }
    } else {
        if (!supportUserValidate.isValid) {
            errors.support_user = supportUserValidate.errorMessage;
        }
        if (!routesValidate.isValid) {
            errors.routes = routesValidate.errorMessage;
        }
    }
    return errors;
};
