import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button } from 'antd';
import { useDispatch } from 'react-redux';

const HeaderModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const { open, handleCancel, one_article, CreateArticle, EditArticle } = props;

    // redux
    const dispatch = useDispatch();

    // function
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res =
            open === 'Add'
                ? await dispatch(CreateArticle(data, false))
                : await dispatch(EditArticle(one_article.id, data, false));
        setLoading(false);
        if (res.success) {
            setData({});
            handleCancel();
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    // useEffect
    useEffect(() => {
        if (one_article) {
            setData(one_article);
        } else {
            setData({});
        }
    }, [one_article]);
    return (
        <Modal title={`${open} Article`} open={open} onCancel={handleCancel} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="name">Header Title</label>
                        <Input
                            type="text"
                            size="large"
                            name="name"
                            id="name"
                            onChange={handleTextChange}
                            value={data.name || ''}
                            required
                        />
                    </Col>
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

export default HeaderModal;
