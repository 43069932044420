import * as types from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case types.RISK_ASSESSMENT_THREATS:
            return {
                ...state,
                risk_assessment_threats: action.payload,
            };
        case types.RISK_ASSESSMENT_VULNERABILITIES:
            return {
                ...state,
                risk_assessment_vulnerabilities: action.payload,
            };
        case types.RISK_ASSESSMENT_CATEGORIES:
            return {
                ...state,
                risk_assessment_categories: action.payload,
            };
        case types.RISK_ASSESSMENT_TEMPLATES:
            return {
                ...state,
                risk_assessment_templates: action.payload,
            };
        case types.MERCHANT_ASSET_GROUP:
            return {
                ...state,
                merchant_asset_groups: action.payload,
            };
        case types.ALL_MERCHANT_ASSETS:
            return {
                ...state,
                all_merchant_assets: action.payload,
            };
        case types.GET_ALL_MERCHANT_USERS:
            return {
                ...state,
                all_merchant_users: action.payload,
            };
        case types.RISK_ASSESSMENT_THREAT_CATEGORIES:
            return {
                ...state,
                all_risk_threat_categories: action.payload,
            };
        case types.RISK_MANAGEMENT_SETTINGS:
            return {
                ...state,
                risk_management_settings: action.payload,
            };
        case types.ALL_MERCHANT_RISK_ASSESSMENT:
            return {
                ...state,
                all_merchant_risk_assessment: action.payload,
            };
        case types.OVERVIEW:
            return {
                ...state,
                overview: action.payload,
            };
        case types.GET_RISK_SETTINGS:
            return {
                ...state,
                get_risk_settings: action.payload,
            };
        case types.GET_ALL_RISK_OWNER:
            return {
                ...state,
                get_risk_owners: action.payload,
            };
        case types.GET_ALL_ASSET_OWNER:
            return {
                ...state,
                get_asset_owners: action.payload,
            };
        case types.OPEN_RISK_ASSESSMENT:
            return {
                ...state,
                run_assessment: action.payload,
            };
        case types.OPEN_AUTO_RISK_MITIGATION:
            return {
                ...state,
                open_auto_risk_mitigation: action.payload,
            };
        case types.OPEN_AUTO_RISK_EXCALATION:
            return {
                ...state,
                open_auto_risk_excalation: action.payload,
            };
        case types.OPEN_AUTO_RISK_APPROVAL:
            return {
                ...state,
                open_auto_risk_approval: action.payload,
            };
        case types.ADD_ASSET_RISK:
            return {
                ...state,
                risk_asset: action.payload,
            };
        case types.OPEN_AUTO_RISK_ASSESSMENT:
            return {
                ...state,
                open_auto_risk: action.payload,
            };
        case 'CLEAR_DATA':
            return {};
        default:
            return state;
    }
};
