import React, { useMemo, useState } from 'react';
import { List, ListItem, Divider, Avatar, Stack, IconButton, Tooltip } from '@mui/material';
import { ArrowBackIosRounded, Topic } from '@mui/icons-material';
import { BsDownload } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import AppDrawer from 'components/new_components/AppDrawer';
import moment from 'moment';
import { toast } from 'react-toastify';

//translations
import { useTranslation } from 'react-i18next';
import { GetDownloadLink } from 'store/actions/generalActions';
import LoadingState from 'components/new_components/LoadingState';

const UploadHistoryDrawer = (props) => {
    const { open, handleClose, selectedDoc, all_merchant_docs_responses, openDeleteModal } = props;

    //translation
    const { t } = useTranslation('dataroom');

    const filteredMerchantResponses = useMemo(() => {
        return all_merchant_docs_responses?.filter((document) => document?.doc === selectedDoc?.id);
    }, [selectedDoc, all_merchant_docs_responses]);

    return (
        <AppDrawer
            open={open}
            icon={<ArrowBackIosRounded sx={{ color: '#46464A', fontSize: '18px' }} />}
            handleClose={handleClose}
            title={
                <div>
                    <p className="text-[16px] font-bold text-[#191B23]">{t('uploadHistory')}</p>
                    <p className="text-[11px] text-[#5E5E62] font-semibold">{selectedDoc?.name}</p>
                </div>
            }
            noClose
        >
            <List sx={{ px: 1.5, py: 0, mt: 3 }}>
                {filteredMerchantResponses?.map((file) => (
                    <DataItem key={file.id} openDeleteModal={openDeleteModal} file={file} />
                ))}
            </List>
        </AppDrawer>
    );
};

const DataItem = ({ openDeleteModal, file }) => {
    const [loadingLink, setLoadingLink] = useState(false);
    const { t } = useTranslation('dataroom');

    const getFileName = (url) => {
        const fileName = url?.split('/')?.[4];
        return fileName;
    };

    const getDownloadLink = async () => {
        if (file.response_file) {
            setLoadingLink(true);
            const res = await GetDownloadLink('compliance', 'data_room_response', file.id)();
            setLoadingLink(false);
            if (res?.success) {
                // open decoded url
                window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
                // open original file url
                window.open(file.response_file, '_blank');
            }
        }
    };

    return (
        <React.Fragment key={file?.id}>
            <ListItem
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    py: 2,
                }}
            >
                <Stack flexDirection="row" alignItems="flex-start" gap={2}>
                    <Avatar sx={{ bgcolor: '#F8F8F8' }} variant="square">
                        <Topic sx={{ color: '#395BA9' }} />
                    </Avatar>
                    <div>
                        <p className="text-[12px] font-medium text-[#395BA9]">{getFileName(file?.response_file)}</p>
                        <p className="text-[10px] font-normal mt-1 text-[#5E5E62]">
                            {t('uploaded')}
                            {moment(file?.date_created).format('MMMM Do, YYYY (h:mm a)')}
                        </p>
                    </div>
                </Stack>
                <Stack flexDirection="row" alignItems="center">
                    <Tooltip title={t('deleteDocument')} placement="top">
                        <IconButton aria-label="delete" onClick={() => openDeleteModal(file?.id)}>
                            <AiOutlineDelete color="#77777A" size="18px" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('downloadDocument')} placement="top">
                        <IconButton
                            // sx={{}}
                            onClick={getDownloadLink}
                        >
                            {loadingLink ? (
                                <LoadingState size={20} styles={{ justifyContent: 'start', alignItems: 'start' }} />
                            ) : (
                                <BsDownload color="#77777A" size="18px" />
                            )}
                        </IconButton>
                    </Tooltip>
                </Stack>
            </ListItem>
            <Divider sx={{ color: '#F0F0FA' }} />
        </React.Fragment>
    );
};

export default UploadHistoryDrawer;
