import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ArrowBackIosNew } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

//translation
import { useTranslation } from 'react-i18next';

const ConnectionViewHeader = (props) => {
    const theme = useTheme();
    const { goBack, authType } = props;

    const { t } = useTranslation('accounts');
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            sx={{
                py: 2.5,
                px: 2.5,
                borderBottom: `1px solid ${theme.palette.gray[200]}`,
            }}
        >
            <ArrowBackIosNew
                sx={{
                    width: 42,
                    height: 42,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: theme.palette.gray[50],
                    color: theme.palette.gray[500],
                    p: 1,
                    mr: 2,
                    borderRadius: '50%',
                    cursor: 'pointer',
                }}
                onClick={goBack}
            />
            <div>
                <Typography
                    sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 700,
                        fontSize: 28,
                    }}
                >
                    {authType === 'email'
                        ? t('twoFactorAuth.connectionViewHeader.emailAuthenticatorSetup')
                        : t('twoFactorAuth.connectionViewHeader.goggleAuthenticatorSetup')}
                </Typography>
                {authType !== 'email' && (
                    <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                        {t('twoFactorAuth.connectionViewHeader.subtitle')}
                    </Typography>
                )}
            </div>
        </Stack>
    );
};

export default ConnectionViewHeader;
