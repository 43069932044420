import React, { useEffect, useState } from 'react'; // useMemo, useState
import { SearchOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { Table, Col, Input, Row } from 'antd';
import BreadCrumb from 'components/Breadcrumb';

import SubcriteriaActions from './CodeReviewAcions';

// redux
import { connect } from 'react-redux';
import { DeleteSubriteria } from 'store/actions/adminActions';
import { GetAllCodeReview } from 'store/actions/merchantActions';
import useSearch from 'hooks/useSearch';

const codeeReviewDocuments = (props) => {
    const { all_code_review, GetAllCodeReview } = props;

    const { data, handleSearch } = useSearch(all_code_review, ['version_control_tool', 'repo_type', 'severity']);

    // // state
    const [loading, setLoading] = useState(false);

    // async functions
    const getallCodeReview = async () => {
        setLoading(true);
        const res = await GetAllCodeReview();
        setLoading(false);
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };
    useEffect(() => {
        getallCodeReview();
    }, []);

    // constants
    const columns = [
        { title: 'Merchant', render: (row) => row?.merchant_name },
        { title: 'Version Control Tool', render: (row) => row?.version_control_tool },
        {
            title: 'Repository Type',
            render: (row) => row?.repo_type,
        },
        {
            title: 'Severity',
            render: (row) => row?.severity,
        },
        { title: 'Frequency', render: (row) => row?.frequency },
        { title: 'Status', render: (row) => row?.status },
        { title: 'Code Review', render: (row) => row?.codereview_id },
        {
            title: 'Actions',
            align: 'right',
            render: (row) => <SubcriteriaActions row={row} />,
        },
    ];

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={['Dashboard', 'Code Review']} />
                </Col>
                <Col span={12} className="flex items-center">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                </Col>
            </Row>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    columns={columns}
                    loading={loading}
                    dataSource={data
                        ?.sort((a, b) => b?.id - a?.id)
                        ?.map((subcriteria, index) => ({ ...subcriteria, sn: index + 1 }))}
                    rowKey={(subcriteria) => subcriteria?.id}
                    rowClassName="cursor-pointer"
                    pagination={{
                        total: data?.length,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                    }}
                />
            </section>
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_code_review: state?.merchantReducers?.all_code_review,
});
export default connect(mapStateToProps, { DeleteSubriteria, GetAllCodeReview })(codeeReviewDocuments);
