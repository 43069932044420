import React from 'react';
//components
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

//translation
import { useTranslation } from 'react-i18next';

const AuthNotificationBar = ({ text }) => {
    const theme = useTheme();

    const { t } = useTranslation('accounts');
    return (
        <Box
            sx={{
                backgroundColor: theme.palette.warning[100],
                p: 2,
                my: 2,
                borderRadius: '8px',
            }}
        >
            <Typography
                sx={{
                    color: theme.palette.primary.main,
                    textAlign: 'center',
                    fontWeight: 500,
                }}
            >
                {text || t('twoFactorAuth.authNotificationBarText')}
            </Typography>
        </Box>
    );
};

export default AuthNotificationBar;
