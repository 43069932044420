import React from 'react';
import CIIReport from '../../AllReport/report/CII';

const CiiirstLayerReport = ({ percentage }) => {
    return (
        <div className="bg-white py-8 px-3">
            <div className="grid grid-cols-3 gap-8">
                <CIIReport percentage={percentage} />
            </div>
        </div>
    );
};

export default CiiirstLayerReport;
