import React from 'react';
import { Box, Button, Typography } from '@mui/material';

import AppCenteredModalNew from 'components/new_components/AppCenteredModalNew';
import CloseIcon from '@mui/icons-material/Close';

//translations
import { useTranslation } from 'react-i18next';

const MarkApprovedModal = (props) => {
    const {
        open,
        handleClose,
        approvedComment,
        setApprovedComment,
        openApproveMitigationModal,
        openDisApproveMitigationModal,
        type,
    } = props;

    //translation
    const { t } = useTranslation('riskAssessment');

    const handleSubmit = () => {
        if (type === 'approve') {
            openApproveMitigationModal();
        } else {
            openDisApproveMitigationModal();
        }
    };
    return (
        <AppCenteredModalNew
            open={open}
            handleClose={handleClose}
            noShadow
            noClose
            title={
                <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                    {' '}
                    {type === 'approve' ? t('approvedModal.title') : t('disapprovedModal.title')}
                </Typography>
            }
            headerAction={
                <Box
                    sx={{
                        border: '1px solid #E2E8F0',
                        borderRadius: 30,
                        width: 30,
                        height: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon sx={{ width: 20, height: 20 }} />
                </Box>
            }
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        mt: 1,
                        backgroundColor: '#F8FAFC',
                        height: '60px',
                        pb: '-2rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit', color: '#334155' }}
                        onClick={handleClose}
                    >
                        {t('approvedModal.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            textTransform: 'inherit',
                            fontWeight: 600,
                            borderRadius: 0.5,
                        }}
                        onClick={handleSubmit}
                    >
                        {t('approvedModal.continue')}
                    </Button>
                </Box>
            }
            width="430px"
            height="300px"
        >
            <Box sx={{ mt: 2 }}>
                <div>
                    <p className="text-[#64748B] text-sm font-medium mb-1.5">
                        {type === 'approve'
                            ? 'Any Comment for approval (Optional)'
                            : 'Any Comment for disapproval (Optional)'}
                    </p>
                    <textarea
                        rows={3}
                        placeholder="Comment"
                        value={approvedComment}
                        onChange={(event) => setApprovedComment(event?.target?.value)}
                        className="text-gray-400 text-sm font-normal placeholder:text-gray-400 border border-gray-200 py-2 rounded-[4px] w-full px-2"
                    />
                </div>
            </Box>
        </AppCenteredModalNew>
    );
};

export default MarkApprovedModal;
