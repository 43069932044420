import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

// core components
import BreadCrumb from '../../../../components/Breadcrumb';
import SurveyModal from '../Actions/QuestionModal';

// icons
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

//redux
import { connect } from 'react-redux';
import { GetAllSurvey, DeleteSrveyQuestion } from 'store/actions/adminActions';

// antd components
import { Button, Row, Col, Typography, Card, Spin, Empty, Modal } from 'antd';
import { Chip } from '@mui/material';

// sub-components
const { Title } = Typography;
const { confirm } = Modal;

const Index = (props) => {
    // state
    const [loading, setLoading] = useState({
        content: false,
    });
    const [oneSurvey, setOneSurvey] = useState({});
    const [modal, setModal] = useState(null);
    const [options, setOptions] = useState(['']);
    const { all_survey, GetAllSurvey, DeleteSrveyQuestion } = props;

    // functions
    const openModal = (mode, survey) => {
        setModal(mode);
        setOneSurvey(survey);
    };
    const closeModal = () => {
        setModal(null);
        setOptions(['']);
    };

    const getAllSurvey = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAllSurvey();
        if (!res.success) {
            toast.error(res?.message);
        }
        setLoading({ ...loading, content: false });
    };

    useEffect(() => {
        getAllSurvey();
    }, []);

    const deleteSrveyQuestion = (id) => {
        confirm({
            title: 'Are you sure delete this Question?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteSrveyQuestion(id, false);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Survey Quetsions' }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openModal('Add')}
                    >
                        Add Survey Question
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {loading.content ? (
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                ) : (
                    <>
                        {all_survey && all_survey?.length > 0 ? (
                            <Row wrap gutter={24}>
                                {all_survey
                                    ?.sort((a, b) => a?.id - b?.id)
                                    ?.map((survey) => (
                                        <Col
                                            xs={24}
                                            md={12}
                                            lg={12}
                                            xxl={8}
                                            key={survey.id}
                                            style={{ marginBottom: '1rem' }}
                                        >
                                            <Card
                                                title={`#${survey.id}`}
                                                loading={false}
                                                actions={[
                                                    <EditOutlined
                                                        key="edit"
                                                        onClick={() => openModal('Edit', survey)}
                                                    />,
                                                    <DeleteOutlined
                                                        key="delete"
                                                        onClick={() => deleteSrveyQuestion(survey?.id)}
                                                    />,
                                                ]}
                                            >
                                                <Title level={5} style={{ textAlign: 'center' }}>
                                                    {survey?.question}
                                                </Title>
                                                {survey?.answer_choices?.length ? (
                                                    <div className="flex justify-between items-center text-center flex-wrap mt-3">
                                                        {survey?.answer_choices?.map((choice, index) => (
                                                            <Chip
                                                                key={index}
                                                                label={choice}
                                                                variant="outlined"
                                                                sx={{ marginBottom: 2 }}
                                                            />
                                                        ))}
                                                    </div>
                                                ) : null}
                                            </Card>
                                        </Col>
                                    ))}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Survey Questions</h3>
                                                <span>Add a new Question </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section>
            <SurveyModal
                survey={oneSurvey}
                open={modal}
                handleCancel={closeModal}
                options={options}
                setOptions={setOptions}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_survey: state?.adminReducers?.all_survey,
    };
};
export default connect(mapStateToProps, {
    GetAllSurvey,
    DeleteSrveyQuestion,
})(Index);
