import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Button, Row, Card, Empty, Tooltip, Modal } from 'antd';
import BreadCrumb from 'components/Breadcrumb';
import {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    DownloadOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import DocumentModal from './components/DocumentModal';

// redux
import { connect } from 'react-redux';
import { GetAllCoursesDocuments, DeleteCourseDocument } from 'store/actions/trainingActions';
import { toast } from 'react-toastify';
import { GetDownloadLink } from 'store/actions/generalActions';
import LoadingState from 'components/new_components/LoadingState';
// sub-components
const { confirm } = Modal;

const CourseDocuments = (props) => {
    const [loading, setLoading] = useState(false);
    const [coursenModalOpen, setCourseModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [modalPayload, setModalPayload] = useState({});
    const { all_training_courses, all_courses_documents, GetAllCoursesDocuments, DeleteCourseDocument } = props;
    const { course_id } = useParams();
    const [loadingLink, setLoadingLink] = useState({ id: null, loading: false });

    //fuctions
    const openCourseModal = (type, details) => {
        setCourseModalOpen(true);
        setModalType(type);
        setModalPayload(details);
    };
    const closeCourseModal = () => {
        setCourseModalOpen(false);
        setModalPayload({});
    };

    const getDownloadLink = async (record) => {
        if (record.file) {
            setLoadingLink({ id: record.id, loading: true });
            const res = await GetDownloadLink('compliance', 'course_doc', record.id)();
            setLoadingLink({ id: record.id, loading: false });
            if (res?.success) {
                // open decoded url
                window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
            }
        }
    };

    //memos
    const course = useMemo(() => {
        return all_training_courses?.find((course) => course?.id === course_id);
    }, [all_training_courses]);
    const documents = useMemo(
        () =>
            all_courses_documents
                ?.filter((doc) => doc?.course === course_id)
                ?.sort((a, b) => a?.doc_number - b?.doc_number),
        [all_courses_documents]
    );

    const documentActions = (document) => {
        let actions = [
            <Tooltip title="Download Document" color="blue">
                <div key="preview" onClick={() => getDownloadLink(document)}>
                    {loadingLink.id === document.id && loadingLink.loading ? (
                        <LoadingState size={13} styles={{ mr: 1.5 }} />
                    ) : (
                        <DownloadOutlined />
                    )}
                </div>
            </Tooltip>,
            <Tooltip title="Update Document" color="blue">
                <EditOutlined key="edit" title="Edit this Document" onClick={() => openCourseModal('Edit', document)} />
            </Tooltip>,
            <Tooltip title="Delete this Document" color="red">
                <DeleteOutlined key="delete" title="Delete this document" onClick={() => deleteModal(document)} />
            </Tooltip>,
        ];
        return actions;
    };

    const deleteModal = (document) => {
        confirm({
            title: 'Do you want to delete this document?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await DeleteCourseDocument(document?.id);
                if (res.success) {
                    toast.success('Document Deleted Successfully');
                } else {
                    toast.error(res.message);
                }
            },
        });
    };
    useEffect(() => {
        const getAllCoursesDocuments = async () => {
            setLoading(true);
            const res = await GetAllCoursesDocuments();
            setLoading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllCoursesDocuments();
    }, []);
    return (
        <>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Course', link: '/training' }, { label: course?.title }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center"
                        onClick={() => openCourseModal('Add')}
                    >
                        Add Document
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {loading ? (
                    'loading'
                ) : (
                    <>
                        {documents && documents?.length > 0 ? (
                            <Row gutter={16}>
                                {documents
                                    ?.sort((a, b) => a?.id - b?.id)
                                    ?.map((document) => {
                                        return (
                                            <Col
                                                xs={24}
                                                sm={12}
                                                md={8}
                                                lg={6}
                                                style={{ marginBottom: '1rem' }}
                                                key={document.id}
                                            >
                                                <Card
                                                    loading={false}
                                                    actions={documentActions(document)}
                                                    title={document.title}
                                                >
                                                    {document.description}
                                                </Card>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Document</h3>
                                                <span>Add a new document </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section>
            <DocumentModal
                open={coursenModalOpen}
                handleClose={closeCourseModal}
                type={modalType}
                modalPayload={modalPayload}
                course_id={course_id}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_training_courses: state?.trainingReducers?.all_training_courses,
        all_courses_documents: state?.trainingReducers?.all_courses_documents,
    };
};

export default connect(mapStateToProps, { GetAllCoursesDocuments, DeleteCourseDocument })(CourseDocuments);
