import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Input, Modal, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { EditAuditCategory } from 'store/actions/auditActions';

const { Option } = Select;

const FieldModal = (props) => {
    // props
    const { open, closeModal, EditAuditCategory, category, questions, fieldIndex } = props;

    // refs
    const optionsRef = useRef([]);

    // state
    const [field, setField] = useState({});
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    // functions
    const closeAll = () => {
        closeModal();
        setField({});
        setOptions([]);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        let payload = [...questions];
        if (open === 'Add') {
            payload.push({ ...field, options: options });
        } else {
            payload[fieldIndex] = { ...field, options: options };
        }
        setLoading(true);
        const res = await EditAuditCategory({ questions: JSON.stringify(payload) }, category);
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setField({ ...field, [name]: value });
    };
    // handle field type
    const handleTypeChange = (value) => {
        setField({ ...field, type: value });
        if (value !== 'dropdown') {
            setOptions(null);
        }
    };
    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setField({ ...field, [name]: checked });
    };
    // options
    const addNewOption = () => {
        setOptions((options) => [...options, '']);
    };
    const removeOption = (index) => {
        const newOptions = [...options];
        newOptions?.splice(index, 1);
        setOptions(newOptions);
    };
    const handleOptionChange = (value, index) => {
        const newOptions = options || [];
        newOptions[index] = value;
        setOptions([...newOptions]);
        setTimeout(() => optionsRef.current[index].focus(), 100);
    };

    // useEffect
    useEffect(() => {
        if (open && fieldIndex) {
            const field = questions[fieldIndex];
            setField(field);
            setOptions(field?.options);
        }
    }, [fieldIndex, open]);

    return (
        <Modal title={`${open} Field`} open={open} onCancel={closeAll} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col xs={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="name">Name</label>
                            <Input
                                id="name"
                                type="text"
                                size="large"
                                value={field?.name || ''}
                                onChange={handleTextChange}
                                name="name"
                            />
                        </div>
                    </Col>
                    <Col xs={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="type">Type</label>
                            <Select
                                id="type"
                                size="large"
                                style={{ width: '100%' }}
                                value={field?.type}
                                onChange={handleTypeChange}
                                name="type"
                            >
                                <Option value="text">Text Field</Option>
                                <Option value="number">Number Field</Option>
                                <Option value="dropdown">Dropdown</Option>
                                <Option value="date">Date</Option>
                                <Option value="time">Time</Option>
                                <Option value="textarea">Textarea</Option>
                            </Select>
                        </div>
                    </Col>
                    {field?.type === 'dropdown' ? (
                        <Col xs={24}>
                            <div className="form-group" style={{ marginBottom: '1rem' }}>
                                <Row align="middle" justify="space-between">
                                    <label htmlFor="field-type">Options</label>
                                    <Button icon={<PlusOutlined />} onClick={addNewOption} />
                                </Row>
                                {options && options?.length ? (
                                    options?.map((option, index) => {
                                        return (
                                            <Row justify="space-between" style={{ margin: '.5rem 0' }} key={index}>
                                                <Input
                                                    style={{ width: '90%' }}
                                                    value={option || ''}
                                                    onChange={(e) => handleOptionChange(e.target.value, index)}
                                                    ref={(element) => (optionsRef.current[index] = element)}
                                                />
                                                <Button
                                                    danger
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => removeOption(index)}
                                                />
                                            </Row>
                                        );
                                    })
                                ) : (
                                    <Row justify="space-between" style={{ margin: '.5rem 0' }}>
                                        <Input
                                            style={{ width: '90%' }}
                                            value={field?.options ? field?.options[0] : ''}
                                            onChange={(e) => handleOptionChange(e.target.value, 0)}
                                        />
                                        <Button danger icon={<DeleteOutlined />} onClick={() => removeOption(0)} />
                                    </Row>
                                )}
                            </div>
                        </Col>
                    ) : null}
                    <Col xs={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="tooltip">Tooltip</label>
                            <Input
                                id="tooltip"
                                type="text"
                                size="large"
                                value={field?.tooltip || ''}
                                onChange={handleTextChange}
                                name="tooltip"
                            />
                        </div>
                    </Col>
                    <Col xs={24} md={12}>
                        <Checkbox onClick={handleCheckbox} name="required" size="large" checked={field?.required}>
                            Required
                        </Checkbox>
                    </Col>
                    <Col xs={24} md={12}>
                        <Checkbox onClick={handleCheckbox} name="fullWidth" size="large" checked={field?.fullWidth}>
                            Full Width
                        </Checkbox>
                    </Col>
                    <Col>
                        <Button
                            className="ant-btn"
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                            style={{ marginTop: '3rem' }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, { EditAuditCategory })(FieldModal);
