import {
    ALL_MERCHANT_ONBOARDING_ITEMS,
    ALL_MERCHANT_ONBOARDING_RESOURCE,
    ALL_ONBOARDING_ITEMS_FOR_MERCHANTS,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case ALL_MERCHANT_ONBOARDING_ITEMS:
            return {
                ...state,
                all_merchant_onboarding_items: action.payload,
            };
        case ALL_MERCHANT_ONBOARDING_RESOURCE:
            return {
                ...state,
                all_merchant_onboarding_resource: action.payload,
            };
        case ALL_ONBOARDING_ITEMS_FOR_MERCHANTS:
            return {
                ...state,
                all_onboarding_items_for_merchant: action.payload,
            };
        case 'CLEAR_DATA':
            return {};
        default:
            return state;
    }
};
