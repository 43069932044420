import React from 'react';
import { MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

const RequestMenuItem = ({ onClick, header, subheader, disabled }) => {
    const theme = useTheme();
    return (
        <MenuItem
            sx={{ flexDirection: 'column', alignItems: 'flex-start !important', fontSize: 14 }}
            onClick={onClick}
            disabled={disabled}
        >
            <Typography sx={{ color: theme.palette.gray[800], fontSize: 14, fontWeight: 600 }}>{header}</Typography>
            <Typography sx={{ color: theme.palette.gray[600], fontSize: 12 }}>{subheader}</Typography>
        </MenuItem>
    );
};

export default RequestMenuItem;
