import React from 'react';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { Typography, IconButton, Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Close } from '@mui/icons-material';
import ContentBody from './ContentBody';
import DataCollectionTable from './DataCollectionTable';
import { useTranslation } from 'react-i18next';

const PrivacyNoticeModal = (props) => {
    const { open, handleClose } = props;
    const theme = useTheme();
    const { t } = useTranslation('auth');

    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            title={
                <Typography sx={{ color: theme.palette.primary[20], fontSize: 20, fontWeight: 700 }}>
                    {t('footerLinks.link1')}
                </Typography>
            }
            headerAction={
                <IconButton onClick={handleClose} sx={{ backgroundColor: '#EEF0FF4D' }}>
                    <Close />
                </IconButton>
            }
            width="720px"
            minWidth="400px"
            maxWidth="unset"
            height="90%"
        >
            <Box sx={{ my: 3 }}>
                <Box>
                    <Typography
                        component="h4"
                        sx={{
                            fontSize: 12,
                            fontWeight: 700,
                            letterSpacing: '0.1px',
                            color: theme.palette.gray[30],
                            mb: 0.5,
                        }}
                    >
                        {t('privacyNotice.date')}
                    </Typography>
                    <ContentBody sx={{ pt: 0 }}>
                        {t('privacyNotice.body1.a')}
                        <a className="underline" href="https://smartcomply.com/" target="_blank" rel="noreferrer">
                            https://smartcomply.com/
                        </a>
                        ("the website) and our webapp:{' '}
                        <a className="underline" href="https://app.smartcomply.com/" target="_blank" rel="noreferrer">
                            https://app.smartcomply.com/
                        </a>
                        {t('privacyNotice.body1.b')}
                    </ContentBody>
                    <ContentBody title={t('headers.a')}>{t('privacyNotice.overview')}</ContentBody>
                    <ContentBody title={t('headers.b')}>{t('privacyNotice.who-we-are')}</ContentBody>
                    <ContentBody title={t('headers.c')}>{t('privacyNotice.personal-information')}</ContentBody>
                    <ContentBody title={t('headers.d')}>{t('privacyNotice.cookies')}</ContentBody>
                    <ContentBody title={t('headers.e')}>
                        {t('privacyNotice.legal-bases.a')}
                        <p className="mb-1">
                            {' '}
                            <strong> {t('privacyNotice.legal-bases.b.a')}</strong> {t('privacyNotice.legal-bases.b.b')}
                        </p>
                        <p className="mb-1">
                            <strong> {t('privacyNotice.legal-bases.c.a')}</strong> {t('privacyNotice.legal-bases.c.b')}
                        </p>
                        <p className="mb-1">
                            <strong> {t('privacyNotice.legal-bases.d.a')}</strong> {t('privacyNotice.legal-bases.d.b')}
                        </p>
                    </ContentBody>
                    <ContentBody title={t('headers.f')}>
                        <p className="mb-3"> {t('privacyNotice.purposes')}</p>
                        <DataCollectionTable />
                    </ContentBody>
                    <ContentBody title={t('headers.g')}>{t('privacyNotice.protection')}</ContentBody>
                    <ContentBody title={t('headers.h')}>
                        <p> {t('privacyNotice.disclosure.a')} </p>
                        <p>{t('privacyNotice.disclosure.b')}</p>
                        <p>{t('privacyNotice.disclosure.c')} </p>
                        <p>{t('privacyNotice.disclosure.d')}</p>
                    </ContentBody>
                    <ContentBody title={t('headers.i')}>
                        <p>{t('privacyNotice.rights.a')}</p>
                        <p>{t('privacyNotice.rights.b')}</p>
                        <p>{t('privacyNotice.rights.c')}</p> <p>{t('privacyNotice.rights.d')}</p>{' '}
                        <p>{t('privacyNotice.rights.e')}</p> <p>{t('privacyNotice.rights.f')} </p>
                        <p>{t('privacyNotice.rights.g')}</p>
                        <p> {t('privacyNotice.rights.h')}</p> <p>{t('privacyNotice.rights.i')}</p>
                        <p className="mt-3">{t('privacyNotice.rights.j')}</p>
                        <p>{t('privacyNotice.rights.k')}</p>
                        <p>
                            {t('login.inputLabel1')}:{' '}
                            <a href="mailto:ope@smartcomplyapp.com" target="_blank" rel="noreferrer">
                                ope@smartcomplyapp.com
                            </a>
                        </p>
                        <p>{t('privacyNotice.rights.l')}: 47, Karimu Ikotun close, Sabo, Yaba, Lagos, Nigeria. </p>
                        <p>{t('privacyNotice.rights.m')}: +234 708 650 1941</p>
                    </ContentBody>
                    <ContentBody title={t('headers.j')}>{t('privacyNotice.data-retention')}</ContentBody>
                    <ContentBody title={t('headers.k')}>
                        <p>{t('privacyNotice.transfer-of-data.a')}</p>
                        <p>{t('privacyNotice.transfer-of-data.b')}</p>
                        <p>{t('privacyNotice.transfer-of-data.c')}</p>
                        <ul className="ml-3 list-disc">
                            <li> {t('privacyNotice.transfer-of-data.d')}</li>
                            <li>{t('privacyNotice.transfer-of-data.e')}</li>
                            <li>{t('privacyNotice.transfer-of-data.f')}</li>
                            <li>{t('privacyNotice.transfer-of-data.g')}</li>
                            <li>{t('privacyNotice.transfer-of-data.h')}</li>
                            <li>{t('privacyNotice.transfer-of-data.i')}</li>
                        </ul>
                        <p>{t('privacyNotice.transfer-of-data.j')}</p>
                    </ContentBody>
                    <ContentBody title={t('headers.l')}>
                        <p>
                            {t('privacyNotice.complaints.a')}{' '}
                            <a
                                className="underline"
                                href="mailto:ope@smartcomplyapp.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t('privacyNotice.complaints.b')}
                            </a>{' '}
                            {t('privacyNotice.complaints.c')}
                        </p>
                        <p>{t('privacyNotice.complaints.d')}</p>
                    </ContentBody>
                    <ContentBody title={t('headers.m')}>{t('privacyNotice.changes')}</ContentBody>
                    <ContentBody title={t('headers.n')}>
                        <p>{t('privacyNotice.contact-us.a')}</p>
                        <p>{t('privacyNotice.contact-us.b')}</p>
                        <p>{t('register.inputLabel5')}: +234 813 326 2024</p>
                        <p>{t('privacyNotice.contact-us.c')}: 47, Karimu Ikotun close, Sabo, Yaba, Lagos, Nigeria</p>
                    </ContentBody>
                </Box>
            </Box>
        </AppCenteredModal>
    );
};

export default PrivacyNoticeModal;
