import React, { useEffect, useState } from 'react';
import { Modal, Row, Select, Col, Button, Checkbox } from 'antd';

//redux
import { connect } from 'react-redux';
import { SendMerchantSurvey } from 'store/actions/adminActions';
import { toast } from 'react-toastify';

const { Option } = Select;

const SendBulkSurveyModal = (props) => {
    const { open, closeModal, SendMerchantSurvey, all_merchants } = props;
    const [data, setData] = useState('');
    const [merchants, setMerchants] = useState([]);
    const [all, setAll] = useState(false);
    const [loading, setloading] = useState(false);
    const [error, setError] = useState('');

    const closeAll = () => {
        setData('');
        closeModal();
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (data === '') {
            setError('error');
        } else if (data === 'few' && merchants.length === 0) {
            setError('error');
        } else if (data === 'all' && merchants.length === 0) {
            setError('error');
        } else {
            setloading(true);
            const body = {
                merchant_ids: merchants,
            };
            const res = await SendMerchantSurvey(body);
            setloading(false);
            setMerchants([]);
            if (res?.success) {
                toast.success(res?.message);
                closeAll();
            } else {
                toast.error(res?.message);
            }
        }
    };

    useEffect(() => {
        if (all === true) {
            const merchant_ids = all_merchants?.map((merchant) => merchant?.id);
            setMerchants(merchant_ids);
        }
    }, [all]);
    return (
        <Modal title={'Send Merchant Survey'} open={open} onCancel={closeAll} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="start">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="plan">Choose Type</label>
                        <Select
                            size="large"
                            name="data"
                            id="data"
                            onChange={(value) => setData(value)}
                            value={data || ''}
                            required
                            style={{ width: '100%' }}
                        >
                            <Option value="">Select type</Option>
                            <Option value="all">All Merchants</Option>
                            <Option value="few">Few Merchants</Option>
                        </Select>
                    </Col>
                    {data === '' && error && <span className="text-[#ff0000]">Please select an Option</span>}
                    {data === 'few' && (
                        <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="plan">Choose Merchant</label>
                            <Select
                                size="large"
                                name="merchants"
                                id="merchants"
                                mode="multiple"
                                placeholder="Please select"
                                onChange={(value) => setMerchants(value)}
                                value={merchants || ''}
                                required
                                style={{ width: '100%' }}
                            >
                                <Option value="">Select Merchant</Option>
                                {all_merchants?.map((merchant) => {
                                    return (
                                        <Option value={merchant?.id} key={merchant?.id}>
                                            {merchant?.name || merchant?.owner_detail?.first_name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                    )}
                    {data === 'few' && merchants.length === 0 && error && (
                        <span className="text-[#ff0000]">Please select merchants</span>
                    )}
                    {data === 'all' && (
                        <Col span={24}>
                            <Checkbox name={all} onChange={() => setAll(!all)} className="ml-0 mr-2 mb-1" checked={all}>
                                Send to all merchants
                            </Checkbox>
                        </Col>
                    )}
                    {data === 'all' && merchants.length === 0 && error && (
                        <span className="text-[#ff0000]">Please check box!</span>
                    )}
                </Row>
                <Row justify="end" className="mt-3">
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchants: state?.adminReducers?.all_merchants,
    };
};
export default connect(mapStateToProps, { SendMerchantSurvey })(SendBulkSurveyModal);
