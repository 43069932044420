import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { FilterList } from '@mui/icons-material';

// core components
import AppDatePicker from 'components/new_components/AppDatePicker';
import AppButton from 'components/new_components/AppButton';

const ActivitiesTableFilter = (props) => {
    const { handleInput, handleFiltering } = props;

    return (
        <Stack component="form" direction={{ xs: 'column', lg: 'row' }} spacing={1} onSubmit={handleFiltering}>
            <FilterDatePicker label="From" name="start" onChange={handleInput} />
            <FilterDatePicker label="To" name="end" onChange={handleInput} />
            <AppButton icon={<FilterList />} type="submit" color="primary" variant="contained" name="Filter" />
        </Stack>
    );
};

const FilterDatePicker = (props) => {
    const theme = useTheme();
    const { label, onChange, name } = props;

    // functions
    const handleChange = (date) => {
        onChange(name, date);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}
        >
            <Box
                component="span"
                sx={{
                    fontWeight: 600,
                    fontSize: 14,
                    mr: 1,
                    color: theme.palette.gray[900],
                }}
            >
                {label}:
            </Box>
            <AppDatePicker placeholder="DD/MM/YYYY" onChange={handleChange} />
        </Box>
    );
};

export default ActivitiesTableFilter;
