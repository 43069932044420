import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import AddDocumentModal from '../../Operations/AddDocumentModal';
import EditDocumentModal from '../../Operations/EditDocumentModal';

import { Card, Row, Col, Button, Typography, Modal, Empty, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { BiUnlink } from 'react-icons/bi';

import { PlusOutlined, EditOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
// redux
import { connect, useDispatch } from 'react-redux';
import {
    DeleteDocument,
    EditDocument,
    GetAllDocumentsByAltTag,
    GetAllDocumentsByAnnexNew,
} from 'store/actions/adminActions';
import { GetDocumentLink } from 'store/actions/complianceActions';

// sub-components
const { Title } = Typography;
const { confirm } = Modal;

const NpdrDocumentCardsView = (props) => {
    const {
        annex_id,
        all_iso_documents,
        EditDocument,
        DeleteDocument,
        GetAllDocumentsByAltTag,
        GetAllDocumentsByAnnexNew,
    } = props;
    const [modal, setModal] = useState({ add: false, edit: false });
    const [one_doc, setOneDoc] = useState({});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const openAddDocumentModal = () => setModal({ ...modal, add: true });
    const closeAddDocumentModal = () => setModal({ ...modal, add: false });
    const openEditDocumentModal = (doc) => {
        setModal({ ...modal, edit: true });
        setOneDoc(doc);
    };
    const closeEditDocumentModal = () => setModal({ ...modal, edit: false });

    const filteredIsoDocumments = all_iso_documents?.filter((iso) => iso?.tech_sec === annex_id);
    console.log(all_iso_documents);
    console.log(filteredIsoDocumments);

    const getDocumentLink = async (id) => {
        const res = await dispatch(GetDocumentLink('document', id));
        if (res?.success) {
            window.open(res?.data, '_blank');
        } else {
            toast.error('Something went wrong!');
        }
    };
    //Actions Array
    const documentActions = (document) => {
        let actions = [
            <Tooltip title="Download Document" color="blue">
                <div onClick={() => getDocumentLink(document?.id)}>
                    <DownloadOutlined />
                </div>
            </Tooltip>,
            <Tooltip title="Edit this Document" color="blue">
                <EditOutlined key="edit" title="Edit this Document" onClick={() => openEditDocumentModal(document)} />
            </Tooltip>,
            <Tooltip title="Delete this Document" color="red">
                <DeleteOutlined key="delete" title="Delete this document" onClick={() => deleteModal(document)} />
            </Tooltip>,
        ];
        // if the tags array are more than one and it contains iso27001
        if (document?.tags?.split(',').length > 1 && document?.tags?.split(',').includes('tech_sec')) {
            actions.push(
                <Tooltip title="Unlink this Document" color="blue">
                    <BiUnlink key="unlink" title="Unlink this document" onClick={() => unlinkModal(document)} />
                </Tooltip>
            );
        }
        return actions;
    };
    const deleteModal = (document) => {
        confirm({
            title: 'Do you want to delete this document?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteDocument(document?.id, annex_id, 'tech_sec');
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };
    const unlinkModal = (document) => {
        confirm({
            title: 'Do you want to unlink this document?',
            icon: <ExclamationCircleOutlined />,
            content: 'Unlinking this document will detach this document from this compliance standard',
            okText: 'Yes',
            cancelText: 'No',
            async onOk() {
                // split csv string to array and filter array to remove iso27001 instance(s) and join back to string
                let newTags = document.tags
                    .split(',')
                    .filter((tag) => tag !== 'tech_sec')
                    .join();
                const formData = new FormData();
                // append tags to form data
                formData.append('tags', newTags);
                const res = await EditDocument(formData, document?.id, 'tech_sec', document?.annex);
                if (res.success) {
                    toast.success('Document Unlinked Successfully');
                } else {
                    toast.error(res.message);
                }
            },
        });
    };
    const getAllDocumentsByAltTags = async () => {
        setLoading(true);
        const res = await GetAllDocumentsByAltTag('tech_sec');
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllIsoDocuments = async () => {
        setLoading(true);
        const res = await GetAllDocumentsByAnnexNew(annex_id);
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        getAllDocumentsByAltTags();
        getAllIsoDocuments();
    }, []);
    return (
        <>
            <Row justify="end">
                <Button type="primary" icon={<PlusOutlined />} onClick={openAddDocumentModal}>
                    Add Document
                </Button>
            </Row>
            <section className="my-3">
                {loading ? (
                    'loading...'
                ) : (
                    <>
                        {filteredIsoDocumments && filteredIsoDocumments?.length > 0 ? (
                            <Row gutter={16}>
                                {filteredIsoDocumments
                                    ?.sort((a, b) => a?.id - b?.id)
                                    ?.map((document) => {
                                        return (
                                            <Col
                                                xs={24}
                                                sm={12}
                                                md={8}
                                                lg={6}
                                                style={{ marginBottom: '1rem' }}
                                                key={document?.id}
                                            >
                                                <Card loading={false} actions={documentActions(document)}>
                                                    <Title level={5} style={{ textAlign: 'center' }}>
                                                        {document?.tech_sec_title}
                                                    </Title>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Document</h3>
                                                <span>Add a new document </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section>
            {modal.add && (
                <AddDocumentModal
                    open={modal.add}
                    handleClose={closeAddDocumentModal}
                    tag="tech_sec"
                    payload={{
                        tech_sec: annex_id,
                    }}
                    titleLabel="tech_sec_title"
                    descLabel="tech_sec_description"
                    groupIdLabel="tech_sec"
                />
            )}
            {modal.edit && (
                <EditDocumentModal
                    open={modal.edit}
                    handleClose={closeEditDocumentModal}
                    one_document={one_doc}
                    tag="tech_sec"
                    payload={{
                        tech_sec: annex_id,
                    }}
                    titleLabel="tech_sec_title"
                    descLabel="tech_sec_description"
                    groupIdLabel="tech_sec"
                />
            )}
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_iso_documents: state?.adminReducers?.all_isonew_documents,
    };
};
export default connect(mapStateToProps, {
    DeleteDocument,
    EditDocument,
    GetAllDocumentsByAltTag,
    GetAllDocumentsByAnnexNew,
})(NpdrDocumentCardsView);
