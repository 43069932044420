import React from 'react';
import { Link } from 'react-router-dom';
// core components
import BreadCrumb from 'components/Breadcrumb';

// antd components
import { Row, Col, Typography, Card, Tooltip } from 'antd';

// icons
import { EyeOutlined } from '@ant-design/icons';

// sub-components
const { Title } = Typography;

const NdprDashboard = () => {
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'ISO27017 + 27032' }]} />
                </Col>
            </Row>
            <section className="my-3">
                <Row wrap gutter={24}>
                    <Col xs={24} md={12} lg={8} xxl={6} style={{ marginBottom: '1rem' }}>
                        <Card
                            title="#1"
                            loading={false}
                            actions={[
                                <Tooltip title="View Annexes" color="blue">
                                    <Link
                                        key="preview"
                                        to={{
                                            pathname: '/admin/iso27017+27032/annexes',
                                        }}
                                    >
                                        <EyeOutlined key="preview" />
                                    </Link>
                                </Tooltip>,
                            ]}
                        >
                            <Title level={5} style={{ textAlign: 'center' }}>
                                Annexes
                            </Title>
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={8} xxl={6} style={{ marginBottom: '1rem' }}>
                        <Card
                            title="#2"
                            loading={false}
                            actions={[
                                <Tooltip title="View Management Clauses" color="blue">
                                    <Link
                                        key="preview"
                                        to={{
                                            pathname: '/admin/iso27017+27032/clauses',
                                        }}
                                    >
                                        <EyeOutlined key="preview" />
                                    </Link>
                                </Tooltip>,
                            ]}
                        >
                            <Title level={5} style={{ textAlign: 'center' }}>
                                Management Clauses
                            </Title>
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={8} xxl={6} style={{ marginBottom: '1rem' }}>
                        <Card
                            title="#3"
                            loading={false}
                            actions={[
                                <Tooltip title="View Cybersecurities" color="blue">
                                    <Link
                                        key="preview"
                                        to={{
                                            pathname: '/admin/iso27017+27032/cybersecurities',
                                        }}
                                    >
                                        <EyeOutlined key="preview" />
                                    </Link>
                                </Tooltip>,
                            ]}
                        >
                            <Title level={5} style={{ textAlign: 'center' }}>
                                Cybersecurity
                            </Title>
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={8} xxl={6} style={{ marginBottom: '1rem' }}>
                        <Card
                            title="#4"
                            loading={false}
                            actions={[
                                <Tooltip title="View Project Managements" color="blue">
                                    <Link
                                        key="preview"
                                        to={{
                                            pathname: '/admin/iso27017+27032/project_managements',
                                        }}
                                    >
                                        <EyeOutlined key="preview" />
                                    </Link>
                                </Tooltip>,
                            ]}
                        >
                            <Title level={5} style={{ textAlign: 'center' }}>
                                Project Managements
                            </Title>
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={8} xxl={6} style={{ marginBottom: '1rem' }}>
                        <Card
                            title="#5"
                            loading={false}
                            actions={[
                                <Tooltip title="View Cloud Securities" color="blue">
                                    <Link
                                        key="preview"
                                        to={{
                                            pathname: '/admin/iso27017+27032/cloud_securities',
                                        }}
                                    >
                                        <EyeOutlined key="preview" />
                                    </Link>
                                </Tooltip>,
                            ]}
                        >
                            <Title level={5} style={{ textAlign: 'center' }}>
                                Cloud Security
                            </Title>
                        </Card>
                    </Col>
                </Row>
            </section>
        </div>
    );
};

export default NdprDashboard;
