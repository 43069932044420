import React, { useState } from 'react';
import { toast } from 'react-toastify';

import AppDeleteModal from 'components/new_components/AppDeleteModal';

// redux
import { connect } from 'react-redux';
import { DeleteTask } from 'store/actions/taskActions';

const DeleteTaskModal = (props) => {
    const { open, handleClose, payload, DeleteTask } = props;

    const [deleting, setDeleting] = useState(false);

    const handleDelete = async () => {
        setDeleting(true);
        const res = await DeleteTask(payload?.id);
        setDeleting(false);
        if (res?.success) {
            toast.success('Successfully deleted a task.');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <AppDeleteModal
            open={open}
            handleClose={handleClose}
            title="Permanently delete this task?"
            subtitle="This action cannot be reversed"
            deleteText="Yes, Delete"
            deleting={deleting}
            onDelete={handleDelete}
            actionsPosition="center"
        />
    );
};
const mapStateToProps = (state) => {
    return { ...state };
};
export default connect(mapStateToProps, { DeleteTask })(DeleteTaskModal);
