import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Button, Select, Input, Switch } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const FieldModal = (props) => {
    const { open, closeModal, formData, mode, one_field, CreateFormField, EditFormField } = props;
    const [field, setField] = useState({});
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const optionsRef = useRef([]);

    // functions
    const handleModalClose = () => {
        closeModal();
        setField({});
    };
    const addNewOption = () => {
        setOptions((options) => [...options, '']);
    };
    const removeOption = (index) => {
        const newOptions = [...options];
        newOptions?.splice(index, 1);
        setOptions(newOptions);
    };
    const handleOptionChange = (value, index) => {
        const newOptions = options || [];
        newOptions[index] = value;
        setOptions([...newOptions]);

        setTimeout(() => optionsRef.current[index].focus(), 100);
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setField({ ...field, [name]: value });
    };
    const handleSubmit = async () => {
        const newField = { ...field };
        if (!options) {
            newField.options = null;
        } else {
            newField.options = options.join(',');
        }
        setLoading(true);
        const res =
            mode === 'add'
                ? await CreateFormField({ ...newField, form: formData?.id })
                : await EditFormField(newField?.id, {
                      ...newField,
                      form: formData?.id,
                  });
        setLoading(false);
        if (res.success) {
            toast.success(res?.message);
            handleModalClose();
        } else {
            toast.error(res?.message);
        }
    };
    // useEffect
    useEffect(() => {
        if (open && mode === 'edit') {
            for (let item in one_field) {
                if (item === 'name_fr' || item === 'name_en') {
                    delete one_field[item];
                }
            }
            setField(one_field);
            setOptions(one_field?.options?.split(','));
        }
    }, [open, mode, one_field]);
    useEffect(() => {
        optionsRef.current = optionsRef.current.slice(0, optionsRef?.current?.length);
    }, [options]);
    useEffect(() => {
        if (field?.type === 'dropdown') {
            setOptions(field?.options?.split(','));
        } else {
            setOptions(null);
        }
    }, [field?.type]);

    return (
        <Modal
            title={`${mode === 'add' ? 'Add' : 'Edit'} Field`}
            open={open}
            onCancel={handleModalClose}
            footer={null}
            destroyOnClose={true}
        >
            <Row justify="end">
                <Col xs={24}>
                    <div className="form-group" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="name">Name</label>
                        <Input
                            id="name"
                            type="text"
                            size="large"
                            value={field?.name || ''}
                            onChange={handleTextChange}
                            name="name"
                        />
                    </div>
                </Col>
                <Col xs={24}>
                    <div className="form-group" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="type">Type</label>
                        <Select
                            id="type"
                            size="large"
                            style={{ width: '100%' }}
                            defaultValue={field?.type}
                            onChange={(value) => setField({ ...field, type: value })}
                            name="type"
                        >
                            <Option value="text">Text Field</Option>
                            <Option value="number">Number Field</Option>
                            <Option value="dropdown">Dropdown</Option>
                            <Option value="date">Date</Option>
                            <Option value="time">Time</Option>
                            <Option value="textarea">Textarea</Option>
                        </Select>
                    </div>
                </Col>
                {field?.type === 'dropdown' ? (
                    <Col xs={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <Row align="middle" justify="space-between">
                                <label htmlFor="field-type">Options</label>
                                <Button icon={<PlusOutlined />} onClick={addNewOption} />
                            </Row>
                            {options && options?.length ? (
                                options?.map((option, index) => {
                                    return (
                                        <Row justify="space-between" style={{ margin: '.5rem 0' }} key={index}>
                                            <Input
                                                style={{ width: '90%' }}
                                                value={option || ''}
                                                onChange={(e) => handleOptionChange(e.target.value, index)}
                                                ref={(element) => (optionsRef.current[index] = element)}
                                            />
                                            <Button
                                                danger
                                                icon={<DeleteOutlined />}
                                                onClick={() => removeOption(index)}
                                            />
                                        </Row>
                                    );
                                })
                            ) : (
                                <Row justify="space-between" style={{ margin: '.5rem 0' }}>
                                    <Input
                                        style={{ width: '90%' }}
                                        value={field?.options ? field?.options[0] : ''}
                                        onChange={(e) => handleOptionChange(e.target.value, 0)}
                                    />
                                    <Button danger icon={<DeleteOutlined />} onClick={() => removeOption(0)} />
                                </Row>
                            )}
                        </div>
                    </Col>
                ) : null}

                <Col xs={24}>
                    Required{' '}
                    <Switch checked={field?.required} onChange={(value) => setField({ ...field, required: value })} />
                </Col>
                <Col>
                    <Button
                        className="ant-btn"
                        htmlType="submit"
                        type="primary"
                        loading={loading}
                        style={{ marginTop: '3rem' }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

export default FieldModal;
