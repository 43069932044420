import React from 'react';
import { Chip, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme } from '@mui/material/styles';

const SeverityTag = (props) => {
    const { text, type, frequency, fontWeight, fontSize, margin } = props;
    const theme = useTheme();
    return (
        <div>
            <Chip
                sx={{
                    marginRight: margin ? margin : 1,
                    marginBottom: margin ? margin : 1,
                    padding: '8px 4px   ',
                    borderRadius: '4px',
                    width: 'fit-content',
                    height: 'unset',
                    '& .MuiChip-label': { p: fontSize ? 0.2 : 'inital' },
                }}
                label={
                    <Typography
                        sx={{
                            fontSize: fontSize ? fontSize : 12,
                            fontWeight: fontWeight ? fontWeight : 600,
                            color: theme.palette.gray[600],
                            textTransform: 'capitalize',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                        component="span"
                    >
                        <FiberManualRecordIcon
                            sx={{
                                width: 12,
                                height: 12,
                                marginRight: theme.spacing(0.5),
                            }}
                            color={type}
                        />
                        {text}
                        {frequency || frequency === 0 ? (
                            <span
                                className="flex items-center justify-center w-[17px] rounded-[50%]
                    h-[16px] text-[10px] bg-[#AAABB4] ml-[0.8px] text-white"
                            >
                                {frequency}
                            </span>
                        ) : null}
                    </Typography>
                }
                variant="outlined"
                size="small"
            />
        </div>
    );
};

export default SeverityTag;
