import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Badge, ListItemButton, ListItemIcon, ListItemText, Stack, Menu, Box } from '@mui/material';
import { KeyboardArrowRightRounded } from '@mui/icons-material';
import UserGuideModal from './UserGuideModal';
import { useSelector } from 'react-redux';
import { getData as guides } from './UserGuideModal/utils/constants';

const SidebarListItem = (props) => {
    const theme = useTheme();
    const { active, icon, icon_active, text, link, isNew, disabled, subMenus, title } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [clicked, setClicked] = useState(false);
    // functions

    function handleClose() {
        console.log('click');
        setClicked(false);
        // setAnchorEl(null);
    }
    let extraStyles = {};
    if (active) {
        extraStyles = {
            backgroundColor: theme.palette.primary.new,
            color: theme.palette.white.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.new,
                color: theme.palette.white.main,
            },
        };
    } else {
        extraStyles = {
            backgroundColor: title === text ? theme.palette.primary.new : 'transparent',
            color: theme.palette.white.main,
            border: title === text ? '1px solid #6E8EDF' : '',
            '&:hover': {
                color: theme.palette.white.main,
            },
        };
    }

    const state = useSelector((state) => state?.userGuideReducers) ?? {};
    const { id: guide_id, isActive } = state;

    const modal_data = guides('john').filter((guide) => guide.title.toLowerCase() === text.toLowerCase())?.[0];

    const is_guide = isActive && modal_data?.id === guide_id;

    useEffect(() => {
        if (is_guide) {
            setAnchorEl(document.getElementById(`${text}-tab`));
            if (document.getElementById(`${text}-tab`)) {
                document.getElementById(`${text}-tab`).style.border = '2px solid #6e8edf';
            }
        } else {
            document.getElementById(`${text}-tab`).style.border = 'none';
        }
    }, [is_guide]);

    return (
        <Box
            sx={{
                borderRadius: '4px',
                overflow: 'hidden',
                // ...extraStyles,
            }}
        >
            <ListItemButton
                id={`${text}-tab`}
                sx={{
                    mx: 1,
                    p: 0.8,
                    pl: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: '4px',
                    zIndex: 1300,
                    '&, & *': {
                        transitionProperty: 'color, background-color, border-bottom, fill',
                        transitionDuration: '0.45s',
                    },
                    ...extraStyles,
                }}
                to={link}
                component={Link}
                disabled={disabled}
            >
                <Stack direction="row" alignItems="center">
                    <ListItemIcon className="listItem__icon" sx={{ minWidth: 40 }}>
                        {active ? icon_active : icon}
                    </ListItemIcon>
                    <Badge variant="dot" invisible={!isNew} color="error">
                        <ListItemText primary={text} sx={{ '& span': { fontSize: '13px', fontWeight: 400 } }} />
                    </Badge>
                </Stack>
                {subMenus?.length ? <KeyboardArrowRightRounded /> : null}
            </ListItemButton>

            <Menu
                anchorEl={anchorEl}
                // open={Boolean(anchorEl)}
                open={is_guide}
                onClose={handleClose}
                // onMouseLeave={handleClose}
                anchorOrigin={{
                    vertical: modal_data?.position ? modal_data?.position : 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: modal_data?.position ? modal_data?.position : 'top',
                    horizontal: 'left',
                }}
                sx={{
                    background: 'rgba(0, 0, 0, 0.2)',
                    '& .MuiMenu-paper': {
                        width: 'auto',
                        ml: 0.5,
                        p: 0,
                        backgroundColor: 'transparent !important',
                        boxShadow: 'none !important',
                        '& ul': { p: 0 },
                    },
                    // display: 'flex',
                }}
                MenuListProps={{ onMouseLeave: !clicked ? handleClose : '' }}
            >
                <UserGuideModal
                    corner={modal_data?.position === 'bottom' ? 'mb-3' : 'mt-3'}
                    align={modal_data?.position === 'bottom' ? 'end' : 'start'}
                />
            </Menu>
        </Box>
    );
};

export default SidebarListItem;
