/* eslint-disable no-unused-vars */

import { Button, Dialog, Snackbar, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import SettingsIcon from 'assets/img/settingsIcon_.svg';
import PageHeader from 'components/new_components/PageHeader';
import { Loader2 } from 'lucide-react';
import { useContext, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GetAllMerchantDocuments } from 'store/actions/complianceActions';
import { GetCompanyDetails } from 'store/actions/merchantActions';
import {
    GetAllAssets,
    GetAllCategories,
    GetAllMerchantUsers,
    GetAllThreatCategories,
    GetAllThreats,
    GetAllVulnerabilities,
    GetAssetGroups,
    GetRiskManagementSettings,
    GetRiskSettings,
} from 'store/actions/riskAssessmentActions';
import { GetAllVendors } from 'store/actions/vendorActions';
import https from '../../../utils/https';
import RiskAssessmentOverview from './components/Overview';
import RiskAssessmentRiskRegister from './components/RiskRegister';
import RunRiskDrawer from './components/RiskRegister/runRisk/RunRiskDrawer';
import { RunAssmentAction } from 'store/actions/riskAssessmentActions';
import CustomTooltip from 'components/new_components/CustomTooltip';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import { ArrowLeftOutlined, LeftCircleOutlined, LeftOutlined } from '@ant-design/icons';

const RiskAssessmentRootTabs = {
    overview: 'Overview',
    riskRegister: 'Risk Register',
};

export default function RiskAssessmentRoot() {
    const theme = useTheme();
    const [currentTab, setCurrentTab] = useState(RiskAssessmentRootTabs.overview);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [openTooltip, setTooltipOpen] = useState(true);
    const [runRiskOpen, setRunRiskOpen] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    // redux
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state?.generalReducers?.user_info);
    const get_risk_settings = useSelector((state) => state?.riskAssessmentReducers?.get_risk_settings);
    const run_assessment = useSelector((state) => state?.riskAssessmentReducers?.run_assessment);
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);
    const userguide_info = useSelector((state) => state?.guideReducers?.userguide_info?.[0]?.risk_assessment);
    const all_article_header = useSelector((state) => state?.articlesReducers?.all_article_header);
    const queryClient = useQueryClient();

    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, riskassessment: false });
        setOpenArticle(false);
    };

    useEffect(() => {
        toggleUserGuide.riskassessment ? OpenArticle() : null;
    }, [toggleUserGuide.riskassessment]);

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Risk Management'),
        [all_article_header]
    );

    const currentUserDetails = useQuery({
        queryKey: ['userDetails', userDetails?.id],
        queryFn: () => getUserDetails(userDetails?.id),
    });

    const getMerchantDetails = async () => {
        const response = await dispatch(GetCompanyDetails());
        if (!response?.success) {
            toast.error('Something went wrong');
        }
    };

    const getAllRiskAssessmentThreats = async () => {
        const res = await dispatch(GetAllThreats());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllVendors = async () => {
        const res = await dispatch(GetAllVendors());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllThreatCategories = async () => {
        const res = await dispatch(GetAllThreatCategories());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllMerchantDocuments = async () => {
        const res = await dispatch(GetAllMerchantDocuments());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getRiskManagementSettings = async () => {
        const res = await dispatch(GetRiskManagementSettings());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAssetGroups = async () => {
        const res = await dispatch(GetAssetGroups());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllAssets = async () => {
        const res = await dispatch(GetAllAssets());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllMerchantUsers = async () => {
        const res = await dispatch(GetAllMerchantUsers());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllRiskAssessmentTCategories = async () => {
        const res = await dispatch(GetAllCategories());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllRiskAssessmentVulnerablilties = async () => {
        const res = await dispatch(GetAllVulnerabilities());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        getAllRiskAssessmentThreats();
        getAllRiskAssessmentVulnerablilties();
        getAllRiskAssessmentTCategories();
        getAssetGroups();
        getAllAssets();
        getAllMerchantUsers();
        getAllThreatCategories();
        getRiskManagementSettings();
        getAllMerchantDocuments();
        getAllVendors();
    }, []);

    const setRiskDefaultSettingsMutation = useMutation({
        mutationFn: setRiskDefaultSettings,
        onSuccess: () => {
            setIsSnackbarOpen(true);
            queryClient.invalidateQueries('userDetails');
            getMerchantDetails();
        },
        onError: (error) => {
            toast.error(error?.message);
        },
    });

    useEffect(() => {
        const getRiskSettings = async () => {
            const res = await dispatch(GetRiskSettings());
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getRiskSettings();
    }, []);

    function RenderActiveTab() {
        switch (currentTab) {
            case RiskAssessmentRootTabs.overview:
                return <RiskAssessmentOverview />;
            case RiskAssessmentRootTabs.riskRegister:
                return <RiskAssessmentRiskRegister setCurrentTab_={setCurrentTab} />;
            default:
                return <RiskAssessmentOverview />;
        }
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setIsSnackbarOpen(false);
    }

    function leavePage() {
        history.back();
    }

    useEffect(() => {
        if (run_assessment === true) {
            setRunRiskOpen(true);
            dispatch(RunAssmentAction(false));
        }
    }, []);

    return (
        <>
            <PageHeader browserTitle="Risk Assessment | Smartcomply" />

            <div className="flex items-center justify-between bg-white px-6 border-y border-[#F1F5F9]">
                <div className="flex items-center gap-6">
                    <button
                        className={`relative text-[15px] bg-transparent !p-0 !py-3 before:absolute before:bottom-0 before:left-1/2 before:h-[3px] before:-translate-x-1/2 before:bg-[#202D66] before:transition-all before:duration-500 hover:bg-transparent hover:text-[#202D66] ${
                            currentTab === RiskAssessmentRootTabs.overview
                                ? 'font-semibold text-[#202D66] before:w-full'
                                : 'text-[#64748B] font-medium before:w-0'
                        }`}
                        onClick={() => setCurrentTab(RiskAssessmentRootTabs.overview)}
                    >
                        <span className="capitalize">{RiskAssessmentRootTabs.overview}</span>
                    </button>

                    <button
                        className={`relative text-[15px] bg-transparent !p-0 !py-3 before:absolute before:bottom-0 before:left-1/2 before:h-[3px] before:-translate-x-1/2 before:bg-[#202D66] before:transition-all before:duration-500 hover:bg-transparent hover:text-[#202D66] ${
                            currentTab === RiskAssessmentRootTabs.riskRegister
                                ? 'font-semibold text-[#202D66] before:w-full'
                                : 'text-[#64748B] font-medium before:w-0'
                        }`}
                        onClick={() => setCurrentTab(RiskAssessmentRootTabs.riskRegister)}
                    >
                        <span className="capitalize">{RiskAssessmentRootTabs.riskRegister}</span>
                    </button>
                </div>

                <div className="flex items-center gap-4">
                    {currentTab === RiskAssessmentRootTabs.overview && (
                        <div
                            className="border border-[#E2E8F0] px-2 py-1 rounded-sm flex items-center gap-2 hover:no-underline hover:bg-[#F1F5F9] transition-colors cursor-pointer"
                            onClick={() => setRunRiskOpen(true)}
                        >
                            <img src="/img/risk-assessment/plus-icon.svg" alt="Plus Icon" className="object-contain" />

                            <span className="text-[#202D66] font-medium text-sm">Run assessment</span>
                        </div>
                    )}
                    <CustomTooltip title="Risk management settings" placement="left">
                        <Link to="/risk-assessment/settings">
                            <img src={SettingsIcon} alt="SettingsIcon" />
                        </Link>
                    </CustomTooltip>

                    {get_risk_settings?.length === 0 && (
                        <Tooltip
                            open={openTooltip}
                            placement="bottom-end"
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        p: '0 !important',
                                    },
                                },
                            }}
                            title={
                                <Stack
                                    gap={1}
                                    p={2}
                                    sx={{ background: '#212529', boxShadow: '0px 12px 24px 0px #5050502E' }}
                                >
                                    <Typography sx={{ fontSize: 13, color: 'white', fontWeight: 600 }}>
                                        Risk assessment settings
                                    </Typography>
                                    <Typography sx={{ fontSize: 13, color: 'white' }}>
                                        We recommend you optimize risk assessment by configuring settings beforehand
                                    </Typography>
                                    <Button
                                        size="small"
                                        sx={{
                                            border: `3px solid ${theme.palette.primary[60]}`,
                                            background: theme.palette.primary[40],
                                            borderRadius: 2,
                                            color: 'white !important',
                                            textTransform: 'none',
                                            fontWeight: 'semibold',
                                            width: 'fit-content',
                                            marginLeft: 'auto',
                                            marginRight: 0,
                                        }}
                                        onClick={() => setTooltipOpen(false)}
                                    >
                                        Got it!
                                    </Button>
                                </Stack>
                            }
                            arrow
                        >
                            <Link
                                to="/risk-assessment/settings"
                                className="hover:no-underline hover:scale-90 transition-transform"
                            >
                                <img
                                    src="/img/risk-assessment/settings-icon.svg"
                                    alt="Settings Icon"
                                    className="object-contain"
                                />
                            </Link>
                        </Tooltip>
                    )}
                </div>
            </div>

            <div className="!py-4">
                <RenderActiveTab />
            </div>

            <Dialog
                open={!merchant_info?.risk_assessment}
                PaperProps={{
                    sx: {
                        borderRadius: '8px !important',
                        padding: 0,
                        boxShadow: '0px 16px 24px 0px #00000026',
                        width: '413px',
                    },
                }}
            >
                <ArrowLeftOutlined className="absolute text-lg left-5 top-5 cursor-pointer" onClick={leavePage} />
                <div className="flex flex-col gap-8 !pb-6">
                    <div className="flex flex-col gap-3">
                        <div className="w-full h-[203px]">
                            <img
                                src="/img/risk-assessment/popup-image.svg"
                                alt="Risk Assessment Preview"
                                className="object-cover h-full w-full"
                            />
                        </div>

                        <div className="flex flex-col gap-2 !px-4">
                            <h4 className="text-[#202D66] font-semibold text-lg">Risk assessment</h4>

                            <p className="text-[#475569] font-normal text-[13px] leading-5">
                                Risk assessment helps you in identifying potential problems, figuring out how bad they
                                could be, and deciding what to do about them. <br />
                                <span className="font-semibold text-[#395BA9]">
                                    We recommend you optimize risk assessment by configuring settings beforehand
                                </span>
                            </p>
                        </div>
                    </div>

                    <div className="flex !px-4 items-center gap-2">
                        <button
                            className="text-[#475569] font-medium text-sm bg-[#fff] border !border-[#E2E8F0] rounded-sm !py-2 flex-1 transition-all hover:brightness-95 flex items-center text-center justify-center disabled:opacity-50 disabled:pointer-events-none"
                            disabled={setRiskDefaultSettingsMutation.isLoading || currentUserDetails.isLoading}
                            onClick={() => setRiskDefaultSettingsMutation.mutate(userDetails?.organization?.id)}
                        >
                            {(setRiskDefaultSettingsMutation.isLoading || currentUserDetails.isLoading) && (
                                <Loader2 className="mr-2 animate-spin h-4 w-4" />
                            )}
                            Use default settings
                        </button>

                        <button
                            className="disabled:opacity-50 disabled:pointer-events-none flex-1 flex items-center"
                            disabled={setRiskDefaultSettingsMutation.isLoading || currentUserDetails.isLoading}
                        >
                            <Link
                                to="/risk-assessment/settings"
                                className="text-[#fff] font-semibold text-[13px] bg-[#37A372] !border-2 !border-[#55BE8B] rounded !py-2 transition-all hover:brightness-95 text-center hover:text-[#fff] hover:no-underline flex-1"
                            >
                                Configure risk settings
                            </Link>
                        </button>
                    </div>
                </div>
            </Dialog>

            <Dialog
                open={currentUserDetails.isLoading}
                PaperProps={{
                    sx: {
                        borderRadius: '8px !important',
                        padding: 0,
                        boxShadow: '0px 16px 24px 0px #00000026',
                        width: '413px',
                    },
                }}
            >
                <div className="flex items-center justify-center flex-col gap-2 py-10">
                    <Loader2 className="h-10 w-10 animate-spin" />

                    <p className="text-[#64748B] text-sm">Proccessing...</p>
                </div>
            </Dialog>

            <Snackbar
                open={isSnackbarOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                autoHideDuration={10000}
                onClose={handleSnackbarClose}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        borderRadius: '4px',
                        padding: '0px',
                        minWidth: 'auto !important',
                        boxShadow: '0px 4px 6px 0px #3D6BA31A',
                    },
                    '& .MuiSnackbarContent-message': {
                        padding: '0px',
                    },
                }}
                message={
                    <div className="bg-[#E8FFEE] !py-3 !px-2 flex items-start gap-[15px]">
                        <div className="flex items-start gap-2 max-w-[278px]">
                            <div>
                                <img
                                    src="/img/risk-assessment/check-square-broken.svg"
                                    alt="Checkmark Icon"
                                    className="object-contain scale-110 mt-1"
                                />
                            </div>

                            <p className="text-[#37A372] text-sm font-medium">
                                Risk configuration successfully set as default settings
                            </p>
                        </div>

                        <button className="transition-transform hover:scale-75" onClick={handleSnackbarClose}>
                            <img
                                src="/img/risk-assessment/close-circle.svg"
                                alt="Close Popup Icon"
                                className="object-contain cursor-pointer"
                            />
                        </button>
                    </div>
                }
            />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Risk Management"
                headerId={oneArticle?.id}
                icon={true}
            />
            <RunRiskDrawer
                open={runRiskOpen}
                handleClose={() => setRunRiskOpen(false)}
                setCurrentTab_={setCurrentTab}
            />
        </>
    );
}

async function getUserDetails(userID) {
    const response = await https().get(`/user_mgt/users/${userID}`);
    return response.data;
}

async function setRiskDefaultSettings(organizationID) {
    const response = await https().post('/scans/risk_mgt_settings/', {
        default_settings: true,
        merchant: organizationID,
    });
    return response.data;
}
