export const slides = [
    'Make smart and cost-effective decisions on compliance by simplifying and automating your compliance process with Smartcomply',
    'Your all-in-one compliance solution suite helping you consolidate your compliance process end-to-end at a very affordable rate with monthly spaced payment.',
    'Integrate all your technology stack into Smartcomply and allow the platform continuously monitor your infrastructure and collect evidence for compliance.',
];

export const APP_COOKIES = [
    {
        domain: '__cf_bm',
        description:
            'This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.',
        type: 'Necessary',
    },
    {
        domain: '1P_Jar',
        description: 'Used for google font on the app.',
        type: 'Functional',
    },
    {
        domain: 'Crsftoken',
        description:
            'A CSRF token is a unique, secret, and unpredictable value that is generated by the server-side application and shared with the client.',
        type: 'Necessary',
    },
    {
        domain: 'session_id',
        description:
            "A session ID is a unique number that a Web site's server assigns a specific user for the duration of that user's visit (session)",
        type: 'Necessary',
    },
    {
        domain: '_fbn',
        description:
            'Used by Facebook Analytics to track user interactions and provide insights into website or app usage',
        type: 'Analytics',
    },
];

export const DATA_COLLECTION_LAW = [
    {
        purpose: [
            'For the administration of our business',
            'To help us to develop, improve, customize or restructure our services',
            'To enforce our terms of service and any terms and conditions of any other agreements for our services',
        ],
        lawful_bases: 'Legitimate interest',
    },
    {
        purpose: ['To send marketing or promotional messages to you'],
        lawful_bases: 'Consent',
    },
    {
        purpose: ['To take statistical data for use internally', 'To send you service-related messages'],
        lawful_bases: 'Legitimate interest',
    },
    {
        purpose: ['To secure your data and prevent fraud'],
        lawful_bases: 'Legitimate interest, legal obligation',
    },
    {
        purpose: ['To inform you whenever there are changes to our terms of business or services'],
        lawful_bases: 'Legal obligation',
    },
    {
        purpose: ['To install non-strictly necessary cookies on your device'],
        lawful_bases: 'Consent',
    },
];
