import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { HiOutlineInformationCircle } from 'react-icons/hi';

//translations
import { useTranslation } from 'react-i18next';

const PendingRequestToast = () => {
    const theme = useTheme();

    //translation
    const { t } = useTranslation('common');

    return (
        <Box
            sx={{
                mx: { md: 3 },
                px: 2,
                py: 1,
                border: `0.6px solid ${theme.palette.primary[900]}50`,
                width: 'fit-content',
                borderRadius: 1,
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: theme.palette.gray[40],
                fontSize: 12,
                fontWeight: 400,
            }}
        >
            <HiOutlineInformationCircle style={{ fontSize: 16 }} />
            <Box component="span" sx={{ ml: 1 }}>
                {t('pending')}
                <Box
                    component="a"
                    href="mailto:abiodunadebambo44@gmail.com"
                    sx={{
                        color: 'inherit',
                        fontWeight: 700,
                        textDecoration: 'underline',
                        '&:hover': { color: 'inherit' },
                    }}
                >
                    {t('message')}
                </Box>
            </Box>
        </Box>
    );
};

export default PendingRequestToast;
