import {
    ALL_TRAINING_COURSES,
    ONE_TRAINING_COURSE,
    ALL_TRAINING,
    ONE_TRAINING,
    ONE_TRAINEE,
    ALL_COURSES_VIDEOS,
    ALL_COURSE_DOCUMENTS,
    ALL_TRAINEE_COURSE_DOCUMENTS,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case ALL_TRAINING_COURSES:
            return {
                ...state,
                all_training_courses: action.payload,
            };
        case ONE_TRAINING_COURSE:
            return {
                ...state,
                one_training_courses: action.payload,
            };
        case ALL_TRAINING:
            return {
                ...state,
                all_training: action.payload,
            };
        case ONE_TRAINING:
            return {
                ...state,
                one_training: action.payload,
            };
        case ALL_COURSES_VIDEOS:
            return {
                ...state,
                all_courses_videos: action.payload,
            };
        case ALL_COURSE_DOCUMENTS:
            return {
                ...state,
                all_courses_documents: action.payload,
            };
        case ONE_TRAINEE:
            return {
                ...state,
                one_trainee: action.payload,
            };
        case ALL_TRAINEE_COURSE_DOCUMENTS:
            return {
                ...state,
                all_trainee_course_documents: action.payload,
            };
        case 'CLEAR_DATA':
            return {};
        default:
            return state;
    }
};
