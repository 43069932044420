import { MoreVert } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import SearchIcon from 'assets/img/Searchicon.svg';
import { useSelector } from 'react-redux';
import {
    // filterOptions,
    mitigationStatuses,
    plans,
    residual3x3Risks,
    risk_sources,
} from '../utils/constants';

const RiskAction = (props) => {
    const {
        categoryAnchorEl,
        setCategoryAnchorEl,
        category,
        setCategory,
        setPlanAnchorEl,
        planAnchorEl,
        plan,
        setPlan,
        currentTab,
        setResidualRiskAnchorEl,
        residualRiskAnchorEl,
        setResidualRisk,
        residualRisk,
        mitigationStatus,
        setMitigationStatus,
        mitigationStatusAnchorEl,
        setMitigationStatusAnchorEl,
        frameworks,
        setFrameworks,
        frameworksAnchorEl,
        setFrameworksAnchorEl,
        actionAnchorEl,
        setActionAnchorEl,
        filters,
        setFilters,
        sources,
        setSources,
        sourcesAnchorEl,
        setSourcesAnchorEl,
        assetOwner,
        setAssetOwner,
        assetOwnerAnchorEl,
        setAssetOwnerAnchorEl,
        riskOwner,
        setRiskOwner,
        riskOwnerAnchorEl,
        setRiskOwnerAnchorEl,
        onSearch,
    } = props;

    // redux
    const { risk_assessment_categories, get_asset_owners, get_risk_owners } = useSelector(
        (state) => state.riskAssessmentReducers
    );
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);

    const category_ids = category?.map((category) => category?.id);

    const filtered_frameworks = merchant_info?.paid_standards
        ?.split(',')
        ?.filter(function (item, index, inputArray) {
            return inputArray.indexOf(item) == index;
        })
        ?.filter((standard) => standard !== 'soc2type2' && standard !== 'soc2 type2');
    const filtered_asset_owners = get_asset_owners
        ?.filter((risk) => risk)
        ?.filter(function (item, index, inputArray) {
            return inputArray.indexOf(item) == index;
        });

    const filtered_risk_owners = get_risk_owners
        ?.filter((risk) => risk)
        ?.filter(function (item, index, inputArray) {
            return inputArray.indexOf(item) == index;
        });
    return (
        <div className="flex items-center gap-3 flex-wrap relative">
            {' '}
            <div className="relative">
                <input
                    placeholder="Search..."
                    onChange={onSearch}
                    className="text-gray-400 text-sm font-400 placeholder:text-gray-400 border border-gray-200 py-2.5 rounded-[4px] w-[230px] px-[30px]"
                />
                <img src={SearchIcon} alt="SearchIcon" className="absolute left-2 top-3 w-[17px]" />
            </div>
            <img src="/img/risk-assessment/filter-lines.svg" alt="Filter Icon" className="object-contain" />
            {filters.includes('category') && (
                <div>
                    <button
                        aria-controls={categoryAnchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={categoryAnchorEl ? 'true' : undefined}
                        onClick={(event) => {
                            event.stopPropagation();
                            setCategoryAnchorEl(event.currentTarget);
                        }}
                        className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 transition-colors"
                    >
                        <p className="text-[#64748B] font-medium text-[13px]">
                            Category:{' '}
                            {category?.length === 0
                                ? 'None'
                                : category?.length === risk_assessment_categories?.length
                                ? 'All'
                                : `${category.length} selected`}
                        </p>

                        <img
                            src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                            alt="Arrow Down Icon"
                            className="object-contain"
                        />
                    </button>

                    <Menu
                        anchorEl={categoryAnchorEl}
                        open={Boolean(categoryAnchorEl)}
                        onClose={() => setCategoryAnchorEl(null)}
                        sx={{
                            '& .MuiPaper-root': {
                                borderRadius: '4px',
                                boxShadow:
                                    '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                            },
                            '& .MuiList-root': {
                                padding: 0,
                            },
                        }}
                    >
                        {risk_assessment_categories?.map((data, index) => (
                            <MenuItem
                                key={index}
                                onClick={(event) => {
                                    event.stopPropagation();

                                    if (category_ids?.includes(data?.id)) {
                                        setCategory(category?.filter((item) => item?.id !== data?.id));
                                    } else {
                                        setCategory([...category, data]);
                                    }
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    borderBottom: `1px solid ${
                                        index === risk_assessment_categories?.length - 1 ? 'transparent' : '#F1F5F9'
                                    }`,
                                    padding: '8px 16px',
                                }}
                            >
                                <div className="flex items-center gap-1">
                                    {category_ids.includes(data?.id) ? (
                                        <img
                                            src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    ) : (
                                        <img
                                            src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    )}

                                    <p className="text-[#64748B] font-medium text-sm">{data?.name}</p>
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
            {filters.includes('plan') && (
                <div>
                    <button
                        aria-controls={planAnchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={planAnchorEl ? 'true' : undefined}
                        onClick={(event) => {
                            event.stopPropagation();
                            setPlanAnchorEl(event.currentTarget);
                        }}
                        className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 transition-colors"
                    >
                        <p className="text-[#64748B] font-medium text-[13px]">
                            Plan:{' '}
                            {plan.length === 0
                                ? 'None'
                                : plan.length === plans.length
                                ? 'All'
                                : `${plan.length} selected`}
                        </p>

                        <img
                            src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                            alt="Arrow Down Icon"
                            className="object-contain"
                        />
                    </button>

                    <Menu
                        anchorEl={planAnchorEl}
                        open={Boolean(planAnchorEl)}
                        onClose={() => setPlanAnchorEl(null)}
                        sx={{
                            '& .MuiPaper-root': {
                                borderRadius: '4px',
                                boxShadow:
                                    '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                            },
                            '& .MuiList-root': {
                                padding: 0,
                            },
                        }}
                    >
                        {plans.map((name, index) => (
                            <MenuItem
                                key={index}
                                onClick={(event) => {
                                    event.stopPropagation();

                                    if (plan.includes(name)) {
                                        setPlan(plan.filter((item) => item !== name));
                                    } else {
                                        setPlan([...plan, name]);
                                    }
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    borderBottom: `1px solid ${index === plans.length - 1 ? 'transparent' : '#F1F5F9'}`,
                                    padding: '8px 16px',
                                }}
                            >
                                <div className="flex items-center gap-1">
                                    {plan.includes(name) ? (
                                        <img
                                            src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    ) : (
                                        <img
                                            src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    )}

                                    <p className="text-[#64748B] font-medium text-sm">{name}</p>
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
            <div>
                <button
                    aria-controls={sourcesAnchorEl ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={sourcesAnchorEl ? 'true' : undefined}
                    onClick={(event) => {
                        event.stopPropagation();
                        setSourcesAnchorEl(event.currentTarget);
                    }}
                    className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 transition-colors"
                >
                    <p className="text-[#64748B] font-medium text-[13px]">
                        Risk Source:{' '}
                        {sources.length === 0
                            ? 'None'
                            : sources.length === risk_sources.length
                            ? 'All'
                            : `${sources.length} selected`}
                    </p>

                    <img
                        src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                        alt="Arrow Down Icon"
                        className="object-contain"
                    />
                </button>

                <Menu
                    anchorEl={sourcesAnchorEl}
                    open={Boolean(sourcesAnchorEl)}
                    onClose={() => setSourcesAnchorEl(null)}
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '4px',
                            boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                        },
                        '& .MuiList-root': {
                            padding: 0,
                        },
                    }}
                >
                    {risk_sources.map((name, index) => (
                        <MenuItem
                            key={index}
                            onClick={(event) => {
                                event.stopPropagation();

                                if (sources.includes(name)) {
                                    setSources(sources.filter((item) => item !== name));
                                } else {
                                    setSources([...sources, name]);
                                }
                            }}
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                borderBottom: `1px solid ${index === plans.length - 1 ? 'transparent' : '#F1F5F9'}`,
                                padding: '8px 16px',
                            }}
                        >
                            <div className="flex items-center gap-1">
                                {sources.includes(name) ? (
                                    <img
                                        src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                        alt="Checkbox icon"
                                        className="object-contain"
                                    />
                                ) : (
                                    <img
                                        src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                        alt="Checkbox icon"
                                        className="object-contain"
                                    />
                                )}

                                <p className="text-[#64748B] font-medium text-sm">{name}</p>
                            </div>
                        </MenuItem>
                    ))}
                </Menu>
            </div>
            {filters.includes('mitigation_status') && (
                <div>
                    <button
                        aria-controls={mitigationStatusAnchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={mitigationStatusAnchorEl ? 'true' : undefined}
                        onClick={(event) => {
                            event.stopPropagation();
                            setMitigationStatusAnchorEl(event.currentTarget);
                        }}
                        className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 transition-colors"
                    >
                        <p className="text-[#64748B] font-medium text-[13px]">
                            Treatment Status:{' '}
                            {mitigationStatus.length === 0
                                ? 'None'
                                : mitigationStatus.length === mitigationStatuses?.length
                                ? 'All'
                                : `${mitigationStatus.length} selected`}
                        </p>

                        <img
                            src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                            alt="Arrow Down Icon"
                            className="object-contain"
                        />
                    </button>

                    <Menu
                        anchorEl={mitigationStatusAnchorEl}
                        open={Boolean(mitigationStatusAnchorEl)}
                        onClose={() => setMitigationStatusAnchorEl(null)}
                        sx={{
                            '& .MuiPaper-root': {
                                borderRadius: '4px',
                                boxShadow:
                                    '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                            },
                            '& .MuiList-root': {
                                padding: 0,
                            },
                            height: '200px',
                        }}
                    >
                        {mitigationStatuses.map((name, index) => (
                            <MenuItem
                                key={index}
                                onClick={(event) => {
                                    event.stopPropagation();

                                    if (mitigationStatus.includes(name)) {
                                        setMitigationStatus(mitigationStatus.filter((item) => item !== name));
                                    } else {
                                        setMitigationStatus([...mitigationStatus, name]);
                                    }
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    borderBottom: `1px solid ${
                                        index === mitigationStatuses.length - 1 ? 'transparent' : '#F1F5F9'
                                    }`,
                                    padding: '8px 16px',
                                }}
                            >
                                <div className="flex items-center gap-1">
                                    {mitigationStatus.includes(name) ? (
                                        <img
                                            src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    ) : (
                                        <img
                                            src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    )}

                                    <p className="text-[#64748B] font-medium text-sm">{name?.replace(/_/g, ' ')}</p>
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
            {filters?.includes('framework') && (
                <div>
                    <button
                        aria-controls={frameworksAnchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={frameworksAnchorEl ? 'true' : undefined}
                        onClick={(event) => {
                            event.stopPropagation();
                            setFrameworksAnchorEl(event.currentTarget);
                        }}
                        className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 transition-colors"
                    >
                        <p className="text-[#64748B] font-medium text-[13px]">
                            Linked frameworks:{' '}
                            {frameworks.length === 0
                                ? 'None'
                                : frameworks.length === filtered_frameworks?.length
                                ? 'All'
                                : `${frameworks.length} selected`}
                        </p>

                        <img
                            src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                            alt="Arrow Down Icon"
                            className="object-contain"
                        />
                    </button>

                    <Menu
                        anchorEl={frameworksAnchorEl}
                        open={Boolean(frameworksAnchorEl)}
                        onClose={() => setFrameworksAnchorEl(null)}
                        sx={{
                            '& .MuiPaper-root': {
                                borderRadius: '4px',
                                boxShadow:
                                    '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                            },
                            '& .MuiList-root': {
                                padding: 0,
                            },
                        }}
                        PaperProps={{
                            style: {
                                height: '300px',
                            },
                        }}
                    >
                        {filtered_frameworks.map((name, index) => (
                            <MenuItem
                                key={index}
                                onClick={(event) => {
                                    event.stopPropagation();

                                    if (frameworks.includes(name)) {
                                        setFrameworks(frameworks.filter((item) => item !== name));
                                    } else {
                                        setFrameworks([...frameworks, name]);
                                    }
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    borderBottom: `1px solid ${
                                        index === filtered_frameworks.length - 1 ? 'transparent' : '#F1F5F9'
                                    }`,
                                    padding: '8px 16px',
                                }}
                            >
                                <div className="flex items-center gap-1">
                                    {frameworks.includes(name) ? (
                                        <img
                                            src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    ) : (
                                        <img
                                            src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    )}

                                    <p className="text-[#64748B] font-medium text-sm">{name?.replace(/_/g, ' ')}</p>
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
            {filters?.includes('risk') && currentTab !== 'all' && (
                <div>
                    <button
                        aria-controls={residualRiskAnchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={residualRiskAnchorEl ? 'true' : undefined}
                        onClick={(event) => {
                            event.stopPropagation();
                            setResidualRiskAnchorEl(event.currentTarget);
                        }}
                        className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 transition-colors"
                    >
                        <p className="text-[#64748B] font-medium text-[13px]">
                            Residual risk:{' '}
                            {residualRisk.length === 0
                                ? 'None'
                                : residualRisk.length === residual3x3Risks.length
                                ? 'All'
                                : `${residualRisk.length} selected`}
                        </p>

                        <img
                            src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                            alt="Arrow Down Icon"
                            className="object-contain"
                        />
                    </button>

                    <Menu
                        anchorEl={residualRiskAnchorEl}
                        open={Boolean(residualRiskAnchorEl)}
                        onClose={() => setResidualRiskAnchorEl(null)}
                        sx={{
                            '& .MuiPaper-root': {
                                borderRadius: '4px',
                                boxShadow:
                                    '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                            },
                            '& .MuiList-root': {
                                padding: 0,
                            },
                        }}
                    >
                        {residual3x3Risks.map((name, index) => (
                            <MenuItem
                                key={index}
                                onClick={(event) => {
                                    event.stopPropagation();

                                    if (residualRisk.includes(name)) {
                                        setResidualRisk(residualRisk.filter((item) => item !== name));
                                    } else {
                                        setResidualRisk([...residualRisk, name]);
                                    }
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    borderBottom: `1px solid ${
                                        index === residual3x3Risks.length - 1 ? 'transparent' : '#F1F5F9'
                                    }`,
                                    padding: '8px 16px',
                                }}
                            >
                                <div className="flex items-center gap-1">
                                    {residualRisk.includes(name) ? (
                                        <img
                                            src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    ) : (
                                        <img
                                            src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    )}

                                    <p className="text-[#64748B] font-medium text-sm">{name}</p>
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
            {filters.includes('asset_owner') && (
                <div>
                    <button
                        aria-controls={assetOwnerAnchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={assetOwnerAnchorEl ? 'true' : undefined}
                        onClick={(event) => {
                            event.stopPropagation();
                            setAssetOwnerAnchorEl(event.currentTarget);
                        }}
                        className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 transition-colors"
                    >
                        <p className="text-[#64748B] font-medium text-[13px]">
                            Asset Owner:{' '}
                            {assetOwner?.length === 0
                                ? 'None'
                                : assetOwner?.length === 1
                                ? assetOwner[0]
                                : assetOwner?.length === filtered_asset_owners?.length
                                ? 'All'
                                : `${assetOwner?.length} selected`}
                        </p>

                        <img
                            src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                            alt="Arrow Down Icon"
                            className="object-contain"
                        />
                    </button>

                    <Menu
                        anchorEl={assetOwnerAnchorEl}
                        open={Boolean(assetOwnerAnchorEl)}
                        onClose={() => setAssetOwnerAnchorEl(null)}
                        sx={{
                            '& .MuiPaper-root': {
                                borderRadius: '4px',
                                boxShadow:
                                    '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                            },
                            '& .MuiList-root': {
                                padding: 0,
                            },
                        }}
                    >
                        {filtered_asset_owners?.map((data, index) => (
                            <MenuItem
                                key={index}
                                onClick={(event) => {
                                    event.stopPropagation();

                                    if (assetOwner.includes(data)) {
                                        setAssetOwner(assetOwner.filter((item) => item !== data));
                                    } else {
                                        setAssetOwner([...assetOwner, data]);
                                    }
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    borderBottom: `1px solid ${
                                        index === filtered_asset_owners?.length - 1 ? 'transparent' : '#F1F5F9'
                                    }`,
                                    padding: '8px 16px',
                                }}
                            >
                                <div className="flex items-center gap-1">
                                    {assetOwner?.includes(data) ? (
                                        <img
                                            src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    ) : (
                                        <img
                                            src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    )}

                                    <p className="text-[#64748B] font-medium text-sm">{data}</p>
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
            {filters.includes('mitigation_owner') && (
                <div>
                    <button
                        aria-controls={riskOwnerAnchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={riskOwnerAnchorEl ? 'true' : undefined}
                        onClick={(event) => {
                            event.stopPropagation();
                            setRiskOwnerAnchorEl(event.currentTarget);
                        }}
                        className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 transition-colors"
                    >
                        <p className="text-[#64748B] font-medium text-[13px]">
                            Treatment Owner:{' '}
                            {riskOwner?.length === 0
                                ? 'None'
                                : riskOwner?.length === filtered_risk_owners?.length
                                ? 'All'
                                : `${riskOwner?.length} selected`}
                        </p>

                        <img
                            src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                            alt="Arrow Down Icon"
                            className="object-contain"
                        />
                    </button>

                    <Menu
                        anchorEl={riskOwnerAnchorEl}
                        open={Boolean(riskOwnerAnchorEl)}
                        onClose={() => setRiskOwnerAnchorEl(null)}
                        sx={{
                            '& .MuiPaper-root': {
                                borderRadius: '4px',
                                boxShadow:
                                    '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                            },
                            '& .MuiList-root': {
                                padding: 0,
                            },
                        }}
                    >
                        {filtered_risk_owners?.map((data, index) => (
                            <MenuItem
                                key={index}
                                onClick={(event) => {
                                    event.stopPropagation();

                                    if (riskOwner.includes(data)) {
                                        setRiskOwner(riskOwner.filter((item) => item !== data));
                                    } else {
                                        setRiskOwner([...riskOwner, data]);
                                    }
                                }}
                                sx={{
                                    color: '#64748B',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    textTransform: 'capitalize',
                                    borderBottom: `1px solid ${
                                        index === filtered_risk_owners?.length - 1 ? 'transparent' : '#F1F5F9'
                                    }`,
                                    padding: '8px 16px',
                                }}
                            >
                                <div className="flex items-center gap-1">
                                    {riskOwner?.includes(data) ? (
                                        <img
                                            src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    ) : (
                                        <img
                                            src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                            alt="Checkbox icon"
                                            className="object-contain"
                                        />
                                    )}

                                    <p className="text-[#64748B] font-medium text-sm">{data}</p>
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
            <div>
                <button
                    aria-controls={actionAnchorEl ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={actionAnchorEl ? 'true' : undefined}
                    onClick={(event) => {
                        event.stopPropagation();
                        setActionAnchorEl(event.currentTarget);
                    }}
                    className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded !py-1.5 !px-2 transition-colors absolute right-0 top-0"
                >
                    <MoreVert />
                </button>
                <Menu
                    anchorEl={actionAnchorEl}
                    open={Boolean(actionAnchorEl)}
                    onClose={() => setActionAnchorEl(null)}
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '4px',
                            boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                        },
                        '& .MuiList-root': {
                            padding: 0,
                        },
                    }}
                >
                    <p className="border-b border-[#F1F5F9] text-[#64748B] text-sm font-normal px-3 py-1">
                        Show Filters:
                    </p>
                    <MenuItem
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '14px',
                            textTransform: 'none',
                            borderBottom: '1px solid #F1F5F9',
                            padding: '8px 16px',
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                            if (filters.includes('category')) {
                                setFilters(filters?.filter((item) => item !== 'category'));
                            } else {
                                setFilters([...filters, 'category']);
                            }
                        }}
                    >
                        <div className="flex items-center gap-1">
                            {filters.includes('category') ? (
                                <img
                                    src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                    alt="Checkbox icon"
                                    className="object-contain"
                                />
                            ) : (
                                <img
                                    src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                    alt="Checkbox icon"
                                    className="object-contain"
                                />
                            )}

                            <p className="text-[#64748B] font-medium text-sm">Category</p>
                        </div>
                    </MenuItem>
                    <MenuItem
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '14px',
                            textTransform: 'none',
                            borderBottom: '1px solid #F1F5F9',
                            padding: '8px 16px',
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                            if (filters.includes('framework')) {
                                setFilters(filters?.filter((item) => item !== 'framework'));
                            } else {
                                setFilters([...filters, 'framework']);
                            }
                        }}
                    >
                        <div className="flex items-center gap-1">
                            {filters.includes('framework') ? (
                                <img
                                    src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                    alt="Checkbox icon"
                                    className="object-contain"
                                />
                            ) : (
                                <img
                                    src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                    alt="Checkbox icon"
                                    className="object-contain"
                                />
                            )}

                            <p className="text-[#64748B] font-medium text-sm">Linked Framework</p>
                        </div>
                    </MenuItem>
                    {currentTab !== 'all' && (
                        <MenuItem
                            sx={{
                                color: '#64748B',
                                fontWeight: 500,
                                fontSize: '14px',
                                textTransform: 'none',
                                borderBottom: '1px solid #F1F5F9',
                                padding: '8px 16px',
                            }}
                            onClick={(event) => {
                                event.stopPropagation();
                                if (filters.includes('risk')) {
                                    setFilters(filters?.filter((item) => item !== 'risk'));
                                } else {
                                    setFilters([...filters, 'risk']);
                                }
                            }}
                        >
                            <div className="flex items-center gap-1">
                                {filters.includes('risk') ? (
                                    <img
                                        src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                        alt="Checkbox icon"
                                        className="object-contain"
                                    />
                                ) : (
                                    <img
                                        src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                        alt="Checkbox icon"
                                        className="object-contain"
                                    />
                                )}

                                <p className="text-[#64748B] font-medium text-sm">Risk</p>
                            </div>
                        </MenuItem>
                    )}
                    <MenuItem
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '14px',
                            textTransform: 'none',
                            borderBottom: '1px solid #F1F5F9',
                            padding: '8px 16px',
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                            if (filters.includes('plan')) {
                                setFilters(filters?.filter((item) => item !== 'plan'));
                            } else {
                                setFilters([...filters, 'plan']);
                            }
                        }}
                    >
                        <div className="flex items-center gap-1">
                            {filters.includes('plan') ? (
                                <img
                                    src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                    alt="Checkbox icon"
                                    className="object-contain"
                                />
                            ) : (
                                <img
                                    src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                    alt="Checkbox icon"
                                    className="object-contain"
                                />
                            )}

                            <p className="text-[#64748B] font-medium text-sm">Plan</p>
                        </div>
                    </MenuItem>
                    <MenuItem
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '14px',
                            textTransform: 'none',
                            borderBottom: '1px solid #F1F5F9',
                            padding: '8px 16px',
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                            if (filters.includes('mitigation_status')) {
                                setFilters(filters?.filter((item) => item !== 'mitigation_status'));
                            } else {
                                setFilters([...filters, 'mitigation_status']);
                            }
                        }}
                    >
                        <div className="flex items-center gap-1">
                            {filters.includes('mitigation_status') ? (
                                <img
                                    src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                    alt="Checkbox icon"
                                    className="object-contain"
                                />
                            ) : (
                                <img
                                    src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                    alt="Checkbox icon"
                                    className="object-contain"
                                />
                            )}

                            <p className="text-[#64748B] font-medium text-sm">Treatment Status</p>
                        </div>
                    </MenuItem>
                    <MenuItem
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '14px',
                            textTransform: 'none',
                            borderBottom: '1px solid #F1F5F9',
                            padding: '8px 16px',
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                            if (filters.includes('mitigation_owner')) {
                                setFilters(filters?.filter((item) => item !== 'mitigation_owner'));
                            } else {
                                setFilters([...filters, 'mitigation_owner']);
                            }
                        }}
                    >
                        <div className="flex items-center gap-1">
                            {filters.includes('mitigation_owner') ? (
                                <img
                                    src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                    alt="Checkbox icon"
                                    className="object-contain"
                                />
                            ) : (
                                <img
                                    src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                    alt="Checkbox icon"
                                    className="object-contain"
                                />
                            )}

                            <p className="text-[#64748B] font-medium text-sm">Treatment Owner</p>
                        </div>
                    </MenuItem>
                    <MenuItem
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '14px',
                            textTransform: 'none',
                            borderBottom: '1px solid #F1F5F9',
                            padding: '8px 16px',
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                            if (filters.includes('asset_owner')) {
                                setFilters(filters?.filter((item) => item !== 'asset_owner'));
                            } else {
                                setFilters([...filters, 'asset_owner']);
                            }
                        }}
                    >
                        <div className="flex items-center gap-1">
                            {filters.includes('asset_owner') ? (
                                <img
                                    src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                    alt="Checkbox icon"
                                    className="object-contain"
                                />
                            ) : (
                                <img
                                    src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                    alt="Checkbox icon"
                                    className="object-contain"
                                />
                            )}

                            <p className="text-[#64748B] font-medium text-sm">Asset Owner</p>
                        </div>
                    </MenuItem>
                    <MenuItem
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '14px',
                            textTransform: 'none',
                            borderBottom: '1px solid #F1F5F9',
                            padding: '8px 16px',
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                            if (filters.includes('source')) {
                                setFilters(filters?.filter((item) => item !== 'source'));
                            } else {
                                setFilters([...filters, 'source']);
                            }
                        }}
                    >
                        <div className="flex items-center gap-1">
                            {filters.includes('source') ? (
                                <img
                                    src="/img/risk-assessment/checkbox-buuton-checked.svg"
                                    alt="Checkbox icon"
                                    className="object-contain"
                                />
                            ) : (
                                <img
                                    src="/img/risk-assessment/checkbox-buuton-unchecked.svg"
                                    alt="Checkbox icon"
                                    className="object-contain"
                                />
                            )}

                            <p className="text-[#64748B] font-medium text-sm">Risk Source</p>
                        </div>
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
};

export default RiskAction;
