import { validation } from 'validate';
export const userValidation = (values) => {
    const errors = {};
    const firstName = validation(values.first_name, 'First name', true);
    const lastName = validation(values.last_name, 'Last name', true);
    const title = validation(values.title, 'title', true);

    if (!firstName.isValid) {
        errors.first_name = firstName.errorMessage;
    }
    if (!lastName.isValid) {
        errors.last_name = lastName.errorMessage;
    }
    if (!title.isValid) {
        errors.title = title.errorMessage;
    }
    console.log(errors);
    return errors;
};
export const passwordValidation = (values) => {
    const errors = {};
    const oldPass = validation(values.old_password, 'Password', true);
    const newPass = validation(values.new_password, 'New password', true);
    const confirmPass = validation(values.confirm_password, 'Confirm password', true);

    if (!oldPass.isValid) {
        errors.old_password = oldPass.errorMessage;
    }
    if (!newPass.isValid) {
        errors.new_password = newPass.errorMessage;
    }
    if (!confirmPass.isValid) {
        errors.confirm_password = confirmPass.errorMessage;
    }
    console.log(errors);
    return errors;
};
