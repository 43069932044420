import { useContext, useEffect, useMemo, useState } from 'react';
import Layout from './Layout';
import { Box } from '@mui/material';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import Overview from './Overview';
import SettingsModal from './components/SettingsModal';
import FraudRegister from './FraudRegister/FraudRegister';
import { connect, useDispatch, useSelector } from 'react-redux';
import { GetAllApprovedUser } from 'store/actions/FraudGauardianActions';
import { toast } from 'react-toastify';
import { initialValues } from './utils/constants';
import { GetFraudMonitoring } from 'store/actions/FraudGauardianActions';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';

const index = (props) => {
    const { all_article_header } = props;
    const [currentTab, setCurrentTab] = useState(0);
    const [openSettings, setSettings] = useState(false);
    const [transaction_status, setTransaction_Status] = useState('');
    const [status, setStatus] = useState('');
    const [payload, setPayload] = useState(initialValues);
    const [loading, setLoading] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    const all_approved_user = useSelector((state) => state?.fraudGuardianReducers?.all_approved_user);
    const { toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const userguide_info = useSelector((state) => state?.guideReducers?.userguide_info?.[0]?.fraud_guardian);

    const dispatch = useDispatch();

    // memo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Transaction Fraud Monitoring'),
        [all_article_header]
    );

    // logic functions
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };

    const handleOpenSettings = () => {
        setSettings(true);
    };

    const handleCloseSettings = () => {
        setSettings(false);
    };

    const getApprovedUser = async () => {
        const res = await dispatch(GetAllApprovedUser());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, fraud_guardian: false });
        setOpenArticle(false);
    };

    const query_params = useMemo(() => {
        const params = {
            ...(transaction_status.length && {
                transaction_status: transaction_status === 'All' ? '' : transaction_status,
            }),
            ...(status.length && { status: status === 'All' ? '' : status }),
        };
        return params;
    }, [transaction_status, status]);

    useEffect(() => {
        const getFraudMonitoring = async () => {
            setLoading(true);
            const res = await dispatch(GetFraudMonitoring(query_params));
            setLoading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getFraudMonitoring();
    }, [currentTab, query_params]);
    useEffect(() => {
        getApprovedUser();
    }, [currentTab]);
    useEffect(() => {
        toggleUserGuide.fraud_guardian ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.fraud_guardian]);

    return (
        <Box sx={{ px: { xs: 0, md: 0.5 }, py: { xs: 0, md: 3 } }}>
            <Layout
                onTabChange={handleTabChange}
                tab={currentTab}
                handleOpenSettings={handleOpenSettings}
                all_approved_user={all_approved_user}
            >
                <AppTabPanel value={currentTab} index={0}>
                    <Overview />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={1}>
                    <FraudRegister
                        match_status={transaction_status}
                        setMatch_Status={setTransaction_Status}
                        entity_type={status}
                        setEntityType={setStatus}
                        loading={loading}
                    />
                </AppTabPanel>
            </Layout>

            <SettingsModal
                open={openSettings}
                handleClose={handleCloseSettings}
                all_approved_user={all_approved_user}
                payload={payload}
                setPayload={setPayload}
            />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Fraud Monitoring"
                headerId={oneArticle?.id}
                icon={true}
            />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};

export default connect(mapStateToProps, {})(index);
