import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Requests from './Requests';
import Preview from '../AuditPreview/index';
const Request = () => {
    return (
        <Switch>
            <Route path="/admin/audit-requests" exact component={Requests} />
            <Route path="/admin/audit-requests/:id" component={Preview} />
        </Switch>
    );
};

export default Request;
