import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Button } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//Redux
import { connect } from 'react-redux';
import { EditRelease } from 'store/actions/adminActions';

const EditModal = (props) => {
    const { open, handleClose, EditRelease, record } = props;

    const [loading, setloading] = useState(false);
    const [details, setDetails] = useState(null);

    const onClose = () => {
        // setData({});
        handleClose();
    };

    useEffect(() => {
        if (record) {
            setDetails(record?.details);
        }
    }, [record]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setloading(true);
        const payload = {
            details: details,
        };

        const res = await EditRelease(payload, record?.id);
        setloading(false);
        if (res?.success) {
            toast.success('Threat Added Successfully!');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <Modal title="Edit Release Note" open={open} onCancel={handleClose} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <CKEditor
                            editor={ClassicEditor}
                            data={details}
                            onReady={(editor) => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                // console.log( { event, editor, data } );

                                setDetails(data);
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                        />
                    </Col>
                    <Col style={{ marginTop: '5rem' }}>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { EditRelease })(EditModal);
