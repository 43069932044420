import { validation } from 'validate';

export const kyc_searchValidation = (values) => {
    const errors = {};
    const nameValidate = validation(values?.entity_name, 'Entity Name', true);
    const typeValidate = validation(values?.entity_type, 'Entity Type', true);
    const fuzziness_levelValidate = validation(values?.fuzziness_level, 'Fuzziness', true);

    if (!nameValidate.isValid) {
        errors.entity_name = nameValidate.errorMessage;
    }
    if (!typeValidate.isValid) {
        errors.entity_type = typeValidate.errorMessage;
    }
    if (!fuzziness_levelValidate.isValid) {
        errors.fuzziness_level = fuzziness_levelValidate.errorMessage;
    }
    return errors;
};

export const settingsValidation = (values) => {
    const errors = {};
    const thresholdValidate = validation(values?.threshold, 'Threshold', true);
    const limitValidate = validation(values?.limit, 'Limit', true);

    if (!thresholdValidate.isValid) {
        errors.threshold = thresholdValidate.errorMessage;
    }

    if (!limitValidate.isValid) {
        errors.limit = limitValidate.errorMessage;
    }

    return errors;
};
