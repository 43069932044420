import React, { useState } from 'react';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { IconButton, Typography, Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { AppForm, AppSubmitButton } from 'components/new_components/forms';
import Cloud from 'assets/img/cloud.svg';
import { accept } from 'validate';
import { CreateScanException } from 'store/actions/automatedScansActions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { AppFormInput } from 'components/new_components/forms';
import { exemptionModalValidation } from '../../utils/validation';
import Pdf from 'assets/img/pngfile.png';

const ExemptScanModal = (props) => {
    const { pdf, docx } = accept;
    const { open, onClose, scan, sub, CreateScanException } = props;

    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState('');
    const [img, setImg] = useState('');

    // / UPLOAD IMAGE ONCHANGE
    const uploadImage = async (event) => {
        const input = event.target;
        const file = input.files?.item(0);
        const base64 = await convertedBase64(file);
        setFile(file);
        setImg(base64);
    };
    // CONVERT IMAGE TO BASE 64
    const convertedBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const filReader = new FileReader();
            filReader.readAsDataURL(file);
            filReader.onload = () => {
                resolve(filReader.result);
            };
            filReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const dragEnter = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#C4C4C4';
        target.style.backgroundColor = '#A58980';
    };

    const dragLeave = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#A58980';
        target.style.backgroundColor = 'transparent';
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const dragDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type.includes('image')) {
            const reader = new FileReader();
            // reader.onload = () => {
            //     setImg(reader.result);
            // };
            reader.readAsDataURL(file);
            convertedBase64(file).then(() => {});
        } else {
            // enqueueSnackbar('Invalid Data Dropped', { variant: 'error' });
        }
        dragLeave();
    };

    const handleSubmit = async (values) => {
        const formdata = new FormData();
        formdata.append('reason', values?.reason);
        formdata.append('scan_name', scan?.scan_name);
        formdata.append('file', file);
        formdata.append('userintegration', scan?.userintegration);
        const payload = {
            reason: values?.reason,
            scan_name: scan?.scan_name,
            userintegration: scan?.userintegration,
        };
        const body = file !== '' ? formdata : payload;
        setLoading(true);
        const res = await CreateScanException(body, file, sub?.id, scan?.account_name);
        setLoading(false);
        if (res?.success) {
            toast.success('Scan successfully exempted');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <AppForm
            initialValues={{
                reason: '',
            }}
            validate={exemptionModalValidation}
            onSubmit={handleSubmit}
        >
            <AppCenteredModal
                open={open}
                handleClose={onClose}
                title={
                    <Typography sx={{ fontSize: '18px', fontWeight: 700, color: theme.palette.gray[30] }}>
                        Reason for exempting
                    </Typography>
                }
                headerAction={
                    <IconButton color="disabled" onClick={onClose}>
                        <Close />
                    </IconButton>
                }
                titleStyle={{ borderBottom: `1px solid ${theme.palette.neutral[90]}` }}
                width={540}
                noBorder
            >
                <Box sx={{ mb: 3 }}>
                    <AppFormInput
                        type="text"
                        name="reason"
                        placeholder={'Brief reason for exempting this scan'}
                        multiline
                        rows={3}
                    />
                    <Box sx={{ marginTop: '2rem' }}>
                        <Typography sx={{ color: '77777A', fontSize: '12px', fontWeight: 500 }}>
                            Upload a document to backup your reason (Optional)
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                border: '0.7px solid #C7C6CA',
                                borderRadius: '4px',
                                width: '100%',
                                height: '90px',
                            }}
                            id="drop-zone"
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={dragDrop}
                            onDragOver={dragOver}
                        >
                            <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
                                <img src={Cloud} alt={'Cloud'} />
                            </label>
                            <input
                                type="file"
                                accept={(pdf, docx)}
                                onChange={(e) => uploadImage(e)}
                                hidden
                                id="file-input"
                            />
                            <Typography
                                sx={{ color: '#303034', fontSize: '12px', fontWeight: 600, marginTop: '0.5rem' }}
                            >
                                <strong>Click to upload</strong>{' '}
                                <span style={{ color: '#77777A', fontWeight: 400 }}>or drag and drop a file</span>
                            </Typography>
                            <Typography sx={{ color: '#ACAAAF', fontSize: '10px', fontWeight: 600 }}>
                                if any PDF
                            </Typography>
                        </Box>
                    </Box>
                    {img !== '' ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '1rem',
                            }}
                        >
                            <Box sx={{ display: 'flex' }}>
                                <img
                                    src={
                                        file?.type === 'application/pdf'
                                            ? Pdf
                                            : file?.type === 'application/png'
                                            ? img
                                            : file?.type === 'application/jpeg'
                                            ? img
                                            : null
                                    }
                                    alt="img"
                                />
                                <Typography sx={{ marginLeft: '1rem', color: '#5E5E62', fontSize: '11px' }}>
                                    {file.name}
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        ''
                    )}
                    <AppSubmitButton
                        text={'Exempt Scan'}
                        variant="contained"
                        color="primary"
                        loading={loading}
                        loadingPosition="center"
                        sx={{ mt: 5, width: '100%' }}
                    />
                </Box>
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        all_automated_scan_groups: state?.automatedScansReducers?.all_automated_scan_group,
    };
};

export default connect(mapStateToProps, { CreateScanException })(ExemptScanModal);
