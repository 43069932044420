import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';

const StatusTag = (props) => {
    const theme = useTheme();

    const { text, type = 'default' } = props;

    const getTagBackgroundColor = () => {
        return type === 'success' ? '#F4FFF5' : type === 'default' ? theme.palette.white.main : '';
    };

    const getBorderColor = () => {
        return type === 'success' ? '#37A372' : type === 'default' ? '#E1E2EC' : '';
    };

    const getTextColor = () => {
        return type === 'success' ? '#0B885A' : type === 'default' ? theme.palette.gray[40] : '';
    };
    return (
        <Box
            component="span"
            sx={{
                color: getTextColor(),
                background: getTagBackgroundColor(),
                textTransform: 'capitalize',
                fontWeight: 500,
                padding: '8px 16px',
                border: `1px solid ${getBorderColor()}`,
                borderRadius: '4px',
                width: 'fit-content',
                fontSize: 12,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                gap: '4px',
            }}
        >
            {text?.includes('running') ? (
                <CircularProgress
                    size={15}
                    color="secondary"
                    sx={{
                        '&.MuiCircularProgress-colorSecondary': {
                            color: theme.palette.gray[40],
                        },
                    }}
                />
            ) : null}
            {text}
        </Box>
    );
};

export default StatusTag;
