import React from 'react';
import SuccessIcon from 'assets/img/Success.svg';

//translation
import { useTranslation } from 'react-i18next';

const Success = () => {
    //translation
    const { t } = useTranslation('vendorManagement');

    return (
        <div className="w-full h-screen flex flex-col justify-center items-center ">
            <div
                className="flex flex-col  items-center w-[90%] pt-8 lg:h-[60%] rounded-[4px]"
                style={{ boxShadow: '4px 0px 32px 0px rgba(14, 44, 102, 0.05)' }}
            >
                <img src={SuccessIcon} alt="success icon" width={144} height={144} />
                <div className="my-5">
                    <h4 className="textbrandColor text-xl text-2xl font-bold">{t('vendorQuestionnaire.thankYou')}</h4>
                    <p className="text-center textbrandColor text-[13px] mt-3 font-medium">
                        {t('vendorQuestionnaire.response')}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Success;
