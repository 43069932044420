import React, { useEffect, useMemo, useState } from 'react';
// import { toast } from 'react-toastify';

// core components
import SubgroupView from '../../common/SubgroupView';
import SubgroupTabs from '../../common/SubgroupTabs';
import DocumentItem from './DocumentItem';
import QuestionItem from './QuestionItem';
import ConditionalItem from './ConditionalItem';
import BackgroundCheckItem from './BackgroundCheckItem';
import RiskAssessmentItem from './RiskAssessmentItem';
import AntiVirusItem from './AntiVirusItem';
import ConfigurationManagementItem from './ConfigManagementItem';
import DocumentationLifeCycleItem from './DocumentationLifeCycleItem';
import ServerConfigurationItem from './ServerConfigurationItem';
import IdentityManagementItem from './IdentityManagementItem';
import CodeRepositoryItem from './CodeRepositoryItem';
import SystemManagementItem from './SystemManagementItem';
import VulnerabilityManagementItem from './VulnerabilityManagemnetItem';
import DataManagementItem from './DataManagementItem';
import NetworkOperationsItem from './NetworkOperationsItem';
import LoadingState from 'components/new_components/LoadingState';
import EmptyState from 'components/new_components/EmptyState';

// Redux
import { connect } from 'react-redux';

//translation
import { useTranslation } from 'react-i18next';

const SubCriterias = (props) => {
    const { goBack, exception, loading, all_blockchain_exception, getAllExemptedBlockchain } = props;

    const [page, setPage] = useState(1);
    const [selectedSubExceptionNo, setselectedSubExceptionNo] = useState(null);

    //translation
    const { t } = useTranslation('compliance');

    // get the selected Criteria obj from the criteria ID
    const selectedException = useMemo(
        () => all_blockchain_exception?.find((exc) => exc?.id === exception),
        [exception, all_blockchain_exception]
    );
    const selectedCategory = all_blockchain_exception?.find((data) => data.id === exception);

    // get the subquirements for the selected requirement
    const subExceptions = useMemo(() => {
        return selectedException?.subcriterias?.length
            ? selectedException?.subcriterias
                  ?.sort((a, b) => a?.id - b?.id)
                  ?.map((subException) => ({
                      id: subException?.id,
                      exceptions: subException?.exceptions,
                      title: subException?.name,
                      description: subException?.description,
                  }))
            : [];
    }, [selectedException]);

    // when the sub group is changed set the page to 1 (first stage)
    const handleSubgroupChange = (id) => {
        setselectedSubExceptionNo(id);
        goToNextResource(1);
    };

    // // useEffect
    useEffect(() => {
        if (selectedSubExceptionNo === null) {
            const activeException = subExceptions?.find((sub) => sub?.id);
            if (activeException) {
                setselectedSubExceptionNo(activeException?.id);
            }
        }
    }, [selectedSubExceptionNo, subExceptions]);

    const selectedSubException = subExceptions?.find((data) => data.id === selectedSubExceptionNo);
    const filteredSelectedSubExceptions = selectedSubException?.exceptions?.filter(
        (exc) =>
            exc?.conditional_detail !== null ||
            exc?.document_detail !== null ||
            exc?.form_detail !== null ||
            exc?.tool !== null
    );

    const selectedResource = useMemo(() => {
        return filteredSelectedSubExceptions?.[page - 1];
    }, [selectedSubException?.exceptions, page]);

    // // functions
    const goToNextResource = (page) => {
        if (page <= filteredSelectedSubExceptions?.length) {
            setPage(page);
        }
    };
    const onResourceChange = () => {
        if (page > 1) {
            setPage(page - 1);
        } else {
            setPage(1);
        }
    };

    const field = {
        id: 1,
        name: selectedResource?.conditional_detail?.question,
        options: 'Yes, No',
        type: 'dropdown',
        required: true,
    };
    const comment = {
        id: 4,
        name: selectedResource?.conditional_detail?.no_label,
        options: null,
        type: 'textarea',
        required: true,
    };
    useEffect(() => {
        if (selectedResource === undefined) {
            const findException = subExceptions?.find((item) => item);
            const activeException = findException?.exceptions?.find((sub) => sub);
            setselectedSubExceptionNo(activeException?.subcriteria);
        }
    }, [selectedResource]);
    return (
        <SubgroupView
            goBack={goBack}
            title={selectedCategory?.name}
            data={filteredSelectedSubExceptions}
            currentResourcePage={page}
            goToNextResource={goToNextResource}
            selectedSubgroup={selectedSubExceptionNo}
        >
            <SubgroupTabs
                subgroups={subExceptions}
                goToNextResource={goToNextResource}
                onSubgroupChange={handleSubgroupChange}
                selectedSubgroup={selectedSubExceptionNo}
                exception
                selectedSubCriteria={selectedSubException}
                setselectedSubExceptionNo={setselectedSubExceptionNo}
            />
            {loading.content ? (
                <LoadingState />
            ) : (
                <>
                    {selectedResource?.document_detail ? (
                        <DocumentItem
                            title={selectedResource?.document_detail?.criteria_title}
                            description={selectedSubException?.description}
                            document={selectedResource}
                            standard="blockchain"
                            selectedSubCriteria={selectedSubException}
                            exceptionId={selectedResource?.id}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                            onResourceChange={onResourceChange}
                            exception
                        />
                    ) : selectedResource?.form_detail ? (
                        <QuestionItem
                            form={selectedResource}
                            title="title"
                            standard="blockchain"
                            selectedSubCriteria={selectedSubException}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                            exceptionId={selectedResource?.id}
                            onResourceChange={onResourceChange}
                            exception
                        />
                    ) : selectedResource?.conditional_detail ? (
                        <ConditionalItem
                            form={selectedResource}
                            comment={comment}
                            field={field}
                            standard="blockchain"
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                            exception
                            selectedResource={selectedResource}
                            exceptionId={selectedResource?.id}
                        />
                    ) : selectedResource?.tool_detail?.category === 'risk assessment' ? (
                        <RiskAssessmentItem
                            risk_assessment={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                            exception
                        />
                    ) : selectedResource?.tool_detail?.category === 'antivirus' ? (
                        <AntiVirusItem
                            antivirus={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                            exception
                        />
                    ) : selectedResource?.tool_detail?.category === 'configuration management' ? (
                        <ConfigurationManagementItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                            exception
                        />
                    ) : selectedResource?.tool_detail?.category === 'identity and access management' ? (
                        <IdentityManagementItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                            exception
                        />
                    ) : selectedResource?.tool_detail?.category === 'systems and security monitoring' ? (
                        <SystemManagementItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                            exception
                        />
                    ) : selectedResource?.tool_detail?.category === 'code repository' ? (
                        <CodeRepositoryItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                            exception
                        />
                    ) : selectedResource?.tool_detail?.category === 'documentation lifecycle' ? (
                        <DocumentationLifeCycleItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                            exception
                        />
                    ) : selectedResource?.tool_detail?.category === 'server configuration' ? (
                        <ServerConfigurationItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                            exception
                        />
                    ) : selectedResource?.tool_detail?.category === 'background check' ? (
                        <BackgroundCheckItem
                            background_checks={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                            exception
                        />
                    ) : selectedResource?.tool_detail?.category === 'vulnerability management' ? (
                        <VulnerabilityManagementItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                            exception
                        />
                    ) : selectedResource?.tool_detail?.category === 'data management' ? (
                        <DataManagementItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                            exception
                        />
                    ) : selectedResource?.tool_detail?.category === 'network operations' ? (
                        <NetworkOperationsItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                            exception
                        />
                    ) : (
                        <EmptyState description={t('blockchain.noSubCriteria')} />
                    )}
                </>
            )}
        </SubgroupView>
    );
};

const mapStateToProps = (state) => {
    return {
        all_criteria_summary: state?.complianceReducers?.all_criteria_summary,
    };
};
export default connect(mapStateToProps, {})(SubCriterias);
