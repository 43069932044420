import { Grid } from '@mui/material';
import EmptyState from 'components/new_components/EmptyState';
import LoadingState from 'components/new_components/LoadingState';
import { useMemo } from 'react';
import { connect } from 'react-redux';
import CategoryCard from '../../common/CategoryCard';

//translations
import { useTranslation } from 'react-i18next';

const Categories = (props) => {
    const {
        resource,
        loading,
        onViewChange,
        all_categories,
        all_categories_metrics,
        all_compliance_assigned_tasks,
        openTaskAssignModal,
        all_gdpa_documents,
        all_gdpa_forms,
    } = props;

    //translation
    const { t } = useTranslation('compliance');

    const documentsByCategory = useMemo(() => {
        return all_gdpa_documents
            ?.filter((document) => {
                const gdpaDocuments = all_compliance_assigned_tasks?.documents?.forms?.gdpa || [];

                const res = gdpaDocuments?.length ? gdpaDocuments.filter((item) => document?.id === item) : [];

                return res?.length ? document : null;
            })
            .map((document) => document?.category);
    }, [all_gdpa_documents, all_compliance_assigned_tasks?.documents?.forms?.gdpa]);

    const formsByCategory = useMemo(() => {
        return all_gdpa_forms
            ?.filter((form) => {
                const gdpaForms = all_compliance_assigned_tasks?.questions?.forms?.gdpa || [];

                const res = gdpaForms?.length ? gdpaForms.filter((item) => form?.id === item) : [];

                return res?.length ? form : null;
            })
            .map((form) => form?.category);
    }, [all_gdpa_forms, all_compliance_assigned_tasks?.documents?.forms?.gdpa]);

    return !loading ? (
        <Grid container spacing={2.5} sx={{ p: 4, pt: 6 }}>
            {all_categories && all_categories?.length ? (
                all_categories
                    ?.sort((a, b) => a?.id - b?.id)
                    ?.map((category) => {
                        {
                            /* get and descructure the categories metrics */
                        }
                        // const { total_form, total_doc, user_form, user_doc } =
                        //   all_categories_metrics?.[category?.title];

                        {
                            /* format metric and label based on resource type */
                        }
                        const metric =
                            resource === 'doc'
                                ? {
                                      totalValue: all_categories_metrics?.[category?.title]?.total_doc,
                                      totalAttendedTo: all_categories_metrics?.[category?.title]?.user_doc,
                                  }
                                : {
                                      totalValue: all_categories_metrics?.[category?.title]?.total_form,
                                      totalAttendedTo: all_categories_metrics?.[category?.title]?.user_form,
                                  };
                        const resourceLabel = resource === 'doc' ? t('fileUploaded') : t('questionAnswered');
                        const isCategoryAssigned =
                            resource === 'doc'
                                ? all_compliance_assigned_tasks?.documents?.category?.gdpa?.includes(category?.id)
                                : all_compliance_assigned_tasks?.questions?.category?.gdpa?.includes(category?.id);

                        return (
                            <CategoryCard
                                key={category?.id}
                                id={category?.id}
                                title={category?.title}
                                resourceLabel={resourceLabel}
                                metric={metric}
                                onViewChange={onViewChange}
                                standard="gdpa"
                                openTaskAssignModal={() =>
                                    openTaskAssignModal('category', {
                                        gdpa_category: category?.id,
                                        is_document: resourceLabel !== 'Questions Answered',
                                        standard: 'gdpa',
                                    })
                                }
                                isCategoryAssigned={isCategoryAssigned}
                                resourceAssigned={resource === 'doc' ? documentsByCategory : formsByCategory}
                            />
                        );
                    })
            ) : (
                <EmptyState description={t('noCategory')} />
            )}
        </Grid>
    ) : (
        <LoadingState />
    );
};
const mapStateToProps = (state) => {
    return {
        all_categories: state?.adminReducers?.all_gdpa_categories,
        all_categories_metrics: state?.complianceReducers?.all_gdpa_categories_metrics,
        all_gdpa_forms: state?.adminReducers?.all_forms_by_tag,
        all_gdpa_documents: state?.adminReducers?.all_documents_by_tags,
    };
};
export default connect(mapStateToProps, {})(Categories);
