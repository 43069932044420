import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import { PersonAddAlt1 } from '@mui/icons-material';
import { FaUserEdit } from 'react-icons/fa';

//core components
import { AppFormInput, AppFormSelect, AppSubmitButton, AppForm, AppFormContext } from 'components/new_components/forms';
import AppCenteredModal from 'components/new_components/AppCenteredModal';

// others
import { userValidate } from './utils/validation';
import { getAvailableUserRoles } from './utils/constants';

// redux
import { connect } from 'react-redux';
import { AddMerchantUser, UpdateMerchantSupportUser, UpdateMerchantUser } from 'store/actions/merchantActions';
import { GetAllSupportUsers } from 'store/actions/adminActions';
import { useTranslation } from 'react-i18next';

const UserModal = (props) => {
    const {
        open,
        handleClose,
        payload,
        isAddModal,
        AddMerchantUser,
        GetAllSupportUsers,
        all_support_users,
        UpdateMerchantSupportUser,
        merchant_standards,
        UpdateMerchantUser,
        hasSupport,
    } = props;
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({});
    // const [routeList, setRouteList] = useState([]);
    const [supportUsers, setSupportUsers] = useState([]);
    const [standards, setStandards] = useState([]);

    //translation
    const { t } = useTranslation('user');

    // functions
    // logic functions
    const getCurrentValues = (values) => setValues(values);
    const handleSubmit = (values) => {
        const payload = getPayload(values);
        if (isAddModal) {
            handleUserAdd(payload, values.role);
        } else {
            handleUserEdit(payload, values.role, values.id);
        }
    };
    const getPayload = (user) => {
        const { email, role, auditor_standards, support_user, routes } = user;
        let payload = {};
        if (user.role !== 'support') {
            payload.email = email;
            payload.role = role;
            if (user.role === 'auditor') {
                payload.auditor_standards = auditor_standards?.join(',');
            }
        } else {
            payload.support_user = support_user;
            payload.support_routes = routes.join(',');
        }
        return payload;
    };

    // async functions
    const handleUserAdd = async (user, role) => {
        setLoading(true);
        const res = role === 'support' ? await UpdateMerchantSupportUser(user) : await AddMerchantUser(user);
        setLoading(false);
        if (res?.success) {
            if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                toast.success('User added successfully.');
            }
            handleClose();
        } else {
            if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                toast.error(res?.message);
            }
        }
    };
    const handleUserEdit = async (user, role, id) => {
        setLoading(true);
        const res = role === 'support' ? await UpdateMerchantSupportUser(user) : await UpdateMerchantUser(user, id);
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    // useEffect
    // useEffect(() => {
    //     const routeList = [].merchant.flatMap((route) => {
    //         if (route.subMenus) {
    //             return route.subMenus?.map((submenu) => ({
    //                 value: submenu.path,
    //                 name: `${route.name} - ${submenu.name}`,
    //             }));
    //         } else {
    //             return { name: route.name, value: route.path };
    //         }
    //     });
    //     setRouteList(routeList);
    // }, []);
    useEffect(() => {
        if (open) {
            GetAllSupportUsers();
        }
    }, [open]);
    useEffect(() => {
        const supportUsers = all_support_users?.map((user) => {
            return {
                value: user.id,
                name: `${user?.user_detail?.first_name} ${user?.user_detail?.last_name}`,
            };
        });
        setSupportUsers(supportUsers);
    }, [all_support_users]);
    useEffect(() => {
        let uniqueStandards = [...new Set(merchant_standards?.toLowerCase()?.split(','))];

        setStandards(
            uniqueStandards?.map((standard) => ({
                value: standard,
                name: standard?.toUpperCase(),
            }))
        );
    }, [merchant_standards]);

    return (
        <AppForm initialValues={payload} onSubmit={handleSubmit} validate={userValidate}>
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                title={isAddModal ? t('createUser') : t('updateUser')}
                width={470}
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            my: 2,
                        }}
                    >
                        <AppSubmitButton
                            text={isAddModal ? t('createNewUser') : t('updateUser')}
                            startIcon={isAddModal ? <PersonAddAlt1 /> : <FaUserEdit />}
                            variant="contained"
                            color="primary"
                            loading={loading}
                            loadingPosition="center"
                            sx={{ width: '70%' }}
                        />
                    </Box>
                }
            >
                <AppFormContext getValues={getCurrentValues}>
                    {values.role === 'support' && !isAddModal ? (
                        <AppFormInput
                            type="text"
                            name="role"
                            label={t('userModal.roleInput')}
                            value={'Support'}
                            disabled={!isAddModal && payload?.role === 'support'} //disable if it's an edit modal for support so they can't change their roles
                        />
                    ) : (
                        <AppFormSelect
                            name="role"
                            label={t('userModal.roleInput')}
                            options={getAvailableUserRoles(hasSupport, isAddModal)}
                            defaultValue={t('userModal.inputSelect1.defaultValue')}
                        />
                    )}
                    {values.role === 'auditor' && (
                        <AppFormSelect
                            name="auditor_standards"
                            label={t('userModal.inputSelect2.label')}
                            options={standards}
                            defaultValue={t('userModal.inputSelect2.defaultValue')}
                            multiple
                        />
                    )}
                    {values.role === 'support' ? (
                        <>
                            <AppFormSelect
                                name="support_user"
                                label={t('userModal.inputSelect3.label')}
                                options={supportUsers}
                                defaultValue={t('userModal.inputSelect3.defaultValue')}
                            />
                            <AppFormSelect
                                name="routes"
                                label={t('userModal.inputSelect4.label')}
                                options={[]}
                                defaultValue={t('userModal.inputSelect4.defaultValue')}
                                multiple
                            />
                        </>
                    ) : (
                        <AppFormInput
                            type="email"
                            label={t('userModal.inputLabel1')}
                            name="email"
                            placeholder={t('userModal.inputLabel1')}
                            disabled={!isAddModal}
                        />
                    )}
                </AppFormContext>
            </AppCenteredModal>
        </AppForm>
    );
};
const mapStateToProps = (state) => {
    return {
        all_support_users: state?.adminReducers?.all_support_users,
        merchant_standards: state?.generalReducers?.user_info?.organization?.standards,
        hasSupport: Boolean(state?.generalReducers?.user_info?.organization?.support_user),
    };
};
export default connect(mapStateToProps, {
    AddMerchantUser,
    GetAllSupportUsers,
    UpdateMerchantSupportUser,
    UpdateMerchantUser,
})(UserModal);
