import React from 'react';

const DisabledFeatureMsg = () => {
    return (
        <>
            <div className="flex items-center mt-3 mb-1">
                <div className="mr-3">
                    <h3 className="font-semibold text-[#77777A] text-[14px]">This feature is not available</h3>
                </div>
            </div>
            <div className="flex items-center mb-3">
                <div className="text-[11px] text-[#77777A] font-medium mr-2">
                    Upgrade your account to access more features
                </div>
            </div>
        </>
    );
};

export default DisabledFeatureMsg;
