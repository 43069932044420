import React from 'react';
import { useTheme } from '@mui/styles';
//components
import { Stack, Typography } from '@mui/material';
import AppSelectInput from 'components/new_components/AppSelectInput';

//utils
import { typeOptions } from '../utils/constants';

const CardHeader = ({ indexValue, onChangeTab, tabList, name, type, onTypeSelect }) => {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%', whiteSpace: 'nowrap' }}
        >
            <Stack direction="row" alignItems="center" sx={{ ml: 1 }}>
                <Typography
                    sx={{
                        color: theme.palette.primary[20],
                        fontSize: '18px',
                        fontWeight: 700,
                        letterSpacing: '0.1px',
                        lineHeight: '30px',
                    }}
                >
                    {name}
                </Typography>
                <div
                    className={`py-2 ${
                        name === 'Risk distribution' ? 'px-2' : 'px-4'
                    } flex items-center gap-2 textbrandColor text-[12px]`}
                >
                    {tabList?.map((res, index) => (
                        <React.Fragment key={index}>
                            <div
                                className={`flex items-center cursor-pointer px-1 transition tab ${
                                    indexValue === res.id && 'active border-b-2 border-b-[#002C72]'
                                }`}
                                onClick={() => onChangeTab(res.id)}
                            >
                                <span className={`${indexValue === res.id ? 'textbrandColor' : 'text-[#919094]'}`}>
                                    {res.name}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </Stack>
            {name === 'Risk distribution' && (
                <AppSelectInput
                    name="type"
                    options={typeOptions}
                    defaultValue="Status"
                    sx={{ mr: 1, borderRadius: '8px', width: '110px', height: 38, fontSize: 12 }}
                    onChange={onTypeSelect}
                    value={type}
                />
            )}
        </Stack>
    );
};

export default CardHeader;
