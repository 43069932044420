import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { GetVendorResponses } from 'store/actions/vendorActions';
import SquareIcon from '@mui/icons-material/Square';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import AppTag from 'components/new_components/AppTags';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AppDonutChart from 'components/new_components/AppDonutChart';

const VendorScorecard = (props) => {
    const theme = useTheme();
    const { GetVendorResponses, vendor_responses, details } = props;

    const getVendorRepsonses = async () => {
        const res = await GetVendorResponses(details?.id);
        if (!res.success) {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        if (details?.id) {
            getVendorRepsonses();
        }
    }, [details?.id]);

    const bgColor = '#E2E8F0';
    const values = [
        [
            details?.overall_score_percent,
            details?.overall_score_percent < 34
                ? '#FF5449'
                : details?.overall_score_percent > 33 && details?.overall_score_percent < 67
                ? '#F2C021'
                : '#55BE8B',
        ],
    ];

    return (
        <>
            {vendor_responses?.length > 0 ? (
                <Box sx={{ mt: 3 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mr: 3,
                            position: 'relative',
                        }}
                    >
                        <AppDonutChart values={values} bgColor={bgColor} lineWidth={8} height={240} width={240} />
                        <Typography
                            sx={{ position: 'absolute', top: 90, color: '#202D66', fontWeight: 700, fontSize: '34px' }}
                        >
                            {details?.overall_score_percent}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 3 }}>
                            <SquareIcon sx={{ color: '#FF5449', borderRadius: 3 }} />
                            <Typography
                                sx={{ color: theme.palette.neutral[500], fontWeight: 500, fontSize: '13px', ml: 0.5 }}
                            >
                                0 - 33{' '}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 3 }}>
                            <SquareIcon sx={{ color: '#F2C021', borderRadius: 3 }} />
                            <Typography
                                sx={{ color: theme.palette.neutral[500], fontWeight: 500, fontSize: '13px', ml: 0.5 }}
                            >
                                34 - 66{' '}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SquareIcon sx={{ color: theme.palette.success[70], borderRadius: 3 }} />
                            <Typography
                                sx={{ color: theme.palette.neutral[500], fontWeight: 500, fontSize: '13px', ml: 0.5 }}
                            >
                                67 - 100{' '}
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        {vendor_responses?.map((response) => (
                            <Box
                                key={response?.id}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderTop: '2px solid #F1F5F9',
                                }}
                            >
                                <Typography
                                    sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px', width: '60%', py: 1 }}
                                >
                                    {response?.form_title}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        alignItems: 'center',
                                        width: '40%',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: theme.palette.primary[40],
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            borderLeft: '2px solid #F1F5F9',
                                            py: 1,
                                            pl: 1,
                                        }}
                                    >
                                        {response?.score_percent}%
                                    </Typography>
                                    <Box
                                        sx={{
                                            marginLeft: '3rem',
                                            py: 1,
                                        }}
                                    >
                                        <AppTag
                                            text={response.risk_level}
                                            icon={
                                                <FiberManualRecordIcon
                                                    sx={{
                                                        width: 12,
                                                        height: 12,
                                                        color:
                                                            response.risk_level === 'low'
                                                                ? theme.palette.success[500]
                                                                : response.risk_level === 'medium'
                                                                ? theme.palette.warning[900]
                                                                : response.risk_level === 'high'
                                                                ? theme.palette.error[500]
                                                                : theme.palette.error[300],
                                                        mr: 0.5,
                                                    }}
                                                />
                                            }
                                            noRounded
                                            noItalic
                                            type="default"
                                            sx={{
                                                padding: '4px',
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 10 }}>
                    <Typography sx={{ fontWeight: 500, fontSize: '14px', color: '#5A38F4' }}>
                        Vendor has not responded
                    </Typography>
                </Box>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
        vendor_responses: state?.vendorReducers?.vendor_responses,
    };
};
export default connect(mapStateToProps, { GetVendorResponses })(VendorScorecard);
