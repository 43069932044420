import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Table, Tag, Modal, Row, Col, Input, Button } from 'antd';
import { AuditorOperations } from './operations/AuditorOperations';
import ViewAuditorDrawer from './operations/ViewAuditorDrawer';
import BreadCrumb from 'components/Breadcrumb';
import MerchantList from './operations/MerchantList';

// redux
import { connect } from 'react-redux';
import { GetAllAuditors, ApproveAnAuditor, DeleteAuditor } from 'store/actions/adminActions';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import AuditorModal from './operations/AuditorModal';
import AuditorInviteModal from './operations/AuditorInviteModal';
import useSearch from 'hooks/useSearch';

const Auditor = (props) => {
    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState(null);
    const [merchantModal, setMerchantModal] = useState(false);
    const [modal, setModal] = useState(null);
    const [invite_modal, setInvite_Modal] = useState(null);
    const [loading, setLoading] = useState({ content: false, approve: false });

    const { GetAllAuditors, all_auditors, ApproveAnAuditor, DeleteAuditor } = props;

    // useEffect
    useEffect(() => {
        getAllAuditors();
    }, []);

    // hooks
    const { data, handleSearch } = useSearch(all_auditors, ['name', 'owner_detail.email']);

    // functions
    const getAllAuditors = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAllAuditors();
        setLoading({ ...loading, content: false });
        if (!res.success) {
            toast.error(res.message);
        }
    };
    const approveAnAuditor = async (id) => {
        setLoading({ ...loading, approve: true });
        const res = await ApproveAnAuditor(id, { is_approved: true });
        setLoading({ ...loading, approve: false });
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };
    const openViewDrawer = (record) => {
        setRecord(record);
        setVisible(true);
    };
    const closeViewDrawer = () => {
        setVisible(false);
    };
    const openMerchantModal = (id) => {
        setRecord(id);
        setMerchantModal(true);
    };
    const closeMerchantModal = () => {
        setMerchantModal(false);
    };
    const openModal = (mode, data = {}) => {
        setModal(mode);
        setRecord(data);
    };

    const inviteModal = (mode, data = {}) => {
        setInvite_Modal(mode);
        setRecord(data);
    };
    const closeInviteModal = () => {
        setInvite_Modal(null);
        setRecord({});
    };
    const closeModal = () => {
        setModal(null);
        setRecord({});
    };
    const deleteModal = (record) => {
        Modal.confirm({
            title: 'Do you wish to delete this Auditor?',
            content: 'Click yes to proceed',
            okText: 'Delete',
            okType: 'danger',
            async onOk() {
                const res = await DeleteAuditor(record?.id);
                if (res?.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            },
            onCancel() {
                return false;
            },
        });
    };

    const columns = [
        {
            title: 'Name',
            render: (record) => record?.name,
        },
        {
            title: 'Email',
            render: (record) => record?.owner_detail?.email,
        },
        {
            title: 'Phone Number',
            render: (record) => record?.phone_number || 'N/A',
        },
        {
            title: 'Approved',
            render: (record) => (
                <>
                    {record?.is_approved && <Tag color="green">Approved</Tag>}
                    {!record?.is_approved && <Tag color="red">Not Approved</Tag>}
                </>
            ),
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => (
                <AuditorOperations
                    record={record}
                    openViewDrawer={openViewDrawer}
                    approveAuditor={approveAnAuditor}
                    openMerchantModal={openMerchantModal}
                    deleteModal={(record) => deleteModal(record)}
                    openModal={openModal}
                />
            ),
        },
    ];

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb view="Auditors" views={[{ label: 'Auditors' }]} />
                </Col>
                <Col span={12} className="flex items-center">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                    <Button
                        onClick={() => inviteModal('Invite')}
                        type="primary"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center ml-3"
                    >
                        Invite Auditor
                    </Button>
                    <Button
                        onClick={() => openModal('Add')}
                        type="primary"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center ml-3"
                    >
                        Add Auditor
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(all_auditors) => all_auditors.id}
                    columns={columns}
                    dataSource={data?.sort((a, b) => b?.owner_detail?.id - a?.owner_detail?.id)}
                    loading={loading.content}
                />
            </section>

            <ViewAuditorDrawer visible={visible} closeViewDrawer={closeViewDrawer} record={record} />
            <MerchantList open={merchantModal} closeModal={closeMerchantModal} auditor={record} />
            <AuditorModal open={Boolean(modal)} mode={modal} closeModal={closeModal} modalData={record} />
            <AuditorInviteModal
                open={Boolean(invite_modal)}
                mode={invite_modal}
                closeModal={closeInviteModal}
                modalData={record}
            />
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        all_auditors: state?.adminReducers?.all_auditors,
    };
};
export default connect(mapStateToProps, { GetAllAuditors, ApproveAnAuditor, DeleteAuditor })(Auditor);
