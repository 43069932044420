import React, { useState, useEffect, useMemo, useContext } from 'react';
import { toast } from 'react-toastify';
import { Stack } from '@mui/material';

// core components
import ExceptionsTabs from '../../common/exceptions/ExceptionsTabs';
import ExceptionGroups from '../../common/exceptions/ExceptionGroups';
import EmptyState from 'components/new_components/EmptyState';
import LoadingState from 'components/new_components/LoadingState';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { GetAllExemptedDocuments, GetAllExemptedForms } from 'store/actions/complianceActions';

//translations
import { useTranslation } from 'react-i18next';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

const SubrequirementSelectionAside = (props) => {
    const { resource, handleResourceChange, handleSubgroupIdChange, selectedSubgroup } = props;

    // state
    const [loading, setLoading] = useState({ content: false });

    // redux
    const dispatch = useDispatch();
    const all_requirements = useSelector((state) => state?.adminReducers?.all_pcidss4point0_requirements);
    const exempted_documents = useSelector((state) => state?.complianceReducers?.all_exempted_documents);
    const exempted_forms = useSelector((state) => state?.complianceReducers?.all_exempted_forms);

    //translation
    const { t } = useTranslation('compliance');
    const language = localStorage.getItem('i18nextLng');

    const { activeMerchant } = useContext(SelectedMerchantContext);

    // logic functions
    //To filter Requirements that has exempted documents
    const getFilteredRequirements = (items = []) => {
        const filteredRequirements = JSON.parse(JSON.stringify(all_requirements))?.filter((requirement) => {
            const requirement_id = requirement?.id;
            const requirementHasExemption = items?.some((exemption) => {
                const exemptionRequirementID =
                    resource === 'doc' ? exemption?.document_detail?.card4_id : exemption?.form_detail?.card4_id;
                return exemptionRequirementID === requirement_id;
            });
            return requirementHasExemption;
        });
        const requirementsWithFilteredSubrequirements = filteredRequirements?.map((requirement) => {
            const filteredSubRequirements = [...requirement.sub_requirements].filter((sub_requirement) => {
                const subRequirementNumber = sub_requirement?.requirement_number;
                const subRequirementHasExemption = items?.some((exemption) => {
                    const exemptionSubRequirementNumber =
                        resource === 'doc'
                            ? exemption?.document_detail?.card4_sub_no
                            : exemption?.form_detail?.card4_sub_no;
                    return exemptionSubRequirementNumber === subRequirementNumber;
                });
                return subRequirementHasExemption;
            });

            requirement.sub_requirements = filteredSubRequirements;
            return requirement;
        });

        const formattedRequirements = requirementsWithFilteredSubrequirements.map((requirement) => {
            return {
                id: requirement?.id,
                title: `${t('requirement')} ${requirement?.requirement_number}`,
                description: language?.includes('fr')
                    ? requirement?.requirement_title_fr
                    : language?.includes('en')
                    ? requirement?.requirement_title_en
                    : requirement?.requirement_title,
                subgroups: requirement?.sub_requirements?.map((subrequirement) => ({
                    label: `${t('sub')} ${subrequirement?.requirement_number}`,
                    id: subrequirement?.requirement_number,
                })),
            };
        });
        return formattedRequirements;
    };

    // async functions
    // get all excempted document for this standard
    const getAllExemptedDocuments = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await dispatch(GetAllExemptedDocuments('card4', activeMerchant));
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exceptions.");
        }
    };
    const getAllExemptedQuestions = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await dispatch(GetAllExemptedForms('card4', activeMerchant));
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exceptions.");
        }
    };

    // memos
    const requirementsWithExceptions = useMemo(() => {
        if (resource === 'doc') {
            return getFilteredRequirements(exempted_documents);
        } else {
            return getFilteredRequirements(exempted_forms);
        }
    }, [resource, exempted_documents, exempted_forms]);

    // useEffect
    useEffect(() => {
        if (resource === 'doc') {
            getAllExemptedDocuments();
        } else {
            getAllExemptedQuestions();
        }
    }, [resource, activeMerchant]);

    return (
        <Stack sx={{ width: '100%' }}>
            <ExceptionsTabs onTabChange={handleResourceChange} currentTab={resource} />
            {!loading.content ? (
                !requirementsWithExceptions?.length ? (
                    <EmptyState description={t('noException')} />
                ) : (
                    <ExceptionGroups
                        hasSubgroup={true}
                        groups={requirementsWithExceptions}
                        handleSubgroupIdChange={handleSubgroupIdChange}
                        selectedSubgroup={selectedSubgroup}
                        standard="card4"
                    />
                )
            ) : (
                <LoadingState />
            )}
        </Stack>
    );
};

export default SubrequirementSelectionAside;
