import React from 'react';

//core component
import { Checkbox } from '@mui/material';
import { CheckBoxOutlineBlankRounded, SquareRounded } from '@mui/icons-material';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const AppCustomCheckbox = (props) => {
    const { color = 'primary', sx, ...otherProps } = props;
    return (
        <Checkbox
            {...label}
            icon={<CheckBoxOutlineBlankRounded color={color} />}
            checkedIcon={<SquareRounded color={color} />}
            color={color}
            sx={{ ...sx }}
            {...otherProps}
        />
    );
};

export default AppCustomCheckbox;
