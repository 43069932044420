import { Button, Col, Input, Modal, Row, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { AiOutlineInbox } from 'react-icons/ai';

// redux
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { CreateDocument, CreateCondtionalItem, EditCondtionalItem } from 'store/actions/adminActions';

const ConditionalModal = (props) => {
    const { open, handleClose, modalData, subcriteria, CreateDocument, CreateCondtionalItem, EditCondtionalItem } =
        props;

    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);

    // drgagger
    const { Dragger } = Upload;
    const draggers = {
        name: 'default',
        action: null,
        multiple: false,
        maxCount: 1,
        async onChange({ fileList, event }) {
            const file = fileList[0]?.originFileObj;
            if (!event) return;
            if (!file.size) return toast.error('The file you are trying to upload is empty.');
            const formData = new FormData();
            formData.append('tags', 'blockchain');
            formData.append('type', 'conditional');
            formData.append('criteria', subcriteria?.criteria);
            formData.append('subcriteria', subcriteria?.id);
            formData.append('default', file);

            setUploading(true);
            const res = await CreateDocument(formData);
            setUploading(false);
            if (res?.success) {
                setData({ ...data, document_id: res?.document?.id });
            } else {
                toast.error(res?.message);
            }
        },
    };

    // functions
    const closeAll = () => {
        handleClose();
        setData({});
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!data?.document_id) return toast.error('Please, upload a document before sbmitting.');
        setLoading(true);
        const res = open === 'Add' ? await CreateCondtionalItem(data) : await EditCondtionalItem(data, data?.id);
        setLoading(false);
        if (res?.success) {
            toast.success(res?.success);
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    // useEffect
    useEffect(() => {
        if (open) {
            setData({ ...modalData, subcriteria: subcriteria?.id, criteria: subcriteria?.criteria });
        }
    }, [open]);

    return (
        <Modal title={`${open} Conditional Item`} open={open} onCancel={closeAll} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24}>
                        <div className="mb-4">
                            <label htmlFor="question">Question</label>
                            <Input
                                size="large"
                                placeholder="What is the question?"
                                onChange={handleTextChange}
                                value={data?.question || ''}
                                name="question"
                                id="question"
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="mb-4">
                            <label>Upload Document</label>
                            <Dragger {...draggers}>
                                <span className="flex justify-center">
                                    <AiOutlineInbox />
                                </span>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="mb-4">
                            <label htmlFor="no_label">Alternative Answer Label</label>
                            <Input
                                size="large"
                                onChange={handleTextChange}
                                name="no_label"
                                id="no_label"
                                value={data?.no_label || ''}
                            />
                        </div>
                    </Col>
                    <Col className="mt-2">
                        <Button htmlType="submit" type="primary" loading={loading} disabled={uploading || loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, { CreateDocument, CreateCondtionalItem, EditCondtionalItem })(ConditionalModal);
