import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CategoryCards from './CategoryCards';
import CategorySettings from './CategorySettings';

const Categories = () => {
    return (
        <Switch>
            <Route path="/admin/audit-categories" exact component={CategoryCards} />
            <Route path="/admin/audit-categories/:id" component={CategorySettings} />
        </Switch>
    );
};

export default Categories;
