import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { ReactComponent as CompletedTraining } from 'assets/img/icons/completed_training.svg';
import { ReactComponent as UncompletedTraining } from 'assets/img/icons/uncompleted_training.svg';

//translations
import { useTranslation } from 'react-i18next';

const TrainingSummary = (props) => {
    const { metrics } = props;

    //translation
    const { t } = useTranslation('training');

    return (
        <Stack direction="row" sx={{ my: 5, flexWrap: 'wrap', gap: 4 }}>
            <SummaryCard
                value={metrics?.complete}
                label={t('trainingSummary.completedTraining')}
                Icon={CompletedTraining}
            />
            <SummaryCard
                value={metrics?.incomplete}
                label={t('trainingSummary.uncompletedTraining')}
                Icon={UncompletedTraining}
            />
        </Stack>
    );
};

const SummaryCard = (props) => {
    const { value, label, Icon } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('training');

    return (
        <Box sx={{ p: 3, pt: 8, backgroundColor: theme.palette.white.main, borderRadius: 2, minWidth: 360 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box>
                    <Typography
                        component="span"
                        sx={{ fontSize: 40, fontWeight: 700, color: theme.palette.gray[20], pr: 2 }}
                    >
                        {value}
                    </Typography>
                    <Typography
                        component="span"
                        sx={{ fontSize: 10, fontWeight: 500, color: theme.palette.neutral[60] }}
                    >
                        {t('trainingSummary.employee')}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: 60,
                        height: 60,
                        borderRadius: 2,
                        backgroundColor: 'rgba(227, 237, 255, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Icon />
                </Box>
            </Stack>
            <Typography sx={{ color: theme.palette.primary[900], fontSize: 16, fontWeight: 600 }}>{label}</Typography>
        </Box>
    );
};

export default TrainingSummary;
