import React from 'react';
import { Formik } from 'formik';

const AppForm = (props) => {
    const { initialValues, onSubmit, validate, children } = props;
    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate} enableReinitialize={true}>
            {() => <>{children}</>}
        </Formik>
    );
};

export default AppForm;
