import React, { useEffect, useState, useContext } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';

import WelcomeModal from '../components/Modal/WelcomeModal';
import MerchantSidebar from 'components/new_components/merchant-layout/sidebar';
import AuditorHeader from 'components/new_components/merchant-layout/header/AuditorHeader';

// routes
import getRoutes from 'routes/auditorRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { getType, getUserToken } from '../utils';
import { SendDeviceToken, UpdateDeviceToken } from 'store/actions/merchantActions';
import { GetUserDetails } from 'store/actions/generalActions';
import { GetAllMerchantNotifications } from 'store/actions/notificationActions';
import { GetUserGuideDetails } from 'store/actions/guideActions';
import NotificationModal from 'components/Notification/NotificationModal';

import { NotificationsActive } from '@mui/icons-material';

import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import SessionTimeout from 'components/sessionTimeout/SessionTimeout';
import { messaging } from 'utils/firebase-config';
import { getToken, onMessage } from 'firebase/messaging';
import { GetUserDeviceToken } from 'store/actions/merchantActions';
import Setup2FaModal from 'views/merchant/AccountSetup/TwoFactorAuth/Setup2FaModal';
import SelectedMerchantContextProvider from 'store/context/SelectedMerchantContextProvider';
// import TwoFactorCountdownModal from 'views/merchant/AccountSetup/TwoFactorAuth/TwoFactorCountdownModal';

const AuditorLayout = () => {
    const location = useLocation();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [openWelcomeModal, setOpenWelcomeModal] = useState(false);
    const [routes, setRoutes] = useState(undefined);
    const [pageTitle, setPageTitle] = useState('');
    const [notificationModalOpen, setNotificationModalOpen] = useState(false);
    const [twoFactorModal, setTwoFactorModal] = useState(false);

    const { updateOneUserGuide } = useContext(UserGuideContext);
    //redux hooks
    const dispatch = useDispatch();

    //getting values from the store
    // const userguide_info = useSelector(state => state?.guideReducers?.userguide_info?.[0]?.welcome);
    const user_id = useSelector((state) => state?.generalReducers?.user_info?.id);
    const user_device_token = useSelector((state) => state?.merchantReducers?.user_device_token);
    const mfa = useSelector((state) => state?.generalReducers?.user_info?.mfa);
    // const auditor_id = useSelector((state) => state?.authReducers?.user_details?.user?.organization?.id);
    const auditor_merchants = useSelector(
        (state) => state?.generalReducers?.user_info?.organization?.assigned_merchant
    );

    // functions
    // control the sidebar
    const openSidebar = () => setSidebarOpen(true);
    const closeSidebar = () => setSidebarOpen(false);

    const closeWelcome = () => {
        updateOneUserGuide({ welcome: true });
        setOpenWelcomeModal(false);
    };

    //control notificationModal
    const openNotificationModal = () => setNotificationModalOpen(true);
    const closeNotificationModal = () => setNotificationModalOpen(false);

    // generate the routes
    const generateRoutes = (routes) => {
        const routesList = routes?.map((prop, key) => {
            if (prop.layout === '/auditor') {
                return <Route exact path={prop.layout + prop.path} component={prop.component} key={key} />;
            } else if (prop.subMenus) {
                return prop.subMenus.map((menu, key) => {
                    return <Route path={menu.layout + menu.path} component={menu.component} key={key} exact />;
                });
            } else {
                return null;
            }
        });
        if (routesList) {
            routesList.push(<Redirect to="/auditor/index" key="redirect" />);
        }
        return routesList;
    };

    // get the page header title
    const getPageTitle = (path) => {
        const current = routes?.find((route) => {
            if (!route?.subMenus) {
                return route?.layout + route?.path === path;
            } else {
                return route.subMenus.find((menu) => menu.layout + menu.path === path);
            }
        });
        if (current?.subMenus) {
            const subMenus = current?.subMenus?.find((menu) => menu?.layout + menu?.path === path)?.name;
            setPageTitle(subMenus);
            return subMenus;
        }
        setPageTitle(current?.name);
    };

    const isAuthenticated = getUserToken('token');
    // const isAuthenticated = getUserToken();

    const askUserPermission = async () => {
        await Notification.requestPermission();

        // If user_device_token exists, return early
        if (user_device_token?.length) {
            const currentToken = await getToken(messaging, {
                vapidKey: 'BIRasTPC0RXXRBB7QyoYjjNvQ5M6KmfcIZEAjZwLL6F4bCRuLf-Ll0SjPSEuIV8cPyGAZ_Vl43I5OSPZjLbSDPk',
            });
            if (user_device_token?.[0]?.device_id !== currentToken) {
                await dispatch(UpdateDeviceToken({ device_id: currentToken }, user_device_token?.[0]?.id));
            }
            return;
        }

        // Get registration token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        getToken(messaging, {
            vapidKey: 'BIRasTPC0RXXRBB7QyoYjjNvQ5M6KmfcIZEAjZwLL6F4bCRuLf-Ll0SjPSEuIV8cPyGAZ_Vl43I5OSPZjLbSDPk',
        })
            .then(async (currentToken) => {
                if (currentToken) {
                    SendDeviceToken({ device_id: currentToken, user: user_id });
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
    };

    useEffect(() => {
        const getUserDetails = async () => {
            const res = await dispatch(GetUserDetails());
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getUserDetails();
    }, []);

    // useEffect(() => {
    //     const getAllMerchants = async () => {
    //         const res = await dispatch(getAuditorMerchants(auditor_id));
    //         if (!res?.success) {
    //             toast.error(res?.message);
    //         }
    //     };
    //     getAllMerchants();
    // }, [auditor_id]);

    useEffect(() => {
        const routes = getRoutes();
        setRoutes(routes);
    }, [localStorage.getItem('i18nextLng')]);

    useEffect(() => {
        const getUserGuideDetails = async () => {
            const res = await dispatch(GetUserGuideDetails());
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getUserGuideDetails();
    }, []);
    useEffect(() => {
        const getAllMerchantNotifications = async () => {
            const res = await dispatch(GetAllMerchantNotifications());
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllMerchantNotifications();
    }, []);
    useEffect(() => {
        const { pathname } = location;
        getPageTitle(pathname);
    }, [location.pathname, routes]);
    useEffect(() => {
        const getUserDeviceToken = async () => {
            const res = await dispatch(GetUserDeviceToken());
            if (!res.success) {
                toast.error(res?.message);
            }
        };
        getUserDeviceToken();
    }, []);
    useEffect(() => {
        askUserPermission();
        // Handle incoming messages. Called when:
        // - a message is received while the app has focus
        // - the user clicks on an app notification created by a service worker
        //   `messaging.onBackgroundMessage` handler.

        onMessage(messaging, (payload) => {
            // ...
            toast.info(payload?.notification?.body);
        });
    }, [user_device_token]);
    // useEffect(() => {
    //     !userguide_info ? openWelcome() : null;
    // }, [userguide_info]);
    useEffect(() => {
        if (location?.pathname !== '/auditor/settings/account' && getType('mfa') !== 'true') {
            setTwoFactorModal(true);
        } else {
            setTwoFactorModal(false);
        }
    }, [location?.pathname, mfa]);

    return routes ? (
        <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
            <SelectedMerchantContextProvider>
                <MerchantSidebar open={sidebarOpen} handleClose={closeSidebar} routes={routes} />
                <AuditorHeader
                    openDrawer={openSidebar}
                    title={
                        pageTitle === 'CII'
                            ? 'CII & RISK MANAGEMENT DIRECTIVES'
                            : pageTitle === 'KDPA'
                            ? 'KDPA: KENYA DATA PROTECTION ACT & REGULATIONS'
                            : pageTitle === 'GDPA'
                            ? 'GDPA: GHANA DATA PROTECTION ACT'
                            : pageTitle
                    }
                    openNotificationModal={openNotificationModal}
                    notificationModalOpen={notificationModalOpen}
                    auditor_merchants={auditor_merchants}
                />
                <Box
                    // ref={sectionRef}
                    component="main"
                    id="main_section_container"
                    sx={{
                        flexGrow: 1,
                        p: {
                            xs: 0,
                            md:
                                pageTitle?.toLowerCase() === 'pcidss' ||
                                pageTitle?.toLowerCase() === 'ndpr' ||
                                pageTitle?.toLowerCase() === 'soc2' ||
                                pageTitle?.toLowerCase() === 'iso27001'
                                    ? 0
                                    : 3,
                        },
                        width: { sm: `calc(100% - 275px)` },
                        mt: { xs: '70px', md: '90px' },
                        maxWidth: '100%',
                        mb: '80px',
                        height: { xs: 'calc(100% - 70px)', md: 'calc(100% - 90px)', lg: 'calc(100% - 90px)' },
                        overflow: 'auto',
                        position: 'fixed',
                        right: 0,
                        backgroundColor: '#f8f9fe',
                    }}
                >
                    <Box sx={{ height: '100%' }}>
                        <Switch>{generateRoutes(routes)}</Switch>
                    </Box>
                </Box>
            </SelectedMerchantContextProvider>
            <WelcomeModal open={openWelcomeModal} handleClose={closeWelcome} />
            <NotificationModal
                open={notificationModalOpen}
                handleClose={closeNotificationModal}
                title="Notifications"
                width={550}
                icon={<NotificationsActive />}
            />

            {isAuthenticated ? <SessionTimeout isAuthenticated={isAuthenticated} /> : null}
            <Setup2FaModal open={twoFactorModal} />
            {/* <TwoFactorCountdownModal open={countdownModal} handleClose={closeCountdownModal} countdown={countdown} /> */}
        </Box>
    ) : null;
};

export default AuditorLayout;
