import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
//Core components
import { Box } from '@mui/material';
import AppTable from 'components/new_components/app-table';
import AppTag from 'components/new_components/AppTags';
import { useTheme } from '@mui/material/styles';
import useSearch from 'hooks/useSearch';
//redux
import { connect } from 'react-redux';
import { GetAllComputers } from 'store/actions/merchantActions';

//utils
import { formatDateObjectHandler, GetFromNowDate } from 'utils';
import PageHeader from 'components/new_components/PageHeader';

//translations
import { useTranslation } from 'react-i18next';

const NO_PER_PAGE = 8;

const Computers = (props) => {
    //state
    const [loading, setloading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const { GetAllComputers, all_computers } = props;

    //theme
    const theme = useTheme();

    const { data, handleSearch } = useSearch(all_computers, [
        'name',
        'serialnumber',
        'systemname',
        'date_updated',
        'riskscore',
    ]);

    //translation
    const { t } = useTranslation('inventory');

    // functions
    const handlePageChange = (page) => {
        setPage(page);
    };

    const columns = [
        {
            title: t('computerTable.column1'),
            key: 'owner',
            render: (row) => <>{row?.name}</>,
        },
        {
            title: t('computerTable.column2'),
            key: 'serialnumber',
            render: (row) => <>{row?.serialnumber}</>,
        },
        {
            title: t('computerTable.column3'),
            key: 'systemname',
            render: (row) => <>{row?.systemname}</>,
        },
        {
            title: t('computerTable.column4'),
            key: 'date_updated',
            render: (row) => (
                <>
                    {formatDateObjectHandler(row.date_updated, 'MMMM Do, YYYY')}
                    <Box
                        component="div"
                        sx={{
                            color: theme.palette.gray[500],
                            ml: 0.2,
                            fontSize: '0.7rem',
                            fontWeight: 400,
                        }}
                    >
                        ({GetFromNowDate(row.date_updated, 'YYYY-MM-DDTHH:mm:ss', 'fromNow')})
                    </Box>
                </>
            ),
        },
        {
            title: t('computerTable.column5'),
            key: 'pwmanager',
            render: (row) => (
                <>
                    <AppTag
                        text={row.pwmanager ? 'Pass' : 'Failed'}
                        type={row.pwmanager ? 'success' : 'failed'}
                        hasIcon={true}
                    />
                </>
            ),
        },
        {
            title: t('computerTable.column6'),
            key: 'diskencryption',
            render: (row) => (
                <>
                    <AppTag
                        text={row.diskencryption ? 'Pass' : 'Failed'}
                        type={row.diskencryption ? 'success' : 'failed'}
                        hasIcon={true}
                    />
                </>
            ),
        },
        {
            title: t('computerTable.column7'),
            key: 'avstatus',
            render: (row) => (
                <>
                    <AppTag
                        text={row.avstatus ? 'Pass' : 'Failed'}
                        type={row.avstatus ? 'success' : 'failed'}
                        hasIcon={true}
                    />
                </>
            ),
        },
        {
            title: t('computerTable.column8'),
            key: 'lockstatus',
            render: (row) => (
                <>
                    <AppTag
                        text={row.lockstatus ? t('computerTable.pass') : t('computerTable.failed')}
                        type={row.lockstatus ? 'success' : 'failed'}
                        hasIcon={true}
                    />
                </>
            ),
        },
    ];

    useEffect(() => {
        const getAllComputers = async () => {
            setloading(true);
            const res = await GetAllComputers();
            if (res?.success) {
                setloading(false);
            } else {
                setloading(false);
                toast.error(res?.message);
            }
        };
        getAllComputers();
    }, []);

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        setPageData(data?.length > 0 ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);

        return data;
    }, [page, data]);
    return (
        <>
            <PageHeader browserTitle="Computers | Smartcomply" />
            <AppTable
                columns={columns}
                data={pageData}
                title={t('computerTitle')}
                loading={loading}
                dataLength={data?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                search={true}
                onSearch={handleSearch}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_computers: state?.merchantReducers?.all_computers,
    };
};
export default connect(mapStateToProps, { GetAllComputers })(Computers);
