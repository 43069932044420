import React from 'react';
import { Slide, Box, Drawer, Stack, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BiDownload } from 'react-icons/bi';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const RiskAssessmentViewModal = (props) => {
    const { open, handleClose, title, children, handleClick } = props;
    const theme = useTheme();
    return (
        <Drawer
            open={open}
            anchor={'right'}
            onClose={handleClose}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 370,
                    width: '650px',
                    flex: 1,
                    overflow: 'scroll',
                },
            }}
            scroll="paper"
        >
            <Box>
                <Stack
                    sx={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: theme.palette.primary[900],
                        px: 3,
                        py: 2,
                        width: '100%',
                    }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack direction="row" justifyContent="flex-start" alignItems="center">
                        <ArrowBackIosIcon onClick={handleClose} sx={{ width: 20, height: 20, cursor: 'pointer' }} />
                        <Typography sx={{ color: '#191B23', fontSize: '16px', fontWeight: 700, ml: 1.5 }}>
                            {title}
                        </Typography>
                    </Stack>

                    <Button
                        variant={'outlined'}
                        sx={{ textTransform: 'capitalize', border: `2px solid ${theme.palette.primary}` }}
                        startIcon={<BiDownload />}
                        onClick={handleClick}
                    >
                        Print
                    </Button>
                </Stack>
                <Box>{children}</Box>
            </Box>
        </Drawer>
    );
};

export default RiskAssessmentViewModal;
