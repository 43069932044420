import { Drawer, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// redux
import { GetAllMerchantUsers } from 'store/actions/merchantActions';
import { connect } from 'react-redux';
import LoadingState from 'components/new_components/LoadingState';

const ViewUsersModal = (props) => {
    const { visible, closeDrawer, record, GetAllMerchantUsers, all_merchant_users } = props;
    const [loading, setLoading] = useState(false);

    const getAllMerchantUser = async () => {
        setLoading(true);
        const res = await GetAllMerchantUsers(record?.id);
        setLoading(false);
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (Object.keys(record || {})?.length > 0) {
            getAllMerchantUser();
        }
    }, [record]);
    return (
        <div>
            <Drawer
                title={`All ${record?.owner_detail?.first_name || ''} ${record?.owner_detail?.last_name || ''} Users`}
                open={visible}
                width="800px"
                getContainer={false}
                onClose={closeDrawer}
                destroyOnClose={true}
                contentWrapperStyle={{
                    overflow: 'auto',
                }}
            >
                {!loading ? (
                    <>
                        {all_merchant_users?.length > 0 ? (
                            <div className="userdataContainer">
                                {all_merchant_users?.map((user) => (
                                    <div className="item" key={user?.if}>
                                        <Row gutter={28} align="middle">
                                            <Col xs={12} xl={8} lg={8}>
                                                <span>Name:</span>
                                            </Col>
                                            <Col xs={12} xl={12} lg={12}>
                                                {user?.user_detail?.first_name} {user?.user_detail?.last_name}
                                            </Col>
                                        </Row>
                                        <Row gutter={28} align="middle">
                                            <Col xs={12} xl={8} lg={8}>
                                                <span>Email:</span>
                                            </Col>
                                            <Col xs={12} xl={12} lg={12}>
                                                {user?.user_detail?.email}
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="flex justify-center items-center">
                                <h1>No User Available</h1>
                            </div>
                        )}
                    </>
                ) : (
                    <LoadingState />
                )}
            </Drawer>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_users: state?.merchantReducers?.all_merchant_users,
    };
};

export default connect(mapStateToProps, { GetAllMerchantUsers })(ViewUsersModal);
