import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

//redux
import { connect } from 'react-redux';

//translations
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import CloseIcon from '@mui/icons-material/Close';
import { ExemptMerchantDocument } from 'store/actions/complianceActions';
import LightIcon from 'assets/img/Light.svg';
import { AddReminder } from 'store/actions/complianceActions';
import { toast } from 'react-toastify';
import { AppForm } from 'components/new_components/forms';
import { AppFormSelect } from 'components/new_components/forms';
import { AppFormInput } from 'components/new_components/forms';
import { AppFormContext } from 'components/new_components/forms';
import AppFormDateInput from 'components/new_components/forms/AppFormDateInput';
import { reminderValidation } from '../validation';
import { AppSubmitButton } from 'components/new_components/forms';
import moment from 'moment';

const UploadDocumentModal = (props) => {
    const { open, handleClose, AddReminder, reminders, setReminders, reminders_ids, setReminders_ids } = props;
    // state
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        schedule_date: '',
        on_scheduled_date: '',
        interval: '',
        frequency: '',
    });

    const handleSubmit = async (values) => {
        const everyBody = {
            every_scheduled_date: {
                frequency: values?.frequency,
                interval: values?.interval,
            },
            tags: 'compliance',
        };
        const onBody = {
            on_scheduled_date: values?.on_scheduled_date,
            tags: 'compliance',
        };
        const on_reminder = `On ${moment(values?.on_scheduled_date).format('MMM Do, YYYY')}`;
        const every_reminder = `Every ${values?.interval} ${values?.frequency}`;
        const body = values?.schedule_date === 'on' ? onBody : everyBody;
        setLoading(true);
        const res = await AddReminder(body);
        setLoading(false);
        if (res?.success) {
            setReminders([...reminders, values?.schedule_date === 'on' ? on_reminder : every_reminder]);
            setReminders_ids([...reminders_ids, res?.data?.id]);
            toast.success(res?.message);
            handleClose();
            setValues({});
        } else {
            toast.error(res?.message);
        }
    };
    const getCurrentValues = (values) => setValues(values);

    return (
        <AppForm initialValues={values} validate={reminderValidation} onSubmit={handleSubmit}>
            <AppFormContext getValues={getCurrentValues}>
                <AppCenteredModal
                    open={open}
                    handleClose={handleClose}
                    title={
                        <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>Reminder</Typography>
                    }
                    icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
                    noShadow
                    noClose
                    actions={
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'right',
                                alignItems: 'center',
                                backgroundColor: '#F8FAFC',
                                py: 2,
                                pr: 3,
                                mx: '-2rem',
                            }}
                        >
                            <Button
                                variant="outlined"
                                sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit' }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <AppSubmitButton
                                loading={loading}
                                text="Save"
                                loadingPosition="center"
                                variant="contained"
                                color="primary"
                                sx={{ textTransform: 'inherit' }}
                            />
                        </Box>
                    }
                    sx={{ borderRadius: 1 }}
                    headerAction={
                        <Box
                            sx={{
                                border: '1px solid #E2E8F0',
                                borderRadius: 30,
                                width: 20,
                                height: 20,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={handleClose}
                        >
                            <CloseIcon sx={{ width: 12, height: 12, color: '#334155' }} />
                        </Box>
                    }
                    width="550px"
                    height="400px"
                    maxHeight={'400px'}
                >
                    <Box sx={{ my: 2 }}>
                        <Box
                            sx={{
                                backgroundColor: '#F0F0FA',
                                display: 'flex',
                                alignItems: 'flex-start',
                                my: 1.5,
                                py: 1,
                                px: 2,
                                borderRadius: 1,
                            }}
                        >
                            <img src={LightIcon} alt={'LightIcon'} />
                            <Typography sx={{ color: '#64748B', fontWeight: 400, fontSize: '13px', ml: 1 }}>
                                You can utilize reminders when you wish to be informed when policies or documents need
                                to be updated within a specific time frame.
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Typography sx={{ color: '#64748B', fontWeight: 500, fontSize: '13px' }}>
                                Remind me:
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mt: values?.schedule_date === 'on' ? 0 : -1,
                                }}
                            >
                                <Box sx={{ width: '100%' }}>
                                    <AppFormSelect
                                        name="schedule_date"
                                        defaultValue="Every"
                                        sx={{
                                            mr: values?.schedule_date === 'on' ? 2 : 0,
                                            mt: values?.schedule_date === 'on' ? -2 : 0,
                                        }}
                                        options={[
                                            {
                                                name: 'Every',
                                                value: 'every',
                                            },
                                            {
                                                name: 'On',
                                                value: 'on',
                                            },
                                        ]}
                                    />
                                </Box>
                                {values?.schedule_date === 'on' ? (
                                    <AppFormDateInput name="on_scheduled_date" height="50px !important" />
                                ) : (
                                    <>
                                        <AppFormInput
                                            name="interval"
                                            type="number"
                                            Placeholder="number"
                                            sx={{ height: '55px', mx: 1 }}
                                        />
                                        <AppFormSelect
                                            name="frequency"
                                            defaultValue="Days"
                                            // sx={{ minHeight: 30, maxHeight: 40 }}
                                            options={[
                                                {
                                                    name: 'Days',
                                                    value: 'days',
                                                },
                                                {
                                                    name: 'Weeks',
                                                    value: 'weeks',
                                                },
                                                {
                                                    name: 'Months',
                                                    value: 'months',
                                                },
                                            ]}
                                        />
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </AppCenteredModal>
            </AppFormContext>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        employee_id: state?.authReducers?.user_info?.employee?.id,
        user: state?.authReducers?.user_info,
    };
};

export default connect(mapStateToProps, { AddReminder, ExemptMerchantDocument })(UploadDocumentModal);
