import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import BreadCrumb from 'components/Breadcrumb';

//antd
import { Row, Col, Button, Empty, Card, Typography, Modal, Tooltip } from 'antd';
import { PlusOutlined, EditOutlined, EyeOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ClauseModal from './IsoActions/ClauseModal';

//redux
import { connect } from 'react-redux';
import { GetAllContinuityClauses, DeleteContinuityClause } from 'store/actions/adminActions';

// sub-components
const { Title } = Typography;
const { confirm } = Modal;

const ContinuityClausesView = (props) => {
    //state
    const [modalOpen, setModalOpen] = useState(null);
    const [oneReq, setOneReq] = useState({});

    //props
    const { GetAllContinuityClauses, DeleteContinuityClause, all_clauses } = props;

    //functions
    const openAddModal = (mode, data) => {
        setModalOpen(mode);
        setOneReq(data);
    };
    const closeAddModal = () => {
        setModalOpen(false);
    };

    const deleteAnnex = (id, category) => {
        confirm({
            title: "Are you sure you want to delete this clause? Note: Deleting this clause means that you are deleting this clause and records of the clause associated with alongside merchant's data",
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await DeleteContinuityClause(id, category);
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };
    useEffect(() => {
        const getAllManagementClauses = async () => {
            const res = await GetAllContinuityClauses();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllManagementClauses();
    }, []);
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'ISO22301', link: '/iso22301' }, { label: 'Clauses' }]} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusOutlined />}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => openAddModal('Add')}
                    >
                        Add Clause
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                {all_clauses && all_clauses?.length > 0 ? (
                    <Row wrap gutter={24}>
                        {all_clauses
                            ?.sort((a, b) => a.id - b.id)
                            ?.map((clause, index) => (
                                <Col xs={24} md={12} lg={8} xxl={6} key={clause.id} style={{ marginBottom: '1rem' }}>
                                    <Card
                                        title={`#${index}`}
                                        loading={false}
                                        actions={[
                                            <Tooltip title="View this Clause" color="blue">
                                                <Link
                                                    key="preview"
                                                    to={{
                                                        pathname: '/admin/iso22301/clause_details',
                                                        state: {
                                                            clause,
                                                        },
                                                    }}
                                                >
                                                    <EyeOutlined key="preview" />
                                                </Link>
                                            </Tooltip>,
                                            <Tooltip title="Edit this Clause" color="blue">
                                                <EditOutlined key="edit" onClick={() => openAddModal('Edit', clause)} />
                                            </Tooltip>,
                                            <Tooltip title="Delete this Clause" color="red">
                                                <DeleteOutlined
                                                    key="delete"
                                                    onClick={() => deleteAnnex(clause.id, clause.category)}
                                                />
                                            </Tooltip>,
                                        ]}
                                    >
                                        <Title level={5} style={{ textAlign: 'center' }}>
                                            {clause.title}
                                        </Title>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                ) : (
                    <Row justify="center">
                        <Col>
                            <Empty
                                description={
                                    <div>
                                        <h3>No Continuity Clauses</h3>
                                        <span>Add a new Continuity Clause </span>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                )}
            </section>
            <ClauseModal open={modalOpen} handleCancel={closeAddModal} one_req={oneReq} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_clauses: state?.adminReducers?.all_continuity_clauses,
    };
};

export default connect(mapStateToProps, {
    GetAllContinuityClauses,
    DeleteContinuityClause,
})(ContinuityClausesView);
