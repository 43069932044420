import React from 'react';
import { Button, Tooltip } from '@mui/material';
import Fade from '@mui/material/Fade';
import { useTheme } from '@mui/material/styles';

const AppPopover = (props) => {
    const { text, name, sx } = props;
    const theme = useTheme();
    return (
        <Tooltip
            title={text}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            PopperProps={{
                sx: {
                    inset: '20px auto auto -8px !important',
                    '& .MuiTooltip-tooltip': {
                        backgroundColor: '#fff !important',
                        color: theme.palette.primary.main,
                        borderRadius: 8,
                        p: 4,
                        maxWidth: '430px !important',
                        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                        ...sx,
                    },
                },
            }}
        >
            <Button sx={{ m: '0 0 0 3px', p: '0 0 0 1px', minWidth: 'unset' }}>{name}</Button>
        </Tooltip>
    );
};

export default AppPopover;
