import AppDeleteModal from 'components/new_components/AppDeleteModal';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

// redux
import { connect } from 'react-redux';
import { DeleteTraining } from 'store/actions/trainingActions';

//translations
import { useTranslation } from 'react-i18next';

const RemoveAssignment = (props) => {
    const { open, record, handleClose, DeleteTraining } = props;

    // state
    const [loading, setLoading] = useState(false);

    //translation
    const { t } = useTranslation('training');

    // functions
    const handleDelete = async () => {
        setLoading(true);
        const res = await DeleteTraining(record?.id, record?.course);
        setLoading(false);
        if (res?.success) {
            toast.success('You have successfully removed the trainee from this training.');
            handleClose();
        } else {
            toast.error("Something when wrong, couldn't remove this employee.");
        }
    };

    return (
        <AppDeleteModal
            open={open}
            handleClose={handleClose}
            title={t('removeAssignmentModal.title')}
            subtitle={t('removeAssignmentModal.subtitle')}
            deleting={loading}
            onDelete={handleDelete}
            deleteText={t('removeAssignmentModal.deleteText')}
        />
    );
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps, { DeleteTraining })(RemoveAssignment);
