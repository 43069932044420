import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button } from 'antd';
import { useDispatch } from 'react-redux';

const SubReqModal = (props) => {
    const {
        open,
        handleClose,
        one_subreq,
        parent_requirement,
        CreateRequirement,
        EditRequirement,
        parent_req_num,
        sub_requirements,
    } = props;

    // state
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    // redux
    const dispatch = useDispatch();

    // function
    const handleSubmit = async (e) => {
        e.preventDefault();
        const userRequirementNumber = data?.requirement_number?.toString()?.split('.')[0];
        const matchSubReq = sub_requirements?.find((subReq) => subReq?.requirement_number == data?.requirement_number);
        if (userRequirementNumber != parent_req_num) {
            return toast.info(
                `You cannot create sub requirement ${data?.requirement_number} in requirement ${parent_req_num}`
            );
        } else if (matchSubReq) {
            if (open === 'Add' || data.id != matchSubReq?.id)
                return toast.info(`Sub requirement number: ${data?.requirement_number} already exists.`);
        }
        setLoading(true);
        const res =
            open == 'Add'
                ? await dispatch(CreateRequirement({ ...data, parent_requirement }, true))
                : await dispatch(
                      EditRequirement(
                          one_subreq?.id,
                          {
                              ...data,
                              parent_requirement,
                          },
                          true
                      )
                  );
        setLoading(false);
        if (res.success) {
            toast.success(res?.message);
            handleClose();
            setData({});
        } else {
            toast.error(res?.message);
        }
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    // useEffect
    useEffect(() => {
        if (one_subreq) {
            setData(one_subreq);
        } else {
            setData({});
        }
    }, [one_subreq]);
    return (
        <Modal title={`${open} Requirment`} open={open} onCancel={handleClose} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="requirement_number">Requirement Number</label>
                            <Input
                                type="number"
                                size="large"
                                name="requirement_number"
                                id="requirement_number"
                                onChange={handleTextChange}
                                value={data.requirement_number || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="requirement_title">Requirement Title</label>
                        <Input
                            type="text"
                            size="large"
                            name="requirement_title"
                            id="requirement_title"
                            onChange={handleTextChange}
                            value={data.requirement_title || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="requirement_description">Requirement Description</label>
                        <Input.TextArea
                            type="text"
                            size="large"
                            name="requirement_description"
                            id="requirement_description"
                            onChange={handleTextChange}
                            value={data.requirement_description || ''}
                            required
                        />
                    </Col>
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

export default SubReqModal;
