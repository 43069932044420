import React, { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { MoreHoriz } from '@mui/icons-material';

const MoreIcon = forwardRef((props, ref) => {
    const { size = 25 } = props;
    const theme = useTheme();
    return (
        <MoreHoriz
            sx={{
                backgroundColor: theme.palette.gray[50],
                borderRadius: '50%',
                color: theme.palette.primary[700],
                p: 0.5,
                fontSize: size,
            }}
            ref={ref}
        />
    );
});

export default MoreIcon;
