import React from 'react';
import { useTheme } from '@mui/styles';
//components
import { Box, Grid, Stack, Typography, Button } from '@mui/material';
import AppModal from 'components/new_components/AppModal';
import { ErrorOutline, Close } from '@mui/icons-material';
import AppChip from 'components/new_components/AppChip';
import EmptyComponent from 'components/EmptyComponents/EmptyComponent';

//formik
import { useFormikContext } from 'formik';

// import AppTags from 'components/new_components/AppTags';

const ComplianceAISuggestions = (props) => {
    const { open, handleClose, answers, question, fieldId } = props;

    const getTags = (index) => {
        if (index === 0) {
            return <AppChip text="Best Suggested" background={theme.palette.success[700]} />;
        } else if (index === 1) {
            return <AppChip text="Better Suggested" background={theme.palette.warning[800]} />;
        } else {
            return <AppChip text="Least Suggested" background={theme.palette.neutral[90]} noBg={true} />;
        }
    };

    const { setFieldValue } = useFormikContext();
    const theme = useTheme();
    return (
        <AppModal open={open} handleClose={handleClose} width={500}>
            <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
                <Box sx={{ mt: '-20px' }}>
                    <Typography sx={{ fontWeight: 700, fontSize: 24, color: theme.palette.gray[800] }}>
                        Suggestions
                    </Typography>
                    <Typography sx={{ fontSize: 12, fontWeight: 600, color: theme.palette.primary.main }}>
                        With Wale AI
                    </Typography>
                </Box>
                <Button
                    sx={{
                        display: 'flex',
                        backgroundColor: 'transparent',
                        color: '#858999',
                        fontSize: '12px',
                        width: '30px',
                        minWidth: 'unset',
                        border: 'none',
                        height: '30px',
                        justifyContent: 'center',
                        alignitems: 'center',
                        '&:hover': {
                            border: 'none',
                            background: 'transparent',
                        },
                    }}
                    variant="outlined"
                    onClick={handleClose}
                >
                    <Close />
                </Button>
            </Stack>
            <Stack direction="row" sx={{ borderBottom: '2px solid #F2F0F4', pb: 1.5 }}>
                <ErrorOutline sx={{ fontSize: '15px', color: theme.palette.gray[40], mb: 1 }} />
                <Typography sx={{ fontSize: 12, color: theme.palette.gray[40], ml: 1 }}>
                    It is easier to complete your audit questions with suggested answers
                </Typography>
            </Stack>

            <ul className="list-disc ml-1 text-[12px] mt-2 font-medium">
                <li>{question}</li>
            </ul>

            <Grid container sx={{ my: 2 }}>
                {Array.isArray(answers) && answers?.length ? (
                    answers
                        ?.sort((a, b) => b.percentage - a.percentage)
                        ?.map((answer, index) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    sx={{
                                        p: 1.5,
                                        my: 2,
                                        border: '1.2px solid #F2F0F4',
                                        fontSize: 12,
                                        color: theme.palette.gray[30],
                                        '&:hover': {
                                            background: '#fafafa',
                                        },
                                    }}
                                    key={index}
                                    onClick={() => {
                                        setFieldValue(fieldId?.toString(), answer?.answer);
                                        handleClose();
                                    }}
                                >
                                    {answer?.answer}
                                    <Stack justifyContent="flex-end" direction="row" sx={{ mt: 1.5 }}>
                                        {getTags(index)}
                                    </Stack>
                                </Grid>
                            );
                        })
                ) : (
                    <EmptyComponent text="No answers available for this question yet" />
                )}
            </Grid>
        </AppModal>
    );
};

export default ComplianceAISuggestions;
