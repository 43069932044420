import React from 'react';
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const AppSideBar = (props) => {
    const { midColour } = props;
    const theme = useTheme();

    return (
        <Stack sx={{ width: '2.7%' }}>
            <Box
                sx={{
                    height: '33.5vh',
                    backgroundColor: theme.palette.primary.main,
                }}
            ></Box>
            <Box
                sx={{
                    height: '33vh',
                    backgroundColor: midColour,
                }}
            ></Box>
            <Box
                sx={{
                    height: '33.5vh',
                    backgroundColor: theme.palette.primary.main,
                }}
            ></Box>
        </Stack>
    );
};

export default AppSideBar;
