import React from 'react';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';

//translations
import { useTranslation } from 'react-i18next';

const VendorGraphReport = ({ dashboardResult }) => {
    //translation
    const { t } = useTranslation('overview');

    //constants
    const labels = [
        t('vendorReportSection.low'),
        t('vendorReportSection.medium'),
        t('vendorReportSection.high'),
        t('vendorReportSection.critical'),
    ];

    const data = {
        labels,
        datasets: [
            {
                label: t('vendorReportSection.vendor'),
                backgroundColor: '#0E2C66',
                width: '20px',
                // data: [15, 10, 30, 40, 60, 70, 90, 95, 100]
                data: Object.values(dashboardResult?.vendors_classification || {}),
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: '',
            },
            datalabels: {
                display: false,
            },
        },
        scales: {
            y: {
                min: 0,
                max: 100,
            },
        },
    };

    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
    return (
        <div>
            <Bar options={options} data={data} type="bar" height="180" />
        </div>
    );
};

export default VendorGraphReport;
