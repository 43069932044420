import React, { useState } from 'react';
import { Box } from '@mui/material';

import ExceptionHeader from './exceptions_new/ExceiptionHeader';
import Document from './exceptions_new/Documents/Index';
import Evidence from './exceptions_new/Evidence/Index';
import Questions from './exceptions_new/Questions/Index';
import { useSelector } from 'react-redux';

const Exceptions = (props) => {
    // props
    const { getControls } = props;

    // // state
    const [resource, setResource] = useState('doc');
    const [selectedSubcontrol, setSelectedSubcontrol] = useState(null);
    const [active_tab, setActive_tab] = useState(0);
    const all_quality_management = useSelector((state) => state?.ISO9001Reducers?.all_quality_management);

    // logic functions
    const handleResourceChange = (resource) => {
        setResource(resource);
        setSelectedSubcontrol(null);
    };
    const handleSubgroupIdChange = (id) => {
        setSelectedSubcontrol(id);
    };

    return (
        <Box sx={{ px: 2.5, py: 3, flex: 1 }}>
            <ExceptionHeader
                active_tab={active_tab}
                setActive_tab={setActive_tab}
                handleResourceChange={handleResourceChange}
            />
            <Box>
                {active_tab === 0 && (
                    <Document
                        resource={resource}
                        handleSubgroupIdChange={handleSubgroupIdChange}
                        selectedSubgroup={selectedSubcontrol}
                        onExempted={getControls}
                        all_data={all_quality_management}
                    />
                )}
            </Box>
            <Box>
                {active_tab === 1 && (
                    <Evidence
                        resource={resource}
                        handleSubgroupIdChange={handleSubgroupIdChange}
                        selectedSubgroup={selectedSubcontrol}
                        onExempted={getControls}
                        all_data={all_quality_management}
                    />
                )}
            </Box>
            <Box>
                {active_tab === 2 && (
                    <Questions
                        resource={resource}
                        handleSubgroupIdChange={handleSubgroupIdChange}
                        selectedSubgroup={selectedSubcontrol}
                        onExempted={getControls}
                        all_data={all_quality_management}
                    />
                )}
            </Box>
        </Box>
    );
};

export default Exceptions;
