import React, { useEffect, useState } from 'react';

//core components
import { useTheme } from '@mui/material/styles';
import { Box, Card, Stack } from '@mui/material';
//utils
import ReleasesAside from './components/ReleasesAside';
import ReleaseContent from './components/ReleaseContent';
import PageHeader from 'components/new_components/PageHeader';

//Redux
import { connect } from 'react-redux';
import { GetAllReleases } from 'store/actions/adminActions';
import { toast } from 'react-toastify';

const ReleaseNotes = (props) => {
    const { GetAllReleases, all_releases } = props;

    const theme = useTheme();

    const [selected, setSelected] = useState(null);
    const [releaseDetails, setReleaseDetails] = useState({});
    const [loading, setloading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);

    //translation
    const language = localStorage.getItem('i18nextLng');

    const removeSelected = () => {
        setSelected(null);
    };

    const handlePageChange = (page) => {
        setPage(page);
    };

    const pageContent = (
        <Stack direction="row" spacing={3} sx={{ p: 1, width: '100%', position: 'relative', height: '100vh' }}>
            <ReleasesAside
                data={pageData}
                loading={loading}
                onClick={setSelected}
                selected={selected}
                dataLength={all_releases?.count}
                onPageChange={handlePageChange}
            />
            <ReleaseContent selected={selected} releaseDetails={releaseDetails} removeSelected={removeSelected} />
        </Stack>
    );
    useEffect(() => {
        const getAllReleases = async () => {
            setloading(true);
            const res = await GetAllReleases(page);
            setloading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllReleases();
    }, [page]);

    useEffect(() => {
        setPageData(all_releases?.results ? all_releases?.results : []);

        return () => setPageData([]);
    }, [page, all_releases?.results]);
    useEffect(() => {
        if (selected) {
            const selectedRelease = pageData?.find((release) => release?.id === selected);
            language?.includes('fr')
                ? setReleaseDetails(selectedRelease?.details_fr)
                : language?.includes('en')
                ? setReleaseDetails(selectedRelease?.details_en)
                : setReleaseDetails(selectedRelease?.details);
        }
    }, [selected, pageData]);

    return (
        <>
            <PageHeader browserTitle="Releases | Smartcomply" />
            <Card sx={{ p: 3, display: { xs: 'none', md: 'block' } }}>{pageContent}</Card>
            <Box
                sx={{
                    width: '100%',
                    overflowX: 'hidden',
                    display: {
                        xs: 'block',
                        md: 'none',
                        backgroundColor: theme.palette.white.main,
                    },
                }}
            >
                {pageContent}
            </Box>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_releases: state?.adminReducers?.all_releases,
    };
};

export default connect(mapStateToProps, { GetAllReleases })(ReleaseNotes);
